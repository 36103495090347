import { Col, Row } from 'antd';
import ComponentVisibilityToggler from 'components/ComponentVisibilityToggler/ComponentVisibilityToggler';
import { LanguageContext } from 'contexts/LanguageContext';
import DateInput, { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';
import RangeInput from 'elements/Inputs/RangeInput/RangeInput';
import SmartSearch from 'elements/Inputs/SmartSearch/SmartSearch';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import { SPACE_SIZES } from 'lib/constants';
import { DATE_FORMAT } from 'lib/Utils';
import _ from 'lodash';
import { useContext } from 'react';
import { translations } from '../../translations';
import { useInvoiceProcessingSection } from './useInvoiceProcessingSection';

const InvoiceProcessingSection = ({disabled}: {disabled: boolean}) => {
  const {
    invoice,
    onChange,
    onChangeProperty,
    onChangeServicePeriod,
    searchForProperty,
    onLoadProperty,
    propertyOptions,
    onChangeServicePeriodVisibility,
    shouldMakeServicePeriodWider,
  } = useInvoiceProcessingSection();
  const { tl } = useContext(LanguageContext);

  return (
    <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
      <Col span={24}>
        <SmartSearch
          value={invoice?.propertyId}
          onChange={onChangeProperty}
          label={tl(translations.invoiceEditing.sections.section1.fields.property)}
          searchFunction={searchForProperty}
          getOneFunction={onLoadProperty}
          options={propertyOptions}
          required
          autoFocus
          disabled={disabled}
        />
      </Col>
      <Col span={12}>
        <TextInput
          value={invoice?.invoiceNumber}
          onChange={v => onChange('invoiceNumber', v)}
          label={tl(translations.invoiceEditing.sections.section1.fields.invoiceNumber)}
          disabled={disabled}
        />
      </Col>
      <Col span={12}>
        <DateInput
          value={invoice?.invoiceDate}
          onChange={v => onChange('invoiceDate', v)}
          label={tl(translations.invoiceEditing.sections.section1.fields.invoiceDate)}
          required
          softMinDate={MIN_DATE_NORMAL}
          softMaxDate={MAX_DATE_NORMAL}
          disabled={disabled}
        />
      </Col>
      <Col span={shouldMakeServicePeriodWider ? 24 : 12}> 
        <ComponentVisibilityToggler
          labelWhenClosed={tl(translations.invoiceEditing.sections.section1.fields.servicePeriodHidden)}
          labelWhenOpen={tl(translations.invoiceEditing.sections.section1.fields.servicePeriodHidden)}
          defaultIsVisible={!_.isEmpty(invoice?.servicePeriod)}
          disabled={disabled}
          onVisibilityChange={onChangeServicePeriodVisibility}
        >
            <RangeInput
              value={invoice?.servicePeriod as unknown as [moment.Moment, moment.Moment]}
              onChange={onChangeServicePeriod}
              label={tl(translations.invoiceEditing.sections.section1.fields.servicePeriod)}
              required={false}
              format={DATE_FORMAT}
              disabled={disabled}
            />
        </ComponentVisibilityToggler>
      </Col>
    </Row>
  );
};


export default InvoiceProcessingSection;
