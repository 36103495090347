import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';

/** DO NOT USE THIS. Fetch the property and check the administration type. Ain't that hard.
 *
 * This method cannot differentiate from propertyHrId between SEV and SEV_MV
 */
export const getAdministrationTypeFromPropertyHrIdForArticles = (propertyHrId: string): PropertyLegacyDtoAdministrationTypeEnum | undefined => {
  if (propertyHrId?.startsWith('WEG')) return PropertyLegacyDtoAdministrationTypeEnum.WEG;
  if (propertyHrId?.startsWith('MV')) return PropertyLegacyDtoAdministrationTypeEnum.MV;
  if (propertyHrId?.startsWith('SEV')) return PropertyLegacyDtoAdministrationTypeEnum.SEV;
  return undefined;
};
