import { Skeleton } from 'antd';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { useDunningLevelSelector } from './useDunningLevelSelector';

export const DunningLevelSelectorCell = ({ record }) => {
  const {
    options, onChange, value, disabled,
  } = useDunningLevelSelector(record);
  // skeleton will be used when the dunning levels will be loaded from the backend

  if (!record.isRootAccount) {
    return null;
  }

  return (
    <Skeleton
      loading={false}
      active
      title={false}
      paragraph={{ rows: 1, width: '100%' }}
    >
      <SelectInput
        options={options}
        onChange={onChange}
        value={value}
        dropdownMatchSelectWidth={false}
        disabled={disabled}
      />
    </Skeleton>
  );
};
