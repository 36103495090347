import { useContext } from 'react';
import { AmountDistributionUpdatersAndDatasourceContext } from './ManualExchangeContext';

export const useOnChangeDistributionValues = () => {
  const amountDistributionUpdatersAndDatasourceContext = useContext(AmountDistributionUpdatersAndDatasourceContext);

  if (amountDistributionUpdatersAndDatasourceContext === undefined) {
    throw new Error('useOnChangeDistributionValues must be used within a ManualExchangeContextProvider');
  }

  const { onChangeDistributionValue } = amountDistributionUpdatersAndDatasourceContext;


  return onChangeDistributionValue;
};
