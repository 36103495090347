import { useContext } from 'react';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { OwnersMeetingProtocolContext } from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';


export const useOwnerMeetingProtocolHeaderEditing = () => {
  const ownersMeetingProtocolContext: any = useContext(OwnersMeetingProtocolContext);
  const propertyListContext: any = useContext(PropertyListContext);

  if (ownersMeetingProtocolContext === undefined) {
    throw new Error('useOwnerMeetingProtocolHeaderEditing must be used within a OwnersMeetingInvitationProvider');
  }

  if (propertyListContext === undefined) {
    throw new Error('useOwnerMeetingProtocolHeaderEditing must be used within a PropertyListProvider');
  }

  const {
    onLoadOwnersMeetingProtocolList, ownersMeeting, isValid, onDeleteOwnersMeetingProtocol, selectedOwnersMeetingId,
  } = ownersMeetingProtocolContext;


  const { goBack } = useContext(OverlayContext);

  const onClickClose = () => {
    onLoadOwnersMeetingProtocolList(true);
    goBack();
  };
  const { selectedDisplayProperty } = propertyListContext;

  // don't show subtitle in create view
  const showSubtitle = !!selectedOwnersMeetingId;

  const property = selectedDisplayProperty.data?.id === ownersMeeting.data?.propertyId
    ? selectedDisplayProperty.data
    : {};


  return {
    onClose: onClickClose,
    ownersMeeting: ownersMeeting.data,
    loading: ownersMeeting.loading,
    property,
    isValid,
    onDeleteOwnersMeetingProtocol,
    showSubtitle,
  };
};
