import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency, formatDate } from 'lib/Utils';
import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { PropertyDisplayDtoVatRelevanceEnum, UnitContractProjectionDto } from 'api/accounting';
import moment from 'moment';
import Amount from 'storybook-components/typography/Amount/Amount';

export interface PnlAnnex {
  unitNrSharingDeclaration: string,
  unitRank: number,
  constractSignedDate: moment.Moment,
  tenantName: string,
  startBalance: number,
  contractStartDate: moment.Moment | undefined,
  contractEndDate: moment.Moment | undefined,
  debitBalance: number,
  creditBalance: number,
  nominalRentNet: number
  opsCostNet: number,
  additionalIncomeNet: number,
  directCostNet: number,
  vat: number | undefined,
  deposit: number,
  endBalance: number,
}

const COLUMN1_WIDTH = '6%';
const COLUMN2_WIDTH = '10%';
const COLUMN3_WIDTH = '8%';
const AMOUNT_COLUMN_WIDTH = '8%';
const VAT_COLUMN_WIDTH = '6%';

export const useAnnexTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useAnnexTable must be used within a ProfitAndLossReportContextProvider');
  }

  const {
    accountBalances, units, unitContracts, reportData, accountBalancesWhereCounterpartNotCash, accountBalancesWhereCounterpartCash,
  } = profitAndLossReportContext;

  const vatRelevantProperty = [PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT, PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT]
    .includes(reportData.property?.vatRelevance);


  const dataSource = useMemo(() => {
    if (!accountBalances.loaded || !units.loaded || !unitContracts.loaded) return [];

    const ds = unitContracts.data
      .filter(uc =>
        // ignore contracts which stareted after the report end date
        (!uc.startDate || moment(uc.startDate).isSameOrBefore(reportData.endDate))
        &&
        // ignore vacancies which ended before report start date        
        (!uc.isVacant || (!uc.endDate || moment(uc.endDate).isSameOrAfter(reportData.startDate)))
      )
      .map((uc: UnitContractProjectionDto) => {
        const { unitRank } = units.data.find(u => u.id === uc.unitId);

        if (uc.isVacant) {
          // vacancies do not have accounts, therefor no balance for them
          return {
            isVacant: uc.isVacant,
            endDate: uc.endDate,
            unitNrSharingDeclaration: uc.unitNrSharingDeclaration,
            unitRank,
            constractSignedDate: moment(uc.signedDate),
            tenantName: tl(translations.report.sections.annexSection.vacancy),
            contractStartDate: uc.startDate ? moment(uc.startDate) : undefined,
            contractEndDate: uc.endDate ? moment(uc.endDate) : undefined,
            startBalance: 0,
            debitBalance: 0,
            creditBalance: 0,
            nominalRentNet: 0,
            opsCostNet: 0,
            additionalIncomeNet: 0,
            directCostNet: 0,
            vat: 0,
            deposit: 0,
            endBalance: 0,
          };
        }

        const nominalRentAccountBalanceByCounterpartCash = accountBalancesWhereCounterpartCash.data.find(ab => ab.accountCode === `${uc.accountCode}/0`);
        const opsCostAccountBalanceByCounterpartCash = accountBalancesWhereCounterpartCash.data.find(ab => ab.accountCode === `${uc.accountCode}/1`);
        const additionalIncomeAccountBalanceByCounterpartCash = accountBalancesWhereCounterpartCash.data.find(ab => ab.accountCode === `${uc.accountCode}/2`);
        const directCostAccountBalanceByCounterpartCash = accountBalancesWhereCounterpartCash.data.find(ab => ab.accountCode === `${uc.accountCode}/3`);

        const vat = nominalRentAccountBalanceByCounterpartCash?.vatBalance
          + opsCostAccountBalanceByCounterpartCash?.vatBalance
          + additionalIncomeAccountBalanceByCounterpartCash?.vatBalance
          + directCostAccountBalanceByCounterpartCash?.vatBalance;

        const debitBalance = accountBalancesWhereCounterpartNotCash.data.find(ab => ab.accountCode === `${uc.accountCode}/0`)?.balance
          + accountBalancesWhereCounterpartNotCash.data.find(ab => ab.accountCode === `${uc.accountCode}/1`)?.balance
          + accountBalancesWhereCounterpartNotCash.data.find(ab => ab.accountCode === `${uc.accountCode}/2`)?.balance
          + accountBalancesWhereCounterpartNotCash.data.find(ab => ab.accountCode === `${uc.accountCode}/3`)?.balance;

        const creditBalance = nominalRentAccountBalanceByCounterpartCash?.balance
          + opsCostAccountBalanceByCounterpartCash?.balance
          + additionalIncomeAccountBalanceByCounterpartCash?.balance
          + directCostAccountBalanceByCounterpartCash?.balance;


        return {
          isVacant: uc.isVacant,
          endDate: uc.endDate,
          unitNrSharingDeclaration: uc.unitNrSharingDeclaration,
          unitRank,
          constractSignedDate: moment(uc.signedDate),
          tenantName: uc.isVacant ? tl(translations.report.sections.annexSection.vacancy) : uc.mailingContact?.name,
          contractStartDate: uc.startDate ? moment(uc.startDate) : undefined,
          contractEndDate: uc.endDate ? moment(uc.endDate) : undefined,
          startBalance: accountBalances.data[uc.accountCode].normalizedBalanceBeforeDateRange,
          debitBalance,
          creditBalance: Math.abs(creditBalance),
          nominalRentNet: Math.abs(nominalRentAccountBalanceByCounterpartCash?.balance - nominalRentAccountBalanceByCounterpartCash?.vatBalance),
          opsCostNet: Math.abs(opsCostAccountBalanceByCounterpartCash?.balance - opsCostAccountBalanceByCounterpartCash?.vatBalance),
          additionalIncomeNet: Math.abs(additionalIncomeAccountBalanceByCounterpartCash?.balance - additionalIncomeAccountBalanceByCounterpartCash?.vatBalance),
          directCostNet: Math.abs(directCostAccountBalanceByCounterpartCash?.balance - directCostAccountBalanceByCounterpartCash?.vatBalance),
          vat: Math.abs(vat),
          deposit: accountBalances.data[`${uc.accountCode}/4`].normalizedBalanceWithinDateRange,
          endBalance: accountBalances.data[uc.accountCode].normalizedBalanceAtEndOfDateRange,
        };
      })
      // ignore non-vacant contract with 0 end balance witch ended before report start date
      .filter(uc => (!uc.endDate || moment(uc.endDate).isSameOrAfter(reportData.startDate)) || (uc.endBalance !== 0) || uc.isVacant)
      .sort((a: PnlAnnex, b: PnlAnnex) => {
        if (a.unitRank === b.unitRank) {
          return a.constractSignedDate.isBefore(b.constractSignedDate) ? -1 : 1;
        }
        return a.unitRank - b.unitRank;
      });
    return ds;
  }, [accountBalances.data, units.data, unitContracts.data]);


  const sums = dataSource.reduce((acc, curr) => ({
    startBalanceSum: acc.startBalanceSum + curr.startBalance,
    debitBalanceSum: acc.debitBalanceSum + curr.debitBalance,
    creditBalanceSum: acc.creditBalanceSum + curr.creditBalance,
    nominalRentSum: acc.nominalRentSum + curr.nominalRentNet,
    opsCostSum: acc.opsCostSum + curr.opsCostNet,
    additionalIncomeSum: acc.additionalIncomeSum + curr.additionalIncomeNet,
    directCostSum: acc.directCostSum + curr.directCostNet,
    depositSum: acc.depositSum + curr.deposit,
    endBalanceSum: acc.endBalanceSum + curr.endBalance,
    vat: acc.vat + curr.vat,
  }), {
    startBalanceSum: 0,
    debitBalanceSum: 0,
    creditBalanceSum: 0,
    nominalRentSum: 0,
    opsCostSum: 0,
    additionalIncomeSum: 0,
    directCostSum: 0,
    depositSum: 0,
    endBalanceSum: 0,
    vat: 0,
  });

  const formatToCurrencyWithoutSymbol = (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>;

  const columns: ColumnsType<PnlAnnex> = [
    {
      title: tl(translations.report.sections.annexSection.columns.unitNrSharingDeclaration),
      dataIndex: 'unitNrSharingDeclaration',
      width: COLUMN1_WIDTH,
      fixed: 'left',
    },
    {
      title: tl(translations.report.sections.annexSection.columns.tenantName),
      dataIndex: 'tenantName',
      width: COLUMN2_WIDTH,
      fixed: 'left',
    },
    {
      title: tl(translations.report.sections.annexSection.columns.rentPeriod),
      dataIndex: 'period',
      width: COLUMN3_WIDTH,
      fixed: 'left',
      render: (_, record) => <div>{`${record.contractStartDate ? formatDate(record.contractStartDate, 'DD.MM.YYYY') : ''} - ${record.contractEndDate ? formatDate(record.contractEndDate, 'DD.MM.YYYY') : ''}`}</div>,
    },
    {
      title: tl(translations.report.sections.annexSection.columns.startBalance),
      dataIndex: 'startBalance',
      className: 'column-align-right no-wrap-td multiline-table-header',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },
    {
      title: vatRelevantProperty
        ? tl(translations.report.sections.annexSection.columns.debitBrut)
        : tl(translations.report.sections.annexSection.columns.debit),
      dataIndex: 'debitBalance',
      className: 'column-align-right no-wrap-td multiline-table-header',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },
    {
      title: vatRelevantProperty
        ? tl(translations.report.sections.annexSection.columns.creditBrut)
        : tl(translations.report.sections.annexSection.columns.credit),
      dataIndex: 'creditBalance',
      className: 'column-align-right no-wrap-td multiline-table-header',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },

    {
      title: vatRelevantProperty
        ? tl(translations.report.sections.annexSection.columns.nominalRentNet)
        : tl(translations.report.sections.annexSection.columns.nominalRent),
      dataIndex: 'nominalRentNet',
      className: 'column-align-right no-wrap-td multiline-table-header group-column-darker',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },
    {
      title: vatRelevantProperty
        ? tl(translations.report.sections.annexSection.columns.opsCostSettlementNet)
        : tl(translations.report.sections.annexSection.columns.opsCostSettlement),
      dataIndex: 'opsCostNet',
      className: 'column-align-right no-wrap-td multiline-table-header group-column-darker',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },
    {
      title: vatRelevantProperty
        ? tl(translations.report.sections.annexSection.columns.additionalIncomeNet)
        : tl(translations.report.sections.annexSection.columns.additionalIncome),
      dataIndex: 'additionalIncomeNet',
      className: 'column-align-right no-wrap-td multiline-table-header group-column-darker',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },
    {
      title: vatRelevantProperty
        ? tl(translations.report.sections.annexSection.columns.directCostNet)
        : tl(translations.report.sections.annexSection.columns.directCost),
      dataIndex: 'directCostNet',
      className: 'column-align-right no-wrap-td multiline-table-header group-column-darker',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },

    {
      title: tl(translations.report.sections.annexSection.columns.deposit),
      dataIndex: 'deposit',
      className: 'column-align-right no-wrap-td multiline-table-header',
      width: AMOUNT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    },
    {
      title: tl(translations.report.sections.annexSection.columns.endBalance),
      dataIndex: 'endBalance',
      className: 'column-align-right no-wrap-td multiline-table-header',
      render: formatToCurrencyWithoutSymbol,
    },
  ];

  if (vatRelevantProperty) {
    columns.splice(10, 0, {
      title: tl(translations.report.sections.annexSection.columns.vat),
      dataIndex: 'vat',
      className: 'column-align-right no-wrap-td multiline-table-header group-column-darker',
      width: VAT_COLUMN_WIDTH,
      render: formatToCurrencyWithoutSymbol,
    });
  }

  return {
    dataSource, sums, columns, vatRelevantProperty,
  };
};
