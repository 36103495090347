export const getSectionErrors = (property: any, errors: any) => {
  const sectionErrors: any = {};

  Object.keys(errors)
    // Get invalid fields from response
    .filter(key => !['status', 'savedEntity', 'title'].includes(key))
    .forEach((key: string) => {
      if (key.endsWith('Insurance')) key += '.contractDateRange';
      if (key.startsWith('accountDrafts') && errors[key] === 'notNull') {
        const newKey = `accounts[${property.accounts.findIndex(a => !a.code)}].code`;
        sectionErrors[newKey] = errors[key];
      } else {
        sectionErrors[key] = errors[key];
      }
    });

  return sectionErrors;
};
