import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import DEFAULT_DATA from '../lib/data';
import { LanguageContext } from './LanguageContext';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { showNotification } from '../lib/Notification';

export const DirectDebitMandateListContext: any = React.createContext({});

export default function DirectDebitMandateListProvider({ children }: any) {
  // TODO (PMP-632) remove dummy data and enable backend queries
  const defaultContext: any = [
    {
      value: 'LSM00001',
      label: 'Auftrag Nr123',
    },
    {
      value: 'LSM00002',
      label: 'Auftrag Nr456',
    },
  ];

  const [directDebitListState, setDirectDebitListState] = useState(DEFAULT_DATA<any>(defaultContext));

  const { tl } = useContext(LanguageContext);

  const optionObjectFromDirectDebitMandate = (ddm: any) => ({ value: ddm.directDebitMandateHrId, label: ddm.name });

  const onLoadDirectDebitMandateList = (filter: string) => {
    return;
    setDirectDebitListState(directDebitListState.startLoading()); // eslint-disable-line
    backend.get(`${endpointUrls.INVOICE}/directDebitMandates`, { filter })
      .then((response: any) => {
        const directDebitMandateOptionsList = response.map(optionObjectFromDirectDebitMandate);
        setDirectDebitListState(directDebitListState.load(directDebitMandateOptionsList));
      })
      .catch(() => {
        setDirectDebitListState(directDebitListState.failed());
        showNotification({
          key: 'loadDirectDebitMandatesError',
          message: tl(translations.notifications.directDebitMandateListContext.loadError.message),
          description: tl(translations.notifications.directDebitMandateListContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onLoadDirectDebitMandate = (hrId: string) => {
    return;
    setDirectDebitListState(directDebitListState.startLoading()); // eslint-disable-line
    backend.get(`${endpointUrls.INVOICE}/directDebitMandates/${hrId}`, {})
      .then((response: any) => {
        const newList = directDebitListState.data.directDebitMandateList;
        const directDebitMandateOption = optionObjectFromDirectDebitMandate(response);
        if (newList.filter((option: any) => option.value === directDebitMandateOption.value).length === 0) {
          newList.push(directDebitMandateOption);
          setDirectDebitListState(directDebitListState.load(newList));
        }
      })
      .catch(() => {
        setDirectDebitListState(directDebitListState.failed());
        showNotification({
          key: 'loadDirectDebitMandateError',
          message: tl(translations.notifications.directDebitMandateListContext.loadError.message),
          description: tl(translations.notifications.directDebitMandateListContext.loadError.description),
          type: 'error',
        });
      });
  };

  return (
    <DirectDebitMandateListContext.Provider value={{
      ...directDebitListState,
      onLoadDirectDebitMandateList,
      onLoadDirectDebitMandate,
    }}
    >
      {children}
    </DirectDebitMandateListContext.Provider>
  );
}

DirectDebitMandateListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
