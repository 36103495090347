import {
  ContractProjectionDto,
  FindFilteredPropertiesUsingGETAdministrationTypesEnum,
  GetUnitContractsUsingGETContractTypesEnum,
  PropertyDisplayDto,
  PropertyDisplayDtoAdministrationTypeEnum,
  PropertyLegacyControllerApi, PropertyLegacyDtoVatRelevanceEnum, UnitContractControllerApi, UnitContractProjectionDto, UnitContractProjectionDtoVatRelevanceEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { showNotification } from 'lib/Notification';
import { DATE_FORMAT, ISO_DATE_FORMAT } from 'lib/Utils';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import moment from 'moment';
import { getPropertyEditingRoute } from 'pages/Property/routes';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { getStateOfContract, isOverlapping } from 'services/UnitContractsList/useUnitContractUtils';
import { ExtendedUnitContractProjectionDto } from 'pages/UnitContractHistory/interfaces';
import SelectOptionWithTwoLines from 'storybook-components/inputs/select/SelectOptionWithTwoLines/SelectOptionWithTwoLines';
import { SEVPropertyCreationModalTranslation } from './SEVPropertyCreationModalTranslation';
import { useCreateSEVPropertyAndCopyDataFromWEG } from './useCreateSEVPropertyAndCopyDataFromWEG';

export interface InputValues {
  propertyId: number,
  ownerContractId: number,
  tenantContractIds: number[],
}

const MAX_PAGE_SIZE = 2147483647;
export const useSEVPropertyCreationModal = (
  existingSEVPropertyId: number | undefined,
  existingSEVUnitId: number | undefined,
  existingSEVVatRelevance: PropertyLegacyDtoVatRelevanceEnum | undefined,
  alreadyCopiedWegTenantContractIds: number[],
  initInputValues: InputValues | undefined,
  onCancel: () => void,
  onSuccess: () => void | undefined,
  visible: boolean,
) => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const history = useHistory();
  const { onCreateSEVPropertyAndCopyDataFromWEG, copyWegTenantContractsToSev, loading } = useCreateSEVPropertyAndCopyDataFromWEG();

  const [inputValues, setInputValues] = useState<InputValues>(undefined);
  const [propertyList, setPropertyList] = useState<DefaultDataInterface<PropertyDisplayDto[]>>(DEFAULT_DATA<PropertyDisplayDto[]>([]));

  const [ownerContracts, setOwnerContracts] = useState<DefaultDataInterface<ExtendedUnitContractProjectionDto[]>>(DEFAULT_DATA<ExtendedUnitContractProjectionDto[]>([]));
  const [tenantContracts, setTenantContracts] = useState<DefaultDataInterface<UnitContractProjectionDto[]>>(DEFAULT_DATA<UnitContractProjectionDto[]>([]));

  const [existingSEVPropertyWithTheSameOwnerContractId, setExistingSEVPropertyWithTheSameOwnerContractId] = useState<PropertyDisplayDto>(undefined);
  const [existingSEVHasDifferentVatRelevanceCompareToContracts, setExistingSEVHasDifferentVatRelevanceCompareToContracts] = useState<boolean>(false);
  const [selectedTenantContarctsAreVatRelevant, setSelectedTenantContarctsAreVatRelevant] = useState<boolean>(false);
  const unitContractController = new UnitContractControllerApi(apiConfiguration('accounting'));
  const propertyController = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const isCopyTenantContractModeOnly = !!existingSEVPropertyId;
  const disabledPropertySelect = isCopyTenantContractModeOnly;
  const disabledOwnerContractSelect = inputValues?.propertyId === undefined || isCopyTenantContractModeOnly;
  const disabledTenantContractSelect = inputValues?.ownerContractId === undefined;
  const disabledCreationButton = inputValues?.propertyId === undefined
    || inputValues?.ownerContractId === undefined
    || (!inputValues?.tenantContractIds?.length && isCopyTenantContractModeOnly)
    || (existingSEVPropertyWithTheSameOwnerContractId !== undefined && !isCopyTenantContractModeOnly)
    || (existingSEVHasDifferentVatRelevanceCompareToContracts)
    || loading;

  useEffect(() => {
    if (!initInputValues) return;

    setInputValues(prev => ({
      ...prev,
      propertyId: initInputValues?.propertyId,
    }));
    setInputValues(prev => ({
      ...prev,
      ownerContractId: initInputValues?.ownerContractId,
    }));
  }, [initInputValues]);

  useEffect(() => {
    if (!visible) {
      setPropertyList(prev => prev.resetToInitial());
      return;
    }

    setPropertyList(prev => prev.startLoading());
    propertyController.findFilteredPropertiesUsingGET({
      administrationTypes: [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG, FindFilteredPropertiesUsingGETAdministrationTypesEnum.SEV] as unknown as FindFilteredPropertiesUsingGETAdministrationTypesEnum,
      excludeFields: ['bankAccounts', 'managementCompany'],
      size: MAX_PAGE_SIZE,
    })
      .then((response) => {
        setPropertyList(prev => prev.load(response.content));
      }).catch((err) => {
        console.error(err);
        setPropertyList(prev => prev.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(SEVPropertyCreationModalTranslation.notifications.propertyLoadError),
          type: 'error',
        });
      });
  }, [visible]);

  useEffect(() => {
    if (inputValues?.propertyId === undefined) {
      setOwnerContracts(prev => prev.resetToInitial());
      setTenantContracts(prev => prev.resetToInitial());
      return;
    }

    onLoadOwnersContractsWithState();
  }, [inputValues?.propertyId]);


  useEffect(() => {
    if (!isCopyTenantContractModeOnly) {
      onCheckIfSEVPropertyAlreadyExists();
    }
    if (inputValues?.ownerContractId === undefined) {
      return;
    }

    setTenantContracts(prev => prev.startLoading());
    unitContractController.getUnitContractsUsingGET({
      propertyId: inputValues.propertyId,
      contractTypes: [GetUnitContractsUsingGETContractTypesEnum.TENANT] as unknown as GetUnitContractsUsingGETContractTypesEnum,
    })
      .then((response) => {
        setTenantContracts(prev => prev.load(response));
      }).catch((err) => {
        console.error(err);
        setTenantContracts(prev => prev.failed());
        showNotification({
          key: 'loadTenantContractsError',
          message: tl(SEVPropertyCreationModalTranslation.notifications.loadTenantContractsError),
          type: 'error',
        });
      });
  }, [inputValues?.ownerContractId, ownerContracts.loaded]);

  useEffect(() => {
    if (isEmpty(inputValues?.tenantContractIds)) {
      return;
    }

    if (!isCopyTenantContractModeOnly) {
      onCheckIfSelectedTenantContarctsAreVatRelevant();
    } else {
      onCheckIfExistingSEVHasDifferentVatRelevanceCompareToContracts();
    }
  }, [inputValues?.tenantContractIds]);

  // default value for tenant contract should be tenant contracts that overlap with the owner contract
  const setDefaultValuesForTenanctContractInput = () => {
    if (isEmpty(tenantContracts?.data)) {
      return;
    }

    const ownerContract = ownerContracts.data.find(contract => contract.unitContractId === inputValues?.ownerContractId);

    const tenantContractsOverlaps = tenantContracts.data.filter(tenantContract => tenantContract?.unitId === ownerContract?.unitId
      && isOverlapping(ownerContract, tenantContract))
      .map(contract => contract.unitContractId);

    if (!isEmpty(tenantContractsOverlaps)) {
      setInputValues({
        ...inputValues,
        tenantContractIds: tenantContractsOverlaps,
      });
    }
  };

  const onLoadOwnerContracts = () => unitContractController.getUnitContractsUsingGET({
    propertyId: inputValues?.propertyId,
    contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER] as unknown as GetUnitContractsUsingGETContractTypesEnum,
  });

  const onLoadActiveOwnerContracts = () => unitContractController.getUnitContractsUsingGET({
    atDate: moment().format(ISO_DATE_FORMAT),
    propertyId: inputValues?.propertyId,
    contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER] as unknown as GetUnitContractsUsingGETContractTypesEnum,
  });

  const onLoadOwnersContractsWithState = () => {
    setOwnerContracts(prev => prev.startLoading());
    Promise.all([onLoadOwnerContracts(), onLoadActiveOwnerContracts()])
      .then(([contracts, activeContracts]) => {
        setOwnerContracts(prev => prev.load(contracts.map((unitContract) => {
          const activeContractId = activeContracts?.find(c => c.unitId === unitContract.unitId)?.unitContractId;
          const state = getStateOfContract(unitContract, activeContractId);
          return {
            ...unitContract,
            state,
          };
        })));
      }).catch((err) => {
        console.error(err);
        setOwnerContracts(prev => prev.failed());
        showNotification({
          key: 'loadOwnerContractsError',
          message: tl(SEVPropertyCreationModalTranslation.notifications.loadOwnerContractsError),
          type: 'error',
        });
      });
  };


  const onChangePropertyId = (value: number) => {
    setInputValues({
      propertyId: value,
      ownerContractId: undefined,
      tenantContractIds: [],
    });
    setExistingSEVPropertyWithTheSameOwnerContractId(undefined);
    setExistingSEVHasDifferentVatRelevanceCompareToContracts(false);
    setSelectedTenantContarctsAreVatRelevant(false);
  };

  const onChangeOwnerContract = (value: number) => {
    setInputValues(prev => ({
      ...prev,
      ownerContractId: value,
      tenantContractIds: [],
    }));
    setExistingSEVHasDifferentVatRelevanceCompareToContracts(false);
    setSelectedTenantContarctsAreVatRelevant(false);
  };

  const onChangeTenantContracts = (values: number[]) => {
    setInputValues(prev => ({
      ...prev,
      tenantContractIds: values,
    }));
    setExistingSEVHasDifferentVatRelevanceCompareToContracts(false);
    setSelectedTenantContarctsAreVatRelevant(false);
  };

  const onCreateSEVProperty = () => {
    onCreateSEVPropertyAndCopyDataFromWEG(inputValues.propertyId, inputValues.ownerContractId, inputValues.tenantContractIds, selectedTenantContarctsAreVatRelevant)
      .then((sevProperty) => {
        onCancel();
        setInputValues(undefined);
        if (sevProperty) {
          propertyList?.data.push(sevProperty as unknown as PropertyDisplayDto);
          history.push(getPropertyEditingRoute(sevProperty.propertyHrId));
        }
        setExistingSEVPropertyWithTheSameOwnerContractId(undefined);
      });
  };

  const onCopyWegContractsToExistingSEV = () => {
    const tenantContractsToCopyFromWeg = tenantContracts.data.filter(
      c => inputValues.tenantContractIds.includes(c.unitContractId)
        && !alreadyCopiedWegTenantContractIds.includes(c.unitContractId),
    );
    copyWegTenantContractsToSev(existingSEVPropertyId, existingSEVUnitId, tenantContractsToCopyFromWeg as unknown as ContractProjectionDto[])
      .then(() => {
        onSuccess?.();
        onCancel();
      });
  };

  const wegOptions: SelectOption[] = useMemo(() => propertyList.data
    .filter(property => property.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG)
    .map(prp => ({
      label: `${prp.propertyIdInternal} - ${prp.name}`,
      value: prp.id!,
    } as SelectOption)), [propertyList.loaded]);

  const ownerContractOptions = useMemo(() => ownerContracts.data.map(contract => ({
    label: `${contract.unitNrSharingDeclaration} - ${contract.mailingContact.name}`,
    value: contract.unitContractId,
    customBody: <SelectOptionWithTwoLines
      primaryText={`${contract.unitNrSharingDeclaration} - ${contract.mailingContact.name}`}
      secondaryText={tl(SEVPropertyCreationModalTranslation.contractStates[contract.state])}
    />,

  } as SelectOption)), [ownerContracts.loaded]);


  const tenantContractsOptions: SelectOption[] = useMemo(() => {
    setDefaultValuesForTenanctContractInput();

    const ownerContract = ownerContracts.data.find(contract => contract.unitContractId === inputValues?.ownerContractId);

    return tenantContracts.data.filter(tenantContract => tenantContract?.unitId === ownerContract?.unitId).map(contract => ({
      label: `${contract.startDate
        ? moment(contract.startDate).format(DATE_FORMAT)
        : '—'
      } - ${contract.endDate
        ? moment(contract.endDate).format(DATE_FORMAT)
        : '—'
      } ${contract.mailingContact?.name ?? tl(SEVPropertyCreationModalTranslation.vacancy)}`,
      value: contract.unitContractId,
    } as SelectOption));
  }, [tenantContracts.loaded]);

  const onCheckIfSEVPropertyAlreadyExists = () => {
    if (inputValues?.ownerContractId === undefined) {
      setExistingSEVPropertyWithTheSameOwnerContractId(undefined);
      return;
    }
    const existingSEV = propertyList.data.find(property => property.ownerContractId === inputValues?.ownerContractId);
    setExistingSEVPropertyWithTheSameOwnerContractId(existingSEV);
  };

  const checkIfTenantContractsAreVatRelevant = () => tenantContracts.data.some(contract => inputValues?.tenantContractIds.includes(contract?.unitContractId)
    && [UnitContractProjectionDtoVatRelevanceEnum.VAT_RELEVANT, UnitContractProjectionDtoVatRelevanceEnum.REDUCED_VAT_RELEVANT].includes(contract.vatRelevance));

  const onCheckIfSelectedTenantContarctsAreVatRelevant = () => {
    if (isEmpty(inputValues?.tenantContractIds)) {
      setSelectedTenantContarctsAreVatRelevant(false);
      return;
    }

    setSelectedTenantContarctsAreVatRelevant(checkIfTenantContractsAreVatRelevant());
  };

  const onCheckIfExistingSEVHasDifferentVatRelevanceCompareToContracts = () => {
    if (existingSEVVatRelevance === undefined) {
      setExistingSEVHasDifferentVatRelevanceCompareToContracts(false);
      return;
    }
    const differentVatRelevance = checkIfTenantContractsAreVatRelevant() !== [PropertyLegacyDtoVatRelevanceEnum.PARTIALLY_RELEVANT, PropertyLegacyDtoVatRelevanceEnum.FULLY_RELEVANT].includes(existingSEVVatRelevance);

    setExistingSEVHasDifferentVatRelevanceCompareToContracts(differentVatRelevance);
  };

  const onCancelAndClearValuesOfInputs = () => {
    setInputValues(undefined);
    setExistingSEVPropertyWithTheSameOwnerContractId(undefined);
    onCancel();
  };


  return {
    inputValues,
    onChangePropertyId,
    wegOptions,
    disabledPropertySelect,
    disabledOwnerContractSelect,
    ownerContractOptions,
    onChangeOwnerContract,
    ownerContractsLoading: ownerContracts.loading,
    propertyListLoading: propertyList.loading,
    tenantContractsOptions,
    onChangeTenantContracts,
    disabledTenantContractSelect,
    onCreateSEVProperty,
    onCopyWegContractsToExistingSEV,
    disabledCreationButton,
    loadingCreationButton: loading,
    onCancelAndClearValuesOfInputs,
    existingSEVPropertyWithTheSameOwnerContractId,
    existingSEVHasDifferentVatRelevanceCompareToContracts,
  };
};
