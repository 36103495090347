
export const executeInParallelBatch = async (allData:any[], batchSize:number = 10, handler:(el:any)=>Promise<void>):Promise<void> => {
  // process the array in parallel batches
  // we cannot let 500 requests run in parallel
  // thus the limit.
  for (let batchIdx = 0; batchIdx < allData.length; batchIdx++) {
    const batchData = allData.slice(batchIdx * batchSize, (batchIdx + 1) * batchSize);
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(batchData.map(handler));
  }
};
