export const translations = {
  title: {
    en: 'Transactions',
    de: 'Überweisungen',
  },
  import: { en: 'Import', de: 'Importieren' },
  description: {
    en: 'List of transactions to upload',
    de: 'Liste der Überweisungen zum hochladen. Die Liste sollte keine schon bereits existierende Überweisungen beinhalten um Duplikate zu vermeiden.',
  },
  upload: {
    en: 'Upload transactions',
    de: 'Überweisungen hochladen',
  },
  success: {
    en: 'Success',
    de: 'Erfolgreich',
  },
  error: {
    en: 'Failed',
    de: 'Fehler',
  },
  validations: {
    success: {
      en: 'Transactions have been verified and ready for import.',
      de: 'Überweisungen wurden geprüft und sind bereit zum Importieren',
    },
    errors: {
      en: 'Issues found in the CSV',
      de: 'Es wurden Fehlern gefunden',
    },
    issues: {
      amount: {
        en: 'Invalid amount',
        de: 'Ungültiges Wert',
      },
      bankBookingDate: {
        en: 'Invalid date',
        de: 'Ungültiges Datum',
      },
      propertyIban: {
        en: 'Invalid IBAN',
        de: 'Ungültiges IBAN',
      },
      balance: {
        en: 'Invalid balance',
        de: 'Ungültiges Kontostand',
      },
    },
  },
};
