/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorCodeDetailsDto
 */
export interface ErrorCodeDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeDetailsDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeDetailsDto
     */
    description?: string;
}

/**
 * Check if a given object implements the ErrorCodeDetailsDto interface.
 */
export function instanceOfErrorCodeDetailsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ErrorCodeDetailsDtoFromJSON(json: any): ErrorCodeDetailsDto {
    return ErrorCodeDetailsDtoFromJSONTyped(json, false);
}

export function ErrorCodeDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCodeDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ErrorCodeDetailsDtoToJSON(value?: ErrorCodeDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'description': value.description,
    };
}

