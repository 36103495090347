/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The new state of a connection
 * @export
 * @interface ConnectionStateUpdateDto
 */
export interface ConnectionStateUpdateDto {
    /**
     * Connection state
     * @type {string}
     * @memberof ConnectionStateUpdateDto
     */
    connectionState?: ConnectionStateUpdateDtoConnectionStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum ConnectionStateUpdateDtoConnectionStateEnum {
    DISABLED = 'DISABLED',
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS'
}


/**
 * Check if a given object implements the ConnectionStateUpdateDto interface.
 */
export function instanceOfConnectionStateUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectionStateUpdateDtoFromJSON(json: any): ConnectionStateUpdateDto {
    return ConnectionStateUpdateDtoFromJSONTyped(json, false);
}

export function ConnectionStateUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionStateUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectionState': !exists(json, 'connectionState') ? undefined : json['connectionState'],
    };
}

export function ConnectionStateUpdateDtoToJSON(value?: ConnectionStateUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectionState': value.connectionState,
    };
}

