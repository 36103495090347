import { Table } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { DataIndex } from 'rc-table/lib/interface';
import _ from 'lodash';

export interface ExtendedColumn<T> extends ColumnType<T>{
    children?: ExtendedColumn<T>[],
    visible?: boolean,
    alwaysVisible?: boolean,
    titleString?: string,
}


/**
 * The shenanigans with Table.EXPAND_COLUMN and Table.SELECTION_COLUMN are needed because
 * antd exports these two columns as a constant object and internally checks for object equality,
 * so if the reference changes (e.g. we `.map` over it and return a new object), then the table
 * will not recognize it as this special column and it will not work.
 */


export function useTableColumnSelectorExtendedColumns<T>({ columns }: { columns: (ColumnGroupType<T> | ColumnType<T> | ExtendedColumn<T>)[] }) {
  const [extendedColumns, setExtendedColumns] = useState<ExtendedColumn<T>[]>([]);

  useEffect(() => {
    setExtendedColumns((oldColumns: (ColumnGroupType<T> | ColumnType<T> | ExtendedColumn<T>)[]) => {
      if (_.isEmpty(oldColumns)) {
        return columns.map((c: any) => {
          if (Table.EXPAND_COLUMN === c || Table.SELECTION_COLUMN === c) return c;

          return {
            ...c,
            visible: c.visible === undefined ? true : c.visible,
          };
        });
      }
      return oldColumns.map((oc: any) => {
        if (Table.EXPAND_COLUMN === oc || Table.SELECTION_COLUMN === oc) return oc;

        // set oldColumn if the current column value is undefined, otherwise dataIndex is lost
        const c = columns.find((nc: any) => nc.dataIndex && nc.dataIndex === oc.dataIndex) ?? oc;
        return ({
          ...c,
          visible: oc.visible === undefined ? true : oc.visible,
          alwaysVisible: oc.alwaysVisible,
        });
      });
    });
  }, [columns]);

  const visibleColumns = useMemo(() => (
    extendedColumns.map((column) => {
      if (Table.EXPAND_COLUMN === column || Table.SELECTION_COLUMN === column) return column;
      return {
        ...column,
        children: column.children?.filter(c => c.visible || c.visible === undefined),
      };
    }).filter(column => (!column.children && (column.visible || column.visible === undefined)) || (column.children && column.children.length! > 0))
  ), [extendedColumns]);

  const changeVisibilityForDataIndex = (dataIndex: DataIndex, cols: ExtendedColumn<T>[]): ExtendedColumn<T>[] => cols.map((column) => {
    if (Table.EXPAND_COLUMN === column || Table.SELECTION_COLUMN === column) return column;

    if (column.children) {
      return {
        ...column,
        children: changeVisibilityForDataIndex(dataIndex, column.children),
      };
    }

    if (column.dataIndex?.toString() === dataIndex.toString()) return ({ ...column, visible: !column.visible });

    return column;
  });

  const changeColumnVisibility = (dataIndex: DataIndex) => {
    setExtendedColumns(oldColumns => changeVisibilityForDataIndex(dataIndex, oldColumns));
  };

  return {
    columns: extendedColumns,
    visibleColumns,
    changeColumnVisibility,
  };
}
