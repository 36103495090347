import {
  Form, Row, Col,
} from 'antd';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import DateInput from 'elements/Inputs/DateInput/DateInput';
import { SPACE_SIZES } from 'lib/constants';
import SectionSummary from 'storybook-components/sections/SectionSummary/SectionSummary';
import Button from 'elements/Buttons/Button/Button';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from '../../../translations';
import ProfitAndLossReportPropertySelector from './components/ProfitAndLossReportPropertySelector/ProfitAndLossPropertySelector';
import { useProfitAndLossReportForm } from './services/useProfitAndLossReportForm';
import './ProfitAndLossReportEditorForm.scss';
import { useInitializeProfitAndLossReportEditor } from './services/useInitializeProfitAndLossReportEditor';

const ProfitAndLossReportEditorForm = () => {
  const { tl } = useContext(LanguageContext);
  const {
    onChange, reportData, onClickEditorFormSection, openSectionId, sectionSummary, onCalculate, validationErrors, loading,
  } = useProfitAndLossReportForm();

  useInitializeProfitAndLossReportEditor();

  const footer = (
    <Button type="primary" onClick={onCalculate} loading={loading}>
      {tl(translations.report.form.labels.calculate)}
    </Button>
  );

  return (
    <div className="ProfitAndLossReportEditorForm">
      <PrimarySectionWrapper
        sectionId="ProfitAndLossReportEditorForm"
        sectionNumber={0}
        sectionTitle={tl(translations.report.form.title)}
        isOpen={openSectionId === 0}
        onClick={onClickEditorFormSection}
        sectionClosedSuffix={<SectionSummary content={sectionSummary} />}
        footer={footer}
      >
        <Form>
          <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
            <Col span={24}>
              <ProfitAndLossReportPropertySelector validationState={validationErrors.property?.state} validationMessage={validationErrors.property?.message} />
            </Col>
            <Col span={6}>
              <DateInput
                className="custom-required"
                validationState={validationErrors.startDate?.state}
                validationMessage={validationErrors.startDate?.message}
                label={tl(translations.report.form.labels.starteDate)}
                disabled={false}
                value={reportData?.startDate}
                onChange={val => onChange('startDate', val)}
              />
            </Col>
            <Col span={6}>
              <DateInput
                className="custom-required"
                validationState={validationErrors.endDate?.state}
                validationMessage={validationErrors.endDate?.message}
                label={tl(translations.report.form.labels.endDate)}
                disabled={false}
                value={reportData?.endDate}
                onChange={val => onChange('endDate', val)}
              />
            </Col>
            <Col span={12}>
              <TextInput
                className="custom-required"
                validationState={validationErrors.documentName?.state}
                validationMessage={validationErrors.documentName?.message}
                label={tl(translations.report.form.labels.docTitle)}
                onChange={val => onChange('documentName', val)}
                value={reportData?.documentName}
              />
            </Col>
          </Row>
        </Form>
      </PrimarySectionWrapper>
    </div>
  );
};

export default ProfitAndLossReportEditorForm;
