import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';

import './SearchBar.scss';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'components/Header/translations';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { DATE_FORMAT } from 'lib/Utils';
import { GlobalSearchContext, SearchSuggestion, SuggestedFilter } from './services/GlobalSearchContext';
import { PAGE_CONTENT_ID } from '../../../Layout/data';
import { SearchBarDropdown } from './components/SearchBarDropdown/SearchBarDropdown';
import { Overlay, OverlayContext } from '../../../../services/OverlayContext/OverlayContext';
import { BreadcrumbInterface, Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs';
import { SelectOption } from '../../../../elements/Inputs/SelectInput/SelectInput';
import DateInput from '../../../../elements/Inputs/DateInput/DateInput';
import { FILTER_VALUE_DELIMITER, useSearchBar } from '../../../../services/SearchBarHooks/useSearchBar';

export const SearchBar: React.FC = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const location = useLocation();
  const {
    onSearchValueChanged,
    getPage,
    breadcrumbs,
    addBreadcrumb,
    replaceBreadcrumb,
    setSelectedPropertyByIdAndNavigate,
    currentlyEditedFilter,
    setCurrentlyEditedFilter,
    dropdownVisible,
    setDropdownVisible,
    setFilterDropdownVisible,
    setSelectedSuggestionIndex,
    hideGlobalSearch,
  } = useContext(GlobalSearchContext);

  const [searchString, setSearchString] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const { overlays } = useContext(OverlayContext);

  const urlKeysPresent = useMemo(() => overlays.filter((overlay: Overlay) => overlay.path !== '/dashboard').length > 0, [overlays]);


  useEffect(() => {
    onSearchValueChanged(searchString || '');
  }, [searchString]);

  const onClickSearchWrapper = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };


  const showDropdown = (force: boolean = false) => {
    setSelectedSuggestionIndex(0);
    setFilterDropdownVisible(false);
    if (!currentlyEditedFilter || force) {
      setDropdownVisible(true);
      const pageContentElement = document.getElementById(PAGE_CONTENT_ID);
      if (pageContentElement) {
        pageContentElement.style.filter = 'blur(2.0rem)';
      }
      onSearchValueChanged(searchString || '');
    }
  };

  const hideDropdown = () => {
    setDropdownVisible(false);
    const pageContentElement = document.getElementById(PAGE_CONTENT_ID);
    if (pageContentElement) {
      pageContentElement.style.filter = 'none';
    }
  };

  /**
   * when the user pressed selects filter without value
   */
  const onFilterSelected = (filter: SuggestedFilter) => {
    setCurrentlyEditedFilter(filter);
    setSearchString(filter.matchingValue as string);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    hideDropdown();
  };


  const getFilterTranslatedValue = (filter: SuggestedFilter, value: any) => {
    let translatedValue;
    if (filter.type === 'enum') {
      translatedValue = filter.options!.filter((option: SelectOption) => option.value === filter.matchingValue)[0].label;
    } else if (filter.type === 'date') {
      const momentValue = moment.utc(value);
      translatedValue = momentValue.format(DATE_FORMAT);
    } else if (filter.type === 'boolean') {
      if (filter.matchingValue === 'true') {
        translatedValue = filter.trueLabel || tl(translations.searchBar.filter.booleanValues[filter.matchingValue]);
      } else {
        translatedValue = filter.falseLabel || tl(translations.searchBar.filter.booleanValues[filter.matchingValue as string]);
      }
    } else {
      translatedValue = filter.matchingValue;
    }

    return translatedValue;
  };

  const getValueBasedOnFilterType = (filter: SuggestedFilter) => {
    if (!filter.multiValue) {
      if (filter.type === 'decimal') {
        return parseFloat((filter.matchingValue as string).replaceAll(',', '.').replaceAll(/\s/g, ''));
      }

      if (filter.type === 'date') {
        const valueAsMoment = moment.utc(filter.matchingValue as string, DATE_FORMAT);

        if (filter.maxDate && filter.maxDate.isBefore(valueAsMoment)) {
          return filter.maxDate.toISOString();
        }

        if (filter.minDate && filter.minDate.isAfter(valueAsMoment)) {
          return filter.minDate.toISOString();
        }

        return valueAsMoment.toISOString();
      }
      return filter.matchingValue;
    }

    const prevValue = breadcrumbs
      .filter((bc: BreadcrumbInterface) => bc.breadcrumbType === 'FILTER' && bc.key === filter.key)
      .flatMap((bc: BreadcrumbInterface) => bc.matchingValue);

    const newValue = Array.from(new Set([...prevValue, filter.matchingValue]));

    // Same value inserted twice
    if (newValue.length === prevValue.length) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }

    return newValue;
  };

  /**
   * when the user selects filter with value
   * Date type values are assumed to be in format DD.MM.YYYY (utils.ts -> DATE_FORMAT). It's the responsibility of the caller to make sure the format is correct
   */
  const onApplyFilter = (filter: SuggestedFilter, finishFiltering: boolean = true) => {
    hideDropdown();
    const { setFilter } = getPage(filter.pageKey).filterProps;
    const value = getValueBasedOnFilterType(filter);
    const translatedValue = getFilterTranslatedValue(filter, value);


    setFilter(filter.key, value);

    // add breadcrumb
    const breadcrumb = {
      ...filter,
      breadcrumbType: 'FILTER' as const,
      matchingValue: filter.matchingValue as BreadcrumbInterface['matchingValue'],
      translatedValue,
    };

    const index = breadcrumbs.findIndex((bc: BreadcrumbInterface) => bc.key === filter.key);
    if (index !== -1 && !filter.multiValue) {
      replaceBreadcrumb(index, breadcrumb);
    } else {
      addBreadcrumb(breadcrumb);
    }

    // reset searchbar
    setSearchString('');
    if (finishFiltering) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const { filterValueValid, onKeyDown } = useSearchBar({
    searchString, dropdownVisible, currentlyEditedFilter, setCurrentlyEditedFilter, showDropdown, onApplyFilter,
  });

  const onSelectSuggestion = (value: SearchSuggestion, withValue?: boolean) => {
    switch (value.type) {
    case 'filter':
      if (withValue) onApplyFilter(value.filterProps!);
      else onFilterSelected(value.filterProps!);
      break;
    case 'property':
      selectProperty(value.propertyId!);
      break;
    case 'navigationItem':
      onSelectNavigationItem(value.navigationPath!, value.absolutePath);
      break;
    default:
      break;
    }
  };

  const selectProperty = (propertyId: number) => {
    setSelectedPropertyByIdAndNavigate(propertyId);
    hideDropdown();
    setSearchString('');
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const onSelectNavigationItem = (path: string, absolutePath?: boolean) => {
    if (absolutePath) {
      history.push(path);
    } else {
      history.push(location.pathname + path);
    }
    hideDropdown();
    setSearchString('');
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <div className="search-bar">
      <Breadcrumbs editingEnabled={!hideGlobalSearch} />
      <div
        className={`search-bar-input-wrapper ${dropdownVisible ? 'focused' : ''}  
        ${_.isEmpty(searchString) && !urlKeysPresent ? 'empty' : ''} ${hideGlobalSearch ? 'hidden' : ''}`}
        onClick={onClickSearchWrapper}
      >
        <Icon
          component={ICONS.magnifyingGlass}
          className="magnifying-icon"
        />
        {currentlyEditedFilter && <span className="filter-label">{currentlyEditedFilter.name + FILTER_VALUE_DELIMITER}</span>}
        {currentlyEditedFilter && currentlyEditedFilter.type === 'date' ? (
          <DateInput
            className="search-bar-input"
            label=""
            onChange={(value) => {
              setSearchString(value ? value.format(DATE_FORMAT) : '');
            }}
            onKeyDown={onKeyDown}
            value={searchString ? moment.utc(searchString, DATE_FORMAT) : undefined}
            hardMaxDate={currentlyEditedFilter.maxDate}
            hardMinDate={currentlyEditedFilter.minDate}
            onAccept={(value) => {
              onApplyFilter({ ...currentlyEditedFilter, matchingValue: value ? value.format(DATE_FORMAT) : '' });
              setCurrentlyEditedFilter(null);
            }}
          />
        ) : (
          <input
            className={`search-bar-input ${!filterValueValid ? 'invalid' : ''}`}
            onChange={e => setSearchString(e.target.value)}
            onKeyDown={onKeyDown}
            onFocus={() => showDropdown()}
            value={searchString || ''}
            placeholder={tl(translations.searchBar.placeholder)}
            ref={inputRef}
          />
        )}
      </div>
      <SearchBarDropdown
        visible={dropdownVisible}
        onSelected={onSelectSuggestion}
        hideDropdown={hideDropdown}
      />
    </div>
  );
};
