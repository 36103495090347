import { useContext } from 'react';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { EconomicPlanControllerApi } from '../../../../api/accounting/apis';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { ECONOMIC_PLAN_ACCOUNT_TYPES } from '../../services/ECONOMIC_PLAN_ACCOUNT_TYPES';
import { EconomicPlanResultsDto } from '../../../../api/accounting';
import { showNotification } from '../../../../lib/Notification';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export const useSaveAccountAmounts = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanContext = useContext(EconomicPlanContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  if (economicPlanContext === undefined) {
    throw new Error('useSaveEconomicPlan must be used within a EconomicPlanContext and PropertyListContext');
  }

  const {
    economicPlan,
    setEconomicPlan,
    economicPlanCreationData,
  } = economicPlanContext;

  const {
    clearDirty,
  } = useSimpleDirtModal();


  const extractValuesFromLeafAccounts = (accountBalances: any[], accountTypes: ECONOMIC_PLAN_ACCOUNT_TYPES[]) => {
    let node: any[] = [];
    accountBalances.forEach((accountBalance) => {
      if (accountBalance.children) {
        node = node.concat(extractValuesFromLeafAccounts(accountBalance.children, accountTypes));
      } else {
        node.push({
          ...accountBalance,
          totalSum:
            accountTypes
              .map(accountType => economicPlan.data[accountType][accountBalance.accountCode] || 0)
              .reduce((sum: number, value: number) => sum + value, 0),
        });
      }
    });

    return node;
  };

  const convertAmountsToBackendModel = () => {
    const accountAmounts: EconomicPlanResultsDto = {
      applicableExpenses: extractValuesFromLeafAccounts(economicPlanCreationData.data?.applicableExpenses || [], [ECONOMIC_PLAN_ACCOUNT_TYPES.applicableExpenses, ECONOMIC_PLAN_ACCOUNT_TYPES.notApplicableExpenses]),
      notApplicableExpenses: extractValuesFromLeafAccounts(economicPlanCreationData.data?.notApplicableExpenses || [], [ECONOMIC_PLAN_ACCOUNT_TYPES.applicableExpenses, ECONOMIC_PLAN_ACCOUNT_TYPES.notApplicableExpenses]),
      income: extractValuesFromLeafAccounts(economicPlanCreationData.data?.income || [], [ECONOMIC_PLAN_ACCOUNT_TYPES.income]),

      reserveFunds: economicPlanCreationData.data?.reserveFundAccounts.map((account: any) => ({
        ...account,
        totalSum: account.value,
      })) || [],
    };

    return accountAmounts;
  };

  const saveAccountAmounts = () => {
    if (economicPlanCreationData.loading || economicPlan.loading) {
      throw Error('You should not be able to save while data is loading');
    }

    economicPlan.startLoading();
    return (
      economicPlanControllerApi.updateAmountsUsingPUT({
        id: economicPlan.data?.id,
        dto: convertAmountsToBackendModel(),
      })
        .then((updatedEconomicPlan) => {
          setEconomicPlan(prev => prev.load({
            ...prev.data, // contains dynamically added fields on initial load
            ...updatedEconomicPlan,
          }, {}, true));
          clearDirty();
        })
        .catch(() => {
          setEconomicPlan(economicPlan.failed());
          showNotification({
            key: 'saveError',
            message: tl(economicPlanTranslations.notifications.saveError.message),
            type: 'error',
          });
        })
    );
  };

  return {
    saveAccountAmounts,
  };
};
