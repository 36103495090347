import React, { useContext, useMemo } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import useSmartTable from '../../../../elements/SmartTable/useSmartTable';
import SmartTable from '../../../../elements/SmartTable/SmartTable';
import { UseUnitReserveFundsColumns } from './UseUnitReserveFundsColumns';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../../elements/Translation/translations';
import { formatCurrency } from '../../../../lib/Utils';
import SummaryRow from '../../../../elements/CustomElements/ExpandableCard/SummaryRow';

interface UnitReserveFundsProps {
  dataSource: any[],
  loading: boolean,
}

interface UnitReserveFundsTotalProps {
  dataSource: any[],
}

export function useRenderUnitReserveFundsTotal(props: UnitReserveFundsTotalProps) {
  const { tl } = useContext(LanguageContext);
  const { dataSource } = props;

  const total = useMemo(() => dataSource.reduce((aggregator: any, reserveFund: any) => aggregator + reserveFund.newTotalAmount, 0), [dataSource]);
  const share = useMemo(() => dataSource.reduce((aggregator: any, reserveFund: any) => aggregator + reserveFund.newUnitAmount, 0), [dataSource]);
  const summaryElements = [{
    title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.summary.total),
    value: <Amount>{formatCurrency(total)}</Amount>,
  },
  {
    title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.summary.share),
    value: <Amount>{formatCurrency(share)}</Amount>,
  }];
  return (
    <SummaryRow
      rightSideElements={summaryElements}
      title={tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.total)}
    />
  );
}

export default function UnitReserveFundsTable(props: UnitReserveFundsProps): JSX.Element {
  const { dataSource, loading } = props;

  const table = useSmartTable({
    tableName: 'reserveFundsTable',
    columns: UseUnitReserveFundsColumns(),
    dataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    rowKey: 'accountName',
  });


  return (
    <div className="UnitReserveFundsTable ">
      <SmartTable {...table} />
    </div>
  );
}
