import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';

import {
  GetSerialLettersPagedUsingGETOrderEnum,
  SerialLetterControllerApi,
  SerialLetterDto,
} from 'api/accounting';
import DEFAULT_DATA from 'lib/data';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { getSectionErrors } from 'contexts/util/SerialLetterSectionFieldsConfiguration';
import { getRouteBasedOnEntityType } from 'navigation/service/useGetRouteBasedOnEntityType';
import _ from 'lodash';
import { translations } from '../translations';
import { SerialLetter } from '../interfaces';
import { AuthContext } from '../../../contexts/AuthContext';
import { getErrorCodeFromURI } from '../../../lib/ErrorCodesUtils';

const PAGE_SIZE = 30;

export function useSerialLetter() {
  const { tl } = useContext(LanguageContext);
  const [serialLetterListState, setSerialLetterListState] = useState(DEFAULT_DATA<SerialLetter[]>([]));
  const [serialLetterListSortState, setSerialLetterListSortState] = useState({
    field: 'created',
    order: -1,
  });
  const [serialLetter, setSerialLetter] = useState(DEFAULT_DATA<SerialLetterDto>({}));
  const [selectedSerialLetterId, setSelectedSerialLetterId] = useState<number | null>(null);
  const [isDirty, setDirty] = useState(false);
  const [isSaveResponseValid, setSaveResponseValid] = useState<boolean>(true);
  const history = useHistory();

  const [filterState, setFilterState] = useState<any>({});
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);

  const { apiConfiguration } = useContext(AuthContext);
  const serialLetterControllerApi = new SerialLetterControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadSerialLetters(true);
    } else {
      setInitialFilterUpdate(false);
    }
    clearData();
  }, [filterState, serialLetterListSortState]);

  useEffect(() => {
    if (selectedSerialLetterId) {
      onLoadSerialLetter();
    }

    return () => {
      clearData();
    };
  }, [selectedSerialLetterId]);

  const updateFilterState = (data: object) => {
    setFilterState((currentState: any) => ({
      ...currentState,
      ...data,
    }));
  };

  const onChangeSortField = (field: string) => {
    setSerialLetterListSortState((old: any) => ({
      field,
      order: old.field === field ? old.order * (-1) : 1,
    }));
  };

  const onLoadSerialLetters = (resetPage: boolean = false) => {
    setSerialLetterListState(serialLetterListState.startLoading());

    abortController.current?.abort();
    abortController.current = new AbortController();
    const { signal } = abortController.current ?? {};

    serialLetterControllerApi.getSerialLettersPagedUsingGET({
      ...filterState,
      shippingDateFrom: filterState.shippingDateFrom
        ? moment(filterState.shippingDateFrom).format('YYYY-MM-DD')
        : undefined,
      shippingDateTo: filterState.shippingDateTo
        ? moment(filterState.shippingDateTo).format('YYYY-MM-DD')
        : undefined,
      page: resetPage ? 0 : serialLetterListState.page,
      size: PAGE_SIZE,
      sort: serialLetterListSortState.field === 'status'
        ? 'shipping_status'
        : _.snakeCase(serialLetterListSortState.field),
      order: serialLetterListSortState.order === 1 ? GetSerialLettersPagedUsingGETOrderEnum.ASC : GetSerialLettersPagedUsingGETOrderEnum.DESC,
    }, { signal })
      .then((response: any) => {
        const { content, last } = response;
        setSerialLetterListState(serialLetterListState.loadPaged(content, resetPage, last));
      })
      .catch(() => {
        if (signal?.aborted) {
          console.warn('Fetch intentionally aborted because the data would have been stale.');
        } else {
          setSerialLetterListState(serialLetterListState.failed());
          showNotification({
            key: 'loadSerialLetterError',
            message: tl(translations.notifications.loadSerialLetterError),
            type: 'error',
          });
        }
      });
  };

  const onLoadSerialLetter = () => {
    if (!selectedSerialLetterId) return;
    setSerialLetter(serialLetter.startLoading());
    serialLetterControllerApi.getSerialLetterByIdUsingGET({ serialLetterId: selectedSerialLetterId })
      .then((response: any) => {
        setSerialLetter(letter => letter.load(convertToFeModel(response)));
      })
      .catch(() => {
        setSerialLetter(serialLetter.failed());
        showNotification({
          key: 'loadSerialLetterError',
          message: tl(translations.notifications.loadError),
          type: 'error',
        });
      });
  };

  const onDeleteSerialLetter = (id: number) => {
    setSerialLetterListState(state => state.startLoading());
    serialLetterControllerApi.deleteSerialLetterUsingDELETE({ serialLetterId: id })
      .then(() => {
        setSerialLetterListState((state) => {
          if (!state.data) return state;
          const tempSerialLetterList = state.data.filter((omi: SerialLetter) => omi.id !== id);
          return state.load(tempSerialLetterList);
        });
        showNotification({
          key: 'deleteSerialLetterSuccess',
          message: tl(translations.notifications.deleteSuccess),
          type: 'success',
        });
      })
      .catch(() => {
        setSerialLetterListState(state => state.failed());
        showNotification({
          key: 'deleteSerialLetterError',
          message: tl(translations.notifications.deleteError),
          type: 'error',
        });
      });
  };

  const handleError = (err: any, sectionIndex: number) => {
    if (err.status === 422) {
      setDirty(false);
      setSaveResponseValid(false);
    } else {
      setDirty(true);
      setSerialLetter(serialLetter.failed());
      showNotification({
        key: 'saveError',
        message: tl(translations.notifications.saveError),
        type: 'error',
      });
      return;
    }

    const errorCodeURI = err.type;
    if (errorCodeURI) {
      const errorCode = getErrorCodeFromURI(errorCodeURI);
      const link = getRouteBasedOnEntityType(err.errorEntityType, err.errorEntityId);
      showNotification({
        key: 'saveSerialLetterWithValidationErrors',
        message: tl(translations.notifications.validationErrors.message),
        description: (
          <>
            <a href={link}>{err.errorEntityName}</a>
            {tl(translations.notifications.error[errorCode])}
          </>
        ),
        duration: 30,
        type: 'error',
      });
    } else {
      // @ts-ignore
      const currentSectionErrors: any = getSectionErrors(sectionIndex, err, serialLetter.data?.numberOfProperties);

      if (!_.isEmpty(currentSectionErrors)) {
        setSerialLetter(prev => prev.load({
          ...prev.data,
        }, currentSectionErrors));
        showNotification({
          key: 'saveSerialLetterWithValidationErrors',
          message: tl(translations.notifications.validationErrors.message),
          type: 'warning',
        });
      }
    }
  };

  const onSaveSerialLetter = (sectionIndex: number, id?: number) => {
    setSerialLetter(prev => prev.startLoading());
    const data = _.cloneDeep(serialLetter.data);
    setDirty(false);

    let p;
    if (!id) {
      p = serialLetterControllerApi.createSerialLetterUsingPOST({ serialLetterDto: data });
    } else {
      p = serialLetterControllerApi.editSerialLetterUsingPUT({ serialLetterDto: data });
    }
    p.then((response: any) => {
      setSerialLetter((letter: any) => letter.load(convertToFeModel(response), {}, true));
      setSaveResponseValid(true);
      if (!id) {
        history.replace(`/letters/edit/${response.id}`, { openSectionIndex: sectionIndex });
      }
      showNotification({
        key: 'saveSerialLetterSuccess',
        message: tl(translations.notifications.saveSuccess),
        type: 'success',
      });
    })
      .catch((error) => {
        error.response.json()
          .then((errorBody) => {
            setSerialLetter(prev => prev.failed(errorBody));
            handleError(errorBody, sectionIndex);


            if (!id) {
              setSerialLetterListState((prevState) => {
                const newState = prevState.data!.slice();
                newState.unshift(errorBody.savedEntity);
                return prevState.load(newState);
              });
            }
          });
      });
    return p;
  };

  const clearData = () => {
    setSerialLetterListState(DEFAULT_DATA<SerialLetter[]>([]));
  };
  const clearFilterState = () => {
    setFilterState({});
  };

  const onClearSerialLetter = () => {
    setSerialLetter(DEFAULT_DATA<SerialLetter>({}));
    setSelectedSerialLetterId(null);
  };

  const convertToFeModel = (letter: SerialLetter) => ({
    ...letter,
    numberOfProperties: letter.properties?.length || 0,
    nrOfAttachments: letter.attachments?.length || 0,
  });

  return {
    serialLetterListState,
    setSerialLetterListState,
    serialLetterListSortState,
    onChangeSortField,
    filterState,
    setFilterState,
    updateFilterState,
    serialLetter,
    setSerialLetter,
    selectedSerialLetterId,
    setSelectedSerialLetterId,
    isDirty,
    setDirty,
    isSaveResponseValid,
    onLoadSerialLetters,
    onLoadSerialLetter,
    clearData,
    onDeleteSerialLetter,
    onSaveSerialLetter,
    onClearSerialLetter,
    clearFilterState,
    setSaveResponseValid,
  };
}
