import { Modal } from 'antd';
import React, { useContext } from 'react';
import { translations } from '../../Translation/translations';
import Button from '../../Buttons/Button/Button';
import { LanguageContext } from '../../../contexts/LanguageContext';

interface DirtModalProps {
  visible: boolean
  onCancel: () => void
  onSave?: (callback?: () => void) => void
  onDiscard?: () => void
  loading: boolean
}

export default function DirtModal({
  visible, onCancel, onSave, onDiscard, loading,
}: DirtModalProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  return (
    <Modal
      visible={visible}
      title={tl(translations.elements.dirtModal.title)}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" type="text" onClick={onCancel}>
          {tl(translations.elements.dirtModal.cancel)}
        </Button>,
        <Button key="discard" type="ghost" onClick={onDiscard}>
          {tl(translations.elements.dirtModal.discard)}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={() => onSave()}
          loading={loading}
        >
          {tl(translations.elements.dirtModal.save)}
        </Button>,
      ]}
    >
      <p>{tl(translations.elements.dirtModal.description)}</p>
    </Modal>
  );
}

DirtModal.defaultProps = {
  onSave: undefined,
  onDiscard: undefined,
};
