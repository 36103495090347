
import { PERSON_EDITING_ROUTE, COMPANY_EDITING_ROUTE } from '../../pages/Contacts/routes';

export const getRouteBasedOnEntityType = (entityType: string, idFromArguments: any) => {
  switch (entityType) {
  case 'PERSON':
    return PERSON_EDITING_ROUTE.replace(':personId', idFromArguments);
  case 'COMPANY':
    return COMPANY_EDITING_ROUTE.replace(':companyId', idFromArguments);
  default:
    console.warn(`The mapping was not possible for ${entityType}`);
    return '';
  }
};
