import React, { useContext } from 'react';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { PNLValidationError } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { usePNLPropertySelector } from './usePNLPropertySelector';
import { translations } from '../../../../../translations';


interface Props {
  validationState: PNLValidationError['state'],
  validationMessage: PNLValidationError['message']
}


const ProfitAndLossReportPropertySelector = ({
  validationState, validationMessage,
}: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    value, options, onChange, onFilterOption,
  } = usePNLPropertySelector();

  return (
    <SelectInput
      showSearch
      className="Select custom-required"
      label={tl(translations.report.form.labels.select)}
      onChange={onChange}
      value={value}
      filterOption={onFilterOption}
      disabled={false}
      options={options}
      validationState={validationState}
      validationMessage={validationMessage}
    />
  );
};

export default React.memo(ProfitAndLossReportPropertySelector);
