import React from 'react';
import { Route, RouteProps } from 'react-router';
import * as Sentry from '@sentry/react';
import { useOverlayContext } from 'services/OverlayContext/useOverlayContext';
import { overlayTypes } from '../services/OverlayContext/OverlayContext';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

export const MainRoute: React.FC<{ componentProps?: any } & RouteProps> = (routeProps) => {
  const { path, exact } = routeProps;
  useOverlayContext({ path: path as string, type: overlayTypes.MAIN, exact });

  return (
    <SentryRoute
      {...routeProps}
    />
  );
};
