import { useContext, useMemo, useState } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentDto } from 'api/public';
import { showNotification } from 'lib/Notification';
import { GetMessagesUsingGETSourceTypeEnum, HouseMoneySettlementAggregationControllerApi, HouseMoneyUnitSettlementDtoStatusEnum } from 'api/accounting';
import { useCheckMessages } from 'services/useCheckMessages';
import { OverlayContext } from '../../../../../../../services/OverlayContext/OverlayContext';
import { translations } from '../../../../../../../elements/Translation/translations';
import { PropertyListContext } from '../../../../../../../contexts/PropertyListContext';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import { HouseMoneySettlementContext } from '../../../../../../../contexts/HouseMoneySettlementContext';
import { useStateChangeButtons } from '../StateChangeButtons/useStateChangeButtons';

export function useRightSideButtons() {
  const { goBack } = useContext(OverlayContext);
  const { tl } = useContext(LanguageContext);
  const { selectedProperty } = useContext(PropertyListContext);
  const {
    inCreation,
    documentsInCreation,
    creationFailed,
    selectedUnitId,
    documents,
    houseMoneySettlementState,
    setHouseMoneySettlementState,
    onLoadHouseMoneySettlementList,
    setStatusFlags,
    selectedEconomicYear,

  } = useContext(HouseMoneySettlementContext);

  const { messagesExist, messagesGenerating } = useCheckMessages({ sourceId: houseMoneySettlementState.data?.id, sourceType: GetMessagesUsingGETSourceTypeEnum.HOUSE_MONEY_SETTLEMENT });

  const [mergedDocumentModalVisible, setMergedDocumentModalVisible] = useState(false);

  const { apiConfiguration } = useContext(AuthContext);

  const houseMoneySettlementController = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));

  const { onRevertHouseMoneySettlementToDraft, onSend, onCheckMessages } = useStateChangeButtons();

  const onRefreshHouseMoneySettlement = () => {
    const onProceed = () => {
      setHouseMoneySettlementState(currentState => currentState.startLoading());

      houseMoneySettlementController.refreshHouseMoneySettlementV2UsingGET({ economicYear: selectedEconomicYear, propertyId: selectedProperty.data?.id })
        .then((houseMoneySettlement: any) => {
          setHouseMoneySettlementState(currentState => currentState.load(houseMoneySettlement));
          setStatusFlags(houseMoneySettlement, []);
        })
        .catch(() => {
          setHouseMoneySettlementState(currentState => currentState.failed());
          showNotification({
            key: 'loadHouseMoneySettlementError',
            message: tl(translations.notifications.houseMoneySettlementContext.loadHouseMoneySettlementError.message),
            type: 'error',
          });
        });
    };

    onProceed();
  };

  const buttonTooltip = useMemo(() => {
    if (inCreation) {
      return tl(translations.pages.houseMoneySettlement.houseMoneySettlementCreationInProgress);
    }
    if (documentsInCreation) {
      return tl(translations.pages.houseMoneySettlement.documentCreationInProgress);
    }
    if (messagesGenerating) {
      return tl(translations.pages.houseMoneySettlement.messagesGeneratingTooltip);
    }
    return undefined;
  }, [inCreation, documentsInCreation, messagesGenerating]);

  const goBackAndRefresh = () => {
    onLoadHouseMoneySettlementList(true);
    goBack();
  };

  const getDropMenuOptions = () => {
    let options = [];
    const finalizedAndPreparedCommonOptions = [
      {
        label: tl(translations.pages.houseMoneySettlement.overview.draft),
        onClick: () => onRevertHouseMoneySettlementToDraft(houseMoneySettlementState.data?.id),
      },
      {
        label: tl(translations.pages.houseMoneySettlement.overview.download),
        onClick: () => setMergedDocumentModalVisible(true),
        disabled: documentsInCreation,
      },
    ];
    switch (houseMoneySettlementState.data?.status) {
      case HouseMoneyUnitSettlementDtoStatusEnum.FINALIZED:
      case HouseMoneyUnitSettlementDtoStatusEnum.PREPARED:
        options = finalizedAndPreparedCommonOptions;
        if (messagesExist) {
          options.splice(1, 0, {
            label: tl(translations.pages.houseMoneySettlement.overview.send),
            onClick: onSend,
          });
        }
        break;
      default:
        options = [
          {
            label: tl(translations.pages.houseMoneySettlement.overview.download),
            onClick: () => setMergedDocumentModalVisible(true),
            disabled: documentsInCreation,
          }];
        if (messagesExist) {
          options.splice(0, 0, {
            label: tl(translations.pages.houseMoneySettlement.overview.send),
            onClick: onSend,
          });
        }
        break;
    }

    return options;
  };

  const selectedDocumentId = selectedUnitId ? documents?.find((d: DocumentDto) => d.unitId === selectedUnitId)?.id : documents?.find((d: DocumentDto) => !d.unitId)?.id;

  const startLoading = () => setHouseMoneySettlementState(hga => hga.startLoading());
  const stopLoading = () => setHouseMoneySettlementState(hga => hga.finishLoading());


  return {
    goBack: goBackAndRefresh,
    buttonTooltip,
    houseMoneySettlementState,
    selectedProperty,
    inCreation,
    documentsInCreation,
    creationFailed,
    onRefreshHouseMoneySettlement,
    mergedDocumentModalVisible,
    setMergedDocumentModalVisible,
    dropMenuOptions: getDropMenuOptions(),
    messagesExist,
    onSend,
    onCheckMessages,
    selectedDocumentId,
    startLoading,
    stopLoading,
    documents,
    messagesGenerating,
  };
}
