export const documentPreviewTranslations = {
  back: {
    en: 'Back',
    de: 'Zurück',
  },
  loadError: {
    en: 'Failed to load document.',
    de: 'Fehler beim Laden des Dokuments.',
  },
  generationError: {
    en: 'Document generation failed.',
    de: 'Dokumenten konnten nicht erstellt werden.',
  },
  generationText: {
    title: {
      en: 'Generating document…',
      de: 'Dokument wird erstellt…',
    },
    subtitle: {
      en: 'The document may take a few minutes to generate. In the meantime, you can leave and come back later.',
      de: 'Da die Erstellung des Dokuments einige Minuten dauern kann, können Sie diese Seite verlassen und später zurückkehren.',
    },
  },
};
