import React, {
  useEffect, useRef, useState,
} from 'react';
import { PagesOrientationType } from 'pages/PdfPageAssigner/services/interfaces';
import { PdfPageWrapperProps } from '../services/interfaces';
import PdfPagePreview from './PdfPage';

// this determines "how much in advance" the page should be rendered
// before the component actually enters the viewport
const VISIBLE_OFFSET_LIST = 2400;
const VISIBLE_OFFSET_GRID = 1000;


const VirtualizePdfPageWrapper = (props: PdfPageWrapperProps) => {
  const { pageNumber, rootNodeId, viewMode } = props;

  const [viewport, setViewPort]: any = useState(() => props.page.getViewport(1));

  const intersectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState<boolean>(pageNumber < 21);


  const height = props.pdfPreviewWidth * 1.41;
  const width = props.pdfPreviewWidth;

  /**
   * Since in grid view the pages are much smaller, we don't want to load too many
   * of them so we reduce the offset in that case.
   */
  const VISIBLE_OFFSET = viewMode === PagesOrientationType.GRID
    ? VISIBLE_OFFSET_GRID
    : VISIBLE_OFFSET_LIST;


  const root = document.getElementById(rootNodeId);

  const idOfTimeout = useRef(null);
  useEffect(() => {
    let observer;

    if (intersectionRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          setIsVisible((currentIsVisible) => {
            if (currentIsVisible === false && entries[0].isIntersecting === false) {
              clearTimeout(idOfTimeout.current);
              idOfTimeout.current = null;
            } else {
              idOfTimeout.current = setTimeout(
                () => setIsVisible(entries[0].isIntersecting),
                200,
              );
            }

            return currentIsVisible;
          });
        },
        { root, rootMargin: `${VISIBLE_OFFSET}px 0px` },
      );


      observer.observe(intersectionRef.current);
    }
    return () => {
      if (intersectionRef.current) {
        observer?.unobserve(intersectionRef.current);
      }
    };
  }, [intersectionRef, VISIBLE_OFFSET]);


  return (
    <div className="VirtualizePdfPageWrapper" ref={intersectionRef}>
      {
        isVisible
          ? <PdfPagePreview {...props} viewport={viewport} setViewPort={setViewPort} />
          : <div id={props.id} className="placeholder-page" style={{ height, width }} />
      }
    </div>
  );
};

export default VirtualizePdfPageWrapper;
