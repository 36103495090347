export const translations = {
  buttons: {
    assign: {
      en: 'Assign',
      de: 'Zuordnen',
    },
    unassign: {
      en: 'Unassign',
      de: 'Entfernen',
    },
  },
};
