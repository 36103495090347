import { useEconomicPlanDocuments } from 'pages/EconomicPlan/EconomicPlanOverview/services/useEconcomicPlanDocuments';
import { useContext } from 'react';
import { EconomicPlanControllerApi, EconomicPlanDto } from '../../../../api/accounting';
import { AuthContext } from '../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { DefaultDataInterface } from '../../../../lib/data';
import { showNotification } from '../../../../lib/Notification';
import useEconomicPlanList from '../../EconomicPlanList/services/useEconomicPlanList';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { HouseMoneyListContext } from '../../services/HouseMoneyContext';
import { useHandleErrorWithType } from '../../services/useHandleErrorWithType';


export function useStateChangeButtons() {
  const economicPlanContext = useContext(EconomicPlanContext);
  const houseMoneyListContext = useContext(HouseMoneyListContext);
  if (economicPlanContext === undefined) {
    // NOTE: House Money List Context CAN be undefined
    throw new Error('useStateChangeButtons must be used within a EconomicPlanContextProvider');
  }

  const {
    economicPlan,
    setEconomicPlan,
    invalidAmounts,
  } = economicPlanContext;

  const { onLoadEconomicPlanDocuments } = useEconomicPlanDocuments();
  const { loadEconomicPlanList } = useEconomicPlanList();
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const { handleErrorWithType } = useHandleErrorWithType();

  const mergeWithPrev = (response: EconomicPlanDto, prev: DefaultDataInterface<EconomicPlanDto>) => ({
    ...prev.data,
    ...response,
  });

  const onMarkEconomicPlan = (status: 'PREPARED' | 'DECIDED' | 'DRAFT', id: number) => {
    setEconomicPlan(economicPlan.startLoading());
    let promise;
    if (status === 'DRAFT') {
      promise = economicPlanControllerApi.revertToDraftUsingPOST({ id });
    } else if (status === 'DECIDED') {
      promise = economicPlanControllerApi.markDecidedUsingPOST({ id });
    } else {
      promise = economicPlanControllerApi.markPreparedUsingPOST({ id });
    }

    promise
      .then((response: EconomicPlanDto) => {
        setEconomicPlan(prev => prev.load(mergeWithPrev(response, prev)));
        loadEconomicPlanList(true);
        if (status === 'PREPARED') {
          // reload documents
          onLoadEconomicPlanDocuments(response?.id);
        }
      })
      .catch((error) => {
        setEconomicPlan(economicPlan.failed());
        error.response.json()
          .then((errorBody) => {
            if (!errorBody.type) {
              showNotification({
                key: 'changeEconomicPlanStatusError',
                message: tl(economicPlanTranslations.economicPlan.notification.statusChangeError) + tl(economicPlanTranslations.economicPlanList.status[status]),
                type: 'error',
              });
              return;
            }

            if (status === 'DECIDED') {
              handleErrorWithType(errorBody, tl(economicPlanTranslations.economicPlan.notification.activationErrorTitle));
              return;
            }

            handleErrorWithType(errorBody, tl(economicPlanTranslations.economicPlan.notification.statusChangeError) + tl(economicPlanTranslations.economicPlanList.status[status]));
          });
      });
  };

  return {
    loading: economicPlan.loading || houseMoneyListContext?.houseMoneyList?.loading,
    invalidAmounts,
    onMarkEconomicPlan,
    economicPlanId: economicPlan.data?.id,
    economicPlanStatus: economicPlan.data?.status,
  };
}
