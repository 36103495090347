import { LanguageContext } from 'contexts/LanguageContext';
import ActionsDropdown from 'elements/SmartTable/components/OptionsMenu/ActionsDropdown';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { useMessageDocumentTableOptions } from './useMessageDocumentTableOptions';

const MessageDocumentTableOptions = ({ record }) => {
  const { tl } = useContext(LanguageContext);
  const { onRemove } = useMessageDocumentTableOptions();

  const options = [
    {
      label: tl(messageSendingTranslations.documentTable.options.remove),
      onAction: () => onRemove(record),
      actionSupported: () => !record.rowKey?.startsWith('prp'),
    },
  ];

  return (
    <ActionsDropdown
      record={record}
      actions={options}
      tableVerticallyScrollable={false}
    />
  );
};

export default MessageDocumentTableOptions;
