import { Table } from 'antd';
import React, { useContext } from 'react';
import { useVT } from 'virtualizedtableforantd4';
import TableContentLoadingIndicator from '../../elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { useOwnerPaymentTableColumns } from './services/useOwnerPaymentTableColumns';
import { useOwnerPaymentsTable } from './services/useOwnerPaymentsTable';
import TableSummaryTotalAmount from '../../elements/TableSummaryTotalAmount/TableSummaryTotalAmount';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ownerPaymentTableTranslations } from './ownerPaymentTableTranslations';
import { formatToEuro } from '../../lib/Utils';
import { UnitContractProjectionDto } from '../../api/accounting';
import { useResizableColumn } from '../table/ResizableColumn/useResizableColumn';

export interface OwnerPaymentTableProps {
  activeContractsList?: Array<UnitContractProjectionDto>,
  amounts?: Array<{ unitId: number, amount: number }>,
  loading?: boolean,
}

export const OwnerPaymentTable = ({
  activeContractsList, amounts, loading,
}: OwnerPaymentTableProps) => {
  const tableHeight = 450;
  const [
    VT,
  ] = useVT(() => ({ scroll: { y: tableHeight - 65 } }), []);
  const { tl } = useContext(LanguageContext);
  const { datasource, totalAmount } = useOwnerPaymentsTable(activeContractsList, amounts);
  const columns = useOwnerPaymentTableColumns();
  const { components, resizableColumns } = useResizableColumn(columns, VT);

  return (
    <Table
      loading={{
        indicator: <TableContentLoadingIndicator />,
        spinning: !!loading,
      }}
      rowKey="unitContractId"
      columns={resizableColumns}
      dataSource={datasource}
      pagination={false}
      scroll={{ y: tableHeight }}
      components={components}
      summary={() => (TableSummaryTotalAmount({
        amountLabel: tl(ownerPaymentTableTranslations.totalAmountLabel),
        totalAmount: formatToEuro(totalAmount),
        iconCellColSpan: 4,
      })
      )}
    />
  );
};

OwnerPaymentTable.defaultProps = {
  activeContractsList: [],
  amounts: [],
  loading: false,
};
