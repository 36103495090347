import {
  DocumentLegacyDtoAssignedToEnum, DocumentLegacyDtoOcrStateEnum, DocumentLegacyDtoSourceTypeEnum, DocumentLegacyDtoStateEnum, DocumentProjectionDto,
} from 'api/document';
import { Watermark, WatermarkMetaData } from '../interfaces';

export function parseWatermark(item: DocumentProjectionDto): Watermark {
  const parsedMetaData: WatermarkMetaData = item.metaData ? JSON.parse(item.metaData) : undefined;

  return {
    ...item,
    assignedTo: item.assignedTo as unknown as DocumentLegacyDtoAssignedToEnum,
    ocrState: item.ocrState as unknown as DocumentLegacyDtoOcrStateEnum,
    sourceType: item.sourceType as unknown as DocumentLegacyDtoSourceTypeEnum,
    state: item.state as unknown as DocumentLegacyDtoStateEnum,
    metaData: parsedMetaData,
  };
}

export function parseWatermarks(items: DocumentProjectionDto[]): Watermark[] {
  return items.map(parseWatermark);
}
