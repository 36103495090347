import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import React, { useContext, useRef } from 'react';
import { translations } from '../../translations';
import { usePdfPageAssignerAddFilesButton } from './usePdfPageAssignerAddFilesButton';

interface PdfPageAssignerAddFilesButtonProps {
  buttonType: 'primary' | 'text',
  hideIfNoFiles: boolean,
}

const PdfPageAssignerAddFilesButton = ({ buttonType, hideIfNoFiles }: PdfPageAssignerAddFilesButtonProps) => {
  const { tl } = useContext(LanguageContext);
  const inputRef = useRef(null);

  const { onAddFiles, loading, noUploadedFiles } = usePdfPageAssignerAddFilesButton();

  if (hideIfNoFiles && noUploadedFiles) {
    return null;
  }

  return (
    <>
      <Button type={buttonType} disabled={loading} onClick={() => inputRef.current?.click()}>{tl(translations.pageHeader.buttons.addFiles)}</Button>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        value=""
        onChange={onAddFiles}
        accept="application/pdf"
        multiple
      />
    </>
  );
};

export default PdfPageAssignerAddFilesButton;
