export const translationsUsePropertyAdministrationTypeFilterOption = {
  administrationType: {
    en: 'Administration type',
    de: 'Verwaltungsart',
  },
  administrationTypes: {
    WEG: {
      en: 'Wohnungseigentum (WEG)',
      de: 'Wohnungseigentum (WEG)',
    },
    MV: {
      en: 'Mietverwaltung (MV)',
      de: 'Mietverwaltung (MV)',
    },
    SEV: {
      en: 'SEV with WEG connection',
      de: 'SEV mit WEG-Verbindung',
    },
    SEV_MV: {
      en: 'SEV without WEG connection',
      de: 'SEV ohne WEG-Verbindung',
    },
  },
};
