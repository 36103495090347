export const translations  = {
    uploadCamtXml: {
        en: 'Upload CAMT XML',
        de: 'CAMT XML Hochladen',
      },
      fileUploadModal: {
        title: {
          en: 'Import transactions',
          de: 'Transaktionen importieren',
        },
        upload: {
          en: 'Upload xml',
          de: 'Hochladen',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        body: {
          en: 'By uploading the file, the transactions will be matched. Are you sure you want to continue?',
          de: 'Durch das Hochladen der Datei werden die Transaktionen importiert und automatisch abgeglichen. Dies kann nicht rückgängig gemacht werden. Bitte stellen Sie sicher, dass die korrekten Dateien importiert werden.',
        },
      },
};