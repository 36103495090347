import { useContext, useEffect, useState } from 'react';

import {
  ListSpecialContributionUsingGETOrderEnum,
  ListSpecialContributionUsingGETSpecialContributionStatusesEnum,
  SpecialContributionControllerApi,
  SpecialContributionDto,
} from '../../../../api/accounting';
import { AuthContext } from '../../../../contexts/AuthContext';
import { showNotification } from '../../../../lib/Notification';
import { translations } from '../../translations';
import DEFAULT_DATA, { DefaultDataInterface } from '../../../../lib/data';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { useSpecialContributionFilters } from '../UseSpecialContributionFilters';
import { useGlobalSearch } from '../../../../components/Header/components/SearchBar/services/useGlobalSearch';

const PAGE_SIZE = 30;

export default function useSpecialContributionList(
  selectedQuickFilter: string,
) {
  const { tl } = useContext(LanguageContext);
  const defaultSortState: { field: string, order: 1 | -1 } = {
    field: 'exchangePlan.dueDate',
    order: 1,
  };
  const [sortState, setSortState] = useState(defaultSortState);
  const [filterState, setFilterState] = useState<any>();
  const globalSearchFilterProps = useSpecialContributionFilters(selectedQuickFilter, setFilterState);
  useGlobalSearch(globalSearchFilterProps);

  const { apiConfiguration } = useContext(AuthContext);
  const specialContributionControllerApi = new SpecialContributionControllerApi(apiConfiguration('accounting'));
  const [specialContributionList, setSpecialContributionList] = useState<DefaultDataInterface<SpecialContributionDto[]>>(DEFAULT_DATA<SpecialContributionDto[]>([]));

  useEffect(() => {
    if (filterState) {
      onLoadSpecialContributionList(true);
    }
  }, [filterState, sortState]);

  useEffect(() => {
    setFilterStateToDefault();
  }, [selectedQuickFilter]);

  const setFilterStateToDefault = () => {
    switch (selectedQuickFilter) {
    case 'ALL':
      setFilterState((fState: any) => ({
        ...fState,
        specialContributionStatuses: [ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DRAFT,
          ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DONE,
          ListSpecialContributionUsingGETSpecialContributionStatusesEnum.ERROR] as ListSpecialContributionUsingGETSpecialContributionStatusesEnum[],
      }));
      break;
    case 'OPEN':
      setFilterState((fState: any) => ({
        ...fState,
        specialContributionStatuses: [ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DRAFT,
          ListSpecialContributionUsingGETSpecialContributionStatusesEnum.ERROR] as ListSpecialContributionUsingGETSpecialContributionStatusesEnum[],
      }));
      break;
    case 'DONE':
      setFilterState((fState: any) => ({
        ...fState,
        specialContributionStatuses: [ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DONE] as ListSpecialContributionUsingGETSpecialContributionStatusesEnum[],
      }));
      break;
    default:
      setFilterState((fState: any) => ({
        ...fState,
        specialContributionStatuses: [ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DRAFT,
          ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DONE,
          ListSpecialContributionUsingGETSpecialContributionStatusesEnum.ERROR] as ListSpecialContributionUsingGETSpecialContributionStatusesEnum[],
      }));
      break;
    }
  };

  const setSortingColumn = (field: string) => {
    // TODO if statement to be removed when amount topic clarified
    if (field !== 'amount') {
      setSortState((currentState) => {
        const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
        return {
          field,
          order,
        };
      });
    }
  };

  const onLoadSpecialContributionList = async (resetPage: boolean = false) => {
    setSpecialContributionList(state => state.startLoading());

    if (filterState?.specialContributionStatuses.length === 0) {
      setFilterStateToDefault();
    } else {
      specialContributionControllerApi.listSpecialContributionUsingGET({
        page: resetPage ? 0 : specialContributionList.page,
        size: PAGE_SIZE,
        sort: sortState.field,
        ...filterState,
        order: sortState.order > 0 ? ListSpecialContributionUsingGETOrderEnum.ASC : ListSpecialContributionUsingGETOrderEnum.DESC,
      })
        .then((response) => {
          setSpecialContributionList(specialContributionList.loadPaged(response.content, resetPage, response.last!));
        })
        .catch(() => {
          setSpecialContributionList(state => state.failed());
          showNotification({
            key: 'loadSpecialContributionError',
            message: tl(translations.specialContribution.loadSpecialContributionListError.message),
            description: tl(translations.specialContribution.loadSpecialContributionListError.description),
            type: 'error',
          });
        });
    }
  };

  return {
    setSpecialContributionList,
    specialContributionList,
    sortField: sortState.field,
    setSortingColumn,
    sortOrder: sortState.order,
    onLoadSpecialContributionList,
  };
}
