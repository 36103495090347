import { EconomicPlanHouseMoneyControllerApi } from 'api/accounting';
import { useContext } from 'react';
import { HouseMoneySectionUpdatersContext } from '../../../services/HouseMoneyContext';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { economicPlanHouseMoneyTableTranslations } from '../economicPlanHouseMoneyTableTranslations';
import { showNotification } from '../../../../../lib/Notification';
import { useSimpleDirtModal } from '../../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export const useLoadHouseMoney = () => {
  const updatersContext = useContext(HouseMoneySectionUpdatersContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const houseMoneyControllerApi = new EconomicPlanHouseMoneyControllerApi(apiConfiguration('accounting'));

  if (updatersContext === undefined) {
    throw new Error('useLoadHouseMoney must be used within a HouseMoneyListContextProvider');
  }
  const { clearDirty } = useSimpleDirtModal();
  const {
    setHouseMoneyList,
  } = updatersContext;

  const loadHouseMoneyAmounts = (economicPlanId) => {
    setHouseMoneyList(prevState => prevState.startLoading());
    if (economicPlanId) {
      houseMoneyControllerApi.getHouseMoneyAmountsUsingGET({ economicPlanId })
        .then(
          (response) => {
            setHouseMoneyList(prev => prev.load(response));
          },
        )
        .catch(() => {
          showNotification({
            key: 'loadHouseMoneyList',
            message: tl(economicPlanHouseMoneyTableTranslations.notifications.loadOwnerHouseMoneyListError.message),
            description: tl(economicPlanHouseMoneyTableTranslations.notifications.loadOwnerHouseMoneyListError.description),
            type: 'error',
          });
        })
        .finally(() => {
          setHouseMoneyList(prevState => prevState.finishLoading());
          clearDirty();
        });
    } else {
      setHouseMoneyList(prev => prev.load([]));
      clearDirty();
    }
  };

  return {
    loadHouseMoneyAmounts,
  };
};
