import _ from 'lodash';
import moment from 'moment';
import {
  InvoiceBookingDto,
  InvoiceLegacyDtoPaymentTypeEnum,
  PropertyBankAccountProjectionDto,
  PropertyBankAccountProjectionDtoPropertyAccountTypesEnum,
  PropertyDisplayDto,
  PropertyLegacyDtoAdministrationTypeEnum,
} from '../../api/accounting';

export interface InvoiceBookingWithNet extends InvoiceBookingDto {
  netAmount?: number,
  incoming?: boolean,
  laborCostPercentage?: number,
}

function getInvoicePaymentAmount(invoice: any) {
  if (invoice.totalGross) {
    return Math.abs(invoice.totalGross);
  }
  return 0;
}

export function convertToFEModel(invoice: any) {
  if (!!invoice.invoicePayments && invoice.invoicePayments.length !== 0) {
    if (invoice.invoicePayments.length > 1) {
      invoice.numberOfPaymentSplits = invoice.invoicePayments.length;
    } else {
      invoice.numberOfPaymentSplits = 2;
      if (moment(invoice.invoicePayments[0].executionDate).isAfter(moment())) {
        invoice.executionDateType = 'date';
      } else {
        invoice.executionDateType = 'ASAP';
        invoice.invoicePayments[0].executionDate = moment();
      }
    }
  } else {
    invoice.numberOfPaymentSplits = 2;
    invoice.invoicePayments = [{
      amount: getInvoicePaymentAmount(invoice),
      executionDate: moment(),
    }];
  }

  if (!invoice.paymentType) {
    invoice.paymentType = 'PAYMENT';
  }

  if (invoice.invoiceDate) {
    invoice.paymentTargetDays = invoice.paymentTargetDate
      ? Math.floor(moment(invoice.paymentTargetDate).diff(invoice.invoiceDate, 'days', true))
      : undefined;
  }

  if (invoice.serviceStartDate || invoice.serviceEndDate) {
    invoice.servicePeriod = [invoice.serviceStartDate ? moment(invoice.serviceStartDate) : undefined, invoice.serviceEndDate ? moment(invoice.serviceEndDate) : undefined];
  }

  invoice.invoiceBookings.forEach((booking) => {
    booking.netAmount = booking.vatAmount ? booking.amount - booking.vatAmount : booking.amount;
    booking.incoming = booking.amount > 0;
  });
  return invoice;
}

export function getDefaultBankAccountId(property: any) {
  if (!property?.bankAccounts) return undefined;
  const propertyBankAccount = property.administrationType === PropertyLegacyDtoAdministrationTypeEnum.WEG
    ? property.bankAccounts.filter((bankAccount: PropertyBankAccountProjectionDto) => bankAccount.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE))[0]
    : property.bankAccounts.filter((bankAccount: PropertyBankAccountProjectionDto) => bankAccount.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.RENT))[0];
  if (propertyBankAccount) {
    return propertyBankAccount.bankAccountId;
  }
  // if no HOUSE or RENT account => do not preselect
  return undefined;
}

/**
 * IMPORTANT: NO ASYNC processes in this function
 * @param invoice
 * @param oldInvoice
 * @param property
 */
export function processChanges(invoice: any, oldInvoice: any, property: PropertyDisplayDto): any {
  // update statement if invoice nr changes
  if (invoice.invoiceNumber) {
    if (invoice.invoiceNumber !== oldInvoice.invoiceNumber) {
      if (!invoice.invoicePayments) {
        // if there were no invoice payments
        invoice.invoicePayments = [{
          statement: invoice.invoiceNumber,
        }];
      } else {
        invoice.invoicePayments.forEach((invoicePayment: any) => {
          if (!invoicePayment.statement || invoicePayment.statement === '') {
            invoicePayment.statement = invoice.invoiceNumber;
          } else if (invoicePayment.statement === oldInvoice.invoiceNumber) {
            invoicePayment.statement = invoice.invoiceNumber;
          }
        });
      }
    }
  }

  // TODO calculate total Gross/Net

  if (typeof oldInvoice.propertyId !== 'undefined' && oldInvoice.propertyId !== invoice.propertyId) {
    delete invoice.bankAccountId;
    delete invoice.propertyIban;
    delete invoice.propertyBic;
  }

  // select default bank account
  if (property?.id === invoice.propertyId!) {
    // if property selected for unit is already loaded
    const propertyBankAccounts = property?.bankAccounts;
    if (typeof invoice.bankAccountId === 'undefined' || propertyBankAccounts.map((ba: PropertyBankAccountProjectionDto) => ba.bankAccountId)
      .indexOf(invoice.bankAccountId) === -1) {
      invoice.bankAccountId = getDefaultBankAccountId(property);
    }
  }

  if (!_.isEmpty(invoice.invoicePayments)) {
    invoice.invoicePayments = [invoice.invoicePayments[invoice.invoicePayments.length - 1]];
    if (invoice.executionDateType === 'ASAP') {
      invoice.invoicePayments[0].executionDate = moment();
    }
    invoice.invoicePayments[0].amount = getInvoicePaymentAmount(invoice);
    invoice.numberOfPaymentSplits = 2;
  }

  return invoice;
}

export function convertToBEModel(invoice: any, invoiceBookings: InvoiceBookingWithNet[]) {
  const newInvoice = _.cloneDeep(invoice);
  newInvoice.invoiceBookings = invoiceBookings;

  if (newInvoice.invoiceBookings.length > 0) {
    newInvoice.invoiceBookings = newInvoice.invoiceBookings.map((booking: InvoiceBookingDto) => ({
      ...booking,
      laborCost: Math.abs(booking.laborCost) * Math.sign(booking.amount),
      vatAmount: booking.vatAmount || 0,
      vatPercentage: booking.vatPercentage || 0,
    }));
  }
  if (!newInvoice.invoiceBookings[0].overwriteBookingDate) {
    newInvoice.invoiceBookings = newInvoice.invoiceBookings.map((booking: InvoiceBookingDto) => ({
      ...booking,
      bookingDate: moment(invoice.invoiceDate),
    }));
  }
  if (newInvoice.invoicePayments) {
    newInvoice.invoicePayments = newInvoice.invoicePayments.map((invoicePayment: any) => ({
      ...invoicePayment,
    }));
  }

  /**
 * Ensuring that total gross and payment split amounts are sent to BE with 2 decimal precision,
 * in order to pass the validations.
 */
  if (newInvoice.totalGross) {
    newInvoice.totalGross = newInvoice.totalGross.toFixed(2);
  }
  const invoiceBookingsTotalNet = invoiceBookings.reduce((sum, booking) => sum + booking.netAmount, 0);
  newInvoice.totalNet = invoiceBookingsTotalNet.toFixed(2);
  if ((invoiceBookingsTotalNet === 0 || _.isNil(invoiceBookingsTotalNet)) && newInvoice.totalGross !== 0) {
    newInvoice.totalNet = newInvoice.totalGross; // it already has 2 decimals
  }

  if ((newInvoice.paymentType === InvoiceLegacyDtoPaymentTypeEnum.STANDING_ORDER || newInvoice.paymentType === InvoiceLegacyDtoPaymentTypeEnum.RECURRING_NO_ORDER)
    && !newInvoice.invoiceStandingOrder) {
    newInvoice.invoiceStandingOrder = {};
  } else if (newInvoice.paymentType === InvoiceLegacyDtoPaymentTypeEnum.PAYMENT || newInvoice.paymentType === InvoiceLegacyDtoPaymentTypeEnum.EXISTING) {
    delete newInvoice.invoiceStandingOrder;
  }

  const invoicePaymentsLength = newInvoice.numberOfPaymentSplits;
  for (let i = 0; i < invoicePaymentsLength; i += 1) {
    if (!!newInvoice.invoicePayments[i] && !!newInvoice.invoicePayments[i].amount) {
      newInvoice.invoicePayments[i].amount = +newInvoice.invoicePayments[i].amount.toFixed(2);
    }
  }
  return newInvoice;
}
