export const documentTranslations = {
  deleteModal: {
    title: {
      en: 'Irreversible deletion of document',
      de: 'Unwiderrufliches Löschen des Dokuments',
    },
    content: {
      en: 'The data cannot be recovered. Are you sure you want to delete the document?',
      de: 'Die Daten können nicht wiederhergestellt werden. Sind Sie sicher, dass Sie das Dokument löschen wollen?',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
  },
};
