import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
  title: string,
  startBalanceSum: number,
  debitBalanceSum: number,
  creditBalanceSum: number,
  nominalRentSum: number,
  opsCostSum: number,
  additionalIncomeSum: number,
  directCostSum: number,
  depositSum: number,
  endBalanceSum: number,
  vat: number | undefined,
  displayVat: boolean,
}

const AnnexTableSummary = ({
  title, startBalanceSum,
  debitBalanceSum,
  creditBalanceSum,
  nominalRentSum,
  opsCostSum,
  additionalIncomeSum,
  directCostSum,
  depositSum,
  endBalanceSum,
  vat,
  displayVat,
}: Props) => (
  <Table.Summary fixed>
    <Table.Summary.Row className="ProfitAndLossSummary">
      <Table.Summary.Cell index={0} colSpan={3}>
        {title}
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={3} colSpan={1}>
        <Amount>{formatCurrency(startBalanceSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={4} colSpan={1}>
        <Amount>{formatCurrency(debitBalanceSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={5} colSpan={1}>
        <Amount>{formatCurrency(creditBalanceSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={6} colSpan={1}>
        <Amount>{formatCurrency(nominalRentSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={7} colSpan={1}>
        <Amount>{formatCurrency(opsCostSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={8} colSpan={1}>
        <Amount>{formatCurrency(additionalIncomeSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={9} colSpan={1}>
        <Amount>{formatCurrency(directCostSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      {
        displayVat
        && (
          <Table.Summary.Cell className="column-align-right no-wrap" index={10} colSpan={1}>
            <Amount>{formatCurrency(vat, '-', false)}</Amount>
          </Table.Summary.Cell>
        )
      }
      <Table.Summary.Cell className="column-align-right no-wrap" index={displayVat ? 11 : 10} colSpan={1}>
        <Amount>{formatCurrency(depositSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={displayVat ? 12 : 11} colSpan={1}>
        <Amount>{formatCurrency(endBalanceSum, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default AnnexTableSummary;
