import { Switch } from 'antd';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { paymentDirectionSwitchTranslations } from './paymentDirectionSwitchTranslations';

export declare type PaymentDirection = 'incoming' | 'outgoing';

export interface PaymentDirectionSwitchProps {
  defaultValue?: PaymentDirection,
  value?: PaymentDirection,
  disabled?: boolean,
  onChange?: (newValue: PaymentDirection) => void,
}

export const PaymentDirectionSwitch = ({
  disabled, defaultValue, onChange: onChangeProp, value: valueFromProp,
}: PaymentDirectionSwitchProps) => {
  const { tl } = useContext(LanguageContext);
  // localValue stores defaultValue as a state so a virtualized table will not reset the value
  const [localValue, setLocalValue] = useState(defaultValue);
  const checked = (valueFromProp ?? localValue) === 'incoming';

  const onChange = (newValue: PaymentDirection) => {
    setLocalValue(newValue);
    onChangeProp(newValue);
  };

  return (
    <Switch
      checkedChildren={tl(paymentDirectionSwitchTranslations.incoming)}
      unCheckedChildren={tl(paymentDirectionSwitchTranslations.outgoing)}
      checked={checked}
      disabled={disabled}
      onChange={newValue => (newValue ? onChange('incoming') : onChange('outgoing'))}
    />
  );
};

PaymentDirectionSwitch.defaultProps = {
  disabled: false,
  onChange: () => {},
  defaultValue: 'incoming',
  value: undefined,
};
