import React, { useContext } from 'react';
import { Modal } from 'antd';
import './AgendaItemTableOptionsMenu.scss';
import { AgendaItemDto, OwnersMeetingProtocolDtoStatusEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { OwnersMeetingProtocolContext } from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';
import { DropMenu, MenuOption } from 'components/DropMenu/DropMenu';
import { translations } from '../../../../../../../../elements/Translation/translations';
import { translations as ownersMeetingProtocolTranslations } from '../../translations';
import { useDeleteActionOnAgendaItemsList } from '../../services/useDeleteActionOnAgendaItemsList';


interface Props {
  record: AgendaItemDto,
}

const AgendaItemTableOptionsMenu = ({ record }: Props) => {
  const { tl } = useContext(LanguageContext);
  const { onDeleteAgendaItem } = useDeleteActionOnAgendaItemsList(record.id);
  const { ownersMeeting } = useContext(OwnersMeetingProtocolContext);
  const isDisabled = !ownersMeeting.data || (ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.SENT || ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.DELETED
      || ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.DONE);

  const actions: MenuOption[] = [
    {
      label: tl(ownersMeetingProtocolTranslations.deleteButton),
      onClick: () => {
        Modal.confirm({
          title: tl(translations.elements.deletionModal.title),
          content: tl(translations.elements.deletionModal.description),
          okText: tl(translations.elements.deletionModal.delete),
          cancelText: tl(translations.elements.deletionModal.cancel),
          okButtonProps: { className: 'Button' },
          cancelButtonProps: { className: 'Button' },
          onOk: onDeleteAgendaItem,
          width: 450,
        });
      },
    },
  ];

  return (
    <div className="AgendaItemTableOptionsMenu">
      <DropMenu options={actions} disabled={isDisabled} />
    </div>
  );
};

export default AgendaItemTableOptionsMenu;
