import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { ORDER_OPEN_BALANCES_OVERVIEW_PATH } from 'pages/OrderOpenBalances/routes';
import { useHistory } from 'react-router';
import { ContractProjectionDto } from 'api/accounting';
import { getNewAndOldPropertyBalancesMerged } from './useOrderOpenBalancesList';
import { ContractDunningLevel, DunningFee } from './interfaces';
import { useOrderOpenBalancesContext, useOrderOpenBalancesSelectionContext } from './OrderOpenBalancesContext';

interface AccountCodeAndBalance {
  code: string,
  balance: number,
  vatAmount: number,
}

// Optional fields are only neccessary for the NEW page and not for the VIEW page
export interface ContractDunningData {
  key: string,
  rootAccountName: string,
  rootAccountCode?: string,
  propertyHrId: string,
  propertyInternalId: string,
  propertyName: string,
  propertyId?: number,
  propertyIban:string,
  propertyManager?: string,
  propertyAccountant?: string,
  propertyAccountHolder?: string,
  managementCompanyName: string,
  creditorId?: string,
  unitId?: number,
  contractId: number,
  dunningLevel?: ContractDunningLevel,
  dunningFee: DunningFee,
  contractBankAccountId?: number,
  contractHasMandate?: boolean,
  openAmount: number,
  unitRank?: number,
  unitNrSharingDeclaration?: string,
  ibanContract: string,
  mandateId?: string,
  accountCodesAndBalances?: AccountCodeAndBalance[],
  propertyBankAccountId?: number,
  balanceUntilDate?: Date,
  contract?: ContractProjectionDto,
}

export interface PropertyContractDunningData {
  key: string,
  propertyId?: number,
  propertyName: string,
  propertyHrId: string,
  propertyInternalId: string,
  accountIdsPerContract?: { [key:number]: number[]; },
  children: ContractDunningData[],
}

const getSelectedAcountBalances = (contractBalance, selectedContractKeys) => {
  if (!selectedContractKeys.some(key => key.includes(contractBalance.accountCode))) {
    return [];
  }
  const selectedAccountBalances = [];
  if (!isEmpty(contractBalance.children)) {
    contractBalance.children.forEach((child) => {
      selectedAccountBalances.push(...getSelectedAcountBalances(child, selectedContractKeys));
    });
  } else {
    selectedAccountBalances.push(contractBalance);
  }

  return selectedAccountBalances.filter(Boolean);
};

const getSelectedContracts = (contractBalances, selectedContractKeys) => contractBalances
  .map(contractBalance => ({
    rootAccountCode: contractBalance.accountCode,
    selectedAccountBalances: getSelectedAcountBalances(contractBalance, selectedContractKeys),
  }))
  .filter(cb => !isEmpty(cb.selectedAccountBalances));


export const useOrderOpenBalancesNavigate = () => {
  const history = useHistory();

  const {
    propertyAndContractBalanceList,
    accounts,
    contractDunningLevels,
    dunningFees, orderOpenbalanceFilterState,
  } = useOrderOpenBalancesContext('useOrderOpenBalancesNavigate');

  const {
    innerTableSelectedRowKeysTotal,
    outerTableSelectedRowKeysTotal,
    cachedPropertyBalances,
  } = useOrderOpenBalancesSelectionContext('useOrderOpenBalancesNavigate');


  const onNavigateToOverview = () => {
    const state: PropertyContractDunningData[] = [];

    const allSelectedProperties = getNewAndOldPropertyBalancesMerged(propertyAndContractBalanceList.data, cachedPropertyBalances);

    outerTableSelectedRowKeysTotal.forEach((selectedKey) => {
      const selectedProperty = allSelectedProperties.find(prp => prp.propertyId.toString() === selectedKey.toString());
      const selectedContractKeys = innerTableSelectedRowKeysTotal.filter(key => key.startsWith(selectedProperty.propertyId.toString()));

      const selectedContracts = getSelectedContracts(selectedProperty.debtorBalancesGrouped, selectedContractKeys);
      const accountIdsPerContract = {};
      const selectedContractsForState = selectedContracts.map((contract) => {
        const {
          unitId,
          bankAccountId: contractBankAccountId,
          contractHasMandate,
          unitRank,
          contractId,
          propertyId,
          propertyHrId,
          unitNrSharingDeclaration,
          ibanContract,
          mandateId,
        } = contract.selectedAccountBalances[0];

        accountIdsPerContract[contractId] = contract.selectedAccountBalances.map(accountBalance => accountBalance.accountId);

        const rootAccountName = accounts.data.find(account => account.code === contract.rootAccountCode && account.propertyId === propertyId)?.name;
        const openAmount = contract.selectedAccountBalances.reduce((amount, account) => amount + account.filteredAccountBalance, 0);
        const dunningLevel = contractDunningLevels.find(cdl => cdl.contractId === contractId);
        const dunningFee = dunningFees.find(dnf => dnf.contractId === contractId);
        const accountCodesAndBalances = contract.selectedAccountBalances.map(sab => ({ code: sab.accountCode, balance: sab.filteredAccountBalance, vatAmount: sab.filteredAccountVatBalance }));

        return ({
          key: nanoid(),
          rootAccountName,
          rootAccountCode: contract.rootAccountCode,
          propertyId,
          propertyHrId,
          propertyInternalId: selectedProperty.propertyIdInternal,
          propertyName: selectedProperty.propertyName,
          propertyManager: selectedProperty.propertyManager,
          propertyAccountant: selectedProperty.propertyAccountant,
          creditorId: selectedProperty.creditorId,
          unitId,
          unitNrSharingDeclaration,
          contractId,
          dunningLevel,
          dunningFee,
          contractBankAccountId,
          contractHasMandate,
          mandateId,
          openAmount,
          unitRank,
          accountCodesAndBalances,
          ibanContract,
          balanceUntilDate: orderOpenbalanceFilterState.balanceUntil,
        });
      });

      state.push({
        key: nanoid(),
        propertyId: selectedProperty.propertyId,
        propertyName: selectedProperty.propertyName,
        propertyInternalId: selectedProperty.propertyIdInternal,
        propertyHrId: selectedProperty.propertyHrId,
        children: selectedContractsForState,
        accountIdsPerContract,
      });
    });

    // filter out from state
    // selected properties where all contracts are excluded
    const propertiesWithIncludedContracts = state.filter(prp => !isEmpty(prp.children));

    history.push(ORDER_OPEN_BALANCES_OVERVIEW_PATH, { propertyContractsDunningInfo: propertiesWithIncludedContracts, openBalanceUntilFilter: orderOpenbalanceFilterState.balanceUntil });
  };
  return {
    onNavigateToOverview,
  };
};
