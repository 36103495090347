import React, {
  useContext,
  useState,
} from 'react';

import {
  BankDetailsDto,
  ContactLegacyControllerApi,
  ContactLegacyDto,
  ContactLegacyDtoTypeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { isValidIBAN } from 'ibantools';

import {
  BankDetailsControllerApi,
} from '../../../../api/accounting/apis/BankDetailsControllerApi';
import { IProgress } from '../interfaces';
import { executeInParallelBatch } from '../utils/executeInParallelBatch';
import { ContactImportDto } from './interfaces';
import { translations } from './translations';

const BIC_PATTERN = '[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}';

const mapContactBankAccountsToContactBankAccountDto = (csvRow: any): Array<BankDetailsDto> => {
  const bankAccountList: Array<BankDetailsDto> = [];

  if (csvRow.iban1) {
    bankAccountList.push({
      accountHolderName: csvRow.accountHolderName1,
      iban: csvRow.iban1.replace(/\s/g, ''),
      bic: csvRow.bic1 ? csvRow.bic1.replace(/\s/g, '') : csvRow.bic1,
      accountInstitute: csvRow.accountInstitute1,
    });
  }
  if (csvRow.iban2) {
    bankAccountList.push({
      accountHolderName: csvRow.accountHolderName2,
      iban: csvRow.iban2.replace(/\s/g, ''),
      bic: csvRow.bic2 ? csvRow.bic2.replace(/\s/g, '') : csvRow.bic2,
      accountInstitute: csvRow.accountInstitute2,
    });
  }
  if (csvRow.iban3) {
    bankAccountList.push({
      accountHolderName: csvRow.accountHolderName3,
      iban: csvRow.iban3.replace(/\s/g, ''),
      bic: csvRow.bic3 ? csvRow.bic3.replace(/\s/g, '') : csvRow.bic3,
      accountInstitute: csvRow.accountInstitute3,
    });
  }

  return bankAccountList;
};

const mapRowToContactDto = (csvRow: any): ContactLegacyDto => {
  const contact = {
    id: csvRow.impowerId,
    externalId: csvRow.contactReference,
    type: csvRow.contactType === 'Firma' || csvRow.contactType === 'COMPANY' ? ContactLegacyDtoTypeEnum.COMPANY : ContactLegacyDtoTypeEnum.PERSON,

    companyName: csvRow.companyName,
    title: csvRow.title,
    salutation: salutationMapping[csvRow.salutation],
    firstName: csvRow.firstName || ' ',
    lastName: csvRow.lastName,
    notes: csvRow.notes,

    email: csvRow.email,
    phonePrivate: csvRow.phonePrivate,
    phoneBusiness: csvRow.phoneBusiness,
    phoneMobile: csvRow.phoneMobile,
    fax: csvRow.fax,
    website: csvRow.website,
    vatId: csvRow.vatId,
    tradeRegisterNumber: csvRow.tradeRegisterNumber,

    casaviId: csvRow.portalId,
    // csvRow.portalId // not supported

    addresses: [{
      recipient: csvRow.recipient,
      street: csvRow.street,
      number: csvRow.number,
      postalCode: csvRow.postalCode,
      city: csvRow.city,
      country: csvRow.country,
      additionalAddress1: csvRow.additionalAddress1,
      additionalAddress2: csvRow.additionalAddress2,
    }],
    dispatchPreferences: !csvRow.dispatchPreferences ? [] : csvRow.dispatchPreferences
      .split(/,|&/) // may be split as EPOST, PORTAL or EPOST & PORTAL
      .map(pref => pref
        .trim()
        .replace('-', '')
        .toUpperCase()),
    bankAccounts: mapContactBankAccountsToContactBankAccountDto(csvRow),
  };
  if (csvRow.dispatchPreferences1 && csvRow.dispatchPreferences1.trim()) {
    contact.dispatchPreferences.push(csvRow.dispatchPreferences1.trim().replace('-', '').toUpperCase());
  }
  if (csvRow.dispatchPreferences2 && csvRow.dispatchPreferences2.trim()) {
    contact.dispatchPreferences.push(csvRow.dispatchPreferences2.trim().replace('-', '').toUpperCase());
  }
  return contact;
};
const updateNotEmptyErpContactFields = (erpContact: ContactLegacyDto, update: ContactLegacyDto): ContactLegacyDto => {
  const updatedContact: ContactLegacyDto = {
    id: erpContact.id,
    externalId: update.externalId || erpContact.externalId,
    // csvRow.portalId // not supported
    type: update.type || erpContact.type,
    companyName: update.companyName || erpContact.companyName,
    title: update.title || (erpContact.title && /Dip.*.-Ing./.test(erpContact.title) ? '' : erpContact.title),
    salutation: update.salutation || erpContact.salutation,
    firstName: update.firstName || erpContact.firstName,
    lastName: update.lastName || erpContact.lastName,
    notes: update.notes || erpContact.notes,
    email: update.email || erpContact.email,
    phonePrivate: update.phonePrivate || erpContact.phonePrivate,
    phoneBusiness: update.phoneBusiness || erpContact.phoneBusiness,
    phoneMobile: update.phoneMobile || erpContact.phoneMobile,
    fax: update.fax || erpContact.fax,
    website: update.website || erpContact.website,
    vatId: update.vatId || erpContact.vatId,
    tradeRegisterNumber: update.tradeRegisterNumber || erpContact.tradeRegisterNumber,
    casaviId: update.casaviId || erpContact.casaviId,
    dispatchPreferences: update.dispatchPreferences || erpContact.dispatchPreferences,
  };
  if (erpContact.addresses && erpContact.addresses.length && update.addresses && update.addresses.length) {
    updatedContact.addresses = erpContact.addresses.map(a => ({ ...a }));
    updatedContact.addresses[0] = {
      recipient: update.addresses[0].recipient || updatedContact.addresses[0].recipient,
      street: update.addresses[0].street || updatedContact.addresses[0].street,
      number: update.addresses[0].number || updatedContact.addresses[0].number,
      postalCode: update.addresses[0].postalCode || updatedContact.addresses[0].postalCode,
      city: update.addresses[0].city || updatedContact.addresses[0].city,
      country: update.addresses[0].country || updatedContact.addresses[0].country,
      additionalAddress1: update.addresses[0].additionalAddress1 || updatedContact.addresses[0].additionalAddress1,
      additionalAddress2: update.addresses[0].additionalAddress2 || updatedContact.addresses[0].additionalAddress2,
    };
  } else if ((erpContact.addresses || erpContact.addresses.length) && update.addresses && update.addresses.length) {
    updatedContact.addresses = update.addresses;
  }
  if (!erpContact.bankAccounts || !erpContact.bankAccounts.length) {
    updatedContact.bankAccounts = update.bankAccounts || [];
  } else {
    const newBankAccounts = update.bankAccounts || [];
    const oldIbans = erpContact.bankAccounts?.map(ba => ba.iban);
    const combinedBankAccounts = [...erpContact.bankAccounts];
    // add only new ibans
    newBankAccounts.forEach((bankAccount) => {
      if (!oldIbans.includes(bankAccount?.iban)) {
        combinedBankAccounts.push(bankAccount);
      }
    });
    updatedContact.bankAccounts = combinedBankAccounts;
  }
  return updatedContact;
};

const salutationMapping = {
  Herr: 'MR', Frau: 'MRS', MR: 'MR', MRS: 'MRS', COMPANY: 'COMPANY', Firma: null,
};
const supportedTitles = ['Prof.', 'Prof. Dr.', 'Dr.'];

export const useImportContactsCsv = () => {
  const [contacts, setContacts] = useState<ContactImportDto[]>([]);
  const [contactsValidationIssues, setContactsValidationIssues] = useState<any[]>([]);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);


  const loadContactsFromCsvRows = async (csvRows: any[]) => {
    const readContacts: ContactLegacyDto[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach((csvRow) => {
      const contact = mapRowToContactDto(csvRow);
      readContacts.push(contact);
      if (contact.type === ContactLegacyDtoTypeEnum.PERSON && (!contact.firstName || !contact.lastName)) {
        validationIssues.push({ message: tl(translations.validations.issues.person), row: csvRow._row });
      } else if (contact.type === ContactLegacyDtoTypeEnum.COMPANY && (!contact.companyName)) {
        validationIssues.push({ message: tl(translations.validations.issues.company), row: csvRow._row });
      }
      if (contact.title && supportedTitles.indexOf(contact.title) === -1) {
        validationIssues.push({ message: `${tl(translations.validations.issues.title)} <${contact.title}>`, row: csvRow._row });
      }
      if (!/^[A-Z]{2}$/.test(contact.addresses[0].country)) {
        validationIssues.push({ message: tl(translations.validations.issues.country), row: csvRow._row });
      }
      if (!contact.addresses[0].city) {
        validationIssues.push({ message: tl(translations.validations.issues.city), row: csvRow._row });
      }
      if (!contact.addresses[0].street) {
        validationIssues.push({ message: tl(translations.validations.issues.street), row: csvRow._row });
      }
      if (!contact.addresses[0].number) {
        validationIssues.push({ message: tl(translations.validations.issues.number), row: csvRow._row });
      }
      contact.bankAccounts.forEach(async (bankAccount) => {
        if (!bankAccount.accountHolderName) {
          validationIssues.push({ message: tl(translations.validations.issues.accountHolderName), row: csvRow._row });
        }
        if (!bankAccount.iban || !isValidIBAN(bankAccount.iban)) {
          validationIssues.push({ message: tl(translations.validations.issues.iban), row: csvRow._row });
        }
        if (!bankAccount.bic || !bankAccount.accountInstitute) {
          // BIC not specified; try to figure it out with an api call
          try {
            const bankInformationApi = new BankDetailsControllerApi(apiConfiguration('accounting'));

            const bankInformation = await bankInformationApi.checkBankDetailsUsingGET({ iban: bankAccount.iban });
            bankAccount.bic = bankInformation.bic;
            bankAccount.accountInstitute = bankInformation.bankName;
          } catch (e) {
            setContactsValidationIssues((validationIssuesPrevState) => {
              validationIssuesPrevState.push({ message: tl(translations.validations.issues.bicIdentificationError), row: csvRow._row });
              validationIssuesPrevState.push({ message: tl(translations.validations.issues.accountInstituteIdentificationError), row: csvRow._row });
              return validationIssuesPrevState.slice();
            });
            setContacts([]);
          }
        } else if (bankAccount.bic.match(BIC_PATTERN) === null) {
          // BIC is specified; check if pattern matches the one we expect on BE
          validationIssues.push({ message: tl(translations.validations.issues.bicPattern), row: csvRow._row });
        }
      });
    });
    setContactsValidationIssues(validationIssues);
    if (validationIssues.length) {
      setContacts([]);
    } else {
      setContacts(readContacts);
    }
  };
  return {
    loadContactsFromCsvRows,
    contacts,
    contactsValidationIssues,
  };
};

export async function importContacts(contacts: ContactImportDto[], contactControllerApi: ContactLegacyControllerApi, setProgress: React.Dispatch<React.SetStateAction<IProgress>>) {
  await executeInParallelBatch(contacts, 1, async (contact: ContactImportDto) => {
    let savedContact;
    let mergedContact: ContactLegacyDto;
    if (contact.id) {
      const erpContact = await contactControllerApi.getContactUsingGET({ contactId: contact.id });
      mergedContact = updateNotEmptyErpContactFields(erpContact, contact);
      savedContact = await contactControllerApi.updateContactUsingPUT({ contactId: contact.id, contact: mergedContact });
    } else {
      savedContact = await contactControllerApi.createContactUsingPOST({ contact });
    }
    contact.importedObject = savedContact;
    setProgress(prev => ({ ...prev, contactsImported: prev.contactsImported + 1 }));
    return savedContact;
  });
}
