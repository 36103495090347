import React, { useContext } from 'react';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../../../../../elements/Translation/translations';
import { navigateConcat } from '../../../../../navigation/History';

export const displayAccountInfo = (e: any, accountCode: string) => {
  navigateConcat(`/account/${encodeURIComponent(accountCode)}`);
  e.stopPropagation();
};
export const useTotalAmountsOverviewColumns = () => {
  const { tl } = useContext(LanguageContext);

  return (
    [
      {
        title: tl(translations.pages.economicPlan.propertyAccountBalances.incomeExpenseOverview.table.headers.account),
        dataKey: 'account',
        className: 'account',
        defaultWidth: '35%',
        render: (text: any, row: any) => {
          if (row.accountCode) {
            return (
              <button
                onClick={(e: any) => displayAccountInfo(e, row.accountCode)}
                className="link"
              >
                {row.account}
              </button>
            );
          }
          return (<span>{row.account}</span>);
        },
      },
      {
        title: tl(translations.pages.economicPlan.propertyAccountBalances.incomeExpenseOverview.table.headers.distributionKey),
        dataKey: 'economicPlanDistributionMode',
        defaultWidth: '20%',
        render: (item: any) => (
          <span>{item ? tl(translations.enums.distributionMode[`${item}`]) : ''}</span>
        ),
      },
      {
        title: tl(translations.pages.economicPlan.propertyAccountBalances.incomeExpenseOverview.table.headers.settlementAmount),
        dataKey: 'houseMoneyDistributionMode',
        defaultWidth: '20%',
        render: (item: any) => (
          <span>{item ? tl(translations.enums.distributionMode[`${item}`]) : ''}</span>
        ),
      },
      {
        title: tl(translations.pages.economicPlan.propertyAccountBalances.incomeExpenseOverview.table.headers.totalAmount),
        dataKey: 'totalShares',
        format: FORMAT.NUMBER,
        defaultWidth: '25%',
      },
    ]
  );
};
