import { useContext } from 'react';
import moment from 'moment';
import usePropertyManagementCompanyEmployeeOptions from 'services/usePropertyManagementCompanyEmployeeOptions';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { OwnersMeetingProtocol } from '../../../interfaces';
import { defaultProtocolTexts } from '../../../utils';
import { replaceAllVariableWithValues } from '../../../../../lib/Utils';
import { translations as ownersMeetingProtocolTranslations } from '../../../translations';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { OptionInterface } from '../../../../../elements/Inputs/SmartSearch/SmartSearch';
import {
  OwnersMeetingProtocolDtoVotingRightsEnum,
  OwnersMeetingProtocolDtoMeetingLeaderRoleEnum,
  OwnersMeetingProtocolDtoVotingSchemeEnum,
} from '../../../../../api/accounting';

export interface OwnersMeetingProtocolReducerActions {
  type: 'update' | 'set' | 'setDefault',
  key?: string,
  value?: any,
}


export function useDefaultProtocolTexts() {
  const { tl } = useContext(LanguageContext);
  const { selectedDisplayProperty } = useContext(PropertyListContext);
  const roleOptions = [
    {
      value: OwnersMeetingProtocolDtoMeetingLeaderRoleEnum.MANAGING_DIRECTOR,
      label: tl(ownersMeetingProtocolTranslations.roleOptions.managingDirector),
    },
    {
      value: OwnersMeetingProtocolDtoMeetingLeaderRoleEnum.PROPERTY_MANAGER,
      label: tl(ownersMeetingProtocolTranslations.roleOptions.propertyManager),
    },
    {
      value: OwnersMeetingProtocolDtoMeetingLeaderRoleEnum.EMPLOYEE,
      label: tl(ownersMeetingProtocolTranslations.roleOptions.employee),
    },
    {
      value: OwnersMeetingProtocolDtoMeetingLeaderRoleEnum.OWNER,
      label: tl(ownersMeetingProtocolTranslations.roleOptions.owner),
    },
  ];

  const votingRightsOptions = [
    {
      value: OwnersMeetingProtocolDtoVotingRightsEnum.MEA,
      label: tl(ownersMeetingProtocolTranslations.distributionOptions.mea),
    },
    {
      value: OwnersMeetingProtocolDtoVotingRightsEnum.UNIT,
      label: tl(ownersMeetingProtocolTranslations.distributionOptions.units),
    },
    {
      value: OwnersMeetingProtocolDtoVotingRightsEnum.HEAD,
      label: tl(ownersMeetingProtocolTranslations.distributionOptions.headcount),
    },
  ];

  const { managementCompanyEmployees } = usePropertyManagementCompanyEmployeeOptions();
  const getDefaultProtocolText = (key:string, ownersMeeting: OwnersMeetingProtocol, value = undefined) => {
    switch (key) {
    case 'meetingDurationProtocolText':
      return replaceAllVariableWithValues(defaultProtocolTexts.meetingDurationProtocolText, value || {
        startTime: ownersMeeting.startTime ? moment(ownersMeeting.startTime, 'HH:mm').format('HH:mm') : '00:00',
        endTime: ownersMeeting.endTime ? moment(ownersMeeting.endTime, 'HH:mm').format('HH:mm') : '00:00',
      });
    case 'meetingLeaderProtocolText': {
      let meetingLeaderRole = '';
      if (ownersMeeting.meetingLeaderRole) {
        meetingLeaderRole = roleOptions.filter((role: OptionInterface) => role.value === ownersMeeting.meetingLeaderRole)[0].label;
      } else if (roleOptions && roleOptions[0]) {
        meetingLeaderRole = roleOptions[0].label;
      }
      const actualValue = value || {
        meetingLeader: managementCompanyEmployees.filter((employee: OptionInterface) => employee.value === ownersMeeting.meetingLeader)[0]?.label,
        meetingLeaderRole,
        managementCompany: selectedDisplayProperty.data.managementCompany?.companyName || '',
      };
      const defaultMeetingLeaderProtocolText = roleOptions[3].label === actualValue.meetingLeaderRole
        ? defaultProtocolTexts.meetingLeaderNoCompanyProtocolText
        : defaultProtocolTexts.meetingLeaderProtocolText;
      return replaceAllVariableWithValues(defaultMeetingLeaderProtocolText, actualValue);
    }
    case 'protocolLeaderProtocolText': {
      let minuteTakerRole = '';
      if (ownersMeeting.minuteTakerRole) {
        minuteTakerRole = roleOptions.filter((role: OptionInterface) => role.value === ownersMeeting.minuteTakerRole)[0].label;
      } else if (roleOptions && roleOptions[0]) {
        minuteTakerRole = roleOptions[0].label;
      }
      const actualValue = value || {
        minuteTaker: managementCompanyEmployees.filter((employee: OptionInterface) => ownersMeeting && employee.value === ownersMeeting.minuteTaker)[0]?.label,
        minuteTakerRole,
        managementCompany: selectedDisplayProperty.data.managementCompany?.companyName || '',
      };
      const defaultProtocolLeaderProtocolText = roleOptions[3].label === actualValue.minuteTakerRole
        ? defaultProtocolTexts.protocolLeaderNoCompanyProtocolText
        : defaultProtocolTexts.protocolLeaderProtocolText;
      return replaceAllVariableWithValues(defaultProtocolLeaderProtocolText, actualValue);
    }
    case 'agendaPointProtocolText':
      return replaceAllVariableWithValues(defaultProtocolTexts.agendaPointProtocolText, value || {
        invitationDate: ownersMeeting.date ? moment(ownersMeeting.created).format('DD.MM.YYYY') : moment(new Date()).format('DD.MM.YYYY'),
      });
    case 'votingRulesProtocolText':
      return replaceAllVariableWithValues(defaultProtocolTexts.votingRulesProtocolText, value || {
        votingScheme: votingSchemeOptions.filter((role: OptionInterface) => role.value === ownersMeeting.votingScheme)[0]?.label || tl(ownersMeetingProtocolTranslations.editPage.sections.protocolSetup.votingScheme.votingSchemeOptions.openVoting),
      });
    case 'votingRightProtocolText':
      return replaceAllVariableWithValues(defaultProtocolTexts.votingRightProtocolText, value || {
        votingRights: votingRightsOptions.filter((role: OptionInterface) => role.value === ownersMeeting.votingRights)[0]?.label || tl(ownersMeetingProtocolTranslations.editPage.sections.protocolSetup.votingRights.fields.byUnits),
      });
    case 'votingRightProtocolTextWithMea':
      return replaceAllVariableWithValues(defaultProtocolTexts.votingRightProtocolTextWithMea, value || {
        votingRights: votingRightsOptions.filter((role: OptionInterface) => role.value === ownersMeeting.votingRights)[0]?.label || tl(ownersMeetingProtocolTranslations.editPage.sections.protocolSetup.votingRights.fields.byUnits),
      });
    case 'votingRightProtocolTextHeadcount':
      return replaceAllVariableWithValues(defaultProtocolTexts.votingRightProtocolTextHeadcount, value || {
        votingRights: votingRightsOptions.filter((role: OptionInterface) => role.value === ownersMeeting.votingRights)[0]?.label || tl(ownersMeetingProtocolTranslations.editPage.sections.protocolSetup.votingRights.fields.byUnits),
      });
    default:
      return defaultProtocolTexts[key];
    }
  };

  const votingSchemeOptions = [{
    value: OwnersMeetingProtocolDtoVotingSchemeEnum.OPEN_VOTING,
    label: tl(ownersMeetingProtocolTranslations.editPage.sections.protocolSetup.votingScheme.votingSchemeOptions.openVoting),
  },
  {
    value: OwnersMeetingProtocolDtoVotingSchemeEnum.SECRET_VOTING,
    label: tl(ownersMeetingProtocolTranslations.editPage.sections.protocolSetup.votingScheme.votingSchemeOptions.secretVoting),
  },
  ];

  return {
    roleOptions,
    votingSchemeOptions,
    votingRightsOptions,
    getDefaultProtocolText,
  };
}
