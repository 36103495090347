import React, { useContext } from 'react';
import Button from '../../../../../../../elements/Buttons/Button/Button';
import { translations } from '../../../../../../../elements/Translation/translations';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import { HouseMoneySettlementDtoStatusEnum } from '../../../../../../../api/accounting';
import { useStateChangeButtons } from './useStateChangeButtons';

export default function StateChangeButtons({ messagesExist } : { messagesExist: boolean }): JSX.Element {
  const {
    settlementStatus,
    onMarkHouseMoneySettlementFinalized,
    onMarkHouseMoneySettlementPrepared,
    onSend,
    settlementId,
    onCheckMessages,
  } = useStateChangeButtons();
  const { tl } = useContext(LanguageContext);

  const sendButton = (
    <Button
      type="primary"
      className="right-side-button icon"
      onClick={onSend}
    >
      {tl(translations.pages.houseMoneySettlement.overview.send)}
    </Button>
  );

  const checkMessagesButton = (
    <Button
      type="primary"
      className="right-side-button icon"
      onClick={onCheckMessages}
    >
      {tl(translations.pages.houseMoneySettlement.overview.checkMessages)}
    </Button>
  );
  const prepareButton = (
    <Button
      type="primary"
      className="right-side-button icon"
      onClick={() => onMarkHouseMoneySettlementPrepared(settlementId)}
    >
      {tl(translations.pages.houseMoneySettlement.overview.prepare)}
    </Button>
  );
  const finalizeButton = (
    <Button
      type="primary"
      className="right-side-button icon"
      onClick={() => onMarkHouseMoneySettlementFinalized(settlementId)}
    >
      {tl(translations.pages.houseMoneySettlement.overview.finalize)}
    </Button>
  );

  return (
    <>
      {(settlementStatus === HouseMoneySettlementDtoStatusEnum.FINALIZED && !messagesExist) && sendButton}
      {(settlementStatus === HouseMoneySettlementDtoStatusEnum.FINALIZED && messagesExist) && checkMessagesButton}
      {(settlementStatus === HouseMoneySettlementDtoStatusEnum.PREPARED) && finalizeButton}
      {(settlementStatus === HouseMoneySettlementDtoStatusEnum.DRAFT) && prepareButton}
    </>
  );
}
