import { RouteDefinition } from 'components/RenderRoutes';
import WatermarkEditor from './pages/WatermarkEditor/WatermarkEditor';
import WatermarksList from './pages/WatermarksList/WatermarksList';

export const WatermarkRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/watermarks',
      component: WatermarksList,
      subRoutes: [
        {
          path: '/watermarks/create',
          type: 'overlay',
          component: WatermarkEditor,
        },
        {
          path: '/watermarks/edit/:watermarkId',
          type: 'overlay',
          component: WatermarkEditor,
        },
      ],
    },
  ],
};
