import { WkaProjectionDto } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import ColorDot, { DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { translations } from '../translations';

const getDotColor = (sent: boolean): DotColorEnum => (sent ? DotColorEnum.GREEN : DotColorEnum.GRAY);


const StatusCell = ({ record }: { record: WkaProjectionDto }) => {
  const { tl } = useContext(LanguageContext);
  const dotColor = getDotColor(record.sent);

  return (
    <ColorDot color={dotColor}>
      {record.sent ? tl(translations.wkaList.sent) : tl(translations.wkaList.notSent)}
    </ColorDot>
  );
};

export default StatusCell;
