import { Col, Row } from 'antd';
import SearchBar from 'storybook-components/SearchBar/SearchBar';
import { useDocumentSearchBar } from './useDocumentSearchBar';

const DocumentSearchBar = () => {
  const { searchInputValue, onChangeSearchValue } = useDocumentSearchBar();

  return (
    <Row>
      <Col span={24}>
        <SearchBar
          searchValue={searchInputValue}
          onSearch={onChangeSearchValue}
          withBottomMargin
        />
      </Col>
    </Row>
  );
};

export default DocumentSearchBar;
