import React, { useContext } from 'react';
import { Breadcrumb } from 'antd';
import { GlobalSearchContext, SuggestedFilter } from '../../services/GlobalSearchContext';
import { ActiveFilter } from './ActiveFilter/ActiveFilter';
import { BreadcrumbAnchor, BreadcrumbLink } from './BreadcrumbLink/BreadcrumbLink';
import './Breadcrumbs.scss';

export interface BreadcrumbInterface extends SuggestedFilter, BreadcrumbLink {
  breadcrumbType?: 'LINK' | 'FILTER'
  className?: string
}

export const Breadcrumbs = ({ editingEnabled }: { editingEnabled: boolean }) => {
  const { breadcrumbs } = useContext(GlobalSearchContext);
  return (
    <Breadcrumb className="Breadcrumbs">
      {breadcrumbs.map((breadcrumb: BreadcrumbInterface, index: number) => (
        <Breadcrumb.Item key={`Breadcrumb${index}`}>
          {breadcrumb.breadcrumbType === 'LINK'
            ? <BreadcrumbAnchor link={breadcrumb} />
            : <ActiveFilter filter={breadcrumb} editingEnabled={editingEnabled} />}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
