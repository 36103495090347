import { DOCUMENT_PREVIEW_PATH } from 'pages/DocumentPreview/routes';
import { MESSAGE_SENDING_PATH } from 'pages/MessageSendingPage/routes';
import { lazy } from 'react';
import MessageSendingPageWithProvider from 'pages/MessageSendingPage/MessageSendingPage';
import { RouteDefinition } from '../../components/RenderRoutes';
import EconomicPlanEditing from './EconomicPlanEditing';
import EconomicPlanPropertyList from './EconomicPlanPropertyList/EconomicPlanPropertyList';
import EconomicPlanList from './EconomicPlanList/EconomicPlanList';
import { EconomicPlanHouseMoney } from './EconomicPlanHouseMoney/EconomicPlanHouseMoney';
import EconomicPlanOverview from './EconomicPlanOverview/EconomicPlanOverview';
import { EconomicPlanRedirectPage } from './EconomicPlanRedirectPage/EconomicPlanRedirectPage';

const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));

const economicPlanGenericViewPath = '/economic-plans/:economicPlanId';
const economicPlanListPath = '/economic-plan/property/:propertyHrId';
const economicPlanAccountBasedViewPath = `${economicPlanListPath}/view/:economicPlanId`;
const economicPlanUnitBasedViewPath = `${economicPlanListPath}/unit-figures/:economicPlanId`;

export const getEconomicPlanViewPath = ({ economicPlanId }: { economicPlanId: string | number }) => (
  economicPlanGenericViewPath
    .replace(':economicPlanId', `${economicPlanId}`)
);

export const getEconomicPlanAccountBasedViewPath = ({ propertyHrId, economicPlanId }: { propertyHrId: string, economicPlanId: string | number }) => (
  economicPlanAccountBasedViewPath
    .replace(':propertyHrId', propertyHrId)
    .replace(':economicPlanId', `${economicPlanId}`)
);

export const getUnitBasedEconomicPlanViewPath = ({ propertyHrId, economicPlanId }: { propertyHrId: string, economicPlanId: string | number }) => (
  economicPlanUnitBasedViewPath
    .replace(':propertyHrId', propertyHrId)
    .replace(':economicPlanId', `${economicPlanId}`)
);

export const getEconomicPlanMessageSendingPath = ({ propertyHrId, economicPlanId }: { propertyHrId: string, economicPlanId: string | number }) => (
  `${economicPlanAccountBasedViewPath}${MESSAGE_SENDING_PATH}`
    .replace(':propertyHrId', propertyHrId)
    .replace(':economicPlanId', `${economicPlanId}`)
);

export const getEconomicPlanListPath = ({ propertyHrId }: { propertyHrId: string }) => (
  economicPlanListPath
    .replace(':propertyHrId', propertyHrId)
);


export const EconomicPlanRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/economic-plans/:economicPlanId',
      component: EconomicPlanRedirectPage,
      exact: true,
    },
    {
      path: '/economic-plan',
      component: EconomicPlanPropertyList,
      subRoutes: [
        {
          path: economicPlanListPath,
          component: EconomicPlanList,
          exact: false,
          type: 'overlay',
          subRoutes: [
            {
              path: economicPlanAccountBasedViewPath,
              component: EconomicPlanOverview,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${economicPlanAccountBasedViewPath}/edit`,
                  component: EconomicPlanEditing,
                  type: 'focus',
                },
                {
                  path: `${economicPlanAccountBasedViewPath}${MESSAGE_SENDING_PATH}`,
                  component: MessageSendingPageWithProvider,
                  type: 'overlay',
                  subRoutes: [
                    {
                      path: `${economicPlanAccountBasedViewPath}${MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                      component: DocumentPreviewPage,
                      type: 'overlay',
                    },
                  ],
                },
              ],
            },
            {
              path: `${economicPlanListPath}/create`,
              component: EconomicPlanEditing,
              exact: true,
              type: 'focus',
            },
            {
              path: `${economicPlanListPath}/unit-figures/:economicPlanId`,
              component: EconomicPlanHouseMoney,
              exact: true,
              type: 'overlay',
            },
          ],
        },
      ],
    },
  ],
};
