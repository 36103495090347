import * as React from 'react';
import { useContext } from 'react';
import './ErrorPage.scss';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';
import { OverlayContext } from '../../services/OverlayContext/OverlayContext';

export function ErrorPage({ clearError }: any): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);

  function onClickBack() {
    goBack();
  }

  return (
    <div className="ErrorPage">
      <div>
        {tl(translations.pages.errorPage.errorOccured)}
      </div>
      <div>
        {tl(translations.pages.errorPage.navigate)}
        {' '}
          &nbsp;
        <Button onClick={onClickBack}>
          {tl(translations.pages.errorPage.back)}
        </Button>
      </div>
      <div>
        {tl(translations.pages.errorPage.or)}
        {' '}
          &nbsp;
        <Button onClick={() => window.location.reload()}>
          {tl(translations.pages.errorPage.reload)}
        </Button>
      </div>
    </div>
  );
}
