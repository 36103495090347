import {
  useContext,
  useState,
} from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import { PdfProxyHtmlRenderDto } from 'data/pdfProxy';
import { showNotification } from 'lib/Notification';
import {
  OwnersMeetingProtocolContext,
} from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';
import { usePdfBlob } from 'services/print/usePdfBlob';
import { usePrintCss } from 'services/print/usePrintCss';
import { usePrintHtml } from 'services/print/usePrintHtml';

import { downloadByteArrayAsPdf } from '../../../../../../../lib/Utils';
import {
  translations as ownersMeetingProtocolTranslations,
} from '../../../../../translations';

const headerAndFooterStyling = `
<style>
  header, footer {
    font-family: 'IBM Plex Sans';
    font-size: 9px;
    line-height: 12px;
    color: #595959;
    width: 100%;
  }

  @media (orientation: portrait) {
    header, footer {
      margin-left: 23mm;
      margin-right: 12mm;
    }
  }

  @media (orientation: landscape) {
    header, footer {
      margin-left: 12mm;
      margin-right: 18mm;
    }
  }
</style>
`;

const getHeader = (headerLeft: string, headerRight: string, headerCenter: string, headerCenter2?: string) => (`
${headerAndFooterStyling}
<header style="color: #595959; font-size: 8px; line-height: 12px;">
        <span style="float: left; width: 0%;">
            ${headerLeft}
        </span>
        <span style="text-align: center; width: 100%;">
             ${headerCenter}
             <br />
             ${headerCenter2}
        </span>
        <span style="float: right; text-align: end; width: 0%;">
            ${headerRight}
        </span>
</header>`
);

// the classnames `pageNumber` and `totalPages` are used to inject values
// https://pptr.dev/next/api/puppeteer.pdfoptions.headertemplate/
const getFooter = (footerCenter: string) => (`
${headerAndFooterStyling}
<footer style="color: #595959; font-size: 8px; line-height: 12px;">
  <span style="float: left; text-align: center; width: 90%; margin-left: 5%;">
             ${footerCenter}
        </span>
  <span style="float: right; text-align: right; width: 5%;">
  <span class='pageNumber'></span>/<span class='totalPages'></span>
  </span>
</footer>`
);

export const useCreateSignatureListDocument = () => {
  const ownersMeetingProtocolContext:any = useContext(OwnersMeetingProtocolContext);

  if (ownersMeetingProtocolContext === undefined) {
    throw new Error('useCreateSignatureListDocument must be used within a OwnersMeetingProtocolContextProvider');
  }
  const {
    ownersMeeting,
  } = ownersMeetingProtocolContext;
  const { tl } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  const { getPrintCss } = usePrintCss();
  const headerCenter = `${ownersMeeting.data.propertyName ?? ''} - ${ownersMeeting.data.titleOfProtocol ?? ''}`;
  const headerCenter2 = ownersMeeting.data.meetingLocation ?? '';
  const { generatePdfBlob } = usePdfBlob();
  const { getPrintHtml } = usePrintHtml();

  const handleError = () => {
    showNotification({
      type: 'error',
      message: tl(ownersMeetingProtocolTranslations.notifications.errorCreatingDocument),
    });
  };
  const downloadDocument = (documentTitle: string) => {
    setLoading(true);
    getPrintCss()
      .then((css) => {
        const body:PdfProxyHtmlRenderDto = {
          displayHeaderFooter: true,
          html: getPrintHtml(),
          css,
          footerTemplate: getFooter(''),
          headerTemplate: getHeader('', '', headerCenter, headerCenter2),
        };
        return generatePdfBlob(body);
      })
      .then((pdf:any) => {
        downloadByteArrayAsPdf(documentTitle, pdf);
      }).catch(handleError)
      .finally(() => setLoading(false));
  };

  return { downloadDocument, loadingDocument: loading };
};
