import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../translations';
import DateInput, { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from '../../../elements/Inputs/DateInput/DateInput';
import { useOccurrences } from './useOccurrences';
import TextInput from '../../../elements/Inputs/TextInput/TextInput';
import DeleteButton from '../../../elements/FormElements/FormSection/DeleteButton';
import { OccurrenceValues } from './ManualExchangeContext';

export const useOccurrenceListColumns = () => {
  const { tl } = useContext(LanguageContext);
  const {
    onChange, deleteOccurrence, formValues, occurrenceValues,
  } = useOccurrences();


  const occurrenceColumns = translations.creationPage.sections.occurrence;
  return [{
    title: (tl(occurrenceColumns.bookingDate)).concat(' *'),
    dataIndex: 'bookingDate',
    render: (_: any, record: OccurrenceValues) => (
      <DateInput
        required
        value={record.bookingDate}
        onChange={(val) => {
          onChange(record.id, 'bookingDate', val);
        }}
        validationMessage={record.errors.bookingDate?.errorMessage}
        validationState={record.errors.bookingDate?.state}
        softMinDate={MIN_DATE_NORMAL}
        softMaxDate={MAX_DATE_NORMAL}
      />
    ),
  },
  {
    title: (tl(occurrenceColumns.bookingText)).concat(' *'),
    dataIndex: 'bookingText',
    render: (_: any, record: OccurrenceValues) => (
      <TextInput
        required
        value={record.bookingText}
        onChange={(val) => {
          onChange(record.id, 'bookingText', val);
        }}
        validationMessage={record.errors.bookingText?.errorMessage}
        validationState={record.errors.bookingText?.state}
      />
    ),
  },
  {
    title: (tl(occurrenceColumns.purpose)).concat(' *'),
    dataIndex: 'bankPurposeText',
    render: (_: any, record: OccurrenceValues) => (
      <TextInput
        required
        value={formValues?.bankAccount === -1 ? '' : record.bankPurposeText}
        disabled={formValues?.bankAccount === -1}
        onChange={(val) => {
          onChange(record.id, 'bankPurposeText', val);
        }}
        validationMessage={record.errors.bankPurposeText?.errorMessage}
        validationState={record.errors.bankPurposeText?.state}
      />
    ),
  },
  {
    title: '',
    render: (_: any, record: OccurrenceValues) => (
      <DeleteButton
        disabled={occurrenceValues.length < 2}
        onClick={() => deleteOccurrence(record.id)}
      />
    ),
  },
  ];
};
