import React from 'react';
import './ContactElement.scss';
import { getContactLabelName } from '../../../pages/Property/PropertyEditing/util/utils';
import { DropdownOptionElement } from '../DropdownOptionElement/DropdownOptionElement';
import { ContactLegacyDto } from '../../../api/accounting/models';
import { formatAddress } from '../../../lib/Utils';

interface ContactElementProps {
  contact: ContactLegacyDto,
}

export function ContactElement({ contact }: ContactElementProps): JSX.Element {
  const label = getContactLabelName(contact);
  const filteredAddresses = contact.addresses!.filter(address => address.forMail);
  const address = filteredAddresses.length > 0 ? filteredAddresses[0] : contact.addresses![0];
  const additionalLabel = formatAddress(address);

  return (
    <DropdownOptionElement label={label} additionalLabelInfo={additionalLabel} />
  );
}
