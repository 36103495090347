import { DocumentLegacyControllerApi } from 'api/document';
import { DocumentDto, DocumentDtoSourceTypeEnum } from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import FileSaver from 'file-saver';
import { showNotification } from 'lib/Notification';
import { formatDate, ISO_DATE_FORMAT } from 'lib/Utils';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useState } from 'react';
import { downloadModalTranslations } from './translations';

export const useDocumentDownloadModal = ({
  selectedDocumentId,
  documents,
  startLoading,
  stopLoading,
  onChangeVisible,
}: {
    selectedDocumentId?: number,
    documents: DocumentDto[],
    startLoading: () => void,
    stopLoading: () => void,
    onChangeVisible: (v: boolean) => void,
}) => {
  const { tl } = useContext(LanguageContext);
  const [merged, setMerged] = useState<boolean>(false);
  const [withOverview, setWithOverview] = useState<boolean>(false);

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));

  const onDownload = () => {
    if (merged || !selectedDocumentId) {
      onDownloadMerged();
    } else {
      onDownloadSingle();
    }
  };

  const onChangeMerged = v => setMerged(v);

  const onChangeWithOverview = v => setWithOverview(v);

  const onDownloadSingle = () => {
    startLoading();
    documentControllerApi.downloadUsingGET1Raw({ documentId: selectedDocumentId })
      .then((documentByteArr: any) => {
        documentByteArr?.raw?.blob()
          .then((blob: Blob) => {
            const name = singleDocumentFileName();
            FileSaver.saveAs(blob, name);
          });
      })
      .catch((error) => {
        console.log({ error });
        showDownloadErrorNotification();
      })
      .finally(() => {
        onChangeVisible(false);
        stopLoading();
      });
  };

  const onDownloadMerged = () => {
    const documentIds = withOverview ? documents?.map(d => d.id) : documents?.filter(d => d.contractId !== undefined).map(d => d.id);
    if (!_.isEmpty(documentIds)) {
      startLoading();
      documentControllerApi.downloadConcatenatedUsingGETRaw({ documentIds })
        .then((resp: any) => {
          resp?.raw?.blob()
            .then((blob: Blob) => new Response(blob).arrayBuffer())
            .then((arrayBuff) => {
              const pdf = new Blob([arrayBuff], { type: 'application/pdf' });
              const name = mergedDocumentFileName();
              FileSaver.saveAs(pdf, name);
            });
        })
        .catch((error) => {
          console.log({ error });
          showDownloadErrorNotification();
        })
        .finally(() => {
          onChangeVisible(false);
          stopLoading();
        });
    } else {
      showDownloadErrorNotification();
      onChangeVisible(false);
    }
  };

  const getSourceName = (sourceType) => {
    switch (sourceType) {
    case DocumentDtoSourceTypeEnum.ECONOMIC_PLAN:
      return 'wirtschaftspläne';
    case DocumentDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT:
      return 'hausgeldabrechnungen';
    case DocumentDtoSourceTypeEnum.SPECIAL_CONTRIBUTION:
      return 'sonderumlagen';
    default:
      return 'dokumente';
    }
  };

  const mergedDocumentFileName = () => {
    const sourceType = documents?.[0]?.sourceType;
    const propertyIdInternal = documents?.[0]?.propertyHrId ?? '';
    if (merged) {
      return `${formatDate(moment(), ISO_DATE_FORMAT)}_${propertyIdInternal}_Gesamt- und Einzel${getSourceName(sourceType)}.pdf`;
    }
    return `${formatDate(moment(), ISO_DATE_FORMAT)}_${propertyIdInternal}_Einzel${getSourceName(sourceType)}.pdf`;
  };

  const singleDocumentFileName = () => {
    const selectedDoc = documents.find(d => d.id === selectedDocumentId);
    const propertyIdInternal = selectedDoc?.propertyHrId ?? '';
    const documentName = selectedDoc?.name ?? '';
    return `${formatDate(moment(), ISO_DATE_FORMAT)}_${propertyIdInternal}_${documentName}.pdf`;
  };

  const showDownloadErrorNotification = () => {
    showNotification({
      key: 'fileDownloadError',
      message: tl(downloadModalTranslations.downloadError),
      type: 'error',
    });
  };

  return {
    merged,
    withOverview,
    onChangeMerged,
    onChangeWithOverview,
    onDownload,
  };
};
