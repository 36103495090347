import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Column } from 'elements/SmartTable/SmartTable';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { Table, Tooltip } from 'antd';
import Amount from 'storybook-components/typography/Amount/Amount';
import { formatDate } from 'lib/Utils';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { orderOpenBalancesTranslations } from '../../translations';
import { DunningFeeCell } from '../components/DunningFeeCell/DunningFeeCell';
import { OpenTransactionsCell } from '../components/ContractBalanceTable/components/OpenTransactionsCell/OpenTransactionsCell';
import OpenAmountCell from '../components/OpenAmountCell/OpenAmountCell';
import { useOrderOpenBalancesSelectionContext } from './OrderOpenBalancesContext';

export const useOrderOpenBalancesColumns = (
  WIDTH_COL_1: number,
  WIDTH_COL_2: number,
  WIDTH_COL_3: number,
  WIDTH_COL_4: number,
) => {
  const { tl } = useContext(LanguageContext);
  const { setExpandedRowKeys } = useOrderOpenBalancesSelectionContext('useOrderOpenBalancesColumns');

  const onClickWrapper = (record) => {
    setExpandedRowKeys((prev) => {
      if (prev.includes(record.propertyId)) {
        return prev.filter(key => key !== record.propertyId);
      }
      return [...prev, record.propertyId];
    });
  };

  return [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.property),
      dataKey: 'propertyName',
      className: 'column-padding-medium',
      defaultWidth: WIDTH_COL_1,
      render: (_, record) => (
        <Tooltip title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.openContractAccounts),
      dataKey: 'debtorBalancesGrouped',
      defaultWidth: WIDTH_COL_2,
      render: (value: Array<any>) => <Amount>{value?.length}</Amount>,
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.openBalance),
      dataKey: 'filteredPropertyBalance',
      className: 'column-align-right column-padding-medium',
      defaultWidth: WIDTH_COL_3,
      render: (val, record) => (<OpenAmountCell filteredBalance={record.filteredPropertyBalance} todaysBalance={record.todaysPropertyBalance} />),
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.dunningFeeNet),
      dataKey: 'dunningFeeNet',
      defaultWidth: WIDTH_COL_4,
      render: (_, record: any) => (<DunningFeeCell accountCode={record.accountCode} propertyId={record.propertyId} contractId={record.contractId} />),
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.openTransactions),
      dataKey: 'propertyId',
      sortable: false,
      render: (_, record) => (<OpenTransactionsCell propertyId={record.propertyId} propertyIdInternal={record.propertyIdInternal} />),
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.administrator),
      dataKey: 'propertyManager',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.accountant),
      dataKey: 'propertyAccountant',
      sortable: false,
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.lastDunningNote),
      dataKey: 'maximumLastDunningDate',
      render: val => (val ? formatDate(val) : '—'),
      sortable: false,
    },
  ] as Column[];
};
