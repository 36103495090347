import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { ICONS } from 'components/icons';
import './NavigationTiles.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router';
import Heading from 'storybook-components/typography/Heading/Heading';
import { NavigationTileItem } from './interfaces';
import { translations } from '../../translations';

export const NavigationTiles: React.FC<{ tileItems: NavigationTileItem[] }> = ({ tileItems = [] }) => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  return (
    <div className="navigation-tiles row">
      {tileItems.map(item => (
        <div className="navigation-item col-xs-3" key={item.key}>
          <Button
            className="navigation-item-button"
            onClick={() => {
              history.push(item.url);
            }}
            {...item.buttonProps}
          >
            <div className="icon-wrapper">
              <Icon component={ICONS[item.icon]} />
              {
                item.tooltip
                && <div className="tooltip">{tl(translations.navigationTiles[item.tooltip])}</div>
              }
            </div>
            <Heading level={3} className="navigation-item-title">
              {item.title ?? tl(translations.navigationTiles[item.key])}
            </Heading>
          </Button>
        </div>
      ))}
    </div>

  );
};
