import { lazy } from 'react';

const UnitSetEditor = lazy(() => import('./UnitSetEditor'));

export const UNIT_SET_CREATE_PATH = '/unit-set/create';
export const UNIT_SET_EDIT_PATH = '/unit-set/:unitSetId';

export const getUnitSetEditPath = (unitSetId: string | number) => `/unit-set/${unitSetId}`;

export const UNIT_SET_COMPONENT = UnitSetEditor;
