import {
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  PropertyDisplayDtoAdministrationTypeEnum,
  UnitContractProjectionDto,
  UnitContractProjectionDtoTypeEnum,
  UnitLegacyDto,
} from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  DATE_FORMAT,
 DATE_PLACEHOLDER, formatDate,
} from 'lib/Utils';
import _ from 'lodash';
import moment from 'moment';
import { CurrentAssignment } from 'pages/PdfPageAssigner/services/interfaces';
import { OptionProps } from 'rc-select/lib/Option';

import {
  PdfPageAssignerDataContext,
  PdfPageAssignerSelectedPagesContext,
  PdfPageAssignerUpdatersContext,
} from '../../../services/PdfPageAssignerContext';
import { translations } from '../../../translations';
import PdfPageAssignerActionBarSelectOption
  from '../components/PdfPageAssignerActionBarSelectOption';

const compareOptions = (a, b) => {
  if (a.entity.unitRank !== b.entity.unitRank) {
    return a.entity.unitRank - b.entity.unitRank;
  }

  if (!a.entity.usagePeriodStart) {
    // if contract A doesn't have a start date then we consider A < B
    return -1;
  }

  if (!b.entity.usagePeriodStart) {
    // if contract B doesn't have a start date then we consider A > B
    return 1;
  }

  return moment(a.entity.usagePeriodStart).isBefore(moment(b.entity.usagePeriodStart)) ? -1 : 1;
};


export const usePdfPageAssignerActionBar = () => {
  const { tl } = useContext(LanguageContext);
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);
  const pdfPageAssignerSelectedPagesContext = useContext(PdfPageAssignerSelectedPagesContext);
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);

  // Because Property option is a button, we must manually control the openness of the select
  // in order to be able to close it when we click the property.
  const [dropdownOpen, setDropdownOpen] = useState(false);


  if (pdfPageAssignerDataContext === undefined || pdfPageAssignerSelectedPagesContext === undefined || pdfPageAssignerUpdatersContext === undefined) {
    throw new Error('usePdfPageAssignerActionBar must be used within a PdfPageAssignerContextProvider');
  }

  const selectedPages = pdfPageAssignerSelectedPagesContext;
  const { setSelectedPages, setCurrentAssignments } = pdfPageAssignerUpdatersContext;
  const {
    units,
    contractsWithinDateRange,
    contractsOutsideOfDateRange,
    property,
    loading,
  } = pdfPageAssignerDataContext;


  /**
   * if contractType === 'TENANT' then set unitId and `contractId` fields
   * if contractType === 'OWNER' then set unitId and leave `contractId` as undefined
   */
  const onSelectUnitOrContract = (contractId: number, opt: OptionProps) => {
    const matchingContract = contractsWithinDateRange.data.find(c => c.unitContractId === contractId);
    /**
     * `matchingContract` can be undefined for example if the WEG doesn't have an active contract
     * for a unit. In that case we get that data from the additional Option object data.
     */
    const unitId = matchingContract?.unitId
      // optionobject is put on the Option in PdfPageAssignerActionBar
      ?? (opt.optionobject as typeof optionList[number]).entity.unitId;

    setCurrentAssignments(prev => [
      ...prev,
      ...selectedPages.map(page => ({
        originalDocumentId: page.documentId,
        page: page.pageNumber,
        unitId,
        ...(matchingContract.type === UnitContractProjectionDtoTypeEnum.TENANT ? { contractId } : {}),
      } as CurrentAssignment)),
    ]);

    onUnselectAll();
  };


  const onSelectProperty = () => {
    setCurrentAssignments(prev => [
      ...prev,
      ...selectedPages.map(page => ({
        originalDocumentId: page.documentId,
        page: page.pageNumber,
        // leave contractId and unitId undefined => assigned to property
      } as CurrentAssignment)),
    ]);
    onUnselectAll();

    // manually close the select dropdown because we've not actually selected an option but clicked a button
    setDropdownOpen(false);
  };

  const formatUsagePeriodDate = (date: string | undefined) => {
    if (!date) return DATE_PLACEHOLDER;

    return formatDate(new Date(date), DATE_FORMAT);
  };

  const mapUnitWithoutContractToSelectOption = (unit: UnitLegacyDto) => {
    const contractName = tl(translations.actionBar.missingContract);

    return {
      value: undefined,
      label: `${unit.unitNrSharingDeclaration} ${contractName} ${DATE_PLACEHOLDER} - ${DATE_PLACEHOLDER}`,
      entity: {
        usagePeriodStart: DATE_PLACEHOLDER,
        usagePeriodEnd: DATE_PLACEHOLDER,
        contractName,
        unitNrSharingDeclaration: unit.unitNrSharingDeclaration,
        unitId: unit.id,
        unitRank: unit.unitRank,
      },
      customBody: (
        <PdfPageAssignerActionBarSelectOption
          unitNr={unit.unitNrSharingDeclaration}
          contract={contractName}
          contractType={null}
          usagePeriodStart={DATE_PLACEHOLDER}
          usagePeriodEnd={DATE_PLACEHOLDER}
          errors={{ contract: true }}
        />
      ),
    };
  };

  const mapContractToSelectOption = (
    contract: UnitContractProjectionDto,
    opts?: { dateInvalid?: boolean },
  ) => {
    const contractName: string = contract.isVacant ? tl(translations.pagePreview.vacancy) : contract.mailingContact.name;
    const contractType: string = tl(translations.actionBar.contractType[contract.type]);
    const usagePeriodStart = formatUsagePeriodDate(contract.startDate);
    const usagePeriodEnd = formatUsagePeriodDate(contract.endDate);

    return {
      value: contract.unitContractId,
      label: `${contract.unitNrSharingDeclaration} ${contractName} ${usagePeriodStart} - ${usagePeriodEnd}`,
      entity: {
        usagePeriodStart,
        usagePeriodEnd,
        contractName,
        unitNrSharingDeclaration: contract.unitNrSharingDeclaration,
        unitId: contract.unitId,
        unitRank: contract.unitRank,
      },
      customBody: (
        <PdfPageAssignerActionBarSelectOption
          unitNr={contract.unitNrSharingDeclaration}
          contract={contractName}
          contractType={contractType}
          usagePeriodStart={usagePeriodStart}
          usagePeriodEnd={usagePeriodEnd}
          errors={{ timePeriod: opts?.dateInvalid }}
        />
      ),
    };
  };

  const optionList = useMemo(() => {
    if (!property.data?.id || _.isEmpty(units.data)) return [];
    if (_.isEmpty(contractsWithinDateRange.data) && _.isEmpty(contractsOutsideOfDateRange.data)) return [];

    const unitsWithoutActiveContracts = units.data.filter((u) => {
      if (contractsWithinDateRange.data.find(c => c.unitId === u.id) || contractsOutsideOfDateRange.data.find(c => c.unitId === u.id)) {
        /**
         * If this unit has a corresponding contract already loaded then don't add another entry for it.
         */
        return false;
      }
      return true;
    });

    const options = [
      ...contractsWithinDateRange.data.map(contract => mapContractToSelectOption(contract)),
      ...contractsOutsideOfDateRange.data.map(contract => mapContractToSelectOption(contract, { dateInvalid: true })),
    ];

    /**
     * We only show units without active contracts when administration type = "WEG".
     */
    if (property?.data?.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG) {
      options.push(...unitsWithoutActiveContracts.map(unit => mapUnitWithoutContractToSelectOption(unit)));
    }

    return options.sort(compareOptions);
  }, [property.data, units.data, contractsWithinDateRange.data, contractsOutsideOfDateRange.data]);

  const filterOption = (input: any, option: any) => {
    const lowerCaseInput = input.toLowerCase();
    const optionStateValue = optionList.filter(o => o?.value === option?.value)[0];

    if (!optionStateValue) {
      return false;
    }

    const { entity } = optionStateValue;
    return (
      entity.unitNrSharingDeclaration?.toLowerCase().startsWith(lowerCaseInput)
      || entity.contractName?.toLowerCase().includes(lowerCaseInput)
      || entity.usagePeriodStart?.includes(lowerCaseInput)
      || entity.usagePeriodEnd?.includes(lowerCaseInput)
    );
  };

  const onUnselectAll = () => {
    setSelectedPages([]);
  };

  const onDropdownVisibleChange = (visible: boolean) => setDropdownOpen(visible);

  return {
    loading,
    optionList,
    filterOption,
    dropdownOpen,
    onDropdownVisibleChange,
    onSelectUnitOrContract,
    onUnselectAll,
    selectedPages,
    setSelectedPages,
    addressConcatenation: property.data?.addressConcatenation,
    onSelectProperty,
  };
};
