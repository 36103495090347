import {
  PortalLink,
} from 'storybook-components/TicketIntegrationSection/components/PotalLink/PortalLink';
import {
  INTEGRATION_APPS,
  TICKET_COLUMN_TYPE,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  TicketIntegrationSection,
} from 'storybook-components/TicketIntegrationSection/TicketIntegrationSection';

import { useEtg24TicketColumns } from './useEtg24TicketColumns';
import {
  useEtg24TicketIntegrationSection,
} from './useEtg24TicketIntegrationSection';

export const Etg24ContactTicketSection = ({
  connectionId,
  contactId,
} : {connectionId: number,
        contactId: number}) => {
  const {
    errorMessage, loading, onChangeSearchTerm, issues, etg24Link, timestamp,
  } = useEtg24TicketIntegrationSection(connectionId, TICKET_COLUMN_TYPE.CONTACT, { contactId });
  const { columns } = useEtg24TicketColumns({ type: TICKET_COLUMN_TYPE.CONTACT });

  const suffix = (
    <PortalLink
      type={TICKET_COLUMN_TYPE.CONTACT}
      timestamp={timestamp}
      portal={INTEGRATION_APPS.ETG24}
      link={etg24Link}
    />
  );

  return (
    <TicketIntegrationSection
      columns={columns}
      errorMessage={errorMessage}
      loading={loading}
      onChangeSearchTerm={onChangeSearchTerm}
      datasource={issues}
      portal={INTEGRATION_APPS.ETG24}
      suffix={suffix}
    />
  );
};
