import { RouteDefinition } from 'components/RenderRoutes';
import { AnalyticsDashboard } from './AnalyticsDashboard/AnalyticsDashboard';
import { EmbeddedPage } from './EmbeddedPage/EmbeddedPage';

export const AnalyticsRoutes: RouteDefinition = {
  subRoutes: [
    {

      path: '/analytics',
      component: AnalyticsDashboard,
      exact: true,
      subRoutes: [
        {
          path: '/analytics/:resourceParam/:resourceIdParam',
          component: EmbeddedPage,
        },
      ],
    },
  ],
};
