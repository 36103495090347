import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../../elements/Translation/translations';
import { economicPlanOverviewTranslations } from '../economicPlanOverviewTranslations';

export const UseUnitResultsTableColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(economicPlanOverviewTranslations.unitResultsTable.headers.unit),
      dataKey: 'unit',
      className: 'unit',
      defaultWidth: '20%',
    },
    {
      title: '',
      dataKey: 'accountName',
      defaultWidth: '25%',
    },
    {
      title: tl(economicPlanOverviewTranslations.unitResultsTable.headers.previousAmount),
      dataKey: 'amountPrevious',
      defaultWidth: '20%',
    },
    {
      title: tl(economicPlanOverviewTranslations.unitResultsTable.headers.fromDate),
      dataKey: 'fromDate',
      defaultWidth: '15%',
    },
    {
      title: tl(economicPlanOverviewTranslations.unitResultsTable.headers.newAmount),
      dataKey: 'newUnitAmount',
      defaultWidth: '20%',
    },
  ];
};
