import { useEpostCountryName } from './useEpostCountryName';
import { useGetContactById } from './useGetContactById';

export const useRecipientAddressLines = (recipientContactId: number) => {
  const { getCountryName } = useEpostCountryName();

  const { contact: contractMailingContact } = useGetContactById(recipientContactId);

  const mailingContactName = contractMailingContact?.addresses[0]?.recipient || '';
  const mailingContactAdditionalAddress = [contractMailingContact?.addresses[0]?.additionalAddress1,
  contractMailingContact?.addresses[0]?.additionalAddress2].map(a => a || '').join(' ');

  const mailingContactStreetAndNumber = [
    contractMailingContact?.addresses[0]?.street,
    contractMailingContact?.addresses[0]?.number,
  ].filter(Boolean).join(' ');
  let mailingContactCountry = getCountryName(contractMailingContact?.addresses[0]?.country);
  // do not display Deutschland
  mailingContactCountry = mailingContactCountry === 'Deutschland' ? '' : mailingContactCountry;
  const mailingContactPostalCodeAndCity = [
    contractMailingContact?.addresses[0]?.postalCode,
    contractMailingContact?.addresses[0]?.city,
  ].filter(Boolean).join(' ');

  const recipientAddressLines = [
    mailingContactName,
    mailingContactAdditionalAddress,
    mailingContactStreetAndNumber,
    mailingContactPostalCodeAndCity,
    mailingContactCountry,
  ];

  return recipientAddressLines;
};
