import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import _, { isNil } from 'lodash';
import { formatDate } from 'lib/Utils';
import { nanoid } from 'nanoid';
import { Moment } from 'moment';
import { ValidationErrors, ValidationErrorValue } from 'lib/interfaces/ValidationErrorInterface';
import {
  ContractCreationDtoTypeEnum, PropertyLegacyDtoVatEligibilityTypeEnum, UnitContractProjectionDtoTypeEnum, UnitContractProjectionDtoVatRelevanceEnum,
} from 'api/accounting';
import { useAllContractsListWithinPropertyEditingContext } from 'pages/Property/PropertyEditing/services/useAllContractsListWithinPropertyEditingContext';
import { getConflictingContracts, UnitContractDates } from 'services/UnitContractsList/useUnitContractUtils';
import { Alert } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import { useEtg24ContractsContext } from 'pages/UnitContractHistory/services/Etg24UnitContractsContext';
import {
  ContractDetailsContext, InitialContractContext, SelectedContactsContext,
} from './UnitContractEditorContext';
import { translations } from '../translations';


const getContractDate = (date: Moment | undefined) => (
  date ? formatDate(date!, 'YYYY-MM-DD') : undefined
);


export const useContractCoreInformationValidation = (contractType: ContractCreationDtoTypeEnum, isVacant: boolean, propertyVatEligibilityType: PropertyLegacyDtoVatEligibilityTypeEnum | undefined) => {
  const contractDetailsContext = useContext(ContractDetailsContext);
  const selectedContactsContext = useContext(SelectedContactsContext);
  const initialUnitContractContext = useContext(InitialContractContext);
  const { allContractsList } = useAllContractsListWithinPropertyEditingContext();

  const { tl } = useContext(LanguageContext);
  if (contractDetailsContext === undefined || selectedContactsContext === undefined
    || initialUnitContractContext === undefined) {
    throw new Error('useWarningMessages must be used within a UnitContractEditorContextProvider');
  }

  const { selectedContacts } = selectedContactsContext;
  const { contractDetails, unitDetails } = contractDetailsContext;

  const { initialUnitContract } = initialUnitContractContext;

  const { etg24ConnectionWithConflictUIEnabled } = useEtg24ContractsContext('useContractCoreInformationValidation');


  const getContractDateValidationErrors = () => {
    const { startDate, endDate } = contractDetails;
    const errors: ValidationErrors = {};

    if (startDate && endDate && endDate.isBefore(startDate)) {
      const message: ValidationErrorValue = {
        state: 'error',
        message: tl(translations.validation.endDateBeforeStartDateError),
      };

      errors.startDate = message;
      errors.endDate = message;
    }

    return errors;
  };

  const isContractVatRelevanceAffectingVatEligibilityShare = () => {
    if (propertyVatEligibilityType !== PropertyLegacyDtoVatEligibilityTypeEnum.AREA || initialUnitContract.unitContractId === undefined) return false;

    // if VAT relevance changed from NOT relevant to any kind of relevant or viceversa
    const currentVatRelevance = contractDetails.vatRelevance as unknown as UnitContractProjectionDtoVatRelevanceEnum;
    return initialUnitContract.vatRelevance !== currentVatRelevance && (initialUnitContract.vatRelevance === UnitContractProjectionDtoVatRelevanceEnum.NOT_RELEVANT || currentVatRelevance === UnitContractProjectionDtoVatRelevanceEnum.NOT_RELEVANT);
  };

  const getWarningMessagesForContractSaveModal = () => {
    // check if existing contract is being updated
    if (initialUnitContract.unitContractId !== undefined) {
      const messages = [];
      const updatedFields = [];
      const actuallyChangedMandateUsages = contractDetails.directDebitMandateUsages.filter(ddmu => ddmu.currentState !== ddmu.originalState);
      const initialContactIds = initialUnitContract.contacts?.map(c => c.contactId) ?? []; // `?? []` because for vacancies there are no contacts
      const newContactIds = selectedContacts?.filter(sc => sc.contactId) // for vacancies there are no contacts
        .map(sc => sc.contactId);

      const contactsDiff = _.xor(initialContactIds, newContactIds);

      if (!_.isEmpty(contactsDiff)) {
        if (contractType === ContractCreationDtoTypeEnum.TENANT) {
          messages.push(tl(translations.warnings.noTenantChange));
        } else {
          messages.push(tl(translations.warnings.noOwnerChange));
        }
      }

      // start date, end date, bank account, dd mandate
      // vacancies do not have bank acocunt, mandate, rent & vat relevance is handled separately
      if (!isVacant) {
        if (initialUnitContract.startDate !== getContractDate(contractDetails.startDate)) {
          updatedFields.push(tl(translations.warnings.startDate));
        }

        if (initialUnitContract.endDate !== getContractDate(contractDetails.endDate)) {
          updatedFields.push(tl(translations.warnings.endDate));
        }


        if (initialUnitContract.bankAccountId !== contractDetails.bankAccountId) {
          updatedFields.push(tl(translations.warnings.bankAccount));
        }

        if (!_.isEmpty(actuallyChangedMandateUsages)) {
          updatedFields.push(tl(translations.warnings.directDebitMandate));
        }

        if (!_.isEmpty(updatedFields)) {
          messages.push(`${updatedFields.join(', ')}`);
        }

        // end date for rent plans
        if (initialUnitContract.endDate !== getContractDate(contractDetails.endDate)
      && contractType === ContractCreationDtoTypeEnum.TENANT && !isVacant) {
          messages.push(tl(translations.warnings.rentPlanUpdate));
        }

        if (isContractVatRelevanceAffectingVatEligibilityShare()) {
          messages.push(tl(translations.warnings.vatRelevanceUpdate));
        }
      }

      return !_.isEmpty(messages) ? (
        <>
          <ul>
            {messages.map(m => (
              <li key={nanoid()}>
                {m}
              </li>
            ))}
          </ul>
          <p>{tl(translations.warnings.noPostingOrBankOrderUpdateWarning)}</p>
        </>
      ) : undefined;
    }

    return undefined;
  };

  const getWarningMessageForConflictingContract = () => {
    // do not show overlap warning for property owner contract
    if (contractType === ContractCreationDtoTypeEnum.PROPERTY_OWNER) {
      return undefined;
    }

    const contractToValidateForConflict: UnitContractDates = {
      startDate: getContractDate(contractDetails.startDate),
      endDate: getContractDate(contractDetails.endDate),
    };


    const contractsForWarnings = allContractsList.data.filter(
      contract => (initialUnitContract.unitContractId === undefined || initialUnitContract.unitContractId !== contract.unitContractId)
      && (contract.unitId === unitDetails.id
        && (contract.type === (contractType as unknown as UnitContractProjectionDtoTypeEnum)
            || initialUnitContract.type === contract.type)),
    );

    /*
      check if start and end date of current contract is causing any conflicts
       with contracts of the same unit and same type
    */
    const conflictingContracts = getConflictingContracts(contractToValidateForConflict, contractsForWarnings);

    const showEtg24ExportInfo = !isNil(etg24ConnectionWithConflictUIEnabled.data);

    return !_.isEmpty(conflictingContracts) ? (
      <>
        <p>{tl(translations.warnings.contractCausingOverlap)}</p>
        <ul>
          {conflictingContracts.map(contract => (
            <li key={nanoid()}>
              {contract.mailingContact.name || tl(translations.warnings.vacancyLabel)}
            </li>
          ))}
        </ul>
        {showEtg24ExportInfo
          && (
            <Alert
              message={tl(translations.warnings.etg24ExportInfo.overlappingContractsWarning)}
              type="info"
              showIcon
              icon={<Icon component={ICONS.info} />}
            />
          )
        }
      </>
    ) : undefined;
  };


  return {
    getWarningMessagesForContractSaveModal,
    getContractDateValidationErrors,
    getWarningMessageForConflictingContract,
    isContractVatRelevanceAffectingVatEligibilityShare,
  };
};
