import React, { useMemo } from 'react';
import useSmartTable from '../../../../../../elements/SmartTable/useSmartTable';
import SmartTable from '../../../../../../elements/SmartTable/SmartTable';
import { useTotalAmountsOverviewColumns } from '../useTotalAmountsOverviewColumns';
import './ReserveFundsOverviewCard.scss';

interface ReserveFundsProps {
  dataSource: any[],
  loading: boolean,
}

export default function ReserveFundsOverviewCard(props: ReserveFundsProps): JSX.Element {
  const { dataSource, loading } = props;

  const toTableData = (data: any) => ({
    ...data,
    account: `${data.accountCode} ${data.accountName}`,
  });

  const reserveFunds = useMemo(() => (dataSource ? dataSource.map(toTableData) : []), [dataSource]);
  const table = useSmartTable({
    tableName: 'reserveFundsOverviewTable',
    columns: useTotalAmountsOverviewColumns(),
    dataSource: reserveFunds,
    contentLoading: loading,
    verticallyScrollable: false,
    rowKey: 'account',
  });


  return (
    <div className="ReserveFundsOverviewCard ">
      <SmartTable {...table} />
    </div>
  );
}
