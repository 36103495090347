import React, { Suspense, useContext } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { CSSTransition } from 'react-transition-group';

import './OverlayRoute.scss';
import { useOverlayContext } from 'services/OverlayContext/useOverlayContext';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import CurrentOverlayInfoProvider from './services/CurrentOverlayInfoContext';
import { OverlayContext, overlayTypes } from '../../services/OverlayContext/OverlayContext';
// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

interface OverlayRouteProps extends RouteProps {
  width?: string,
  addBreadcrumb?: boolean,
}

export const OverlayRoute: React.FC<OverlayRouteProps> = (routeProps) => {
  const { overlays } = useContext(OverlayContext);
  const {
    path, component, location, exact, width,
  } = routeProps;
  const { level, zIndex, lastFocusLevel } = useOverlayContext({
    path: path as string, type: overlayTypes.OVERLAY, addBreadcrumb: routeProps.addBreadcrumb, exact,
  });
  const history = useHistory();
  const Component = component as React.FC;

  const goBack = () => {
    const currentOverlayIndex = overlays.findIndex(ov => ov.path === routeProps.path);
    const prev = currentOverlayIndex > 0
      ? overlays[currentOverlayIndex - 1]
      // fallback value is back to dashboard
      : { url: '/dashboard', search: '' };

    history.push(`${prev.url}${prev.search}`);
  };

  /**
   * Math.max is needed because on page refresh the OverlayContext's initialization is slow,
   * so without Math.max initially it calculates this as a negative number, which causes the
   * overlay to appear off-screen, then it jumps to the correct position. The fix doesn't
   * work perfectly in case of refresh with 2+ overlays, but that requires more refactoring.
   */
  const overlayOffset = `${Math.max((level - lastFocusLevel - 2), 0) * 48}px`;

  return (
    <SentryRoute
      location={location}
      path={path}
      exact={exact}
    >
      {({ match }) => (
        <CSSTransition
          appear
          in={match != null}
          timeout={200}
          classNames="overlay-transition"
          unmountOnExit
        >
          <div
            className={`overlay-wrapper ${lastFocusLevel > 0 ? 'over-focus' : ''}`}
            style={{ zIndex, left: overlayOffset }}
          >
            <svg className="overlay-nav-darkening-new" />
            <div
              className="overlay-nav-link"
              style={{ width: width ? `calc(100vw - ${width})` : '4.8rem' }}
              onClick={goBack}
              tabIndex={-1}
              onKeyDown={() => {
              }}
              role="link"
            />
            <div className="overlay-page" style={{ width }}>
              <CurrentOverlayInfoProvider currentPathname={location.pathname} pathPattern={path}>
                <Suspense fallback={<LoadingScreen />}>
                  <Component />
                </Suspense>
              </CurrentOverlayInfoProvider>
            </div>
          </div>
        </CSSTransition>
      )}
    </SentryRoute>
  );
};
