import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import defaultSection from './defaultSection';
import { HouseMoneyTable } from '../../components/HouseMoneyTable/HouseMoneyTable';
import { HouseMoneySectionContextProvider } from '../../services/HouseMoneyContext';
import { economicPlanAccountEditingTranslations } from '../economicPlanAccountEditingTranslations';

interface useResultsSection4Props {
  economicPlan: any,
  economicPlanCreationData: any;
}

export default function useResultsSection4({
  economicPlan,
  economicPlanCreationData,
}: useResultsSection4Props) {
  const { tl } = useContext(LanguageContext);
  return {
    ...defaultSection({
      index: 3,
      openingIndex: 3,
      economicPlan,
      economicPlanCreationData,
    }),
    hideSaveButton: true,
    sectionTitle: tl(economicPlanAccountEditingTranslations.sections.results.sectionTitle),
    sectionId: 'results',
    onSubmit: () => Promise.resolve(),
    content: [{
      content: economicPlan.data?.id ? (
        <HouseMoneySectionContextProvider>
          <HouseMoneyTable />
        </HouseMoneySectionContextProvider>
      ) : (
        <div>{tl(economicPlanAccountEditingTranslations.sections.results.savingText)}</div>
      ),
    }],
  };
}
