import { ExtendedExchangeProjectionTypeEnum, InvoiceLegacyControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { navigateConcat } from 'navigation/History';
import { useContext } from 'react';
import { PostingTextsContext, ServiceCompanyAllocationValues, SetPostingTextsContext } from '../../../../services/ServiceCompanyAllocationContext';

export const usePostingTextsCell = () => {
  const setPostingTexts = useContext(SetPostingTextsContext);
  const postingTexts = useContext(PostingTextsContext);

  if (setPostingTexts === undefined || postingTexts === undefined) {
    throw new Error('usePostingTextsCell must be used within a ServiceCompanyAllocationContextProvider');
  }

  const onChangePostingText = (key: string, transactionId: number, value: string) => {
    setPostingTexts(prev => prev?.map(pt => (
      pt.key === key && pt.transactionId === transactionId
        ? { ...pt, postingText: value }
        : pt
    )));
  };

  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));


  const navigateToInvoice = (e, record: ServiceCompanyAllocationValues) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (record.type === ExtendedExchangeProjectionTypeEnum.INVOICE) {
      const invoiceId = record.planIds?.[0];
      invoiceControllerApi.getInvoiceHrIdByIdUsingGET({ invoiceId })
        .then((hrId) => {
          navigateConcat(`/invoice/${hrId}`, { openSection: 2 });
        });
    }
  };

  return {
    postingTexts,
    onChangePostingText,
    navigateToInvoice,
  };
};
