import { useMemo } from 'react';
import { getPropertyAccountBalancePath } from 'pages/Account/routes';
import { useOrderOpenBalancesContext } from '../../../../services/OrderOpenBalancesContext';

export const useAccountNameCell = (accountCode: string, propertyHrId: string) => {
  const { accounts } = useOrderOpenBalancesContext('useAccountNameCell');

  const account = useMemo(
    () => accounts.data.find(a => a.code === accountCode && a.propertyHrId === propertyHrId),
    [accounts, accountCode, propertyHrId],
  );

  return {
    account,
    linkToAccountSheet: getPropertyAccountBalancePath(propertyHrId, accountCode),
  };
};
