export const downloadModalTranslations = {
  title: {
    en: 'Download document',
    de: 'Dokument herunterladen',
  },
  cancel: {
    en: 'Cancel',
    de: 'Abbrechen',
  },
  download: {
    en: 'Download',
    de: 'Herunterladen',
  },
  format: {
    en: 'Document format',
    de: 'Dokumentformat',
  },
  single: {
    en: 'Single',
    de: 'Einzeln',
  },
  merged: {
    en: 'Merged',
    de: 'Gesamt',
  },
  includeOverview: {
    en: 'Include property overview',
    de: 'Allgemeine Objektübersicht in das Gesamtdokument aufnehmen',
  },
  yes: {
    en: 'Yes',
    de: 'Ja',
  },
  no: {
    en: 'No',
    de: 'Nein',
  },
  downloadError: {
    en: 'Download failed',
    de: 'Download fehlgeschlagen',
  },
  documents: {
    en: 'Documents',
    de: 'Dokumente',
  },
  info: {
    en: 'The documents contain the address of the currently active contract.',
    de: 'Die Dokumente enthalten die Adresse des aktuell aktiven Vertrags.',
  },
  downloadDocuments: {
    en: 'Download documents',
    de: 'Dokumente herunterladen',
  },
};
