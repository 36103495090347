import { Modal } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import React, { useContext } from 'react';
import { translations } from '../../Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';

interface DeletionModalProps {
  visible: boolean,
  onClickCancel: () => void,
  onClickOk: () => void,
  loading?: boolean,
  title?: string,
  description?: string | React.ReactNode,
  deleteButtonText?: string,
  hideDeleteButton?: boolean,
}

export function DeletionModal(props: DeletionModalProps) {
  const {
    visible, onClickCancel, onClickOk, loading, title, description, deleteButtonText, hideDeleteButton,
  } = props;
  const { tl } = useContext(LanguageContext);
  return (
    <Modal
      visible={visible}
      title={title || tl(translations.elements.deletionModal.title)}
      onCancel={onClickCancel}
      footer={[
        <Button key="cancel" type="text" onClick={onClickCancel}>
          {tl(translations.elements.deletionModal.cancel)}
        </Button>,
        <span key="delete-button-wrapper">
          {!hideDeleteButton && (
            <Button
              key="delete"
              type="primary"
              onClick={onClickOk}
              loading={loading}
            >
              {deleteButtonText || tl(translations.elements.deletionModal.delete)}
            </Button>
          )}
        </span>,
      ]}
    >
      <p>{description || tl(translations.elements.deletionModal.description)}</p>
    </Modal>
  );
}

DeletionModal.defaultProps = {
  loading: false,
};
