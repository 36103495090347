import './ContactIntegrationsSection.scss';

import React from 'react';

import { Alert } from 'antd';
import { ContactLegacyDto } from 'api/accounting';
import { isEmpty } from 'lodash';
import {
  NoPortalAvailableMessage,
} from 'storybook-components/TicketIntegrationSection/components/NoPortalAvailableMessage/NoPortalAvailableMessage';

import { useContactIntegrations } from './services/useContactIntegrations';

export const ContactIntegrations = ({ contact }: { contact: ContactLegacyDto }) => {
  // one could check and display sg alternatively if none of the integrations work
  // for example a link to "settings" where an integration can be enabled
  const { integrations, loading } = useContactIntegrations(contact);

  if (isEmpty(integrations) && !loading) {
    return (<Alert message={<NoPortalAvailableMessage />} />);
  }

  return (
    <div className="ContactIntegrationsSection">
      {integrations}
    </div>
  );
};
