import React, { useContext } from 'react';
import SearchBar from 'storybook-components/SearchBar/SearchBar';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import { Col, Row, Table } from 'antd';
import { SPACE_SIZES } from 'lib/constants';
import { LanguageContext } from '../../../contexts/LanguageContext';
import Button from '../../../elements/Buttons/Button/Button';
import { recipientSelectorModalTranslations } from './translations';
import RecipientSummary from './components/Summary/RecipientSummary';
import { SelectedContractContact, useRecipientSelectorModal } from './useRecipientSelectorModal';
import './RecipientSelectorModal.scss';

export interface RecipientSelectorModalProps {
  propertyId: number,
  propertyHrId: string,
  contractContactIds: {contractId: number, contactId: number}[],
  selectedContractContactIds: {contractId: number, contactId: number}[],
  visible: boolean,
  subtitle: string,
  onCancel: () => void,
  onApply: (v: SelectedContractContact[]) => void,

}

export const RecipientSelectorModal = (props: RecipientSelectorModalProps) => {
  const {
    propertyId, propertyHrId, contractContactIds, selectedContractContactIds, subtitle, visible, onApply, onCancel,
  } = props;
  const { tl } = useContext(LanguageContext);

  const {
    datasource, searchValue, contractFilter, contractFilterOptions, onChangeSearchValue, onChangeContractFilter,
    columns, loading, selectedValues, rowSelection, expandedRowKeys, onExpand,
  } = useRecipientSelectorModal({
    propertyId, propertyHrId, selectedContractContactIds, contractContactIds, visible,
  });

  return (
    <ModalWithSubtitle
      title={tl(recipientSelectorModalTranslations.title)}
      subtitle={subtitle}
      onCancel={onCancel}
      className="RecipientSelectorModal"
      visible={visible}
      footer={[
        <Button key="cancel" type="text" onClick={onCancel}>
          {tl(recipientSelectorModalTranslations.cancel)}
        </Button>,
        <Button key="apply" type="primary" onClick={() => onApply(selectedValues)}>
          {tl(recipientSelectorModalTranslations.apply)}
        </Button>,
      ]}
    >
      <Row gutter={[SPACE_SIZES.small, SPACE_SIZES.small]}>
        <Col span={24}>
          <SelectInput
            inputClassName="input-small"
            label={tl(recipientSelectorModalTranslations.contactSelection)}
            value={contractFilter}
            options={contractFilterOptions}
            onChange={onChangeContractFilter}
          />
        </Col>
        <Col span={24}>
          <SearchBar searchValue={searchValue} onSearch={onChangeSearchValue} />
        </Col>
        <Col span={24}>
          <Table
            rowKey="rowKey"
            columns={columns}
            dataSource={datasource}
            pagination={false}
            scroll={{ y: 300 }}
            expandable={{
              expandedRowKeys,
              expandRowByClick: true,
              onExpand,
              expandIconColumnIndex: 2,
              indentSize: 0,
              expandIcon: () => null,
            }}
            rowSelection={rowSelection}
            loading={{
              indicator: <TableContentLoadingIndicator />,
              spinning: loading,
            }}
            summary={() => RecipientSummary({ nrOfRecipients: selectedValues?.length })}
          />
        </Col>
      </Row>
    </ModalWithSubtitle>
  );
};
