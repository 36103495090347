import moment from 'moment';
import { useContext, useMemo } from 'react';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { PropertyBankAccountProjectionDtoPropertyAccountTypesEnum } from 'api/accounting';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { useFindOverlappingEconomicPlans } from '../../services/useFindOverlappingEconomicPlans';

export const useEconomicPlanActivationModal = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  const propertyListContext = useContext(PropertyListContext);

  if (economicPlanContext === undefined) {
    throw new Error('useEconomicPlanActivationModal must be used within an EconomicPlanContext');
  }

  if (propertyListContext === undefined) {
    throw new Error('useEconomicPlanActivationModal must be used within a PropertyListProvider');
  }

  const { economicPlan } = economicPlanContext;
  const { selectedProperty } = useContext(PropertyListContext);

  const { findOverlappingEconomicPlans } = useFindOverlappingEconomicPlans();
  const { tl } = useContext(LanguageContext);


  return useMemo(() => {
    if (!economicPlan.data?.startDate) {
      return { listItems: [], hasHouseMoneyBankAccount: false };
    }

    const economicPlanStartDate = moment(economicPlan.data?.startDate);
    const hasHouseMoneyBankAccount = (selectedProperty.data.bankAccounts
      ?.filter(ba => ba.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE)) ?? [])
      .length > 0;

    const startOfNextMonth = moment().set('date', 1).add(1, 'month');
    const firstExchangeGenerationMonth = moment.max(economicPlanStartDate, startOfNextMonth);
    let activationText = tl(economicPlanTranslations.economicPlan.activationModal.economicPlanFutureActivationText)(economicPlanStartDate?.format('DD.MM.YYYY'));

    if (economicPlanStartDate.isBefore(moment())) {
      if (findOverlappingEconomicPlans().length > 0) {
        activationText = tl(economicPlanTranslations.economicPlan.activationModal.economicPlanCorrectionsActivationText)(economicPlanStartDate?.format('DD.MM.YYYY'));
      } else {
        activationText = tl(economicPlanTranslations.economicPlan.activationModal.economicPlanPastActivationText)(economicPlanStartDate?.format('DD.MM.YYYY'));
      }
    }


    let postingGenerationText;
    if (hasHouseMoneyBankAccount) {
      postingGenerationText = tl(economicPlanTranslations.economicPlan.activationModal.postingGenerationText)(firstExchangeGenerationMonth?.format('DD.MM.YYYY'));
    } else {
      postingGenerationText = tl(economicPlanTranslations.economicPlan.activationModal.missingHouseMoneyBankAccountListItem);
    }

    return {
      listItems: [
        ...activationText,
        postingGenerationText,
      ],
      hasHouseMoneyBankAccount,
    };
  }, [economicPlan.data?.startDate, selectedProperty.data.bankAccounts, findOverlappingEconomicPlans]);
};
