import React, { useContext, useEffect, useMemo } from 'react';
import './ResolutionRecordDocument.scss';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { useParams } from 'react-router';
import moment from 'moment';
import { ResolutionRecordContext } from 'contexts/ResolutionRecordContext';
import { translations } from 'elements/Translation/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { ICONS } from 'components/icons';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { DATE_FORMAT, formatDate } from 'lib/Utils';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import { OverlayContext } from '../../../services/OverlayContext/OverlayContext';

export default function ResolutionRecordDocument(): JSX.Element {
  const resolutionRecordContext: any = useContext(ResolutionRecordContext);
  const propertyListContext: any = useContext(PropertyListContext);
  const { goBack } = useContext(OverlayContext);
  const { tl } = useContext(LanguageContext);
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { selectedProperty, setSelectedPropertyHrId } = propertyListContext;

  const url = resolutionRecordContext.getPropertyResolutionRecordDocumentPath();

  useEffect(() => {
    if (propertyHrId) {
      setSelectedPropertyHrId(propertyHrId);
    }
  }, [propertyHrId]);

  const fileName = useMemo(() => {
    if (selectedProperty?.data?.propertyIdInternal) {
      return `${formatDate(moment(), DATE_FORMAT)}_${selectedProperty?.data?.propertyIdInternal}_Beschlusssammlung`;
    }
    return `${formatDate(moment(), DATE_FORMAT)}_Beschlusssammlung`;
  }, [selectedProperty.data]);

  return (
    <div className="ResolutionRecordDocument">
      <div className="top-actionbar">
        <Button
          type="text"
          className="back-button"
          onClick={goBack}
        >
          <Icon component={ICONS.arrowLeft} />
          {tl(translations.pages.resolutionRecordEditing.back)}
        </Button>
      </div>
      <div className="pdf-container">
        <SimplePdfViewer url={url} filename={fileName} renderTextLayer />
      </div>
    </div>
  );
}
