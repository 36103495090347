import {
  useContext, useEffect, useState,
} from 'react';
import backend from 'backend_api';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { downloadByteArrayAsPdf } from 'lib/Utils';
import { usePdfJsLib } from 'lib/usePdfJsLib';
import { usePdfUtils } from 'services/usePdfUtils';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentApi } from 'api/public';
import { translations } from '../../Translation/translations';


declare let pdfjsLib: any;


export interface UsePdfViewerArgs {
  /**
   * `url` used for files that have been persisted in our database
   */
  url?: string,
  /**
   * `base64Url` used for files that have only been uploaded into the memory
   * in the browser, not yet persisted in the database
   */
  base64Url?: string,
  /**
   * `documentId` used for files that have been persisted in our database & were loaded via public API
   */
  documentId?: number,
  /**
   * `url` used when downloading from the browser
   */
  filename: string,
  /**
   * In case of invoice loading sometimes we start loading the invoice, but then the OCR finishes
   * before the file loading finishes, and that means that the initial file will be deleted
   * (because it's overwritten by the OCR result - extended with text layer). And in that case
   * we don't want to show the error notification, and we must enable a way to do that.
   */
  customOnLoadCatch?: () => void,
}


export const usePdfViewer = ({
  url,
  base64Url,
  filename,
  documentId,
  customOnLoadCatch,
}: UsePdfViewerArgs) => {
  const { tl } = useContext(LanguageContext);


  const [pdf, setPdf] = useState(null);


  const { pdfJsLibLoaded } = usePdfJsLib();
  const { convertDataURIToBinary } = usePdfUtils();

  const { publicApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentApi(publicApiConfiguration('public'));

  useEffect(() => {
    if (!pdfJsLibLoaded) return;

    if (base64Url) {
      loadPdfByBase64Url();
    } else if (url) {
      loadPdfByUrl();
    } else if (documentId) {
      loadPdfByDocumentId();
    } else {
      // if none provided, make sure that previous pdf is not displayed
      setPdf(null);
    }
  }, [url, base64Url, documentId, pdfJsLibLoaded]);

  const handleError = (error) => {
    console.error(error);
    // make sure that previous pdf is not displayed
    setPdf(null);
    if (customOnLoadCatch) {
      customOnLoadCatch();
    } else {
      showNotification({
        key: 'loadPdfError',
        message: tl(translations.notifications.pdfViewer.failedToLoad),
        type: 'error',
      });
    }
  };

  const loadPdfByUrl = () => {
    if (!url || url.indexOf('undefined') !== -1) return;

    backend.getFile(url)
      .then((response: any) => {
        pdfjsLib.getDocument({ data: new Uint8Array(response) }).promise
          .then((responsePdf: any) => {
            setPdf(responsePdf);
          })
          .catch(handleError);
      })
      .catch(handleError);
  };

  const loadPdfByBase64Url = () => {
    const pdfAsArray = convertDataURIToBinary(base64Url);

    pdfjsLib.getDocument(pdfAsArray).promise
      .then((responsePdf: any) => {
        setPdf(responsePdf);
      })
      .catch(handleError);
  };

  const loadPdfByDocumentId = () => {
    documentControllerApi.downloadUsingGETRaw({ documentId })
      .then((resp: any) => {
        resp?.raw?.blob()
          .then((blob: Blob) => new Response(blob).arrayBuffer())
          .then((arrayBuff) => {
            pdfjsLib.getDocument({ data: new Uint8Array(arrayBuff) }).promise
              .then((responsePdf: any) => {
                setPdf(responsePdf);
              })
              .catch(handleError);
          })
          .catch(handleError);
      })
      .catch(handleError);
  };

  const onDownloadPdf = () => {
    pdf?.getData().then((res: any) => {
      downloadByteArrayAsPdf(`${filename}.pdf`, res);
    });
  };


  return { pdf, onDownloadPdf };
};
