import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  InvoiceLegacyControllerApi,
  InvoiceLegacyDto,
  InvoiceLegacyDtoStateEnum,
} from '../api/accounting';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import DEFAULT_DATA, { DefaultDataInterface } from '../lib/data';
import { showNotification } from '../lib/Notification';
import { OverlayContext } from '../services/OverlayContext/OverlayContext';
import { AuthContext } from './AuthContext';
import { LanguageContext } from './LanguageContext';

const PAGE_SIZE = 30;

export const InvoiceListContext: any = React.createContext({});

export default function InvoiceListProvider({ children }: any): JSX.Element {
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const [invoiceSort, setInvoiceSort] = useState({
    field: 'invoiceHrId',
    order: -1,
  });
  const invoiceSortRef: any = useRef();
  invoiceSortRef.current = invoiceSort;
  const [counterpartFilter, setCounterpartFilter] = useState<boolean>(false);

  const { tl } = useContext(LanguageContext);

  const defaultContext = {
    invoiceList: [],
    page: 0,
    lastPage: false,
  };

  const defaultInvoiceFilterState = {};

  const [invoiceListState, setInvoiceListState] = useState(DEFAULT_DATA<any>(defaultContext));
  const [invoiceFilterState, setInvoiceFilterState] = useState<any>(undefined);
  const [smartSearchFilterState, setSmartSearchFilterState] = useState({});
  const lastRequestTimestamp = useRef<number | null>(null);

  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));
  const { goBack } = useContext(OverlayContext);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadInvoiceList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [invoiceFilterState, invoiceSort]);


  const updateListState = (data: any) => {
    setInvoiceListState(invoiceListState.load({
      ...invoiceListState.data,
      ...data,
    }));
  };

  const addInvoiceToList = (invoice: any) => {
    const invoiceIndex = invoiceListState.data.invoiceList.findIndex(
      inv => inv.id === invoice.id,
    );
    let tempInvoices = [...invoiceListState.data.invoiceList];
    if (invoiceIndex === -1) {
      tempInvoices = [
        invoice,
        ...invoiceListState.data.invoiceList,
      ];
    } else {
      tempInvoices[invoiceIndex] = { ...invoice };
    }
    updateListState({ invoiceList: tempInvoices });
  };

  /**
   * Concats invoiceList2 to the end of invoiceList1.
   * If an invoice is present in both lists we only keep the one in the first list
   */
  const mergeInvoiceLists = (invoiceList1, invoiceList2) => {
    if (_.isEmpty(invoiceList1)) {
      return invoiceList2;
    }
    if (_.isEmpty(invoiceList2)) {
      return invoiceList1;
    }
    const list2WithoutList1Items = invoiceList2.filter(inv2 => !invoiceList1.some(inv1 => inv1.id === inv2.id));
    return invoiceList1.concat(list2WithoutList1Items);
  };

  const getInvoiceFilters = () => {
    const { created, updated } = invoiceFilterState ?? {};

    return {
      ...(invoiceFilterState ?? {}),
      created: created ? moment.utc(created) : undefined,
      updated: updated ? moment.utc(updated) : undefined,
    };
  };

  const onLoadInvoiceList = (resetPage: boolean = false, sort: any = invoiceSort): void => {
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;
    if (resetPage || invoiceListState.data.invoiceList.length === 0) {
      setInvoiceListState(invoiceListState.startLoading());
    }
    invoiceControllerApi.findFilteredInvoicesUsingGET({
      ...getInvoiceFilters(),
      page: resetPage ? 0 : invoiceListState.data.page,
      size: PAGE_SIZE,
      order: sort.order > 0 ? 'ASC' : 'DESC',
      sort: sort.field,
    })
      .then((response: any) => {
        // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        updateListState({
          invoiceList: resetPage ? response.content : mergeInvoiceLists(invoiceListState.data.invoiceList, response.content),
          page: resetPage ? 1 : invoiceListState.data.page + 1,
          lastPage: response.last,
        });
      })
      .catch((err) => {
        console.error(err);
        setInvoiceListState({
          ...invoiceListState.failed(),
          data: {
            ...invoiceListState.data,
            lastPage: true,
          },
        });
        showNotification({
          key: 'loadInvoiceListError',
          message: tl(translations.notifications.invoiceListContext.loadError.message),
          description: tl(translations.notifications.invoiceListContext.loadError.description),
          type: 'warning',
        });
      });
  };

  const translateInvoiceStates = (state: string) => {
    try {
      return tl(translations.pages.invoice.table.states[state]);
    } catch (e) {
      return state;
    }
  };

  const onClearInvoiceList = (): void => {
    updateListState({
      invoiceList: [],
      page: 0,
      lastPage: false,
    });
  };


  const onClearInvoiceListAndFilter = (): void => {
    setInvoiceFilterState(defaultInvoiceFilterState);
    onClearInvoiceList();
  };


  const setSortField = (field: string) => {
    const order = invoiceSortRef.current.field === field ? invoiceSortRef.current.order * (-1) : 1;
    setInvoiceSort({
      field,
      order,
    });
  };

  const onLoadInvoiceByInternalRefNr = async (invoiceInternalRefNr: string) => {
    const { invoiceList } = invoiceListState.data;
    if (invoiceList.filter((invoice: any) => invoice.refNr === invoiceInternalRefNr).length === 0) {
      backend.get(`${endpointUrls.INVOICE}/ref/${invoiceInternalRefNr}`, {})
        .then((response: any) => {
          invoiceList.push(response);
          updateListState({
            invoiceList,
          });
        })
        .catch(() => {
          showNotification({
            key: 'loanInvoiceByReferenceError',
            message: tl(translations.notifications.invoiceListContext.loadError.message),
            description: tl(translations.notifications.invoiceListContext.loadError.description),
            type: 'error',
          });
        });
    }
  };

  const getInvoiceByRefNr = (refNr: string) => {
    const { invoiceList } = invoiceListState.data;
    return invoiceList.filter((invoice: any) => invoice.refNr === refNr)[0];
  };

  const setInvoicesByHrIdToState = (invoiceHrIds: string[], state: InvoiceLegacyDtoStateEnum) => {
    setInvoiceListState((currentInvoiceListState: DefaultDataInterface<any>) => {
      let newInvoiceList;
      if (_.isEmpty(invoiceFilterState.state) || invoiceFilterState.state.includes(state)) {
        // update states
        newInvoiceList = currentInvoiceListState.data.invoiceList
          .map((invoice: InvoiceLegacyDto) => {
            const newInvoice = invoice;
            if (invoiceHrIds.includes(invoice.invoiceHrId!)) {
              newInvoice.state = state;
            }
            return newInvoice;
          });
      } else {
        // remove from list
        newInvoiceList = currentInvoiceListState.data.invoiceList
          .filter((invoice: InvoiceLegacyDto) => !invoiceHrIds.includes(invoice.invoiceHrId!));
      }

      return currentInvoiceListState.load({
        ...currentInvoiceListState.data,
        invoiceList: newInvoiceList,
      });
    });
  };

  const onRejectInvoices = (invoiceHrIds: string[]) => {
    backend.post(`${endpointUrls.INVOICE}/reject`, { invoiceHrIds })
      .then(() => {
        showNotification({
          key: 'rejectionSuccess',
          message: tl(translations.notifications.invoiceListContext.rejectionSuccess.message),
          type: 'success',
        });
        setInvoicesByHrIdToState(invoiceHrIds, InvoiceLegacyDtoStateEnum.NEW);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 422) {
          showNotification({
            key: 'rejectionError',
            message: tl(translations.notifications.invoiceListContext.rejectionError.message),
            description: tl(translations.notifications.invoiceListContext.rejectionError.description),
            type: 'error',
          });
        } else {
          showNotification({
            key: 'rejectionError',
            message: tl(translations.notifications.invoiceListContext.rejectionError.message),
            type: 'error',
          });
        }
      });
  };

  const updateDeletedInvoices = (invoiceIds: number[]) => {
    const tempInvoiceList = invoiceListState.data.invoiceList
      .filter((inv: InvoiceLegacyDto) => !invoiceIds.includes(inv.id!));
    updateListState({
      invoiceList: tempInvoiceList,
      page: invoiceListState.data.page,
      lastPage: invoiceListState.data.lastPage,
    });
  };

  const onDeleteDraftInvoices = (invoiceIds: number[], shouldNavigateBack?: boolean) => {
    invoiceControllerApi.deleteMultipleInvoicesUsingDELETE({ invoiceIds })
      .then((response) => {
        showNotification({
          key: 'deletionSuccess',
          message: tl(translations.notifications.invoiceListContext.deletionSuccess.message),
          type: 'success',
        });
        updateDeletedInvoices(invoiceIds);
        if (shouldNavigateBack) {
          goBack();
        }
      })
      .catch((err) => {
        if (err.status === 422) {
          showNotification({
            key: 'deletionError',
            message: tl(translations.notifications.invoiceListContext.deletionError.message),
            description: tl(translations.notifications.invoiceListContext.deletionError.description),
            type: 'error',
          });
        } else {
          showNotification({
            key: 'deletionError',
            message: tl(translations.notifications.invoiceListContext.deletionError.message),
            type: 'error',
          });
        }
      });
  };


  const onMarkDeleteInvoice = (invoiceId: number) => {
    invoiceControllerApi.markDeleteInvoiceUsingPOST({ invoiceId })
      .then((response) => {
        showNotification({
          key: 'deletionSuccess',
          message: tl(translations.notifications.invoiceListContext.deletionSuccess.message),
          type: 'success',
        });
        goBack();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 422) {
          showNotification({
            key: 'deletionError',
            message: tl(translations.notifications.invoiceListContext.deletionError.message),
            description: tl(translations.notifications.invoiceListContext.deletionError.description),
            type: 'error',
          });
        } else {
          showNotification({
            key: 'deletionError',
            message: tl(translations.notifications.invoiceListContext.deletionError.message),
            type: 'error',
          });
        }
      });
  };

  const onStopRecurringInvoice = (invoiceId: number) => {
    invoiceControllerApi.stopRecurringInvoiceUsingPOST({ invoiceId })
      .then((response) => {
        showNotification({
          key: 'stoppedSuccess',
          message: tl(translations.notifications.invoiceListContext.stoppedSuccess),
          type: 'success',
        });
        goBack();
      })
      .catch((err) => {
        showNotification({
          key: 'stoppedError',
          message: tl(translations.notifications.invoiceListContext.stoppedError),
          type: 'error',
        });
      });
  };

  const onCopyInvoice = (invoiceId: number) => {
    invoiceControllerApi.copyInvoiceUsingPOST({ invoiceId })
      .then((resp) => {
        addInvoiceToList({
          ...resp,
        });
        goBack();
        showNotification({
          key: 'stoppedSuccess',
          message: tl(translations.notifications.invoiceListContext.copySuccess),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'stoppedError',
          message: tl(translations.notifications.invoiceListContext.copyError),
          type: 'error',
        });
      });
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setInvoiceFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  return (
    <InvoiceListContext.Provider value={{
      ...invoiceListState,
      invoiceFilterState,
      invoiceSort,
      setSmartSearchFilterState,
      onSetDefaultFilterFromQueryParams,
      smartSearchFilterState,
      setInvoiceFilterState,
      onLoadInvoiceList,
      onLoadInvoiceByInternalRefNr,
      getInvoiceByRefNr,
      onClearInvoiceList,
      onClearInvoiceListAndFilter,
      onStopRecurringInvoice,
      setSortField,
      translateInvoiceStates,
      onRejectInvoices,
      onMarkDeleteInvoice,
      onCopyInvoice,
      onDeleteDraftInvoices,
      setInvoicesByHrIdToState,
      invoiceListLoading: invoiceListState.loading,
      sortField: invoiceSortRef.current.field,
      sortOrder: invoiceSortRef.current.order,
      counterpartFilter,
      setCounterpartFilter,
      addInvoiceToList,
    }}
    >
      {children}
    </InvoiceListContext.Provider>
  );
}


InvoiceListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
