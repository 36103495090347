import { useContext } from 'react';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { formatCurrency } from 'lib/Utils';
import { LanguageContext } from 'contexts/LanguageContext';
import { InvoiceEpoPaymentTypeEnum, StandingOrderDtoIntervalEnum } from 'api/accounting';
import Amount from 'storybook-components/typography/Amount/Amount';
import { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';
import { translations } from '../../translations';
import PaymentTargetInformation from './components/paymentTargetInformation/PaymentTargetInformation';
import defaultSection from '../defaultSection';

export default function usePaymentInstructionSection(canGenerateOrder: boolean) {
  const { tl } = useContext(LanguageContext);
  const invoiceEditingContext = useContext(InvoiceEditingContext);

  const accountOptions = (): any[] => {
    const options: any[] = [];
    if (invoiceEditingContext.property.data) {
      const { bankAccounts } = invoiceEditingContext.property.data;
      const displayedAccount: any[] = [];

      if (bankAccounts) {
        bankAccounts.forEach((bankAccount: any) => {
          const tempBankAccount = { ...bankAccount };
          displayedAccount.push(tempBankAccount);
        });
      }

      displayedAccount.forEach((bankAccount: any) => {
        options.push({
          value: bankAccount.bankAccountId,
          boxLabel: <Amount>{formatCurrency(bankAccount.balance)}</Amount>,
          label: `${bankAccount.accountName} - ${bankAccount.iban}`,
          secondaryLabel: bankAccount.balance >= invoiceEditingContext.data.invoice.totalGross ? tl(translations.invoiceEditing.sections.section4.subsection2.fields.sufficientFunds)
            : tl(translations.invoiceEditing.sections.section4.subsection2.fields.insufficientFunds),
        });
      });
    }

    return options;
  };

  const executionDate = (): any => [
    [{
      type: 'radioGroup',
      key: 'executionDateType',
      props: {
        label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.executionDate.label),
        options: [
          {
            value: 'ASAP',
            label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.executionDate.asap),
          },
          {
            value: 'date',
            label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.executionDate.date),
          },
        ],
      },
    },
    {
      type: 'date',
      key: 'invoicePayments[0].executionDate',
      props: {
        label: '',
        disabled: invoiceEditingContext.data.invoice.executionDateType !== 'date',
        softMinDate: MIN_DATE_NORMAL,
        softMaxDate: MAX_DATE_NORMAL,
      },
    },
    ],
  ];

  const paymentTargetInformation = [{
    sectionId: 'paymentTargetInformation',
    title: tl(translations.invoiceEditing.sections.section4.subsection0.title),
    content: (
      <PaymentTargetInformation />
    ),
  }];

  const orderTypes = [{
    value: InvoiceEpoPaymentTypeEnum.PAYMENT,
    label: tl(translations.invoiceEditing.sections.section4.subsection1.fields.paymentType.createNew),
  },
  {
    value: InvoiceEpoPaymentTypeEnum.STANDING_ORDER,
    label: tl(translations.invoiceEditing.sections.section4.subsection1.fields.paymentType.standingOrder),
  }];

  const noOrderTypes = [{
    value: InvoiceEpoPaymentTypeEnum.EXISTING,
    label: tl(translations.invoiceEditing.sections.section4.subsection1.fields.paymentType.existing),
  },
  {
    value: InvoiceEpoPaymentTypeEnum.RECURRING_NO_ORDER,
    label: tl(translations.invoiceEditing.sections.section4.subsection1.fields.paymentType.recurringNoOrder),
  }];

  const paymentOptions = canGenerateOrder ? orderTypes.concat(noOrderTypes) : noOrderTypes;

  const paymentTypeSection: Array<any> = [{
    sectionId: 'paymentType',
    title: tl(translations.invoiceEditing.sections.section4.subsection1.title),
    content: [
      [
        {
          type: 'radioGroup',
          key: 'paymentType',
          props: {
            label: '',
            options: paymentOptions,
          },
        },
      ],
    ],
  }];

  const accountSelectionSection: Array<any> = [{
    sectionId: 'accountSelection',
    title: tl(translations.invoiceEditing.sections.section4.subsection2.title),
    content: [
      [
        {
          type: 'currency',
          key: 'totalGross',
          props: {
            label: tl(translations.invoiceEditing.sections.section4.subsection2.fields.total),
            disabled: true,
            value: Math.abs(invoiceEditingContext.data.invoice.totalGross),
          },
        },
      ],
      [
        {
          type: 'radioGroupWithBox',
          key: 'bankAccountId',
          props: {
            label: '',
            options: accountOptions(),
            noOptionsMessage: tl(translations.invoiceEditing.sections.section4.subsection2.fields.noBankAccounts),
          },
        },
      ],
    ],
  }];
  const executionSection: Array<any> = [{
    sectionId: 'Execution',
    title: tl(translations.invoiceEditing.sections.section4.subsection4.title),
    content: [
      [
        {
          type: 'currency',
          key: 'totalGross',
          props: {
            label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.bruttoAmount),
            disabled: true,
            value: Math.abs(invoiceEditingContext.data.invoice.totalGross),
          },
        },
      ],
      [
        {
          type: 'text',
          key: 'invoicePayments[0].statement',
          props: {
            label: `${tl(translations.invoiceEditing.sections.section4.subsection4.fields.statement)}*`,
          },
        },
      ],
      ...executionDate(),
    ],
  }];

  const standingOrderExecutionSection: Array<any> = [{
    sectionId: 'StandingOrderExecution',
    title: tl(translations.invoiceEditing.sections.section4.subsection4.title),
    content: [
      [
        {
          type: 'text',
          key: 'invoiceStandingOrder.statement',
          props: {
            label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.statement),
            required: true,
          },
        },
        {
          type: 'select',
          key: 'invoiceStandingOrder.interval',
          props: {
            label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.interval),
            options: [
              {
                value: StandingOrderDtoIntervalEnum.MONTHLY,
                label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.intervalOptions.monthly),
              },
              {
                value: StandingOrderDtoIntervalEnum.BIMONTHLY,
                label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.intervalOptions.bimonthly),
              },
              {
                value: StandingOrderDtoIntervalEnum.QUARTERLY,
                label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.intervalOptions.quarterly),
              },
              {
                value: StandingOrderDtoIntervalEnum.FOURMONTHLY,
                label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.intervalOptions.fourmonth),
              },
              {
                value: StandingOrderDtoIntervalEnum.HALFYEARLY,
                label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.intervalOptions.halfYearly),
              },
              {
                value: StandingOrderDtoIntervalEnum.YEARLY,
                label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.intervalOptions.yearly),
              },
            ],
            required: true,
          },
        },
      ],
      [
        {
          type: 'date',
          key: 'invoiceStandingOrder.startDate',
          props: {
            label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.firstExecution),
            required: true,
            softMinDate: MIN_DATE_NORMAL,
            softMaxDate: MAX_DATE_NORMAL,
          },
        },
        {
          type: 'date',
          key: 'invoiceStandingOrder.plannedEndDate',
          props: {
            label: tl(translations.invoiceEditing.sections.section4.subsection4.fields.lastExecution),
            softMinDate: MIN_DATE_NORMAL,
            softMaxDate: MAX_DATE_NORMAL,
          },
        },
      ],
    ],
  }];
  let content = [...paymentTargetInformation, ...paymentTypeSection];
  if (invoiceEditingContext.data.invoice.paymentType === InvoiceEpoPaymentTypeEnum.PAYMENT) {
    content = [...content, ...accountSelectionSection];
    content = [...content, ...executionSection];
  } else if (invoiceEditingContext.data.invoice.paymentType === InvoiceEpoPaymentTypeEnum.STANDING_ORDER) {
    content = [...content, ...accountSelectionSection];
    content = [...content, ...standingOrderExecutionSection];
  } else if (invoiceEditingContext.data.invoice.paymentType === InvoiceEpoPaymentTypeEnum.RECURRING_NO_ORDER) {
    content = [...content, ...standingOrderExecutionSection];
  }
  return {
    ...defaultSection(3),
    sectionTitle: tl(translations.invoiceEditing.sections.section4.title),
    sectionId: 'PaymentInstruction',
    content,
  };
}
