import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { translations } from 'elements/Translation/translations';
import './PropertyEconomicPlan.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { ExpandableCardsList } from 'elements/CustomElements/ExpandableCardsList/ExpandableCardsList';
import { formatCurrency } from 'lib/Utils';
import SummaryRow from 'elements/CustomElements/ExpandableCard/SummaryRow';
import { EconomicPlanDto } from 'api/accounting';
import { DefaultDataInterface } from 'lib/data';
import Amount from 'storybook-components/typography/Amount/Amount';
import PropertyExpensesCard
  from '../../../HouseMoneySettlement/pages/HouseMoneySettlementOverview/components/AccountBalancesCards/PropertyExpensesCard/PropertyExpensesCard';
import PropertyIncomeCard
  from '../../../HouseMoneySettlement/pages/HouseMoneySettlementOverview/components/AccountBalancesCards/PropertyIncomeCard/PropertyIncomeCard';
import ReserveFundsTable, { useRenderReserveFundsTotal } from '../../EconomicPlanAccountEditing/sections/ReserveFundsSection3/ReserveFundsTable';
import { RenderResultsTotal, ResultsTable } from '../UnitResultsTable/UnitResultsTable';
import TotalAmountTable from '../TotalAmountTable/TotalAmountTable';
import IncomeExpensesOverviewCard from './OverviewCards/IncomeExpenses/IncomeExpensesOverviewCard';
import ReserveFundsOverviewCard from './OverviewCards/ReserveFunds/ReserveFundsOverviewCard';
import useEconomicPlanOverview from '../services/useEconomicPlanOverview';

function useRenderPropertyExpensesIncomeTotal() {
  const { tl } = useContext(LanguageContext);
  const defaultTotalCost = { incomeTotal: 0, applicableTotal: 0, notApplicableTotal: 0 };
  const [totalCosts, setTotalCosts] = useState(defaultTotalCost);

  const {
    economicPlan,
  }: {
    economicPlan: DefaultDataInterface<EconomicPlanDto>,
  } = useEconomicPlanOverview();

  useEffect(() => {
    if (economicPlan.data && economicPlan.data.results) {
      const applicableTotal = economicPlan.data.results.applicableExpenses?.reduce((aggregator: any, expense: any) => aggregator + (expense.totalSum || 0), 0);
      const notApplicableTotal = economicPlan.data.results.notApplicableExpenses?.reduce((aggregator: any, expense: any) => aggregator + (expense.totalSum || 0), 0);
      const incomeTotal = economicPlan.data.results.income?.reduce((aggregator: any, income: any) => aggregator + (income.totalSum || 0), 0);
      setTotalCosts({ incomeTotal, applicableTotal, notApplicableTotal });
    }
  }, [economicPlan]);

  const summaryElements = [
    {
      title: tl(translations.pages.economicPlan.propertyAccountBalances.expenses.subTitles.applicableExpenses),
      value: <Amount>{formatCurrency(totalCosts.applicableTotal)}</Amount>,
    },
    {
      title: tl(translations.pages.economicPlan.propertyAccountBalances.expenses.subTitles.notApplicableExpenses),
      value: <Amount>{formatCurrency(totalCosts.notApplicableTotal)}</Amount>,
    },
    {
      title: tl(translations.pages.economicPlan.propertyAccountBalances.income.title),
      value: <Amount>{formatCurrency(totalCosts.incomeTotal)}</Amount>,
    }];

  return (
    <SummaryRow
      rightSideElements={summaryElements}
      title={tl(translations.pages.economicPlan.propertyAccountBalances.expenses.total)}
    />
  );
}

export default function PropertyEconomicPlan(props: { economicPlan: any }): JSX.Element {
  const { economicPlan } = props;
  const { tl } = useContext(LanguageContext);

  const convertReserveFund = (reserveFund: any) => ({
    ...reserveFund,
    account: `${reserveFund.accountCode}${reserveFund.accountName ? `-${reserveFund.accountName}` : ''}`,
    economicPlanDistributionMode: reserveFund.economicPlanDistributionMode,
    value: reserveFund.totalSum,
  });

  const reserveFundsDataSource = useMemo(() => {
    const reserveFunds = economicPlan.data && economicPlan.data.results && economicPlan.data.results.reserveFunds ? economicPlan.data.results.reserveFunds : [];
    return reserveFunds.map((reserveFund: any) => convertReserveFund(reserveFund));
  }, [economicPlan]);

  const totalAmountDataSource = useMemo(() => ({
    totalExpenses: economicPlan.data && economicPlan.data.results ? economicPlan.data.results.applicableExpenses.reduce((acc: any, expense: any) => acc + (expense.totalSum || 0), 0)
        + economicPlan.data.results.notApplicableExpenses.reduce((acc: any, expense: any) => acc + (expense.totalSum || 0), 0)
      : 0,
    totalIncome: economicPlan.data && economicPlan.data.results ? economicPlan.data.results.income.reduce((acc: any, expense: any) => acc + (expense.totalSum || 0), 0) : 0,
    totalReserveFunds: reserveFundsDataSource ? reserveFundsDataSource.reduce((acc: any, reserve: any) => (typeof reserve.totalSum === 'number' ? acc + (reserve.totalSum || 0) : acc), 0) : 0,
  }), [economicPlan]);

  const expandableCardGroups = [
    {
      groupName: tl(translations.pages.economicPlan.navigationGroups.overview),
      expandableCards: [
        {
          loading: economicPlan.loading,
          title: tl(
            translations.pages.economicPlan.propertyAccountBalances.expenses
              .title,
          ),
          children: (
            <div className="expenses-income">
              <PropertyExpensesCard
                applicableExpenses={
                  economicPlan.data && economicPlan.data.results
                    ? economicPlan.data.results.applicableExpenses
                    : []
                }
                notApplicableExpenses={
                  economicPlan.data && economicPlan.data.results
                    ? economicPlan.data.results.notApplicableExpenses
                    : []
                }
                labelsPrefix="economicPlan"
                showShares={false}
                loading={economicPlan.loading}
              />
              <div className="subtitle" id="income">
                {tl(
                  translations.pages.economicPlan.propertyAccountBalances.income
                    .title,
                )}
              </div>
              <PropertyIncomeCard
                income={
                  economicPlan.data && economicPlan.data.results
                    ? economicPlan.data.results.income
                    : []
                }
                labelsPrefix="economicPlan"
                loading={economicPlan.loading}
                showShares={false}
                addSubtotalRow
              />
            </div>
          ),
          category: 'expenses-income',
          navigationTitle: tl(
            translations.pages.economicPlan.propertyAccountBalances.expenses
              .title,
          ),
          summaryOnBottom: true,
          summary: useRenderPropertyExpensesIncomeTotal(),
          navigationSubElements: [
            {
              name: tl(
                translations.pages.economicPlan.propertyAccountBalances.expenses
                  .subTitles.applicableExpenses,
              ),
              elementId: 'applicableExpenses',
            },
            {
              name: tl(
                translations.pages.economicPlan.propertyAccountBalances.expenses
                  .subTitles.notApplicableExpenses,
              ),
              elementId: 'notApplicableExpenses',
            },
            {
              name: tl(
                translations.pages.economicPlan.propertyAccountBalances.income
                  .title,
              ),
              elementId: 'income',
            },
          ],
        },
        {
          loading: economicPlan.loading,
          title: tl(
            translations.pages.economicPlan.propertyAccountBalances.reserveFunds
              .title,
          ),
          children: (
            <ReserveFundsTable
              dataSource={reserveFundsDataSource}
              loading={economicPlan.loading}
            />
          ),
          category: 'reserve-funds',
          navigationTitle: tl(
            translations.pages.economicPlan.propertyAccountBalances.reserveFunds
              .title,
          ),
          summaryOnBottom: true,
          summary: useRenderReserveFundsTotal({
            dataSource: reserveFundsDataSource,
          }),
        },
        {
          loading: economicPlan.loading,
          title: tl(
            translations.pages.economicPlan.propertyAccountBalances.totalAmount
              .title,
          ),
          children: (
            <TotalAmountTable
              dataSource={totalAmountDataSource}
              loading={economicPlan.loading}
            />
          ),
          category: 'total-amount',
          navigationTitle: tl(
            translations.pages.economicPlan.propertyAccountBalances.totalAmount
              .title,
          ),
        },
        {
          loading: economicPlan.loading,
          title: tl(
            translations.pages.economicPlan.propertyAccountBalances.results
              .title,
          ),
          children: (
            <ResultsTable
              dataSource={
                economicPlan.data && economicPlan.data.results
                  ? economicPlan.data.results.unitResults
                  : []
              }
              loading={economicPlan.loading}
            />
          ),
          category: 'results',
          navigationTitle: tl(
            translations.pages.economicPlan.propertyAccountBalances.results
              .title,
          ),
          summaryOnBottom: true,
          summary: RenderResultsTotal({
            dataSource:
              economicPlan.data && economicPlan.data.results
                ? economicPlan.data.results.unitResults
                : [],
          }),
        },
      ],
    },
    {
      groupName: tl(
        translations.pages.economicPlan.propertyAccountBalances
          .totalAmountTitle,
      ),
      groupTitle: tl(
        translations.pages.economicPlan.propertyAccountBalances
          .totalAmountTitle,
      ),
      expandableCards: [
        {
          loading: economicPlan.loading,
          title: tl(
            translations.pages.economicPlan.propertyAccountBalances.expenses
              .title,
          ),
          children: (
            <IncomeExpensesOverviewCard
              income={
                economicPlan.data && economicPlan.data.results
                  ? economicPlan.data.results.income
                  : []
              }
              applicableExpenses={
                economicPlan.data && economicPlan.data.results
                  ? economicPlan.data.results.applicableExpenses
                  : []
              }
              notApplicableExpenses={
                economicPlan.data && economicPlan.data.results
                  ? economicPlan.data.results.notApplicableExpenses
                  : []
              }
              loading={economicPlan.loading}
            />
          ),
          category: 'income-expenses-overview',
          navigationTitle: tl(
            translations.pages.economicPlan.propertyAccountBalances.expenses
              .title,
          ),
        },
        {
          loading: economicPlan.loading,
          title: tl(
            translations.pages.economicPlan.propertyAccountBalances.reserveFunds
              .title,
          ),
          children: (
            <ReserveFundsOverviewCard
              dataSource={reserveFundsDataSource}
              loading={economicPlan.loading}
            />
          ),
          category: 'reserve-funds-overview',
          navigationTitle: tl(
            translations.pages.economicPlan.propertyAccountBalances.reserveFunds
              .title,
          ),
        },
      ],
    },
  ];
  return (
    <div className="PropertyEconomicPlan">
      <ExpandableCardsList
        headerChildren={<div />}
        expandableCardGroups={expandableCardGroups}
      />
    </div>
  );
}
