import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Configuration, DocumentLegacyControllerApi, FindDocumentsFilteredUsingGETSourceTypesEnum, PageOfDocumentProjectionDto } from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { Watermark } from 'pages/Watermarks/interfaces';
import { LanguageContext } from '../contexts/LanguageContext';
import DEFAULT_DATA from '../lib/data';
import { translations } from '../elements/Translation/translations';
import { SelectOption } from '../elements/Inputs/SelectInput/SelectInput';
import { showNotification } from '../lib/Notification';
import { parseWatermarks } from 'pages/Watermarks/services/utilParseWatermarkDocumentDto';
import SelectOptionWithTwoLines from 'storybook-components/inputs/select/SelectOptionWithTwoLines/SelectOptionWithTwoLines';


export default function useWatermarkOptions() {
  const [watermarksState, setWatermarksState] = useState(DEFAULT_DATA<Watermark[]>([]));
  const { tl } = useContext(LanguageContext);
  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document') as unknown as Configuration);

  const optionObjectFromWatermark = (watermark: Watermark): SelectOption => ({
    value: watermark.id,
    label: watermark.name,
    customBody: <SelectOptionWithTwoLines
    primaryText={watermark.name}
    secondaryText={watermark?.metaData?.isDefault ? tl(translations.notifications.watermarkHook.defaultWatermark): null}
  />,
  });

  const watermarkOptions: SelectOption[] = useMemo(() => (watermarksState.data ? watermarksState.data.map(optionObjectFromWatermark) : []), [watermarksState.data]);
  
  const defaultWatermarkId: number | undefined = useMemo(() => {
    const defaultWatermark = watermarksState.data?.find((w) => w.metaData?.isDefault);
    return defaultWatermark?.id ?? undefined;
  }, [watermarksState.data]);


  const onLoadWatermarks = () => {
    setWatermarksState(state => state.startLoading());
    documentControllerApi.findDocumentsFilteredUsingGET({ sourceTypes: FindDocumentsFilteredUsingGETSourceTypesEnum.WATERMARK })
      .then((response: PageOfDocumentProjectionDto) => {
        setWatermarksState(state => state.load(parseWatermarks(response.content)));
      })
      .catch((error: Error) => {
        console.error(error);
        setWatermarksState(state => state.failed());
        showNotification({
          key: 'loadWatermarksError',
          message: tl(translations.notifications.watermarkHook.error),
          type: 'error',
        });
      });
  };

  useEffect(() => {
    onLoadWatermarks();
  }, []);

  return {
    watermarkOptions,
    defaultWatermarkId
  };
}
