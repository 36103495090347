import { useEffect, useState } from 'react';
import { useParams } from 'react-router';


export const useAllocationSearchBar = (onSearch: (input: string) => void) => {
  const [inputValue, setInputValue] = useState('');
  const params = useParams<{ allocationGroupId: string | undefined }>();

  const onChange = (v) => {
    setInputValue(v);
    onSearch(v);
  };


  /**
   * if we fully allocate a incoming tx then we automatically open the next one;
   * if that one is also an incoming tx then the unit tab stays open and we need to reset the search string
   */
  useEffect(() => {
    if (params.allocationGroupId && inputValue) {
      setInputValue('');
      onSearch('');
    }
  }, [params.allocationGroupId]);

  return { onChange, inputValue };
};
