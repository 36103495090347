import React, {
  ReactNode,
  useEffect,
  useState,
} from 'react';

import {
  useConnections,
} from 'pages/PropertyPortalImport/service/useConnections';
import {
  CasaviContactTicketSection,
} from 'storybook-components/TicketIntegrationSection/portals/casavi/CasaviContactTicketSection';
import {
  Etg24ContactTicketSection,
} from 'storybook-components/TicketIntegrationSection/portals/etg24/Etg24ContactTicketSection';
import {
  FaciliooContactTicketSection,
} from 'storybook-components/TicketIntegrationSection/portals/facilioo/FaciliooContactTicketSection';

export const useContactIntegrations = (contact) => {
  const [integrations, setIntegrations] = useState<ReactNode[]>([]);
  const { connections, loading } = useConnections();

  useEffect(() => {
    const newIntegrations = [];
    connections.forEach((connection) => {
      if (!connection) return;
      switch (connection.appId) {
      case 1:
        newIntegrations.push(
          <FaciliooContactTicketSection
            key={`facilioo-connection-${connection.id}}`}
            connectionId={connection.id}
            contactId={contact.id}
          />,
        );
        break;
      case 5:
        newIntegrations.push(
          <CasaviContactTicketSection
            key={`casavi-connection-${connection.id}`}
            connectionId={connection.id}
            contactId={contact.id}
          />,
        );
        break;
      case 6:
        newIntegrations.push(
          <Etg24ContactTicketSection
            key={`etg24-connection-${connection.id}`}
            connectionId={connection.id}
            contactId={contact.id}
          />,
        );
        break;
      default:
      }
    });
    setIntegrations(ints => [...ints, ...newIntegrations]);
  }, [connections]);

  return {
    integrations,
    loading,
  };
};
