import { UnitHouseMoneySettlementDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import moment from 'moment';
import React, {
  createContext, ReactNode, useMemo, useState,
} from 'react';
import { ValidateStatuses } from '../../../../../lib/Utils';
import { TableDataSourceType } from './TableDataSourceType';

export interface AmountDirectionAndProcessingValues {
  unitId: number,
  amount: number | undefined,
  processing: 'PLATFORM' | 'SELF_PAYER',
  direction: 'incoming' | 'outgoing',
}

export interface FormValues {
  bookingDate: moment.Moment | undefined,
  bookingText: string | undefined,
  bankAccount: number | undefined,
}

interface ValidationErrorValue {
  state: (typeof ValidateStatuses)[number],
  errorMessage: string,
}

export interface ValidationErrors {
  [key: string]: ValidationErrorValue,
}

export const FORM_DEFAULT_VALUES: FormValues = {
  bookingDate: undefined,
  bookingText: undefined,
  bankAccount: undefined,
};

export interface PropertyAndEconomicYearDataType {
  propertyId: number | undefined,
  propertyName: string | undefined,
  propertyIdInternal: string | undefined,
  propertyHrId: string | undefined,
  economicYear: string | undefined,
}

const HouseMoneySettlementAndPropertyContext = createContext<
  | {
    propertyAndEconomicYearData: PropertyAndEconomicYearDataType,
    setPropertyAndEconomicYearData: React.Dispatch<React.SetStateAction<PropertyAndEconomicYearDataType | undefined>>
  } | undefined
>(undefined);

const AccountSelectionContext = createContext<
  | {
    formValues: FormValues,
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,

    bankAccountOptions: DefaultDataInterface<Array<{ label: string; value: number }>>,
    setBankAccountOptions: React.Dispatch<React.SetStateAction<DefaultDataInterface<Array<{ label: string; value: number }>>>>,

    validationErrors: ValidationErrors,
    setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
    setDirty: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  }
  | undefined
>(undefined);


const DatasourceContext = createContext<
  | {
    setAmountDirectionAndProcessingValues: React.Dispatch<React.SetStateAction<Array<AmountDirectionAndProcessingValues>|undefined>>,
    initializeDirectionAndProcessingValues:(unitOwners: TableDataSourceType[]) => void,
    tableDataSource: DefaultDataInterface<Array<TableDataSourceType>>,
    setTableDataSource: React.Dispatch<React.SetStateAction<DefaultDataInterface<Array<TableDataSourceType>>>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    loading: boolean|undefined,
    isDirty: boolean|undefined,
      }
      | undefined
      >(undefined);

const AmountDirectionAndProcessingValuesContext = createContext<AmountDirectionAndProcessingValues[] | undefined>(undefined);

const HouseMoneySettlementContextProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean|undefined>();
  const [isDirty, setDirty] = useState<boolean|undefined>(false);
  const [propertyAndEconomicYearData, setPropertyAndEconomicYearData] = useState<PropertyAndEconomicYearDataType | undefined>();
  const [formValues, setFormValues] = useState(FORM_DEFAULT_VALUES);
  const [bankAccountOptions, setBankAccountOptions] = useState(DEFAULT_DATA<Array<{ label: string; value: number }>>([]));
  const [amountDirectionAndProcessingValues, setAmountDirectionAndProcessingValues] = useState<Array<AmountDirectionAndProcessingValues> | undefined>(undefined);
  const [tableDataSource, setTableDataSource] = useState(DEFAULT_DATA<Array<TableDataSourceType>>(undefined));
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  const memoizedProcessingAndDistributionProviderValues = useMemo(() => {
    const initializeDirectionAndProcessingValues = (tableEntries: TableDataSourceType[]) => {
      const reduceInitialValue: [AmountDirectionAndProcessingValues[]] = [[]];

      const [initialAmountsToDistribute] = tableEntries.reduce((acc, unit) => {
        const [amountAndSelected] = acc;


        amountAndSelected.push({
          unitId: unit.unitId!,
          amount: unit.amount,
          direction: unit.direction,
          processing: unit.processing,
        });
        return acc;
      }, reduceInitialValue);

      setAmountDirectionAndProcessingValues(initialAmountsToDistribute);
    };

    return {
      setAmountDirectionAndProcessingValues, initializeDirectionAndProcessingValues, tableDataSource, setTableDataSource, setLoading, loading, isDirty,
    };
  }, [setAmountDirectionAndProcessingValues, tableDataSource, setTableDataSource, setDirty, loading, isDirty]);

  return (
    <HouseMoneySettlementAndPropertyContext.Provider value={{
      propertyAndEconomicYearData,
      setPropertyAndEconomicYearData,
    }}
    >
      <AccountSelectionContext.Provider value={{
        formValues,
        setFormValues,
        bankAccountOptions,
        setBankAccountOptions,
        validationErrors,
        setValidationErrors,
        setDirty,
      }}
      >
        <DatasourceContext.Provider value={memoizedProcessingAndDistributionProviderValues}>
          <AmountDirectionAndProcessingValuesContext.Provider value={amountDirectionAndProcessingValues}>
            {children}
          </AmountDirectionAndProcessingValuesContext.Provider>
        </DatasourceContext.Provider>
      </AccountSelectionContext.Provider>
    </HouseMoneySettlementAndPropertyContext.Provider>
  );
};

export {
  HouseMoneySettlementContextProvider,
  HouseMoneySettlementAndPropertyContext,
  AccountSelectionContext,
  DatasourceContext,
  AmountDirectionAndProcessingValuesContext,
};
