import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {
  AccountBalancesListContext,
} from 'contexts/AccountBalancesListContext';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import _ from 'lodash';
import {
  useHistory,
  useParams,
} from 'react-router';

import { ISO_DATE_FORMAT, getCurrentEconomicYear } from 'lib/Utils';
import moment from 'moment';
import {
  useGlobalSearch,
} from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import useSiteMap from '../../../services/useSiteMap/useSiteMap';
import MainPageHeader
  from '../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useAccountBalancesFilter, { ACCOUNT_BALANCE_DEFAULT_FILTERS } from './useAccountBalancesFilter';
import { UseAccountBalancesListColumns } from './UseAccountBalancesListColumns';

export default function AccountBalances(): JSX.Element {
  const accountBalancesListContext: any = useContext(AccountBalancesListContext);
  const history = useHistory();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  useGlobalSearch({
    key: 'accountBalances',
    filterProps: {
      availableFilters: useAccountBalancesFilter(),
      setFilter: (key: string, value: any) => accountBalancesListContext.setAccountBalancesFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);
          newFilter[key] = value || undefined;
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      filterState: accountBalancesListContext.accountBalancesFilterState,
      defaultFilters: ACCOUNT_BALANCE_DEFAULT_FILTERS,
      onSetDefaultFilterFromQueryParams: accountBalancesListContext.onSetDefaultFilterFromQueryParams,
    },
  });

  useEffect(() => () => {
    accountBalancesListContext.clearAccountBalancesListState();
    accountBalancesListContext.clearAccountBalancesFilterState();
  }, []);

  useEffect(() => {
    accountBalancesListContext.onLoadAccountBalancesList(true);
  }, [accountBalancesListContext.accountBalancesFilterState]);

  useEffect(() => {
    if (propertyHrId) {
      accountBalancesListContext.updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  const tableData = useMemo(() => accountBalancesListContext.data, [accountBalancesListContext.data]);
  const smartTable = useSmartTable({
    tableName: 'accountBalancesList',
    onRow: (record: any) => ({
      onClick: () => {
        const adjustedEconomicYear = getCurrentEconomicYear(record.economicYearStart);
        const startDateParam = moment(adjustedEconomicYear.economicYearStart).format(ISO_DATE_FORMAT);
        const endDateParam = moment(adjustedEconomicYear.economicYearEnd).format(ISO_DATE_FORMAT);
        history.push(`/account-balances/${record.propertyHrId}?startDate=${startDateParam}&endDate=${endDateParam}`);
      },
    }),
    columns: UseAccountBalancesListColumns(),
    dataSource: tableData,
    contentLoading: accountBalancesListContext.loading,
    infiniteScrollerProps: {
      hasMoreData: !accountBalancesListContext.lastPage,
      loadMoreData: accountBalancesListContext.onLoadAccountBalancesList,
    },
    rowKey: 'propertyHrId',
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'accountBalances',
    navItems: subcategorySwitcherItems.accounting,
  };

  return (
    <div className="Account-balances page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      <SmartTable {...smartTable} />
    </div>
  );
}
