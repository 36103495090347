import { PropertyLegacyControllerApi, PropertyUpdateDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import { showNotification } from 'lib/Notification';
import { useOrderOpenBalancesContext } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/OrderOpenBalancesContext';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { getInitialDunningFeeNet } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/useOrderOpenBalancesList';
import { DunningFee } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';

interface DunningFeeModalProps {
    accountCode: string;
    onCloseModal: () => void;
    dunningFeeLocalValue: DunningFee;
}


enum FeeChangeEnum {
  'contract',
  'property',
  'globalSettings'
}


export const useDunningFeeModal = ({
  accountCode, dunningFeeLocalValue, onCloseModal,
} :DunningFeeModalProps) => {
  const { tl } = useContext(LanguageContext);

  const {
    setDunningFees, propertyAndContractBalanceList,
  } = useOrderOpenBalancesContext('useDunningFeeModal');

  const { apiConfiguration } = useContext(AuthContext);

  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const [amount, setAmount] = useState(dunningFeeLocalValue?.dunningFeeNet);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAmount(dunningFeeLocalValue?.dunningFeeNet);
  }, [dunningFeeLocalValue?.dunningFeeNet]);


  const accountModalOptions = [{
    value: FeeChangeEnum.contract,
    label: tl(orderOpenBalancesTranslations.dunningFeeModal.radioGroupOptions.contract),
  },
  {
    value: FeeChangeEnum.property,
    label: tl(orderOpenBalancesTranslations.dunningFeeModal.radioGroupOptions.allContracts),
  },
  {
    value: FeeChangeEnum.globalSettings,
    label: tl(orderOpenBalancesTranslations.dunningFeeModal.radioGroupOptions.globalSettingsOfProperty),
  },
  ];

  const propertyModalOptions = [
    {
      value: FeeChangeEnum.property,
      label: tl(orderOpenBalancesTranslations.dunningFeeModal.radioGroupOptions.property),
    },
    {
      value: FeeChangeEnum.globalSettings,
      label: tl(orderOpenBalancesTranslations.dunningFeeModal.radioGroupOptions.globalSettingsOfProperty),
    },
  ];

  const applyTheFeeChangeInitialValue = () => {
    if (accountCode) {
      return accountModalOptions[0]?.value;
    }
    return propertyModalOptions[0]?.value;
  };

  const [applyTheFeeChange, setApplyTheFeeChange] = useState(applyTheFeeChangeInitialValue);


  const onChangeApplyTheFeeChange = (value) => {
    setApplyTheFeeChange(value);
  };

  const onChangeAmount = (value) => {
    setAmount(value);
  };


  const updateGloballySettingsProperty = () => {
    propertyControllerApi.getPropertyByIdUsingGET({ propertyId: dunningFeeLocalValue?.propertyId })
      .then((response) => {
        const propertyUpdateDto = {
          ...response,
          dunningFeeNet: amount,
          ...response.propertyAddress,
          id: dunningFeeLocalValue?.propertyId,
        } as unknown as PropertyUpdateDto;


        propertyControllerApi.updatePropertyUsingPUT({ propertyUpdateDto }).then((updatedProperty) => {
          setDunningFees(prevState => prevState.map((dunningFee) => {
            if (dunningFee.propertyId === updatedProperty.id) {
              const updatedPropertyBalances = propertyAndContractBalanceList.data.find(prp => prp.propertyId === dunningFee.propertyId);
              const debtorBalanceObject = updatedPropertyBalances.debtorBalancesGrouped.find(db => db.contractId === dunningFee.contractId);

              const balance = debtorBalanceObject?.filteredAccountBalance;
              const contractHasMandate = debtorBalanceObject?.contractHasMandate;
              const dunningFeeNet = getInitialDunningFeeNet(balance, contractHasMandate, updatedProperty.dunningFeeNet);

              return { ...dunningFee, dunningFeeNet };
            }

            return dunningFee;
          }));

          setLoading(false);
          onCancel();
        });
      }).catch((err) => {
        showNotification({
          key: 'updatePropetyFunningFee',
          message: tl(orderOpenBalancesTranslations.dunningFeeModal.updatePropertyError),
          type: 'error',
        });
        setLoading(false);
        console.error(err);
      });
  };


  const infoAlertIsDisplayed = applyTheFeeChange === FeeChangeEnum.globalSettings;

  const onApply = () => {
    setLoading(true);

    if (applyTheFeeChange === FeeChangeEnum.globalSettings) {
      updateGloballySettingsProperty();
      return;
    }

    setDunningFees((prevState) => {
      if (applyTheFeeChange === FeeChangeEnum.contract) {
        return prevState.map((dunningFee) => {
          if (accountCode && (dunningFee.accountCode === accountCode || dunningFee.accountCode?.startsWith(`${accountCode}/`))
          ) {
            return { ...dunningFee, dunningFeeNet: amount };
          }
          return dunningFee;
        });
      }

      if (applyTheFeeChange === FeeChangeEnum.property) {
        return prevState.map((dunningFee) => {
          if (dunningFee.propertyId === dunningFeeLocalValue?.propertyId) {
            const selectedProperty = propertyAndContractBalanceList.data.find(prp => prp.propertyId === dunningFee.propertyId);
            const debtorBalanceObject = selectedProperty.debtorBalancesGrouped.find(db => db.contractId === dunningFee.contractId);

            const balance = debtorBalanceObject?.filteredAccountBalance;
            const contractHasMandate = debtorBalanceObject?.contractHasMandate;

            const dunningFeeNet = getInitialDunningFeeNet(balance, contractHasMandate, amount);
            return { ...dunningFee, dunningFeeNet };
          }
          return dunningFee;
        });
      }

      return prevState;
    });

    setLoading(false);
    onCloseModal();
  };

  const onCancel = () => {
    onCloseModal();
    onChangeAmount(dunningFeeLocalValue?.dunningFeeNet);
  };

  return {
    onApply,
    onChangeAmount,
    infoAlertIsDisplayed,
    propertyHrId: dunningFeeLocalValue?.propertyHrId,
    options: accountCode ? accountModalOptions : propertyModalOptions,
    applyTheFeeChange,
    onChangeApplyTheFeeChange,
    amount,
    loading,
    onCancel,
  };
};
