import { useContext } from 'react';
import { DatasourceContext } from './HouseMoneySettlementCloseYearContext';

export const useHouseMoneySettlementCloseYearTableData = () => {
  const dataSourceContext = useContext(DatasourceContext);

  if (dataSourceContext === undefined) {
    throw new Error('useHouseMoneySettlementCloseYearTableData must be used within a HouseMoneySettlementCloseYearContextProvider');
  }

  const { tableDataSource, loading } = dataSourceContext;

  return { tableDataSource, loading };
};
