import Icon from '@ant-design/icons/lib/components/Icon';
import { Tooltip } from 'antd';
import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';

export const ContractBalanceTableRowSelectionCell = ({ record, originNode }) => {
  const { tl } = useContext(LanguageContext);

  if (record.contractDunningLevel === ContractProjectionDtoDunningLevelEnum.EXCLUDED) {
    if (record.isRootAccount) {
      return (
        <Tooltip
          placement="topRight"
          title={(
            <Link target="_blank" to={{ pathname: articleUrls.orderOpenBalances.excludedContract }}>
              {tl(orderOpenBalancesTranslations.innerTableColumns.actions.tooltip)}
            </Link>
          )}
        >
          <div style={{ display: 'flex' }}>
            <Icon component={ICONS.pause} />
          </div>
        </Tooltip>
      );
    }
    return null;
  }

  return originNode;
};
