import React, { useContext } from 'react';
import { Modal } from 'antd';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import Button from '../../Buttons/Button/Button';

export interface FileUploadProps {
  name: string,
  visible: boolean
  handleUpload: Function
  handleCancel?: Function
  upload: string
  title: string
  body: string
  accept?: string
  multiple?: boolean
}

export default function FileUploadModal(props: FileUploadProps): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const {
    name, visible, handleUpload, handleCancel, body, upload, title, accept, multiple,
  } = props;
  return (
    <div className="FileUploadModal">
      <Modal
        title={title}
        visible={visible}
        onCancel={() => handleCancel && handleCancel()}
        footer={[
          <Button key="cancel" onClick={() => handleCancel && handleCancel()}>
            {tl(translations.pages.payment.fileUploadModal.cancel)}
          </Button>,
          <Button key="upload" onClick={() => document.getElementById(`${name}-file-input`)!.click()} type="primary">
            {upload}
          </Button>,
        ]}
      >
        <div>
          {body}
        </div>
        <input
          id={`${name}-file-input`}
          className="file-input"
          style={{
            display: 'none',
          }}
          type="file"
          value=""
          onChange={(event: any) => handleUpload(event)}
          accept={accept}
          multiple={multiple}
        />
      </Modal>
    </div>
  );
}
