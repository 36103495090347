import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { ContractState } from 'pages/UnitContractHistory/interfaces';
import { PropertyDisplayDtoPropertyStateEnum } from 'api/accounting';
import { contactPropertiesTranslations } from '../translation';
import { ContactPropertiesDataSource } from '../services/ContactPropertiesContext';


interface Props {
  record:ContactPropertiesDataSource
}


const getDotColor = (state: ContractState|PropertyDisplayDtoPropertyStateEnum): DotColorEnum => {
  switch (state) {
  case ContractState.FUTURE:
    return DotColorEnum.BLUE;
  case ContractState.OVERRIDEN:
    return DotColorEnum.YELLOW;
  case ContractState.ACTIVE:
  case PropertyDisplayDtoPropertyStateEnum.READY:
    return DotColorEnum.GREEN;
  case ContractState.PAST:
    return DotColorEnum.CREAM;
  default:
    return DotColorEnum.GRAY;
  }
};


const StatusCell = ({ record }: Props) => {
  const { tl } = useContext(LanguageContext);
  const dotColor = getDotColor(record.state!);

  return (
    <ColorDot color={dotColor}>
      {tl(contactPropertiesTranslations.propertyTable.states[record.state])}
    </ColorDot>
  );
};

export default StatusCell;
