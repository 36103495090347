import { translations } from 'elements/Translation/translations';
import _, { isEmpty } from 'lodash';
import moment from 'moment';

import { SERVICE_TYPE } from '../../pages/Property/interfaces';
import {
  insuranceTypes,
  linkableSections,
} from '../util/PropertyTypes';

const oneNameFieldExists = (prp: any) => prp.propertyIdInternal || prp.street || prp.number || prp.postalCode || prp.city;
const generatePropertyName = (prp: any) => (oneNameFieldExists(prp) ? `${prp.administrationType === 'SEV_MV' ? 'SEV' : prp.administrationType || 'WEG'} ${prp.propertyIdInternal ? (prp.propertyIdInternal) : ''}`
    + `${prp.street ? (` ${prp.street}`) : ''}`
    + `${prp.number ? (` ${prp.number}`) : ''}`
    + `${prp.postalCode ? (`, ${prp.postalCode}`) : ''}`
    + `${prp.city ? (` ${prp.city}`) : ''}` : undefined);

export const convertToFEModel = (property: any): any => {
  // set autoGenerateName to true if the name is not specified yet
  property.autoGenerateName = ((property.name === generatePropertyName(property)) || (property.name === undefined && generatePropertyName(property) === property.administrationType));
  if (property.economicYearStart) {
    property.economicYearStart = moment(property.economicYearStart);
  }

  property.totalHouseMoney = 0;
  property.totalReserveMoney = 0;

  if (property.buildings) {
    property.numberBuildings = Math.max(property.buildings.length, 1);

    property.buildings.forEach((building: any) => {
      linkableSections.forEach((section: string) => {
        building[`${section}Linked`] = !!building[`${section}LinkedBuildingHrId`];
      });
      if (building.elevators) {
        building.elevators = building.elevators.slice(0, building.elevatorNr);
        building.elevators.forEach((elevator: any) => {
          elevator.contractRange = [
            elevator.contractStartDate ? moment(elevator.contractStartDate) : null,
            elevator.contractEndDate ? moment(elevator.contractEndDate) : null,
          ];
        });
      }
      building.buildersGuarantee = !!building.guaranteeDueDate && Math.ceil(moment(building.guaranteeDueDate).diff(moment(), 'days', true)) >= 1;
      building.constructionDate = building.constructionDate ? moment(building.constructionDate) : null;
      building.tvContractDates = [
        building.tvContractStartDate ? moment(building.tvContractStartDate) : null,
        building.tvContractEndDate ? moment(building.tvContractEndDate) : null,
      ];
      building.internetContractDates = [building.internetContractStartDate ? moment(building.internetContractStartDate) : null,
        building.internetContractEndDate ? moment(building.internetContractEndDate) : null,
      ];
      // section7 house/reserve money accounts
      if (building.houseOwnerLiabilityInsurance) {
        building.houseOwnerLiabilityInsurance = convertInsuranceToFE(building.houseOwnerLiabilityInsurance, building.houseOwnerLiabilityInsuranceIncludedInBuildingInsurance);
      } else {
        building.houseOwnerLiabilityInsurance = {};
        building.houseOwnerLiabilityInsurance.riskIncluded = 'NO';
      }
      if (building.buildingInsurance) {
        building.buildingInsurance = convertInsuranceToFE(building.buildingInsurance, building.buildingInsuranceIncludedInBuildingInsurance);
        building.buildingInsurance.hasInsurance = true;
        delete building.buildingInsurance.riskIncluded;
      } else {
        building.buildingInsurance = {};
        building.buildingInsurance.hasInsurance = false;
        building.buildingInsurance.isDeductible = false;
      }
      if (building.fireInsurance) {
        building.fireInsurance = convertInsuranceToFE(building.fireInsurance, building.fireInsuranceIncludedInBuildingInsurance);
      } else {
        building.fireInsurance = {};
        building.fireInsurance.riskIncluded = 'NO';
        building.fireInsurance.isDeductible = false;
      }
      if (building.stormInsurance) {
        building.stormInsurance = convertInsuranceToFE(building.stormInsurance, building.stormInsuranceIncludedInBuildingInsurance);
      } else {
        building.stormInsurance = {};
        building.stormInsurance.riskIncluded = 'NO';
        building.stormInsurance.isDeductible = false;
      }
      if (building.waterInsurance) {
        building.waterInsurance = convertInsuranceToFE(building.waterInsurance, building.waterInsuranceIncludedInBuildingInsurance);
      } else {
        building.waterInsurance = {};
        building.waterInsurance.riskIncluded = 'NO';
      }
      if (building.naturalDisasterInsurance) {
        building.naturalDisasterInsurance = convertInsuranceToFE(building.naturalDisasterInsurance, building.naturalDisasterInsuranceIncludedInBuildingInsurance);
      } else {
        building.naturalDisasterInsurance = {};
        building.naturalDisasterInsurance.riskIncluded = 'NO';
        building.naturalDisasterInsurance.isDeductible = false;
      }
      building.coldWaterRented = !!building.coldWaterCounterCompany;
      building.hotWaterRented = !!building.hotWaterCounterCompany;
      building.hkvRented = !!building.heatingCostDistributorCompany;


      // section7 house/reserve money per buildings
      building.totalHouseMoney = 0;
      building.totalReserveMoney = 0;
      // set number of meters
      if (building.meters) {
        building.meterNr = Math.max(building.meters.length, 1);
      } else {
        building.meterNr = 1;
      }
    });
  }
  if (property.councilInsurance) {
    property.councilInsurance.hasInsurance = true;
    property.councilInsurance.contractDateRange = property.councilInsurance.termStartDate && property.councilInsurance.termEndDate && [moment(property.councilInsurance.termStartDate), moment(property.councilInsurance.termEndDate)];
    property.councilInsurance.isDeductible = !!property.councilInsurance.deductible;
  } else {
    property.councilInsurance = {};
    property.councilInsurance.hasInsurance = false;
    property.councilInsurance.isDeductible = false;
  }
  if (property.houseOwnerLiabilityInsurance) {
    property.houseOwnerLiabilityInsurance.hasInsurance = true;
    property.houseOwnerLiabilityInsurance.contractDateRange = property.houseOwnerLiabilityInsurance.termStartDate && property.houseOwnerLiabilityInsurance.termEndDate && [moment(property.houseOwnerLiabilityInsurance.termStartDate), moment(property.houseOwnerLiabilityInsurance.termEndDate)];
    property.houseOwnerLiabilityInsurance.isDeductible = !!property.houseOwnerLiabilityInsurance.deductible;
  } else {
    property.houseOwnerLiabilityInsurance = {};
    property.houseOwnerLiabilityInsurance.hasInsurance = false;
    property.houseOwnerLiabilityInsurance.isDeductible = false;
  }

  // convert accountDrafts to FE accounts and subdivisions
  property = convertDraftAccountsToFE(property);

  if (property.propertyAddress) {
    property.country = property.propertyAddress.country;
    property.state = property.propertyAddress.state;
    property.city = property.propertyAddress.city;
    property.street = property.propertyAddress.street;
    property.number = property.propertyAddress.number;
    property.postalCode = property.propertyAddress.postalCode;
  }

  property.savedVatEligibilityType = property.vatEligibilityType;
  property.savedVatEligibility = property.vatEligibility;

  const fees = property.administrationFees;
  if (fees) {
    fees.flatFeeGross = fees.flatRateFee ? fees.flatRateFee * 1.19 : null;
    fees.apartmentFeeGross = fees.apartmentFee ? fees.apartmentFee * 1.19 : null;
    fees.commercialFeeGross = fees.commercialFee ? fees.commercialFee * 1.19 : null;
    fees.garageFeeGross = fees.garageFee ? fees.garageFee * 1.19 : null;
    fees.undergroundGarageFeeGross = fees.undergroundGarageFee ? fees.undergroundGarageFee * 1.19 : null;
    fees.basementFeeGross = fees.basementFee ? fees.basementFee * 1.19 : null;
    fees.partialOwnershipFeeGross = fees.partialOwnershipFee ? fees.partialOwnershipFee * 1.19 : null;
  }

  return property;
};

const defaultBuilding = (buildingHrId: string, property: any): any => {
  const address = {
    city: property.city,
    country: property.country,
    number: property.number,
    postalCode: property.postalCode,
    state: property.state,
    street: property.street,
  };

  const building: any = {
    address,
    supervisorContactId: property.supervisorContactId,
  };

  linkableSections.forEach((section: string) => {
    building[`${section}Linked`] = true;
    building[`${section}LinkedBuildingHrId`] = buildingHrId;
  });

  return building;
};

export const processChanges = (property: any, oldProperty: any): any => {
  if (property) {
    if (!!oldProperty && (property.name !== oldProperty.name)) {
      property.autoGenerateName = false;
    }
    if ((property.autoGenerateName === undefined || property.autoGenerateName || (!property.name && !oldProperty.name))) {
      property.name = generatePropertyName(property);
    }


    const fees = property.administrationFees;
    if (fees) {
      fees.flatFeeGross = fees.flatRateFee ? fees.flatRateFee * 1.19 : null;
      fees.apartmentFeeGross = fees.apartmentFee ? fees.apartmentFee * 1.19 : null;
      fees.commercialFeeGross = fees.commercialFee ? fees.commercialFee * 1.19 : null;
      fees.garageFeeGross = fees.garageFee ? fees.garageFee * 1.19 : null;
      fees.undergroundGarageFeeGross = fees.undergroundGarageFee ? fees.undergroundGarageFee * 1.19 : null;
      fees.basementFeeGross = fees.basementFee ? fees.basementFee * 1.19 : null;
      fees.partialOwnershipFeeGross = fees.partialOwnershipFee ? fees.partialOwnershipFee * 1.19 : null;
    }
    if (!property.buildings) property.buildings = [];
    if (property.numberBuildings !== property.buildings.length) {
      const nrDiff = property.numberBuildings - property.buildings.length;
      if (nrDiff > 0) {
        property.buildings = property.buildings.concat(Array.from(Array(nrDiff), () => (property.buildings.length !== 0 ? defaultBuilding(property.buildings[0].buildingHrId, property) : {})));
      } else {
        property.buildings = property.buildings.slice(0, property.numberBuildings);
      }
    }

    if (property.buildings) {
      property.buildings.forEach((building: any, buildingIndex: number) => {
        // autofill for legionnaire inspection interval
        if (_.isBoolean(building.legionnaireFound)) {
          if (building.legionnaireFound) {
            building.legionnaireExamInterval = '1';
          } else {
            building.legionnaireExamInterval = '3';
          }
        }
        // split elevator contract range
        if (building.elevators) {
          for (let i = 0; i < building.elevators.length; i += 1) {
            if (building.elevators[i].contractRange) {
              building.elevators[i].contractStartDate = building.elevators[i].contractRange[0];
              building.elevators[i].contractEndDate = building.elevators[i].contractRange[1];
            } else {
              delete building.elevators[i].contractStartDate;
              delete building.elevators[i].contractEndDate;
            }
          }
        }
        // split tv contract range
        if (building.tvContractDates) {
          building.tvContractStartDate = building.tvContractDates[0];
          building.tvContractEndDate = building.tvContractDates[1];
        } else {
          delete building.tvContractStartDate;
          delete building.tvContractEndDate;
        }
        if (!!building.tvNoticePeriodMonths && !!building.tvContractEndDate) {
          building.tvCancellationDueDate = moment(building.tvContractEndDate).subtract(building.tvNoticePeriodMonths, 'months');
        }
        // split internet contract range
        if (building.internetContractDates) {
          building.internetContractStartDate = building.internetContractDates[0];
          building.internetContractEndDate = building.internetContractDates[1];
        } else {
          delete building.internetContractStartDate;
          delete building.internetContractEndDate;
        }
        if (!!building.internetNoticePeriodMonths && !!building.internetContractEndDate) {
          building.internetCancellationDueDate = moment(building.internetContractEndDate).subtract(building.internetNoticePeriodMonths, 'months');
        }
        // autofill for service monthly fees
        Object.values(SERVICE_TYPE).forEach((serviceType: SERVICE_TYPE) => {
          const monthlyInvoiceNet = building[`${serviceType}MonthlyInvoice`];
          const monthlyInvoiceGross = building[`${serviceType}MonthlyInvoiceGross`];
          if (typeof monthlyInvoiceNet !== 'undefined' && oldProperty.buildings[buildingIndex] && monthlyInvoiceNet !== oldProperty.buildings[buildingIndex][`${serviceType}MonthlyInvoice`]) {
            building[`${serviceType}MonthlyInvoiceGross`] = monthlyInvoiceNet * 1.19;
          } else if (typeof monthlyInvoiceGross !== 'undefined' && oldProperty.buildings[buildingIndex] && monthlyInvoiceGross !== oldProperty.buildings[buildingIndex][`${serviceType}MonthlyInvoiceGross`]) {
            building[`${serviceType}MonthlyInvoice`] = monthlyInvoiceGross / 1.19;
          } else if (typeof monthlyInvoiceNet === 'undefined') {
            delete building[`${serviceType}MonthlyInvoiceGross`];
          } else if (typeof monthlyInvoiceGross === 'undefined') {
            delete building[`${serviceType}MonthlyInvoice`];
          }
        });

        if (building.houseOwnerLiabilityInsurance) {
          building.houseOwnerLiabilityInsurance = onChangeInsuranceDetails(building.houseOwnerLiabilityInsurance, 'houseOwnerLiabilityInsurance', building.buildingInsurance);
          building = onChangeRiskIncluded(building, building.houseOwnerLiabilityInsurance, 'houseOwnerLiabilityInsurance');
        }
        if (building.buildingInsurance) {
          building.buildingInsurance = onChangeInsuranceDetails(building.buildingInsurance, 'buildingInsurance');
        }
        if (building.fireInsurance) {
          building.fireInsurance = onChangeInsuranceDetails(building.fireInsurance, 'fireInsurance', building.buildingInsurance);
          building = onChangeRiskIncluded(building, building.fireInsurance, 'fireInsurance');
        }

        if (building.stormInsurance) {
          building.stormInsurance = onChangeInsuranceDetails(building.stormInsurance, 'stormInsurance', building.buildingInsurance);
          building = onChangeRiskIncluded(building, building.stormInsurance, 'stormInsurance');
        }
        if (building.naturalDisasterInsurance) {
          building.naturalDisasterInsurance = onChangeInsuranceDetails(building.naturalDisasterInsurance, 'naturalDisasterInsurance', building.buildingInsurance);
          building = onChangeRiskIncluded(building, building.naturalDisasterInsurance, 'naturalDisasterInsurance');
        }
        if (building.waterInsurance) {
          building.waterInsurance = onChangeInsuranceDetails(building.waterInsurance, 'waterInsurance', building.buildingInsurance);
          building = onChangeRiskIncluded(building, building.waterInsurance, 'waterInsurance');
        }
      });
    }

    if (property.councilInsurance) {
      property.councilInsurance = onChangeInsuranceDetails(property.councilInsurance);
    }
    if (property.houseOwnerLiabilityInsurance) {
      property.houseOwnerLiabilityInsurance = onChangeInsuranceDetails(property.houseOwnerLiabilityInsurance);
    }
  }


  return property;
};

const copySection = (section: string, fromBuilding: any, toBuilding: any) => {
  fromBuilding && Object.keys(fromBuilding)
    .filter((key: string) => key.startsWith(section) && !key.includes('Linked') && !key.includes('riskIncluded') && !key.includes('hasInsurance') && !key.includes('garageNr') && !key.includes('garageAnnexNr'))
    .forEach((key: string) => toBuilding[key] = fromBuilding[key]);
};

export const convertToBEModel = (property: any, tl: any): any => {
  // add economicYearEnd according to start

  if (property.economicYearStart) {
    property.economicYearEnd = moment(property.economicYearStart);
    property.economicYearEnd.add(1, 'years');
    property.economicYearEnd.subtract(1, 'days');
  } else {
    delete property.economicYearStart;
    delete property.economicYearEnd;
  }

  if (!property.administrationType) {
    property.administrationType = 'WEG';
  }

  if (isEmpty(property.billingAddress)) {
    property.billingAddress = null;
  }

  if (isEmpty(property.reserveAccountDrafts)) {
    property.reserveAccountDrafts = [
      {
        name: 'Rücklage',
      },
    ];
  }

  if (property.buildings) {
    property.buildings.forEach((building: any, buildingIndex: number) => {
      linkableSections.forEach((section: string) => {
        if (building[`${section}Linked`]) {
          const fromBuilding = property.buildings.find((b: any) => b.buildingHrId === building[`${section}LinkedBuildingHrId`]);
          copySection(section, fromBuilding, building);
        }
      });

      if (building.insuranceLinked || property.identicalInsurances) {
        let fromBuilding = property.buildings[0];
        if (building.insuranceLinkedBuildingHrId) {
          fromBuilding = property.buildings.find((b: any) => b.buildingHrId === building.insuranceLinkedBuildingHrId);
        }
        copySection('houseOwnerLiabilityInsurance', fromBuilding, building);
        copySection('buildingInsurance', fromBuilding, building);
        copySection('fireInsurance', fromBuilding, building);
        copySection('stormInsurance', fromBuilding, building);
        copySection('naturalDisasterInsurance', fromBuilding, building);
        copySection('waterInsurance', fromBuilding, building);
      }
      if (!!building.buildingInsurance && !building.buildingInsurance.hasInsurance) {
        delete building.buildingInsurance;
      }

      insuranceTypes.forEach((insurance) => {
        if (!!building[`${insurance}Insurance`] && (building[`${insurance}Insurance`].riskIncluded === 'NO'
          || (!building.buildingInsurance && building[`${insurance}Insurance`].riskIncluded === 'YES'))) {
          delete building[`${insurance}Insurance`].riskIncluded;
          delete building[`${insurance}Insurance`];
        }
      });

      if (_.isEmpty(building.name)) {
        building.name = getBuildingNameWithDefaultAsFallback(building, buildingIndex + 1, tl);
      }

      // if reduced the number of elevators then we should also remove the elevator data from the array
      if (building.elevators) {
        building.elevators = building.elevators.slice(0, building.elevatorNr);
      }
    });
  }

  if (!!property.councilInsurance && (!property.councilInsurance.hasInsurance || property.administrationType === 'MV' || property.administrationType === 'SEV_MV' || property.administrationType === 'SEV')) {
    delete property.councilInsurance;
  }
  if (!!property.houseOwnerLiabilityInsurance && (!property.houseOwnerLiabilityInsurance.hasInsurance || property.administrationType === 'MV' || property.administrationType === 'SEV_MV' || property.administrationType === 'SEV')) {
    delete property.houseOwnerLiabilityInsurance;
  }

  // create draftAccounts
  property = convertAccountsToBE(property);

  return property;
};

const convertDraftAccountsToFE = (property: any) => {
  if (property.accountDrafts) {
    property.accounts = property.accountDrafts.reduce((accumulator: Array<any>, account: any) => {
      const group = accumulator.filter(gr => gr.code === account.parentAccountCode);
      if (group.length === 0) {
        accumulator.push({
          code: account.parentAccountCode,
          divisions: [{ name: account.name, code: account.code, id: account.id }],
        });
      } else {
        group[0].divisions.push({
          name: account.name,
          code: account.code,
          id: account.id,
        });
      }
      return accumulator;
    }, []);

    property.accountNr = property.accounts.length;
    property.accounts.forEach((account: any) => {
      account.numberSubAccounts = account.divisions.length;
    });
    property.reserveAccountsNr = property.reserveAccountDrafts ? property.reserveAccountDrafts.length : 1;
  }
  return property;
};

const convertAccountsToBE = (property: any) => {
  if (property.accounts) {
    property.accountDrafts = [];
    property.accounts.forEach((account: any) => {
      if (account.code && account?.divisions) {
        account.divisions.forEach((subAccount: any) => {
          const id = subAccount.id ? subAccount.id : null;
          property.accountDrafts.push({
            name: subAccount.name, code: subAccount.code, parentAccountCode: account.code, id,
          });
        });
      }
    });
  }
  return property;
};

const onChangeInsuranceDetails = (insurance: any, name?: string, buildingInsurance?: any) => {
  if (insurance.contractDateRange) {
    insurance.termStartDate = insurance.contractDateRange[0];
    insurance.termEndDate = insurance.contractDateRange[1];
  } else {
    delete insurance.termStartDate;
    delete insurance.termEndDate;
  }
  if (!insurance.isDeductible) {
    insurance.deductible = 0;
  }
  if ((!insurance.hasInsurance && !insurance.riskIncluded) || insurance.riskIncluded === 'NO') {
    delete insurance.policyNumber;
    delete insurance.insuranceCompany;
    delete insurance.insuranceRepresentative;
    delete insurance.dueDate;
    delete insurance.paymentInterval;
    delete insurance.insuredSum;
    delete insurance.termStartDate;
    delete insurance.termEndDate;
    delete insurance.closingDate;
    delete insurance.deductible;
    delete insurance.insuranceHrId;
  } else if (insurance.riskIncluded === 'YES' && !!name && !!buildingInsurance) {
    copySection('', buildingInsurance, insurance);
  }
  return insurance;
};

const onChangeRiskIncluded = (building: any, insurance: any, insuranceName: string) => {
  const key = `${insuranceName}IncludedInBuildingInsurance`;
  building[key] = insurance.riskIncluded === 'YES';
  return building;
};

const convertInsuranceToFE = (insurance: any, included: boolean) => {
  if (included) {
    insurance.riskIncluded = 'YES';
  } else {
    insurance.riskIncluded = 'OTHER';
  }
  insurance.contractDateRange = insurance.termStartDate && insurance.termEndDate && [moment(insurance.termStartDate), moment(insurance.termEndDate)];
  insurance.isDeductible = !!insurance.deductible;
  return insurance;
};


export const getBuildingNameWithDefaultAsFallback = (building: any, buildingNumber: number, tl: any) => `${building && building.name
  ? `${building.name}`
  : tl(translations.pages.property.editPage.sections.section4.title)
    + buildingNumber
}`;
