import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Alert, Col, Row } from 'antd';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from './translations';
import { loadCsv } from '../services/loadCsv';
import { showNotification } from '../../../../lib/Notification';
import { FileUploadButton } from '../components/FileUploadButton/FileUploadButton';
import Button from '../../../../elements/Buttons/Button/Button';
import { useUnitsImportCsv } from './useUnitsImportCsv';


export const UnitsImportSection = () => {
  const { tl } = useContext(LanguageContext);
  const {
    units, unitsValidationIssues, loadUnitsFromCsvRows, uploadUnits, inProgress,
  } = useUnitsImportCsv();
  const unitValidationErrors = unitsValidationIssues.filter(issue => typeof issue.type === 'undefined' || issue.type === 'error');
  const unitValidationWarnings = unitsValidationIssues.filter(issue => issue.type === 'warning');
  const showImport = unitValidationErrors.length < 1 && units.length > 0;
  return (

    <PrimarySectionWrapper
      sectionNumber={4}
      sectionTitle={tl(translations.title)}
      sectionId="units-import"
    >
      <Row>
        <Col>
          <FileUploadButton
            label={tl(translations.upload)}
            accept="text/csv"
            onChange={event => loadCsv(event.target.files, 0)
              .then(res => loadUnitsFromCsvRows(res))
              .catch((err) => {
                showNotification({ message: tl(translations.validations.errors) });
                console.error('Error while loading csv', err);
              })}
          />
        </Col>
      </Row>
      {units.length > 0
        ? <Alert message={`${units.length} ${tl(translations.validations.success)}`} type="success" />
        : <Alert message={tl(translations.description)} />
      }
      {unitValidationErrors.length > 0 && (
        <Alert
          message={tl(translations.validations.errors)}
          type="error"
          description={(
            <ul>
              {' '}
              {
                unitValidationErrors.map(issue => (
                  <li key={issue.row + issue.message}>
                    {issue.row + 1}
                    :
                    {' '}
                    {issue.message}
                  </li>
                ))
              }
            </ul>
          )}
        />

      )}
      {unitValidationWarnings.length > 0 && (
        <Alert
          message={tl(translations.validations.warnings)}
          type="warning"
          description={(
            <ul>
              {' '}
              {
                unitValidationWarnings.map(issue => (
                  <li key={issue.row + issue.message}>
                    {issue.row + 1}
                    :
                    {' '}
                    {issue.message}
                  </li>
                ))
              }
            </ul>
          )}
        />

      )}
      {showImport && (<Button onClick={uploadUnits} type="primary" loading={inProgress}>{tl(translations.import)}</Button>)}
    </PrimarySectionWrapper>
  );
};
