import { useContext } from 'react';
import { translations } from '../../../../elements/Translation/translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';

export const useHouseMoneySettlementPropertyListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.houseMoneySettlement.table.headers.hgaStatus),
      dataKey: ['hga', 'status'],
    },
    {
      title: tl(translations.pages.houseMoneySettlement.table.headers.type),
      dataKey: 'administrationType',
    },
    {
      title: tl(translations.pages.property.table.headers.propertyHrId),
      dataKey: 'propertyHrId',
    },
    {
      title: tl(translations.pages.property.table.headers.internalNr),
      dataKey: 'propertyIdInternal',
    },
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'name',
    },
    {
      title: tl(translations.pages.houseMoneySettlement.table.headers.administrator),
      dataKey: 'supervisorName',
    },
  ];
};
