import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import SummaryRow from 'elements/CustomElements/ExpandableCard/SummaryRow';
import { translations } from 'elements/Translation/translations';
import { formatCurrency } from 'lib/Utils';
import { usePropertyCardColumns } from '../usePropertyCardColumns';
import './PropertyIncomeCard.scss';
import Amount from 'storybook-components/typography/Amount/Amount';

interface incomeCard {
  income: any[],
  labelsPrefix: string,
  loading: boolean,
  showShares?: boolean,
  addSubtotalRow?: boolean,
}

export function useRenderPropertyIncomeTotal(props: incomeCard) {
  const { tl } = useContext(LanguageContext);
  const { income, labelsPrefix } = props;
  const [totalCosts, setTotalCosts] = useState(0);
  useEffect(() => {
    if (income) {
      setTotalCosts(income.reduce((aggregator: any, inc: any) => aggregator + (inc.totalSum || 0), 0));
    }
  }, [income]);

  const summaryElements = [{
    title: tl(translations.pages[labelsPrefix].propertyAccountBalances.income.summary.costs),
    value: <Amount>{formatCurrency(totalCosts)}</Amount>,
  }];
  return (
    <SummaryRow rightSideElements={summaryElements} />
  );
}


export default function PropertyIncomeCard(props: incomeCard) {
  const {
    income, loading, showShares, addSubtotalRow, labelsPrefix,
  } = props;
  const isEconomicPlan: boolean = useMemo<boolean>(() => labelsPrefix.indexOf('economicPlan') !== -1, [labelsPrefix]);
  const { tl } = useContext(LanguageContext);

  const defaultSubtotal = {
    account: tl(translations.pages.economicPlan.propertyAccountBalances.expenses.subtotal),
    totalSum: 0,
  };

  const incomeSubtotal = useMemo(() => {
    if (!income) return defaultSubtotal;
    return {
      account: tl(translations.pages.economicPlan.propertyAccountBalances.expenses.subtotal),
      totalSum: income.reduce((aggregator: number, expense: any) => aggregator + (expense.totalSum || 0), 0),
    };
  }, [income]);

  const incomeDataSource = useMemo(() => {
    if (income) {
      if (addSubtotalRow) return income.concat(incomeSubtotal);
      return income;
    }
    return [];
  }, [income]);

  const smartTable = useSmartTable({
    tableName: 'propertyAccountIncome',
    columns: usePropertyCardColumns({ showShares, isEconomicPlan }),
    dataSource: incomeDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'accountCode',
  });

  return (
    <div className="PropertyIncomeCard">
      <SmartTable {...smartTable} />
    </div>
  );
}
