import React, { useContext, useMemo } from 'react';
import { EconomicPlanCreationDataAccountBalanceDto } from 'api/accounting';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import './EconomicPlanTotalTable.scss';
import { economicPlanAccountEditingTranslations } from '../../../economicPlanAccountEditingTranslations';
import { formatToEuro } from '../../../../../../lib/Utils';
import { Table } from 'antd';
import TableContentLoadingIndicator from '../../../../../../elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import TableSummaryTotalAmount from '../../../../../../elements/TableSummaryTotalAmount/TableSummaryTotalAmount';
import Amount from 'storybook-components/typography/Amount/Amount';


export default function EconomicPlanTotalTable({ economicPlan }: any): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      width: 'auto',
    },
    {
      title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.total.totalQuantity),
      width: '20%',
      dataIndex: 'value',
      render: (amount: number) => <Amount>{formatToEuro(amount)}</Amount>,
    },
  ];

  const dataSource = useMemo(() => {
    const applicableExpenses: EconomicPlanCreationDataAccountBalanceDto[] = economicPlan?.data?.applicableExpenses || [];
    const notApplicableExpenses: EconomicPlanCreationDataAccountBalanceDto[] = economicPlan?.data?.notApplicableExpenses || [];
    const income = economicPlan?.data?.income || [];

    return [
      {
        key: tl(economicPlanAccountEditingTranslations.sections.editAmounts.total.expenses),
        value: Object.values(applicableExpenses).reduce((sum: number, amount: any) => sum + (amount || 0), 0)
            + Object.values(notApplicableExpenses).reduce((sum: number, amount: any) => sum + (amount || 0), 0),
      },
      {
        key: tl(economicPlanAccountEditingTranslations.sections.editAmounts.total.incomes),
        value: Object.values(income).reduce((sum: number, amount: any) => sum + (amount || 0), 0),
      },
    ];
  }, [economicPlan.data]);

  return (
    <div className="EconomicPlanTotalTable">
      <Table
        loading={{
          indicator: <TableContentLoadingIndicator />,
          spinning: economicPlan.loading,
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => (TableSummaryTotalAmount({
          amountLabel: tl(economicPlanAccountEditingTranslations.sections.editAmounts.total.total),
          totalAmount: <Amount>{formatToEuro(dataSource[0].value - dataSource[1].value)}</Amount>,
        })
        )}
      />
    </div>
  );
}
