import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import React from 'react';
import { useOpsCostCell } from './useOpsCostCell';

interface Props {
  unitId: number,
  isEditable: boolean,
}

const OpsCostCell = ({
  unitId,
  isEditable,
}: Props) => {
  const {
    value,
    onChange,
  } = useOpsCostCell(unitId);

  return (
    <CurrencyInput
      value={value!}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      disabled={!isEditable}
    />
  );
};

export default OpsCostCell;
