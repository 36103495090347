const ERROR_CODES_ENDPOINT = '/error-codes';

export const getErrorCodeFromURI = (uri: string) => {
  if (!uri) return '';
  const index = uri.lastIndexOf(ERROR_CODES_ENDPOINT);
  return index === -1 ? '' : uri.substring(index + ERROR_CODES_ENDPOINT.length + 1);
};

export const getIndexAndFieldNameFromField = (issue: string) => {
  const [indexString, fieldName] = issue.split('.');
  const index = parseInt(indexString, 10);

  return { index, fieldName };
};
