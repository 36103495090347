import React, { useContext, useEffect, useMemo } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { OwnersMeetingInvitationContext } from 'contexts/OwnersMeetingInvitationContext';
import SmartTable from 'elements/SmartTable/SmartTable';
import { Action } from 'elements/SmartTable/data';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { OwnersMeetingInvitationDto, OwnersMeetingInvitationDtoStatusEnum } from 'api/accounting/models/OwnersMeetingInvitationDto';
import { Modal } from 'antd';
import { useOwnersMeetingInvitationListColumns } from './useOwnersMeetingInvitationListColumns';
import useOwnersMeetingInvitationFilters from './UseOwnersMeetingInvitationFilters';
import { ownersMeetingInvitationTranslations } from '../OwnersMeetingInvitationEditing/translations/OwnersMeetingInvitationTranslations';
import './OwnersMeetingsInvitationList.scss';

export default function OwnersMeetingsInvitationList(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const ownersMeetingInvitationContext: any = useContext(OwnersMeetingInvitationContext);
  const history = useHistory();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  const {
    filterState, ownersMeetingListState, onLoadOwnersMeetingList, clearFilter, setFilterState, updateFilterState, sortField, sortOrder, setSortField,
  } = ownersMeetingInvitationContext;

  useGlobalSearch({
    key: 'ownersMeetingInvitations',
    filterProps: {
      availableFilters: useOwnersMeetingInvitationFilters(),
      setFilter: (key: string, value: any) => setFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);

          newFilter[key] = value;
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams: updateFilterState,
    },
  });

  useEffect(() => {
    onLoadOwnersMeetingList();
    return () => clearFilter();
  }, []);

  useEffect(() => {
    if (propertyHrId) {
      updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);


  const toTableData = (data: any) => {
    const houseMoneySettlementStatus = data.houseMoneySettlementStatus ? tl(ownersMeetingInvitationTranslations.ownersMeetingStates[data.houseMoneySettlementStatus]) : '';
    const economicPlanStatus = data.economicPlanStatus ? tl(ownersMeetingInvitationTranslations.ownersMeetingStates[data.economicPlanStatus]) : '';

    return {
      ...data,
      propertyHrId: data.property.propertyHrId,
      propertyIdInternal: data.property.propertyIdInternal,
      propertyName: data.property.name,
      propertySupervisorName: data.property.supervisorName,
      propertyAccountant: data.property.accountantName,
      houseMoneySettlementStatus,
      economicPlanStatus,
      etvStatus: tl(ownersMeetingInvitationTranslations.ownersMeetingStates[data.status]),
    };
  };

  const actionsMenu: Action[] = [{
    label: tl(ownersMeetingInvitationTranslations.editPage.deleteButton),
    onAction: row => Modal.confirm({
      title: tl(ownersMeetingInvitationTranslations.deleteModal.title),
      content: tl(ownersMeetingInvitationTranslations.deleteModal.content),
      okText: tl(ownersMeetingInvitationTranslations.deleteModal.okText),
      cancelText: tl(ownersMeetingInvitationTranslations.deleteModal.cancelText),
      okButtonProps: { className: 'Button', danger: true },
      cancelButtonProps: { className: 'Button' },
      onOk: () => { ownersMeetingInvitationContext.onDeleteOwnersMeetingInvitation(row.id); },
      width: 450,
      icon: <Icon component={ICONS.statusError} />,
    }),
    actionSupported: ((record: OwnersMeetingInvitationDto) => record.status === OwnersMeetingInvitationDtoStatusEnum.DRAFT),
  }];

  const tableData = useMemo(() => ownersMeetingListState.data?.map(toTableData) || [], [ownersMeetingListState.data]);
  const smartTable = useSmartTable({
    tableName: 'OwnersMeetingsInvitationListTable',
    columns: useOwnersMeetingInvitationListColumns(),
    dataSource: tableData,
    contentLoading: ownersMeetingListState.loading,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    onRow: (record: any) => ({
      onClick: () => {
        const navPrefix = propertyHrId ? `/properties/${propertyHrId}/edit` : '';
        if ((['DRAFT', 'ERROR'].includes(record.status))) {
          history.push(`${navPrefix}/owners-meeting/invitation/edit/${record.id}`);
        }
        else {
          history.push(`${navPrefix}/owners-meeting/invitation/view/${record.id}`);
        }
      },
    }),
    infiniteScrollerProps: {
      hasMoreData: !ownersMeetingListState.lastPage,
      loadMoreData: ownersMeetingInvitationContext.onLoadOwnersMeetingList,
    },
    rowKey: 'id',
    actionsMenu,
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'ownersMeetingInvitations',
    navItems: subcategorySwitcherItems.meetings,
  };

  return (
    <div className="OwnersMeetingsInvitationList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button
            type="primary"
            onClick={() => {
              history.push('/owners-meeting/invitation/create');
            }}
          >
            {tl(ownersMeetingInvitationTranslations.elements.createButton)}
          </Button>
        )}
      />
      <SmartTable {...smartTable} />
    </div>
  );
}
