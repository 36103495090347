import React from 'react';
import './LoadingScreen.scss';
import { LoadingIndicator } from 'elements/LoadingIndicator/LoadingIndicator';


interface LoadingScreenProps{
 title?: string,
 subtitle?: string,

}
export function LoadingScreen({ title, subtitle }: LoadingScreenProps) {
  return (
    <div className="LoadingScreen">
      <div className="container">
        <div className="loading-wrapper">
          <LoadingIndicator size="large" />
          <div className="loading-message">
            {title && (
              <>
                <span className="loading-message-title">{title}</span>
                <br />
              </>
            )}
            {subtitle && <span className="loading-message-subtitle">{subtitle}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}

LoadingScreen.defaultProps = {
  title: '',
  subtitle: '',
};
