import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  BankingBankAccountControllerApi,
  BankingBankAccountDto,
  PropertyBankAccountControllerApi,
  PropertyBankAccountCreateDto,
  PropertyBankAccountCreateDtoPropertyAccountTypesEnum,
  PropertyBankAccountCreateDtoStateEnum,
  PropertyBankAccountProjectionDtoPropertyAccountTypesEnum,
} from 'api/accounting';
import {
  Configuration,
  PropertyApi,
} from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { isValidIBAN } from 'ibantools';
import { showNotification } from 'lib/Notification';
import { isEmpty } from 'lodash';

import {
  BankDetailsControllerApi,
} from '../../../../api/accounting/apis/BankDetailsControllerApi';
import { IProgress } from '../interfaces';
import { PropertyImportDto } from '../PropertiesImportSection/interfaces';
import { executeInParallelBatch } from '../utils/executeInParallelBatch';
import { BankAccountImportDto } from './interfaces';
import { translations } from './translations';

const bankAccountTypes = {
  Hausgeld: PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE,
  Bankkonto: PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.RESERVE,
  Mieten: PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.RENT,
  Kaution: PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.DEPOSIT,
};

const mapRowToBankAccountDto = (csvRow: any): BankAccountImportDto => {
  const bankAccountTypesArray = [];
  csvRow?.accountType?.split(',').forEach(type => bankAccountTypesArray.push(bankAccountTypes[type?.trim()]));
  return {
    propertyAccountTypes: bankAccountTypesArray,
    accountHolderName: csvRow.accountHolderName,
    accountName: csvRow.accountName,
    iban: csvRow.iban ? csvRow.iban.replace(/\s/g, '') : csvRow.iban,
    bic: csvRow.bic ? csvRow.bic.replace(/\s/g, '') : csvRow.bic,
    accountInstitute: csvRow.accountInstitute,
    propertyReference: csvRow.propertyReference,
    propertyId: csvRow.propertyId,
  };
};

export const useImportBankAccountsCsv = () => {
  const [bankAccounts, setBankAccounts] = useState<BankAccountImportDto[]>([]);
  const [bankAccountsValidationIssues, setBankAccountsValidationIssues] = useState<any[]>([]);
  const [existingPropertyIds, setExistingPropertyIds] = useState<number[]>([]);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const bankInformationApi = new BankDetailsControllerApi(apiConfiguration('accounting'));
  const propertyAPI = new PropertyApi(apiConfiguration('public') as unknown as Configuration);

  useEffect(() => {
    propertyAPI.getPropertiesByFilterUsingGET({ page: 0, size: 1000 })
      .then((response) => {
        setExistingPropertyIds(response.content.map(p => p.id));
      })
      .catch(() => {
        showNotification({
          key: 'loadPropertyListError',
          message: tl(translations.notifications.propertyLoadError),
          type: 'error',
        });
      });
  }, [bankAccounts]);


  const loadBankAccountsFromCsvRows = async (csvRows: any[], properties: PropertyImportDto[]) => {
    const baccounts: BankAccountImportDto[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach(async (csvRow) => {
      const bankAccount = mapRowToBankAccountDto(csvRow);

      if (!csvRow.propertyId && !csvRow.propertyReference) {
        validationIssues.push({ message: `${tl(translations.validations.issues.property)}`, row: csvRow._row });
      }

      if (csvRow.propertyId && existingPropertyIds.indexOf(parseInt(csvRow.propertyId, 10)) < 0) {
        validationIssues.push({ message: `${tl(translations.validations.issues.property)} <${csvRow.propertyId}>`, row: csvRow._row });
      }

      if (!csvRow.propertyId && csvRow.propertyReference && properties.filter(c => c.externalId === csvRow.propertyReference).length !== 1) {
        validationIssues.push({ message: `${tl(translations.validations.issues.property)} <${csvRow.propertyReference}>`, row: csvRow._row });
      }

      baccounts.push(bankAccount);
      if (isEmpty(bankAccount.propertyAccountTypes)) {
        validationIssues.push({
          message: `${tl(translations.validations.issues.accountType)} ${Object.keys(bankAccountTypes)}`,
          row: csvRow._row,
        });
      }
      if (!bankAccount.accountHolderName) {
        validationIssues.push({ message: tl(translations.validations.issues.accountHolderName), row: csvRow._row });
      }
      if (!bankAccount.iban || !isValidIBAN(bankAccount.iban)) {
        validationIssues.push({ message: tl(translations.validations.issues.iban), row: csvRow._row });
      }
      if (!bankAccount.bic || !bankAccount.accountInstitute) {
        try {
          const bankInformation = await bankInformationApi.checkBankDetailsUsingGET({ iban: bankAccount.iban });
          bankAccount.bic = bankInformation.bic;
          bankAccount.accountInstitute = bankInformation.bankName;
        } catch (e) {
          validationIssues.push({ message: tl(translations.validations.issues.bic), row: csvRow._row });
          validationIssues.push({ message: tl(translations.validations.issues.accountInstitute), row: csvRow._row });
        }
      }
    });
    setBankAccountsValidationIssues(validationIssues);
    if (validationIssues.length) {
      setBankAccounts([]);
    } else {
      setBankAccounts(baccounts);
    }
  };

  return {
    loadBankAccountsFromCsvRows,
    bankAccounts,
    bankAccountsValidationIssues,
  };
};

export const importBankAccounts = async (bankAccounts: BankAccountImportDto[], bankingBankAccountControllerApi: BankingBankAccountControllerApi, propertyBankAccountControllerApi: PropertyBankAccountControllerApi, setProgress: React.Dispatch<React.SetStateAction<IProgress>>) => {
  // get set of properties affected
  const bankAccountPropertyIds = [...Array.from(new Set(bankAccounts.map(ba => ba.propertyId)))];

  await executeInParallelBatch(bankAccountPropertyIds, 1, async (propertyId) => {
    const propertyBankAccounts = bankAccounts
      // only this property's bank accounts
      .filter(ba => ba.propertyId === propertyId);
    const uniquePropertyBankAccounts = propertyBankAccounts
      // eliminate duplicate ibans on the same property
      .filter((ba, index, a) => a.findIndex(ba2 => (ba2.iban === ba.iban)) === index);

    // check for already existing bank accounts
    let existingBankingBankAccounts: BankingBankAccountDto[] = [];
    if (uniquePropertyBankAccounts.length > 0) {
      existingBankingBankAccounts = await bankingBankAccountControllerApi.getBankingBankAccountsUsingGET({ ibans: uniquePropertyBankAccounts.map(ba => ba.iban) });
    }

    const bankingBankAccountPromises = uniquePropertyBankAccounts
      // eliminate already existing bank accounts
      .filter(pba => existingBankingBankAccounts.findIndex(eba => (pba.iban === eba.iban)) === -1)
      .map(ba => bankingBankAccountControllerApi.createBankingBankAccountUsingPOST({
        bankingBankAccountCreateDto: {
          iban: ba.iban?.replaceAll(' ', ''),
          bic: ba.bic?.replaceAll(' ', ''),
          accountHolderName: ba.accountHolderName,
          accountInstitute: ba.accountInstitute,
        },
      }));
    const createdBankingBankAccounts: BankingBankAccountDto[] = await Promise.all(bankingBankAccountPromises);

    const allBankAccounts: BankingBankAccountDto[] = existingBankingBankAccounts.concat(createdBankingBankAccounts);

    const propertyBankAccountCreateDtos: PropertyBankAccountCreateDto[] = allBankAccounts.map((ba) => {
      const importBa = uniquePropertyBankAccounts.find(pba => pba.iban?.replaceAll(' ', '') === ba.iban);

      const propertyAccountTypes = [...Array.from(new Set(propertyBankAccounts.filter(pba => pba.iban === importBa.iban).flatMap(pba => pba.propertyAccountTypes)))];

      return {
        bankAccountId: ba.id,
        propertyId,
        accountName: importBa.accountName ? importBa.accountName : ba.accountHolderName,
        propertyAccountTypes: propertyAccountTypes as unknown as Array<PropertyBankAccountCreateDtoPropertyAccountTypesEnum>,
        state: PropertyBankAccountCreateDtoStateEnum.BOOKED,
      };
    });

    propertyBankAccountControllerApi.createPropertyBankAccountUsingPOST({ propertyBankAccountCreateDtos });

    // update progress
    setProgress(prev => ({
      ...prev,
      bankAccountsImported: prev.bankAccountsImported + propertyBankAccounts.length,
    }));
  });
};
