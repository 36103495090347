import React from 'react';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { UnitContractProjectionDto } from '../../../api/accounting';
import { ProcessingSelectorOptions, useProcessingSelector } from './useProcessingSelector';

export interface ProcessingSelectorProps {
  contract: UnitContractProjectionDto
  onChange?: (value: ProcessingSelectorOptions, record: UnitContractProjectionDto) => void
  disabled?: boolean
  direction?: 'incoming' | 'outgoing'
}


export const ProcessingSelector = ({
  contract, onChange, disabled, direction,
}: ProcessingSelectorProps) => {
  const { SELECT_OPTIONS, defaultValue } = useProcessingSelector(contract, direction);

  return (
    <SelectInput
      options={SELECT_OPTIONS}
      value={defaultValue}
      disabled={disabled}
      onChange={val => onChange(val, contract)}
    />
  );
};

ProcessingSelector.defaultProps = {
  disabled: false,
  direction: 'outgoing',
  onChange: () => {},
};
