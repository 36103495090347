import { anyMatch, createFieldRegExp } from '../../lib/Utils';

const ownersMeetingProtocolSectionFieldsMap: any = {
  0: [],
  1: [
    'propertyId',
    'date',
    'titleOfProtocol',
  ],
  2: [
    'startTime',
    'endTime',
    'meetingLeader',
    'meetingLeaderRole',
    'minuteTaker',
    'minuteTakerRole',
    'votingRights',
    'votingScheme',
  ],
  3: [
    'voteKey',
    'voteMajorityKey',
    'title',
    'text',
  ],
  4: [
    'voteKey',
    'voteMajorityKey',
    'agendaItem.resolutionRecordText',
  ],
  vote: [
    'presentVotes',
  ],
  5: [
    'isSigningPerson',
    'signingRole',
    'signingDispatchType',
  ],
  6: [
    'dispatchType',
  ],
  7: [],
};

const getSection3Fields = (): string[] => ownersMeetingProtocolSectionFieldsMap[3].map((field: string) => `pointOfOrders\\[.+\\]\\.${field}`);
const getSection4Fields = (): string[] => ownersMeetingProtocolSectionFieldsMap[4].map((field: string) => `agendaItemVotes\\[.+\\]\\.${field}`);
const getSection5Fields = (): string[] => ownersMeetingProtocolSectionFieldsMap[5].map((field: string) => `protocolRecipients\\[.+\\]\\.${field}`)
  .concat([
    'meetingLeaderSigningDispatchTypes',
  ]);
const getSection6Fields = (): string[] => ownersMeetingProtocolSectionFieldsMap[6].map((field: string) => `protocolRecipients\\[.+\\]\\.${field}`);

const getOwnersMeetingProtocolSectionFieldsMap = (savingSectionNumber: number) => {
  let fields: string[] = [];
  switch (savingSectionNumber) {
  case 1:
    fields = ownersMeetingProtocolSectionFieldsMap[1];
    break;
  case 2:
    fields = ownersMeetingProtocolSectionFieldsMap[2];
    break;
  case 3:
    fields = getSection3Fields();
    break;
  case 4:
    fields = getSection4Fields();
    break;
  case 5:
    fields = getSection5Fields();
    break;
  case 6:
    fields = getSection6Fields();
    break;
  default:
    fields = ownersMeetingProtocolSectionFieldsMap[savingSectionNumber];
  }

  return fields.map((field: string) => createFieldRegExp(field));
};

export const getSectionErrors = (sectionNumber: number, errors: any) => {
  const fields = getOwnersMeetingProtocolSectionFieldsMap(sectionNumber);
  const sectionErrors: any = {};

  Object.keys(errors)
    .filter((key: string) => anyMatch(fields, key))
    .forEach((key: string) => {
      sectionErrors[key] = errors[key];
    });

  return sectionErrors;
};

export interface TopicIndex {
  index: number
}

export const getVotingErrors = (topicTypeAndIndex: TopicIndex, errors: any) => {
  const fields = ownersMeetingProtocolSectionFieldsMap.vote
    .map((field: string) => `agendaItemVotes\\[${topicTypeAndIndex.index}]\\.${field}`)
    .map((field: string) => createFieldRegExp(field));

  const voteErrors: any = {};

  Object.keys(errors)
    .filter((key: string) => anyMatch(fields, key))
    .forEach((key: string) => {
      voteErrors[key] = errors[key];
    });

  return voteErrors;
};
