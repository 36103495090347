import { ErrorCodeUsingGETValueEnum } from '../../api/accounting';

export const translations = {
  title: {
    en: 'Serial letters',
    de: 'Briefe Übersicht',
  },
  actions: {
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
  },
  create: {
    en: 'Create',
    de: 'Hinzufügen',
  },
  states: {
    SENT: {
      en: 'Sent',
      de: 'Gesendet',
    },
    SENDING: {
      en: 'Sending',
      de: 'Versenden im Gange',
    },
    DRAFT: {
      en: 'Draft',
      de: 'Entwurf',
    },
    ERROR: {
      en: 'Failed to send',
      de: 'Konnte nicht gesendet werden',
    },
  },
  headers: {
    status: {
      en: 'Status',
      de: 'Status',
    },
    internalNr: {
      en: 'Property internal ID',
      de: 'Objekt-Nr Intern',
    },
    propertyName: {
      en: 'Property name',
      de: 'Objektbezeichnung',
    },
    dateFrom: {
      en: 'Shipping date from',
      de: 'Versanddatum ab',
    },
    dateTo: {
      en: 'Shipping date until',
      de: 'Versanddatum bis',
    },
    sender: {
      en: 'Sender',
      de: 'Absender',
    },
    subject: {
      en: 'Subject',
      de: 'Subjekt',
    },
    accountant: {
      en: 'Accountant',
      de: 'Buchhalter',
    },
    supervisor: {
      en: 'Administrator',
      de: 'Betreuer',
    },
    administrationType: {
      en: 'Administration type',
      de: 'Verwaltungsart',
    },
    administrationTypes: {
      WEG: {
        en: 'Wohnungseigentum (WEG)',
        de: 'Wohnungseigentum (WEG)',
      },
      MV: {
        en: 'Mietverwaltung (MV)',
        de: 'Mietverwaltung (MV)',
      },
      SEV: {
        en: 'SEV with WEG connection',
        de: 'SEV mit WEG-Verbindung',
      },
      SEV_MV: {
        en: 'SEV without WEG connection',
        de: 'SEV ohne WEG-Verbindung',
      },
    },
    createdBy: {
      en: 'Created by',
      de: 'Erstellt von',
    },
  },
  list: {
    status: {
      en: 'Status',
      de: 'Status',
    },
    subject: {
      en: 'Subject',
      de: 'Betreff',
    },
    propertyHrId: {
      en: 'Property Hr ID',
      de: 'Objekt ID',
    },
    property: {
      en: 'Property',
      de: 'Objekt',
    },
    sender: {
      en: 'Sender',
      de: 'Absender',
    },
    accountant: {
      en: 'Accountant',
      de: 'Buchhalter',
    },
    supervisor: {
      en: 'Administrator',
      de: 'Betreuer',
    },
    created: {
      en: 'Created on',
      de: 'Erstellt am',
    },
    createdBy: {
      en: 'Created by',
      de: 'Erstellt von',
    },
  },
  editPage: {
    createTitle: {
      en: 'Create new letter',
      de: 'Neuen Brief erstellen',
    },
    editTitle: {
      en: 'Edit letter',
      de: 'Brief bearbeiten',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    saveAll: {
      en: 'Save all',
      de: 'Als Entwurf Speichern',
    },
    sendOut: {
      en: 'Send out',
      de: 'Verschicken',
    },
    back: {
      en: 'Back',
      de: 'Zurück',
    },
    dirtModal: {
      title: {
        en: 'You have unsaved changes',
        de: 'Sie haben nicht gespeicherte Änderungen',
      },
      description: {
        en: 'Save the changes before closing section?',
        de: 'Änderungen vor dem Schließen des Abschnitts speichern?',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      discard: {
        en: 'Discard changes',
        de: 'Änderungen verwerfen',
      },
      save: {
        en: 'Save',
        de: 'Speichern',
      },
    },
    fieldValidationWarning: {
      en: 'Saved serial letter with missing or invalid fields. '
        + 'Please check field validations before marking the serial letter ready for send out.',
      de: 'Serienbrief mit fehlenden oder ungültigen Eingabefeldern gespeichert. '
        + 'Bitte prüfen Sie die Eingabefelder, bevor Sie den Brief versenden.',
    },
    sections: {
      letterDataSection: {
        title: {
          en: 'Letter data',
          de: 'Briefdaten',
        },
        propertySubsection: {
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          addMore: {
            en: 'Add property',
            de: 'Objekt hinzufügen',
          },
        },
        letterSubsection: {
          template: {
            en: 'Template',
            de: 'Vorlage',
          },
          subject: {
            en: 'Subject',
            de: 'Betreff',
          },
          body: {
            en: 'Body',
            de: 'Anschreiben',
          },
          watermark: {
            en: 'Watermark',
            de: 'Briefpapier',
          },
          signingPerson: {
            en: 'Signing person',
            de: 'Person für das Anschreiben ',
          },
        },
        attachments: {
          selectDocument: {
            en: 'Select attachment',
            de: 'Anhang auswählen',
          },
          addDocument: {
            en: 'Add attachment',
            de: 'Anhang hinzufügen',
          },
        },
      },
    },
  },
  notifications: {
    noChangeMsg: {
      message: {
        en: 'Nothing changed',
        de: 'Nichts hat sich verändert',
      },
    },
    loadSerialLetterError: {
      en: 'Failed loading serial letters',
      de: 'Fehler beim Laden des Briefs',
    },
    loadError: {
      en: 'Failed loading letter.',
      de: 'Fehler beim Laden der Briefe.',
    },
    saveSuccess: {
      en: 'Letter saved successfully.',
      de: 'Der Brief wurde erfolgreich gespeichert.',
    },
    saveError: {
      en: 'Letter could not be saved.',
      de: 'Brief konnte nicht gespeichert werden.',
    },
    validationError: {
      en: 'There are validation errors.',
      de: 'Es sind Validierungsfehler aufgetreten.',
    },
    deleteSuccess: {
      en: 'Letter deleted successfully',
      de: 'Der Brief wurde erfolgreich gelöscht',
    },
    deleteError: {
      en: 'Cannot delete letter',
      de: 'Der Brief kann nicht gelöscht werden',
    },
    validationErrors: {
      message: {
        en: 'There are validation errors.',
        de: 'Es sind Validierungsfehler aufgetreten.',
      },
    },
    prompt: {
      en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
      de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie weiter?',
    },
    failedToDownloadPdf: {
      message: {
        en: 'Failed to download document',
        de: 'Fehler beim Herunterladen des Dokuments',
      },
      invalidDataMessage: {
        en: 'Failed to download document because of validation errors',
        de: 'Fehler beim Herunterladen des Dokuments aufgrund von Validierungsfehlern',
      },
    },
    error: {
      [ErrorCodeUsingGETValueEnum.BAD_CONTACT_ADDRESS]: {
        en: ' does not have an address',
        de: ' hat keine Adresse',
      },
    },
    sendOutError: {
      message: {
        en: 'Could not send out letter.',
        de: 'Der Brief konnte nicht gesendet werden.',
      },
    },
    generateDocumentError: {
      en: 'Failed to generate documents.',
      de: 'Dokumentengenerierung fehlgeschlagen.',
    },
    sendSuccess: {
      message: {
        en: 'Started generating messages that will be sent out automatically.',
        de: 'Nachrichtengenerierung wurde gestartet und werden automatisch verschickt.',
      },
    },
  },
};
