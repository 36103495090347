import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { UnitContractProjectionDto } from 'api/accounting';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { Table } from 'antd';
import { UseOwnersMeetingInvitationUnitDocumentOverviewColumns } from './UseOwnersMeetingInvitationUnitDocumentOverviewColumns';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { ownersMeetingInvitationTranslations } from '../../translations/OwnersMeetingInvitationTranslations';

export const UseUnitOverviewAndUpload = (ownersMeeting: any, unitContracts: UnitContractProjectionDto[], setDirty: (dirty: boolean) => void) => {
  const { tl } = useContext(LanguageContext);

  const dataSource = useMemo(() => (ownersMeeting.units ? ownersMeeting.units.map((unit: any) => {
    const data = _.cloneDeep(unit);
    const unitContract = unitContracts.find(uc => uc.unitId === unit.unitId);
    if (unitContract) {
      data.ownerName = unitContract.mailingContact?.name;
    }
    if (unit.additionalDocuments) {
      unit.additionalDocuments.forEach((additionalDocument: any) => {
        data[`document${additionalDocument.type}`] = additionalDocument.url;
      });
    }
    return data;
  }): []), [ownersMeeting, unitContracts]);

  return {
    sectionId: 'unitOverview',
    title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitOverview.title),
    content: <Table
      dataSource={dataSource ?? []}
      loading={{
        indicator: <TableContentLoadingIndicator />,
        spinning: ownersMeeting.loading ?? false,
      }}
      rowKey="unitNrSharingDeclaration"
      columns={UseOwnersMeetingInvitationUnitDocumentOverviewColumns(ownersMeeting, setDirty)}
      scroll={{ y: 300 }}
      pagination={false}
    />,
  };
};
