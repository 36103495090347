import {
  useContext,
  useMemo,
} from 'react';

import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import {
  ProfitAndLossReportContext,
} from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { translations } from 'pages/ProfitAndLossReport/translations';
import Amount from 'storybook-components/typography/Amount/Amount';
import { PropertyDisplayDtoVatRateCountryCodeEnum, PropertyDisplayDtoVatRelevanceEnum } from 'api/accounting';
import {
  useIncomeTable,
} from '../../RentIncomeSection/components/IncomeTable/useIncomeTable';
import {
  useOtherIncomeTable,
} from '../../RentIncomeSection/components/OtherIncomeTable/useOtherIncomeTable';
import { useExpenseTable } from '../../ExpenseSection/components/useExpenseTable';

export const useVatResultTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useVatResultTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances, reportData } = profitAndLossReportContext;

  const vatRelevantGermanProperty = [PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT, PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT]
    .includes(reportData.property?.vatRelevance) && reportData.property?.vatRateCountryCode === PropertyDisplayDtoVatRateCountryCodeEnum.DE;


  const { vatDatasource: rentIncomeVatDatsource } = useIncomeTable();
  const { vatDatasource: otherIncomeVatDatasource } = useOtherIncomeTable();

  const firstDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    return [
      ...rentIncomeVatDatsource.filter(ds => ds.vatPercentage !== 0 && ds.vatPercentage != -1).map(ds => ({ label: `${tl(translations.report.sections.resultSection.rentIncomeVatTotal)} ${ds.vatPercentage}%`, value: ds.totalVat })),
      ...otherIncomeVatDatasource.filter(ds => ds.vatPercentage !== 0 && ds.vatPercentage != -1).map(ds => ({ label: `${tl(translations.report.sections.resultSection.otherIncomeVatTotal)} ${ds.vatPercentage}%`, value: ds.totalVat })),
    ];
  }, [accountBalances.data]);


  const firstSum = firstDataSource.reduce((acc, curr) => (acc + curr.value), 0);

  const { totalVatEligibility } = useExpenseTable();

  const secondDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    return [{ label: tl(translations.report.sections.resultSection.eligibleVatTotal), value: -totalVatEligibility }];
  }, [accountBalances.data]);

  const secondSum = firstSum - totalVatEligibility;


  const columns: ColumnsType<{ label: string, value: number }> = [
    {
      title: tl(translations.report.sections.resultSection.vatColumns.vatToBePaid),
      dataIndex: 'label',
      // width given otherwise the 2 tables below each other have different width
      // and the columns don't line up in the printed view
      width: '70%',
    },
    {
      title: tl(translations.report.sections.resultSection.vatColumns.amount),
      dataIndex: 'value',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  return {
    columns,
    firstDataSource,
    firstSum,
    secondDataSource,
    secondSum,
    vatRelevantGermanProperty,
  };
};
