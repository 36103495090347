import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { Link } from 'react-router-dom';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';

export const useDistributionCalculationAlerts = (
  getAccountDistributionPath,
  getAccountDistributionOverviewPath,
  reportEndDate,
  tl,
) => {
  const getAccountsWithIncorrectAccountDistribution = incorrectEntities => (
    <ul>
      {
        incorrectEntities.map(entity => (
          <li key={entity.accountCode}>
            <Link to={getAccountDistributionPath(entity.accountCode)} target="_blank" rel="noopener noreferrer">
              {`${entity.accountCode} - ${entity.accountName}`}
            </Link>
          </li>
        ))
      }
    </ul>
  );

  const getAccountsWithZeroValueDistributionSets = incorrectEntities => (
    <ul>
      {
        incorrectEntities.map(entity => (
          <li key={entity.accountCode}>
            <Link to={getAccountDistributionPath(entity.accountCode)} target="_blank" rel="noopener noreferrer">
              {`${entity.accountCode} - ${entity.accountName}: ${entity.distributionSet.name}`}
            </Link>
          </li>
        ))
      }
    </ul>
  );

  const incorrectDirectCostArticleLink = (
    <AlertArticleLink
      content={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.incorrectDirectCostArticleLinkTitle)}
      url={articleUrls.opsCostReport.incorrectDirectCost}
    />
  );

  const missingDSArticleLink = (
    <AlertArticleLink
      content={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.missingDistributionSetArticleLinkTitle)}
      url={articleUrls.opsCostReport.missingDistributionSet}
    />
  );

  const generalDSArticleLink = (
    <AlertArticleLink
      content={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.generalDistributionSetArticleLinkTitle)}
      url={articleUrls.opsCostReport.generalDistributionSet}
    />
  );

  const getIncorrectDirectCostAlertDescription = incorrectEntities => (
    <>
      {getAccountsWithIncorrectAccountDistribution(incorrectEntities)}
      {incorrectDirectCostArticleLink}
    </>
  );

  const getMissingDSAlertDescription = incorrectEntities => (
    <>
      {getAccountsWithIncorrectAccountDistribution(incorrectEntities)}
      {missingDSArticleLink}
    </>
  );

  const getDSWithZeroValueDescription = incorrectEntities => (
    <>
      {getAccountsWithZeroValueDistributionSets(incorrectEntities)}
      {generalDSArticleLink}
    </>
  );
  const getMissingAccountDistributionsMessage = () => (
    <>
      {
        tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.missingAccountDistributions)(reportEndDate)
      }
      <Link to={getAccountDistributionOverviewPath()} target="_blank" rel="noopener noreferrer">
        {tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.missingAccountDistributionsLink)}
      </Link>
    </>
  );

  return {
    getIncorrectDirectCostAlertDescription,
    getMissingDSAlertDescription,
    getDSWithZeroValueDescription,
    getMissingAccountDistributionsMessage,
  };
};
