import { LanguageContext } from 'contexts/LanguageContext';
import CurrencyInputWithPrefix from 'elements/Inputs/CurrencyInputWithPrefix/CurrencyInputWithPrefix';
import { useContext } from 'react';
import { translations } from '../../../../translations';
import { useInvoiceTotalGrossAmount } from './useInvoiceTotalGrossAmount';
import { PaymentDirectionSwitch } from '../../../../../../../storybook-components/inputs/PaymentDirectionSwitch/PaymentDirectionSwitch';

const InvoiceTotalGrossAmount = () => {
  const { tl } = useContext(LanguageContext);
  const {
    data, outgoingDirection, onChangeOutgoingValue, onChangeTotal, disabled,
  } = useInvoiceTotalGrossAmount();

  const prefix = (
    <PaymentDirectionSwitch
      defaultValue={(outgoingDirection ? 'outgoing' : 'incoming')}
      disabled={disabled}
      onChange={(value) => {
        onChangeOutgoingValue((value === 'outgoing'));
      }}
    />
  );

  return (
    <div className="input-column">
      <CurrencyInputWithPrefix
        onChange={(value) => {
          onChangeTotal('totalGross', value);
        }}
        value={Math.abs(data.invoice.totalGross)}
        inputClassName="number-input-with-switch"
        prefixComponent={prefix}
        label={tl(translations.invoiceEditing.sections.section3.subsection1.fields.totalAmount)}
        disabled={disabled}
        required
        autoFocus
        autoFormat
      />
    </div>
  );
};

export default InvoiceTotalGrossAmount;
