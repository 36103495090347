import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { NUMBER_MATCHER, DECIMAL_MATCHER, DATE_FORMAT } from '../../lib/Utils';
import { SuggestedFilter } from '../../components/Header/components/SearchBar/services/GlobalSearchContext';

export const FILTER_VALUE_DELIMITER = ' : ';
export const useSearchBar = ({
  searchString, dropdownVisible, currentlyEditedFilter, setCurrentlyEditedFilter, showDropdown, onApplyFilter,
}: {
  searchString: string | null, dropdownVisible: boolean, currentlyEditedFilter: SuggestedFilter | null, setCurrentlyEditedFilter: Function,
  showDropdown: (v?: boolean) => void, onApplyFilter: Function
}) => {
  const filterValueValid = useMemo(() => {
    const filterValue = searchString || '';

    if (_.isEmpty(searchString) || !currentlyEditedFilter) {
      return true;
    }

    if (currentlyEditedFilter.matcher) {
      return currentlyEditedFilter.matcher.test(filterValue);
    }

    switch (currentlyEditedFilter.type) {
      case 'number':
        if (!NUMBER_MATCHER.test(filterValue)) {
          return false;
        }
        break;
      case 'decimal':
        if (!DECIMAL_MATCHER.test(filterValue)) {
          return false;
        }
        break;
      case 'date':
        if (!moment(filterValue).isValid()) {
          return false;
        }
        break;
      default:
    }

    return true;
  }, [searchString]);

  useEffect(() => {
    // focus date input after date filter selection
    if (currentlyEditedFilter && currentlyEditedFilter.type === 'date') {
      const dateInput: HTMLInputElement | null = document.querySelector('.search-bar-input .MuiInput-input');
      if (dateInput) {
        dateInput.focus();
      }
    }
  }, [currentlyEditedFilter]);

  const onKeyDown = (e: any) => {
    if (currentlyEditedFilter) {
      // if ENTER pressed
      if ((e.keyCode === 13) && filterValueValid) {
        const filterValue = searchString || '';
        if (!_.isEmpty(filterValue)) {
          let applyFilterValue = filterValue;
          if (currentlyEditedFilter.type === 'date') {
            applyFilterValue = moment.utc(filterValue, DATE_FORMAT).format(DATE_FORMAT);
          }
          onApplyFilter({ ...currentlyEditedFilter, matchingValue: applyFilterValue }, e.keyCode === 13);
          setCurrentlyEditedFilter(null);
        }
        // if BACKSPACE pressed
      } else if (e.keyCode === 8 && _.isEmpty(searchString)) {
        setCurrentlyEditedFilter(null);
      }
      // if dropdown is hidden and an alphanumeric key pressed
    } else if (!dropdownVisible && /^[-_.,a-z0-9 ]$/i.test(e.key)) {
      showDropdown();
    }
  };

  return {
    filterValueValid,
    onKeyDown,
  };
};
