import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { watermarkTranslations } from '../../translations';
import useWatermarkEditorHeader from './useWatermarkEditorHeader';

interface WatermarkEditorHeaderProps {
  saveButtonDisabled: boolean;
  watermarkId: number;
  isWatermarkDirty: boolean;
  newFileUploaded: boolean;
  onSave: () => void;
}

export default function WatermarkEditorHeader({
  saveButtonDisabled, watermarkId, isWatermarkDirty, onSave, newFileUploaded,
}: WatermarkEditorHeaderProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { onClose } = useWatermarkEditorHeader(watermarkId, isWatermarkDirty, newFileUploaded);

  const cancelButton = (
    <Button
      type="text"
      onClick={onClose}
    >
      {tl(watermarkTranslations.close)}
    </Button>
  );

  const saveButton = (
    <Button type="primary" onClick={onSave} disabled={saveButtonDisabled}>{tl(watermarkTranslations.save)}</Button>
  );

  const rightSideButtons = (
    <>
      {cancelButton}
      {saveButton}
    </>
  );

  return (
    <PageHeader
      title={tl(watermarkTranslations.createTitle)}
      rightSideComponent={rightSideButtons}
    />
  );
}
