import React, { useState } from 'react';
import { isMasterLikeEnv } from 'config';
import { StringMap } from 'lib/Utils';
import { showNotification } from 'lib/Notification';
import { useQuery } from 'lib/useQuery';

interface LanguageContextType {
  language?: string,
  tl?: any, // currently is: (s: StringMap) => string | (() => string) - but defining it causes way too many troubles
  setLanguage?: (s: string) => void
}
export const LanguageContext = React.createContext<LanguageContextType>({});
export const languages = { en: 'English', de: 'Deutsch' };
export const defaultLanguage = isMasterLikeEnv ? 'de' : 'en';


export default function LanguageProvider({ children }: any): JSX.Element {
  const queryParams = useQuery();
  const language = queryParams.get('lang') || localStorage.getItem('language');
  const defaultContext = {
    language: languages[language] ? language : defaultLanguage,
  };
  const [languageState, setLanguageState] = useState<{ language: string }>(defaultContext);

  const tl = (obj: StringMap) => {
    try {
      return obj[languageState.language];
    } catch (error) {
      if (isMasterLikeEnv) {
        return '';
      }
      console.error('Translation error: ', error);
      showNotification({
        key: 'translation error',
        message: 'There was a translation error!',
        type: 'error',
      });
      return 'tl-err';
    }
  };

  const setLanguage = (lang: string) => {
    setLanguageState({ language: lang });
    localStorage.setItem('language', lang);
  };

  return (
    <LanguageContext.Provider value={{ ...languageState, tl, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
