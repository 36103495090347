import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { useContext } from 'react';
import { opsCostTranslation } from '../../translations';
import { useCreateOpsCostReportButton } from './useCreateOpsCostReportButton';

const CreateOpsCostReportButton = () => {
  const { tl } = useContext(LanguageContext);
  const { onCreateOpsCostReport } = useCreateOpsCostReportButton();

  return (
    <Button onClick={onCreateOpsCostReport} type="primary">
      {tl(opsCostTranslation.createButton)}
    </Button>
  );
};

export default CreateOpsCostReportButton;
