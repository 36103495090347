

import { useContext } from 'react';
import { ActiveContractsListContext } from './ActiveContractsListContext';


export const useInvalidateActiveContractsListCache = () => {
  const activeContractsListContext = useContext(ActiveContractsListContext);

  if (activeContractsListContext === undefined) {
    throw new Error('useInvalidateActiveContractsListCache must be used within an ActiveContractsListContextProvider!');
  }


  const {
    setParamOfCachedValue,
  } = activeContractsListContext;


  const invalidateCache = () => {
    setParamOfCachedValue(undefined);
  };


  return {
    invalidateCache,
  };
};
