import React, {
  useContext,
  useMemo,
} from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import { PersonEditingContext } from 'contexts/PersonEditingContext';
import { OptionInterface } from 'elements/Inputs/SmartSearch/SmartSearch';
import { translations } from 'elements/Translation/translations';
import { range } from 'lib/Utils';
import useCountryOptions from 'services/useCountryOptions';

import {
  ContactLegacyDtoDispatchPreferencesEnum,
} from '../../../../../api/accounting';
import {
  AddBankAccountButton,
} from './components/AddBankAccountButton/AddBankAccountButton';
import {
  BankAccountSubsection,
} from './components/BankAccountSubsection/BankAccountSubsection';
import defaultSection from './defaultSection';

interface ContactSectionProps {
  addressNumber: number,
  bankAccountNumber: number,
  duplicate: boolean
}

export default function useContactSection(props: ContactSectionProps) {
  const { addressNumber, bankAccountNumber, duplicate } = props;
  const { tl, language } = useContext(LanguageContext);
  const contactEditingContext: any = useContext(PersonEditingContext);

  const dispatchOptions = useMemo(() => Object.values(ContactLegacyDtoDispatchPreferencesEnum).map(dispatchType => ({
    value: dispatchType,
    label: tl(translations.pages.contactEditing.contactSection.contactSection.dispatchTypes[dispatchType]),
  })), [language]);

  const addAddressButton = [
    {
      type: 'addButton',
      key: 'addressNumber',
      props: {
        label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.add),
      },
    },
  ];
  const duplicateInfo = [
    {
      type: 'info',
      key: '',
      props: {
        label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.personDuplicate),
      },
    },
  ];

  function addressInputs(index: number, isLast: boolean, countryOptions: OptionInterface[]) {
    const contact = contactEditingContext.data ? contactEditingContext.data.contact : null;
    const isInvoicingAddress = contact && contact.addresses && contact.addresses[index] && contact.addresses[index].forInvoice;
    const section = [{
      sectionId: `address${index}`,
      title: isInvoicingAddress || index === 1 ? tl(translations.pages.contactEditing.contactSection.addressSection.invoicingAddress) : tl(translations.pages.contactEditing.contactSection.addressSection.mailingAddress),
      content: [
        [
          {
            type: 'text',
            key: `addresses[${index}].recipient`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.recipient),
            },
          },
          {},
        ],
        [
          {
            type: 'text',
            key: `addresses[${index}].street`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.street),
            },
          },
          {
            type: 'text',
            key: `addresses[${index}].number`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.number),
            },
          },
        ],
        [
          {
            type: 'text',
            key: `addresses[${index}].additionalAddress1`,
            props: {
              label: `${tl(translations.pages.contactEditing.contactSection.addressSection.fields.additional)} 1`,
            },
          },
          {
            type: 'text',
            key: `addresses[${index}].additionalAddress2`,
            props: {
              label: `${tl(translations.pages.contactEditing.contactSection.addressSection.fields.additional)} 2`,
            },
          },
        ],
        [
          {
            type: 'text',
            key: `addresses[${index}].postalCode`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.postalCode),
            },
          },
          {
            type: 'text',
            key: `addresses[${index}].city`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.location),
            },
          },
        ],
        [
          {
            type: 'select',
            key: `addresses[${index}].country`,
            props: {
              required: true,
              showSearch: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.country.title),
              options: countryOptions,
            },
          },
          {},
        ],
      ],
    }];
    if (isLast && index === 0) section[0].content.push(addAddressButton);
    if (isLast && duplicate) section[0].content.push(duplicateInfo);
    return section;
  }

  function useGetAddressInputs(numberAddresses: number) {
    const displayNumber = Math.max(0, numberAddresses - 1);
    const { countryOptions } = useCountryOptions();
    return range(0, displayNumber).reduce((accumulator: Array<any>, number: number) => accumulator.concat(addressInputs(number, number === displayNumber, countryOptions)), []);
  }

  function bankInputs(index: number, isLast: boolean) {
    const section = [{
      sectionId: `account${index}`,
      title: `${tl(translations.pages.contactEditing.contactSection.bankSection.title)} ${index + 1} (${tl(translations.pages.contactEditing.contactSection.bankSection.optional)})`,
      content: <BankAccountSubsection indexOfBankAccount={index} addButton={isLast} />,
    }];
    return section;
  }

  function addBankAccountButton() {
    const section = [{
      sectionId: 'add-bank-account',
      title: tl(translations.pages.contactEditing.contactSection.bankSection.title),
      content: <AddBankAccountButton />,
    }];
    return section;
  }
  function getBankInputs(numberAccounts: number) {
    const displayNumber = Math.max(0, numberAccounts - 1);
    if (numberAccounts === 0) return addBankAccountButton();
    return range(0, displayNumber).reduce((accumulator: Array<any>, number: number) => accumulator.concat(bankInputs(number, number === displayNumber)), []);
  }

  return {
    ...defaultSection(0),
    sectionTitle: tl(translations.pages.contactEditing.contactSection.title),
    sectionId: 'ContactData',
    content: [
      {
        sectionId: 'person',
        title: tl(translations.pages.contactEditing.contactSection.personSection.title),
        content: [
          [
            {
              type: 'select',
              key: 'title',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.personSection.fields.title),
                allowClear: true,
                options: [
                  {
                    value: 'Prof.',
                    label: tl(translations.pages.contactEditing.contactSection.personSection.fields.titleOptions.prof),
                  },
                  {
                    value: 'Prof. Dr.',
                    label: tl(translations.pages.contactEditing.contactSection.personSection.fields.titleOptions.profDr),
                  },
                  {
                    value: 'Dr.',
                    label: tl(translations.pages.contactEditing.contactSection.personSection.fields.titleOptions.dr),
                  },
                ],
              },
            },
            {
              type: 'select',
              key: 'salutation',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.personSection.fields.salutation.title),
                allowClear: true,
                options: [
                  {
                    value: 'MR',
                    label: tl(translations.pages.contactEditing.contactSection.personSection.fields.salutation.mr),
                  },
                  {
                    value: 'MRS',
                    label: tl(translations.pages.contactEditing.contactSection.personSection.fields.salutation.mrs),
                  },
                ],
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'firstName',
              props: {
                required: true,
                label: tl(translations.pages.contactEditing.contactSection.personSection.fields.firstName),
              },
            },
            {
              type: 'text',
              key: 'lastName',
              props: {
                required: true,
                label: tl(translations.pages.contactEditing.contactSection.personSection.fields.lastName),
              },
            },
          ],
          [
            {
              type: 'date',
              key: 'dateOfBirth',
              props: {
                format: 'DD.MM.YYYY',
                label: tl(translations.pages.contactEditing.contactSection.personSection.fields.birthday),
                placeholder: tl(translations.pages.contactEditing.contactSection.personSection.fields.birthdayPlaceholder),
              },
            },
            {
              type: 'textArea',
              key: 'notes',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.personSection.fields.note),
              },
            },
          ],
        ],
      },
      ...useGetAddressInputs(addressNumber),
      ...getBankInputs(bankAccountNumber),
      {
        sectionId: 'phones',
        title: tl(translations.pages.contactEditing.contactSection.phoneSection.title),
        content: [
          [
            {
              type: 'text',
              key: 'email',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.email),
              },
            },
            {
              type: 'text',
              key: 'phoneBusiness',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.phoneBusiness),
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'phoneMobile',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.phoneMobile),
              },
            },
            {
              type: 'text',
              key: 'phonePrivate',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.phonePrivate),
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'fax',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.fax),
              },
            },
            {},
          ],
        ],
      },
      {
        sectionId: 'contact',
        title: `${tl(translations.pages.contactEditing.contactSection.contactSection.title)}`,
        content: [
          [
            {
              type: 'checkboxGroup',
              key: 'dispatchPreferences',
              props: {
                label: '',
                options: dispatchOptions,
              },
            },
            { },
          ],
        ],
      },
    ],
  };
}
