import React, { useContext } from 'react';

import { Tooltip } from 'antd';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import {
  getPropertyCellSubtitleInList,
} from 'lib/utilGetPropertyCellSubtitleInList';
import moment from 'moment';
import CellWithTwoLines
  from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

import { LanguageContext } from '../../../contexts/LanguageContext';
import { DATE_FORMAT } from '../../../lib/Utils';
import StatusCell from './components/StatusCell';
import { translations } from './translations';

export const useWkaListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.wkaList.tableColumns.state),
      dataKey: 'sent',
      render: (_, record) => (
        <StatusCell record={record} />
      ),
      defaultWidth: '10%',
      sortable: false,
    },
    {
      title: tl(translations.wkaList.tableColumns.property),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.wkaList.tableColumns.title),
      dataKey: 'title',
    },
    {
      title: tl(translations.wkaList.tableColumns.dateRange),
      dataKey: 'dateRange',
      render: ((_, record) => `${moment(record.startDate).format(DATE_FORMAT)} - ${moment(record.endDate).format(DATE_FORMAT)}`),
      defaultWidth: '15%',
      sortable: false,
    },
    {
      title: tl(translations.wkaList.tableColumns.totalCosts),
      dataKey: 'totalCost',
      className: 'column-align-right',
      format: FORMAT.CURRENCY,
      defaultWidth: '10%',
    },
    {
      title: tl(translations.wkaList.tableColumns.accountant),
      dataKey: 'accountantName',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(translations.wkaList.tableColumns.administrator),
      dataKey: 'supervisorName',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(translations.wkaList.tableColumns.created),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
    {
      title: tl(translations.wkaList.tableColumns.createdBy),
      dataKey: 'createdBy',
      sortable: false,
      defaultVisible: false,
    },
  ];
};
