import { ExtendedExchangeProjectionTypeEnum } from 'api/accounting';
import Button from 'elements/Buttons/Button/Button';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import React from 'react';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import { usePostingTextsCell } from './usePostingTextsCell';
import PurposeEditorModal from '../PurposeEditorModal/PurposeEditorModal';

interface Props {
  record: ServiceCompanyAllocationValues,
  selectedTransactions: ExtendedBankTransaction[],
}

const PurposeEditorCell = ({ record, selectedTransactions }: Props) => {
  const { postingTexts, onChangePostingText, navigateToInvoice } = usePostingTextsCell();

  if (selectedTransactions === undefined) return null;

  if (record.type !== ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
    return (
      <Button className="allocation-invoice-link" type="text" onClick={e => navigateToInvoice(e, record)}>
        <PopoverTruncatingText value={record.purpose ?? ''} containerClassName="ExchangeList" />
      </Button>
    );
  }

  if (selectedTransactions.length === 1) {
    const selectedTransactionId = selectedTransactions[0].bankTransactionId!;
    const defaultPostingText = postingTexts.find(pt => pt.key === record.key && pt.transactionId === selectedTransactionId)?.postingText;
    return <TextInput defaultValue={defaultPostingText} onChange={val => onChangePostingText(record.key, selectedTransactionId, val)} />;
  }


  return (
    <PurposeEditorModal
      record={record}
      selectedTransactions={selectedTransactions}
    />
  );
};

export default PurposeEditorCell;
