import {
  PropertyLegacyControllerApi, PropertyLegacyDtoVatRelevanceEnum, UnitContractControllerApi, UnitLegacyControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import _ from 'lodash';
import moment from 'moment';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { useContext, useEffect } from 'react';
import { calculateEligibilityShareValue, getActiveVatRelevantUnits } from 'services/vatEligibility/vatEligibilityUtils';
import { PropertyVatEligibilityInfoContext } from './ServiceCompanyAllocationContext';

export const useInitializePropertyVatEligibilityInfo = (propertyHrIds: string[], selectedBankTransactions: ExtendedBankTransaction[]) => {
  const context = useContext(PropertyVatEligibilityInfoContext);

  if (context === undefined) {
    throw new Error('useInitializePropertyVatEligibilityInfo must be used within a ServiceCompanyAllocationContextProvider');
  }

  const { setPropertyVatEligibilityInfo } = context;

  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const unitsControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (_.isEmpty(propertyHrIds)) return;
    initialize();
  }, [propertyHrIds]);

  const initialize = () => {
    const promises = selectedBankTransactions[0]?.propertyList?.map((p) => {
      if (p.vatRelevance === PropertyLegacyDtoVatRelevanceEnum.NOT_RELEVANT) {
        return Promise.resolve({
          propertyId: p.propertyId,
          propertyHrId: p.propertyHrId,
          vatEligibilityShare: undefined,
        });
      }
      if (p.vatRelevance === PropertyLegacyDtoVatRelevanceEnum.FULLY_RELEVANT) {
        return Promise.resolve({
          propertyId: p.propertyId,
          propertyHrId: p.propertyHrId,
          vatEligibilityShare: 100,
        });
      }

      return getVatEligibilityShareForPartiallyRelevantProperty(p.propertyId, p.propertyHrId);
    });
    Promise.all(promises)
      .then((propertyVatEligibilityInfo) => {
        setPropertyVatEligibilityInfo(propertyVatEligibilityInfo);
      });
  };

  const getVatEligibilityShareForPartiallyRelevantProperty = (propertyId: number, propertyHrId: string) => {
    const prpPromise = propertyControllerApi.getPropertyDisplayByIdUsingGET({ propertyId });

    const contractPromise = unitContractControllerApi.getUnitContractsUsingGET({
      propertyId,
      atDate: moment().format('YYYY-MM-DD'),
    });

    const unitPromise = unitsControllerApi.getSimpleUnitsUsingGET({ propertyId });

    return Promise.all([prpPromise, contractPromise, unitPromise])
      .then(([property, unitContracts, units]) => {
        const activeContracts = unitContracts.filter(contract => contract.unitContractId !== undefined);
        const vatRelevantUnits = getActiveVatRelevantUnits(units, activeContracts);
        const eligibilityShare = calculateEligibilityShareValue(property, units, vatRelevantUnits);

        return Promise.resolve({
          propertyId,
          propertyHrId,
          vatEligibilityShare: eligibilityShare,
        });
      })
      .catch((err) => {
        console.error(err);
        // this value will only be used as a default, would not block allocation if it cannot be loaded
        return Promise.resolve({
          propertyId,
          propertyHrId,
          vatEligibilityShare: undefined,
        });
      });
  };
};
