import Big from 'big.js';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import _ from 'lodash';
import { useContext } from 'react';

export const useNetAndVatAmount = (index: number) => {
  const { invoiceBookings, changeInvoiceBookings, partiallyVatRelevantProperty } = useContext(InvoiceEditingContext);


  const onChangeVatPercentage = (vatPercentage: number) => {
    const grossAmount = sanitizeValue(invoiceBookings[index].amount);
    vatPercentage = sanitizeValue(vatPercentage);
    changeInvoiceBookings({
      type: 'update', key: 'vatPercentage', idx: index, value: vatPercentage,
    });

    updateVatAmount(grossAmount, vatPercentage);
    updateNetAmount(grossAmount, vatPercentage);
  };

  const onChangeGrossAmount = (grossAmount: number) => {
    const signedGrossAmount = getSign() * sanitizeValue(grossAmount);
    changeInvoiceBookings({
      type: 'update', key: 'amount', idx: index, value: signedGrossAmount,
    });

    const vatPercentage = sanitizeValue(invoiceBookings[index].vatPercentage);
    updateNetAmount(signedGrossAmount, vatPercentage);
    updateVatAmount(signedGrossAmount, vatPercentage);
  };

  const onChangeNetAmount = (netAmount: number) => {
    const vatPercentage = sanitizeValue(invoiceBookings[index].vatPercentage);
    const signedNetAmount = getSign() * sanitizeValue(netAmount);
    changeInvoiceBookings({
      type: 'update', key: 'netAmount', idx: index, value: signedNetAmount,
    });

    updateGrossAmount(signedNetAmount, vatPercentage);
    updateVatAmount(calculateGrossAmount(signedNetAmount, vatPercentage), vatPercentage);
  };

  const updateNetAmount = (grossAmount: number, vatPercentage: number) => {
    const grossAmountBig = new Big(sanitizeValue(grossAmount));
    const vatPercentageBig = new Big(sanitizeValue(vatPercentage));
    const netAmount = grossAmountBig.toNumber() !== 0
      ? parseFloat(grossAmountBig.times(new Big(100)).div(new Big(100).plus(vatPercentageBig)).toFixed(2))
      : 0;
    changeInvoiceBookings({
      type: 'update', key: 'netAmount', idx: index, value: netAmount,
    });
  };

  const updateGrossAmount = (netAmount: number, vatPercentage: number) => {
    const r = calculateGrossAmount(netAmount, vatPercentage);

    changeInvoiceBookings({
      type: 'update', key: 'amount', idx: index, value: r,
    });
  };


  const updateVatAmount = (grossAmount: number, vatPercentage: number) => {
    const r = calculateVatAmount(grossAmount, vatPercentage);

    changeInvoiceBookings({
      type: 'update', key: 'vatAmount', idx: index, value: r,
    });

    if (invoiceBookings[index].vatEligibilityPercentage !== undefined) {
      updateVatEligibilityAmount(r, sanitizeValue(invoiceBookings[index].vatEligibilityPercentage));
    }

  };

  const updateVatEligibilityAmount = (vatAmount: number, vatEligibilityPercentage: number) => {
    const r = calculateVatEligibilityAmount(vatAmount, vatEligibilityPercentage);

    changeInvoiceBookings({
      type: 'update', key: 'vatEligibilityAmount', idx: index, value: r,
    });
  }

  const calculateVatAmount = (grossAmount: number, vatPercentage: number) => {
    const grossAmountBig = new Big(sanitizeValue(grossAmount));
    const vatPercentageBig = new Big(sanitizeValue(vatPercentage));
    return grossAmountBig.toNumber() !== 0
      ? parseFloat(grossAmountBig.times(vatPercentage).div(new Big(100).plus(vatPercentageBig)).toFixed(2))
      : 0;
  };

  const calculateGrossAmount = (netAmount: number, vatPercentage: number) => {
    const netAmountBig = new Big(sanitizeValue(netAmount));
    const vatPercentageBig = new Big(sanitizeValue(vatPercentage));
    return netAmountBig.toNumber() !== 0
      ? parseFloat(netAmountBig.times(new Big(100).plus(vatPercentageBig)).div(new Big(100)).toFixed(2))
      : 0;
  };

  const calculateVatEligibilityAmount = (vatAmount: number, vatEligibilityPercentage: number) => {
    const vatAmountBig = new Big(sanitizeValue(vatAmount));
    const vatEligibilityPercentageBig = new Big(sanitizeValue(vatEligibilityPercentage));
    return vatAmountBig.toNumber() !== 0
      ? parseFloat(vatAmountBig.times(vatEligibilityPercentageBig).div(new Big(100)).toFixed(2))
      : 0;
  };

  const onChangeVatEligibilityPercentage = (eligibilityPercentage: number) => {
    changeInvoiceBookings({
      type: 'update', key: 'vatEligibilityPercentage', idx: index, value: Math.abs(eligibilityPercentage),
    });

    const vatAmount = sanitizeValue(invoiceBookings[index].vatAmount);
    updateVatEligibilityAmount(vatAmount, Math.abs(eligibilityPercentage));
  };

  const sanitizeValue = (amt?: number) => ((!_.isNil(amt) && !Number.isNaN(amt)) ? amt : 0);

  const getSign = (): number => (invoiceBookings[index].incoming ? 1 : -1);

  const vatOptions = [
    {
      value: 19,
      label: '19%',
    },
    {
      value: 7,
      label: '7%',
    },
    {
      value: 16,
      label: '16%',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 20,
      label: '20%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 0,
      label: '0%',
    },
  ];

  return {
    invoiceBookings,
    onChangeVatPercentage,
    changeInvoiceBookings,
    onChangeNetAmount,
    onChangeGrossAmount,
    vatOptions,
    displayVatEligibilityFields: partiallyVatRelevantProperty,
    onChangeVatEligibilityPercentage,
  };
};
