import { get, isEmpty } from 'lodash';


/**
 * Merges two lists while removing duplicates based on a specified identifier.
 *
 * @template T - The type of items in the lists.
 * @param {T[]} targetList - The target list to merge into.
 * @param {T[]} sourceList - The source list to merge from.
 * @param {string | string[] | ((item: T) => string | number)} identifierAccessor - The identifier accessor for the items in both lists.
 * @param {boolean} resetPage - Whether the pagination was reset. If true, the target list will be replaced with the source list.
 * @returns {T[]} - The merged list with duplicates removed.
 */
export function mergeListsRemovingDuplicates<T>(
  targetList: T[],
  sourceList: T[],
  identifierAccessor: string | string[] | ((item: T) => string | number),
  resetPage: boolean,
) {
  if (resetPage) {
    return sourceList;
  }

  if (isEmpty(targetList)) {
    return sourceList;
  }

  if (isEmpty(sourceList)) {
    return targetList;
  }

  const getItemIdentifier = typeof identifierAccessor === 'function'
    ? identifierAccessor
    : (item: T) => get(item, identifierAccessor);

  const uniqueNewItems = sourceList.filter(
    newItem => !targetList.some(
      existingItem => getItemIdentifier(existingItem) === getItemIdentifier(newItem),
    ),
  );

  return targetList.concat(uniqueNewItems);
}
