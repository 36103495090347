import {
  useContext,
  useMemo,
} from 'react';

import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';
import { DistributionKeyContext } from 'contexts/DistributionKeyContext';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  PropertyDistributionKeyListContext,
} from 'contexts/PropertyDistributionKeyListContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { compareAccountCodes } from 'lib/Utils';
import _, { isEmpty } from 'lodash';
import {
  DISTRIBUTION_FOR_OPS_COST,
} from 'pages/DistributionKeys/AccountDistributionEditor/services/utils';
import {
  useLocation,
  useParams,
} from 'react-router';

import { translations } from '../../../elements/Translation/translations';

const sortRecursivelyByAccountCode = (data: any[]) => {
  data?.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode));

  data?.forEach((item) => {
    if (item.children) {
      sortRecursivelyByAccountCode(item.children);
    }
  });

  return data;
};


export const usePropertyDistributionKeysTable = () => {
  const { tl } = useContext(LanguageContext);
  const { propertyDistributionKeyListState, selectedEconomicYear }:any = useContext(PropertyDistributionKeyListContext);
  const { selectedProperty }: any = useContext(PropertyListContext);


  const { setSelectedEconomicYear }: any = useContext(DistributionKeyContext);

  const { economicYear } = useParams<{ economicYear: string }>();
  const economicYearValue = economicYear ? parseInt(economicYear, 2) : selectedEconomicYear;

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const isForOpsCost = urlSearchParams.get('distributionFor') === DISTRIBUTION_FOR_OPS_COST;

  const isWeg = selectedProperty?.data?.administrationType === 'WEG';

  const isAccountWkaRelevant = (data: any) => !_.isNil(data.heatingCenterId);

  const toTableData = (administrationType: PropertyLegacyDtoAdministrationTypeEnum, data: any): any => {
    const newData = {
      ...data,
      economicPlan: data.economicPlan ? tl(translations.enums.distributionMode[data.economicPlan]) : '',
      houseMoneySettlement: data.houseMoneySettlement ? tl(translations.enums.distributionMode[data.houseMoneySettlement]) : '',
      wkaRelevant: isAccountWkaRelevant(data),
      economicYear: economicYearValue,
    };

    if (newData.children) {
      newData.wkaRelevant = undefined; // do not show for parents
      newData.children = newData.children.map(c => toTableData(administrationType, c));
    }

    return newData;
  };


  const incomeTableData = useMemo(() => (
    !isEmpty(propertyDistributionKeyListState.data)
      ? sortRecursivelyByAccountCode(propertyDistributionKeyListState.data?.income?.map(data => toTableData(selectedProperty.data?.administrationType, data)))
      : []
  ), [propertyDistributionKeyListState.data?.income]);


  const expensesTableData = useMemo(() => (
    !isEmpty(propertyDistributionKeyListState.data)
      ? sortRecursivelyByAccountCode(propertyDistributionKeyListState.data?.expenses?.map(data => toTableData(selectedProperty.data?.administrationType, data)))
      : []
  ), [propertyDistributionKeyListState.data?.expenses]);

  const reserveFundsTableData = useMemo(() => (
    !isEmpty(propertyDistributionKeyListState.data)
      ? sortRecursivelyByAccountCode(propertyDistributionKeyListState.data?.reserveFunds?.map(data => toTableData(selectedProperty.data?.administrationType, data)))
      : []
  ), [propertyDistributionKeyListState.data?.reserveFunds]);


  return {
    incomeTableData,
    expensesTableData,
    reserveFundsTableData,
    isWeg,
    isForOpsCost,
    selectedEconomicYear: economicYearValue,
    propertyDistributionKeyListState,
    setSelectedEconomicYear,
  };
};
