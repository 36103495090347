import { useHistory, useLocation } from 'react-router';

export const useCreateOpsCostReportButton = () => {
  const history = useHistory();
  const location = useLocation();

  const onCreateOpsCostReport = () => {
    history.push(`${location.pathname}/create`);
  };

  return {
    onCreateOpsCostReport,
  };
};
