import {
  Configuration, DocumentLegacyControllerApi, DocumentProjectionDto, DocumentProjectionDtoStateEnum,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { showNotification } from 'lib/Notification';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import usePoll from 'services/usePoll';
import { documentPreviewTranslations } from './translations';

export const useDocumentPreviewPage = () => {
  const { tl } = useContext(LanguageContext);
  const { documentDbId } = useParams<{ documentDbId: string }>();

  const [document, setDocument] = useState<DefaultDataInterface<DocumentProjectionDto>>(DEFAULT_DATA({}));

  const { goBack } = useContext(OverlayContext);

  useEffect(() => {
    initialize();
  }, [documentDbId]);

  const { apiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as Configuration);

  const initialize = () => {
    if (documentDbId !== undefined) {
      loadDocument(parseInt(documentDbId, 10));
    }
  };

  const loadDocument = (documentId: number) => {
    setDocument(prev => prev.startLoading());
    documentControllerApi.getDocumentByIdUsingGET({ documentId })
      .then((resp) => {
        setDocument(prev => prev.load(resp));
      })
      .catch((err) => {
        console.error(err);
        setDocument(prev => prev.failed());
        showNotification({
          key: 'documentLoadError',
          message: tl(documentPreviewTranslations.loadError),
          type: 'error',
        });
      });
  };

  const subtitle = `${document.data?.propertyIdInternal || ''} · ${document.data?.propertyName || ''}`;
  const inGeneration = useMemo(() => [DocumentProjectionDtoStateEnum.GENERATING, DocumentProjectionDtoStateEnum.DRAFT].includes(document.data?.state), [document.data?.state]);
  const generationFailed = useMemo(() => [DocumentProjectionDtoStateEnum.FAILED].includes(document.data?.state), [document.data?.state]);

  const filename = document.data?.name?.trim();

  usePoll({
    shouldPoll: inGeneration,
    pollFunction: initialize,
    timeoutSeconds: 5000,
  });

  return {
    title: document.data?.name,
    subtitle,
    url: document.data?.url,
    filename,
    loading: document.loading,
    inGeneration,
    generationFailed,
    goBack,
  };
};
