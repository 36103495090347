import { KeyboardEvent } from 'react';
import { CellWithTwoLinesHookProps } from './interfaces';


export const useCellWithTwoLines = ({
  onClickFirstElement, onClickSecondElement, onClickWrapperElement, disabled,
}: CellWithTwoLinesHookProps) => {
  const onClickWrapper = (e) => {
    if (onClickWrapperElement !== undefined && !disabled) {
      e.stopPropagation();
      onClickWrapperElement();
    }
  };

  const onClickFirst = (e) => {
    if (onClickFirstElement !== undefined) {
      e.stopPropagation();
      onClickFirstElement();
    }
  };

  const onClickSecond = (e) => {
    if (onClickSecondElement !== undefined && !disabled) {
      e.stopPropagation();
      onClickSecondElement();
    }
  };

  // to allow triggering the onClick with Spacebar when element is focused with Tab
  const onKeyDownWrapper = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      onClickWrapper(e);
    }
  };

  // - set tabIndex to 0 so that they can be focused through the keyboard
  // - set to undefined if no onClick was provided so that they can't be focused
  const tabIndexWrapper = onClickWrapperElement && 0;
  const tabIndexFirstElement = onClickFirstElement && 0;
  const tabIndexSecondElement = onClickSecondElement && 0;

  return {
    onClickFirst,
    onClickSecond,
    onClickWrapper,
    onKeyDownWrapper,
    tabIndexWrapper,
    tabIndexFirstElement,
    tabIndexSecondElement,
  };
};
