import {
  ContractLegacyControllerApi, ContractProjectionDtoTypeEnum, PropertyLegacyControllerApi, PropertyLegacyDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import { getContractEditingRoute, getPropertyOwnerContractEditingRoute } from 'pages/Property/routes';
import { contractPageTranslations } from './contractPageTranslations';
import { LoadingScreen } from '../../storybook-components/feedback/LoadingScreen/LoadingScreen';

/**
 * Redirects to contract viewing pages
 * that currently only exist/can be accessed as overlays on the property page
 */
export const ContractPage = () => {
  const [redirectPath, setRedirectPath] = useState('');
  const { tl } = useContext(LanguageContext);

  const { apiConfiguration } = useContext(AuthContext);
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const { contractId: contractIdString } = useParams<{ contractId: string }>();
  const contractId = parseInt(contractIdString, 10);

  const loadRedirectPath = () => {
    contractControllerApi.getUnitContractUsingGET({ contractId })
      .then((contract) => {
        const { propertyId, unitId } = contract;
        return propertyControllerApi.getPropertyByIdUsingGET({ propertyId })
          .then((property: PropertyLegacyDto) => {
            const { propertyHrId } = property;
            if (contract.type === ContractProjectionDtoTypeEnum.OWNER
              || contract.type === ContractProjectionDtoTypeEnum.TENANT) {
              setRedirectPath(getContractEditingRoute({ propertyHrId, unitDbId: unitId, contractDbId: contractId }));
            } else if (contract.type === ContractProjectionDtoTypeEnum.PROPERTY_OWNER) {
              setRedirectPath(getPropertyOwnerContractEditingRoute({ propertyHrId, contractDbId: contractId }));
            } else {
              // assuming the unknown case is still somewhere listed in the property, this is a good enough redirect
              setRedirectPath(`/properties/${propertyId}`);
            }
          });
      }).catch(() => {
        showNotification({ type: 'warning', message: tl(contractPageTranslations.contractNotFound) });
        setRedirectPath('/properties');
      });
  };
  useEffect(loadRedirectPath, [contractId]);
  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (<LoadingScreen />);
};
