import { lazy } from 'react';

export const HEATING_COST_ENERGY_EDITOR_PAGE = lazy(() => import('./HeatingCostEnergyEditor/HeatingCostEnergyEditor'));

export const HEATING_COST_ENERGY_CREATE_PATH = '/:heatingCostGroupId/heating-cost-energy/create';
export const HEATING_COST_ENERGY_EDIT_PATH = '/:heatingCostGroupId/heating-cost-energy/:hceId';

export const HEATING_COST_OTHER_EDITOR_PAGE = lazy(() => import('./HeatingCostOtherEditor/HeatingCostOtherEditor'));

export const HEATING_COST_OTHER_CREATE_PATH = '/:heatingCostGroupId/heating-cost-other/create';
export const HEATING_COST_OTHER_EDIT_PATH = '/:heatingCostGroupId/heating-cost-other/:hcoId';
