import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import React from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
  title: string,
  laborCostSum: number,
  yourShareSum: number,
}

const Annex35aSectionTableSummary = ({
  title,
  laborCostSum,
  yourShareSum,
}: Props) => (
  <Table.Summary>
    <Table.Summary.Row className="Annex35aSectionTableSummary">
      <Table.Summary.Cell index={0} colSpan={4}>
        {title}
      </Table.Summary.Cell>

      <Table.Summary.Cell index={1} colSpan={1} className="column-align-right">
        <Amount>{formatCurrency(laborCostSum, '-', false)}</Amount>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={2} colSpan={1} className="column-align-right">
        <Amount>{formatCurrency(yourShareSum, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default Annex35aSectionTableSummary;
