import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { getStateColor } from 'storybook-components/headers/utils';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import CellWithTwoLinesAndTag from 'storybook-components/table/CellWithTwoLinesAndTag/CellWithTwoLinesAndTag';
import { uniq } from 'lodash';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { Column, FORMAT } from 'elements/SmartTable/SmartTable';
import { translations } from '../../../translations';

export const useSerialLetterListColumns = (): Column[] => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.list.status),
      dataKey: 'translatedStatus',
      render: (_, r) => (<ColorDot color={getStateColor(r.shippingStatus) as DotColorEnum}>{r.translatedStatus}</ColorDot>),
    },
    {
      title: tl(translations.list.property),
      dataKey: 'propertyIdInternal',
      sortable: false,
      render: (_, row) => (
        <CellWithTwoLinesAndTag
          firstElement={row.properties?.[0]?.propertyName}
          secondElement={getPropertyCellSubtitleInList(row.properties?.[0], 'propertyAdministrationType')}
          tagLabel={row.properties.length > 1 ? `+${row.properties.length - 1}` : undefined}
        />
      ),
    },
    {
      title: tl(translations.list.accountant),
      dataKey: 'accountant',
      sortable: false,
      render: (_, record) => (
        <PopoverTruncatingText
          value={uniq(record.properties.map(p => p.accountantName)).join(', ')}
          tooltipMessage={uniq(record.properties.map(p => p.accountantName)).join('\n')}
        />
      ),
      defaultVisible: false,
    },
    {
      title: tl(translations.list.supervisor),
      dataKey: 'supervisor',
      sortable: false,
      render: (_, record) => (
        <PopoverTruncatingText
          value={uniq(record.properties.map(p => p.supervisorName)).join(', ')}
          tooltipMessage={uniq(record.properties.map(p => p.supervisorName)).join('\n')}
        />
      ),
      defaultVisible: false,
    },
    {
      title: tl(translations.list.subject),
      dataKey: 'subject',
    },
    {
      title: tl(translations.list.sender),
      dataKey: 'signingPersonName',
    },
    {
      title: tl(translations.list.created),
      dataKey: 'created',
      format: FORMAT.DATE,
      sortable: true,
      defaultVisible: false,
    },
    {
      title: tl(translations.list.createdBy),
      dataKey: 'createdBy',
      sortable: false,
      defaultVisible: false,
    },
  ];
};
