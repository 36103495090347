import React from 'react';
import InformationBar from 'storybook-components/feedback/InformationBar/InformationBar';
import { useInvoiceBookingSummaryInfoBar } from './useInvoiceBookingSummaryInfoBar';


const InvoiceBookingSummaryInfoBar = () => {
  const { infoBarContent, isPdfView } = useInvoiceBookingSummaryInfoBar();

  return (
    <InformationBar
      content={infoBarContent}
      color="green"
      delimiter="|"
      vertical={isPdfView}
    />
  );
};

export default InvoiceBookingSummaryInfoBar;
