import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { EInvoiceData, EInvoiceItem } from '../types';
import { eInvoiceTranslations } from '../translations';
import './EInvoiceReportPageView.scss'

export const EInvoiceReportPageView: React.FC<{ invoice: EInvoiceData }> = ({ invoice }) => {
  const { tl } = useContext(LanguageContext);
  function getGrossBasedOnNetAndVatPercent(item: EInvoiceItem): any {
    if (item.vatPercent) {
      return Math.round(item?.totalNet * (1 + item?.vatPercent / 100 || 0) * 100) / 100;
    } else {
      return item?.totalNet
    }
  }

  const EInvoiceAmount = ({ amount }) => {
    amount = Math.round(amount * 100);
    if (typeof amount !== 'undefined') {
      const wholeEuro = ~~(amount / 100);
      const cents = Math.abs(amount % 100);
      return (
        <span className="amount">
          {safe(wholeEuro)}
          ,
          {safe(cents < 10 ? '0' : '')}
          {safe(cents)}
          &euro;
        </span>
      );
    }
    return (<span className="amount">-,- &euro;</span>);
  };

  const safe = (v: any, def: any = '') => {
    if (typeof v === 'undefined') {
      return def;
    }
    if (v === null) {
      return def;
    }
    return v;
  };

  const EInvoiceHeader = () => (
    <header>
      {/* <img className="logo" src="public/einvoice-logo.png" alt="" /> */}
      <div className="invoice-header">
        <div className="logo">E-Rechnung</div>
        <dl className="company-info">
          <dt />
          <div className="company-data name">
            {safe(invoice?.supplier?.name)}
            {' '}
            {safe(invoice?.supplier?.companyName)}
          </div>
          <div className="company-data">
            {safe(invoice?.supplier?.street)}
            {' '}
            {safe(invoice?.supplier?.postalCode)}
            {' '}
            {safe(invoice?.supplier?.city)}
            {' '}
            {safe(invoice?.supplier?.country)}
          </div>
          <div className="company-data">{safe(invoice?.supplier?.mail)}</div>
          <div className="company-data">{safe(invoice?.supplier?.phone)}</div>
        </dl>
      </div>
    </header>
  );
  const EInvoiceStart = () => (
    <>
      <div className="line">
        <dl className="recipient">
          <div className="name1 detail-label">
            {safe(invoice?.customer?.name)}
            {' '}
            {safe(invoice?.customer?.companyName)}
          </div>
          <div className="street">{safe(invoice?.customer?.street)}</div>
          <div className="city">
            {safe(invoice?.customer?.postalCode)}
            {' '}
            {safe(invoice?.customer?.city)}
          </div>
          <div className="country">{safe(invoice?.customer?.country)}</div>
        </dl>
        <dl className="invoice-details">
          <div className="invoice-detail-row">
            <div className="detail-label">{tl(eInvoiceTranslations.invoiceNumber)}</div> 
            <div className="detail-value">{safe(invoice?.number)}</div>
          </div>
          <div className="invoice-detail-row">
            <div className="detail-label">{tl(eInvoiceTranslations.invoiceDate)}</div>
            <div className="detail-value">{safe(invoice?.invoiceDate)}</div>
          </div>
        </dl>
      </div>
      <div className="invoice-message">
        <h1>{safe(invoice?.message?.title || `E-Rechnung ${safe(invoice?.number)}`)}</h1>
        <div className="invoice-message-details">{safe(invoice?.message?.details || 'Die Rechnungsdaten aus dem E-/E-Rechnung Datei')}</div>
      </div>
    </>
  );

  const EInvoiceSummary = () => (
    <table className="invoice-summary">
      <thead>
        <tr>
          <th className='invoice-th'>
            {tl(eInvoiceTranslations.items)}
          </th>
          <th className='invoice-th'>
            {tl(eInvoiceTranslations.unitPrice)}
          </th>
          <th className='invoice-th'>
            {tl(eInvoiceTranslations.count)}
          </th>
          <th className='invoice-th'>
            {tl(eInvoiceTranslations.vat)}
          </th>
          <th className='invoice-th'>
            {tl(eInvoiceTranslations.net)}
          </th>
          <th className='invoice-th'>
            {tl(eInvoiceTranslations.gross)}
          </th>
        </tr>
      </thead>
      <tbody>
        {invoice?.items?.map(item => (
          <tr key={JSON.stringify(item)}>
            <td className="invoice-td">
              {safe(item?.name)}
            </td>
            <td className="invoice-td">
              <EInvoiceAmount amount={item?.unitPrice} />
            </td>
            <td className="invoice-td">
              {safe(item?.count, '-')}
            </td>
            <td className="invoice-td">
              {safe(item?.vatPercent, 0)}
              %
            </td>
            <td className="invoice-td">
              <EInvoiceAmount amount={item?.totalNet} />
            </td>
            <td className="invoice-td">
              <EInvoiceAmount amount={safe(item?.totalGross, getGrossBasedOnNetAndVatPercent(item))} />
            </td>

          </tr>
        ))}

        {invoice?.totalDiscount ? (
          <tr>
            <td className="invoice-td" colSpan={1}>
              {tl(eInvoiceTranslations.discount)}
            </td>
            <td colSpan={3} />
            <td className="invoice-td">
              {/* assume it's net */''}
              <EInvoiceAmount amount={-invoice?.totalDiscount} />
            </td>
            <td />
          </tr>
        ) : ''}
        <tr>
          <td className="invoice-td">
            <b>{tl(eInvoiceTranslations.total)}</b>
          </td>
          <td colSpan={3} />
          <td className="invoice-td">
            <b><EInvoiceAmount amount={invoice?.totalNet} /></b>
          </td>
          <td className="invoice-td">
            <b><EInvoiceAmount amount={invoice?.totalGross} /></b>
          </td>
        </tr>
        <tr>
          <td className="invoice-td">
            {tl(eInvoiceTranslations.totalTax)}
          </td>
          <td colSpan={4} />
          <td className="invoice-td">
            <EInvoiceAmount amount={invoice?.totalTax} />
          </td>
        </tr>
        {invoice?.totalPrepaid ? (
          <tr>
            <td className="invoice-td">
              {tl(eInvoiceTranslations.totalPrepaid)}
            </td>
            <td colSpan={4} />
            <td className="invoice-td">
              <EInvoiceAmount amount={-invoice?.totalPrepaid} />
            </td>
          </tr>
        ) : ''}
      </tbody>
      <tfoot>
        <tr>
          <td className="invoice-td">
            {tl(eInvoiceTranslations.amountToPay)}
          </td>
          <td colSpan={4} />
          <td className="invoice-td">
            <EInvoiceAmount amount={invoice?.totalPayable} />
          </td>
        </tr>
      </tfoot>
    </table>
  );
  document.title = `IP_${safe(invoice.number)}_Rechnung_${safe(invoice?.customer?.companyName)}_${safe(invoice?.totalGross / 100)}EUR`;
  return (
    <>
      <EInvoiceHeader />
      <EInvoiceStart />
      <EInvoiceSummary />
      <div className="invoice-order">
        {invoice?.directDebit
          ? <>{tl(eInvoiceTranslations.orderTextIntro)}</>
          : (
            <>
              {tl(eInvoiceTranslations.orderText)}
              {' '}
              {invoice?.supplier?.bankaccount?.accountHolder || invoice?.supplier?.companyName}
              {' '}
              {invoice?.supplier?.bankaccount?.iban}
            </>
          )
        }
      </div>
    </>
  );
};
