import { DocumentCreateDtoSourceTypeEnum } from 'api/document';
import _, { isNil } from 'lodash';
import moment from 'moment';

import {
  UnitDistributionSetDtoDistributionModeEnum,
  MessageListProjection,
} from '../api/accounting';

export const ValidateStatuses = ['success', 'warning', 'error', 'validating', ''] as const;
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
export const IBAN_MOD97_PATTERN = /^([A-Z]{2}[0-9]{2})([A-Z0-9]){9,30}$/i;
export const DATE_MATCHER = /^([0123]?\d)[- .]([01]?\d)[- .](19|20)\d\d$/;
export const NUMBER_MATCHER = /^(0|[1-9]\d*)$/;
export const DECIMAL_MATCHER = /^(\d+(,\d+)?)$/;
export const NEGATIVE_DECIMAL_MATCHER = /^-?\s*\d+(,\d+)?$/;
export const EMAIL_MATCHER = /^\S+@\S+\.\S+$/;
export const PASSWORD_MATCHER = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
export const PHONE_NUMBER_MATCHER = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const DATE_PLACEHOLDER = '';

export const hrIdMatcherParser = (hrIdString: string) => {
  const matcherString = `^(${hrIdString})?\\d*$`;
  return new RegExp(matcherString, 'i');
};

export const setValue = (data: any, key: string, value: any) => {
  const keyParts = key.split('.');

  // array
  if (keyParts.length !== 1) {
    let key1 = keyParts[0];

    // array
    if (key1.indexOf('[') >= 0) {
      const index: number = parseInt(key1.substring(key1.indexOf('[') + 1, key1.indexOf(']')), 10);
      key1 = key1.substring(0, key1.indexOf('['));
      // construct new array
      if (!Array.isArray(data[key1])) {
        data[key1] = [];
      }

      // construct new Object
      if (!(data[key1][index] instanceof Object)) {
        data[key1][index] = {};
      }

      // remove first key part
      keyParts.shift();
      setValue(data[key1][index], keyParts.join('.'), value);
    } else { // object
      // construct new Object
      if (!(data[key1] instanceof Object)) {
        data[key1] = {};
      }
      // remove first key part
      keyParts.shift();
      setValue(data[key1], keyParts.join('.'), value);
    }
  } else if (key.indexOf('[') >= 0) {
    const index: number = parseInt(key.substring(key.indexOf('[') + 1, key.indexOf(']')), 10);
    key = key.substring(0, key.indexOf('['));

    // construct new array
    if (!Array.isArray(data[key])) {
      data[key] = [];
    }

    data[key][index] = value;
  } else { // object
    data[key] = value;
  }
};

export const readValue = (data: any, key: string): any => {
  if (!key) return null;
  const keyParts = key.split('.');
  if (keyParts.length !== 1) {
    let key1 = keyParts[0];

    // array
    if (key1.indexOf('[') >= 0) {
      const index: number = parseInt(key1.substring(key1.indexOf('[') + 1, key1.indexOf(']')), 10);
      key1 = key1.substring(0, key1.indexOf('['));

      keyParts.shift();
      if (Array.isArray(data[key1]) && data[key1][index] instanceof Object) {
        return readValue(data[key1][index], keyParts.join('.'));
      }
      return undefined;
    }
    // object


    keyParts.shift();
    if (data[key1] instanceof Object) {
      return readValue(data[key1], keyParts.join('.'));
    }
    return undefined;
  }
  // array
  if (key.indexOf('[') >= 0) {
    const index: number = parseInt(key.substring(key.indexOf('[') + 1, key.indexOf(']')), 10);
    key = key.substring(0, key.indexOf('['));

    if (Array.isArray(data[key])) {
      return data[key][index];
    }
    return undefined;
  }
  // object

  return data[key];
};

export const deleteKey = (data: any, key: string): void => {
  if (key === undefined) {
    return;
  }

  const keyParts = key?.split('.');

  // array
  if (keyParts?.length !== 1) {
    let key1 = keyParts[0];

    // array
    if (key1.indexOf('[') >= 0) {
      const index: number = parseInt(key1.substring(key1.indexOf('[') + 1, key1.indexOf(']')), 10);
      key1 = key1.substring(0, key1.indexOf('['));

      keyParts.shift();
      if (Array.isArray(data[key1]) && data[key1][index] instanceof Object) {
        deleteKey(data[key1][index], keyParts.join('.'));
      }
    } else { // object
      keyParts.shift();
      if (data[key1] instanceof Object) {
        deleteKey(data[key1], keyParts.join('.'));
      }
    }
  } else if (key?.indexOf('[') >= 0) { // array
    const index: number = parseInt(key.substring(key.indexOf('[') + 1, key.indexOf(']')), 10);
    key = key.substring(0, key.indexOf('['));

    if (Array.isArray(data[key])) {
      data[key].splice(index, 1);
    }
  } else { // object
    delete data[key];
  }
};

/**
 * Deletes all occurrences of the key in the data object (including child objects and arrays)
 */
export const deepDeleteKey = (data: any, keyToDelete: string): void => {
  Object.keys(data)
    .forEach((key: string) => {
      if (key === keyToDelete) {
        delete data[key];
      } else if (data[key] instanceof Object) {
        deepDeleteKey(data[key], keyToDelete);
      } else if (Array.isArray(data[key])) {
        data[key].forEach((value: any) => {
          if (value instanceof Object) {
            deepDeleteKey(value, keyToDelete);
          }
        });
      }
    });
};


export const extractFileName = (fullPath: string) => {
  let fileName = '';
  if (fullPath && fullPath !== '') {
    const strings = fullPath.split('/');
    fileName = strings[strings.length - 1].split('_')
      .slice(0, -1)
      .join('_');
  }
  return fileName;
};

export const getFileNameForMessageListProjection = (message: MessageListProjection, extension?: string) => `${moment().format(ISO_DATE_FORMAT)
}_${message.propertyIdInternal
}_${message.processName
}_${
  message.contactName
}.${
  extension || 'pdf'
}`;

/**
 *
 * @param arrays A list of arrays containing any type of data (objects or primitives)
 * @returns A single array without duplicates
 */
export const mergeArrays = (...arrays: [any][]) => {
  const reducer = (accumulator: [any], currentValue: any) => ({ ...accumulator, ...currentValue.map((value: any) => JSON.stringify(value)) });

  const defaultValue: any = [];
  const mergedArray: [string] = arrays.reduce(reducer, defaultValue);
  const removedDuplicates = [];

  mergedArray.forEach((entry: string, idx: number) => {
    if (mergedArray.indexOf(entry) === idx) {
      removedDuplicates.push(JSON.parse(entry));
    }
  });
  return mergeArrays;
};

/**
 * Converts a snake case string to camel case
 * @param str
 */
export const snakeToCamel = (str: string) => str.replace(
  /([-_][a-z])/g,
  group => group.toUpperCase()
    .replace('-', '')
    .replace('_', ''),
);

export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, c => `_${c.toLowerCase()}`);

/**
 * Generates an array of numbers from start to end
 * @param start
 * @param end
 */

export const range = (start: number, end: number) => {
  const length = Math.floor((end - start)) + 1;
  return Array.from(Array(length), ((x: number, index: number) => start + index));
};

/**
 * Load value from localStorage
 * @param key
 * @param type
 */
export const loadFromLocalStorage = (key: string, type: 'array' | 'object' | 'string') => {
  const value = localStorage.getItem(key);

  switch (type) {
    case 'array':
      return value ? JSON.parse(value) : [];
    case 'object':
      return value ? JSON.parse(value) : {};
    case 'string':
      return value;
    default:
      return null;
  }
};

/**
 * Save the value in localStorage
 * @param key
 * @param value
 */
export const saveToLocalStorage = (key: string, value: Object | string) => {
  let data = '';
  switch (typeof value) {
    case 'object':
      data = JSON.stringify(value);
      break;
    case 'string':
      data = value;
      break;
    default:
      break;
  }

  localStorage.setItem(key, data);
};

const groupDigits = (number: string): string => {
  const str = number.split(',');
  if (str[0]) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');
  }
  return str.join(',');
};

/**
 * Converts the parameter to the following format: € 10.000,00
 * @Deprecated
 * Use formatToEuro instead
 */
export const formatCurrency = (n: any | number, undefinedValue: string = '-', withCurrency = true, numberOfDigits: number = 2) => {
  if (typeof n !== 'number' || Number.isNaN(n)) {
    return undefinedValue;
  }
  return `${groupDigits(n.toFixed(numberOfDigits)
    .replace('-', '- ')
    .replace('.', ','))
  }${withCurrency ? ' €' : ''
  }`;
};


const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});
/**
 * Converts the parameter to the following format: € 10.000,00
 */
export const formatToEuro = (n: number) => formatter.format(n);

export const floatToFormattedString = (num: number = 0, numberOfDigits: number = 2) => formatCurrency(num, '0,00',
  false, numberOfDigits);

export const formattedStringToFloat = (str: string = '0,00') => parseFloat(
  str
    .replaceAll(/\s/g, '')
    .replaceAll('.', '')
    .replace(',', '.'),
);

export const formatCurrencyString = (unformatted: string, precision = 2) => {
  const [integerPart, decimalPart] = unformatted.replace(/\.|-/g, '')
    .split(',');

  return `${
    integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }${
    decimalPart !== undefined
      ? `,${decimalPart.substr(0, precision)}`
      : ''
  }`;
};

export const formatPercentage = (n: any | number, undefinedValue: string = '-') => {
  if (typeof n !== 'number' || Number.isNaN(n)) {
    return undefinedValue;
  }
  return `${n.toFixed(2)
    .replace('-', '- ')
    .replace('.', ',')}%`;
};

export const formatDate = (date: moment.Moment | Date, format?: string) => {
  if (!moment(date, moment.ISO_8601, true)
    .isValid()) {
    return '—';
  }
  if (format) {
    return moment(date)
      .startOf('day')
      .format(format);
  }
  return moment(date)
    .startOf('day')
    .format('DD.MM.YYYY');
};

export const formatCurrencyToNumber = (n: string) => parseFloat(n.replace('- ', '-')
  .replace('.', '')
  .replace(',', '.')
  .replace('€', ''));

export const trimIban = (iban: string) => iban.substr(iban.length - 10);


export const formatAddress = (address: any) => {
  if (!address) return '';
  return `${address.street}${address.number ? ` ${address.number}` : ''}${address.postalCode ? `, ${address.postalCode}` : ''}${address.city ? ` ${address.city}` : ''}`;
};

/**
 * Generates the menu elements from a form
 * @param sections
 * @param openSectionIndex
 */
interface section {
  sectionTitle: string,
  sectionId: string,
  sectionNumber: number,
  subSectionNumber?: number,
  openingNumber: number
  content: Array<{
    title?: string,
    sectionId: string
  }>
}

export const generateMenuFromSections = ({
  sections,
  openSectionIndex,
}: { sections: Array<section>, openSectionIndex: number }) => sections.reduce((menu: any, sectionItem: any, index: number) => {
  let menuElement = {
    title: sectionItem.sectionTitle,
    menuTitle: sectionItem.menuTitle,
    href: sectionItem.sectionId,
    number: sectionItem.subsectionNumber ? `${sectionItem.sectionNumber}.${sectionItem.subsectionNumber}` : sectionItem.sectionNumber?.toString(),
    sectionIndex: index,
    openByDefault: (sectionItem.openingNumber - 1) === openSectionIndex,
    disabled: sectionItem.disabled || false,
    subElements: [],
    groupElements: [],
  };
  menuElement.subElements = sectionItem.content.filter((subSection: any) => !!subSection.title)
    .map((subSection: any) => ({
      title: subSection.title,
      menuTitle: subSection.menuTitle,
      href: subSection.sectionId,
    }));
  if (sectionItem.contentGroups) {
    menuElement.groupElements = sectionItem.contentGroups.filter((group: any) => !!group.title)
      .map((group: any) => ({
        title: group.title,
        menuTitle: group.menuTitle,
        href: group.sectionId,
        number: group.groupNumber,
        subElements: group.content.filter((subSection: any) => !!subSection.title)
          .map((subSection: any) => ({
            title: subSection.title,
            href: subSection.sectionId,
          })),
      }));
  }

  // overwrite props from section.sideMenuElement
  menuElement = {
    ...menuElement,
    ...sectionItem.sideMenuElement,
  };

  menu.push(menuElement);
  return menu;
}, []);

export interface StringMap {
  [s: string]: string;
}

export const renameKeyRecursively = (data: any, oldKey: string, newKey: string) => {
  const newData = data;
  if (oldKey in data) {
    newData[newKey] = newData[oldKey];
    delete newData[oldKey];
  }

  Object.keys(newData)
    .forEach((key) => {
      if (typeof newData[key] === 'object' && !!newData[key]) {
        newData[key] = renameKeyRecursively(newData[key], oldKey, newKey);
      }
    });

  return newData;
};

export const clone = (object: any): any => {
  if (typeof object === 'object') {
    return JSON.parse(JSON.stringify(object));
  }
  return object;
};

export const downloadByteArrayAsPdf = (reportName: string, byte: any) => {
  const blob = new Blob([byte], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = reportName;
  link.click();
};

export const printBlob = (blob: any) => {
  const data = URL.createObjectURL(blob);
  const documentWindow = window.open(data, 'PRINT');
  documentWindow!.focus();
  documentWindow!.print();
};

export function round2dec(value: number): number {
  return Math.round(value * 100) / 100;
}

export function roundNdec(value: number, nrOfDecimals: number): number {
  const divider = 10 ** nrOfDecimals;
  return Math.round(value * divider) / divider;
}

export function round2decCeil(value: number): number {
  return Math.round(Math.ceil(value * 100)) / 100;
}

export const createFieldRegExp = (regExpString: string) => new RegExp(`^${regExpString}$`);

export const anyMatch = (matchers: RegExp[], value: string) => {
  let result = false;

  matchers.forEach((regExp: RegExp) => {
    if (regExp.test(value)) {
      result = true;
    }
  });

  return result;
};

export function replaceAllVariableWithValues(currentValue: string, replacements: any) {
  if (_.isEmpty(replacements)) {
    return currentValue;
  }

  if (typeof replacements === 'object') {
    const variables = Object.keys(replacements);
    variables.forEach((variable: string) => {
      const regexString = `{${variable}}`;
      currentValue = currentValue.replace(new RegExp(regexString, 'g'), replacements[variable] || '');
    });
  } else {
    currentValue = replacements;
  }

  return currentValue;
}

export function setCurrentYearForDate(date: string | Date) {
  const tempDate = new Date(date);
  tempDate.setFullYear(new Date().getFullYear());
  return tempDate;
}

type Diff<T extends keyof any, U extends keyof any> =
  ({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];
export type Overwrite<T, U> = Pick<T, Diff<keyof T, keyof U>> & U;


export function getParentScrollElement(element: HTMLElement | null): HTMLElement {
  if (element) {
    if (element.id === 'scrollElement') {
      return element;
    }
    return getParentScrollElement(element.parentElement);
  }
  return document.getElementsByTagName('body')[0];
}

export function createEnumMatcher(enumValues: string[]) {
  return new RegExp(`^(${enumValues.map(value => value.toLowerCase()).join('|')})$`);
}

export function getCurrentEconomicYear(economicYearStart: Date) {
  let currentYear: number = new Date().getFullYear();

  const tempEconomicYearStart = new Date(economicYearStart);
  tempEconomicYearStart.setFullYear(currentYear);
  if (new Date() < tempEconomicYearStart) {
    // if economic year of current calendaristic year didn't start yet
    currentYear -= 1;
  }

  return getDateRangeForEconomicYear(economicYearStart, currentYear);
}

/**
 * @deprecated This will give the year of when the property was created, not necessarily the current economic year.
 * You'll probably want to use `getDateRangeForCurrentEconomicYear` instead.
 */
export function getDateRangeForEconomicYear(economicYearStart: Date, year?: number) {
  if (economicYearStart === undefined) {
    // set start to 01.01
    economicYearStart = new Date();
    economicYearStart.setMonth(0);
    economicYearStart.setDate(1);
  }

  const tempEconomicYearStart = new Date(economicYearStart);
  if (year) {
    tempEconomicYearStart.setFullYear(year);
  }
  const tempEconomicYearEnd = new Date(tempEconomicYearStart);
  tempEconomicYearEnd.setFullYear(tempEconomicYearStart.getFullYear() + 1);
  tempEconomicYearEnd.setDate(tempEconomicYearEnd.getDate() - 1);

  return {
    economicYearStart: tempEconomicYearStart,
    economicYearEnd: tempEconomicYearEnd,
  };
}

export const convertSizeToNumberInDocument = (size: string | number, direction: 'vertical' | 'horizontal'): number => convertSizeToNumber(size, direction === 'vertical' ? document.body.offsetHeight : document.body.offsetWidth);

export const convertSizeToNumber = (size: string | number, containerSize: number): number => {
  if (typeof size === 'number') {
    return size;
  }
  if (size.endsWith('px')) {
    return parseInt(size.slice(0, -2), 10);
  }
  if (size.endsWith('%')) {
    const percentage = parseInt(size.slice(0, -1), 10);
    return !Number.isNaN(containerSize) ? (containerSize * percentage) / 100 : 0;
  }

  return parseInt(size, 10);
};

export function isBaseDistribution(distribution: UnitDistributionSetDtoDistributionModeEnum | undefined) {
  return !(!distribution
    || [UnitDistributionSetDtoDistributionModeEnum.DIRECT_COST,
      UnitDistributionSetDtoDistributionModeEnum.DISTRIBUTION_VALUE,
      UnitDistributionSetDtoDistributionModeEnum.INDIVIDUAL,
      UnitDistributionSetDtoDistributionModeEnum.HEATING_COST]
      .includes(distribution));
}

export const stringWidthToNumber = (width: string | number, parentWidth: number): number => {
  if (typeof width === 'number') return width;
  let w;
  if (width.endsWith('px')) {
    w = parseInt(width.slice(0, -2), 10);
  } else if (width.endsWith('%')) {
    const percentage = parseInt(width.slice(0, -1), 10);
    w = !Number.isNaN(parentWidth) ? (parentWidth * percentage) / 100 : 0;
  } else {
    w = parseInt(width, 10);
  }
  return w;
};

export const compareAccountCodes = (a: string, b: string): number => {
  const splitsA = a.split('/');
  const splitsB = b.split('/');
  const len = Math.min(splitsA.length, splitsB.length);
  let idx = 0;
  while (idx < len && splitsA[idx] === splitsB[idx]) {
    idx += 1;
  }

  return parseInt(splitsA[idx], 10) - parseInt(splitsB[idx], 10);
};


export const generateReportDocumentName = (sourceType: DocumentCreateDtoSourceTypeEnum, startDate: moment.Moment, endDate: moment.Moment) => {
  const sourceName = getDocumentSourceName(sourceType);
  const start = startDate ? ` - ${moment(startDate).format('DD.MM.YY')}` : '';
  const end = endDate ? ` - ${moment(endDate).format('DD.MM.YY')}` : '';
  return `${sourceName}${start}${end}`;
};

export const generateDocumentName = (date: string, propertyIdInternal: string, title: string, unitNrSharingDeclaration: string, contractName: string, amount: string) => {
  const firstPart = [date, propertyIdInternal, title].filter(val => !isNil(val) && val !== '').join('_');
  const amountPart = amount ? `_${amount.replace('.', '')}` : '';
  return `${firstPart} ${unitNrSharingDeclaration} ${contractName} ${amountPart}`;
};

const getDocumentSourceName = (sourceType: DocumentCreateDtoSourceTypeEnum) => {
  switch (sourceType) {
    case DocumentCreateDtoSourceTypeEnum.PROFIT_AND_LOSS:
      return 'Eigentümerbericht ';
    case DocumentCreateDtoSourceTypeEnum.OPS_COST_REPORT:
      return 'Betriebskostenabrechnung ';
    case DocumentCreateDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION:
      return 'Wärmekostenabrechnung ';
    default:
      return '';
  }
};
export const getGermanMonthNumber = (month: string) => moment(`${month} 12, 2012`, 'MMMM dd, YYYY', 'de').month() + 1;

export const numberInputParser = (val: string, precision: number = 4) => (
  !_.isNil(val)
    ? parseFloat(
      val.replace(/\s+|\.+/g, '') // remove whitespaces and thounsand separators
        .replace(',', '.') // replace german `,` decimal separator with JS-parsable `.` decimal separator
        .replace(/\.\d+/, match => match.slice(0, precision + 1)), // keep only last N digits after decimal point (N = precision)
    )
    : undefined
);

export const oneDigitFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 1 });
export const twoDigitFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 });
export const threeDigitFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 3 });
export const fourDigitFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 4 });
export const fiveDigitFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 5 });


export const digitNumberFormatter = (val, digits?: 1 | 2 | 3 | 4 | 5) => {
  if (isNil(val)) {
    return undefined;
  }

  switch (digits) {
    case 1:
      return oneDigitFormatter.format(val);
    case 2:
      return twoDigitFormatter.format(val);
    case 3:
      return threeDigitFormatter.format(val);
    case 5:
      return fiveDigitFormatter.format(val);
    default:
    case 4:
      return fourDigitFormatter.format(val);
  }
};

export const fourDigitNumberFormatter = val => digitNumberFormatter(val);
