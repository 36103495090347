import React from 'react';
import './EmptyState.scss';


export interface EmptyStateProps {
  icon?: React.ReactNode,
  title?: string | React.ReactNode,
  subTitle?: string | React.ReactNode,
  button?: React.ReactNode,
  className?: string,
}

export const EmptyState = ({
  icon,
  title,
  subTitle,
  button,
  className,
}: EmptyStateProps) => (
  <div className={`EmptyState ${className}`}>
    {icon && <div className="icon-wrapper">{icon}</div>}

    {title && (
      typeof title === 'string' ? <div className="title">{title}</div> : title
    )}

    {subTitle && (
      typeof subTitle === 'string' ? <div className="subTitle">{subTitle}</div> : subTitle
    )}

    {button && <div className="button-wrapper">{button}</div>}
  </div>
);

export default EmptyState;

EmptyState.defaultProps = {
  icon: undefined,
  title: undefined,
  subTitle: undefined,
  button: undefined,
  className: '',
};
