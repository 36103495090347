import React, { useState } from 'react';
import _ from 'lodash';
import { deleteKey, readValue, setValue } from '../../../lib/Utils';
import { DeletionModal } from '../../Modals/DeletionModal/DeletionModal';

interface UseDeleteButtonProps {
  value: any
  deletionKey: string,
  nrDeletionKey?: string,
  addDirt?: () => void
  onChange?: (val: any) => void,
  loading?: boolean,
  deleteFunction?: (deletionKey: any, nrDeletionKey: any) => void,
  deletionCallback?: Function,
}

export function useDeleteButton(props: UseDeleteButtonProps) {
  const {
    value, deletionKey, nrDeletionKey, addDirt, onChange, loading, deleteFunction, deletionCallback,
  } = props;
  const [deletionModalVisible, setDeletionModalVisible] = useState(false);
  const [deletionModal, setDeletionModal] = useState(undefined);

  const onDelete = () => {
    if (deleteFunction) {
      setDeletionModalVisible(false);
      if (addDirt) addDirt();
      deleteFunction(deletionKey, nrDeletionKey);
      return;
    }

    if (deletionModal?.onClickOk) {
      setDeletionModalVisible(false);
      deletionModal.onClickOk();
      return;
    }
    const newValue: any = _.cloneDeep(value.current);
    deleteKey(newValue, deletionKey);
    if (nrDeletionKey) {
      const oldValue = readValue(newValue, nrDeletionKey);
      setValue(newValue, nrDeletionKey, oldValue - 1);
    }
    if (deletionCallback) {
      deletionCallback(newValue);
    }
    if (addDirt) addDirt();
    if (onChange) {
      onChange(newValue);
    }
    setDeletionModalVisible(false);
  };

  return ({
    modal: <DeletionModal
      visible={deletionModalVisible}
      onClickCancel={() => setDeletionModalVisible(false)}
      onClickOk={onDelete}
      loading={loading}
      title={deletionModal?.title}
      description={deletionModal?.description}
      deleteButtonText={deletionModal?.deleteButtonText}
      hideDeleteButton={deletionModal?.hideDeleteButton}
    />,
    setDeletionModalVisible,
    setDeletionModal,
  });
}
