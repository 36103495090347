import React, { useContext } from 'react';
import { ExtendedExchangeProjectionTypeEnum } from 'api/accounting';
import CurrencyInputWithCheckbox from 'components/CurrencyInputWithCheckbox/CurrencyInputWithCheckbox';
import { LanguageContext } from 'contexts/LanguageContext';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import useSumToAllocateCell from './useSumToAllocateCell';
import { translations } from '../../../../../../../translations';


const formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });


interface Props {
  record: ServiceCompanyAllocationValues,
  transactionRemainigAmount: number | undefined,
}

const SumToAllocateCell = ({ record, transactionRemainigAmount }: Props) => {
  const { tl } = useContext(LanguageContext);
  const { currentValue, onChange } = useSumToAllocateCell(record.key);


  const maxValueExceededErrorMessage = record.exchangeAmount !== undefined
    ? tl(translations.bankTransactionAllocation.serviceCompanyAllocation.notifications.exceededAmountErrorMessage)(
      formatter.format(Math.abs(record.exchangeAmount ?? 0)),
    )
    : undefined;

  const suggestedValue = record.type === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? Math.abs(transactionRemainigAmount ?? 0)
    : Math.min(Math.abs(record.exchangeRemainingAmount ?? 0), Math.abs(transactionRemainigAmount ?? 0));

  return (
    <CurrencyInputWithCheckbox
      onChange={onChange}
      currentValue={currentValue !== undefined ? Math.abs(currentValue) : undefined}
      previousValue={Math.abs(record.previousAllocationAmount ?? 0) > 0 ? Math.abs(record.previousAllocationAmount!) : undefined}
      suggestedValue={suggestedValue}
      maxValue={record.type === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? undefined : Math.abs((record.exchangeRemainingAmount!) + (record.previousAllocationAmount ?? 0))}
      maxValueExceededErrorMessage={maxValueExceededErrorMessage}
      getTooltipPopupContainer={() => (document.getElementsByClassName('ExchangeListTable')[0] as HTMLElement) ?? document.body}
    />
  );
};

export default SumToAllocateCell;
