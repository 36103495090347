import { useContext } from 'react';
import { useParams } from 'react-router';
import { formatDate, ISO_DATE_FORMAT } from 'lib/Utils';
import { AuthContext } from 'contexts/AuthContext';
import {
  EconomicPlanControllerApi, EconomicPlanDtoStatusEnum, EconomicPlanUnitResultDto, GetMessagesUsingGETSourceTypeEnum,
} from 'api/accounting';
import { showNotification } from 'lib/Notification';
import _ from 'lodash';
import { ErrorCodeUsingGETValueEnum } from 'api/document';
import { DocumentDto } from 'api/public';
import { EconomicPlanContext } from 'pages/EconomicPlan/services/EconomicPlanContext';
import { useRevertToDraftOption } from 'pages/EconomicPlan/components/StateChangeButtons/useRevertToDraftOption';
import { useSendEconomicPlan } from 'pages/EconomicPlan/components/StateChangeButtons/useSendEconomicPlan';
import { useCheckMessages } from 'services/useCheckMessages';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import useEconomicPlanOverview from '../services/useEconomicPlanOverview';
import { useCopyEconomicPlan } from '../../services/useCopyEconomicPlan';
import { useDeleteEconomicPlan } from '../../services/useDeleteEconomicPlan';
import { useHandleErrorWithType } from '../../services/useHandleErrorWithType';
import { useEconomicPlanDocuments } from '../services/useEconcomicPlanDocuments';

export const useRightSideButtons = () => {
  const { tl } = useContext(LanguageContext);
  const {
    invalidAmounts,
  } = useEconomicPlanOverview();

  const {
    documentsGenerating, documents, generateDocuments, onLoadEconomicPlanDocuments, documentCreationFailed,
  } = useEconomicPlanDocuments();

  const economicPlanContext = useContext(EconomicPlanContext);
  const {
    economicPlan, setEconomicPlan, selectedUnitId, mergedDocumentModalVisible, setMergedDocumentModalVisible,
  } = economicPlanContext;

  const { economicPlanId } = useParams<{ economicPlanId: string }>();

  const { messagesExist, messagesGenerating } = useCheckMessages({ sourceId: parseInt(economicPlanId, 10), sourceType: GetMessagesUsingGETSourceTypeEnum.ECONOMIC_PLAN });

  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  const { copyEconomicPlan } = useCopyEconomicPlan();
  const { deleteEconomicPlan } = useDeleteEconomicPlan();
  const { handleErrorWithType } = useHandleErrorWithType();

  const handleRecalculationError = (errorBody) => {
    if (errorBody?.type) {
      handleErrorWithType(errorBody, tl(economicPlanTranslations.economicPlan.notification.recreateEconomicPlanError.message));
      return;
    }
    showNotification({
      key: 'recreateEconomicPlanError',
      message: tl(economicPlanTranslations.economicPlan.notification.recreateEconomicPlanError.message),
      type: 'error',
    });
  };

  const recalculate = () => {
    const onProceed = () => {
      setEconomicPlan(economicPlan.startLoading());
      economicPlanControllerApi.calculateUsingGET({ id: parseInt(economicPlanId, 10) }).then((response: EconomicPlanUnitResultDto[]) => {
        setEconomicPlan(currentEp => currentEp.load({
          ...currentEp.data!,
          documentGeneratedOn: formatDate(new Date(), ISO_DATE_FORMAT),
          results: {
            ...currentEp.data?.results,
            unitResults: response!,
          },
        }));
      }).catch((error) => {
        setEconomicPlan(prevState => prevState.failed());
        error.response.json()
          .then(body => handleRecalculationError(body));
      })
        .finally(() => {
        // reload documents even if recalculation failed, might be that they no longer exists
          onLoadEconomicPlanDocuments(parseInt(economicPlanId, 10));
        });
    };

    onProceed();
  };

  const revertToDraftOption = useRevertToDraftOption();
  const { onSend, onCheckMessages } = useSendEconomicPlan();

  const documentsExists = !_.isEmpty(documents.data);

  const dropMenuOptionList = [];

  if (economicPlan.data?.id) {
    dropMenuOptionList.push({
      label: tl(economicPlanTranslations.actions.copy),
      onClick: () => {
        copyEconomicPlan(economicPlan.data?.id);
      },
    });
  }

  if (economicPlan.data?.status === EconomicPlanDtoStatusEnum.DRAFT) {
    if (messagesExist) {
      dropMenuOptionList.push({
        label: tl(economicPlanTranslations.economicPlan.header.buttons.checkMessages),
        onClick: onCheckMessages,
      });
    }
    if (documentsExists) {
      dropMenuOptionList.push({
        label: tl(economicPlanTranslations.economicPlan.header.buttons.send),
        onClick: onSend,
        disabled: documentCreationFailed,
      });
    }
  }


  if (documentsExists) {
    dropMenuOptionList.push({
      label: tl(economicPlanTranslations.economicPlan.header.buttons.download),
      disabled: documentsGenerating || documentCreationFailed,
      onClick: () => {
        if (_.isEmpty(invalidAmounts.data)) {
          setMergedDocumentModalVisible(true);
        } else {
          showNotification({
            key: 'invalidAccountAmountsError',
            message: tl(economicPlanTranslations.economicPlan.notification.errorCodes[ErrorCodeUsingGETValueEnum.INVALID_ACCOUNT_AMOUNTS]),
            type: 'error',
          });
        }
      },
    });
  } else {
    dropMenuOptionList.push({
      label: tl(economicPlanTranslations.economicPlan.header.buttons.generateDocuments),
      onClick: () => generateDocuments(economicPlan.data?.id),
    });
  }

  if (economicPlan.data?.status === EconomicPlanDtoStatusEnum.DRAFT) {
    dropMenuOptionList.push({
      label: tl(economicPlanTranslations.actions.delete),
      onClick: () => {
        deleteEconomicPlan(economicPlan.data?.id);
      },
    });
  } else if ([EconomicPlanDtoStatusEnum.ACTIVE, EconomicPlanDtoStatusEnum.DECIDED, EconomicPlanDtoStatusEnum.PREPARED].includes(economicPlan.data?.status) && messagesExist) {
    dropMenuOptionList.push({
      label: tl(economicPlanTranslations.economicPlan.header.buttons.send),
      onClick: onSend,
      disabled: documentCreationFailed,
    });
  }

  if ([EconomicPlanDtoStatusEnum.PREPARED, EconomicPlanDtoStatusEnum.DECIDED].includes(economicPlan.data?.status)) {
    dropMenuOptionList.push(revertToDraftOption);
  }

  const selectedDocumentId = selectedUnitId
    ? documents.data?.find((d: DocumentDto) => d.unitId === selectedUnitId)?.id
    : documents.data?.find((d: DocumentDto) => !d.contractId)?.id;

  const startLoading = () => {
    setEconomicPlan(economicPlan.startLoading());
  };

  const stopLoading = () => {
    setEconomicPlan(economicPlan.finishLoading());
  };

  return {
    loading: economicPlan.loading,
    documentsLoading: documents.loading,
    recalculate,
    invalidAmounts,
    economicPlanStatus: economicPlan.data?.status,
    propertyHrId: economicPlan.data?.propertyHrId,
    dropMenuOptionList,
    documentsGenerating,
    mergedDocumentModalVisible,
    setMergedDocumentModalVisible,
    documentsExists,
    documentCreationFailed,
    generateDocuments,
    messagesExist,
    messagesGenerating,
    selectedDocumentId,
    documents,
    startLoading,
    stopLoading,
  };
};
