export const economicPlanHouseMoneyTableTranslations = {
  houseMoneyTable: {
    header: {
      unitId: {
        en: 'Unit-id',
        de: 'Nr. - Eigentümer',
      },
      opsCost: {
        en: 'Ops cost',
        de: 'Hausgeld',
      },
      totalOfUnit: {
        en: 'Total of unit',
        de: 'Summe',
      },
    },
    summaryRow: {
      label: {
        en: 'Total amount',
        de: 'Gesamtbetrag',
      },
    },
  },
  notifications: {
    saveHmError: {
      message: {
        en: 'Some amounts could not be saved.',
        de: 'Einige Beträge konnten nicht gespeichert werden.',
      },
    },
    loadOwnerHouseMoneyListError: {
      message: {
        en: 'Resource loading error.',
        de: 'Fehler beim Laden der Ressource.',
      },
      description: {
        en: 'Cannot load house money.',
        de: 'Eigentümer und Hausgeld Beträge können nicht geladen werden.',
      },
    },
  },
  saveEconomicPlanInfoText: {
    en: 'House Money Amounts will be shown after start date is set',
    de: 'Hausgeld Beträge wird angezeigt, nachdem das Startdatum festgelegt wurde',
  },
};
