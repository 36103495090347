import { Overwrite } from '../../lib/Utils';
import {
  AllocationGroupDto,
  GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum,
  BankTransactionProjectionDto, BankTransactionProjectionDtoTransactionPaymentStatusEnum,
  AccountDto, InvoiceLegacyDto, ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum, PropertyLegacyDtoAdministrationTypeEnum, GetAllocationGroupsUsingGETAdministrationTypesEnum, PropertyLegacyDtoVatRelevanceEnum,
  UnitContractProjectionDtoVatRelevanceEnum,
  UnitOwnerAccountBalanceDtoContractVatRelevanceEnum,
  PropertyLegacyDtoVatRateCountryCodeEnum,
} from '../../api/accounting';
import { PropertyVatRate } from './BankTransactionAllocation/services/PropertyVatRatesContext';


export interface ExtendedBankTransactionProperty {
  propertyId?: number;
  propertyIdInternal?: string;
  propertyHrId?: string;
  propertyName?: string;
  accountantName?: string;
  supervisorName?: string;
  vatRelevance?: PropertyLegacyDtoVatRelevanceEnum;
  propertyAdministrationType: PropertyLegacyDtoAdministrationTypeEnum;
  vatRateCountryCode?: PropertyLegacyDtoVatRateCountryCodeEnum;
}

export interface ExtendedBankTransactionProperty {
  propertyId?: number;
  propertyIdInternal?: string;
  propertyHrId?: string;
  propertyName?: string;
  accountantName?: string;
  supervisorName?: string;
  vatRelevance?: PropertyLegacyDtoVatRelevanceEnum;
  propertyAdministrationType: PropertyLegacyDtoAdministrationTypeEnum;
}

export interface BankTransactionExtension {
  translatedStatus?: string;
  bankTransactionId?: number;
  invoiceInternalRefNr?: string;
  counterpartName?: string;
  amounts?: any;
  children?: any;
  allocationGroupId?: number;
  rowKey?: string;
  transactionDate?: Date;
  allocatedInvoices?: { invoiceHrId?: string; invoiceId: number }[];
  propertyList?: ExtendedBankTransactionProperty[];
}

export interface ExtendedBankTransaction extends Overwrite<BankTransactionProjectionDto, BankTransactionExtension> {
}

export interface TransactionFilter {
  allocationGroupId?: number,
  propertyHrId?: string,
  propertyIdInternal?: string,
  propertyAdministrationTypes?: GetAllocationGroupsUsingGETAdministrationTypesEnum[],
  name?: string,
  transactionPaymentStatuses?: GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum[],
  minAmount?: number,
  maxAmount?: number,
  transactionIds?: number[],
}

export enum ALLOCATION_TYPES {
  TENANT = 'TENANT',
  UNIT_OWNER = 'UNIT_OWNER',
  SERVICE_COMPANY = 'SERVICE_COMPANY',
  PRP_OWNER = 'PRP_OWNER',
}

export interface InvoiceFilter {
  propertyHrId?: string,
  propertyIdInternal?: string,
  propertyName?: string,
  state?: string[],
  minAmount?: number,
  maxAmount?: number,
  creditorName?: string,
  receiverIban?: string,
  minProcessingDate?: Date,
  maxProcessingDate?: Date,
}

export interface AccountFilter {
  accountTypes?: string[],
  name?: string,
}
interface AccountExtension {
  balance?: number
  unitId?: number,
  unitRank?: number,
  accountId?: string,
  unitNrSharingDeclaration?: string,
  accountOwnerFullName?: string,
  vatRelevance?: UnitOwnerAccountBalanceDtoContractVatRelevanceEnum,
  vatRatesForAccount?: PropertyVatRate,
  contractId?: number,
  sumToAllocate?: number,
  previouslyAllocatedAmount?: number,
  vatPercentageToAllocate?: number,
  defaultVatPercentage?: number,
  previouslyAllocatedVatPercentage?: number,
  unitAccountKey?: string,
  accountCode?: string,
  childRows?: FEAccountBalanceDto[],
  lastBookingAmount?: number,
  postingTexts?: PostingText[],
  tags?: string[],
  isCurrentlyActiveAccount?: boolean
}

export interface FEAccountBalanceDto extends Overwrite<AccountDto, AccountExtension> {
  houseMoneyAmount?: number,
  reserveFundAmount?: number,
  searchValue: string,
  propertyIdInternal?: string,
  propertyName?: string,
}

export interface UnitAccount {
  unitId?: number,
  unitRank?: number,
  unitNrSharingDeclaration?: string,
  accountOwnerFullName?: string,
  accountBalances?: FEAccountBalanceDto[],
  children?: FEAccountBalanceDto[],
}

export interface BookingItem {
  accountCode?: string,
  bookingText?: string,
  amount?: number,
}
export interface Booking {
  domainId?: number,
  propertyHrId?: string,
  postDate?: Date,
  reference?: string,
  debitList?: BookingItem[],
  creditList?: BookingItem[],
  accrualBased?: boolean,
}

// eslint-disable-next-line no-shadow
export enum UNIT_ACCOUNT_TAGS {
  RESERVE_FUND = 'RESERVE_FUND',
  HOUSE_MONEY = 'HOUSE_MONEY',
  UNIT_SETTLEMENT = 'UNIT_SETTLEMENT',
  ADDITIONAL_SERVICE_MANAGER = 'ADDITIONAL_SERVICE_MANAGER',
  SPECIAL_CONTRIBUTION = 'SPECIAL_CONTRIBUTION',
  DUNNING_FEES = 'DUNNING_FEES',
  RETURN_DEBIT_FEE = 'RETURN_DEBIT_FEE',
  DIRECT_LOAD = 'DIRECT_LOAD',
}

export interface AccountAllocation extends AccountDto {
  sumToAllocate: number,
  vatPercentageToAllocate?: number,
  postingTexts?: PostingText[],
  accountId?: never,
}

// will be removed from BE, still needed on FE
export interface AllocationAmountsDto {
  /**
   *
   * @type {Array<AccountBalanceDto>}
   * @memberof AllocationAmountsDto
   */
  unitAllocationAmounts?: Array<AccountAllocation>;
}

export interface InvoiceDtoExtension {
  sumToAllocate?: number,
}

export interface ExtendedInvoiceDto extends Overwrite<InvoiceLegacyDto, InvoiceDtoExtension> {
}

export interface AllocationGroupExtension {
  allocatedInvoices?: { invoiceHrId?: string, invoiceId: number }[];
  amount?: number;
  bankBookingDate?: Date;
  transactionDate?: Date;
  counterpartBic?: string;
  counterpartIban?: string;
  propertyHrId?: string;
  propertyIdInternal?: string;
  propertyName?: string;
  purpose?: string;
  propertyIban?: string;
  remainingAmount?: number;
  supervisorName?: string;
  transactionPaymentStatus?: BankTransactionProjectionDtoTransactionPaymentStatusEnum;
  translatedStatus?: string,
  bankTransactionId?: number,
  invoiceInternalRefNr?: string,
  counterpartName?: string,
  rowKey?: string,
  children?: ExtendedBankTransaction[],
  propertyList?: ExtendedBankTransactionProperty[],
}

export interface ExtendedAllocationGroup extends Overwrite<AllocationGroupDto, AllocationGroupExtension> {
}

export interface PostingText {
  bankTransactionId?: number,
  postingText?: string,
  postingId?: number,
  postingItemId?: number,
  exchangeId?: number,
  counterpartAccount?: string,
}

export interface ManualAllocationExchangeInterface {
  key: number,
  accountCode?: string,
  accountName?: string,
  contactId?: number,
  laborCostType?: ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
  laborCost?: number,
  amount?: number,
  initialPostingTexts?: Array<PostingText>,
  postingTexts?: Array<PostingText>,
}

export type BankTransactionListLocationStateType = {
  navigatedFromApp?: boolean, reloadFirstPage?: boolean
}
