import React, { useContext } from 'react';
import { Prompt, useLocation } from 'react-router';
import { LanguageContext } from 'contexts/LanguageContext';
import { useIsDirtyValue } from '../services/useIsDirtyValue';
import { translations } from '../translations';

export const PromptWrapper = () => {
  const { isDirty } = useIsDirtyValue();
  const { tl } = useContext(LanguageContext);
  const location = useLocation();

  return (
    <Prompt
      when={isDirty}
      message={loc => (loc.pathname.includes(location.pathname) ? true : tl(translations.creationPage.prompt))}
    />
  );
};
