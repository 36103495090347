import React, { useContext, useEffect } from 'react';
import './ManualExchangeSection1.scss';
import { Property } from 'pages/ManualExchange/services/ManualExchangeContext';
import {
  Col, Form, Row, Select, Spin,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { LoadingIndicator } from 'elements/LoadingIndicator/LoadingIndicator';
import Icon from '@ant-design/icons';
import {
  FindFilteredPropertiesUsingGETAdministrationTypesEnum, PropertyDisplayDtoAdministrationTypeEnum, PropertyDisplayDtoVatRateCountryCodeEnum, PropertyLegacyDtoAdministrationTypeEnum,
} from 'api/accounting';
import { OptionProps } from 'rc-select/lib/Option';
import { getChartOfAccountsArticleUrl } from 'storybook-components/feedback/AlertArticleLink/ArticleUrlUtils';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import SelectInput from '../../../../elements/Inputs/SelectInput/SelectInput';
import { usePropertiesAndAccounts } from '../../services/usePropertiesAndAccounts';
import { useValidationErrors } from '../../services/useValidationErrors';
import { ICONS } from '../../../../components/icons';
import { useManualExchangeSection1 } from './useManualExchangeSection1';
import { SPACE_SIZES } from '../../../../lib/constants';

export default function ManualExchangeSection1(): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const {
    getProperties, properties, accounts, onChange, formValues,
  } = usePropertiesAndAccounts();

  const { counterAccounts, accountTags, bankAccounts } = accounts.data ?? {};

  const { validationErrors } = useValidationErrors();

  const administrationType = formValues?.property?.administrationType as unknown as PropertyLegacyDtoAdministrationTypeEnum;

  const infoText = administrationType
    ? (
      <Link target="_blank" to={{ pathname: getChartOfAccountsArticleUrl(administrationType) }}>
        {tl(translations.creationPage.sections.section1.accountArticleLabel)}
      </Link>
    )
    : undefined;

  useEffect(() => {
    getProperties([FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG, FindFilteredPropertiesUsingGETAdministrationTypesEnum.MV, FindFilteredPropertiesUsingGETAdministrationTypesEnum.SEV, FindFilteredPropertiesUsingGETAdministrationTypesEnum.SEV_MV]);
  }, []);


  type PropertySelectOptionType = DefaultOptionType & {
    propertyhrid: string,
    administrationtype: PropertyDisplayDtoAdministrationTypeEnum,
    vatratecountrycode?: PropertyDisplayDtoVatRateCountryCodeEnum,
  }

  const { getContractTypes, contractTypeSelectorDisabled } = useManualExchangeSection1();

  return (
    <div className="ManualExchangeSection1">
      <Spin spinning={accounts.loading} indicator={<LoadingIndicator size="large" />}>
        <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
          <Col span={24} className="column property-select">
            <Form.Item
              label={`${tl(translations.creationPage.sections.section1.property)} *`}
              validateStatus={validationErrors.property?.state}
              help={<span className="validation-message">{validationErrors.property?.errorMessage}</span>}
            >
              <Select
                className="Select"
                showSearch
                placeholder={tl(translations.creationPage.sections.section1.propertySelectPlaceholder)}
                onChange={(val, option) => {
                  onChange('property', {
                    id: val,
                    propertyHrId: (option as PropertySelectOptionType).propertyhrid,
                    administrationType: (option as PropertySelectOptionType).administrationtype,
                    vatRateCountryCode: (option as PropertySelectOptionType).vatratecountrycode,
                  } as Property);
                }}
                filterOption={((input: any, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                disabled={properties.loading}
                suffixIcon={<Icon component={ICONS.triangleDown} />}
              >
                {properties.data?.map(prp => (
                  <Select.Option
                    value={prp.id!}
                    key={prp.id}
                    // propertyhrid is spelled lowercase because react doesn't recognize custom attributes with upprecase letters
                    propertyhrid={prp.propertyHrId}
                    administrationtype={prp.administrationType}
                    vatratecountrycode={prp.vatRateCountryCode}
                    label={`${prp.propertyIdInternal} - ${prp.name}`}
                  >
                    {`${prp.propertyIdInternal} - ${prp.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <SelectInput
              required
              showSearch
              label={tl(translations.creationPage.sections.section1.contractType)}
              onChange={(val) => {
                onChange('contractType', val);
              }}
              value={formValues.contractType}
              options={getContractTypes()}
              disabled={contractTypeSelectorDisabled}
              validationState={validationErrors.contractType?.state}
              validationMessage={validationErrors.contractType?.errorMessage}
            />
          </Col>
          <Col span={12}>
            <SelectInput
              required
              showSearch
              label={tl(translations.creationPage.sections.section1.bankAccount)}
              options={bankAccounts ?? []}
              disabled={bankAccounts === null}
              onChange={(val) => {
                onChange('bankAccount', val);
              }}
              value={formValues.bankAccount}
              validationState={validationErrors.bankAccount?.state}
              validationMessage={validationErrors.bankAccount?.errorMessage}
            />
          </Col>
          <Col span={12}>
            <SelectInput
              required
              showSearch
              label={tl(translations.creationPage.sections.section1.counterAccount)}
              onChange={(val) => {
                onChange('counterAccount', val);
              }}
              value={formValues.counterAccount}
              options={counterAccounts ?? []}
              disabled={counterAccounts === null}
              validationState={validationErrors.counterAccount?.state}
              validationMessage={validationErrors.counterAccount?.errorMessage}
              filterOption={((input: string, option: OptionProps) => (
                option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  || option.props.title.replaceAll('/', '').includes(input))
              )}
              infoText={infoText}
            />
          </Col>
          <Col span={12}>
            <SelectInput
              required
              showSearch
              label={tl(translations.creationPage.sections.section1.contractAccount)}
              options={accountTags ?? []}
              disabled={accountTags === null}
              onChange={(val) => {
                onChange('accountTag', val);
              }}
              value={formValues.accountTag}
              validationState={validationErrors.accountTag?.state}
              validationMessage={validationErrors.accountTag?.errorMessage}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
