import { Link, useRouteMatch } from 'react-router-dom';
import './TabLink.scss';

export interface TabLinkProps {
    links: TabLinkConfig[],
}

export interface TabLinkConfig {
    label: string,
    link: string,
}

export const TabLink = ({ links }: TabLinkProps): JSX.Element => {
  const matchedRout = useRouteMatch();

  const tabs = links.map((l) => {
    const activeTab = matchedRout.url.includes(l.link);
    return (
      <span key={l.link} className={activeTab ? 'tab-active' : ''}>
        <Link to={l.link}>
          {l.label}
        </Link>
      </span>
    );
  });

  return (
    <div className="TabLinks">
      {tabs}
    </div>
  );
};


TabLink.defaultProps = {};
