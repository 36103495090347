import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  DocumentApi,
  GetDocumentsByFilterUsingGETSourceTypeEnum,
} from 'api/public';
import { AuthContext } from 'contexts/AuthContext';

export function useGetInvoiceDocumentId(invoiceId: number) {
  const [documentId, setDocumentId] = useState<number>();

  const { publicApiConfiguration } = useContext(AuthContext);
  const documentApi = new DocumentApi(publicApiConfiguration('public'));

  useEffect(() => {
    if (invoiceId) {
      documentApi.getDocumentsByFilterUsingGET({
        sourceType: GetDocumentsByFilterUsingGETSourceTypeEnum.INVOICE,
        sourceId: invoiceId,
      })
        .then((resp) => {
          setDocumentId(resp.content?.[0]?.id);
        })
        .catch((e) => {
          console.warn('No document found for invoice', e);
        });
    }
  }, [invoiceId]);

  return {
    documentId,
  };
}
