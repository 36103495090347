import React from 'react';
import { Checkbox } from 'antd';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import { PagesOrientationType } from 'pages/PdfPageAssigner/services/interfaces';
import { usePdfAssignmentCheckboxWrapper } from './usePdfAssignmentCheckboxWrapper';
import './PdfAssignmentCheckboxWrapper.scss';

type Props = {
  children: React.ReactNode,
  documentId: number,
  pageNumber: number,
}

const PdfAssignmentCheckboxWrapper = React.forwardRef<HTMLDivElement, Props>(({ children, documentId, pageNumber }: Props, ref) => {
  const {
    viewMode,
    onChangeCheckbox,
    checked,
    tagLabel,
    tooltipMessage,
    loading,
    isInvalid,
    borderType,
  } = usePdfAssignmentCheckboxWrapper(documentId, pageNumber);

  const containerClassName = `PdfAssignmentCheckboxWrapper${viewMode === PagesOrientationType.GRID ? 'Grid' : 'List'}`;
  const assignmentTagClassName = `assignment-tag ${isInvalid ? 'has-error' : ''}`;


  return (
    <div className={containerClassName} ref={ref}>
      <div className={`checkbox-wrapper-content ${borderType}`}>
        {tagLabel ? (
          <PopoverTruncatingText
            value={tagLabel}
            tooltipMessage={tooltipMessage}
            containerClassName={containerClassName}
            className={assignmentTagClassName}
            placement="bottomLeft"
          />
        ) : null}

        <Checkbox
          className="pdf-assignment-checkbox"
          checked={checked}
          onChange={onChangeCheckbox}
          disabled={loading}
        />
        {children}
      </div>
    </div>
  );
});

export default PdfAssignmentCheckboxWrapper;
