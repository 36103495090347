import React from 'react';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { ICONS } from '../../../components/icons';
import './AdditionalEntityButton.scss';

interface additionalEntityButtonProps {
  label?: string,
  onClick: () => void,
  disabled?: boolean
  className?: string
}

export default function AdditionalEntityButton(props: additionalEntityButtonProps): JSX.Element {
  const {
    onClick, disabled, label, className,
  } = props;
  return (
    <Button
      className={`AdditionalEntityButton ${className || ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon component={ICONS.plus} />
      <div className="label">
        {label}
      </div>
    </Button>
  );
}
