import React from 'react';
import { Table } from 'antd';
import Amount from 'storybook-components/typography/Amount/Amount';
import TableSummaryCellWithTwoLines from 'storybook-components/table/TableSummaryCellWithTwoLines/TableSummaryCellWithTwoLines';
import { TableSummaryIconCell } from '../TableSummaryIconCell/TableSummaryIconCell';

export interface TableSummaryTotalAmountProps {
  totalAmount: React.ReactNode, // amount already formatted.
  amountLabel: React.ReactNode,
  iconCellColSpan?: number
  amountCellColSpan?: number
}

export default function TableSummaryTotalAmount(props: TableSummaryTotalAmountProps) {
  const {
    totalAmount,
    amountLabel,
    iconCellColSpan,
    amountCellColSpan,
  } = props;
  return (
    <Table.Summary fixed>
      <Table.Summary.Row className="TableSummaryTotalAmount">
        <TableSummaryIconCell colSpan={iconCellColSpan} />
        <TableSummaryCellWithTwoLines
          colSpan={amountCellColSpan}
          index={iconCellColSpan}
          firstElement={<Amount>{totalAmount}</Amount>}
          secondElement={amountLabel}
        />
      </Table.Summary.Row>
    </Table.Summary>
  );
}

TableSummaryTotalAmount.defaultProps = {
  iconCellColSpan: 1,
  amountCellColSpan: 1,
};
