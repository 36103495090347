import { Configuration, GetAllUsingGETOrderEnum, UserControllerApi } from 'api/user';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { UsersListContext } from 'pages/Users/UserList/services/UsersListContext';
import { userListTranslations } from 'pages/Users/UserList/userListTranslations';
import { useContext, useRef } from 'react';

export const useLoadUsers = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const usersApi = new UserControllerApi(apiConfiguration('user') as unknown as Configuration);
  const usersContext = useContext(UsersListContext);
  const abortController = useRef<AbortController | undefined>(undefined);
  if (usersContext === undefined) {
    throw Error('useLoadUsers must be used within a UsersContextProvider');
  }
  const {
    usersList, setUsersList, filterState, sortOrder,
  } = usersContext;

  const loadUserList = (resetPage: boolean = false) => {
    setUsersList(prevState => prevState.startLoading());
    abortController.current?.abort();
    abortController.current = new AbortController();
    const { signal } = abortController.current;
    return (
      usersApi.getAllUsingGET({
        page: resetPage ? 0 : usersList.page,
        emailFilter: filterState?.email,
        sort: 'email', // hardcoded in absence of other fields
        order: sortOrder > 0 ? GetAllUsingGETOrderEnum.ASC : GetAllUsingGETOrderEnum.DESC,
      }, { signal })
        .then((response) => {
          setUsersList(prev => prev.loadPaged(response.content, resetPage, response.last));
        })
        .catch((e) => {
          if (signal?.aborted) return;
          console.error(e);
          setUsersList(prevState => prevState.failed());
          showNotification({
            key: 'loadUsersError',
            description: tl(userListTranslations.notifications.loadUsersListError.description),
            message: tl(userListTranslations.notifications.loadUsersListError.message),
            type: 'error',
          });
        })
    );
  };

  return {
    loadUserList,
  };
};
