import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { usePropertyAdministrationTypeFilterOption } from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';
import { Filter } from '../SearchBar/services/GlobalSearchContext';
import { translations } from './translations';

export default function useDefaultPropertyFilters(withAdministrationTypeFilter?: boolean): Filter[] {
  const { tl } = useContext(LanguageContext);
  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  const filters: Filter[] = [
    {
      type: 'text',
      name: tl(translations.internalNr),
      key: 'propertyIdInternal',
    },
    {
      type: 'text',
      name: tl(translations.name),
      key: 'name',
    },
  ];

  // have to check for !== false, as it will be called with undefined from all the flows where we don't filter for administrationTypa
  if (withAdministrationTypeFilter !== false) {
    filters.push(administrationTypeFilterOption);
  }


  return filters;
}
