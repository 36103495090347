import {
  Button, Skeleton,
} from 'antd';
import { formatCurrency } from 'lib/Utils';
import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import Amount from 'storybook-components/typography/Amount/Amount';
import { isNil } from 'lodash';
import { useDunningFeeCell } from './useDunningFeeCell';
import { DunningFeeModal } from './components/DunningFeeModal';
import './DunningFeeCell.scss';

interface DunningFeeCellProps {
  accountCode: string,
  contractId: number,
  propertyId: number,
  contractDunningLevel?: ContractProjectionDtoDunningLevelEnum,
}

export const DunningFeeCell = ({
  accountCode, contractId, propertyId, contractDunningLevel,
}: DunningFeeCellProps) => {
  const {
    onOpenModal, dunningFeeLocalValue, modalVisible, onCancel, disabled,
  } = useDunningFeeCell(accountCode, propertyId, contractDunningLevel);

  if (isNil(dunningFeeLocalValue)) {
    return null;
  }

  const color = disabled ? 'gray' : undefined;

  return (
    <>
      <Skeleton
        loading={!(contractId || propertyId)}
        active
        title={false}
        className="DunningFeeCell"
        paragraph={{ rows: 1, width: '100%' }}
      >
        <Button type="link" onClick={onOpenModal} disabled={disabled}>
          <Amount color={color}>{formatCurrency(dunningFeeLocalValue?.dunningFeeNet ?? 0)}</Amount>
        </Button>
      </Skeleton>
      <DunningFeeModal accountCode={accountCode} dunningFeeLocalValue={dunningFeeLocalValue} modalVisible={modalVisible} onCloseModal={onCancel} />
    </>
  );
};

DunningFeeCell.defaultProps = {
  contractDunningLevel: ContractProjectionDtoDunningLevelEnum.LEVEL_1,
};
