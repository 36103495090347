import { useContext } from 'react';

import { Alert } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { isEmpty } from 'lodash';
import {
  ProgressBar,
} from 'storybook-components/feedback/ProgressBar/ProgressBar';
import {
  PrimarySectionWrapper,
} from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';

import {
  FileUploadButton,
} from '../components/FileUploadButton/FileUploadButton';
import { translations } from './translations';
import {
  useDirectDebitMandateImportCsv,
} from './useDirectDebitMandateImportCsv';

export const DirectDebitMandateImportSection = () => {
  const { tl } = useContext(LanguageContext);
  const {
    dataValidationIssues, mandateData, loadCsvFile, ddmProgress, importDdms,
  } = useDirectDebitMandateImportCsv();
  const showImportButton = isEmpty(dataValidationIssues) && !isEmpty(mandateData);

  return (
    <PrimarySectionWrapper
      sectionNumber={8}
      sectionTitle={tl(translations.title)}
      sectionId="ddm-import"
    >
      {!ddmProgress.started ? (
        <>
          <FileUploadButton
            label={tl(translations.upload)}
            accept="text/csv"
            onChange={loadCsvFile}
          />
          {!isEmpty(mandateData)
            ? (
              <Alert
                message={`${mandateData.length} ${tl(translations.validations.success)}`}
                type="success"
              />
            )
            : <Alert message={tl(translations.description)} />}
          {
            !isEmpty(dataValidationIssues) && (
              <Alert
                message={tl(translations.validations.errors)}
                type="error"
                description={(
                  <ul>
                    {
                      dataValidationIssues.map(issue => (
                        <li key={issue.row + issue.message}>
                          {`${issue.row + 1}: ${issue.message}`}
                        </li>
                      ))
                    }
                  </ul>
                )}
              />
            ) }
          {showImportButton && (
            <Button
              onClick={() => importDdms()}
              type="primary"
            >
              {tl(translations.import.button)}
            </Button>
          )}
        </>
      ) : (
        <ProgressBar
          title={tl(translations.import.title)}
          description={tl(translations.import.description)}
          data={[{
            label: tl(translations.title),
            percentage: Math.round(ddmProgress.ddmsImported * 100 / Math.max(mandateData.length, 1)),
          }]}
        />
      )}
    </PrimarySectionWrapper>
  );
};
