import { useContext } from 'react';

import _ from 'lodash';
import {
  usePropertyAdministrationTypeFilterOption,
} from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';

import {
  FindFilteredUsingGET1Request,
  PaymentFilterDtoPaymentStatesEnum,
} from '../../api/accounting';
import {
  Filter,
} from '../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { PaymentListContext } from '../../contexts/PaymentListContext';
import { translations } from '../../elements/Translation/translations';
import {
  DECIMAL_MATCHER,
  deleteKey,
  hrIdMatcherParser,
  IBAN_MOD97_PATTERN,
} from '../../lib/Utils';

export const usePaymentFilters = () => {
  const { tl } = useContext(LanguageContext);
  const paymentListContext: any = useContext(PaymentListContext);
  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  const filters = [
    {
      type: 'enum',
      name: tl(translations.pages.payment.table.headers.state),
      key: 'paymentStates',
      options: Object.values(PaymentFilterDtoPaymentStatesEnum).map(value => ({
        label: tl(translations.pages.payment.table.paymentStates[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'date',
      name: tl(translations.pages.payment.filter.minExecutionDate),
      key: 'minExecutionDate',
    },
    {
      type: 'date',
      name: tl(translations.pages.payment.filter.maxExecutionDate),
      key: 'maxExecutionDate',
    },
    {
      type: 'decimal',
      name: tl(translations.pages.payment.filter.minAmount),
      matcher: DECIMAL_MATCHER,
      key: 'minAmount',
    },
    {
      type: 'decimal',
      name: tl(translations.pages.payment.filter.maxAmount),
      matcher: DECIMAL_MATCHER,
      key: 'maxAmount',
    },
    {
      type: 'decimal',
      name: tl(translations.pages.payment.filter.amount),
      matcher: DECIMAL_MATCHER,
      key: 'amount',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.businessPartnerName),
      key: 'counterpartName',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.businessPartnerIban),
      key: 'counterpartIban',
      matcher: IBAN_MOD97_PATTERN,
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.propertyIban),
      key: 'propertyIban',
      matcher: IBAN_MOD97_PATTERN,
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.propertyBic),
      key: 'propertyBic',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.propertyBankCode),
      key: 'propertyIban',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.propertyAccountInstitute),
      key: 'propertyAccountInstitute',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.invoiceHrId),
      key: 'invoiceHrId',
      matcher: hrIdMatcherParser('INV'),
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.statement),
      key: 'statement',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.filter.propertyIdInternalList),
      key: 'propertyIdInternalList',
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.propertyName),
      key: 'propertyName',
    },
    administrationTypeFilterOption,
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.createdBy),
      key: 'createdBy',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.filter.contactIds),
      key: 'contactIds',
      visibleInSearchDropdown: false,
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.accountant),
      key: 'accountantName',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.administrator),
      key: 'supervisorName',
    },
  ] as Filter[];

  const onChangeFilterState = (key: keyof FindFilteredUsingGET1Request, value: any[keyof FindFilteredUsingGET1Request]) => {
    paymentListContext.setPaymentFilterState(
      (currentFilter: any) => {
        const newFilter = _.cloneDeep(currentFilter);
        if (Array.isArray(value) && _.isEmpty(value)) {
          deleteKey(newFilter, key);
        } else {
          newFilter[key] = value;
        }
        return newFilter;
      },
    );
  };

  return {
    filters,
    onChangeFilterState,
  };
};
