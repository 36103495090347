import React, { useContext, useRef, useState } from 'react';

interface InvoiceEditorMiscDataContextValue {
  openSectionIndex: number,
  setOpenSectionIndex: React.Dispatch<React.SetStateAction<number>>,
  dirtModalVisible: boolean,
  setDirtModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  dirtModalSection: number,
  setDirtModalSection: React.Dispatch<React.SetStateAction<number>>,
  openNextSectionOnSuccessRef: React.MutableRefObject<boolean>,
  actionAfterSaveRef: React.MutableRefObject<AfterSaveAction | null>,
}

export enum AfterSaveAction {
    'OPEN_NEXT_SECTION',
    'CLOSE_SECTION',
    'NAVIGATE_BACK'
  }


export const InvoiceEditorMiscDataContext = React.createContext<InvoiceEditorMiscDataContextValue | undefined>(undefined);

export const useInvoiceEditorMiscDataContext = (usageName: string) => {
  const invoiceEditorMiscDataContext = useContext(InvoiceEditorMiscDataContext);

  if (invoiceEditorMiscDataContext === undefined) {
    throw new Error(`${usageName} must be used within a InvoiceEditorMiscDataContextProvider`);
  }

  return invoiceEditorMiscDataContext;
};

const InvoiceEditorMiscDataContextProvider = ({ children } : { children: React.ReactNode }) => {
  const [openSectionIndex, setOpenSectionIndex] = useState(0);
  const [dirtModalVisible, setDirtModalVisible] = useState(false);
  const [dirtModalSection, setDirtModalSection] = useState(-1);
  const actionAfterSaveRef = useRef<AfterSaveAction | null>(null);

  const openNextSectionOnSuccessRef = useRef(false);

  const value = {
    openSectionIndex,
    setOpenSectionIndex,
    dirtModalVisible,
    setDirtModalVisible,
    dirtModalSection,
    setDirtModalSection,
    openNextSectionOnSuccessRef,
    actionAfterSaveRef,
  };

  return (
    <InvoiceEditorMiscDataContext.Provider value={value}>
      {children}
    </InvoiceEditorMiscDataContext.Provider>
  );
};

export default InvoiceEditorMiscDataContextProvider;
