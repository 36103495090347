
export const ownerPaymentTableTranslations = {
  columns: {
    unitNrSharingDeclaration: {
      en: 'Nr.',
      de: 'Nr.',
    },
    ownerName: {
      en: 'Owner Name',
      de: 'Eigentümer',
    },
    amount: {
      en: 'Amount',
      de: 'Betrag',
    },
    direction: {
      en: 'Direction',
      de: 'Richtung',
    },
    processing: {
      en: 'Processing',
      de: 'Abwicklung',
    },
  },

  totalAmountLabel: {
    en: 'Total amount is split',
    de: 'Der Gesamtbetrag wurde verteilt',
  },
};
