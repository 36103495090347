import * as Sentry from '@sentry/react';
import { getEnvVar } from 'lib/getEnvVar';
import { history } from 'navigation/History';

/**
 *  Registers the google tag manager through which other tracking tools can be installed
 *  without programming
 * @param config
 */
const registerGoogleTagManager = () => {
  const googleTagManagerId = getEnvVar('tracking.googleTagManagerId') as string;
  if (!googleTagManagerId) {
    return;
  }
  const scriptTag = document.createElement('script');
  scriptTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManagerId}');
    `;
  document.head.appendChild(scriptTag);
  // It doesn't make sense to add noscript tagmanager
  // as the application doesn't work if there are no scripts enabled
  // <!-- Google Tag Manager (noscript) -->
  // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=`${config.googleTagManagerId}`"
  // height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  // <!-- End Google Tag Manager (noscript) -->
};


const registerSentry = () => {
  /**
   * This project has been set up to accept events from everywhere at no cost.
   */
  const DEFAULT_FE_PROJECT_DSN = 'https://68787c73731e11b4012d4af28713080b@sentry.develop.impower.de/2';

  const sentryDsn = getEnvVar('tracking.sentryDsn', DEFAULT_FE_PROJECT_DSN) as string;

  let envName = window.location.host; // covers localhost and unknown host
  if (/develop.impower.de/.test(window.location.hostname)) {
    envName = window.location.hostname.replace('.develop.impower.de', '');
  } else if (window.location.hostname === 'app.impower.de') {
    envName = 'production';
  }
  Sentry.init({
    dsn: sentryDsn,
    environment: envName,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    /**
     * The following errors are ignored because they have no visible impact on the UI.
     * ResizeObserver loop - account sheet
     * Rendering cancelled - pdf renderer
     * signal is aborted without reason - multiple places (not an error, just not handled correctly)
     */
    ignoreErrors: [
      'ResizeObserver loop',
      'Rendering cancelled',
      'signal is aborted without reason',
    ],

    // with the default of 10 the requests will be too large
    normalizeDepth: 3,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', `api.${window.location.hostname}`],

    // Capture Replay for 0% of sessions without errors,
    replaysSessionSampleRate: 0,
    // Capture Replay for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
  });
};

export default {
  registerGoogleTagManager,
  registerSentry,
};
