import React, { useRef } from 'react';
import { Form } from 'antd';
import moment from 'moment';
import './TimeInput.scss';
import InputProps from '../InputProps';
import TimeInputField from './TimeInputField/TimeInputField';

export default function TimeInput(props: InputProps<moment.Moment>): JSX.Element {
  const {
    className, inputClassName, disabled, label, value, required, validationState, validationMessage, onChange: onChangeProp,
  } = props;

  const onChange = (time: moment.Moment | undefined) => {
    onChangeProp(time);
  };

  const timeInputElement = useRef(null);
  const minute = value ? value.minute() : 0;
  const hour = value ? value.hour() : 0;
  return (
    <div className={`TimeInput ${className}`} ref={timeInputElement}>
      <Form.Item
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <TimeInputField
          className={`${inputClassName} ${disabled ? 'read-only' : ''}`}
          hour={hour}
          minute={minute}
          onChange={onChange}
          disabled={disabled || false}
        />
      </Form.Item>
    </div>
  );
}

TimeInput.defaultProps = {
  required: false,
  className: '',
  inputClassName: '',
};
