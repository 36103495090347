import {
  useContext,
  useState,
} from 'react';

import {
  ContractLegacyControllerApi,
  ContractUpdateDtoTypeEnum,
  DirectDebitMandateControllerApi,
  DirectDebitMandateCreationDto,
  DirectDebitMandateDto,
  UnitContractMandateUsageControllerApi,
  UnitContractMandateUsageCreationDto,
  UnitContractMandateUsageCreationDtoStateEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { isEmpty } from 'lodash';
import {
  getContractUpdateDtoFromProjection,
} from 'pages/ContractEditor/services/useSubmitContractCoreInformation';

import { DDMProgress } from '../interfaces';
import { loadCsv } from '../services/loadCsv';
import { executeInParallelBatch } from '../utils/executeInParallelBatch';
import { DirectDebitMandateData } from './interfaces';
import { translations } from './translations';

const mapRowToDirectDebitMandateData = (csvRow: any): DirectDebitMandateData => ({
  propertyId: csvRow.propertyId,
  bankAccountId: csvRow.bankAccountId,
  contractId: csvRow.contractId,
  directDebitMandateId: csvRow.directDebitMandateId,
  directDebitValidFromDate: csvRow.directDebitValidFromDate,
  directDebitSignedOnDate: csvRow.directDebitSignedOnDate,
});

export const useDirectDebitMandateImportCsv = () => {
  const [mandateData, setMandateData] = useState<DirectDebitMandateData[]>([]);
  const [dataValidationIssues, setDataValidationIssues] = useState<any[]>([]);
  const [ddmProgress, setDdmProgress] = useState<DDMProgress>({ started: false, finished: false, ddmsImported: 0 });
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const contractLegacyControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const directDebitMandateControllerApi = new DirectDebitMandateControllerApi(apiConfiguration('accounting'));
  const unitContractMandateUsageControllerApi = new UnitContractMandateUsageControllerApi(apiConfiguration('accounting'));


  const loadMandateDataFromCsvRows = (csvRows: any[]) => {
    const readMandateData: DirectDebitMandateData[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach((csvRow) => {
      const oneMandateData = mapRowToDirectDebitMandateData(csvRow);
      // no direct debit mandate
      if (!oneMandateData.directDebitMandateId && !oneMandateData.directDebitSignedOnDate && !oneMandateData.directDebitValidFromDate) {
        return;
      }
      readMandateData.push(oneMandateData);
      // check if prefilled data is there
      if (!oneMandateData.propertyId || !oneMandateData.bankAccountId || !oneMandateData.contractId) {
        validationIssues.push({ message: tl(translations.validations.issues.prefilled), row: csvRow._row - 1 });
      }
      // check if user provided data is filled out
      if (!oneMandateData.directDebitMandateId || !oneMandateData.directDebitSignedOnDate || !oneMandateData.directDebitValidFromDate) {
        validationIssues.push({ message: tl(translations.validations.issues.data), row: csvRow._row - 1 });
      }
      // no duplicate mandate for same property
      const duplicateMandates = mandateData.filter(mandate => mandate.propertyId === oneMandateData.propertyId && mandate.directDebitMandateId === oneMandateData.directDebitMandateId);
      if (!isEmpty(duplicateMandates)) {
        validationIssues.push({ message: tl(translations.validations.issues.duplicate), row: csvRow._row - 1 });
      }
      // only one mandate for a contract
      const sameContractDuplicateMandates = mandateData.filter(mandate => mandate.contractId === oneMandateData.contractId
        && mandate.bankAccountId !== oneMandateData.bankAccountId);
      if (!isEmpty(sameContractDuplicateMandates)) {
        validationIssues.push({ message: tl(translations.validations.issues.sameContract), row: csvRow._row - 1 });
      }
    });
    setDataValidationIssues(validationIssues);
    if (!isEmpty(validationIssues)) {
      setMandateData([]);
    } else {
      setMandateData(readMandateData);
    }
  };


  const loadCsvFile = (event) => {
    loadCsv(event.target.files, 0)
      .then(res => loadMandateDataFromCsvRows(res))
      .catch((err) => {
        showNotification({ message: tl(translations.validations.errors) });
        console.error('Error while loading csv', err);
      });
  };

  const importDdms = async () => {
    setDdmProgress({
      started: true,
      finished: false,
      ddmsImported: 0,
    });
    try {
      await importAllDdms();
    } catch (e) {
      console.error('Import stopped', e, e.response);
      showNotification({
        key: 'importValidation-error',
        duration: 0,
        message: tl(translations.notifications.error.title),
        description: tl(translations.notifications.error.description),
        type: 'error',
      });
      setDdmProgress(prev => ({ ...prev, finished: false }));
    }
  };

  const importAllDdms = async () => {
    await executeInParallelBatch(mandateData, 1, async (data: DirectDebitMandateData) => {
      // add bank account to contract
      const contracts = await contractLegacyControllerApi.getContractsUsingGET({ contractIds: [data.contractId] });
      const [contract] = contracts.filter(c => !c.isVacant); // vacant contracts cannot have direct debit mandates
      contract.bankAccountId = data.bankAccountId;
      const contractUpdateDto = getContractUpdateDtoFromProjection(contract);
      await contractLegacyControllerApi.updateContractsUsingPUT({ contractUpdateDtos: [contractUpdateDto] });


      // check if mandate exists
      let newDirectDebitMandate: DirectDebitMandateDto;
      let existingMandates = await directDebitMandateControllerApi.getMandatesUsingGET({ propertyIds: [data.propertyId], bankAccountIds: [data.bankAccountId] });
      existingMandates = existingMandates.filter(ddm => ddm.directDebitMandateId === data.directDebitMandateId);
      if (isEmpty(existingMandates)) {
        // create mandate
        const directDebitMandateCreationDto: DirectDebitMandateCreationDto = {
          propertyId: data.propertyId,
          bankAccountId: data.bankAccountId,
          directDebitMandateId: data.directDebitMandateId,
          directDebitSignedOnDate: data.directDebitSignedOnDate,
          directDebitValidFromDate: data.directDebitValidFromDate,
        };
        newDirectDebitMandate = await directDebitMandateControllerApi.createDirectDebitMandateUsingPOST({ directDebitMandateCreationDto });
      } else {
        [newDirectDebitMandate] = existingMandates;
      }

      // create mandate usage
      const unitContractMandateCreationDtos: UnitContractMandateUsageCreationDto[] = [{
        directDebitMandateId: newDirectDebitMandate.id,
        state: UnitContractMandateUsageCreationDtoStateEnum.BOOKED,
        unitContractId: data.contractId,
      }];
      await unitContractMandateUsageControllerApi.saveUsingPOST({ unitContractMandateCreationDtos });

      setDdmProgress(prev => ({ ...prev, ddmsImported: prev.ddmsImported + 1 }));
    });
  };

  return {
    mandateData,
    dataValidationIssues,
    loadCsvFile,
    importDdms,
    ddmProgress,
  };
};
