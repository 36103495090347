/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data that describes an existing connection
 * @export
 * @interface ConnectionDto
 */
export interface ConnectionDto {
    /**
     * Id of an application
     * @type {number}
     * @memberof ConnectionDto
     */
    appId?: number;
    /**
     * Name of the application of the connection
     * @type {string}
     * @memberof ConnectionDto
     */
    appName?: string;
    /**
     * Creation time of the entity.
     * @type {Date}
     * @memberof ConnectionDto
     */
    created?: Date;
    /**
     * Id of the entity.
     * @type {number}
     * @memberof ConnectionDto
     */
    id?: number;
    /**
     * Name of the connection
     * @type {string}
     * @memberof ConnectionDto
     */
    name?: string;
    /**
     * Last update time of the entity.
     * @type {Date}
     * @memberof ConnectionDto
     */
    updated?: Date;
    /**
     * Webhook of the connection that will be called like
     * ```
     * POST https://your.webhook.url/path/somewhere
     * Authorization: Bearer TOKEN
     * 
     * {
     *   "connectionId": 0,
     *   "entityType": "properties|contacts|invoices|etc.",
     *   "entityId": 0,
     *   "eventType": "CREATE|UPDATE|DELETE"
     * }```
     * @type {string}
     * @memberof ConnectionDto
     */
    webhookUrl?: string;
}

/**
 * Check if a given object implements the ConnectionDto interface.
 */
export function instanceOfConnectionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectionDtoFromJSON(json: any): ConnectionDto {
    return ConnectionDtoFromJSONTyped(json, false);
}

export function ConnectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appId': !exists(json, 'appId') ? undefined : json['appId'],
        'appName': !exists(json, 'appName') ? undefined : json['appName'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'webhookUrl': !exists(json, 'webhookUrl') ? undefined : json['webhookUrl'],
    };
}

export function ConnectionDtoToJSON(value?: ConnectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appId': value.appId,
        'appName': value.appName,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'id': value.id,
        'name': value.name,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'webhookUrl': value.webhookUrl,
    };
}

