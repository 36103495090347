import { PropertyLegacyControllerApi, PropertyLegacyDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import { propertyPageTranslations } from './propertyPageTranslations';
import { getPropertyEditingRoute } from 'pages/Property/routes';

/**
 * Redirects to legacy property viewing pages based on property type
 */
export const PropertyPage = () => {
  const [redirectPath, setRedirectPath] = useState('');
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const { propertyId: propertyIdString } = useParams<{ propertyId: string }>();
  const propertyId = parseInt(propertyIdString, 10);

  const loadRedirectPath = () => {
    // propertyId parses to NaN for the legacy routes /properties/WEG00005 or /properties/create
    if (typeof propertyId === 'number' && !isNaN(propertyId)) {
      propertyControllerApi.getPropertyByIdUsingGET({ propertyId })
        .then((property: PropertyLegacyDto) => {
          setRedirectPath(getPropertyEditingRoute(property.propertyHrId));
        }).catch(() => {
          showNotification({ type: 'warning', message: tl(propertyPageTranslations.propertyNotFound) });
          setRedirectPath('/properties');
        });
    }
  };
  useEffect(loadRedirectPath, [propertyId]);
  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (<LoadingScreen />);
};
