import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { isEmpty, uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useOrderOpenBalancesContext, useOrderOpenBalancesSelectionContext } from './OrderOpenBalancesContext';
import { useOnLoadPropertyAndContractBalances } from './useOnLoadPropertyAndContractBalances';


export const getAllKeys = (dataSource) => {
  if (!dataSource || isEmpty(dataSource)) {
    return [];
  }

  return [
    ...dataSource.map(item => item.id), // TODO this is `rowKey`
    ...dataSource.map(item => getAllKeys(item.children)).flat(),
  ];
};


export const useOrderOpenBalancesCheckboxes = () => {
  const {
    setInnerTableSelectedRowKeysCurrent,
    setInnerTableSelectedRowKeysTotal,
    setOuterTableSelectedRowKeysCurrent,
    setOuterTableSelectedRowKeysTotal,
    innerTableSelectedRowKeysCurrent,
    outerTableSelectedRowKeysCurrent,
  } = useOrderOpenBalancesSelectionContext('useOrderOpenBalancesCheckboxes');

  const { propertyAndContractBalanceList } = useOrderOpenBalancesContext('useOrderOpenBalancesCheckboxes');

  const { onLoadPropertyAndContractBalancesAllPages } = useOnLoadPropertyAndContractBalances();

  const keysOfAccountsOfExcludedContracts = useMemo(() => (
    propertyAndContractBalanceList.data.map(
      prp => prp.debtorBalancesGrouped
        .filter(db => db.contractDunningLevel === ContractProjectionDtoDunningLevelEnum.EXCLUDED)
        .map(db => `${prp.propertyId}-${db.accountCode}`),
    ).flat()
  ), [propertyAndContractBalanceList.data]);


  const onChangeInnerTableSelectedRowKeys = (propertyId, selectedKeysAccordingToTable: any[]) => {
    // we need to take into consideration the parentKey
    // because when inner tables call the onChange
    // then the keys are only for the inner table; so the other keys shouldn't be removed,
    // only if they belong to the same parentKey

    setInnerTableSelectedRowKeysCurrent((prev) => {
      // also set Outer table selected keys if any inner table is selected
      if (!isEmpty(selectedKeysAccordingToTable)) {
        setOuterTableSelectedRowKeysCurrent((prevOuterCurrent) => {
          // add it to the current outer table keys
          const newOuterTableCurrentSelectedKeys = uniq([...prevOuterCurrent, propertyId]);

          // also add the newly selected keys to the outer table total
          setOuterTableSelectedRowKeysTotal(prevOuterTotal => uniq([
            ...prevOuterTotal,
            ...newOuterTableCurrentSelectedKeys,
          ]));

          return newOuterTableCurrentSelectedKeys;
        });
      } else {
        // if all inner rows were deselected then deselect outer table row too
        setOuterTableSelectedRowKeysCurrent((prevOuterCurrent) => {
          // remove from total outer table keys
          setOuterTableSelectedRowKeysTotal(prevOuterTotal => prevOuterTotal.filter(key => key !== propertyId));
          // remove from current outer table keys
          return prevOuterCurrent.filter(key => key !== propertyId);
        });
      }

      // add/remove the newly selected keys to the total selection
      setInnerTableSelectedRowKeysTotal((prevInnerTotal) => {
        const property = propertyAndContractBalanceList.data.find(prp => prp.propertyId === propertyId);
        /**
         * `selectedKeysAccordingToTable` will give you all of the keys of the selected rows in the current inner table (a.k.a. current property)
         * BUT we must also keep the selected keys of this property that are not currently in view (they are filtered out)
         */
        const [selectedKeysBelongingToThisInnerTableTotal, selectedKeysBelongingToOtherInnerTablesTotal] = prevInnerTotal.reduce((acc, curr) => {
          if (curr.startsWith(propertyId)) {
            acc[0].push(curr);
          } else {
            acc[1].push(curr);
          }
          return acc;
        }, [[], []]);

        const selectedKeysBelongingToThisPropertyButNotInCurrentView = selectedKeysBelongingToThisInnerTableTotal.filter(
          key => !property.allKeysOfDebtorBalancesGrouped.includes(key),
        );
        return [
          ...selectedKeysBelongingToOtherInnerTablesTotal,
          ...selectedKeysAccordingToTable,
          ...selectedKeysBelongingToThisPropertyButNotInCurrentView,
        ];
      });


      // add/remove the newly selected keys to the current selection
      const selectedKeysBelongingToOtherInnerTablesCurrent = prev?.filter(key => !key.startsWith(propertyId));
      return [
        ...selectedKeysBelongingToOtherInnerTablesCurrent,
        ...selectedKeysAccordingToTable,
      ];
    });
  };

  const onChangeOuterTableSelectedRowKeys = (selectedPropertiesIds, selectedRecord) => {
    // when 'select all' checkbox is checked
    if (selectedRecord === undefined) {
      return;
    }

    setOuterTableSelectedRowKeysCurrent((prevOuterCurrent) => {
      // Finding newly selected properties in the outer table
      const newlySelectedOuterTable = selectedPropertiesIds?.filter(
        propertyId => !prevOuterCurrent.includes(propertyId),
      );

      const deselectedOuterTable = prevOuterCurrent.filter(
        propertyId => !selectedPropertiesIds.includes(propertyId),
      );


      // if we "deselect" an outer table BUT not all of its children were selected
      // then instead of deselecting the outer table, we should select all of its children
      let shouldDeselect = true;

      // Filter the selected keys array based on the excluded keys array
      const allKeysOfClickedPropertysContractsThatAreNotExcluded = selectedRecord.allKeysOfDebtorBalancesGrouped
        .filter(key => !keysOfAccountsOfExcludedContracts.includes(key)
            && !keysOfAccountsOfExcludedContracts.some(excludedKey => key.startsWith(`${excludedKey}/`)));

      setInnerTableSelectedRowKeysCurrent((prevInnerCurrent) => {
        if (!isEmpty(deselectedOuterTable)) {
          const allKeysOfDeselectedOuterTableThatWereNotSelected = allKeysOfClickedPropertysContractsThatAreNotExcluded.filter(
            key => !prevInnerCurrent.includes(key),
          );


          if (!isEmpty(allKeysOfDeselectedOuterTableThatWereNotSelected)) {
            shouldDeselect = false;

            const newInnerSelectedKeys = uniq([
              ...prevInnerCurrent,
              ...allKeysOfDeselectedOuterTableThatWereNotSelected,
            ]);

            // add the newly selected keys to the innerTotal
            setInnerTableSelectedRowKeysTotal(prevInnerTotal => uniq([
              ...prevInnerTotal,
              ...newInnerSelectedKeys,
            ]));

            // add the newly selected keys to the innerCurrent
            return newInnerSelectedKeys;
          }
          // else: everything was already selected, so we should therefore deselect everything

          // remove the deselected property's contracts from innerTotal
          setInnerTableSelectedRowKeysTotal(prevInnerTotal => prevInnerTotal.filter(key => !key.startsWith(deselectedOuterTable.toString())));
          // remove the deselected property's contracts from innerCurrent
          return prevInnerCurrent.filter(key => !key.startsWith(deselectedOuterTable.toString()));
        }
        // else: the property is newly selected, it didn't have previous selections

        const stillSelectedKeys = prevInnerCurrent.filter(
          // keep all inner-table selections that belong to a propertyId that is still selected,
          // excluding exact matches to avoid duplicates
          key => selectedPropertiesIds.some(propertyId => key.startsWith(propertyId) && key !== propertyId),
        );

        const allKeysOfChildrenOfSelectedRecord = newlySelectedOuterTable
          ? allKeysOfClickedPropertysContractsThatAreNotExcluded
          : [];

        const newlySelectedInnerTableKeys = uniq([
          ...stillSelectedKeys,
          ...allKeysOfChildrenOfSelectedRecord,
        ]);

        // add the newly selected keys to innerTotal
        setInnerTableSelectedRowKeysTotal(prevInnerTotal => uniq([
          ...prevInnerTotal,
          ...newlySelectedInnerTableKeys,
        ]));

        // add the newly selected keys to innerCurrent
        return newlySelectedInnerTableKeys;
      });


      if (!shouldDeselect) {
        // if we didn't deselect the property but instead selected all of its children
        // then we don't want to change the outer table selection
        return prevOuterCurrent;
      }
      // else: we deselected the property, so we should remove it from the outer table selection

      // remove the deselected property from outerTotal
      setOuterTableSelectedRowKeysTotal(prevOuterTotal => uniq([
        ...prevOuterTotal.filter(key => !deselectedOuterTable.includes(key)),
        ...selectedPropertiesIds,
      ]));

      // remove the deselected property from outerCurrent
      return selectedPropertiesIds;
    });
  };


  const onSelectAll = () => {
    // if all rows are selected => unselect displayed records
    if (propertyAndContractBalanceList.data.length === outerTableSelectedRowKeysCurrent.length) {
      onUnselectCurrentSelectedKeys();
      return;
    }

    onLoadPropertyAndContractBalancesAllPages()
      .then((response) => {
        const selectedPropertyIds = response.map(record => record.propertyId);

        const allKeysOfDebtorBalancesGroupedForSelectedRecords = response?.filter(r => !outerTableSelectedRowKeysCurrent.includes(r.propertyId))
          .map(record => record.allKeysOfDebtorBalancesGrouped).flat();

        onChangeOuterTableSelectedRowKeys(selectedPropertyIds,
          { allKeysOfDebtorBalancesGrouped: allKeysOfDebtorBalancesGroupedForSelectedRecords });
      });
  };

  const onUnselectCurrentSelectedKeys = () => {
    setInnerTableSelectedRowKeysTotal(prev => prev.filter(rowKeyTotal => !innerTableSelectedRowKeysCurrent.includes(rowKeyTotal)));
    setOuterTableSelectedRowKeysTotal(prev => prev.filter(rowKeyTotal => !outerTableSelectedRowKeysCurrent.includes(rowKeyTotal)));
    setInnerTableSelectedRowKeysCurrent([]);
    setOuterTableSelectedRowKeysCurrent([]);
  };

  // manually calculate and set `indeterminate` state for the outer table's checkbox
  const getCheckboxProps = useCallback((record) => {
    const allSelectedKeysForThisProperty = innerTableSelectedRowKeysCurrent
      .filter(key => key.startsWith(`${record.propertyId}-`));

    const indeterminate = !isEmpty(allSelectedKeysForThisProperty) && allSelectedKeysForThisProperty.length !== record.allKeysOfDebtorBalancesGrouped.length;

    return { indeterminate };
  }, [innerTableSelectedRowKeysCurrent]);


  return {
    innerTableSelectedRowKeysCurrent,
    outerTableSelectedRowKeysCurrent,
    onChangeInnerTableSelectedRowKeys,
    onChangeOuterTableSelectedRowKeys,
    getCheckboxProps,
    onSelectAll,
  };
};
