export const translations: any = {
  propertyData: {
    title: {
      de: 'Objektdaten',
      en: 'Property Data',
    },
    subMenuPoints: {
      property: {
        title: {
          en: 'Property',
          de: 'Objekte',
        },
        navItems: {
          propertyOverview: {
            en: 'Property Overview',
            de: 'Objekte',
          },
        },
      },
    },
  },
  accounting: {
    title: {
      de: 'Buchhaltung',
      en: 'Accounting',
    },
    subMenuPoints: {
      bookings: {
        title: {
          de: 'Buchen',
          en: 'Bookings',
        },
        navItems: {
          invoices: {
            en: 'Invoices',
            de: 'Rechnungen',
          },
          bankTransactions: {
            en: 'Bank transactions',
            de: 'Bankumsätze',
          },
          standingOrders: {
            en: 'Standing orders',
            de: 'Daueraufträge',
          },
          manualExchange: {
            en: 'Manual Exchange',
            de: 'Manuelle Buchung',
          },
        },
      },
      accounts: {
        title: {
          en: 'Accounts',
          de: 'Sachkonten',
        },
        navItems: {
          accountBalances: {
            en: 'Account Balances',
            de: 'Kontensalden',
          },
          distributionKeys: {
            en: 'Distribution keys',
            de: 'Verteilerschlüssel',
          },
          orderOpenBalances: {
            en: 'Order Open Balances',
            de: 'Offene Salden',
          },
        },
      },
      planningAndReporting: {
        title: {
          en: 'Planning and Reporting',
          de: 'Planung und Abrechnung',
        },
        navItems: {
          houseMoneySettlement: {
            en: 'House money settlement',
            de: 'Hausgeldabrechnung',
          },
          economicPlan: {
            en: 'Economic plan',
            de: 'Wirtschaftsplan',
          },
          specialContribution: {
            en: 'Special Contribution',
            de: 'Sonderumlage',
          },
          wka: {
            en: 'WKA',
            de: 'Wärmekostenabrechnungen',
          },
          profitAndLoss: {
            en: 'Profit and Loss Reports',
            de: 'Eigentümerbericht',
          },
          opsCostReport: {
            en: 'Ops Cost Reports',
            de: 'Betriebskostenabrechnung',
          },
        },
      },
      payment: {
        title: {
          de: 'Zahlungsverkehr',
          en: 'Payment',
        },
        navItems: {
          bankAccounts: {
            en: 'Bank Accounts',
            de: 'Bankkonten',
          },
          paymentRun: {
            en: 'Payment run',
            de: 'Zahlungslauf',
          },
          directDebits: {
            en: 'Direct Debits',
            de: 'Lastschriftzahlungen',
          },
          transactionImports: {
            en: 'Transaction imports',
            de: 'Transaktionsimporte',
          },
        },
      },
    },
  },
  meetings: {
    title: {
      de: 'Versammlungen',
      en: 'Meetings',
    },
    subMenuPoints: {
      ownersMeeting: {
        title: {
          en: 'Owners meetings',
          de: 'ETV',
        },
        navItems: {
          ownersMeetingInvitations: {
            en: 'Meetings invitation',
            de: 'ETV Einladung',
          },
          ownersMeetingProtocol: {
            en: 'Owners meetings protocol',
            de: 'ETV Protokoll',
          },
        },
      },
      resolutionRecord: {
        title: {
          en: 'Resolution Record',
          de: 'Beschlusssammlung',
        },
        navItems: {
          resolutionRecordOverview: {
            en: 'Resolution Record Overview',
            de: 'Beschlusssammlung',
          },
        },
      },
    },
  },
  contacts: {
    title: {
      de: 'Kontakte',
      en: 'Contacts',
    },
    subMenuPoints: {
      contacts: {
        title: {
          en: 'Contacts',
          de: 'Kontakte',
        },
        navItems: {
          contactPerson: {
            en: 'People',
            de: 'Personen',
          },
          users: {
            en: 'User Management',
            de: 'Benutzerverwaltung',
          },
          contactCompany: {
            de: 'Unternehmen',
            en: 'Company',
          },
        },
      },
    },
  },
  communication: {
    title: {
      en: 'Communication',
      de: 'Kommunikation',
    },
    submenuPoints: {
      letters: {
        title: {
          en: 'Letters',
          de: 'Briefe',
        },
        navItems: {
          serialLetters: {
            en: 'Serial Letters',
            de: 'Serienbriefe',
          },
        },
      },
      messages: {
        title: {
          en: 'Messages',
          de: 'Nachrichten',
        },
      },
    },
  },
  settings: {
    title: {
      en: 'Settings',
      de: 'Einstellungen',
    },
    subMenuPoints: {
      settings: {
        title: {
          en: 'Settings',
          de: 'Einstellungen',
        },
      },
      watermarks: {
        title: {
          en: 'Watermark',
          de: 'Briefpapier',
        },
        navItems: {
          settings: {
            en: 'Watermark',
            de: 'Briefpapier',
          },
        },
      },
    },
  },
  documents: {
    title: {
      en: 'Documents',
      de: 'Dokumente',
    },
    subMenuPoints: {
      navItems: {
        en: 'Documents',
        de: 'Dokumente',
      },
    },
  },
  logout: {
    title: {
      en: 'Log out',
      de: 'Abmelden',
    },
  },
};
