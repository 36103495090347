import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { translations } from 'elements/Translation/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { HouseMoneySettlementContext } from 'contexts/HouseMoneySettlementContext';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import useDefaultPropertyFilters from 'components/Header/components/data/useDefaultPropertyFilters';
import { useHouseMoneySettlementPropertyListColumns } from './useHouseMoneySettlementPropertyListColumns';

export default function HouseMoneySettlement(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const houseMoneySettlementContext: any = useContext(HouseMoneySettlementContext);
  const history = useHistory();
  const { propertyHrId } = useParams<{propertyHrId:string}>();
  useGlobalSearch({
    key: 'houseMoneySettlements',
    filterProps: {
      availableFilters: useDefaultPropertyFilters(false),
      setFilter: (key: string, value: any) => houseMoneySettlementContext.setFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);
          newFilter[key] = value;
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      filterState: houseMoneySettlementContext.filterState,
      onSetDefaultFilterFromQueryParams: houseMoneySettlementContext.updateFilterState,
    },
  });

  useEffect(() => () => {
    houseMoneySettlementContext.onClearFilterState();
  }, []);

  useEffect(() => {
    if (propertyHrId) {
      houseMoneySettlementContext.updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  const toTableData = (data: any) => ({
    ...data,
    propertyHrId: data.propertyHrId,
    hga: {
      ...data.houseMoneySettlement,
      status: tl(translations.pages.houseMoneySettlement.table.houseMoneySettlementStatuses[data.houseMoneySettlement.status.toLowerCase()]),
    },
  });

  const tableData = useMemo(() => houseMoneySettlementContext.houseMoneySettlementListState.data.map(toTableData), [houseMoneySettlementContext.houseMoneySettlementListState.data]);
  const smartTable = useSmartTable({
    tableName: 'houseMoneySettlementPropertyList',
    columns: useHouseMoneySettlementPropertyListColumns(),
    dataSource: tableData,
    contentLoading: houseMoneySettlementContext.houseMoneySettlementListState.loading,
    onRow: (record: any) => ({ onClick: () => history.push(`/house-money-settlement/${record.propertyHrId}`) }),
    infiniteScrollerProps: {
      hasMoreData: !houseMoneySettlementContext.houseMoneySettlementListState.lastPage,
      loadMoreData: houseMoneySettlementContext.onLoadHouseMoneySettlementList,
    },
    rowKey: 'propertyHrId',
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'houseMoneySettlement',
    navItems: subcategorySwitcherItems.accounting,
  };
  return (
    <div className="DistributionKeyTable page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      <SmartTable {...smartTable} />
    </div>
  );
}
