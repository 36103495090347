import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { ConfigProvider, Table } from 'antd';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { translations } from 'pages/OwnersMeetingProtocol/translations';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { useSigningPersonsTable } from './useSigningPersonsTable';


export const SigningPersonsTable = () => {
  const { tl } = useContext(LanguageContext);
  const { columns, dataSource, loading } = useSigningPersonsTable();


  return (
    <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(translations.editPage.sections.attendeeList.emptyMessage)} />}>
      <Table
        id="SigningPersonsTable"
        rowKey="ownerName"
        dataSource={dataSource}
        columns={columns}
        loading={{
          spinning: loading,
          indicator: <TableContentLoadingIndicator />,
        }}
        pagination={false}
        scroll={{ y: 400 }}
      />
    </ConfigProvider>
  );
};
