import { UnitContractProjectionDto } from 'api/accounting';
import moment from 'moment';
import { ContractState } from 'pages/UnitContractHistory/interfaces';

export type SortableUnitContract = Pick<UnitContractProjectionDto, 'startDate' | 'signedDate'>;

export interface UnitContractDates {
  startDate: string,
  endDate: string,
}

export const sortUnitContracts = (allContracts: SortableUnitContract[]) => allContracts.sort((a: SortableUnitContract, b: SortableUnitContract) => {
  if (a.startDate === b.startDate) {
    // if a.startDate and b.startDate are both undefined it also enters here
    return a.signedDate! > b.signedDate! ? -1 : 1;
  }
  if (a.startDate === undefined) {
    return 1;
  } if (b.startDate === undefined) {
    return -1;
  }
  return a.startDate > b.startDate ? -1 : 1;
});

const isEtg24UnitContract = (contract: UnitContractProjectionDto) => {
  /*
    etg24 contracts don't have signedDate or created date
    overlaps should be decided by startDate and endDate only
  */
  if (contract.signedDate === undefined && contract.created === undefined) {
    return true;
  }
  return false;
};

export const isContractOverridden = (contract: UnitContractProjectionDto, allContracts: UnitContractProjectionDto[]) => (allContracts?.find(
  c => c.unitContractId !== contract.unitContractId
    && (isEtg24UnitContract(contract)
      || c.signedDate! > contract.signedDate! || (c.signedDate === contract.signedDate && c.created! >= contract.created!)
    )
    && ((contract.startDate === undefined || c.endDate === undefined || contract.startDate <= c.endDate)
    && (contract.endDate === undefined || c.startDate === undefined || contract.endDate >= c.startDate)),
) !== undefined);

export const isContractOverwriting = (contract: UnitContractProjectionDto, allContracts: UnitContractProjectionDto[]) => allContracts?.find(c => c.unitContractId !== contract.unitContractId
  && (isEtg24UnitContract(c)
    || c.signedDate! < contract.signedDate! || (c.signedDate === contract.signedDate && c.created! <= contract.created!)
  )
  && ((c.startDate === undefined || contract.endDate === undefined || c.startDate <= contract.endDate)
  && (c.endDate === undefined || contract.startDate === undefined || c.endDate >= contract.startDate))) !== undefined;

export const isContractCausingGap = (contract: UnitContractProjectionDto, allContracts: UnitContractProjectionDto[]) => {
  const index = allContracts.findIndex(c => c.unitContractId === contract.unitContractId);
  if (index !== -1) {
    const nextContract = index !== 0 ? allContracts[(index - 1)] : undefined; // it's next, because order is descending
    const previousContract = index !== allContracts.length - 1 ? allContracts[(index + 1)] : undefined;
    const expectedPrevEndDate = moment(contract.startDate).subtract(1, 'days').format('YYYY-MM-DD');
    const expectedNextStartDate = moment(contract.endDate).add(1, 'days').format('YYYY-MM-DD');
    return (previousContract?.endDate !== undefined && contract.startDate !== undefined && moment(previousContract.endDate) < moment(expectedPrevEndDate))
    || (nextContract?.startDate !== undefined && contract.endDate !== undefined && moment(nextContract.startDate) > moment(expectedNextStartDate));
  }

  return false;
};

export const noActiveContractExists = (contract: UnitContractProjectionDto, allActiveContracts: UnitContractProjectionDto[]) => allActiveContracts.find(c => c.unitId === contract.unitId) === undefined;

export const getStateOfContract = (contract: UnitContractProjectionDto, activeContractId?: number) => {
  if (contract.unitContractId === activeContractId) {
    return ContractState.ACTIVE;
  }
  if (contract.endDate !== undefined && moment(contract.endDate) <= moment()) {
    return ContractState.PAST;
  }
  if (contract.startDate !== undefined && moment(contract.startDate) > moment()) {
    return ContractState.FUTURE;
  }

  return ContractState.OVERRIDEN;
};

export const isOverlapping = (firstContract: UnitContractProjectionDto, secondContract: UnitContractProjectionDto) => (
  firstContract.startDate === undefined
 || secondContract.endDate === undefined
      || secondContract.endDate >= firstContract.startDate)
    && (secondContract.startDate === undefined
       || firstContract.endDate === undefined
      || secondContract.startDate <= firstContract.endDate);


export const hasOverlappingUnitContracts = (unitContracts: SortableUnitContract[]) => {
  for (let i = 0; i < unitContracts.length; i += 1) {
    const contract = unitContracts[i];

    if (isContractOverwriting(contract, unitContracts) || isContractOverridden(contract, unitContracts)) {
      return true;
    }
  }
  return false;
};

export const getConflictingContracts = (contract: UnitContractDates, contracts: UnitContractProjectionDto[]) => contracts?.filter(
  c => ((c.startDate === undefined || contract.endDate === undefined || c.startDate <= contract.endDate)
    && (c.endDate === undefined || contract.startDate === undefined || c.endDate >= contract.startDate)),
);
