import React from 'react';
import { Table } from 'antd';
import { useManualExchangeValuesSectionColumns } from '../../services/useManualExchangeValuesSectionColumns';
import { useManualExchangeTableData } from '../../services/useManualExchangeTableData';
import InformationBarWrapper from './InformationBarWrapper';

const ManualExchangeValuesSection = () => {
  const { tableDataSource, onChangeSelectedRowKeys } = useManualExchangeTableData();

  return (
    <div className="ManualExchangeSection2">
      <Table
        columns={useManualExchangeValuesSectionColumns()}
        dataSource={tableDataSource.data}
        rowSelection={{
          columnWidth: 40,
          onChange: onChangeSelectedRowKeys,
          selectedRowKeys: tableDataSource.data?.filter(({ selected }) => selected === true).map(contract => contract.unitContractId!),
        }}
        pagination={false}
        scroll={{ y: 500 }}
      />
      <InformationBarWrapper />
    </div>
  );
};

export default ManualExchangeValuesSection;
