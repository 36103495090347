import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { ContractDataSource } from '../services/ContactPropertiesContext';
import { contactPropertiesTranslations } from '../translation';


const ContactPropertiesRoleCell = ({ record } : {record: ContractDataSource}) => {
  const { tl } = useContext(LanguageContext);

  if (isEmpty(record.role)) {
    return null;
  }

  return (
    <>
      {record.role
        .map(role => tl(contactPropertiesTranslations.propertyTable.role[role]))
        .join(', ')}
    </>
  );
};

export default ContactPropertiesRoleCell;
