import { ContractLegacyControllerApi, GetContractsUsingGETTypeEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useRef } from 'react';
import { translations } from 'pages/Property/PropertyEditing/pages/MvEditing/translations';
import { formatDate } from 'lib/Utils';
import { ActivePropertyOwnerContractsContext } from './ActivePropertyOwnerContractsContext';

export const useActivePropertyOwnerContracts = (propertyId: number | undefined) => {
  const activepropertyOwnerContractsContext = useContext(ActivePropertyOwnerContractsContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  if (activepropertyOwnerContractsContext === undefined) {
    throw new Error('useActivePropertyOwnerContracts must be used within a ActivePropertyOwnerContractsContextProvider');
  }

  const {
    activePropertyOwnerContractList,
    setActivePropertyOwnerContractList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  } = activepropertyOwnerContractsContext;


  const isCacheValid = paramOfCachedValue === propertyId;

  useEffect(() => {
    if (propertyId === undefined || isCacheValid) return;


    setParamOfFetchInProgress((prevParamOfFetchInProgress) => {
      // this must be inside the setParamOfFetchInProgress to make sure we have the latest
      // value of `paramOfFetchInProgress`, in case another instance of this hook
      // already triggered the fetch
      const doesCurrentParamMatchTheParamOfTheInProgressFetch = prevParamOfFetchInProgress === propertyId;

      if (prevParamOfFetchInProgress === undefined || !doesCurrentParamMatchTheParamOfTheInProgressFetch) {
        fetchActivePropertyOwnerContracts();

        return propertyId;
      }

      return prevParamOfFetchInProgress;
    });
  }, [propertyId, isCacheValid]);


  const fetchActivePropertyOwnerContracts = () => {
    if (propertyId === undefined) {
      throw new Error('Trying to fetch ALL unit contracts with undefined propertyId');
    }

    // if params changed since last initiated fetch then abort the in-progress fetch
    abortController.current?.abort();
    // create new abort controllers
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setActivePropertyOwnerContractList(prev => prev.startLoading());

    contractControllerApi.getContractsUsingGET({
      propertyId,
      type: [GetContractsUsingGETTypeEnum.PROPERTY_OWNER] as unknown as GetContractsUsingGETTypeEnum,
      validAtDate: formatDate(new Date(), 'YYYY-MM-DD'),
    }, { signal })
      .then((resp) => {
        setActivePropertyOwnerContractList(prev => prev.load(resp));
        setParamOfCachedValue(propertyId);
      })
      .catch((err) => {
        if (signal.aborted) return;
        showNotification({
          type: 'error',
          message: tl(translations.notifications.loadContractsError),
        });
        setActivePropertyOwnerContractList(prev => prev.failed(err));
      })
      .finally(() => {
        setParamOfFetchInProgress(undefined);
      });
  };

  return {
    activePropertyOwnerContracts: activePropertyOwnerContractList.data,
    loading: activePropertyOwnerContractList.loading,
  };
};
