export const ContractWarningModalContent = ({
  warningMessages,
  conflictWarning,
} : {
    warningMessages: React.ReactNode,
    conflictWarning: React.ReactNode,
}) => (
  <>
    <div className="contract-warning-section">{warningMessages}</div>
    <div className="contract-conflict-warning-section">{conflictWarning}</div>
  </>
);
