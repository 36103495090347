import { PdfPageAssignerUpdatersContext } from 'pages/PdfPageAssigner/services/PdfPageAssignerContext';
import { getDocumentIdByFingerprintAndPageNumber } from 'pages/PdfPageAssigner/services/utils';
import { useContext } from 'react';

export const usePdfPageWithAssignment = (pdfFingerprint: string, page: any) => {
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);

  if (pdfPageAssignerUpdatersContext === undefined) {
    throw new Error('usePdfPageWithAssignment must be used within PdfPageAssignerContextProvider');
  }

  const { setIdOfPageInView } = pdfPageAssignerUpdatersContext;


  const onChangeIdOfPageInView = (id: string) => setIdOfPageInView(id);

  const pageId = getDocumentIdByFingerprintAndPageNumber(pdfFingerprint, page._pageIndex);


  return {
    pageId,
    onChangeIdOfPageInView,
  };
};
