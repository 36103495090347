import { ContactLegacyControllerApi, ContactLegacyDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useState } from 'react';

export const useGetContactById = (contactId: number) => {
  const { tl } = useContext(LanguageContext);
  const [contact, setcontact] = useState<ContactLegacyDto>();

  const { apiConfiguration } = useContext(AuthContext);
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));


  useEffect(() => {
    loadContact();
  }, [contactId]);

  const loadContact = () => {
    if (!contactId) return;
    contactControllerApi.getContactUsingGET({ contactId })
      .then((resp) => {
        setcontact(resp);
      })
      .catch(() => {
        showNotification({
          key: 'loadContactError',
          type: 'error',
          message: tl(translations.notifications.contactEditingContext.loadError.description),
        });
      });
  };

  return {
    contact,
  };
};
