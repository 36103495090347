import { useContext, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { DefaultDataInterface } from '../../../lib/data';
import { showNotification } from '../../../lib/Notification';
import { economicPlanTranslations } from '../economicPlanTranslations';
import { EconomicPlanContext } from './EconomicPlanContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { AuthContext } from '../../../contexts/AuthContext';
import {
  CDAccountBalanceDto,
  EconomicPlanControllerApi,
  EconomicPlanDto, EconomicPlanDtoStatusEnum, EconomicPlanDtoTypeEnum,
  EconomicPlanResultDto,
  EconomicPlanUnitResultDto,
} from '../../../api/accounting';

export const DEFAULT_ACCOUNT_DISTRIBUTION_ECONOMIC_PLAN: EconomicPlanDto = {
  id: null,
  status: EconomicPlanDtoStatusEnum.DRAFT,
  type: EconomicPlanDtoTypeEnum.ACCOUNT_DISTRIBUTION,
  title: `Wirtschaftsplan ${moment().year() + 1}`,
};


export const useLoadAccountDistributionEconomicPlan = () => {
  const { tl } = useContext(LanguageContext);
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useLoadAccountDistributionEconomicPlan must be used within a EconomicPlanContextProvider');
  }

  const {
    economicPlan,
    setEconomicPlan,
    setEconomicPlanCreationData,
  } = economicPlanContext;
  const [, setParamOfEpFetchInProgress] = useState<number>();

  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  const convertAccountBalancesToFEModel = (accountBalances: CDAccountBalanceDto[]): any[] => accountBalances.map((accountBalance: any) => {
    let children;
    if (accountBalance.subAccounts) {
      children = convertAccountBalancesToFEModel(accountBalance.subAccounts);
    }

    return {
      ...accountBalance,
      account: `${accountBalance.accountCode} ${accountBalance.accountName}`,
      children,
      totalSum: children ? children.reduce((total, ab) => total + (ab.totalSum || 0), 0) : accountBalance.totalSum,
    };
  });

  const convertUnitResultAccountBalancesToFEModel = (accountBalances: EconomicPlanResultDto[]): any[] => accountBalances.map((accountBalance: any) => {
    let children;
    if (accountBalance.subAccounts) {
      children = convertUnitResultAccountBalancesToFEModel(accountBalance.subAccounts);
    }

    return {
      ...accountBalance,
      account: `${accountBalance.accountCode} ${accountBalance.accountName}`,
      children,
      newTotalShares: children ? children.reduce((total, ab) => total + (ab.newTotalShares || 0), 0) : accountBalance.newTotalShares,
      newUnitShares: children ? children.reduce((total, ab) => total + (ab.newUnitShares || 0), 0) : accountBalance.newUnitShares,
      newTotalAmount: children ? children.reduce((total, ab) => total + (ab.newTotalAmount || 0), 0) : accountBalance.newTotalAmount,
      newUnitAmount: children ? children.reduce((total, ab) => total + (ab.newUnitAmount || 0), 0) : accountBalance.newUnitAmount,
    };
  });

  /*
    Filtering is necessary, to avoid the same account code showing up both under 'applicable' and 'notApplicable' keys.
    (/tree-structure does not take into account the distribution keys)

    The correct value of the accountBalance for a leaf account, is the one where the economicPlanDistributionMode exists.
  */
  const extractLeafAccounts = (accountBalances: CDAccountBalanceDto[]): any[] => accountBalances
    .filter(accountBalance => (_.isEmpty(accountBalance.subAccounts) && accountBalance.economicPlanDistributionMode)
    || !_.isEmpty(accountBalance.subAccounts))
    .map((accountBalance: CDAccountBalanceDto) => {
      if (accountBalance.subAccounts && accountBalance.subAccounts.length > 0) {
        if (accountBalance.totalSum) {
          accountBalance.subAccounts[0].totalSum = accountBalance.totalSum;
        }
        return extractLeafAccounts(accountBalance.subAccounts);
      }
      // leaf
      return accountBalance;
    })
    .flat(1);

  const convertEconomicPlanToFEModel = (data: EconomicPlanDto, ecPlan: any) => {
    const newEconomicPlan: any = {
      ...ecPlan.data,
      ...data,
      applicableExpenses: {},
      notApplicableExpenses: {},
      income: {},
      reserveFunds: {},
    };

    // edit process
    newEconomicPlan.applicableExpenses = extractLeafAccounts(data.results.applicableExpenses)
      .reduce((obj, accountBalance) => ({
        ...obj,
        [accountBalance.accountCode]: accountBalance.totalSum,
      }), {});
    newEconomicPlan.notApplicableExpenses = extractLeafAccounts(data.results.notApplicableExpenses)
      .reduce((obj, accountBalance) => ({
        ...obj,
        [accountBalance.accountCode]: accountBalance.totalSum,
      }), {});
    newEconomicPlan.income = extractLeafAccounts(data.results.income)
      .reduce((obj, accountBalance) => ({
        ...obj,
        [accountBalance.accountCode]: accountBalance.totalSum,
      }), {});

    data.results.reserveFunds.forEach((account: any) => {
      newEconomicPlan.reserveFunds[account.accountCode] = account.totalSum;
    });

    // overview
    newEconomicPlan.results.applicableExpenses = convertAccountBalancesToFEModel(newEconomicPlan.results.applicableExpenses);
    newEconomicPlan.results.notApplicableExpenses = convertAccountBalancesToFEModel(newEconomicPlan.results.notApplicableExpenses);
    newEconomicPlan.results.income = convertAccountBalancesToFEModel(newEconomicPlan.results.income);
    newEconomicPlan.results.unitResults.forEach((unitResult: EconomicPlanUnitResultDto) => {
      unitResult.applicableExpenseResults = convertUnitResultAccountBalancesToFEModel(unitResult.applicableExpenseResults || []);
      unitResult.notApplicableExpenseResults = convertUnitResultAccountBalancesToFEModel(unitResult.notApplicableExpenseResults || []);
      unitResult.incomeResults = convertUnitResultAccountBalancesToFEModel(unitResult.incomeResults || []);
    });

    return newEconomicPlan;
  };

  const onLoadEconomicPlan = (economicPlanId: number) => {
    if (economicPlanId) {
      setParamOfEpFetchInProgress((paramOfCurrentEpFetchInProgress) => {
        if (paramOfCurrentEpFetchInProgress === economicPlanId) {
          return paramOfCurrentEpFetchInProgress;
        }
        setEconomicPlan(economicPlan.startLoading());
        economicPlanControllerApi.getInTreeStructureByIdUsingGET({ id: economicPlanId })
          .then((response: any) => {
            setEconomicPlan((ecPlan: DefaultDataInterface<any>) => ecPlan.load(convertEconomicPlanToFEModel(response, ecPlan)));
          })
          .catch(() => {
            setEconomicPlan(economicPlan.failed());
            showNotification({
              key: 'loadEconomicPlanError',
              message: tl(economicPlanTranslations.notifications.economicPlanContext.loadEconomicPlanError.message),
              description: tl(economicPlanTranslations.notifications.economicPlanContext.loadEconomicPlanError.description),
              type: 'error',
            });
          })
          .finally(() => setParamOfEpFetchInProgress(undefined));
        return economicPlanId;
      });
    } else {
      // Creating new Economic Plan
      setEconomicPlan(prevState => prevState.load(DEFAULT_ACCOUNT_DISTRIBUTION_ECONOMIC_PLAN));
      setEconomicPlanCreationData(prev => prev.load({}));
    }
  };

  return {
    onLoadEconomicPlan,
  };
};
