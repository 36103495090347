import { useState } from 'react';
import moment from 'moment';
import { DOWNLOAD_ALL_ACCOUNTS, TIME_PERIOD_TYPE } from './AccountOverviewDataExportBody';
import { useAccountBalanceAndPostingsCsvDownload } from '../services/useAccountBalanceAndPostingsCsvDownload';

interface useAccountOverviewDataExportModalProps {
  currentEconomicYear: any[],
  previousEconomicYear: any[],
  startDateProp?: moment.Moment | undefined,
  endDateProp?: moment.Moment | undefined,
}

export function useAccountOverviewDataExportModal(props: useAccountOverviewDataExportModalProps) {
  const {
    previousEconomicYear, currentEconomicYear, startDateProp, endDateProp,
  } = props;
  const [visible, setVisible] = useState(false);
  const [downloadAllAccounts, setDownloadAllAccounts] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [allAccountCodes, setAllAccountsCode] = useState([]);
  const [timePeriodType, setTimePeriodType] = useState<undefined|String>(TIME_PERIOD_TYPE.CUSTOM);
  const [pdfFormat, setPdfFormat] = useState(true);
  const [excelFormat, setExcelFormat] = useState(true);

  const {
    onDownloadAccountBalanceAndPostingsCsv, onDownloadAccountBalanceAndPostingsPDF, loadingDownload, loadingPrint,
  } = useAccountBalanceAndPostingsCsvDownload();

  const showModal = () => {
    setVisible(true);
  };

  const setTimePeriod = (value: string) => {
    let dates = [];
    if (value === TIME_PERIOD_TYPE.PREVIOUS_YEAR) {
      dates = previousEconomicYear;
    } else if (value === TIME_PERIOD_TYPE.CURRENT_ECONOMIC_YEAR) {
      dates = currentEconomicYear;
    } else {
      dates = [startDateProp, endDateProp];
    }
    setTimePeriodType(value);
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  const setDownloadAllAccountsValue = (value: any) => {
    if (value === DOWNLOAD_ALL_ACCOUNTS.TRUE) {
      setSelectedAccounts(allAccountCodes);
    } else {
      setSelectedAccounts([]);
    }
    setDownloadAllAccounts(value);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const isDisabled = !endDate || !startDate || !selectedAccounts.length;
  const disabledDownloadButton = isDisabled || (!excelFormat && !pdfFormat);

  const disabledPrintButton = isDisabled;

  const onClickPrint = (propertyHrId: string, includeCorrectional: boolean) => {
    onDownloadAccountBalanceAndPostingsPDF(propertyHrId, startDate, endDate, selectedAccounts, true, includeCorrectional);
  };

  const onClickDownload = (propertyHrId: string, includeCorrectional: boolean) => {
    if (propertyHrId) {
      if (pdfFormat) {
        onDownloadAccountBalanceAndPostingsPDF(propertyHrId, startDate, endDate, selectedAccounts, false, includeCorrectional);
      }
      if (excelFormat) {
        onDownloadAccountBalanceAndPostingsCsv(propertyHrId, startDate, endDate, selectedAccounts, includeCorrectional);
      }
    }
  };

  return {
    visible,
    showModal,
    handleCancel,
    downloadAllAccounts,
    setDownloadAllAccountsValue,
    setTimePeriod,
    options,
    setOptions,
    selectedAccounts,
    setSelectedAccounts,
    setAllAccountsCode,
    timePeriodType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    pdfFormat,
    setPdfFormat,
    excelFormat,
    setExcelFormat,
    loadingDownload,
    disabledDownloadButton,
    loadingPrint,
    disabledPrintButton,
    onClickDownload,
    onClickPrint,
  };
}
