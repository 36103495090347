import { useContext, useEffect } from 'react';
import _ from 'lodash';
import useContactOptions from 'services/useContactOptions';
import { LanguageContext } from 'contexts/LanguageContext';
import { navigateConcat } from 'navigation/History';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import { translations } from '../translations';
import defaultSection from './defaultSection';

interface CreditorSectionProps {
  setDirty: (value: boolean) => void,
  setAutoSelectCompany: (key: string) => void,
  viewMode: 'fullscreen' | 'withPdf',
}

export default function useCreditorSection2(props: CreditorSectionProps) {
  const { tl } = useContext(LanguageContext);

  const invoiceEditingContext = useContext(InvoiceEditingContext);

  const creditorContact: any = useContactOptions([ContactLegacyDtoTypeEnum.COMPANY, ContactLegacyDtoTypeEnum.MANAGEMENT_COMPANY, ContactLegacyDtoTypeEnum.PERSON]);
  const { invoice } = invoiceEditingContext.data;
  const bankAccountOptions: any = invoice.counterpartContactId
    && creditorContact.contactBankAccountMap[invoice.counterpartContactId]
    ? creditorContact.contactBankAccountMap[invoice.counterpartContactId].map((option: any) => ({
      label: option.iban,
      value: option.iban,
    })) : [];


  useEffect(() => {
    updateBankAccount(invoiceEditingContext.data?.invoice);
  }, [invoiceEditingContext.data?.invoice?.counterpartContactId]);

  /**
   * FIXME:
   * WARNING: Circular dependecy:
   *  useffect calls this if invoice  is updated
   *  This updates the invoice
   * This cycle is hanging by a thread. Very easy to break. Very hard to follow
   * since the state update is wrapped in updateInvoiceState that takes the state wrapped as {state: state}
   */
  const updateBankAccount = (changedValue: any) => {
    const newInvoice = _.cloneDeep(changedValue);
    const { counterpartContactId } = newInvoice;
    const bankAccounts = creditorContact.contactBankAccountMap[counterpartContactId];
    if (!newInvoice.counterpartBic || !newInvoice.counterpartBankAccountId || invoice.counterpartContactId !== newInvoice.counterpartContactId) {
      if (newInvoice.counterpartContactId) {
        const company = creditorContact.getContact(newInvoice.counterpartContactId);
        if (company) {
          newInvoice.counterpartName = company.companyName ? company.companyName : `${company.firstName} ${company.lastName}`;
        }
      }
      if (counterpartContactId && bankAccounts && bankAccounts.length > 0) {
        newInvoice.counterpartIban = bankAccounts[0].iban;
        newInvoice.counterpartBankAccountId = bankAccounts[0].value;
        newInvoice.counterpartBic = bankAccounts[0].bic;
      } else if (bankAccounts !== undefined) {
        // if bankAccounts are loaded already but there are no options
        newInvoice.counterpartIban = null;
        newInvoice.counterpartBic = null;
        newInvoice.counterpartBankAccountId = null;
      }
    }

    invoiceEditingContext.updateInvoiceState({ invoice: newInvoice });
  };

  const counterpartContactInput = {
    type: 'smartSearch',
    key: 'counterpartContactId',
    props: {
      label: tl(translations.invoiceEditing.sections.section2.fields.company),
      searchFunction: creditorContact.searchForContact,
      options: creditorContact.contactOptions,
      getOneFunction: creditorContact.onLoadContact,
      addButtons: [{
        label: tl(translations.invoiceEditing.sections.section2.fields.smartSearch.addMore),
        onClick: async () => {
          props.setAutoSelectCompany('counterpartContactId');
          await props.setDirty(false);
          navigateConcat('/create-company');
        },
      }],
      required: true,
      autoFocus: true,
    },
  };

  const counterpartIbanInput = {
    type: 'select',
    key: 'counterpartIban',
    props: {
      label: tl(translations.invoiceEditing.sections.section2.fields.bankAccount),
      options: bankAccountOptions,
      allowClear: true,
    },
  };

  return {
    ...defaultSection(1),
    sectionTitle: tl(translations.invoiceEditing.sections.section2.title),
    sectionId: 'creditor',
    onChange: updateBankAccount,
    content: [
      {
        sectionId: 'creditor',
        content: props.viewMode === 'fullscreen'
          // one row, two columns if fullscreen
          ? [[
            counterpartContactInput,
            counterpartIbanInput,
          ]]
          // two rows, one column if withPdf
          : [
            [counterpartContactInput],
            [counterpartIbanInput],
          ],
      },
    ],
  };
}
