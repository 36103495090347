import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { translations } from '../../../../translations';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import './SearchBarDropdown.scss';
import { GlobalSearchContext, SearchSuggestion } from '../../services/GlobalSearchContext';
import useEvent from '../../../../../../lib/useEvent';
import { useSearchBarDropdown } from '../../../../../../services/SearchBarHooks/useSearchBarDropdown';

interface SearchBarDropdownProps {
  visible: boolean,
  hideDropdown: () => void
  onSelected: (value: SearchSuggestion, withValue?: boolean) => void
}

export const SearchBarDropdown = ({
  visible, hideDropdown, onSelected,
}: SearchBarDropdownProps) => {
  const { tl } = useContext(LanguageContext);
  const { searchSuggestions, selectedSuggestionIndex, setSelectedSuggestionIndex } = useContext(GlobalSearchContext);

  const onSelect = (suggestion: SearchSuggestion) => {
    if (!suggestion) {
      // this can happen if the search bar is enabled where there are no selectable filter options and they press `Enter`
      hideDropdown();
      return;
    }

    if (suggestion.type !== 'filter') {
      setSelectedSuggestionIndex(0);
      onSelected(suggestion);
      return;
    }
    if (_.isEmpty(suggestion.filterProps!.matchingValue)) {
      onSelected(suggestion, false);
    } else {
      onSelected(suggestion, true);
    }
    setSelectedSuggestionIndex(0);
  };

  const { eventHandler } = useSearchBarDropdown({
    visible, searchSuggestions, hideDropdown, onSelect, selectedSuggestionIndex, setSelectedSuggestionIndex,
  });

  useEvent('keydown', document, eventHandler);

  const getValue = (suggestion: SearchSuggestion) => {
    if (suggestion.type === 'property' || suggestion.type === 'navigationItem') {
      return '';
    }
    const filterProps = suggestion.filterProps!;
    if (filterProps.matchingValue) {
      let translatedValue = filterProps!.matchingValue;
      if (filterProps.options) {
        translatedValue = filterProps.options.filter(option => option.value === filterProps.matchingValue)[0].label;
      }
      if (filterProps.type === 'boolean') {
        if (filterProps.matchingValue === 'true') {
          translatedValue = filterProps.trueLabel || tl(translations.searchBar.filter.booleanValues[filterProps.matchingValue]);
        } else {
          translatedValue = filterProps.falseLabel || tl(translations.searchBar.filter.booleanValues[filterProps.matchingValue as string]);
        }
      }
      return `: ${translatedValue}`;
    }
    return '';
  };

  return visible ? (
    <div className="SearchBarDropdown">
      <div
        className="back-navigation-button-hider"
        onClick={hideDropdown}
        role="button"
        tabIndex={0}
      />
      <div className="hint-container">
        <span className="hint">{tl(translations.searchBar.dropdown.hint)}</span>
      </div>
      <div className="suggestions">
        {searchSuggestions.map((suggestion: SearchSuggestion, index: number) => (
          <div
            role="button"
            tabIndex={0}
            key={`${suggestion.value} ${suggestion.type === 'filter' ? suggestion.filterProps!.matchingValue : ''} ${suggestion.type}`}
            className={`suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
            onMouseDown={(e) => {
              e.preventDefault();
              onSelect(suggestion);
            }}
          >
            {`${suggestion.value}${getValue(suggestion)}`}
          </div>
        ))}
      </div>
    </div>
  ) : <React.Fragment />;
};
