import {
  PortalLink,
} from 'storybook-components/TicketIntegrationSection/components/PotalLink/PortalLink';
import {
  INTEGRATION_APPS,
  TICKET_COLUMN_TYPE,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  TicketIntegrationSection,
} from 'storybook-components/TicketIntegrationSection/TicketIntegrationSection';

import { useFaciliooTicketColumns } from './useFaciliooTicketColumns';
import {
  useFaciliooTicketIntegrationSection,
} from './useFaciliooTicketIntegrationSection';

export const FaciliooContactTicketSection = ({
  connectionId,
  contactId,
} : {connectionId: number,
          contactId: number}) => {
  const {
    errorMessage, loading, onChangeSearchTerm, issues, faciliooLink, timestamp,
  } = useFaciliooTicketIntegrationSection(connectionId, TICKET_COLUMN_TYPE.CONTACT, { contactId });
  const { columns } = useFaciliooTicketColumns({ type: TICKET_COLUMN_TYPE.CONTACT });

  const suffix = (
    <PortalLink
      type={TICKET_COLUMN_TYPE.CONTACT}
      timestamp={timestamp}
      portal={INTEGRATION_APPS.FACILIOO}
      link={faciliooLink}
    />
  );

  return (
    <TicketIntegrationSection
      columns={columns}
      errorMessage={errorMessage}
      loading={loading}
      onChangeSearchTerm={onChangeSearchTerm}
      datasource={issues}
      portal={INTEGRATION_APPS.FACILIOO}
      suffix={suffix}
    />
  );
};
