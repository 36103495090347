import { useContext, useEffect, useState } from 'react';
import { translations } from '../../../translations';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { showNotification } from '../../../../../lib/Notification';
import {
  UnitDistributionRatioDto,
  UnitDistributionSetCalculationResultDto,
  UnitDistributionSetControllerApi,
  UnitDistributionSetDto,
  UnitDistributionSetDtoDistributionModeEnum,
  SpecialContributionControllerApi,
  SpecialContributionDetailsDto,
  SpecialContributionDetailsDtoStateEnum,
  SpecialContributionDetailsDtoTypeEnum,
  UnitContractProjectionDto,
  UnitLegacyControllerApi,
  UnitPaymentPreferenceDto,
  UnitPaymentPreferenceDtoPaymentPreferenceEnum,
} from '../../../../../api/accounting';
import { AuthContext } from '../../../../../contexts/AuthContext';
import './Section3.scss';
import { UnitSelectionTableData } from './model/UnitSelectionTableData';
import DEFAULT_DATA from '../../../../../lib/data';
import { AmountAndDistributionModeSelector } from './subsections/AmountAndDistributionModeSelector';
import { UnitOwnersTable } from './subsections/UnitOwnersTable';
import { TransactionExecutionFlagSelector } from './subsections/TransactionExecutionFlagSelector';
import { usePrevious } from '../../../../../lib/usePrevious';
import { formatDate, isBaseDistribution } from '../../../../../lib/Utils';

export default function Section3(
  setDirty: (dirty: boolean) => void,
  setLoading: (value: boolean) => void,
  setOpenSectionIndex: (openSectionIndex: number) => void,
  specialContributionDetails: SpecialContributionDetailsDto | undefined,
  setSpecialContributionDetails: (specialContributionDetailsDto: SpecialContributionDetailsDto) => void,
) {
  // API / ACTIONS
  const { apiConfiguration } = useContext(AuthContext);
  const api = new SpecialContributionControllerApi(apiConfiguration('accounting'));
  const unitControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const distributionSetControllerApi = new UnitDistributionSetControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);

  // state
  const [allUnitTableData, setAllUnitTableData] = useState(DEFAULT_DATA<UnitSelectionTableData[]>([]));
  const [filteredUnitTableData, setFilteredUnitTableData] = useState(DEFAULT_DATA<UnitSelectionTableData[]>([]));
  const [distributionMode, setDistributionMode] = useState<UnitDistributionSetDtoDistributionModeEnum>();
  const [transactionsExecutionAllowed, setTransactionsExecutionAllowed] = useState(true);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalShares, setTotalShares] = useState<number | undefined>(0);
  const prevDistributionMode = usePrevious(distributionMode);

  const applyTableFilters = () => {
    // TODO filtering step  should happen here
    setFilteredUnitTableData(filteredUnitTableData.load(allUnitTableData.data?.map(data => ({
      ...data,
      key: data.unitNrSharingDeclaration,
    }))!));
  };

  const onChangeTransactionExecutionFlag = (newVal: boolean) => {
    setTransactionsExecutionAllowed(newVal);
    setDirty(true);
  };

  const onChangeTotalAmount = (amountToDistribute: number) => {
    setTotalAmount(amountToDistribute);
    setDirty(true);
  };

  const onChangeDistributionMode = (newDistributionMode: UnitDistributionSetDtoDistributionModeEnum) => {
    setDistributionMode(newDistributionMode);
    setDirty(true);
  };

  function setSelectedUnitsAndDkValue(selection: Array<UnitDistributionRatioDto> | undefined) {
    setAllUnitTableData(prevState => prevState.load(prevState.data?.map((unit) => {
      const distributionRatio = selection?.find(it => it.unitNrSharingDeclaration === unit.unitNrSharingDeclaration);
      unit.selected = !!distributionRatio;
      unit.dkValue = distributionRatio?.value || 0;
      return unit;
    })!));
  }

  const getPaymentPreference = (unit: UnitContractProjectionDto) => {
    const unitPaymentPreference: UnitPaymentPreferenceDto | undefined = specialContributionDetails?.unitPaymentPreference?.find(paymentPreference => paymentPreference.unitNrSharingDeclaration === unit.unitNrSharingDeclaration);
    if (unitPaymentPreference?.paymentPreference) {
      return unitPaymentPreference.paymentPreference;
    }
    if ((specialContributionDetails?.type === SpecialContributionDetailsDtoTypeEnum.CONTRIBUTION && unit.hasMandateAtDate)
      || (specialContributionDetails?.type === SpecialContributionDetailsDtoTypeEnum.DISTRIBUTION && unit.bankAccountIban)) {
      return UnitPaymentPreferenceDtoPaymentPreferenceEnum.SYSTEM_MANAGED;
    }
    return UnitPaymentPreferenceDtoPaymentPreferenceEnum.SELF_MANAGED;
  };

  const mapUnitsToState = (unitsWithOwnerInfo: UnitContractProjectionDto[]) => {
    setAllUnitTableData(allUnitTableData.load(unitsWithOwnerInfo.map((unit: UnitContractProjectionDto): UnitSelectionTableData => ({
      selected: false,
      hasMandate: unit.hasMandateAtDate,
      iban: unit.bankAccountIban,
      unitNrSharingDeclaration: unit.unitNrSharingDeclaration!,
      unitType: unit.unitType!,
      ownerName: unit.mailingContact!.name!,
      paymentPreference: getPaymentPreference(unit),
      dkValue: 0,
    }))));
  };

  const mapDistributionValuesToState = (unitShares: Array<UnitDistributionRatioDto>) => {
    // eslint-disable-next-line no-unused-expressions
    allUnitTableData.data?.forEach((unit: UnitSelectionTableData) => {
      unit.dkValue = unitShares.find(it => it.unitNrSharingDeclaration === unit.unitNrSharingDeclaration)?.value || 0;
    });
  };
  const getDistributionSet = (dsMode: UnitDistributionSetDtoDistributionModeEnum | undefined) => ({
    distributionMode: dsMode,
    id: specialContributionDetails?.distributionSetId!,
    propertyHrId: specialContributionDetails?.propertyHrId,
    selection: (allUnitTableData.data
      ?.filter(unit => unit.selected) || [])
      ?.map(unit => ({
        unitNrSharingDeclaration: unit.unitNrSharingDeclaration,
        value: unit.dkValue,
      } as UnitDistributionRatioDto)) || [],
  } as UnitDistributionSetDto);

  const computeUnitAmounts = (dsMode: UnitDistributionSetDtoDistributionModeEnum | undefined, total: number) => {
    distributionSetControllerApi.calculateUsingPOST({
      total: total ? String(total) : '0',
      distributionSetDto: getDistributionSet(dsMode),
    })
      .then((result: UnitDistributionSetCalculationResultDto) => {
        setAllUnitTableData(prevState => prevState.load(prevState.data!.map(
          (unit: UnitSelectionTableData) => {
            unit.unitAmount = result?.unitAmounts?.[unit.unitNrSharingDeclaration]
              || 0;
            return unit;
          },
        )));
        setTotalShares(result.totalShares);
      })
      .catch(() => {
        showNotification({
          key: 'fetchUnitAmounts',
          message: tl(translations.specialContribution.section3.fetchUnitAmounts),
          type: 'error',
        });
      })
      .finally(() => applyTableFilters());
  };

  const onChangePaymentPreference = (unitNrSharingDeclaration: string, paymentPreference: UnitPaymentPreferenceDtoPaymentPreferenceEnum) => {
    const unitToUpdate = allUnitTableData.data
      ?.find((unit: UnitSelectionTableData) => unit.unitNrSharingDeclaration === unitNrSharingDeclaration);
    if (unitToUpdate) {
      unitToUpdate.paymentPreference = paymentPreference;
      setDirty(true);
      applyTableFilters();
    }
  };

  const onChangeDkValue = (unitNrSharingDeclaration: string,
    dkValue: number) => {
    const unitToUpdate = allUnitTableData.data
      ?.find((unit: UnitSelectionTableData) => unit.unitNrSharingDeclaration
        === unitNrSharingDeclaration);
    if (unitToUpdate) {
      unitToUpdate.dkValue = dkValue;
      setDirty(true);
      applyTableFilters();
    }
  };

  const setFocus = (elementId: string) => {
    // @ts-ignore
    setTimeout(() => document.getElementById(elementId)
      .focus(), 300);
  };

  const areAllRowsSelected = (addedRows: string[]) => addedRows.length === allUnitTableData.data?.length;

  const onChangeSelectedRows = (selectedRowKeys: any[]) => {
    // eslint-disable-next-line no-unused-expressions
    allUnitTableData.data?.map((row: UnitSelectionTableData) => {
      if (selectedRowKeys.includes(row.unitNrSharingDeclaration!)) {
        row.selected = true;
      } else {
        row.selected = false;
        if (!isBaseDistribution(distributionMode) && !areAllRowsSelected(selectedRowKeys)) {
          row.dkValue = 0;
        }
      }
    });
    setDirty(true);
    setTotalAmount((oldAmount: number) => {
      computeUnitAmounts(distributionMode, oldAmount);
      return oldAmount;
    });
  };

  const onRowSelected = (row: UnitSelectionTableData, selected: boolean) => {
    if (!isBaseDistribution(distributionMode) && selected) {
      setFocus(row!.unitNrSharingDeclaration);
    }
  };

  const onClickWrapper = (row: UnitSelectionTableData) => {
    const unitToUpdate = allUnitTableData.data
      ?.find((unit: UnitSelectionTableData) => unit.unitNrSharingDeclaration
        === row.unitNrSharingDeclaration);
    unitToUpdate!.selected = true;
    onRowSelected(unitToUpdate!, true);
    applyTableFilters();
    setDirty(true);
  };


  const onAllRowsSelected = (selected: boolean) => {
    setAllUnitTableData(allUnitTableData.load(allUnitTableData.data?.map((row: UnitSelectionTableData) => ({
      ...row,
      selected,
    }))!));

    setDirty(true);
    setTotalAmount((oldAmount: number) => {
      computeUnitAmounts(distributionMode, oldAmount);
      return oldAmount;
    });
  };

  const setAllSelectedValues = () => {
    if (prevDistributionMode !== undefined
      && (isBaseDistribution(prevDistributionMode) !== isBaseDistribution(distributionMode) || !isBaseDistribution(distributionMode))) {
      const value = isBaseDistribution(distributionMode);
      // eslint-disable-next-line no-unused-expressions,no-return-assign
      allUnitTableData.data?.map(it => it.selected = value);
    }
  };

  useEffect(() => {
    if (specialContributionDetails?.propertyHrId && specialContributionDetails?.type) {
      setFilteredUnitTableData(filteredUnitTableData.startLoading());
      if (specialContributionDetails?.transactionsExecutionAllowed !== undefined) {
        setTransactionsExecutionAllowed(specialContributionDetails?.transactionsExecutionAllowed);
      }
      setDistributionMode(undefined);
      setTotalAmount(specialContributionDetails?.totalAmount || 0);
      unitControllerApi.getUnitOwnerAtDateUsingGET({
        atDate: specialContributionDetails.dueDate ?? formatDate(new Date(), 'YYYY-MM-DD'),
        propertyHrId: specialContributionDetails.propertyHrId,
      })
        .then((response: UnitContractProjectionDto[]) => {
          mapUnitsToState(response);
        })
        .then(() => distributionSetControllerApi.getDistributionSetUsingGET({ id: specialContributionDetails.distributionSetId! }))
        .then((distributionSetDto) => {
          setSelectedUnitsAndDkValue(distributionSetDto.selection);
          setDistributionMode(distributionSetDto.distributionMode);
          applyTableFilters();
        })
        .catch(() => {
          setFilteredUnitTableData(filteredUnitTableData.failed());
          showNotification({
            key: 'fetchUnitWithOwnerNameList',
            message: tl(translations.specialContribution.section3.fetchUnitWithOwnerNameError),
            type: 'error',
          });
        });
    }
  }, [specialContributionDetails]);

  useEffect(() => {
    computeUnitAmounts(distributionMode, totalAmount);
  }, [totalAmount]);

  useEffect(() => {
    if (!specialContributionDetails || !distributionMode) {
      return;
    }
    setFilteredUnitTableData(filteredUnitTableData.startLoading());
    setAllSelectedValues();
    if (isBaseDistribution(distributionMode) || prevDistributionMode !== undefined) {
      distributionSetControllerApi.getUnitSharesForPropertyUsingGET({
        distributionMode: String(distributionMode),
        propertyHrId: specialContributionDetails.propertyHrId!,
      })
        .then(mapDistributionValuesToState)
        .then(() => computeUnitAmounts(distributionMode, totalAmount))
        .catch(() => {
          setFilteredUnitTableData(filteredUnitTableData.failed());
          showNotification({
            key: 'fetchDistributionSet',
            message: tl(translations.specialContribution.section3.fetchUnitDistributionRatiosError),
            type: 'error',
          });
        });
    } else {
      computeUnitAmounts(distributionMode, totalAmount);
    }
  }, [distributionMode]);

  const save = (isSuccessNotificationRequired: boolean = true) => {
    setLoading(true);
    return distributionSetControllerApi.updateUsingPUT({
      distributionSetId: specialContributionDetails!.distributionSetId!,
      distributionSetDto: getDistributionSet(distributionMode),
    })
      .then(() => api.updateOwnerDistributionUsingPUT({
        dto: {
          totalAmount,
          transactionsExecutionAllowed,
          unitPaymentPreference: allUnitTableData.data?.map((unitSelectionItem: UnitSelectionTableData) => ({
            unitNrSharingDeclaration: unitSelectionItem.unitNrSharingDeclaration,
            paymentPreference: unitSelectionItem.paymentPreference,
          })),
        },
        id: specialContributionDetails!.id!,
      }))
      .then((response: SpecialContributionDetailsDto) => {
        setDirty(false);
        if (isSuccessNotificationRequired) {
          setSpecialContributionDetails({ ...response });
          setOpenSectionIndex(-1);
          showNotification({
            key: 'saveSpecialContributionSuccess',
            message: tl(translations.specialContribution.saveSpecialContributionSuccess),
            type: 'success',
          });
        }
      })
      .catch(() => {
        showNotification({
          key: 'createSpecialContributionError',
          message: tl(translations.specialContribution.saveSpecialContributionError),
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const isSectionDisabled = specialContributionDetails?.state !== SpecialContributionDetailsDtoStateEnum.DRAFT;

  return {
    sectionTitle: tl(translations.specialContribution.section3.title),
    sectionId: 'section3',
    sectionNumber: 3,
    openingNumber: 2,
    buttonDisabled: (allUnitTableData.data?.filter(unit => unit.selected) || []).length === 0,
    onSubmit: (isSuccessNotificationRequired: boolean) => save(isSuccessNotificationRequired),
    inputsDisabled: isSectionDisabled,
    content:
      [
        AmountAndDistributionModeSelector(onChangeTotalAmount,
          onChangeDistributionMode, distributionMode,
          specialContributionDetails?.type,
          totalAmount, isSectionDisabled),
        UnitOwnersTable(onChangePaymentPreference, onChangeDkValue,
          onChangeSelectedRows, onAllRowsSelected, onRowSelected, onClickWrapper, computeUnitAmounts, filteredUnitTableData, distributionMode,
          specialContributionDetails?.type, transactionsExecutionAllowed,
          totalShares, totalAmount, isSectionDisabled),
        TransactionExecutionFlagSelector(onChangeTransactionExecutionFlag, transactionsExecutionAllowed, isSectionDisabled)],
  };
}
