import React from 'react';
import './SubSectionTitle.scss';

interface subSectionTitleProps {
  title: string
}

export default function SubSectionTitle(props: subSectionTitleProps): JSX.Element {
  return (
    <div className="SubSectionTitle">
      <div className="section-title-box">
        <div className="section-title">
          {props.title}
        </div>
      </div>
    </div>
  );
}
