import React, { useContext, useEffect } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Alert } from 'antd';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from './translations';
import { loadCsv } from '../services/loadCsv';
import { useImportContactsCsv } from './useContactsImportCsv';
import { FileUploadButton } from '../components/FileUploadButton/FileUploadButton';
import { showNotification } from '../../../../lib/Notification';
import { ContactImportDto } from './interfaces';


export const ContactsImportSection = ({ onLoaded }: { onLoaded: (data: ContactImportDto[]) => void }) => {
  const { tl } = useContext(LanguageContext);
  const { loadContactsFromCsvRows, contacts, contactsValidationIssues } = useImportContactsCsv();
  useEffect(() => { onLoaded(contacts); }, [contacts]);
  return (

    <PrimarySectionWrapper
      sectionNumber={1}
      sectionTitle={tl(translations.title)}
      sectionId="hms-close-year-account-selection"
    >
      <div className="row">
        <div className="col-xs-12">
          <FileUploadButton
            label={tl(translations.upload)}
            accept="text/csv"
            onChange={event => loadCsv(event.target.files, 0)
              .then(res => loadContactsFromCsvRows(res))
              .catch((err) => { showNotification({ message: tl(translations.validations.errors) }); console.error('Error while loading csv', err); })}
          />
        </div>
      </div>
      {contacts.length > 0
        ? <Alert message={`${contacts.length} ${tl(translations.validations.success)}. To create: ${contacts.filter(c => !c.id).length} To update: ${contacts.filter(c => c.id).length}`} type="success" />
        : <Alert message={tl(translations.description)} />
      }
      {contactsValidationIssues.length > 0 && (
        <Alert
          message={tl(translations.validations.errors)}
          type="error"
          description={(
            <ul>
              {' '}
              {
                contactsValidationIssues.map(issue => (
                  <li key={issue.row + issue.message}>
                    {`${issue.row + 1}: ${issue.message}`}
                  </li>
                ))
              }
            </ul>
          )}
        />

      )}

    </PrimarySectionWrapper>
  );
};
