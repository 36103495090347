import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { IBAN_MOD97_PATTERN } from 'lib/Utils';
import { translations } from '../../translations';

export default function usePropertyIbanAndCounterpartIbanFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    {
      type: 'text',
      name: tl(translations.searchBar.filter.counterpartName),
      key: 'counterpartName',
    },
    {
      type: 'text',
      name: tl(translations.searchBar.filter.counterpartIban),
      key: 'counterpartIban',
      matcher: IBAN_MOD97_PATTERN,
    },
    {
      type: 'text',
      name: tl(translations.searchBar.filter.wegIban),
      key: 'propertyIban',
      matcher: IBAN_MOD97_PATTERN,
    },
  ];
}
