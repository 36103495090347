import React, { useContext, useRef } from 'react';
import './RichTextEditor.scss';
import { Form } from 'antd';
import { LanguageContext } from '../../../contexts/LanguageContext';
import InputProps from '../InputProps';
import { CustomJoditEditor } from './CustomJoditEditor';

export interface RichTextProps extends InputProps<string> {
  buttons?: string
}

export default function RichTextEditor(props: RichTextProps) {
  const { tl, language } = useContext(LanguageContext);
  const editor = useRef(null);
  const {
    value, onChange, label, required, className, buttons, disabled, validationState, validationMessage,
  } = props;
  const buttonsConfig = buttons || 'source,|,bold,strikethrough,underline,italic,eraser,|,ul,ol,|,outdent,indent,|,align,undo,redo,|,paste,symbol';
  const config = {
    readonly: disabled || false,
    uploader: {
      insertImageAsBase64URI: true,
    },
    buttons: buttonsConfig,
    buttonsMD: buttonsConfig,
    buttonsSM: buttonsConfig,
    buttonsXS: buttonsConfig,
    placeholder: '',
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_clear_html',
    allowTabNavigation: true,
    tabIndex: 0,
    spellcheck: true,
    language,
  };

  return (
    <div className={`RichTextEditor ${className || ''} ${disabled ? 'disabled' : ''}`}>
      <Form.Item
        label={`${label || ''}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <CustomJoditEditor value={value} ref={editor} config={config} onChange={onChange} onBlur={undefined} tabIndex={undefined} name={undefined} />
      </Form.Item>
    </div>
  );
}

RichTextEditor.defaultProps = {
  buttons: 'source,|,bold,strikethrough,underline,italic,eraser,|,ul,ol,|,outdent,indent,|,align,undo,redo,|,paste,symbol',
};
