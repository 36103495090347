import './DirectDebitList.scss';

import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {
  PaymentHeaderButtons,
} from 'components/PaymentHeaderButtons/PaymentHeaderButtons';
import { deleteKey } from 'lib/Utils';
import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router';
import {
  useManagementCompanySelection,
} from 'services/useManagementCompanySelection';

import { useFeatureSettings } from 'lib/useFeatureSettings';
import {
  DirectDebitDto,
  DirectDebitDtoStateEnum,
} from '../../api/accounting';
import {
  useGlobalSearch,
} from '../../components/Header/components/SearchBar/services/useGlobalSearch';
import DirectDebitListProvider, {
  DirectDebitListContext,
} from '../../contexts/DirectDebitListContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import {
  useExecutionModal,
} from '../../elements/Modals/ExecutionModal/useExecutionModal';
import FileUploadModal
  from '../../elements/Modals/FileUploadModal/FileUploadModal';
import {
  useFileUploadModal,
} from '../../elements/Modals/FileUploadModal/useFileUploadModal';
import { Action } from '../../elements/SmartTable/data';
import SmartTable from '../../elements/SmartTable/SmartTable';
import useSmartTable from '../../elements/SmartTable/useSmartTable';
import { translations } from '../../elements/Translation/translations';
import useSiteMap from '../../services/useSiteMap/useSiteMap';
import MainPageHeader
  from '../../storybook-components/headers/MainPageHeader/MainPageHeader';
import DirectDebitExecutionModal from './DirectDebitExecutionModal';
import { DirectDebitTableData } from './interfaces';
import { useDirectDebitColumns } from './UseDirectDebitColumns';
import useDirectDebitFilters from './UseDirectDebitFilters';

export default function DirectDebitList(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    directDebitListState,
    onLoadDirectDebitList,
    onDownloadAllAsEbics,
    directDebitFilterState,
    onSetDefaultFilterFromQueryParams,
    sortField,
    sortOrder,
    setSortField,
    onClearDirectDebitList,
    onClearDirectDebitFilterState,
    setDirectDebitFilterState,
    onRepairEbicsUpload,
    defaultFilters,
    updateFilterState,
    onDownloadDirectDebitsByIdAsEbics,
    onLoadEbicsDownloadDataByFilters,
    downloadData,
    onSetToNew,
    onDelete,
    onMarkAsSent,
    sendAmendMandateInfoToBank,
    setSendAmendMandateInfoToBank,
  }: any = useContext(DirectDebitListContext);
  const { propertyIdInternal } = useParams<{ propertyIdInternal: string }>();

  const { isMandateAmendmentEnabled } = useFeatureSettings();

  const { managementCompanies, selectedManagementCompany, onSelectCompany } = useManagementCompanySelection({
    onSelected: (id) => {
      updateFilterState({
        managementCompanyId: id,
      });
    },
    defaultValue: directDebitFilterState.managementCompanyId,
  });

  useGlobalSearch({
    key: 'directDebits',
    filterProps: {
      availableFilters: useDirectDebitFilters(),
      setFilter: (key: string, value: any) => {
        setDirectDebitFilterState((currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);
          if (Array.isArray(value) && _.isEmpty(value)) {
            deleteKey(newFilter, key);
          } else {
            newFilter[key] = value;
          }
          return newFilter;
        });
      },
    },
    queryParamAsFilter: {
      filterState: directDebitFilterState,
      onSetDefaultFilterFromQueryParams,
      defaultFilters,
    },
  });

  // clear list on unmount
  useEffect(() => () => {
    onClearDirectDebitList();
    onClearDirectDebitFilterState();
  }, []);

  useEffect(() => {
    if (propertyIdInternal) {
      updateFilterState({ propertyIdInternalList: propertyIdInternal });
    }
  }, [propertyIdInternal]);

  useEffect(() => {
    if (directDebitTable.onUnselectAll) directDebitTable.onUnselectAll();
    // reset selection when updating the filter
  }, [directDebitFilterState]);

  const actionsMenu: Action[] = [{
    label: tl(translations.pages.payment.mark),
    onAction: (record: DirectDebitDto) => {
      const param = [record.id!];
      onMarkAsSent(param);
    },
    onBatchAction: (records: DirectDebitDto[]) => {
      const params = records.map(record => record.id!);
      onMarkAsSent(params);
    },
    actionSupported: (record: DirectDebitDto) => record.state !== DirectDebitDtoStateEnum.SENT,
  },
  {
    label: tl(translations.pages.payment.delete),
    onAction: (record: DirectDebitDto) => {
      const param = [record.id!];
      onDelete(param);
    },
    onBatchAction: (records: DirectDebitDto[]) => {
      const params = records.map(record => record.id!);
      onDelete(params);
    },
    actionSupported: (record: DirectDebitDto) => record.state !== DirectDebitDtoStateEnum.DELETED,
  },
  {
    label: tl(translations.pages.payment.reset),
    onAction: (record: DirectDebitDto) => {
      const param = [record.id!];
      onSetToNew(param);
    },
    onBatchAction: (records: DirectDebitDto[]) => {
      const params = records.map(record => record.id);
      onSetToNew(params);
    },
    actionSupported: (record: DirectDebitDto) => record.state !== DirectDebitDtoStateEnum.NEW,
  }];

  const directDebitEbicsRepairModal = useFileUploadModal({
    onUpload: onRepairEbicsUpload,
    name: 'direct-debit-ebics-repair',
    body: tl(translations.pages.payment.fileUploadModal.body),
    title: tl(translations.pages.payment.fileUploadModal.title),
    upload: tl(translations.pages.payment.fileUploadModal.upload),
  });

  const toTableData = (directDebit: any): DirectDebitTableData => ({
    ...directDebit,
    executionDate: directDebit.executionDate ? moment(directDebit.executionDate).format('DD.MM.YYYY') : null,
    translatedState: directDebit.state ? tl(translations.pages.payment.table.paymentStates[directDebit.state]) : null,
    unformattedAmount: directDebit.amount, // used to add up the selectedRecords
  });

  const directDebits = useMemo(() => (directDebitListState.data ? directDebitListState.data.map((directDebit: any) => toTableData(directDebit)) : []), [directDebitListState]);
  const directDebitTable = useSmartTable({
    tableName: 'directDebitTable',
    columns: useDirectDebitColumns(),
    dataSource: directDebits,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    contentLoading: directDebitListState.loading,
    infiniteScrollerProps: {
      hasMoreData: !directDebitListState.lastPage,
      loadMoreData: onLoadDirectDebitList,
    },
    rowKey: 'id',
    supportBatchActions: true,
    actionsMenu,
  });

  const nrOfTransactions = useMemo(() => {
    try {
      if (directDebitTable.selectedRecords!.length > 0) return directDebitTable.selectedRecords!.length;
      return downloadData.data.totalNumberOfPayments;
    } catch (e) {
      return null;
    }
  }, [directDebitTable, downloadData]);

  const totalAmount = useMemo(() => {
    try {
      if (directDebitTable.selectedRecords!.length > 0) {
        return directDebitTable.selectedRecords!.reduce((acc, record) => acc + record.unformattedAmount, 0);
      }
      return downloadData.data.totalAmount;
    } catch (e) {
      return null;
    }
  }, [directDebitTable, downloadData]);

  const executionDateWarning = useMemo(() => {
    const today = moment();
    try {
      if (directDebitTable.selectedRecords!.length > 0) {
        return directDebitTable.selectedRecords!.reduce((acc, record) => acc || moment(new Date(record.executionDate)).isBefore(today, 'day'), false);
      }
      return downloadData.data.executionDateWarning;
    } catch (e) {
      return null;
    }
  }, [downloadData, directDebitTable.selectedRecords]);

  const directDebitExecutionModal = useExecutionModal({
    onDownload: () => {
      if (directDebitTable.selectedRecords && directDebitTable.selectedRecords.length > 0) {
        onDownloadDirectDebitsByIdAsEbics(directDebitTable.selectedRecords.map(dd => dd.id), nrOfTransactions, totalAmount, directDebitTable.onUnselectAll);
      } else {
        onDownloadAllAsEbics(nrOfTransactions, totalAmount);
      }
    },
    filter: directDebitFilterState,
    numberOfTransactions: nrOfTransactions,
    amount: totalAmount,
    basedOnSelection: directDebitTable.selectedRecords && directDebitTable.selectedRecords.length > 0,
    tl,
    managementCompanyName: selectedManagementCompany?.companyName,
    executionDateWarning,
    sendAmendMandateInfoToBank,
    setSendAmendMandateInfoToBank,
    showMandateAmendmentCheckbox: isMandateAmendmentEnabled,
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'directDebits',
    navItems: subcategorySwitcherItems.accounting,
  };

  const onDownloadSelected = () => {
    if (directDebitTable.selectedRecords && directDebitTable.selectedRecords.length > 0) {
      directDebitExecutionModal.showModal();
    } else {
      onLoadEbicsDownloadDataByFilters(directDebitExecutionModal.showModal);
    }
  };

  return (
    <div className="DirectDebitList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <PaymentHeaderButtons
            loading={downloadData.loading}
            onRepairEbics={directDebitEbicsRepairModal.showModal}
            onDownloadSelected={onDownloadSelected}
            downloadLabel={directDebitTable.selectedRecords && directDebitTable.selectedRecords.length > 0
              ? tl(translations.pages.payment.sepaSelect)
              : tl(translations.pages.payment.sepaAll)}
            managementCompanyList={managementCompanies}
            managementCompanyLabel={selectedManagementCompany?.companyName || ''}
            managementCompanyLoading={selectedManagementCompany === null}
            onSelectCompany={onSelectCompany}
          />
        )}
      />
      <SmartTable {...directDebitTable} />
      <DirectDebitExecutionModal {...directDebitExecutionModal} />
      <FileUploadModal {...directDebitEbicsRepairModal} />
    </div>
  );
}

export const DirectDebitListWithProvider = () => (
  <DirectDebitListProvider>
    <DirectDebitList />
  </DirectDebitListProvider>
);
