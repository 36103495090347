import React from 'react';
import { useHistory } from 'react-router';
import Button from 'elements/Buttons/Button/Button';
import { BreadcrumbInterface } from '../Breadcrumbs';
import './BreadcrumbLink.scss';


export interface BreadcrumbLink {
  label?: string
  path?: string
  url?: string
}

export const BreadcrumbAnchor = ({ link }: { link: BreadcrumbInterface }) => {
  const history = useHistory();
  return (
    <Button
      className={`BreadcrumbAnchor ${link.className}`}
      type="text"
      onClick={() => history.push(link.url)}
    >
      {link.label}
    </Button>
  );
};
