import {
  ExtendedExchangeProjectionTypeEnum,
} from 'api/accounting';
import { difference, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { useContext, useMemo } from 'react';
import { useScoreStringSearch } from 'services/search/useScoreStringSearch';
import { formatCurrency } from 'lib/Utils';
import {
  ExchangeListAndPreviousAllocationValuesContext,
  PostingText,
  PropertyVatEligibilityInfoContext,
  ServiceCompanyAllocationUpdatersContext,
  ServiceCompanyAllocationValues,
  SetPostingTextsContext,
} from '../../../services/ServiceCompanyAllocationContext';
import { shouldShowPropertyDataInExchangeList } from './exchangeListUtils';


const keyPresentInAcc = (
  missingKeys: string[],
  newPostingTexts: PostingText[],
) => missingKeys.reduce(
  (acc, key) => acc || newPostingTexts.findIndex(pt => pt.key === key) > -1,
  false,
);

const stringifyFieldsRelevantForSearch = (xc: ServiceCompanyAllocationValues, selectedBankTransactions: ExtendedBankTransaction[]) => {
  const searchableFields = `${
    xc.accounts.map(acc => `${acc.code ?? ''} ${acc.code?.replaceAll('/', '') ?? ''} ${acc.name ?? ''}`)
  } ${
    xc.counterpartName ?? ''
  } ${
    xc.date ?? ''
  } ${
    xc.exchangeRemainingAmount ?? ''
  } ${
    formatCurrency(xc.exchangeAmount) ?? ''
  } ${
    xc.purpose ?? ''
  }`;

  if (!shouldShowPropertyDataInExchangeList(selectedBankTransactions)) {
    return searchableFields;
  }

  return searchableFields.concat(` ${
    xc.propertyHrId
  } ${
    xc.propertyIdInternal
  } ${
    xc.propertyName
  }`);
};


export const useExchangeList = (selectedBankTransactions: ExtendedBankTransaction[]) => {
  const exchangeListAndPreviousAllocationValuesContext = useContext(ExchangeListAndPreviousAllocationValuesContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);
  const setPostingTexts = useContext(SetPostingTextsContext);
  const propertyVatEligibilityInfoContext = useContext(PropertyVatEligibilityInfoContext);

  if (exchangeListAndPreviousAllocationValuesContext === undefined || updatersContext === undefined || setPostingTexts === undefined || propertyVatEligibilityInfoContext == undefined) {
    throw new Error('useExchangeList must be used within a ServiceCompanyAllocationContextProvider');
  }

  const { exchangeList, setExchangeList, searchString } = exchangeListAndPreviousAllocationValuesContext;
  const {
    setAllocationAmounts,
    setAllocationLaborCostValues,
    setSelectInputValues,
    setAllocationVatEligibilityValues,
  } = updatersContext;

  const { propertyVatEligibilityInfo } = propertyVatEligibilityInfoContext;
  const scoreStringSearch = useScoreStringSearch();


  const addNewExchange = (selectedTransactions: ExtendedBankTransaction[]) => {
    const propertyHrId = selectedBankTransactions[0]?.propertyList?.length === 1 ? selectedBankTransactions[0]?.propertyList[0]?.propertyHrId : undefined;
    const vatEligibility = propertyHrId !== undefined ? propertyVatEligibilityInfo.find(p => p.propertyHrId === propertyHrId)?.vatEligibilityShare : undefined;

    const newXcKey = nanoid();
    setExchangeList(prev => prev.load([
      { key: newXcKey, type: ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED, propertyHrId },
      ...(prev.data ?? []),
    ]));

    setAllocationAmounts(prev => [
      { key: newXcKey, exchangeType: ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED },
      ...(prev ?? []),
    ]);

    setAllocationLaborCostValues(prev => [
      { key: newXcKey, laborCostAmount: 0 },
      ...(prev ?? []),
    ]);

    setSelectInputValues(prev => [
      { key: newXcKey, vatPercentage: 19, propertyHrId },
      ...(prev ?? []),
    ]);

    setPostingTexts(prev => ([
      ...selectedTransactions.map(tx => ({ key: newXcKey, transactionId: tx.bankTransactionId, postingText: tx.purpose || '' })),
      ...(prev ?? []),
    ]));


    setAllocationVatEligibilityValues(prev => [
      { key: newXcKey, vatEligibilityPercentage: vatEligibility },
      ...(prev ?? []),
    ]);

    // setTimeout is needed so that the scrolling is put to the end of the eventLoop
    // so that the UI has time to render the newly added row
    setTimeout(() => document.querySelector('.ExchangeList .ant-table-body')?.scrollTo(0, 0), 0);
  };

  /**
   * Example: there is a group of 4 transactions, of which 2 are selected.
   * Since the user can click 'Create new order' with 2 selected txs,
   * and then select the 3rd tx from the same group, we need to initialize the postingText
   * for that newly selected tx as well
   */
  const addPotentiallyMissingPostingTexts = (selectedTransactions: ExtendedBankTransaction[]) => {
    setPostingTexts((prev) => {
      const txIdToMatchingKeysMap = selectedTransactions.reduce((acc, tx) => {
        const postingTextsMatchingTxId = prev?.filter(pt => pt.transactionId === tx.bankTransactionId);
        acc[tx.bankTransactionId!] = { keys: new Set(postingTextsMatchingTxId?.map(pt => pt.key)), transaction: tx };
        return acc;
      }, {} as { [key: number]: { keys: Set<string>, transaction: ExtendedBankTransaction } });

      const missingPostingTexts: PostingText[] = Object.entries(txIdToMatchingKeysMap).reduce((acc, [txId, matchingKeysMap]) => {
        Object.entries(txIdToMatchingKeysMap).forEach(([otherTxId, otherMatchingKeysMap]) => {
          // skip same entry
          if (otherTxId === txId) return;

          const diff = difference(Array.from(otherMatchingKeysMap.keys), Array.from(matchingKeysMap.keys));
          if (!isEmpty(diff) && !keyPresentInAcc(diff, acc)) {
            acc.push(...(diff.map(missingKey => ({ key: missingKey, transactionId: parseInt(txId, 10), postingText: matchingKeysMap.transaction.purpose ?? '' }))));
          }
        });
        return acc;
      }, [] as PostingText[]);


      return [...(prev ?? []), ...missingPostingTexts];
    });
  };


  // to avoid recalculating `searchValue` on every search we prepare it only once, when the exchange list changes
  const preparedExchangeList = useMemo(() => {
    const listOfExchanges = exchangeList.data?.map(xc => ({
      ...xc,
      ...(
        // only add id and searchValue for not TX_BASED exchanges
        xc.type !== ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED
          ? { id: xc.key, searchValue: stringifyFieldsRelevantForSearch(xc, selectedBankTransactions) }
          : {}
      ),
    })) ?? [];
    // ensure that matching exchanges are displayed first
    if (selectedBankTransactions[0]) {
      const amountToMatch = selectedBankTransactions[0].remainingAmount;
      const ibanToMatch = selectedBankTransactions[0].counterpartIban;
      listOfExchanges.sort((a, b) => {
        const ibanMatch = xc => xc.counterpartIban === ibanToMatch;
        const amountMatch = xc => xc.exchangeAmount === amountToMatch;
        const fullMatch = xc => ibanMatch(xc) && amountMatch(xc);
        // matching iban AND amount comes first
        if (fullMatch(a) && !fullMatch(b)) {
          return -1;
        } if (fullMatch(b) && !fullMatch(a)) {
          return 1;
        } if (fullMatch(b) && fullMatch(a)) {
          return 0;
        }
        // matching iban comes first
        if (ibanMatch(a) && !ibanMatch(b)) {
          return -1;
        }
        if (ibanMatch(b) && !ibanMatch(a)) {
          return 1;
        }
        // matching amount comes first
        if (amountMatch(a) && !amountMatch(b)) {
          return -1;
        }
        if (amountMatch(b) && !amountMatch(a)) {
          return 1;
        }
        // everything else sort descending by amount
        if (a.exchangeAmount > b.exchangeAmount) {
          return -1;
        }
        if (a.exchangeAmount < b.exchangeAmount) {
          return 1;
        }
        return 0;
      });
    }

    return listOfExchanges;
  }, [exchangeList.data]);


  const dataSource = useMemo(() => {
    const [txBasedXCs, otherXCs] = preparedExchangeList?.reduce((acc, curr) => {
      if (curr.type === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
        acc[0].push(curr);
      } else {
        acc[1].push(curr);
      }

      return acc;
    }, [[], []]);


    // if more than one txs are selected then only show TX_BASED exchanges
    if (selectedBankTransactions.length > 1) {
      return txBasedXCs;
    }


    const scored = scoreStringSearch(otherXCs, searchString);

    return [...txBasedXCs, ...scored];
  }, [selectedBankTransactions, preparedExchangeList, searchString]);


  return {
    dataSource,
    exchanges: exchangeList,
    addNewExchange,
    addPotentiallyMissingPostingTexts,
  };
};
