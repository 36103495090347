import { lazy } from 'react';
import { RouteDefinition } from 'components/RenderRoutes';
import { MESSAGE_SENDING_PATH } from 'pages/MessageSendingPage/routes';
import { DOCUMENT_PREVIEW_PATH } from 'pages/DocumentPreview/routes';
import DocumentPreviewPage from 'pages/DocumentPreview/DocumentPreviewPage';
import { OrderOpenBalancesListWithProvider } from './OrderOpenBalancesList/OrderOpenBalancesList';
import { DunningListWithProvider } from './DunningList/DunningList';


const OrderOpenBalancesViewWithProvider = lazy(() => import('./OrderOpenBalancesView/OrderOpenBalancesView'));
const MessageSendingPageWithProvider = lazy(() => import('pages/MessageSendingPage/MessageSendingPage'));

export const getOrderOpenbalanceViewPath = (dunningId: string | number) => `${ORDER_OPEN_BALANCES_LIST_PATH}/view/${dunningId}`;


export const ORDER_OPEN_BALANCES_BASE = '/order-open-balances';

export const ORDER_OPEN_BALANCES_NEW_PATH = `${ORDER_OPEN_BALANCES_BASE}/new`;
/** Overviw page of a to be created dunning */
export const ORDER_OPEN_BALANCES_OVERVIEW_PATH = `${ORDER_OPEN_BALANCES_NEW_PATH}/overview`;

export const ORDER_OPEN_BALANCES_LIST_PATH = `${ORDER_OPEN_BALANCES_BASE}/list`;
/** View of existing dunning */
export const ORDER_OPEN_BALANCES_VIEW_PATH = `${ORDER_OPEN_BALANCES_LIST_PATH}/view/:dunningId`;

export const ORDER_OPEN_BALANCES_BASE_MESSAGE_SENDING_PATH = `${ORDER_OPEN_BALANCES_LIST_PATH}${MESSAGE_SENDING_PATH}`;

export const OrderOpenBalancesRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: ORDER_OPEN_BALANCES_NEW_PATH,
      component: OrderOpenBalancesListWithProvider,
      subRoutes: [
        {
          path: ORDER_OPEN_BALANCES_OVERVIEW_PATH,
          component: OrderOpenBalancesViewWithProvider,
          type: 'focus',
        },
      ],
    },
    {
      path: ORDER_OPEN_BALANCES_LIST_PATH,
      component: DunningListWithProvider,
      subRoutes: [
        {
          path: ORDER_OPEN_BALANCES_VIEW_PATH,
          component: OrderOpenBalancesViewWithProvider,
          type: 'focus',
        },
        {
          path: ORDER_OPEN_BALANCES_BASE_MESSAGE_SENDING_PATH,
          component: MessageSendingPageWithProvider,
          type: 'overlay',
          subRoutes: [
            {
              path: `${ORDER_OPEN_BALANCES_BASE_MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
              component: DocumentPreviewPage,
              type: 'overlay',
            },
          ],
        },
      ],
    },
  ],
};
