import React from 'react';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { OwnersMeetingProtocolDtoStatusEnum } from '../../../../api/accounting';

function getDotColor(state: OwnersMeetingProtocolDtoStatusEnum): DotColorEnum {
  switch (state) {
  case OwnersMeetingProtocolDtoStatusEnum.OPEN:
    return DotColorEnum.YELLOW;
  case OwnersMeetingProtocolDtoStatusEnum.SENT:
  case OwnersMeetingProtocolDtoStatusEnum.DONE:
    return DotColorEnum.GREEN;
  case OwnersMeetingProtocolDtoStatusEnum.SENT_FOR_SIGNATURE:
    return DotColorEnum.BLUE;
  case OwnersMeetingProtocolDtoStatusEnum.PROBLEM:
    return DotColorEnum.RED;
  default:
    return DotColorEnum.GRAY;
  }
}

const ProtocolStatusCell = ({ record }) => (
  <>
    <ColorDot color={getDotColor(record.status)} />
    {record.protocolStatus}
  </>
);

export default ProtocolStatusCell;
