import {
  useContext,
} from 'react';
import { AgendaItemsControllerApi } from 'api/accounting';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import { ownersMeetingInvitationTranslations } from 'pages/OwnersMeetingInvitation/OwnersMeetingInvitationEditing/translations/OwnersMeetingInvitationTranslations';
import { AuthContext } from 'contexts/AuthContext';
import { OwnersMeetingInvitationContext } from '../../../../../../contexts/OwnersMeetingInvitationContext';
import { AgendaItemsContext } from '../../../../../../contexts/AgendaItemsContext';

export const useLoadAgendaItems = () => {
  const agendaItemsContext = useContext(AgendaItemsContext);
  const { apiConfiguration } = useContext(AuthContext);

  if (agendaItemsContext === undefined) {
    throw new Error('useLoadAgendaItems must be used within a AgendaItemsContextProvider');
  }
  const {
    agendaItemsList, setAgendaItemsList,
  } = agendaItemsContext;
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));
  const { ownersMeeting } = useContext(OwnersMeetingInvitationContext);
  const { tl } = useContext(LanguageContext);

  const onLoadAgendaItemsList = (resetPage: boolean = false) => {
    setAgendaItemsList((prev) => {
      if (!prev.loading) {
        loadAgendaItemsList(resetPage);
        return prev.startLoading();
      }
      return prev;
    });
  };

  const loadAgendaItemsList = (resetPage: boolean = false) => {
    agendaItemsControllerApi.getAllAgendaItemsForAnInvitationUsingGET({
      ownersMeetingInvitationId: ownersMeeting.data.id,
      page: resetPage ? 0 : agendaItemsList.page,
    })
      .then((response) => {
        setAgendaItemsList(prev => prev.loadPaged(response.content.sort((a, b) => a.index - b.index), resetPage, response.last!));
      })
      .catch((err) => {
        showNotification({
          type: 'error',
          message: tl(ownersMeetingInvitationTranslations.agendaItemsTable.loadAgendaItemsError),
        });
        setAgendaItemsList(prev => prev.failed(err));
      });
  };


  return { onLoadAgendaItemsList, loadAgendaItemsList };
};
