import React, { useContext, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { PropertyDisplayDtoVatRelevanceEnum } from 'api/accounting';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../../elements/Translation/translations';
import { ICONS } from '../../../components/icons';
import { Column, FORMAT } from '../../../elements/SmartTable/SmartTable';

export const UsePostingListColumns = (): (Column)[] => {
  const { tl } = useContext(LanguageContext);
  const { headers } = translations.pages.accountSheet.table;
  const location = useLocation();
  const history = useHistory();

  const { selectedProperty } = useContext(PropertyListContext);

  const openInvoice = (e: any, invoiceHrId: string) => {
    history.push(`${location.pathname}/invoice/edit/${invoiceHrId}`);
    e.stopPropagation();
  };

  const columns = useMemo(() => {
    const bookingColumns = [{
      title: tl(headers.date),
      format: FORMAT.DATE,
      defaultWidth: 155,
      dataKey: 'postDate',
      sortable: true,
    },
    {
      title: tl(headers.bookingText),
      dataKey: 'bookingText',
      sortable: false,
      defaultWidth: '20%',
    },
    {
      title: tl(headers.counterAccount),
      dataKey: 'counterAccounts',
      sortable: false,
      defaultWidth: '15%',
    },
    {
      title: tl(headers.debit),
      format: FORMAT.CURRENCY,
      dataKey: 'debit',
      sortable: false,
      defaultWidth: '10%',
    },
    {
      title: tl(headers.credit),
      format: FORMAT.CURRENCY,
      dataKey: 'credit',
      sortable: false,
      defaultWidth: '10%',
    },
    {
      title: tl(headers.laborCost),
      dataKey: 'laborCost',
      defaultVisible: false,
      sortable: false,
      defaultWidth: 155,
    },
    {
      title: tl(headers.laborCostType),
      dataKey: 'laborCostType',
      defaultVisible: false,
      sortable: false,
      defaultWidth: 155,
    },
    {
      title: tl(headers.vatAmount),
      dataKey: 'vatAmount',
      format: FORMAT.CURRENCY,
      defaultVisible: false,
      sortable: false,
      defaultWidth: 155,
    },
    {
      title: tl(headers.vatPercentage),
      dataKey: 'vatPercentage',
      format: FORMAT.PERCENTAGE,
      defaultVisible: false,
      sortable: false,
      defaultWidth: 50,
    },
    ];

    if ([PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT, PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT].includes(selectedProperty.data?.vatRelevance)) {
      bookingColumns.push({
        title: tl(headers.vatEligibilityAmount),
        dataKey: 'vatEligibilityAmount',
        format: FORMAT.CURRENCY,
        defaultVisible: false,
        sortable: false,
        defaultWidth: 155,
      },
      {
        title: tl(headers.vatEligibilityPercentage),
        dataKey: 'vatEligibilityPercentage',
        format: FORMAT.PERCENTAGE,
        defaultVisible: false,
        sortable: false,
        defaultWidth: 50,
      });
    }

    const tempColumns = [
      {
        title: `${tl(headers.account)}`,
        dataKey: 'accountCode',
        sortable: false,
        defaultWidth: 100,
        disabled: true,
      },
      {
        title: `${tl(headers.invoice)}`,
        children: [{
          title: `${tl(headers.invoice)}`,
          defaultWidth: 50,
          dataKey: 'invoiceHrId',
          sortable: false,
          render: (text: any, row: any) => {
            if (row.invoiceHrId) {
              return (
                <Icon
                  className="invoice-icon"
                  onClick={(e) => {
                    openInvoice(e, row.invoiceHrId);
                  }}
                  component={ICONS.file}
                />
              );
            }
            return null;
          },
        },
        {
          title: tl(headers.invoiceDate),
          format: FORMAT.DATE,
          defaultWidth: 155,
          dataKey: 'invoiceDate',
          defaultVisible: false,
          sortable: false,
        },
        {
          title: tl(headers.invoiceAmount),
          format: FORMAT.CURRENCY,
          defaultWidth: 155,
          dataKey: 'totalGross',
          defaultVisible: false,
          sortable: false,
        },
        {
          title: tl(headers.invoiceState),
          defaultWidth: 100,
          dataKey: 'state',
          defaultVisible: false,
          sortable: false,
        },
        {
          title: tl(headers.creditor),
          defaultWidth: '15%',
          dataKey: 'creditor',
          sortable: false,
        }],
      },
      {
        title: tl(headers.booking),
        defaultWidth: '55%',
        children: bookingColumns,
      },
      {
        title: tl(headers.balance),
        format: FORMAT.CURRENCY,
        dataKey: 'runningBalance',
        sortable: false,
        defaultWidth: '10%',
      },
    ];

    return tempColumns;
  }, [selectedProperty.data?.vatRelevance]);

  return columns;
};
