import React, { useContext } from 'react';
import { Table } from 'antd';
import { InformationBar } from 'storybook-components/feedback/InformationBar/InformationBar';
import { useVT } from 'virtualizedtableforantd4';
import { translations } from '../../../../translations';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { UnitSelectionColumns } from '../component/UnitSelectionColumns/UnitSelectionColumns';
import {
  UnitDistributionSetDtoDistributionModeEnum,
  SpecialContributionDetailsDtoTypeEnum,
  UnitPaymentPreferenceDtoPaymentPreferenceEnum,
} from '../../../../../../api/accounting';
import { DefaultDataInterface } from '../../../../../../lib/data';
import { UnitSelectionTableData } from '../model/UnitSelectionTableData';
import TableSummaryTotalAmount from '../../../../../../elements/TableSummaryTotalAmount/TableSummaryTotalAmount';
import { formatToEuro, isBaseDistribution } from '../../../../../../lib/Utils';

export function UnitOwnersTable(
  onChangePaymentPreference: (unitNrSharingDeclaration: string, paymentPreference: UnitPaymentPreferenceDtoPaymentPreferenceEnum) => void,
  onChangeDkValue: (unitNrSharingDeclaration: string, dkValue: number) => void,
  onChangeSelectedRows: (selectedRowKeys: any[]) => void,
  onAllRowsSelected: (selected: boolean, selectedRows: any[]) => void,
  onRowSelected: (row: UnitSelectionTableData, selected: boolean) => void,
  onClickWrapper: (row: UnitSelectionTableData) => void,
  computeUnitAmounts: (dsMode: UnitDistributionSetDtoDistributionModeEnum | undefined, total: number) => void,
  filteredUnitTableData: DefaultDataInterface<UnitSelectionTableData[]>,
  distributionMode?: UnitDistributionSetDtoDistributionModeEnum,
  type?: SpecialContributionDetailsDtoTypeEnum,
  transactionsExecutionAllowed?: boolean,
  totalShares?: number,
  totalAmount?: number,
  disabled: boolean = false,
) {
  const { tl } = useContext(LanguageContext);

  const [countIncluded, countSelfManaged, countSystemManaged, amountToDistribute] = filteredUnitTableData.data?.filter((unitSelectionTableData: UnitSelectionTableData) => unitSelectionTableData.selected)
    .reduce(([included, selfManaged, systemManaged, amountToDistributeTotal], value) => [
      included + (value.selected ? 1 : 0),
      selfManaged + (value.paymentPreference === UnitPaymentPreferenceDtoPaymentPreferenceEnum.SELF_MANAGED ? 1 : 0),
      systemManaged + (value.paymentPreference === UnitPaymentPreferenceDtoPaymentPreferenceEnum.SYSTEM_MANAGED ? 1 : 0),
      parseFloat(amountToDistributeTotal.toFixed(2)) + (parseFloat((value.unitAmount ?? 0).toFixed(2))),
    ], [0, 0, 0, 0]) ?? [0, 0, 0, 0];

  const getAmountSummaryLabel = () => {
    if (isBaseDistribution(distributionMode)) {
      return tl(translations.specialContribution.section3.summaryRow.labelForBaseDistribution);
    }
    const restValue = amountToDistribute - (totalAmount ?? 0);
    const label = restValue > 0 ? tl(translations.specialContribution.section3.summaryRow.exceededAmount) : tl(translations.specialContribution.section3.summaryRow.rest);
    return `${label} ${formatToEuro(restValue)}`;
  };

  const getInformationBarColor = () => {
    if (amountToDistribute === (totalAmount ?? 0)) return 'green';
    if (amountToDistribute < (totalAmount ?? 0)) return 'orange';
    return 'red';
  };

  const tableHeight = 500;
  const [
    VT,
  ] = useVT(() => ({
    scroll: {
      y: tableHeight,
    },
  }), []);

  return {
    sectionId: 'UnitSelection',
    content: (
      <>
        <div className="unit-selection-table-title">
          {tl(translations.specialContribution.section3.subsections.unitSelectionTitle)}
        </div>
        <Table
          // @ts-ignore
          columns={UnitSelectionColumns(type, transactionsExecutionAllowed, onChangePaymentPreference, onChangeDkValue, onClickWrapper,
            computeUnitAmounts, distributionMode, totalShares, totalAmount, disabled)}
          dataSource={filteredUnitTableData?.data || []}
          rowSelection={{
            columnWidth: 40,
            selectedRowKeys: filteredUnitTableData?.data?.reduce((selectedKeysAcc: string[], unit) => (unit.selected ? [...selectedKeysAcc, unit.unitNrSharingDeclaration] : selectedKeysAcc),
              []),
            onChange: onChangeSelectedRows,
            onSelect: onRowSelected,
            getCheckboxProps: () => ({
              disabled,
            }),
          }}
          components={VT}
          pagination={false}
          scroll={{ y: tableHeight }}
          summary={() => TableSummaryTotalAmount({
            amountLabel: getAmountSummaryLabel(),
            totalAmount: formatToEuro(amountToDistribute),
            amountCellColSpan: 6,
          })}
        />
        <InformationBar
          delimiter="‧"
          color={getInformationBarColor()}
          content={[
            {
              label: tl(translations.specialContribution.section3.informationBar.total),
              value: filteredUnitTableData.data?.length ?? 0,
            },
            {
              label: tl(translations.specialContribution.section3.informationBar.excluded),
              value: filteredUnitTableData.data?.length! - countIncluded ?? 0,
            },
            {
              label: tl(translations.specialContribution.section3.informationBar.included),
              value: countIncluded ?? 0,
            },
            {
              label: tl(translations.specialContribution.section3.informationBar.processingOptions[type ?? SpecialContributionDetailsDtoTypeEnum.CONTRIBUTION].SYSTEM_MANAGED),
              value: countSystemManaged ?? 0,
            },
            {
              label: tl(translations.specialContribution.section3.informationBar.processingOptions[type ?? SpecialContributionDetailsDtoTypeEnum.CONTRIBUTION]?.SELF_MANAGED),
              value: countSelfManaged ?? 0,
            },
          ]}
        />
      </>
    ),
  };
}
