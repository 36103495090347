import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { useContext } from 'react';
import { OwnersMeetingInvitationDtoMeetingTypeEnum, OwnersMeetingProtocolDtoStatusEnum } from 'api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';

export default function useOwnersMeetingProtocolFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    {
      type: 'enum',
      name: tl(translations.headers.status),
      key: 'statuses',
      options: Object.values(OwnersMeetingProtocolDtoStatusEnum).map(value => ({
        label: tl(translations.states[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(translations.headers.property),
      key: 'propertyName',
    },
    {
      type: 'date',
      name: `Min ${tl(translations.list.date)}`,
      key: 'minDate',
    },
    {
      type: 'date',
      name: `Max ${tl(translations.list.date)}`,
      key: 'maxDate',
    },
    {
      type: 'text',
      name: tl(translations.list.meetingLeader),
      key: 'meetingLeader',
    },
    {
      type: 'enum',
      name: tl(translations.list.type),
      key: 'meetingType',
      options: Object.values(OwnersMeetingInvitationDtoMeetingTypeEnum).map(value => ({
        label: tl(translations.list[value.toLowerCase()]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(translations.list.location),
      key: 'location',
    },
  ];
}
