import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../../elements/Translation/translations';
import { ContactListContext } from '../../../contexts/ContactListContext';
import DEFAULT_DATA from '../../../lib/data';
import { AuthContext } from '../../../contexts/AuthContext';
import {
  SliceOfPropertyDisplayDto, PropertyBankAccountProjectionDto, PropertyBankAccountProjectionDtoPropertyAccountTypesEnum, PropertyLegacyControllerApi, PropertyDisplayDto,
} from '../../../api/accounting';
import { showNotification } from '../../../lib/Notification';

const PAGE_SIZE = 30;
export const DEFAULT_FILTER_BANK_ACCOUNT_LIST: any = {
  propertyStates: ['READY', 'OFFBOARDED'],
  excludeFields: ['buildings', 'units'],
  administrationTypes: ['WEG', 'MV'],
};

export const useBankAccountList = () => {
  const contactListContext: any = useContext(ContactListContext);
  const { tl } = useContext(LanguageContext);

  const [bankAccountListState, setBankAccountListState] = useState(DEFAULT_DATA<any>([]));
  const [filterState, setFilterState] = useState<any>({});
  const [bankAccountSortState, setBankAccountSortState] = useState({
    field: 'propertyHrId',
    order: 1,
  });
  const [initialSortUpdate, setInitialSortUpdate] = useState(true);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const lastRequestTimestamp = useRef<number | null>(null);

  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (!initialSortUpdate) {
      onLoadBankAccountList(true);
    } else {
      setInitialSortUpdate(false);
    }
  }, [bankAccountSortState]);


  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadBankAccountList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState]);

  function convertToBankAccount(property: PropertyDisplayDto) {
    let houseMoneyBalance: number = 0;
    let houseMoneyUndefined = true;
    let reserveMoneyUndefined = true;
    let reserveMoneyBalance: number = 0;
    let calculatedHouseMoneyBalance: number = 0;
    let calculatedReserveMoneyBalance: number = 0;
    let calculatedTotalBalance: number = 0;
    const bankAccounts: Array<{
      id: number | undefined,
      type: PropertyBankAccountProjectionDtoPropertyAccountTypesEnum[] | undefined,
      name: string | undefined,
      institute: string | undefined,
      iban: string,
      bic: string,
      balance: number | undefined,
      calculatedBalance: number | undefined,
    }> = [];
    if (!!property.supervisorContactId && !contactListContext.containsContact(property.supervisorContactId)) {
      contactListContext.onLoadContact(property.supervisorContactId);
    }
    // eslint-disable-next-line no-unused-expressions
    property.bankAccounts?.forEach((bankAccount: PropertyBankAccountProjectionDto) => {
      bankAccounts.push({
        id: bankAccount.bankAccountId,
        type: bankAccount.propertyAccountTypes,
        name: bankAccount.accountName,
        institute: bankAccount.accountInstitute,
        iban: bankAccount.iban,
        bic: bankAccount.bic,
        balance: bankAccount.balance,
        calculatedBalance: bankAccount.calculatedBalance || undefined,
      });

      if (typeof bankAccount.balance !== 'undefined') {
        if (bankAccount.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE)) {
          houseMoneyBalance += bankAccount.balance;
          houseMoneyUndefined = false;
        } else {
          reserveMoneyBalance += bankAccount.balance;
          reserveMoneyUndefined = false;
        }
      }
      if (bankAccount.calculatedBalance) {
        calculatedTotalBalance += bankAccount.calculatedBalance;
        if (bankAccount.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE)) {
          calculatedHouseMoneyBalance += bankAccount.calculatedBalance;
        } else {
          calculatedReserveMoneyBalance += bankAccount.calculatedBalance;
        }
      }
    });

    return {
      propertyHrId: property.propertyHrId,
      propertyIdInternal: property.propertyIdInternal,
      propertyId: property.propertyIdInternal,
      managementCompanyId: property.managementCompany?.id,
      name: property.name,
      propertyState: property.propertyState,
      supervisorName: property.supervisorName,
      administrator: contactListContext.getName(property.supervisorContactId),
      houseMoneyBalance: !houseMoneyUndefined ? houseMoneyBalance : undefined,
      reserveMoneyBalance: !reserveMoneyUndefined ? reserveMoneyBalance : undefined,
      calculatedHouseMoneyBalance: calculatedHouseMoneyBalance || undefined,
      calculatedReserveMoneyBalance: calculatedReserveMoneyBalance || undefined,
      totalBalance: !houseMoneyUndefined || !reserveMoneyUndefined ? houseMoneyBalance + reserveMoneyBalance : undefined,
      calculatedTotalBalance: calculatedTotalBalance || undefined,
      bankAccounts: bankAccounts.map((bankAccount: any) => ({
        ...bankAccount,
        type: bankAccount.type?.map(type => tl(translations.pages.bankAccount.table.headers[type])).join(', '),
        balance: bankAccount.balance,
        calculatedBalance: bankAccount.calculatedBalance,
      })),
    };
  }

  function convertToBankAccountList(properties?: PropertyDisplayDto[]) {
    return properties ? properties.map(property => convertToBankAccount(property)) : [];
  }

  function errorLoading() {
    setBankAccountListState({
      ...bankAccountListState.failed(),
      data: {
        ...bankAccountListState.data,
      },
      lastPage: true,
    });
    showNotification({
      key: 'loadBankAccountListError',
      message: tl(translations.notifications.bankAccountListContext.loadError.message),
      type: 'error',
    });
  }

  const onLoadBankAccountList = async (resetPage: boolean = false, sort: any = bankAccountSortState) => {
    if (bankAccountListState.loading) {
      return;
    }
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setBankAccountListState(bankAccountListState.startLoading());
    try {
      const response: SliceOfPropertyDisplayDto = await propertyControllerApi.findFilteredPropertiesWithBankAccountsUsingGET({
        ...filterState,
        page: resetPage ? 0 : bankAccountListState.page,
        size: PAGE_SIZE,
        order: sort.order > 0 ? 'ASC' : 'DESC',
        sort: sort.field,
      });
      const bankAccounts = convertToBankAccountList(response.content);

      // do nothing if this is a response for an older request
      if (currentTimestamp !== lastRequestTimestamp.current) return;

      setBankAccountListState(old => old.loadPaged(
        bankAccounts,
        resetPage,
        !!response.last,
      ));
    } catch (e) {
      errorLoading();
    }
  };


  const onClearBankAccountList = (): void => {
    setBankAccountListState(bankAccountListState.load([]));
  };

  const onClearFilterState = (): void => {
    setFilterState({});
  };

  const updateFilterState = (data: object) => {
    setFilterState((old: any) => ({
      ...old,
      ...data,
    }));
  };

  const setSortField = (field: string) => {
    setBankAccountSortState((old: any) => ({
      field,
      order: old.field === field ? old.order * (-1) : 1,
    }));
  };

  return {
    ...bankAccountListState,
    onLoadBankAccountList,
    onClearBankAccountList,
    onClearFilterState,
    setSortField,
    filterState,
    updateFilterState,
    sortField: bankAccountSortState.field,
    sortOrder: bankAccountSortState.order,
  };
};
