export const translations = {
  defaultLabels: {
    frequency: {
      en: 'Frequency',
      de: 'Häufigkeit',
    },
    dayAndMonth: {
      en: 'On Day',
      de: 'Am Tag',
    },
    startDate: {
      en: 'Start date',
      de: 'Startdatum',
    },
    endCondition: {
      en: 'Validity end',
      de: 'Endet',
    },
    until: {
      en: 'End date',
      de: 'Enddatum',
    },
    count: {
      en: 'Count',
      de: 'Anzahl',
    },
  },
  countInputSuffix: {
    en: 'times',
    de: 'Ausführung(en)',
  },
  selectOptions: {
    frequency: {
      yearly: {
        en: 'Year(s)',
        de: 'Jahr(e)',
      },
      monthly: {
        en: 'Month(s)',
        de: 'Monat(e)',
      },
    },
    endCondition: {
      after: {
        en: 'After',
        de: 'Nach',
      },
      onDate: {
        en: 'On date',
        de: 'Datum',
      },
      never: {
        en: 'Never',
        de: 'Niemals',
      },
    },
    days: {
      lastDay: {
        en: 'Last day',
        de: 'Letzter Tag',
      },
    },
  },
  months: {
    january: {
      en: 'January',
      de: 'Januar',
    },
    february: {
      en: 'February',
      de: 'Februar',
    },
    march: {
      en: 'March',
      de: 'März',
    },
    april: {
      en: 'April',
      de: 'April',
    },
    may: {
      en: 'May',
      de: 'Mai',
    },
    june: {
      en: 'June',
      de: 'Juni',
    },
    july: {
      en: 'July',
      de: 'Juli',
    },
    august: {
      en: 'August',
      de: 'August',
    },
    september: {
      en: 'September',
      de: 'September',
    },
    october: {
      en: 'October',
      de: 'Oktober',
    },
    november: {
      en: 'November',
      de: 'November',
    },
    december: {
      en: 'December',
      de: 'Dezember',
    },
  },
  validation: {
    required: {
      en: 'Field is required.',
      de: 'Feld ist erforderlich.',
    },
    positive: {
      en: 'Must be a positive number.',
      de: 'Muss eine positive Zahl sein.',
    },
    skippedGenerationWarning: {
      short: {
        en: 'Shorter months are skipped.',
        de: 'Kürzere Monate werden übersprungen.',
      },
      detailed: {
        en: 'If a month has less than the selected amount of days then no event is generated for that month.',
        de: 'Für Monate mit weniger als der ausgewählten Anzahl an Tagen wird kein Ereignis erstellt.',
      },
    },
    endDateBeforeStartDateError: {
      en: 'Invalid date range.',
      de: 'Ungültiger Datumsbereich.',
    },
  },
};
