import { MessageListProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  createContext, ReactNode, useMemo, useState,
} from 'react';
import { Order } from 'services/useSort';


const MessagesListContext = createContext<| {
  messages: DefaultDataInterface<Array<MessageListProjectionDto>>
  setMessages: React.Dispatch<React.SetStateAction<DefaultDataInterface<MessageListProjectionDto[]>>>
  totalMessages: number,
  setTotalMessages: React.Dispatch<React.SetStateAction<number>>,
  filterState: any,
  setFilterState: React.Dispatch<React.SetStateAction<any>>,
  sortState: { field: keyof MessageListProjectionDto, order: Order },
  setSortState: React.Dispatch<React.SetStateAction<{ field: keyof MessageListProjectionDto, order: Order }>>,
  tableHeight: number,
  setTableHeight: React.Dispatch<React.SetStateAction<number>>,

}
    | undefined>(undefined);

export const useMessagesListContext = (usageName: string) => {
  const context = React.useContext(MessagesListContext);

  if (context === undefined) {
    throw new Error(`${usageName} must be used within an MessagesListContextProvider`);
  }

  return context;
};

export const MessagesSelectionContext = createContext<{
  selectedRowKeysCurrent: number[];
  selectedRowKeysTotal: number[];
  showAllSelected: boolean;
  setSelectedRowKeysCurrent: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedRowKeysTotal: React.Dispatch<React.SetStateAction<number[]>>;
  setShowAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
} | undefined>(undefined);

export const useMessagesSelectionContext = (usageName: string) => {
  const context = React.useContext(MessagesSelectionContext);

  if (context === undefined) {
    throw new Error(`${usageName} must be used within an MessagesListContextProvider`);
  }

  return context;
};

const DEFAULT_SORT: { field: keyof MessageListProjectionDto, order: Order } = {
  field: 'updated',
  order: -1,
};

const MessagesListContextProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState(DEFAULT_DATA<Array<MessageListProjectionDto>>([]));
  const [totalMessages, setTotalMessages] = useState<number>(0);
  const [sortState, setSortState] = useState(DEFAULT_SORT);
  const [filterState, setFilterState] = useState({});
  const [tableHeight, setTableHeight] = useState<number>(0);

  const [selectedRowKeysCurrent, setSelectedRowKeysCurrent] = useState<number[]>([]);
  const [selectedRowKeysTotal, setSelectedRowKeysTotal] = useState<number[]>([]);
  const [showAllSelected, setShowAllSelected] = useState(false);

  const state = useMemo(() => ({
    messages,
    setMessages,
    totalMessages,
    setTotalMessages,
    filterState,
    setFilterState,
    sortState,
    setSortState,
    tableHeight,
    setTableHeight,
  }), [messages, setMessages, totalMessages, setTotalMessages, filterState, setFilterState, sortState, setSortState, tableHeight, setTableHeight]);

  const selectionValue = useMemo(() => ({
    selectedRowKeysCurrent,
    selectedRowKeysTotal,
    showAllSelected,
    setSelectedRowKeysCurrent,
    setSelectedRowKeysTotal,
    setShowAllSelected,
  }), [selectedRowKeysCurrent, setSelectedRowKeysCurrent, selectedRowKeysTotal, setSelectedRowKeysTotal, showAllSelected, setShowAllSelected]);

  return (
    <MessagesListContext.Provider value={state}>
      <MessagesSelectionContext.Provider value={selectionValue}>
        {children}
      </MessagesSelectionContext.Provider>
    </MessagesListContext.Provider>
  );
};

export {
  MessagesListContext,
  MessagesListContextProvider,
};
