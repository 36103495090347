import { useContext, useMemo } from 'react';
import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';
import { useFeatureSettings } from 'lib/useFeatureSettings';
import { LanguageContext } from 'contexts/LanguageContext';
import { translationsUsePropertyAdministrationTypeFilterOption as translations } from './translationsUsePropertyAdministrationTypeFilterOption';

export const usePropertyAdministrationTypeFilterOption = () => {
  const { tl } = useContext(LanguageContext);
  const { isWegTenantContractEnabled } = useFeatureSettings();

  const administrationTypes = isWegTenantContractEnabled
    ? Object.keys(PropertyLegacyDtoAdministrationTypeEnum)
    : ['WEG', 'MV'];

  const filterOption = useMemo(() => ({
    type: 'enum',
    name: tl(translations.administrationType),
    key: 'administrationTypes',
    options: administrationTypes.map(value => ({
      label: tl(translations.administrationTypes[value]),
      value,
    })),
    multiValue: true,
    visibleAllOptions: true,
  } as const), [tl, isWegTenantContractEnabled]);

  return filterOption;
};
