import { useContext, useMemo, useState } from 'react';
import { matchPath } from 'react-router';
import { OPS_COST_REPORTS_PATH } from 'pages/OpsCostReport/routes';
import { DIRECT_DEBIT_LIST_URL } from 'pages/DirectDebitList/routes';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { translations } from '../../../../translations';
import { translations as globalTranslations } from '../../../../../../elements/Translation/translations';

export interface NavItem {
  name: string,
  path: string,
  absolute?: boolean
}

export default function useNavigationItems({ currentPage }: { currentPage: string }) {
  const { tl } = useContext(LanguageContext);
  const [filter, setNavigationItemFilter] = useState<string>('');

  const defaultNavigationItems = [
    {
      absolute: true,
      name: tl(globalTranslations.pages.invoice.title),
      path: '/invoice',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.accountSales.title),
      path: '/account-sales',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.accountBalances.title),
      path: '/account-balances',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.distributionKeyDetails.title),
      path: '/distribution-keys',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.houseMoneySettlement.title),
      path: '/house-money-settlement',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.economicPlan.title),
      path: '/economic-plan',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.wka.title),
      path: '/wka',
    },
    /* {
      absolute: true,
    name: tl(globalTranslations.pages.bankAccount.title),
    path: '/bank-account',
  }, */
    {
      absolute: true,
      name: tl(globalTranslations.pages.payment.title),
      path: '/payments',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.standingOrderOverview.title),
      path: '/standing-orders',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.directDebitList.title),
      path: 'direct-debits',
    },
    {
      absolute: true,
      name: tl(translations.quickAddMenu.menuOptions.ownersMeeting),
      path: '/owners-meeting/invitation',
    },
    {
      absolute: true,
      name: tl(translations.quickAddMenu.menuOptions.ownersMeetingProtocol),
      path: '/owners-meeting/protocol',
    },
    {
      absolute: true,
      name: tl(globalTranslations.pages.resolutionRecordOverview.title),
      path: '/resolution-records',
    },
    {
      absolute: true,
      name: tl(translations.quickAddMenu.menuOptions.contactCompany),
      path: '/companies',
    },
    {
      absolute: true,
      name: tl(translations.quickAddMenu.menuOptions.contactPerson),
      path: '/persons',
    },
    {
      absolute: true,
      name: tl(translations.quickAddMenu.menuOptions.opsCostReport),
      path: OPS_COST_REPORTS_PATH,
    },
  ];

  const propertyNavigationItems = [
    {
      name: tl(globalTranslations.pages.invoice.title),
      path: '/invoice',
    },
    {
      name: tl(globalTranslations.pages.accountSales.title),
      path: '/account-sales',
    },
    {
      name: tl(globalTranslations.pages.accountBalances.title),
      path: '/account-balances',
    },
    {
      name: tl(globalTranslations.pages.distributionKeyDetails.title),
      path: '/distribution-keys',
    },
    {
      name: tl(globalTranslations.pages.houseMoneySettlement.title),
      path: '/house-money-settlement',
    },
    {
      name: tl(globalTranslations.pages.economicPlan.title),
      path: '/economic-plan',
    },
    {
      name: tl(globalTranslations.pages.wka.title),
      path: '/wka',
    },
    {
      name: tl(globalTranslations.pages.payment.title),
      path: '/payments',
    },
    {
      name: tl(globalTranslations.pages.standingOrderOverview.title),
      path: '/standing-orders',
    },
    {
      name: tl(globalTranslations.pages.directDebitList.title),
      path: DIRECT_DEBIT_LIST_URL,
    },
    {
      name: tl(translations.quickAddMenu.menuOptions.ownersMeeting),
      path: '/owners-meeting/invitation',
    },
    {
      name: tl(translations.quickAddMenu.menuOptions.ownersMeetingProtocol),
      path: '/owners-meeting/protocol',
    },
    {
      name: tl(globalTranslations.pages.resolutionRecordOverview.title),
      path: '/resolution-records',
    },
    {
      name: tl(translations.quickAddMenu.menuOptions.opsCostReport),
      path: OPS_COST_REPORTS_PATH,
    },
  ];

  const navigationItemMap = [
    {
      path: '/dashboard',
      routes: defaultNavigationItems,
    },
    {
      path: '/properties/:propertyHrId/edit',
      routes: propertyNavigationItems,
    },
  ];

  const filteredNavigationItems = useMemo(() => {
    const matchingPaths = navigationItemMap.filter(entry => matchPath(currentPage, {
      path: entry.path,
      strict: true,
    }));
    if (matchingPaths.length === 0) return [];
    return matchingPaths[0]?.routes?.filter((navItem: NavItem) => navItem?.name?.toLowerCase().includes(filter?.toLowerCase()))
    || [];
  },
  [currentPage, filter]);

  return { filteredNavigationItems, setNavigationItemFilter };
}
