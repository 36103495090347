
export const paymentDirectionSwitchTranslations = {
  incoming: {
    en: 'Incoming',
    de: 'Eingehend',
  },
  outgoing: {
    en: 'Outgoing',
    de: 'Ausgehend',
  },
};
