import React, { useEffect, useState } from 'react';
import './SectionMenu.scss';
import _ from 'lodash';
import useEvent from '../../lib/useEvent';
import Link from '../../elements/FormElements/FormSidebar/Link/Link';
import SectionSubElements, { SectionMenuSubElement } from './SectionSubElements';
import SectionGroupElements, { SectionMenuGroupElement } from './SectionGroupElements';


export interface SectionMenuElement {
  title: string,
  sectionId: string,
  open?: boolean,
  subElements?: SectionMenuSubElement[],
  groupElements?: SectionMenuGroupElement[]
}
interface SectionMenuProps {
  elements: SectionMenuElement[],
  scrollTarget: HTMLElement | null
}

export default function SectionMenu(props: SectionMenuProps): JSX.Element {
  const { scrollTarget, elements } = props;

  const [activeElementHref, setActiveElementHref] = useState<string>();
  const [menuElements, setElements] = useState(elements);


  useEffect(() => setActiveElementHref(menuElements[0].sectionId), []);


  useEvent('scroll', scrollTarget, (() => {
    if (scrollTarget) {
      const rect = scrollTarget.getBoundingClientRect();
      const sectionElement = document.elementsFromPoint(rect.left + (rect.width / 2), rect.top + (rect.height / 6))
        .filter((element: Element) => !!element.id && _.findIndex(menuElements.map(e => e.sectionId), si => _.isEqual(si, element.id)) !== -1)[0];

      if (!!sectionElement && !!sectionElement.id && activeElementHref !== sectionElement.id) {
        setActiveElementHref(sectionElement.id);
      }
    }
  }));

  const onOpenElement = (index: number) => {
    setElements(menuElements.map((element: SectionMenuElement, i: number) => {
      if (i === index) {
        element.open = !element.open;
      }
      return element;
    }));
  };

  /**
   * Search the clicked element/subelement
   * and navigate to it.
   * @param sectionId
   */
  const onScrollToSection = (sectionId: string) => {
    const elm = document.getElementById(sectionId);
    if (elm) {
      elm.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveElementHref(sectionId);
  };

  const hasSubElements = (element: SectionMenuElement) => (!!element.subElements && element.subElements.length !== 0) || (!!element.groupElements && element.groupElements.length !== 0);

  return (
    <div className="SectionMenu">
      {menuElements.map((element, i) => (
        <Link
          className="main-link"
          key={`main-link-${element.sectionId}`}
          level={1}
          active={element.sectionId === activeElementHref}
          onClick={() => onScrollToSection(element.sectionId)}
          showArrow={hasSubElements(element)}
          onArrowClick={() => onOpenElement(i)}
          open={element.open}
          title={`${i + 1}\u00A0\u00A0\u00A0\u00A0${element.title}`}
        >
          <SectionSubElements
            elements={element.subElements || []}
            open={element.open || false}
            activeElementHref={activeElementHref}
            onScrollToSection={onScrollToSection}
            parentSectionId={element.sectionId}
          />
          <SectionGroupElements
            elements={element.groupElements || []}
            open={element.open || false}
            activeElementHref={activeElementHref}
            onScrollToSection={onScrollToSection}
            parentSectionId={element.sectionId}
          />
        </Link>
      ))}
    </div>
  );
}
