import React, {
  createContext, ReactNode, useContext, useState,
} from 'react';
import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import { ALLOCATION_TYPES } from '../../interfaces';


const AllocationTypeSelectorContext = createContext<
  | {
    selectedType: ALLOCATION_TYPES,
    setSelectedType: React.Dispatch<React.SetStateAction<ALLOCATION_TYPES>>,
  }
  | undefined
>(undefined);


export const AllocationTypeSelectorProvider = ({ children }: { children: ReactNode }) => {
  const [selectedType, setSelectedType] = useState<ALLOCATION_TYPES>();
  const value = {
    selectedType,
    setSelectedType,
  };

  return (
    <AllocationTypeSelectorContext.Provider value={value}>
      {children}
    </AllocationTypeSelectorContext.Provider>
  );
};


export const useAllocationTypeSelector = (propertyAdministrationTypes: PropertyLegacyDtoAdministrationTypeEnum[]) => {
  const allocationTypeSelectorContext = useContext(AllocationTypeSelectorContext);

  if (allocationTypeSelectorContext === undefined) {
    throw new Error('useAllocationTypeSelector must be used within a AllocationTypeSelectorContext');
  }

  const { tl } = useContext(LanguageContext);

  const types = [];

  if (propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.WEG)) {
    types.push({
      label: tl(translations.bankTransactionAllocation.allocationTypes.unit_owner),
      value: ALLOCATION_TYPES.UNIT_OWNER,
    });
  }

  if (propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.MV) || propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.SEV) || propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.SEV_MV)) {
    types.push({
      label: tl(translations.bankTransactionAllocation.allocationTypes.tenant),
      value: ALLOCATION_TYPES.TENANT,
    });
  }

  types.push({
    label: tl(translations.bankTransactionAllocation.allocationTypes.serviceCompany),
    value: ALLOCATION_TYPES.SERVICE_COMPANY,
  });

  if (propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.MV) || propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.SEV) || propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.SEV_MV)) {
    types.push({
      label: tl(translations.bankTransactionAllocation.allocationTypes.prpOwner),
      value: ALLOCATION_TYPES.PRP_OWNER,
    });
  }

  return {
    types,
    ...allocationTypeSelectorContext,
  };
};
