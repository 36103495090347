import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { FindFilteredPropertiesUsingGETAdministrationTypesEnum } from 'api/accounting';
import { ProfitAndLossFilter, ProfitAndLossReportListContext } from './ProfitAndLossReportListContext';

export const useProfitAndLossReportListFilters = () => {
  const { tl } = useContext(LanguageContext);
  const profitAndLossReportContext = useContext(ProfitAndLossReportListContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useProfitAndLossReportListFilters must be used within a ProfitAndLossReportListContextProvider');
  }

  const { filterState, setFilterState } = profitAndLossReportContext;

  const onChangeFilterState = (key: keyof ProfitAndLossFilter, value: ProfitAndLossFilter[keyof ProfitAndLossFilter]) => {
    setFilterState((prev) => {
      if (value === null) {
        return ({
          ...prev,
          [key]: undefined,
        });
      }
      return ({
        ...prev,
        [key]: value,
      });
    });
  };

  const updateFilterState = (data: object) => {
    setFilterState((state: any) => ({
      ...state,
      ...data,
    }));
  };

  const filters: Filter[] = useMemo(() => [
    {
      type: 'text',
      name: tl(translations.table.name),
      key: 'name',
    },
    {
      type: 'text',
      name: tl(translations.table.propertyIdInternal),
      key: 'propertyIdInternal',
    },
    {
      type: 'text',
      name: tl(translations.table.propertyName),
      key: 'propertyName',
    },
    {
      type: 'text',
      name: tl(translations.table.accountant),
      key: 'accountant',
    },
    {
      type: 'text',
      name: tl(translations.table.administrator),
      key: 'administrator',
    },
    {
      type: 'text',
      name: tl(translations.table.createdBy),
      key: 'createdBy',
    },
    {
      type: 'date',
      name: tl(translations.filters.minCreated),
      key: 'minCreated',
    },
    {
      type: 'date',
      name: tl(translations.filters.maxCreated),
      key: 'maxCreated',
    },
    {
      type: 'enum',
      name: `${tl(translations.filters.propertyAdministrationTypes)}`,
      key: 'administrationTypes',
      options: Object.keys(FindFilteredPropertiesUsingGETAdministrationTypesEnum).filter(a => a !== 'WEG').map(value => ({
        label: tl(translations.filters.administrationTypeValues[value]),
        value,
      })),
      multiValue: true,
      visibleAllOptions: true,
    },
  ], []);

  return {
    filters,
    filterState,
    updateFilterState,
    onChangeFilterState,
    defaultFilters: profitAndLossReportContext.defaultFilter,
  };
};
