import {
  AccountControllerApi, BankDetailsControllerApi, ContractLegacyControllerApi, DirectDebitControllerApi, FindFilteredUsingGET1PaymentStatesEnum, FindFilteredUsingGETStatesEnum, GetContractAccountBalancesUsingGETOrderEnum, PaymentControllerApi,
} from 'api/accounting/apis';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useRef } from 'react';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  groupBy, isEmpty, isNil, maxBy, uniq,
} from 'lodash';
import { ContractProjectionDtoDunningLevelEnum, PaymentProjectionDto } from 'api/accounting';
import { compareAccountCodes } from 'lib/Utils';
import { useQueryStringSplitter } from 'services/useQueryStringSplitter';
import { useOrderOpenBalancesContext, useOrderOpenBalancesSelectionContext } from './OrderOpenBalancesContext';
import { mapFESortColumnToBESortColumn } from './useOrderOpenBalanceListSort';
import { getInitialDunningFeeNet, getNewAndOldPropertyBalancesMerged } from './useOrderOpenBalancesList';
import {
  AccountBalanceGroupData,
  DebtorBalancesGroupedType, DunningLevelEnum, OpenBankOrdersAndFutureBalance, PropertyContractsDebtorBalances, PropertyListWithOpenAccounts,
} from './interfaces';
import { orderOpenBalancesTranslations } from '../../translations';
import { getAllKeys } from './useOrderOpenBalancesCheckboxes';

const MAX_PAGE_SIZE = 10000;
const PAGE_SIZE = 20;

type OpenBalancesType = Array<{
    propertyId: number,
    bankAccountId?: number,
    contractId: number,
    todaysAccountBalance: number,
    overallAccountBalance: number,
  }>;


const groupByLevel = (
  balances: PropertyContractsDebtorBalances[],
  propertyId: number,
  propertyHrId: string,
  propertyIdInternal: string,
  level: number,
) => {
  const groupedAtCurrentLevel = groupBy(balances, item => item?.accountCode?.split('/').splice(0, level + 1).join('/'));


  const withChildrenGroupedRecursively: AccountBalanceGroupData[] = Object.entries(groupedAtCurrentLevel)
    .map(([key, childAccounts]) => {
      // Sinlge account on the last level => leaf
      if (childAccounts.length === 1 && childAccounts[0].accountCode === key) {
        return {
          ...childAccounts[0],
          propertyId,
          propertyIdInternal,
          propertyHrId,
          id: `${propertyId}-${childAccounts[0].accountCode}`,
        };
      }

      const childAccountsGrouped = groupByLevel(childAccounts, propertyId, propertyHrId, propertyIdInternal, level + 1);
      childAccountsGrouped.sort((a, b) => {
        if (!a && !b) return 0;
        if (!a) return -1;
        if (!b) return 1;

        return compareAccountCodes(a.accountCode, b.accountCode);
      });

      const accountBalanceGroupData: AccountBalanceGroupData = {
        contractId: childAccounts[0].contractId,
        contractHasMandate: childAccounts[0].contractHasMandate,
        accountCode: key,
        overallAccountBalance: childAccounts.reduce((acc, item) => acc + item.overallAccountBalance, 0),
        todaysAccountBalance: childAccounts.reduce((acc, item) => acc + item.todaysAccountBalance, 0),
        filteredAccountBalance: childAccounts.reduce((acc, item) => acc + item.filteredAccountBalance, 0),
        overallAccountVatBalance: childAccounts.reduce((acc, item) => acc + item.overallAccountVatBalance, 0),
        todaysAccountVatBalance: childAccounts.reduce((acc, item) => acc + item.todaysAccountVatBalance, 0),
        filteredAccountVatBalance: childAccounts.reduce((acc, item) => acc + item.filteredAccountVatBalance, 0),
        children: childAccountsGrouped,
        id: `${propertyId}-${key}`,
        propertyId,
        propertyHrId,
        propertyIdInternal,
        bankAccountId: childAccounts[0].bankAccountId,
        contractDunningLevel: childAccounts[0].contractDunningLevel,
        unitRank: childAccounts[0].unitRank,
        lastDunningState: childAccounts[0].lastDunningState,
        lastDunningDate: childAccounts[0].lastDunningDate,
        lastDunningId: childAccounts[0].lastDunningId,
      };

      return accountBalanceGroupData;
    });
  return withChildrenGroupedRecursively;
};

export const useOnLoadPropertyAndContractBalances = () => {
  const { tl } = useContext(LanguageContext);
  const { splitRequestParam } = useQueryStringSplitter();

  const { apiConfiguration } = useContext(AuthContext);
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const bankDetailsController = new BankDetailsControllerApi(apiConfiguration('accounting'));
  const directDebitControllerApi = new DirectDebitControllerApi(apiConfiguration('accounting'));
  const paymentControllerApi = new PaymentControllerApi(apiConfiguration('accounting'));


  const {
    propertyAndContractBalanceList,
    setPropertyAndContractBalanceList,
    setAccounts,
    setContractDunningLevels,
    orderOpenbalanceFilterState,
    sortState,
    setOpenBankOrdersAndFutureBalance,
    setDunningFees,
  } = useOrderOpenBalancesContext('useOrderOpenBalancesList');

  const {
    innerTableSelectedRowKeysTotal,
    outerTableSelectedRowKeysTotal,
    setInnerTableSelectedRowKeysCurrent,
    setOuterTableSelectedRowKeysCurrent,
    setExpandedRowKeys,
    setCachedPropertyBalances,
  } = useOrderOpenBalancesSelectionContext('useOrderOpenBalancesList');

  const openBalanceListAbortController = useRef<AbortController | undefined>(undefined);

  const onLoadPropertyAndContractBalancesAllPages = () => {
    // don't reload the data if the last page was loaded
    if (propertyAndContractBalanceList.lastPage) {
      return Promise.resolve(propertyAndContractBalanceList.data);
    }

    return onLoadPropertyAndContractBalances(true, MAX_PAGE_SIZE);
  };

  const mapToDunningFees = (arr: PropertyListWithOpenAccounts[] = []) => arr?.map(({ debtorBalancesGrouped, ...e }) => {
    const commonProperties = {
      propertyId: e.propertyId,
      propertyHrId: e?.propertyHrId,
    };

    return [
      // property level
      {
        ...commonProperties,
        dunningFeeNet: e?.dunningFeeNet,
      },
      // contract level
      ...debtorBalancesGrouped.map(db => ({
        accountCode: db.accountCode,
        contractId: db.contractId,
        dunningFeeNet: getInitialDunningFeeNet(db.filteredAccountBalance, db.contractHasMandate, e?.dunningFeeNet),
        ...commonProperties,
      })),
    ];
  }).flat();


  const onLoadPropertyAndContractBalances = (resetPage: boolean = false, pageSize: number = PAGE_SIZE) => {
    setPropertyAndContractBalanceList(prev => prev.startLoading());

    // if params changed since last initiated fetch then abort the in-progress fetch
    openBalanceListAbortController.current?.abort();
    // create new abort controller
    openBalanceListAbortController.current = new AbortController();
    const { signal } = openBalanceListAbortController.current;

    return contractControllerApi.getContractAccountBalancesUsingGET({
      ...orderOpenbalanceFilterState,
      page: resetPage ? 0 : propertyAndContractBalanceList.page,
      size: pageSize,
      sort: mapFESortColumnToBESortColumn(sortState.field),
      order: sortState.order > 0 ? GetContractAccountBalancesUsingGETOrderEnum.ASC : GetContractAccountBalancesUsingGETOrderEnum.DESC,
    }, { signal })
      .then((response) => {
        let dunningFeeList = [];
        const transformed: PropertyListWithOpenAccounts[] = response.content
          // Filter out debtorBalances of weg owned units and recalculate todaysPropertyBalance.
          // TODO PMP-21494: remove map when WEG owned unit tenant allocation is in place
          .map((item) => {
            const parsedBalances: PropertyContractsDebtorBalances[] = JSON.parse(item.debtorBalances);
            const notWegOwnedUnitBalances = parsedBalances.filter(debtorBalace => !(item.propertyAdministrationType === 'WEG' && debtorBalace.accountCode.startsWith('2001')));
            return {
              ...item,
              debtorBalances: !isEmpty(notWegOwnedUnitBalances) ? JSON.stringify(notWegOwnedUnitBalances) : undefined,
              todaysPropertyBalance: notWegOwnedUnitBalances.reduce((acc, parsedBalance) => acc + parsedBalance.todaysAccountBalance, 0),
              filteredPropertyBalance: notWegOwnedUnitBalances.reduce((acc, parsedBalance) => acc + parsedBalance.filteredAccountBalance, 0),
            };
          })
          // Filter out properties where today's balance is 0. (Needed because today's balance was recalculated because of filtered out weg owned units)
          // TODO PMP-21494: remove filter when WEG owned unit tenant allocation is in place
          .filter(item => !isEmpty(item.debtorBalances))

          .map((item) => {
            const parsedBalances: PropertyContractsDebtorBalances[] = JSON.parse(item.debtorBalances);
            const groupingResult = groupByLevel(parsedBalances, item.propertyId, item.propertyHrId, item.propertyIdInternal, 1);


            const debtorBalancesGrouped: DebtorBalancesGroupedType[] = groupingResult.map(group => ({
              ...group,
              isRootAccount: true,
              contractId: group.contractId ?? group.children?.[0]?.contractId,
              unitRank: group.unitRank ?? group.children?.[0]?.unitRank,
              bankAccountId: group.bankAccountId ?? group.children?.[0]?.bankAccountId,
            })).sort((a, b) => a.unitRank - b.unitRank);


            return ({
              ...item,
              // this can't be called `children`, otherwise the SmartTable will try to render it's children
              // but we're already taking care of that with the inner table
              debtorBalancesGrouped,
              // cache the keys to not recalculate it on every checkbox change
              allKeysOfDebtorBalancesGrouped: getAllKeys(debtorBalancesGrouped),
              // the latest dunning date for any contract in this property;
              // we cache it here to not recalculate it on every render
              maximumLastDunningDate: maxBy(debtorBalancesGrouped, 'lastDunningDate')?.lastDunningDate,
            });
          });

        if (transformed && transformed.length) {
          const propertyIds = transformed.map(db => db.propertyId);
          onLoadAccounts(propertyIds);
          initializeContractDunningLevels(transformed);


          // map dunningFees
          dunningFeeList = mapToDunningFees(transformed);


          onLoadOpenBankOrders(transformed);
        }

        if (resetPage) {
          setExpandedRowKeys([]);

          // we need to add the balances selected in the current view to the cached balances if the page will be reset
          setCachedPropertyBalances((prevCachedBalances) => {
            const balancesSelectedInCurrentView = propertyAndContractBalanceList.data
              .filter(({ propertyId }) => outerTableSelectedRowKeysTotal.includes(propertyId));
            // from the cached balances we keep only those that are still selected.
            // e.g. a balance can match multiple filters, and if it was present and selected on the previous view,
            // but it is also present in the current view and we unselect it, then we need to remove it from the cached balances
            const previouslySelectedBalancesThatAreStillSelected = prevCachedBalances
              .filter(({ propertyId }) => outerTableSelectedRowKeysTotal.includes(propertyId));

            // Filter out potential duplicates:
            return getNewAndOldPropertyBalancesMerged(balancesSelectedInCurrentView, previouslySelectedBalancesThatAreStillSelected);
          });

          const keysOfNewlyLoadedOuterTableItems = transformed.map(item => item.propertyId);
          const keysOfNewlyLoadedInnerTableItems = transformed.flatMap(prp => getAllKeys(prp.debtorBalancesGrouped));

          const newInnerTableSelectedRowKeysCurrent = keysOfNewlyLoadedInnerTableItems.filter(key => innerTableSelectedRowKeysTotal.includes(key));
          setInnerTableSelectedRowKeysCurrent(newInnerTableSelectedRowKeysCurrent);
          setOuterTableSelectedRowKeysCurrent(
            keysOfNewlyLoadedOuterTableItems.filter(prpId => (
              outerTableSelectedRowKeysTotal.includes(prpId)
                  && newInnerTableSelectedRowKeysCurrent.some(innerKey => innerKey.startsWith(`${prpId}-`))
            )),
          );
        } else {
          // add keys from Total to Current if they are present on this newly loaded page
          const keysOfNewlyLoadedOuterTableItems = transformed.map(item => item.propertyId);
          const keysOfNewlyLoadedInnerTableItems = transformed.flatMap(prp => getAllKeys(prp.debtorBalancesGrouped));
          setInnerTableSelectedRowKeysCurrent((prevInnerKeys) => {
            const newInnerTableSelectedRowKeysCurrent = [
              ...prevInnerKeys,
              ...keysOfNewlyLoadedInnerTableItems.filter(key => innerTableSelectedRowKeysTotal.includes(key)),
            ];


            setOuterTableSelectedRowKeysCurrent(prevOuterKeys => ([
              ...prevOuterKeys,
              ...keysOfNewlyLoadedOuterTableItems.filter(key => (
                outerTableSelectedRowKeysTotal.includes(key)
                    && newInnerTableSelectedRowKeysCurrent.some(innerKey => innerKey.startsWith(`${key}-`))
              )),
            ]));


            return newInnerTableSelectedRowKeysCurrent;
          });
        }


        setPropertyAndContractBalanceList(prev => prev.loadPaged(transformed, resetPage, response.last));


        if (!isEmpty(dunningFeeList)) {
          setDunningFees(prevDunningState => prevDunningState.concat(dunningFeeList));
        }
        return Promise.resolve(transformed);
      }).catch((error) => {
        if (signal?.aborted) return;
        console.error(error);
        setPropertyAndContractBalanceList(listState => (listState.failed()));
        showNotification({
          key: 'loadOpenBalanceError',
          message: tl(orderOpenBalancesTranslations.notifications.loadListError.message),
          type: 'error',
        });
        return Promise.reject();
      });
  };

  const initializeContractDunningLevels = (propertyDebtorBalances: PropertyListWithOpenAccounts[]) => {
    const initialDunningLevels = [];
    propertyDebtorBalances.forEach(propertyDebtorBalance => propertyDebtorBalance.debtorBalancesGrouped.forEach((debtorBalance) => {
      const { contractId } = debtorBalance.children ? debtorBalance.children[0] : debtorBalance;
      const { contractHasMandate } = debtorBalance.children ? debtorBalance.children[0] : debtorBalance;
      const balance = debtorBalance.filteredAccountBalance;

      // initialize dunning level
      let dunningLevel;
      if (!debtorBalance.contractDunningLevel) {
        if (balance < 0) {
          dunningLevel = DunningLevelEnum.PAY_OUT;
        } else if (contractHasMandate) {
          dunningLevel = DunningLevelEnum.SEPA_DIRECT_DEBIT;
        } else {
          dunningLevel = DunningLevelEnum.PAYMENT_REMINDER;
        }
      } else if (debtorBalance.contractDunningLevel === ContractProjectionDtoDunningLevelEnum.LEVEL_1) {
        dunningLevel = DunningLevelEnum.DUNNING_NOTE;
      } else {
        // if currently is second or third => select third.
        dunningLevel = DunningLevelEnum.LAST_DUNNING_NOTE;
      }

      initialDunningLevels.push({
        contractId,
        dunningLevel,
      });
    }));
    setContractDunningLevels(existingDunningLevels => [...existingDunningLevels, ...initialDunningLevels]);
  };

  const onLoadAccounts = (propertyIds: number[]) => {
    setAccounts(prev => prev.startLoading());
    accountControllerApi.getAccountsMatchingRegexUsingGET({
      // eslint-disable-next-line no-useless-escape
      accountCodeRegex: '^(2000\\/\\d+\\/2|2001\\/\\d+\\/[01234]|200[01]\\/\\d+)$',
      propertyHrIds: [],
      propertyIds,
    })
      .then((resp) => {
        setAccounts(prev => prev.load([...prev.data, ...resp]));
      })
      .catch((err) => {
        console.error(err);
        setAccounts(prev => prev.failed());
        showNotification({
          key: 'loadContractsError',
          message: tl(orderOpenBalancesTranslations.notifications.loadAccountsError),
          type: 'error',
        });
      });
  };

  const onLoadOpenBankOrders = (propertyListWithOpenAccounts: PropertyListWithOpenAccounts[]) => {
    setOpenBankOrdersAndFutureBalance(prev => prev.startLoading());

    const propertyIdInternalList = propertyListWithOpenAccounts.map(prp => prp.propertyIdInternal);
    const managementCompanyIds = uniq(propertyListWithOpenAccounts.map(prp => prp.managementCompanyId));
    const bankAccountIds = uniq(propertyListWithOpenAccounts.flatMap(prp => prp.debtorBalancesGrouped.map(db => db.bankAccountId)).filter(id => !isNil(id)));


    const paymentRequests = managementCompanyIds.map(managementCompanyId => (
      paymentControllerApi.findFilteredUsingGET1({
        managementCompanyId,
        propertyIdInternalList,
        paymentStates: FindFilteredUsingGET1PaymentStatesEnum.NEW,
        size: 999999,
      })
    ));


    const bankAccountIdSplitRequests = splitRequestParam(bankAccountIds, 'bankAccountIds').map(
      bankAccountIdSplit => bankDetailsController.getBankDetailsForContactsUsingGET({ contactIds: [], bankAccountIds: bankAccountIdSplit }),
    );

    const bankAccounDetailsPromises = Promise.all(bankAccountIdSplitRequests);

    Promise.allSettled([
      directDebitControllerApi.findFilteredUsingGET({ propertyIdInternalList, states: FindFilteredUsingGETStatesEnum.NEW, size: 999999 }),
      bankAccounDetailsPromises,
      ...paymentRequests,
    ])
      .then(([directDebitResponse, bankAccountResponses, ...paymentResponse]) => {
        if (directDebitResponse.status === 'rejected' || bankAccountResponses.status === 'rejected' || paymentResponse.some(resp => resp.status === 'rejected')) {
          showNotification({
            type: 'error',
            message: tl(orderOpenBalancesTranslations.notifications.checkOpenOrdersError),
          });
          setOpenBankOrdersAndFutureBalance(prev => prev.failed());
          return;
        }

        const bankAccountDetails = bankAccountResponses.value.map(r => r).flat();

        const openBalances: OpenBalancesType = propertyListWithOpenAccounts
          .flatMap(prp => prp.debtorBalancesGrouped.map(db => ({
            propertyId: prp.propertyId,
            bankAccountId: db.bankAccountId,
            contractId: db.contractId,
            todaysAccountBalance: db.todaysAccountBalance,
            overallAccountBalance: db.overallAccountBalance,
          })));


        const directDebits = directDebitResponse.value.content;
        // @ts-ignore
        const payments = paymentResponse.map(resp => resp.value.content).flat() as Array<PaymentProjectionDto>;


        const openBankOrdersAndFutureBalance: OpenBankOrdersAndFutureBalance = new Map();

        openBalances.forEach(({
          propertyId, contractId, bankAccountId, todaysAccountBalance, overallAccountBalance,
        }) => {
          const managementCompanyId = propertyListWithOpenAccounts.find(prp => prp.propertyId === propertyId)?.managementCompanyId;
          const ibanForThisContract = bankAccountDetails.find(ba => ba.id === bankAccountId)?.iban;

          /**
           * Sometimes the PAY/DD doesn't have a contractId (e.g. if created on Payment page), but
           * if the iban matches and contractId is not set, then we can assume that it's for this contract
           */
          const directDebitsForContract = directDebits.filter(dd => (
            dd.contractId === contractId
            || (isNil(dd.contractId) && ibanForThisContract && dd.counterpartIban === ibanForThisContract)
          ));
          const paymentsForContract = payments.filter(p => (
            p.contractId === contractId
            || (isNil(p.contractId) && ibanForThisContract && p.counterpartIban === ibanForThisContract)));

          const sumDirectDebits = directDebitsForContract.reduce((acc, item) => acc + item.amount, 0);
          const sumPayments = paymentsForContract.reduce((acc, item) => acc + item.amount, 0);

          if (sumDirectDebits === 0 && sumPayments === 0 && (todaysAccountBalance - overallAccountBalance) === 0) {
            return;
          }

          openBankOrdersAndFutureBalance.set(contractId, {
            managementCompanyId,
            contractIban: ibanForThisContract,
            sumDirectDebits,
            sumPayments,
            directDebits,
            payments,
            todaysAccountBalance,
            overallAccountBalance,
          });
        });
        // @ts-ignore
        setOpenBankOrdersAndFutureBalance(prev => prev.load(new Map([...prev.data, ...openBankOrdersAndFutureBalance])));
      });
  };

  return {
    onLoadPropertyAndContractBalances,
    onLoadPropertyAndContractBalancesAllPages,
  };
};
