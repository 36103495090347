import { useContext, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from 'lib/Utils';
import { BreadcrumbInterface } from '../../components/Breadcrumbs/Breadcrumbs';
import { translations } from '../../../../translations';
import { GlobalSearchFilterProps } from '../useGlobalSearch';
import { Filter } from '../GlobalSearchContext';

export const useBreadcrumbs = () => {
  const { tl } = useContext(LanguageContext);

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInterface[]>([]);
  const breadcrumbsRef = useRef<BreadcrumbInterface[]>([]);
  breadcrumbsRef.current = breadcrumbs;

  const addDefaultBreadcrumbs = (filterProps: GlobalSearchFilterProps) => {
    // add default filters to breadcrumb
    // wait until page is added to set the filter afterwards
    const tip = setInterval(() => {
      if (breadcrumbsRef.current.length > 0) {
        clearInterval(tip);
        Object.keys(filterProps.defaultFilters).forEach((filterKey) => {
          const availableFilter = filterProps.availableFilters.filter(avFilter => avFilter.key === filterKey)[0];
          if (!availableFilter) return;
          if (_.isArray(filterProps.defaultFilters[filterKey])) {
            filterProps.defaultFilters[filterKey].forEach((value: any) => {
              addBreadcrumb({
                ...availableFilter,
                matchingValue: value,
                breadcrumbType: 'FILTER',
                translatedValue: getTranslatedValue(availableFilter, value),
              } as BreadcrumbInterface);
            });
          } else {
            addBreadcrumb({
              ...availableFilter,
              breadcrumbType: 'FILTER',
              translatedValue: getTranslatedValue(availableFilter, filterProps.defaultFilters[filterKey]),
              matchingValue: filterProps.defaultFilters[filterKey],
            } as BreadcrumbInterface);
          }
        });
      }
    }, 100);
  };

  const addBreadcrumb = (breadcrumb: BreadcrumbInterface) => {
    setBreadcrumbs((currentBreadcrumbs: BreadcrumbInterface[]) => {
      const newBreadcrumbs = _.cloneDeep(currentBreadcrumbs);
      newBreadcrumbs.push(breadcrumb);
      return newBreadcrumbs;
    });
  };

  const replaceBreadcrumb = (index: number, breadcrumb: BreadcrumbInterface) => {
    setBreadcrumbs((currentBreadcrumbs: BreadcrumbInterface[]) => {
      const newBreadcrumbs = _.cloneDeep(currentBreadcrumbs);
      newBreadcrumbs[index] = breadcrumb;
      return newBreadcrumbs;
    });
  };

  const removeBreadcrumb = (predicate: (breadcrumb: BreadcrumbInterface) => boolean) => {
    setBreadcrumbs((currentBreadcrumbs: BreadcrumbInterface[]) => {
      const newBreadcrumbs = _.cloneDeep(currentBreadcrumbs);

      const indexToRemove = newBreadcrumbs.findIndex(predicate);
      newBreadcrumbs.splice(indexToRemove, 1);

      return newBreadcrumbs;
    });
  };

  const getTranslatedValue = (availableFilter: Filter, value: any) => {
    let translatedValue = '';
    if (availableFilter.type === 'enum') {
      translatedValue = availableFilter.options!.filter((option: SelectOption) => option.value === value || option.value === String(value))[0].label;
    } else if (availableFilter.type === 'boolean') {
      translatedValue = tl(translations.searchBar.filter.booleanValues[value]);
    } else if (availableFilter.type === 'date') {
      const momentValue = moment.utc(value);
      translatedValue = momentValue.format(DATE_FORMAT);
    } else {
      translatedValue = value;
    }
    return translatedValue;
  };

  return {
    breadcrumbs, setBreadcrumbs, addDefaultBreadcrumbs, addBreadcrumb, removeBreadcrumb, replaceBreadcrumb,
  };
};
