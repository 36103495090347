import ColorDot, { DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { DirectDebitProjectionDtoStateEnum } from 'api/accounting';
import { DirectDebitTableData } from '../interfaces';

const mapSTateToColor = (state: DirectDebitProjectionDtoStateEnum) => {
  switch (state) {
  case DirectDebitProjectionDtoStateEnum.NEW:
    return DotColorEnum.BLUE;
  case DirectDebitProjectionDtoStateEnum.DELETED:
    return DotColorEnum.GRAY;
  case DirectDebitProjectionDtoStateEnum.SENT:
    return DotColorEnum.GREEN;
  default: return DotColorEnum.GRAY;
  }
};

export const DirectDebitStateCell = ({ record } : { record: DirectDebitTableData }) => (
  <ColorDot color={mapSTateToColor(record.state)}>
    <span>{record.translatedState}</span>
  </ColorDot>
);
