import { PropertyDisplayDto, PropertyDisplayDtoVatRelevanceEnum, UnitContractControllerApi, UnitLegacyControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import moment from 'moment';
import { useContext } from 'react';
import { calculateEligibilityShareValue, getActiveVatRelevantUnits } from 'services/vatEligibility/vatEligibilityUtils';

export const useGetVatEligibilityForProperty = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const unitsControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));

  const getVatEligibilityShareForProperty = (property: PropertyDisplayDto) => {
    if (property?.vatRelevance === PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT) return Promise.resolve(100);
    if (property?.vatRelevance === PropertyDisplayDtoVatRelevanceEnum.NOT_RELEVANT) return Promise.resolve(undefined);

    const contractPromise = unitContractControllerApi.getUnitContractsUsingGET({
      propertyId: property?.id,
      atDate: moment().format('YYYY-MM-DD'),
    });

    const unitPromise = property?.id
      ? unitsControllerApi.getSimpleUnitsUsingGET({ propertyId: property?.id })
      : Promise.resolve([]);

    return Promise.all([contractPromise, unitPromise])
      .then(([unitContracts, units]) => {
        const activeContracts = unitContracts.filter(contract => contract.unitContractId !== undefined);
        const vatRelevantUnits = getActiveVatRelevantUnits(units, activeContracts);
        const eligibilityShare = calculateEligibilityShareValue(property, units, vatRelevantUnits);

        return Promise.resolve(eligibilityShare);
      })
      .catch((err) => {
        console.error(err);
        // this value will only be used as a default, would not block process
        return Promise.resolve(undefined);
      });
  };

  return {
    getVatEligibilityShareForProperty,
  };
};
