import { Icon } from '@material-ui/core';
import { Alert } from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { watermarkTranslations } from '../../translations';

export default function UploadWatermarkInfo(): JSX.Element {
  const { tl } = useContext(LanguageContext);

  return (
    <Alert
      message={(
        <>
          {tl(watermarkTranslations.infoPart1)}
          <a href="https://impower.atlassian.net/servicedesk/customer/kb/view/2411593762" target="_blank" rel="noreferrer">{tl(watermarkTranslations.readTutorial)}</a>
          {tl(watermarkTranslations.infoPart2)}
          <a target="_blank" href="/agbs/Briefpapier_Vorlage.docx">{tl(watermarkTranslations.downloadTemplate)}</a>
          {tl(watermarkTranslations.infoPart3)}
        </>
      )}
      type="info"
      showIcon
      icon={<Icon component={ICONS.info} />}
    />
  );
}
