import { useContext, useRef } from 'react';
import { useLocation } from 'react-router';
import { translations } from 'elements/Translation/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import Amount from 'storybook-components/typography/Amount/Amount';


export const useResolutionRecordOverviewColumns = () => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation();

  const pathName = useRef(location.pathname);
  pathName.current = location.pathname;

  return [
    {
      title: tl(translations.pages.resolutionRecordOverview.table.headers.serialNumber),
      dataKey: 'resolutionRecordNumber',
      defaultWidth: '10%',
      render: (amount: number) => (
        <Amount>{amount}</Amount>
      ),
    },
    {
      title: tl(translations.pages.resolutionRecordOverview.table.headers.title),
      dataKey: 'resolutionTitle',
    },
    {
      title: tl(translations.pages.resolutionRecordOverview.table.headers.date),
      dataKey: 'date',
      format: FORMAT.DATE,
    },
    {
      title: tl(translations.pages.resolutionRecordOverview.table.headers.status),
      dataKey: 'resolutionRecordStatus',
    },
  ];
};
