import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { mergeListsRemovingDuplicates } from 'lib/utilMergeListsRemovingDuplicates';
import DEFAULT_DATA, { DefaultDataInterface } from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from './LanguageContext';
import { showNotification } from '../lib/Notification';

export const StandingOrderContext: any = React.createContext({});


export default function StandingOrderContextProvider({ children }: any): JSX.Element {
  const PAGE_SIZE = 30;
  const { tl } = useContext(LanguageContext);

  const initialSortChange = useRef(true);
  const [sort, setSort] = useState({
    field: 'propertyHrId',
    order: 1,
  });

  const defaultFilter: any = {
    showStopped: false,
  };

  const [standingOrders, setStandingOrders] = useState(DEFAULT_DATA<any>([]));

  const [filterState, setFilterState] = useState<any>(defaultFilter);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const lastRequestTimestamp = useRef<number | null>(null);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadStandingOrderList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState]);

  useEffect(() => {
    if (!initialSortChange.current) {
      onLoadStandingOrderList(true);
    } else {
      initialSortChange.current = false;
    }
  }, [sort]);

  const setSortField = (field: string) => {
    setSort((oldOrder: any) => {
      const newOrder = {
        ...oldOrder,
        field,
        order: oldOrder.field === field ? oldOrder.order * (-1) : 1,
      };
      return newOrder;
    });
  };

  const updateFilterState = (data: object) => {
    setFilterState({
      ...filterState,
      ...data,
    });
  };

  const onStopStandingOrder = (standingOrderId: string) => {
    backend.post(`${endpointUrls.STANDING_ORDER}/stop/${standingOrderId}`, {})
      .then(() => {
        const oldStandingOrderList = standingOrders.data.slice();
        const index = oldStandingOrderList.findIndex((so: any) => so.id === standingOrderId);
        oldStandingOrderList.splice(index, 1);
        setStandingOrders(standingOrders.load(oldStandingOrderList));
        showNotification({
          key: 'stopStandingOrderSuccess',
          message: tl(translations.notifications.standingOrderContext.stopSuccess.message),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'stopStandingOrderError',
          message: tl(translations.notifications.standingOrderContext.stopError.message),
          type: 'error',
        });
      });
  };

  const onStopMultipleStandingOrders = (ids: string[]) => {
    backend.post(`${endpointUrls.STANDING_ORDER}/stop-by-ids`, ids)
      .then(() => {
        setStandingOrders((oldStandingOrderList: DefaultDataInterface<any>) => {
          const newStandingOrders = oldStandingOrderList.data.slice();
          if (filterState && filterState.showStopped) {
            ids.forEach((id) => {
              const index = newStandingOrders.findIndex((so: any) => so.id === id);
              newStandingOrders[index].stopped = true;
            });
          } else {
            ids.forEach((id) => {
              const index = newStandingOrders.findIndex((so: any) => so.id === id);
              newStandingOrders.splice(index, 1);
            });
          }
          return oldStandingOrderList.load(newStandingOrders);
        });
        showNotification({
          key: 'stopStandingOrderSuccess',
          message: tl(translations.notifications.standingOrderContext.stopSuccess.message),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'stopStandingOrderError',
          message: tl(translations.notifications.standingOrderContext.stopError.message),
          type: 'error',
        });
      });
  };

  const onLoadStandingOrderList = (resetPage: boolean = false) => {
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setStandingOrders(standingOrders.startLoading());
    backend.get(`${endpointUrls.STANDING_ORDER}`, {
      ...filterState,
      page: resetPage ? 0 : standingOrders.page,
      size: PAGE_SIZE,
      order: sort.order > 0 ? 'ASC' : 'DESC',
      sort: sort.field,
    })
      .then((response: any) => {
        // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        setStandingOrders(prev => prev.loadPagedNoConcat(
          mergeListsRemovingDuplicates<any>(prev.data, response.content, 'id', resetPage),
          resetPage,
          response.last,
        ));
      })
      .catch((ex) => {
        console.error(ex);
        setStandingOrders(standingOrders.failed());
        showNotification({
          key: 'loadStandingOrdersError',
          message: tl(translations.notifications.standingOrderContext.loadError.message),
          description: tl(translations.notifications.standingOrderContext.loadError.description),
          type: 'error',
        });
      });
  };


  const onClearStandingOrderList = () => {
    setStandingOrders(standingOrders.load([]));
  };

  const onClearFilterState = () => {
    setFilterState(defaultFilter);
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  return (
    <StandingOrderContext.Provider
      value={{
        onClearStandingOrderList,
        onClearFilterState,
        onLoadStandingOrderList,
        onStopStandingOrder,
        onStopMultipleStandingOrders,
        onSetDefaultFilterFromQueryParams,
        standingOrders,
        filterState,
        setFilterState,
        sortField: sort.field,
        sortOrder: sort.order,
        setSortField,
        updateFilterState,
      }}
    >
      {children}
    </StandingOrderContext.Provider>
  );
}
