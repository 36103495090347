import { Resizable } from 'react-resizable';
import React from 'react';
import './ResizableColumn.scss';

export default function ResizeableColumn(props: any): JSX.Element {
  const {
    onResize, width, ...restProps
  } = props;

  return (
    <Resizable
      // Add a default (if there is none or given in %)d as the initial value and after that it will be overwritten anyways with the correct one.
      width={!width || typeof width === 'string' ? 250 : width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
}
