import _ from 'lodash';
import { Redirect, useLocation } from 'react-router';
import { PROFIT_AND_LOSS_REPORT_CREATE_PATH } from '../routes';

export const ProfitAndLossReportInstantCalculatePage = () => {
  const searchKeyToAppend = 'instantCalculate';
  const searchValueToAppend = 'true';

  const { search } = useLocation();

  let newUrl = PROFIT_AND_LOSS_REPORT_CREATE_PATH + search;

  if (_.isEmpty(search)) {
    newUrl += `?${searchKeyToAppend}=${searchValueToAppend}`;
  } else if (!search.includes(searchKeyToAppend)) {
    newUrl += `&${searchKeyToAppend}=${searchValueToAppend}`;
  }

  return <Redirect to={newUrl} />;
};
