import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../../elements/Translation/translations';
import { FORMAT } from '../../../elements/SmartTable/SmartTable';

export const useBankAccountListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.property.table.headers.propertyHrId),
      dataKey: 'propertyHrId',
    },
    {
      title: tl(translations.pages.property.table.headers.internalNr),
      dataKey: 'propertyIdInternal',
    },
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'name',
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.administrator),
      dataKey: 'supervisorName',
      sortable: false,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.calculatedHouseMoneyBalance),
      dataKey: 'calculatedHouseMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.houseMoneyBalance),
      dataKey: 'houseMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.calculatedReserveMoneyBalance),
      dataKey: 'calculatedReserveMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.reserveMoneyBalance),
      dataKey: 'reserveMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.calculatedTotalBalance),
      dataKey: 'calculatedTotalBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.totalBalance),
      dataKey: 'totalBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
  ];
};
