import { PropertyLegacyDtoPropertyStateEnum } from 'api/accounting';
import useDefaultPropertyFilters from 'components/Header/components/data/useDefaultPropertyFilters';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { PropertyWegAndMvAndSevMvAdministrationTypes } from 'lib/utilMapPropertyAdministrationTypeToFrontend';
import { useContext } from 'react';


export default function useDistributionKeyFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    ...useDefaultPropertyFilters(false),
    {
      type: 'enum',
      name: `${tl(translations.pages.distributionKeyTable.filters.administrationType)}`,
      key: 'administrationTypes',
      options: Object.keys(PropertyWegAndMvAndSevMvAdministrationTypes).map(value => ({
        label: tl(translations.pages.distributionKeyTable.filters.administrationTypes[value]),
        value,
      })),
      multiValue: true,
      visibleAllOptions: true,
    },
    {
      type: 'text',
      name: tl(translations.pages.distributionKeyTable.table.headers.accountant),
      key: 'accountantName',
    },
    {
      type: 'text',
      name: tl(translations.pages.distributionKeyTable.table.headers.administrator),
      key: 'supervisorName',
    },
    {
      type: 'enum',
      name: tl(translations.pages.property.table.headers.status),
      key: 'propertyStates',
      options: Object.keys(PropertyLegacyDtoPropertyStateEnum).map(value => ({
        label: tl(translations.pages.property.table.propertyStates[value]),
        value,
      })),
      multiValue: true,
    },
  ];
}
