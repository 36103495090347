import { formatCurrency } from 'lib/Utils';
import { CellWithTwoAmountsProps } from './interfaces';

const getFormattedAmount = (showSignForPositiveAmount, amount) => {
  const sign = showSignForPositiveAmount && amount > 0 ? '+ ' : '';
  return `${sign}${formatCurrency(amount)}`;
};

export const useCellWithTwoAmounts = ({
  firstAmount, secondAmount,
}: CellWithTwoAmountsProps) => {
  const firstAmountConfig = typeof firstAmount === 'number'
    ? { amount: formatCurrency(firstAmount) }
    : { ...firstAmount, amount: getFormattedAmount(firstAmount.showSignForPositiveAmount, firstAmount.amount) };

  const secondAmountConfig = typeof secondAmount === 'number'
    ? { amount: formatCurrency(secondAmount) }
    : { ...secondAmount, amount: getFormattedAmount(secondAmount.showSignForPositiveAmount, secondAmount.amount) };


  return {
    firstAmountConfig,
    secondAmountConfig,
  };
};
