export const deleteUserModalTranslations = {
  deletionModal: {
    title: {
      en: 'Delete user',
      de: 'Benutzer löschen',
    },
    description: {
      en: 'After deleting the account, the user can no longer access the application.',
      de: 'Nach dem Löschen des Kontos kann der Benutzer nicht mehr auf die Anwendung zugreifen.',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
  },

  deleteUserError: {
    message: {
      en: 'Error deleting user.',
      de: 'Fehler beim Löschen des Benutzers.',
    },
  },
  deleteUserSuccess: {
    message: {
      en: 'User Deleted.',
      de: 'Benutzer gelöscht.',
    },
  },
};
