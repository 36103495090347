export const translations = {
  invoiceEditing: {
    title: {
      en: 'Invoice',
      de: 'Rechnung',
    },
    bookingsEditing: {
      save: {
        en: 'Save',
        de: 'Speichern',
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
    },
    back: {
      en: 'Back',
      de: 'Zurück',
    },
    saveAsDraft: {
      en: 'Save as draft',
      de: 'Als Entwurf speichern',
    },
    pay: {
      en: 'Ready for payment',
      de: 'Zur Zahlung freigeben',
    },
    approve: {
      en: 'Put to approved',
      de: 'Anlegen',
    },
    sections: {
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
      save: {
        en: 'Save and Continue',
        de: 'Speichern und Weiter',
      },
      section1: {
        title: {
          en: 'Invoice processing',
          de: 'Rechnungserfassung',
        },
        fields: {
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          invoiceNumber: {
            en: 'Invoice number',
            de: 'Beleg-/Rechnungs-Nr',
          },
          invoiceDate: {
            en: 'Invoice date',
            de: 'Beleg-/Rechnungs-Datum',
          },
          servicePeriod: {
            en: 'Service Period',
            de: 'Leistungsdatum/-zeitraum (von-bis)',
          },
          servicePeriodHidden: {
            en: 'Service Period',
            de: 'Leistungsdatum',
          },
        },
      },
      section2: {
        title: {
          en: 'Creditor',
          de: 'Rechnungssteller',
        },
        fields: {
          company: {
            en: 'Company',
            de: 'Firma oder Einzelperson',
          },
          bankAccount: {
            en: 'Bank account',
            de: 'Bankkonto',
          },
          smartSearch: {
            addMore: {
              en: 'Add more',
              de: 'Hinzufügen',
            },
          },
        },
      },
      section3: {
        title: {
          en: 'Cost assignment',
          de: 'Kostenzuordnung',
        },
        subsection1: {
          title: {
            en: 'Assignment to WEG/flat',
            de: 'Zuordnung WEG/Wohneinheit',
          },
          fields: {
            overwriteBookingDate: {
              en: 'Overwrite booking date',
              de: 'Buchungsdatum überschreiben',
            },
            bookingDate: {
              en: 'Booking date',
              de: 'Buchungsdatum',
            },
            incoming: {
              en: 'Incoming',
              de: 'Eingehend',
            },
            outgoing: {
              en: 'Outgoing',
              de: 'Ausgehend',
            },
            totalAmount: {
              en: 'Total amount gross',
              de: 'Gesamtbetrag brutto',
            },
          },
        },
        subsection2: {
          fields: {
            laborCostTypes: {
              NO_LABOR_COST: {
                en: 'No labor cost',
                de: 'Keine Lohnkosten',
              },
              HOUSEHOLD_RELATED_SERVICES: {
                en: 'Household related services',
                de: 'Haushaltsnahe DL',
              },
              TECHNICIAN_SERVICE: {
                en: 'Technician service',
                de: 'Dl.anteil Handwerkerrechnungen',
              },
              MARGINAL_EMPLOYMENT: {
                en: 'Marginal employment',
                de: 'Geringf. Beschäftigung',
              },
              INSURABLE_EMPLOYMENT: {
                en: 'Insurable employment',
                de: 'SV.pfl. Beschäftigung',
              },
            },
            wegLaborCostTypeSelect: {
              en: 'Labor cost type',
              de: 'Lohnkostenart',
            },
            wegLaborCostPercentage: {
              en: 'Labor cost in %',
              de: 'Lohnanteil in %',
            },
            wegLaborCostNet: {
              en: 'Labor cost Amount net',
              de: 'Lohnkosten netto',
            },
            wegLaborCostBrut: {
              en: 'Labor cost amount gross',
              de: 'Lohnkosten brutto',
            },
            addLaborCost: {
              en: 'Add labor cost',
              de: 'Lohnkosten eingeben',
            },
            vatAmount: {
              en: 'VAT amount',
              de: 'MwSt Betrag',
            },
            vatPercentage: {
              en: 'VAT in %',
              de: 'MwSt in %',
            },
            netAmount: {
              en: 'Net amount',
              de: 'Betrag netto',
            },
            grossAmount: {
              en: 'Gross amount',
              de: 'Betrag brutto',
            },
            vatEligibilityPercentage: {
              en: 'Eligible VAT share',
              de: 'Anteil anrechenb. USt.',
            },
            vatEligibilityAmount: {
              en: 'Eligible VAT amount',
              de: 'Anrechenbare USt.',
            },
            vatEligibilityTooltip: {
              en: 'Based on the preselected value for the property. This value can be changed manually here. A general adjustment can be made in the property settings. ',
              de: 'Berechnung basiert auf vorausgewähltem Wert für das Objekt und kann hier manuell abgeändert werden. Eine generelle Anpassung kann in den Objekteinstellungen vorgenommen werden. ',
            },
            vatEligibilityTooltipLink: {
              en: 'Learn more in our support article.',
              de: 'Erfahren Sie mehr in unserem Supportartikel.',
            },
          },
        },
        subsection3: {
          title: {
            en: 'Assignment to accounts',
            de: 'Zuordnung Sachkonten',
          },
          fields: {
            split: {
              en: 'Divide the amount',
              de: 'Rechnungsbetrag auf ein weiteres Sachkonto aufteilen',
            },
            splitAmountP1: {
              en: 'Gross amount',
              de: 'Betrag brutto',
            },
            bookingText: {
              en: 'Booking text',
              de: 'Buchungstext',
            },
            account: {
              en: 'Account №',
              de: 'Sachkonto №',
            },
            accountsOverview: {
              en: 'Accounts Overview',
              de: 'Zur Sachkontenübersicht',
            },
          },
          accountArticleLabel: {
            en: 'An overview of all accounts is provided in this support article.',
            de: 'Eine Übersicht über alle Konten finden Sie in diesem Support Artikel.',
          },
        },
        summaryInfoBar: {
          incoming: {
            en: 'Incoming',
            de: 'Eingehend',
          },
          outgoing: {
            en: 'Outgoing',
            de: 'Ausgehend',
          },
          totalNet: {
            en: 'Total NET',
            de: 'Gesamt netto',
          },
          vat: {
            en: 'VAT',
            de: 'MwSt',
          },
          vatIncoming: {
            en: 'VAT Incoming',
            de: 'MwSt Eingehend',
          },
          vatOutgoing: {
            en: 'VAT Outgoing',
            de: 'MwSt Ausgehend',
          },
          totalGross: {
            en: 'Total GROSS',
            de: 'Gesamt brutto',
          },
          laborCostNet: {
            en: 'Labor cost NET',
            de: 'Lohnkosten netto',
          },
          laborCostGross: {
            en: 'Labor Cost GROSS',
            de: 'Lohnkosten brutto',
          },
        },
      },
      section4: {
        title: {
          en: 'Payment Instruction',
          de: 'Zahlungsanweisung',
        },
        subsection0: {
          title: {
            en: 'Payment information',
            de: 'Zahlungsinformationen',
          },
          fields: {
            paymentTargetDays: {
              en: 'Payment target (days)',
              de: 'Zahlungsziel (Tage)',
            },
            paymentTargetDate: {
              en: 'Payment target (date)',
              de: 'Zahlungsziel (Datum)',
            },
          },
        },
        subsection1: {
          title: {
            en: 'Payment method for this invoice',
            de: 'Zahlungsanweisung',
          },
          fields: {
            paymentType: {
              existing: {
                en: 'No payment',
                de: 'Keine Zahlung',
              },
              createNew: {
                en: 'Create transaction',
                de: 'Überweisung',
              },
              standingOrder: {
                en: 'Standing order',
                de: 'Dauerauftrag',
              },
              recurringNoOrder: {
                en: 'Standing order without transaction',
                de: 'Dauerauftrag ohne Zahlung',
              },
            },
            existingOrder: {
              en: 'Existing order',
              de: 'Vorh. Dauefauftrag',
            },
            directDebit: {
              en: 'Existing SEPA direct debit mandate',
              de: 'Vorh. Lastschriftmandat',
            },
          },
        },
        subsection2: {
          title: {
            en: 'Choose Account',
            de: 'Konto auswählen',
          },
          fields: {
            total: {
              en: 'Total',
              de: 'Gesamtbetrag',
            },
            noBankAccounts: {
              en: 'There is no bank account to display.',
              de: 'Es ist kein Bankkonto zum Anzeigen vorhanden.',
            },
            HOUSE: {
              en: 'House account',
              de: 'Girokonto',
            },
            RESERVE: {
              en: 'Reserve account',
              de: 'Rücklagenkonto',
            },
            RENT: {
              en: 'Rent account',
              de: 'Mietenkonto',
            },
            DEPOSIT: {
              en: 'Deposit account',
              de: 'Kautionskonto',
            },
            sufficientFunds: {
              en: 'SUFFICIENT FUNDS',
              de: 'AUSREICHENDE DECKUNG',
            },
            insufficientFunds: {
              en: 'INSUFFICIENT FUNDS',
              de: 'MANGELNDE DECKUNG',
            },
          },
        },
        subsection3: {
          title: {
            en: 'Split invoice amount to several transactions',
            de: 'Rechnungsbetrag in mehrere Überweisungen aufteilen',
          },
          fields: {
            splitting: {
              yes: {
                en: 'Yes',
                de: 'Ja',
              },
              no: {
                en: 'No',
                de: 'Nein',
              },
            },
            splitAmount: {
              en: 'Split amount №',
              de: 'Teilzahlungsbetrag №',
            },
            splitDate: {
              en: 'Date',
              de: 'Datum',
            },
            addSplit: {
              en: 'Add split amount',
              de: 'Teilzahlungsbetrag hinzufügen',
            },
          },
        },
        subsection4: {
          title: {
            en: 'Execution',
            de: 'Ausführen',
          },
          fields: {
            statement: {
              en: 'Statement',
              de: 'Verwendungszweck',
            },
            bruttoAmount: {
              en: 'Invoice amount brutto',
              de: 'Rechnungsbetrag brutto',
            },
            executionDate: {
              label: {
                en: 'Date',
                de: 'Termin',
              },
              asap: {
                en: 'ASAP',
                de: 'Nächstmöglich',
              },
              date: {
                en: 'Date',
                de: 'Datum',
              },
            },
            firstExecution: {
              en: 'First execution (Date)',
              de: 'Erste Ausführung (Datum)',
            },
            lastExecution: {
              en: 'Last execution (Date)',
              de: 'Letzte Ausführung (Datum)',
            },
            interval: {
              en: 'Interval',
              de: 'Intervall',
            },
            intervalOptions: {
              weekly: {
                en: 'Weekly',
                de: 'Wöchentlich',
              },
              biweekly: {
                en: 'Biweekly',
                de: 'Zweiwöchentlich',
              },
              monthly: {
                en: 'Monthly',
                de: 'Monatlich',
              },
              bimonthly: {
                en: 'Bimonthly',
                de: 'Zweimonatlich',
              },
              quarterly: {
                en: 'Quarterly',
                de: 'Quartalsweise',
              },
              fourmonth: {
                en: 'Four months',
                de: 'Viermonatlich',
              },
              halfYearly: {
                en: 'Half-yearly',
                de: 'Halbjährlich',
              },
              yearly: {
                en: 'Yearly',
                de: 'Jährlich',
              },
            },
          },
        },
      },
    },
    duplicateWarning: {
      title: {
        en: 'Duplicate invoice',
        de: 'Duplikatswarnung',
      },
      messagePart1: {
        en: 'This invoice looks like a duplicate of the following invoices: ',
        de: 'Diese Rechnung scheint ein Duplikat der folgenden Rechnungen zu sein:',
      },
      messagePart2: {
        en: 'Do you want to put it on the payment run anyway?',
        de: 'Möchten Sie die Rechnung dennoch in den Zahlungslauf geben?',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      confirm: {
        en: 'Confirm',
        de: 'Ja',
      },
    },
    confirmationModal: {
      title: {
        en: 'Confirm',
        de: 'Bestätigen',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      discard: {
        en: 'Discard',
        de: 'Abbrechen',
      },
      save: {
        en: 'Save',
        de: 'Speichern',
      },
      description: {
        en: 'By saving all bookings related to this invoice will be corrected. Do you want to save?',
        de: 'Durch Speichern werden alle mit der Rechnung verknüpften Buchungen korrigiert. Möchten Sie fortfahren?',
      },
      descriptionStopRecurring: {
        en: 'Please note that by stopping the recurring invoice already created postings and payments won´t be updated, only upcoming ones.'
          + ' Therefore please check the correctness of related postings and payments. If there are incorrect cases, please revert the postings/payments.',
        de: 'Bitte beachten Sie, dass beim Stoppen des Dauerauftrags bereits erstellte Buchungen und Zahlungen nicht aktualisiert werden, sondern'
          + ' nur zukünftige. Bitte prüfen Sie daher selbstständig die Richtigkeit der anstehenden zugehörigen Buchungen und Zahlungen. Falls inkorrekte Fälle vorliegen, setzen Sie die Buchungen bzw. Zahlungen bitte zurück.',
      },
    },
  },
  notifications: {
    analyzing: {
      en: 'Analyzing document',
      de: 'Dokument wird analysiert',
    },
    meterLoadError: {
      message: {
        en: 'Resource loading error',
        de: 'Fehler beim Laden der Ressource',
      },
      description: {
        en: 'Cannot load meters.',
        de: 'Zähler können nicht geladen werden.',
      },
    },
  },
  validations: {
    defaultError: {
      en: 'Field is incorrect.',
      de: 'Das Feld ist falsch.',
    },
    mandatory: {
      en: 'Field is required',
      de: 'Feld ist erforderlich.',
    },
    notNull: {
      en: 'Field is required',
      de: 'Feld ist erforderlich.',
    },
    notEmpty: {
      en: 'Field is required',
      de: 'Feld ist erforderlich.',
    },
    postalCodeSize: {
      en: 'Field is invalid. Size must be 5.',
      de: 'Postleitzahl muss fünfstellig sein.',
    },
    tooSmall: {
      en: 'Field is invalid. Value must be higher.',
      de: 'Das Feld ist ungültig. Wert muss höher sein.',
    },
    tooBig: {
      en: 'Field is invalid. Value must be smaller.',
      de: 'Das Feld ist ungültig. Wert muss kleiner sein.',
    },
    invalidIbanPattern: {
      en: 'Invalid IBAN pattern.',
      de: 'Ungültiges IBAN-Format.',
    },
    duplicateIban: {
      en: 'IBAN already used.',
      de: 'IBAN bereits verwendet.',
    },
    duplicateBankAccountId: {
      en: 'Bank Account ID already used.',
      de: 'Bankkonto-ID bereits verwendet.',
    },
    isDeleted: {
      en: 'Entity is deleted.',
      de: 'Entität wird gelöscht.',
    },
    invalidBicPattern: {
      en: 'Invalid BIC pattern.',
      de: 'Ungültiges BIC-Format.',
    },
    invalidDirectDebitMandatePattern: {
      en: 'Invalid Mandate ID pattern.',
      de: 'Ungültiges Mandatsreferenz-format.',
    },
    dateMustBeInFuture: {
      en: 'Date must be in future',
      de: 'Datum muss in der Zukunft liegen.',
    },
    dateMustBeInPast: {
      en: 'Date must be in past',
      de: 'Datum muss in der Vergangenheit liegen.',
    },
    invalidDateRange: {
      en: 'Invalid date range.',
      de: 'Ungültiger Datumsbereich.',
    },
    sameIBAN: {
      en: 'Sender IBAN same as recipient.',
      de: 'Sender IBAN ist dieselbe wie beim Empfänger.',
    },
    invalidEndDate: {
      en: 'End date cannot be after start date.',
      de: 'Erste Ausführung nach letzter Ausführung.',
    },
    invalidTimeRange: {
      en: 'Invalid time range',
      de: 'Ungültiger Zeitbereich',
    },
    splitAmountTooHigh: {
      en: 'Split amount sum is too high.',
      de: 'Summe der Teilbeträge übersteigt Gesamtsumme.',
    },
    apportionedOnlyWhenHouseMoneySettlement: {
      en: 'Apportioned should be selected only if House money settlement is selected. ',
      de: 'Umlage sollte nur gewählt werden, wenn die Hausgeldabrechnung gewählt ist. ',
    },
    sumOfPercentagesNotOneHundred: {
      en: 'Sum of percentages is not 100%. ',
      de: 'Die Summe der Prozentsätze ist nicht 100%. ',
    },
    mustBeUnique: {
      en: 'Value must be unique',
      de: 'Der Wert muss eindeutig sein',
    },
    invalidLaborCost: {
      en: 'Amount too high',
      de: 'Betrag zu hoch',
    },
    invalidPhoneNumber: {
      en: 'Invalid phone number',
      de: 'Ungültige Telefonnummer',
    },
    nonNumericValues: {
      en: 'Field must not contain non-numeric values',
      de: 'Das Feld darf keine nicht numerischen Werte enthalten',
    },
    notLeafAccount: {
      en: 'Account is divided.',
      de: 'Sachkonto ist unterteilt.',
    },
    emptyAccountCode: {
      en: 'Invalid account',
      de: 'Ungültiges Konto',
    },
    invalidPaymentTypeForOffboardedProperty: {
      en: 'Standing order is forbidden for offboarded property',
      de: 'Daueraufträge sind für abgegebene Objekte nicht erlaubt',
    },
  },
};
