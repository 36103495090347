import { useContext } from 'react';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import defaultSection from '../defaultSection';
import { translations } from '../../translations';
import InvoiceProcessingSection from './InvoiceProcessingSection';

export default function useInvoiceProcessingSection1(disabled: boolean) {
  const { tl } = useContext(LanguageContext);

  return {
    ...defaultSection(0),
    sectionTitle: tl(translations.invoiceEditing.sections.section1.title),
    sectionId: 'InvoiceProcessing',
    content: [
      {
        sectionId: 'InvoiceProcessing',
        content: <InvoiceProcessingSection disabled={disabled} />
      },
    ],
  };
}
