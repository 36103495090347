import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import { AuthContext } from '../contexts/AuthContext';

export function PrivateRoute({ ...routeProps }): JSX.Element {
  const auth: any = useContext(AuthContext);

  if ((!auth.loaded && !auth.error) || auth.loading) {
    return <LoadingScreen />;
  }
  return auth.isLoggedIn()
    ? <Route {...routeProps} />
    : <Redirect to="/login" />;
}
