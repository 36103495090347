import { useState } from 'react';

import { useModal } from 'elements/Modals/useModal/useModal';
import {
  PortalDocumentProps,
  TICKET_COLUMN_TYPE,
} from 'storybook-components/TicketIntegrationSection/interfaces';

import { useCasaviTicketColumns } from './useCasaviTicketColumns';
import { useCasaviTickets } from './useCasaviTickets';

export const useCasaviTicketIntegrationSection = (connectionId: number,
  flowType: TICKET_COLUMN_TYPE, { propertyId, contactId }:
   {propertyId?: number, contactId?: number},
  documentProps?: PortalDocumentProps, getPortalComment?: (string) => string) => {
  const {
    assignTicket, errorMessage, isAssigned,
    issues, loading, onChangeSearchTerm, unassignTicket, casaviLink, timestamp,
  } = useCasaviTickets(connectionId, { propertyId, contactId }, documentProps);
  const [comment, setComment] = useState<string>('');

  const onSubmit = (ticketId: number) => {
    const portalComment = getPortalComment(comment);
    assignTicket(ticketId, portalComment);
    setComment('');
  };

  const commentModal = useModal({ onOk: onSubmit });
  const unassignModal = useModal({ onOk: unassignTicket });

  const { columns } = useCasaviTicketColumns({
    type: flowType,
    isTicketAssigned: isAssigned,
    showCommentModal: commentModal.showModal,
    showUnassignModal: unassignModal.showModal,
  });

  const onChangeComment = (comm: string) => setComment(comm);

  return {
    columns,
    errorMessage,
    issues,
    isAssigned,
    loading,
    onChangeSearchTerm,
    casaviLink,
    timestamp,
    comment,
    setComment: onChangeComment,
    onCancelComment: commentModal.onClickCancel,
    onCancelUnassign: unassignModal.onClickCancel,
    onCommentClickOk: commentModal.onClickOk,
    onUnassignClickOk: unassignModal.onClickOk,
    commentModalVisible: commentModal.visible,
    unassignModalVisible: unassignModal.visible,
    onCommentShow: commentModal.showModal,
    onUnassignShow: unassignModal.showModal,
  };
};
