export const translations = {
  creationPage: {
    process: {
      en: 'The process',
      de: 'Der Prozess',
    },
    title: {
      en: 'Manual Exchange',
      de: 'Manuelle Buchung',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    execute: {
      en: 'Execute',
      de: 'Ausführen',
    },
    prompt: {
      en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
      de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie weiter?',
    },
    accountSelectionTitle: {
      en: 'Account Selection',
      de: 'Kontoauswahl',
    },
    occurrenceTitle: {
      en: 'Occurrence',
      de: 'Ereignisse',
    },
    valuesTitle: {
      en: 'Values',
      de: 'Werte',
    },
    sections: {
      section1: {
        property: {
          en: 'Property',
          de: 'Objekt',
        },
        propertySelectPlaceholder: {
          en: 'Please select...',
          de: 'Bitte auswählen...',
        },
        caseSelection: {
          label: {
            en: 'Case selection',
            de: 'Anlassauswahl',
          },
          options: {
            ownersMeeting: {
              en: 'Owner event',
              de: 'Eigentümeranlass',
            },
          },
        },
        contractType: {
          en: 'Contract type',
          de: 'Vertragstyp',
        },
        counterAccount: {
          en: 'Counter account',
          de: 'Gegenkonto',
        },
        contractAccount: {
          en: 'Contract account',
          de: 'Vertragskonto',
        },
        bankAccount: {
          en: 'Bank account',
          de: 'Bankkonto',
        },
        noOrder: {
          en: 'No order needed',
          de: 'Keine Überweisungen',
        },
        orderDate: {
          en: 'Bank order date',
          de: 'Ausführungsdatum',
        },
        propertyOwner: {
          en: 'Property owner',
          de: 'Objekteigentümer',
        },
        tenant: {
          en: 'Tenant',
          de: 'Mieter',
        },
        owner: {
          en: 'Owner',
          de: 'Eigentümer',
        },
        accountArticleLabel: {
          en: 'An overview of all accounts is provided in this support article.',
          de: 'Eine Übersicht über alle Konten finden Sie in diesem Support Artikel.',
        },
      },
      occurrence: {
        bookingDate: {
          en: 'Booking date',
          de: 'Buchungsdatum',
        },
        bookingText: {
          en: 'Booking text',
          de: 'Buchungstext',
        },
        purpose: {
          en: 'Bank purpose text',
          de: 'Verwendungszweck',
        },
        add: {
          en: 'Add occurrence',
          de: 'Ereignis hinzufügen',
        },
      },
      section2: {
        unitNumber: {
          en: 'Nr.',
          de: 'Nr.',
        },
        ownerContract: {
          en: 'Name',
          de: 'Name',
        },
        vat: {
          en: 'VAT',
          de: 'MwSt.',
        },
        amountToDistribute: {
          en: 'Gross Amount to Distribute',
          de: 'Zu verteilender Brutto-Betrag',
        },
        direction: {
          en: 'Direction',
          de: 'Richtung',
        },
        processing: {
          en: 'Processing',
          de: 'Abwicklung',
        },
        selectOptionsLabels: {
          selfPayer: {
            en: 'Self payer',
            de: 'Selbstzahler',
          },
          paymentRun: {
            en: 'Payment run',
            de: 'Zahlungslauf',
          },
          directDebit: {
            en: 'Direct debit',
            de: 'Lastschriftzahlung',
          },
        },
        totalAmountLabel: {
          en: 'Total amount is split',
          de: 'Der Gesamtbetrag wurde verteilt',
        },
        infoBar: {
          unitsSelected: {
            en: 'Units selected',
            de: 'Ausgewählte Einheiten',
          },
          totalAmountPayout: {
            en: 'Total Amount Payout',
            de: 'Gesamtbetrag Auszahlung',
          },
          totalAmountPull: {
            en: 'Total Amount Pull',
            de: 'Gesamtbetrag Einzug',
          },
          bankOrders: {
            en: 'Bank orders',
            de: 'Bankaufträge',
          },
        },
      },
    },
    notifications: {
      validationError: {
        en: 'There are validation errors',
        de: 'Es sind Validierungsfehler aufgetreten',
      },
      amountIsZeroError: {
        en: 'The amount to distribute must be greater than 0',
        de: 'Der zu verteilende Betrag muss größer sein als 0',
      },
      mandatory: {
        en: 'Field is required',
        de: 'Feld ist erforderlich.',
      },
      executeOneSuccess: {
        en: 'Exchange created successfully.',
        de: 'Buchung wurde erfolgreich erstellt.',
      },
      executeManyRegistered: {
        title: {
          en: 'Exchanges successfully submitted.',
          de: 'Buchungen wurden erfolgreich übermittelt.',
        },
        description: {
          en: 'Depending on the size of the property and number of occurrences it may take up to an hour for all the bookings to be created.',
          de: 'Je nach Größe des Objekts und Anzahl der Ereignisse kann es bis zu einer Stunde dauern, bis alle Buchungen erstellt wurden.',
        },
      },
      executeError: {
        en: 'Failed to create exchange',
        de: 'Buchung konnte nicht erstellt werden',
      },
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load properties.',
          de: 'Objekte können nicht geladen werden.',
        },
      },
      loadVatRateError: {
        en: 'Failed to load VAT rate values.',
        de: 'Mehrwertsteuersätze konnten nicht geladen werden.',
      },
      vatRateNotApplied: {
        en: 'There are contracts which are VAT relevant, but VAT rate could not be applied.',
        de: 'Es gibt umsatzsteuerpflichtige Verträge, aber der Mehrwertsteuersatz konnte nicht angewendet werden.',
      },
    },
  },
};
