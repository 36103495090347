import React, {
  createContext,
  useMemo,
  useState,
} from 'react';

import {
  ExtendedExchangeProjectionTypeEnum,
  ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
} from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';

export interface PostingText {
  key: string,
  transactionId?: number,
  exchangeId?: number,
  allocationId?: number,
  postingText: string,
  previousPostingText?: string,
}

// TODO cleanup after submit is implemented to only keep essentials
export interface ServiceCompanyAllocationValues {
  key: string,
  accounts?: { code: string, name: string }[],
  counterpartContactId?: number,
  counterpartName?: string,
  laborCost?: number,
  laborCostType?: ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
  vatPercentage?: number | number[],
  vatEligibilityPercentage?: number | number[],
  vatEligibilityAmount?: number | number[],
  exchangeAmount?: number,
  exchangeRemainingAmount?: number,
  allocationAmount?: number,
  previousAllocationAmount?: number,
  date?: string,
  type?: ExtendedExchangeProjectionTypeEnum,
  exchangeIds?: number[],
  planIds?: number[],
  purpose?: string,
  propertyIban?: string,
  counterpartIban?: string,
  propertyHrId?: string,
  propertyIdInternal?: string,
  propertyName?: string,
}


export interface AllocationAmount {
  key: string,
  currentAmount?: number,
  previousAmount?: number,
  exchangeAmount?: number,
  exchangeIds?: number[],
  exchangePurpose?: string,
  exchangeType?: ExtendedExchangeProjectionTypeEnum,
}

export interface AllocationLaborCostValues {
  key: string,
  laborCostAmount?: number,
  previousLaborCostAmount?: number,
  laborCostType?: ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
  previousLaborCostType?: ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
}

export interface AllocationVatEligibilityValues {
  key: string,
  vatEligibilityPercentage?: number,
  previousVatEligibilityPercentage?: number,
}


export interface SelectInputValues {
  key: string,
  propertyHrId?: string,
  accountCode?: string[],
  counterpartContactId?: number,
  vatPercentage?: number,
  previousAccountCode?: string[],
  previousCounterpartContactId?: number,
  previousVatPercentage?: number,
}


export interface MergedValuesType extends SelectInputValues, AllocationLaborCostValues, AllocationAmount, AllocationVatEligibilityValues {
  postingTexts: PostingText[];
}

export interface PropertyVatEligibilityInfo {
  propertyId?: number,
  propertyHrId?: string,
  vatEligibilityShare?: number | undefined,
}

const PropertyVatEligibilityInfoContext = createContext<{
  propertyVatEligibilityInfo: PropertyVatEligibilityInfo[];
  setPropertyVatEligibilityInfo: React.Dispatch<React.SetStateAction<PropertyVatEligibilityInfo[]>>;
} | undefined>(undefined);

const PostingTextsContext = createContext<PostingText[] | undefined>(undefined);
const SetPostingTextsContext = createContext<React.Dispatch<React.SetStateAction<PostingText[]>> | undefined>(undefined);


const ServiceCompanyAllocationUpdatersContext = createContext<
  | {
      setAllocationAmounts: React.Dispatch<React.SetStateAction<AllocationAmount[]>>;
      setAllocationLaborCostValues: React.Dispatch<React.SetStateAction<AllocationLaborCostValues[]>>;
      setAllocationVatEligibilityValues: React.Dispatch<React.SetStateAction<AllocationVatEligibilityValues[]>>;
      setSelectInputValues: React.Dispatch<React.SetStateAction<SelectInputValues[]>>;
      setSearchString: React.Dispatch<React.SetStateAction<String>>;
    }
  | undefined
>(undefined);

const ExchangeListAndPreviousAllocationValuesContext = createContext<{
  previousAllocationValues: ServiceCompanyAllocationValues[] | undefined,
  setPreviousAllocationValues: React.Dispatch<React.SetStateAction<ServiceCompanyAllocationValues[] | undefined>>,
  exchangeList: DefaultDataInterface<ServiceCompanyAllocationValues[]>,
  setExchangeList: React.Dispatch<React.SetStateAction<DefaultDataInterface<ServiceCompanyAllocationValues[]>>>,
  searchString: string,
} | undefined>(undefined);


const AllocationAmountsStateContext = createContext<AllocationAmount[] | undefined>(undefined);

const AllocationLaborCostValuesStateContext = createContext<AllocationLaborCostValues[] | undefined>(undefined);

const AllocationVatEligibilityValuesStateContext = createContext<AllocationVatEligibilityValues[] | undefined>(undefined);

const SelectInputValuesStateContext = createContext<SelectInputValues[] | undefined>(undefined);


/**
 * The reason why the cell components in the table are connected to the value in the state
 * (and we're not relying on them managing their local state to remember the display input value)
 * is because the table is virtualized, and so if I input some value in the first row and then scroll
 * to the bottom of the list then the component will unmount; then when I scroll back up to the first row,
 * then the component is remounted BUT it can't know what the previously inputted value was, unless
 * I connect it to the state.
 */

const ServiceCompanyAllocationContextProvider = ({ children } : { children: React.ReactChild }) => {
  const [exchangeList, setExchangeList] = useState<DefaultDataInterface<ServiceCompanyAllocationValues[]>>(DEFAULT_DATA(undefined));
  const [previousAllocationValues, setPreviousAllocationValues] = useState<ServiceCompanyAllocationValues[] | undefined>(undefined);

  /**
   * These are text/number inputs and to avoid performance issues when typing,
   * we need to separate them so we only rerender the respective columns, not the whole table
   * Ex: typing in a laborCostType cell will rerender only cells in that column,
   * sumToAllocate and postingTexts will not rerender
   */
  const [allocationAmounts, setAllocationAmounts] = useState<AllocationAmount[]>([]);
  const [allocationLaborCostValues, setAllocationLaborCostValues] = useState<AllocationLaborCostValues[]>([]);
  const [selectInputValues, setSelectInputValues] = useState<SelectInputValues[]>([]);
  const [postingTexts, setPostingTexts] = useState<PostingText[]>([]);
  const [allocationVatEligibilityValues, setAllocationVatEligibilityValues] = useState<AllocationVatEligibilityValues[]>([]);
  const [propertyVatEligibilityInfo, setPropertyVatEligibilityInfo] = useState<PropertyVatEligibilityInfo[]>([]);
  const [searchString, setSearchString] = useState('');


  const exchangeListAndPreviousAllocationValuesContextValue = useMemo(() => ({
    exchangeList,
    setExchangeList,
    previousAllocationValues,
    setPreviousAllocationValues,
    searchString,
  }), [exchangeList, setExchangeList, previousAllocationValues, setPreviousAllocationValues, searchString]);

  const updatersValue = useMemo(() => ({
    setAllocationAmounts,
    setAllocationLaborCostValues,
    setAllocationVatEligibilityValues,
    setSelectInputValues,
    setSearchString,
  }), [setAllocationAmounts, setAllocationLaborCostValues, setAllocationVatEligibilityValues, setSelectInputValues, setSearchString]);

  return (
    <PropertyVatEligibilityInfoContext.Provider value={{ propertyVatEligibilityInfo, setPropertyVatEligibilityInfo }}>
      <PostingTextsContext.Provider value={postingTexts}>
        <SetPostingTextsContext.Provider value={setPostingTexts}>
          <AllocationAmountsStateContext.Provider value={allocationAmounts}>
            <AllocationLaborCostValuesStateContext.Provider value={allocationLaborCostValues}>
              <SelectInputValuesStateContext.Provider value={selectInputValues}>
                <ServiceCompanyAllocationUpdatersContext.Provider value={updatersValue}>
                  <AllocationVatEligibilityValuesStateContext.Provider value={allocationVatEligibilityValues}>
                    <ExchangeListAndPreviousAllocationValuesContext.Provider value={exchangeListAndPreviousAllocationValuesContextValue}>
                      {children}
                    </ExchangeListAndPreviousAllocationValuesContext.Provider>
                  </AllocationVatEligibilityValuesStateContext.Provider>
                </ServiceCompanyAllocationUpdatersContext.Provider>
              </SelectInputValuesStateContext.Provider>
            </AllocationLaborCostValuesStateContext.Provider>
          </AllocationAmountsStateContext.Provider>
        </SetPostingTextsContext.Provider>
      </PostingTextsContext.Provider>
    </PropertyVatEligibilityInfoContext.Provider>
  );
};

export {
  AllocationAmountsStateContext,
  AllocationLaborCostValuesStateContext,
  AllocationVatEligibilityValuesStateContext,
  ExchangeListAndPreviousAllocationValuesContext,
  PostingTextsContext,
  SelectInputValuesStateContext,
  ServiceCompanyAllocationContextProvider,
  ServiceCompanyAllocationUpdatersContext,
  SetPostingTextsContext,
  PropertyVatEligibilityInfoContext,
};
