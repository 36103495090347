import { ContactLegacyDto } from 'api/accounting';
import { ContactLegacyControllerApi, SearchContactsByTypeUsingGETTypesEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';

export const useManagementCompanyList = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));

  const [mangementCompanyList, setManagementCompanyList] = useState<ContactLegacyDto[]>([]);

  useEffect(() => {
    async function loadManagementCompanies() {
      const contactPage = await contactControllerApi.searchContactsByTypeUsingGET({ types: SearchContactsByTypeUsingGETTypesEnum.MANAGEMENT_COMPANY });
      setManagementCompanyList(contactPage.content || []);
    }
    loadManagementCompanies();
  }, []);

  return mangementCompanyList;
};
