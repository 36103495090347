import { ListDunningDtoUsingGETStatesEnum } from 'api/accounting/apis/DunningControllerApi';
import { GetMessagesUsingGETStateEnum } from 'api/accounting';
import { DunningLevelEnum } from './OrderOpenBalancesList/services/interfaces';
import { DunningProcessingEnum } from './OrderOpenBalancesView/components/ContractDunningProcessingCell/useContractDunningProcessingCell';

export const orderOpenBalancesTranslations = {
  header: {
    button: {
      en: 'Proceed',
      de: 'Fortfahren',
    },
  },
  emptyTableMessage: {
    heading: {
      en: 'No results found that match the filter set.',
      de: 'Keine Ergebnisse gefunden, die dem Filter entsprechen.',
    },
    message: {
      en: 'Please check, if all filters are set correctly or change them.',
      de: 'Überprüfen Sie, ob alle Filter korrekt gesetzt sind oder ändern Sie diese nochmals ab.',
    },
  },
  nonColumnFilters: {
    propertyIdInternal: {
      en: 'Property Number',
      de: 'Objekt-Nr',
    },
    openPropertyAmount: {
      en: 'Open Property amount',
      de: 'Offener Objektbetrag',
    },
    openAccountAmount: {
      en: 'Open Account amount',
      de: 'Offener Vertragsbetrag',
    },
    postDate: {
      en: 'Open Amount Until',
      de: 'Offener Saldo bis (Datum)',
    },
    onlyActiveContracts: {
      en: 'Status Contracts',
      de: 'Status Vertrag',
    },
    onlyActiveContractsValue: {
      active: {
        en: 'Active',
        de: 'Aktiv',
      },
      all: {
        en: 'All',
        de: 'Alle',
      },
    },
    onlyActiveMandates: {
      en: 'Direct Debit Mandate Status',
      de: 'Status Lastschriftmandat',
    },
    onlyActiveMandatesValue: {
      active: {
        en: 'Active',
        de: 'Aktiv',
      },
      all: {
        en: 'All',
        de: 'Alle',
      },
    },
    propertyAdministrationTypes: {
      en: 'Administration type',
      de: 'Verwaltungsart',
    },
    administrationTypeValues: {
      WEG: {
        en: 'Wohnungseigentum (WEG)',
        de: 'Wohnungseigentum (WEG)',
      },
      MV: {
        en: 'Mietverwaltung (MV)',
        de: 'Mietverwaltung (MV)',
      },
      SEV_MV: {
        en: 'SEV without WEG connection',
        de: 'SEV ohne WEG-Verbindung',
      },
      SEV: {
        en: 'SEV with WEG connection',
        de: 'SEV mit WEG-Verbindung',
      },
    },
    lastDunningMessageExecutedUntil: {
      en: 'Last dunning note sent until',
      de: 'Letzte Mahnung gesendet bis',
    },
  },
  outerTableColumns: {
    property: {
      en: 'Property',
      de: 'Objektbezeichnung',
    },
    openContractAccounts: {
      en: 'Contract count',
      de: 'Anzahl Verträge',
    },
    openBalance: {
      en: 'Open balance',
      de: 'Offener Saldo',
    },
    openTransactions: {
      en: 'Open transactions',
      de: 'Offene Bankumsätze',
    },
    administrator: {
      en: 'Administrator',
      de: 'Objektbetreuer',
    },
    accountant: {
      en: 'Accountant',
      de: 'Objektbuchhalter',
    },
    lastDunningNote: {
      en: 'Last dunning note',
      de: 'Letztes Mahnschreiben',
    },
    dunningFeeNet: {
      en: 'Dunning Fee net',
      de: 'Mahngebühr netto',
    },
  },
  innerTableColumns: {
    accountNr: {
      en: 'Account Nr.',
      de: 'Konto-Nr',
    },
    accountName: {
      en: 'Account name',
      de: 'Kontobezeichnung',
    },
    openBalance: {
      en: 'Open balance',
      de: 'Offener Saldo',
    },
    dunningFee: {
      en: 'Dunning fee net',
      de: 'Mahngebühr netto',
    },
    upcomingDunningLevel: {
      en: 'Upcoming dunning level',
      de: 'Nächste Mahnstufe',
    },
    articleLinkLabel: {
      en: 'Check out the templates in ',
      de: 'Sehen Sie sich die Vorlagen in ',
    },
    articleLinkContent: {
      en: 'this Article',
      de: 'diesem Artikel an',
    },
    lastDunningNote: {
      en: 'Last dunning note',
      de: 'Letztes Mahnschreiben',
    },
    dunningFeeNet: {
      en: 'Dunning Fee net',
      de: 'Mahngebühr netto',
    },
    lastDunningMessageStates: {
      [GetMessagesUsingGETStateEnum.SENT]: {
        en: 'Sent',
        de: 'Gesendet',
      },
      [GetMessagesUsingGETStateEnum.READY]: {
        en: 'Ready to send',
        de: 'Versandfertig',
      },
      [GetMessagesUsingGETStateEnum.GENERATING]: {
        en: 'Generating',
        de: 'Wird erstellt',
      },
      [GetMessagesUsingGETStateEnum.SENDING]: {
        en: 'Sending',
        de: 'Senden',
      },
      [GetMessagesUsingGETStateEnum.FAILED]: {
        en: 'Error',
        de: 'Fehler',
      },
      [GetMessagesUsingGETStateEnum.DELETED]: {
        en: 'Deleted',
        de: 'Gelöscht',
      },
      [GetMessagesUsingGETStateEnum.DRAFT]: {
        en: 'Draft',
        de: 'Entwurf',
      },
    },
    actions: {
      exclude: {
        en: 'Exclude contract',
        de: 'Vertrag ausschließen',
      },
      include: {
        en: 'Include contract',
        de: 'Vertrag einschließen',
      },
      modal: {
        title: {
          en: 'Exclude contract',
          de: 'Vertrag ausschließen',
        },
        line1: {
          en: 'This contract will be excluded from the dunning process and you won´t be able to select it anymore.',
          de: 'Dieser Vertrag wird aus dem Mahnwesen ausgeschlossen und Sie können ihn nicht mehr auswählen.',
        },
        line2: {
          en: 'You can include the contract again here at any point in time.',
          de: 'Sie können den Vertrag jederzeit hier wieder einschließen.',
        },
        ok: {
          en: 'Exclude contract',
          de: 'Vertrag ausschließen',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
      },
      tooltip: {
        en: 'This contract was excluded from the dunning.',
        de: 'Dieser Vertrag wurde vom Mahnwesen ausgeschlossen.',
      },
    },
  },
  dunningAnnexAccountBalanceColumns: {
    date: {
      en: 'Date',
      de: 'Datum',
    },
    bookingText: {
      en: 'Booking text',
      de: 'Buchungstext',
    },
    creditDebitNote: {
      en: 'Credit (+) / debit (-) notes',
      de: 'Schuld (+) / Guthaben (-)',
    },
    yourPayments: {
      en: 'Your payments',
      de: 'Ihre Zahlungen',
    },
    balance: {
      en: 'Balance',
      de: 'Saldo',
    },
  },
  dunningAnnexAccountBalanceSummaryRow: {
    title: {
      en: 'Summary',
      de: 'Gesamt',
    },
    titleResult: {
      en: 'Total balance',
      de: 'Summe Saldo',
    },
  },
  dunningAnnexDocumentCover: {
    title: {
      en: 'Account Overview',
      de: 'Kontenübersicht',
    },
    propertyNr: {
      en: 'Property-Nr',
      de: 'Objekt-Nr',
    },
    propertyName: {
      en: 'Property Name',
      de: 'Objektbezeichnung',
    },
    unitNr: {
      en: 'Unit-Nr',
      de: 'Einheit-Nr',
    },
  },
  dunningAnnexExplanatoryText: {
    en: 'Please note that negative balances (-) are an indicator for an overpayment and positive balances (+) outstanding payments.',
    de: 'Bitte beachten Sie, dass negative Salden (-) eine Überzahlung und positive Salden (+) einen Zahlungsrückstand indizieren.',
  },
  openBalanceTitle: {
    en: 'Amount according to filter result on top, overall at the bottom.',
    de: 'Betrag gemäß Filter oben, gesamt unten.',
  },
  dunningFeeModal: {
    title: {
      en: 'Adapt dunning fee',
      de: 'Mahngebühr anpassen',
    },
    infoAlertText: {
      firstPart: {
        en: 'This setting will change the fee globally in the',
        de: 'Diese Einstellung ändert die Mahngebühr global in der Sektion',
      },
      linkText: {
        en: ' special fees ',
        de: ' Sonderhonorare ',
      },
      lastPart: {
        en: ' section in the property details.',
        de: ' der Objekt Details.',
      },
    },
    amountInputTitle: {
      en: 'Fee amount net',
      de: 'Betrag Mahngebühr netto',
    },
    radioButtonTitle: {
      en: 'Apply the fee change',
      de: 'Gebührenänderung anwenden',
    },
    radioGroupOptions: {
      contract: {
        en: 'Once for this contract',
        de: 'Einmalig für diesen Vertrag',
      },
      property: {
        en: 'Once',
        de: 'Einmalig',
      },
      allContracts: {
        en: 'Once for all contracts of this property',
        de: 'Einmalig für alle Verträge dieses Objekts',
      },
      globalSettingsOfProperty: {
        en: 'On the global settings of the property',
        de: 'In den globalen Einstellungen des Objekts',
      },
    },
    applyButton: {
      en: 'Apply',
      de: 'Anwenden',
    },
    cancelButton: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    updatePropertyError: {
      en: 'Dunning fee in property flow couldn´t be updated.',
      de: 'Die Mahngebühr konnte im Objekt nicht aktualisiert werden.',
    },
  },
  dunningLevelSelectorLabels: {
    [DunningLevelEnum.PAY_OUT]: {
      label: {
        en: '0 - Payout',
        de: '0 - Auszahlung',
      },
      description: {
        en: 'Announcement',
        de: 'Ankündigung',
      },
    },
    [DunningLevelEnum.SEPA_DIRECT_DEBIT]: {
      label: {
        en: '1 - SEPA Direct Debit',
        de: '1 - Lastschrifteinzug',
      },
      description: {
        en: 'Announcement',
        de: 'Ankündigung',
      },
    },
    [DunningLevelEnum.PAYMENT_REMINDER]: {
      label: {
        en: '1 - Payment Reminder',
        de: '1 - Zahlungserinnerung',
      },
      description: {
        en: 'Friendly Reminder',
        de: 'Freundliche Erinnerung',
      },
    },
    [DunningLevelEnum.PAYMENT_REMINDER_TAKEOVER_PROPERTY]: {
      label: {
        en: '1 - Payment Reminder Takeover Property',
        de: '1 - Zahlungserinnerung bei Objektübernahme',
      },
      description: {
        en: 'Includes recommendation for checks',
        de: 'Mit Empfehlung für Überprüfungen',
      },
    },
    [DunningLevelEnum.DUNNING_NOTE]: {
      label: {
        en: '2 - Dunning Note',
        de: '2 - Mahnschreiben',
      },
      description: {
        en: 'More strict tone',
        de: 'Strengerer Ton',
      },
    },
    [DunningLevelEnum.LAST_DUNNING_NOTE]: {
      label: {
        en: '3 - Last Dunning Note',
        de: '3 - Letzte Mahnung',
      },
      description: {
        en: 'Announces legal steps',
        de: 'Kündigt rechtliche Schritte an',
      },
    },
    [DunningLevelEnum.NO_MESSAGE]: {
      label: {
        en: 'No message',
        de: 'Keine Nachricht',
      },
      description: {
        en: 'Continue the process without sending out a message',
        de: 'Prozess fortsetzen, ohne eine Nachricht zu versenden',
      },
    },
  },
  openOrdersModal: {
    title: {
      en: 'Open amount overview',
      de: 'Übersicht zum offenen Betrag',
    },
    alert: {
      firstPart: {
        en: '',
        de: 'Die',
      },
      linkText: {
        en: 'Open transactions ',
        de: ' offenen Transaktionen ',
      },
      secondPart: {
        en: 'of this property might also affect the open balance.',
        de: 'des Objekts könnten ebenfalls den offenen Saldo beeinflussen.',
      },
    },
    table: {
      category: {
        en: 'Category',
        de: 'Kategorie',
      },
      amount: {
        en: 'Amount',
        de: 'Betrag',
      },
      openDirectDebits: {
        en: 'Open direct debits',
        de: 'Offene Lastschriften',
      },
      openPayments: {
        en: 'Open payments',
        de: 'Offene Zahlungen',
      },
      calculatedBalance: {
        en: 'Calculated balance',
        de: 'Errechneter Saldo',
      },
      todaysBalance: {
        en: "Today's balance",
        de: 'Heutiger Saldo',
      },
      overallBalance: {
        en: 'Future posting amount',
        de: 'Betrag zukünftiger Buchungen (gesamt)',
      },
    },
  },
  notifications: {
    checkOpenOrdersError: {
      en: 'Failed to check open bank transactions.',
      de: 'Offene Banktransaktionen konnten nicht geprüft werden.',
    },
    loadListError: {
      message: {
        en: 'Failed to load open balances.',
        de: 'Offene Salden konnten nicht geladen werden.',
      },
    },
    errorExecution: {
      en: "Order open balances couldn't be executed.",
      de: 'Offene Salden konnten nicht ausgeführt werden.',
    },
    propertyLoadError: {
      en: 'Error loading properties.',
      de: 'Objekte konnten nicht geladen werden',
    },
    loadContractsError: {
      en: 'Failed to load contracts list.',
      de: 'Verträge konnten nicht geladen werden.',
    },
    loadAccountsError: {
      en: 'Failed to load accounts',
      de: 'Konten konnten nicht geladen werden',
    },
    messagesLoadError: {
      en: 'Failed to load messages.',
      de: 'Nachrichten konnten nicht geladen werden.',
    },
  },
  orderOpenBalancesView: {
    title: {
      en: 'Order open balances',
      de: 'Offene Salden abwickeln',
    },
    sectionTitle: {
      en: 'Overview',
      de: 'Übersicht',
    },
    header: {
      buttonLabels: {
        cancel: {
          en: 'Back',
          de: 'Zurück',
        },
        execute: {
          en: 'Execute',
          de: 'Ausführen',
        },
        sendOut: {
          en: 'Preview and send',
          de: 'Vorschau & Versenden',
        },
        checkMessages: {
          en: 'View messages',
          de: 'Nachrichten ansehen',
        },
      },
    },
    message: {
      intro: {
        info: {
          en: 'Upon execution the following actions will take place:',
          de: 'Beim Ausführen werden folgende Aktionen durchgeführt:',
        },
        warning: {
          en: 'The open balances have already been booked.',
          de: 'Die offenen Salden wurden bereits verbucht.',
        },
        messagesFailed: {
          en: 'Sending out dunning notes failed.',
          de: 'Versenden von Mahnschreiben fehlgeschlagen.',
        },
        messagesInGeneration: {
          en: 'The Open Balances have already been booked and and the messages are currently generated.',
          de: 'Die offenen Salden wurden bereits verbucht und die Nachrichten werden gerade erstellt.',
        },
        messagesSent: {
          en: 'The Open Balances have already been booked and the messages have been sent.',
          de: 'Die offenen Salden wurden bereits verbucht und die Nachrichten wurden versendet.',
        },
      },
      warning: {
        description: {
          en: 'The following actions already took place:',
          de: 'Die folgenden Aktionen haben bereits stattgefunden:',
        },
        lastWordDe: {
          en: '',
          de: 'sichtbar',
        },
        DUNNING: {
          firstPart: {
            en: 'Dunning Fees have been booked and are visible in the ',
            de: 'Mahngebühren wurden verbucht und sind in den ',
          },
          secondPart: {
            en: 'Account Sheets',
            de: 'Kontensalden ',
          },
        },
        BANK_ORDERS: {
          firstPart: {
            en: 'Bank Orders were generated and are visible in the ',
            de: 'Bankaufträge wurden generiert und sind unter ',
          },
          secondPart: {
            en: 'Direct Debit',
            de: 'Lastschriftzahlungen',
          },
          thirdPart: {
            en: 'Payment Overview',
            de: 'Zahlungslauf ',
          },
        },
        DOCUMENTS: {
          firstPart: {
            en: 'Documents were generated and are visible in the ',
            de: 'Dokumente wurden erstellt und sind unter ',
          },
          secondPart: {
            en: 'Documents Overview',
            de: 'Dokumente ',
          },
        },
        note: {
          en: 'You can proceed to the send out or revert the actions via the screens listed above.',
          de: 'Sie können mit dem Versenden fortfahren oder die Aktionen über die oben genannten Screens rückgängig machen.',
        },
        messagesFailed: {
          en: 'To view the failed dunning notes and resend go to the messages overview',
          de: 'Um die fehlgeschlagenen Mahnschreiben anzusehen und erneut zu versenden, gehen Sie bitte zur Nachrichten Übersicht.',
        },
      },
      info: {
        description: {
          en: '',
          de: '',
        },
        DUNNING: {
          en: 'Dunning fees will be booked',
          de: 'Mahngebühren werden verbucht',
        },
        BANK_ORDERS: {
          en: 'Bank orders will be generated',
          de: 'Bankaufträge werden erstellt',
        },
        DOCUMENTS: {
          en: 'Documents will be generated',
          de: 'Dokumente werden erstellt',
        },
        note: {
          en: 'In the next step you can send out the dunning notes.',
          de: 'Im nächsten Schritt können Sie die Mahnschreiben versenden.',
        },
      },
      zeroBalanceWarning: {
        en: 'Contracts with amounts of 0,00 €: To execute the dunning process, please remove the contracts listed by clicking on the three dot menu:',
        de: 'Verträge mit Beträgen von 0,00 €: Um den Mahnlauf durchzuführen, entfernen Sie bitte die aufgelisteten Verträge, indem Sie auf das ••• Menü klicken',
      },
    },
    overViewTable: {
      columns: {
        contract: {
          en: 'Contract',
          de: 'Vertrag',
        },
        dunningNote: {
          en: 'Dunning Note',
          de: 'Mahnschreiben',
        },
        processing: {
          en: 'processing',
          de: 'Abwicklung',
        },
        dunningFeeNet: {
          en: 'Dunning Fee net',
          de: 'Mahngebühr netto',
        },
        amount: {
          en: 'Open Balance',
          de: 'Offener Saldo',
        },
        remove: {
          en: 'Remove',
          de: 'Entfernen',
        },
      },
      dunningProcessingLabels: {
        [DunningProcessingEnum.NO_BANK_ORDER]: {
          en: 'No bank order',
          de: 'Kein Bankauftrag',
        },
        [DunningProcessingEnum.DIRECT_DEBIT]: {
          en: 'Direct debit',
          de: 'Lastschrift',
        },
        [DunningProcessingEnum.PAYOUT]: {
          en: 'Payout',
          de: 'Auszahlung',
        },
      },
      emptyMessage: {
        en: 'All contracts removed.\nPlease go back to the overview page.',
        de: 'Alle Veträge wurden entfernt.\nBitte gehen Sie zurück zur Übersichtsseite.',
      },
    },
  },
  actionBar: {
    warningTooltip: {
      en: 'Some of the selected contracts are not be visible — change the filters or keep scrolling.',
      de: 'Einige der ausgewählten Verträge sind nicht sichtbar — ändern Sie die Filter oder scrollen Sie weiter',
    },
    expandAll: {
      en: 'Expand all selected',
      de: 'Alle Ausgewählten aufklappen',
    },
    collapseAll: {
      en: 'Collapse all selected',
      de: 'Alle Ausgewählten zuklappen',
    },
    unselectAll: {
      en: 'Unselect all',
      de: 'Auswahl widerrufen',
    },
    showAllSelected: {
      en: 'Show all selected',
      de: 'Alle ausgewählten anzeigen',
    },
  },
  dunningNotes: {
    title: {
      dunningLevel1Doc: {
        en: 'Payment Reminder Standard',
        de: 'Zahlungserinnerung',
      },
      'dunningLevel1.1Doc': {
        en: 'Payment Reminder Takeover Property',
        de: 'Zahlungserinnerung',
      },
      dunningLevel2Doc: {
        en: 'Dunning Note',
        de: 'Mahnung',
      },
      dunningLevel3Doc: {
        en: 'Last Dunning Note',
        de: ' Letzte Mahnung',
      },
      drawMoneyDoc: {
        en: 'Drawing Money',
        de: 'Vorankündigung Lastschrift',
      },
      payingOutMoneyDoc: {
        en: 'Paying out Money',
        de: 'Vorankündigung Auszahlung',
      },
      directDebitMandateDoc: {
        en: 'Direct Debit Mandate',
        de: 'SEPA-Lastschriftmandat',
      },
    },
  },
  dunningList: {
    tableColumns: {
      multiple: {
        en: 'Multiple',
        de: 'Mehrere',
      },
      state: {
        label: {
          en: 'State',
          de: 'Status',
        },
        [ListDunningDtoUsingGETStatesEnum.DRAFT]: {
          en: 'Draft',
          de: 'Entwurf',
        },
        [ListDunningDtoUsingGETStatesEnum.BOOKED]: {
          en: 'Booked',
          de: 'Ausgeführt',
        },
        [ListDunningDtoUsingGETStatesEnum.SENDING]: {
          en: 'Sending',
          de: 'Senden',
        },
        [ListDunningDtoUsingGETStatesEnum.SENT]: {
          en: 'Sent',
          de: 'Versendet',
        },
        [ListDunningDtoUsingGETStatesEnum.FAILED]: {
          en: 'Failed',
          de: 'Fehler',
        },
      },
      numberOfProperties: {
        en: 'Properties',
        de: 'Objekte',
      },
      numberOfContracts: {
        en: 'Contracts',
        de: 'Verträge',
      },
      amount: {
        en: 'Amount',
        de: 'Betrag',
      },
      administrator: {
        en: 'Administrator',
        de: 'Objektbetreuer',
      },
      accountant: {
        en: 'Accountant',
        de: 'Objektbuchhalter',
      },
      numberOfAllMessages: {
        en: 'All messages',
        de: 'Alle Nachrichten',
      },
      numberOfFailedMessages: {
        en: 'Failed messages',
        de: 'Fehlgeschlagene Nachrichten',
      },
      executionDate: {
        en: 'Created on',
        de: 'Erstellt am',
      },
      createdBy: {
        en: 'Created by',
        de: 'Erstellt von',
      },
      reference: {
        en: 'Reference',
        de: 'Referenz',
      },
    },
    notifications: {
      loadListError: {
        en: 'Failed to load list of dunnings.',
        de: 'Mahnläufe konnten nicht geladen werden.',
      },
    },
  },
  tabLinks: {
    new: {
      en: 'New',
      de: 'Neu',
    },
    list: {
      en: 'Initiated',
      de: 'Initiiert',
    },
  },
};
