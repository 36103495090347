import { formatDate } from 'lib/Utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { useActivePropertyOwnerContracts } from 'services/PropertyOwnerContractsList/useActivePropertyOwnerContracts';
import { usePropertyOwnerContracts } from 'services/PropertyOwnerContractsList/usePropertyOwnerContracts';
import { useEpostCountryName } from 'services/useEpostCountryName';
import { useRecipientAddressLines } from 'services/useRecipientAddressLines';
import { ProfitAndLossReportContext } from './ProfitAndLossReportContext';

export const useProfitAndLossReportEditor = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useProfitAndLossReportEditor must be used within ProfitAndLossReportContextProvider');
  }

  const { reportData } = profitAndLossReportContext;
  const { activePropertyOwnerContracts } = useActivePropertyOwnerContracts(reportData.property?.id);

  const { getCountryName } = useEpostCountryName();

  const recipientAddressLines = useRecipientAddressLines(activePropertyOwnerContracts?.[0]?.mailingContact?.contactId);

  const isDirty = !isEmpty(reportData);

  const managementCompany = reportData.property?.managementCompany;
  const managementCompanyAddress = managementCompany?.addresses?.[0];
  let managementCompanyCountry = getCountryName(managementCompanyAddress?.country);
  managementCompanyCountry = managementCompanyCountry === 'Deutschland' ? '' : managementCompanyCountry;

  const managementCompanyAddressLine = `${managementCompanyAddress?.street} ${managementCompanyAddress?.number} | ${managementCompanyAddress?.postalCode} ${managementCompanyAddress?.city} ${managementCompanyCountry}`;

  const { propertyOwnerContracts } = usePropertyOwnerContracts(reportData.property?.id);
  const propertyOwnerNames = propertyOwnerContracts?.map(contract => contract.mailingContact.name);
  const additionalReportInfo = [{ label: '', value: formatDate(new Date()) }];

  return {
    isDirty,
    managementCompanyName: managementCompany?.companyName || '',
    managementCompanyAddress: managementCompanyAddressLine,
    recipientAddressLines,
    title: reportData.documentName || '',
    propertyName: reportData.property?.name || '',
    reportInterval: reportData?.startDate && reportData?.endDate ? `${formatDate(moment(reportData?.startDate))} - ${formatDate(moment(reportData?.endDate))}` : '',
    propertyOwnerNames,
    additionalReportInfo,
  };
};
