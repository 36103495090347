import { GetUnitContractsUsingGETContractTypesEnum, UnitContractControllerApi, UnitContractProjectionDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import DEFAULT_DATA from 'lib/data';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import { ActiveContractsListContext } from './ActiveContractsListContext';
import { translations } from './translations';

const DEFAULT_EMPTY_LOADING = DEFAULT_DATA<UnitContractProjectionDto[]>([]).startLoading();

type Props = {
  propertyId: number | undefined,
  atDate?: moment.Moment,
  isComponentHidden?: boolean,
  contractTypes?: GetUnitContractsUsingGETContractTypesEnum[],
}
export const useActiveContractsList = ({
  propertyId,
  atDate = moment(),
  isComponentHidden,
  contractTypes,
}: Props) => {
  const activeContractsListContext = useContext(ActiveContractsListContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  if (activeContractsListContext === undefined) {
    throw new Error('useActiveContractsList must be used within a UnitContractsListContextProvider');
  }

  const {
    activeContractsList,
    setActiveContractsList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  } = activeContractsListContext;

  const isCacheValid = paramOfCachedValue?.propertyId === propertyId && atDate.isSame(paramOfCachedValue?.atDate, 'day');


  useEffect(() => {
    if (propertyId === undefined || isCacheValid || isComponentHidden) return;

    setParamOfFetchInProgress((prevParamOfFetchInProgress) => {
      // this must be inside the setParamOfFetchInProgress to make sure we have the latest
      // value of `paramOfFetchInProgress`, in case another instance of this hook
      // already triggered the fetch
      const doesCurrentParamMatchTheParamOfTheInProgressFetch = prevParamOfFetchInProgress?.propertyId === propertyId
        && atDate.isSame(prevParamOfFetchInProgress?.atDate, 'day');

      if (prevParamOfFetchInProgress === undefined || !doesCurrentParamMatchTheParamOfTheInProgressFetch) {
        fetchActiveContracts();

        return { propertyId, atDate };
      }

      return prevParamOfFetchInProgress;
    });
  }, [propertyId, isCacheValid, isComponentHidden]);


  const fetchActiveContracts = () => {
    if (propertyId === undefined) {
      throw new Error('Trying to fetch unit contracts with undefined propertyId');
    }

    abortController.current?.abort();
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setActiveContractsList(prev => prev.startLoading());
    unitContractControllerApi.getUnitContractsUsingGET({
      propertyId,
      atDate: atDate.format('YYYY-MM-DD'),
      contractTypes: contractTypes as unknown as GetUnitContractsUsingGETContractTypesEnum,
    }, { signal })
      .then((resp) => {
        setActiveContractsList(prev => prev.load(resp.filter(contract => contract.unitContractId !== undefined)));
        setParamOfCachedValue({ propertyId, atDate });
      })
      .catch((err) => {
        if (signal.aborted) return;
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.loadContractsError),
        });
        setActiveContractsList(prev => prev.failed(err));
      })
      .finally(() => {
        setParamOfFetchInProgress(undefined);
      });
  };

  return {
    activeContractsList: isCacheValid || activeContractsList.error
      ? activeContractsList
      : DEFAULT_EMPTY_LOADING,
    manuallyTriggerFetchActiveContracts: fetchActiveContracts,
  };
};
