import React, { useContext } from 'react';
import moment from 'moment';
import { LanguageContext } from 'contexts/LanguageContext';
import { DATE_FORMAT } from 'lib/Utils';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { getStateColor } from 'storybook-components/headers/utils';
import { translations } from '../translations';


export const useSpecialContributionListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.specialContribution.table.header.status),
      dataKey: ['exchangePlan', 'state'],
      render: (text: any, row: any) => (
        <ColorDot color={getStateColor(row.exchangePlan.state) as DotColorEnum}>
          {tl(translations.specialContribution.statuses[row.exchangePlan.state])}
        </ColorDot>
      ),
    },
    {
      title: tl(translations.specialContribution.table.header.type),
      dataKey: 'type',
      render: (text: any, row: any) => (
        <>
          {row.type ? tl(translations.specialContribution.types[row.type]) : '-'}
        </>
      ),
    },
    {
      title: tl(translations.specialContribution.table.header.propertyId),
      dataKey: ['exchangePlan', 'propertyIdInternal'],
    },
    {
      title: tl(translations.specialContribution.table.header.propertyName),
      dataKey: ['exchangePlan', 'propertyName'],
    },
    {
      title: tl(translations.specialContribution.table.header.amount),
      dataKey: 'totalAmount',
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.specialContribution.table.header.dueDate),
      dataKey: ['exchangePlan', 'dueDate'],
      render: (dueDate:string) => (dueDate ? moment.utc(dueDate).format(DATE_FORMAT) : '-'),
    },
    {
      title: tl(translations.specialContribution.table.header.statement),
      dataKey: ['exchangePlan', 'statement'],
    },
  ];
};
