
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  createContext, useMemo, useState,
} from 'react';
import { Order } from 'services/useSort';
import { AccountDto, PropertyContractsBalanceDto } from 'api/accounting';
import {
  ContractDunningLevel, DunningFee, OpenBankOrdersAndFutureBalance, OpenTransactionNumber, PropertyListWithOpenAccounts,
} from './interfaces';


const OrderOpenBalancesContext = createContext<{
  propertyAndContractBalanceList: DefaultDataInterface<PropertyListWithOpenAccounts[]>,
  setPropertyAndContractBalanceList: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyListWithOpenAccounts[]>>>,
  accounts: DefaultDataInterface<AccountDto[]>,
  setAccounts: React.Dispatch<React.SetStateAction<DefaultDataInterface<AccountDto[]>>>,
  dunningFees: DunningFee[],
  setDunningFees: React.Dispatch<React.SetStateAction<DunningFee[]>>,
  contractDunningLevels: ContractDunningLevel[],
  setContractDunningLevels: React.Dispatch<React.SetStateAction<ContractDunningLevel[]>>
  orderOpenbalanceFilterState: any,
  setOrderOpenbalanceFilterState: React.Dispatch<React.SetStateAction<any>>
  sortState: { field: keyof PropertyContractsBalanceDto, order: Order },
  setSortState: React.Dispatch<React.SetStateAction<{ field: keyof PropertyContractsBalanceDto, order: Order }>>,
  openBankOrdersAndFutureBalance: DefaultDataInterface<OpenBankOrdersAndFutureBalance>,
  setOpenBankOrdersAndFutureBalance: React.Dispatch<React.SetStateAction<DefaultDataInterface<OpenBankOrdersAndFutureBalance>>>,
}>(undefined);
OrderOpenBalancesContext.displayName = 'OrderOpenBalancesContext';

export const useOrderOpenBalancesContext = (usageName: string) => {
  const context = React.useContext(OrderOpenBalancesContext);

  if (context === undefined) {
    throw new Error(`${usageName} must be used within an OrderOpenBalancesContextProvider`);
  }

  return context;
};

export const OpenTransactionNumberContext = createContext<{
  openTransactionNumbers: OpenTransactionNumber,
  setOpenTransactionNumbers: React.Dispatch<React.SetStateAction<OpenTransactionNumber>>,
} | undefined>(undefined);


export const useOpenTransactionNumberContext = (usageName: string) => {
  const context = React.useContext(OpenTransactionNumberContext);

  if (context === undefined) {
    throw new Error(`${usageName} must be used within an OpenTransactionNumberContextProvider`);
  }

  return context;
};


export const OrderOpenBalancesSelectionContext = createContext<{
  expandedRowKeys: number[];
  innerTableSelectedRowKeysCurrent: string[];
  innerTableSelectedRowKeysTotal: string[];
  outerTableSelectedRowKeysCurrent: number[];
  outerTableSelectedRowKeysTotal: number[];
  cachedPropertyBalances: PropertyListWithOpenAccounts[];
  showAllSelected: boolean;
  setExpandedRowKeys: React.Dispatch<React.SetStateAction<number[]>>;
  setInnerTableSelectedRowKeysCurrent: React.Dispatch<React.SetStateAction<string[]>>;
  setInnerTableSelectedRowKeysTotal: React.Dispatch<React.SetStateAction<string[]>>;
  setOuterTableSelectedRowKeysCurrent: React.Dispatch<React.SetStateAction<number[]>>;
  setOuterTableSelectedRowKeysTotal: React.Dispatch<React.SetStateAction<number[]>>;
  setCachedPropertyBalances: React.Dispatch<React.SetStateAction<PropertyListWithOpenAccounts[]>>;
  setShowAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
    } | undefined>(undefined);

export const useOrderOpenBalancesSelectionContext = (usageName: string) => {
  const context = React.useContext(OrderOpenBalancesSelectionContext);

  if (context === undefined) {
    throw new Error(`${usageName} must be used within an OrderOpenBalancesSelectionContextProvider`);
  }

  return context;
};


const DEFAULT_SORT: { field: keyof PropertyContractsBalanceDto, order: Order } = {
  field: 'filteredPropertyBalance',
  order: 1,
};

type OrderOpenBalancesContextProps = {
  children: React.ReactNode,
}

const OrderOpenBalancesContextProvider = ({ children }: OrderOpenBalancesContextProps) => {
  const [propertyAndContractBalanceList, setPropertyAndContractBalanceList] = useState(DEFAULT_DATA<PropertyListWithOpenAccounts[]>([]));
  /**
   * Since we keep the selection even when the filter changes (i.e. the property balances list changes),
   * we need to cache the balances of selected properties in case they click "Proceed" when not all
   * the selected properties are visible in the current view.
   */
  const [cachedPropertyBalances, setCachedPropertyBalances] = useState<PropertyListWithOpenAccounts[]>([]);

  const [accounts, setAccounts] = useState(DEFAULT_DATA<AccountDto[]>([]));
  const [contractDunningLevels, setContractDunningLevels] = useState<ContractDunningLevel[]>([]);
  const [openTransactionNumbers, setOpenTransactionNumbers] = useState<OpenTransactionNumber>({});
  const [orderOpenbalanceFilterState, setOrderOpenbalanceFilterState] = useState<any>(undefined);
  const [sortState, setSortState] = useState(DEFAULT_SORT);
  const [openBankOrdersAndFutureBalance, setOpenBankOrdersAndFutureBalance] = useState(DEFAULT_DATA<OpenBankOrdersAndFutureBalance>(new Map()));
  const [dunningFees, setDunningFees] = useState<DunningFee[]>([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  /**
   * We need both `current` and `total` because otherwise, even if you keep the previous selection,
   * if they select something on the current view then the previous ones will get overwritten.
   */
  const [innerTableSelectedRowKeysCurrent, setInnerTableSelectedRowKeysCurrent] = useState<string[]>([]);
  const [innerTableSelectedRowKeysTotal, setInnerTableSelectedRowKeysTotal] = useState<string[]>([]);
  const [outerTableSelectedRowKeysCurrent, setOuterTableSelectedRowKeysCurrent] = useState<number[]>([]);
  const [outerTableSelectedRowKeysTotal, setOuterTableSelectedRowKeysTotal] = useState<number[]>([]);

  const [showAllSelected, setShowAllSelected] = useState(false);


  const orderOpenBalanceProviderValue = useMemo(() => ({
    propertyAndContractBalanceList,
    setPropertyAndContractBalanceList,
    accounts,
    setAccounts,
    contractDunningLevels,
    setContractDunningLevels,
    dunningFees,
    setDunningFees,
    openBankOrdersAndFutureBalance,
    setOpenBankOrdersAndFutureBalance,
    orderOpenbalanceFilterState,
    setOrderOpenbalanceFilterState,
    sortState,
    setSortState,
  }),
  [
    propertyAndContractBalanceList,
    accounts,
    contractDunningLevels,
    dunningFees,
    openBankOrdersAndFutureBalance,
    orderOpenbalanceFilterState,
    sortState,
  ]);

  const openTransactionNumbersProviderValue = useMemo(() => ({
    openTransactionNumbers,
    setOpenTransactionNumbers,
  }), [openTransactionNumbers]);


  const selectionProviderValue = useMemo(
    () => ({
      expandedRowKeys,
      innerTableSelectedRowKeysCurrent,
      innerTableSelectedRowKeysTotal,
      outerTableSelectedRowKeysCurrent,
      outerTableSelectedRowKeysTotal,
      cachedPropertyBalances,
      showAllSelected,
      setExpandedRowKeys,
      setInnerTableSelectedRowKeysCurrent,
      setInnerTableSelectedRowKeysTotal,
      setOuterTableSelectedRowKeysCurrent,
      setOuterTableSelectedRowKeysTotal,
      setCachedPropertyBalances,
      setShowAllSelected,
    }),
    [
      expandedRowKeys,
      innerTableSelectedRowKeysCurrent,
      outerTableSelectedRowKeysCurrent,
      innerTableSelectedRowKeysTotal,
      outerTableSelectedRowKeysTotal,
      cachedPropertyBalances,
      showAllSelected,
    ],
  );


  return (
    <OrderOpenBalancesContext.Provider value={orderOpenBalanceProviderValue}>
      <OpenTransactionNumberContext.Provider value={openTransactionNumbersProviderValue}>
        <OrderOpenBalancesSelectionContext.Provider value={selectionProviderValue}>
          {children}
        </OrderOpenBalancesSelectionContext.Provider>
      </OpenTransactionNumberContext.Provider>
    </OrderOpenBalancesContext.Provider>
  );
};

export default OrderOpenBalancesContextProvider;
