import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import InputNumber from 'elements/Inputs/NumberInput/InputNumber';
import React, { useContext, useState } from 'react';
import { inviteUsersTranslations } from 'pages/Users/InviteUsers/inviteUsersTranslations';
import './AddUsersComponent.scss';

/**
 * This component is designed to be used independently. It can be extracted in a generic standalone component since it does not use any context
 * and the result is given back through props callback
 */
interface AddUsersComponentProps {
  onClickAddUsers: (value: number) => void;
}

export const AddUsersComponent = ({ onClickAddUsers }:AddUsersComponentProps) => {
  const { tl } = useContext(LanguageContext);
  const [count, setCount] = useState(1);

  const submit = () => { onClickAddUsers(count); setCount(1); };

  return (
    <div className="AddUsers">
      <InputNumber className="Input" min={0} value={count} onChange={setCount} />
      <Button onClick={submit}>{tl(inviteUsersTranslations.header.buttons.add)}</Button>
    </div>
  );
};
