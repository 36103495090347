import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useInvalidateAllContactsListCache } from 'services/ContactsList/useInvalidateAllContactsListCache';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { LanguageContext } from './LanguageContext';
import { translations } from '../elements/Translation/translations';
import { convertToBEModel, convertToFEModel } from './functions/ContactEditingFunctions';
import { AutoSelectFunctionType } from './PersonEditingContext';
import { getCompanySectionErrors } from './util/ContactSectionFieldsConfiguration';
import { showNotification } from '../lib/Notification';
import { ContactListContext } from './ContactListContext';

export const CompanyEditingContext: any = React.createContext({});

const DUPLICATE_STATUS_CODE = 409;

export default function CompanyEditingProvider({ children }: any) {
  const defaultCompany = {
    type: 'COMPANY',
    addressNumber: 1,
    employeeNumber: 1,
    bankAccountNumber: 0,
  };

  const { invalidateCache } = useInvalidateAllContactsListCache();

  const defaultAutoSelectFunction: AutoSelectFunctionType = (id?: number) => {
  };
  const [savedCompany, setSavedCompany] = useState(DEFAULT_DATA<any>(defaultCompany));
  const [company, setCompany] = useState(DEFAULT_DATA<any>(defaultCompany));
  const [duplicate, setDuplicate] = useState(false);
  const isDirty = useRef(false);
  const [firstRecipientNameTouched, setFirstRecipientNameTouched] = useState(false);
  const [autoSelectCompanyAfterCreation, setAutoSelectCompanyAfterCreation]: [AutoSelectFunctionType, any] = useState(() => defaultAutoSelectFunction);

  const { tl } = useContext(LanguageContext);
  const { onLoadCompanyList } = useContext(ContactListContext);

  useEffect(() => {
    let timer: any;
    if (isDirty.current) {
      timer = setTimeout(() => {
        verifyDuplicate();
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [company]);

  const setDirty = (value: boolean) => {
    isDirty.current = value;
  };


  const clearCompany = () => {
    setCompany(DEFAULT_DATA<any>(defaultCompany));
    setFirstRecipientNameTouched(false);
  };

  const onLoadCompany = (id: string): void => {
    setCompany(state => state.startLoading());
    setSavedCompany(state => state.startLoading());
    backend.get(`${endpointUrls.CONTACT}/${id}`, {})
      .then((response: any) => {
        const FEData = convertToFEModel(response);
        setCompany(state => state.load(FEData));
        setSavedCompany(state => state.load(FEData));

        // only auto-fill when creating, not when editing
        setFirstRecipientNameTouched(true);
      })
      .catch(() => {
        setCompany(state => state.failed());
        setSavedCompany(state => state.failed());
        showNotification({
          key: 'loadCompanyError',
          message: tl(translations.notifications.serviceCompanyEditingContext.loadError.message),
          description: tl(translations.notifications.serviceCompanyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onSaveCompany = (savingSectionNumber: number) => {
    setCompany(company.startLoading());
    setDirty(false);
    let p;
    const data = convertToBEModel(company.data);
    if (company.data.id) {
      p = backend.put(`${endpointUrls.CONTACT}/${data.id}`, data);
    } else {
      p = backend.post(`${endpointUrls.CONTACT}`, data);
    }
    p.then((response: any) => {
      const FEData = convertToFEModel(response);
      setCompany(state => state.load(FEData, {}, true));
      setSavedCompany(state => state.load(FEData, {}, true));
      onLoadCompanyList(true);
      showNotification({
        key: 'saveCompanySuccess',
        message: tl(translations.notifications.contactEditingContext.success.message),
        type: 'success',
      });

      invalidateCache();
    }).catch((error) => {
      setDirty(true);
      if (error.title === 'Validation error') {
        const currentSectionErrors: any = getCompanySectionErrors(savingSectionNumber || 1, error.validationErrors);

        if (!_.isEmpty(currentSectionErrors)) {
          showNotification({
            key: 'invalidCompanyError',
            message: tl(translations.notifications.contactEditingContext.validationError.message),
            type: 'error',
          });
        } else if (!_.isEmpty(error.validationErrors)) {
          showNotification({
            key: 'invalidContactError',
            message: tl(translations.notifications.contactEditingContext.validationError.differentSection),
            type: 'warning',
          });
        }
        setCompany(state => state.load(state.data, currentSectionErrors, false));
      } else {
        setCompany(state => state.failed());
        showNotification({
          key: 'loadCompanyError',
          message: tl(translations.notifications.serviceCompanyEditingContext.saveError.message),
          description: tl(translations.notifications.serviceCompanyEditingContext.saveError.description),
          type: 'error',
        });
      }
    });
    return p;
  };

  const verifyDuplicate = () => {
    backend.post(`${endpointUrls.CONTACT}/duplicate`, company.data)
      .then(() => {
        setDuplicate(false);
      })
      .catch((response) => {
        if (response.status === DUPLICATE_STATUS_CODE) setDuplicate(true);
      });
  };

  const onChangeCompanyData = (value: any) => {
    setCompany((prev) => {
      // set first recipient's name to companyName if it wasn't touched yet (on creation)
      if (!firstRecipientNameTouched && prev.data.companyName !== value.companyName) {
        return prev.load({
          ...value,
          addresses: [
            {
              ...(prev.data?.addresses?.[0] ?? {}),
              recipient: value.companyName,
            },
            ...(prev.data.addresses?.slice(1) ?? []),
          ],
        });
      }

      // if first recipient's name was changed by the user then set touched to true
      if (prev.data.addresses?.[0]?.recipient !== value.addresses?.[0]?.recipient) {
        setFirstRecipientNameTouched(true);
      }

      return prev.load(value);
    });
    setDirty(true);
  };

  return (
    <CompanyEditingContext.Provider value={{
      company,
      savedCompany,
      setCompany,
      duplicate,
      autoSelectCompanyAfterCreation,
      setAutoSelectCompanyAfterCreation,
      isDirty: isDirty.current,
      setDirty,
      onLoadCompany,
      onSaveCompany,
      clearCompany,
      onChangeCompanyData,
    }}
    >
      {children}
    </CompanyEditingContext.Provider>
  );
}

CompanyEditingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
