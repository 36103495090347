import {
  useContext, useEffect, useState,
} from 'react';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { showNotification } from '../lib/Notification';
import { AuthContext } from 'contexts/AuthContext';
import { AccountControllerApi } from 'api/accounting';

export enum AccountType {
  ASSET = 'ASSET,',
  LIABILITY = 'LIABILITY,',
  CAPITAL = 'CAPITAL,',
  DRAWING = 'DRAWING,',
  REVENUE = 'REVENUE,',
  EXPENSE = 'EXPENSE',
}

export interface Account {
  id: number,
  name: string,
  externalRef: string,
  code: string,
  parentAccountCode: string,
  divisible: boolean,
  domainId: number,
  propertyHrId: string,
  closed: boolean,
  accountType: AccountType,
  leaf: boolean,
}

export interface AccountFE extends Account {
  isParent: boolean,
  pathString: string,
  matchScore: number,
}

interface UseAccountListProps {
  propertyHrId?: string,
}

export function useAccountList({ propertyHrId }: UseAccountListProps) {
  const { tl } = useContext(LanguageContext);
  const [allAccounts, setAllAccounts] = useState(DEFAULT_DATA<Account[]>([]));
  const { apiConfiguration } = useContext(AuthContext);
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));

  // Clean up list is property changes
  useEffect(() => {
    setAllAccounts(DEFAULT_DATA<Account[]>([]));
    onLoadAllAccounts();
  }, [propertyHrId]);

  const onLoadAllAccounts = () => {
    if (!propertyHrId) {
      return;
    }
    setAllAccounts(prevValue => prevValue.startLoading());
    accountControllerApi.filterSimpleAccountsUsingGET({ propertyHrIds: [propertyHrId] })
      .then((response) => {
        setAllAccounts(prevValue => prevValue.load(response as unknown as Account[]));
      })
      .catch(errorLoading);
  };

  const errorLoading = () => {
    setAllAccounts(prev => prev.failed());
    showNotification({
      key: 'loadAccountListError',
      message: tl(translations.notifications.accountContext.loadAccountError.message),
      description: tl(translations.notifications.accountContext.loadAccountError.description),
      type: 'error',
    });
  };

  const getAccountByCode = (code: string) => {
    if (allAccounts.data) {
      const foundAccounts = allAccounts.data.filter(account => account.code === code);
      if (foundAccounts[0]) {
        return foundAccounts[0];
      }
    }
    return null;
  };

  return {
    allAccounts, getAccountByCode,
  };
}
