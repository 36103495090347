import { BankDetailsControllerApi } from 'api/accounting/apis/BankDetailsControllerApi';
import { BankDataDto } from 'api/accounting/models/BankDataDto';
import { AuthContext } from 'contexts/AuthContext';
import { IBAN_MOD97_PATTERN } from 'lib/Utils';
import { useContext, useRef, useState } from 'react';

export const useBankInformation = () => {
  const { apiConfiguration } = useContext(AuthContext);

  const [bankInformation, setBankInformation] = useState<BankDataDto>();
  const [paramOfCachedValue, setParamOfCachedValue] = useState<string | undefined>(undefined);
  const paramOfFetchInProgressState = useState<string | undefined>(undefined);
  const setParamOfFetchInProgress = paramOfFetchInProgressState[1]; // we only need the setter

  const abortController = useRef<AbortController | undefined>(undefined);
  const bankDetailsControllerApi = new BankDetailsControllerApi(apiConfiguration('accounting'));

  const fetchBankInformation = (iban: string | undefined, callback: (bankInformation: BankDataDto) => void) => {
    if (iban === undefined) return;

    const formattedIban = iban.replaceAll(/\s/g, '').toUpperCase();

    if (paramOfCachedValue === formattedIban && bankInformation !== undefined) {
      callback(bankInformation);
      return;
    }

    if (!IBAN_MOD97_PATTERN.test(formattedIban)) {
      console.warn(`Not fetching bank information because ${formattedIban} is an invalid iban pattern.`);
      return;
    }

    setParamOfFetchInProgress((prevParamOfFetchInProgress) => {
      // this must be inside the setParamOfFetchInProgress to make sure we have the latest
      // value of `paramOfFetchInProgress`, in case another instance of this hook
      // already triggered the fetch
      const doesCurrentParamMatchTheParamOfTheInProgressFetch = prevParamOfFetchInProgress === formattedIban;

      if (prevParamOfFetchInProgress === undefined || !doesCurrentParamMatchTheParamOfTheInProgressFetch) {
        // fetch bank info based on iban

        // if params changed since last initiated fetch then abort the in-progress fetch
        abortController.current?.abort();
        // create new abort controllers
        abortController.current = new AbortController();
        const { signal } = abortController.current;
        bankDetailsControllerApi.checkBankDetailsUsingGET({ iban: formattedIban }, { signal })
          .then((resp) => {
            setBankInformation(resp);
            callback(resp);
            setParamOfCachedValue(formattedIban);
          })
          .catch((err) => {
            if (signal.aborted) return;
            console.error(err);
            setBankInformation(undefined);
            setParamOfCachedValue(undefined);
          })
          .finally(() => {
            setParamOfFetchInProgress(undefined);
          });
        return formattedIban;
      }

      return prevParamOfFetchInProgress;
    });
  };

  return {
    fetchBankInformation,
  };
};
