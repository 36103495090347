import { BuildingProjection, UnitProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';


type Props = {
  children: React.ReactNode;
};


export const BuildingsListContext = createContext<{
  buildingsList: DefaultDataInterface<BuildingProjection[]>,
  setBuildingsList: React.Dispatch<React.SetStateAction<DefaultDataInterface<BuildingProjection[]>>>,
  paramOfCachedValue: number | undefined,
  setParamOfCachedValue: React.Dispatch<React.SetStateAction<number | undefined>>,
  setParamOfFetchInProgress: React.Dispatch<React.SetStateAction<number | undefined>>,
} | undefined>(undefined);


const BuildingsListContextProvider = ({ children }: Props) => {
  const [buildingsList, setBuildingsList] = useState(DEFAULT_DATA<BuildingProjection[]>([]));
  const [paramOfCachedValue, setParamOfCachedValue] = useState<number | undefined>(undefined);
  const [paramOfFetchInProgress, setParamOfFetchInProgress] = useState<number | undefined>(undefined);

  // putting them in objects so they are easier to destructure when using the contexts
  // no need to memoize since they're all in the same object
  const providerValue = {
    buildingsList,
    setBuildingsList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  };

  return (
    <BuildingsListContext.Provider value={providerValue}>
      {children}
    </BuildingsListContext.Provider>
  );
};

export default BuildingsListContextProvider;
