import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  AccountControllerApi,
  AccountDistributionControllerApi,
  AccountDistributionProjectionDto,
  AccountDto,
  AccountTypeDtoAccountTypeEnum,
  DistributionSetControllerApi,
  DistributionSetDtoDistributionModeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { range } from 'lodash';

import { accountDistributionTranslations } from '../../translations';

export const useAccountDistributionCopyModal = ({ accountDistribution, propertyHrId, closeModal } : {accountDistribution: AccountDistributionProjectionDto, propertyHrId: string, closeModal: () => void}) => {
  const [propertyAccounts, setPropertyAccounts] = useState<AccountDto[]>([]);
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const accountDistributionControllerApi = new AccountDistributionControllerApi(apiConfiguration('accounting'));
  const distributionSetControllerApi = new DistributionSetControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (!propertyHrId) return;
    accountControllerApi.getAccountsMatchingRegexUsingGET({
      propertyHrIds: [propertyHrId],
      accountCodeRegex: '^(3|41|440|6|8|9).*',
    })
      .then((accounts) => {
        setPropertyAccounts(accounts.filter(a => a.leaf
                && [AccountTypeDtoAccountTypeEnum.EXPENSE,
                  AccountTypeDtoAccountTypeEnum.REVENUE,
                  AccountTypeDtoAccountTypeEnum.CAPITAL]
                  .includes(a.accountType.accountType)));
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          key: 'loadAccountsError',
          message: tl(accountDistributionTranslations.notifications.loadAccountsError),
          type: 'error',
        });
      });
  }, [propertyHrId]);


  const getDistributionModeOfDistributionSet = (distributionSetId: number): Promise<{
    distributionMode: DistributionSetDtoDistributionModeEnum | null;
  }> => {
    if (!distributionSetId) {
      return Promise.resolve({ distributionMode: null });
    }

    return distributionSetControllerApi.getDistributionByIdUsingGET({ distributionSetId })
      .then((ds) => {
        const { distributionMode } = ds;

        return { distributionMode };
      });
  };


  const onOk = () => {
    setLoading(true);

    getDistributionModeOfDistributionSet(accountDistribution.distributionSetId)
      .then(({ distributionMode }) => {
        accountDistributionControllerApi.createAccountDistributionsUsingPOST({
          accountDistributions: getAccountDistributionCreateDtos(distributionMode),
        })
          .then(() => {
            showNotification({
              key: 'copySuccess',
              message: tl(accountDistributionTranslations.notifications.copySuccess),
              type: 'success',
            });
            closeModal();
            resetData();
          })
          .catch((err) => {
            console.error(err);
            showNotification({
              key: 'copyError',
              message: tl(accountDistributionTranslations.notifications.copyError),
              description: tl(accountDistributionTranslations.notifications.validationError),
              type: 'error',
            });
          });
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          key: 'copyError',
          message: tl(accountDistributionTranslations.notifications.copyError),
          description: tl(accountDistributionTranslations.notifications.validationError),
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccountDistributionCreateDtos = (distributionMode: DistributionSetDtoDistributionModeEnum) => selectedYears.map(year => selectedAccounts.map(code => ({
    accountCode: code,
    economicYear: year,
    propertyId: accountDistribution.propertyId,
    unitSetId: accountDistribution.unitSetId,
    heatingCenterId: accountDistribution.heatingCenterId,
    apply: accountDistribution.apply,
    // do not copy Direct Cost distribution set
    distributionSetId: distributionMode === DistributionSetDtoDistributionModeEnum.DIRECT_COST
      ? null
      : accountDistribution.distributionSetId,
  }))).flat();

  const onCancel = () => {
    closeModal();
    resetData();
  };

  const destinationAccounts = propertyAccounts.map(a => ({
    value: a.code,
    label: [a.code, ' ', a.name].join(''),
  }));

  const getEconomicYearOptions = () => {
    const from = new Date().getFullYear() - 3;
    const to = new Date().getFullYear() + 3;
    const years = range(from, to).reverse().map((number: number) => number.toString());

    return years.map(year => ({ label: year, value: year }));
  };

  const resetData = () => {
    setSelectedAccounts([]);
    setSelectedYears([]);
  };

  return {
    selectedYears,
    setSelectedYears,
    selectedAccounts,
    setSelectedAccounts,
    onOk,
    onCancel,
    destinationAccounts,
    economicYearOptions: getEconomicYearOptions(),
    loading,
  };
};
