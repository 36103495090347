import { anyMatch, createFieldRegExp } from '../../lib/Utils';

const ownersMeetingSectionFieldsMap: any = {
  1: [
    'propertyId',
    'meetingType',
  ],
  2: [
    'location',
    'startTime',
    'endTime',
    'date',
  ],
  3: [
    'title',
    'resolutionRecordText',
  ],
  4: [
    'houseMoneySettlementEconomicYear',
    'economicPlanId',
  ],
  5: [
    'contactByPreference',
    'contactByEmail',
    'contactByPortal',
    'contactByPost',
    'coverLetterTemplate',
    'templateId',
    'watermarkId',
    'selectedUnitId',
  ],
};

const getSection3Fields = (): string[] => ownersMeetingSectionFieldsMap[3].map((field: string) => `agendaItems\\[.+\\]\\.${field}`);

const getOwnersMeetingSectionFieldsMap = (savingSectionNumber: number) => {
  let fields: string[] = [];
  switch (savingSectionNumber) {
  case 3:
    fields = getSection3Fields();
    break;
  default:
    fields = ownersMeetingSectionFieldsMap[savingSectionNumber];
  }

  return fields.map((field: string) => createFieldRegExp(field));
};

export const getSectionErrors = (sectionNumber: number, errors: any) => {
  const fields = getOwnersMeetingSectionFieldsMap(sectionNumber);
  const sectionErrors: any = {};

  Object.keys(errors)
    .filter((key: string) => anyMatch(fields, key))
    .forEach((key: string) => {
      // map Dto field to the corresponding FE input key
      sectionErrors[key] = errors[key];
    });

  return sectionErrors;
};
