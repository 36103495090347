import { useState } from 'react';

export const useControlHoverStates = () => {
  const [lastHoverOnProperty, setLastHoverOnProperty] = useState(false);

  const onMouseLeavePropertyOption = () => {
    setLastHoverOnProperty(true);
  };

  const onMouseEnterUnitOptions = () => {
    setLastHoverOnProperty(false);
  };

  return {
    onMouseLeavePropertyOption,
    onMouseEnterUnitOptions,
    lastHoverOnProperty,
  };
};
