export const translations: any = {
  properties: {
    en: 'Properties',
    de: 'Objekte',
  },
  changeowner: {
    en: 'Owner change on unit:',
    de: 'Eigentümerwechsel am Einheit:',
  },
  invoice: {
    en: 'Invoice',
    de: 'Rechnungen',
  },
  persons: {
    en: 'People',
    de: 'Personen',
  },
  companies: {
    en: 'Company',
    de: 'Firma',
  },
  viewperson: {
    en: 'View person:',
    de: 'Person anzeigen:',
  },
  viewcompany: {
    en: 'View company:',
    de: 'Firma anzeigen',
  },
  view: {
    en: '',
    de: '',
  },
  unitfigures: {
    en: '',
    de: '',
  },
  banktransactions: {
    en: 'Bank transactions',
    de: 'Bankumsätze',
  },
  allocate: {
    en: 'Allocate',
    de: 'Zuweisen',
  },
  bankaccount: {
    en: 'Bank account',
    de: 'Bankkonto',
  },
  accountbalances: {
    en: 'Account balances',
    de: 'Kontensalden',
  },
  booking: {
    en: 'Create booking',
    de: 'Buchen',
  },
  create: {
    en: '',
    de: '',
  },
  account: {
    en: 'Account',
    de: 'Sachkonto',
  },
  distributionkeys: {
    en: 'Distribution keys',
    de: 'Verteilerschlüssel',
  },
  distributionkeysdetails: {
    en: 'Distribution keys details',
    de: 'Verteilerschlüssel Details',
  },
  distribution: {
    en: 'Distribution',
    de: 'Verteilung',
  },
  unitset: {
    en: 'Unit set',
    de: 'Einheitenset',
  },
  accountmapping: {
    en: 'Account Mapping',
    de: 'Kontenzuweisung',
  },
  housemoneysettlement: {
    en: 'House money settlement',
    de: 'Hausgeldabrechnung',
  },
  activate: {
    en: 'Activation',
    de: 'Aktivierung',
  },
  specialcontribution: {
    en: 'Special Contribution',
    de: 'Sonderumlage',
  },
  opscostreports: {
    en: 'Ops Cost Reports',
    de: 'Betriebskostenabrechnung',
  },
  economicplan: {
    en: 'Economic plan',
    de: 'Wirtschaftsplan',
  },
  wka: {
    en: 'WKA',
    de: 'WKA',
  },
  costs: {
    en: 'Unit costs',
    de: 'Einheit Kosten',
  },
  payments: {
    en: 'Payment',
    de: 'Zahlungslauf',
  },
  standingorders: {
    en: 'Standing order',
    de: 'Dauerauftrag',
  },
  directdebits: {
    en: 'Direct debit',
    de: 'Lastschriftmandat',
  },
  transactionimports: {
    en: 'Transaction imports',
    de: 'Transaktionsimporte',
  },
  ownersmeeting: {
    en: 'Owners meeting',
    de: 'Eigentümerversammlung',
  },
  invitation: {
    en: '- Invitation',
    de: '- Einladung',
  },
  protocol: {
    en: '- Protocol',
    de: '- Protokoll',
  },
  resolutionrecords: {
    en: 'Resolution record',
    de: 'Beschlusssammlung',
  },
  edit: {
    en: 'Edit:',
    de: 'Bearbeiten:',
  },
  letters: {
    en: 'Letter',
    de: 'Brief',
  },
  settings: {
    en: 'Settings',
    de: 'Einstellungen',
  },
  watermarks: {
    en: 'Watermark',
    de: 'Briefpapier',
  },
  propertyview: {
    en: '',
    de: '',
  },
  property_view: {
    en: '',
    de: '',
  },
  document: {
    en: 'Document',
    de: 'Dokument',
  },
  creditor: {
    en: 'Creditor:',
    de: 'Rechnungssteller',
  },
  unit: {
    en: 'Unit',
    de: 'Einheit',
  },
  contract: {
    en: 'Contract',
    de: 'Vertrag',
  },
  profitandloss: {
    en: 'Profit and Loss Reports',
    de: 'Eigentümerbericht',
  },
  messagesending: {
    en: 'Message sending',
    de: 'Nachrichtenversand',
  },
  users: {
    en: 'User Management',
    de: 'Benutzerverwaltung',
  },
  execute: {
    en: 'Book',
    de: 'Buchen',
  },
  orderopenbalances: {
    en: 'Order Open Balances',
    de: 'Offene Salden',
  },
  documents: {
    en: 'Documents',
    de: 'Dokumente',
  },
  messages: {
    en: 'Messages',
    de: 'Nachrichten',
  },
  // This is added so that the /order-open-balances/[new|list] path would show only "Order Open Balances" without the 'new' | 'list' sub-path
  new: {
    en: '',
    de: '',
  },
  list: {
    en: '',
    de: '',
  },
  preview: {
    en: 'Document preview',
    de: 'Dokumentvorschau',
  },
};
