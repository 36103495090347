import './UnassignModal.scss';

import { useContext } from 'react';

import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import {
  INTEGRATION_APPS,
} from 'storybook-components/TicketIntegrationSection/interfaces';

import { translations } from './translations';

interface UnassignModalProps {
    visible: boolean,
    onCancel: () => void,
    onOk: (string) => void,
    app: INTEGRATION_APPS,
}

export const UnassignModal = ({
  app, onCancel, onOk, visible,
}: UnassignModalProps) => {
  const { tl } = useContext(LanguageContext);

  return (
    <Modal
      className="UnassignModal"
      visible={visible}
      onCancel={onCancel}
      title={tl(translations.unassignModal.title)}
      footer={[
        <Button
          key="cancel"
          type="ghost"
          onClick={onCancel}
        >
          {tl(translations.unassignModal.buttons.cancel)}
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={onOk}
        >
          {tl(translations.unassignModal.buttons.unassign)}
        </Button>,
      ]}
    >
      <p>{tl(translations.unassignModal.description)(app)}</p>
    </Modal>
  );
};
