import { useContext } from 'react';

import {
  Filter,
} from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';

import { usePropertyAdministrationTypeFilterOption } from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';
import {
  FindFilteredInvoicesUsingGETDirectionEnum,
} from '../../../api/accounting';
import { hrIdMatcherParser } from '../../../lib/Utils';
import { invoiceListTranslations } from './translations';

export enum InvoiceStateEnum {
  NEW = 'NEW',
  READYFORAPPROVAL = 'READY_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  SENTTOBANK = 'SENT_TO_BANK',
  PAID = 'PAID',
  RECURRING = 'RECURRING',
  RECURRINGSTOPPED = 'RECURRING_STOPPED',
  DELETED = 'DELETED',
  OVERDUE = 'OVERDUE'
}

export default function useInvoiceFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);
  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  const directions = [
    {
      label: tl(invoiceListTranslations.searchBar.directions.outgoing),
      value: FindFilteredInvoicesUsingGETDirectionEnum.OUTGOING,
    },
    {
      label: tl(invoiceListTranslations.searchBar.directions.incoming),
      value: FindFilteredInvoicesUsingGETDirectionEnum.INCOMING,
    },
  ];

  return [
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.internalNr),
      key: 'propertyIdInternal',
    },
    {
      type: 'enum',
      name: tl(invoiceListTranslations.table.headers.state),
      key: 'state',
      options: Object.values(InvoiceStateEnum).map(value => ({
        label: tl(invoiceListTranslations.table.states[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.invoiceNumber),
      key: 'invoiceNumber',
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.invoiceHrId),
      key: 'invoiceHrId',
      matcher: hrIdMatcherParser('INV'),
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.property),
      key: 'property',
    },
    administrationTypeFilterOption,
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.administrator),
      key: 'administrator',
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.accountant),
      key: 'accountant',
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.businessPartnerName),
      key: 'counterpartName',
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.receiverIban),
      key: 'counterpartIban',
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.counterpartContactId),
      key: 'counterpartContactId',
      visibleInSearchDropdown: false,
    },
    {
      type: 'enum',
      name: tl(invoiceListTranslations.searchBar.direction),
      key: 'direction',
      options: directions,
      multiValue: false,
    },
    {
      type: 'decimal',
      name: `${tl(invoiceListTranslations.table.headers.amount)}`,
      key: 'amount',
    },
    {
      type: 'decimal',
      name: `Min ${tl(invoiceListTranslations.table.headers.amount)}`,
      key: 'minAmount',
    },
    {
      type: 'decimal',
      name: `Max ${tl(invoiceListTranslations.table.headers.amount)}`,
      key: 'maxAmount',
    },
    {
      type: 'date',
      name: `Min ${tl(invoiceListTranslations.table.headers.dueDate)}`,
      key: 'minDueDate',
    },
    {
      type: 'date',
      name: `Max ${tl(invoiceListTranslations.table.headers.dueDate)}`,
      key: 'maxDueDate',
    },
    {
      type: 'text',
      name: tl(invoiceListTranslations.table.headers.createdBy),
      key: 'creator',
    },
    {
      type: 'date',
      name: tl(invoiceListTranslations.table.headers.invoiceDate),
      key: 'invoiceDate',
    },
    {
      type: 'date',
      name: tl(invoiceListTranslations.searchBar.invoiceDateFrom),
      key: 'invoiceDateFrom',
    },
    {
      type: 'date',
      name: tl(invoiceListTranslations.searchBar.invoiceDateTo),
      key: 'invoiceDateTo',
    },
  ];
}
