import React, {
  ReactElement,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import './FormSection.scss';
import Button from 'elements/Buttons/Button/Button';
import _ from 'lodash';
import Icon from '@ant-design/icons';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import { deleteKey, readValue, setValue } from '../../../lib/Utils';
import CheckboxInput from '../../Inputs/CheckboxInput/CheckboxInput';
import SelectInput from '../../Inputs/SelectInput/SelectInput';
import InputRenderer from './InputRenderer';
import GroupContent from './GroupContent';
import ToggleButton from '../../Buttons/ToggleButton/ToggleButton';
import DeleteButton from './DeleteButton';
import { useDeleteButton } from './UseDeleteButton';

interface input {
  type: string,
  key: string,
  minWidth?: string,
  maxWidth?: string,
  props?: any,
  validator?: (value: any) => boolean,
}

interface link {
  linked: string,
  options: { label: string, value: string }[],
  key: string,
}

interface sectionRows {
  rows: sectionRow[],
}

interface sectionRow {
  columns: sectionColumn[],
}

interface sectionColumn {
  inputs: input[],
}

interface subSection {
  title?: string,
  titleNote?: string,
  sectionId: string,
  content: input[][] | sectionRows | JSX.Element,
  link?: link,
  isClosable?: boolean,
  defaultValue?: any,
  additionalButton?: any,
  deleteButton?: any,
  disabled?: boolean
}

interface subSectionGroups {
  title?: string,
  groupNumber: number | string,
  sectionId: string,
  content: subSection[]
  additionalButton?: any,
  deleteButton?: any,
}

interface formSectionProps {
  sectionNumber: number | string,
  sectionIcon?: any,
  sectionTitle: string | ReactElement,
  sectionId?: string,
  open: boolean,
  buttonLoading: boolean,
  buttonDisabled: boolean
  onClickHeader?: (event: any) => void,
  content: subSection[],
  contentGroups?: subSectionGroups[],
  onSubmit: () => void,
  value: any,
  addDirt: () => void,
  onChange?: (value: any) => void,
  validationErrors?: any,
  hideSaveButton?: boolean,
  inputsDisabled?: boolean,
  startingElement?: JSX.Element,
  loading?: boolean,
  additionalButton?: any,
  deleteButton?: any,
  deleteFunction?: (key: any, nr: any) => void,
  onHoverDisplayButton?: boolean,
  hoverButton?: any,
  hoverButtonFunction: (event: any) => void,
  hoverButtonText: string,
  sectionDisabled?: boolean,
  isValid?: boolean,
}


const getSectionNumberBoxClassname = (isValid?: boolean) => {
  if (isValid === false) {
    return 'section-number-box section-number-box-invalid';
  }

  return 'section-number-box';
};


const FormSection = (props: formSectionProps): JSX.Element => {
  const {
    sectionNumber,
    sectionIcon,
    sectionTitle,
    sectionId,
    open,
    buttonLoading,
    buttonDisabled,
    onClickHeader,
    content,
    contentGroups,
    onSubmit,
    value,
    addDirt,
    validationErrors,
    hideSaveButton,
    sectionDisabled,
    inputsDisabled: disabled,
    startingElement,
    loading,
    additionalButton,
    deleteButton,
    deleteFunction,
    onHoverDisplayButton,
    hoverButtonFunction,
    hoverButtonText,
    isValid,
  } = props;
  const { tl } = useContext(LanguageContext);
  const [deletionKey, setDeletionKey] = useState('');
  const [nrDeletionKey, setNrDeletionKey] = useState('');
  const [deletionCallback, setDeletionCallback]: [Function, any] = useState(() => () => {
  });
  const updatedValueRef = React.useRef(value);
  updatedValueRef.current = value;

  const [display, setDisplay] = useState('none');

  const deleteButtonProps = useDeleteButton({
    deletionKey,
    nrDeletionKey,
    value: updatedValueRef,
    onChange: props.onChange,
    addDirt,
    loading,
    deleteFunction,
    deletionCallback,
  });

  useEffect(() => {
    if (loading || !open) return;
    const newValue: any = _.cloneDeep(value);
    content.forEach((subSection: any) => {
      if (subSection.isClosable && !subSection.link) {
        const subsectionContent = Array.isArray(subSection.content) ? subSection.content : [];
        const subsectionKeys = subsectionContent.flat().map((item: any) => item.key);
        newValue[`${subSection.sectionId}Open`] = subsectionKeys.reduce((acc: boolean, key: string) => {
          const v = readValue(value, key);
          if (value && v && (!_.isArray(v) || _.compact(v).length !== 0)) {
            acc = true;
          }
          return acc;
        }, false);
      }
    });
    if (!!props.onChange && content.flat().filter((sc: any) => sc.isClosable && !sc.link).length > 0 && open) {
      props.onChange(newValue);
    }
  }, [loading, open]);

  const onAddEntity = (key: string, firstValue: number) => {
    const newValue: any = _.cloneDeep(updatedValueRef.current) || {};
    const currentValue = readValue(newValue, key);
    if (!currentValue) {
      setValue(newValue, key, firstValue === undefined ? 2 : firstValue);
    } else {
      setValue(newValue, key, currentValue + 1);
    }
    addDirt();
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const onToggle = (key: string) => {
    const newValue: any = _.cloneDeep(updatedValueRef.current) || {};
    const currentValue = readValue(newValue, key);
    if (!currentValue) {
      setValue(newValue, key, true);
    } else {
      setValue(newValue, key, !currentValue);
      addDirt();
    }
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const onChange = (v: any, key: string) => {
    const newValue: any = _.cloneDeep(updatedValueRef.current) || {};
    if (!(typeof v === 'undefined' || v === '' || v === null || v.length === 0)) {
      setValue(newValue, key, v);
    } else {
      deleteKey(newValue, key);
    }
    if (updatedValueRef.current && updatedValueRef.current[key] !== v && !hideSaveButton) {
      addDirt();
    }
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const renderLink = (link: link) => {
    const linked = readValue(value, link.linked);
    const v = readValue(value, link.key);
    return (
      <div className="link">
        <CheckboxInput
          onChange={(val) => {
            const newValue: any = _.cloneDeep(value);
            setValue(newValue, link.linked, val);
            setValue(newValue, link.key, val ? link.options[0].value : null);
            addDirt();
            if (props.onChange) {
              props.onChange(newValue);
            }
          }}
          label={tl(translations.elements.section.copy)}
          value={linked}
          disabled={disabled}
        />
        <SelectInput
          label=""
          onChange={(val) => {
            const newValue: any = _.cloneDeep(value);
            setValue(newValue, link.key, val);
            addDirt();
            if (props.onChange) {
              props.onChange(newValue);
            }
          }}
          value={v}
          options={link.options}
          disabled={!linked || disabled}
        />
      </div>
    );
  };

  const onClickToggleButton = (val: boolean, key: string) => {
    const newValue: any = _.cloneDeep(value);
    setValue(newValue, key, val);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };
  const renderToggleButton = (subsectionSectionId: string) => {
    const oldOpenValue = value ? readValue(value, `${subsectionSectionId}Open`) : false;
    return (
      <ToggleButton
        onClick={() => onClickToggleButton(!oldOpenValue, `${subsectionSectionId}Open`)}
        label={!oldOpenValue ? tl(translations.elements.section.add) : tl(translations.elements.section.collapse)}
      />
    );
  };
  const renderGroupTitle = useCallback((group, key) => (
    <div className="section-header" key={key}>
      <div className={`section-number-box ${open ? 'open' : ''}`}>
        {sectionIcon
          ? <Icon className="section-number" component={sectionIcon} />
          : (
            <div className="section-number">
              {group.sectionNumber}
            </div>
          )}
      </div>
      <div className="section-title-box">
        <div className={`section-title ${open ? 'open' : ''}`}>
          {group.title}
        </div>
      </div>
      {group.deleteButton && group.deleteButton.props.show
        && (
          <div className={`additional-button-wrapper ${open ? 'open' : ''}`}>
            <DeleteButton {...group.deleteButton.props} onClick={setDeletionKeys} />
          </div>
        )}
      {
        group.additionalButton && <div className={`additional-button-wrapper ${open ? 'open' : ''}`}>{group.additionalButton}</div>
      }
    </div>
  ), []);
  const renderGroupContent = (cg: subSectionGroups[]) => cg.map((group, i) => (
    <div id={group.sectionId} key={group.sectionId}>
      {renderGroupTitle(group, i)}
      {/* eslint-disable-next-line no-shadow */}
      {Array.isArray(group.content) ? group.content.map(subSection => (
        <GroupContent
          section={subSection}
          key={subSection.sectionId}
          renderLink={renderLink}
          value={props.value}
          validationErrors={validationErrors}
          onChange={onChange}
          onAddEntity={onAddEntity}
          onToggle={onToggle}
          setDeletionKey={setDeletionKeys}
          disabled={disabled}
        />
      )) : group.content}
    </div>
  ));

  const setDeletionKeys = (key: string, nrKey?: string, callback?: Function, modalText?: object) => {
    setDeletionKey(key);
    if (nrKey) setNrDeletionKey(nrKey);
    if (callback) setDeletionCallback(() => callback);
    if (modalText) deleteButtonProps.setDeletionModal(modalText);
    deleteButtonProps.setDeletionModalVisible(true);
  };
  const renderContentForm = useMemo(() => content.map((subSection, idx) => {
    const linked = subSection.link ? readValue(value, subSection.link.linked) : false;
    /* the subsection is open when:
      - it is not closable
      - it is linkable and not linked to another subsection's data (e.g. buildings)
      - it is closable and it has been already opened
     */
    const isOpen = !subSection.isClosable || (subSection.link && !linked) || (value ? readValue(value, `${subSection.sectionId}Open`) : true);
    return (
      // in case the save button is visible the last sub-section is not considered as being last child, so the "last" classname is needed to have the same styling as without a save button
      <div className={`sub-section ${!hideSaveButton && !disabled && idx === content.length - 1 ? 'last' : ''}`} key={subSection.sectionId} id={subSection.sectionId}>
        <div className={`sub-section-header ${!subSection.title && !subSection.titleNote ? 'empty' : ''}`}>
          {!!subSection.title && (
            <div className="sub-section-title">
              {subSection.title}
              {!!subSection.titleNote
                && <span className="sub-section-title-note">{subSection.titleNote}</span>}
            </div>
          )}
          {subSection.deleteButton && subSection.deleteButton.props.show
            && <DeleteButton {...subSection.deleteButton.props} className="additional-section-button" onClick={setDeletionKeys} />}
          {
            subSection.additionalButton
          }
          {!!subSection.link && renderLink(subSection.link)}
          {subSection.isClosable && !subSection.link && renderToggleButton(subSection.sectionId)}
        </div>
        {isOpen
          && (
            <div className="sub-section-content">
              {subSection.content && 'rows' in subSection.content
                ? subSection.content.rows.filter(row => !!row).map((row, rowIndex) => (
                  <div className="sub-section-content-row" key={rowIndex}>
                    {row.columns.filter(col => !!col).map((col, idx) => (
                      <div
                        className="sub-section-group-column"
                        style={{ minWidth: '25.0rem', maxWidth: 'auto' }}
                        key={idx}
                      >
                        {col.inputs.filter(inp => !!inp).map(inp => (
                          <div className="sub-section-small-col">
                            <InputRenderer
                              input0={inp}
                              disabled={disabled || subSection.disabled}
                              value={props.value ? readValue(props.value, inp.key) : null}
                              validationErrors={validationErrors}
                              onChange={onChange}
                              onAddEntity={onAddEntity}
                              onToggle={onToggle}
                              setDeletionKey={setDeletionKeys}
                            />
                          </div>
                        ))}

                      </div>
                    ))}
                  </div>
                )) : (Array.isArray(subSection.content) ? subSection.content.filter((r: any) => !!r).map((row, idx1) => (
                  <div className="sub-section-content-row" key={idx1}>
                    {row.filter(input => !!input).map((input, idx) => (
                      <div
                        className="sub-section-content-column"
                        style={{ minWidth: input.minWidth ? input.minWidth : '25.0rem', maxWidth: input.maxWidth ? input.maxWidth : 'auto' }}
                        key={idx}
                      >
                        <InputRenderer
                          input0={input}
                          disabled={disabled || subSection.disabled}
                          value={props.value ? readValue(props.value, input.key) : null}
                          validationErrors={validationErrors}
                          onChange={onChange}
                          onAddEntity={onAddEntity}
                          onToggle={onToggle}
                          setDeletionKey={setDeletionKeys}
                        />
                        {' '}
                      </div>
                    ))}
                  </div>
                ))
                  : subSection.content)}
            </div>
          )}
      </div>
    );
  }), [content]);


  const sectionClassName = `FormSection ${sectionDisabled ? 'FormSectionDisabled' : ''}`;

  return (
    <div className={sectionClassName} id={`${sectionId}`}>
      <div className={`form-section-wrapper ${open ? 'open' : ''}`}>
        <div
          className={`main-section-header section-header ${open ? 'open' : 'not-open'}`}
          onClick={onClickHeader}
          onMouseOver={() => onHoverDisplayButton && setDisplay('block')}
          onMouseOut={() => onHoverDisplayButton && setDisplay('none')}
        >
          <div className={`${getSectionNumberBoxClassname(isValid)} ${open ? 'open' : ''} `}>
            {sectionIcon
              ? <Icon className="section-number" component={sectionIcon} />
              : (
                <div className="section-number">
                  {sectionNumber}
                </div>
              )}
          </div>
          <div className={`section-title-box ${open ? 'open' : ''}`}>
            <div className="section-title">
              {sectionTitle}
            </div>
          </div>
          {onHoverDisplayButton && (
            <div className="additional-button-wrapper">
              <Button
                className={display}
                type="ghost"
                onClick={hoverButtonFunction}
              >
                {hoverButtonText}
              </Button>
            </div>
          )}
          {deleteButton && deleteButton.props.show
            && (
              <div className={`additional-button-wrapper ${open ? 'open' : ''}`}>
                <DeleteButton {...deleteButton.props} onClick={setDeletionKeys} />
              </div>
            )}
          {
            additionalButton && <div className={`additional-button-wrapper ${open ? 'open' : 'closed'}`}>{additionalButton}</div>
          }
        </div>
        {open && (
          <div className="section-content">
            {!!startingElement && startingElement}
            {contentGroups ? renderGroupContent(contentGroups) : renderContentForm}
            {!hideSaveButton && !disabled
              && (
                <Button
                  className="section-submit"
                  onClick={() => onSubmit()}
                  loading={buttonLoading}
                  disabled={buttonDisabled}
                >
                  {tl(translations.elements.section.save)}
                </Button>
              )
            }
          </div>
        )}
        {deleteButtonProps.modal}
      </div>
    </div>
  );
};

export default FormSection;
