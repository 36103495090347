import React from 'react';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import { ExtendedExchangeProjectionTypeEnum } from 'api/accounting';
import Amount from 'storybook-components/typography/Amount/Amount';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import { useVatCell } from './useVatCell';
import './VatCell.scss';


interface Props {
  record: ServiceCompanyAllocationValues,
}


const VatCell = ({ record }: Props) => {
  const {
    selectInputValue, commaSeparatedValues, onChange, options, placeholder,
  } = useVatCell(record);


  if (record.type !== ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
    return (
      <Amount>
        <PopoverTruncatingText
          className="VatCell"
          value={commaSeparatedValues}
          containerClassName="ExchangeList"
        />
      </Amount>
    );
  }


  return (
    <SelectInput
      size="large"
      allowClear
      showSearch
      placeholder={placeholder}
      value={selectInputValue}
      onChange={onChange}
      options={options}
      dropdownClassName="large-dropdown"
    />
  );
};

export default VatCell;
