import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import defaultSection from './defaultSection';
import { economicPlanAccountEditingTranslations } from '../economicPlanAccountEditingTranslations';
import { DefaultDataInterface } from '../../../../lib/data';
import { EconomicPlanCreationDto, EconomicPlanDto } from '../../../../api/accounting';
import { useSaveEconomicPlan } from '../../services/useSaveEconomicPlan';
import { CorrectionsWrapper } from '../../components/CorrectionsWrapper/CorrectionsWrapper';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export interface Section5Props {
  economicPlan: DefaultDataInterface<EconomicPlanDto>,
  economicPlanCreationData: DefaultDataInterface<EconomicPlanCreationDto>,
}

export default function useCorrectionalBookingsSection5({
  economicPlan,
  economicPlanCreationData,
}: Section5Props) {
  const { tl } = useContext(LanguageContext);
  const { saveEconomicPlan } = useSaveEconomicPlan();
  const { dirty: isDirty } = useSimpleDirtModal();

  return {
    ...defaultSection({
      index: 4,
      openingIndex: 4,
      economicPlan,
      economicPlanCreationData,
    }),
    hideSaveButton: true,
    sectionTitle: tl(economicPlanAccountEditingTranslations.sections.corrections.sectionTitle),
    sectionId: 'corrections',
    buttonDisabled: !isDirty || !economicPlan.data?.correctionBookingDate,
    onSubmit: saveEconomicPlan,
    content: [{
      content: (
        <CorrectionsWrapper />
      ),
    }],
  };
}
