import { Alert, Col, Row } from 'antd';
import DateInput from 'elements/Inputs/DateInput/DateInput';
import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { correctionalBookingsTranslations } from './correctionalBookingsTranslations';
import { useCorrectionalBookings } from './services/useCorrectionalBookings';
import { OwnerPaymentTableWithContracts } from '../../../../../storybook-components/OwnerPaymentTable/OwnerPaymentTableWithContracts';
import { SPACE_SIZES } from '../../../../../lib/constants';
import ActiveContractsListContextProvider from '../../../../../services/UnitContractsList/ActiveContractsListContext';
import { ICONS } from '../../../../../components/icons';


export const CorrectionalBookings = () => {
  const { tl } = useContext(LanguageContext);
  const {
    amounts, bookingDate, propertyId, loading, onChangeBookingDate, isEditable,
  } = useCorrectionalBookings();

  return (
    <ActiveContractsListContextProvider>
      <div className="CorrectionalBookings">
        <Row gutter={[SPACE_SIZES.large, SPACE_SIZES.large]}>
          <Col span={24}>
            <Alert
              message={tl(correctionalBookingsTranslations.infoText)}
              type="info"
              showIcon
              icon={<Icon component={ICONS.info} />}
            />
          </Col>
          <Col span={12}>
            <DateInput
              label={tl(correctionalBookingsTranslations.bookingDate)}
              onChange={onChangeBookingDate}
              value={bookingDate}
              disabled={!isEditable}
            />
          </Col>
          <Col span={24}>
            <OwnerPaymentTableWithContracts atDate={bookingDate} propertyId={propertyId} amounts={amounts} amountsLoading={loading} />
          </Col>
        </Row>
      </div>
    </ActiveContractsListContextProvider>
  );
};
