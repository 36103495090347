const translations = {
  title: {
    de: 'Willkommen bei Impower',
    en: 'Welcome to Impower',
  },
  subTitle: {
    de: 'Registrieren Sie sich noch heute und erstellen Sie einen Zugang!',
    en: 'Register today to access the platform!',
  },
  registrationForm: {
    domainPlaceholder: {
      en: 'Domainname. Allowed characters: lowercase letters(a-z) and dash(-).',
      de: 'Domainname. Erlaubt sind: Kleinbuchstaben(a-z) und Bindestrich(-).',
    },
    emailPlaceholder: {
      en: 'Email',
      de: 'Ihre E-mail Adresse',
    },
    passwordPlaceholder: {
      en: 'Password. Minimum 8 characters.',
      de: 'Ihr gewünschtes Passwort. Min. 8 Zeichen',
    },
    acceptTcLabel: {
      en: 'I accept the <a target="_blank" href="/agbs/v1.2.pdf">Terms and Conditions</a>.',
      de: 'Ich bin mit den <a target="_blank" href="/agbs/v1.2.pdf">AGB einverstanden</a>.',
    },
    domainValidation: {
      en: 'Only lowercase letters(a-z) and hyphens(-) are allowed.',
      de: 'Erlaubt sind nur Kleinbuchstaben(a-z) und Bindestriche(-)',
    },
    emailValidation: {
      en: 'Please enter a valid email address!',
      de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    },
    passwordValidation: {
      en: 'The password must consist of at least 8 characters and contain at least one uppercase letter, one lowercase letter and one digit.',
      de: 'Das Passwort muss aus mindestens 8 Zeichen bestehen und mindestens einen Großbuchstaben, einen Kleinbuchstaben sowie eine Ziffer enthalten.',
    },
    acceptTcValidation: {
      en: 'Please read and accept the Terms and conditions to continue.',
      de: 'Bitte akzeptieren Sie die AGB.',
    },
    registerButton: {
      en: 'Register',
      de: 'Registrieren',
    },
    register: {
      success: {
        de: 'Die Registrierung war erfolgreich!',
        en: 'Registration successful.',
      },
      errors: {
        errorCodes: {
          USER_DOMAIN_ALREADY_EXISTS: {
            de: 'Der Domainname ist leider nicht verfügbar.',
            en: 'The Domainname is not available.',
          },
          USER_DOMAIN_REGISTRATION_DISABLED: {
            de: 'Die Registrierung ist momentan nicht verfügbar. Bitte wenden Sie sich an support@impower.de.',
            en: 'Registration is currently not available. Please contact support@impower.de.',
          },
          COMMON_VALIDATION_INVALID_DATA: {
            de: 'Ungültige Domain, Email und/oder Passwort.',
            en: 'Invalid domain, email and/or password.',
          },
        },
        notAcceptedTc: {
          de: 'Bitte akzeptieren Sie die AGB.',
          en: 'Please accept the Terms and Conditions.',
        },
        invalidData: {
          de: 'Bitte überprüfen Sie die Gültigkeit der Felder.',
          en: 'Please ensure entered data is correct.',
        },
      },
    },
  },
};

export { translations };
