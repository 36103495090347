import { PropertyDisplayDtoVatRelevanceEnum } from 'api/accounting';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { OptionInterface } from 'elements/Inputs/SmartSearch/SmartSearch';
import { round2dec } from 'lib/Utils';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import usePropertyOptions from 'services/usePropertyOptions';
import { useGetVatEligibilityForProperty } from '../../InvoiceEditorForm/services/useGetVatEligibilityShareForProperty';

export const useInvoiceProcessingSection = () => {
  const {
    propertyOptions, searchForProperty, onLoadProperty, getProperty,
  } = usePropertyOptions(true, ['managementCompany']); // bank accounts are also excluded by default, but those are needed here
  const invoiceEditingContext = useContext(InvoiceEditingContext);
  const {
    data, updateInvoiceState, setProperty, setVatEligibilityShare, invoiceBookings, changeInvoiceBookings,
  } = invoiceEditingContext;

  const { invoice } = data;

  // if the invoice has a pdf attached to it then the data inputs are in a sidebar
  // and the service period input was too narrow, the dates weren't visible
  const shouldMakeServicePeriodWider = data.invoice?.documentUrl?.length > 0;

  const servicePeriod: moment.Moment[] = [];
  if (invoice.serviceStartDate) {
    servicePeriod[0] = moment(invoice.serviceStartDate);
  }
  if (invoice.serviceEndDate) {
    servicePeriod[1] = moment(invoice.serviceEndDate);
  }

  const onChangeServicePeriod = (value) => {
    const newValue = { ...invoice, servicePeriod: value };
    if (value !== invoice.servicePeriod) {
      if (value) {
        newValue.serviceStartDate = value[0];
        newValue.serviceEndDate = value[1];
      } else {
        delete newValue.serviceStartDate;
        delete newValue.serviceEndDate;
      }
    }

    updateInvoiceState({ invoice: newValue });
  };

  const onChangeServicePeriodVisibility = (visible) => {
    if (!visible) {
      updateInvoiceState({ invoice: { ...invoice, servicePeriod: undefined, serviceStartDate: undefined, serviceEndDate: undefined } });
    }
  };

  const { getVatEligibilityShareForProperty } = useGetVatEligibilityForProperty();

  const onChangeProperty = async (v) => {
    const newValue = { ...invoice, propertyId: v };
    updateInvoiceState({ invoice: newValue });

    // side effects
    const prp = getProperty(v);
    setProperty(prev => prev.load(prp));

    if (prp?.vatRelevance === PropertyDisplayDtoVatRelevanceEnum.NOT_RELEVANT) {
      // if the newly selected property is not VAT relevant, the eligibility fields neeed to be cleared anyway
      changeInvoiceBookings({
        type: 'setDefault',
        value: [...invoiceBookings].map(ib => (
          {
            ...ib,
            vatEligibilityPercentage: undefined,
            vatEligibilityAmount: undefined,
          })),
      });
      setVatEligibilityShare(undefined);
    } else {
      // if the propertyd is VAT relevant, the invoice bookings should only be updated if
      // 1. the previous prp was not VAT relevant
      // 2. the prev. property was VAT relevant & the invoice bookings still contain the default values

      const newVatEligibilityShare = await getVatEligibilityShareForProperty(prp);

      setVatEligibilityShare((prev) => {
        if (prev !== newVatEligibilityShare && _.isEmpty(invoiceBookings.filter(ib => ib.vatEligibilityPercentage !== prev))) {
          changeInvoiceBookings({
            type: 'setDefault',
            value: [...invoiceBookings].map(ib => (
              {
                ...ib,
                vatEligibilityPercentage: newVatEligibilityShare,
                vatEligibilityAmount: newVatEligibilityShare !== undefined ? round2dec(ib.vatAmount * newVatEligibilityShare / 100) : undefined,
              })),
          });
        }

        return newVatEligibilityShare;
      });
    }
  };


  const onChange = (key: string, v: any) => {
    const newValue = { ...invoice, [key]: v };
    updateInvoiceState({ invoice: newValue });
  };

  return {
    invoice,
    onChange,
    onChangeProperty,
    onChangeServicePeriod,
    onChangeServicePeriodVisibility,
    propertyOptions: propertyOptions as unknown as OptionInterface[],
    searchForProperty,
    onLoadProperty,
    shouldMakeServicePeriodWider,
  };
};
