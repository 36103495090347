import {
  Context,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Configuration,
  ConnectionDto,
  ConnectionLegacyDto,
  ConnectionsApi,
  GetConnectionsUsingGETStateEnum,
} from 'api/app';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import DEFAULT_DATA from 'lib/data';
import { showNotification } from 'lib/Notification';

import { translations } from '../translations';

export function useConnections(connectionId?: number) {
  const [connections, setConnections] = useState(DEFAULT_DATA<ConnectionLegacyDto[]>([]));
  const { tl } = useContext(LanguageContext);

  const { apiConfiguration } = useContext<{ apiConfiguration:(string) => Configuration }>(AuthContext as unknown as Context<{ apiConfiguration: (string) => Configuration }>);
  const connectionController = new ConnectionsApi(apiConfiguration('app'));


  useEffect(() => {
    loadConnections(connectionId);
  }, []);

  const showLoadError = () => showNotification({
    key: 'loadConnectionsError',
    message: tl(translations.notifications.loadError),
    type: 'error',
  });


  const loadConnections = async (id?: number) => {
    setConnections(state => state.startLoading());
    try {
      if (id) {
        const connection:ConnectionLegacyDto = await connectionController.getConnectionUsingGET({ id });
        setConnections(state => state.load([connection]));
      } else {
      // appId = 5 is casavi
      // appId = 1 is facilioo
      // appId = 6 is etg24
        const allowedAppIds = [5, 1, 6];
        const conns:ConnectionLegacyDto[] = (await connectionController.getConnectionsUsingGET({ state: GetConnectionsUsingGETStateEnum.READY }))
          .content.filter(c => allowedAppIds.indexOf(c.appId) !== -1);
        setConnections(state => state.load(conns));
      }
    } catch (e) {
      setConnections(state => state.failed());
      console.error(e);
      showLoadError();
    }
  };


  return {
    connections: connections.data,
    loading: connections.loading,
    loaded: connections.loaded,
  };
}
