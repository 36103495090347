import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { useOtherIncomeTable } from './useOtherIncomeTable';
import IncomeTableSummary from '../IncomeTableSummary';
import _ from 'lodash';


const OtherIncomeTable = () => {
  const {
    columns, dataSource, sum, netSum, vatSum, vatRelevantGermanProperty, vatColumns, vatDatasource,
  } = useOtherIncomeTable();
  const { tl } = useContext(LanguageContext);

  return (
    <div className="OtherIncomeTable">
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => (
          <IncomeTableSummary
            title={tl(translations.report.sections.incomeSection.totalOtherIncome)}
            totalGross={sum}
            totalNet={netSum}
            totalVat={vatSum}
            nrTotalColumns={columns.length}
            displayVatColumns={vatRelevantGermanProperty}
          />
        )}
      />
      {!_.isEmpty(vatDatasource) && vatDatasource.length > 1 && 
        <Table
          className="NoHeaderTable SecondaryTable"
          columns={vatColumns}
          dataSource={vatDatasource}
          pagination={false} 
          rowKey="vatPercentage"
          rowClassName={(_, index) => (index === 0 ? 'reference-row' : '')}
        />
      }
    </div>
  );
};

export default OtherIncomeTable;
