import { useContext } from 'react';
import { PropertyListContext } from '../../../../contexts/PropertyListContext';

interface useDefaultSectionProps {
  economicPlan: any,
  economicPlanCreationData: any,
  index: number,
  openingIndex: number
}

export default function useDefaultSection({
  economicPlan, economicPlanCreationData, index, openingIndex,
}: useDefaultSectionProps) {
  const propertyListContext: any = useContext(PropertyListContext);
  const selectedPropertyId = propertyListContext.selectedDisplayProperty.data ? propertyListContext.selectedDisplayProperty.data.id : null;

  return {
    sectionNumber: index + 1,
    openingNumber: openingIndex + 1,
    buttonLoading: economicPlan.loading || economicPlanCreationData.loading || propertyListContext.loading,
    buttonDisabled: !economicPlan.data || !selectedPropertyId,
    value: economicPlan.data,
  };
}
