import { PropertyDisplayDto, PropertyDisplayDtoPropertyStateEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { useContext } from 'react';
import ColorDot, { DotColorEnum } from 'storybook-components/ColorDot/ColorDot';

interface Props {
    record: PropertyDisplayDto,
  }


const getDotColor = (state: PropertyDisplayDtoPropertyStateEnum): DotColorEnum => {
  switch (state) {
  case PropertyDisplayDtoPropertyStateEnum.READY:
    return DotColorEnum.GREEN;
  case PropertyDisplayDtoPropertyStateEnum.DRAFT:
    return DotColorEnum.GRAY;
  case PropertyDisplayDtoPropertyStateEnum.OFFBOARDED:
    return DotColorEnum.DUST;
  default:
    return DotColorEnum.GRAY;
  }
};


const StatusCell = ({ record }: Props) => {
  const { tl } = useContext(LanguageContext);
  const dotColor = getDotColor(record.propertyState!);

  return (
    <div>
      <ColorDot color={dotColor}>
        {tl(translations.pages.property.table.propertyStates[record.propertyState as PropertyDisplayDtoPropertyStateEnum])}
      </ColorDot>
    </div>
  );
};

export default StatusCell;
