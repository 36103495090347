import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from './LanguageContext';
import { ContactListContext } from './ContactListContext';
import { showNotification } from '../lib/Notification';
import { AuthContext } from './AuthContext';
import { PropertyLegacyControllerApi, PropertyDisplayDto, SliceOfPropertyDisplayDto } from '../api/accounting';

const PAGE_SIZE = 30;

export const BankAccountListContext: any = React.createContext({});

export default function BankAccountListProvider({ children }: any) {
  const contactListContext: any = useContext(ContactListContext);
  const { tl } = useContext(LanguageContext);

  const defaultBankAccountListContext: any = {
    bankAccountList: [],
    page: 0,
    lastPage: false,
  };
  const defaultFilterContext: any = {
    propertyStates: ['READY', 'DELETED'],
  };

  const [bankAccountListState, setBankAccountListState] = useState(DEFAULT_DATA<any>(defaultBankAccountListContext));
  const [filterState, setFilterState] = useState<any>(defaultFilterContext);
  const [bankAccountSortState, setBankAccountSortState] = useState({
    field: 'propertyHrId',
    order: 1,
  });
  const bankAccountSortRef: any = useRef();
  bankAccountSortRef.current = bankAccountSortState;
  const [initialSortUpdate, setInitialSortUpdate] = useState(true);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const lastRequestTimestamp = useRef<number | null>(null);

  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (!initialSortUpdate) {
      onLoadBankAccountList(true);
    } else {
      setInitialSortUpdate(false);
    }
  }, [bankAccountSortState]);


  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadBankAccountList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState]);

  function convertToBankAccount(property: any) {
    let houseMoneyBalance: number = 0;
    let houseMoneyUndefined = true;
    let reserveMoneyUndefined = true;
    let reserveMoneyBalance: number = 0;
    let calculatedHouseMoneyBalance: number = 0;
    let calculatedReserveMoneyBalance: number = 0;
    let calculatedTotalBalance: number = 0;
    const bankAccounts: Array<{
      id: number,
      type: 'HOUSE' | 'RESERVE',
      name: string,
      institute: string,
      iban: string,
      bic: string,
      balance: number,
      calculatedBalance: number,
    }> = [];
    if (!!property.supervisorContactId && !contactListContext.containsContact(property.supervisorContactId)) {
      contactListContext.onLoadContact(property.supervisorContactId);
    }
    property.bankAccounts.forEach((bankAccount: any) => {
      bankAccounts.push({
        id: bankAccount.id,
        type: bankAccount.accountType,
        name: bankAccount.accountName,
        institute: bankAccount.accountInstitute,
        iban: bankAccount.iban,
        bic: bankAccount.bic,
        balance: bankAccount.balance,
        calculatedBalance: bankAccount.calculatedBalance || undefined,
      });

      if (typeof bankAccount.balance !== 'undefined') {
        if (bankAccount.accountType === 'HOUSE') {
          houseMoneyBalance += bankAccount.balance;
          houseMoneyUndefined = false;
        } else {
          reserveMoneyBalance += bankAccount.balance;
          reserveMoneyUndefined = false;
        }
      }
      if (bankAccount.calculatedBalance) {
        calculatedTotalBalance += bankAccount.calculatedBalance;
        if (bankAccount.accountType === 'HOUSE') {
          calculatedHouseMoneyBalance += bankAccount.calculatedBalance;
        } else {
          calculatedReserveMoneyBalance += bankAccount.calculatedBalance;
        }
      }
    });
    const { units } = property;

    return {
      propertyHrId: property.propertyHrId,
      propertyIdInternal: property.propertyIdInternal,
      propertyId: property.id,
      managementCompanyId: property.managementCompany.id,
      name: property.name,
      propertyState: property.propertyState,
      supervisorName: property.supervisorName,
      administrator: property.supervisorContactId,
      houseMoneyBalance: !houseMoneyUndefined ? houseMoneyBalance : undefined,
      reserveMoneyBalance: !reserveMoneyUndefined ? reserveMoneyBalance : undefined,
      calculatedHouseMoneyBalance: calculatedHouseMoneyBalance || undefined,
      calculatedReserveMoneyBalance: calculatedReserveMoneyBalance || undefined,
      totalBalance: !houseMoneyUndefined || !reserveMoneyUndefined ? houseMoneyBalance + reserveMoneyBalance : undefined,
      calculatedTotalBalance: calculatedTotalBalance || undefined,
      units,
      bankAccounts,
    };
  }

  function convertToBankAccountList(properties?: PropertyDisplayDto[]) {
    return properties ? properties.map(property => convertToBankAccount(property)) : [];
  }

  function errorLoading() {
    setBankAccountListState({
      ...bankAccountListState.failed(),
      data: {
        ...bankAccountListState.data,
        lastPage: true,
      },
    });
    showNotification({
      key: 'loadBankAccountListError',
      message: tl(translations.notifications.bankAccountListContext.loadError.message),
      type: 'error',
    });
  }

  const onLoadBankAccountList = async (resetPage: boolean = false, sort: any = bankAccountSortState) => {
    if (bankAccountListState.loading) {
      return;
    }
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setBankAccountListState(bankAccountListState.startLoading());
    try {
      const response: SliceOfPropertyDisplayDto = await propertyControllerApi.findFilteredPropertiesWithBankAccountsUsingGET({
        ...filterState,
        page: resetPage ? 0 : bankAccountListState.data.page,
        size: PAGE_SIZE,
        order: sort.order > 0 ? 'ASC' : 'DESC',
        sort: sort.field,
      });
      const bankAccounts = convertToBankAccountList(response.content);

      // do nothing if this is a response for an older request
      if (currentTimestamp !== lastRequestTimestamp.current) return;

      setBankAccountListState(bankAccountListState.load({
        bankAccountList: resetPage ? bankAccounts : bankAccountListState.data.bankAccountList.concat(bankAccounts),
        lastPage: response.last,
        page: resetPage ? 0 : bankAccountListState.data.page + 1,
      }));
    } catch (e) {
      errorLoading();
    }
  };

  const onLoadBankAccountsForProperty = (propertyHrId: string): void => {
    backend.get(`${endpointUrls.PROPERTY}/${propertyHrId}`, {})
      .then((response: any) => {
        const { bankAccountList } = bankAccountListState.data;
        if (bankAccountList.filter((property: any) => property.propertyHrId === response.propertyHrId).length === 0) {
          bankAccountList.push(convertToBankAccount(response));
        }
        setBankAccountListState(bankAccountListState.load({
          ...bankAccountListState.data,
          bankAccountList,
        }));
      })
      .catch(errorLoading);
  };

  const getBankAccountsForProperty = (propertyHrId: string) => {
    const { bankAccountList } = bankAccountListState.data;
    const bankAccount = bankAccountList.filter((property: any) => property.propertyHrId === propertyHrId);
    if (bankAccount.length > 0) {
      return bankAccount[0];
    }
    return null;
  };


  const onClearBankAccountList = (): void => {
    setBankAccountListState(bankAccountListState.load(defaultBankAccountListContext));
  };

  const updateFilterState = (data: object) => {
    if (_.isEqual(data, filterState)) return;
    setFilterState((old: any) => (_.isEqual(data, filterState) ? old : {
      ...old,
      ...data,
    }));
  };

  const setSortField = (field: string) => {
    setBankAccountSortState((old: any) => ({
      field,
      order: old.field === field ? old.order * (-1) : 1,
    }));
  };

  return (
    <BankAccountListContext.Provider value={{
      ...bankAccountListState,
      onLoadBankAccountList,
      onLoadBankAccountsForProperty,
      getBankAccountsForProperty,
      onClearBankAccountList,
      setSortField,
      filterState,
      setFilterState,
      updateFilterState,
      sortField: bankAccountSortRef.current.field,
      sortOrder: bankAccountSortRef.current.order,
    }}
    >
      {children}
    </BankAccountListContext.Provider>
  );
}

BankAccountListProvider.propType = {
  children: PropTypes.node.isRequired,
};
