import React, { useContext, useEffect, useRef } from 'react';
import PdfPageWrapper from 'elements/PdfViewer/components/PdfPageWrapper';
import VirtualizePdfPageWrapper from 'elements/PdfViewer/components/VirtualizePdfPageWrapper';
import { getDocumentIdByFingerprintAndPageNumber } from 'pages/PdfPageAssigner/services/utils';
import { PDF_VIEWER_ROOT_NODE_ID } from 'elements/PdfViewer/SimplePdfViewer';
import { PdfPageAssignerUpdatersContext } from 'pages/PdfPageAssigner/services/PdfPageAssignerContext';
import { PagesOrientationType } from 'pages/PdfPageAssigner/services/interfaces';
import PdfAssignmentCheckboxWrapper from '../PdfAssignmentCheckboxWrapper/PdfAssignmentCheckboxWrapper';
import { usePdfPageWithAssignment } from './usePdfPageWithAssignment';

type Props = {
  page: any,
  documentId: number,
  virtualize: boolean,
  pdfFingerprint: string,
  pdfPreviewWidth: number,
  viewMode: PagesOrientationType,
}


const PdfPageWithAssignment = ({
  page,
  documentId,
  virtualize,
  pdfFingerprint,
  pdfPreviewWidth,
  viewMode,
}: Props) => {
  const intersectionRef = useRef(null);
  const PageComponent = virtualize ? VirtualizePdfPageWrapper : PdfPageWrapper;

  const { pageId, onChangeIdOfPageInView } = usePdfPageWithAssignment(pdfFingerprint, page);

  /**
   * Use an IntersectionObserver to call setIdOfPageInView when the page scrolls into view.
   * The value of `idOfPageInView` is read in the preview sidebar to highlight the page preview.
   */
  useEffect(() => {
    const root = document.getElementById(PDF_VIEWER_ROOT_NODE_ID);
    let observer;

    if (intersectionRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onChangeIdOfPageInView(pageId);
          }
        },
        { root, threshold: 0.5 },
      );

      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) {
        observer?.unobserve(intersectionRef.current);
      }
    };
  }, [intersectionRef]);

  return (
    <PdfAssignmentCheckboxWrapper ref={intersectionRef} documentId={documentId} pageNumber={page._pageIndex}>
      <PageComponent
        page={page}
        key={pdfFingerprint + page._pageIndex}
        id={pageId}
        pdfPreviewWidth={pdfPreviewWidth}
        parentWidth={pdfPreviewWidth}
        selectableText={false}
        rootNodeId={PDF_VIEWER_ROOT_NODE_ID}
        pageNumber={page._pageIndex}
        // no text layer for grid view
        renderTextLayer={false}
        viewMode={viewMode}
      />
    </PdfAssignmentCheckboxWrapper>
  );
};


export default PdfPageWithAssignment;
