import React, { useContext } from 'react';
import { useSectionManager } from 'services/SectionManager/useSectionManager';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import ReactDOM from 'react-dom';
import { getSectionPortalMountNode } from '../../services/utils';
import AnnexTable from './components/AnnexTable';
import './AnnexSection.scss';
import PageBreakWrapper from '../PageBreakWrapper/PageBreakWrapper';


const AnnexSection = () => {
  const { openSectionId } = useSectionManager();
  const { tl } = useContext(LanguageContext);

  const getRenderedContent = (print: boolean) => (
    <PrimarySectionWrapper
      sectionId="AnnexSection"
      sectionTitle={tl(translations.report.sections.annexSection.title)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
      withContentPadding
    >
      <AnnexTable print={print} />
    </PrimarySectionWrapper>
  );

  return (
    <>
      {getRenderedContent(false)}
      {ReactDOM.createPortal(
        <PageBreakWrapper breakBefore>
          {getRenderedContent(true)}
        </PageBreakWrapper>,
        getSectionPortalMountNode(),
      )}
    </>
  );
};

export default AnnexSection;
