import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import moment from 'moment';
import useBoolean from 'ahooks/lib/useBoolean';
import { ContractContactCreationDtoRoleEnum } from 'api/accounting/models/ContractContactCreationDto';
import { UnitContractProjectionDto } from 'api/accounting/models/UnitContractProjectionDto';
import { ContractProjectionDtoDunningLevelEnum, ContractProjectionDtoVatRelevanceEnum, UnitContractMandateUsageDtoStateEnum, UnitProjectionDto } from 'api/accounting';
import { ValidationErrors } from 'lib/interfaces/ValidationErrorInterface';
import { UnitDto } from 'api/public';

interface Props {
  children: React.ReactNode
}

export interface ContactTableDataSource {
  key: string,
  contacyType: string,
}


export interface SelectedContact {
  key: string,
  contactId?: number,
  name?: string,
  contactType: ContractContactCreationDtoRoleEnum[],
}

export interface DirectDebitMandateUsage {
  unitContractId: number,
  directDebitMandateId: number,
  currentState: UnitContractMandateUsageDtoStateEnum,
  originalState: UnitContractMandateUsageDtoStateEnum,
}

export interface ContractAdditionalData {
  depositType: string,
  [key: string]: any
}

export const NO_DUNNING_OPTION_VALUE = -1;

export interface ContractDetails {
  unitContractId?: number,
  contractNumber?: string,
  mailingContactId?: number,
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  signedDate?: moment.Moment,
  bankAccountId?: number,
  bankAccountOptions: BankAccountOption[],
  directDebitMandateUsages: DirectDebitMandateUsage[],
  additionalData?: ContractAdditionalData,
  vatRelevance?: ContractProjectionDtoVatRelevanceEnum,
  dunningLevel?: ContractProjectionDtoDunningLevelEnum | typeof NO_DUNNING_OPTION_VALUE,
  contractGroupId?: number,
}


export interface MailingContactOption {
  contactId: number;
  name: string;
}

export interface BankAccountOption {
  contactId: number;
  bankAccountId: number;
  accountHolderName: string;
  iban: string;
}

export const InitialContractContext = createContext<{
  initialUnitContract: UnitContractProjectionDto,
  setInitialUnitContract: React.Dispatch<React.SetStateAction<UnitContractProjectionDto>>,
} | undefined>(undefined);

export const useInitialContractContext = (usageName: string) => {
  const initialContractContext = useContext(InitialContractContext);

  if (initialContractContext === undefined) {
    throw new Error(`${usageName} must be used within a UnitSetEditorContextProvider`);
  }

  return initialContractContext;
};

export const useSelectedContactsContext = (usageName: string) => {
  const selectedContactsContext = useContext(SelectedContactsContext);

  if (selectedContactsContext === undefined) {
    throw new Error(`${usageName} must be used within a UnitSetEditorContextProvider`);
  }

  return selectedContactsContext;
};

export const UnitContractEditorUpdatersContext = createContext<{
  setSelectedContacts: React.Dispatch<React.SetStateAction<SelectedContact[]>>,
  setContractDetails: React.Dispatch<React.SetStateAction<ContractDetails>>,
  setUnitDetails: React.Dispatch<React.SetStateAction<UnitDto>>,
  setDirty: React.Dispatch<React.SetStateAction<boolean>>,
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  startLoading:() => void,
  stopLoading: () => void,
  setHasPostings: React.Dispatch<React.SetStateAction<boolean>>,
    } | undefined>(undefined);

export const useUnitContractEditorUpdatersContext = (usageName: string) => {
  const unitContractEditorUpdatersContex = useContext(UnitContractEditorUpdatersContext);

  if (unitContractEditorUpdatersContex === undefined) {
    throw new Error(`${usageName} must be used within a UnitSetEditorContextProvider`);
  }

  return unitContractEditorUpdatersContex;
};

export const useContractDetailsContext = (usageName: string) => {
  const contractDetailsContext = useContext(ContractDetailsContext);

  if (contractDetailsContext === undefined) {
    throw new Error(`${usageName} must be used within a ContractDetailsContext`);
  }

  return contractDetailsContext;
};


export const SelectedContactsContext = createContext<{
  selectedContacts: SelectedContact[],
} | undefined>(undefined);


export const SelectedContactsTableDataSourceContext = createContext<{
  dataSource: ContactTableDataSource[],
} | undefined>(undefined);


export const ContractDetailsContext = createContext<{
  contractDetails: ContractDetails,
  unitDetails: UnitDto,
  validationErrors: ValidationErrors,
  hasPostings: boolean,
} | undefined>(undefined);

export const DirtyContractContext = createContext<{
  dirty: boolean,
  loading: boolean,
} | undefined>(undefined);

export const UnitContractModalsContext = createContext<{
  warningModalVisible: boolean,
  previousContract: UnitContractProjectionDto | undefined,
  setWarningModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setPreviousContract: React.Dispatch<React.SetStateAction<UnitContractProjectionDto | undefined>>,
} | undefined>(undefined);

export const DEFAULT_CONTRACT_DETAILS_STATE: ContractDetails = {
  unitContractId: undefined,
  contractNumber: undefined,
  mailingContactId: undefined,
  startDate: undefined,
  endDate: undefined,
  signedDate: undefined,
  bankAccountId: undefined,
  bankAccountOptions: [],
  directDebitMandateUsages: [],
  contractGroupId: undefined,
};

const UnitContractEditorContextProvider = ({ children }: Props) => {
  const [initialUnitContract, setInitialUnitContract] = useState<UnitContractProjectionDto>({});
  const [selectedContacts, setSelectedContacts] = useState<SelectedContact[]>([]);
  const [contractDetails, setContractDetails] = useState<ContractDetails>(DEFAULT_CONTRACT_DETAILS_STATE);
  const [unitDetails, setUnitDetails] = useState<UnitDto>();
  const [dirty, setDirty] = useState<boolean>(false);
  const [loading, { setTrue: startLoading, setFalse: stopLoading }] = useBoolean(false);
  const [warningModalVisible, setWarningModalVisible] = useState<boolean>(false);
  const [previousContract, setPreviousContract] = useState<UnitContractProjectionDto>();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [hasPostings, setHasPostings] = useState<boolean>(undefined);

  const updatersContextProviderValue = useMemo(() => ({
    setSelectedContacts,
    setContractDetails,
    setUnitDetails,
    setDirty,
    startLoading,
    stopLoading,
    setValidationErrors,
    setHasPostings,
  }), [setSelectedContacts, setContractDetails, setUnitDetails, setDirty, setValidationErrors, setHasPostings]);

  const selectedContactsProviderValue = useMemo(() => ({
    selectedContacts,
  }), [selectedContacts]);


  const contractDetailsProviderValue = useMemo(() => ({
    contractDetails,
    unitDetails,
    validationErrors,
    hasPostings,
  }), [contractDetails, unitDetails, validationErrors, hasPostings]);

  const initialUnitContractProviderValue = useMemo(() => ({
    initialUnitContract,
    setInitialUnitContract,
  }), [initialUnitContract, setInitialUnitContract]);

  const unitContractModalsProviderValue = useMemo(() => ({
    warningModalVisible,
    previousContract,
    setWarningModalVisible,
    setPreviousContract,
  }), [warningModalVisible, previousContract, setWarningModalVisible, setPreviousContract]);

  return (
    <UnitContractEditorUpdatersContext.Provider value={updatersContextProviderValue}>
      <SelectedContactsContext.Provider value={selectedContactsProviderValue}>
        <ContractDetailsContext.Provider value={contractDetailsProviderValue}>
          <DirtyContractContext.Provider value={{ dirty, loading }}>
            <InitialContractContext.Provider value={initialUnitContractProviderValue}>
              <UnitContractModalsContext.Provider value={unitContractModalsProviderValue}>
                {children}
              </UnitContractModalsContext.Provider>
            </InitialContractContext.Provider>
          </DirtyContractContext.Provider>
        </ContractDetailsContext.Provider>
      </SelectedContactsContext.Provider>
    </UnitContractEditorUpdatersContext.Provider>
  );
};

export default UnitContractEditorContextProvider;
