import { useContext } from 'react';

import {
  Configuration,
  DocumentLegacyControllerApi,
  DocumentProjectionDto,
  FindDocumentsFilteredUsingGETOrderEnum,
  FindDocumentsFilteredUsingGETSourceTypesEnum,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { isNil } from 'lodash';
import { useParams } from 'react-router';

import { translations } from '../translations';
import {
  DocumentMetaDataType,
  ExistingAssignment,
  PdfAssignmentDocumentType,
} from './interfaces';
import { PdfPageAssignerUpdatersContext } from './PdfPageAssignerContext';

const PAGE_SIZE = 10000;


export const useLoadAssignmentDocuments = () => {
  const { tl } = useContext(LanguageContext);
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);
  const { apiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as Configuration);

  const { propertyId, sourceId, sourceType } = useParams<{ [key: string]: string }>();


  if (pdfPageAssignerUpdatersContext === undefined) {
    throw new Error('usePdfPageAssigner must be used within a PdfPageAssignerContextProvider');
  }

  const {
    setOriginalDocuments,
    setExistingAssignments,
    setCurrentAssignments,
    setLoading,
  } = pdfPageAssignerUpdatersContext;


  const loadAssignmentDocuments = () => {
    if ([propertyId, sourceId, sourceType].some(isNil)) return;

    setLoading(true);
    documentControllerApi.findDocumentsFilteredUsingGET({
      propertyId: parseInt(propertyId, 10),
      sourceIds: [parseInt(sourceId, 10)],
      sourceTypes: [sourceType] as unknown as FindDocumentsFilteredUsingGETSourceTypesEnum,
      size: PAGE_SIZE,
      // sorting so the documents don't change orded between fetches
      order: FindDocumentsFilteredUsingGETOrderEnum.DESC,
      sort: 'id',
    })
      .then(({ content }) => {
        const [assignmentDocs, originalDocs] = content.reduce((acc, doc) => {
          if (doc.assignedTo === undefined) {
            acc[1].push({
              ...doc,
              metaData: JSON.parse(doc.metaData ?? '{}'),
            });
          } else {
            acc[0].push(doc);
          }

          return acc;
        }, [[], []] as [DocumentProjectionDto[], PdfAssignmentDocumentType[]]);


        const parsedAssignmentDocs = assignmentDocs.map(doc => ({
          ...doc,
          metaData: JSON.parse(doc.metaData) as DocumentMetaDataType,
        }));


        const existingAssignments: ExistingAssignment[] = parsedAssignmentDocs.map(doc => ({
          assignmentDocumentId: doc.id,
          contractId: doc.contractId,
          unitId: doc.unitId,
          originalDocumentId: doc.metaData.originalDocumentId,
          pages: doc.metaData.originalDocumentPages,
        }));

        const currentAssignments = existingAssignments.map(({ pages, ...rest }) => (
          pages.map(pageNumber => ({ ...rest, page: pageNumber }))
        )).flat();


        setOriginalDocuments(prev => prev.load(originalDocs));
        setExistingAssignments(existingAssignments);
        setCurrentAssignments(currentAssignments);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.loadAssignmentsError),
        });

        setOriginalDocuments(prev => prev.load([]).failed());
        setExistingAssignments([]);
        setCurrentAssignments([]);
      })
      .finally(() => { setLoading(false); });
  };

  return { loadAssignmentDocuments };
};
