import { useHistory, useLocation } from 'react-router';

export const useCreateProfitAndLossReportButton = () => {
  const history = useHistory();
  const location = useLocation();

  const onCreateProfitAndLossReport = () => {
    history.push(`${location.pathname}/create`);
  };

  return {
    onCreateProfitAndLossReport,
  };
};
