import './SelectOptionWithTwoLines.scss';

const SelectOptionWithTwoLines = ({
  primaryText,
  secondaryText,
} : {primaryText: string, secondaryText: string}) => (
  <div className="SelectOptionWithTwoLines">
    <div className="primary-text">
      {primaryText}
    </div>
    <div className="secondary-text">
      {secondaryText}
    </div>
  </div>
);

export default SelectOptionWithTwoLines;
