import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import Amount from 'storybook-components/typography/Amount/Amount';
import { getOpenTransactionUrl, useOpenTransactionsCell } from './useOpenTransactionsCell';

export const OpenTransactionsCell = ({ propertyId, propertyIdInternal }) => {
  const { numberOfOpenTransactions } = useOpenTransactionsCell(propertyId);

  return (
    <Skeleton
      loading={isNil(numberOfOpenTransactions)}
      active
      title={false}
      paragraph={{ rows: 1, width: '100%' }}
    >
      <Link
        to={getOpenTransactionUrl(propertyIdInternal)}
        target="_blank"
      >
        <Amount>{numberOfOpenTransactions}</Amount>
      </Link>
    </Skeleton>
  );
};
