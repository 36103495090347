import { Color } from '..';

const getColorClassFromColor = (color: Color) => {
  const baseColorClass = 'color';
  if (color) {
    switch (color) {
    case Color.Blue: {
      return `${baseColorClass}-blue`;
    }
    case Color.DarkGray: {
      return `${baseColorClass}-dark-gray`;
    }
    case Color.Dust: {
      return `${baseColorClass}-dust`;
    }
    case Color.Gray: {
      return `${baseColorClass}-gray`;
    }
    case Color.Green: {
      return `${baseColorClass}-green`;
    }
    case Color.LightGray: {
      return `${baseColorClass}-light-gray`;
    }
    case Color.LightGreen: {
      return `${baseColorClass}-light-green`;
    }
    case Color.LightRed: {
      return `${baseColorClass}-light-red`;
    }
    case Color.LightYellow: {
      return `${baseColorClass}-light-yellow`;
    }
    case Color.Purple: {
      return `${baseColorClass}-purple`;
    }
    case Color.Red: {
      return `${baseColorClass}-red`;
    }
    case Color.White: {
      return `${baseColorClass}-white`;
    }
    case Color.Warning: {
      return `${baseColorClass}-warning`;
    }
    case Color.WhiteCream: {
      return `${baseColorClass}-white-cream`;
    }
    case Color.WhiteMilk: {
      return `${baseColorClass}-white-milk`;
    }
    case Color.Yellow: {
      return `${baseColorClass}-yellow`;
    }
    default:
      return `${baseColorClass}-black`;
    }
  }
  return `${baseColorClass}-black`;
};

export default getColorClassFromColor;
