import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment';
import { AuthContext } from '../../../contexts/AuthContext';
import {
  HouseMoneySettlementAggregationControllerApi,
  HouseMoneySettlementStatusDto,
  HouseMoneySettlementStatusDtoStatusEnum,
} from '../../../api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from '../../../lib/data';
import { showNotification } from '../../../lib/Notification';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from './translations';

interface usePostingHGAValidationProps {
  propertyId: number | undefined,
  date: moment.Moment | undefined,
}

export default function usePostingHGAValidation({ propertyId, date }: usePostingHGAValidationProps) {
  const [visible, setVisible] = useState(false);
  const [hgaDetails, setHgaDetails] = useState<DefaultDataInterface<HouseMoneySettlementStatusDto | null>>(DEFAULT_DATA(null));
  const { apiConfiguration } = useContext(AuthContext);
  const houseMoneySettlementApi = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));
  const { PREPARED, FINALIZED } = HouseMoneySettlementStatusDtoStatusEnum;
  const onProceed = useRef<Function>();
  const { tl } = useContext(LanguageContext);

  useEffect(() => {
    if ((!hgaDetails.data && propertyId && date) || (hgaDetails.data && (propertyId !== hgaDetails.data!.propertyId || date?.get('year').toString() !== hgaDetails.data!.economicYear))) {
      checkHGAClosed(propertyId, date?.get('year'));
    }
  }, [propertyId, date]);


  const checkHGAClosed = (pId: number, economicYear: number) => {
    if (hgaDetails.data?.propertyId === pId && hgaDetails.data?.economicYear === economicYear?.toString()) return;
    if (!pId || !economicYear) return;

    houseMoneySettlementApi.getHouseMoneySettlementStatusUsingGET({ propertyId: pId, economicYear })
      .then((response: HouseMoneySettlementStatusDto) => setHgaDetails(prevState => prevState.load(response)))
      .catch(() => {
        showNotification({
          key: 'loadPropertiesError',
          message: tl(translations.modal.checkError),
          type: 'error',
        });
        setHgaDetails(prevState => prevState.failed());
      });
  };

  const isHgaClosed = useMemo(() => Array.of(PREPARED, FINALIZED).includes(hgaDetails.data?.status), [hgaDetails]);

  const showModal = (onProceedFunction: Function) => {
    setVisible(true);
    onProceed.current = onProceedFunction;
  };

  const onClickCancel = () => {
    onProceed.current = undefined;
    setVisible(false);
  };

  const onClickReview = () => {
    if (isHgaClosed && hgaDetails.data) {
      window.open(`${window.location.origin}/house-money-settlement/${hgaDetails.data?.propertyHrId}?year=${hgaDetails.data?.economicYear}`);
    }
  };

  const onClickProceed = () => {
    if (onProceed.current) {
      onProceed.current();
    }
    setVisible(false);
  };

  const showModalIfHgaClosed = (callbackFunction: () => void) => {
    if (isHgaClosed) {
      return () => showModal(callbackFunction);
    }
    return callbackFunction;
  };

  return {
    onClickCancel,
    onClickReview,
    onClickProceed,
    isHgaClosed,
    visible,
    showModal,
    showModalIfHgaClosed,
    loading: hgaDetails.loading,
    year: hgaDetails.data?.economicYear,
  };
}
