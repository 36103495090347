import { ContactLegacyDto } from 'api/accounting';
import { useManagementCompanyList } from 'pages/PaymentList/services/useManagementCompanyList';
import { useEffect, useState } from 'react';

interface useManagementCompanySelectionProps {
  onSelected: (id: number) => void,
  defaultValue?: number
}

export const useManagementCompanySelection = (props: useManagementCompanySelectionProps) => {
  const managementCompanies = useManagementCompanyList();
  const [selectedManagementCompany, setSelectedManagementCompany] = useState<ContactLegacyDto | null>(null);

  const onSelectCompany = (index: number) => {
    setSelectedManagementCompany(managementCompanies[index]);
    props.onSelected(managementCompanies[index].id!);
  };

  const finIndexOfId = (id: number) => managementCompanies.map(c => c.id).indexOf(id);

  // Initialize a selected management company
  useEffect(() => {
    if (selectedManagementCompany === null && managementCompanies.length > 0) {
      if (props.defaultValue !== null && props.defaultValue !== undefined) {
        const idx = finIndexOfId(props.defaultValue);
        onSelectCompany(idx === -1 ? 0 : idx);
      } else {
        onSelectCompany(0);
      }
    }
  }, [managementCompanies, props.defaultValue]);
  return { onSelectCompany, managementCompanies, selectedManagementCompany };
};
