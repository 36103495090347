import { useEffect } from 'react';

export const useAutoOpenUnitAccountRow = ({ accountCode, loaded }: { accountCode: number | undefined, loaded: boolean }) => {
  useEffect(() => {
    if (loaded && accountCode) {
      openUnitAccountRow();
    }
  }, [loaded, accountCode]);

  const openUnitAccountRow = () => {
    setTimeout(() => {
      const cardExpandButton = document.querySelector('#debitorsCreditors .expand-card-button') as HTMLButtonElement;
      cardExpandButton?.click();


      const expandArrow1 = document.querySelector('[data-row-key="2000"] .RowExpandIcon') as HTMLDivElement;
      expandArrow1?.click();

      const unitAccountRow = document.querySelector(`[data-row-key="${accountCode}"]`);

      if (unitAccountRow) {
        setTimeout(() => {
          unitAccountRow.scrollIntoView({ behavior: 'smooth', block: 'start' });
          unitAccountRow.classList.add('highlight');

          const expandArrow2 = document.querySelector(`[data-row-key="${accountCode}"] .RowExpandIcon`) as HTMLDivElement;
          expandArrow2.click();
        }, 500);
      }
    }, 1000);
  };
};
