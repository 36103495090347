import { useContext } from 'react';
import { PropertyWegAndMvAdministrationTypes } from 'lib/utilMapPropertyAdministrationTypeToFrontend';
import { Filter } from '../../../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import { SerialLetterDtoShippingStatusEnum } from '../../../../api/accounting/models';
import { hrIdMatcherParser } from '../../../../lib/Utils';

export default function useSerialLetterFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    {
      type: 'enum',
      name: tl(translations.headers.status),
      key: 'shippingStatuses',
      options: Object.values(SerialLetterDtoShippingStatusEnum).map(value => ({
        label: tl(translations.states[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(translations.headers.internalNr),
      key: 'propertyIdInternal',
    },
    {
      type: 'text',
      name: tl(translations.headers.propertyName),
      key: 'propertyName',
    },
    {
      type: 'enum',
      name: tl(translations.headers.administrationType),
      key: 'propertyAdministrationTypes',
      options: Object.keys(PropertyWegAndMvAdministrationTypes).map(value => ({
        label: tl(translations.headers.administrationTypes[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(translations.headers.sender),
      key: 'signingPersonName',
    },
    {
      type: 'text',
      name: tl(translations.headers.subject),
      key: 'subject',
    },
    {
      type: 'text',
      name: tl(translations.headers.accountant),
      key: 'accountantName',
    },
    {
      type: 'text',
      name: tl(translations.headers.supervisor),
      key: 'supervisorName',
    },
    {
      type: 'text',
      name: tl(translations.headers.createdBy),
      key: 'createdBy',
    },
  ];
}
