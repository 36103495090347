export const translations = {
  header: {
    title: { en: 'Import', de: 'Importieren' },
    buttonLabel: { en: 'Import', de: 'Importieren' },
  },
  notifications: {
    error: {
      title: { en: 'Error importing CSV', de: 'Fehler' },
      description: { en: 'Import failed half way through', de: 'Import Prozess fehlgeschlagen' },
    },
  },
};
