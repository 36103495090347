import { StoreApi } from 'zustand';
import { produce } from 'immer';
import { isNil } from 'lodash';
import { HiddenValues, OpsCostReportHiddenAmountsStore } from './interfacesOpsCostReportHiddenAmounts';


/* not declared with the rest of interfaces because these are just helper types to avoid long lines */
type Actions = OpsCostReportHiddenAmountsStore['actions'];
type Set = StoreApi<OpsCostReportHiddenAmountsStore>['setState'];


const getInitialHiddenValuesForContract = (): HiddenValues => ({
  individualTenantCostsKeys: [],
  otherOrTransferableCostsKeys: [],
  yourPrepaymentsKeys: [],
});


export const addHiddenRow = (set: Set, ...args: Parameters<Actions['addHiddenRow']>) => set((state) => {
  const [contractId, targetSection, key] = args;

  return produce(state, (draft) => {
    if (isNil(draft.values[contractId])) {
      draft.values[contractId] = getInitialHiddenValuesForContract();
    }
    draft.values[contractId][`${targetSection}Keys`].push(key);
  });
});


export const removeHiddenRows = (set: Set, ...args: Parameters<Actions['removeHiddenRows']>) => set((state) => {
  const [contractId, targetSection, keys] = args;

  return produce(state, (draft) => {
    draft.values[contractId][`${targetSection}Keys`] = draft.values[contractId][`${targetSection}Keys`].filter(k => !keys.includes(k));
  });
});

export const clearStoreHiddenRows = (set: Set) => set(state => (
  produce(state, (draft) => {
    draft.values = {};
  })
));


export const initializeStoreWithExistingHiddenValues = (set: Set, ...args: Parameters<Actions['initializeStoreWithExistingHiddenValues']>) => set((state) => {
  const [values] = args;

  return produce(state, (draft) => {
    draft.values = values;
  });
});
