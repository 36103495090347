import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { getDotColor } from 'pages/OpsCostReport/services/utils';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import { useOpsCostReportPreviewHeader } from './useOpsCostReportPreviewHeader';


interface OpsCostReportPreviewHeaderProps {
  onClose: () => void,
}


const OpsCostReportPreviewHeader = ({
  onClose,
}: OpsCostReportPreviewHeaderProps) => {
  const { tl } = useContext(LanguageContext);
  const { title, subtitle, state } = useOpsCostReportPreviewHeader();

  const headerButtons = (
    <Button
      type="text"
      onClick={onClose}
    >
      {tl(opsCostTranslation.report.header.closeButton)}
    </Button>
  );

  return (
    <PageHeader
      title={title}
      subtitle={subtitle}
      rightSideComponent={headerButtons}
      tagColor={getDotColor(state) as PageHeaderTagColor}
      tagContent={state && tl(opsCostTranslation.opsCostReportPreview.header.status[state])}
    />
  );
};

export default OpsCostReportPreviewHeader;
