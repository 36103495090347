import React, { useContext } from 'react';
import { Modal } from 'antd';
import { AgendaItemDto, OwnersMeetingInvitationDtoStatusEnum } from '../../../../../../api/accounting';
import { ownersMeetingInvitationTranslations } from '../../../translations/OwnersMeetingInvitationTranslations';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { Action } from '../../../../../../elements/SmartTable/data';
import ActionsDropdown from '../../../../../../elements/SmartTable/components/OptionsMenu/ActionsDropdown';
import { useDeleteActionOnAgendaItemsList } from '../services/useDeleteActionOnAgendaItemsList';
import { OwnersMeetingInvitationContext } from '../../../../../../contexts/OwnersMeetingInvitationContext';

interface Props {
  record: AgendaItemDto,
}

const AgendaItemTableOptionsMenu = ({ record }: Props) => {
  const { tl } = useContext(LanguageContext);
  const { onDeleteAgendaItem } = useDeleteActionOnAgendaItemsList(record?.id);
  const { ownersMeeting } = useContext(OwnersMeetingInvitationContext);
  const isDisabled = !ownersMeeting.data || (ownersMeeting.data!.status === OwnersMeetingInvitationDtoStatusEnum.SENT || ownersMeeting.data!.status === OwnersMeetingInvitationDtoStatusEnum.DELETED);

  const actions: Action[] = [
    {
      label: tl(ownersMeetingInvitationTranslations.editPage.deleteButton),
      onAction: () => {
        Modal.confirm({
          title: tl(ownersMeetingInvitationTranslations.elements.deletionModal.title),
          content: tl(ownersMeetingInvitationTranslations.elements.deletionModal.description),
          okText: tl(ownersMeetingInvitationTranslations.elements.deletionModal.delete),
          cancelText: tl(ownersMeetingInvitationTranslations.elements.deletionModal.cancel),
          okButtonProps: { className: 'Button' },
          cancelButtonProps: { className: 'Button' },
          onOk: onDeleteAgendaItem,
          width: 450,
        });
      },
    },
  ];

  return (
    <ActionsDropdown actions={isDisabled ? undefined : actions} record={record} tableVerticallyScrollable={false} />
  );
};

export default AgendaItemTableOptionsMenu;
