import { LanguageContext } from 'contexts/LanguageContext';
import { PersonEditingContext } from 'contexts/PersonEditingContext';
import { setValue } from 'lib/Utils';
import { useContext, useState } from 'react';
import { useBankInformation } from 'services/useBankInformation';
import { translations } from 'pages/Contacts/pages/PersonEditing/translations';
import { useBankAccountValidations } from 'services/useBankAccountValidations';
import { BankDataDto } from 'api/accounting';

export interface BankAccountFormValues {
    accountHolderName: string | undefined,
    iban: string | undefined,
    bic: string | undefined,
    accountInstitute: string | undefined,
}

export const useBankAccountSubsection = (bankAccountIndex: number) => {
  const { tl } = useContext(LanguageContext);
  const personEditingContext: any = useContext(PersonEditingContext);

  if (personEditingContext === undefined) {
    throw new Error('useBankAccountSubsection must be used within a PersonEditingContext');
  }

  const { setContactState } = personEditingContext;
  const { bankAccounts, id: contactId } = personEditingContext.data.contact;

  const bankAccount = bankAccounts ? bankAccounts[bankAccountIndex] : {};

  const { validationErrors } = useBankAccountValidations(personEditingContext.validationErrors, bankAccountIndex);


  const onChange = (key: keyof BankAccountFormValues, value: BankAccountFormValues[keyof BankAccountFormValues]) => {
    setContactState((prevState: any) => {
      const newData = { ...prevState.data };
      setValue(newData.contact, `bankAccounts[${bankAccountIndex}].${key}`, value);
      return prevState.load(newData);
    });
  };

  const { fetchBankInformation } = useBankInformation();
  const [bicPlaceHolder, setBicPlaceholder] = useState(tl(translations.bankAccountSubsection.bicWillBeGenerated));


  const callback = (bankInformation: BankDataDto) => {
    onChange('accountInstitute', bankInformation?.bankName ?? '');
    onChange('bic', bankInformation?.bic ?? '');

    if (!bankInformation || bankInformation.bic === undefined) {
      setBicPlaceholder(tl(translations.bankAccountSubsection.bicNotGenerated));
    }
  };


  const onBlurIban = () => {
    fetchBankInformation(bankAccount?.iban, callback);
  };

  const removeBankAccountFromList = (index: number) => {
    setContactState((prevState: any) => {
      const newData = { ...prevState.data };
      newData.contact.bankAccounts?.splice(index, 1);
      newData.contact.bankAccountNumber -= 1;

      return prevState.load(newData);
    });
  };

  return {
    bankAccount,
    bicPlaceHolder,
    onChange,
    onBlurIban,
    validationErrors,
    contactId,
    removeBankAccountFromList,
  };
};
