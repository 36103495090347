import { debounce } from 'lodash';
import { useCallback, useState } from 'react';


export const PDF_PREVIEW_WIDTH = [
  300,
  400,
  500,
  600,
  750,
  850,
  950,
  1050,
  1200,
] as const;


export const usePdfWidthAndZooming = (initialWidth: typeof PDF_PREVIEW_WIDTH[number] = PDF_PREVIEW_WIDTH[0]) => {
  const [pdfPreviewWidth, setPdfPreviewWidth] = useState<typeof PDF_PREVIEW_WIDTH[number]>(initialWidth);
  const zoomInDisabled = pdfPreviewWidth === PDF_PREVIEW_WIDTH[PDF_PREVIEW_WIDTH.length - 1];
  const zoomOutDisabled = pdfPreviewWidth === PDF_PREVIEW_WIDTH[0];

  /**
   * Debounce is needed because if we trigger multiple renders on the pdf canvas then it will flip around.
   * https://impower.atlassian.net/browse/PMP-16573
   */
  const debouncedSetPdfPreviewWidth = useCallback(debounce(setPdfPreviewWidth, 200), []);


  const onChangeZoom = (type: 'zoomIn' | 'zoomOut' | typeof PDF_PREVIEW_WIDTH[number]) => {
    debouncedSetPdfPreviewWidth((prevWidth) => {
      const index = PDF_PREVIEW_WIDTH.findIndex(w => w === prevWidth);

      if (typeof type === 'number') {
        return type;
      }

      if (type === 'zoomIn' && index < PDF_PREVIEW_WIDTH.length - 1) {
        return PDF_PREVIEW_WIDTH[index + 1];
      }

      if (type === 'zoomOut' && index > 0) {
        return PDF_PREVIEW_WIDTH[index - 1];
      }

      return prevWidth;
    });
  };


  return {
    pdfPreviewWidth,
    zoomInDisabled,
    zoomOutDisabled,
    onChangeZoom,
  };
};
