import { VatRateDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  createContext,
  useContext,
  useState,
} from 'react';

export interface PropertyVatRate extends VatRateDto {
    propertyId?: number,
    propertyHrId?: string
}

export const PropertyVatRatesContext = createContext<{
  propertyVatRates: DefaultDataInterface<PropertyVatRate[]>,
  setPropertyVatRates: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyVatRate[]>>>,
} | undefined >(undefined);

export const usePropertyVatRatesContext = (usageName: string) => {
  const propertyVatRatesContext = useContext(PropertyVatRatesContext);

  if (propertyVatRatesContext === undefined) {
    throw new Error(`${usageName} must be used within a PropertyVatRatesContextProvider`);
  }
  
  return propertyVatRatesContext;
}

const PropertyVatRatesContextProvider = ({ children }: any) => {
  const [propertyVatRates, setPropertyVatRates] = useState<DefaultDataInterface<PropertyVatRate[]>>(DEFAULT_DATA([]));

  const value = {
    propertyVatRates, setPropertyVatRates,
  };

  return (
    <PropertyVatRatesContext.Provider value={value}>
      {children}
    </PropertyVatRatesContext.Provider>
  );
};

export default PropertyVatRatesContextProvider;
