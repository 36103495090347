import { useContext } from 'react';
import { translations } from 'elements/Translation/translations';
import { LanguageContext } from 'contexts/LanguageContext';

export const useResolutionRecordPropertyListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.property.table.headers.propertyHrId),
      dataKey: 'propertyHrId',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.property.table.headers.internalNr),
      dataKey: 'propertyIdInternal',
      defaultWidth: '13%',
    },
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'name',
      defaultWidth: '50%',
    },
    {
      title: tl(translations.pages.resolutionRecordPropertyList.table.headers.supervisorName),
      dataKey: 'supervisorName',
    },
  ];
};
