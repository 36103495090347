
export const userDetailsTranslations = {
  header: {
    title: {
      en: 'Edit user',
      de: 'Benutzer bearbeiten',
    },
    buttons: {
      save: {
        en: 'Save',
        de: 'Speichern',
      },
      close: {
        en: 'Close',
        de: 'Schließen',
      },
      delete: {
        en: 'Delete user',
        de: 'Benutzer löschen',
      },
      add: {
        en: 'Add users',
        de: 'Benutzer hinzufügen',
      },
    },
  },
  section: {
    title: {
      en: 'Core information',
      de: 'Allgemein',
    },
  },
  password: {
    en: 'Password',
    de: 'Passwort',
  },
  role: {
    en: 'Role',
    de: 'Rolle',
  },
  passwordHint: {
    en: 'We do not store password for privacy reasons. If you do not fill in the input, the password remains the same.',
    de: 'Aus Datenschutzgründen speichern wir kein Passwort. Wenn Sie die Eingabe nicht ausfüllen, bleibt das Passwort gleich.',
  },

  notifications: {
    loadRolesError: {
      en: 'User Roles could not be loaded.',
      de: 'Benutzerrollen konnten nicht geladen werden.',
    },
    loadUserError: {
      en: 'Failed to load user.',
      de: 'Benutzer konnte nicht geladen werden.',
    },
    saveUserError: {
      en: 'Failed to save user.',
      de: 'Benutzer konnte nicht gespeichert werden.',
    },
    saveUserSuccess: {
      en: 'User saved successfully.',
      de: 'Benutzer erfolgreich gespeichert.',
    },
  },
};
