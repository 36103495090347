export interface agendaTemplateItemInterface {
  label: string,
  value: {
    title: string,
    comment: string,
    resolutionRecordText: string,
  },
}

export const templateAgendaItems: agendaTemplateItemInterface[] = [
  {
    label: 'Jahresabrechnung samt Fälligstellung',
    value: {
      title: 'Jahresabrechnung samt Fälligstellung',
      comment: '',
      resolutionRecordText: 'Die Nachschüsse bzw. Anpassungen der beschlossenen Vorschüsse aus den Einzelabrechnungen für das Wirtschaftsjahr XXXX vom XX.XX.XXXX werden genehmigt und fällig gestellt. Der Einzug der Forderungen per SEPA Lastschrift erfolgt frühestens zwei Wochen nach Beschlussfassung. Etwaige Guthaben der Eigentümer aufgrund der Anpassung der beschlossenen Vorschüsse hat der Verwalter in Vertretung für die GdWE - sofern kein anderweitiger Rückstand besteht - zu diesem Termin zu überweisen.',
    },
  },
  {
    label: 'Wirtschaftsplan samt Fälligstellung',
    value: {
      title: 'Wirtschaftsplan samt Fälligstellung',
      comment: '',
      resolutionRecordText: 'Die Einzahlungen in die Erhaltungsrücklage und die Vorschüsse aus den Einzelwirtschaftsplänen für das Jahr XXXX vom XX.XX.XXXX werden genehmigt. Sie sind bis spätestens zum dritten Werktag eines Monats im Voraus fällig, erstmalig im MONAT JAHR. Die Vorschüsse aus den Einzelwirtschaftsplänen für das Jahr XXXX gelten so lange, bis ein neuer Beschluss über Vorschüsse aus Einzelwirtschaftsplänen gefasst wird.',
    },
  },
  {
    label: 'Entlastung des Verwaltungsbeirats',
    value: {
      title: 'Entlastung des Verwaltungsbeirats',
      comment: '',
      resolutionRecordText: 'Die Eigentümergemeinschaft beschließt, den Verwaltungsbeirat für seine Tätigkeit im zurückliegenden Wirtschaftsjahr zu entlasten.',
    },
  },
  {
    label: 'Entlastung der Verwaltung',
    value: {
      title: 'Entlastung der Verwaltung',
      comment: '',
      resolutionRecordText: 'Die Eigentümergemeinschaft beschließt, die Verwaltung für Ihre Tätigkeit im zurückliegenden Wirtschaftsjahr zu entlasten.',
    },
  },
  {
    label: 'Änderung Verteilerschlüssel',
    value: {
      title: 'Änderung Verteilerschlüssel',
      comment: '',
      resolutionRecordText: 'Die Eigentümergemeinschaft beschließt, den Verteilerschlüssel für XXXX beginnend ab dem ersten Wirtschaftsjahr, das dieser Beschlussfassung folgt, zu ändern. Diese Kosten werden nicht mehr auf alle Eigentümer wie bisher nach ________ verteilt, sondern auf alle Eigentümer nach _________.',
    },
  },
  {
    label: 'Verlängerung der Verwalterbestellung mit neuem Vertrag',
    value: {
      title: 'Verlängerung der Verwalterbestellung mit neuem Vertrag',
      comment: '',
      resolutionRecordText: 'Die ________ wird für den Zeitraum von XX.XX.XXXX bis XX.XX.XXXX zum Verwalter bestellt. \n'
      + 'Für den Zeitraum dieser Verwalterbestellung soll der Verwaltervertrag in der Fassung vom XX.XX.XXXX abgeschlossen werden. \n'
      + 'Das Honorar beträgt monatlich XX,XX EUR brutto pro Wohn-/Gewerbe- und Teileigentumseinheit und monatlich X,XX Euro brutto pro abzurechnenden Kfz-Stellplatz. \n'
      + 'Die Vergütung steigt in den Folgejahren um jeweils X% an. Zusätzlich erhält der Verwalter die vertraglich geregelte Sondervergütung für die im Verwaltervertrag geregelten Zusatzleistungen. \n'
      + 'Der Eigentümer __________ wird bevollmächtigt und beauftragt, als Vertreter der Gemeinschaft einen aktuellen Verwaltervertrag und die Verwaltervollmacht zu unterzeichnen. \n',
    },
  },
  {
    label: 'Verlängerung der Verwalterbestellung mit bestehendem Vertrag',
    value: {
      title: 'Verlängerung der Verwalterbestellung mit bestehendem Vertrag',
      comment: '',
      resolutionRecordText: 'Die ________ wird für den Zeitraum von XX.XX.XXXX bis XX.XX.XXXX zum Verwalter bestellt. Der bisher geltende Verwaltervertrag gilt fort. Die Verwaltervergütung erfolgt weiterhin gemäß geltendem Verwaltervertrag.',
    },
  },
  {
    label: 'Kostentragung Verwaltervergütung',
    value: {
      title: 'Kostentragung Verwaltervergütung',
      comment: '',
      resolutionRecordText: 'Die folgenden variablen Vergütungen des Verwaltervertrags sind im Rahmen der Jahresabrechnung dem Sondereigentum zu belasten, dessen Eigentümer diese Kosten verursacht hat: <ul>'
      + '<li>Mahnschreiben durch den im Verzug befindlichen Eigentümer,</li>'
      + '<li>Vergütung bei Nicht-Teilnahme von Eigentümern am Lastschriftverfahren</li>'
      + '<li>Bearbeitung von kostenpflichtigen Eigentümerwechseln und Nutzerwechseln</li>'
      + '<li>Erteilung von Verwalterzustimmungen</li>'
      + '</ul>',
    },
  },
  {
    label: 'Online-Teilnahme bei der Eigentümerversammlung',
    value: {
      title: 'Online-Teilnahme bei der Eigentümerversammlung',
      comment: '',
      resolutionRecordText: 'Die Online-Teilnahme an Eigentümerversammlungen wird nach Maßgabe folgender Bestimmungen zugelassen, ohne ein grundsätzliches Recht auf Online-Teilnahme zu begründen: <ul>'
          + '<li>Der Verwalter legt für jede Eigentümerversammlung fest, ob eine Online-Teilnahme für die Versammlung vorgesehen wird und informiert die Eigentümer im Rahmen der Einladung darüber.</li>'
          + '<li>Jeder Eigentümer kann sein Teilnahme-/Rede-/Antrags- sowie Stimmrecht nach Wahl des Versammlungsleiters per Chat, Audio, oder Video-Funktion im Wege von der Verwaltung auszuwählender elektronischer Kommunikationsmittel ausüben.</li>'
          + '<li>Die Online-Beteiligung hat über einen durch geeignete Verschlüsselung geschützten Zugang zu erfolgen. Der berechtigte Online-Teilnehmer hat die Übertragung an Nichtberechtigte zu unterbinden.</li>'
          + '<li>Jeglicher Übertragungsfehler - gleich auf wessen Verantwortungsbereich dieser beruht - hindert den Fortgang der Eigentümerversammlung nicht. Der Online-Teilnehmer ist für einen solchen Fall darauf verwiesen, sich von einer anwesenden Person vertreten zu lassen.</li>'
          + '</ul>',
    },
  },
  {
    label: 'Digitalisierung von Verwaltungsunterlagen',
    value: {
      title: 'Digitalisierung von Verwaltungsunterlagen',
      comment: '',
      resolutionRecordText: 'Die Wohnungseigentümer ermächtigen den Verwalter, neu eingehende und bereits vorhandene Verwaltungsunterlagen zu digitalisieren und anschließend die vorhandenen Papierdokumente zu vernichten. Allerdings sind solche Originale aufzubewahren, durch die heute noch Rechtswirkungen erzeugt werden und durch die eine zur Wirksamkeit erforderliche Form wie notarielle Beurkundung oder Schriftform gewahrt wird. Die Kosten von XX,XX€ brutto pro Ordner bis XX Seiten, XX,XX€ brutto pro Ordner bis XX Seiten, oder XX,XX€ brutto pro Ordner bis XX Seiten werden von der Gemeinschaft getragen.',
    },
  },
  {
    label: 'Verwalterkompetenzen des Wohnungseigentumsverwalters im Sinne von §27 WEG',
    value: {
      title: 'Verwalterkompetenzen des Wohnungseigentumsverwalters im Sinne von §27 WEG',
      comment: '',
      resolutionRecordText: 'Die Eigentümer bestimmen nach § 27 Abs. 2 WEG, dass der Verwalter zusätzlich zu seinen Rechten und Pflichten gemäß §27 WEG Abs. 1 folgende Maßnahmen ordnungsmäßiger Verwaltung treffen darf, ohne zuvor einen Beschluss der Eigentümerversammlung einzuholen. <ul>'
      + '<li>Erhaltungsmaßnahmen bis zu einem Kostenaufwand von XXX Euro (brutto) im Einzelfall; bis zu einem Kostenaufwand von XXX Euro (brutto im Einzelfall nach Abstimmung mit dem Beirat. Der Verwalter hat die Maßnahme dem Beiratsvorsitzenden anzuzeigen. Er darf sie nur ausführen, falls der Beiratsvorsitzende nicht binnen 2 Wochen widerspricht. Der jährliche Gesamtaufwand hierfür darf den im jeweiligen Wirtschaftsplan enthaltenen Gesamtansatz für die laufende Erhaltung nicht überschreiten.</li>'
       + '<li>Abschluss von Versorgungsverträgen (Strom, Wasser, Gas, Müll, etc.), Versicherungs-, Wartungs- und Hausmeisterverträgen mit einer Laufzeit von bis zu XXX Jahren.</li>'
       + '<li>Beauftragung von technischen Sachverständigen zur Ergründung von Gebäudemängeln bis zu einem Kostenaufwand von XXX Euro (brutto) im Einzelfall.</li>'
       + '<li>Außergerichtliche und gerichtliche Beitreibung von Zahlungsansprüchen der GdWE, einschließlich der Einleitung von Maßnahmen der Zwangsvollstreckung, -verwaltung und -versteigerung sowie der Vertretung in einem Insolvenzverfahren und der Beendigung von Zwangsvollstreckungsmaßnahmen sowie deren Aufhebung und Löschung. Ebenfalls hiervon umfasst, ist die Beauftragung eines Rechtsanwaltes mit den genannten Maßnahmen.   Der Verwalter ist befugt, mit dem Rechtsanwalt eine den gesetzlichen Vergütungsanspruch erweiternde Vergütungsvereinbarung bis zu einem Stundensatz von XXX Euro (brutto) zu vereinbaren.</li>'
       + '<li>Führung von Beschlussklagen auf Passivseite (Auswahl eines Rechtsanwalts, Abschluss einer Vergütungsvereinbarung bis zu einem Stundensatz von XXX Euro (brutto), Abstimmung der Strategie sowie Entscheidung über Rechtsmittel).</li>'
       + '<li>Verfolgen von Ansprüchen wegen Störungen des gemeinschaftlichen Eigentums und Verstößen gegen Vereinbarungen der Eigentümer, einschließlich der gerichtlichen Geltendmachung der Unterlassungsansprüche.</li>'
       + '</ul>',
    },
  },
  {
    label: 'Wahl des Verwaltungsbeirats',
    value: {
      title: 'Wahl des Verwaltungsbeirats',
      comment: '',
      resolutionRecordText: 'Die Eigentümergemeinschaft beschließt, den Eigentümer/ die Eigentümerin ___________ mit sofortiger Wirkung zum Verwaltungsbeirat/zur Verwaltungsbeirätin zu bestellen.',
    },
  },
  {
    label: 'Technische Maßnahme',
    value: {
      title: 'Technische Maßnahme',
      comment: 'Das Angebot/ die Angebote finden Sie online im Eigentümerportal zum Download.',
      resolutionRecordText: 'Die Eigentümergemeinschaft beschließt *** gemäß Angebot Nr. XXXXXX vom XX.XX.XX der Firma *** in Höhe von X.XXX,XX Euro. Die Kosten gehen zu Lasten der Erhaltungsrücklage (oder) zu Lasten der laufenden Abrechnung. Für den Mehraufwand durch die Begleitung der Maßnahme erhält die Verwaltung ein Sonderhonorar in Höhe von X.XXX,XX Euro.',
    },
  },
  {
    label: 'Umlaufbeschluss mit einfacher Mehrheit',
    value: {
      title: 'Umlaufbeschluss mit einfacher Mehrheit',
      comment: '',
      resolutionRecordText: 'Die Wohnungseigentümer bestimmen nach § 23 Abs. 3 Satz 2 WEG, dass über _________________ im Wege eines Umlaufbeschlusses, also ohne Eigentümerversammlung, mit der einfachen Mehrheit der abgegebenen Stimmen entschieden werden kann.',
    },
  },
];
