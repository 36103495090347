import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import { PercentageInput } from 'elements/Inputs/PercentageInput/PercentageInput';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Col, Row } from 'antd';
import { SPACE_SIZES } from 'lib/constants';
import { round2dec } from 'lib/Utils';
import SelectInput, { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { InvoiceBookingWithNet } from 'contexts/functions/InvoiceEditingFunctions';
import { translations } from '../../../../translations';
import { useInvoiceBookingValidation } from '../../useInvoiceBookingValidation';
import { calculateNetAmount } from '../../invoiceBookingUtils';

interface LaborCostRowProps {
    index: number,
    invoiceBooking: InvoiceBookingWithNet,
    dispatch: (arg: any) => void,
    laborCostTypeOptions: SelectOption[],
    disabled?: boolean,
}


const LaborCostRow = ({
  index,
  invoiceBooking,
  dispatch,
  laborCostTypeOptions,
  disabled,
}: LaborCostRowProps) => {
  const { bookingValidationError } = useInvoiceBookingValidation(index);

  const {
    laborCost, laborCostPercentage, laborCostType, vatPercentage,
  } = invoiceBooking;
  const { tl } = useContext(LanguageContext);

  return (

    <Row gutter={[SPACE_SIZES.xLarge, 10]} className="legacy-row">
      <Col className="legacy-col">
        <SelectInput
          label={`${tl(translations.invoiceEditing.sections.section3.subsection2.fields.wegLaborCostTypeSelect)}`}
          options={laborCostTypeOptions}
          value={laborCostType || tl(translations.invoiceEditing.sections.section3.subsection2.fields.laborCostTypes.NO_LABOR_COST)}
          onChange={(value: any) => dispatch({
            type: 'update', key: 'laborCostType', idx: index, value,
          })}
          disabled={disabled}
          validationState={bookingValidationError?.laborCostType?.validationState}
          validationMessage={bookingValidationError?.laborCostType?.validationMessage}
          allowClear={laborCostType !== undefined}
        />
      </Col>
      {laborCostType !== undefined
        ? (
          <Col className="legacy-col">
            <CurrencyInput
              label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.wegLaborCostNet)}
              value={calculateNetAmount(laborCost, vatPercentage)}
              onChange={(val: any) => dispatch({
                type: 'update',
                key: 'laborCost',
                idx: index,
                value: round2dec(val * (100 + (vatPercentage || 0)) / 100),
              })}
              disabled={disabled}
              validationState={bookingValidationError?.laborCostNet?.validationState}
              validationMessage={bookingValidationError?.laborCostNet?.validationMessage}
            />
          </Col>
        )
        : <Col className="legacy-col" /> }
      {laborCostType !== undefined
        ? (
          <Col className="legacy-col">
            <CurrencyInput
              label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.wegLaborCostBrut)}
              value={Math.abs(laborCost)}
              onChange={(value: any) => dispatch({
                type: 'update', key: 'laborCost', idx: index, value,
              })}
              disabled={disabled}
              validationState={bookingValidationError?.laborCostGross?.validationState}
              validationMessage={bookingValidationError?.laborCostGross?.validationMessage}
            />
          </Col>
        )
        : <Col className="legacy-col" /> }
      {laborCostType !== undefined
        ? (
          <Col className="legacy-col">
            <PercentageInput
              label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.wegLaborCostPercentage)}
              onChange={(val: any) => dispatch({
                type: 'update',
                key: 'laborCostPercentage',
                idx: index,
                value: val,
              })}
              value={laborCostPercentage}
              disabled={disabled}
              maxValue={10000}
              validationState={bookingValidationError?.laborCostPercentage?.validationState}
              validationMessage={bookingValidationError?.laborCostPercentage?.validationMessage}
            />
          </Col>
        )
        : <Col className="legacy-col" />
      }
    </Row>
  );
};

export default LaborCostRow;

LaborCostRow.defaultProps = {
  disabled: undefined,
};
