import React, { useContext, useRef } from 'react';
import { Form } from 'antd';
import moment from 'moment';
import { translations } from '../../Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { getParentScrollElement, range } from '../../../lib/Utils';
import './YearInput.scss';
import InputProps from '../InputProps';
import SelectInput from '../SelectInput/SelectInput';

interface YearInputProps extends InputProps<moment.Moment> {
  to?: number,
  from?: number,
  noPopupContainer?: boolean
}

const START_YEAR = 1850;

export default function YearInput(props: YearInputProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    className, label, required, inputClassName, disabled, validationState, validationMessage, showPlaceholderWhenDisabled,
    from: fromProp, to: toProp, onChange: onChangeProp, value: valueProp, noPopupContainer: noPopupContainerProp, placeholder: placeholderProp,
  } = props;
  const from = fromProp || START_YEAR;
  const to = toProp || new Date().getFullYear();
  const years = range(from, to).reverse().map((number: number) => number.toString());

  const options = years.map(year => ({ label: year, value: year }));

  const onChange = (year: any): void => {
    if (year) {
      onChangeProp(moment.utc(year, 'YYYY'));
    } else {
      onChangeProp(undefined);
    }
  };

  let placeholder = placeholderProp || tl(translations.elements.selectInput.placeholder);
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }
  const noPopupContainer = noPopupContainerProp || false;
  const value = valueProp ? moment.utc(valueProp).format('YYYY') : null;
  const yearInputElement = useRef(null);
  return (
    <div className={`YearInput ${className}`} ref={yearInputElement}>
      <Form.Item
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <SelectInput
          className={`Select ${inputClassName} ${disabled ? 'read-only' : ''}`}
          onChange={onChange}
          showSearch
          disabled={disabled}
          value={value || undefined}
          placeholder={placeholder}
          allowClear
          options={options}
          {...(!noPopupContainer && { getPopupContainer: (): HTMLElement => getParentScrollElement(yearInputElement.current) })}
        />
      </Form.Item>
    </div>
  );
}

YearInput.defaultProps = {
  to: undefined,
  from: undefined,
  noPopupContainer: undefined,
};
