import { PersonEditingContext } from 'contexts/PersonEditingContext';
import { useContext } from 'react';


export const useAddBankAccountButton = () => {
  const personEditingContext: any = useContext(PersonEditingContext);

  if (personEditingContext === undefined) {
    throw new Error('useAddBankAccountButton must be used within a PersonEditingContext');
  }

  const { setContactState } = personEditingContext;


  const onAddNewBankAccount = () => {
    setContactState((prevState: any) => {
      const newData = { ...prevState.data };
      if (newData.contact.bankAccountNumber !== undefined) {
        newData.contact.bankAccountNumber += 1;
      } else {
        newData.contact.bankAccountNumber = 1;
      }
      return prevState.load(newData);
    });
  };


  return {
    onAddNewBankAccount,
  };
};
