import _ from 'lodash';
import { RouteDefinition } from '../../components/RenderRoutes';
import InvoiceEditingRouter from '../Invoice/InvoiceEditing/InvoiceEditingRouter';
import BankTransactionListWithProvider from './BankTransactionList/BankTransactionList';
import BankTransactionAllocationProvider from './services/BankTransactionAllocationContext';

const TRANSACTION_LIST_BASE_PATH = '/bank-transactions';

export const getTransactionListPath = (transactionIds?: number[]) => `${TRANSACTION_LIST_BASE_PATH}${!_.isEmpty(transactionIds) ? `?transactionIds=${transactionIds.join(',')}` : ''}`;

export const BankTransactionRoutes: RouteDefinition = {
  context: BankTransactionAllocationProvider,
  subRoutes: [
    {
      path: '/bank-transactions/:allocationGroupId?',
      component: BankTransactionListWithProvider,
      subRoutes: [
        {
          path: '/bank-transactions/:allocationGroupId/invoice/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
      ],
    },
    {
      path: '*/:propertyHrId/bank-transactions',
      component: BankTransactionListWithProvider,
      type: 'overlay',
      width: '90%',
      subRoutes: [
        {
          path: '*/:propertyHrId/bank-transactions/:allocationGroupId/invoice/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'overlay',
        },
      ],
    },
  ],
};
