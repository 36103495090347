import { LanguageContext } from 'contexts/LanguageContext';
import ActionsDropdown from 'elements/SmartTable/components/OptionsMenu/ActionsDropdown';
import { RecipientDatasource } from 'pages/MessageSendingPage/interfaces';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { useRecipientTableOptions } from './useRecipientTableOptions';

const RecipientTableOptions = ({ record } : {record: RecipientDatasource }) => {
  const { tl } = useContext(LanguageContext);
  const { onRemove } = useRecipientTableOptions(record);

  const options = [
    {
      label: tl(messageSendingTranslations.documentTable.options.remove),
      onAction: () => onRemove(),
      actionSupported: () => !record.children,
    },
  ];

  return (
    <ActionsDropdown
      record={record}
      actions={options}
      tableVerticallyScrollable={false}
    />
  );
};

export default RecipientTableOptions;
