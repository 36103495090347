import { useHistory, useLocation } from 'react-router';

export const useAddAgendaItems = () => {
  const history = useHistory();
  const location = useLocation();

  const addAgendaItem = () => {
    history.push(`${location.pathname}/agenda-item/create`);
  };

  return { addAgendaItem };
};
