import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { AccountSelectionContext, DirectionAndProcessingInputValuesContext } from './ManualExchangeContext';
import { translations } from '../translations';
import { UnitContractProjectionDto } from '../../../api/accounting';

export const useProcessingSelectorOptions = (record: UnitContractProjectionDto) => {
  const { tl } = useContext(LanguageContext);
  const directionAndProcessingInputValues = useContext(DirectionAndProcessingInputValuesContext);
  const selectionContext = useContext(AccountSelectionContext);

  const dist = (directionAndProcessingInputValues ?? []).find(d => d.accountCode === record.accountCode);

  const SELECT_OPTIONS = [
    {
      label: tl(translations.creationPage.sections.section2.selectOptionsLabels.selfPayer),
      value: 'SELF_PAYER',
    },
    {
      label: tl(translations.creationPage.sections.section2.selectOptionsLabels[dist?.direction === 'incoming' ? 'directDebit' : 'paymentRun']),
      value: 'PLATFORM',
      disabled: (dist?.direction === 'incoming' && !record.hasMandateAtDate) || (dist?.direction === 'outgoing' && record.bankAccountIban === undefined),
    },
  ];

  if (selectionContext?.formValues?.bankAccount === -1) {
    return {
      SELECT_OPTIONS: [SELECT_OPTIONS[0]],
      value: SELECT_OPTIONS[0].value,
    };
  }

  return {
    SELECT_OPTIONS,
    value: dist?.processing,
  };
};
