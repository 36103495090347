import React, { useContext, useMemo, useState } from 'react';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { DropdownOptionElement } from '../elements/CustomElements/DropdownOptionElement/DropdownOptionElement';
import { showNotification } from '../lib/Notification';
import {
  PropertyLegacyControllerApi, FindFilteredPropertiesUsingGETAdministrationTypesEnum, FindFilteredPropertiesUsingGETPropertyStatesEnum, PropertyDisplayDto, PropertyDisplayDtoPropertyStateEnum,
} from '../api/accounting';
import { AuthContext } from '../contexts/AuthContext';


export default function usePropertyOptions(includeOffBoardedProperties: boolean, excludedFields: string[] = ['bankAccounts', 'managementCompany']) {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const [properties, setProperties] = useState(DEFAULT_DATA<PropertyDisplayDto[]>([]));

  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));


  const optionObjectFromProperty = (property: PropertyDisplayDto) => {
    const label = property.name;
    let labelWithState = label;
    if (property.propertyState !== PropertyDisplayDtoPropertyStateEnum.READY) {
      // display the state everytime the property is not ready for accounting
      labelWithState = `${label} - ${tl(translations.elements.propertyOptionElement.stateLabels[property.propertyState])}`;
    }
    let additionalLabelInfo;
    if (property.propertyIdInternal) {
      additionalLabelInfo = `${property.propertyIdInternal} | ${property.administrationType} | ${tl(translations.elements.propertyOptionElement.stateLabels[property.propertyState])}`;
    }
    return {
      value: property.id,
      label: <DropdownOptionElement
        label={label}
        additionalLabelInfo={additionalLabelInfo}
      />,
      simpleLabel: labelWithState,
      propertyHrId: property.propertyHrId,
      administrationType: property.administrationType,
    };
  };

  const propertyOptions = useMemo(() => (properties.data ? properties.data.map(optionObjectFromProperty) : []), [properties.data]);

  const searchForProperty = (filter: string, administrationTypes?: FindFilteredPropertiesUsingGETAdministrationTypesEnum[]) => {
    setProperties(state => state.startLoading());
    const propertyStates = [FindFilteredPropertiesUsingGETPropertyStatesEnum.READY];
    if (includeOffBoardedProperties) {
      propertyStates.push(FindFilteredPropertiesUsingGETPropertyStatesEnum.OFFBOARDED);
    }
    propertyControllerApi.findFilteredPropertiesUsingGET({
      // @ts-ignore
      propertyStates,
      // @ts-ignore
      administrationTypes,
      excludeFields: excludedFields,
      searchString: filter,
      size: 30,
    })
      .then((resp) => {
        setProperties(prev => prev.load(resp.content));
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          key: 'loadPropertiesError',
          message: tl(translations.notifications.usePropertyList.loadError.message),
          type: 'error',
        });
        setProperties(prev => prev.failed(err));
      });
  };

  const onLoadProperty = (id: number): void => {
    if (properties.data && properties.data.filter((prp: any) => prp.id === id).length > 0) return;

    setProperties(state => state.startLoading());
    backend.get(`${endpointUrls.PROPERTY}/display`, { propertyId: id })
      .then((response: any) => {
        if (properties.data && properties.data.filter((prp: any) => prp.id === id).length > 0) return;
        setProperties(state => state.load(state.data ? state.data.concat(response) : response));
      })
      .catch((error) => {
        console.error(error);
        setProperties(state => state.failed());
        showNotification({
          key: 'loadPropertiesError',
          message: tl(translations.notifications.propertyEditingContext.loadError.message),
          description: tl(translations.notifications.propertyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const getProperty = (id: number) => {
    const property = properties.data ? properties.data.filter((prp: any) => prp.id === id) : [];
    if (property.length > 0) return property[0];
    return null;
  };

  return {
    propertyOptions,
    searchForProperty,
    onLoadProperty,
    getProperty,
  };
}
