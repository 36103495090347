import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import React from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
  title: string,
  totalGross: number,
  nrTotalColumns: number,
  totalNet?: number,
  totalVat?: number,
  displayVatColumns?: boolean,
}

const IncomeTableSubSummary = ({
  title, totalGross, nrTotalColumns, totalNet, totalVat, displayVatColumns = false,
}: Props) => (
  <Table.Summary>
    <Table.Summary.Row className="IncomeTableSubSummary">
      <Table.Summary.Cell index={0} colSpan={nrTotalColumns - (displayVatColumns ? 3 : 1)}>
        {title}
      </Table.Summary.Cell>
      {displayVatColumns && (
        <Table.Summary.Cell className="column-align-right no-wrap" index={nrTotalColumns - 3} colSpan={1}>
          <Amount>{formatCurrency(totalNet, '-', false)}</Amount>
        </Table.Summary.Cell>
      )}
      {displayVatColumns && (
        <Table.Summary.Cell className="column-align-right no-wrap" index={nrTotalColumns - 2} colSpan={1}>
          <Amount>{formatCurrency(totalVat, '-', false)}</Amount>
        </Table.Summary.Cell>
      )}
      <Table.Summary.Cell className="column-align-right no-wrap" index={nrTotalColumns - 1} colSpan={1}>
        <Amount>{formatCurrency(totalGross, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default IncomeTableSubSummary;

IncomeTableSubSummary.defaultProps = {
  totalNet: undefined,
  totalVat: undefined,
  displayVatColumns: false,
}