import React from 'react';
import Icon from '@ant-design/icons';
import { ICONS } from '../../components/icons';
import './LoadingIndicator.scss';

export function LoadingIndicator({ size }: { size?: 'small' | 'default' | 'large' }): JSX.Element {
  return (
    <div className={`LoadingIndicator ${size}`}>
      <Icon component={ICONS.loading} />
    </div>
  );
}

LoadingIndicator.defaultProps = {
  size: 'default',
};
