import React from 'react';
import moment from 'moment';
import { GetUnitContractsUsingGETContractTypesEnum } from 'api/accounting';
import { OwnerPaymentTable } from './OwnerPaymentTable';
import { useActiveContractsList } from '../../services/UnitContractsList/useActiveContractsList';

export interface OwnerPaymentTableWithContractsProps {
  propertyId: number
  amounts?: Array<{unitId: number, amount: number}>,
  atDate?: moment.Moment,
  amountsLoading: boolean

}

export const OwnerPaymentTableWithContracts = ({
  amounts, propertyId, atDate, amountsLoading = false,
}: OwnerPaymentTableWithContractsProps) => {
  const { activeContractsList } = useActiveContractsList({ propertyId, atDate, contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER] });
  return <OwnerPaymentTable amounts={amounts} activeContractsList={activeContractsList?.data} loading={activeContractsList.loading || amountsLoading} />;
};

OwnerPaymentTableWithContracts.defaultProps = {
  amounts: [],
  atDate: moment(),
};
