import { useContext, useState } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import { useConnections } from 'pages/PropertyPortalImport/service/useConnections';
import { useHistory } from 'react-router';
import { translations } from 'elements/Translation/translations';
import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';

import { isEmpty } from 'lodash';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { getPropertyCreationRoute } from 'pages/Property/routes';
import { useFeatureSettings } from 'lib/useFeatureSettings';
import { MenuItem } from 'storybook-components/DropdownButton/DropdownButton';

export const useCreatePropertyButton = () => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>();

  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  const { connections } = useConnections();

  const onChangeSEVCreationModalVisible = () => {
    setIsVisibleModal(prev => !prev);
  };

  const onCancelModal = () => {
    setIsVisibleModal(false);
  };

  const menuOptions:MenuItem[] = [
    {
      key: 'mv/weg',
      label: tl(translations.pages.property.addButton.dropdownOptions.mvAndWegGroupLabel),
      children: [
        {
          key: 'MV',
          onClick: () => history.push(getPropertyCreationRoute(PropertyLegacyDtoAdministrationTypeEnum.MV)),
          label: tl(translations.pages.property.addButton.dropdownOptions.mvLabel),
          'data-testid': 'add-MV-option',
        },
        {
          key: 'WEG',
          onClick: () => history.push(getPropertyCreationRoute(PropertyLegacyDtoAdministrationTypeEnum.WEG)),
          label: tl(translations.pages.property.addButton.dropdownOptions.wegLabel),
          'data-testid': 'add-WEG-option',
        },
      ],
    },
  ];

  const menuOptionsSEV: MenuItem = {
    key: 'sev',
    label: tl(translations.pages.property.addButton.dropdownOptions.sevGroupLabel),
    children: [
      {
        key: 'SEV/mit',
        onClick: onChangeSEVCreationModalVisible,
        label: tl(translations.pages.property.addButton.dropdownOptions.sevWithWegLabel),
      },
      {
        key: 'SEV/ohne',
        onClick: () => { history.push(getPropertyCreationRoute(PropertyLegacyDtoAdministrationTypeEnum.SEV_MV)); },
        label: tl(translations.pages.property.addButton.dropdownOptions.sevWithoutWegLabel),
      },
    ],
  };

  // If they have the tenant contracts in WEGs feature => they should also have SEV
  const { isWegTenantContractEnabled } = useFeatureSettings();

  if (isWegTenantContractEnabled) {
    menuOptions.push(menuOptionsSEV);
  }

  if (!isEmpty(connections)) {
    menuOptions.push({
      key: 'import',
      label: tl(translations.pages.property.addButton.dropdownOptions.importGroupLabel),
      children: connections.map(connection => ({
        key: `${connection.appName} ${connection.name}`,
        onClick: () => history.push(`/properties/import-from-portal/${connection.id}`),
        label: (
          <CellWithTwoLines
            firstElement={<span className="capitalize">{connection.appName}</span>}
            secondElement={connection.name}
          />
        ),
      })),
    });
  }

  return {
    isVisibleModal,
    menuOptions,
    onCancelModal,
  };
};
