import './BankTransactionAllocation.scss';

import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import _ from 'lodash';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import {
  PropertyLegacyDtoAdministrationTypeEnum,
} from '../../../api/accounting';
import {
  ALLOCATION_TYPES,
} from '../interfaces';
import {
  BankTransactionAllocationContext,
} from '../services/BankTransactionAllocationContext';
import ServiceCompanyAllocation
  from './components/ServiceCompanyAllocation/ServiceCompanyAllocation';
import {
  ServiceCompanyAllocationContextProvider,
} from './components/ServiceCompanyAllocation/services/ServiceCompanyAllocationContext';
import {
  AccountsAndContactsProvider,
} from './services/AccountsAndContactsContext';
import {
  useAllocationTypeSelector,
} from './services/AllocationTypeSelectorContext';
import useTransactionAllocation, {
  UpdateListElementFunction,
} from './services/useTransactionAllocation';
import { useTransactionListActions } from '../services/useTransactionListActions';
import { useSelectedTransactions } from '../services/useSelectedTransactions';
import { useInitializePropertyVatRates } from './services/useInitializePropertyVatRates';
import UnitAllocation from './components/UnitAllocation/UnitAllocation';

interface BankTransactionAllocationProps {
  updateListElement: UpdateListElementFunction;
  parentHeight: number;
  parentComponentId: string;
}

export default function BankTransactionAllocation({
  updateListElement,
  parentHeight,
  parentComponentId,
}: BankTransactionAllocationProps): JSX.Element {
  const {
    allocationContentLoading, setAllocationContentLoading, setTransactionActionInProgress,
    unitAllocationAmounts,
  } = useContext(BankTransactionAllocationContext);

  const previousPropertyAdministrationTypes = useRef<PropertyLegacyDtoAdministrationTypeEnum[]>([]);

  const {
    resetTransaction: resetTransactionInList,
    markTransactionNotBookable: markTransactionNotBookableInList,
    markTransactionsAsDuplicate: markTransactionsAsDuplicateInList,
  } = useTransactionListActions();

  const {
    bankTransactionsInCurrentlySelectedGroup: bankTransactionsInGroup,
    selectedTransactions: selectedBankTransactions,
  } = useSelectedTransactions();

  const transactionStatements = useMemo(() => selectedBankTransactions.map(tx => ({
    bankTransactionId: tx.bankTransactionId,
    postingText: tx.purpose,
  })), [selectedBankTransactions.length, selectedBankTransactions[0]?.allocationGroupId]);

  const propertyAdministrationTypes = useMemo(
    () => {
      const newPropertyAdministrationTypes = selectedBankTransactions[0]?.propertyList?.map(p => p.propertyAdministrationType) || [];
      if (_.isEqual(newPropertyAdministrationTypes, previousPropertyAdministrationTypes.current)) {
        return previousPropertyAdministrationTypes.current;
      }

      previousPropertyAdministrationTypes.current = newPropertyAdministrationTypes;
      return newPropertyAdministrationTypes;
    },
    [selectedBankTransactions[0]?.propertyList],
  );

  const { selectedType, setSelectedType, types } = useAllocationTypeSelector(propertyAdministrationTypes);

  const {
    propertyHrIds,
    onAllocateAccounts,
    unitTabDirty,
    setUnitTabDirty,
    allocationProposals,
  } = useTransactionAllocation({ updateListElement, statements: transactionStatements });

  useInitializePropertyVatRates({ propertyHrIds, selectedBankTransactions });

  useEffect(() => {
    if (selectedBankTransactions[0]?.amount === undefined || selectedBankTransactions[0]?.amount === null) return;
    setSelectedType(() => {
      // Incoming amount usually means unit prepayment
      if ((selectedBankTransactions[0]?.amount ?? 0) > 0) {
        if (propertyAdministrationTypes.includes(PropertyLegacyDtoAdministrationTypeEnum.WEG)) {
          // for WEG-s user usually allocates to unit owners
          return ALLOCATION_TYPES.UNIT_OWNER;
        }
        // for MV-s and SEV-s user usually allocates to tenants
        return ALLOCATION_TYPES.TENANT;
      }
      // Outgoing amount usually means invoice
      return ALLOCATION_TYPES.SERVICE_COMPANY;
    });
  }, [selectedBankTransactions[0]?.amount, propertyAdministrationTypes]);


  const transactionIds = !_.isEmpty(selectedBankTransactions) ? selectedBankTransactions.map(tx => tx.bankTransactionId!) : [];
  const allocationGroupId = selectedBankTransactions[0]?.allocationGroupId;

  const resetTransaction = () => {
    setAllocationContentLoading(true);
    setTransactionActionInProgress(true);
    resetTransactionInList(true, allocationGroupId!, transactionIds, selectedBankTransactions || [], () => {
      setAllocationContentLoading(false);
      setTransactionActionInProgress(false);
    });
  };

  const markTransactionNotBookable = () => {
    setAllocationContentLoading(true);
    setTransactionActionInProgress(true);
    markTransactionNotBookableInList(true, allocationGroupId!, transactionIds, selectedBankTransactions || [], () => {
      setAllocationContentLoading(false);
      setTransactionActionInProgress(false);
    });
  };

  const markTransactionDuplicate = () => {
    setAllocationContentLoading(true);
    setTransactionActionInProgress(true);
    markTransactionsAsDuplicateInList(true, allocationGroupId!, transactionIds, selectedBankTransactions || [], () => {
      setAllocationContentLoading(false);
      setTransactionActionInProgress(false);
    });
  };

  if (allocationContentLoading) return <LoadingScreen />;

  return (
    <div className="BankTransactionAllocation page">
      {selectedType === ALLOCATION_TYPES.SERVICE_COMPANY ? (
        <ServiceCompanyAllocationContextProvider>
          <AccountsAndContactsProvider>
            <ServiceCompanyAllocation
              selectedBankTransactions={selectedBankTransactions}
              bankTransactionsInGroup={bankTransactionsInGroup}
              contentMaxHeight={parentHeight - 165 - 41}
              propertyHrIds={propertyHrIds}
              resetTransaction={resetTransaction}
              markTransactionNotBookable={markTransactionNotBookable}
              markTransactionDuplicate={markTransactionDuplicate}
              updateListElement={updateListElement}
              unitAllocations={unitAllocationAmounts}
            />
          </AccountsAndContactsProvider>
        </ServiceCompanyAllocationContextProvider>
      )
        // selectedType === ALLOCATION_TYPES.UNIT
        : (
          <UnitAllocation
            selectedBankTransactions={selectedBankTransactions}
            parentHeight={parentHeight}
            resetTransaction={resetTransaction}
            markTransactionNotBookable={markTransactionNotBookable}
            markTransactionDuplicate={markTransactionDuplicate}
            parentComponentId={parentComponentId}
            onAllocateAccounts={onAllocateAccounts}
            unitTabDirty={unitTabDirty}
            setUnitTabDirty={setUnitTabDirty}
            allocationProposals={allocationProposals}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            types={types}
          />
        )
      }
    </div>
  );
}
