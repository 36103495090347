import React, { useContext, useEffect } from 'react';
import { Prompt, useLocation, useParams } from 'react-router';
import { useShowCorrectionalBookings } from 'pages/EconomicPlan/services/useShowCorrectionalBookings';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import { HouseMoneySectionContextProvider } from '../services/HouseMoneyContext';
import './EconomicPlanHouseMoney.scss';
import { EconomicPlanHouseMoneyHeader } from './components/Header/EconomicPlanHouseMoneyHeader';
import { useLoadUnitFiguresEconomicPlan } from './services/useLoadUnitFiguresEconomicPlan';
import { SectionManagerProvider } from '../../../services/SectionManager/SectionManagerContext';
import { EconomicPlanDataSection } from './sections/EconomicPlanDataSection';
import { HouseMoneyTableSection } from './sections/HouseMoneyTableSection';
import { CorrectionalBookingsSection } from './sections/CorrectionalBookingsSection';
import { translations } from '../../../elements/Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import SimpleDirtModalContextProvider from '../../../elements/Modals/DirtModal/SimpleDirtModal/SimpleDirtModalContext';
import { useSimpleDirtModal } from '../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

const EconomicPlanHouseMoneyComponent = () => {
  const {
    dirty: isDirty,
  } = useSimpleDirtModal();

  const { tl } = useContext(LanguageContext);
  const location = useLocation();
  const { economicPlanId } = useParams<{ economicPlanId: string }>();

  const { showCorrectionalBookings } = useShowCorrectionalBookings();

  const {
    loadUnitFiguresEconomicPlan,
  } = useLoadUnitFiguresEconomicPlan();

  useEffect(() => {
    loadUnitFiguresEconomicPlan(parseInt(economicPlanId, 10));
  }, [economicPlanId]);

  return (

    <Page className="EconomicPlanHouseMoney">
      <Prompt
        when={isDirty}
        message={loc => (loc.pathname.includes(location.pathname) ? true : tl(translations.elements.prompt))}
      />
      <EconomicPlanHouseMoneyHeader />
      <PageContent className="page-content">
        <EconomicPlanDataSection />
        <HouseMoneyTableSection />
        {showCorrectionalBookings && <CorrectionalBookingsSection />}
      </PageContent>
    </Page>
  );
};

export const EconomicPlanHouseMoney = () => (
  <SectionManagerProvider initialOpenSectionId={0}>
    <HouseMoneySectionContextProvider>
      <SimpleDirtModalContextProvider>
        <EconomicPlanHouseMoneyComponent />
      </SimpleDirtModalContextProvider>
    </HouseMoneySectionContextProvider>
  </SectionManagerProvider>
);
