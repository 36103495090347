import { DataIndex } from 'rc-table/lib/interface';
import { ExtendedColumn } from './useTableColumnSelectorExtendedColumns';

export function useTableColumnSelector<T>(columns: ExtendedColumn<T>[], changeColumnVisibility: (dataIndex: DataIndex) => void) {
  const flattenedColumns = columns.flatMap((column: ExtendedColumn<T>) => (column.children ? column.children : column));
  const allChecked = flattenedColumns
    .filter(column => column.visible === false && !column.alwaysVisible).length <= 0;

  const someChecked = flattenedColumns
    .filter(column => column.visible || column.visible === undefined).length > 0;

  const onSelectAll = () => {
    flattenedColumns
      .forEach((col: ExtendedColumn<T>) => {
        if (col.dataIndex && !col.alwaysVisible && (col.visible === allChecked || (allChecked && col.visible === undefined))) changeColumnVisibility(col.dataIndex.toString());
      });
  };


  return {
    allChecked,
    someChecked,
    onSelectAll,
  };
}
