import './PdfViewerWithAssignments.scss';

import React from 'react';

import { SimplePdfViewerProps } from 'elements/PdfViewer/SimplePdfViewer';
import {
  PagesOrientationType,
} from 'pages/PdfPageAssigner/services/interfaces';

import PdfPageWithAssignment
  from './components/PdfPageWithAssignment/PdfPageWithAssignment';
import PdfPreviewSidebar
  from './components/PdfPreviewSidebar/PdfPreviewSidebar';
import PdfViewerWithAssignmentsHeader
  from './components/PdfViewerWithAssignmentsHeader/PdfViewerWithAssignmentsHeader';
import { usePdfViewerWithAssignments } from './usePdfViewerWithAssignments';

interface PdfViewerWithAssignmentsProps extends Pick<SimplePdfViewerProps, 'url' | 'filename' | 'virtualize' | 'gridView'> {
  documentId: number,
  pdfPreviewWidth: number,
}

const PdfViewerWithAssignments = ({
  documentId,
  filename,
  url,
  virtualize,
  pdfPreviewWidth,
}: PdfViewerWithAssignmentsProps) => {
  const {
    viewMode,
    pages,
    pdfFingerprint,
  } = usePdfViewerWithAssignments({ documentId, filename, url });


  return (
    <div className="PdfViewerWithAssignments">
      <div className="pdf-viewer-with-assignments-content">
        {viewMode === PagesOrientationType.LIST ? (
          <PdfPreviewSidebar
            pdfFingerprint={pdfFingerprint}
            pages={pages}
            virtualize={virtualize}
          />
        ) : null}

        <div className="pdf-viewer-with-assignments-pages-wrapper">
          {pages.map(page => (
            <PdfPageWithAssignment
              key={`${documentId}_${page._pageIndex}`}
              documentId={documentId}
              page={page}
              virtualize={virtualize}
              pdfFingerprint={pdfFingerprint}
              pdfPreviewWidth={pdfPreviewWidth}
              viewMode={viewMode}
            />
          ))}
        </div>
      </div>
      {/* keep the header in second place, the order is reversed in css
      this is done in order to avoid z index issues with the assignment selection bar */}
      <PdfViewerWithAssignmentsHeader
        documentId={documentId}
        filename={filename}
      />
    </div>
  );
};

export default PdfViewerWithAssignments;
