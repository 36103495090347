import React, { useContext } from 'react';
import { translations } from '../../../../translations';
import TransactionExecution from '../component/TransactionExecution/TransactionExecution';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';

export function TransactionExecutionFlagSelector(
  onChangeTransactionExecutionFlag: (newValue: boolean) => void,
  transactionsExecutionAllowed: boolean = true,
  disabled: boolean = false,
) {
  const { tl } = useContext(LanguageContext);
  return {
    sectionId: 'transactionExecution',
    title: tl(translations.specialContribution.section3.subsections.transactionExecutionTitle),
    content: (
      <TransactionExecution
        onChange={onChangeTransactionExecutionFlag}
        value={transactionsExecutionAllowed}
        disabled={disabled}
      />),
  };
}
