import React, { useContext } from 'react';
import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { translations } from '../../../translations';
import './ConfirmResetAllocationModal.scss';


interface Props {
  onConfirm: () => void,
  onCancel: () => void,
  isVisible: boolean,
}


const ConfirmResetAllocationModal = ({ onConfirm, onCancel, isVisible }: Props) => {
  const { tl } = useContext(LanguageContext);

  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      className="confirm-reset-allocation-modal"
      footer={[
        <Button key="cancel" type="text" onClick={onCancel}>
          {tl(translations.bankTransactionAllocation.modal.cancel)}
        </Button>,
        <Button
          key="delete"
          type="primary"
          onClick={onConfirm}
        >
          {tl(translations.bankTransactionAllocation.modal.confirm)}
        </Button>,
      ]}
    >
      {tl(translations.bankTransactionAllocation.modal.title)}
    </Modal>
  );
};


export default ConfirmResetAllocationModal;
