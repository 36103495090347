import Icon from '@ant-design/icons';
import React from 'react';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import { ICONS } from '../../../../components/icons';
import './RowExpandIcon.scss';

interface Props<T> extends RenderExpandIconProps<T> {
  needIndentSpaced?: boolean;
}

export function RowExpandIcon(props: Props<any>): JSX.Element {
  const {
    expandable, expanded, record, onExpand, needIndentSpaced,
  } = props;

  if (expandable) {
    return (
      <button
        className={`RowExpandIcon ${expanded ? 'open' : 'closed'}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onExpand(record, e);
        }}
      >
        <Icon component={ICONS.expandRow} />
      </button>
    );
  }

  if (needIndentSpaced) {
    return (<span className="RowExpandIcon placeholder" />);
  }

  return (<></>);
}
