import React from 'react';
import { useHistory } from 'react-router';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { getBankAccountEditPath } from 'pages/BankAccountEditor/routes';
import { useBankAccountTableColumns } from './UseBankAccountTableColumns';
import './BankAccountTable.scss';

export default function BankAccountTable({ property }: any): JSX.Element {
  const history = useHistory();

  const smartTable = useSmartTable(
    {
      tableName: `${property.propertyHrId}Accounts`,
      dataSource: property.bankAccounts,
      rowKey: 'iban',
      columns: useBankAccountTableColumns(),
      contentLoading: false,
      onRow: record => ({ onClick: () => history.push(`/bank-account${getBankAccountEditPath(record.id)}`) }),
      showHeader: true,
      verticallyScrollable: false,

      hideColumnSelector: true,
    },
  );


  return (
    <div className="BankAccountTable">
      <SmartTable {...smartTable} />
    </div>
  );
}
