import './InviteUsers.scss';

import React, { useContext } from 'react';

import {
  Alert,
  ConfigProvider,
  Table,
} from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import TableContentLoadingIndicator
  from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { translations } from 'elements/Translation/translations';
import {
  AddUsersComponent,
} from 'pages/Users/InviteUsers/components/AddUsersComponent/AddUsersComponent';
import EmptyUsersTableComponent
  from 'pages/Users/InviteUsers/components/EmptyUsersTableComponent';
import {
  inviteUsersTranslations,
} from 'pages/Users/InviteUsers/inviteUsersTranslations';
import InviteUsersContextProvider
  from 'pages/Users/InviteUsers/services/InviteUsersContext';
import {
  useInviteUsers,
} from 'pages/Users/InviteUsers/services/useInviteUsers';
import {
  useInviteUsersColumns,
} from 'pages/Users/InviteUsers/services/useInviteUsersColumns';
import { Prompt } from 'react-router';
import { useLocation } from 'react-router-dom';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';

const InviteUsers = () => {
  const { tl } = useContext(LanguageContext);
  const {
    addUsers, goBack, datasource, loading, save, dirty,
  } = useInviteUsers();
  const location = useLocation();
  const columns = useInviteUsersColumns();

  return (
    <Page className="InviteUsers">
      <Prompt
        when={dirty && datasource.length > 0}
        message={loc => (loc.pathname.includes(location.pathname) ? true : tl(translations.elements.prompt))}
      />
      <PageHeader
        title={tl(inviteUsersTranslations.header.title)}
        showLogo
        rightSideComponent={(
          <>
            <Button
              type="text"
              onClick={goBack}
            >
              {tl(inviteUsersTranslations.header.buttons.close)}
            </Button>
            <Button
              type="primary"
              onClick={save}
              disabled={!dirty}
            >
              {tl(inviteUsersTranslations.header.buttons.save)}
            </Button>
          </>
        )}
      />
      <PageContent>
        <PrimarySectionWrapper
          sectionId="coreInfo"
          withContentPadding
          sectionTitle={tl(inviteUsersTranslations.section.title)}
          sectionOpenSuffix={<AddUsersComponent onClickAddUsers={addUsers} />}
        >
          <Alert
            message={tl(inviteUsersTranslations.section.alert.message)}
            description={(
              <ul>
                {tl(inviteUsersTranslations.section.alert.description).map(text => <li key={text}>{text}</li>)}
              </ul>
            )}
          />
          <ConfigProvider renderEmpty={EmptyUsersTableComponent}>
            <Table
              loading={{
                indicator: <TableContentLoadingIndicator />,
                spinning: loading,
              }}
              scroll={{ y: 500 }}
              dataSource={datasource}
              columns={columns}
              pagination={false}
              rowKey="syntheticId"
            />
          </ConfigProvider>
        </PrimarySectionWrapper>
      </PageContent>
    </Page>
  );
};


export default () => (
  <InviteUsersContextProvider>
    <InviteUsers />
  </InviteUsersContextProvider>
);
