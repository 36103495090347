import { useContext } from 'react';
import { OwnersMeetingInvitationFE } from 'data/ownersMeeting';
import { EconomicPlanSimpleProjection, UnitContractProjectionDto, WkaProjectionDto } from 'api/accounting';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import defaultSection from '../DefaultSection';
import { UseStandardDocuments } from './UseStandardDocuments';
import { UseUnitSpecificDocuments } from './UseUnitSpecificDocuments';
import { UseUnitOverviewAndUpload } from './UseUnitOverviewAndUpload';
import { ownersMeetingInvitationTranslations } from '../../translations/OwnersMeetingInvitationTranslations';

interface OwnersMeetingInvitationDocumentsSection{
  index: number,
  setDirty:React.Dispatch<React.SetStateAction<boolean>>,
  ownersMeeting:OwnersMeetingInvitationFE,
  unitContracts: UnitContractProjectionDto[],
  setOwnersMeeting: React.Dispatch<React.SetStateAction<OwnersMeetingInvitationFE>>,
  houseMoneySettlementEconomicYears: [],
  economicPlans: EconomicPlanSimpleProjection[],
  disabled: boolean,
}

interface OwnersMeetingInvitationDocumentsSection{
  index: number,
  setDirty:React.Dispatch<React.SetStateAction<boolean>>,
  ownersMeeting:OwnersMeetingInvitationFE,
  unitContracts: UnitContractProjectionDto[],
  setOwnersMeeting: React.Dispatch<React.SetStateAction<OwnersMeetingInvitationFE>>,
  houseMoneySettlementEconomicYears: [],
  economicPlans: EconomicPlanSimpleProjection[],
  wkas: WkaProjectionDto[],
  disabled: boolean,
}

export default function useOwnersMeetingInvitationDocumentsSection({
  index, setDirty, ownersMeeting, unitContracts, setOwnersMeeting, houseMoneySettlementEconomicYears, economicPlans, wkas, disabled,
}: OwnersMeetingInvitationDocumentsSection) {
  const { tl } = useContext(LanguageContext);
  return {
    ...defaultSection(index, index),
    sectionTitle: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.title),
    sectionId: 'OwnersMeetingInvitationDocumentSection',
    disabled,
    content: [
      UseStandardDocuments(ownersMeeting),
      UseUnitSpecificDocuments(ownersMeeting, setDirty, setOwnersMeeting, houseMoneySettlementEconomicYears, economicPlans, wkas),
      UseUnitOverviewAndUpload(ownersMeeting, unitContracts, setDirty),
    ],
  };
}
