/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data to create a new sync-data
 * @export
 * @interface CreateSyncDataDto
 */
export interface CreateSyncDataDto {
    /**
     * ID of core entity marked for sync
     * @type {number}
     * @memberof CreateSyncDataDto
     */
    entityId?: number;
    /**
     * External ID, or list of ids adjacent ot the core entity
     * @type {string}
     * @memberof CreateSyncDataDto
     */
    externalEntityId?: string;
    /**
     * Id of the process that generated the synced document (only for document sync data)
     * @type {number}
     * @memberof CreateSyncDataDto
     */
    sourceId?: number;
    /**
     * Name of the process that generated the synced document (only for document sync data)
     * @type {string}
     * @memberof CreateSyncDataDto
     */
    sourceType?: CreateSyncDataDtoSourceTypeEnum;
    /**
     * State resulted after data sync
     * @type {string}
     * @memberof CreateSyncDataDto
     */
    state?: CreateSyncDataDtoStateEnum;
    /**
     * Details of the sync process
     * @type {string}
     * @memberof CreateSyncDataDto
     */
    stateData?: string;
    /**
     * Human-readable description of the sync process
     * @type {string}
     * @memberof CreateSyncDataDto
     */
    stateText?: string;
    /**
     * Time in which the last sync was attempted
     * @type {Date}
     * @memberof CreateSyncDataDto
     */
    syncTimestamp?: Date;
}

/**
* @export
* @enum {string}
*/
export enum CreateSyncDataDtoSourceTypeEnum {
    HOUSE_MONEY_SETTLEMENT = 'HOUSE_MONEY_SETTLEMENT',
    ECONOMIC_PLAN = 'ECONOMIC_PLAN',
    BANK_ORDER = 'BANK_ORDER',
    BANK_TRANSACTION = 'BANK_TRANSACTION',
    HEATING_COST_DISTRIBUTION = 'HEATING_COST_DISTRIBUTION',
    INVOICE = 'INVOICE',
    SERIAL_LETTER = 'SERIAL_LETTER',
    OWNERS_MEETING_INVITATION = 'OWNERS_MEETING_INVITATION',
    OWNERS_MEETING_PROTOCOL = 'OWNERS_MEETING_PROTOCOL',
    PROFIT_AND_LOSS = 'PROFIT_AND_LOSS',
    OPS_COST_REPORT = 'OPS_COST_REPORT',
    SPECIAL_CONTRIBUTION = 'SPECIAL_CONTRIBUTION',
    DUNNING_ANNEX = 'DUNNING_ANNEX',
    DUNNING = 'DUNNING',
    DUNNING_DD_MANDATE = 'DUNNING_DD_MANDATE',
    WATERMARK = 'WATERMARK',
    MESSAGE = 'MESSAGE',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum CreateSyncDataDtoStateEnum {
    READY = 'READY',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}


/**
 * Check if a given object implements the CreateSyncDataDto interface.
 */
export function instanceOfCreateSyncDataDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSyncDataDtoFromJSON(json: any): CreateSyncDataDto {
    return CreateSyncDataDtoFromJSONTyped(json, false);
}

export function CreateSyncDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSyncDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'externalEntityId': !exists(json, 'externalEntityId') ? undefined : json['externalEntityId'],
        'sourceId': !exists(json, 'sourceId') ? undefined : json['sourceId'],
        'sourceType': !exists(json, 'sourceType') ? undefined : json['sourceType'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'stateData': !exists(json, 'stateData') ? undefined : json['stateData'],
        'stateText': !exists(json, 'stateText') ? undefined : json['stateText'],
        'syncTimestamp': !exists(json, 'syncTimestamp') ? undefined : (new Date(json['syncTimestamp'])),
    };
}

export function CreateSyncDataDtoToJSON(value?: CreateSyncDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'externalEntityId': value.externalEntityId,
        'sourceId': value.sourceId,
        'sourceType': value.sourceType,
        'state': value.state,
        'stateData': value.stateData,
        'stateText': value.stateText,
        'syncTimestamp': value.syncTimestamp === undefined ? undefined : (value.syncTimestamp.toISOString()),
    };
}

