import {
  useContext, useEffect, useRef, useState, MutableRefObject,
} from 'react';
import './SerialLetterEditor.scss';
import { Modal } from 'antd';
import { Prompt, useLocation, useParams } from 'react-router';
import _ from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import FormSection from 'elements/FormElements/FormSection/FormSection';
import { deleteKey, setValue } from 'lib/Utils';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import Button from 'elements/Buttons/Button/Button';
import { DefaultDataInterface } from 'lib/data';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import AllContractsForMultiplePropertiesContextProvider from 'services/UnitContractsList/ContractsForMultipleProperties/AllContractsForMultiplePropertiesContex';
import { Location } from 'history';
import useLetterDataSection from './services/useLetterDataSection';
import { useSerialLetter } from '../../services/useSerialLetter';
import { translations } from '../../translations';
import { SerialLetter } from '../../interfaces';
import SerialLetterHeaderButtons from './components/SerialLetterHeaderButtons/SerialLetterHeaderButtons';
import { useSendOutSerialLetter } from './services/useSendOutSerialLetter';


const getViewMode = (location: Location<any>, prevViewMode: MutableRefObject<boolean>) => {
  const splitVal = location.pathname.split('/')[2];
  const newViewMode = splitVal === undefined ? prevViewMode.current : splitVal === 'view';
  prevViewMode.current = newViewMode;

  return newViewMode;
};


export default function SerialLetterEditor(): JSX.Element {
  const { letterId } = useParams<{ letterId: any }>();
  const location = useLocation<any>();
  const { goBack } = useContext(OverlayContext);

  /**
   * We need to track prevViewMode because when you close the overlay then `location.pathname.split('/')[2]`
   * is undefined so with the old logic it would show the Create header for a split second, causing
   * an ugly flicker.
   */
  const prevViewMode = useRef(false);
  const viewMode = getViewMode(location, prevViewMode);

  const [openSectionIndex, setOpenSectionIndex] = useState<number>(() => {
    if (location.state?.openSectionIndex) {
      return location.state.openSectionIndex;
    }
    return 0;
  });

  const [dirtModalVisible, setDirtModalVisible] = useState<boolean>(false);
  const [dirtModalSection, setDirtModalSection] = useState(-1);
  const openNextSectionOnSuccessRef = useRef(false);

  const { tl } = useContext(LanguageContext);
  const serialLetterHook = useSerialLetter();
  const {
    isDirty, setDirty, isSaveResponseValid, serialLetter, setSerialLetter, setSaveResponseValid,
  } = serialLetterHook;

  const {
    onSendOutSerialLetter,
    onMarkLetterSent,
  } = useSendOutSerialLetter({ serialLetter, setSerialLetter, setSaveResponseValid });

  useEffect(() => {
    if (location.state?.messagesCreated) {
      onMarkLetterSent(letterId);
    }
  }, [location.state, letterId]);

  useEffect(() => {
    if (openSectionIndex >= 0) {
      const sectionId = sections[openSectionIndex] ? sections[openSectionIndex].sectionId : null;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openSectionIndex]);

  useEffect(() => {
    if (letterId) serialLetterHook.setSelectedSerialLetterId(letterId);
  }, [letterId, location]);


  useEffect(() => () => {
    serialLetterHook.onClearSerialLetter();
  }, []);

  useEffect(() => {
    if (serialLetterHook.serialLetter.saved) {
      setDirty(false);

      if (_.isEmpty(serialLetterHook.serialLetter.validationErrors)) {
        if (viewMode) {
          setOpenSectionIndex(-1);
        } else if (openNextSectionOnSuccessRef.current) {
          if (dirtModalSection !== -1) {
            setOpenSectionIndex(dirtModalSection);
            setDirtModalSection(-1);
          } else {
            setOpenSectionIndex(openSectionIndex + 1);
          }
        } else {
          setOpenSectionIndex(-1);
        }
      }
    }
    setDirtModalVisible(false);
  }, [serialLetterHook.serialLetter.saved]);

  const isSerialLetterSection1Valid = !!serialLetterHook.serialLetter.data?.subject && !!serialLetterHook.serialLetter.data?.body
      && !_.isEmpty(serialLetterHook.serialLetter.data?.properties);

  const sendOut = (): void => {
    if (isDirty || letterId === undefined) {
      serialLetterHook.onSaveSerialLetter(openSectionIndex + 1, letterId)
        .then((sl: SerialLetter) => {
          onSendOutSerialLetter(sl.id);
        });
    } else {
      onSendOutSerialLetter(letterId);
    }
  };

  const customOnChange = (v: any, key: string) => {
    serialLetterHook.setSerialLetter((letter: DefaultDataInterface<SerialLetter>) => {
      const newLetter = _.cloneDeep(letter.data);
      if (!(typeof v === 'undefined' || v === '' || v === null || v.length === 0)) {
        setValue(newLetter, key, v);
      } else {
        deleteKey(newLetter, key);
      }
      return letter.load(newLetter!);
    });
  };

  let sections: any[] = [
    useLetterDataSection({
      index: 0, serialLetter: serialLetterHook.serialLetter, onChange: customOnChange, setDirty,
    }),
  ];
  const save = (openNextSectionOnSuccessParam: boolean): void => {
    openNextSectionOnSuccessRef.current = openNextSectionOnSuccessParam;
    setDirtModalSection(-1);
    serialLetterHook.onSaveSerialLetter(openSectionIndex + 1, letterId);
  };

  const handleDirtModalDiscard = (): void => {
    setDirtModalVisible(false);
    setDirty(false);
    if (letterId) {
      serialLetterHook.onLoadSerialLetter();
    } else {
      serialLetterHook.onClearSerialLetter();
    }

    if (openSectionIndex === dirtModalSection) setOpenSectionIndex(-1);
    else setOpenSectionIndex(dirtModalSection);
  };

  const handleDirtModalSave = (): void => {
    openNextSectionOnSuccessRef.current = true;
    serialLetterHook.onSaveSerialLetter(openSectionIndex + 1, letterId);
  };

  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (openSectionIndex === nextSectionIndex) nextSectionIndex = -1;
    if (isDirty) {
      setDirtModalVisible(true);
      setDirtModalSection(nextSectionIndex);
    } else {
      setOpenSectionIndex(nextSectionIndex);
    }
  };

  const ViewPageHeader = (): JSX.Element => {
    const cancelButton = (
      <Button
        type="text"
        onClick={goBack}
      >
        {tl(translations.editPage.close)}
      </Button>
    );

    return (
      <PageHeader
        title={tl(translations.editPage.createTitle)}
        rightSideComponent={cancelButton}
      />
    );
  };

  const EditPageHeader = (): JSX.Element => (
    <PageHeader
      title={letterId ? tl(translations.editPage.editTitle) : tl(translations.editPage.createTitle)}
      rightSideComponent={(
        <SerialLetterHeaderButtons
          isSendOutValid={isSerialLetterSection1Valid && isSaveResponseValid}
          onSave={save}
          onSend={sendOut}
          letterId={serialLetter.data?.id}
          loading={serialLetter.loading}
          letterState={serialLetter.data?.shippingStatus}
        />
      )}
    />
  );

  return (
    <Page className="SerialLetterEditor">
      <Prompt
        when={isDirty}
        message={loc => (location.pathname === loc.pathname
          ? true : tl(translations.notifications.prompt))}
      />
      {viewMode
        ? <ViewPageHeader />
        : <EditPageHeader />
      }
      <PageContent className={`page-content ${viewMode ? 'view-mode' : 'edit-mode'}`}>
        <div className="form-content">
          <div id="scrollElement">
            {sections.map((section: any) => {
              const prevSectionIndex = section.openingNumber - 1;
              const onChange = (value: any) => {
                serialLetterHook.setSerialLetter(letter => letter.load(value));
              };
              return (
                <FormSection
                  key={section.openingNumber}
                  open
                  addDirt={() => setDirty(true)}
                  onClickHeader={() => handleOpenSectionChange(prevSectionIndex)}
                  onSubmit={() => save(true)}
                  onChange={onChange}
                  hideSaveButton={viewMode}
                  loading={serialLetterHook.serialLetter.loading}
                  validationErrors={serialLetterHook.serialLetter.validationErrors}
                  {...section}
                />
              );
            })}
          </div>
        </div>
      </PageContent>
      <Modal
        visible={dirtModalVisible}
        title={tl(translations.editPage.dirtModal.title)}
        onCancel={() => setDirtModalVisible(false)}
        footer={[
          <Button key="cancel" type="text" onClick={() => setDirtModalVisible(false)}>
            {tl(translations.editPage.dirtModal.cancel)}
          </Button>,
          <Button key="discard" type="ghost" onClick={handleDirtModalDiscard}>
            {tl(translations.editPage.dirtModal.discard)}
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleDirtModalSave}
            loading={serialLetterHook.serialLetter.loading}
          >
            {tl(translations.editPage.dirtModal.save)}
          </Button>,
        ]}
      >
        <p>{tl(translations.editPage.dirtModal.description)}</p>
      </Modal>
    </Page>
  );
}

export const SerialLetterEditorWithProvider = () => (
  <AllContractsForMultiplePropertiesContextProvider>
    <SerialLetterEditor />
  </AllContractsForMultiplePropertiesContextProvider>
);
