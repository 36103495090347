import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import { getViewContactRoute } from 'pages/Contacts';
import { useGetContactById } from 'services/useGetContactById';

export const useContactLink = (contactId: number) => {
  const { contact } = useGetContactById(contactId);

  const url = getViewContactRoute({ contactId: contact?.id });

  const name = contact?.type === ContactLegacyDtoTypeEnum.PERSON
    ? `${contact?.firstName || ''} ${contact?.lastName || ''}`
    : `${contact?.companyName || ''}`;

  return {
    name,
    url,
  };
};
