import { GetMessagesUsingGETSourceTypeEnum } from 'api/accounting/apis/MessageControllerApi';

export const messagesListTranslations = {
  header: {
    state: {
      en: 'State',
      de: 'Status',
    },
    property: {
      de: 'Objekt',
      en: 'Property',
    },
    title: {
      en: 'Title',
      de: 'Titel',
    },
    sourceId: {
      en: 'Process ID',
      de: 'Prozess ID',
    },
    recipient: {
      en: 'Recipient',
      de: 'Empfänger',
    },
    accountant: {
      en: 'Accountant',
      de: 'Buchhalter',
    },
    supervisor: {
      en: 'Administrator',
      de: 'Administrator',
    },
    createdOn: {
      en: 'Created on',
      de: 'Erstellt am',
    },
    createdBy: {
      en: 'Created by',
      de: 'Erstellt von',
    },
    updated: {
      en: 'Last update',
      de: 'Letztes Update',
    },
  },
  messages: {
    en: 'Messages',
    de: 'Nachrichten',
  },
  lastUpdated: {
    en: 'Last update',
    de: 'letzte Aktualisierung ',
  },
  property: {
    en: 'Property',
    de: 'Objekte',
  },
  notifications: {
    downloadError: {
      message: {
        en: 'Failed to download document',
        de: 'Fehler beim Herunterladen des Dokuments.',
      },
    },
    loadError: {
      message: {
        en: 'Error loading messages.',
        de: 'Fehler beim Laden.',
      },
    },
    retry: {
      retryError: {
        message: {
          en: 'Error retrying sending message',
          de: 'Versand fehlgeschlagen',
        },
      },
      retrySuccess: {
        message: {
          en: 'Retrying sending message',
          de: 'Erneute Versand wurde gestartet.',
        },
      },

    },
  },
  states: {
    SENT: {
      en: 'Sent',
      de: 'Gesendet',
    },
    READY: {
      en: 'Ready to send',
      de: 'Versandfertig',
    },
    GENERATING: {
      en: 'Generating',
      de: 'Wird erstellt',
    },
    SENDING: {
      en: 'Sending',
      de: 'Senden',
    },
    FAILED: {
      en: 'Error',
      de: 'Fehler',
    },
    DELETED: {
      en: 'Deleted',
      de: 'Gelöscht',
    },
    DRAFT: {
      en: 'Draft',
      de: 'Entwurf',
    },
  },
  tableOptionMenu: {
    download: {
      en: 'Download',
      de: 'Herunterladen',
    },
    retry: {
      en: 'Retry',
      de: 'Erneut versenden',
    },
  },
  filters: {
    propertyInternalId: {
      en: 'Property internal ID',
      de: 'Objekt-Nr Intern',
    },
    propertyName: {
      en: 'Property name',
      de: 'Objektname',
    },
    minUpdated: {
      en: 'Update date from',
      de: 'Letztes Update von',
    },
    maxUpdated: {
      en: 'Update date until',
      de: 'Letztes Update bis',
    },
    createdBy: {
      en: 'Created by',
      de: 'Erstellt von',
    },
    applicationName: {
      en: 'Application',
      de: 'Versandart',
    },
    businessPartnerName: {
      en: 'Recipient name',
      de: 'Empfänger Name',
    },
    processType: {
      en: 'Process type',
      de: 'Prozesstyp',
    },
    sourceTypes: {
      [GetMessagesUsingGETSourceTypeEnum.DUNNING]: {
        en: 'Dunning',
        de: 'Offene Salden',
      },
      [GetMessagesUsingGETSourceTypeEnum.SERIAL_LETTER]: {
        en: 'Serial Letter',
        de: 'Serienbrief',
      },
      [GetMessagesUsingGETSourceTypeEnum.OM_INVITE]: {
        en: 'Owners Meeting Invite',
        de: 'Eigentümerversammlung Einladung',
      },
      [GetMessagesUsingGETSourceTypeEnum.OM_PROTOCOL_SIGN]: {
        en: 'Owners Meeting Protocol Sign',
        de: 'Eigentümerversammlung Protokollunterschrift',
      },
      [GetMessagesUsingGETSourceTypeEnum.OM_PROTOCOL_OWNER]: {
        en: 'Owners Meeting Protocol',
        de: 'Eigentümerversammlung Protokolle',
      },
      [GetMessagesUsingGETSourceTypeEnum.PROFIT_AND_LOSS]: {
        en: 'Profit and Loss',
        de: 'Eigentümerbericht',
      },
      [GetMessagesUsingGETSourceTypeEnum.OPS_COST_REPORT]: {
        en: 'Ops Cost Report',
        de: 'Betriebskostenabrechnung',
      },
      [GetMessagesUsingGETSourceTypeEnum.ECONOMIC_PLAN]: {
        en: 'Economic plan',
        de: 'Wirtschaftsplan',
      },
      [GetMessagesUsingGETSourceTypeEnum.HOUSE_MONEY_SETTLEMENT]: {
        en: 'House Money Settlement',
        de: 'Hausgeldabrechnung',
      },
      [GetMessagesUsingGETSourceTypeEnum.SPECIAL_CONTRIBUTION]: {
        en: 'Special contribution',
        de: 'Sonderumlage',
      },
      [GetMessagesUsingGETSourceTypeEnum.HEATING_COST_DISTRIBUTION]: {
        en: 'WKA',
        de: 'WKA',
      },
    },
    contactId: {
      en: 'Contact Id',
      de: 'Kontakt Id',
    },
    administrator: {
      en: 'Administrator',
      de: 'Betreuer',
    },
    accountant: {
      en: 'Accountant',
      de: 'Buchhalter',
    },
  },
  actionBar: {
    warningTooltip: {
      en: 'Some of the selected messages are not be visible — change the filters or keep scrolling.',
      de: 'Einige der ausgewählten Nachrichten sind nicht sichtbar — ändern Sie die Filter oder scrollen Sie weiter',
    },
    unselectAll: {
      en: 'Unselect all',
      de: 'Auswahl widerrufen',
    },
    showAllSelected: {
      en: 'Show all selected',
      de: 'Alle ausgewählten anzeigen',
    },
  },
  downloadAllMerged: {
    en: 'Download all merged',
    de: 'Alle herunterladen',
  },
  downloadAllZip: {
    en: 'Download all in zip',
    de: 'Alle herunterladen als Zip',
  },
  downloadSelectedMerged: {
    en: 'Download selected merged',
    de: 'Ausgewählte herunterladen',
  },
  downloadSelectedZip: {
    en: 'Download selected in zip',
    de: 'Ausgewählte herunterladen als Zip',
  },
};
