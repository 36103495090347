import { useContext } from 'react';
import { OwnersMeetingInvitationContext } from 'contexts/OwnersMeetingInvitationContext';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { useHistory } from 'react-router';


export const useOwnerMeetingInvitationHeaderEditing = () => {
  const ownersMeetingContext: any = useContext(OwnersMeetingInvitationContext);

  if (ownersMeetingContext === undefined) {
    throw new Error('useOwnerMeetingInvitationHeaderEditing must be used within a OwnersMeetingInvitationProvider');
  }

  const history = useHistory();
  const {
    setDirty,
    ownersMeeting,
    isValid,
    onLoadOwnersMeetingList,
    onDeleteOwnersMeetingInvitation,
    selectedOwnersMeetingId,
  } = ownersMeetingContext;

  const { goBack } = useContext(OverlayContext);


  const onClose = () => {
    onLoadOwnersMeetingList(true);
    goBack();
    setDirty(false);
  };

  const onCheckMessages = () => {
    history.push(`/messages?sourceId=${ownersMeeting.data.id}`);
  };

  return {
    onClose,
    isValid,
    ownersMeeting: ownersMeeting?.data,
    loading: ownersMeeting?.loading,
    onCheckMessages,
    onDelete: onDeleteOwnersMeetingInvitation,
    showSubtitle: !!selectedOwnersMeetingId,
  };
};
