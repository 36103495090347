import _ from 'lodash';

import { ExternalPropertyIssue } from '../interfaces';

export const calculateRelevance = (searchTerm: string): ((ExternalPropertyIssue) => ExternalPropertyIssue) => {
  const terms = searchTerm
    .toLocaleLowerCase()
    .split(' ')
    .filter(s => s)
    .map(s => s.trim());
  return (ticket: ExternalPropertyIssue) => {
    const allTermsMatch = terms.reduce((total, term) => {
      // base rule: all term shall match
      const termFound = ticket?.searchValue?.indexOf(term) >= 0;
      return total && termFound;
    }, true);
    const relevanceScore = allTermsMatch ? 1 : 0;
    return { ...ticket, relevanceScore };
  };
};
