import React, { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useExecuteButton } from './useExecuteButton';


const ExecuteButton = () => {
  const { tl } = useContext(LanguageContext);
  const { execute, loading, disabled } = useExecuteButton();

  return (
    <Button onClick={execute} type="primary" loading={loading} disabled={disabled}>
      {tl(messageSendingTranslations.execute)}
    </Button>
  );
};

export default ExecuteButton;
