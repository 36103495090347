import React, { useContext } from 'react';
import { InviteUsersContext } from 'pages/Users/InviteUsers/services/InviteUsersContext';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import SelectOptionWithTwoLines from 'storybook-components/inputs/select/SelectOptionWithTwoLines/SelectOptionWithTwoLines';

export const useSelectUserRole = (id: string) => {
  const inviteUsersContext = useContext(InviteUsersContext);
  if (inviteUsersContext === undefined) {
    throw Error('useSelectUserRole not wrapped in InviteUsersContextProvider');
  }
  const {
    usersList, setUsersList, setDirty, roles,
  } = inviteUsersContext;

  const user = usersList.data.find(it => it.syntheticId === id);
  if (user === undefined) {
    throw Error(`No user available with id ${id}`);
  }

  const onChange = (value: number) => {
    setUsersList(prev => prev.load(
      prev.data.map(usr => (
        id !== usr.syntheticId ? usr
          : ({
            ...usr,
            roleId: value,
            validationState: {
              ...usr.validationState,
              role: undefined,
            },
          })
      )),
    ));
    setDirty(true);
  };

  return {
    options: roles?.data?.map(role => ({ label: role.name, value: role.id, customBody: <SelectOptionWithTwoLines primaryText={role.name} secondaryText={role.description} /> }) as SelectOption),
    loading: true,
    value: user.roleId,
    onChange,
    validationState: user.validationState.role,
  };
};
