import { useContext } from 'react';
import { EconomicPlanContext } from './EconomicPlanContext';

export const useClearEconomicPlanContext = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useClearEconomicPlanContext must be used within a EconomicPlanContextProvider');
  }

  const {
    setEconomicPlan,
    setEconomicPlanCreationData,
  } = economicPlanContext;

  const onClearEconomicPlan = () => {
    setEconomicPlanCreationData(prev => prev.load({}));
    setEconomicPlan(prevState => prevState.load({}));
  };

  return {
    onClearEconomicPlan,
  };
};
