import React, { useContext, useMemo } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import SmartTable from '../../../../elements/SmartTable/SmartTable';
import useSmartTable from '../../../../elements/SmartTable/useSmartTable';
import { UseMonthlyPaymentTableColumns } from './UseMonthlyPaymentTableColumns';
import SummaryRow from '../../../../elements/CustomElements/ExpandableCard/SummaryRow';
import './MonthlyPaymentTable.scss';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { formatCurrency } from '../../../../lib/Utils';
import { economicPlanOverviewTranslations } from '../economicPlanOverviewTranslations';

interface MonthlyPaymentsTableProps {
  dataSource: any[],
  loading: boolean
}

interface MonthlyPaymentTotalProps {
  dataSource: any[],
}

export function OwnershipDuesTable(props: MonthlyPaymentsTableProps) {
  const { dataSource, loading } = props;

  const table = useSmartTable({
    tableName: 'monthlyPayments',
    columns: UseMonthlyPaymentTableColumns(),
    dataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    rowKey: 'name',
  });

  return (
    <div className="OwnershipDuesTable">
      <SmartTable {...table} />
    </div>
  );
}

export function useRenderOwnerShipDuesTotal(props: MonthlyPaymentTotalProps) {
  const { dataSource } = props;
  const { tl } = useContext(LanguageContext);
  const defaultSummary = {
    previous: 0,
    newAmount: 0,
    difference: 0,
  };

  const summaryValues = useMemo(() => (dataSource ? dataSource.reduce((accumulator: any, currentItem: any) => {
    accumulator.previous += currentItem.previous;
    accumulator.newAmount += currentItem.newAmount;
    accumulator.difference += (currentItem.newAmount - currentItem.previous);
    return accumulator;
  }, defaultSummary) : defaultSummary), [dataSource]);

  const summaryElements = [
    {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.previous),
      value: <Amount>{formatCurrency(summaryValues.previous)}</Amount>,
    }, {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.newAmount),
      value: <Amount>{formatCurrency(summaryValues.newAmount)}</Amount>,
    },
    {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.difference),
      value: <Amount>{formatCurrency(summaryValues.difference)}</Amount>,
    },
  ];

  return (
    <SummaryRow rightSideElements={summaryElements} />
  );
}

export default function MonthlyPaymentTable(props: MonthlyPaymentsTableProps): JSX.Element {
  const { dataSource, loading } = props;

  return (
    <div className="MonthlyPaymentTable">
      <OwnershipDuesTable dataSource={dataSource} loading={loading} />
      <div className="monthly-payments">
        {useRenderOwnerShipDuesTotal({ dataSource })}
      </div>
    </div>
  );
}
