import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import {
  SelectInputValuesStateContext,
  ServiceCompanyAllocationUpdatersContext,
  ServiceCompanyAllocationValues,
} from '../../../../services/ServiceCompanyAllocationContext';
import { translations } from '../../../../../../../translations';

export const useVatCell = (record: ServiceCompanyAllocationValues) => {
  const { tl } = useContext(LanguageContext);
  const selectInputValues = useContext(SelectInputValuesStateContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);

  if (updatersContext === undefined) {
    throw new Error('useVatCell must be used within a ServiceCompanyAllocationContextProvider!');
  }

  const headerTranslations = translations.bankTransactionAllocation.serviceCompanyAllocation.headers;
  const placeholder = tl(headerTranslations.vat);

  const { setSelectInputValues } = updatersContext;

  const currentValue = selectInputValues?.find(alloc => alloc.key === record.key)?.vatPercentage;

  const commaSeparatedValues = Array.isArray(record.vatPercentage)
    ? record.vatPercentage.map(vat => `${vat} %`).join(', ')
    : '';


  const onChange = (value: number) => {
    setSelectInputValues(prev => prev.map(selectInput => (selectInput.key === record.key
      ? { ...selectInput, vatPercentage: value }
      : selectInput)));
  };

  const options = [
    {
      value: 19,
      label: '19%',
    },
    {
      value: 7,
      label: '7%',
    },
    {
      value: 16,
      label: '16%',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 20,
      label: '20%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 0,
      label: '0%',
    },
  ];

  return {
    selectInputValue: currentValue,
    commaSeparatedValues,
    onChange,
    placeholder,
    options,
  };
};
