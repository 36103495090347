import { useOrderOpenBalancesContext } from '../../services/OrderOpenBalancesContext';

export const useOpenAmountCell = () => {
  const { orderOpenbalanceFilterState } = useOrderOpenBalancesContext('useOrderOpenBalancesList');

  const postDateFilterInactive = !orderOpenbalanceFilterState?.balanceUntil;

  return {
    postDateFilterInactive,
  };
};
