import { ExtendedExchangeProjectionTypeEnum } from 'api/accounting';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { isEmpty } from 'lodash';
import { useAccountsAndContacts } from 'pages/BankTransactions/BankTransactionAllocation/services/AccountsAndContactsContext';
import React, { useContext, useMemo } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import { OptionProps } from 'rc-select/lib/Option';
import LinkToAccountSheet from '../../../../../LinkToAccountSheet/LinkToAccountSheet';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import useAccountCell from './useAccountCell';
import { translations } from '../../../../../../../translations';

interface Props {
  value: any,
  record: ServiceCompanyAllocationValues,
}

const AccountCell = ({
  value,
  record,
}: Props) => {
  const { tl } = useContext(LanguageContext);
  const { accounts } = useAccountsAndContacts();
  const {
    currentValue,
    onChange,
    propertyHrId,
  } = useAccountCell(record.key);
  const headerTranslations = translations.bankTransactionAllocation.serviceCompanyAllocation.headers;

  const selectOptions = useMemo(() => (accounts.data?.filter(a => (a.propertyHrId === propertyHrId)).map(({
    code,
    name,
  }) => ({
    value: code!,
    label: `${code} ${name}`,
  })) || []), [accounts.data, propertyHrId]);

  if (record.type === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
    return (
      <SelectInput
        size="large"
        showSearch
        placeholder={tl(headerTranslations.account)}
        value={currentValue}
        disabled={isEmpty(accounts.data) || accounts.loading || accounts.error}
        defaultValue={record.accounts?.[0]?.code}
        options={selectOptions}
        onChange={onChange}
        dropdownClassName="large-dropdown"
        filterOption={((input: string, option: OptionProps) => (
          option?.title?.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            || option?.title?.toString().replaceAll('/', '').includes(input)
        )
        )}
      />
    );
  }

  const accountCodesAsLinks = value?.map(({
    code,
    name,
  }: { code: string, name: string }, index: number) => (
    <>
      <LinkToAccountSheet
        accountCode={code}
        propertyHrId={propertyHrId}
      >
        {`${code} - ${name}`}
      </LinkToAccountSheet>
      {index < value.length - 1 ? ', ' : null}
    </>
  ));

  return <PopoverTruncatingText value={accountCodesAsLinks} containerClassName="ExchangeList" />;
};

export default AccountCell;
