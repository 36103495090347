import { ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useState } from 'react';
import { translations } from 'pages/BankTransactions/translations';
import { AllocationLaborCostValuesStateContext, ServiceCompanyAllocationUpdatersContext } from 'pages/BankTransactions/BankTransactionAllocation/components/ServiceCompanyAllocation/services/ServiceCompanyAllocationContext';
import { isEmpty } from 'lodash';
import { ValidationErrors } from 'lib/interfaces/ValidationErrorInterface';


export const useAllocationLaborCostModal = (key: string, onClose: () => void) => {
  const { tl } = useContext(LanguageContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);
  const allocationLaborCostValues = useContext(AllocationLaborCostValuesStateContext);


  if (updatersContext === undefined || allocationLaborCostValues === undefined) {
    throw new Error('useAllocationLaborCostModal must be used within a ServiceCompanyAllocationContextProvider');
  }


  const { setAllocationLaborCostValues } = updatersContext;
  const { laborCostAmount, laborCostType } = allocationLaborCostValues?.find(alloc => alloc.key === key) ?? {};

  const [laborCostTypeLocalValue, setLaborCostTypeLocalValue] = useState(laborCostType);
  const [laborCostAmountLocalValue, setLaborCostAmountLocalValue] = useState(Math.abs(laborCostAmount));
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});


  const { laborCostTypes: laborCostTypesTranslations, laborCostModal: laborCostModalTranslations } = translations.bankTransactionAllocation.serviceCompanyAllocation;


  const laborCostTypeOptions = Object.values(
    ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
  ).map(type => ({
    value: type,
    label: tl(laborCostTypesTranslations[type]),
  }));


  const getValidationErrors = () => {
    const errors: ValidationErrors = {};

    if (laborCostAmountLocalValue > 0 && laborCostTypeLocalValue === undefined) {
      errors.laborCostType = {
        state: 'error',
        message: tl(laborCostModalTranslations.validation.required),
      };
    }

    if (laborCostTypeLocalValue !== undefined && laborCostAmountLocalValue === 0) {
      errors.laborCostAmount = {
        state: 'error',
        message: tl(laborCostModalTranslations.validation.required),
      };
    }

    return errors;
  };

  const onCancel = () => {
    setValidationErrors({});
    onClose();
  };

  const onApply = () => {
    const errors = getValidationErrors();
    setValidationErrors(errors);
    if (!isEmpty(errors)) {
      return;
    }

    setAllocationLaborCostValues(prev => prev.map((laborCost) => {
      if (laborCost.key === key) {
        return {
          ...laborCost,
          laborCostAmount: laborCostAmountLocalValue,
          laborCostType: laborCostTypeLocalValue,
        };
      }

      return laborCost;
    }));

    onClose();
  };

  const onChangeLaborCostAmount = (val: number) => {
    setLaborCostAmountLocalValue(val);
  };

  const onChangeLaborCostType = (val: ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum) => {
    setLaborCostTypeLocalValue(val);
  };


  return {
    laborCostTypeOptions,
    onApply,
    onCancel,
    onChangeLaborCostAmount,
    onChangeLaborCostType,
    laborCostAmount: Math.abs(laborCostAmount),
    laborCostType,
    validationErrors,
  };
};
