import { useContext } from 'react';
import { LanguageContext } from '../../../../../contexts/LanguageContext';

export default function useDefaultSection(index: number) {
  const { tl } = useContext(LanguageContext);

  return {
    sectionNumber: index + 1,
    openingNumber: index + 1,
  };
}
