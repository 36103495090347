import { UnitContractProjectionDtoVatRelevanceEnum, UnitOwnerAccountBalanceDtoContractVatRelevanceEnum, VatRateDto } from 'api/accounting';
import Big from 'big.js';
import { isNil } from 'lodash';

export const calculateGrossAmount = (netAmount: number | undefined, vatPercentage: number | undefined) => {
  if (!vatPercentage || !netAmount) return netAmount;
  const netAmountBig = new Big(netAmount);
  const vatPercentageBig = new Big(vatPercentage);
  return netAmountBig.toNumber() !== 0
    ? parseFloat(netAmountBig.times(new Big(100).plus(vatPercentageBig)).div(new Big(100)).toFixed(2))
    : 0;
};

export const calculateNetAmount = (grossAmount: number | undefined, vatPercentage: number | undefined) => {
  if (!vatPercentage || !grossAmount) return grossAmount;
  const grossAmountBig = new Big(grossAmount);
  const vatPercentageBig = new Big(vatPercentage);
  return parseFloat(grossAmountBig.times(new Big(100)).div(new Big(100).plus(vatPercentageBig)).toFixed(2));
};

export const calculateVatAmountFromGross = (grossAmount: number | undefined, vatPercentage: number | undefined) => {
  if (!vatPercentage || !grossAmount) return 0;
  const grossAmountBig = new Big(grossAmount);
  const vatPercentageBig = new Big(vatPercentage);

  return parseFloat(grossAmountBig.times(vatPercentageBig).div(new Big(100).plus(vatPercentageBig)).toFixed(2));
};

export const calculateVatPercentageFromGrossAndVatAmount = (grossAmount: number | undefined, vatAmount: number | undefined) => {
  if (!vatAmount || !grossAmount) return 0;
  const grossAmountBig = new Big(grossAmount);
  const vatAmountBig = new Big(vatAmount);
  const netAmountBig = grossAmountBig.minus(vatAmountBig);

  return parseFloat(vatAmountBig.times(new Big(100)).div(netAmountBig).toFixed(2));
};

export const getDefaultVatPercentageForContract = (vatRate: VatRateDto, contractVatRelevance?: UnitContractProjectionDtoVatRelevanceEnum | UnitOwnerAccountBalanceDtoContractVatRelevanceEnum) => {
  if (!contractVatRelevance || !vatRate) {
    return 0;
  }
  switch (contractVatRelevance) {
    case UnitContractProjectionDtoVatRelevanceEnum.VAT_RELEVANT:
      return vatRate.vatPercentage;
    case UnitContractProjectionDtoVatRelevanceEnum.REDUCED_VAT_RELEVANT:
      return vatRate.reducedVatPercentage;
    default:
      return 0;
  }
};

export const mapVatPercentageToSelectOption = (value: number) => ({
  value,
  label: `${value}%`,
});

export const mapCountryVatRateToSelectOptions = (vatRate: VatRateDto) => {
  if (isNil(vatRate)) {
    return [];
  }

  return [vatRate.vatPercentage, vatRate.reducedVatPercentage, 0]
    .map(v => mapVatPercentageToSelectOption(v));
};
