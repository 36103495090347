import React, { useContext } from 'react';
import _ from 'lodash';
import { InformationBar } from 'storybook-components/feedback/InformationBar/InformationBar';
import { translations } from '../../translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { useDistributionAmountValues } from '../../services/useDistributionAmountValues';
import { DirectionAndProcessing, DirectionAndProcessingInputValuesContext } from '../../services/ManualExchangeContext';

const InformationBarWrapper = () => {
  const distributionAmountValues = useDistributionAmountValues().distributionValues;
  const directionAndProcessingInputValues = useContext(DirectionAndProcessingInputValuesContext);
  const { tl } = useContext(LanguageContext);

  const mergedDistributionValues = distributionAmountValues?.map(item => Object.assign({}, item, directionAndProcessingInputValues?.find((d: DirectionAndProcessing) => d.accountCode === item.accountCode)));
  const [countUnitsSelected, countPlatform, countSelfPayout, totalAmountPayout, totalAmountPull] = mergedDistributionValues?.filter(d => !_.isNil(d) && d.selected)
    .reduce(([countUnitsSelectedAcc, countPlatformAcc, countSelfPayoutAcc, totalAmountPayoutAcc, totalAmountPullAcc], value) => [
      countUnitsSelectedAcc + (value.selected ? 1 : 0),
      countPlatformAcc + (value.processing === 'PLATFORM' ? 1 : 0),
      countSelfPayoutAcc + (value.processing === 'SELF_PAYER' ? 1 : 0),
      totalAmountPayoutAcc + (!_.isNil(value.amount) && value.direction === 'outgoing' ? value.amount : 0),
      totalAmountPullAcc + (!_.isNil(value.amount) && value.direction === 'incoming' ? value.amount : 0),
    ], [0, 0, 0, 0, 0]) ?? [0, 0, 0, 0, 0];

  return (
    <InformationBar
      content={[{ label: tl(translations.creationPage.sections.section2.infoBar.unitsSelected), value: countUnitsSelected },
        { label: tl(translations.creationPage.sections.section2.infoBar.totalAmountPayout), value: `${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalAmountPayout)}` },
        { label: tl(translations.creationPage.sections.section2.infoBar.totalAmountPull), value: `${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalAmountPull)}` },
        { label: tl(translations.creationPage.sections.section2.infoBar.bankOrders), value: countPlatform ?? 0 },
        { label: tl(translations.creationPage.sections.section2.selectOptionsLabels.selfPayer), value: countSelfPayout ?? 0 }]}
      delimiter="‧"
    />
  );
};

export default InformationBarWrapper;
