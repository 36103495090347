import { round2dec } from 'lib/Utils';

export const calculateNetAmount = (grossAmount: number | undefined, vatPercentage: number | undefined) => {
  grossAmount = Math.abs(grossAmount ?? 0);
  vatPercentage = Math.abs(vatPercentage ?? 0);
  return round2dec(grossAmount * 100 / (100 + vatPercentage));
};

export const calculateLaborCostPercentage = (laborCostGross: number | undefined, amountGross: number | undefined) => {
  if (amountGross === 0 || laborCostGross === 0) {
    return 0;
  }
  return round2dec(((Math.abs(laborCostGross) || 0) * 100 / Math.abs(amountGross)));
};
