import { GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum } from 'api/accounting';
import _ from 'lodash';
import { useMemo } from 'react';
import { useBankTransactionListContext } from './BankTransactionListContext';

export const useTransactionListFilterAndSort = () => {
  const {
    sortState,
    setSortState,
    filterState,
    setFilterState,
  } = useBankTransactionListContext('useTransactionListFilterAndSort');

  const setSortField = (field: string) => {
    setSortState((currentState) => {
      const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
      return {
        field,
        order,
      };
    });
  };

  const onClearFilterState = () => {
    setFilterState({});
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    if (searchParams.transactionIds !== undefined && typeof searchParams.transactionIds === 'string') {
      const transactionIds = searchParams.transactionIds.split(',').map(id => parseInt(id, 10));
      setFilterState({ ...searchParams, transactionIds });
    } else {
      setFilterState({ ...searchParams });
    }
  };

  const defaultFilters = useMemo(() => ({
    transactionPaymentStatuses: [GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.UNASSIGNED, GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.PARTIALLY_BOOKED],
  }), []);

  const filterContainsOpenStates = _.isEmpty(filterState.transactionPaymentStatuses)
  || filterState.transactionPaymentStatuses?.includes(GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.UNASSIGNED)
  || filterState.transactionPaymentStatuses?.includes(GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.PARTIALLY_BOOKED);

  return {
    sortField: sortState.field,
    sortOrder: sortState.order,
    setSortField,
    filterState,
    defaultFilters,
    filterContainsOpenStates,
    setFilterState,
    onClearFilterState,
    onSetDefaultFilterFromQueryParams,
  };
};
