import { anyMatch, createFieldRegExp } from '../../lib/Utils';

const personSection1Fields: string[] = [
  'title',
  'salutation',
  'firstName',
  'lastName',
  'dateOfBirth',
  'role',
  'email',
  'phoneBusiness',
  'phoneMobile',
  'phonePrivate',
  'fax',
  'dispatchPreferences',
];

const personSection2Fields: string[] = [
  'linkedContactId',
  'role',
];
const getPersonSection2Fields = (): string[] => personSection2Fields.map((field: string) => `linkedToContacts\\[.+\\]\\.${field}`);


const companySection1Fields: string[] = [
  'companyName',
  'tradeRegisterNumber',
  'vatId',
  'website',
];

const companySection3Fields: string[] = [
  'contactId',
  'role',
];
const getCompanySection3Fields = (): string[] => companySection3Fields.map((field: string) => `linkedByContacts\\[.+\\]\\.${field}`);


const addressFields: string[] = [
  'street',
  'number',
  'additionalAddress1',
  'additionalAddress2',
  'postalCode',
  'city',
  'country',
  'street',
  'number',
  'additionalAddress1',
  'additionalAddress2',
  'postalCode',
  'city',
  'country',
  'recipient',
];
const getAddressFields = (): string[] => addressFields.map((field: string) => `addresses\\[.+\\]\\.${field}`);

const bankAccountFields: string[] = [
  'accountHolderName',
  'accountInstitute',
  'iban',
  'bic',
];
const getBankAccountFields = (): string[] => bankAccountFields.map((field: string) => `bankAccounts\\[.+\\]\\.${field}`);

const getPersonSectionFieldsMap = (sectionNumber: number): RegExp[] => {
  let fields: string[] = [];
  switch (sectionNumber) {
  case 1:
    fields = [
      ...personSection1Fields,
      ...getAddressFields(),
      ...getBankAccountFields(),
    ];
    break;
  case 2:
    fields = getPersonSection2Fields();
    break;
  default:
    fields = [];
  }

  return fields.map((field: string) => createFieldRegExp(field));
};

const getCompanySectionFieldsMap = (sectionNumber: number): RegExp[] => {
  let fields: string[] = [];
  switch (sectionNumber) {
  case 1:
    fields = [
      ...companySection1Fields,
      ...getAddressFields(),
    ];
    break;
  case 2:
    fields = getBankAccountFields();
    break;
  case 3:
    fields = getCompanySection3Fields();
    break;
  default:
    fields = [];
  }

  return fields.map((field: string) => createFieldRegExp(field));
};


export const getSectionErrors = (sectionNumber: number, errors: any, fields: RegExp[]) => {
  const sectionErrors: any = {};

  Object.keys(errors)
    .filter((key: string) => anyMatch(fields, key))
    .forEach((key: string) => {
      sectionErrors[key] = errors[key];
    });

  return sectionErrors;
};

export const getPersonSectionErrors = (sectionNumber: number, errors: any) => {
  const fields = getPersonSectionFieldsMap(sectionNumber);
  return getSectionErrors(sectionNumber, errors, fields);
};

export const getCompanySectionErrors = (sectionNumber: number, errors: any) => {
  const fields = getCompanySectionFieldsMap(sectionNumber);
  return getSectionErrors(sectionNumber, errors, fields);
};
