import { ContactProjection } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';


type Props = {
  children: React.ReactNode;
};


export const ContactsListContext = createContext<{
  contactsList: DefaultDataInterface<ContactProjection[]>,
  setContactsList: React.Dispatch<React.SetStateAction<DefaultDataInterface<ContactProjection[]>>>,
  isCacheValid: boolean,
  setIsCacheValid: React.Dispatch<React.SetStateAction<boolean>>,
} | undefined>(undefined);


const ContactsListContextProvider = ({ children }: Props) => {
  const [contactsList, setContactsList] = useState(DEFAULT_DATA<ContactProjection[]>([]));
  const [isCacheValid, setIsCacheValid] = useState(false);


  // putting them in objects so they are easier to destructure when using the contexts
  // no need to memoize since they're all in the same object
  const providerValue = {
    contactsList,
    setContactsList,
    isCacheValid,
    setIsCacheValid,
  };

  return (
    <ContactsListContext.Provider value={providerValue}>
      {children}
    </ContactsListContext.Provider>
  );
};

export default ContactsListContextProvider;
