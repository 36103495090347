import { Form } from 'antd';
import React from 'react';
import { ValidateStatuses } from '../../../lib/Utils';
import './ValidationError.scss';

interface validationErrorProps {
  validationState?: (typeof ValidateStatuses)[number],
  validationMessage?: string,
  disabled?: boolean
}

export default function ValidationError(props: validationErrorProps): JSX.Element | null {
  const { validationState, validationMessage, disabled } = props;

  if (validationState) {
    return (
      <div className="ValidationError">
        <Form.Item
          validateStatus={disabled ? '' : (validationState || 'success')}
          help={disabled ? '' : (
            <span className="validation-message">
              {validationMessage}
            </span>
          )}
        />
      </div>
    );
  }
  return null;
}
