import { DocumentProjectionDto, FindDocumentsFilteredUsingGETAdministrationTypesEnum } from 'api/document';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useMemo, useState } from 'react';
import moment from 'moment';
import { Order } from 'services/useSort';

interface Props {
  children: React.ReactNode;
}

export interface ProfitAndLossFilter {
  name?: string,
  propertyIdInternal?: string,
  propertyName?: string,
  minCreated?: moment.Moment,
  maxCreated?: moment.Moment,
  createdBy?: string,
  administrationTypes?: FindDocumentsFilteredUsingGETAdministrationTypesEnum,
}

const DEFAULT_SORT: { field: keyof DocumentProjectionDto, order: Order } = {
  field: 'created',
  order: -1,
};

const DEFAULT_FILTER: ProfitAndLossFilter = {
  administrationTypes: [FindDocumentsFilteredUsingGETAdministrationTypesEnum.MV, FindDocumentsFilteredUsingGETAdministrationTypesEnum.SEV_MV] as unknown as FindDocumentsFilteredUsingGETAdministrationTypesEnum,
};

export const ProfitAndLossReportListContext = createContext<{
  profitAndLossReportList: DefaultDataInterface<DocumentProjectionDto[]>,
  setProfitAndLossReportList: React.Dispatch<React.SetStateAction<DefaultDataInterface<DocumentProjectionDto[]>>>,
  filterState: ProfitAndLossFilter,
  setFilterState: React.Dispatch<React.SetStateAction<ProfitAndLossFilter>>,
  sortState: { field: keyof DocumentProjectionDto, order: Order },
  setSortState: React.Dispatch<React.SetStateAction<{ field: keyof DocumentProjectionDto, order: Order }>>,
  defaultFilter: ProfitAndLossFilter,
} | undefined>(undefined);


const ProfitAndLossReportListContextProvider = ({ children }: Props) => {
  const [profitAndLossReportList, setProfitAndLossReportList] = useState(DEFAULT_DATA<DocumentProjectionDto[]>([]));
  const [filterState, setFilterState] = useState<ProfitAndLossFilter>(DEFAULT_FILTER);
  const [sortState, setSortState] = useState(DEFAULT_SORT);

  const profitAndLossReportListContextProviderValue = useMemo(() => ({
    profitAndLossReportList,
    setProfitAndLossReportList,
    filterState,
    setFilterState,
    sortState,
    setSortState,
    defaultFilter: DEFAULT_FILTER,
  }), [profitAndLossReportList, setProfitAndLossReportList, filterState, setFilterState, sortState, setSortState, DEFAULT_FILTER]);

  return (
    <ProfitAndLossReportListContext.Provider value={profitAndLossReportListContextProviderValue}>
      {children}
    </ProfitAndLossReportListContext.Provider>
  );
};

export default ProfitAndLossReportListContextProvider;
