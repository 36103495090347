import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { translations } from '../../translations';
import { TransactionImportDtoStatusEnum } from '../../../../api/accounting';
import { ICONS } from 'components/icons';
import Amount from 'storybook-components/typography/Amount/Amount';

function getDotColor(status: TransactionImportDtoStatusEnum) {
  switch (status) {
    case TransactionImportDtoStatusEnum.PROCESSED:
      return DotColorEnum.GREEN;
    case TransactionImportDtoStatusEnum.FAILED:
      return DotColorEnum.RED;
    // @ts-ignore
    case 'WARNING':
      return DotColorEnum.YELLOW;
    case TransactionImportDtoStatusEnum.IN_PROGRESS:
      return DotColorEnum.GRAY;
    default:
      return DotColorEnum.GRAY;
  }
}


export const useTransactionImportColumns = (download: (url: string) => void) => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.columns.status),
      dataKey: 'status',
      sortable: false,
      render: (status: any, row: any) => (
        <ColorDot color={getDotColor(status)}>
          {row.statusTranslated}
        </ColorDot>
      ),
    },
    {
      title: tl(translations.columns.file),
      dataKey: 'files',
      sortable: false,
      defaultWidth: 80,
      className: 'center',
      // eslint-disable-next-line no-nested-ternary
      render: (text: string, row: any) => (row.totalNrOfFiles ? (
        row.status === 'IN_PROGRESS'
          ? <Amount>{`${row.children ? row.children.length : 0} / ${row.totalNrOfFiles}`}</Amount>
          : <Amount>{row.totalNrOfFiles}</Amount>
      )
        : (
          <Icon
            onClick={() => {
              download(row.fileUrl);
            }}
            className="file-icon"
            component={ICONS.file}
          />
        )),
    },
    {
      title: tl(translations.columns.numberOfTransactions),
      dataKey: 'numberOfTransactions',
      render: (amount: number) => (
        <Amount>{amount}</Amount>
      ),
    },
    {
      title: tl(translations.columns.created),
      dataKey: 'created',
      format: FORMAT.DATE,
    },
    {
      title: tl(translations.columns.totalAmount),
      dataKey: 'totalAmount',
      format: FORMAT.CURRENCY,
    },
  ];
};
