import {
  AgendaItemDto, AgendaItemsControllerApi, AgendaItemUpdateDtoAgendaItemTypeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import {
  useContext, useEffect,
} from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _, { isEmpty } from 'lodash';
import { translations } from '../../../../../OwnersMeetingProtocol/translations';
import { AgendaItemsContext } from '../../../../../../contexts/AgendaItemsContext';
import { OwnersMeetingInvitationContext } from '../../../../../../contexts/OwnersMeetingInvitationContext';
import { useCurrentOverlayInfo } from '../../../../../../components/OverlayRoute/services/useCurrentOverlayInfo';
import { useLoadAgendaItems } from './useLoadAgendaItems';

export const useAgendaItemsList = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const { ownersMeeting, setDirty } = useContext(OwnersMeetingInvitationContext);
  const agendaItemsContext = useContext(AgendaItemsContext);
  if (agendaItemsContext === undefined) {
    throw new Error('useAgendaItemsList must be used within a AgendaItemsContextProvider');
  }
  const {
    agendaItemsList, setAgendaItemsList,
  } = agendaItemsContext;
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));
  const { onLoadAgendaItemsList } = useLoadAgendaItems();
  // wrapper for BasicDragNDrop table call
  const setDragNDropValuesDispatcher = (setStateFunction: (param: AgendaItemDto[]) => AgendaItemDto[]) => {
    setAgendaItemsList(prev => prev.load(setStateFunction(prev.data)));
  };

  const onChangeOrderOfAgendaItems = () => {
    const modifiedAgendaItems = [];
    const newAgendaItems = agendaItemsList.data.map((agendaItem, index) => {
      if (agendaItem.index !== index) {
        const newAgendaItem = {
          ...agendaItem,
          index,
          persistedTopicNumber: agendaItem.topicNumber,
          topicNumber: index + 1,
        };
        modifiedAgendaItems.push(newAgendaItem);
        return newAgendaItem;
      }

      return agendaItem;
    });


    if (!_.isEmpty(modifiedAgendaItems)) {
      setAgendaItemsList(prev => prev.loadPaged(newAgendaItems, true, agendaItemsList.lastPage));
      onSaveAgendaItems(modifiedAgendaItems);
    }
  };

  const { isOverlayOnTop } = useCurrentOverlayInfo();

  useEffect(() => {
    if (!isEmpty(agendaItemsList?.data)) {
      onChangeOrderOfAgendaItems();
    }
    // this useEffect has agendaItemsList?.data as a dependency because the DragNDrop reordering directly sets the state and we need to save the order changes
  }, [agendaItemsList?.data]);


  useEffect(() => {
    if (!ownersMeeting.data.id) {
      setAgendaItemsList(prev => prev.load([]));
    }
    if (isOverlayOnTop && ownersMeeting.data.id) {
      onLoadAgendaItemsList(true);
    }
  }, [ownersMeeting.data.id, isOverlayOnTop]);

  const onSaveAgendaItems = (modifiedAgendaItems: AgendaItemDto[], callback?: () => void) => {
    const promises: any = [];
    modifiedAgendaItems.forEach((agendaItem) => {
      if (agendaItem?.resolutionRecordText?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
        agendaItem.resolutionRecordText = agendaItem.resolutionRecordText.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
      }

      if (agendaItem?.comment?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
        agendaItem.comment = agendaItem.comment.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
      }

      promises.push(agendaItemsControllerApi.updateAgendaItemUsingPUT({
        agendaItemId: agendaItem.id,
        agendaItemUpdateDto: {
          ...agendaItem, index: agendaItem.index, topicNumber: agendaItem.topicNumber, agendaItemType: agendaItem.agendaItemType as unknown as AgendaItemUpdateDtoAgendaItemTypeEnum,
        },
      }));
    });
    if (_.isEmpty(promises)) {
      callback?.();
    } else {
      Promise.all(promises).then(() => {
        showNotification({
          key: 'saveOwnersMeetingSuccess',
          message: tl(translations.editPage.sections.agendaSection.saveSuccess.message),
          type: 'success',
        });
        callback?.();
      }).catch(() => {
        showNotification({
          key: 'saveOwnersMeetingWithValidationErrors',
          message: tl(translations.editPage.sections.agendaSection.saveError.message),
          type: 'warning',
        });
      }).finally(() => {
        setDirty(false);
      });
    }
  };

  return {
    agendaItemsList,
    setAgendaItemsList,
    setDragNDropValuesDispatcher,
    setDirty,
  };
};
