import './ColorDot.scss';

import React from 'react';

export enum DotColorEnum {
   YELLOW = 'YELLOW',
   GREEN = 'GREEN',
   RED = 'RED',
   BLUE = 'BLUE',
   PURPLE = 'PURPLE',
   BLACK = 'BLACK',
   CYAN = 'CYAN',
   CREAM = 'CREAM',
   GRAY = 'GRAY',
   DUST = 'DUST',
}

interface ColorDotProps {
  color: DotColorEnum,
  children?: React.ReactNode,
}


export const ColorDot = ({ color, children }: ColorDotProps) => (
  <div className="ColorDot">
    <span className={`dot ${color?.toLowerCase?.()}`} />
    {children}
  </div>
);


export default ColorDot;


ColorDot.defaultProps = {
  children: undefined,
};
