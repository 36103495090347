import React, { useContext, useState } from 'react';
import './DateInput.scss';
import Icon from '@ant-design/icons';
import { Form } from 'antd';
import moment from 'moment';
import { DatePickerView, KeyboardDatePicker } from '@material-ui/pickers';
import { isNil } from 'lodash';
import InputProps from '../InputProps';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from './translations';
import { ICONS } from '../../../components/icons';
import { useValidateDate } from './useValidateDate';
import { getLabelForInput } from '../utilGetLabelForInput';

export const MIN_DATE_NORMAL = moment('1990-01-01');
export const MAX_DATE_NORMAL = moment().add(5, 'years');

interface dateProps extends InputProps<moment.Moment | undefined> {
  format?: string,
  views?: DatePickerView[],
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void,
  onAccept?: (v: moment.Moment | null) => void,
  hardMinDate?: string | moment.Moment,
  hardMaxDate?: string | moment.Moment,
  softMinDate?: string | moment.Moment,
  softMaxDate?: string | moment.Moment,
}


export default function DateInput(props: dateProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    value, className, label, required, inputClassName, disabled, validationState, validationMessage,
    showPlaceholderWhenDisabled, format, autoFocus, onAccept, views,
    hardMinDate, hardMaxDate, softMinDate, softMaxDate, infoText,
  } = props;

  const [pickerOpen, setPickerOpen] = useState(false);
  const [validValue, setValidValue] = useState(true);

  const { onChange: onChangeProp, placeholder: placeholderProp, onKeyDown: onKeyDownProp } = props;
  const { validateDateWithingNormalDateRange } = useValidateDate(softMinDate, softMaxDate);

  const onChange = (date: moment.Moment | null, val?: string | null) => {
    if (date) {
      if (date.isValid()) {
        setValidValue(true);

        validateDateWithingNormalDateRange(date);

        if (!isNil(hardMinDate) && date.isBefore(hardMinDate)) {
          onChangeProp(moment.utc(hardMinDate, format));
          return;
        }

        if (!isNil(hardMaxDate) && date.isAfter(hardMaxDate)) {
          onChangeProp(moment.utc(hardMaxDate, format));
          return;
        }

        onChangeProp(moment.utc(val!, format));
      } else {
        setValidValue(false);
      }
    } else {
      onChangeProp(undefined);
    }
  };

  const onBlur = () => {
    // call onChange with undefined only if there is no valid value when input looses focus
    if (!validValue) {
      onChangeProp(undefined);
    }
  };

  let placeholder = placeholderProp || tl(translations.placeholder);
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (onKeyDownProp) {
      onKeyDownProp(e);
      return;
    }

    if (e.key === 'Enter') {
      setPickerOpen(true);
      e.stopPropagation();
    }
  };


  return (
    <div className={`DateInput ${className}`}>
      <Form.Item
        label={getLabelForInput(label, infoText, required)}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <KeyboardDatePicker
          className={`${inputClassName} ${disabled ? 'read-only' : ''} ${pickerOpen ? 'picker-open' : ''}`}
          onChange={onChange}
          format={format}
          value={value || null}
          disabled={disabled}
          variant="inline"
          placeholder={placeholder}
          invalidDateMessage={null}
          minDate={hardMinDate}
          maxDate={hardMaxDate}
          minDateMessage=""
          maxDateMessage=""
          autoFocus={autoFocus}
          disableToolbar
          onKeyDown={onKeyDown}
          open={pickerOpen}
          views={views}
          onClose={() => {
            setPickerOpen(false);
          }}
          onOpen={() => {
            setPickerOpen(true);
          }}
          onAccept={(v: moment.Moment | null) => {
            setPickerOpen(false);
            if (onAccept) {
              onAccept(v);
            }
          }}
          KeyboardButtonProps={
            { tabIndex: -1 }
          }
          rightArrowIcon={<Icon component={ICONS.calendarArrowRight} />}
          leftArrowIcon={<Icon component={ICONS.calendarArrowLeft} />}
          onBlur={onBlur}
        />
      </Form.Item>
    </div>
  );
}

DateInput.defaultProps = {
  format: 'DD.MM.YYYY',
  onAccept: undefined,
  onKeyDown: undefined,
  views: undefined,
  hardMinDate: undefined,
  hardMaxDate: undefined,
  softMinDate: undefined,
  softMaxDate: undefined,
};
