import { InvoiceEpoPaymentTypeEnum, InvoiceEpoStateEnum } from 'api/accounting';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { useContext, useEffect, useState } from 'react';

export const useInvoiceTotalGrossAmount = () => {
  const { data, updateInvoiceState, changeInvoiceBookings } = useContext(InvoiceEditingContext);

  const [outgoingDirection, setOutgoingDirection] = useState(data.invoice.totalGross ? data.invoice.totalGross < 0 : true);

  useEffect(() => {
    if (outgoingDirection !== undefined && data.invoice.totalGross) {
      onChangeTotal('totalGross', data.invoice.totalGross);
    }
  }, [outgoingDirection]);

  useEffect(() => {
    setOutgoingDirection(data.invoice.totalGross ? data.invoice.totalGross < 0 : true);
  }, [data.invoice.totalGross]);

  const onChangeInput = (key: string, value: number | InvoiceEpoPaymentTypeEnum) => {
    updateInvoiceState({
      invoice: {
        ...data.invoice,
        [key]: value,
      },
    });
  };

  const onChangeOutgoingValue = (value: boolean) => {
    const paymentIsOrderType = ![InvoiceEpoPaymentTypeEnum.EXISTING, InvoiceEpoPaymentTypeEnum.RECURRING_NO_ORDER].includes(data.invoice.paymentType);
    if (!value && paymentIsOrderType) {
      onChangeInput('paymentType', InvoiceEpoPaymentTypeEnum.EXISTING);
    }
    setOutgoingDirection(value);
    changeInvoiceBookings({ type: 'updateSign', idx: 0 });
  };

  const onChangeTotal = (name: string, newValue: number | undefined) => {
    if (newValue === undefined || isNaN(newValue)) {
      onChangeInput(name, undefined);
    } else {
      const newTotal = outgoingDirection ? -Math.abs(newValue) : Math.abs(newValue);
      onChangeInput(name, newTotal);
    }
  };

  const disabled = data.invoice.state !== InvoiceEpoStateEnum.NEW && data.invoice.state !== InvoiceEpoStateEnum.READY_FOR_APPROVAL;


  return {
    data,
    outgoingDirection,
    onChangeOutgoingValue,
    onChangeTotal,
    disabled,
  };
};
