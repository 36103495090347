import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import './StandingOrderOverview.scss';
import { StandingOrderContext } from 'contexts/StandingOrderContext';
import { translations } from 'elements/Translation/translations';
import { formatDate } from 'lib/Utils';
import SmartTable from 'elements/SmartTable/SmartTable';
import { Action } from 'elements/SmartTable/data';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { LanguageContext } from 'contexts/LanguageContext';
import { useParams } from 'react-router';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import _ from 'lodash';
import { StandingOrderStopModal } from './components/StandingOrderStopModal';
import { useStandingOrderOverviewColumns } from './UseStandingOrderOverviewColumns';
import useStandingOrderFilters from './UseStandingOrderFilters';
import { StandingOrderDto } from '../../../api/accounting/models';


export default function StandingOrderOverview(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { propertyHrId } = useParams<{propertyHrId:string}>();
  const {
    standingOrders, onLoadStandingOrderList, onStopStandingOrder, onClearStandingOrderList, onClearFilterState, setFilterState, onSetDefaultFilterFromQueryParams, sortField, sortOrder, setSortField, updateFilterState, filterState, onStopMultipleStandingOrders,
  }: any = useContext(StandingOrderContext);

  const [stopModalVisible, setStopModalVisible] = useState(false);
  const stopStandingOrderIdRef = useRef(-1);
  const [standingOrderIdsToStop, setStandingOrderIdsToStop] = useState<(number | undefined)[]>([]);

  useGlobalSearch({
    key: 'standingOrders',
    filterProps: {
      availableFilters: useStandingOrderFilters(),
      setFilter: (key: string, value: any) => setFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);
          newFilter[key] = value;
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams,
    },
  });

  // clear list on unmount
  useEffect(() => (() => { onClearFilterState(); onClearStandingOrderList(); }), []);

  useEffect(() => {
    if (propertyHrId) {
      updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  useEffect(() => {
    if (standingOrderTable.onUnselectAll) standingOrderTable.onUnselectAll();
  }, [filterState]);
  const toTableData = (standingOrder: any): any => ({
    ...standingOrder,
    interval: standingOrder.interval ? tl(translations.pages.standingOrderOverview.table.fields.intervalOptions[standingOrder.interval.toString().toLowerCase()]) : '',
    created: `\n${formatDate(standingOrder.created)}`,
  });

  const actionsMenu: Action[] = [
    {
      label: tl(translations.pages.standingOrderOverview.table.fields.menuItems.stop),
      onAction: (record: StandingOrderDto) => {
        setStopModalVisible(true);
        stopStandingOrderIdRef.current = record.id as number;
      },
      onBatchAction: (records: StandingOrderDto[]) => {
        setStopModalVisible(true);
        setStandingOrderIdsToStop(records.map(record => record.id));
      },
    }];

  const tableData = useMemo(() => standingOrders.data.map(toTableData), [standingOrders.data]);
  const standingOrderTable = useSmartTable({
    tableName: 'standingOrderOverviewTable',
    columns: useStandingOrderOverviewColumns(),
    dataSource: tableData,
    contentLoading: standingOrders.loading,
    infiniteScrollerProps: {
      hasMoreData: !standingOrders.lastPage,
      loadMoreData: onLoadStandingOrderList,
    },
    actionsMenu,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    supportBatchActions: true,
    rowKey: 'id',
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'standingOrders',
    navItems: subcategorySwitcherItems.accounting,
  };

  return (
    <div className="StandingOrderOverview page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      <SmartTable {...standingOrderTable} />
      <StandingOrderStopModal
        visible={stopModalVisible}
        onClickCancel={() => setStopModalVisible(false)}
        onClickDelete={() => {
          setStopModalVisible(false);
          if (stopStandingOrderIdRef.current !== -1) {
            onStopStandingOrder(stopStandingOrderIdRef.current);
          } else {
            onStopMultipleStandingOrders(standingOrderIdsToStop);
          }
        }}
      />
    </div>
  );
}
