import { ConfigProvider, Table } from 'antd';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import { getRowClassName } from 'pages/OpsCostReport/services/utils';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { opsCostTranslation } from '../../../translations';
import YourPrepaymentsTableSummary from './components/YourPrepaymentTableSummary';
import { useYourPrepaymentsSection as hook } from './useYourPrepaymentsSection';
import AddRowButtonAndDescription from '../AddRowButtonAndDescription/AddRowButtonAndDescription';
import { InvalidManualAmountsAlert } from '../OpsCostReportEditorForm/ExpensesSection/components/InvalidManualAmountsAlert';


type ReturnTypeOfHook = ReturnType<typeof hook>;
export type GetYourPrepaymentsSectionProps = ReturnTypeOfHook['getYourPrepaymentsSectionProps'];
export type ReturnTypeOfYourPrepaymentsHook = ReturnType<GetYourPrepaymentsSectionProps>;


interface YourPrepaymentsSectionProps {
  tl: any,
  openSectionId: number,
  useYourPrepaymentsSection: ReturnTypeOfYourPrepaymentsHook,
}


const YourPrepaymentsSection = ({
  tl,
  useYourPrepaymentsSection,
  openSectionId,
}: YourPrepaymentsSectionProps) => {
  const {
    columns, prepaymentsDataSource, getPrepaymentsTotalIncludingManualAmounts, loading, isContractVatRelevant, contractId,
  } = useYourPrepaymentsSection;

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(prepaymentsDataSource) ? { y: 200 } : undefined;

  return (
    <PrimarySectionWrapper
      sectionId="YourPrepaymentsSection"
      className="YourPrepaymentsSection"
      sectionNumber={3}
      sectionTitle={tl(opsCostTranslation.report.sections.yourPrepaymentsSection.titel)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
      sectionOpenSuffix={<AddRowButtonAndDescription section="yourPrepayments" contractId={contractId} disabled={loading} />}
    >
      <InvalidManualAmountsAlert
        tl={tl}
        contractId={contractId}
        section="yourPrepayments"
      />
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.yourPayments)} />}>
        <Table
          className="table-with-input-rows"
          rowClassName={record => getRowClassName(record.isHiddenRow, record.isManualRow)}
          columns={columns}
          dataSource={prepaymentsDataSource}
          rowKey="key"
          pagination={false}
          scroll={tableHeight}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <YourPrepaymentsTableSummary
              title={tl(opsCostTranslation.report.sections.yourPrepaymentsSection.columns.total)}
              getPrepaymentsTotalIncludingManualAmounts={getPrepaymentsTotalIncludingManualAmounts}
              nrTotalColumns={columns.length}
              isContractVatRelevant={isContractVatRelevant}
              contractId={contractId}
            />
          )}
        />
      </ConfigProvider>
    </PrimarySectionWrapper>
  );
};

export default YourPrepaymentsSection;
