import { RouteDefinition } from 'components/RenderRoutes';
import { lazy } from 'react';

import {
  getWkaEditingRoute, WKA_CREATE_PATH, WKA_EDITOR_COMPONENT, WKA_EDIT_PATH,
} from 'pages/Wka/routes';
import MessageSendingPageWithProvider from 'pages/MessageSendingPage/MessageSendingPage';
import DocumentPreviewPage from 'pages/DocumentPreview/DocumentPreviewPage';
import { DOCUMENT_PREVIEW_PATH } from 'pages/DocumentPreview/routes';
import { PDF_DOCUMENT_ASSIGNER_COMPONENT, PDF_DOCUMENT_ASSIGNER_PATH } from 'pages/PdfPageAssigner/routes';
import OpsCostReportListWithProvider from './OpsCostReportList/OpsCostReportList';
import OpsCostReportPreviewWithProvider from './OpsCostReportPreview/OpsCostReportPreview';

const OpsCostReportViewWithProvider = lazy(() => import('./OpsCostReportView/OpsCostReportView'));
const OpsCostReportEditor = lazy(() => import('./OpsCostReportEditing/OpsCostReportEditor'));
const OpsCostReportExecution = lazy(() => import('./OpsCostReportExecution/OpsCostReportExecution'));

export const OPS_COST_REPORTS_PATH = '/ops-cost-reports';

export const OPS_COST_REPORTS_VIEW_PATH = '/ops-cost-reports/:opsCostReportId/view';
export const OPS_COST_REPORTS_EDIT_PATH = '/ops-cost-reports/:opsCostReportId/edit';
export const OPS_COST_REPORTS_CREATE_PATH = '/ops-cost-reports/create';
export const OPS_COST_REPORTS_EXECUTION_PATH = '/ops-cost-reports/:opsCostReportId/view/execute';
export const OPS_COST_REPORTS_SENDING_PATH = '/ops-cost-reports/:opsCostReportId/view/message-sending';
export const OPS_COST_REPORT_PREVIEW = '/preview';
export const getOpsCostReportEditPath = (opsCostReportId: string | number) => `/ops-cost-reports/${opsCostReportId}/edit`;
export const getOpsCostReportViewPath = (opsCostReportId: string | number) => `/ops-cost-reports/${opsCostReportId}/view`;
export const getOpsCostReportExecutionPath = (opsCostReportId: string | number) => `/ops-cost-reports/${opsCostReportId}/view/execute`;

export const getWkaEditingRouteForOpsCostReport = (locationPathname: string, propertyHrId: string, wkaId: number) => `${locationPathname}/${propertyHrId}${getWkaEditingRoute(wkaId)}`;

export const getWkaCreateRouteForOpsCostReport = (locationPathname: string, propertyHrId: string) => `${locationPathname}/${propertyHrId}${WKA_CREATE_PATH}`;

export const OpsCostReportRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: OPS_COST_REPORTS_PATH,
      component: OpsCostReportListWithProvider,
      subRoutes: [
        {
          path: OPS_COST_REPORTS_VIEW_PATH,
          component: OpsCostReportViewWithProvider,
          type: 'overlay',
          addBreadcrumb: false,
          subRoutes: [
            {
              path: OPS_COST_REPORTS_EXECUTION_PATH,
              component: OpsCostReportExecution,
              type: 'overlay',
            },
            {
              path: OPS_COST_REPORTS_SENDING_PATH,
              component: MessageSendingPageWithProvider,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${OPS_COST_REPORTS_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                  component: DocumentPreviewPage,
                  type: 'overlay',
                },
              ],
            },
          ],
        },
        {
          path: [OPS_COST_REPORTS_CREATE_PATH, OPS_COST_REPORTS_EDIT_PATH],
          component: OpsCostReportEditor,
          type: 'focus',
        },
        {
          path: [`${OPS_COST_REPORTS_CREATE_PATH}${OPS_COST_REPORT_PREVIEW}`, `${OPS_COST_REPORTS_EDIT_PATH}${OPS_COST_REPORT_PREVIEW}`],
          component: OpsCostReportPreviewWithProvider,
          type: 'overlay',
        },
        {
          path: [
            `${OPS_COST_REPORTS_CREATE_PATH}/:propertyHrId${WKA_CREATE_PATH}`,
            `${OPS_COST_REPORTS_CREATE_PATH}/:propertyHrId${WKA_EDIT_PATH}`,
            `${OPS_COST_REPORTS_EDIT_PATH}/:propertyHrId${WKA_CREATE_PATH}`,
            `${OPS_COST_REPORTS_EDIT_PATH}/:propertyHrId${WKA_EDIT_PATH}`,
          ],
          component: WKA_EDITOR_COMPONENT,
          type: 'overlay',
          subRoutes: [
            {
              path: `${OPS_COST_REPORTS_CREATE_PATH}/:propertyHrId${WKA_EDIT_PATH}/${PDF_DOCUMENT_ASSIGNER_PATH}`,
              component: PDF_DOCUMENT_ASSIGNER_COMPONENT,
              type: 'overlay',
            },
          ],
        },
      ],
    },
  ],
};
