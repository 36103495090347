import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import DEFAULT_DATA from '../lib/data';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { showNotification } from '../lib/Notification';
import {
  FindFilteredPropertiesUsingGETAdministrationTypesEnum,
  FindFilteredPropertiesUsingGETOrderEnum, PropertyLegacyControllerApi, PropertyDisplayDto, PropertyLegacyDtoPropertyStateEnum,
} from '../api/accounting';
import { AuthContext } from '../contexts/AuthContext';


export default function usePropertyList() {
  const { tl } = useContext(LanguageContext);
  const [propertyList, setPropertyList] = useState(DEFAULT_DATA<PropertyDisplayDto[]>([]).load([], {}, false));

  const defaultFilterState = {
    propertyStates: [PropertyLegacyDtoPropertyStateEnum.READY],
  };

  const defaultSort: { field: string, order: 1 | -1 } = {
    field: 'propertyHrId',
    order: 1,
  };
  const [filters, setFilters] = useState<any>(defaultFilterState);


  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (propertyList.loaded) {
      loadPropertyList(true);
    }
  }, [filters]);

  const loadPropertyList = (resetPage: boolean = false) => {
    setPropertyList(state => state.startLoading());
    propertyControllerApi.findFilteredPropertiesUsingGET({
      ..._.pickBy(filters, _.identity),
      // @ts-ignore
      administrationTypes: [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG],
      excludeFields: ['bankAccounts'],
      page: resetPage ? 0 : propertyList.page,
      order: defaultSort.order > 0 ? FindFilteredPropertiesUsingGETOrderEnum.ASC : FindFilteredPropertiesUsingGETOrderEnum.DESC,
      sort: defaultSort.field,
      size: 30,
    }).then((response) => {
      setPropertyList(state => state.loadPaged(response.content, resetPage, response.last!));
    }).catch(() => {
      setPropertyList(state => state.failed());
      showNotification({
        key: 'loadPropertiesError',
        message: tl(translations.notifications.usePropertyList.loadError.message),
        description: tl(translations.notifications.usePropertyList.loadError.description),
        type: 'error',
      });
    });
  };

  const updateFilterState = (data: object) => {
    setFilters((state: any) => ({
      ...state,
      ...data,
    }));
  };

  return {
    filters,
    propertyList,
    loadPropertyList,
    updateFilterState,
  };
}
