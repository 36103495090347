import React, { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { useIsolatedDirtModalUtils } from 'elements/Modals/DirtModal/IsolatedSectionsDirtModal/useIsolatedDirtModalUtils';
import { useOpsCostPrintMode, useOpsCostReportManualAmountsActions } from '../../services/stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';
import { OpsCostSectionKeys } from '../../services/stores/opsCostReportManualAmountsStore/interfacesOpsCostReportManualAmounts';
import './AddRowButtonAndDescription.scss';

type AddRowButtonProps = {
  section: OpsCostSectionKeys,
  contractId: number,
  disabled:boolean,
}

const AddRowButtonAndDescription = ({
  section, contractId, disabled,
}: AddRowButtonProps) => {
  const { tl } = useContext(LanguageContext);
  const { addRow } = useOpsCostReportManualAmountsActions();
  const printMode = useOpsCostPrintMode();
  const { onSetDirtyValue } = useIsolatedDirtModalUtils();

  if (printMode) {
    return null;
  }

  return (
    <div className="AddRowButtonAndDescription">
      <span className="add-row-description">{tl(opsCostTranslation.report.sections.common.addRowDescription)}</span>
      <Button
        icon={<Icon component={ICONS.plus} />}
        onClick={() => {
          onSetDirtyValue();
          addRow(contractId, section);
        }}
        disabled={disabled}
      >
        {tl(opsCostTranslation.report.sections.common.addButton)}
      </Button>
    </div>
  );
};

export default AddRowButtonAndDescription;
