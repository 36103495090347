import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { documentTranslations } from './translations';

export const useDeleteDocumentModal = () => {
  const { tl } = useContext(LanguageContext);

  const showModal = (onDelete: () => void) => {
    Modal.confirm({
      title: tl(documentTranslations.deleteModal.title),
      content: tl(documentTranslations.deleteModal.content),
      okText: tl(documentTranslations.deleteModal.delete),
      cancelText: tl(documentTranslations.deleteModal.cancel),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: onDelete,
      width: 450,
    });
  };

  return {
    showModal,
  };
};
