import React, { useEffect, useState } from 'react';
import './FormSidebar.scss';
import Link from './Link/Link';
import useEvent from '../../../lib/useEvent';

interface formSidebarProps {
  elements: {
    title: JSX.Element,
    menuTitle?: string,
    href: string,
    number: string,
    openByDefault: boolean,
    disabled?: boolean,
    subElements?: {
      title: JSX.Element,
      menuTitle?: string,
      href: string,
      onClick?: Function
    }[],
    groupElements?: {
      title: JSX.Element,
      menuTitle?: string,
      href: string,
      number: string,
      subElements?: {
        title: string,
        href: string,
      }[],
    }[]
  }[]
  openSection: number
  onOpenSection: (index: number) => void
  scrollTarget?: any
}

export default function FormSidebar(props: formSidebarProps): JSX.Element {
  const defaultElements = () => props.elements.map((element: any) => ({
    ...element,
    open: element.openByDefault,
  }));


  const { openSection, scrollTarget } = props;

  const [activeElementHref, setActiveElementHref] = useState<string>();
  const [elements, setElements] = useState(defaultElements());


  useEffect(() => {
    setElements(defaultElements());
  }, [props]);

  useEffect(() => {
    if (openSection !== -1 && !!elements[openSection]) {
      setActiveElementHref(elements[openSection].href);
    } else {
      setActiveElementHref(elements[0].href);
    }
  }, [openSection]);


  useEvent('scroll', scrollTarget, ((e: any) => {
    const rect = scrollTarget.getBoundingClientRect();
    const sectionElement = document.elementsFromPoint(rect.left + (rect.width / 2), rect.top + (rect.height / 6))
      .filter((element: any) => !!element.id && element.className.toLowerCase().indexOf('section') !== -1)[0];

    if (!!sectionElement && !!sectionElement.id && activeElementHref !== sectionElement.id) {
      setActiveElementHref(sectionElement.id);
    }
  }));

  const onOpenElement = (index: number) => {
    setElements(elements.map((element: any, i: number) => {
      if (i === index) {
        element.open = !element.open;
      }
      return element;
    }));
  };

  /**
   * Search the clicked element/subelement
   * navigate / open section depending on case.
   * @param href
   */
  const onOpenSection = (href: string) => {
    const filteredElements = elements.filter(
      (element: any) => element.href === href
            || (
              !!element.subElements
                && element.subElements.filter((subElement: any) => subElement.href === href).length > 0
            )
            || (!!element.groupElements
                && element.groupElements.filter(
                  (groupElement: any) => groupElement.href === href
                        || groupElement.subElements.filter((subElement: any) => subElement.href === href).length > 0,
                )
            ).length > 0,
    );

    if (filteredElements.length > 0) {
      const sectionNumber = filteredElements[0].sectionIndex;
      if (openSection !== sectionNumber) {
        props.onOpenSection(sectionNumber);
      }

      if (filteredElements[0].subElements) {
        filteredElements[0].subElements.forEach((subElement: any) => {
          if (subElement.href === href && subElement.onClick) {
            subElement.onClick();
          }
        });
      }

      if (filteredElements[0].groupElements) {
        filteredElements[0].groupElements.forEach((groupElement: any) => {
          groupElement.subElements.forEach((subElement: any) => {
            if (subElement.href === href && subElement.onClick) {
              subElement.onClick();
            }
          });
        });
      }
      // gives time for the section to open, if needed
      setTimeout(() => {
        const elm = document.getElementById(href);
        if (elm) {
          elm.scrollIntoView({ behavior: 'smooth' });
        }
        setActiveElementHref(href);
      }, 0);
    }
  };

  return (
    <div className="FormSidebar">
      {elements.map((element, i) => (
        <Link
          className="main-link"
          key={`${i}${element.href}`}
          level={1}
          active={element.href === activeElementHref}
          onClick={() => onOpenSection(element.href)}
          showArrow={(!!element.subElements && element.subElements.length !== 0) || (!!element.groupElements && element.groupElements.length !== 0)}
          onArrowClick={() => onOpenElement(i)}
          open={element.open}
          disabled={element.disabled}
          title={element.menuTitle
            ? `${element.number}\u00A0\u00A0\u00A0\u00A0${element.menuTitle}`
            : `${element.number}\u00A0\u00A0\u00A0\u00A0${element.title}`
          }
        >
          {element.open && !!element.subElements && element.subElements.map((subElement: any, j: number) => (
            <Link
              className="sub-link"
              key={`${j}${subElement.href}`}
              level={2}
              active={subElement.href === activeElementHref}
              onClick={() => onOpenSection(subElement.href)}
              title={subElement.menuTitle ? subElement.menuTitle : subElement.title}
            />
          ))}
          {element.open && !!element.groupElements && element.groupElements.map((groupElement: any, j: number) => (
            <Link
              className="sub-link"
              key={`${j}${groupElement.href}`}
              level={2}
              active={groupElement.href === activeElementHref}
              onClick={() => onOpenSection(groupElement.href)}
              title={groupElement.menuTitle
                ? `${groupElement.number}\u00A0\u00A0\u00A0\u00A0${groupElement.menuTitle}`
                : `${groupElement.number}\u00A0\u00A0\u00A0\u00A0${groupElement.title}`
              }
            >
              {!!groupElement.subElements && groupElement.subElements.map((subElement: any, k: number) => (
                <Link
                  className="sub-link"
                  key={`${k}${subElement.href}`}
                  level={3}
                  active={subElement.href === activeElementHref}
                  onClick={() => onOpenSection(subElement.href)}
                  title={subElement.menuTitle ? subElement.menuTitle : subElement.title}
                />
              ))}

            </Link>
          ))}
        </Link>
      ))}
    </div>
  );
}
