import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { Alert } from 'antd';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { translations as dateInputTranslations } from 'elements/Inputs/DateInput/translations';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import DateInput from '../../../../../../elements/Inputs/DateInput/DateInput';
import TextInput from '../../../../../../elements/Inputs/TextInput/TextInput';
import { useAccounts } from '../../services/useAccounts';
import { useHouseMoneySettlementAndProperty } from '../../services/useHouseMoneySettlementAndProperty';
import { useValidationErrors } from '../../services/useValidationErrors';
import { translations } from '../../translations';
import './HouseMoneySettlementCloseYearSection1.scss';


export default function HouseMonetSettlementCloseYearSection1(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { hmsId } = useParams<{ hmsId: string }>();

  const { validationErrors, bookingDateValidation } = useValidationErrors();
  const {
    onChange,
    formValues,
    bankAccountOptions,
  } = useAccounts();

  const {
    sectionTitle,
    getHouseMoneySettlement,
  } = useHouseMoneySettlementAndProperty();

  useEffect(() => {
    if (hmsId) {
      getHouseMoneySettlement(parseInt(hmsId, 10));
    }
  }, [hmsId]);

  const isBookingDateInvalid = bookingDateValidation.isBeforeReportEndDate || bookingDateValidation.isFarInFuture;

  return (
    <PrimarySectionWrapper
      sectionNumber={1}
      sectionTitle={sectionTitle}
      sectionId="hms-close-year-account-selection"
    >
      <div className="HouseMoneySettlementCloseYearSection1 form">
        <div className="row">
          <DateInput
            required
            label={tl(translations.creationPage.sections.section1.bookingDate)}
            value={formValues.bookingDate}
            onChange={(val) => {
              onChange('bookingDate', val);
            }}
            validationState={validationErrors.bookingDate?.state}
            validationMessage={validationErrors.bookingDate?.errorMessage}
          />
        </div>
        <div className="row">
          <TextInput
            label={tl(translations.creationPage.sections.section1.bookingText)}
            onChange={(val) => {
              onChange('bookingText', val);
            }}
            value={formValues.bookingText}
            validationState={validationErrors.bookingText?.state}
            validationMessage={validationErrors.bookingText?.errorMessage}
          />
        </div>
        <div className="row">
          <SelectInput
            required
            showSearch
            label={tl(translations.creationPage.sections.section1.bankAccount)}
            options={bankAccountOptions?.data ?? []}
            disabled={!bankAccountOptions.data}
            onChange={(val) => {
              onChange('bankAccount', val);
            }}
            value={formValues.bankAccount}
            validationState={validationErrors.bankAccount?.state}
            validationMessage={validationErrors.bankAccount?.errorMessage}
          />
        </div>
        { isBookingDateInvalid
        && (
          <div className="row">
            <Alert
              message={bookingDateValidation.isBeforeReportEndDate
                ? tl(translations.creationPage.sections.section1.bookingDateBeforeEndWarning)
                : `${tl(dateInputTranslations.dateTooFarInFutureMessage)} ${tl(dateInputTranslations.dateTooFarDescription)}`
              }
              type="warning"
              showIcon
              icon={<Icon component={ICONS.warning} />}
            />
          </div>
        )
        }
      </div>
    </PrimarySectionWrapper>
  );
}
