import { useContext } from 'react';
import { ProfitAndLossReportViewContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportView/serivces/ProfitAndLossReportViewContext';

export const useProfitAndLossReportView = () => {
  const profitAndLossReportViewContext = useContext(ProfitAndLossReportViewContext);

  if (profitAndLossReportViewContext === undefined) {
    throw new Error('useProfitAndLossReportView must be used within a ProfitAndLossReportViewContextProvider');
  }

  const { profitAndLossReport } = profitAndLossReportViewContext;

  return {
    url: encodeURI(profitAndLossReport.data?.url),
    filename: profitAndLossReport.data?.name,
  };
};
