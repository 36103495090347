import {
  Context,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Configuration,
  ConnectionsApi,
  CreateSyncDataDtoSourceTypeEnum,
  CreateSyncDataDtoStateEnum,
  CreateSyncDataUsingPOSTResourceNameEnum,
  DeleteSyncDataUsingDELETEResourceNameEnum,
  GetSyncDataUsingGETResourceNameEnum,
  GetSyncDataUsingGETSourceTypeEnum,
  SyncDataApi,
  SyncDataDto,
} from 'api/app';
import { FaciliooAPI } from 'api/facilioo/faciliooApi';
import {
  FaciliooCredentials,
  FaciliooTicket,
} from 'api/facilioo/interfaces';
import { DocumentApi } from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { getEnvVar } from 'lib/getEnvVar';
import { showNotification } from 'lib/Notification';
import _, { debounce } from 'lodash';
import {
  ExternalPropertyIssue,
  PortalDocumentProps,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  calculateRelevance,
} from 'storybook-components/TicketIntegrationSection/services/ticketUtils';

import { translations } from '../../translations';

const faciliooUiBaseUrl = getEnvVar('facilioo.uiUrl', 'https://stage-app.facilioo.de') as string;

const mapTicketToIssue = (ticket: FaciliooTicket): ExternalPropertyIssue => ({
  id: ticket.id,
  type: 'Ticket',
  number: ticket.number,
  name: ticket.subject,
  description: ticket.report,
  url: `${faciliooUiBaseUrl}/orderGet.html?id=${ticket.id}`,
  relevanceScore: 1,
  assigned: false,
  searchValue: `${ticket.number} ${ticket.subject} ${ticket.report}`.toLocaleLowerCase(),
});

const debouncableMethod = (setSearchTerm, searchTermNewValue) => {
  setSearchTerm(searchTermNewValue);
};

const debouncedSearch = debounce(debouncableMethod, 500);

export const useFaciliooTickets = (connectionId: number,
  { propertyId, contactId }: {propertyId?: number, contactId?: number},
  documentProps: PortalDocumentProps) => {
  const [legacyKey, setLegacyKey] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [issues, setIssues] = useState<ExternalPropertyIssue[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [faciliooId, setFaciliooId] = useState<string>();
  const [faciliooLink, setFaciliooLink] = useState<string>();
  const [timestamp, setTimestamp] = useState<Date>();
  const [syncData, setSyncData] = useState<SyncDataDto>();


  const appContext = useContext<{ apiConfiguration:(string)
    => Configuration }>(AuthContext as unknown as Context<{ apiConfiguration: (string) => Configuration }>);
  const { publicApiConfiguration } = useContext(AuthContext);
  const documentApi = new DocumentApi(publicApiConfiguration('public'));

  const { tl } = useContext(LanguageContext);

  const connectionsApi = new ConnectionsApi(appContext.apiConfiguration('app'));
  const syncDataApi = new SyncDataApi(appContext.apiConfiguration('app'));

  useEffect(() => {
    if (!loading && contactId && connectionId) {
      getContactSyncData();
    }
  }, [contactId, connectionId]);

  useEffect(() => {
    if (!loading && propertyId && connectionId) {
      getPropertySyncData();
    }
  }, [propertyId, connectionId]);

  useEffect(() => {
    if (documentProps?.entityId || documentProps?.sourceId) {
      getSyncData();
    }
  }, [documentProps?.entityId, documentProps?.sourceId, connectionId]);

  useEffect(() => {
    if (!loading && faciliooId) {
      getIssues(faciliooId);
    }
  }, [faciliooId]);

  const issuesWithAssignments = useMemo(() => {
    let assignedIssueId;
    if (syncData?.externalEntityId) {
      assignedIssueId = JSON.parse(syncData?.externalEntityId).orderId;
    }
    return issues.filter(i => !_.isNil(i)).map(issue => ({ ...issue, assigned: issue?.id === assignedIssueId }));
  }, [issues, syncData]);

  const filteredAndRankedIssues = useMemo(() => {
    if (!searchTerm) {
      return [...issuesWithAssignments]
        .sort((a, b) => (Number(b.assigned) - Number(a.assigned)));
    }
    return issuesWithAssignments
      .map(calculateRelevance(searchTerm))
      .filter(e => e.relevanceScore > 0)
      .sort((a, b) => {
        if (a.relevanceScore > b.relevanceScore) {
          return 1;
        } if (a.relevanceScore < b.relevanceScore) {
          return -1;
        }
        return 0;
      });
  }, [issuesWithAssignments, searchTerm]);

  const getPropertySyncData = async () => {
    try {
      const response: SyncDataDto[] = (await syncDataApi.getSyncDataUsingGET({
        connectionId,
        resourceName: GetSyncDataUsingGETResourceNameEnum.properties,
        resourceId: propertyId,
      })).content;
      setFaciliooId(response[0]?.externalEntityId);
      setTimestamp(response[0]?.lastSyncTimestamp);
    } catch (e) {
      console.error(e);
    }
  };

  const getContactSyncData = async () => {
    try {
      const response: SyncDataDto[] = (await syncDataApi.getSyncDataUsingGET({
        connectionId,
        resourceName: GetSyncDataUsingGETResourceNameEnum.contacts,
        resourceId: contactId,
      })).content;
      setFaciliooId(response[0]?.externalEntityId);
      setTimestamp(response[0]?.lastSyncTimestamp);
    } catch (e) {
      console.error(e);
    }
  };

  const getSyncData = async () => {
    try {
      const response: SyncDataDto[] = (await syncDataApi.getSyncDataUsingGET({
        connectionId,
        resourceId: documentProps?.entityId,
        resourceName: GetSyncDataUsingGETResourceNameEnum.documents,
        sourceId: documentProps?.entityId ? undefined : documentProps?.sourceId,
        sourceType: documentProps?.entityId ? undefined : documentProps?.sourceType as unknown as GetSyncDataUsingGETSourceTypeEnum,
      })).content;
      setSyncData(response[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getFaciliooCredentials = async (): Promise<FaciliooCredentials> => {
    const connection = await (await connectionsApi.getConnectionUsingGET({ id: connectionId }));
    // format of externalConfig {"email":"info@impower.de","password":"[passbolt]", "defaultTenantId":1234}
    return JSON.parse(connection.externalConfig) as FaciliooCredentials;
  };

  const getIssues = async (externalId: string) => {
    setLoading(true);
    // retrieve external ids of property
    const credentials = await getFaciliooCredentials();
    setLegacyKey(credentials.legacyKey);
    let allTickets: FaciliooTicket[] = [];

    try {
      const faciliooApi = new FaciliooAPI({ ...credentials });
      // load issues based on property
      if (propertyId) {
        const fclP = await faciliooApi.getPropertyByExternalId(externalId);
        setFaciliooLink(`${faciliooUiBaseUrl}/objectGet.html?id=${fclP?.id}`);
        allTickets = await faciliooApi.getAllTicketsBy({ propertyId: fclP.id });
        setIssues(allTickets.map(mapTicketToIssue));
      }
      // load issues based on contact
      if (contactId) {
        const cc = await faciliooApi.getPartyByExternalId(externalId);
        setFaciliooLink(`${faciliooUiBaseUrl}/companyGet.html?id=${cc?.id}`);
        allTickets = await faciliooApi.getAllTicketsBy({ partyId: cc.id });
        setIssues(allTickets.map(mapTicketToIssue));
      }
    } catch (e) {
      setErrorMessage(tl(translations.errors.communication)('facilioo'));
      console.error(e);
    }

    setLoading(false);
  };

  const onChangeSearchTerm = (searchTermNewValue) => {
    debouncedSearch(setSearchTerm, searchTermNewValue);
  };

  const isAssigned = useMemo(() => issuesWithAssignments.some(issue => issue.assigned), [issuesWithAssignments]);

  const createDocumentSyncData = async (orderId: number, timelineEntryId: number) => {
    try {
      const dSyncData = {
        entityId: documentProps?.entityId,
        sourceId: documentProps?.sourceId,
        sourceType: documentProps?.sourceType as unknown as CreateSyncDataDtoSourceTypeEnum,
        externalEntityId: JSON.stringify({ orderId, timelineEntryId }),
        state: CreateSyncDataDtoStateEnum.SUCCESS,
        syncTimestamp: new Date(),
      };
      const response: SyncDataDto = await syncDataApi.createSyncDataUsingPOST({
        resourceName: CreateSyncDataUsingPOSTResourceNameEnum.documents,
        connectionId,
        createSyncDataDto: dSyncData,
      });
      setSyncData(response);
    } catch (e) {
      console.error(e);
      setErrorMessage(tl(translations.errors.assign));
    }
  };

  const assignTicket = async (issueId: number, comm: string) => {
    const credentials = await getFaciliooCredentials();
    setLegacyKey(credentials.legacyKey);
    setLoading(true);
    try {
      try {
        const faciliooApi = new FaciliooAPI({ ...credentials });
        const response = await faciliooApi.addOrderTimelineEntry(issueId, comm);
        createDocumentSyncData(issueId, response.result);
        if (documentProps?.entityId) {
          try {
            const addedFile = await faciliooApi.addFile(documentProps?.fileName, 'pdf');
            const file: any = await documentApi.downloadUsingGET(
              { documentId: documentProps?.entityId },
            );
            await fetch(addedFile.uploadUrl, { method: 'PUT', body: file });
            await faciliooApi.addOrderTimelineEntryFile(response.result, addedFile.result);
          } catch (e) {
            showNotification({
              key: 'faciliooAssignError',
              message: tl(translations.errors.uploadFail)('facilioo'),
              type: 'warning',
            });
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
        setErrorMessage(tl(translations.errors.communication)('facilioo'));
      }
    } catch (e) {
      setErrorMessage(tl(translations.errors.credentials));
    }
    setLoading(false);
  };

  const unassignTicket = async () => {
    try {
      await syncDataApi.deleteSyncDataUsingDELETE({
        resourceName: DeleteSyncDataUsingDELETEResourceNameEnum.documents,
        connectionId,
        id: syncData.id,
      });
      setSyncData(undefined);
    } catch (e) {
      console.error(e);
      setErrorMessage(tl(translations.errors.unassign));
    }
  };

  return {
    legacyKey,
    loading,
    errorMessage,
    issues: filteredAndRankedIssues,
    faciliooLink,
    timestamp,
    isAssigned,
    onChangeSearchTerm,
    assignTicket,
    unassignTicket,
  };
};
