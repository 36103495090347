import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { useHistory, useLocation } from 'react-router';
import { DropdownButton } from 'storybook-components/DropdownButton/DropdownButton';
import { economicPlanTranslations } from '../economicPlanTranslations';


const CreateEconomicPlanButton = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const location = useLocation();

  const menuItems = [
    {
      key: 'ACCOUNT_DISTRIBUTION',
      onClick: () => history.push(`${location.pathname}/create?type=ACCOUNT_DISTRIBUTION`),
      label: tl(economicPlanTranslations.economicPlanList.ACCOUNT_DISTRIBUTION),
    },
    {
      key: 'UNIT_FIGURES',
      onClick: () => history.push(`${location.pathname}/create?type=UNIT_FIGURES`),
      label: tl(economicPlanTranslations.economicPlanList.UNIT_FIGURES),
    },
  ];


  return (
    <DropdownButton
      buttonLabel={tl(economicPlanTranslations.economicPlanList.create)}
      menuItems={menuItems}
      getPopupContainer={() => (document.getElementsByClassName('EconomicPlanList')?.[0]) as HTMLElement}
    />
  );
};

export default CreateEconomicPlanButton;
