import React, {
  createContext,
  ReactNode,
  useState,
} from 'react';

import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';

import { AgendaItemDto } from '../../../../../../../api/accounting';

export const AgendaItemDetailsContext = createContext<{
  selectedAgendaItem: DefaultDataInterface<AgendaItemDto>,
  setSelectedAgendaItem: React.Dispatch<React.SetStateAction<DefaultDataInterface<AgendaItemDto>>>,
  dirty: boolean,
  setDirty:React.Dispatch<React.SetStateAction<boolean>>,
  dirtModalVisible: boolean,
  setDirtModalVisible:React.Dispatch<React.SetStateAction<boolean>>,
} | undefined>(undefined);


const AgendaItemDetailsContextProvider = ({ children }: { children: ReactNode }) => {
  const [selectedAgendaItem, setSelectedAgendaItem] = useState(DEFAULT_DATA<AgendaItemDto>({ }));
  const [dirty, setDirty] = useState<boolean>(false);
  const [dirtModalVisible, setDirtModalVisible] = useState<boolean>(false);

  const providerValue = {
    selectedAgendaItem,
    setSelectedAgendaItem,
    setDirty,
    dirty,
    dirtModalVisible,
    setDirtModalVisible,
  };

  return (
    <AgendaItemDetailsContext.Provider value={providerValue}>
      {children}
    </AgendaItemDetailsContext.Provider>
  );
};

export default AgendaItemDetailsContextProvider;
