import React, { useContext } from 'react';
import './HelpButton.scss';
import Icon from '@ant-design/icons';
import { ICONS } from '../icons';
import { DropMenu } from '../DropMenu/DropMenu';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from './translations';

export const HelpButton: React.FC = () => {
  const { tl } = useContext(LanguageContext);
  const options = [
    {
      label: tl(translations.helpCenter),
      onClick: () => window.open('https://impower.atlassian.net/servicedesk/customer/portals'),
      className: 'help-menu-option-item',
    },
  ];
  return (
    <DropMenu
      options={options}
      className="help-button"
      placement="topLeft"
      dropdownVisibleClassName="help-button-pressed"
    >
      <Icon component={ICONS.help} />
    </DropMenu>
  );
};
