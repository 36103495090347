import SectionMenu, { SectionMenuElement } from 'components/SectionMenu/SectionMenu';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import HouseMoneySettlementCloseYearSection1 from './components/HouseMoneySettlementCloseYearSection1/HouseMoneySettlementCloseYearSection1';
import HouseMoneySettlementCloseYearSection2 from './components/HouseMoneySettlementCloseYearSection2/HouseMoneySettlementCloseYearSection2';
import { PromptWrapper } from './components/PromptWrapper';
import './HouseMoneySettlementCloseYearCreation.scss';
import HouseMoneySettlementCloseYearHeaderButtons from './components/HouseMoneySettlementCloseYearHeaderButtons';
import { HouseMoneySettlementContextProvider } from './services/HouseMoneySettlementCloseYearContext';
import { translations } from './translations';

const HOUSE_MONEY_SETTLEMENT_CLOSE_YEAR_CREATION_CONTENT_ID = 'hms-close-year-creation-form-content';
export default function HouseMoneySettlementCloseYearCreation(): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const menuElements: SectionMenuElement[] = [
    {
      title: tl(translations.creationPage.accountSelectionTitle),
      sectionId: 'hms-close-year-account-selection',
    },
    {
      title: tl(translations.creationPage.valuesTitle),
      sectionId: 'hms-close-year-values',
    },
  ];

  return (
    <HouseMoneySettlementContextProvider>
      <Page className="HouseMoneySettlementCloseYearCreation">
        <PageHeader
          title={tl(translations.creationPage.title)}
          rightSideComponent={<HouseMoneySettlementCloseYearHeaderButtons />}
          showLogo
        />
        <PageContent className="page-content">
          <PromptWrapper />
          <div className="form-content" id={HOUSE_MONEY_SETTLEMENT_CLOSE_YEAR_CREATION_CONTENT_ID}>
            <HouseMoneySettlementCloseYearSection1 />
            <HouseMoneySettlementCloseYearSection2 />
          </div>
          <SectionMenu
            elements={menuElements}
            scrollTarget={document.getElementById(HOUSE_MONEY_SETTLEMENT_CLOSE_YEAR_CREATION_CONTENT_ID)}
          />
        </PageContent>
      </Page>
    </HouseMoneySettlementContextProvider>
  );
}
