import {
  ManualExchangeCreationDto, ManualExchangeCreationDtoBankOrderStateEnum, ManualExchangePlanControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { calculateVatAmountFromGross } from 'lib/vatUtils';
import {
  AccountSelectionContext,
  AmountDistributionUpdatersAndDatasourceContext,
  AmountToDistributeInputValuesContext,
  DirectionAndProcessingInputValuesContext,
  Distribution,
  OccurrenceValuesContext,
} from './ManualExchangeContext';
import { translations } from '../translations';

export const useExecuteManualExchange = () => {
  const directionAndProcessingInputValues = useContext(DirectionAndProcessingInputValuesContext);
  const amountToDistributeInputValues = useContext(AmountToDistributeInputValuesContext);
  const accountSelectionContext = useContext(AccountSelectionContext);
  const occurrenceContext = useContext(OccurrenceValuesContext);
  const dataSourceContext = useContext(AmountDistributionUpdatersAndDatasourceContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const manualExchangeControllerApi = new ManualExchangePlanControllerApi(apiConfiguration('accounting'));
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onExecute = () => {
    if (directionAndProcessingInputValues === undefined || amountToDistributeInputValues === undefined || accountSelectionContext === undefined || occurrenceContext === undefined || dataSourceContext === undefined) {
      throw new Error('useExecuteManualExchange must be used within a ManualExchangeContextProvider');
    }

    setLoading(true);

    const { formValues } = accountSelectionContext;
    const { occurrenceValues } = occurrenceContext;
    const { tableDataSource } = dataSourceContext;

    const distributions: Array<Distribution> = amountToDistributeInputValues.filter(({ selected, amount }) => selected && amount)
      .map(((amountAndSelected) => {
        const directionAndProcessing = directionAndProcessingInputValues.find(({ accountCode }) => accountCode === amountAndSelected.accountCode);
        const unitContract = tableDataSource.data.find(uc => uc.unitContractId === amountAndSelected.unitContractId);

        if (directionAndProcessing === undefined) {
          setLoading(false);
          throw new Error(`Invalid state: no matching directionAndProcessingInputValue for ${amountAndSelected.accountCode}`);
        }

        return { ...amountAndSelected, ...directionAndProcessing, vatRelevance: unitContract?.vatRelevance };
      }));


    const firstOccurenceManualExchangeCreationDtoList: Array<ManualExchangeCreationDto> = [];
    const manualExchangeCreationDtoList: Array<ManualExchangeCreationDto> = [];
    distributions.forEach((dist: Distribution) => {
      const sign = dist.direction === 'outgoing' ? -1 : 1;
      const amount = dist.amount * sign;
      const vatAmount = calculateVatAmountFromGross(amount, dist.vatPercentage);
      occurrenceValues.forEach((occ, index) => (index === 0 ? firstOccurenceManualExchangeCreationDtoList : manualExchangeCreationDtoList).push({
        propertyHrId: formValues.property?.propertyHrId,
        amount: dist.amount! * sign,
        name: occ.bankPurposeText,
        debtDate: occ.bookingDate?.toISOString(),
        unitContractId: dist.unitContractId,
        propertyBankAccountId: dist.processing === 'PLATFORM' && formValues?.bankAccount !== -1 ? formValues.bankAccount : undefined,
        counterpartBankDetailsId: dist.processing === 'PLATFORM' ? dist.unitBankAccountId : undefined,
        bankOrderState: (formValues?.bankAccount === -1 || dist.processing === 'SELF_PAYER')
          ? ManualExchangeCreationDtoBankOrderStateEnum.NOT_NEEDED
          : ManualExchangeCreationDtoBankOrderStateEnum.GENERATE_ORDER,
        counterpartInstruction: [{
          counterpartCode: `${dist.accountCode}${formValues.accountTag}`,
          categorisationInstructions: [{
            amount,
            bookingText: occ.bookingText,
            accountCode: formValues.counterAccount,
            vatPercentage: dist.vatPercentage,
            vatAmount,
          }],
        }],
      }));
    });

    manualExchangeControllerApi.batchCreateManualExchangesUsingPOST({ manualExchangeCreationDtoList: firstOccurenceManualExchangeCreationDtoList })
      .then(() => {
        if (manualExchangeCreationDtoList && manualExchangeCreationDtoList.length) {
          // Fire and forget, the rest of the occurances
          manualExchangeControllerApi.batchCreateManualExchangesUsingPOSTRaw({ manualExchangeCreationDtoList });
          showNotification({
            message: tl(translations.creationPage.notifications.executeManyRegistered.title),
            description: tl(translations.creationPage.notifications.executeManyRegistered.description),
            type: 'success',
            duration: 15,
          });
        } else {
          showNotification({
            message: tl(translations.creationPage.notifications.executeOneSuccess),
            type: 'success',
          });
        }

        history.push('/dashboard');
      })
      .catch(() => {
        showNotification({
          key: 'executeManualExchangeError',
          message: tl(translations.creationPage.notifications.executeError),
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { onExecute, loading };
};
