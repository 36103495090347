import {
  useContext,
  useEffect,
} from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import { useParams } from 'react-router-dom';

import {
  AgendaItemVoteControllerApi,
  AgendaItemVoteCreationDtoStatusEnum,
  AgendaItemVoteCreationDtoVoteKeyEnum,
  AgendaItemVoteCreationDtoVoteMajorityKeyEnum,
  AgendaItemVoteDto,
  AgendaItemVoteDtoStatusEnum,
} from '../../../../../../../api/accounting';
import { AuthContext } from '../../../../../../../contexts/AuthContext';
import { AgendaItemVoteContext } from '../AgendaItemVoteContext';
import { translations } from '../translations';

export const useAgendaItemVotingResult = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const agendaItemVoteControllerApi = new AgendaItemVoteControllerApi(apiConfiguration('accounting'));

  const { agendaItemId } = useParams<{agendaItemId:string}>();
  const selectedAgendaItemId = parseInt(agendaItemId, 10);

  const agendaItemVoteContext = useContext(AgendaItemVoteContext);
  if (agendaItemVoteContext === undefined) {
    throw new Error('useAgendaItemVotingResult must be used within a AgendaItemVoteContextProvider');
  }
  const {
    selectedAgendaItemVote, setSelectedAgendaItemVote, setIsStandardVoteDetails, isStandardVoteDetails, voteIsDirty, setVoteIsDirty,
  } = agendaItemVoteContext;

  const votingStatus = [
    {
      value: AgendaItemVoteDtoStatusEnum.ACCEPTED,
      label: tl(translations.votingResult.statusOptions.accepted),
      text: 'Der Versammlungsleiter verkündet den Beschluss als mehrheitlich angenommen.',
    },
    {
      value: AgendaItemVoteDtoStatusEnum.DECLINED,
      label: tl(translations.votingResult.statusOptions.declined),
      text: 'Der Versammlungsleiter verkündet den Beschluss als mehrheitlich abgelehnt.',
    },
    {
      value: AgendaItemVoteDtoStatusEnum.NOT_DECIDED,
      label: tl(translations.votingResult.statusOptions.notDecided),
      text: 'Zu diesem Tagesordnungspunkt erfolgte keine Abstimmung.',
    },
    {
      value: AgendaItemVoteDtoStatusEnum.DRAFT,
      label: tl(translations.votingResult.statusOptions.draft),
      text: ' ',
    },
  ];

  const isStandardText = (status: AgendaItemVoteDtoStatusEnum, voteDetails: string) => {
    const votingStatusText = votingStatus.find(vs => vs.value === status)?.text;
    return votingStatusText === voteDetails;
  };

  const onSaveAgendaItemVotingResult = (agendaItemIdLocal: number) => {
    if (selectedAgendaItemVote?.data.id && voteIsDirty) {
      return agendaItemVoteControllerApi.updateAgendaItemVoteUsingPUT({
        agendaItemVoteId: selectedAgendaItemVote.data.id,
        agendaItemVoteDto: {
          ...selectedAgendaItemVote.data,
        },
      });
    }
    if (!selectedAgendaItemVote?.data?.id) {
      return agendaItemVoteControllerApi.createAgendaItemVoteUsingPOST({
        agendaItemVoteDto: {
          ...selectedAgendaItemVote?.data,
          agendaItemId: agendaItemIdLocal,
          status: selectedAgendaItemVote?.data?.status ? selectedAgendaItemVote?.data?.status as unknown as AgendaItemVoteCreationDtoStatusEnum : AgendaItemVoteCreationDtoStatusEnum.DRAFT,
          voteKey: selectedAgendaItemVote?.data?.voteKey as unknown as AgendaItemVoteCreationDtoVoteKeyEnum,
          voteMajorityKey: selectedAgendaItemVote?.data?.voteMajorityKey as unknown as AgendaItemVoteCreationDtoVoteMajorityKeyEnum,
        },
      });
    }
  };


  useEffect(() => {
    if (selectedAgendaItemId) {
      agendaItemVoteControllerApi.getAgendaItemVoteByAgendaItemIdUsingGET({ agendaItemId: selectedAgendaItemId })
        .then((response: any) => {
          setSelectedAgendaItemVote(prev => prev.load(response));
          setIsStandardVoteDetails(isStandardText(response.status, response.voteDetails));
        })
        .catch(() => {
          setSelectedAgendaItemVote(null);
          setIsStandardVoteDetails(true);
        });
    } else {
      setIsStandardVoteDetails(true);
    }
  }, [selectedAgendaItemId]);


  const onChangeVoteResult = (key: keyof AgendaItemVoteDto, value: AgendaItemVoteDto[keyof AgendaItemVoteDto]) => {
    let newVoteDetails = '';
    if (key === 'status') {
      setIsStandardVoteDetails(true);
      newVoteDetails = votingStatus.filter(st => st.value === value)[0].text;
    }
    setSelectedAgendaItemVote(prev => prev.load({ ...prev.data, voteDetails: newVoteDetails || prev.data.voteDetails, [key]: value }));
    setVoteIsDirty(true);
  };

  const toggleStandardVoteDetails = (value: boolean) => {
    if (value) {
      setIsStandardVoteDetails(true);
      const newVoteDetails = votingStatus.filter(st => st.value === selectedAgendaItemVote.data.status)[0].text;
      setSelectedAgendaItemVote(prev => prev.load({ ...prev.data, voteDetails: newVoteDetails || prev.data.voteDetails }));
      setVoteIsDirty(true);
    } else {
      setIsStandardVoteDetails(false);
    }
  };

  return {
    votingStatus,
    selectedAgendaItemVote,
    setSelectedAgendaItemVote,
    isStandardVoteDetails,
    toggleStandardVoteDetails,
    onSaveAgendaItemVotingResult,
    voteIsDirty,
    setVoteIsDirty,
    onChangeVoteResult,
  };
};
