import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { DunningProjectionStateEnum, ListDunningDtoUsingGETPropertyAdministrationTypesEnum } from 'api/accounting';
import { orderOpenBalancesTranslations } from '../../translations';
import { useDunningListContext } from './DunningListContext';

export default function useDunningListFilters(): any {
  const { tl } = useContext(LanguageContext);
  const { dunningListFilterState, setDunningListFilterState } = useDunningListContext('useDunningListFilters');

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setDunningListFilterState({ ...searchParams });
  };


  const setFilter = (key: string, value: string) => setDunningListFilterState(
    (currentFilter: any) => {
      const newFilter = { ...currentFilter };
      if (value === undefined || value === null) {
        // @ts-ignore
        delete newFilter[key];
      } else {
        newFilter[key] = value;
      }
      return newFilter;
    },
  );


  const createNewDunningPageFilters: Filter[] = [
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.nonColumnFilters.propertyIdInternal),
      key: 'propertyIdInternal',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.outerTableColumns.property),
      key: 'propertyName',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.outerTableColumns.administrator),
      key: 'propertyAdministrator',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.outerTableColumns.accountant),
      key: 'propertyAccountant',
    },
    {
      type: 'date',
      name: `${tl(orderOpenBalancesTranslations.dunningList.tableColumns.executionDate)} min`,
      key: 'minExecutionDate',
    },
    {
      type: 'date',
      name: `${tl(orderOpenBalancesTranslations.dunningList.tableColumns.executionDate)} max`,
      key: 'maxExecutionDate',
    },
    {
      type: 'enum',
      name: tl(orderOpenBalancesTranslations.dunningList.tableColumns.state.label),
      options: Object.keys(DunningProjectionStateEnum).map(value => ({
        label: tl(orderOpenBalancesTranslations.dunningList.tableColumns.state[value]),
        value,
      })),
      multiValue: true,
      key: 'states',
    },
    {
      type: 'number',
      name: `${tl(orderOpenBalancesTranslations.dunningList.tableColumns.reference)}`,
      key: 'referenceId',
    },
    {
      type: 'enum',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.propertyAdministrationTypes)}`,
      key: 'propertyAdministrationTypes',
      options: Object.keys(ListDunningDtoUsingGETPropertyAdministrationTypesEnum).map(value => ({
        label: tl(orderOpenBalancesTranslations.nonColumnFilters.administrationTypeValues[value]),
        value,
      })),
      multiValue: true,
      visibleAllOptions: true,
    },
  ];

  return {
    dunningListFilterState,
    setFilter,
    onSetDefaultFilterFromQueryParams,
    createNewDunningPageFilters,
  };
}
