import { useContext, useEffect, useMemo } from 'react';
import { useLoadPropertyDistributionKeyList } from 'pages/PropertyDistributionKeys/services/useLoadPropertyDistributionKeyList';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import { DISTRIBUTION_FOR_OPS_COST } from 'pages/DistributionKeys/AccountDistributionEditor/services/utils';
import { showNotification } from '../../../../lib/Notification';
import { translations } from '../../translations';
import { AuthContext } from '../../../../contexts/AuthContext';
import {
  AccountDistributionKeyControllerApi,
  AccountDistributionKeyCopyRequestDtoSourceEnum,
  AccountDistributionKeyCopyRequestDtoTargetEnum, AccountDistributionKeyTreeDto,
} from '../../../../api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { IndividualDistributionKeyCopyModalContext } from './context/IndividualDistributionKeyCopyModalContext';
import { PropertyDistributionKeyListContext } from '../../../../contexts/PropertyDistributionKeyListContext';

interface ModalProps {
    adkId: number,
    setModalVisible: Function,
}

export function useIndividualDistributionKeyCopyModal({
  adkId, setModalVisible,
}: ModalProps) {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const accountDistributionKeyControllerApi = new AccountDistributionKeyControllerApi(apiConfiguration('accounting'));
  const { propertyDistributionKeyListState, mapToLeafAccounts }: any = useContext(PropertyDistributionKeyListContext);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const distributionFor = urlSearchParams.get('distributionFor');
  const { onLoadPropertyDistributionKeys } = useLoadPropertyDistributionKeyList();
  const {
    sourceDocumentType,
    setSourceDocumentType,
    targetEPType,
    setTargetEPType,
    targetHMSType,
    setTargetHMSType,
    targetAllType,
    setTargetAllType,
    selectedAccounts,
    setSelectedAccounts,
    selectedYears,
    setSelectedYears,
  } = useContext(IndividualDistributionKeyCopyModalContext);

  const isDistributionForOpsCost = distributionFor === DISTRIBUTION_FOR_OPS_COST;

  const onCancel = () => {
    resetFields();
    setModalVisible(false);
  };

  useEffect(() => {
    resetFields();
  }, [isDistributionForOpsCost]);

  const resetFields = () => {
    setSelectedAccounts([]);
    setSelectedYears([]);

    if (isDistributionForOpsCost) {
      setTargetAllType(true);
      setTargetEPType(true);
      setTargetHMSType(true);
      setSourceDocumentType(AccountDistributionKeyCopyRequestDtoSourceEnum.ALL);
      return;
    }

    if (targetEPType) {
      setTargetEPType(false);
    }

    if (targetHMSType) {
      setTargetHMSType(false);
    }
  };

  const onOk = () => {
    accountDistributionKeyControllerApi.copyAccountDistributionKeyUsingPOST({
      adkId,
      requestDto: {
        accountCodes: selectedAccounts,
        source: sourceDocumentType,
        target: [
          ...(targetEPType ? [AccountDistributionKeyCopyRequestDtoTargetEnum.EP] : []),
          ...(targetHMSType ? [AccountDistributionKeyCopyRequestDtoTargetEnum.HMS] : []),
          ...(targetAllType ? [AccountDistributionKeyCopyRequestDtoTargetEnum.ALL] : []),
        ],
        years: selectedYears.map((year: string) => parseInt(year, 10)),
      },
    })
      .then(() => {
        showNotification({
          key: 'copySuccess',
          message: tl(translations.propertyDistributionKey.copySuccess),
          type: 'success',
        });
        resetFields();
        onLoadPropertyDistributionKeys();
      })
      .catch(() => {
        showNotification({
          key: 'copyError',
          message: tl(translations.propertyDistributionKey.copyError),
          type: 'error',
        });
      })
      .finally(() => {
        setModalVisible(false);
      });
  };

  const destinationAccounts = useMemo(() => {
    const propertyDistributionKeyList = propertyDistributionKeyListState.data;
    if (isEmpty(propertyDistributionKeyList)) {
      return [];
    }

    const accountCodes = propertyDistributionKeyList?.income
      .concat(propertyDistributionKeyList.expenses)
      .concat(propertyDistributionKeyList.reserveFunds) ?? [];

    return accountCodes?.flatMap((account: any) => mapToLeafAccounts(account))
      .map((account: AccountDistributionKeyTreeDto) => ({
        value: account.accountCode,
        label: [account.accountCode, ' ', account.accountName].join(''),
      }));
  }, [propertyDistributionKeyListState.data]);

  return {
    onOk,
    onCancel,
    sourceDocumentType,
    setSourceDocumentType,
    targetEPType,
    setTargetEPType,
    targetHMSType,
    setTargetHMSType,
    targetAllType,
    setTargetAllType,
    selectedAccounts,
    setSelectedAccounts,
    selectedYears,
    setSelectedYears,
    destinationAccounts,
    isDistributionForOpsCost,
  };
}
