import { useContext } from 'react';
import { AllContractsListContext } from './AllContractsListContext';

export const useInvalidateAllContractsListCache = () => {
  const allContractsListContext = useContext(AllContractsListContext);

  if (allContractsListContext === undefined) {
    throw new Error('useInvalidateAllContractsListCache must be used within a ContractsListContextProvider');
  }

  const { setParamOfCachedValue } = allContractsListContext;

  const invalidateCache = () => {
    setParamOfCachedValue(undefined);
  };

  return { invalidateCache };
};
