import React, { useContext, useEffect } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Alert } from 'antd';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from './translations';
import { loadCsv } from '../services/loadCsv';
import { useImportBankAccountsCsv } from './useBankAccountsImportCsv';
import { FileUploadButton } from '../components/FileUploadButton/FileUploadButton';
import { showNotification } from '../../../../lib/Notification';
import { PropertyImportDto } from '../PropertiesImportSection/interfaces';
import { BankAccountImportDto } from './interfaces';

export const BankAccountsImportSection = ({ properties, onLoaded }: { properties: PropertyImportDto[], onLoaded: (data: BankAccountImportDto[]) => void }) => {
  const { tl } = useContext(LanguageContext);
  const { loadBankAccountsFromCsvRows, bankAccounts, bankAccountsValidationIssues } = useImportBankAccountsCsv();
  useEffect(() => { onLoaded(bankAccounts); }, [bankAccounts]);
  return (

    <PrimarySectionWrapper
      sectionNumber={3}
      sectionTitle={tl(translations.title)}
      sectionId="hms-close-year-account-selection"
    >
      <div className="row">
        <div className="col-xs-12">
          <FileUploadButton
            label={tl(translations.upload)}
            accept="text/csv"
            onChange={event => loadCsv(event.target.files, 0)
              .then(res => loadBankAccountsFromCsvRows(res, properties))
              .catch((err) => { showNotification({ message: tl(translations.validations.errors) }); console.error('Error while loading csv', err); })}
          />
        </div>
      </div>
      {bankAccounts.length > 0
        ? <Alert message={`${bankAccounts.length} ${tl(translations.validations.success)}`} type="success" />
        : <Alert message={tl(translations.description)} />
      }
      {bankAccountsValidationIssues.length > 0 && (
        <Alert
          message={tl(translations.validations.errors)}
          type="error"
          description={(
            <ul>
              {' '}
              {
                bankAccountsValidationIssues.map(issue => (
                  <li key={issue.row + issue.message}>
                    {`${issue.row + 1}: ${issue.message}`}
                  </li>
                ))
              }
            </ul>
          )}
        />

      )}
    </PrimarySectionWrapper>
  );
};
