import React, { useContext } from 'react';
import { OpsCostReportListContext } from './OpsCostReportListContext';

export const useOpsCostReportListSort = () => {
  const opsCostReportListContext = useContext(OpsCostReportListContext);
  if (opsCostReportListContext === undefined) {
    throw new Error('useOpsCostReportListSort must be used within a OpsCostReportListContextProvider');
  }

  const { setSortState, sortState } = opsCostReportListContext;

  const onChangeSort = (field: string) => {
    setSortState((currentState) => {
      const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
      return {
        field,
        order,
      };
    });
  };

  return {
    sortField: sortState.field,
    sortOrder: sortState.order,
    onChangeSort,
  };
};
