import React from 'react';

type HeadingProps = {
  level?: 1 | 2 | 3,
  children: React.ReactNode,
  className?: string,
}


/**
 * Styling is in src/styles/_typography.scss
 */

const Heading = ({ level, children, className: classNameProp }: HeadingProps) => {
  const className = classNameProp ?? '';


  if (level === 1) return <h1 className={className}>{children}</h1>;

  if (level === 2) return <h2 className={className}>{children}</h2>;

  // level === 3
  return <h3 className={className}>{children}</h3>;
};


Heading.defaultProps = {
  level: 1,
  className: undefined,
};


export default Heading;
