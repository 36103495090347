import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { useMemo, useState } from 'react';
import { useOrderOpenBalancesContext } from '../../services/OrderOpenBalancesContext';

export const useDunningFeeCell = (accountCode:string, propertyId: number, contractDunningLevel?: ContractProjectionDtoDunningLevelEnum) => {
  const {
    dunningFees,
  } = useOrderOpenBalancesContext('useOrderOpenBalancesList');

  const [modalVisible, setModalVisible] = useState(false);

  const dunningFeeLocalValue = useMemo(
    () => dunningFees?.find(dunningFee => (dunningFee.propertyId === propertyId && dunningFee.accountCode === accountCode),
  ), [accountCode, propertyId, accountCode, dunningFees]);

  const onOpenModal = () => {
    setModalVisible(true);
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  const disabled = contractDunningLevel && contractDunningLevel === ContractProjectionDtoDunningLevelEnum.EXCLUDED;

  return {
    onOpenModal,
    dunningFeeLocalValue,
    modalVisible,
    onCancel,
    disabled,
  };
};
