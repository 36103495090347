import { useContext } from 'react';
import { ContactPropertiesActionsMenu } from '../components/ContactPropertiesActionsMenu/ContactPropertiesActionsMenu';
import { contactPropertiesTranslations } from '../translation';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import StatusCell from '../components/StatusCell';
import ContactPropertiesNameCell from '../components/ContactPropertiesNameCell/ContactPropertiesNameCell';
import ContactPropertiesAmountCell from '../components/ContactPropertiesAmountCell/ContactPropertiesAmountCell';
import ContactPropertiesDunningLevelCell from '../components/ContactPropertiesDunningLevelCell';
import ContactPropertiesRoleCell from '../components/ContactPropertiesRoleCell';


export const useContactPropertiesColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(contactPropertiesTranslations.propertyTable.columns.property),
      width: '40%',
      className: 'column-padding-medium',
      dataIndex: 'name',
      render: (_, record) => (
        <ContactPropertiesNameCell record={record} />
      ),
    },
    {
      title: tl(contactPropertiesTranslations.propertyTable.columns.role),
      dataIndex: 'role',
      width: '10%',
      render: (_, record) => <ContactPropertiesRoleCell record={record} />,
    },
    {
      title: tl(contactPropertiesTranslations.propertyTable.columns.accountBalance),
      width: '15%',
      dataIndex: 'accountBalance',
      render: (_, record) => <ContactPropertiesAmountCell record={record} />,
      className: 'column-align-right',
    },
    {
      dataIndex: 'dunningLevel',
      render: (_, record) => <ContactPropertiesDunningLevelCell record={record} />,
      width: '5%',
    },
    {
      title: tl(contactPropertiesTranslations.propertyTable.columns.status),
      dataIndex: 'status',
      width: '25%',
      render: (_, record) => (
        <StatusCell record={record} />
      ),
    },
    {
      dataIndex: 'optionsMenu',
      render: (_, record) => (<ContactPropertiesActionsMenu record={record} />),
    },
  ];
};
