import React from 'react';
import { Space } from 'antd';
import './SectionSummary.scss';
import { SPACE_SIZES } from 'lib/constants';
import { SectionSummaryProps } from './services/interfaces';
import SectionSummaryContent from './components/SectionSummaryContent';


const SectionSummary = ({ content }: SectionSummaryProps) => (
  <Space size={SPACE_SIZES.medium} className="SectionSummary">
    {content.map((item, idx) => (
      <SectionSummaryContent item={item} key={idx}/>
    ))}
  </Space>
);


export default SectionSummary;
