import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';


export const useGetSimulationOfTheYearlyProfitAndLoss = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useGetSimulationOfTheYearlyProfitAndLoss must be used within a ProfitAndLossReportContextProvider');
  }

  const { simulationAccountBalances } = profitAndLossReportContext;


  const simulationOfTheYearlyProfitAndLoss = useMemo(() => {
    if (!simulationAccountBalances.loaded) return undefined;

    const leafAccounts = Object.keys(simulationAccountBalances.data)
      .filter(accCode => simulationAccountBalances.data[accCode].leaf)
      .map(code => simulationAccountBalances.data[code]);

    const sumFor6xAccounts = leafAccounts
      .filter(account => account.accountCode.startsWith('6'))
      .reduce((acc, curr) => (acc + curr.normalizedBalanceAtEndOfDateRange), 0);

    const sumFor8xAccounts = leafAccounts
      .filter(account => account.accountCode.startsWith('8'))
      .reduce((acc, curr) => (acc + curr.normalizedBalanceAtEndOfDateRange), 0);


    return sumFor6xAccounts - sumFor8xAccounts;
  }, [simulationAccountBalances.data]);


  return {
    simulationOfTheYearlyProfitAndLoss,
  };
};
