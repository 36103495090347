export const translations = {
  uploadCamtXml: {
    en: 'Upload CAMT XML',
    de: 'CAMT XML Hochladen',
  },
  columns: {
    status: {
      en: 'Status of CAMT imports',
      de: 'Status der CAMT-Importe',
    },
    file: {
      en: 'XML Files',
      de: 'XML Dateien',
    },
    numberOfTransactions: {
      en: 'Number of transactions',
      de: 'Anzahl der Transaktionen',
    },
    totalAmount: {
      en: 'Total amount',
      de: 'Gesamtsumme',
    },
    created: {
      en: 'Import date',
      de: 'Importdatum',
    },
  },
  status: {
    PROCESSED: {
      en: 'Processed',
      de: 'Verarbeitet',
    },
    FAILED: {
      en: 'Failed',
      de: 'Gescheitert',
    },
    IN_PROGRESS: {
      en: 'Loading...',
      de: 'Lädt...',
    },
    WARNING: {
      en: 'Warning',
      de: 'Warnung',
    },
  },
  fileUploadModal: {
    title: {
      en: 'Import transactions',
      de: 'Transaktionen importieren',
    },
    upload: {
      en: 'Upload xml',
      de: 'Hochladen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    body: {
      en: 'By uploading the file, the transactions will be matched. Are you sure you want to continue?',
      de: 'Durch das Hochladen der Datei werden die Transaktionen importiert und automatisch abgeglichen. Dies kann nicht rückgängig gemacht werden. Bitte stellen Sie sicher, dass die korrekten Dateien importiert werden.',
    },
  },
  notifications: {
    transactionImportLoadError: {
      message: {
        en: 'Failed loading transaction imports',
        de: 'Fehler beim Laden der Transaktionsimporte',
      },
    },
    bankListImportLoadError: {
      message: {
        en: 'Failed loading bank list',
        de: 'Fehler beim Laden der Bankliste',
      },
    },
    finApiAuthError: {
      message: {
        en: 'Authentication failed',
        de: 'Authentifizierung fehlgeschlagen',
      },
    },
    uploadError: {
      message: {
        en: 'Upload failed',
        de: 'Upload fehlgeschlagen',
      },
    },
    uploadSuccess: {
      message: {
        en: 'files were uploaded, they are currently processed.',
        de: 'Dateien wurden hochgeladen, sie werden derzeit verarbeitet',
      },
    },
    downloadError: {
      message: {
        en: 'Download failed',
        de: 'Download fehlgeschlagen',
      },
    },
  },
};
