import { AmountToDistributeInputValuesContext, TableDataSourceType } from 'pages/ManualExchange/services/ManualExchangeContext';
import { useContext } from 'react';

export const useAmountToDistributeCell = (record: TableDataSourceType) => {
  const distributionValues = useContext(AmountToDistributeInputValuesContext);

  if (distributionValues === undefined) return { amount: undefined };

  const amount = distributionValues.find(dv => dv.unitContractId === record.unitContractId)?.amount;

  return { amount };
};
