import { ReactNode } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { DocumentDtoSourceTypeEnum } from 'api/public';

export interface TicketIntegrationSectionProps {
  portal: INTEGRATION_APPS,
  datasource: ExternalPropertyIssue[],
  columns: ColumnsType<ExternalPropertyIssue>,
  loading,
  onChangeSearchTerm: (term: string) => void,
  suffix?: ReactNode,
  errorMessage: string,
  comment?: string,
  setComment?: (string) => void,
  onCancelComment?: () => void,
  onCancelUnassign?: () => void,
  onCommentClickOk?: () => void,
  onUnassignClickOk?: () => void,
  commentModalVisible?: boolean,
  unassignModalVisible?: boolean,
}

export interface ExternalPropertyIssue {
    id: number | string,
    number?: string,
    type?: string,
    state?: string,
    contactName?: string,
    name: string,
    description: string,
    url: string,
    assigned?: boolean,
    searchValue: string,
    relevanceScore: number,
  }

export enum INTEGRATION_APPS {
    CASAVI = 'casavi',
    FACILIOO = 'facilioo',
    ETG24 = 'etg24',
  }

export interface PortalDocumentProps {
    entityId?: number,
    sourceId?: number,
    sourceType?: DocumentDtoSourceTypeEnum
    fileName: string,
}

export enum TICKET_COLUMN_TYPE {
  'PROPERTY', 'CONTACT', 'DOCUMENT', 'INVOICE'
}

export interface TicketColumnProps {
  type: TICKET_COLUMN_TYPE,
  isTicketAssigned?: boolean,
  showCommentModal?: (id:number) => void,
  showUnassignModal?: () => void,
}

export const APPS_REQUIRING_HTML = [INTEGRATION_APPS.FACILIOO, INTEGRATION_APPS.ETG24];
