import React from 'react';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { Distribution, TableDataSourceType } from 'pages/ManualExchange/services/ManualExchangeContext';
import { useProcessingSelectorOptions } from 'pages/ManualExchange/services/useProcessingSelectorOptions';


interface Props {
  record: TableDataSourceType;
  onChange: (unitAccountCode: string, field: keyof Distribution, value: string | number, record: TableDataSourceType) => void
}


const getProcessingDefaultValue = (record: TableDataSourceType) => {
  // we are assuming that the default direction is `incoming`
  if (record.hasMandateAtDate) return 'PLATFORM';
  return 'SELF_PAYER';
};


const ProcessingSelector = ({ record, onChange }: Props) => {
  const { SELECT_OPTIONS, value } = useProcessingSelectorOptions(record);

  return (
    <SelectInput
      options={SELECT_OPTIONS}
      value={value}
      disabled={SELECT_OPTIONS.length < 2 || !record.selected}
      onChange={val => onChange(record.accountCode!, 'processing', val, record)}
      defaultValue={getProcessingDefaultValue(record)}
    />
  );
};

export default ProcessingSelector;
