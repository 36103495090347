import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  BankConnection,
  BankConnectionImportDtoConnectionInterfaceEnum,
  FinApiBankConnectionControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import DEFAULT_DATA from 'lib/data';
import { showNotification } from 'lib/Notification';
import { Action } from 'elements/SmartTable/data';
import moment from 'moment';
import { FinApiBankAccount, useFinApiBankAccounts } from './useFinApiBankAccounts';
import { translations } from './translations';

export default function useBankConnections() {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const bankConnectionControllerApi = new FinApiBankConnectionControllerApi(apiConfiguration('accounting'));

  const [bankConnections, setBankConnections] = useState(DEFAULT_DATA<BankConnection[]>([]));
  const [updatingBankConnection, setUpdatingBankConnection] = useState(false);
  const [selectedBankConnection, setSelectedBankConnection] = useState<BankConnection>(null);

  useEffect(() => {
    if (selectedBankConnection == null) {
      loadBankConnections();
    }
  }, [selectedBankConnection]);


  const loadBankConnections = async () => {
    setBankConnections(state => state.startLoading());
    try {
      const response = await bankConnectionControllerApi.getConnectionsUsingGET();
      setBankConnections(state => state.load(response));
    } catch (error) {
      setBankConnections(state => state.failed());
    }
  };

  const updateBankConnection = async (bankConnectionId: number, importNewAccounts: boolean) => {
    try {
      setUpdatingBankConnection(true);
      const response = await bankConnectionControllerApi.updateConnectionUsingPOST({
        bankConnectionId,
        importNewAccounts,
      });
      if (response.status === 'COMPLETED') {
        /* this COMPLETED status is FAKED by the BE to signal no need for FinAPI redirect */
        showNotification({
          message: tl(translations.bankConnectionActions.useBankConnections.updateSuccess.message),
          type: 'success',
        });
      } else {
        // we always expect the redirect flow
        const queryParams = `${window.location.search}`;
        const redirectLocation = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${window.location.pathname}${queryParams}${window.location.hash}`;
        window.location.href = `${response.url}&redirectUrl=${encodeURIComponent(`${redirectLocation}`)}`;
      }
    } catch (error) {
      showNotification({
        key: 'updateBankConnectionFormError',
        message: `${tl(translations.bankConnectionActions.useBankConnections.updateError.message)} Details: (${error})`,
        type: 'error',
      });
    } finally {
      setUpdatingBankConnection(false);
    }
  };
  const importBankConnection = async (managementCompanyId?: number, bankId?: number, name?: string,
    connectionInterface: BankConnectionImportDtoConnectionInterfaceEnum = BankConnectionImportDtoConnectionInterfaceEnum.XS2A) => {
    if (!managementCompanyId || !bankId || !name) {
      showNotification({
        key: 'addBankConnectionFormError',
        message: tl(translations.bankConnectionActions.useBankConnections.addError.message),
        type: 'error',
      });
      return;
    }
    try {
      const response = await bankConnectionControllerApi.importConnectionUsingPOST({
        importRequest: {
          bankId,
          name,
          managementCompanyId,
          connectionInterface,
        },
      });
      // we always expect the redirect flow
      window.location.href = `${response.url}&redirectUrl=${encodeURIComponent(window.location.href)}`;
    } catch (error) {
      showNotification({
        key: 'addBankConnectionFormError',
        message: tl(translations.bankConnectionActions.useBankConnections.addError.message),
        type: 'error',
      });
    }
  };
  const { getBankAccounts } = useFinApiBankAccounts();
  const downloadAccountListAsCsv = async (record:BankConnection) => {
    try {
      const accounts:FinApiBankAccount[] = await getBankAccounts(record.id);
      const rows = accounts.map(a => [
        a.accountName,
        a.iban,
        a.accountNumber,
        a.accountHolderName,
        a.balance,
        a.overdraft,
        a.overdraftLimit,
        a.availableFunds,
      ]);
      const csvContent = `data:text/csv;charset=utf-8,${
        rows.map(e => e.join(',')).join('\n')}`;
      const downloadCsvUrl = encodeURI(csvContent);
      const link = document.createElement('a');
      link.download = `${moment().format('YYYY-MM-DD')}_FinAPI_Konten_${record.name}.csv`;
      link.href = downloadCsvUrl;
      link.click();
    } catch (e) {
      showNotification({
        key: 'cannot-download-csv',
        message: tl(translations.cannotDownloadAccountsCsv),
        type: 'error',
      });
    }
  };
  const listActions:Action[] = [{
    label: tl(translations.listActions.downloadDetectedAccounts),
    onAction: downloadAccountListAsCsv,
  },
  ];
  const finApiEnabled = bankConnections.error !== true && (
    bankConnections.data?.length
      || bankConnections.loaded
  );

  return {
    finApiEnabled,
    bankConnections,
    listActions,
    updatingBankConnection,
    selectedBankConnection,
    setSelectedBankConnection,
    loadBankConnections,
    updateBankConnection,
    importBankConnection,
  };
}
