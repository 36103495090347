import React, { useContext } from 'react';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { TabLink } from 'storybook-components/TabLink/TabLink';
import './BankConnections.scss';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import SmartTable from 'elements/SmartTable/SmartTable';
import { LanguageContext } from 'contexts/LanguageContext';
import Page from 'storybook-components/layout/Page/Page';
import { EmptyResultsHint } from 'components/EmptyResults/EmptyResultsHint';
import Button from 'elements/Buttons/Button/Button';
import { useTransactionImportsSectionLinks } from '../services/useTransactionImportsSectionLinks';
import useBankConnections from './services/useBankConnections';
import { useBankConnectionColumns } from './services/useBankConnectionColumns';
import { BankConnectionActions } from './BankConnectionActions/BankConnectionActions';
import BankConnectionUpdateModal from './BankConnectionActions/components/BankConnectionUpdateModal';
import { translations } from './services/translations';

export const BankConnections: React.FC = () => {
  const { tl } = useContext(LanguageContext);
  const {
    bankConnections, finApiEnabled, selectedBankConnection, setSelectedBankConnection, listActions,
  } = useBankConnections();

  const columns = useBankConnectionColumns();

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'transactionImports',
    navItems: subcategorySwitcherItems.accounting,
  };

  const smartTable = useSmartTable({
    tableName: 'bankConnections',
    columns,
    dataSource: bankConnections.data,
    contentLoading: bankConnections.loading,
    virtualize: false,
    propSort: {
      field: 'name',
      order: 1,
      onSortChange: () => { },
    },
    onRow: bankConnection => ({
      onClick: () => setSelectedBankConnection(bankConnection),
    }),
    rowKey: 'id',
    actionsMenu: listActions,
  });


  const { transactionImportSectionLinks } = useTransactionImportsSectionLinks();
  return (
    <Page>
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={<BankConnectionActions finApiEnabled={finApiEnabled} />}
      />
      <TabLink links={transactionImportSectionLinks} />
      {finApiEnabled
        ? <SmartTable {...smartTable} />
        : (!bankConnections.loading
            && (
              <EmptyResultsHint
                icon={null}
                title={tl(translations.enableFinAPI.title)}
                subTitle={tl(translations.enableFinAPI.description)}
              >
                <Button href="mailto:support@impower.de">{tl(translations.enableFinAPI.contactSupportAction)}</Button>
              </EmptyResultsHint>
            )
        )}
      <BankConnectionUpdateModal
        selectedBankConnection={selectedBankConnection}
        onClose={() => setSelectedBankConnection(null)}
      />
    </Page>
  );
};
