import React, { useContext } from 'react';
import { Col, Modal, Row } from 'antd';
import { SPACE_SIZES } from 'lib/constants';
import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import { LanguageContext } from 'contexts/LanguageContext';
import { PercentageInput } from 'elements/Inputs/PercentageInput/PercentageInput';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { Link } from 'react-router-dom';
import { translations } from '../../../../../../../../translations';
import { useVatEligibilityModal } from './useVatEligibilityModal';

interface Props {
  recordKey: string;
  visible: boolean;
  onClose: () => void;
}

const VatEligibilityModal = ({ recordKey, onClose, visible }: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    vatEligibilityModal,
  } = translations.bankTransactionAllocation.serviceCompanyAllocation;

  const {
    vatEligibilityAmount,
    vatEligibilityPercentage,
    onChangeVatEligibilityPercentage,
    onApply,
    onCancel,
  } = useVatEligibilityModal(recordKey, onClose);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onApply}
      destroyOnClose
      title={tl(vatEligibilityModal.title)}
      okText={tl(vatEligibilityModal.apply)}
      okButtonProps={{ className: 'Button' }}
      cancelButtonProps={{ className: 'Button' }}
    >
      <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.large]}>
        <Col span={24}>
          <PercentageInput
            autoFocus
            label={tl(vatEligibilityModal.vatEligibilityPercentage)}
            onChange={onChangeVatEligibilityPercentage}
            value={vatEligibilityPercentage}
            infoText={(
              <div>
                {tl(vatEligibilityModal.vatEligibilityTooltip)}
                <Link target="_blank" to={{ pathname: articleUrls.vatEligibility }}>
                  {tl(vatEligibilityModal.vatEligibilityTooltipLink)}
                </Link>
              </div>
            )}
          />
        </Col>
        <Col span={24}>
          <CurrencyInput
            label={tl(vatEligibilityModal.vatEligibilityAmount)}
            value={vatEligibilityAmount}
            min={0}
            onChange={() => {}}
            disabled
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default VatEligibilityModal;
