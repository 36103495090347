export const contractSelectorTranslations = {
  unitId: {
    en: 'Unit-ID',
    de: 'Einheit-Nr.',
  },
  incompleteInformation: {
    en: 'Incomplete information',
    de: 'Unvollständige Informationen',
  },
  vacantName: {
    en: 'Vacancy',
    de: 'Leerstand',
  },
  contractType: {
    TENANT: {
      en: 'Tenant',
      de: 'Mieter',
    },
    OWNER: {
      en: 'Owner',
      de: 'Eigentümer',
    },
    PROPERTY_OWNER: {
      en: 'Property owner',
      de: 'Objekteigentümer',
    },
    ALL: {
      en: 'Contract',
      de: 'Vertrag',
    },
  },
  searchPlaceholder: {
    en: 'Search',
    de: 'Suche',
  },
  noSearchData: {
    title: {
      en: 'No search results...',
      de: 'Keine Suchergebnisse...',
    },
    subtitle: {
      en: 'Please search for another contract',
      de: 'Bitte suchen Sie nach einem anderen Vertrag',
    },
  },
  noDataAvailable: {
    title: {
      en: 'No data',
      de: 'Keine Daten',
    },
  },
  loadContractError: {
    en: 'Failed to load contracts.',
    de: 'Verträge konnten nicht geladen werden.',
  },
};
