import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';
import { EmptyState } from 'storybook-components/feedback/EmptyState/EmptyState';
import { translations } from '../../../translations';

interface Props {
    noData: boolean,
}

export const EmptyContractDropdownComponent = ({ noData }:Props) => {
  const { tl } = useContext(LanguageContext);

  return (
    <EmptyState
      className="empty-results-contract-selector"
      icon={<Icon component={ICONS.file} />}
      title={noData ? tl(translations.actionBar.noData.noDataAvailable.title) : tl(translations.actionBar.noData.noSearchData.title)}
      subTitle={noData ? '' : tl(translations.actionBar.noData.noSearchData.subtitle)}
    />
  );
};
