import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { NEGATIVE_DECIMAL_MATCHER } from 'lib/Utils';
import moment from 'moment';
import { GetContractAccountBalancesUsingGETPropertyAdministrationTypesEnum } from 'api/accounting';
import { orderOpenBalancesTranslations } from '../../translations';
import { useOrderOpenBalancesContext } from './OrderOpenBalancesContext';


const TODAY = moment();


export default function useOrderOpenbalanceFilters(): any {
  const { tl } = useContext(LanguageContext);
  const { orderOpenbalanceFilterState, setOrderOpenbalanceFilterState } = useOrderOpenBalancesContext('useOrderOpenBalancesList');

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setOrderOpenbalanceFilterState({ ...searchParams });
  };


  const createNewDunningPageFilters: Filter[] = [
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.nonColumnFilters.propertyIdInternal),
      key: 'propertyIdInternal',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.outerTableColumns.property),
      key: 'propertyName',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.outerTableColumns.accountant),
      key: 'propertyAccountant',
    },
    {
      type: 'decimal',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.openAccountAmount)} min.`,
      key: 'contractBalanceMin',
      matcher: NEGATIVE_DECIMAL_MATCHER,
    },
    {
      type: 'date',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.postDate)}`,
      key: 'balanceUntil',
      maxDate: TODAY,
    },
    {
      type: 'enum',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.onlyActiveContracts)}`,
      options: [{
        label: tl(orderOpenBalancesTranslations.nonColumnFilters.onlyActiveContractsValue.active),
        value: 'true',
      },
      {
        label: tl(orderOpenBalancesTranslations.nonColumnFilters.onlyActiveContractsValue.all),
        value: 'false',
      }],
      key: 'onlyActiveContracts',
    },
    {
      type: 'date',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.lastDunningMessageExecutedUntil)}`,
      key: 'lastDunningMessageExecutedUntil',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.innerTableColumns.accountName),
      key: 'accountName',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.innerTableColumns.accountNr),
      key: 'accountCode',
    },
    {
      type: 'text',
      name: tl(orderOpenBalancesTranslations.outerTableColumns.administrator),
      key: 'propertyAdministrator',
    },
    {
      type: 'decimal',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.openPropertyAmount)} min.`,
      key: 'propertyBalanceMin',
      matcher: NEGATIVE_DECIMAL_MATCHER,
    },
    {
      type: 'decimal',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.openPropertyAmount)} max.`,
      key: 'propertyBalanceMax',
      matcher: NEGATIVE_DECIMAL_MATCHER,
    },
    {
      type: 'decimal',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.openAccountAmount)} max.`,
      key: 'contractBalanceMax',
      matcher: NEGATIVE_DECIMAL_MATCHER,
    },
    {
      type: 'enum',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.onlyActiveMandates)}`,
      options: [{
        label: tl(orderOpenBalancesTranslations.nonColumnFilters.onlyActiveMandatesValue.active),
        value: 'true',
      },
      {
        label: tl(orderOpenBalancesTranslations.nonColumnFilters.onlyActiveMandatesValue.all),
        value: 'false',
      }],
      key: 'onlyActiveMandates',
    },
    {
      type: 'enum',
      name: `${tl(orderOpenBalancesTranslations.nonColumnFilters.propertyAdministrationTypes)}`,
      key: 'propertyAdministrationTypes',
      options: Object.keys(GetContractAccountBalancesUsingGETPropertyAdministrationTypesEnum).map(value => ({
        label: tl(orderOpenBalancesTranslations.nonColumnFilters.administrationTypeValues[value]),
        value,
      })),
      multiValue: true,
      visibleAllOptions: true,
    },
  ];

  return {
    orderOpenbalanceFilterState,
    setOrderOpenbalanceFilterState,
    onSetDefaultFilterFromQueryParams,
    createNewDunningPageFilters,
  };
}
