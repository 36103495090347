import { useState } from 'react';

interface useFileUploadModalProps {
  onUpload: Function
  body: string
  upload: string
  title: string
  name: string,
  accept?: string,
  multiple?: boolean,
}

export function useFileUploadModal(props: useFileUploadModalProps) {
  const {
    onUpload, body, upload, title, name, accept, multiple,
  } = props;
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleUpload = (event: any) => {
    setVisible(false);
    onUpload(event);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return {
    name,
    visible,
    showModal,
    handleUpload,
    handleCancel,
    body,
    upload,
    title,
    accept,
    multiple,
  };
}
