import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import {
  Column,
  FORMAT,
} from 'elements/SmartTable/SmartTable';
import { translations } from 'elements/Translation/translations';

import { Tooltip } from 'antd';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import {
  AccountBalanceAmountCell,
} from './components/AccountBalanceAmountCell';

export const UseAccountBalancesListColumns = (): (Column)[] => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: '',
      children: [
        {
          title: tl(translations.pages.property.table.headers.property),
          dataKey: 'propertyIdInternal',
          defaultWidth: 200,
          render: (_, record) => (
            <Tooltip title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
              {/* without this div the Tooltip doesn't work */}
              <div>
                <CellWithTwoLines
                  firstElement={record.name || '—'}
                  secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
                />
              </div>
            </Tooltip>
          ),
        },
        {
          title: tl(translations.pages.accountBalances.table.headers.accountant),
          dataKey: 'accountantName',
          defaultWidth: 120,
          render: accountantName => accountantName || '—',
        },
        {
          title: tl(translations.pages.accountBalances.table.headers.supervisor),
          dataKey: 'supervisorName',
          defaultWidth: 120,
          render: supervisorName => supervisorName || '—',
        },
      ],
    },
    {
      title: tl(translations.pages.accountBalances.table.headers.currentYear),
      children: [
        {
          title: tl(translations.pages.accountBalances.table.headers.income),
          format: FORMAT.CURRENCY,
          dataKey: 'incomeBalance',
          render: (text:string, row: any) => <AccountBalanceAmountCell amount={row.incomeBalance} />,
          defaultWidth: 100,
        },
        {
          title: tl(translations.pages.accountBalances.table.headers.expense),
          format: FORMAT.CURRENCY,
          dataKey: 'expenseBalance',
          render: (text:string, row: any) => <AccountBalanceAmountCell amount={row.expenseBalance} />,
          defaultWidth: 100,
        },
      ],
    },
    {
      title: tl(translations.pages.accountBalances.table.headers.reserve),
      format: FORMAT.CURRENCY,
      dataKey: 'reserveBalance',
      render: (text:string, row: any) => <AccountBalanceAmountCell amount={row.reserveBalance} />,
      defaultWidth: 100,
    },
    {
      title: tl(translations.pages.accountBalances.table.headers.contractBalance),
      format: FORMAT.CURRENCY,
      dataKey: 'contractAccountsBalance',
      render: (text:string, row: any) => <AccountBalanceAmountCell amount={row.contractAccountsBalance} />,
      defaultWidth: 100,
    },
  ];
};
