import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import _ from 'lodash';
import TextInput from '../TextInput/TextInput';
import { ValidateStatuses } from '../../../lib/Utils';
import NoData from './NoData';

interface DropdownTextInputProps {
  options?: MenuOption[]
  disabled?: boolean,
  onChange?: (val: any) => void,
  validationState?: (typeof ValidateStatuses)[number]
  validationMessage?: string
  label?: string,
  value?: string,
}

export interface MenuOption {
  value: any,
  label: string,
  entity?: any,
  filteredValue?: string,
  customBody?: JSX.Element
}

export default function DropdownTextInput(props: DropdownTextInputProps): JSX.Element {
  const {
    options, disabled, onChange, validationState, validationMessage, label, value,
  } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<MenuOption[] | undefined>();

  useEffect(() => {
    if (value) {
      setSearchValue(value);
    }
  }, [value]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);


  const onChangeSearchValue = (val: string) => {
    setSearchValue(val);
    if (onChange) {
      onChange(val);
    }
    // filter the options
    setFilteredOptions((oldOptions) => {
      try {
        if (_.isEmpty(val)) return options;
        return options!.filter(opt => (opt.filteredValue ? opt.filteredValue.includes(val.toLowerCase()) : opt.label.includes(val.toLowerCase())));
      } catch (e) {
        return oldOptions;
      }
    });
  };
  const menu = useMemo(() => (
    <Menu>
      {filteredOptions && filteredOptions.length > 0 ? filteredOptions.map(option => (
        <Menu.Item
          onClick={() => onChangeSearchValue(option.value)}
          key={option.value}
          title={option.label}
        >
          {option.customBody ? option.customBody : option.label}
        </Menu.Item>
      )) : (
        <NoData />
      )}
    </Menu>
  ), [filteredOptions]);

  return (
    <Dropdown
      className="text-input-dropdown"
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      disabled={disabled}
      getPopupContainer={(): HTMLElement => document.getElementsByClassName('dropdown-input-wrapper')[0] as HTMLElement}
    >
      <div className="dropdown-input-wrapper">
        <TextInput
          value={searchValue}
          onChange={onChangeSearchValue}
          onClick={() => setMenuVisible(!menuVisible)}
          validationMessage={validationMessage}
          validationState={validationState}
          label={label}
          disabled={disabled}
        />
      </div>
    </Dropdown>
  );
}
