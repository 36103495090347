import React from 'react';
import _ from 'lodash';
import { SearchSuggestion, SuggestedFilter } from '../../components/Header/components/SearchBar/services/GlobalSearchContext';

export const useSearchBarDropdown = ({
  visible, searchSuggestions, hideDropdown, onSelect, selectedSuggestionIndex, setSelectedSuggestionIndex,
}: {
  visible: boolean, searchSuggestions: SuggestedFilter[] | SearchSuggestion[],
  hideDropdown: () => void, onSelect: Function,
  selectedSuggestionIndex: number, setSelectedSuggestionIndex: Function
}) => {
  const handleUpArrowPressed = () => {
    setSelectedSuggestionIndex((currentSelectedSuggestionIndex: number) => {
      if (currentSelectedSuggestionIndex !== 0) {
        return currentSelectedSuggestionIndex - 1;
      }
      return searchSuggestions.length - 1;
    });
  };

  const handleDownArrowPressed = () => {
    setSelectedSuggestionIndex((currentSelectedSuggestionIndex: number) => {
      if (currentSelectedSuggestionIndex !== (searchSuggestions.length - 1)) {
        return currentSelectedSuggestionIndex + 1;
      }
      return 0;
    });
  };

  const eventHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (visible) {
      switch (e.keyCode) {
      case 38: // up arrow
        e.preventDefault();
        handleUpArrowPressed();
        break;
      case 40: // down arrow
        e.preventDefault();
        handleDownArrowPressed();
        break;
      case 13: // enter
        e.preventDefault();
        e.stopPropagation();
        if (!_.isNull(selectedSuggestionIndex)) {
          onSelect(searchSuggestions[selectedSuggestionIndex]);
        }
        break;
      case 27: // esc
        hideDropdown();
        break;
      default:
      }
    }
  };

  return {
    eventHandler,
  };
};
