import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { translations } from '../../../../../../elements/Translation/translations';
import { FORMAT } from '../../../../../../elements/SmartTable/SmartTable';
import { displayAccountInfo } from './usePropertyCardColumns';

export const useUnitAccountBalancesColumns = (isIncome?: boolean, isEconomicPlan?: boolean) => {
  const { tl } = useContext(LanguageContext);
  const { propertyHrId } = useParams<{propertyHrId:string}>();

  return [
    {
      title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.account),
      dataKey: 'account',
      className: 'account',
      defaultWidth: '30%',
      render: (text: any, row: any) => {
        if (row.accountCode) {
          return (
            <button
              onClick={(e: any) => displayAccountInfo(e, propertyHrId!, row.accountCode)}
              className="link"
            >
              {row.account}
            </button>
          );
        }
        return (<span>{row.account}</span>);
      },
    },
    {
      title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.distributionKey),
      dataKey: isEconomicPlan ? 'economicPlanDistributionMode' : 'houseMoneyDistributionMode',
      defaultWidth: '10%',
      render: (item: any) => (
        <span>{item ? tl(translations.enums.distributionMode[`${item}`]) : ''}</span>
      ),
    },
    {
      title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.settlementAmount),
      defaultWidth: '30%',
      children: [
        {
          title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.total),
          dataKey: 'newTotalShares',
          format: FORMAT.NUMBER,
          defaultWidth: '15%',
        },
        {
          title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.yourShare),
          dataKey: 'newUnitShares',
          format: FORMAT.NUMBER,
          defaultWidth: '15%',
        },
      ],
    },
    {
      title: isIncome ? tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.income) : tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.expenses),
      defaultWidth: '30%',
      children: [
        {
          title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.total),
          dataKey: 'newTotalAmount',
          format: FORMAT.CURRENCY_DASH,
          defaultWidth: '15%',
        },
        {
          title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.table.headers.yourShare),
          dataKey: 'newUnitAmount',
          format: FORMAT.CURRENCY_DASH,
          defaultWidth: '15%',
        }],
    },
  ];
};
