import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { useContext } from 'react';
import { translations as globalTranslations } from 'elements/Translation/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { DateType } from '@date-io/type';

export const usePaymentTargetInformation = () => {
  const { tl } = useContext(LanguageContext);
  const { data, validationErrors, updateInvoiceState } = useContext(InvoiceEditingContext);

  const isValidationError = (key: any): boolean => !!validationErrors && !!validationErrors[key];

  const getValidationMessage = (key: string) => {
    if (!validationErrors || !validationErrors[key]) {
      return '';
    }

    return tl(
      globalTranslations.validations[validationErrors.paymentTargetDate]
        || globalTranslations.validations.defaultError,
    );
  };

  const getValidationState = (key: string) => (isValidationError(key) ? 'error' : 'success');

  const onChangePaymentTargetDaysAndDate = (paymentTargetDays: number, paymentTargetDate: DateType) => {
    updateInvoiceState({
      invoice: {
        ...data.invoice,
        paymentTargetDate,
        paymentTargetDays,
      },
    });
  };

  return {
    data,
    onChangePaymentTargetDaysAndDate,
    getValidationMessage,
    getValidationState,
  };
};
