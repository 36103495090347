import React, { useContext } from 'react';
import { Configuration, DomainControllerApi } from 'api/user';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { ContactLegacyControllerApi, ContactLegacyDtoTypeEnum } from 'api/accounting';
import { translations } from './translations';
import './Register.scss';
import { showNotification } from '../../lib/Notification';

export const useRegister = () => {
  const authContext = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const registrationApi = new DomainControllerApi(authContext.apiConfiguration('user') as unknown as Configuration);

  const register = async (domain: string, email: string, password: string, acceptedTc: boolean) => {
    if (!acceptedTc) {
      showNotification({
        message: tl(translations.registrationForm.register.errors.notAcceptedTc),
        type: 'error',
      });
    } else {
      try {
        await registrationApi.registerDomainUsingPOST({ domainRegisterDto: { name: domain, email, password } });
        const { token } = await authContext.onLogin(email, password, domain);
        showNotification({
          message: tl(translations.registrationForm.register.success),
          type: 'success',
        });
        const contactControllerApi = new ContactLegacyControllerApi(authContext.apiConfiguration('accounting', token));
        await contactControllerApi.createContactUsingPOST({
          contact: {
            type: ContactLegacyDtoTypeEnum.MANAGEMENT_COMPANY,
            companyName: `Demo ${domain} GmbH`,
            addresses: [{
              recipient: `Demo ${domain} GmbH`,
              country: 'DE',
              city: 'Demo Ort',
              street: 'Demostraße',
              number: '0815',
              postalCode: '80815',
            }],
          },
        });
      } catch (e) {
        if (e?.response?.json) {
          const serverError = await e?.response.json();
          if (serverError.type && /[A-Z_]+$/.test(serverError.type)) {
            const errorCode = /[A-Z_]+$/.exec(serverError.type)[0];
            showNotification({
              message: tl(translations.registrationForm.register.errors.invalidData),
              description: tl(translations.registrationForm.register.errors.errorCodes[errorCode]),
              type: 'error',
            });
          } else if (serverError.title) {
            const description = serverError.description ? ` (${serverError.description})` : '';
            const cause = serverError.cause ? ` [${JSON.stringify(serverError.cause)}]` : '';
            showNotification({
              message: tl(translations.registrationForm.register.errors.invalidData),
              description: `Details: ${serverError.title}${description}${cause}`,
              type: 'error',
            });
          } else {
            showNotification({
              message: tl(translations.registrationForm.register.errors.invalidData),
              description: `Details: ${JSON.stringify(serverError)}`,
              type: 'error',
            });
          }
        } else {
          showNotification({
            message: tl(translations.registrationForm.register.errors.invalidData),
            description: `Details: ${JSON.stringify(e)}`,
            type: 'error',
          });
        }
      }
    }
  };
  return { register };
};
