/* eslint-disable no-plusplus */


// this is what is required to search
export interface SearchInputTypeRequiredProperties {
  id?: string | number,
  searchValue: string,
  children?: SearchInputTypeRequiredProperties[],
}

// the fields required for search + the fields of type T provided by user of function
export type SearchInputType<T extends SearchInputTypeRequiredProperties> = T & SearchInputTypeRequiredProperties;


type ScoringResultType<T extends SearchInputTypeRequiredProperties> = SearchInputType<T> & {
  score: number,
}


/**
 * get score for leaf node
 */
const getScore = (target: string, searchTerms: string[]) => {
  let score = 0;
  const targetSurroundedBySpaces = ` ${target} `;
  let nrMatchedTerms = 0;


  for (let j = 0; j < searchTerms.length; j++) {
    // if "exact match" then increase score drastically
    if (targetSurroundedBySpaces.indexOf(` ${searchTerms[j]} `) >= 0) {
      score += 1;
      nrMatchedTerms += 1;
    } else if (target.indexOf(searchTerms[j]) >= 0) {
      score += 0.1;
      nrMatchedTerms += 1;
    }
  }

  if (nrMatchedTerms !== searchTerms.length) {
    return 0;
  }

  return score;
};


/**
 * recursively score this node or it's children, if it has any
 */
const scoreArray = <T extends SearchInputTypeRequiredProperties>(collection: SearchInputType<T> | SearchInputType<T>[], allTerms: string[]) => {
  if (!Array.isArray(collection)) {
    return {
      ...collection,
      score: getScore(collection.searchValue.toLocaleLowerCase(), allTerms),
    };
  }

  const result: ScoringResultType<T>[] = [];
  // keep track of the total score of this group
  let totalScore = 0;

  for (let i = 0; i < collection.length; i++) {
    // if it has children then recursively score it
    if (Array.isArray(collection[i].children)) {
      const { score, children: scoredChildren } = scoreArray(collection[i].children, allTerms);

      if (score > 0) {
        totalScore += score;
        result.push({
          ...collection[i],
          score,
          children: scoredChildren,
        });
      }
    } else {
      // if this is a leaf then score this node
      const score = getScore(collection[i].searchValue.toLocaleLowerCase(), allTerms);

      if (score > 0) {
        totalScore += score;
        result.push({
          ...collection[i],
          score,
        });
      }
    }
  }

  // sort the children inside this group
  const sortedResult = result.sort((a, b) => b.score - a.score);

  return {
    score: totalScore,
    children: sortedResult,
  };
};


export const useScoreStringSearch = () => {
  const scoreFn = <T extends SearchInputTypeRequiredProperties>(collection: SearchInputType<T>[], searchTerm: string) => {
    const sanitizedSearchTerm = searchTerm.trim().toLocaleLowerCase();
    if (!sanitizedSearchTerm) {
      return collection;
    }

    const allTerms = sanitizedSearchTerm.split(/\s/).map(term => term.trim());

    // start recursively scoring the collection
    const { children } = scoreArray(collection, allTerms);

    return (children as ScoringResultType<T>[]).sort((a, b) => b.score! - a.score!);
  };

  return scoreFn;
};
