import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { ICONS } from '../../components/icons';
import './InfoIcon.scss';

export interface InfoIconProps {
  popupText: string | React.ReactNode,
  placement?: TooltipPlacement,
  overlayClassName?: string;
  size?: 'small' | 'large';
  tooltipMaxWidth?: number | 'unset',
  tooltipMinWidth?: number | 'unset',
}

export const InfoIcon = ({
  popupText, placement, overlayClassName, size, tooltipMaxWidth, tooltipMinWidth,
}: InfoIconProps): JSX.Element => {
  const icon = size === 'small'
    ? 'infoSmall'
    : 'infoLarge';

  return (
    <Tooltip
      title={popupText}
      autoAdjustOverflow
      mouseEnterDelay={0}
      overlayStyle={{ maxWidth: tooltipMaxWidth, minWidth: tooltipMinWidth }}
      placement={placement}
      overlayClassName={overlayClassName}
    >
      <Icon className="InfoIcon" component={ICONS[icon]} />
    </Tooltip>
  );
};


InfoIcon.defaultProps = {
  placement: 'top',
  overlayClassName: '',
  size: 'large',
  tooltipMaxWidth: 250,
  tooltipMinWidth: 'unset',
};
