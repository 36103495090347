export const STATE_TYPE_KEYS = [
  'FREQ',
  'INTERVAL',
  'BYMONTHDAY',
  'BYMONTH',
  'UNTIL',
  'COUNT',
];

export type FrequencyType = 'MONTHLY' | 'YEARLY';

export interface StateType {
  FREQ: FrequencyType,
  INTERVAL: number,
  BYMONTHDAY: number,
  BYMONTH: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  UNTIL: string | undefined, // ISOString,
  COUNT: number | undefined,
}

export interface ErrorType {
  status: 'error' | 'warning',
  message: string,
}

export type Errors = { [key in keyof StateType | 'DTSTART']?: ErrorType }

export interface LabelsType {
  frequency?: string,
  dayAndMonth?: string,
  startDate?: string,
  endCondition?: string,
  until?: string,
  count?: string,
  skippedGenerationWarningDetailed?: string;
}

export interface Props {
  disabled?: boolean,
  labels?: LabelsType,
  defaultState?: string, // rrule
  defaultStartDate?: string, // ISOString
  onChange: (rrule: string, DTSTART: string, isValid?: boolean, errors?: Errors) => void,
}

export type EndConditionType = 'onDate' | 'never' | 'afterCount';
