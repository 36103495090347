import { Table } from 'antd';
import { TableSummaryIconCell } from 'elements/TableSummaryIconCell/TableSummaryIconCell';
import { formatCurrency } from 'lib/Utils';
import { isNil } from 'lodash';
import { TotalSummaryData } from 'pages/OpsCostReport/OpsCostReportEditing/services/interfaces';
import Amount from 'storybook-components/typography/Amount/Amount';

export interface ExpenseTableSummaryProps {
  title: string,
  totalSummaryData: TotalSummaryData,
  nrTotalColumns: number,
  addPlaceholderLastColumn: boolean,
}

const ExpenseTableSummary = ({
  title, totalSummaryData, nrTotalColumns, addPlaceholderLastColumn,
} : ExpenseTableSummaryProps) => (
  <Table.Summary>
    <Table.Summary.Row>
      <TableSummaryIconCell colSpan={nrTotalColumns - (addPlaceholderLastColumn ? 3 : 2)} content={title} />

      {!isNil(totalSummaryData.totalAmount.net) && (
        <Table.Summary.Cell className="group-column-darker column-align-right no-wrap" index={1} colSpan={1}>
          <Amount>{formatCurrency(totalSummaryData.totalAmount.net, '-', false)}</Amount>
        </Table.Summary.Cell>
      )}

      <Table.Summary.Cell className={`${!isNil(totalSummaryData.totalAmount.net) && 'group-column-darker'} column-align-right no-wrap`} index={1} colSpan={1}>
        <Amount>{formatCurrency(totalSummaryData.totalAmount.gross, '-', false)}</Amount>
      </Table.Summary.Cell>

      {!isNil(totalSummaryData.yourAmount.net) && (
        <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
          <Amount>{formatCurrency(totalSummaryData.yourAmount.net, '', false)}</Amount>
        </Table.Summary.Cell>
      )}

      {!isNil(totalSummaryData.yourAmount.vat) && (
        <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
          <Amount>{formatCurrency(totalSummaryData.yourAmount.vat, '', false)}</Amount>
        </Table.Summary.Cell>
      )}

      <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
        <Amount>{formatCurrency(totalSummaryData.yourAmount.gross, '-', false)}</Amount>
      </Table.Summary.Cell>

      {addPlaceholderLastColumn && (
        <Table.Summary.Cell index={1} colSpan={1}>
          {/* empty cell for RowActionColumn */}
        </Table.Summary.Cell>
      )}
    </Table.Summary.Row>
  </Table.Summary>
);

export default ExpenseTableSummary;
