import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { range } from 'lib/Utils';
import defaultSection from './defaultSection';
import { BankAccountSubsection } from './components/BankAccountSubsection/BankAccountSubsection';
import { AddBankAccountButton } from './components/AddBankAccountButton/AddBankAccountButton';


interface BankSectionProps {
  bankAccountNumber: number
}

export default function useBankSection(props: BankSectionProps) {
  const { bankAccountNumber } = props;
  const { tl } = useContext(LanguageContext);

  function bankInputs(index: number, isLast: boolean) {
    const section = [{
      sectionId: `account${index}`,
      title: `${tl(translations.pages.companyEditing.bankSection.data.title)} ${index + 1}`,
      content: <BankAccountSubsection indexOfBankAccount={index} addButton={isLast} />,
    }];
    return section;
  }

  function addBankAccountButton() {
    const section = [{
      sectionId: 'addBankAccountButton',
      title: tl(translations.pages.companyEditing.bankSection.data.title),
      content: <AddBankAccountButton />,
    }];
    return section;
  }

  function getBankInputs(numberAccounts: number) {
    if (numberAccounts === 0) return addBankAccountButton();
    const displayNumber = Math.max(0, numberAccounts - 1) || 0;
    return range(0, displayNumber).reduce((accumulator: Array<any>, number: number) => accumulator.concat(bankInputs(number, number === displayNumber)), []);
  }

  return {
    ...defaultSection(1),
    sectionTitle: tl(translations.pages.companyEditing.bankSection.title),
    sectionId: 'BankData',
    content: [
      ...getBankInputs(bankAccountNumber),
    ],
  };
}
