import { useLocation } from 'react-router';
import { EconomicPlanDtoTypeEnum } from '../../api/accounting';
import EconomicPlanAccountEditing from './EconomicPlanAccountEditing/EconomicPlanAccountEditing';
import { EconomicPlanHouseMoney } from './EconomicPlanHouseMoney/EconomicPlanHouseMoney';

export default () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  if (searchParams.get('type') === EconomicPlanDtoTypeEnum.UNIT_FIGURES) {
    return <EconomicPlanHouseMoney />;
  }
  if (searchParams.get('type') === EconomicPlanDtoTypeEnum.ACCOUNT_DISTRIBUTION) {
    return (<EconomicPlanAccountEditing />);
  }
  return null;
};
