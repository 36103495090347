import './ActionsDropdown.scss';

import React, {
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Dropdown,
  Menu,
} from 'antd';

import Icon from '@ant-design/icons';

import { ICONS } from '../../../../components/icons';
import { getParentScrollElement } from '../../../../lib/Utils';
import { Action } from '../../data';

interface ActionsDropdownProp {
  record: any,
  actions?: Action[],
  tableVerticallyScrollable: boolean
}

export default function ActionsDropdown(props: ActionsDropdownProp): JSX.Element {
  const { actions, record, tableVerticallyScrollable } = props;
  const [visible, setVisible] = useState(false);
  const actionsDropdownElement = useRef(null);

  const scrollElement = useMemo(() => (tableVerticallyScrollable
    ? document.getElementsByClassName('ant-table-body')[0] as HTMLElement
    : getParentScrollElement(actionsDropdownElement.current).parentElement),
  [tableVerticallyScrollable, actionsDropdownElement.current]);

  const onScroll = () => {
    setVisible(false);
  };

  const onVisibleChange = (v: boolean) => {
    setVisible(v);
    const event = 'scroll';
    if (scrollElement) {
      if (v) {
        scrollElement.addEventListener(event, onScroll);
      } else {
        scrollElement.removeEventListener(event, onScroll);
      }
    }
  };

  const supportedActions = useMemo(() => (actions
    ? actions.filter(action => typeof action.actionSupported === 'undefined' || action.actionSupported(record))
    : []),
  [actions, record]);

  const menu = useMemo(() => (
    <Menu>
      {supportedActions
        .map((option: Action, index: number) => (
          <Menu.Item
            key={`option${index}`}
            onClick={(clickParam) => {
              clickParam.domEvent.stopPropagation();
              setVisible(false);
              option.onAction(record);
            }}
          >
            {option.element || option.label}
          </Menu.Item>
        ))}
    </Menu>
  ), [supportedActions]);


  if (supportedActions.length === 0) {
    return <React.Fragment />;
  }

  return (
    <div className="ActionsDropdown" ref={actionsDropdownElement}>
      {actions
        && (
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="topLeft"
            overlayClassName="smart-table-option-dropdown"
            disabled={actions.length === 0}
            visible={visible}
            onVisibleChange={onVisibleChange}
          >
            <Icon component={ICONS.menuDots} onClick={e => e.stopPropagation()} className="ant-dropdown-link" />
          </Dropdown>
        )}
    </div>
  );
}
