import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { UnitContractProjectionDto } from 'api/accounting';
import { OpsCostReportPreviewLocationState } from '../interfaces';


export const useInitOpsCostReportPreview = (onClose: () => void, onSelectTenenantContract: (contract: UnitContractProjectionDto) => void) => {
  const location = useLocation<OpsCostReportPreviewLocationState>();


  useEffect(() => {
    if (location.state === undefined) {
      onClose();
      return;
    }

    if (location.state?.selectedTenantContractId) {
      const contract: UnitContractProjectionDto = {
        unitContractId: location.state?.selectedTenantContractId,
      };
      onSelectTenenantContract(contract);
    }
  }, []);
};
