/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectionAuthRequestDto
 */
export interface ConnectionAuthRequestDto {
    /**
     * How long shall a token be valid. Value shall be kept as short as possible since the current stateless security model does not yet support token invalidation. ie. Once a token has been generated: it is always accepted by the backend until it expires. Only use long validity if the integrator does not support requesting reauthenticating every now and again (ex: Powerautomate). Defaults to 120 seconds
     * @type {number}
     * @memberof ConnectionAuthRequestDto
     */
    tokenValidityInSeconds?: number;
}

/**
 * Check if a given object implements the ConnectionAuthRequestDto interface.
 */
export function instanceOfConnectionAuthRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectionAuthRequestDtoFromJSON(json: any): ConnectionAuthRequestDto {
    return ConnectionAuthRequestDtoFromJSONTyped(json, false);
}

export function ConnectionAuthRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionAuthRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tokenValidityInSeconds': !exists(json, 'tokenValidityInSeconds') ? undefined : json['tokenValidityInSeconds'],
    };
}

export function ConnectionAuthRequestDtoToJSON(value?: ConnectionAuthRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tokenValidityInSeconds': value.tokenValidityInSeconds,
    };
}

