import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { BurgerMenuItem } from 'services/useSiteMap/interfaces';
import { useAnalyticsDomainDataList } from './analyticsDomainDataList';
import { translations } from './translations';

export const useAnalyticsMenuItems: () => BurgerMenuItem | null = () => {
  const { analyticsDomainData } = useAnalyticsDomainDataList();
  const { tl } = useContext(LanguageContext);

  return analyticsDomainData ? {
    key: 'analytics',
    title: tl(translations.analytics.title),
    subMenuPoints: analyticsDomainData.map(group => ({
      key: `${group.pages[0].path}`,
      title: tl(group.title),
      navItems: group.pages.map(page => ({
        key: `${page.path}`,
        url: `${page.path}`,
        title: tl(page.title),
      })),
    })),
  } : null;
};
