import { UnitOwnerAccountBalanceDtoContractVatRelevanceEnum } from 'api/accounting';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { mapCountryVatRateToSelectOptions, mapVatPercentageToSelectOption } from 'lib/vatUtils';
import { PropertyVatRate } from '../../services/PropertyVatRatesContext';

interface Props {
  propertyVatRate: PropertyVatRate,
  contractVatRelevance: UnitOwnerAccountBalanceDtoContractVatRelevanceEnum,
  value: number,
  onChange: (val: number) => void,
  disabled?: boolean,
}

const UnitAllocationVatCell = ({
  propertyVatRate, contractVatRelevance, value, onChange, disabled,
}: Props) => {
  const options = contractVatRelevance === UnitOwnerAccountBalanceDtoContractVatRelevanceEnum.NOT_RELEVANT
    ? [mapVatPercentageToSelectOption(0)]
    : mapCountryVatRateToSelectOptions(propertyVatRate);

  return (
    <SelectInput
      defaultValue={value}
      options={options}
      onChange={onChange}
      disabled={disabled || options.length <= 1}
    />
  );
};

UnitAllocationVatCell.defaultProps = {
  disabled: false,
};

export default UnitAllocationVatCell;
