import Amount from 'storybook-components/typography/Amount/Amount';
import CellWithTwoLines from '../CellWithTwoLines/CellWithTwoLines';
import './CellWithTwoAmounts.scss';
import { CellWithTwoAmountsProps } from './interfaces';
import { useCellWithTwoAmounts } from './useCellWithTwoAmounts';

const CellWithTwoAmounts = ({
  firstAmount,
  secondAmount,
  title,
}: CellWithTwoAmountsProps) => {
  const {
    firstAmountConfig,
    secondAmountConfig,
  } = useCellWithTwoAmounts({ firstAmount, secondAmount });

  return (
    <div title={title} className="CellWithTwoAmounts">
      <CellWithTwoLines
        firstElement={(
          <Amount
            color={firstAmountConfig.color}
            className={`first-amount ${firstAmountConfig.strikethrough ? 'strikethrough-amount' : ''}`}
          >
            {firstAmountConfig.amount}
          </Amount>
        )}
        secondElement={(
          <Amount
            color={secondAmountConfig.color}
            className={`second-amount ${secondAmountConfig.strikethrough ? 'strikethrough-amount' : ''}`}
          >
            {secondAmountConfig.amount}
          </Amount>
        )}
      />
    </div>
  );
};

export default CellWithTwoAmounts;
