import './AssignButton.scss';

import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';

import { translations } from './translations';

interface AssignButtonProps {
    issueId: number,
    assigned: boolean,
    hidden: boolean,
    showModal: (id: number) => void,
    unassignInvoice: () => void,
}

export const AssignButton = ({
  issueId, assigned, hidden, showModal, unassignInvoice,
}:AssignButtonProps) => {
  const { tl } = useContext(LanguageContext);
  const onClick = assigned ? unassignInvoice : () => showModal(issueId);
  const label = assigned ? tl(translations.buttons.unassign) : tl(translations.buttons.assign);
  return (
    <Button
      className="AssignButton"
      onClick={onClick}
      hidden={hidden}
    >
      {label}
    </Button>
  );
};
