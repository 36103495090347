import backend, { endpointUrls } from 'backend_api';
import { PdfProxyHtmlRenderDto } from 'data/pdfProxy';

export const usePdfBlob = () => {
  const generatePdfBlob = (pdfProxyHtml:PdfProxyHtmlRenderDto) => {
    const renderUrl = `${endpointUrls.PDF_PROXY}/render-html`;

    return backend.getFileUsingPostToProxy(renderUrl, pdfProxyHtml);
  };

  return { generatePdfBlob };
};
