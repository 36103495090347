import { mapPropertyAdministrationTypeToFrontend } from './utilMapPropertyAdministrationTypeToFrontend';


type RecordWithPropertyData = { [key: string]: any } & (
  | { administrationType: string }
  | { propertyAdministrationType: string }
);


export const getPropertyCellSubtitleInList = (record: RecordWithPropertyData, administrationTypeKey: 'administrationType' | 'propertyAdministrationType') => `${
  mapPropertyAdministrationTypeToFrontend(record[administrationTypeKey]) ?? '—'
}${
  record.propertyIdInternal
    ? ` • ${record.propertyIdInternal}`
    : ''
}`;
