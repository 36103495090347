import React, { ReactNode } from 'react';
import Modal, { ModalProps } from 'antd/lib/modal';
import './ModalWithSubtitle.scss';

interface ModalWithSubtitleProps extends ModalProps {
  subtitle: ReactNode;
  children?: ReactNode;
}

const ModalWithSubtitle = ({
  title,
  subtitle,
  children,
  className,
  ...rest
}: ModalWithSubtitleProps) => {
  const titleAndSubtitle = (
    <div className="title-wrapper">
      <div className="main-title">
        {title}
      </div>
      <div className="subtitle">
        {subtitle}
      </div>
    </div>
  );

  return (
    <Modal className={`modal-with-subtitle ${className || ''}`} title={titleAndSubtitle} {...rest}>
      {children}
    </Modal>
  );
};

export default ModalWithSubtitle;


ModalWithSubtitle.defaultProps = {
  children: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  destroyOnClose: true,
};
