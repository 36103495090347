export const translations = {
  confirmDialog: {
    close: {
      confirmationTitle: {
        en: 'Are you sure you want to discard the changes?',
        de: 'Möchten Sie die Änderungen wirklich verwerfen?',
      },
      confirmationText: {
        en: 'This will discard all the changes that you have performed for the current watermark.',
        de: 'Dadurch werden alle Änderungen verworfen, die Sie für das aktuelle Briefpapier vorgenommen haben.',
      },
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    ok: {
      en: 'Ok',
      de: 'Ok',
    },
  },
  error: {
    couldNotDiscardChangesMessage: {
      en: 'There was an error while discarding the changes.',
      de: 'Beim Verwerfen der Änderungen ist ein Fehler aufgetreten.',
    },
    couldNotDiscardChangesDescription: {
      en: 'There was an unexpected error while discarding the changes to the current watermark.',
      de: 'Beim Verwerfen der Änderungen am aktuellen Briefpapier ist ein unerwarteter Fehler aufgetreten.',
    },
  },
};
