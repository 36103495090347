import { usePropertyEditing } from '../services/PropertyEditingContext/usePropertyEditing';

export default function useDefaultSection(index: number, openingIndex: number, isValid?: boolean) {
  const { loading, property } = usePropertyEditing();

  return {
    sectionNumber: index + 1,
    openingNumber: openingIndex + 1,
    buttonLoading: loading,
    value: property,
    isValid,
  };
}
