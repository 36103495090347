import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EconomicPlanContext } from './EconomicPlanContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { EconomicPlanControllerApi, EconomicPlanDtoTypeEnum } from '../../../api/accounting';
import { showNotification } from '../../../lib/Notification';
import { economicPlanTranslations } from '../economicPlanTranslations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { convertEconomicPlanDtoToEconomicPlanSimpleProjection } from './economicPlanUtils';


export const useCopyEconomicPlan = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const economicPlanContext = useContext(EconomicPlanContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  if (economicPlanContext === undefined) {
    throw new Error('useEconomicPlanList must be used within a EconomicPlanContextProvider');
  }
  const {
    setEconomicPlanList,
  } = economicPlanContext;

  const copyEconomicPlan = (id: number) => {
    economicPlanControllerApi.copyEconomicPlanUsingPOST({ id })
      .then((economicPlan) => {
        setEconomicPlanList(prevState => prevState.load([
          convertEconomicPlanDtoToEconomicPlanSimpleProjection(economicPlan),
          ...prevState.data,
        ]));
        return economicPlan;
      })
      .then((economicPlan) => {
        showNotification({
          key: 'copyEconomicPlanSuccess',
          message: tl(economicPlanTranslations.notifications.copyEconomicPlanSuccess.message),
          type: 'success',
        });
        return economicPlan;
      })
      .then((economicPlan) => {
        if (economicPlan.type === EconomicPlanDtoTypeEnum.ACCOUNT_DISTRIBUTION) {
          history.push(`/economic-plan/property/${economicPlan.propertyHrId}/view/${economicPlan.id}/edit?type=ACCOUNT_DISTRIBUTION`);
        } else {
          history.push(`/economic-plan/property/${economicPlan.propertyHrId}/unit-figures/${economicPlan.id}`);
        }
      })
      .catch(() => {
        showNotification({
          key: 'copyEconomicPlanError',
          message: tl(economicPlanTranslations.notifications.copyEconomicPlanError.message),
          type: 'error',
        });
      });
  };

  return {
    copyEconomicPlan,
  };
};
