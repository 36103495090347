import ComponentVisibilityToggler from 'components/ComponentVisibilityToggler/ComponentVisibilityToggler';
import { translations } from 'elements/Translation/translations';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { AccountFilterComponent } from 'elements/Inputs/AccountFilterComponent/AccountFilterComponent';
import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import DateInput, { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import { useAccountBookingForm } from 'pages/Account/AccountBooking/useAccountBookingForm';
import React, { useContext } from 'react';
import { Account } from 'services/useAccountList';
import { useParams } from 'react-router';
import { SPACE_SIZES } from 'lib/constants';
import { Col, Row } from 'antd';
import { getChartOfAccountsArticleUrl } from 'storybook-components/feedback/AlertArticleLink/ArticleUrlUtils';
import { Link } from 'react-router-dom';
import { getAdministrationTypeFromPropertyHrIdForArticles } from 'services/Property/usePropertyUtils';
import { DefaultDataInterface } from 'lib/data';
import VatInput from './VatInput';
import { useBookingRow } from './useBookingRow';

type Props = {
  bookingNr: number
  allAccounts: DefaultDataInterface<Account[]>
}

const BookingRow = ({ bookingNr, allAccounts }: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    deleteBooking, onVisibilityChange, onChangeBooking, onChangeGrossAmount,
  } = useAccountBookingForm();
  const { value, rowValidationErrors } = useBookingRow(bookingNr);

  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const administrationType = getAdministrationTypeFromPropertyHrIdForArticles(propertyHrId);


  const infoText = propertyHrId
    ? (
      <Link target="_blank" to={{ pathname: getChartOfAccountsArticleUrl(administrationType) }}>
        {tl(translations.pages.accountBooking.form.accountArticleLabel)}
      </Link>
    )
    : undefined;

  return (
    <div key={`booking-${bookingNr}`} className="section">
      <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
        <Col span={8}>
          <CurrencyInput
            onChange={val => onChangeGrossAmount(val, bookingNr)}
            label={tl(translations.pages.accountBooking.form.amount)}
            value={value?.amount}
            className="input"
            validationState={rowValidationErrors?.amount?.validationState}
            validationMessage={rowValidationErrors?.amount?.validationMessage}
            autoFocus
          />
        </Col>
        <Col span={8}>
          <AccountFilterComponent
            hideParents
            propertyHrId={propertyHrId}
            allAccounts={allAccounts.data}
            required
            label={tl(translations.pages.accountBooking.form.accountDebit)}
            onChange={val => onChangeBooking(val, 'debitAccount', bookingNr)}
            className="input"
            value={value?.debitAccount}
            validationState={rowValidationErrors?.debitAccount?.validationState}
            validationMessage={rowValidationErrors?.debitAccount?.validationMessage}
            infoText={infoText}
          />
        </Col>
        <Col span={8}>
          <AccountFilterComponent
            hideParents
            propertyHrId={propertyHrId}
            allAccounts={allAccounts.data}
            required
            label={tl(translations.pages.accountBooking.form.accountCredit)}
            onChange={val => onChangeBooking(val, 'creditAccount', bookingNr)}
            className="input"
            value={value?.creditAccount}
            validationState={rowValidationErrors?.creditAccount?.validationState}
            validationMessage={rowValidationErrors?.creditAccount?.validationMessage}
            infoText={infoText}
          />
        </Col>
      </Row>
      <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
        <Col span={16}>
          <TextInput
            onChange={val => onChangeBooking(val, 'bookingText', bookingNr)}
            label={tl(translations.pages.accountBooking.form.bookingText)}
            value={value?.bookingText}
            className="input"
          />
        </Col>
        <Col span={8}>
          <DateInput
            value={value?.postDate}
            onChange={val => onChangeBooking(val, 'postDate', bookingNr)}
            label={tl(translations.pages.accountBooking.form.bookingDate)}
            className="input"
            required
            validationState={rowValidationErrors?.postDate?.validationState}
            validationMessage={rowValidationErrors?.postDate?.validationMessage}
            softMinDate={MIN_DATE_NORMAL}
            softMaxDate={MAX_DATE_NORMAL}
          />
        </Col>
      </Row>
      <ComponentVisibilityToggler
        labelWhenClosed={tl(translations.pages.accountBooking.form.addVat)}
        labelWhenOpen={tl(translations.pages.accountBooking.form.removeVat)}
        onVisibilityChange={visible => onVisibilityChange(visible, ['vatPercentage', 'vatAmount', 'vatEligibilityPercentage', 'vatEligibilityAmount'], bookingNr)}
      >
        <Row gutter={[SPACE_SIZES.xLarge, 0]}>
          <VatInput
            value={value}
            bookingNr={bookingNr}
            validationState={rowValidationErrors?.vatAmount?.validationState}
            validationMessage={rowValidationErrors?.vatAmount?.validationMessage}
          />
        </Row>
      </ComponentVisibilityToggler>
      <ComponentVisibilityToggler
        labelWhenClosed={tl(translations.pages.accountBooking.form.addLaborCost)}
        labelWhenOpen={tl(translations.pages.accountBooking.form.removeLaborCost)}
        onVisibilityChange={visible => onVisibilityChange(visible, ['laborCostType', 'laborCost'], bookingNr)}
      >
        <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
          <Col span={8}>
            <SelectInput
              onChange={val => onChangeBooking(val, 'laborCostType', bookingNr)}
              value={value?.laborCostType}
              label={tl(translations.pages.accountBooking.form.laborCostType)}
              options={[
                {
                  value: 'HOUSEHOLD_RELATED_SERVICES',
                  label: tl(translations.pages.accountBooking.form.laborCostTypes.HOUSEHOLD_RELATED_SERVICES),
                },
                {
                  value: 'TECHNICIAN_SERVICE',
                  label: tl(translations.pages.accountBooking.form.laborCostTypes.TECHNICIAN_SERVICE),
                },
                {
                  value: 'MARGINAL_EMPLOYMENT',
                  label: tl(translations.pages.accountBooking.form.laborCostTypes.MARGINAL_EMPLOYMENT),
                },
                {
                  value: 'INSURABLE_EMPLOYMENT',
                  label: tl(translations.pages.accountBooking.form.laborCostTypes.INSURABLE_EMPLOYMENT),
                },
              ]}
              className="input"
              validationState={rowValidationErrors?.laborCostType?.validationState}
              validationMessage={rowValidationErrors?.laborCostType?.validationMessage}
            />
          </Col>
          <Col span={8}>
            <CurrencyInput
              onChange={val => onChangeBooking(val, 'laborCost', bookingNr)}
              label={tl(translations.pages.accountBooking.form.laborCost)}
              value={value?.laborCost}
              className="input"
              validationState={rowValidationErrors?.laborCost?.validationState}
              validationMessage={rowValidationErrors?.laborCost?.validationMessage}
            />
          </Col>
        </Row>
      </ComponentVisibilityToggler>
      {bookingNr > 0 && (
        <div className="section-row">
          <Button
            type="ghost"
            className="icon-button trash"
            onClick={(e) => {
              e.stopPropagation();
              deleteBooking(bookingNr);
            }}
          >
            <Icon component={ICONS.bin} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default BookingRow;
