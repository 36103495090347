import React from 'react';
import './ResizableDrawer.scss';
import Icon from '@ant-design/icons';
import { Drawer } from 'antd';
import { ICONS } from '../icons';
import Button from '../../elements/Buttons/Button/Button';
import { convertSizeToNumberInDocument } from '../../lib/Utils';

interface DrawerProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  placement: 'top' | 'right' | 'bottom' | 'left'
  minSize?: number | string
  maxSize?: number | string
  onCloseCallback?: Function
  height: number
  setHeight: Function
  width: number
  setWidth: Function
  direction: 'vertical' | 'horizontal'
  id: string,
  disableClose?: boolean,
  afterVisibleChange?: (visible: boolean) => void,
}

export const ResizableDrawer: React.FC<DrawerProps> = (props) => {
  const {
    visible, setVisible, placement, children, minSize, maxSize, onCloseCallback, height, setHeight, width, setWidth, direction, id, disableClose, afterVisibleChange,
  } = props;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    let newSize = 0;

    switch (placement) {
    case 'left':
      newSize = (e.clientX - document.body.offsetLeft);
      break;
    case 'right':
      newSize = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      break;
    case 'top':
      newSize = (e.clientY - document.body.offsetTop);
      break;
    case 'bottom':
      newSize = document.body.offsetHeight - (e.clientY - document.body.offsetTop);
      break;
    default:
      break;
    }

    const minSizeNumber = convertSizeToNumberInDocument(minSize!, direction);
    const maxSizeNumber = convertSizeToNumberInDocument(maxSize!, direction);

    if (newSize! >= minSizeNumber! && newSize! <= maxSizeNumber!) {
      if (['top', 'bottom'].includes(placement)) {
        setHeight(newSize!);
      } else {
        setWidth(newSize!);
      }
    }
  };

  return (
    <Drawer
      className={`ResizableDrawer ${placement}`}
      closable={false}
      mask={false}
      visible={visible}
      placement={placement}
      width={width}
      height={height}
      afterVisibleChange={afterVisibleChange}
    >
      {visible && (
        <>
          <div tabIndex={0} role="button" className="resizable-drawer-dragger" onMouseDown={handleMouseDown}>
            <Icon component={ICONS.dragger} />
          </div>
          <Button
            className="resizable-drawer-close"
            disabled={disableClose}
            onClick={() => {
              setVisible(false);
              if (onCloseCallback) {
                onCloseCallback();
              }
            }}
          >
            <Icon component={ICONS.x} />
          </Button>
          <div id={id}>
            {children}
          </div>
        </>
      )}
    </Drawer>
  );
};

ResizableDrawer.defaultProps = {
  minSize: '20%',
  maxSize: '80%',
  disableClose: false,
};
