import React, { useContext, useEffect, useState } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import { Table } from 'antd';
import { LoadingIndicator } from 'elements/LoadingIndicator/LoadingIndicator';
import { UseReserveFundsColumns } from './UseReserveFundsColumns';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../../../../../elements/Translation/translations';
import { formatCurrency } from '../../../../../lib/Utils';
import SummaryRow from '../../../../../elements/CustomElements/ExpandableCard/SummaryRow';

interface ReserveFundsProps {
  dataSource: any[],
  loading: boolean,
  onChange?: (accountCode: string, value: number) => void,
}

interface ReserveFundsTotalProps {
  dataSource: any[],
}

export function useRenderReserveFundsTotal(props: ReserveFundsTotalProps) {
  const { tl } = useContext(LanguageContext);
  const [totalCosts, setTotalCosts] = useState(0);
  const { dataSource } = props;
  useEffect(() => {
    if (dataSource) {
      const total = dataSource.reduce((aggregator: any, reserveFund: any) => (typeof reserveFund.value === 'number' ? aggregator + reserveFund.value : aggregator), 0);
      setTotalCosts(total);
    }
  }, [dataSource]);

  const summaryElements = [{
    title: tl(translations.pages.economicPlan.propertyAccountBalances.reserveFunds.summaryTitle),
    value: <Amount>{formatCurrency(totalCosts)}</Amount>,
  }];
  return (
    <SummaryRow
      rightSideElements={summaryElements}
      title={tl(translations.pages.economicPlan.propertyAccountBalances.expenses.total)}
    />
  );
}

export default function ReserveFundsTable(props: ReserveFundsProps): JSX.Element {
  const { dataSource, loading, onChange } = props;

  return (
    <div className="ReserveFundsTable ">
      <Table
        columns={UseReserveFundsColumns({ onChange })}
        dataSource={dataSource}
        loading={{
          spinning: loading,
          indicator: <LoadingIndicator />,
        }}
        pagination={false}
        rowKey="account"
      />
    </div>
  );
}
