
import { DocumentCreateDto, DocumentLegacyDto, DocumentCreateDtoSourceTypeEnum } from 'api/document';
import backend, { endpointUrls } from 'backend_api';

const url = `${endpointUrls.DOCUMENT}/upload`;

type UploadFilesArgs = {
  // files: HTMLInputElement['files'],
  file: File,
  propertyId: number,
  sourceType: DocumentCreateDtoSourceTypeEnum,
  sourceId: number,
  name?: string,
}

export function useUploadFiles() {
  function uploadFiles(files: UploadFilesArgs[]) {
    if (!files || files.length === 0) {
      return [];
    }

    return Array.from(files).map(f => backend.postFile(url, f.file, {
      name: f.name ?? f.file.name,
      propertyId: f.propertyId,
      sourceType: f.sourceType,
      sourceId: f.sourceId,
      metaData: JSON.stringify({ originalFileName: f.file.name }),
    } as DocumentCreateDto) as Promise<DocumentLegacyDto>);
  }

  return { uploadFiles };
}
