import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useContext, useState } from 'react';
import { PdfBlobForContract } from '../interfaces';

interface Props {
  children: React.ReactNode;
}


export const OpsCostReportPreviewContext = createContext<{
  pdfBlobForContracts: DefaultDataInterface<PdfBlobForContract[]>,
  setPdfBlobForContracts: React.Dispatch<React.SetStateAction<DefaultDataInterface<PdfBlobForContract[]>>>,
  selectedContractId:number,
  setSelectedContractId: React.Dispatch<number>,
} | undefined>(undefined);


const OpsCostReportPreviewContextProvider = ({ children }: Props) => {
  const [pdfBlobForContracts, setPdfBlobForContracts] = useState(DEFAULT_DATA<PdfBlobForContract[]>([]));
  const [selectedContractId, setSelectedContractId] = useState<number>();

  return (
    <OpsCostReportPreviewContext.Provider value={{
      pdfBlobForContracts,
      setPdfBlobForContracts,
      selectedContractId,
      setSelectedContractId,
    }}
    >
      {children}
    </OpsCostReportPreviewContext.Provider>
  );
};

OpsCostReportPreviewContext.displayName = 'OpsCostReportPreviewContext';
export const useOpsCostReportPreviewContext = (usageName: string) => {
  const opsCostReportViewContext = useContext(OpsCostReportPreviewContext);

  if (opsCostReportViewContext === undefined) {
    throw new Error(`${usageName} must be used within an OpsCostReportPreviewContextProvider`);
  }

  return opsCostReportViewContext;
};


export default OpsCostReportPreviewContextProvider;
