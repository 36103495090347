import React, { useContext, useEffect } from 'react';
import SmartTable from 'elements/SmartTable/SmartTable';
import { PropertyListContext } from 'contexts/PropertyListContext';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import './ResolutionRecordPropertyList.scss';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { useResolutionRecordPropertyListColumns } from './UseResolutionRecordPropertyListColumns';
import MainPageHeader from '../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../../services/useSiteMap/useSiteMap';
import { useGlobalSearch } from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import { FindFilteredPropertiesUsingGETAdministrationTypesEnum, PropertyLegacyDtoPropertyStateEnum } from '../../../api/accounting';
import useResolutionRecordFilters from './useResolutionRecordFilters';

export default function ResolutionRecordPropertyList(): JSX.Element {
  const propertyListContext: any = useContext(PropertyListContext);
  const history = useHistory();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  useGlobalSearch({
    key: 'resolutionRecordPropertyList',
    filterProps: {
      availableFilters: useResolutionRecordFilters(),
      setFilter: (key: string, value: any) => propertyListContext.setPropertyFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);
          newFilter[key] = value;
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      filterState: propertyListContext.propertyFilterState,
      onSetDefaultFilterFromQueryParams: propertyListContext.updateFilterState,
    },
  });

  useEffect(() => {
    propertyListContext.updateFilterState({
      propertyStates: [PropertyLegacyDtoPropertyStateEnum.READY],
      administrationTypes: [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG],
    });
    return (() => {
      propertyListContext.onClearFilterState();
    });
  }, []);

  useEffect(() => {
    if (propertyHrId) {
      propertyListContext.updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  const smartTable = useSmartTable(
    {
      tableName: 'resolutionRecordPropertyList',
      columns: useResolutionRecordPropertyListColumns(),
      dataSource: propertyListContext.data.propertyList,
      contentLoading: propertyListContext.loading,
      propSort: {
        field: propertyListContext.sortField,
        order: propertyListContext.sortOrder,
        onSortChange: (dataKey: string) => propertyListContext.setSortField(dataKey),
      },
      onRow: (record: any) => ({
        onClick: () => {
          if (propertyHrId) {
            history.push(`/properties/${propertyHrId}/edit/resolution-records`);
          } else {
            history.push(`/resolution-records/${record.propertyHrId}`);
          }
        },
      }),
      infiniteScrollerProps: {
        hasMoreData: !propertyListContext.data.lastPage,
        loadMoreData: propertyListContext.onLoadPropertyList,
      },
      rowKey: 'propertyHrId',
    },
  );

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'resolutionRecordOverview',
    navItems: subcategorySwitcherItems.meetings,
  };

  return (
    <div className="ResolutionRecordPropertyList page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      <SmartTable {...smartTable} />
    </div>
  );
}
