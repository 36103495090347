import { UnitContractProjectionDto } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { AccountSelectionContext, AmountDirectionAndProcessingValuesContext, DatasourceContext } from './HouseMoneySettlementCloseYearContext';
import { translations } from '../translations';

export const useProcessingSelectorCell = (record: UnitContractProjectionDto) => {
  const { tl } = useContext(LanguageContext);
  const amountDirectionAndProcessingValues = useContext(AmountDirectionAndProcessingValuesContext);
  const datasourceContext = useContext(DatasourceContext);
  const accountSelectionContext = useContext(AccountSelectionContext);

  if (datasourceContext === undefined || accountSelectionContext === undefined) {
    throw new Error('useProcessingSelectorCell must be used within a HouseMoneySettlementCloseYearontextProvider');
  }

  const { setAmountDirectionAndProcessingValues } = datasourceContext;
  const { setDirty } = accountSelectionContext;

  const onChangeProcessing = (processingValue: 'SELF_PAYER' | 'PLATFORM', rec: UnitContractProjectionDto) => {
    setDirty(true);
    amountDirectionAndProcessingValues!.find(entry => entry.unitId === rec.unitId)!.processing = processingValue;
    setAmountDirectionAndProcessingValues(amountDirectionAndProcessingValues?.map(entry => ({ ...entry, processing: entry.unitId === rec.unitId ? processingValue : entry.processing })));
  };

  const dist = (amountDirectionAndProcessingValues ?? [])?.find(entry => entry.unitId === record.unitId);

  const SELECT_OPTIONS = [
    {
      label: tl(translations.creationPage.sections.section2.selectOptionsLabels.selfPayer),
      value: 'SELF_PAYER',
    },
    {
      label: tl(translations.creationPage.sections.section2.selectOptionsLabels[dist?.direction === 'incoming' ? 'directDebit' : 'paymentRun']),
      value: 'PLATFORM',
      disabled: (dist?.direction === 'incoming' && !record.hasMandateAtDate) || (dist?.direction === 'outgoing' && record.bankAccountIban === undefined),
    },
  ];

  if (accountSelectionContext?.formValues?.bankAccount === -1) {
    return {
      SELECT_OPTIONS: [SELECT_OPTIONS[0]],
      value: SELECT_OPTIONS[0].value,
    };
  }

  return { SELECT_OPTIONS, value: dist?.processing, onChangeProcessing };
};
