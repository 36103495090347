import { Dispatch } from 'react';

import {
  OwnersMeetingProtocolDtoVotingRightsEnum,
  OwnersMeetingProtocolDtoVotingSchemeEnum,
} from 'api/accounting';
import moment from 'moment';

import { PropertyDisplay } from '../../../data/property';
import {
  OptionInterface,
} from '../../../elements/Inputs/SmartSearch/SmartSearch';
import { DefaultDataInterface } from '../../../lib/data';
import {
  DispatchType,
  OwnersMeetingProtocol,
  OwnersMeetingProtocolTexts,
} from '../interfaces';
import {
  OwnersMeetingProtocolReducerActions,
} from '../pages/OwnersMeetingProtocolEditing/services/useDefaultProtocolTexts';

export const convertToBEModel = (beModel: OwnersMeetingProtocol | null) => {
  if (beModel) {
    if (beModel.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.MEA) {
      beModel.votingRightProtocolText = beModel.votingRightProtocolTextWithMea;
    } else if (beModel.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.HEAD) {
      beModel.votingRightProtocolText = beModel.votingRightProtocolTextHeadcount;
    }
    if (beModel.startTime) beModel.startTime = moment(beModel.startTime).format('HH:mm:ss');
    if (beModel.endTime) beModel.endTime = moment(beModel.endTime).format('HH:mm:ss');
  }

  return beModel;
};

export const convertToFeModel = (feModel: OwnersMeetingProtocol, protocolText: OwnersMeetingProtocolTexts, getDefaultProtocolText: any) => {
  if (feModel) {
    Object.keys(protocolText).forEach((key: string) => {
      // special cases
      if ((key === 'votingRightProtocolTextWithMea' && feModel.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.MEA)
      || (key === 'votingRightProtocolTextHeadcount' && feModel.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.HEAD)
      || (key === 'votingRightProtocolText' && feModel.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.UNIT)) {
        if (feModel.votingRightProtocolText === undefined) {
          // @ts-ignore
          feModel[key] = getDefaultProtocolText(key, feModel);
        } else {
          feModel[key] = feModel.votingRightProtocolText;
        }
        feModel[`${key}IsStandardText`] = getDefaultProtocolText(key, feModel) === feModel[key] || feModel[key] === undefined;
      } else if (!key.startsWith('votingRightProtocol') || !feModel.votingRights) {
        // If is standard text we have to get it from protocolText
        if (feModel[key] === undefined) {
        // @ts-ignore
          feModel[key] = getDefaultProtocolText(key, feModel);
        }
        feModel[`${key}IsStandardText`] = getDefaultProtocolText(key, feModel) === feModel[key] || feModel[key] === undefined;
      }
    });
    if (feModel.ownerMeetingInvitationId === undefined && feModel.agendaPointProtocolText === undefined) {
      feModel.agendaPointProtocolTextIsStandardText = false;
      feModel.agendaPointProtocolText = '';
    }
    if (feModel.startTime) feModel.startTime = moment(feModel.startTime, 'HH:mm');
    if (feModel.endTime) feModel.endTime = moment(feModel.endTime, 'HH:mm');

    if (feModel.protocolRecipients) {
      feModel.lastSigningIndex = -1;
    }

    if (!feModel.dispatchType) {
      feModel.dispatchType = DispatchType.EPOST;
    }
  }
  feModel.votingScheme = feModel.votingScheme || OwnersMeetingProtocolDtoVotingSchemeEnum.OPEN_VOTING;
  feModel.votingRights = feModel.votingRights || OwnersMeetingProtocolDtoVotingRightsEnum.MEA;
  return feModel;
};

/*
  Replacing placeholder variables which depend on property, when it gets loaded:
  - management company
  - meeting leader: one of the management company's employees
  - minute taker: one of the management company's employees
  * */
export const updateProtocolTexts = (ownersMeeting: DefaultDataInterface<OwnersMeetingProtocol>, selectedDisplayProperty: DefaultDataInterface<PropertyDisplay>,
  changeProtocolTexts: Dispatch<OwnersMeetingProtocolReducerActions>, managementCompanyEmployees: OptionInterface[], roleOptions: OptionInterface[]) => {
  const managementCompany = selectedDisplayProperty.data && selectedDisplayProperty.data.managementCompany ? selectedDisplayProperty.data.managementCompany.companyName : '';
  let minuteTaker = '';
  if (ownersMeeting.data && ownersMeeting.data.minuteTaker) {
    try {
      minuteTaker = managementCompanyEmployees.filter((employee: OptionInterface) => ownersMeeting.data && employee.value === ownersMeeting.data.minuteTaker)[0].label;
    } catch (e) {
      // NOOP
    }
  } else if (managementCompanyEmployees[0]) {
    minuteTaker = managementCompanyEmployees[0].label;
  }
  const minuteTakerRole = ownersMeeting.data && ownersMeeting.data.minuteTakerRole ? roleOptions.filter((role: OptionInterface) => ownersMeeting.data && role.value === ownersMeeting.data.minuteTakerRole)[0].label : roleOptions[0].label;

  let meetingLeader = '';
  try {
    meetingLeader = managementCompanyEmployees.filter((employee: OptionInterface) => ownersMeeting.data && employee.value === ownersMeeting.data.meetingLeader)[0].label;
  } catch (e) {
  }

  changeProtocolTexts({
    type: 'update',
    key: 'meetingLeaderProtocolText',
    value: {
      meetingLeader,
      meetingLeaderRole: ownersMeeting.data && ownersMeeting.data.meetingLeaderRole ? roleOptions.filter((role: OptionInterface) => ownersMeeting.data && role.value === ownersMeeting.data.meetingLeaderRole)[0].label : roleOptions[0].label,
      managementCompany,
    },
  });
  changeProtocolTexts({
    type: 'update',
    key: 'protocolLeaderProtocolText',
    value: {
      minuteTaker,
      minuteTakerRole,
      managementCompany,
    },
  });
};
