import { UnitContractProjectionDtoVatRelevanceEnum } from 'api/accounting';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { mapCountryVatRateToSelectOptions, mapVatPercentageToSelectOption } from 'lib/vatUtils';
import { AccountSelectionContext, AmountToDistributeInputValuesContext, TableDataSourceType } from 'pages/ManualExchange/services/ManualExchangeContext';
import { useContext, useMemo } from 'react';

export const useManualExchangeVatSelectorCell = (record: TableDataSourceType) => {
  const distributionValues = useContext(AmountToDistributeInputValuesContext);
  const accountSelectionContext = useContext(AccountSelectionContext);

  if (distributionValues === undefined || accountSelectionContext === undefined) {
    throw new Error('useManualExchangeVatSelectorCell must be used within a ManualExchangeContextProvider');
  }

  const { vatRate, contracts } = accountSelectionContext;

  const vatSelectOptions: Array<SelectOption> = useMemo(() => {
    const currentContract = contracts.find(c => c.unitContractId === record.unitContractId);
    if (!vatRate || !currentContract) {
      return [];
    }
    if (currentContract.vatRelevance === UnitContractProjectionDtoVatRelevanceEnum.NOT_RELEVANT) {
      return [mapVatPercentageToSelectOption(0)];
    }
    return mapCountryVatRateToSelectOptions(vatRate);
  }, [vatRate, contracts]);

  const vatPercentage = distributionValues.find(dv => dv.unitContractId === record.unitContractId)?.vatPercentage;

  return { vatSelectOptions, vatPercentage };
};
