import { RouteDefinition } from 'components/RenderRoutes';
import InvoiceList from './InvoiceList/InvoiceList';
import InvoiceEditingRouter from './InvoiceEditing/InvoiceEditingRouter';
import { InvoicePage } from './InvoicePage/InvoicePage';

const invoiceEditingPath = '/invoice/edit/:invoiceHrId';

export const getInvoiceEditingRoute = ({ invoiceHrId } : { invoiceHrId: string }) => invoiceEditingPath.replace(':invoiceHrId', invoiceHrId);

export const InvoiceRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/invoices/:invoiceId',
      component: InvoicePage,
    },
    {
      path: '/invoice',
      component: InvoiceList,
      subRoutes: [
        {
          path: '/invoice/create',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
        {
          path: invoiceEditingPath,
          component: InvoiceEditingRouter,
          type: 'focus',
        },
      ],
    },
  ],
};
