export const translations: any = {
  salutation: {
    en: 'Welcome',
    de: 'Willkommen',
  },
  navigationTiles: {
    comingSoon: {
      en: 'This feature will be released soon',
      de: 'Dieses Feature wird bald zur Verfügung stehen',
    },
    propertyData: {
      de: 'Objektdaten',
      en: 'Property Data',
    },
    accounting: {
      en: 'Accounting',
      de: 'Buchhaltung',
    },
    meetings: {
      en: 'Meetings',
      de: 'Versammlungen',
    },
    analytics: {
      en: 'Analytics',
      de: 'Analytics',
    },
    contacts: {
      en: 'Contacts',
      de: 'Kontakte',
    },
    communication: {
      en: 'Communication',
      de: 'Kommunikation',
    },
    messages: {
      en: 'Messages',
      de: 'Nachrichten',
    },
    documents: {
      en: 'Documents',
      de: 'Dokumente',
    },
    settings: {
      en: 'Settings',
      de: 'Einstellungen',
    },
  },
};
