/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}

/**
 * Check if a given object implements the Sort interface.
 */
export function instanceOfSort(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SortFromJSON(json: any): Sort {
    return SortFromJSONTyped(json, false);
}

export function SortFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
        'sorted': !exists(json, 'sorted') ? undefined : json['sorted'],
        'unsorted': !exists(json, 'unsorted') ? undefined : json['unsorted'],
    };
}

export function SortToJSON(value?: Sort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'empty': value.empty,
        'sorted': value.sorted,
        'unsorted': value.unsorted,
    };
}

