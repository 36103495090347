import React, { useContext } from 'react';
import { EmptyState } from 'storybook-components/feedback/EmptyState/EmptyState';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';
import SimpleDirtModalContextProvider from 'elements/Modals/DirtModal/SimpleDirtModal/SimpleDirtModalContext';
import { Prompt } from 'react-router';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { useSimpleDirtModal } from 'elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PdfPageAssignerActionBar from './components/PdfPageAssignerActionBar/PdfPageAssignerActionBar';
import PdfPageAssignerHeader from './components/PdfPageAssignerHeader/PdfPageAssignerHeader';
import PdfViewerWithAssignments from './components/PdfViewerWithAssignments/PdfViewerWithAssignments';
import PdfPageAssignerContextProvider from './services/PdfPageAssignerContext';
import './PdfPageAssigner.scss';
import { usePdfPageAssigner } from './services/usePdfPageAssigner';
import PdfPageAssignerAddFilesButton from './components/PdfPageAssignerAddFilesButton/PdfPageAssignerAddFilesButton';
import PdfZoomButtons from './components/PdfZoomButtons/PdfZoomButtons';


const PdfPageAssigner = () => {
  const { tl } = useContext(LanguageContext);
  const {
    originalDocuments,
    pdfPreviewWidth,
    onChangeZoom,
    zoomInDisabled,
    zoomOutDisabled,
  } = usePdfPageAssigner();
  const { dirty } = useSimpleDirtModal();


  return (
    <Page className="PdfPageAssigner">
      <Prompt message={tl(translations.elements.prompt)} when={dirty} />
      <PdfPageAssignerHeader />

      <PageContent className="content-wrapper" id="pdf-virtual-pages-scrollable-wrapper">
        {originalDocuments.data.length === 0 ? (
          <EmptyState
            icon={<Icon component={ICONS.file} />}
            title="No PDF files..."
            subTitle="You need to upload at least 1 PDF file in order to make an assignment."
            button={<PdfPageAssignerAddFilesButton buttonType="primary" hideIfNoFiles={false} />}
          />
        ) : null}


        {/* this wrapper is needed so that the header stays visible as we scroll */}
        <div>
          {originalDocuments.data?.map(doc => (
            <PdfViewerWithAssignments
              key={doc.id}
              documentId={doc.id}
              url={doc.url}
              filename={doc.metaData.originalFileName ?? doc.name}
              virtualize
              pdfPreviewWidth={pdfPreviewWidth}
            />
          ))}


          {originalDocuments.data.length > 0 && (
            <PdfZoomButtons
              onChangeZoom={onChangeZoom}
              zoomInDisabled={zoomInDisabled}
              zoomOutDisabled={zoomOutDisabled}
            />
          )}
        </div>

        <PdfPageAssignerActionBar />
      </PageContent>
    </Page>
  );
};


const PdfPageAssignerWithProvider = () => (
  <SimpleDirtModalContextProvider>
    <PdfPageAssignerContextProvider>
      <PdfPageAssigner />
    </PdfPageAssignerContextProvider>
  </SimpleDirtModalContextProvider>
);


export default PdfPageAssignerWithProvider;
