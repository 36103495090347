import { useContext, useMemo } from 'react';
import { translations } from '../../../../../../../elements/Translation/translations';
import { formatDate } from '../../../../../../../lib/Utils';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import { HouseMoneySettlementContext } from '../../../../../../../contexts/HouseMoneySettlementContext';
import { PropertyListContext } from '../../../../../../../contexts/PropertyListContext';

export function useRightSideComponents() {
  const { tl } = useContext(LanguageContext);
  const {
    inCreation, houseMoneySettlementState, selectedEconomicYear, setSelectedEconomicYear,
  } : any = useContext(HouseMoneySettlementContext);
  const { selectedProperty } = useContext(PropertyListContext);


  const generatedOnLabel = useMemo(() => {
    if (inCreation) {
      return `${tl(translations.pages.houseMoneySettlement.creationInitiatedOn1)} ${formatDate(houseMoneySettlementState.data?.created)} ${tl(translations.pages.houseMoneySettlement.creationInitiatedOn2)}`;
    }
    return `${tl(translations.pages.houseMoneySettlement.generatedOn)} ${formatDate(houseMoneySettlementState.data.created)}`;
  }, [houseMoneySettlementState.data?.created, inCreation]);

  return {
    generatedOnLabel,
    selectedEconomicYear,
    setSelectedEconomicYear,
    selectedProperty,
  };
}
