import React, { useContext } from 'react';
import { useSectionManager } from 'services/SectionManager/useSectionManager';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import SecondarySectionWrapper from 'storybook-components/sections/SecondarySectionWrapper/SecondarySectionWrapper';
import ReactDOM from 'react-dom';
import OtherIncomeTable from './components/OtherIncomeTable/OtherIncomeTable';
import IncomeTable from './components/IncomeTable/IncomeTable';
import { getSectionPortalMountNode } from '../../services/utils';


const RentIncomeSection = ({ sectionNumber }: { sectionNumber: number }) => {
  const { openSectionId } = useSectionManager();
  const { tl } = useContext(LanguageContext);

  const renderContent = (
    <PrimarySectionWrapper
      sectionId="rentIncomeSection"
      sectionNumber={sectionNumber}
      sectionTitle={tl(translations.report.sections.incomeSection.title)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
    >
      <SecondarySectionWrapper title={`${sectionNumber}.1 ${tl(translations.report.sections.incomeSection.rentIncomeTitle)}`}>
        <IncomeTable />
      </SecondarySectionWrapper>
      <SecondarySectionWrapper title={`${sectionNumber}.2 ${tl(translations.report.sections.incomeSection.otherIncomeTitle)}`}>
        <OtherIncomeTable />
      </SecondarySectionWrapper>
    </PrimarySectionWrapper>
  );

  return (
    <>
      {renderContent}
      {ReactDOM.createPortal(renderContent, getSectionPortalMountNode())}
    </>
  );
};

export default RentIncomeSection;
