import {
  ContractLegacyControllerApi, ContractProjectionDtoDunningLevelEnum, ContractUpdateDtoDunningLevelEnum, ContractUpdateDtoTypeEnum, UnitContractProjectionDtoDunningLevelEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { getContractUpdateDtoFromProjection } from 'pages/ContractEditor/services/useSubmitContractCoreInformation';
import { useContext } from 'react';

export const useUpdateContractDunningLevel = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const contractController = new ContractLegacyControllerApi(apiConfiguration('accounting'));


  const updateContractDunningLevel = (contractId: number, dunningLevel: ContractUpdateDtoDunningLevelEnum | ContractProjectionDtoDunningLevelEnum | UnitContractProjectionDtoDunningLevelEnum) => (
    contractController
      .getUnitContractUsingGET({ contractId })
      .then((resp) => {
        const contractUpdateDtos = [
          {
            ...getContractUpdateDtoFromProjection(resp),
            dunningLevel: dunningLevel as unknown as ContractUpdateDtoDunningLevelEnum,
          },
        ];
        return contractController.updateContractsUsingPUT({ contractUpdateDtos });
      }));

  return {
    updateContractDunningLevel,
  };
};
