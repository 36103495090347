import React, {useRef, useState} from "react";
import "./ResizableContainer.scss";

export default function ResizableContainer({ className, children, initialWidth, minWidth, maxWidth }: ResizableContainerProps): JSX.Element {

  const [size, setSize] = useState(initialWidth);
  const [prevMouseX, setPrevMouseX] = useState(-1);
  const [dragging, setDragging] = useState(false);
  const mouseXRef:any = useRef();
  const sizeRef:any = useRef();
  mouseXRef.current = prevMouseX;
  sizeRef.current = size; 

  const getMousePosition = (e:any) => {
    if (mouseXRef.current === -1) {
      setPrevMouseX(e.clientX);
      return;
    }
    let newSize: number = sizeRef.current + mouseXRef.current - e.clientX;

    //For a smoother UX
    if (newSize >= maxWidth+10 || newSize <= minWidth-10) {
      onDragEnd();
    }
    newSize = Math.max(Math.min(newSize, maxWidth),minWidth); 
    setSize(newSize);
    setPrevMouseX(e.clientX);
  }

  const onDragStart = (e:any) => {
    setDragging(true);
    window.addEventListener("mousemove", getMousePosition, false);
    window.addEventListener("mouseup", onDragEnd, false);
  }
  
  const onDragEnd = () => {
    setPrevMouseX(-1);
    setDragging(false);
    window.addEventListener("mouseup", onDragEnd);
    window.removeEventListener("mousemove", getMousePosition);
  }

  return (
    <div 
      className={`ResizableContainer ${className} ${dragging? 'dragging':''}`}
      style={{width: size}}
    >
      <div 
        className="drag-handle"
        onMouseDown={onDragStart}
      >
        <div className="drag-dot" />
        <div className="drag-dot" />
        <div className="drag-dot" />
      </div>
      {children}
    </div>
  )
}

interface ResizableContainerProps {
  className: string, 
  children: JSX.Element | JSX.Element[], 
  initialWidth: number, 
  minWidth: number, 
  maxWidth: number
}

ResizableContainer.defaultPorps = {
  className: "",
  maxWidth: window.innerWidth,
  minWidth: 400,
  initialWidth: 600,
}