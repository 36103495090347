import React from 'react';
import Icon from '@ant-design/icons';
import './ActionBarSelectionCounter.scss';
import { ICONS } from 'components/icons';

type ActionBarSelectionCounterProps = {
  children: React.ReactNode;
}

const ActionBarSelectionCounter = ({ children }: ActionBarSelectionCounterProps) => (
  <div className="ActionBarSelectionCounter">
    <Icon className="icon" component={ICONS.checkBoxCheckMark} />
    {children}
  </div>
);

export default ActionBarSelectionCounter;
