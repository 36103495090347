import React from 'react';
import { useReserveFundCell } from './useReserveFundCell';
import { CurrencyInput } from '../../../../../../elements/Inputs/CurrencyInput/CurrencyInput';

interface Props {
  rowIndex: number, // to find it faster
  columnIndex: number, // to find it faster
  isEditable: boolean,
}

const ReserveFundCell = ({
  rowIndex,
  columnIndex,
  isEditable,
}: Props) => {
  const {
    value,
    onChange,
  } = useReserveFundCell(rowIndex, columnIndex);

  return (
    <CurrencyInput
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      disabled={!isEditable}
    />
  );
};

export default ReserveFundCell;
