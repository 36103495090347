import { useContext } from 'react';
import { AmountDirectionAndProcessingValuesContext } from './HouseMoneySettlementCloseYearContext';


export const useHouseMoneySettlementTableSummary = () => {
  const amountDirectionAndProcessingValues = useContext(AmountDirectionAndProcessingValuesContext);

  const totalAmount = amountDirectionAndProcessingValues?.reduce((acc, curr) => acc + (curr.amount! * (curr.direction === 'incoming' ? 1 : -1) ?? 0), 0) ?? 0;

  return { totalAmount, distributionValues: amountDirectionAndProcessingValues };
};
