import React from 'react';
import History from 'history';
import { RouteProps } from 'react-router';
import { FocusRoute } from 'navigation/FocusRoute';
import { OverlayRoute } from './OverlayRoute/OverlayRoute';
import { MainRoute } from './MainRoute';

declare const navigationTypes: ['overlay', 'focus', 'main'];
declare type NavigationType = (typeof navigationTypes) [number];

interface RouteDeclaration extends RouteProps {
  subRoutes?: RouteDeclaration[]
  type?: NavigationType,
  width?: string,
  addBreadcrumb?: boolean,
}

export interface RouteDefinition {
  context?: React.FC
  subRoutes: RouteDeclaration[],
}


interface RenderRoutesProps {
  routes: RouteDefinition[]
  children?: React.ReactNode
  location?: Object,
}

/**
 * Switch is hack: Either render the new layout or the old one,
 */
export const RenderRoutes = ({ routes, children, location }: RenderRoutesProps) => (
  <>
    {routes.map((route, i) => {
      const ContextElement = route.context ? route.context : React.Fragment;
      return (
        <ContextElement key={i}>
          {route.subRoutes?.map(subRoute => <NestedRoute location={location} key={subRoute.path as string} route={subRoute} />)}
        </ContextElement>
      );
    })}
    {children || <></>}
  </>
);

const NestedRoute: React.FC<{ route: RouteDeclaration, location?: Object }> = ({ route, location }) => {
  const {
    subRoutes, type, path, ...routeProps
  } = route;
  let RouteComponent;
  switch (type) {
  case 'focus':
    RouteComponent = FocusRoute;
    break;
  case 'overlay':
    RouteComponent = OverlayRoute;
    break;
  default:
    RouteComponent = MainRoute;
  }
  return (
    <React.Fragment>
      <RouteComponent location={location as History.Location<any>} {...routeProps} path={path} />
      {subRoutes && subRoutes.map(subRoute => (
        <NestedRoute
          key={subRoute.path as string}
          location={location}
          route={subRoute}
        />
      ))}
    </React.Fragment>
  );
};
