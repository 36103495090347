import { ContactLegacyControllerApi, ContactLegacyDto, ContactLegacyDtoTypeEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { getViewCompanyRoute, getViewPersonRoute } from 'pages/Contacts/routes';
import { contactPageTranslations } from './contactPageTranslations';

/**
 * Redirects to legacy contact viewing pages based on contact type
 */
export const ContactPage = () => {
  const [redirectPath, setRedirectPath] = useState('');
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const { contactId: contactIdString } = useParams<{ contactId: string }>();
  const contactId = parseInt(contactIdString, 10);

  const loadRedirectPath = () => {
    contactControllerApi.getContactUsingGET({ contactId })
      .then((contact: ContactLegacyDto) => {
        if (contact.type === ContactLegacyDtoTypeEnum.PERSON) {
          setRedirectPath(getViewPersonRoute({ personId: contactId }));
        } else {
          setRedirectPath(getViewCompanyRoute({ companyId: contactId }));
        }
      }).catch(() => {
        showNotification({ type: 'warning', message: tl(contactPageTranslations.contactNotFound) });
        setRedirectPath('/persons');
      });
  };
  useEffect(loadRedirectPath, [contactId]);
  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (<LoadingScreen />);
};
