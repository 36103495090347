import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { useSelectUserRole } from 'pages/Users/InviteUsers/components/SelectUserRole/useSelectUserRole';
import React from 'react';


export const SelectUserRole = ({ id }: {id: string}) => {
  const {
    onChange, options, value, validationState,
  } = useSelectUserRole(id);
  return (
    <SelectInput options={options} onChange={onChange} value={value} validationState={validationState} />
  );
};
