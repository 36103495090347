import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { translations as ownersMeetingProtocolTranslations } from '../../translations';

const EmptyAgendaItemsListComponent = () => {
  const { tl } = useContext(LanguageContext);

  return (
    <EmptyTableMultilineMessage description={tl(ownersMeetingProtocolTranslations.agendaItemsTable.noItems)} />
  );
};

export default EmptyAgendaItemsListComponent;
