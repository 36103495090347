import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import Amount from 'storybook-components/typography/Amount/Amount';

interface PropertyOwnerPaymentsTableColumns {
    owner: string,
    paymentsFromOwner: number,
    paymentsToOwner: number,
    balance: number
}

export const usePropertyOwnerPaymentsTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('usePropertyOwnerPaymentsTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances } = profitAndLossReportContext;

  const dataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    const parentPropertyOwnerAccountCodes = Object.keys(accountBalances.data)
      .filter(accCode => (
        accCode.match(/^301\/\d+$/)
      ));

    const ds = parentPropertyOwnerAccountCodes.map((ownerAccountCode) => {
      const paymentsFromOwnerAccountCode = `${ownerAccountCode}/2`;
      const paymentsToOwnerAccountCode = `${ownerAccountCode}/1`;
      const parentAccountName = accountBalances.data[ownerAccountCode].accountName;
      return {
        owner: parentAccountName.replace(parentAccountName.match(/.*?- /)[0], ''), // remove everything before first `-`
        paymentsFromOwner: accountBalances.data[paymentsFromOwnerAccountCode].creditAmountWithinDateRange,
        paymentsToOwner: -accountBalances.data[paymentsToOwnerAccountCode].debitAmountWithinDateRange,
        balance: accountBalances.data[paymentsFromOwnerAccountCode].creditAmountWithinDateRange - accountBalances.data[paymentsToOwnerAccountCode].debitAmountWithinDateRange,
      };
    });
    return ds;
  }, [accountBalances.data]);


  // to only iterate through the dataSource once we calculate them all in one go
  const sums = dataSource.reduce((acc, curr) => ({
    ownerPaymentsFromSum: acc.ownerPaymentsFromSum + curr.paymentsFromOwner,
    ownerPaymentsToSum: acc.ownerPaymentsToSum + curr.paymentsToOwner,
    ownerPaymentsBalanceSum: acc.ownerPaymentsBalanceSum + curr.balance,
  }), {
    ownerPaymentsFromSum: 0,
    ownerPaymentsToSum: 0,
    ownerPaymentsBalanceSum: 0,
  });

  const columns: ColumnsType<PropertyOwnerPaymentsTableColumns> = [
    {
      title: tl(translations.report.sections.propertyOwnerPaymentsSection.columns.ownerName),
      dataIndex: 'owner',
    },
    {
      title: tl(translations.report.sections.propertyOwnerPaymentsSection.columns.deposits),
      dataIndex: 'paymentsFromOwner',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.propertyOwnerPaymentsSection.columns.withdrawals),
      dataIndex: 'paymentsToOwner',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.propertyOwnerPaymentsSection.columns.balance),
      dataIndex: 'balance',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  return {
    columns,
    dataSource,
    ownerPaymentsFromSum: sums.ownerPaymentsFromSum,
    ownerPaymentsToSum: sums.ownerPaymentsToSum,
    ownerPaymentsBalanceSum: sums.ownerPaymentsBalanceSum,
  };
};
