import {
  AddressDto,
  BuildingDto,
  BuildingEpo,
  ContractContactCreationDtoRoleEnum,
  ContractCreationDto,
  ContractCreationDtoDunningLevelEnum,
  ContractCreationDtoTypeEnum,
  ContractLegacyControllerApi, ContractProjectionDto, ContractProjectionDtoTypeEnum, ContractUpdateDto,
  PropertyCreationDtoAdministrationTypeEnum,
  PropertyLegacyControllerApi, PropertyLegacyDto, PropertyUpdateDto, PropertyUpdateDtoVatRelevanceEnum, UnitCreationDto, UnitLegacyControllerApi, UnitLegacyDto, UnitProjectionDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { useContext, useState } from 'react';
import { showNotification } from 'lib/Notification';
import { translations } from '../../../../../elements/Translation/translations';

const getPropertyCreationDto = (wegPropertyAddress: AddressDto, wegOwnerContractId, sevPropertyName: string) => ({
  administrationType: PropertyCreationDtoAdministrationTypeEnum.SEV,
  propertyIdInternal: null,
  name: sevPropertyName,
  ...wegPropertyAddress,
  ownerContractId: wegOwnerContractId,
});

const getBuildingUpdateDto = (sevProperty: PropertyLegacyDto, wegBuilding?: BuildingDto) => {
  const building: BuildingEpo = wegBuilding ? {
    ...(wegBuilding as unknown as BuildingEpo),
    id: null,
    buildingHrId: undefined,
    propertyId: sevProperty.id,
  } : undefined;

  return building ? [building] : [];
};


const getPropertyUpdateDto = (sevProperty: PropertyLegacyDto, wegProperty: PropertyLegacyDto, wegBuilding: BuildingDto, selectedTenantContarctsAreVatRelevant:boolean) => ({
  ...sevProperty,
  ...sevProperty.propertyAddress,
  id: sevProperty.id,
  buildings: getBuildingUpdateDto(sevProperty, wegBuilding),
  billingAddress: {
    ...wegProperty.billingAddress,
    id: null,
  },
  feeType: wegProperty.feeType,
  yearlyFeeAdaptation: wegProperty.yearlyFeeAdaptation,
  dunningFeeNet: wegProperty.dunningFeeNet,
  wageAccountingFee: wegProperty.wageAccountingFee,
  noParticipationDirectDebitProcedureFee: wegProperty.noParticipationDirectDebitProcedureFee,
  socialAuditFee: wegProperty.socialAuditFee,
  legalDefaultActionFee: wegProperty.legalDefaultActionFee,
  certificate35AFee: wegProperty.certificate35AFee,
  additionalBenefitsHourlyFee: wegProperty.additionalBenefitsHourlyFee,
  directorHourlyFee: wegProperty.directorHourlyFee,
  assistantHourlyFee: wegProperty.assistantHourlyFee,
  propertySupervisorHourlyFee: wegProperty.propertySupervisorHourlyFee,
  authorizedOfficerHourlyFee: wegProperty.authorizedOfficerHourlyFee,
  maintenanceType: wegProperty.maintenanceType,
  maintenanceVolume: wegProperty.maintenanceVolume,
  specialOwnerAssemblyFlatFee: wegProperty.specialOwnerAssemblyFlatFee,
  economicYearEnd: wegProperty.economicYearEnd,
  economicYearStart: wegProperty.economicYearStart,
  managementCompany: wegProperty.managementCompany,
  accountantContactId: wegProperty.accountantContactId,
  accountantName: wegProperty.accountantName,
  supervisorContactId: wegProperty.supervisorContactId,
  supervisorName: wegProperty.supervisorName,
  vatRelevance: selectedTenantContarctsAreVatRelevant ? PropertyUpdateDtoVatRelevanceEnum.PARTIALLY_RELEVANT : PropertyUpdateDtoVatRelevanceEnum.NOT_RELEVANT,
} as unknown as PropertyUpdateDto);

const getUnitCreationDto = (wegUnit: UnitLegacyDto, sevProperty:PropertyLegacyDto) => ({
  domainID: wegUnit.domainId,
  propertyId: sevProperty.id,
  buildingId: isEmpty(sevProperty.buildings) ? undefined : sevProperty.buildings[0].id,
  externalId: undefined,
  casaviId: undefined,
  unitNrSharingDeclaration: wegUnit.unitNrSharingDeclaration,
  unitType: wegUnit.unitType,
  isOwnedByWeg: wegUnit.isOwnedByWeg,
  floor: wegUnit.floor,
  position: wegUnit.position,
  unitRank: 1,
  livingArea: wegUnit.livingArea,
  heatingArea: wegUnit.heatingArea,
  persons: wegUnit.persons,
  propertyShare: wegUnit.propertyShare,
} as unknown as UnitCreationDto);

const getContractCreationDto = (wegContract: ContractProjectionDto, type: ContractCreationDtoTypeEnum, sevPropertyId: number, sevUnitId: number | undefined, contractGroupId: number | undefined) => ({
  propertyId: sevPropertyId,
  unitId: sevUnitId,
  signedDate: wegContract.signedDate,
  startDate: wegContract.startDate,
  endDate: wegContract.endDate,
  contractNumber: wegContract.contractNumber,
  documentUrl: wegContract.documentUrl,
  contacts: wegContract.contacts,
  mailingContactId: wegContract.mailingContact.contactId,
  bankAccountId: wegContract.bankAccountId,
  type,
  isVacant: wegContract.isVacant,
  additionalData: wegContract.additionalData ? JSON.stringify(wegContract.additionalData) : undefined,
  vatRelevance: wegContract.vatRelevance,
  dunningLevel: wegContract.dunningLevel as unknown as ContractCreationDtoDunningLevelEnum,
  contractGroupId,
} as unknown as ContractCreationDto);

const createPropertyOwnerContractCreationDto = (wegOwnerContract: ContractProjectionDto, sevPropertyId: number): ContractCreationDto => {
  // create property owner contract
  const sevPropertyOwnerContract = getContractCreationDto(
    wegOwnerContract,
    ContractCreationDtoTypeEnum.PROPERTY_OWNER,
    sevPropertyId,
    undefined,
    undefined,
  );

  const contactsWithRolesOtherThanCouncil = sevPropertyOwnerContract.contacts.filter(
    (contact) => {
      const contactHasNoRoles = isEmpty(contact.role);
      /**
       * The COUNCIL, HEAD_OF_COUNCIL roles do not exist in MV's and SEV's therefore
       */
      const contactHasRolesOtherThanCouncil = !isEmpty(contact.role.filter(role => ![
        ContractContactCreationDtoRoleEnum.COUNCIL,
        ContractContactCreationDtoRoleEnum.HEAD_OF_COUNCIL,
      ].includes(role)));

      return contactHasNoRoles || contactHasRolesOtherThanCouncil;
    },
  );

  // Replace OWNER roles with PROPERTY_OWNER
  sevPropertyOwnerContract.contacts = contactsWithRolesOtherThanCouncil.map((contact) => {
    const contactHadOwnerRole = contact.role.find(r => r === ContractContactCreationDtoRoleEnum.OWNER);
    const contactRolesExceptOwnerAndCouncil = contact.role.filter(r => ![
      ContractContactCreationDtoRoleEnum.OWNER,
      ContractContactCreationDtoRoleEnum.COUNCIL,
      ContractContactCreationDtoRoleEnum.HEAD_OF_COUNCIL,
    ].includes(r));

    if (contactHadOwnerRole) {
      contactRolesExceptOwnerAndCouncil.push(ContractContactCreationDtoRoleEnum.PROPERTY_OWNER);
    }

    return ({
      ...contact,
      role: contactRolesExceptOwnerAndCouncil,
    });
  });

  return sevPropertyOwnerContract;
};

export const useCreateSEVPropertyAndCopyDataFromWEG = () => {
  const { tl } = useContext(LanguageContext);

  const { apiConfiguration } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);

  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const unitLegacyControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));

  const contractController = new ContractLegacyControllerApi(apiConfiguration('accounting'));

  const onGetPropertyById = (propertyId:number) => propertyControllerApi.getPropertyByIdUsingGET({ propertyId });

  const onGetContracts = (contractIds:number[]) => contractController.getContractsUsingGET({ contractIds });

  const onCreateSEVProperty = (wegPropertyId:number, ownerContractId:number, tenantContractIds:number[], selectedTenantContarctsAreVatRelevant:boolean) => {
    let contractIds = [ownerContractId];
    if (!isEmpty(tenantContractIds)) {
      contractIds = contractIds.concat(tenantContractIds);
    }

    setLoading(true);

    return Promise.all([onGetPropertyById(wegPropertyId), onGetContracts(contractIds)])
      .then(([wegProperty, wegContracts]) => {
        const ownerContract = wegContracts.find(contract => contract.type === ContractProjectionDtoTypeEnum.OWNER);
        return unitLegacyControllerApi.getUnitUsingGET({ unitId: ownerContract.unitId })
          .then((wegUnit) => {
            const propertyName = `${wegProperty.name.replace('WEG', 'SEV')}-${
              wegUnit.unitNrSharingDeclaration}-${
              ownerContract.mailingContact.name
            }`;

            // create SEV property
            return propertyControllerApi.createPropertyUsingPOST({
              propertyCreationDto: getPropertyCreationDto(wegProperty.propertyAddress, ownerContractId, propertyName),
            })
              .then(sevProperty => Promise.resolve({
                wegUnit, wegContracts, sevProperty, wegProperty, selectedTenantContarctsAreVatRelevant,
              }));
          });
      })
      .catch((err) => {
        console.error(err.status);
        showNotification({
          key: 'savePropertyError',
          message: tl(translations.notifications.propertyEditingContext.saveError.message),
          type: 'error',
        });
        return undefined;
      });
  };

  const onUpdateProperty = ({
    wegUnit, wegContracts, sevProperty, wegProperty, selectedTenantContarctsAreVatRelevant,
  }: {
        wegUnit: UnitLegacyDto,
        wegContracts: ContractProjectionDto[],
        sevProperty: PropertyLegacyDto,
        wegProperty: PropertyLegacyDto,
        selectedTenantContarctsAreVatRelevant:boolean,
    }) => {
    const wegBuilding = wegProperty.buildings?.find(building => wegUnit.buildingId === building.id);

    return propertyControllerApi.updatePropertyUsingPUT({
      propertyUpdateDto: getPropertyUpdateDto(sevProperty, wegProperty, wegBuilding, selectedTenantContarctsAreVatRelevant),
    })
    // we don't have 'then' here because we will always have a validation error (no propertyIdInternal)
      .catch(err => err.response.json().then((e) => {
        console.error(e);
        return Promise.resolve({
          wegUnit, wegContracts, sevProperty: e.savedEntity,
        });
      })
        .catch((e) => {
          console.error(e);
          return Promise.resolve({
            wegUnit, wegContracts, sevProperty,
          });
        }));
  };

  const onCreateSEVUnit = ({
    wegUnit, wegContracts, sevProperty,
  }: {
        wegUnit: UnitLegacyDto,
        wegContracts: ContractProjectionDto[],
        sevProperty: PropertyLegacyDto
    }) => unitLegacyControllerApi.createUnitsUsingPOST({ unitCreationDtoList: [getUnitCreationDto(wegUnit, sevProperty)] })
    .then(sevUnits => Promise.resolve({ sevUnit: sevUnits[0], sevProperty, wegContracts }));

  const onCreateAndUpdateContracts = ({ sevUnit, sevProperty, wegContracts }
    : { sevUnit: UnitProjectionDto,
         sevProperty: PropertyLegacyDto,
          wegContracts: ContractProjectionDto[] }) => {
    const wegOwnerContract = wegContracts.find(contract => contract.type === ContractProjectionDtoTypeEnum.OWNER);
    const wegTenantContracts = wegContracts.filter(contract => contract.type !== ContractProjectionDtoTypeEnum.OWNER);
    const promises = [];

    const sevPropertyOwnerContractCreationDtos: ContractCreationDto[] = [
      createPropertyOwnerContractCreationDto(wegOwnerContract, sevProperty.id),
    ];

    promises.push(copyWegTenantContractsToSev(sevProperty.id, sevUnit.id, wegTenantContracts));
    promises.push(contractController.createContractsUsingPOST({ contractCreationDtos: sevPropertyOwnerContractCreationDtos }));

    return Promise.all(promises)
      .then(() => sevProperty)
      .catch(() => sevProperty);
  };

  const copyWegTenantContractsToSev = (sevPropertyId: number, sevUnitId: number, wegTenantContracts: ContractProjectionDto[]): Promise<any> => {
    if (isEmpty(wegTenantContracts)) {
      return Promise.resolve();
    }
    if (!loading) setLoading(true);

    const sevTenatContractCreateDtos: ContractCreationDto[] = wegTenantContracts.map(wegTenantContract => getContractCreationDto(
      wegTenantContract,
      ContractCreationDtoTypeEnum.TENANT,
      sevPropertyId,
      sevUnitId,
      wegTenantContract.unitContractId,
    ));

    // set the contractGroupId on the WEG' tenant contracts
    const wegContractUpdateDtos: ContractUpdateDto[] = wegTenantContracts.map(wegTenantContract => ({
      ...wegTenantContract,
      contractGroupId: wegTenantContract.unitContractId,
      additionalData: wegTenantContract.additionalData
        ? JSON.stringify(wegTenantContract.additionalData)
        : undefined,
      mailingContactId: wegTenantContract.mailingContact.contactId,
    } as unknown as ContractUpdateDto));

    const updateWegContractsPromise = contractController.updateContractsUsingPUT({ contractUpdateDtos: wegContractUpdateDtos });

    const createSevTenantContractsPromise = contractController.createContractsUsingPOST({ contractCreationDtos: sevTenatContractCreateDtos });

    return Promise.all([updateWegContractsPromise, createSevTenantContractsPromise]).finally(() => setLoading(false));
  };

  const onCreateSEVPropertyAndCopyDataFromWEG = (propertyId, ownerContractId, tenantContractIds, selectedTenantContarctsAreVatRelevant) => onCreateSEVProperty(propertyId, ownerContractId, tenantContractIds, selectedTenantContarctsAreVatRelevant)
    .then(onUpdateProperty)
    .then(onCreateSEVUnit)
    .then(onCreateAndUpdateContracts)
    .catch((err) => {
      console.error(err.status);
      showNotification({
        key: 'savePropertyValidationError',
        message: tl(translations.notifications.propertyEditingContext.saveValidationError.message),
        type: 'warning',
      });
    })
    .finally(() => {
      setLoading(false);
    });


  return {
    onCreateSEVPropertyAndCopyDataFromWEG,
    copyWegTenantContractsToSev,
    loading,
  };
};
