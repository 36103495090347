import { PropertyLegacyDto, UnitContractProjectionDto } from 'api/accounting';
import { getContractEditingRoute } from 'pages/Property/routes';

export const useContractLink = (property: PropertyLegacyDto, contract: UnitContractProjectionDto) => {
  const url = getContractEditingRoute({propertyHrId: property.propertyHrId, contractDbId: contract.unitContractId, unitDbId: contract?.unitId});

  const label = `${property?.propertyIdInternal} · ${property?.name} · ${contract?.unitNrSharingDeclaration ? `${contract?.unitNrSharingDeclaration} · ` : ''}${contract?.mailingContact.name}`;

  return {
    label,
    url,
  };
};
