import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { translations } from '../../../../../../elements/Translation/translations';
import { Column, FORMAT } from '../../../../../../elements/SmartTable/SmartTable';
import { navigateConcat } from '../../../../../../navigation/History';

export const displayAccountInfo = (e: any, propertyHrId: string, accountCode: string) => {
  navigateConcat(`/${propertyHrId}/account/${encodeURIComponent(accountCode)}`);
  e.stopPropagation();
};
export const usePropertyCardColumns = ({ showShares, isEconomicPlan }: any): (Column)[] => {
  const { tl } = useContext(LanguageContext);
  const { propertyHrId } = useParams<{propertyHrId:string}>();
  const baseColumns: any = [
    {
      title: tl(translations.pages.houseMoneySettlement.propertyAccountBalances.table.headers.account),
      dataKey: 'accountCode',
      className: 'account',
      defaultWidth: '30%',
      render: (accountCode: any, row: any) => {
        if (row.accountCode) {
          return (
            <button
              onClick={(e: any) => displayAccountInfo(e, propertyHrId!, row.accountCode)}
              className="link"
            >
              <span className="link-label">{`${accountCode} ${row.accountName}`}</span>
            </button>
          );
        }
        return row.account;
      },
    },
    {
      title: tl(translations.pages.houseMoneySettlement.propertyAccountBalances.table.headers.distributionKey),
      dataKey: isEconomicPlan ? 'economicPlanDistributionMode' : 'houseMoneyDistributionMode',
      defaultWidth: '20%',
      render: (item: any) => (
        <span>{item ? tl(translations.enums.distributionMode[`${item}`]) : ''}</span>
      ),
    },
  ];
  if (showShares) {
    baseColumns.push({
      title: tl(translations.pages.houseMoneySettlement.propertyAccountBalances.table.headers.shares),
      dataKey: 'totalShares',
      defaultWidth: '20%',
    });
  }
  baseColumns.push({
    title: tl(translations.pages.houseMoneySettlement.propertyAccountBalances.table.headers.costs),
    dataKey: 'totalSum',
    format: FORMAT.CURRENCY_DASH,
    defaultWidth: showShares ? '30%' : '50%',
  });
  return baseColumns;
};
