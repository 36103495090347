import { Alert, ConfigProvider, Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import WarningList from '../WarningList/WarningList';
import DocumentSearchBar from './components/DocumentSearch/DocumentSearchBar';
import { useMessageDocumentTable } from './useMessageDocumentTable';
import './MessageDocumentTable.scss';


const MessageDocumentTable = () => {
  const { tl } = useContext(LanguageContext);
  const {
    datasource, columns, loading, onRow, components, expandedRowKeys, onExpand, emptyTableText,
  } = useMessageDocumentTable();

  return (
    <PrimarySectionWrapper
      sectionId="MessageDocumentTable"
      className="MessageDocumentTable"
      sectionNumber={1}
      sectionTitle={tl(messageSendingTranslations.documentTable.title)}
      isOpen
    >
      <Alert
        message={tl(messageSendingTranslations.documentTable.info)}
        type="info"
        showIcon
        icon={<Icon component={ICONS.info} />}
      />
      <WarningList sectionNr={1} />
      <DocumentSearchBar />
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={emptyTableText} />}>
        <Table
          rowKey="rowKey"
          columns={columns}
          dataSource={datasource}
          pagination={false}
          scroll={{ y: 500 }}
          expandable={{
            expandedRowKeys,
            expandRowByClick: true,
            onExpand,
            expandIconColumnIndex: 1,
            // setting to 0 because `expandIconCell` adds a placeholder when needed
            indentSize: 0,
            // setting to null because we're using `expandIconCell`
            expandIcon: () => null,
          }}
          rowClassName={r => (!r.movable && r.children ? 'parent-row-level-0' : '')}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          onRow={(record, index) => onRow(record, index, record.movable)}
          components={components}
        />
      </ConfigProvider>
    </PrimarySectionWrapper>
  );
};


export default MessageDocumentTable;
