import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { useContext } from 'react';

export const useInvoiceBookingValidation = (index: number) => {
  const { invoiceBookingValidationErrors } = useContext(InvoiceEditingContext);
  const bookingValidationError = invoiceBookingValidationErrors[index];

  return {
    bookingValidationError,
  };
};
