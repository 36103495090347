import '../../OwnersMeetingProtocolEditing.scss';
import './OwnerMeetingProtocolEditingHeader.scss';

import React, {
  useContext,
} from 'react';

import { DownloadMenu } from 'components/DownloadMenu/DownloadMenu';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router';
import { getStateColor } from 'storybook-components/headers/utils';

import { DropMenu } from 'components/DropMenu/DropMenu';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { Modal } from 'antd';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import {
  translations as ownersMeetingProtocolTranslations,
} from '../../../../translations';
import {
  useCreateSignatureListDocument,
} from './service/useCreateSignatureListDocument';
import {
  useOwnerMeetingProtocolHeaderEditing,
} from './service/useOwnerMeetingProtocolHeaderEditing';

interface OwnerMeetingProtocolHeaderProps {
  isProtocolDone: boolean,
  save: (openNextSection: boolean) => void,
  editMode: boolean,
  onClickFinalize: () => void,
  onRevertToDraft: () => void,
}

const OwnerMeetingProtocolEditingHeader = ({
  isProtocolDone, save, editMode, onClickFinalize, onRevertToDraft,
}: OwnerMeetingProtocolHeaderProps) => {
  const { tl } = useContext(LanguageContext);
  const { etvId } = useParams<{ etvId: string }>();

  const {
    onClose,
    ownersMeeting,
    loading,
    property,
    isValid,
    onDeleteOwnersMeetingProtocol,
    showSubtitle,
  } = useOwnerMeetingProtocolHeaderEditing();

  const { downloadDocument, loadingDocument } = useCreateSignatureListDocument();
  const { goBack } = useContext(OverlayContext);

  const onClickRevertToDraft = () => {
    Modal.confirm({
      title: tl(ownersMeetingProtocolTranslations.revertToDraftModal.title),
      content: tl(ownersMeetingProtocolTranslations.revertToDraftModal.content),
      okText: tl(ownersMeetingProtocolTranslations.revertToDraftModal.okText),
      cancelText: tl(ownersMeetingProtocolTranslations.revertToDraftModal.cancelText),
      okButtonProps: { className: 'Button', danger: true },
      cancelButtonProps: { className: 'Button' },
      onOk: onRevertToDraft,
      width: 450,
      icon: <Icon component={ICONS.statusError} />,
    });
  };

  const closeButton = (
    <Button
      key="backButton"
      type="text"
      onClick={onClose}
      loading={loading}
    >
      {tl(ownersMeetingProtocolTranslations.editPage.close)}
    </Button>
  );

  const saveButton = (
    <Button
      key="saveAllButton"
      onClick={() => save(false)}
      loading={loading}
    >
      {tl(ownersMeetingProtocolTranslations.editPage.saveAll)}
    </Button>
  );

  const finalizeButton = (
    <Button
      key="finalizeButton"
      type="primary"
      className="right-side-button icon"
      onClick={onClickFinalize}
      loading={loading}
      disabled={!etvId || !isValid}
    >
      {tl(ownersMeetingProtocolTranslations.editPage.finalize)}
    </Button>
  );

  const revertToDraftButton = (
    <Button
      key="revertToDraftButton"
      onClick={onClickRevertToDraft}
      loading={loading}
    >
      {tl(ownersMeetingProtocolTranslations.editPage.revertToDraft)}
    </Button>
  );

  const deleteMenuButton = (
    <DropMenu
      key="ownersMeetingProtocolDeletionModal"
      options={[{
        label: tl(ownersMeetingProtocolTranslations.actions.delete),
        onClick: () => Modal.confirm({
          title: tl(ownersMeetingProtocolTranslations.deleteModal.title),
          content: tl(ownersMeetingProtocolTranslations.deleteModal.content),
          okText: tl(ownersMeetingProtocolTranslations.deleteModal.okText),
          cancelText: tl(ownersMeetingProtocolTranslations.deleteModal.cancelText),
          okButtonProps: { className: 'Button', danger: true },
          cancelButtonProps: { className: 'Button' },
          onOk: () => onDeleteOwnersMeetingProtocol(ownersMeeting.id, goBack()),
          width: 450,
          icon: <Icon component={ICONS.statusError} />,
        }),
      }]}
    />
  );

  const downloadMenuButton = (
    <DownloadMenu
      key="downloadMenuButton"
      loading={loadingDocument}
      options={[{
        label: tl(ownersMeetingProtocolTranslations.editPage.downloadSignatureList),
        onClick: () => {
          const title = `${moment().format('YYYY-MM-DD')}_${ownersMeeting.propertyIdInternal}_ETV Protokoll Anwesenheitsliste.pdf`;
          downloadDocument(title);
        },
      }]}
    />
  );

  const rightSideButtons = [closeButton];

  if (editMode) {
    rightSideButtons.push(deleteMenuButton);
    rightSideButtons.push(downloadMenuButton);
  }

  if (editMode && !isProtocolDone) {
    rightSideButtons.push(saveButton);
    rightSideButtons.push(finalizeButton);
  }

  if (!loading && isProtocolDone) {
    rightSideButtons.push(revertToDraftButton);
  }


  const titleForCreatedEtv = editMode ? tl(ownersMeetingProtocolTranslations.editPage.editTitle) : tl(ownersMeetingProtocolTranslations.editPage.createTitle);
  return (
    <PageHeader
      title={etvId ? titleForCreatedEtv : tl(ownersMeetingProtocolTranslations.editPage.createTitle)}
      subtitle={showSubtitle && <PropertyInfo property={property} />}
      rightSideComponent={rightSideButtons}
      tagContent={ownersMeeting.status && ownersMeeting.propertyId !== undefined ? tl(ownersMeetingProtocolTranslations.states[ownersMeeting.status]) : ''}
      tagColor={getStateColor(ownersMeeting?.status) as PageHeaderTagColor}
      showLogo
    />
  );
};
export default OwnerMeetingProtocolEditingHeader;
