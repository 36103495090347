export interface Etg24Property {
    id: string,
    extId: string,
    title: string,
    managementType: string,
}

export interface Etg24Signatory {
    contractId: string,
    contact: {
        id: string,
    }
    firstName: string,
    lastName: string,
    agent: boolean,
    // add rest of the fields if needed
}

export interface Etg24Process {
    id: string,
    title: string,
    description: string,
    url: string,
}

export interface Etg24ContactExternalId {
    signatoryIds: string[],
    // add the rest if needed
}

export interface Etg24Credentials {
    accessToken: string,
    tenantName: string,
  }

export interface Etg24Contract {
    id: string,
    contractType: Etg24ContractTypeEnum,
    validity: { validFrom: string, validTo?: string },
    signatories: Etg24Signatory[],
    unitId: string,
    urls: { detail: string }
}

export enum Etg24ContractTypeEnum {
    OWNER = 'owner',
    TENANT = 'tenant'
}
