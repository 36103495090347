import { RouteDefinition } from '../../components/RenderRoutes';
import { BankConnections } from './Bankconnections/BankConnections';
import { TransactionImports } from './CamtImports/TransactionImports';

export const TransactionImportRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/transaction-imports/manual',
      component: TransactionImports,
    },
    {
      path: '/transaction-imports/connections',
      component: BankConnections,
    },
  ],
};
