import React, { useContext, useMemo } from 'react';
import './PowerOfAttorneyDocument.scss';
import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { useParams } from 'react-router';
import moment from 'moment';
import { LanguageContext } from 'contexts/LanguageContext';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { ICONS } from 'components/icons';
import { OwnersMeetingProtocolRecipientExtendedDisplayDto } from 'api/accounting';
import { DATE_FORMAT, formatDate } from 'lib/Utils';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import { OwnersMeetingProtocolContext } from '../../../../services/OwnersMeetingProtocolContext';
import { translations } from '../../../../translations';


export default function PowerOfAttorneyDocument(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);
  const { attendeeId } = useParams<{ attendeeId: string }>();
  const { ownersMeeting } = useContext(OwnersMeetingProtocolContext);

  const attendee = useMemo(() => {
    try {
      return ownersMeeting.data.protocolRecipients.filter((att: OwnersMeetingProtocolRecipientExtendedDisplayDto) => att.id === Number(attendeeId))[0];
    } catch (e) {
      return {};
    }
  }, [attendeeId, ownersMeeting]);
  const url = useMemo(() => {
    try {
      return attendee.documentUrl;
    } catch (e) {
      return '';
    }
  }, [attendee]);

  const fileName = useMemo(() => {
    try {
      return `${formatDate(moment(), DATE_FORMAT)}_${attendee.ownerName}_Vollmacht`;
    } catch (e) {
      return `${formatDate(moment(), DATE_FORMAT)}_Vollmacht`;
    }
  }, [attendee]);

  return (
    <div className="PowerOfAttorneyDocument">
      <div className="top-actionbar">
        <Button
          type="text"
          className="back-button"
          onClick={goBack}
        >
          <Icon component={ICONS.arrowLeft} />
          {tl(translations.editPage.close)}
        </Button>
      </div>
      <div className="pdf-preview">
        <SimplePdfViewer url={url} filename={fileName} renderTextLayer />
      </div>
    </div>
  );
}
