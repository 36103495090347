export const translations = {
  integrations: {
    title: {
      en: 'Integrations',
      de: 'Integrationen',
    },
    notAvailable: {
      en: 'No integrations available.',
      de: 'Keine Integrationen verfügbar.',
    },
    selectProperty: {
      en: 'Select a property from the first section to see related tickets.',
      de: 'Bitte Objekt im Rechnungserfassung auswählen um seine Vorgänge zu sehen.',
    },
    sevNotSupported: {
      en: 'SEV (with WEG connection) properties do not support integrations.',
      de: 'SEV (mit WEG-Verbindung) Objekte unterstützen keine Integrationen.',
    },
  },
};
