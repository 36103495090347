import { UserDto } from 'api/user';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { useState } from 'react';

export interface UsersListContextType {
  usersList: DefaultDataInterface<UserDto[]>,
  setUsersList: React.Dispatch<React.SetStateAction<DefaultDataInterface<UserDto[]>>>,
  filterState: any,
  setFilterState: React.Dispatch<React.SetStateAction<any>>
  sortOrder: 1 | -1,
  setSortOrder: React.Dispatch<React.SetStateAction<number>>
}

export const UsersListContext = React.createContext<UsersListContextType | undefined>(undefined);

const UsersContextProvider = ({ children }) => {
  const [usersList, setUsersList] = useState<DefaultDataInterface<UserDto[]>>(DEFAULT_DATA([]));
  const [filterState, setFilterState] = useState<any>(undefined);
  const [sortOrder, setSortOrder] = useState<1| -1>(1);
  return (
    <UsersListContext.Provider value={{
      usersList,
      setUsersList,
      filterState,
      setFilterState,
      sortOrder,
      setSortOrder,
    }}
    >
      {children}
    </UsersListContext.Provider>
  );
};

export default UsersContextProvider;
