import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { LanguageContext } from './LanguageContext';
import { translations } from '../elements/Translation/translations';
import { showNotification } from '../lib/Notification';

export const PropertyContext: any = React.createContext({});

export default function PropertyProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);
  const [selectedPropertyHrId, setSelectedPropertyHrId] = useState<string>();
  const [property, setProperty] = useState(DEFAULT_DATA<any>({}));

  useEffect(() => {
    if (selectedPropertyHrId) {
      loadProperty(selectedPropertyHrId);
    }
  }, [selectedPropertyHrId]);

  const loadProperty = (propertyHrId: string): void => {
    setProperty(property.startLoading());
    backend.get(`${endpointUrls.PROPERTY}/${propertyHrId}`, {})
      .then((response: any) => {
        setProperty((state: any) => state.load(response));
      })
      .catch(() => {
        setProperty(property.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyContext.loadError.message),
          description: tl(translations.notifications.propertyContext.loadError.description),
          type: 'error',
        });
      });
  };

  return (
    <PropertyContext.Provider value={{
      property,
      selectedPropertyHrId,
      setSelectedPropertyHrId,
    }}
    >
      {children}
    </PropertyContext.Provider>
  );
}


PropertyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
