import { useContext, useEffect, useReducer } from 'react';
import _ from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import { round2dec } from 'lib/Utils';
import { InvoiceBookingWithNet } from 'contexts/functions/InvoiceEditingFunctions';
import { InvoiceBookingDto } from 'api/accounting';
import { translations } from '../../translations';
import { InvoiceBookingNS } from '../../Interfaces/InvoiceBookingInterfaces';
import { calculateLaborCostPercentage } from '../../sections/invoiceBooking/invoiceBookingUtils';

export const defaultInvoiceBooking: InvoiceBookingWithNet = {
  netAmount: 0,
  amount: 0,
  accountCode: '',
  bookingText: '',
  vatAmount: 0,
  vatPercentage: 19,
  vatEligibilityPercentage: undefined,
  laborCost: 0,
  laborCostPercentage: 0,
  laborCostType: undefined,
  unitId: undefined,
  bookingDate: undefined,
  overwriteBookingDate: false,
  incoming: false,
};

export interface InvoiceBookingReducerActions {
  type: 'addSplit' | 'removeSplit' | 'update' | 'updateSign' | 'setDefault' | 'recalculateDefault',
  idx?: number,
  key?: any,
  value?: any,
  notDirty?: boolean,
}


/**
 * Because we calculate with two decimals only, we cannot always calculate the value of `laborCostPercentage`
 * based on `laborCost` and `amount` directly. For example for amount 28.92 and laborCostPercentage of 10%
 * the calculated value of `laborCost` will be 2.89 - and if we calculate `laborCostPercentage` based on
 * these values, we will get 9.99% instead of 10% and it will be impossible to write 10% into the percentage
 * input field. Therefore we introduce a state variable for it that we initialize at load and modify if they
 * change the input values.
 */
const decorateInvoiceBookingWithLaborCostPercentage = (invoiceBooking: InvoiceBookingDto[]): InvoiceBookingWithNet[] => (
  invoiceBooking.map((booking) => {
    if (!booking.amount || !booking.laborCost) {
      return booking;
    }

    const laborCostPercentage = calculateLaborCostPercentage(booking.laborCost, booking.amount);
    return {
      ...booking,
      laborCostPercentage,
    };
  })
);


export function useInvoiceBookings({
  total, setDirty, propertyVatEligibilityShare,
}: InvoiceBookingNS.InvoiceBookingHookProps) {
  const { tl } = useContext(LanguageContext);
  const [invoiceBookings, dispatch] = useReducer(reducer, [defaultInvoiceBooking]);


  function reducer(state: InvoiceBookingWithNet[], action: InvoiceBookingReducerActions) {
    let newState: any;
    switch (action.type) {
      case 'addSplit': {
        if (setDirty) {
          setDirty(true);
        }
        newState = _.cloneDeep(state);
        const currentBookingDate = newState?.length ? newState[0].bookingDate : defaultInvoiceBooking.bookingDate;
        const currentOverwriteBookingDate = newState?.length ? newState[0].overwriteBookingDate : defaultInvoiceBooking.overwriteBookingDate;
        const currentVatEligibilityPercentage = newState?.[0]?.vatEligibilityPercentage;
        const currentVatEligibilityAmount = currentVatEligibilityPercentage !== undefined ? round2dec(newState[0].vatAmount * currentVatEligibilityPercentage / 100) : undefined;
        newState.push({
          ...defaultInvoiceBooking,
          bookingDate: currentBookingDate,
          overwriteBookingDate: currentOverwriteBookingDate,
          vatEligibilityPercentage: currentVatEligibilityPercentage,
          vatEligibilityAmount: currentVatEligibilityAmount,
        });
        return newState;
      }
      case 'removeSplit':
        if (setDirty) {
          setDirty(true);
        }
        newState = _.cloneDeep(state);
        newState.splice(action.idx, 1);
        return newState;
      case 'update':
        if (setDirty && !action.notDirty) {
          setDirty(true);
        }
        if (typeof action.idx === 'undefined' || action.idx === null || !action.key) return state;
        newState = _.cloneDeep(state);
        if (action.key === 'amount' || action.key === 'netAmount') {
          newState[action.idx][action.key] = round2dec(action.value);
        } else if (action.key === 'laborCostPercentage') {
          newState[action.idx].laborCostPercentage = action.value;
          newState[action.idx].laborCost = round2dec(action.value / 100 * newState[action.idx].amount);
        } else if (action.key === 'laborCost') {
          newState[action.idx].laborCost = action.value;
          newState[action.idx].laborCostPercentage = calculateLaborCostPercentage(action.value, newState[action.idx].amount);
        } else {
          newState[action.idx][action.key] = action.value;

          if (action.key === 'laborCostType' && !action.value) {
            // clear labor cost amount if type is deselected
            newState[action.idx].laborCost = undefined;
          }
        }
        return newState;
      case 'updateSign':
        if (setDirty && !action.notDirty) {
          setDirty(true);
        }
        if (typeof action.idx === 'undefined' || action.idx === null) return state;
        newState = _.cloneDeep(state);
        newState[action.idx].netAmount *= -1;
        newState[action.idx].amount *= -1;
        newState[action.idx].vatAmount *= -1;
        newState[action.idx].vatEligibilityAmount = newState[action.idx].vatEligibilityAmount !== undefined ? newState[action.idx].vatEligibilityAmount * -1 : undefined;
        newState[action.idx].laborCost *= -1;
        newState[action.idx].incoming = !newState[action.idx].incoming;
        return newState;
      case 'setDefault':
        newState = action.value && action.value.length
          ? decorateInvoiceBookingWithLaborCostPercentage(action.value)
          : [{
            ...defaultInvoiceBooking,
            vatEligibilityPercentage: propertyVatEligibilityShare,
            vatEligibilityAmount: propertyVatEligibilityShare !== undefined ? 0 : undefined, // by default the vat amount is 0, so this will also be 0
          }];
        return newState;
      case 'recalculateDefault':
        newState = _.cloneDeep(state);
        newState[0].amount = action.value;
        newState[0].netAmount = round2dec(action.value * 100 / (100 + newState[0].vatPercentage));
        newState[0].vatAmount = round2dec(action.value * newState[0].vatPercentage / (100 + newState[0].vatPercentage));
        newState[0].vatEligibilityAmount = newState[0].vatEligibilityPercentage !== undefined ? round2dec(newState[0].vatAmount * newState[0].vatEligibilityPercentage / 100) : undefined;
        return newState;
      default:
        return state;
    }
  }

  // Calculate split nr 0 amount based on total and the rest of the splits
  useEffect(() => {
    const currentTotal = round2dec(invoiceBookings.reduce((t: number, i2: InvoiceBookingWithNet) => t + i2.amount, 0));
    if (total === null
        || Number.isNaN(currentTotal)
        || typeof total === 'undefined'
        || currentTotal === round2dec(total)) {
      return;
    }
    dispatch({
      type: 'recalculateDefault', value: round2dec(calculateRest(invoiceBookings)),
    });
  }, [total, invoiceBookings]);

  const calculateRest = (iBookings: InvoiceBookingWithNet[]): number => {
    const invBookings = _.cloneDeep(iBookings);
    invBookings.splice(0, 1);
    return (total || 0) - invBookings.reduce<number>((t, i2) => t + i2.amount, 0);
  };

  const laborCostTypeOptions = [
    {
      value: 'HOUSEHOLD_RELATED_SERVICES',
      label: tl(translations.invoiceEditing.sections.section3.subsection2.fields.laborCostTypes.HOUSEHOLD_RELATED_SERVICES),
    },
    {
      value: 'TECHNICIAN_SERVICE',
      label: tl(translations.invoiceEditing.sections.section3.subsection2.fields.laborCostTypes.TECHNICIAN_SERVICE),
    },
    {
      value: 'MARGINAL_EMPLOYMENT',
      label: tl(translations.invoiceEditing.sections.section3.subsection2.fields.laborCostTypes.MARGINAL_EMPLOYMENT),
    },
    {
      value: 'INSURABLE_EMPLOYMENT',
      label: tl(translations.invoiceEditing.sections.section3.subsection2.fields.laborCostTypes.INSURABLE_EMPLOYMENT),
    },
  ];

  return [
    invoiceBookings,
    dispatch,
    laborCostTypeOptions,
  ];
}
