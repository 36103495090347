import { BuildingControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useRef } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { translations } from './translations';
import { BuildingsListContext } from './BuildingsListContext';


export const useBuildingsList = (propertyId: number | undefined) => {
  const buildingsListContext = useContext(BuildingsListContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const buildingsControllerApi = new BuildingControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  if (buildingsListContext === undefined) {
    throw new Error('useBuildingsList must be used within a BuildingsListContextProvider');
  }

  const {
    setBuildingsList,
    buildingsList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  } = buildingsListContext;

  const isCacheValid = paramOfCachedValue === propertyId;

  useEffect(() => {
    if (propertyId === undefined || isCacheValid) return;

    setParamOfFetchInProgress((prevParamOfFetchInProgress) => {
      // this must be inside the setParamOfFetchInProgress to make sure we have the latest
      // value of `paramOfFetchInProgress`, in case another instance of this hook
      // already triggered the fetch
      const doesCurrentParamMatchTheParamOfTheInProgressFetch = prevParamOfFetchInProgress === propertyId;

      if (prevParamOfFetchInProgress === undefined || !doesCurrentParamMatchTheParamOfTheInProgressFetch) {
        fetchBuildings();

        return propertyId;
      }

      return prevParamOfFetchInProgress;
    });
  }, [propertyId, isCacheValid]);


  const fetchBuildings = () => {
    if (propertyId === undefined) {
      throw new Error('Trying to fetch units with undefined propertyId');
    }

    // if params changed since last initiated fetch then abort the in-progress fetch
    abortController.current?.abort();
    // create new abort controllers
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setBuildingsList(prev => prev.startLoading());

    buildingsControllerApi.findBuildingsUsingGET({ propertyId }, { signal })
      .then((resp) => {
        setBuildingsList(prev => prev.load(resp.content));
        setParamOfCachedValue(propertyId);
      })
      .catch((err) => {
        if (signal.aborted) return;
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.loadBuildingsError),
        });
        setBuildingsList(prev => prev.failed(err));
      })
      .finally(() => {
        setParamOfFetchInProgress(undefined);
      });
  };

  return {
    buildingsList,
  };
};
