import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { DropMenu } from 'components/DropMenu/DropMenu';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import { getStateColor } from 'storybook-components/headers/utils';
import ActivePropertyOwnerContractsProvider from 'services/PropertyOwnerContractsList/ActivePropertyOwnerContractsContext';
import { useProfitAndLossReportHeader } from './useProfitAndLossReportHeader';

export function ProfitAndLossReportViewHeader() {
  const {
    title, subtitle, onClickDelete, onEdit, onClickPrimaryButton, onPrepareSendOut, primaryButtonLabel, messagesExist, state,
    generationFailed, onRetry, onClose,
  } = useProfitAndLossReportHeader();
  const { tl } = useContext(LanguageContext);

  const headerButtons = generationFailed ? (
    <div className="header-buttons">
      <Button type="text" onClick={onClose}>
        {tl(translations.profitAndLossReportView.close)}
      </Button>
      <Button type="primary" onClick={onRetry}>
        {tl(translations.profitAndLossReportView.retry)}
      </Button>
    </div>
  ) : (
    <div className="header-buttons">
      <Button type="text" onClick={onClose}>
        {tl(translations.profitAndLossReportView.close)}
      </Button>
      <DropMenu
        options={[
          {
            label: tl(translations.profitAndLossReportView.prepareSend),
            onClick: onPrepareSendOut,
          },
          {
            label: tl(translations.profitAndLossReportView.deleteModal.deleteButton),
            onClick: onClickDelete,
          },
        ]}
      />
      <Button type="ghost" onClick={onEdit} disabled={messagesExist}>
        {tl(translations.profitAndLossReportView.edit)}
      </Button>
      <Button type="primary" onClick={onClickPrimaryButton}>
        {primaryButtonLabel}
      </Button>
    </div>
  );

  return (
    <PageHeader
      title={title}
      subtitle={subtitle}
      tagContent={state ? tl(translations.profitAndLossReportView.states[state]) : ''}
      tagColor={getStateColor(state) as PageHeaderTagColor}
      rightSideComponent={headerButtons}
    />
  );
}


const ProfitAndLossReportViewHeaderWithProvider = () => (
  <ActivePropertyOwnerContractsProvider>
    <ProfitAndLossReportViewHeader />
  </ActivePropertyOwnerContractsProvider>
);


export default ProfitAndLossReportViewHeaderWithProvider;
