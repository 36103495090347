import { ConfigProvider, Table } from 'antd';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import SecondarySectionWrapper from 'storybook-components/sections/SecondarySectionWrapper/SecondarySectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import React from 'react';
import { getRowClassName } from 'pages/OpsCostReport/services/utils';
import { useOtherOpsCostOrTransferableCostsFromWegSection as hook } from './useOtherOpsCostOrTransferableCostsFromWegSection ';
import AddRowButtonAndDescription from '../../../AddRowButtonAndDescription/AddRowButtonAndDescription';
import OtherOrTransferableCostsTableSummary from './components/OtherOrTransferableCostsTableSummary';
import DistributionCalculationAlerts from './components/DistributionCalculationAlerts';
import { MissingHiddenAccountCodeAlert } from '../components/MissingHiddenAccountCodeAlert/MissingHiddenAccountCodeAlert';
import { InvalidManualAmountsAlert } from '../components/InvalidManualAmountsAlert';


type ReturnTypeOfHook = ReturnType<typeof hook>;
export type GetOtherOpsCostOrTransferableCostsFromWegSectionProps = ReturnTypeOfHook['getOtherOpsCostOrTransferableCostsFromWegSectionProps'];
export type ReturnTypeOfOtherOpsCostsHook = ReturnType<GetOtherOpsCostOrTransferableCostsFromWegSectionProps>;


interface OtherOpsCostOrTransferableCostsFromWegSectionProps {
  sectionNumber: number;
  tl: any;
  useOtherOpsCostOrTransferableCostsFromWegSection: ReturnTypeOfOtherOpsCostsHook;
}


const OtherOpsCostOrTransferableCostsFromWegSection = ({
  sectionNumber,
  tl,
  useOtherOpsCostOrTransferableCostsFromWegSection,
}: OtherOpsCostOrTransferableCostsFromWegSectionProps) => {
  const {
    columns,
    dataSource,
    loading,
    getTotalIncludingManualAmounts,
    incorrectDirectCosts,
    accountsWithMissingDistributionSets,
    accountDistributions,
    accountsWithZeroValueDistributionSets,
    getAccountDistributionPath,
    getAccountDistributionOverviewPath,
    reportEndDate,
    relevantAccountBalancesExist,
    isWegPropertySelected,
    contractId,
    missingHidddenAccountCodes,
    onChangeIncorrectDirectCosts,
    printMode,
  } = useOtherOpsCostOrTransferableCostsFromWegSection;

  const distributionCalculationAlertsProps = {
    accountDistributions,
    incorrectDirectCosts,
    accountsWithMissingDistributionSets,
    accountsWithZeroValueDistributionSets,
    tl,
    getAccountDistributionPath,
    getAccountDistributionOverviewPath,
    reportEndDate,
    relevantAccountBalancesExist,
    onChangeIncorrectDirectCosts,
    printMode,
  };

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(dataSource) ? { y: 200 } : undefined;
  const subSectionTitle = isWegPropertySelected ? tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.transferableCostsFromWeg) : tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.otherOpsCostTitle);

  return (
    <SecondarySectionWrapper
      title={`${sectionNumber}.2 ${subSectionTitle}`}
      subSectionSuffix={<AddRowButtonAndDescription section="otherOrTransferableCosts" contractId={contractId} disabled={loading} />}
    >
      <MissingHiddenAccountCodeAlert
        contractId={contractId}
        missingHidddenAccountCodes={missingHidddenAccountCodes}
        tl={tl}
        sectionName="otherOrTransferableCosts"
      />
      <InvalidManualAmountsAlert
        tl={tl}
        contractId={contractId}
        section="otherOrTransferableCosts"
      />
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <DistributionCalculationAlerts
          tl={tl}
          distributionCalculationAlertsProps={distributionCalculationAlertsProps}
        />
        <Table
          className="table-with-input-rows"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={tableHeight}
          rowKey="key"
          rowClassName={record => getRowClassName(record.isHiddenRow, record.isManualRow)}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <OtherOrTransferableCostsTableSummary
              title={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.subtotal)}
              getTotalIncludingManualAmounts={getTotalIncludingManualAmounts}
              nrTotalColumns={columns.length}
              addPlaceholderLastColumn
              contractId={contractId}
            />
          )}
        />
      </ConfigProvider>
    </SecondarySectionWrapper>
  );
};

export default OtherOpsCostOrTransferableCostsFromWegSection;
