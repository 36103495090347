import { ColumnsType } from 'antd/lib/table';
import { ExtendedAccountBalanceDto, PropertyDisplayDtoVatRateCountryCodeEnum, PropertyDisplayDtoVatRelevanceEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import Amount from 'storybook-components/typography/Amount/Amount';


const COLUMN1_WIDTH = 200;
const COLUMN2_WIDTH = 400;


export const useOtherIncomeTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useOtherIncomeTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances, reportData, incomeAccountBalancesByVatPercentage } = profitAndLossReportContext;

  const vatRelevantGermanProperty = [PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT, PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT]
    .includes(reportData.property?.vatRelevance) && reportData.property?.vatRateCountryCode === PropertyDisplayDtoVatRateCountryCodeEnum.DE;


  // for now let's only memoize where we have to iterate through accountBalances.data;
  // and memoize the columns/sums only if we run into performance issues
  const dataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    const matchingAccountCodes = Object.keys(accountBalances.data)
      .filter(accCode => (
        accCode.match(/^(?!60\/.*)(?!695.*)6.*$/)
        && accountBalances.data[accCode].leaf
        && accountBalances.data[accCode].normalizedBalanceWithinDateRange !== 0
      ));

    const ds = matchingAccountCodes.map(code => accountBalances.data[code])
      .sort((a, b) => a.accountCode.localeCompare(b.accountCode));

    return ds;
  }, [accountBalances.data]);

  const sum = dataSource.reduce((acc, curr) => (acc + curr.normalizedBalanceWithinDateRange), 0);
  const vatSum = dataSource.reduce((acc, curr) => (acc + curr.normalizedBalanceForVatAmountWithinDateRange), 0);
  const netSum = sum - vatSum;

  const columns: ColumnsType<ExtendedAccountBalanceDto> = [
    {
      title: tl(translations.report.sections.incomeSection.columns.accountCode),
      dataIndex: 'accountCode',
      width: COLUMN1_WIDTH,
    },
    {
      title: tl(translations.report.sections.incomeSection.columns.accountName),
      dataIndex: 'accountName',
      width: COLUMN2_WIDTH,
    },
    {
      title: tl(translations.report.sections.incomeSection.columns.currentPeriod),
      dataIndex: 'normalizedBalanceWithinDateRange',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  if (vatRelevantGermanProperty) {
    columns.splice(2, 0, {
      title: tl(translations.report.sections.incomeSection.columns.netAmount),
      dataIndex: 'normalizedNetBalanceWithinDateRange',
      className: 'column-align-right no-wrap',
      render: (_, record) => <Amount>{formatCurrency((record.normalizedBalanceWithinDateRange - record.normalizedBalanceForVatAmountWithinDateRange), '-', false)}</Amount>,
    });
    columns.splice(3, 0, {
      title: tl(translations.report.sections.incomeSection.columns.vat),
      dataIndex: 'normalizedBalanceForVatAmountWithinDateRange',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    });
  }

  const vatDatasource = useMemo(() => {
    if (!incomeAccountBalancesByVatPercentage.loaded) return [];

    // we need the max values from each column to display them as a "ghost" row in order to have the same width across table columns
    const referenceRowData = dataSource.reduce((acc, curr) => ({
      normalizedBalanceWithinDateRange: Math.max(Math.abs(acc.normalizedBalanceWithinDateRange), Math.abs(curr.normalizedBalanceWithinDateRange)),
      normalizedBalanceForVatAmountWithinDateRange: Math.max(Math.abs(acc.normalizedBalanceForVatAmountWithinDateRange), Math.abs(curr.normalizedBalanceForVatAmountWithinDateRange)),
    }), {
      normalizedBalanceWithinDateRange: 0,
      normalizedBalanceForVatAmountWithinDateRange: 0,
    });

    return [{
      ignoreIfNoBalance: false,
      vatPercentage: -1,
      totalGross: referenceRowData.normalizedBalanceWithinDateRange,
      totalVat: referenceRowData.normalizedBalanceForVatAmountWithinDateRange,
      totalNet: referenceRowData.normalizedBalanceWithinDateRange - referenceRowData.normalizedBalanceForVatAmountWithinDateRange,
    }].concat(incomeAccountBalancesByVatPercentage.data?.map((v) => {
      const matchingAccountCodes = Object.keys(v.accountBalances)
        .filter(accCode => (
          accCode.match(/^(?!60\/.*)(?!695.*)6.*$/)
        && v.accountBalances[accCode].leaf
        && v.accountBalances[accCode].normalizedCashBalanceWithinDateRange !== 0
        ));

      const totalGross = matchingAccountCodes.map(code => v.accountBalances[code])
        .reduce((acc, curr) => (acc + curr.normalizedCashBalanceWithinDateRange), 0);

      const totalVat = matchingAccountCodes.map(code => v.accountBalances[code])
        .reduce((acc, curr) => (acc + curr.normalizedCashBalanceForVatAmountWithinDateRange), 0);

      const totalNet = totalGross - totalVat;

      return {
        ignoreIfNoBalance: v.ignoreIfNoBalance,
        vatPercentage: v.vatPercentage,
        totalGross,
        totalVat,
        totalNet,
      };
    }).filter(v => v.totalGross !== 0 || !v.ignoreIfNoBalance));
  }, [incomeAccountBalancesByVatPercentage.data, dataSource]);


  const vatColumns: ColumnsType<(typeof vatDatasource)[number]> = [
    {
      title: '',
      dataIndex: 'vatPercentage',
      width: COLUMN1_WIDTH + COLUMN2_WIDTH,
      render: (num: number) => `${tl(translations.report.sections.incomeSection.fromThisWithVat)} ${num}%`,
    },
    {
      title: tl(translations.report.sections.incomeSection.columns.netAmount),
      dataIndex: 'totalNet',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.incomeSection.columns.vat),
      dataIndex: 'totalVat',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.incomeSection.columns.currentPeriod),
      dataIndex: 'totalGross',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  return {
    dataSource,
    sum,
    columns,
    vatSum,
    netSum,
    vatRelevantGermanProperty,
    vatDatasource,
    vatColumns,
  };
};
