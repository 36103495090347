import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { isNil } from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { translations } from './translations';

export const useValidateDate = (minDate: string | moment.Moment, maxDate: string | moment.Moment) => {
  const { tl } = useContext(LanguageContext);

  const validateDateWithingNormalDateRange = (date: moment.Moment | null) => {
    if (!isNil(minDate) && date.isBefore(minDate)) {
      showNotification({
        key: 'dateTooFarInPast',
        message: tl(translations.dateTooFarInPastMessage),
        description: tl(translations.dateTooFarDescription),
        type: 'warning',
      });
    }

    if (!isNil(maxDate) && date.isAfter(maxDate)) {
      showNotification({
        key: 'dateTooFarInFuture',
        message: tl(translations.dateTooFarInFutureMessage),
        description: tl(translations.dateTooFarDescription),
        type: 'warning',
      });
    }
  };
  return {
    validateDateWithingNormalDateRange,
  };
};
