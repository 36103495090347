import {
  ContractContactProjectionDtoRoleEnum, PropertyDisplayDtoAdministrationTypeEnum, PropertyDisplayDtoPropertyStateEnum, UnitContractProjectionDto,
} from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { ContractState } from 'pages/UnitContractHistory/interfaces';
import React, { ReactNode, createContext, useState } from 'react';

export interface ContractDataSource extends UnitContractProjectionDto {
    accountBalance: number,
    state: ContractState,
    role: ContractContactProjectionDtoRoleEnum[],
    propertyHrId:string,
}

export interface ContactPropertiesDataSource{
    key: number,
    name: string,
    propertyHrId: string,
    propertyIdInternal: string,
    administrationType: PropertyDisplayDtoAdministrationTypeEnum,
    state: PropertyDisplayDtoPropertyStateEnum,
    children: ContractDataSource[],
    linkedProperty: {
      propertyIdInternal: string,
      name: string,
      propertyHrId: string,
    }
}

export const useContactPropertiesContext = (usageName: string) => {
  const context = React.useContext(ContactPropertiesContext);

  if (context === undefined) {
    throw new Error(`${usageName} must be used within an ContactPropertiesContextProvider`);
  }

  return context;
};

const ContactPropertiesContext = createContext<| {
    contactPropertiesDataSource: DefaultDataInterface<Array<ContactPropertiesDataSource>>
    setContactPropertiesDataSource: React.Dispatch<React.SetStateAction<DefaultDataInterface<ContactPropertiesDataSource[]>>>
}
| undefined>(undefined);

const ContactPropertiesContextProvider = ({ children }: { children: ReactNode }) => {
  const [contactPropertiesDataSource, setContactPropertiesDataSource] = useState(DEFAULT_DATA<Array<ContactPropertiesDataSource>>([]));

  return (
    <ContactPropertiesContext.Provider value={{
      contactPropertiesDataSource,
      setContactPropertiesDataSource,
    }}
    >
      {children}
    </ContactPropertiesContext.Provider>
  );
};

export {
  ContactPropertiesContext,
  ContactPropertiesContextProvider,
};
