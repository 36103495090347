import { useState } from 'react';
import { RichTextProps } from './RichTextEditor';

interface useRichTextProps {
  value?: string,
  onChange?: (val: string) => void,
}

export default function useRichTextEditor(props: useRichTextProps): RichTextProps {
  const [defaultValue, setDefaultValue] = useState('');
  const {
    value,
    onChange,
  } = props;

  const defaultOnChange = (val: string) => {
    setDefaultValue(val);
    if (onChange) onChange(val);
  };

  return {
    value: typeof value !== 'undefined' ? value : defaultValue,
    onChange: defaultOnChange,
  };
}
