import { useContext } from 'react';
import _ from 'lodash';
import { showNotification } from 'lib/Notification';
import { UnitContractProjectionDtoVatRelevanceEnum } from 'api/accounting';
import {
  AccountSelectionContext,
  AmountDistributionUpdatersAndDatasourceContext,
  AmountToDistributeInputValuesContext,
  OccurrenceValuesContext,
  ValidationErrors,
} from './ManualExchangeContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../translations';

const REQUIRED_FIELDS = ['property', 'counterAccount', 'accountTag', 'bankAccount'];
const OCCURRENCE_FIELDS = ['bookingDate', 'bookingText', 'bankPurposeText'];
export const useValidationErrors = () => {
  const { tl } = useContext(LanguageContext);
  const accountSelectionContext = useContext(AccountSelectionContext);
  const occurrenceContext = useContext(OccurrenceValuesContext);
  const amountToDistributeInputValuesContext = useContext(AmountToDistributeInputValuesContext);
  const dataSourceContext = useContext(AmountDistributionUpdatersAndDatasourceContext);

  if (accountSelectionContext === undefined || occurrenceContext === undefined || dataSourceContext === undefined) {
    throw new Error('useValidationErrors must be used within a ManualExchangeContextProvider');
  }

  const {
    validationErrors, setValidationErrors, formValues, vatRate,
  } = accountSelectionContext;
  const { setOccurrenceValues } = occurrenceContext;

  const isExchangeValid = () => {
    const newErrors: ValidationErrors = {};
    // check required fields
    REQUIRED_FIELDS.forEach((requiredFieldKey: string) => {
      // @ts-ignore
      const field = formValues[requiredFieldKey];
      if (field === undefined || field === null || field === '') {
        newErrors[requiredFieldKey] = {
          state: 'error',
          errorMessage: tl(translations.creationPage.notifications.mandatory),
        };
      }
    });

    const REQUIRED_OCCURRENCE_FIELDS = formValues?.bankAccount === -1
      ? OCCURRENCE_FIELDS.filter(occ => occ !== 'bankPurposeText') : OCCURRENCE_FIELDS;


    let newOccurrenceErrors = false;
    setOccurrenceValues(prevState => prevState.map((prevValue) => {
      const errors = {};
      REQUIRED_OCCURRENCE_FIELDS.forEach((requiredFieldKey: string) => {
        // @ts-ignore
        const field = prevValue[requiredFieldKey];
        if (field === undefined || field === null || field === '') {
          newOccurrenceErrors = true;
          // @ts-ignore
          errors[requiredFieldKey] = {
            state: 'error',
            errorMessage: tl(translations.creationPage.notifications.mandatory),
          };
        }
      });
      return { ...prevValue, errors };
    }));

    const nrSelectedAndNotZero = amountToDistributeInputValuesContext?.filter(atd => atd.selected && atd.amount).length;

    setValidationErrors(newErrors);

    if (nrSelectedAndNotZero === 0) {
      showNotification({
        type: 'error',
        message: tl(translations.creationPage.notifications.amountIsZeroError),
      });

      return false;
    }

    return _.isEmpty(Object.keys(newErrors)) && !newOccurrenceErrors;
  };

  const { tableDataSource } = dataSourceContext;

  const isVatRateApplied = () => {
    const vatRelevantSelectedContractNr = tableDataSource.data?.filter(uc => uc.selected && uc.vatRelevance !== UnitContractProjectionDtoVatRelevanceEnum.NOT_RELEVANT).length;
    return formValues?.property.vatRateCountryCode === undefined || vatRelevantSelectedContractNr === 0 || vatRate !== undefined;
  };

  return {
    validationErrors,
    isExchangeValid,
    isVatRateApplied,
  };
};
