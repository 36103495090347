import { MESSAGE_SENDING_PATH } from 'pages/MessageSendingPage/routes';
import { DOCUMENT_PREVIEW_PATH } from 'pages/DocumentPreview/routes';
import { lazy } from 'react';
import { RouteDefinition } from '../../components/RenderRoutes';
import { SpecialContributionList } from './SpecialContributionList/SpecialContributionList';
import SpecialContributionCreation from './SpecialContributionCreation/SpecialContributionCreation';

const MessageSendingPageWithProvider = lazy(() => import('pages/MessageSendingPage/MessageSendingPage'));
const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));


const specialContributionEditingPath = '/special-contribution/edit/:specialContributionId';

export const getSpecialContributionEditingRoute = ({ specialContributionId } : { specialContributionId: number | string }) => (
  specialContributionEditingPath.replace(':specialContributionId', `${specialContributionId}`)
);

export const getSpecialContributionSendingRoute = ({ specialContributionId } : { specialContributionId: number | string }) => (
  `${specialContributionEditingPath}${MESSAGE_SENDING_PATH}`.replace(':specialContributionId', `${specialContributionId}`)
);

export const SpecialContributionRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/special-contribution',
      component: SpecialContributionList,
      subRoutes: [
        {
          path: '/special-contribution/create',
          component: SpecialContributionCreation,
          type: 'focus',
        },
        {
          path: specialContributionEditingPath,
          component: SpecialContributionCreation,
          type: 'focus',
          subRoutes: [{
            path: `${specialContributionEditingPath}${MESSAGE_SENDING_PATH}`,
            component: MessageSendingPageWithProvider,
            type: 'overlay',
            subRoutes: [
              {
                path: `${specialContributionEditingPath}${MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                component: DocumentPreviewPage,
                type: 'overlay',
              },
            ],
          }],
        },
      ]
    },
  ],
};
