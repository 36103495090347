import React, { useState, useRef } from 'react';
import {
  Checkbox, Input, Tooltip,
} from 'antd';
import './CurrencyInputWithCheckbox.scss';
import { formatCurrencyString, round2dec } from 'lib/Utils';

const CURRENCY = '€';
const REGEX_FORMATTED_STRING_OF_ONLY_ZEROS_ = /^(0|\.)+,00$/;


const formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
const customFormat = (val: number) => formatter.formatToParts(val).reduce((acc, { type, value }) => ((type === 'currency') ? acc : `${acc}${value}`), '').trim();
const formattedStringToFloat = (value: string) => round2dec(parseFloat(value.replaceAll('.', '').replaceAll(',', '.') || '0'));


interface Props {
  suggestedValue?: number,
  previousValue?: number,
  currentValue?: number,
  maxValue?: number,
  maxValueExceededErrorMessage?: string,
  getTooltipPopupContainer?: () => HTMLElement,
  onChange: (val: number) => void
}

/**
 * Note: Cannot use the Checkbox as the Input's `prefix` because clicking the Checkbox
 * causes the Input's onFocus to trigger before the Checkbox's onClick and onChange
 * and therefore cannot make the component work as required.
 */

const CurrencyInputWithCheckbox = ({
  suggestedValue,
  previousValue,
  currentValue,
  maxValue,
  maxValueExceededErrorMessage,
  getTooltipPopupContainer,
  onChange,
}: Props) => {
  // @ts-ignore
  const inputRef = useRef<Input | null>(null);
  const isCheckedInitialValue = (currentValue !== undefined && (currentValue > 0 || (currentValue === 0 && previousValue !== undefined)))
    || previousValue !== undefined;
  const [isChecked, setIsChecked] = useState(isCheckedInitialValue);
  const [inputValue, setInputValue] = useState(customFormat(currentValue ?? previousValue ?? suggestedValue ?? 0));


  const maxValueExceeded = maxValue !== undefined ? formattedStringToFloat(inputValue) > maxValue : false;


  const onBlur = () => setTimeout(() => {
    setInputValue((currInputValue) => {
      const formattedValue = customFormat(formattedStringToFloat(currInputValue));

      if (REGEX_FORMATTED_STRING_OF_ONLY_ZEROS_.test(formattedValue) && previousValue === undefined) {
        setIsChecked(false);
        onChange(0);
      }

      return formattedValue;
    });
  }, 150);


  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
    setIsChecked((prevChecked) => {
      if (!prevChecked) {
        onChange(formattedStringToFloat(customFormat(currentValue ?? previousValue ?? suggestedValue ?? 0)));
        setInputValue(customFormat(currentValue ?? previousValue ?? suggestedValue ?? 0));
      }

      return true;
    });
  };


  const onChangeCheckbox = () => {
    setIsChecked((prevChecked) => {
      if (prevChecked) {
        if (previousValue !== undefined) {
          setInputValue(customFormat(0));
          onChange(0);
          return true;
        }
        // if there is no previous value, the current value should be set to undefined on deselection, so the amount suggestion works on second click as well
        onChange(undefined);
        return false;
      }

      inputRef.current?.focus();
      onChange(formattedStringToFloat(customFormat(currentValue ?? previousValue ?? suggestedValue ?? 0)));
      setInputValue(customFormat(currentValue ?? previousValue ?? suggestedValue ?? 0));
      return true;
    });
  };


  const onKeyDown = (e: any) => {
    if (
      !e.ctrlKey
        && !/[0-9,]/.test(e.key)
        && !e.key.startsWith('Arrow')
        && !e.key.startsWith('Page')
        && !['Backspace', 'Delete', 'Home', 'End'].includes(e.key)
    ) {
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === ',' && inputValue.includes(',')) {
      e.stopPropagation();
      e.preventDefault();
    }
  };


  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (REGEX_FORMATTED_STRING_OF_ONLY_ZEROS_.test(e.target.value)) {
      inputRef.current?.blur();
    } else {
      setInputValue(formatCurrencyString(e.target.value));
      onChange(formattedStringToFloat(e.target.value));
    }
  };

  const getClassName = () => {
    if (!isChecked) return 'input-with-checkbox-unchecked';
    if (maxValueExceeded) return 'input-with-checkbox-danger';
    return 'input-with-checkbox-checked';
  };


  return (
    <Tooltip
      className="CurrencyInputWithCheckbox"
      title={maxValueExceededErrorMessage}
      visible={maxValueExceeded && isChecked}
      getPopupContainer={getTooltipPopupContainer}
      placement="top"
    >
      <Checkbox
        checked={isChecked}
        onChange={onChangeCheckbox}
        className="checkbox-bigger"
        tabIndex={-1}
      />

      <Input
        ref={inputRef}
        placeholder={customFormat(suggestedValue ?? 0)}
        className={`input-with-checkbox ${getClassName()}`}
        inputMode="decimal"
        size="large"
        value={isChecked ? inputValue : customFormat(currentValue || previousValue || suggestedValue || 0)}
        onChange={onChangeInput}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        suffix={CURRENCY}
      />
    </Tooltip>
  );
};


CurrencyInputWithCheckbox.defaultProps = {
  suggestedValue: undefined,
  previousValue: undefined,
  currentValue: undefined,
  maxValue: undefined,
  maxValueExceededErrorMessage: undefined,
  getTooltipPopupContainer: undefined,
};


export default CurrencyInputWithCheckbox;
