import React, { useEffect, useMemo } from 'react';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import './BankAccountList.scss';
import SmartTable from 'elements/SmartTable/SmartTable';
import useDefaultPropertyFilters from 'components/Header/components/data/useDefaultPropertyFilters';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { useParams } from 'react-router';
import { useBankAccountListColumns } from './UseBankAccountListColumns';
import { DEFAULT_FILTER_BANK_ACCOUNT_LIST, useBankAccountList } from './UseBankAccountList';
import BankAccountTable from './BankAccountTable/BankAccountTable';

export default function BankAccountList(): JSX.Element {
  const bankAccountListContext: any = useBankAccountList();
  const { propertyHrId } = useParams<{propertyHrId:string}>();
  useGlobalSearch({
    key: 'bankAccounts',
    filterProps: {
      availableFilters: useDefaultPropertyFilters(),
      setFilter: (key: string, value: any) => bankAccountListContext.updateFilterState({ [key]: value || undefined }),
    },
    queryParamAsFilter: {
      filterState: bankAccountListContext.filterState,
      defaultFilters: DEFAULT_FILTER_BANK_ACCOUNT_LIST,
      onSetDefaultFilterFromQueryParams: bankAccountListContext.updateFilterState,
    },
  });

  const tableData = useMemo(() => bankAccountListContext.data, [bankAccountListContext.data]);
  const smartTable = useSmartTable(
    {
      tableName: 'bankAccountList',
      columns: useBankAccountListColumns(),
      dataSource: tableData,
      propSort: {
        field: bankAccountListContext.sortField,
        order: bankAccountListContext.sortOrder,
        onSortChange: (dataKey: string) => bankAccountListContext.setSortField(dataKey),
      },
      contentLoading: bankAccountListContext.loading,
      infiniteScrollerProps: {
        hasMoreData: !bankAccountListContext.lastPage,
        loadMoreData: bankAccountListContext.onLoadBankAccountList,
      },
      expandedRowRender: (record: any) => <BankAccountTable property={record} />,
      rowKey: 'propertyHrId',
      virtualize: false,
    },
  );

  useEffect(() => () => {
    bankAccountListContext.onClearBankAccountList();
    bankAccountListContext.onClearFilterState();
  }, []);

  useEffect(() => {
    if (propertyHrId) {
      bankAccountListContext.updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'bankAccounts',
    navItems: subcategorySwitcherItems.accounting,
  };

  return (
    <div className="BankAccountList page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      <SmartTable {...smartTable} />
    </div>
  );
}
