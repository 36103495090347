import React, { useContext } from 'react';
import ActionBar from 'storybook-components/ActionBar/ActionBar/ActionBar';
import CheckboxInput from 'elements/Inputs/CheckboxInput/CheckboxInput';
import Button from 'elements/Buttons/Button/Button';
import ActionBarSelectionCounter from 'storybook-components/ActionBar/ActionBarSelectionCounter/ActionBarSelectionCounter';
import { LanguageContext } from 'contexts/LanguageContext';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { Tooltip } from 'antd';
import { useDunningActionBar } from './useDunningActionBar';
import './DunningActionBar.scss';


const DunningActionBar = () => {
  const { tl } = useContext(LanguageContext);
  const {
    nrSelectedContractsCurrent,
    nrSelectedContractsTotal,
    actionBarVisible,
    showWarningIcon,
    showAllSelected,
    onChangeShowAllSelected,
    onUnselectAll,
    tooltipVisible,
    onChangeTooltipVisible,
    onCollapseAllSelected,
    onExpandAllSelected,
    allSelectedKeysAreExpanded,
  } = useDunningActionBar();

  return (
    <Tooltip
      title={tl(orderOpenBalancesTranslations.actionBar.warningTooltip)}
      visible={tooltipVisible}
      overlayStyle={{ maxWidth: 600, padding: 0 }}
    >
      <ActionBar className="DunningActionBar" visible={actionBarVisible}>
        <ActionBarSelectionCounter>
          <span>{`${nrSelectedContractsCurrent}/${nrSelectedContractsTotal}`}</span>
          {showWarningIcon && (
            <span
              className="warning-icon-wrapper"
              onMouseEnter={() => onChangeTooltipVisible(true)}
              onMouseLeave={() => onChangeTooltipVisible(false)}
            >
              <Icon component={ICONS.warning} />
            </span>
          )}
        </ActionBarSelectionCounter>

        <Button type="primary" onClick={onUnselectAll}>
          {tl(orderOpenBalancesTranslations.actionBar.unselectAll)}
        </Button>

        {allSelectedKeysAreExpanded
          ? (
            <Button type="primary" onClick={onCollapseAllSelected}>
              { tl(orderOpenBalancesTranslations.actionBar.collapseAll)}
            </Button>
          )
          : (
            <Button type="primary" onClick={onExpandAllSelected}>
              {tl(orderOpenBalancesTranslations.actionBar.expandAll)}
            </Button>
          )}

        <CheckboxInput
          onChange={onChangeShowAllSelected}
          value={showAllSelected}
          label={tl(orderOpenBalancesTranslations.actionBar.showAllSelected)}
        />
      </ActionBar>
    </Tooltip>
  );
};

export default DunningActionBar;
