import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationObject, showNotification } from '../../../lib/Notification';
import { AuthContext } from '../../../contexts/AuthContext';
import { AllocationDto, BankTransactionControllerApi, AllocationControllerApi } from '../../../api/accounting';

import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../translations';

export function useTransactionNavigation() {
  const history = useHistory();
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));
  const allocationControllerApi = new AllocationControllerApi(apiConfiguration('accounting'));

  // 'navigatedFromApp' is used to prevent reloading data when allocationId is changed and same transaciton is kept open (eg. tab change & partail allocation)
  const navigateToAllocationFromTx = async (transactionId: number) => {
    try {
      const allocationGroupId = await bankTransactionControllerApi.getAllocationGroupIdOfBankTransactionUsingGET({ bankTransactionId: transactionId });
      history.push(`/bank-transactions/${allocationGroupId}?transactionIds=${transactionId}`, { navigatedFromApp: false, reloadFirstPage: true });
    } catch (e) {
      const transactionGroupIdLoadError: NotificationObject = {
        key: 'transactionGroupIdLoadError',
        message: tl(translations.transactionNavigation.loadError),
        type: 'error',
      };
      showNotification(transactionGroupIdLoadError);
    }
  };

  const navigateToAllocation = async (allocationId: number) => {
    try {
      const allocation: AllocationDto = await allocationControllerApi.getAllocationUsingGET({ allocationId });
      history.push(`/bank-transactions/${allocation.transaction?.allocationGroupId}?transactionIds=${allocation.transaction?.id}`, { navigatedFromApp: false, reloadFirstPage: true });
    } catch (e) {
      const transactionGroupIdLoadError: NotificationObject = {
        key: 'allocationLoadError',
        message: tl(translations.transactionNavigation.loadError),
        type: 'error',
      };
      showNotification(transactionGroupIdLoadError);
    }
  };

  return {
    navigateToAllocationFromTx,
    navigateToAllocation,
  };
}
