import React, { useContext, useMemo } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { DownloadMenu } from 'components/DownloadMenu/DownloadMenu';
import { ContactLegacyDto } from 'api/accounting';
import { useHistory, useLocation } from 'react-router';
import Icon from '@ant-design/icons';
import { translations } from '../../elements/Translation/translations';
import './PaymentHeaderButtons.scss';
import Button from '../../elements/Buttons/Button/Button';
import { ICONS } from '../icons';

interface PaymentHeaderButtonsInterface {
  onRepairEbics: () => void,
  onDownloadSelected: () => void,
  loading: boolean,
  downloadLabel: string,
  managementCompanyList: ContactLegacyDto[],
  managementCompanyLabel: string,
  managementCompanyLoading: boolean,
  onSelectCompany: (index: number) => void,
}

export const PaymentHeaderButtons: React.FC<PaymentHeaderButtonsInterface> = ({
  onRepairEbics,
  loading,
  onDownloadSelected,
  downloadLabel,
  managementCompanyList,
  onSelectCompany,
  managementCompanyLabel,
  managementCompanyLoading,
}) => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const location = useLocation();

  const menuOptions = [
    {
      label: downloadLabel,
      onClick: onDownloadSelected,
    },
    {
      label: tl(translations.pages.payment.repairSepa),
      onClick: onRepairEbics,
    },
  ];

  const managementCompanyListOptions = useMemo(() => managementCompanyList.map((mngmntCmp, idx) => ({
    label: mngmntCmp.companyName!,
    onClick: () => onSelectCompany(idx),
  })), [managementCompanyList]);

  return (
    <div className="header-buttons">
      <DownloadMenu
        label={managementCompanyLabel}
        loading={managementCompanyLoading}
        options={managementCompanyListOptions}
      />
      <Button
        type="ghost"
        onClick={() => history.push(`${location.pathname}/new`)}
      >
        <Icon component={ICONS.plus} />
      </Button>
      <DownloadMenu
        type="primary"
        label={tl(translations.pages.payment.sepa)}
        loading={loading}
        options={menuOptions}
      />
    </div>
  );
};
