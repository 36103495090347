import { Col, Row } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { translations } from '../../../translations';

interface Props {
  addressConcatenation: string,
  onSelectProperty: () => void,
  lastHoverOnPropertyOption: boolean,
  onMouseLeavePropertyOption: () => void
}
export const DropdownHeader = ({
  addressConcatenation, onSelectProperty, lastHoverOnPropertyOption, onMouseLeavePropertyOption,
}:Props) => {
  const { tl } = useContext(LanguageContext);
  return (
    <>
      <div className="dropdown-header">
        <div>{tl(translations.actionBar.header.property)}</div>
      </div>
      <button
        className={`property-details-dropdown-header ${lastHoverOnPropertyOption ? 'active' : ''}`}
        onClick={onSelectProperty}
        onMouseLeave={onMouseLeavePropertyOption}
      >
        {addressConcatenation}
      </button>
      <Row className="dropdown-header">
        <Col span={4}>{tl(translations.actionBar.header.unitId)}</Col>
        <Col span={12}>{tl(translations.actionBar.header.contract)}</Col>
        <Col span={8}>{tl(translations.actionBar.header.usagePeriod)}</Col>
      </Row>
    </>
  );
};
