import React, {
  useContext,
  useMemo,
} from 'react';

import { ColumnsType } from 'antd/lib/table';
import {
  ExtendedExchangeProjectionTypeEnum,
} from 'api/accounting/models/ExtendedExchangeProjection';
import StrikethroughAmount
  from 'components/StrikethroughAmount/StrikethroughAmount';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatDate } from 'lib/Utils';
import moment from 'moment';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import PopoverTruncatingText
  from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';

import { translations } from '../../../../../../translations';
import {
  ServiceCompanyAllocationValues,
} from '../../../services/ServiceCompanyAllocationContext';
import {
  useServiceCompanyAllocationAmounts,
} from '../../../services/useServiceCompanyAllocationAmounts';
import AccountCell from '../components/AccountCell/AccountCell';
import CounterpartCell from '../components/CounterpartCell/CounterpartCell';
import LaborCostCell from '../components/LaborCostCell/LaborCostCell';
import PropertyCell from '../components/PropertyCell/PropertyCell';
import PurposeEditorCell
  from '../components/PurposeEditorCell/PurposeEditorCell';
import SumToAllocateCell
  from '../components/SumToAllocateCell/SumToAllocateCell';
import VatCell from '../components/VatCell/VatCell';
import {
  shouldShowPropertyDataInExchangeList, shouldShowVatEligibilityColumnInExchangeList,
} from './exchangeListUtils';
import VatEligibilityCell from '../components/VatEligibilityCell/VatEligibilityCell';

export const useExchangeListColumns = (selectedTransactions: ExtendedBankTransaction[]) : ColumnsType<ServiceCompanyAllocationValues> => {
  const { tl, language } = useContext(LanguageContext);
  const headerTranslations = translations.bankTransactionAllocation.serviceCompanyAllocation.headers;

  const { totalAllocatedAmount, previouslyAllocatedAmount } = useServiceCompanyAllocationAmounts(selectedTransactions);
  const remainingAmount = parseFloat((Math.abs(selectedTransactions[0]?.remainingAmount ?? 0) - Math.abs(totalAllocatedAmount) + Math.abs(previouslyAllocatedAmount)).toFixed(2));

  const columns: ColumnsType<ServiceCompanyAllocationValues> = useMemo(() => {
    const cols = [
      {
        title: tl(headerTranslations.purpose),
        dataIndex: 'name',
        render: (_, record) => (
          <PurposeEditorCell
            record={record}
            selectedTransactions={selectedTransactions}
          />
        ),
      },
      {
        title: tl(headerTranslations.date),
        dataIndex: 'date',
        render: value => formatDate(moment(value)),
      },
      {
        title: tl(headerTranslations.account),
        dataIndex: 'accounts',
        render: (value, record) => (
          <AccountCell
            value={value}
            record={record}
          />
        ),
      },
      {
        title: tl(headerTranslations.counterpart),
        dataIndex: 'counterpartName',
        render: (value, record) => (record.type !== ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? (
          <PopoverTruncatingText value={value} containerClassName="ExchangeList" />
        ) : (
          <CounterpartCell
            record={record}
          />
        )),
      },
      {
        title: tl(headerTranslations.laborCost),
        dataIndex: 'laborCost',
        render: (value, record) => (
          <LaborCostCell
            record={record}
          />
        ),
      },
      {
        title: tl(headerTranslations.vat),
        dataIndex: 'vat',
        render: (value, record) => (
          <VatCell
            record={record}
          />
        ),
      },
      {
        title: tl(headerTranslations.openAmount),
        dataIndex: 'exchangeRemainingAmount',
        render: (_, record) => (record.type !== ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? (
          <StrikethroughAmount totalAmount={Math.abs(record.exchangeAmount!)} remainingAmount={Math.abs(record.exchangeRemainingAmount ?? record.exchangeAmount!)} />)
          : null
        ),
      },
      {
        title: tl(headerTranslations.sumToAllocate),
        dataIndex: 'allocationAmount',
        render: (_, record) => (
          <SumToAllocateCell
            record={record}
            transactionRemainigAmount={remainingAmount}
          />
        ),
      },
    ];
    if (shouldShowVatEligibilityColumnInExchangeList(selectedTransactions)) {
      cols.splice(6, 0,  {
        title: tl(headerTranslations.vatEligibility),
        dataIndex: 'vat',
        render: (_, record) => (
          <VatEligibilityCell
            record={record}
            properties={selectedTransactions[0]?.propertyList}
          />
        ),
      },)
    }
    if (shouldShowPropertyDataInExchangeList(selectedTransactions)) {
      cols.splice(0, 0, {
        title: tl(headerTranslations.property),
        dataIndex: 'propertyHrId',
        render: (_, record) => (
          <PropertyCell
            record={record}
            properties={selectedTransactions[0]?.propertyList}
          />
        ),
      });
    }
    return cols;
  }, [language, remainingAmount]);

  return columns;
};
