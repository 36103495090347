export const correctionalBookingsTranslations = {
  infoText: {
    en: 'Overlapping Economic plans have been detected. \n'
        + 'To correct the situation, the difference between the two economic plans will be booked into the accounts at the date specified by you and Bank Orders will be generated accordingly.\n'
        + 'Note that no existing Postings and or Bank Orders will be modified.',
    de: 'Es wurden Überschneidungen mit anderen Wirtschaftsplänen festgestellt. '
        + 'Zur Korrektur wird die Differenz der beiden Wirtschaftspläne in die Konten zum von Ihnen festgelegten Datum eingebucht sowie Bankaufträge erstellt. '
        + 'Bitte beachten Sie, dass bereits erstellte Buchungen und Bankaufträge nicht angepasst werden.',
  },
  bookingDate: {
    en: 'Correction Booking Date',
    de: 'Datum Korrekturbuchung',
  },
};
