import React from 'react';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import AllocationLaborCostModal from './components/AllocationLaborCostModal/AllocationLaborCostModal';
import useLaborCostCell from './useLaborCostCell';

interface Props {
  record: ServiceCompanyAllocationValues,
}

const LaborCostCell = ({ record }: Props) => {
  const {
    laborCostAmount, laborCostType, onClickCell, modalVisible, onCloseModal,
  } = useLaborCostCell(record);


  return (
    <>
      <CellWithTwoLines
        firstElement={<Amount>{laborCostAmount}</Amount>}
        secondElement={laborCostType}
        onClickWrapperElement={onClickCell}
      />
      <AllocationLaborCostModal recordKey={record.key} visible={modalVisible} onClose={onCloseModal} />
    </>
  );
};

export default LaborCostCell;
