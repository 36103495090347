import { nanoid } from 'nanoid';
import { StoreApi } from 'zustand';
import { produce } from 'immer';
import { isEmpty, isNil } from 'lodash';
import {
  areAllManualValuesEmpty, getRequiredKeysBasedOnSectionAndVatRelevance,
  getValidTenantContract,
} from 'pages/OpsCostReport/services/utils';
import { UnitContractProjectionDtoVatRelevanceEnum } from 'api/accounting';
import {
  IndividualTenantCostManualValue, IndividualTenantCostValidation, ManualValues, OpsCostReportManualAmountsStore, OpsCostSectionKeys, OtherOrTransferableCostManualValue, OtherOrTransferableCostValidation, ValidationValues, YourPrepaymentManualValue,
  YourPrepaymentValidation,
} from './interfacesOpsCostReportManualAmounts';


/* not declared with the rest of interfaces because these are just helper types to avoid long lines */
type Actions = OpsCostReportManualAmountsStore['actions'];
type Set = StoreApi<OpsCostReportManualAmountsStore>['setState'];


const getInitialManualValuesForContract = (): ManualValues => ({
  individualTenantCosts: {},
  otherOrTransferableCosts: {},
  yourPrepayments: {},
  individualTenantCostsKeys: [],
  otherOrTransferableCostsKeys: [],
  yourPrepaymentsKeys: [],
});
const getInitialValidationValuesForContract = ():ValidationValues => ({
  individualTenantCosts: {},
  otherOrTransferableCosts: {},
  yourPrepayments: {},
  individualTenantCostsNumberOfInvalidFields: 0,
  yourPrepaymentsNumberOfInvalidFields: 0,
  otherOrTransferableCostsNumberOfInvalidFields: 0,
});


export const initializeStoreWithExistingValues = (set: Set, ...args: Parameters<Actions['initializeStoreWithExistingValues']>) => set((state) => {
  const [values, tenantContracts, sevTenantContracts] = args;

  return produce(state, (draft) => {
    draft.values = values;
    Object.keys(draft.values).forEach((contractId) => {
      if (isNil(draft.validation.values[contractId])) {
        draft.validation.values[contractId] = getInitialValidationValuesForContract();
      }
      Object.keys(draft.values[contractId]).filter(section => ['otherOrTransferableCosts', 'individualTenantCosts', 'yourPrepayments'].includes(section)).forEach((targetSection) => {
        const contract = getValidTenantContract(parseInt(contractId, 10), sevTenantContracts, tenantContracts);
        const isContractVatRelevant = contract ? contract.vatRelevance !== UnitContractProjectionDtoVatRelevanceEnum.NOT_RELEVANT : false;
        const requiredKeys = getRequiredKeysBasedOnSectionAndVatRelevance(targetSection as OpsCostSectionKeys, isContractVatRelevant);
        Object.keys(draft.values[contractId][targetSection]).forEach((key) => {
          draft.validation.values[contractId][targetSection][key] = {};
          requiredKeys.forEach((requiredKey) => {
            draft.validation.values[contractId][targetSection][key][requiredKey] = isValueValid(draft.values[contractId][targetSection][key][requiredKey], requiredKeys, draft.values[contractId][targetSection][key]);
          });
          const numberOfInvalidFields = getNumberOfInvalidFieldsForContract(draft.validation.values[contractId][targetSection]);
          draft.validation.values[contractId][`${targetSection}NumberOfInvalidFields`] = numberOfInvalidFields;
        });
      });
    });
  });
});

export const clearStore = (set: Set) => set(state => (
  produce(state, (draft) => {
    draft.values = {};
    draft.validation = { values: {}, showValidationErrors: false };
  })
));


export const addRow = (set: Set, ...args: Parameters<Actions['addRow']>) => set((state) => {
  const [contractId, targetSection] = args;
  const newKey = nanoid();

  return produce(state, (draft) => {
    if (isNil(draft.values[contractId])) {
      draft.values[contractId] = getInitialManualValuesForContract();
    }

    if (isNil(draft.validation.values[contractId])) {
      draft.validation.values[contractId] = getInitialValidationValuesForContract();
    }


    draft.values[contractId][targetSection][newKey] = { sign: 1 };
    draft.values[contractId][`${targetSection}Keys`].push(newKey);
    draft.validation.values[contractId][targetSection][newKey] = {};
  });
});


export const removeRow = (set: Set, ...args: Parameters<Actions['removeRow']>) => set((state) => {
  const [contractId, targetSection, key] = args;

  return produce(state, (draft) => {
    const numberOfInvalidFields = getNumberOfInvalidFiledsForRow(draft.validation.values[contractId][targetSection][key]);
    delete draft.values[contractId][targetSection][key];
    draft.values[contractId][`${targetSection}Keys`] = draft.values[contractId][`${targetSection}Keys`].filter(k => k !== key);
    delete draft.validation.values[contractId][targetSection][key];
    draft.validation.values[contractId][`${targetSection}NumberOfInvalidFields`] -= numberOfInvalidFields;
  });
});

const isValueValid = (value:any, requiredKeys: string[], manualValues: OtherOrTransferableCostManualValue| IndividualTenantCostManualValue |YourPrepaymentManualValue) => {
  if (areAllManualValuesEmpty(manualValues, requiredKeys)) {
    return 'valid';
  }

  if (typeof value === 'number') {
    return value !== undefined ? 'valid' : 'invalid';
  }

  if (!isEmpty(value)) {
    return 'valid';
  }

  return 'invalid';
};

const getNumberOfInvalidFiledsForRow = (vaidationValues:OtherOrTransferableCostValidation| IndividualTenantCostValidation|YourPrepaymentValidation) => Object.keys(vaidationValues).reduce((sum, property) => {
  // acountCode is not a required field but we need it for calculating if all values are empty or not
  if (property === 'accountCode') {
    return sum;
  }

  if (vaidationValues[property] === 'invalid') {
    return sum + 1;
  }

  return sum;
}, 0);

const getNumberOfInvalidFieldsForContract = (vaidationValues:any) => Object.keys(vaidationValues).reduce((sum, rowKey) => sum + getNumberOfInvalidFiledsForRow(vaidationValues[rowKey]), 0);

export const onChange = (set: Set, ...args: Parameters<Actions['onChange']>) => set((state) => {
  const [contractId, targetSection, key, property, value, isContractVatRelevant] = args;
  const requiredKeys = getRequiredKeysBasedOnSectionAndVatRelevance(targetSection as OpsCostSectionKeys, isContractVatRelevant);
  return produce(state, (draft) => {
    draft.values[contractId][targetSection][key][property] = value;
    requiredKeys.forEach((requiredKey) => {
      draft.validation.values[contractId][targetSection][key][requiredKey] = isValueValid(draft.values[contractId][targetSection][key][requiredKey], requiredKeys, draft.values[contractId][targetSection][key]);
    });
    const numberOfInvalidFields = getNumberOfInvalidFieldsForContract(draft.validation.values[contractId][targetSection]);
    draft.validation.values[contractId][`${targetSection}NumberOfInvalidFields`] = numberOfInvalidFields;
  });
});

export const onShowValidationErrors = (set: Set) => set(state => produce(state, (draft) => {
  draft.validation.showValidationErrors = true;
}));
