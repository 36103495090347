import { DefaultDataInterface } from '../../../../../lib/data';
import { SerialLetter } from '../../../interfaces';

export default function useDefaultSection(index: number, openingIndex: number, serialLetter: DefaultDataInterface<SerialLetter>) {
  return {
    sectionNumber: index + 1,
    openingNumber: openingIndex + 1,
    buttonLoading: serialLetter.loading,
    value: serialLetter.data, 
  };
}
