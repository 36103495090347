import { InvoiceLegacyControllerApi, InvoiceLegacyDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import { getInvoiceEditingRoute } from '../routes';
import { invoicePageTranslations } from './invoicePageTranslations';

/**
 * Redirects to legacy invoice editing page
 */
export const InvoicePage = () => {
  const [redirectPath, setRedirectPath] = useState('');
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));
  const { invoiceId: invoiceIdString } = useParams<{ invoiceId: string }>();
  const invoiceId = parseInt(invoiceIdString, 10);

  const loadRedirectPath = () => {
    invoiceControllerApi.getInvoiceByIdUsingGET({ invoiceId })
      .then((invoice: InvoiceLegacyDto) => {
        setRedirectPath(getInvoiceEditingRoute({ invoiceHrId: invoice.invoiceHrId }));
      }).catch(() => {
        showNotification({ type: 'warning', message: tl(invoicePageTranslations.invoiceNotFound) });
        setRedirectPath('/invoice' /* should be invoices :smilecry: */);
      });
  };
  useEffect(loadRedirectPath, [invoiceId]);
  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (<LoadingScreen />);
};
