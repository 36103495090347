import { useLocation } from 'react-router';
import { OpsCostReportProjectionStatusEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { OpsCostReportPreviewLocationState } from '../../interfaces';
import { opsCostTranslation } from '../../../translations';


export const useOpsCostReportPreviewHeader = () => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation<OpsCostReportPreviewLocationState>();


  const intervalLabel = location.state?.startDate && location.state?.endDate
    ? `· ${location.state?.startDate} - ${location.state?.endDate}`
    : '';

  const subtitle = location.state?.propertyIdInternal && location.state.propertyName
    ? `${location.state?.propertyIdInternal}  ·  ${location.state?.propertyName} ${intervalLabel}`
    : '';

  const title = `${tl(opsCostTranslation.opsCostReportPreview.preview)} ${location.state?.documentName || ''}`;

  return {
    title,
    subtitle,
    state: location.state?.status as unknown as OpsCostReportProjectionStatusEnum,
  };
};
