import React, { useContext } from 'react';
import './Dashboard.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import LanguageSwitcher from 'elements/LanguageSwitcher/LanguageSwitcher';
import { useAnalyticsDomainDataList } from 'pages/Analytics/analyticsDomainDataList';
import { getEnvVar } from 'lib/getEnvVar';
import AnnouncementAlert from 'storybook-components/AnnouncementAlert/AnnouncementAlert';
import { isMasterLikeEnv } from '../../config';
import { NavigationTiles } from './components/NavigationTiles';
import { translations } from './translations';
import { useGlobalSearch } from '../../components/Header/components/SearchBar/services/useGlobalSearch';
import { HelpButton } from '../../components/HelpButton/HelpButton';
import { NavigationTileItem } from './components/NavigationTiles/interfaces';
import { useAlertOnDashboard } from './services/useAlertOnDashboard';

export const Dashboard: React.FC = () => {
  const { tl } = useContext(LanguageContext);
  const { analyticsDomainData } = useAnalyticsDomainDataList();

  useGlobalSearch({
    key: 'dashboard',
    filterProps: {
      availableFilters: [],
      setFilter: () => {
      },
    },
  });

  const navigationTileItems: NavigationTileItem[] = [
    {
      icon: 'property',
      key: 'propertyData',
      url: '/properties',
    },
    {
      icon: 'accounting',
      key: 'accounting',
      url: '/invoice',
    },
    {
      icon: 'meetings',
      key: 'meetings',
      url: '/owners-meeting/invitation',
    },
    {
      icon: 'contacts',
      key: 'contacts',
      url: '/persons',
    },
    {
      icon: 'documents',
      key: 'documents',
      url: '/documents',
    },
    {
      icon: 'analytics',
      key: 'analytics',
      url: analyticsDomainData ? `${analyticsDomainData[0].pages[0].path}` : '/analytics',
      buttonProps: {
        disabled: !analyticsDomainData,
      },
      // tooltip: 'comingSoon',
    },
    {
      icon: 'communication',
      key: 'messages',
      url: '/messages',
    },
    {
      icon: 'settings',
      key: 'settings',
      url: '/watermarks',
    },
  ];

  return (
    <div className="Dashboard page">
      <div className="container">
        <div className="dashboard-header-row">
          <span className="salutation">
            {`${tl(translations.salutation)}!`}
          </span>
          {(getEnvVar('app.showLanguageSelector', `${!isMasterLikeEnv}`) as string) === 'true'
            && (
              <span className="language-switcher-wrapper">
                <LanguageSwitcher />
              </span>
            )}
        </div>
        <AnnouncementAlert {...useAlertOnDashboard()} />
        <NavigationTiles tileItems={navigationTileItems} />
      </div>
      <HelpButton />
    </div>
  );
};
