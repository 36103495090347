import {
  Context,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Configuration,
  ConnectionsApi,
  CreateSyncDataDtoSourceTypeEnum,
  CreateSyncDataDtoStateEnum,
  CreateSyncDataUsingPOSTResourceNameEnum,
  DeleteSyncDataUsingDELETEResourceNameEnum,
  GetSyncDataUsingGETResourceNameEnum,
  GetSyncDataUsingGETSourceTypeEnum,
  SyncDataApi,
  SyncDataDto,
} from 'api/app';
import {
  getProcessesByContractId,
  getProcessesByObjectId,
  getSignatory,
  postUpdateOnProcess,
} from 'api/etg24/etg24Api';
import {
  Etg24Credentials,
  Etg24Process,
  Etg24Signatory,
} from 'api/etg24/interfaces';
import { DocumentApi } from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _, { debounce } from 'lodash';
import {
  ExternalPropertyIssue,
  PortalDocumentProps,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  calculateRelevance,
} from 'storybook-components/TicketIntegrationSection/services/ticketUtils';

import { translations } from '../../translations';

const mapTicketToIssue = (process: Etg24Process): ExternalPropertyIssue => ({
  id: process.id,
  type: 'Ticket',
  number: process.id,
  name: process.title,
  description: process.description?.replace(/(<([^>]+)>)/gi, ''),
  url: process.url,
  relevanceScore: 1,
  assigned: false,
  searchValue: `${process.id} ${process.title} ${process.description}`.toLocaleLowerCase(),
});

const debouncableMethod = (setSearchTerm, searchTermNewValue) => {
  setSearchTerm(searchTermNewValue);
};

const debouncedSearch = debounce(debouncableMethod, 500);

export const useEtg24Tickets = (connectionId: number,
  { propertyId, contactId }: {propertyId?: number, contactId?: number},
  documentProps: PortalDocumentProps) => {
  const { apiConfiguration } = useContext<{ apiConfiguration:(string) =>
     Configuration }>(AuthContext as unknown as Context<{ apiConfiguration: (string) => Configuration }>);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [issues, setIssues] = useState<ExternalPropertyIssue[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [etg24Link, setEtg24Link] = useState();
  const [entitySyncData, setEntitySyncData] = useState<SyncDataDto>();
  const [documentSyncData, setDocumentSyncData] = useState<SyncDataDto>();

  const appContext = useContext<{ apiConfiguration:(string) =>
    Configuration }>(AuthContext as unknown as Context<{ apiConfiguration: (string) => Configuration }>);
  const { publicApiConfiguration } = useContext(AuthContext);
  const documentApi = new DocumentApi(publicApiConfiguration('public'));
  const syncDataApi = new SyncDataApi(appContext.apiConfiguration('app'));
  const connectionsApi = new ConnectionsApi(apiConfiguration('app'));
  const { tl } = useContext(LanguageContext);

  useEffect(() => {
    if (!loading && contactId && connectionId) {
      getContactSyncData();
    }
  }, [contactId, connectionId]);

  useEffect(() => {
    if (!loading && propertyId && connectionId) {
      getPropertySyncData();
    }
  }, [propertyId, connectionId]);

  useEffect(() => {
    if (documentProps?.entityId || documentProps?.sourceId) {
      getDocumentSyncData();
    }
  }, [documentProps?.entityId, documentProps?.sourceId, connectionId]);

  useEffect(() => {
    if (!loading && entitySyncData?.externalEntityId) {
      getIssues();
    }
  }, [entitySyncData?.externalEntityId]);

  const issuesWithAssignments = useMemo(() => {
    let assignedIssueId;
    if (documentSyncData?.externalEntityId) {
      assignedIssueId = JSON.parse(documentSyncData?.externalEntityId).processId;
    }
    return issues.filter(i => !_.isNil(i)).map(issue => ({ ...issue, assigned: issue?.id === assignedIssueId }));
  }, [issues, documentSyncData]);

  const filteredAndRankedIssues = useMemo(() => {
    if (!searchTerm) {
      return [...issuesWithAssignments]
        .sort((a, b) => (Number(b.assigned) - Number(a.assigned)));
    }
    return issuesWithAssignments
      .map(calculateRelevance(searchTerm))
      .filter(e => e.relevanceScore > 0)
      .sort((a, b) => {
        if (a.relevanceScore > b.relevanceScore) {
          return 1;
        } if (a.relevanceScore < b.relevanceScore) {
          return -1;
        }
        return 0;
      });
  }, [issuesWithAssignments, searchTerm]);

  const getPropertySyncData = async () => {
    try {
      const response: SyncDataDto[] = (await syncDataApi.getSyncDataUsingGET({
        connectionId,
        resourceName: GetSyncDataUsingGETResourceNameEnum.properties,
        resourceId: propertyId,
      })).content;
      setEntitySyncData(response[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getContactSyncData = async () => {
    try {
      const response: SyncDataDto[] = (await syncDataApi.getSyncDataUsingGET({
        connectionId,
        resourceName: GetSyncDataUsingGETResourceNameEnum.contacts,
        resourceId: contactId,
      })).content;
      setEntitySyncData(response[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getDocumentSyncData = async () => {
    try {
      const response: SyncDataDto[] = (await syncDataApi.getSyncDataUsingGET({
        connectionId,
        resourceId: documentProps?.entityId,
        resourceName: GetSyncDataUsingGETResourceNameEnum.documents,
        sourceId: documentProps?.entityId ? undefined : documentProps?.sourceId,
        sourceType: documentProps?.entityId ? undefined : documentProps?.sourceType as unknown as GetSyncDataUsingGETSourceTypeEnum,
      })).content;
      setDocumentSyncData(response[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getIssues = async () => {
    let allIssues;
    setLoading(true);
    try {
      // load issues based on property
      if (propertyId) {
        const { objectId } = JSON.parse(entitySyncData.externalEntityId);
        allIssues = await getProcessesByObjectId(connectionId, objectId);
        setIssues(is => is.concat(allIssues?.processes?.map(mapTicketToIssue)));
        const link = allIssues?.urls?.index?.replace('/processes', '');
        setEtg24Link(link);
      }
      // load issues based on contact
      if (contactId) {
        JSON.parse(entitySyncData.externalEntityId)?.signatoryIds?.forEach(
          async (signatoryId) => {
            const signatory: Etg24Signatory = await getSignatory(connectionId, signatoryId);
            allIssues = await getProcessesByContractId(connectionId, signatory?.contractId);
            setIssues(is => is.concat(allIssues?.processes?.map(mapTicketToIssue)));
            if (!etg24Link) {
              let link = allIssues?.urls?.index?.split('/property')[0];
              link = `${link}/property/bo/contacts?contentType=contact&contentId=${signatory?.contact?.id}`;
              setEtg24Link(link);
            }
          },
        );
      }
    } catch (e) {
      setErrorMessage(tl(translations.errors.communication)('etg24'));
      console.error(e);
    }
    setLoading(false);
  };

  const isAssigned = useMemo(() => issuesWithAssignments.some(issue => issue.assigned), [issuesWithAssignments]);

  const createDocumentSyncData = async (cId: number, processId: string) => {
    try {
      const syncData = {
        sourceId: documentProps?.sourceId,
        entityId: documentProps?.entityId,
        sourceType: documentProps?.sourceType as unknown as CreateSyncDataDtoSourceTypeEnum,
        externalEntityId: JSON.stringify({ processId }),
        state: CreateSyncDataDtoStateEnum.SUCCESS,
        syncTimestamp: new Date(),
      };
      const response: SyncDataDto = await syncDataApi.createSyncDataUsingPOST({
        resourceName: CreateSyncDataUsingPOSTResourceNameEnum.documents,
        connectionId: cId,
        createSyncDataDto: syncData,
      });
      setDocumentSyncData(response);
    } catch (e) {
      console.error(e);
      setErrorMessage(tl(translations.errors.assign));
    }
  };

  const getEtg24Credentials = async (): Promise<Etg24Credentials> => {
    const connection = await (await connectionsApi.getConnectionUsingGET({ id: connectionId }));
    // format of externalConfig {"accessToken":"[passbolt]", "tenantName":"username"}
    return JSON.parse(connection.externalConfig) as Etg24Credentials;
  };

  const assignTicket = async (processId: string, comment: string) => {
    setLoading(true);
    const credentials = await getEtg24Credentials();
    try {
      const data: FormData = new FormData();
      data.append('description', comment);
      // store as a private comment so not all users will have direct access automatically
      data.append('internal', 'true');
      if (documentProps?.entityId) {
        try {
          const file: any = await documentApi.downloadUsingGET(
            { documentId: documentProps?.entityId },
          );
          const blob = new Blob([file], { type: 'application/pdf' });
          data.append('files[1]', blob, `${documentProps?.fileName}.pdf`);
        } catch (e) {
          showNotification({
            key: 'etg24AssignError',
            message: tl(translations.errors.uploadFail)('etg24'),
            type: 'warning',
          });
          console.error(e);
        }
      }
      await postUpdateOnProcess(credentials.accessToken, processId, data);
      createDocumentSyncData(connectionId, processId);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setErrorMessage(tl(translations.errors.credentials));
    }
  };

  const unassignTicket = async () => {
    try {
      await syncDataApi.deleteSyncDataUsingDELETE({
        resourceName: DeleteSyncDataUsingDELETEResourceNameEnum.documents,
        connectionId,
        id: documentSyncData.id,
      });
      setDocumentSyncData(undefined);
    } catch (e) {
      console.error(e);
      setErrorMessage(tl(translations.errors.unassign));
    }
  };

  const onChangeSearchTerm = (searchTermNewValue) => {
    // don't setLoading(true); since it causes performance issues
    debouncedSearch(setSearchTerm, searchTermNewValue);
  };

  return {
    loading,
    errorMessage,
    issues: filteredAndRankedIssues,
    onChangeSearchTerm,
    etg24Link,
    timestamp: entitySyncData?.lastSyncTimestamp,
    isAssigned,
    assignTicket,
    unassignTicket,
  };
};
