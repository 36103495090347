import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { useContext, useMemo } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import { formatCurrency, formatDate } from 'lib/Utils';
import { Column } from 'elements/SmartTable/SmartTable';
import { Link } from 'react-router-dom';
import { getMessagePath } from 'pages/Messages/routes';
import { GetMessagesUsingGETSourceTypeEnum } from 'api/accounting';
import { DunningStateCell } from '../components/DunningStateCell/DunningStateCell';
import { DunningPropertiesCell } from '../components/DunningPropertiesCell/DunningPropertiesCell';

export const useDunningColumns = () => {
  const { tl } = useContext(LanguageContext);

  const cols = useMemo(() => [
    Table.EXPAND_COLUMN,
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.state.label),
      dataKey: 'state',
      defaultWidth: 170,
      sortable: false,
      render: (_, record) => <DunningStateCell record={record} />,
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.numberOfProperties),
      dataKey: 'properties',
      defaultWidth: 125,
      sortable: false,
      render: (_, record) => <DunningPropertiesCell record={record} />,
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.amount),
      dataKey: 'totalAmount',
      defaultWidth: 200,
      sortable: false,
      className: 'column-align-right',
      render: (_, record) => <Amount color={record?.totalAmount > 0 ? 'green' : 'red'}>{formatCurrency(record?.totalAmount ?? 0)}</Amount>,
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.numberOfContracts),
      dataKey: 'numberOfContracts',
      defaultWidth: 120,
      sortable: false,
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.reference),
      dataKey: 'id',
      defaultWidth: 120,
      sortable: false,
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.administrator),
      dataKey: 'propertyManager',
      defaultWidth: 250,
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.accountant),
      dataKey: 'propertyAccountant',
      defaultWidth: 250,
      sortable: false,
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.numberOfAllMessages),
      dataKey: 'numberOfAllMessages',
      sortable: false,
      render: (_, record) => (record.numberOfAllMessages === 0
        ? '—'
        : (
          <Link to={getMessagePath(record.id, GetMessagesUsingGETSourceTypeEnum.DUNNING)} onClick={e => e.stopPropagation()}>
            {record.numberOfAllMessages}
          </Link>
        )),
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.numberOfFailedMessages),
      dataKey: 'numberOfFailedMessages',
      sortable: false,
      defaultWidth: 200,
      render: (_, record) => (record.numberOfFailedMessages === 0
        ? '—'
        : (
          <Link to={getMessagePath(record.id, GetMessagesUsingGETSourceTypeEnum.DUNNING)} onClick={e => e.stopPropagation()}>
            {record.numberOfFailedMessages}
          </Link>
        )),
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.executionDate),
      dataKey: 'executionDate',
      defaultWidth: 200,
      sortable: true,
      defaultVisible: false,
      render: (_, record) => formatDate(record.executionDate),
    },
    {
      title: tl(orderOpenBalancesTranslations.dunningList.tableColumns.createdBy),
      dataKey: 'createdBy',
      sortable: false,
      defaultVisible: false,
    },
  ] as Column[], []);
  return cols;
};
