import { UpdateListElementFunction } from 'pages/BankTransactions/BankTransactionAllocation/services/useTransactionAllocation';
import { AccountAllocation, ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import React from 'react';
import BankTransactionAllocationDetails from '../BankTransactionAllocationDetails/BankTransactionAllocationDetails';
import BankTransactionAllocationControlsRow, { OnSubmitType } from './components/BankTransactionAllocationControlsRow';

interface Props {
  selectedBankTransactions: ExtendedBankTransaction[],
  bankTransactionsInGroup: ExtendedBankTransaction[],
  resetTransaction: () => void;
  markTransactionNotBookable: () => void;
  markTransactionDuplicate: () => void;
  propertyHrIds: string[],
  unitAllocations: AccountAllocation[],
  updateListElement: UpdateListElementFunction,
  useAmounts: (selectedTransactions: ExtendedBankTransaction[]) => { totalAllocatedAmount: number, previouslyAllocatedAmount: number };
  useControls: (propertyHrIds: string[], selectedTransactions?: ExtendedBankTransaction[], bankTransactionsInGroup?: ExtendedBankTransaction[], unitAllocations?: AccountAllocation[]) => { onSubmit: OnSubmitType, isDirty: boolean, loading: boolean };
}

const BankTransactionAllocationHeader = ({
  selectedBankTransactions, bankTransactionsInGroup, resetTransaction, markTransactionNotBookable, markTransactionDuplicate, updateListElement, propertyHrIds, useAmounts, useControls, unitAllocations,
}: Props) => {
  const { totalAllocatedAmount, previouslyAllocatedAmount } = useAmounts(selectedBankTransactions);

  const remainingAmount = parseFloat((Math.abs(selectedBankTransactions[0]?.remainingAmount ?? 0) - Math.abs(totalAllocatedAmount) + Math.abs(previouslyAllocatedAmount)).toFixed(2));

  return (
    <div>
      <BankTransactionAllocationDetails
        bankTransaction={selectedBankTransactions[0]}
        amountAllocated={totalAllocatedAmount}
        remainingAmount={remainingAmount}
        numberOfTransactions={selectedBankTransactions.length}
      />
      <BankTransactionAllocationControlsRow
        selectedBankTransactions={selectedBankTransactions}
        bankTransactionsInGroup={bankTransactionsInGroup}
        resetTransaction={resetTransaction}
        markTransactionNotBookable={markTransactionNotBookable}
        markTransactionDuplicate={markTransactionDuplicate}
        updateListElement={updateListElement}
        propertyHrIds={propertyHrIds}
        unitAllocations={unitAllocations}
        useControls={useControls}
      />
    </div>
  );
};

export default BankTransactionAllocationHeader;
