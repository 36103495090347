import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import { SERIAL_LETTER, useFileUpload } from '../../Inputs/FileUpload/UseFileUpload';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import './IconFileUpload.scss';
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';

export interface fileFileUploadProps {
  name: string,
  value: string,
  onChange: (value: string) => void,
  uploadIcon: any,
  downloadIcon: any,
  deleteIcon?: any
  accept?: string
  disabled?: boolean,
  forLetter: boolean
}

export const IconFileUpload = (props: fileFileUploadProps) => {
  const { tl } = useContext(LanguageContext);
  const {
    name, value, uploadIcon, downloadIcon, deleteIcon, accept, disabled, forLetter,
  } = props;

  const documentType = forLetter ? SERIAL_LETTER : null;
  const fileUploader = useFileUpload('', documentType);

  const onChange = (event: any) => {
    if (event.target.value === props.value) return;
    fileUploader.onChange(event, (url: string) => props.onChange(url));
  };

  const onDelete = () => {
    fileUploader.onDeleteFile();
    props.onChange('');
  };

  const onDownload = (e: any) => {
    e.preventDefault();
    fileUploader.onDownload(value);
  };

  if (fileUploader.uploading) return <LoadingIndicator />;

  if (value) {
    // render download and delete
    return (
      <div className="IconFileUpload">
        <a href={value} onClick={onDownload} className="download-icon">
          <Icon
            component={downloadIcon}
            title={tl(translations.elements.iconFileUpload.download.title)}
          />
        </a>
        {deleteIcon && !disabled
          && (
            <div className="delete-icon-wrapper">
              <Icon
                className="delete-icon"
                component={deleteIcon}
                onClick={onDelete}
                title={tl(translations.elements.iconFileUpload.delete.title)}
              />
            </div>
          )
        }
      </div>
    );
  }

  if (disabled) {
    return <React.Fragment />;
  }

  // render upload
  return (
    <div className="IconFileUpload">
      <Icon
        component={uploadIcon}
        title={tl(translations.elements.iconFileUpload.upload.title)}
        onClick={() => {
          // @ts-ignore
          document.getElementById(`${name}-file-input`).click();
        }}
      />
      <input
        id={`${name}-file-input`}
        className="file-input"
        style={{
          height: 0, width: 0, overflow: 'hidden', textOverflow: 'hidden',
        }}
        type="file"
        value=""
        onChange={onChange}
        accept={accept}
      />
    </div>
  );
};

IconFileUpload.defaultProps = {
  accept: '*',
};
