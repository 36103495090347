import { useContext } from 'react';

import {
  AllocationControllerApi,
  AllocationCreationDto,
  BankTransactionControllerApi,
  ExchangeControllerApi,
  ExtendedExchangeProjectionTypeEnum,
  ManualExchangePlanControllerApi,
  ManualXCPCreationByServiceCompanyAllocationDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  NotificationObject,
  showNotification,
} from 'lib/Notification';
import _, {
  groupBy,
  isEqual,
  uniq,
} from 'lodash';
import moment from 'moment';
import {
  useTransactionNavigation,
} from 'pages/AccountSheet/services/useTransactionNavigation';
import {
  useHgaValidation,
} from 'pages/BankTransactions/BankTransactionAllocation/services/useHgaValidation';
import {
  UpdateListElementFunction,
} from 'pages/BankTransactions/BankTransactionAllocation/services/useTransactionAllocation';
import {
  AccountAllocation,
  ExtendedBankTransaction,
} from 'pages/BankTransactions/interfaces';
import {
  BankTransactionAllocationContext,
} from 'pages/BankTransactions/services/BankTransactionAllocationContext';
import {
  ServiceCompanyAllocationValidationErrorTypes,
} from 'pages/BankTransactions/translations';

import { translations } from '../../../../translations';
import {
  AllocationAmount,
  AllocationAmountsStateContext,
  AllocationLaborCostValues,
  AllocationLaborCostValuesStateContext,
  AllocationVatEligibilityValues,
  AllocationVatEligibilityValuesStateContext,
  ExchangeListAndPreviousAllocationValuesContext,
  MergedValuesType,
  PostingText,
  PostingTextsContext,
  SelectInputValues,
  SelectInputValuesStateContext,
  ServiceCompanyAllocationUpdatersContext,
  ServiceCompanyAllocationValues,
} from './ServiceCompanyAllocationContext';
import { useInitializeExchangeList } from './useInitializeExchangeList';
import { calculateVatAmount, calculateVatEligibilityAmount } from './serviceCompanyAllocationUtils';


const getExchangesRelevantForValidation = (allocationValues: MergedValuesType[]) => allocationValues.filter((alloc) => {
  if (alloc.currentAmount === undefined) return false;
  if (alloc.currentAmount > 0) return true;

  return alloc.previousAmount !== undefined;
});


const hasDuplicates = (allocationValues: MergedValuesType[]) => {
  // do not consider invoices duplicates
  const inputsConcatonatedAsString = allocationValues.map(({
    accountCode,
    currentAmount,
    counterpartContactId,
    laborCostAmount,
    laborCostType,
    vatPercentage,
    exchangeType,
    exchangeIds,
  }) => `${accountCode}${currentAmount}${counterpartContactId}${laborCostAmount}${laborCostType}${vatPercentage}${exchangeType === ExtendedExchangeProjectionTypeEnum.INVOICE ? exchangeIds : ''}`);

  return inputsConcatonatedAsString.length !== new Set(inputsConcatonatedAsString).size;
};

const isLaborCostValid = (allocationValues: MergedValuesType[]) => allocationValues.reduce(
  (acc, { currentAmount, laborCostAmount, exchangeType }) => acc
      && (currentAmount === undefined
        || laborCostAmount === undefined
        || laborCostAmount! <= currentAmount!
        || exchangeType !== ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED),
  true,
);

// if you uncheck the amount in the input then that is disregarded on submission, so you can have undefined data in that case
const hasUndefinedValues = (allocationValues: MergedValuesType[]) => allocationValues.reduce((acc, {
  accountCode, currentAmount, laborCostAmount, laborCostType,
}) => acc
        || (
          (currentAmount !== undefined && currentAmount > 0)
            && (accountCode === undefined
                || currentAmount === undefined
                || (laborCostType === undefined && laborCostAmount! > 0))
        ),
false);

const validateAllocation = (mergedValues: MergedValuesType[]) => {
  const releventAllocations = getExchangesRelevantForValidation(mergedValues);

  if (hasUndefinedValues(releventAllocations)) return { isValid: false, error: ServiceCompanyAllocationValidationErrorTypes.MISSING_VALUES };
  if (!isLaborCostValid(releventAllocations)) return { isValid: false, error: ServiceCompanyAllocationValidationErrorTypes.LABOR_COST };
  if (hasDuplicates(releventAllocations)) return { isValid: false, error: ServiceCompanyAllocationValidationErrorTypes.DUPLICATES };

  return { isValid: true };
};


const getIsAllocationAmountValid = (
  relevantAllocationAmounts: AllocationAmount[],
  selectedTransactions: ExtendedBankTransaction[],
) => {
  const transactionRemainingAmount = Math.abs(
    selectedTransactions?.[0]?.remainingAmount ?? 0,
  );

  const noAllocationAmountExceedsExchangeAmount = relevantAllocationAmounts.reduce(
    (acc, alloc) => acc && (
      Math.abs(alloc.currentAmount ?? 0) <= Math.abs(alloc.exchangeAmount ?? 0)
            || alloc.exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED
    ),
    true,
  );

  const relevantAllocationAmountsSum = relevantAllocationAmounts.reduce(
    (acc, alloc) => (
      acc
          + Math.abs(alloc.currentAmount ?? 0)
          - Math.abs(alloc.previousAmount ?? 0)
    ),
    0,
  );

  const allocationAmountDoesntExceedTxAmount = parseFloat(relevantAllocationAmountsSum.toFixed(2)) <= parseFloat(transactionRemainingAmount.toFixed(2));

  return (
    noAllocationAmountExceedsExchangeAmount
    && allocationAmountDoesntExceedTxAmount
  );
};


const getIsDirty = (
  relevantAllocationAmounts: AllocationAmount[],
  relevantSelectInputValues: SelectInputValues[],
  relevantLaborCostAmounts: AllocationLaborCostValues[],
  relevantPostingTexts: PostingText[],
  relevantVatEligibilityValues: AllocationVatEligibilityValues[],
) => ([
  relevantAllocationAmounts.reduce(
    (acc, { currentAmount, previousAmount }) => (acc || Math.abs(currentAmount ?? 0) !== Math.abs(previousAmount ?? 0)),
    false,
  ),
  relevantSelectInputValues.reduce(
    (acc, {
      accountCode, counterpartContactId,
      previousAccountCode, previousCounterpartContactId,
      vatPercentage, previousVatPercentage,
    }) => (acc || !isEqual(accountCode, previousAccountCode) || counterpartContactId !== previousCounterpartContactId || vatPercentage !== previousVatPercentage
    ),
    false,
  ),
  relevantLaborCostAmounts.reduce(
    (acc, {
      laborCostAmount,
      previousLaborCostAmount,
      laborCostType,
      previousLaborCostType,
    }) => (
      (acc || Math.abs(laborCostAmount ?? 0) !== Math.abs(previousLaborCostAmount ?? 0))
      || (laborCostType !== previousLaborCostType)
    ),
    false,
  ),
  relevantPostingTexts.reduce(
    (acc, { postingText, previousPostingText }) => (acc || postingText !== previousPostingText),
    false,
  ),
  relevantVatEligibilityValues.reduce(
    (acc, { vatEligibilityPercentage, previousVatEligibilityPercentage }) => (acc || vatEligibilityPercentage !== previousVatEligibilityPercentage),
    false,
  ),
].some(Boolean));


const getNewAndRevertedAllocationInvoiceIds = (invoiceDirtyExistingExchanges: MergedValuesType[], exchangeListData: ServiceCompanyAllocationValues[]) => {
  const [newAllocationInvoiceIds, revertedAllocationInvoiceIds] = invoiceDirtyExistingExchanges.reduce(
    ([newAcc, revertedAcc], exchange) => {
      // since we're only looking at dirtyExchanges, it means that the previousAmount must differ from 0
      // if the currentAmount is 0 then this allocation will be reverted
      if ((exchange.currentAmount ?? 0) === 0) {
        revertedAcc.push(exchangeListData?.find(xc => xc.key === exchange.key)?.planIds ?? []);
      }
      // if it wasn't previously allocated then we add it to the new allocations list
      if (Math.abs(exchange.previousAmount ?? 0) === 0) {
        newAcc.push(exchangeListData?.find(xc => xc.key === exchange.key)?.planIds ?? []);
      }

      // if the current and previous values are > 0 then this is a re-allocation
      // and in that case it is neither new nor reverted, so we don't add it to either list

      return [newAcc, revertedAcc];
    },
    [[], []] as [number[][], number[][]],
  );

  return [newAllocationInvoiceIds.flat(), revertedAllocationInvoiceIds.flat()];
};


export const useSubmitServiceCompanyAllocation = (propertyHrIds: string[], selectedTransactions?: ExtendedBankTransaction[], bankTransactionsInGroup?: ExtendedBankTransaction[], unitAllocations?: AccountAllocation[]) => {
  const { tl } = useContext(LanguageContext);
  const { navigateToAllocationFromTx } = useTransactionNavigation();
  const { apiConfiguration } = useContext(AuthContext);
  const allocationAmounts = useContext(AllocationAmountsStateContext);
  const selectInputValues = useContext(SelectInputValuesStateContext);
  const laborCostValues = useContext(AllocationLaborCostValuesStateContext);
  const vatEligibilityValues = useContext(AllocationVatEligibilityValuesStateContext);
  const postingTexts = useContext(PostingTextsContext);
  const exchangeListContext = useContext(ExchangeListAndPreviousAllocationValuesContext);
  const { transactionActionInProgress, setTransactionActionInProgress } = useContext(BankTransactionAllocationContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);

  const {
    setAllocationAmounts,
    setAllocationLaborCostValues,
    setSelectInputValues,
    setAllocationVatEligibilityValues,
  } = updatersContext;

  const { initialize } = useInitializeExchangeList();

  const { checkIfHgaIsClosed } = useHgaValidation();

  if (allocationAmounts === undefined || selectInputValues === undefined || laborCostValues === undefined || postingTexts === undefined || exchangeListContext === undefined) {
    throw new Error('useSubmitServiceCompanyAllocation must be used within a ServiceCompanyAllocationContextProvider');
  }

  const allocationControllerApi = new AllocationControllerApi(apiConfiguration('accounting'));
  const exchangeControllerApi = new ExchangeControllerApi(apiConfiguration('accounting'));
  const manualExchangePlanControllerApi = new ManualExchangePlanControllerApi(apiConfiguration('accounting'));
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));

  const { exchangeList, setExchangeList } = exchangeListContext;

  const shouldAllocateOnlyToTransactionBasedExchanges = (selectedTransactions?.length ?? 0) > 1;

  const relevantKeys = allocationAmounts.reduce((acc, alloc) => {
    if (shouldAllocateOnlyToTransactionBasedExchanges && alloc.exchangeType !== ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
      return acc;
    }

    if (Math.abs(alloc.currentAmount ?? 0) > 0 || Math.abs(alloc.previousAmount ?? 0)) {
      return [...acc, alloc.key];
    }

    return acc;
  }, [] as string[]);

  const relevantAllocationAmounts = allocationAmounts.filter(alloc => relevantKeys.includes(alloc.key));

  const relevantSelectInputValues = selectInputValues.filter(selectInput => relevantKeys.includes(selectInput.key));

  const relevantLaborCostValues = laborCostValues.filter(laborCost => relevantKeys.includes(laborCost.key));

  const relevantVatEligibilityValues =  vatEligibilityValues.filter(vatEligibility => relevantKeys.includes(vatEligibility.key));

  const relevantPostingTexts = postingTexts.filter(
    pt => relevantKeys.includes(pt.key)
          && (pt.transactionId === undefined
              || selectedTransactions
                ?.map(tx => tx.bankTransactionId!)
                .includes(pt.transactionId)),
  );


  const isAllocationAmountValid = getIsAllocationAmountValid(relevantAllocationAmounts, selectedTransactions ?? []);

  const isDirty = getIsDirty(relevantAllocationAmounts, relevantSelectInputValues, relevantLaborCostValues, relevantPostingTexts, relevantVatEligibilityValues);


  const onSubmit = async (triggeredByTabChange: boolean, updateListElement: UpdateListElementFunction, callback?: () => void) => {
    const transactionSign = Math.sign(selectedTransactions?.[0]?.amount ?? 0);
    setTransactionActionInProgress(true);
    setExchangeList(prev => prev.startLoading());


    const groupedByKeys = groupBy([
      ...relevantAllocationAmounts,
      ...relevantSelectInputValues,
      ...relevantLaborCostValues,
      ...relevantPostingTexts,
      ...relevantVatEligibilityValues,
    ], item => item.key);


    // @ts-ignore
    const mergedValues: MergedValuesType[] = Object.values(groupedByKeys).map(gd => gd.reduce(
      (acc: MergedValuesType, curr) => (curr.hasOwnProperty('postingText')
        ? { ...acc, postingTexts: [...acc.postingTexts, curr] } as MergedValuesType
        : { ...acc, ...curr } as MergedValuesType),
        { postingTexts: [] } as unknown as MergedValuesType,
    ));

    const propertyHrIdsFromMergedValues = uniq(mergedValues.filter(xc => (xc.currentAmount ?? 0) > 0).map(mv => mv.propertyHrId));
    const unitAllocationPrpHrIds = unitAllocations.filter(u => u.propertyHrId).map(u => u.propertyHrId);

    if (propertyHrIdsFromMergedValues.length > 1 || (!_.isEmpty(unitAllocationPrpHrIds) && propertyHrIdsFromMergedValues[0] !== unitAllocationPrpHrIds[0])) {
      showNotification({
        key: 'allocationToDifferentPropertiesError',
        message: tl(translations.notifications.allocationToDifferentPropertiesError),
        type: 'error',
      });
      setTransactionActionInProgress(false);
      setExchangeList(prev => prev.finishLoading());
      return;
    }

    const onProceeed = async () => {
      const { isValid: areAllocationInputsValid, error } = validateAllocation(mergedValues);

      if (!areAllocationInputsValid) {
        showNotification({
          key: 'submitAllocationsError',
          message: tl(translations.notifications.validationErrors[error!]),
          type: 'error',
        });
        setTransactionActionInProgress(false);
        setExchangeList(prev => prev.finishLoading());
        if (callback) callback();
        return;
      }

      const [newOrders, existingOrders] = mergedValues.reduce(([newOrdersAcc, existingOrdersAcc], curr) => {
        if (curr.previousAmount === undefined) {
          newOrdersAcc.push(curr);
        } else {
          existingOrdersAcc.push(curr);
        }
        return [newOrdersAcc, existingOrdersAcc];
      }, [[], []] as [MergedValuesType[], MergedValuesType[]]);


      const dirtyExistingOrders = existingOrders.filter((ord: any) => (
        Math.abs(ord.currentAmount) !== Math.abs(ord.previousAmount ?? 0)
        || ord.counterpartContactId !== ord.previousCounterpartContactId
        || ord.laborCostAmount !== ord.previousLaborCostAmount
        || ord.laborCostType !== ord.previousLaborCostType
        || ord.vatPercentage !== ord.previousVatPercentage
        || ord.vatEligibilityPercentage !== ord.previousVatEligibilityPercentage
        || !isEqual(new Set(ord.accountCode), new Set(ord.previousAccountCode))
        || ord.postingTexts.some((pt: PostingText) => pt.previousPostingText !== pt.postingText)
      ));


      const [
        transactionBasedDirtyExistingExchanges,
        invoiceDirtyExistingExchanges,
      ] = dirtyExistingOrders.reduce(([txBasedAcc, invoiceBasedAcc], deo) => {
        if (deo.exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
          txBasedAcc.push(deo);
        } else {
          invoiceBasedAcc.push(deo);
        }

        return [txBasedAcc, invoiceBasedAcc];
      }, [[] as MergedValuesType[], [] as MergedValuesType[]]);


      try {
      // 1. delete TX_BASED existing exchanges (which also deletes allocations and reverts postings)
        const exchangeIdsToRevert = transactionBasedDirtyExistingExchanges.map(xc => xc.postingTexts.map(pt => pt.exchangeId!)).flat();

        if (exchangeIdsToRevert.length > 0) {
          await exchangeControllerApi.deleteExchangesUsingDELETE({ deleteRequest: { exchangeIds: exchangeIdsToRevert } });
        }


        // 2. delete allocations for INVOICE (and other) exchanges
        const allocationIdsToRevert = invoiceDirtyExistingExchanges.map(xc => xc.postingTexts.map(pt => pt.allocationId!)).flat();

        if (allocationIdsToRevert.length > 0) {
          await allocationControllerApi.revertAllocationsUsingDELETE({ allocationIds: allocationIdsToRevert });
        }


        // 3. create TX_BASED exchanges and allocations
        const exchangeCreationByServiceCompanyAllocationDtos: ManualXCPCreationByServiceCompanyAllocationDto[] = [
          ...newOrders,
          ...(transactionBasedDirtyExistingExchanges.filter(xc => (xc.currentAmount ?? 0) > 0)),
        ].map(xc => xc.postingTexts.map(pt => {
          const vatAmount = calculateVatAmount(xc.vatPercentage, xc.currentAmount!, transactionSign);
          const vatEligibilityAmount = xc.vatEligibilityPercentage !== undefined 
            ? calculateVatEligibilityAmount(xc.vatEligibilityPercentage, Math.abs(vatAmount), transactionSign) 
            : undefined;
          
          return ({
            propertyHrId: xc.propertyHrId,
            accountCode: xc.accountCode?.[0],
            contactId: xc.counterpartContactId,
            amount: xc.currentAmount! * transactionSign,
            laborCost: xc.laborCostAmount,
            laborCostType: xc.laborCostType,
            vatAmount,
            vatPercentage: xc.vatPercentage,
            vatEligibilityPercentage: xc.vatEligibilityPercentage,
            vatEligibilityAmount,
            bankTransactionId: pt.transactionId,
            bookingText: pt.postingText,
          } as ManualXCPCreationByServiceCompanyAllocationDto);
        })).flat();

        if (exchangeCreationByServiceCompanyAllocationDtos.length > 0) {
          await manualExchangePlanControllerApi.batchCreateByServiceCompanyAllocationUsingPOST({ createByAllocationList: exchangeCreationByServiceCompanyAllocationDtos });
        }


        // 4. create allocations for the rest of the non-TX_BASED exchanges
        const allocationCreationDtos: AllocationCreationDto[] = invoiceDirtyExistingExchanges
          .filter(xc => (xc.currentAmount ?? 0) > 0)
          .map(xc => ({
            exchangeId: xc.exchangeIds?.[0],
            allocationAmount: xc.currentAmount! * transactionSign,
            transactionId: selectedTransactions?.[0]?.bankTransactionId,
          }));

        if (allocationCreationDtos.length > 0) {
          await allocationControllerApi.allocateToExchangesUsingPOST({ allocationCreationDtos });
        }


        // 5. get new state of txs for the txs
        const {
          allocationGroupId, amount, remainingAmount, transactionPaymentStatus,
        } = await bankTransactionControllerApi.getBankTransactionUsingGET({ bankTransactionId: selectedTransactions?.[0]?.bankTransactionId! });

        const transactionIds = selectedTransactions?.map(tx => tx.bankTransactionId!) ?? [];
        const originalAllocationGroupId = selectedTransactions?.[0]?.allocationGroupId;
        const shouldOpenNext = !triggeredByTabChange && (remainingAmount === 0 || remainingAmount === amount);


        // if only the postingText changed then the allocationGroupId won't change
        // but we still delete & re-create the exchanges, so we need to re-initialize
        if (originalAllocationGroupId === allocationGroupId) {
          initialize(propertyHrIds, bankTransactionsInGroup!);
        }

        const [newAllocationInvoiceIds, revertedAllocationInvoiceIds] = getNewAndRevertedAllocationInvoiceIds(invoiceDirtyExistingExchanges, exchangeList.data ?? []);

        updateListElement(
          shouldOpenNext,
        originalAllocationGroupId!,
        allocationGroupId!,
        transactionIds,
        transactionPaymentStatus!,
        remainingAmount!,
        newAllocationInvoiceIds,
        revertedAllocationInvoiceIds,
        );
        if (callback) callback();

        setTransactionActionInProgress(false);
        if (shouldOpenNext) {
          // clear context values before opening next transactions to prevent caching incorrect data
          setExchangeList(prev => prev.load([]));
          setAllocationAmounts([]);
          setAllocationLaborCostValues([]);
          setSelectInputValues([]);
          setAllocationVatEligibilityValues([]);
        } else {
          setExchangeList(prev => prev.finishLoading());
        }
      } catch (e) {
        showNotification(allocationErrorNotification);
        setTimeout(() => {
          navigateToAllocationFromTx(selectedTransactions?.[0]?.bankTransactionId!);
          setTransactionActionInProgress(false);
          setExchangeList(prev => prev.finishLoading());
        }, 3000);
      }
    };

    const propertyHrId = mergedValues?.filter(mv => mv.propertyHrId)[0]?.propertyHrId;
    const propertyId = selectedTransactions[0]?.propertyList?.find(p => p.propertyHrId === propertyHrId)?.propertyId;
    const economicYear = selectedTransactions[0]?.bankBookingDate ? moment(selectedTransactions[0]?.bankBookingDate, 'YYYY-MM-DD').year() : undefined;

    checkIfHgaIsClosed(propertyId, propertyHrId, economicYear, onProceeed, () => setTransactionActionInProgress(false));
  };

  const allocationErrorNotification: NotificationObject = {
    message: tl(translations.bankTransactionAllocation.serviceCompanyAllocation.notifications.allocationErrorMessage),
    type: 'error',
  };

  return {
    onSubmit,
    isDirty,
    loading: transactionActionInProgress,
    isValid: isAllocationAmountValid,
  };
};
