import { ListOpsCostReportUsingGETRequest, OpsCostReportProjection } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  createContext, ReactNode, useState,
} from 'react';
import { Order } from 'services/useSort';

export type OpsCostReportFilter = Omit<ListOpsCostReportUsingGETRequest, 'sort'| 'order'| 'page'|'size'>;


const OpsCostReportListContext = createContext<| {
    opsCostReportList: DefaultDataInterface<Array<OpsCostReportProjection>>
    setOpsCostReportList: React.Dispatch<React.SetStateAction<DefaultDataInterface<OpsCostReportProjection[]>>>
    filterState: OpsCostReportFilter,
    setFilterState: React.Dispatch<React.SetStateAction<OpsCostReportFilter>>,
    sortState: { field: string, order: Order },
    setSortState: React.Dispatch<React.SetStateAction<{ field: string, order: Order }>>,
  }
  | undefined>(undefined);

const DEFAULT_SORT: { field: string, order: Order } = {
  field: 'updated',
  order: -1,
};
const OpsCostReportListContextProvider = ({ children }: { children: ReactNode }) => {
  const [opsCostReportList, setOpsCostReportList] = useState(DEFAULT_DATA<Array<OpsCostReportProjection>>([]));
  const [sortState, setSortState] = useState(DEFAULT_SORT);
  const [filterState, setFilterState] = useState<OpsCostReportFilter>();
  const opsCostReportListState = {
    opsCostReportList,
    setOpsCostReportList,
    filterState,
    setFilterState,
    sortState,
    setSortState,
  };

  return (
    <OpsCostReportListContext.Provider value={opsCostReportListState}>
      {children}
    </OpsCostReportListContext.Provider>
  );
};

export {
  OpsCostReportListContext,
  OpsCostReportListContextProvider,
};
