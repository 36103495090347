import React, { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './LinkToAccountSheet.scss';

interface Props {
  propertyHrId: string,
  accountCode: string,
  economicYear?: string,
  children: ReactNode,
}

const LinkToAccountSheet = ({
  propertyHrId, accountCode, economicYear, children,
}: Props) => {
  const { pathname } = useLocation();
  const url = economicYear ? `${pathname}/${propertyHrId}/account/${encodeURIComponent(accountCode)}?economicYear=${economicYear}` : `${pathname}/${propertyHrId}/account/${encodeURIComponent(accountCode)}`;
  return (
    <Link to={url} className="LinkToAccountSheet">
      {children}
    </Link>
  );
};

LinkToAccountSheet.defaultProps = {
  economicYear: undefined,
};

export default LinkToAccountSheet;
