import {
  AccountControllerApi, AccountDistributionKeyControllerApi, AccountDistributionKeyDto, ExtendedAccountBalanceDto, GetUnitContractsUsingGETContractTypesEnum, GetVatRatesUsingGETCountryCodeEnum, PropertyDisplayDtoVatRateCountryCodeEnum, PropertyDisplayDtoVatRelevanceEnum, UnitContractControllerApi, UnitContractProjectionDto, UnitLegacyControllerApi, UnitProjectionDto, VatRateControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { ProfitAndLossReportContext, ReportCreationData } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext } from 'react';
import { translations } from '../../../../translations';

export const useGetProfitAndLossReportData = () => {
  const { tl } = useContext(LanguageContext);
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);


  if (profitAndLossReportContext === undefined) {
    throw new Error('useGetProfitAndLossReportData must be used within a ProfitAndLossReportContextProvider');
  }


  const {
    setAccountBalances, setDistributionKeys, setUnits, setSimulationAccountBalances, setUnitContracts,
    setIncomeAccountBalancesByVatPercentage, setAccountBalancesByCounterpartIncome, setAccountBalancesByCounterpartNotIncome,
  } = profitAndLossReportContext;

  const { apiConfiguration } = useContext(AuthContext);
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const accountDistributionKeyControllerApi = new AccountDistributionKeyControllerApi(apiConfiguration('accounting'));
  const unitControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const vatRateControllerApi = new VatRateControllerApi(apiConfiguration('accounting'));

  const onError = (onErrorCb?: () => void) => {
    showNotification({
      type: 'error',
      message: tl(translations.notifications.loadAccountBalancesError),
    });
    setAccountBalances(prev => prev.failed());
    setDistributionKeys(prev => prev.failed());
    setUnits(prev => prev.failed());
    setUnitContracts(prev => prev.failed());
    setSimulationAccountBalances(prev => prev.failed());
    setIncomeAccountBalancesByVatPercentage(prev => prev.failed());
    setAccountBalancesByCounterpartIncome(prev => prev.failed());
    setAccountBalancesByCounterpartNotIncome(prev => prev.failed());
    onErrorCb?.();
  };


  const getProfitAndLossReportData = ({ reportData, onSuccessCb, onErrorCb }: { reportData: ReportCreationData, onSuccessCb?: () => void, onErrorCb?: () => void }) => {
    setAccountBalances(prev => prev.startLoading());
    setDistributionKeys(prev => prev.startLoading());
    setUnits(prev => prev.startLoading());
    setUnitContracts(prev => prev.startLoading());
    setSimulationAccountBalances(prev => prev.startLoading());
    setIncomeAccountBalancesByVatPercentage(prev => prev.startLoading());
    setAccountBalancesByCounterpartIncome(prev => prev.startLoading());
    setAccountBalancesByCounterpartNotIncome(prev => prev.startLoading());

    const unitRequest: Promise<UnitProjectionDto[]> = unitControllerApi.getSimpleUnitsUsingGET({ propertyId: reportData.property.id });
    const unitContractRequest: Promise<UnitContractProjectionDto[]> = unitContractControllerApi
      .getUnitContractsUsingGET({
        propertyId: reportData.property.id,
        contractTypes: [GetUnitContractsUsingGETContractTypesEnum.TENANT] as unknown as GetUnitContractsUsingGETContractTypesEnum,
      });
    const accountBalanceRequest: Promise<{ [key: string]: ExtendedAccountBalanceDto }> = accountControllerApi.getExtendedAccountBalancesForAccountCodesUsingPOST({
      startDate: reportData.startDate.format('YYYY-MM-DD'),
      endDate: reportData.endDate.format('YYYY-MM-DD'),
      propertyHrId: reportData.property.propertyHrId,
    });
    const distributionKEyRequest: Promise<AccountDistributionKeyDto[]> = accountDistributionKeyControllerApi.getAccountDistributionsForPropertyByEconomicYearUsingGET({
      economicYear: reportData.endDate.format('YYYY'),
      propertyId: reportData.property.id,
    });
    const simulationAccountBalancesRequest = accountControllerApi.getExtendedAccountBalancesUsingGET({
      propertyHrId: reportData.property.propertyHrId,
      startDate: `${reportData.startDate.format('YYYY')}-01-01`,
      endDate: reportData.endDate.format('YYYY-MM-DD'),
      regex: '^[68].*$',
    });

    const accountBalancesWhereCounterpartNotCashRequest = accountControllerApi.getAccountBalancesFilteredUsingGET({
      startDate: reportData.startDate.format('YYYY-MM-DD'),
      endDate: reportData.endDate.format('YYYY-MM-DD'),
      propertyHrId: reportData.property.propertyHrId,
      accountCodeRegex: '^2001/[0-9]+/.*$',
      counterpartCodeRegex: '^(?!2[6789]).*$',
    });

    const accountBalancesWhereCounterpartCashRequest = accountControllerApi.getAccountBalancesFilteredUsingGET({
      startDate: reportData.startDate.format('YYYY-MM-DD'),
      endDate: reportData.endDate.format('YYYY-MM-DD'),
      propertyHrId: reportData.property.propertyHrId,
      accountCodeRegex: '^2001/[0-9]+/.*$',
      counterpartCodeRegex: '^(2[6789]).*$',
    });

    const vatRelevantGermanProperty = [PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT, PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT]
      .includes(reportData.property?.vatRelevance) && reportData.property?.vatRateCountryCode === PropertyDisplayDtoVatRateCountryCodeEnum.DE;

    const incomeAccountBalancesByVatPercentageRequests = vatRelevantGermanProperty ? buildIncomeAccountBalancesByVatPercentageRequests({ reportData }) : Promise.resolve([Promise.resolve(undefined)]);

    Promise.allSettled([accountBalanceRequest, distributionKEyRequest, unitRequest, simulationAccountBalancesRequest, unitContractRequest, incomeAccountBalancesByVatPercentageRequests,
      accountBalancesWhereCounterpartNotCashRequest, accountBalancesWhereCounterpartCashRequest])
      .then(([accountResp, dkResp, unitResp, simulationResp, unitContractResp, incomeAccountBalancesByVatPercentageResp,
        accountBalancesWhereCounterpartNotCashResp, accountBalancesWhereCounterpartCashResp]) => {
        if (
          accountResp.status === 'rejected'
          || dkResp.status === 'rejected'
          || unitResp.status === 'rejected'
          || simulationResp.status === 'rejected'
          || unitContractResp.status === 'rejected'
          || incomeAccountBalancesByVatPercentageResp.status === 'rejected'
          || accountBalancesWhereCounterpartNotCashResp.status === 'rejected'
          || accountBalancesWhereCounterpartCashResp.status === 'rejected'
        ) {
          onError(onErrorCb);
          return;
        }

        Promise.allSettled(incomeAccountBalancesByVatPercentageResp.value)
          .then((resp) => {
            if (resp.some(r => r.status === 'rejected')) {
              onError(onErrorCb);
              return;
            }

            // ts-ignore needed because value of promise is not recognized for some reason
            // @ts-ignore
            setIncomeAccountBalancesByVatPercentage(prev => prev.load(resp.filter(r => r.value !== undefined).map(r => r.value)));

            setDistributionKeys(prev => prev.load(dkResp.value));
            setAccountBalances(prev => prev.load(accountResp.value));
            setUnits(prev => prev.load(unitResp.value));
            setUnitContracts(prev => prev.load(unitContractResp.value));
            setSimulationAccountBalances(prev => prev.load(simulationResp.value));
            setAccountBalancesByCounterpartIncome(prev => prev.load(accountBalancesWhereCounterpartNotCashResp.value));
            setAccountBalancesByCounterpartNotIncome(prev => prev.load(accountBalancesWhereCounterpartCashResp.value));
            onSuccessCb?.();
          });
      });
  };

  const buildIncomeAccountBalancesByVatPercentageRequests = ({ reportData }: { reportData: ReportCreationData }) => getVatRate({ reportData })
    .then((vatRates) => {
      const vatRate = vatRates?.[0];
      return [vatRate.vatPercentage, vatRate.reducedVatPercentage, 0].map(vatPercentage => accountControllerApi.getExtendedAccountBalancesUsingGET({
        startDate: reportData.startDate.format('YYYY-MM-DD'),
        endDate: reportData.endDate.format('YYYY-MM-DD'),
        propertyHrId: reportData.property.propertyHrId,
        vatPercentageFilter: vatPercentage,
        regex: '^(?!695.*)6.*$',
      }).then(resp => Promise.resolve({
        vatPercentage,
        accountBalances: resp,
        ignoreIfNoBalance: vatPercentage === vatRate.reducedVatPercentage,
      })));
    });

  const getVatRate = ({ reportData }: { reportData: ReportCreationData }) => vatRateControllerApi.getVatRatesUsingGET({ countryCode: reportData.property?.vatRateCountryCode as unknown as GetVatRatesUsingGETCountryCodeEnum });

  return { getProfitAndLossReportData };
};
