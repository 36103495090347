import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';
import { translations } from './translations';
import { LanguageContext } from '../../contexts/LanguageContext';


export interface OverlayContextType {
  overlays?: Overlay[],
  lastOverlayUrl?: string,
  addOverlay?: (overlay: Overlay) => [number, number, number, string, string, string],
  popOverlay?: (pathToRemove: string) => void,
  goBack?: (navigationProps?: any) => void,
}

export const OverlayContext = React.createContext<OverlayContextType | undefined>(undefined);

export enum overlayTypes {
  FOCUS = 'focus', OVERLAY = 'overlay', MAIN = 'main'
}

export interface Overlay {
  path: string
  type: overlayTypes
  url?: string,
  exact?: boolean,
  label?: string,
  search?: string,
  addBreadcrumb?: boolean,
}

/**
 * Overlay stack context.
 * Overlays are now stored globally
 */
export const OverlayContextProvider: React.FC = ({ children }) => {
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const size = useRef<number>(0);
  const lastFocusLevel = useRef<number>(0);
  const currentZIndex = useRef<number>(200);
  const lastOverlayUrl = useRef<string>('/dashboard');
  const lastOverlayPath = useRef<string>('/dashboard');
  const OVERLAY_INCREMENT = 10;
  const FOCUS_INCREMENT = 1300; // needs to be higher than the header's z-index, lower than z-index-max (dropdowns/modals)
  const location = useLocation<{ updateUrlOnCurrentOverlay?: boolean }>();
  const history = useHistory();
  const { tl } = useContext(LanguageContext);

  useEffect(() => {
    if ((history.action === 'REPLACE' || location.state?.updateUrlOnCurrentOverlay) && overlays.length > 0) {
      //  if the last navigation action was replace, update the url of the last overlay
      overlays[overlays.length - 1].url = location.pathname;
    }

    // update the overlay url if the parameters changed
    // e.g. On unit contract creation 'Save and open next unit' will change the unit id in the url, so we need to update the urls of previous overlays as well
    setOverlays((prev) => {
      let isDirty = false;
      const newOverlays = prev.map((ov) => {
        const matchObj = matchPath(location.pathname, { path: ov.path, exact: false, strict: false });
        if (matchObj !== null && matchObj.url !== ov.url) {
          isDirty = true;
          return {
            ...ov,
            url: matchObj.url,
          };
        }

        // if is currently active overlay and search params changed, then update it
        if (ov.url === location.pathname && ov.search !== location.search) {
          isDirty = true;
          return {
            ...ov,
            search: location.search,
          };
        }

        return ov;
      });
      if (isDirty) {
        return newOverlays;
      }
      return prev;
    });
  }, [location]);

  /*
  Method to determine the label of the overlay displayed in the search bar. If there is a previous overlay and its url
  is part of the current one's url, then that part would be "removed" in order to prevent the duplication of the labels.
  The url is split by '/', the label will contain the translations which belong to splits, or the splits themselves, it there are no translations provided (mostly ids)
  * */
  const computeOverlayNavigationLabel = (overlay: Overlay) => {
    let label = '';
    const regex = new RegExp('^[0-9]+$');
    if (overlay && overlay.url) {
      let urlParts;
      if (lastOverlayUrl) {
        const urlPartsWithoutLastOverlaysUrl = overlay.url.split(lastOverlayUrl.current);
        urlParts = urlPartsWithoutLastOverlaysUrl[urlPartsWithoutLastOverlaysUrl.length - 1].split('/');
      } else {
        urlParts = overlay.url.split('/');
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= urlParts.length - 1; i++) {
        if (translations[urlParts[i].replace(new RegExp('-', 'g'), '')]) {
          label += ` ${tl(translations[urlParts[i].replace(new RegExp('-', 'g'), '')])}`;
        } else if (i >= 1 && regex.test(urlParts[i - 1]) && regex.test(urlParts[i])) {
          label += `${urlParts[i]}`;
        } else label += ` ${decodeURIComponent(urlParts[i])}`;
      }
    }
    return label.trim();
  };

  const addOverlay: (overlay: Overlay) => [number, number, number, string, string, string] = (overlay) => {
    size.current += 1;
    const currentLastOverlayUrl = lastOverlayUrl.current;
    const currentLastPath = lastOverlayPath.current;
    currentZIndex.current += overlay.type === overlayTypes.FOCUS ? FOCUS_INCREMENT : OVERLAY_INCREMENT;
    if (overlay.type === overlayTypes.FOCUS) {
      lastFocusLevel.current = size.current - 1;
    }

    overlay.label = computeOverlayNavigationLabel(overlay);

    lastOverlayUrl.current = overlay.url || '/dashboard';
    lastOverlayPath.current = overlay.path || '/dashboard';

    setOverlays(prevOverlays => prevOverlays.concat([overlay]));

    return [size.current, currentZIndex.current, lastFocusLevel.current, currentLastOverlayUrl, currentLastPath, overlay.label];
  };

  const popOverlay: (pathToRemove: string) => void = (pathToRemove) => {
    size.current -= 1;

    setOverlays((oldOverlayList) => {
      try {
        lastOverlayUrl.current = oldOverlayList[oldOverlayList.length - 2].url || '';
        lastOverlayPath.current = oldOverlayList[oldOverlayList.length - 2].path || '';
      } catch (e) {
        // if there are less then 2 overlays, 1 gets removed and 1 is the main page => dashboard is the previous page
        lastOverlayUrl.current = '/dashboard';
        lastOverlayPath.current = '/dashboard';
      }
      const newOverlayList = oldOverlayList.slice();
      const indexToRemove = oldOverlayList.findIndex((o: Overlay) => o.path === pathToRemove);
      const overlay = oldOverlayList[indexToRemove];
      currentZIndex.current -= overlay?.type === overlayTypes.FOCUS ? FOCUS_INCREMENT : OVERLAY_INCREMENT;
      if (overlay?.type === overlayTypes.FOCUS) {
        // if focus was removed, find previous focus overlay
        const tempLastFocusLevel = oldOverlayList.slice(0, -1).map(o => o.type).lastIndexOf(overlayTypes.FOCUS);
        if (tempLastFocusLevel === -1) {
          lastFocusLevel.current = 0;
        } else {
          lastFocusLevel.current = tempLastFocusLevel;
        }
      }
      newOverlayList.splice(indexToRemove, 1);
      return newOverlayList;
    });
  };

  const goBack = (navigationProps?: any) => {
    const bankTransactionsUrlRegex = new RegExp('/bank-transactions/[^/]+$');
    const state = navigationProps && navigationProps.state ? navigationProps.state : null;
    if (overlays && overlays.length > 1 && overlays[overlays.length - 2] && overlays[overlays.length - 2].url) {
      history.push(`${overlays[overlays.length - 2].url!}${overlays[overlays.length - 2].search}`, state);
    } else if (bankTransactionsUrlRegex.test(overlays[0]?.url ?? '')) {
      history.replace('/bank-transactions', { ...(location.state) });
    } else {
      history.push('/dashboard', state);
    }
  };

  return (
    <OverlayContext.Provider value={{
      overlays,
      lastOverlayUrl: lastOverlayUrl.current,
      addOverlay,
      popOverlay,
      goBack,
    }}
    >
      {children}
    </OverlayContext.Provider>
  );
};
