import { RouteDefinition } from '../../components/RenderRoutes';
import { DirectDebitListWithProvider } from './DirectDebitList';
import DirectDebitView from '../DirectDebitView/DirectDebitView';

export const DIRECT_DEBIT_LIST_URL = '/direct-debits';

export const DirectDebitRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: DIRECT_DEBIT_LIST_URL,
      component: DirectDebitListWithProvider,
      subRoutes: [
        {
          path: `${DIRECT_DEBIT_LIST_URL}/new`,
          component: DirectDebitView,
          type: 'overlay',
          width: '60.0rem',
          exact: true,
        },
      ],
    },
    {
      path: `*/:propertyIdInternal${DIRECT_DEBIT_LIST_URL}`,
      component: DirectDebitListWithProvider,
      type: 'overlay',
      width: '90%',
    },
  ],
};
