import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { Distribution, TableDataSourceType } from 'pages/ManualExchange/services/ManualExchangeContext';
import { useManualExchangeVatSelectorCell } from './useManualExchangeVatSelectorCell';

type Props = {
  record: TableDataSourceType;
  onChangeDistributionValues: (unitAccountCode: string, field: keyof Distribution, value: string | number, record: TableDataSourceType) => void
};

const ManualExchangeVatSelectorCell = ({
  record,
  onChangeDistributionValues,
}: Props) => {
  const { vatSelectOptions, vatPercentage } = useManualExchangeVatSelectorCell(record);

  const onChange = (value) => {
    onChangeDistributionValues(record.accountCode!, 'vatPercentage', value, record);
  };

  const disabled = vatSelectOptions?.length <= 1;

  return (
    <SelectInput
      defaultValue={vatPercentage}
      options={vatSelectOptions}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default ManualExchangeVatSelectorCell;
