import { Form, Checkbox, Tooltip } from 'antd';
import * as React from 'react';
import './CheckboxInput.scss';
import InputProps from '../InputProps';


export default function CheckboxInput(props: InputProps<boolean>): JSX.Element {
  const {
    className, disabled, value, label, onChange, validationState, validationMessage, inputClassName, indeterminate, style, tooltipText,
  } = props;
  const onChangeHandler = (event: any) => {
    onChange(event.target.checked);
  };

  const tooltipPopupContainer = () => (document.getElementsByClassName(`CheckboxInput ${className}`)[0] as HTMLElement) ?? document.body;

  return (
    <div
      className={`CheckboxInput ${className}`}
      onClick={e => e.stopPropagation()}
    >
      <Form.Item
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        {tooltipText
          ? (
            <Tooltip title={tooltipText} placement="bottom" getPopupContainer={tooltipPopupContainer}>
              <Checkbox
                style={style}
                className={`${inputClassName}`}
                onChange={onChangeHandler}
                onClick={e => e.stopPropagation()}
                checked={value}
                disabled={disabled || false}
                indeterminate={indeterminate}
              >
                {label}
              </Checkbox>
            </Tooltip>
          ) : (
            <Checkbox
              style={style}
              className={`${inputClassName}`}
              onChange={onChangeHandler}
              onClick={e => e.stopPropagation()}
              checked={value}
              disabled={disabled || false}
              indeterminate={indeterminate}
            >
              {label}
            </Checkbox>
          )
        }
      </Form.Item>
    </div>
  );
}
