import { useSimpleDirtModal } from 'elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';
import moment from 'moment';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { groupBy, sum } from 'lodash';
import { AuthContext } from 'contexts/AuthContext';
import {
  EconomicPlanControllerApi, EconomicPlanDtoStatusEnum, HouseMoneyDto, MonthlyHouseMoneyDto, PropertyLegacyDto,
} from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { EconomicPlanContext } from '../../../../services/EconomicPlanContext';

const DATE_FORMAT = 'YYYY-MM-DD';
export const useCorrectionalBookings = () => {
  const economicPlanContext = useContext(EconomicPlanContext);

  if (economicPlanContext === undefined) { // NOTE: houseMoneyListContext is undefined in case of account_distribution EPs and that is expected
    throw new Error('useCorrectionalBookings must be used within a EconomicPlanContextProvider');
  }

  const [correctionAmounts, setCorrectionAmounts] = useState<DefaultDataInterface<HouseMoneyDto[]>>(DEFAULT_DATA([]));

  const { selectedProperty } = useContext(PropertyListContext);
  const property: PropertyLegacyDto = selectedProperty.data;
  const { economicPlan, setEconomicPlan } = economicPlanContext;
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const { addDirt } = useSimpleDirtModal();

  useEffect(() => {
    if (economicPlan.data?.id && economicPlan.loaded) {
      setCorrectionAmounts(prev => prev.startLoading());
      economicPlanControllerApi.getCorrectionAmountsUsingGET({ economicPlanId: economicPlan?.data?.id })
        .then((response: Array<MonthlyHouseMoneyDto>) => {
          setCorrectionAmounts(prev => prev.load(response));
        })
        .catch(() => correctionAmounts.failed());
    }
  }, [economicPlan.loaded, economicPlan.data?.id]);

  const amounts = useMemo(() => {
    const correctionAmountsByUnit = groupBy(correctionAmounts.data, 'unitId');
    return Object.keys(correctionAmountsByUnit)
      .map((unitId) => {
        const currentUnitCorrectionAmounts: Array<HouseMoneyDto> = correctionAmountsByUnit[unitId];
        const currentUnitCorrectionAmountValues = currentUnitCorrectionAmounts.flatMap(it => it.houseMoneyAmounts).map(it => it.amount);
        return ({
          unitId: parseInt(unitId, 10),
          amount: sum(currentUnitCorrectionAmountValues),
        });
      });
  }, [correctionAmounts.data]);

  const onChangeBookingDate = (newValue: moment.Moment) => {
    addDirt();
    setEconomicPlan(prev => prev.load({
      ...prev.data,
      correctionBookingDate: newValue?.format(DATE_FORMAT) || moment().format(DATE_FORMAT),
    }));
  };

  return {
    loading: correctionAmounts.loading || economicPlan?.loading,
    bookingDate: moment(economicPlan.data?.correctionBookingDate),
    amounts,
    onChangeBookingDate,
    propertyId: property.id,
    isEditable: (economicPlan?.data?.status === EconomicPlanDtoStatusEnum.DRAFT),
  };
};
