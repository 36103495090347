import { Table } from 'antd';
import { PauseOutlined } from '@ant-design/icons';
import React from 'react';
import './TableSummaryIconCell.scss';

interface Props {
  colSpan?: number;
  content?: React.ReactNode,
}

export const TableSummaryIconCell = ({ colSpan, content }: Props) => (
  <Table.Summary.Cell colSpan={colSpan} index={0} className="TableSummaryIconCell">
    <span className="summary-icon">
      <PauseOutlined rotate={90} className="clipped-background" />
    </span>
    {content && <span className="summary-content">{content}</span>}
  </Table.Summary.Cell>
);


TableSummaryIconCell.defaultProps = {
  colSpan: 1,
  content: undefined,
};
