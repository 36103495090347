import React, { useContext } from 'react';
import './BankInformation.scss';
import { Card } from 'antd';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../../../../../elements/Translation/translations';

interface BankInformationProps {
  bankAccounts: any,
}

export default function BankInformation(props: BankInformationProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { bankAccounts } = props;
  let cardTitle = tl(translations.pages.contactDetails.bankInformation.smallTitle);

  return (
    <div className="BankInformation component app">
      <Card className="data-card hs">
        {bankAccounts.map((bankAccount: any, number: number) => (
          <div className=" section item" key={bankAccount.iban}>
            <div className="title-small">
              {cardTitle} {number + 1}
            </div>
            <div className="account-holder field">
              <div className="label">
                {tl(translations.pages.contactDetails.bankInformation.fields.accountHolder)}
              </div>
              <div className="value">
                {bankAccount.accountHolderName ? bankAccount.accountHolderName : '-'}
              </div>
            </div>
            <div className="bank field">
              <div
                className="label"
              >
                {tl(translations.pages.contactDetails.bankInformation.fields.bank)}
              </div>
              <div className="value">
                {bankAccount.accountInstitute ? bankAccount.accountInstitute : '-'}
              </div>
            </div>
            <div className="iban field">
              <div className="label">
                {tl(translations.pages.contactDetails.bankInformation.fields.iban)}
              </div>
              <div className="value">
                {bankAccount.iban ? bankAccount.iban : '-'}
              </div>
            </div>
            <div className="bic field">
              <div className="label">
                {tl(translations.pages.contactDetails.bankInformation.fields.bic)}
              </div>
              <div className="value">
                {bankAccount.bic ? bankAccount.bic : '-'}
              </div>
            </div>
          </div>))}
      </Card>
    </div>
  );
}
