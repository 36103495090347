import { MessageListProjectionStateEnum } from 'api/accounting';
import { uniq } from 'lodash';
import { useMessagesSelectionContext } from './MessagesListContext';

export const useMessageListCheckboxes = () => {
  const {
    selectedRowKeysCurrent,
    setSelectedRowKeysCurrent,
    setSelectedRowKeysTotal,
  } = useMessagesSelectionContext('useMessageListCheckboxes');

  const onChangeSelectedRowKeys = (selected) => {
    setSelectedRowKeysCurrent((prevCurrent) => {
      // theoretically should only be one deselected row
      const deselected = prevCurrent.filter(
        rowKey => !selected.includes(rowKey),
      )[0];


      // remove the deselected row from total
      setSelectedRowKeysTotal(prevTotal => uniq([
        ...prevTotal.filter(key => key !== deselected),
        ...selected,
      ]));

      return selected;
    });
  };

  const shouldDisableCheckbox = record => [MessageListProjectionStateEnum.GENERATING, MessageListProjectionStateEnum.FAILED].includes(record.state);


  return {
    selectedRowKeysCurrent,
    onChangeSelectedRowKeys,
    shouldDisableCheckbox,
  };
};
