import { useSevOwnerContext } from './SevOwnerContext';

export const useSevContextData = () => {
  const {
    sevOwnerContract, sevOwnerProperty, setSevOwnerContract, setSevOwnerProperty,
  } = useSevOwnerContext('useInitSevContext');

  const onChangeSevOwnerContract = (value) => {
    setSevOwnerContract(value);
  };

  const onChangeSevOwnerProperty = (value) => {
    setSevOwnerProperty(value);
  };

  return {
    sevOwnerContract,
    sevOwnerProperty,
    onChangeSevOwnerContract,
    onChangeSevOwnerProperty,
  };
};
