import React from 'react';
import './NumberInputWithButtons.scss';
import { Form, Input } from 'antd';
import Icon from '@ant-design/icons';
import { ICONS } from '../../../components/icons';
import InputProps from '../InputProps';

interface numberInputWithButtonsProps extends InputProps<number> {
  min?: number
  max?: number
  decreaseHidden?: boolean
  decreaseDisabled?: boolean
  increaseDisabled?: boolean
}

export default function NumberInputWithButtons(props: numberInputWithButtonsProps): JSX.Element {
  const {
    value, className, label, required, inputClassName, disabled, validationState, validationMessage, max, min, decreaseDisabled, increaseDisabled,
    decreaseHidden, autoFocus,
  } = props;
  const onChangeInput = (event: any): void => {
    onChange(parseInt(event.target.value, 10));
  };

  const onChange = (v: number): void => {
    if (typeof (min) !== 'undefined' && v < min) {
      v = min;
    } else if (typeof (max) !== 'undefined' && v > max) {
      v = max;
    }
    props.onChange(v);
  };

  const minusDisabled = disabled || decreaseDisabled || (min !== undefined && value <= min);
  const plusDisabled = disabled || increaseDisabled || (max !== undefined && value >= max);

  return (
    <div className={`NumberInputWithButtons ${className}`}>
      <Form.Item
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={validationState || 'success'}
        help={(
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <div className="button-wrapper">
          {!disabled && !decreaseHidden && (
            <button
              onClick={() => onChange(value - 1)}
              disabled={minusDisabled}
            >
              <Icon component={ICONS.minus} />
            </button>
          )}
          <Input
            className={`Input number-input ${inputClassName} ${disabled ? 'disabled' : ''}`}
            type="number"
            value={typeof value !== 'undefined' ? value : ''}
            onChange={onChangeInput}
            disabled
          />
          {!disabled && (
            <button
              onClick={() => onChange(value + 1)}
              disabled={plusDisabled}
              autoFocus={autoFocus}
            >
              <Icon component={ICONS.plus} />
            </button>
          )}
        </div>
      </Form.Item>
    </div>
  );
}
NumberInputWithButtons.defaultProps = {
  value: 0,
  className: '',
  inputClassName: '',
};
