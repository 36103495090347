import { UnitContractProjectionDto } from 'api/accounting';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import React from 'react';
import { useProcessingSelectorCell } from '../../services/useProcessingSelectorCell';


interface Props {
  record: UnitContractProjectionDto;
}


const getProcessingDefaultValue = (record: UnitContractProjectionDto) => {
  // we are assuming that the default direction is `incoming`
  if (record.hasMandateAtDate) return 'PLATFORM';
  return 'SELF_PAYER';
};


const ProcessingSelector = ({ record }: Props) => {
  const { SELECT_OPTIONS, value, onChangeProcessing } = useProcessingSelectorCell(record);

  return (
    <SelectInput
      options={SELECT_OPTIONS}
      value={value}
      disabled={SELECT_OPTIONS.length < 2}
      onChange={val => onChangeProcessing!(val, record)}
      defaultValue={getProcessingDefaultValue(record)}
    />
  );
};

export default ProcessingSelector;
