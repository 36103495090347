import useResizeableDrawerSize from 'components/Drawer/useResizeableDrawerSize';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useBankTransactionListDrawerContext } from './BankTransactionListContext';
import { useSelectedTransactions } from './useSelectedTransactions';

export const useTransactionAllocationDrawer = () => {
  const [drawerId] = useState(Math.random().toString(36).substring(7));
  const [heightOfDrawer, setHeightOfDrawer] = useState<number | undefined>(undefined);

  const {
    selectedTransactions,
    allocationVisible,
    setAllocationVisible,
  } = useBankTransactionListDrawerContext('useTransactionAllocationDrawer');

  const {
    removePartiallyAllocatedTransactionsFromListIfOnDoneTab,
    removeAllocatedTransactionsFromListIfOnOpenTab,
  } = useSelectedTransactions();

  useEffect(() => {
    if (!_.isEmpty(selectedTransactions)) {
      // scroll to the selected row
      const selectedRow = document.getElementsByClassName('selected-row')[0];
      if (selectedRow) {
        selectedRow.scrollIntoView({ block: 'start' });
      }
    }
  }, [selectedTransactions[0]?.allocationGroupId]);

  const resizeableDrawerSize = useResizeableDrawerSize({
    placement: 'bottom',
    defaultSize: '60%',
  });

  const debouncedSetHeightOfDrawer = useCallback(_.debounce((allocationVisibleA, resizeableDrawerSizeA) => {
    setHeightOfDrawer(allocationVisibleA ? resizeableDrawerSizeA.height : undefined);
  }, 300), []);


  useEffect(() => {
    if (heightOfDrawer) {
      // the timeout is needed because after the heightOfDrawer changes
      // the rerender doesn't happen fast enough to be able to scroll there instantly
      setTimeout(() => {
        const selectedRow = document.getElementsByClassName('selected-row')[0];

        if (selectedRow) {
          selectedRow.scrollIntoView({ block: 'start' });
        }
      }, 1000);
    }
  }, [heightOfDrawer]);

  useEffect(() => {
    debouncedSetHeightOfDrawer(allocationVisible, resizeableDrawerSize);
  }, [resizeableDrawerSize.height]);


  const afterVisibleChangeDrawer = () => {
    setHeightOfDrawer(
      allocationVisible ? resizeableDrawerSize.height : undefined,
    );
    if (!allocationVisible) {
      removePartiallyAllocatedTransactionsFromListIfOnDoneTab();
      removeAllocatedTransactionsFromListIfOnOpenTab();
    }
  };

  return {
    drawerId,
    heightOfDrawer,
    resizeableDrawerSize,
    allocationVisible,
    setAllocationVisible,
    afterVisibleChangeDrawer,
  };
};
