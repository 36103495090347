import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { translations } from 'elements/Translation/translations';
import { DropMenu } from 'components/DropMenu/DropMenu';
import { navigateConcat } from '../../../../navigation/History';
import './ContactHeaderButtons.scss';
import { useContactDeleteButton } from './useContactDeleteButton';

interface ContactHeaderButtonsProps {
  contactType: ContactLegacyDtoTypeEnum,
  contactId: number,
  contactName?: string,
  editEnabled: boolean,
  onSave?: (boolean) => void,
  onGoBack?: () => void,
  duplicate?: boolean,
}

export const ContactHeaderButtons = ({
  contactType,
  contactId,
  contactName,
  editEnabled,
  onSave,
  onGoBack,
  duplicate,
}: ContactHeaderButtonsProps) => {
  const { tl } = useContext(LanguageContext);
  const { onClickDelete } = useContactDeleteButton();
  const navigationUrl = contactType === ContactLegacyDtoTypeEnum.PERSON
    ? '/edit-person/'
    : '/edit-company/';

  const isNotManagementCompany = contactType !== ContactLegacyDtoTypeEnum.MANAGEMENT_COMPANY;

  if (editEnabled) {
    return (
      <div className="buttons">
        <Button
          className="cancel"
          type="text"
          onClick={onGoBack}
        >
          {tl(translations.pages.contactEditing.header.close)}
        </Button>
        <Button
          className="save"
          type="primary"
          onClick={() => onSave(false)}
          disabled={duplicate}
        >
          {tl(translations.pages.contactEditing.header.save)}
        </Button>
      </div>

    );
  }

  return (
    <div className="buttons">
      <Button
        className="cancel"
        type="text"
        onClick={onGoBack}
      >
        {tl(translations.pages.contactEditing.header.close)}
      </Button>
      { isNotManagementCompany
      && (
        <DropMenu
          options={[
            {
              label: tl(translations.pages.contactDetails.header.delete),
              onClick: () => onClickDelete(contactName, contactId),
            },
          ]}
        />
      )
      }
      <Button
        type="default"
        onClick={() => navigateConcat(`${navigationUrl}${contactId}`)}
      >
        {tl(translations.pages.contactDetails.header.edit)}
      </Button>
    </div>
  );
};

export default ContactHeaderButtons;

ContactHeaderButtons.defaultProps = {
  contactName: '',
  onSave: undefined,
  onGoBack: undefined,
  duplicate: false,
};
