import {
  PropertyDisplayDto,
  UnitContractProjectionDto,
  UnitLegacyDto,
} from 'api/accounting';
import { DocumentProjectionDto } from 'api/document';
import { DefaultDataInterface } from 'lib/data';

interface AssignmentBase {
  assignmentDocumentId?: number,
  originalDocumentId: number,
  unitId?: number, // IF contractId === undefined && unitId === undefined THEN assignment to PROPERTY
  contractId?: number, // IF contractId === undefined && unitId !== undefined THEN assignment to UNIT
}

export interface ExistingAssignment extends AssignmentBase {
  pages: number[],
}

export interface CurrentAssignment extends AssignmentBase {
  page: number,
}

export interface SelectedPage {
  documentId: number,
  pageNumber: number,
}

export enum PagesOrientationType {
  GRID = 'GRID',
  LIST = 'LIST'
}

export interface DocumentMetaDataType {
  originalDocumentId: number;
  originalDocumentPages: number[];
  originalFileName?: string,
}

export type PdfAssignmentDocumentType = Pick<DocumentProjectionDto, 'id' | 'url' | 'name'> & {
  metaData?: DocumentMetaDataType
};


export interface PdfPageAssignerUpdatersContextType {
  setViewMode: React.Dispatch<React.SetStateAction<PagesOrientationType>>,
  setIdOfPageInView: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setProperty: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyDisplayDto>>>,
  setUnits: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitLegacyDto[]>>>,
  setContractsWithinDateRange: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  setContractsOutsideOfDateRange: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  setOriginalDocuments: React.Dispatch<React.SetStateAction<DefaultDataInterface<PdfAssignmentDocumentType[]>>>,
  setExistingAssignments: React.Dispatch<React.SetStateAction<ExistingAssignment[]>>,
  setCurrentAssignments: React.Dispatch<React.SetStateAction<CurrentAssignment[]>>,
  setSelectedPages: React.Dispatch<React.SetStateAction<SelectedPage[]>>,
  setLoadedPdfsData: React.Dispatch<React.SetStateAction<LoadedPdfData[]>>
  setShowWarningForSelectedPages: React.Dispatch<React.SetStateAction<boolean>>
}

export interface PdfPageAssignerDataContextType {
  viewMode: PagesOrientationType,
  idOfPageInView: string,
  loading: boolean,
  property: DefaultDataInterface<PropertyDisplayDto>,
  units: DefaultDataInterface<UnitLegacyDto[]>,
  contractsWithinDateRange: DefaultDataInterface<UnitContractProjectionDto[]>,
  contractsOutsideOfDateRange: DefaultDataInterface<UnitContractProjectionDto[]>,
  originalDocuments: DefaultDataInterface<PdfAssignmentDocumentType[]>,
  existingAssignments: ExistingAssignment[],
  loadedPdfsData: LoadedPdfData[],
  showWarningForSelectedPages: boolean,
}

export type PdfPageAssignerCurrentAssignmentsContextType = CurrentAssignment[];

export type PdfPageAssignerSelectedPagesContextType = SelectedPage[];

/**
 * The pdf assignment page's header needs to know the number of total pages of the pdfs
 */
export interface LoadedPdfData {
  documentId: number,
  nrPages: number,
}
