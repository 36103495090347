import { useContext } from 'react';

import { Modal } from 'antd';
import {
  ContractContactCreationDto,
  ContractContactCreationDtoRoleEnum,
  ContractCreationDto,
  ContractCreationDtoDunningLevelEnum,
  ContractCreationDtoTypeEnum,
  ContractCreationDtoVatRelevanceEnum,
  ContractLegacyControllerApi,
  ContractProjectionDto,
  ContractUpdateDto,
  ContractUpdateDtoDunningLevelEnum,
  ContractUpdateDtoTypeEnum,
  ContractUpdateDtoVatRelevanceEnum,
  ErrorCodeUsingGETValueEnum,
  GetContractsUsingGETTypeEnum,
  PropertyLegacyDtoVatEligibilityTypeEnum,
  UnitContractMandateUsageControllerApi,
  UnitContractMandateUsageCreationDtoStateEnum,
} from 'api/accounting';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { formatDate } from 'lib/Utils';
import _, { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import {
  useInvalidateAllContractsListCache,
} from 'pages/Property/PropertyEditing/services/useInvalidateAllContractsList';
import { useHistory } from 'react-router';
import {
  useInvalidateDirectDebitMandateListCache,
} from 'services/DirectDebitMandateList/useInvalidateDirectDebitMandateListCache';
import {
  useInvalidateActiveContractsListCache,
} from 'services/UnitContractsList/useInvalidateActiveContractsListCache';
import { useSevContextData } from 'pages/Property/PropertyEditing/services/SevEditingContext/useSevOwnerContextData';
import { translations } from '../translations';
import {
  ContractDetails,
  ContractDetailsContext,
  DEFAULT_CONTRACT_DETAILS_STATE,
  DirtyContractContext,
  InitialContractContext,
  NO_DUNNING_OPTION_VALUE,
  SelectedContact,
  SelectedContactsContext,
  UnitContractEditorUpdatersContext,
} from './UnitContractEditorContext';
import {
  useContractCoreInformationValidation,
} from './useContractCoreInformationValidation';
import { useContractGroupWithProperties } from './ContractGroupWithPropertiesContext/useContractGroupWithProperties';
import { ContractWarningModalContent } from '../components/ContractWarningModal/ContractWarningModal';

const getSignedDate = (contractDetails: ContractDetails) => formatDate(_.max([contractDetails.startDate, contractDetails.endDate]) || moment(), 'YYYY-MM-DD');
const getContractCreationDto = (propertyId: number, unitId: number, contractDetails: ContractDetails, contacts: SelectedContact[], contractType: ContractCreationDtoTypeEnum, isVacant: boolean) => ({
  propertyId,
  unitId,
  contractNumber: contractDetails.contractNumber,
  mailingContactId: contractDetails.mailingContactId,
  bankAccountId: contractDetails.bankAccountId,
  startDate: contractDetails.startDate ? formatDate(contractDetails.startDate, 'YYYY-MM-DD') : undefined,
  endDate: contractDetails.endDate ? formatDate(contractDetails.endDate, 'YYYY-MM-DD') : undefined,
  signedDate: getSignedDate(contractDetails),
  contacts: contacts?.filter(c => c.contactId !== undefined).map(c => ({
    contactId: c.contactId,
    role: c.contactType as unknown as ContractContactCreationDtoRoleEnum[],
  }) as ContractContactCreationDto),
  type: contractType,
  isVacant,
  additionalData: contractDetails.additionalData ? JSON.stringify(contractDetails.additionalData) : undefined,
  vatRelevance: contractDetails.vatRelevance as unknown as ContractCreationDtoVatRelevanceEnum,
  dunningLevel: contractDetails.dunningLevel === NO_DUNNING_OPTION_VALUE
    ? null
    : contractDetails.dunningLevel as unknown as ContractCreationDtoDunningLevelEnum,
  contractGroupId: contractDetails.contractGroupId,
});

export const getContractUpdateDto = (propertyId: number, unitId: number, contractDetails: ContractDetails, contacts: SelectedContact[], contractType: ContractCreationDtoTypeEnum, isVacant: boolean) => ({
  ...getContractCreationDto(propertyId, unitId, contractDetails, contacts, contractType, isVacant),
  type: contractType as unknown as ContractUpdateDtoTypeEnum,
  unitContractId: contractDetails.unitContractId!,
  vatRelevance: contractDetails.vatRelevance as unknown as ContractUpdateDtoVatRelevanceEnum,
  dunningLevel: contractDetails.dunningLevel === NO_DUNNING_OPTION_VALUE
    ? null
    : contractDetails.dunningLevel as unknown as ContractUpdateDtoDunningLevelEnum,
});

type getContractUpdateDtoFromProjectionType = (uc: ContractProjectionDto) => ContractUpdateDto;

export const getContractUpdateDtoFromProjection: getContractUpdateDtoFromProjectionType = unitContractProjection => ({
  propertyId: unitContractProjection.propertyId,
  unitId: unitContractProjection.unitId,
  unitContractId: unitContractProjection.unitContractId!,
  contractNumber: unitContractProjection.contractNumber,
  mailingContactId: unitContractProjection.mailingContact?.contactId,
  bankAccountId: unitContractProjection.bankAccountId,
  startDate: unitContractProjection.startDate,
  endDate: unitContractProjection.endDate,
  signedDate: unitContractProjection.signedDate,
  type: unitContractProjection.type as unknown as ContractUpdateDtoTypeEnum,
  isVacant: unitContractProjection.isVacant,
  contacts: unitContractProjection.contacts?.map(c => ({
    contactId: c.contactId,
    role: c.role as unknown as ContractContactCreationDtoRoleEnum[],
  }) as ContractContactCreationDto),
  vatRelevance: unitContractProjection.vatRelevance as unknown as ContractUpdateDtoVatRelevanceEnum,
  dunningLevel: unitContractProjection.dunningLevel as unknown as ContractUpdateDtoDunningLevelEnum,
  contractGroupId: unitContractProjection.contractGroupId,
  additionalData: unitContractProjection.additionalData ? JSON.stringify(unitContractProjection.additionalData) : undefined,
});

interface Props {
  propertyId: number;
  unitId: number;
  contractType: ContractCreationDtoTypeEnum;
  isVacant: boolean;
  propertyVatEligibilityType: PropertyLegacyDtoVatEligibilityTypeEnum | undefined,
}


export const useSubmitContractCoreInformation = ({
  propertyId, unitId, contractType, isVacant, propertyVatEligibilityType
}: Props) => {
  const contractDetailsContext = useContext(ContractDetailsContext);
  const dirtyContractContext = useContext(DirtyContractContext);
  const selectedContactsContext = useContext(SelectedContactsContext);
  const unitContractEditorUpdatersContext = useContext(UnitContractEditorUpdatersContext);
  const initialContractContext = useContext(InitialContractContext);

  const { invalidateCache: invalidateDirectDebitMandateListCache } = useInvalidateDirectDebitMandateListCache();
  const { invalidateCache: invalidateAllContractsListCache } = useInvalidateAllContractsListCache();

  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  if (
    contractDetailsContext === undefined
    || selectedContactsContext === undefined
    || unitContractEditorUpdatersContext === undefined
    || initialContractContext === undefined
    || dirtyContractContext === undefined
  ) {
    throw new Error('useSubmitUnitContact must be used within a UnitContractEditorContextProvider');
  }

  const { loading } = dirtyContractContext;
  const { selectedContacts } = selectedContactsContext;
  const { contractDetails } = contractDetailsContext;
  const {
    setSelectedContacts, setContractDetails, startLoading, stopLoading, setDirty, setValidationErrors, setHasPostings,
  } = unitContractEditorUpdatersContext;
  const { invalidateCache: invalidateActiveContractCache } = useInvalidateActiveContractsListCache();
  const {
    getWarningMessagesForContractSaveModal,
    getContractDateValidationErrors,
    getWarningMessageForConflictingContract,
    isContractVatRelevanceAffectingVatEligibilityShare,
  } = useContractCoreInformationValidation(contractType, isVacant,propertyVatEligibilityType);
  const { setInitialUnitContract } = initialContractContext;

  const { apiConfiguration } = useContext(AuthContext);
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const unitContractMandateUsageController = new UnitContractMandateUsageControllerApi(apiConfiguration('accounting'));

  const { getGroupForContract } = useContractGroupWithProperties();
  const { sevOwnerContract } = useSevContextData();

  const getPreviousContractWhichCouldBeClosed = async () => {
    // if it's a new contract and there is a startDate, check if there is one which could be closed
    if (contractDetails.unitContractId === undefined && contractDetails.startDate !== undefined) {
      // fetch contract on startDate - 1 day
      const atDate = _.cloneDeep(contractDetails.startDate);
      atDate.subtract(1, 'day');
      return contractControllerApi.getContractsUsingGET({
        propertyId,
        unitId: !Number.isNaN(unitId) ? unitId : undefined,
        validAtDate: formatDate(atDate, 'YYYY-MM-DD'),
        type: contractType as unknown as GetContractsUsingGETTypeEnum,
      });
    }
    return undefined;
  };


  const onSave = async ({
    navigationUrl,
    sectionIndex,
    onCreateCallback,
    onUpdateCallback,
  }: {
    navigationUrl?: string;
    sectionIndex?: number;
    onCreateCallback?: (createdContracts: ContractProjectionDto[]) => void;
    onUpdateCallback?: (updatedContracts: ContractProjectionDto[]) => void;
  }) => {
    const onProceed = () => {
      onSaveWithoutWarnings(
        navigationUrl,
        sectionIndex,
        onCreateCallback,
        onUpdateCallback,
      );
    };

    const contractDateValidationErrors = getContractDateValidationErrors();
    if (!isEmpty(contractDateValidationErrors)) {
      setValidationErrors(contractDateValidationErrors);
      return;
    }
    // if no longer invalid then clear error from state
    setValidationErrors({});

    const warningMessages = getWarningMessagesForContractSaveModal();
    const conflictWarning = getWarningMessageForConflictingContract();
    if (!isNil(warningMessages) || !isNil(conflictWarning)) {
      Modal.confirm({
        onOk: onProceed,
        title: tl(translations.warnings.subtitle),
        content: (
          <ContractWarningModalContent
            warningMessages={warningMessages}
            conflictWarning={conflictWarning}
          />
        ),
        okText: tl(translations.warnings.warningModalActions.save),
        cancelText: tl(translations.warnings.warningModalActions.cancel),
        okButtonProps: { className: 'Button' },
        cancelButtonProps: { className: 'Button' },
        width: 520,
      });
    } else if (isVacant && isContractVatRelevanceAffectingVatEligibilityShare()) {
      Modal.confirm({
        onOk: () => onProceed(),
        title: tl(translations.warnings.changeContractVatRelevanceModal.title),
        icon: <Icon component={ICONS.warning} />,
        content: (
          <div>
            <p>
              { tl(translations.warnings.changeContractVatRelevanceModal.contentLine1)}
            </p>
            <p>
              { tl(translations.warnings.changeContractVatRelevanceModal.contentLine2)}
            </p>
          </div>),
        okText: tl(translations.warnings.changeContractVatRelevanceModal.okText),
        cancelText: tl(translations.warnings.changeContractVatRelevanceModal.cancelText),
        okButtonProps: { className: 'Button', danger: true },
        cancelButtonProps: { className: 'Button' },
        width: 520,
        className: 'ContractVatRelevanceChangeWarningModal',
        closable: true,
      });

    } else {
      onProceed();
    }
  };


  const onUpdatePreviousContractEndDate = (previousContract: ContractProjectionDto) => {
    const contractUpdateDto = getContractUpdateDtoFromProjection(previousContract!);
    const newEndDate = _.cloneDeep(contractDetails.startDate);
    newEndDate!.subtract(1, 'day');
    contractUpdateDto.endDate = formatDate(newEndDate!, 'YYYY-MM-DD');
    onUpdate(contractUpdateDto);
  };

  const onSaveWithoutWarnings = (navigationUrl?: string, sectionIndex?: number, onCreateCallback?: (createdContracts: ContractProjectionDto[]) => void,
    onUpdateCallback?: (updatedContracts: ContractProjectionDto[]) => void) => {
    startLoading();
    getPreviousContractWhichCouldBeClosed()
      .then((previousUnitContracts) => {
        if (_.size(previousUnitContracts?.filter((uc: ContractProjectionDto) => !uc.endDate && uc.unitContractId)) === 1) {
          const newEndDate = _.cloneDeep(contractDetails.startDate)?.subtract(1, 'day');
          Modal.confirm({
            onOk: () => {
              onUpdatePreviousContractEndDate(previousUnitContracts![0]);
              onSubmit({
                navigationUrl, sectionIndex, onCreateCallback, onUpdateCallback,
              });
            },
            onCancel: () => onSubmit({
              navigationUrl, sectionIndex, onCreateCallback, onUpdateCallback,
            }),
            title: tl(translations.closePreviousModal.title),
            content: tl(translations.closePreviousModal.content)(previousUnitContracts![0]?.mailingContact?.name ?? '', newEndDate ? formatDate(newEndDate, 'YYYY-MM-DD') : ''),
            okText: tl(translations.closePreviousModal.yes),
            cancelText: tl(translations.closePreviousModal.no),
            okButtonProps: { className: 'Button' },
            cancelButtonProps: { className: 'Button' },
            width: 520,
          });
        } else {
          onSubmit({
            navigationUrl, sectionIndex, onCreateCallback, onUpdateCallback,
          });
        }
      });
  };

  const updateContractInActiveList = () => {
    invalidateActiveContractCache();
    invalidateAllContractsListCache();
  };

  const createContractSubmittedInEditor = (contractCreationDto: ContractCreationDto, onCreateCallback?: (createdContracts: ContractProjectionDto[]) => void, navigationUrl?: string, sectionIndex?: number) => contractControllerApi.createContractsUsingPOST({ contractCreationDtos: [contractCreationDto] })
    .then((resp) => {
      showNotification({
        type: 'success',
        message: tl(translations.notifications.saveSuccess),
      });
      setDirty(false);
      if (navigationUrl !== undefined) {
        history.push(navigationUrl, { openSectionNumber: sectionIndex });
      }
      onUpdateMandates(resp[0].unitContractId!)
        .then(() => {
          if ([ContractCreationDtoTypeEnum.OWNER, ContractCreationDtoTypeEnum.TENANT].includes(contractCreationDto.type)) {
            updateContractInActiveList();
          }

          onCreateCallback?.(resp);
        });
    })
    .catch((e) => {
      console.error(e);
      e.response.json()
        .then((errorBody) => {
          if (errorBody.detail === 'Missing address') {
            showNotification({
              type: 'error',
              message: tl(translations.notifications.MISSING_ADDRESS),
            });
          } else {
            showNotification({
              type: 'error',
              message: tl(translations.notifications.saveError),
            });
          }
        });
    });

  const onCreate = (contractCreationDtoSubmittedInEditor: ContractCreationDto, onCreateCallback?: (createdContracts: ContractProjectionDto[]) => void, navigationUrl?: string, sectionIndex?: number) => {
    startLoading();

    if (!sevOwnerContract) {
      return createContractSubmittedInEditor(contractCreationDtoSubmittedInEditor,
        onCreateCallback,
        navigationUrl,
        sectionIndex)
        .finally(() => {
          stopLoading();
        });
    }

    const wegContractCreateDto = {
      ...contractCreationDtoSubmittedInEditor,
      propertyId: sevOwnerContract.propertyId,
      unitId: sevOwnerContract.unitId,
    };

    return contractControllerApi.createContractsUsingPOST({ contractCreationDtos: [wegContractCreateDto] })
      .then(wegContract => createContractSubmittedInEditor(
        { ...contractCreationDtoSubmittedInEditor, contractGroupId: wegContract[0].unitContractId },
        onCreateCallback,
        navigationUrl,
        sectionIndex,
      ).then(() => wegContract[0]))
      .then((wegContract) => {
        const wegContractUpdateDto = { ...getContractUpdateDtoFromProjection(wegContract), contractGroupId: wegContract.unitContractId };
        return contractControllerApi.updateContractsUsingPUT({ contractUpdateDtos: [wegContractUpdateDto] });
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onUpdate = (contractUpdateDtoSubmittedInEditor: ContractUpdateDto,
    onUpdateCallback?: (updatedContracts: ContractProjectionDto[]) => void,
    navigationUrl?: string,
    sectionIndex?: number) => getGroupForContract(contractUpdateDtoSubmittedInEditor.unitContractId).then((contractGroup) => {
    const contractGroupUpdateDtos = contractGroup.map(contract => ({
      ...contractUpdateDtoSubmittedInEditor,
      unitContractId: contract.unitContractId,
      propertyId: contract.propertyId,
      unitId: contract.unitId,
    }));

    return contractControllerApi.updateContractsUsingPUT({ contractUpdateDtos: contractGroupUpdateDtos })
      .then((resp) => {
        showNotification({
          type: 'success',
          message: tl(translations.notifications.saveSuccess),
        });
        if (navigationUrl !== undefined) {
          setDirty(false); // setting only if there is a navigation, because this method is also used for updating previous contract
          history.push(navigationUrl, { openSectionNumber: sectionIndex });
        }
        onUpdateCallback?.([...resp.filter(c => c.unitContractId === contractUpdateDtoSubmittedInEditor.unitContractId)]);
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.saveError),
        });
      });
  });
  const onUpdateMandates = (unitContractId: number) => {
    // unitContractId is a parameter for this to work in both create and update cases
    const actuallyChangedMandateUsages = contractDetails.directDebitMandateUsages.filter(ddmu => ddmu.currentState !== ddmu.originalState).map(
      ddmu => ({
        directDebitMandateId: ddmu.directDebitMandateId,
        unitContractId,
        state: ddmu.currentState as unknown as UnitContractMandateUsageCreationDtoStateEnum | undefined,
      }),
    );

    if (!_.isEmpty(actuallyChangedMandateUsages)) {
      return unitContractMandateUsageController
        .saveUsingPOST({ unitContractMandateCreationDtos: actuallyChangedMandateUsages })
        .then(() => {
          invalidateDirectDebitMandateListCache();
          setContractDetails(prev => ({ ...prev, directDebitMandateUsages: [] }));
        })
        .catch((err) => {
          console.error(err);
          showNotification({
            type: 'error',
            message: tl(translations.notifications.mandateSaveError),
          });
          throw err;
        });
    }

    return Promise.resolve();
  };


  const onSubmit = ({
    navigationUrl,
    sectionIndex,
    onCreateCallback,
    onUpdateCallback,
  }: {
    navigationUrl?: string;
    sectionIndex?: number;
    onCreateCallback?: (createdContracts: ContractProjectionDto[]) => void;
    onUpdateCallback?: (updatedContracts: ContractProjectionDto[]) => void;
  }) => {
    if (contractDetails.unitContractId === undefined) {
      const contractCreationDto = getContractCreationDto(propertyId, unitId, contractDetails, selectedContacts, contractType, isVacant);
      onCreate(contractCreationDto, onCreateCallback, navigationUrl, sectionIndex);
      // updating mandate happens after the contract is created because we need the unitContractId
    } else {
      const contractUpdateDto = getContractUpdateDto(propertyId, unitId, contractDetails, selectedContacts, contractType, isVacant);
      startLoading();
      // since the contract already exists we can initiate the update in parallel because we have a unitContractId
      Promise.allSettled([
        onUpdate(contractUpdateDto, onUpdateCallback, navigationUrl, sectionIndex),
        onUpdateMandates(contractUpdateDto.unitContractId),
      ])
        .then(([update, updateMandate]) => {
          if ([ContractUpdateDtoTypeEnum.OWNER, ContractUpdateDtoTypeEnum.TENANT].includes(contractUpdateDto.type)) {
            updateContractInActiveList();
          }

          if (update.status === 'fulfilled' && updateMandate.status === 'fulfilled') {
          // reset context
            resetContextValuesForNextContract(navigationUrl);
          }
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const resetContextValuesForNextContract = (navigationUrl?: string) => {
    if (navigationUrl?.includes('contract')) {
      setSelectedContacts([]);
      setContractDetails(DEFAULT_CONTRACT_DETAILS_STATE);
      setHasPostings(undefined);
      setInitialUnitContract({});
    }
  };

  return {
    loading,
    onSubmit,
    onSave,
    onSaveWithoutWarnings,
    onUpdate,
    resetContextValuesForNextContract,
  };
};
