import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { ORDER_OPEN_BALANCES_LIST_PATH, ORDER_OPEN_BALANCES_NEW_PATH } from './routes';
import { orderOpenBalancesTranslations } from './translations';

export const useDunningTabLinks = () => {
  const { tl } = useContext(LanguageContext);
  return [
    {
      link: ORDER_OPEN_BALANCES_NEW_PATH,
      label: tl(orderOpenBalancesTranslations.tabLinks.new),
    },
    {
      link: ORDER_OPEN_BALANCES_LIST_PATH,
      label: tl(orderOpenBalancesTranslations.tabLinks.list),
    },
  ];
};
