import React, { useContext } from 'react';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { FORMAT } from '../../../elements/SmartTable/SmartTable';
import InvitationStatusCell from './InvitationStatusCell';
import { ownersMeetingInvitationTranslations } from '../OwnersMeetingInvitationEditing/translations/OwnersMeetingInvitationTranslations';

export const useOwnersMeetingInvitationListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.status),
      dataKey: 'status',
      sortable: true,
      defaultWidth: '10%',
      render: (_, record) => <InvitationStatusCell record={record} />,
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.etvId),
      dataKey: 'id',
      defaultVisible: false,
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.propertyId),
      dataKey: 'propertyId',
      defaultVisible: false,
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.property),
      dataKey: 'propertyName',
      defaultWidth: '30%',
      sortable: true,
      render: (_, record) => (
        <CellWithTwoLines firstElement={record.propertyName} secondElement={record.propertyIdInternal} />),
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.date),
      dataKey: 'date',
      defaultWidth: '10%',
      format: FORMAT.DATE,
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.propertyManager),
      dataKey: 'propertySupervisorName',
      sortable: false,
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.propertyAccountant),
      dataKey: 'propertyAccountant',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.hga),
      dataKey: 'houseMoneySettlement',
      sortable: true,
      defaultWidth: '15%',
      render: (_, record) => (
        <CellWithTwoLines firstElement={record.houseMoneySettlementEconomicYear} secondElement={record.houseMoneySettlementStatus} />),
    },
    {
      title: tl(ownersMeetingInvitationTranslations.table.headers.economicPlan),
      dataKey: 'economicPlan',
      defaultWidth: '15%',
      sortable: true,
      render: (_, record) => (
        <CellWithTwoLines firstElement={record.economicPlanTitle} secondElement={record.economicPlanStatus} />),
    },
  ];
};
