import { Link } from 'react-router-dom';
import { useContactLink } from './useContactLink';

const ContactLink = ({ contactId } : {contactId: number}) => {
  const { name, url } = useContactLink(contactId);
  return (
    <Link to={url} target="_blank">{name}</Link>
  );
};

export default ContactLink;
