import {
  InvoiceBookingDto,
  InvoiceLegacyControllerApi, PropertyDisplayDto, PropertyLegacyControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect } from 'react';
import querystring from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import _ from 'lodash';
import { round2dec } from 'lib/Utils';
import { useLoadInvoice } from './useLoadInvoice';
import { AfterSaveAction, useInvoiceEditorMiscDataContext } from './InvoiceEditorMiscDataContext';
import { useGetVatEligibilityForProperty } from './useGetVatEligibilityShareForProperty';

export const useInitializeInvoiceEditor = () => {
  const { tl } = useContext(LanguageContext);

  const location = useLocation<{openSection: number}>();
  const history = useHistory();
  const { invoiceHrId } = useParams<{ invoiceHrId: string }>();

  const { onLoadInvoice } = useLoadInvoice();

  const invoiceEditingContext = useContext(InvoiceEditingContext);

  const {
    setEditingBookings, clearInvoice, setApprovable, setProperty, setDirty, setVatEligibilityShare, invoiceBookings, changeInvoiceBookings,
  } = invoiceEditingContext;

  const {
    setDirtModalVisible, openSectionIndex, setOpenSectionIndex, dirtModalSection,
    setDirtModalSection, actionAfterSaveRef,
  } = useInvoiceEditorMiscDataContext('useInitializeInvoiceEditor');

  const { goBack } = useContext(OverlayContext);

  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const { getVatEligibilityShareForProperty } = useGetVatEligibilityForProperty();

  useEffect(() => {
    if (invoiceHrId) {
      onLoadInvoice(invoiceHrId)
        .then(async result => ({
          property: await loadPropertyByHrId(result?.propertyHrId),
          invoiceBookings: result.invoiceBookings,
        }))
        .then(loadVatEligibility);

      onValidateInvoice(invoiceHrId);

      const { triggerEdit } = querystring.parse(location.search, { parseBooleans: true });
      if (triggerEdit) {
        setEditingBookings(true);
      }
    }

    return () => {
      // only clear data if invoiceHrId is not undefined.
      // this is a workaround to not lose data when navigating from creation url to editing url
      if (invoiceHrId) {
        clearInvoice();
        setEditingBookings(false);
      }
    };
  }, []);

  useEffect(() => {
    if (invoiceEditingContext.saved && !invoiceEditingContext.loading) {
      setDirty(false);

      if (!invoiceHrId && invoiceEditingContext.data.invoice.invoiceHrId) {
        history.replace(`/invoice/edit/${invoiceEditingContext.data.invoice.invoiceHrId}`, { openSection: 1 });
      }

      if (_.isEmpty(invoiceEditingContext.validationErrors)) {
        switch (actionAfterSaveRef.current) {
          case AfterSaveAction.OPEN_NEXT_SECTION:
            if (dirtModalSection !== -1) {
              setOpenSectionIndex(dirtModalSection);
              setDirtModalSection(-1);
            } else {
              setOpenSectionIndex(openSectionIndex + 1);
            }
            break;
          case AfterSaveAction.CLOSE_SECTION:
            setOpenSectionIndex(-1);
            break;
          case AfterSaveAction.NAVIGATE_BACK:
            goBack();
            break;
          default:
            return;
        }
      }
    }
    setDirtModalVisible(false);
  }, [invoiceEditingContext.saved]);

  useEffect(() => {
    if (location.state?.openSection) {
      setOpenSectionIndex(location.state.openSection);
    }
  }, [location.state]);


  const onValidateInvoice = (hrId: string): void => {
    invoiceControllerApi.getValidationForInvoiceUsingGET({ invoiceHrId: hrId })
      .then(() => {
        setApprovable(true);
      })
      .catch((error) => {
        setApprovable(false);
        if (error.status === 409 && error.invoice === 'hasDuplicate') {
          showNotification({
            key: 'duplicateInvoice',
            message: `${tl(translations.notifications.invoiceEditingContext.duplicateInvoiceError.message)} ${error.duplicateInvoice}`,
            type: 'error',
          });
        }
      });
  };

  const loadPropertyByHrId = async (propertyHrId: string | null): Promise<PropertyDisplayDto> => {
    if (!propertyHrId) {
      return Promise.resolve(undefined);
    }
    setProperty(prev => prev.startLoading());
    return propertyControllerApi.getPropertyDisplayByHrIdUsingGET({ propertyHrId })
      .then((resp) => {
        setProperty(prev => prev.load(resp));
        return Promise.resolve(resp);
      }).catch((e) => {
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyListContext.loadError.message),
          description: tl(translations.notifications.propertyListContext.loadError.description),
          type: 'warning',
        });
        setProperty(prev => prev.failed());
        return Promise.reject(e);
      });
  };

  const loadVatEligibility = ({
    property, invoiceBookings: invoiceBookingsArg,
  }: {
    property: PropertyDisplayDto;
    invoiceBookings: InvoiceBookingDto[],
  }) => {
    getVatEligibilityShareForProperty(property)
      .then((share) => {
        setVatEligibilityShare(share);

        // set default if no invoice bookings saved yet
        if (share !== undefined && _.isEmpty(invoiceBookingsArg.filter(ib => ib.vatEligibilityPercentage !== undefined))) {
          changeInvoiceBookings({
            type: 'setDefault',
            value: invoiceBookingsArg.map(ib => (
              {
                ...ib,
                vatEligibilityPercentage: share,
                vatEligibilityAmount: share !== undefined ? round2dec(ib.vatAmount * share / 100) : undefined,
              })),
          });
        }
      });
    // no need for catch, method returns undefined in case of failure & we do not block process
  };
};
