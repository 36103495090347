import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { useBankTransactionListContext } from 'pages/BankTransactions/services/BankTransactionListContext';
import { useEffect, useState } from 'react';


const getInitiallyVisibleProperty = (
  propertyList: ExtendedBankTransaction['propertyList'],
  propertyIdInternal: string | undefined,
  propertyName: string | undefined,
) => {
  if (propertyList.length === 0) {
    return undefined;
  }

  if ((!propertyIdInternal && !propertyName) || propertyList.length === 1) {
    return propertyList[0];
  }

  return propertyList.find(property => (
    property.propertyIdInternal === propertyIdInternal
    || property.propertyName?.toLowerCase().includes(propertyName?.toLowerCase())
  )) || propertyList[0];
};

export const useBankTransactionListPropertyColumn = (propertyList: ExtendedBankTransaction['propertyList']) => {
  /**
   * This cannot be a calculated value because `filterState?.propertyIdInternal` changes instantly,
   * and it takes a while * for the bankTransactionListState (and with it the propertyList) to
   * update because it's a fetch. So if we were to make it a calculated value then the UI would
   * update instantly when the filter changes, even though the table is still loading - which is not nice ux.
   */
  const [initiallyVisibleProperty, setInitiallyVisibleProperty] = useState(
    () => getInitiallyVisibleProperty(propertyList, filterState?.propertyIdInternal, filterState?.name),
  );


  const {
    filterState,
    bankTransactionListState,
  } = useBankTransactionListContext('useTransactionListFilterAndSort');


  useEffect(() => {
    if (!bankTransactionListState.loading) {
      setInitiallyVisibleProperty(getInitiallyVisibleProperty(propertyList, filterState?.propertyIdInternal, filterState?.name));
    }
    /**
     * `filterState?.propertyIdInternal` and `propertyList` are intentionally omitted as dependencies
     * for basically the same reasons as above.
     * If `filterState?.propertyIdInternal` changes then that will eventually mean that
     * `bankTransactionListState.data` will change (and `propertyList` comes from
     * `bankTransactionListState.data`), so in order to update the UI at the right time
     * we only add `bankTransactionListState.data` as a dependency.
     */
  }, [bankTransactionListState.data]);


  return initiallyVisibleProperty;
};
