import React from 'react';
import './PageBreakWrapper.scss';

interface Props {
  children: React.ReactChild | React.ReactChildren
  breakBefore?: boolean,
  breakAfter?: boolean,
}

const PageBreakWrapper = ({ children, breakBefore, breakAfter }: Props) => {
  const className = `${
    breakBefore ? 'PageBreakBeforeWrapper' : ''
  } ${
    breakAfter ? 'PageBreakAfterWrapper' : ''
  }`;

  return (
    <div className={className}>{children}</div>
  );
};


PageBreakWrapper.defaultProps = {
  breakBefore: undefined,
  breakAfter: undefined,
};

export default PageBreakWrapper;
