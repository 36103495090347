import _ from 'lodash';
import { useSearchContext } from 'pages/MessageSendingPage/services/MessageSendingContext';
import { useState } from 'react';

const DEBOUNCE_WAIT_TIME = 200;

export const useDocumentSearchBar = () => {
  const [searchInputValue, setSearchInputValue] = useState('');

  const searchContext = useSearchContext('useDocumentSearchBar');
  const { setDocumentSearchString: setSearchString } = searchContext;

  const onSearch = _.debounce((input: string) => {
    setSearchString(input);
  }, DEBOUNCE_WAIT_TIME);

  const onChangeSearchValue = (val) => {
    setSearchInputValue(val);
    onSearch(val);
  };

  return {
    onChangeSearchValue,
    searchInputValue,
  };
};
