import { useContext } from 'react';
import { SelectInputValuesStateContext, ServiceCompanyAllocationUpdatersContext } from '../../../../services/ServiceCompanyAllocationContext';


const useAccountCell = (key: string) => {
  const selectInputValues = useContext(SelectInputValuesStateContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);

  if (updatersContext === undefined) {
    throw new Error('useAccountCell must be used within a ServiceCompanyAllocationContextProvider!');
  }

  const { setSelectInputValues } = updatersContext;

  const currentValue = selectInputValues?.find(alloc => alloc.key === key)?.accountCode;
  const propertyHrId = selectInputValues?.find(alloc => alloc.key === key)?.propertyHrId;

  const onChange = (value: string) => {
    setSelectInputValues(prev => prev.map(selectInput => (
      selectInput.key === key
        ? { ...selectInput, accountCode: [value] }
        : selectInput
    )));
  };

  return { currentValue, onChange, propertyHrId };
};

export default useAccountCell;
