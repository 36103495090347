export enum Permissions {
  PERM_ACCOUNT_READ = 'PERM_ACCOUNT_READ',
  PERM_ACCOUNT_MANAGE = 'PERM_ACCOUNT_MANAGE',
  PERM_CONN_READ = 'PERM_CONN_READ',
  PERM_CONN_MANAGE = 'PERM_CONN_MANAGE',
  PERM_CONN_UPDATE = 'PERM_CONN_UPDATE',
  PERM_DOCUMENT_DOWNLOAD = 'PERM_DOCUMENT_DOWNLOAD',
  PERM_DOCUMENT_MANAGE = 'PERM_DOCUMENT_MANAGE',
  PERM_USER_READ = 'PERM_USER_READ',
  PERM_USER_MANAGE = 'PERM_USER_MANAGE',
}

export const getUserPermissions = () => JSON.parse(atob(JSON.parse(localStorage.auth).token.split('.')[1])).permissions.split(',');

export const checkUserHasPermission = (permission: Permissions) => getUserPermissions().indexOf(permission) !== -1;
