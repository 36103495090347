import React from 'react';
import { Table } from 'antd';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { CellWithTwoLinesComponentProps } from '../CellWithTwoLines/interfaces';
import './TableSummaryCellWithTwoLines.scss';

interface TableSummaryCellWithTwoLinesProps extends CellWithTwoLinesComponentProps {
  index: number,
  colSpan?: number,
  // in case the summary is used in a column with input fields this will increase the right-padding to match the padding of the input fields
  offsetContentForColumnWithInputs?: boolean,
}

const TableSummaryCellWithTwoLines = ({
  index,
  colSpan,
  offsetContentForColumnWithInputs,
  ...restProps
}: TableSummaryCellWithTwoLinesProps) => {
  const className = `TableSummaryCellWithTwoLines column-padding-medium ${offsetContentForColumnWithInputs
    ? 'offset-content-for-column-with-inputs'
    : ''
  }`;

  return (
    <Table.Summary.Cell
      className={className}
      index={index}
      colSpan={colSpan}
    >
      <CellWithTwoLines {...restProps} />
    </Table.Summary.Cell>
  );
};

TableSummaryCellWithTwoLines.defaultProps = {
  colSpan: 1,
  offsetContentForColumnWithInputs: false,
};

export default TableSummaryCellWithTwoLines;
