import { useMemo } from 'react';
import { BreadcrumbInterface } from '../../components/Breadcrumbs/Breadcrumbs';
import { Filter, Page } from '../GlobalSearchContext';
import { useSearchBarFilters } from '../../../../../../services/SearchBarHooks/useSearchBarFilters';


export const useFilters = (pages: Page[], breadcrumbs: BreadcrumbInterface[]) => {
  const availableFilters = useMemo(() => pages
    .filter(page => page.filterProps && page.filterProps.availableFilters)
    .flatMap(page => (
      page.filterProps!.availableFilters
        .filter((filter: Filter) => filter.visibleInSearchDropdown !== false)
        .map((filter: Filter) => ({ ...filter, pageKey: page.key, matchingValue: '' })))),
  [pages]);

  const {
    suggestedFilters,
    setSuggestedFilters,
    updateSuggestedFilters,
    currentlyEditedFilter,
    setCurrentlyEditedFilter,
  } = useSearchBarFilters(breadcrumbs, availableFilters);

  return {
    suggestedFilters,
    setSuggestedFilters,
    updateSuggestedFilters,
    currentlyEditedFilter,
    setCurrentlyEditedFilter,
  };
};
