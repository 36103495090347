import { articleUrls } from "storybook-components/feedback/AlertArticleLink/ArticleUrls";

export const translations = {
  personList: {
    filters: {
      street: {
        en: 'Street',
        de: 'Straße',
      },
      number: {
        en: 'Number',
        de: 'Nummer',
      },
      city: {
        en: 'City',
        de: 'Stadt',
      },
      postalCode: {
        en: 'Postal code',
        de: 'Postleitzahl',
      },
      name: {
        en: 'Name',
        de: 'Name',
      },
      email: {
        en: 'Email',
        de: 'Email',
      },
      phoneMobile: {
        en: 'Phone mobile',
        de: 'Mobiltelefon',
      },
      phoneBusiness: {
        en: 'Phone office',
        de: 'Telefon Büro',
      },
      phonePrivate: {
        en: 'Phone private',
        de: 'Telefon privat',
      },
      iban: {
        en: 'Iban',
        de: 'Iban',
      },
      vatId: {
        en: 'VAT-ID',
        de: 'USt-IdNr',
      },
      tradeRegisterNumber: {
        en: 'Trade register number',
        de: 'Registrierungsnummer',
      },
    },
  },
  deleteModal: {
    title: {
      en: 'Delete contact',
      de: 'Kontakt löschen',
    },
    line1: {
      en: (name: string) => `Before proceeding, please note that deleting ${name} is irreversible.`,
      de: (name: string) => `Bevor Sie fortfahren beachten Sie bitte, dass das Löschen von ${name} unwiderruflich ist.`,
    },
    line2: {
      en: (
        <p>
          {'Due to existing links in accounting, it is currently not possible to delete the contact. We kindly ask you to unlink the data in the sections of the contact listed below on your own. To make the process easier, you can refer to the '}
          <a href={articleUrls.contacts.delete} target="blank">support article</a>
          {' .'}
        </p>
      ),
      de: (
        <p>
          {'Aufgrund bestehender Verknüpfungen in der Buchhaltung ist es momentan nicht möglich, den Kontakt zu löschen. Wir bitten Sie, die unten aufgeführten Verknüpfungen in der jeweiligen Sektion des Kontakts selbst aufzuheben. Um Sie dabei bestmöglich zu unterstützen, haben wir  einen '}
          <a href={articleUrls.contacts.delete} target="blank">Support Artikel</a>
          {' für Sie erstellt.'}
        </p>
      ),
    },
    line3: {
      en: 'Upon deletion, the following associated data will automatically be deleted or unlinked:',
      de: 'Mit dem Löschen des Kontakts werden folgende verknüpfte Daten automatisch gelöscht oder die Verknüpfung aufgehoben:',
    },
    userActionItems: {
      contracts: {
        en: (
          <>
            Contracts in
            <b> Properties </b>
            section
          </>
        ),
        de: (
          <>
            Verträge in
            <b> Objekte </b>
            Sektion
          </>
        ),
      },
      invoices: {
        en: (
          <>
            Invoices in
            <b> Invoices </b>
            section
          </>
        ),
        de: (
          <>
            Rechnungen in
            <b> Rechnungen </b>
            Sektion
          </>
        ),
      },
    },
    deletedAndUnlinkedItems: {
      address: {
        en: 'Address',
        de: 'Addresse',
      },
      bankAccount: {
        en: 'Bank account',
        de: 'Bankkonto',
      },
      creditorAccount: {
        en: 'Creditor 442 accounts and its postings',
        de: 'Kreditorenkonto 442 und deren Buchungen',
      },
      messages: {
        en: 'Messages',
        de: 'Nachrichten',
      },
      propertyRoles: {
        en: 'Property manager and accountant',
        de: 'Objektbetreuer und Objektbuchhalter',
      },
      connectedContacts: {
        en: 'Connected contacts',
        de: 'Verknüpfte Kontakte',
      },
      documents: {
        en: 'Documents',
        de: 'Dokumente',
      },
      serviceProviders: {
        en: 'Service provider from properties',
        de: 'Dienstleister in Objekten',
      },
    },
    line4: {
      en: 'Manually created bank orders will not be deleted. You can find those by searching for the bank account holder as recipient / sender and the IBAN in the Direct Debit or Payments overview and mark them as deleted.',
      de: 'Manuell erstellte Bankaufträge werden nicht gelöscht. Sie können unter Lastschriften und Zahlungsaufträge nach dem Kontoinhaber als Empfänger bzw. Schuldner sowie der IBAN suchen und die Bankaufträge als gelöscht markieren.',
    },
    ok: {
      en: 'Delete contact',
      de: 'Kontakt löschen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    successNotification: {
      en: 'Contact successfully deleted.',
      de: 'Kontakt erfolgreich gelöscht.',
    },
    errorNotification: {
      en: 'Contact deletion failed.',
      de: 'Kontakt konnte nicht gelöscht werden.',
    },
  },
};
