import { BankTransactionControllerApi, BankTransactionProjectionDtoTransactionPaymentStatusEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { DefaultDataInterface } from 'lib/data';
import { showNotification } from 'lib/Notification';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useHgaValidation } from '../BankTransactionAllocation/services/useHgaValidation';
import { BankTransactionListLocationStateType, ExtendedAllocationGroup, ExtendedBankTransaction } from '../interfaces';
import { translations } from '../translations';
import { useBankTransactionListContext, useBankTransactionListDrawerContext } from './BankTransactionListContext';
import { useSelectedTransactions } from './useSelectedTransactions';

export const useTransactionListActions = () => {
  const {
    bankTransactionListState,
    setBankTransactionListState,
  } = useBankTransactionListContext('useTransactionListActions');

  const {
    setAllocationVisible,
  } = useBankTransactionListDrawerContext('useTransactionList');

  const {
    onChangeSelectedRowKeys,
  } = useSelectedTransactions();

  const { tl } = useContext(LanguageContext);
  const location = useLocation<BankTransactionListLocationStateType>();
  const history = useHistory();

  const { apiConfiguration } = useContext(AuthContext);
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));


  const { checkIfHgaIsClosed } = useHgaValidation();


  const updateListStateAfterAction = (allocationGroupId: number, transactionIds?: number[], onSuccess?: Function, successMessage?: string) => {
    setBankTransactionListState((oldTransactionList: DefaultDataInterface<ExtendedAllocationGroup[]>) => {
      const newTransactionList = oldTransactionList.data ? oldTransactionList.data.slice() : [];
      const transactionIdsAsNumbers = transactionIds?.map(txId => (typeof txId === 'number' ? txId : parseInt(txId, 10))) ?? [];

      removeTransactionFromList(newTransactionList, allocationGroupId, transactionIdsAsNumbers);
      return oldTransactionList.load(newTransactionList);
    });
    if (successMessage) {
      showNotification({
        key: 'bankTransactionWontBeAllocatedSuccess',
        message: successMessage,
        type: 'success',
      });
    }
    if (onSuccess) {
      onSuccess();
    }
  };


  const removeTransactionFromList = (transactionList: ExtendedAllocationGroup[], allocationGroupId: number, idsToRemove: number | number[]) => {
    const groupIdx = transactionList.findIndex(tx => tx.allocationGroupId === allocationGroupId);
    const idsToRemoveArray = typeof idsToRemove === 'number' ? [idsToRemove] : idsToRemove;

    if (groupIdx === -1) {
      console.error(`Could not find transaction group with id ${allocationGroupId}`);
      return;
    }

    if (_.isEmpty(transactionList[groupIdx].children) || transactionList[groupIdx].children!.length === 1) {
      // remove only if there is only one in group
      transactionList.splice(groupIdx, 1);
      return;
    }

    const filteredChildren = transactionList[groupIdx].children?.filter(({ bankTransactionId }) => !idsToRemoveArray.includes(bankTransactionId!)) ?? [];

    if (filteredChildren.length === 0) {
      // if all children need to be removed then remove group
      transactionList.splice(groupIdx, 1);
      return;
    }

    if (idsToRemoveArray.includes(transactionList[groupIdx].children?.[0].bankTransactionId!)) {
      // if first tx is to be removed then update group data
      transactionList[groupIdx] = _.cloneDeep(transactionList[groupIdx].children![0]);
      transactionList[groupIdx].rowKey = `${filteredChildren?.map(tx => tx.bankTransactionId)?.join('-')}`;
    }

    transactionList[groupIdx].children = filteredChildren;
  };

  const checkIfTransactactionIsAllocatedToPropertyWithClosedHga = (tx: ExtendedBankTransaction, onProceed: () => void, errorMessage: string) => {
    if ([BankTransactionProjectionDtoTransactionPaymentStatusEnum.ASSIGNED, BankTransactionProjectionDtoTransactionPaymentStatusEnum.PARTIALLY_BOOKED].includes(tx.transactionPaymentStatus)) {
      bankTransactionControllerApi.getAllocationAmountsUsingGET({ bankTransactionIds: [tx.bankTransactionId] })
        .then((resp) => {
          const propertyHrId = resp[tx.bankTransactionId]?.[0].propertyHrId;
          const propertyId = tx?.propertyList?.find(p => p.propertyHrId === propertyHrId)?.propertyId;
          const economicYear = tx?.bankBookingDate ? moment(tx?.bankBookingDate, 'YYYY-MM-DD').year() : undefined;
          checkIfHgaIsClosed(propertyId, propertyHrId, economicYear, onProceed, () => setBankTransactionListState(listState => listState.finishLoading()));
        })
        .catch(() => {
          showNotification({
            key: 'hgaCheckError',
            message: errorMessage,
            type: 'error',
          });
        });
    } else {
      onProceed();
    }
  };

  const markTransactionNotBookable = async (allocationGroupId: number, transactionIds?: number[], bankTransactionsForAction?: ExtendedBankTransaction[], onSuccess?: Function) => {
    if (_.isEmpty(transactionIds)) return;
    setBankTransactionListState(listState => listState.startLoading(true));

    const onProceed = async () => {
      try {
        await bankTransactionControllerApi.markTransactionsWontBeAllocatedUsingPOST({ bankTransactionIds: transactionIds! });
        updateListStateAfterAction(allocationGroupId, transactionIds, onSuccess, tl(translations.notifications.wontBeAllocatedSuccess.message));
      } catch (err) {
        setBankTransactionListState(listState => (listState.failed()));
        showNotification({
          key: 'bankTransactionNotBookableError',
          message: tl(translations.notifications.notBookableError.message),
          type: 'error',
        });
      }
    };

    checkIfTransactactionIsAllocatedToPropertyWithClosedHga(bankTransactionsForAction[0], onProceed, tl(translations.notifications.notBookableError.message));
  };

  const resetTransaction = async (allocationGroupId: number, transactionIds?: number[], bankTransactionsForAction?: ExtendedBankTransaction[], onSuccess?: Function) => {
    if (_.isEmpty(transactionIds)) return;
    setBankTransactionListState(listState => listState.startLoading(true));

    const onProceed = async () => {
      try {
        await bankTransactionControllerApi.markTransactionsUnassignedUsingPOST({ bankTransactionIds: transactionIds! });
        updateListStateAfterAction(allocationGroupId, transactionIds, onSuccess);
      } catch (err) {
        console.error(err);
        setBankTransactionListState(listState => (listState.failed()));
        showNotification({
          key: 'bankTransactionResetTransactionError',
          message: tl(translations.notifications.resetAllocationError.message),
          type: 'error',
        });
      }
    };

    checkIfTransactactionIsAllocatedToPropertyWithClosedHga(bankTransactionsForAction[0], onProceed, tl(translations.notifications.resetAllocationError.message));
  };

  const markTransactionsAsDuplicate = async (allocationGroupId: number, transactionIds?: number[], bankTransactionsForAction?: ExtendedBankTransaction[], onSuccess?: Function) => {
    if (_.isEmpty(transactionIds)) return;
    setBankTransactionListState(listState => listState.startLoading(true));

    const onProceed = async () => {
      try {
        await bankTransactionControllerApi.markTransactionsDuplicateUsingPOST({ bankTransactionIds: transactionIds! });
        updateListStateAfterAction(allocationGroupId, transactionIds, onSuccess);
      } catch (err) {
        console.error(err);
        setBankTransactionListState(listState => (listState.failed()));
        showNotification({
          key: 'bankTransactionMarkDuplicateError',
          message: tl(translations.notifications.markTransactionsDuplicateError.message),
          type: 'error',
        });
      }
    };

    checkIfTransactactionIsAllocatedToPropertyWithClosedHga(bankTransactionsForAction[0], onProceed, tl(translations.notifications.markTransactionsDuplicateError.message));
  };

  const onActionSuccess = (
    openNextTransaction: boolean,
    allocationGroupId: number,
    onSuccess?: () => void,
  ) => {
    const currentTxIndex = _.findIndex(bankTransactionListState.data, tx => tx.allocationGroupId === allocationGroupId);
    const { navigatedFromApp } = location.state || {};

    if (currentTxIndex === -1) return;

    if (!openNextTransaction || !bankTransactionListState.data?.[currentTxIndex + 1]) {
      history.replace('/bank-transactions', { navigatedFromApp });
      setAllocationVisible(false);
      onChangeSelectedRowKeys([]);
      return;
    }

    if (onSuccess) {
      onSuccess();
    }
    history.push(`/bank-transactions/${bankTransactionListState.data?.[currentTxIndex + 1].allocationGroupId}`, { navigatedFromApp, updateUrlOnCurrentOverlay: true });
  };

  const markTransactionWontBeAllocatedWithOnSuccess = (
    openNextTransaction: boolean,
    allocationGroupId: number,
    transactionIds?: number[] | undefined,
    bankTransactionsForAction?: ExtendedBankTransaction[] | undefined,
    onSuccess?: () => void,
  ) => markTransactionNotBookable(allocationGroupId, transactionIds, bankTransactionsForAction, () => onActionSuccess(openNextTransaction, allocationGroupId, onSuccess));

  const resetTransactionWithOnSuccess = (
    openNextTransaction: boolean,
    allocationGroupId: number,
    transactionIds?: number[] | undefined,
    bankTransactionsForAction?: ExtendedBankTransaction[] | undefined,
    onSuccess?: () => void,
  ) => resetTransaction(allocationGroupId, transactionIds, bankTransactionsForAction, () => onActionSuccess(openNextTransaction, allocationGroupId, onSuccess));

  const markTransactionAsDuplicateWithOnSuccess = (
    openNextTransaction: boolean,
    allocationGroupId: number,
    transactionIds?: number[] | undefined,
    bankTransactionsForAction?: ExtendedBankTransaction[] | undefined,
    onSuccess?: () => void,
  ) => markTransactionsAsDuplicate(allocationGroupId, transactionIds, bankTransactionsForAction, () => onActionSuccess(openNextTransaction, allocationGroupId, onSuccess));

  return {
    resetTransaction: resetTransactionWithOnSuccess,
    markTransactionNotBookable: markTransactionWontBeAllocatedWithOnSuccess,
    markTransactionsAsDuplicate: markTransactionAsDuplicateWithOnSuccess,
  };
};
