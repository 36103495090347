import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency, formatToEuro } from 'lib/Utils';
import { useContext, useMemo } from 'react';
import { InformationBarContent } from 'storybook-components/feedback/InformationBar/InformationBar';
import { translations } from '../../../../translations';


const getFormattedAmount = (value: number) => formatCurrency(Math.abs(value ?? 0));


export const useInvoiceBookingSummaryInfoBar = () => {
  const { tl } = useContext(LanguageContext);
  const invoiceEditingContext = useContext(InvoiceEditingContext);

  const { summaryInfoBar: summaryTranslations } = translations.invoiceEditing.sections.section3;

  if (invoiceEditingContext === undefined) {
    throw new Error('useInvoiceBookingSummaryInfoBar must be used within an InvoiceEditingProvider');
  }

  const { invoiceBookings } = invoiceEditingContext;

  const bookingSums = useMemo(() => (
    invoiceBookings.reduce((acc, curr) => ({
      netAmountSum: acc.netAmountSum + (curr.netAmount ?? 0),
      netAmountIncomingSum: acc.netAmountIncomingSum + ((curr.netAmount ?? 0) > 0 ? curr.netAmount : 0),
      netAmountOutgoingSum: acc.netAmountOutgoingSum + ((curr.netAmount ?? 0) < 0 ? curr.netAmount : 0),
      vatAmountIncomingSum: acc.vatAmountIncomingSum + ((curr.vatAmount ?? 0) > 0 ? curr.vatAmount : 0),
      vatAmountOutgoingSum: acc.vatAmountOutgoingSum + ((curr.vatAmount ?? 0) < 0 ? curr.vatAmount : 0),
      grossAmountSum: acc.grossAmountSum + (curr.amount ?? 0),
      grossAmountIncomingSum: acc.grossAmountIncomingSum + ((curr.amount ?? 0) > 0 ? curr.amount : 0),
      grossAmountOutgoingSum: acc.grossAmountOutgoingSum + ((curr.amount ?? 0) < 0 ? curr.amount : 0),
    }), {
      netAmountSum: 0,
      netAmountIncomingSum: 0,
      netAmountOutgoingSum: 0,
      vatAmountIncomingSum: 0,
      vatAmountOutgoingSum: 0,
      grossAmountSum: 0,
      grossAmountIncomingSum: 0,
      grossAmountOutgoingSum: 0,
    })
  ), [invoiceBookings]);

  const getGrossLabel = () => {
    if (bookingSums.grossAmountIncomingSum && bookingSums.grossAmountOutgoingSum) {
      return `${tl(summaryTranslations.totalGross)} (${tl(summaryTranslations.incoming)}: ${formatToEuro(bookingSums.grossAmountIncomingSum)} ‧ ${tl(summaryTranslations.outgoing)}: ${formatToEuro(Math.abs(bookingSums.grossAmountOutgoingSum))})`;
    }
    return tl(summaryTranslations.totalGross);
  };

  const getNetLabel = () => {
    if (bookingSums.netAmountIncomingSum && bookingSums.netAmountOutgoingSum) {
      return `${tl(summaryTranslations.totalNet)} (${tl(summaryTranslations.incoming)}: ${formatToEuro(bookingSums.netAmountIncomingSum)} ‧ ${tl(summaryTranslations.outgoing)}: ${formatToEuro(Math.abs(bookingSums.netAmountOutgoingSum))})`;
    }
    return tl(summaryTranslations.totalNet);
  };

  const getVatInfo = (): {label: string; value: number;}[] => {
    const vatInfo = [];
    if (bookingSums.vatAmountIncomingSum) {
      vatInfo.push({
        label: tl(bookingSums.vatAmountOutgoingSum ? summaryTranslations.vatIncoming : summaryTranslations.vat),
        value: getFormattedAmount(bookingSums.vatAmountIncomingSum),
      });
    }
    if (bookingSums.vatAmountOutgoingSum) {
      vatInfo.push({
        label: tl(bookingSums.vatAmountIncomingSum ? summaryTranslations.vatOutgoing : summaryTranslations.vat),
        value: getFormattedAmount(bookingSums.vatAmountOutgoingSum),
      });
    }
    return vatInfo;
  };

  const infoBarContent: InformationBarContent[] = [
    {
      label: getGrossLabel(),
      value: getFormattedAmount(bookingSums.grossAmountSum),
    },
    {
      label: getNetLabel(),
      value: getFormattedAmount(bookingSums.netAmountSum),
    },
    ...getVatInfo(),
  ];

  return {
    infoBarContent,
    isPdfView: !!invoiceEditingContext.data.invoice.documentUrl,
  };
};
