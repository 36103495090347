import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { ownersMeetingInvitationTranslations } from '../translations/OwnersMeetingInvitationTranslations';
import defaultSection from './DefaultSection';
import { OwnersMeetingInvitationContext } from '../../../../contexts/OwnersMeetingInvitationContext';
import { OwnersMeetingProtocolDtoStatusEnum } from '../../../../api/accounting';


interface AppointmentSectionProps {
  index: number,
  disabled: boolean,
}

export default function useAppointmentSection(props: AppointmentSectionProps) {
  const { index, disabled } = props;
  const { tl } = useContext(LanguageContext);
  const { ownersMeeting } = useContext(OwnersMeetingInvitationContext);
  const isSent = !ownersMeeting.data || [OwnersMeetingProtocolDtoStatusEnum.SENT, OwnersMeetingProtocolDtoStatusEnum.DONE].includes(ownersMeeting.data.status);

  return {
    ...defaultSection(index, index),
    sectionTitle: tl(ownersMeetingInvitationTranslations.editPage.sections.appointmentSection.title),
    sectionId: 'AppointmentSection',
    disabled,
    content: [
      {
        sectionId: 'locationAndDate',
        title: '',
        content: [
          [
            {
              type: 'text',
              key: 'location',
              props: {
                required: true,
                label: tl(ownersMeetingInvitationTranslations.editPage.sections.appointmentSection.fields.location),
                disabled: isSent,
              },
            },
            {
              type: 'text',
              key: 'room',
              props: {
                label: tl(ownersMeetingInvitationTranslations.editPage.sections.appointmentSection.fields.room),
                disabled: isSent,
              },
            },
          ],
          [
            {
              type: 'time',
              key: 'startTime',
              props: {
                required: true,
                label: tl(ownersMeetingInvitationTranslations.editPage.sections.appointmentSection.fields.startTime),
                disabled: isSent,
              },
            },
            {
              type: 'time',
              key: 'endTime',
              props: {
                label: tl(ownersMeetingInvitationTranslations.editPage.sections.appointmentSection.fields.endTime),
                disabled: isSent,
              },
            },
            {
              type: 'date',
              key: 'date',
              props: {
                required: true,
                label: tl(ownersMeetingInvitationTranslations.editPage.sections.appointmentSection.fields.date),
                disabled: isSent,
              },
            },
            // TODO remove empty object after grid layout fixed PMP-1850
            {},
          ],
        ],
      },
    ],
  };
}
