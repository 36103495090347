export const SPACE_SIZES = {
  tiny: 4,
  small: 8,
  /**
   * Even less space: ex Alerts
   */
  medium: 16,
  /**
   * Used where there is little space; ex: modals
   */
  large: 24,
  /**
   * Default spacing, given enough space
   */
  xLarge: 32,
};
