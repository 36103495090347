import React, { useContext, useState } from 'react';
import './SpecialContributionList.scss';
import { useHistory } from 'react-router';
import Button from 'elements/Buttons/Button/Button';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { LanguageContext } from 'contexts/LanguageContext';
import SmartTable from 'elements/SmartTable/SmartTable';
import { StepsComponent } from 'components/Steps/StepsComponent';
import { translations } from '../translations';
import useSpecialContributionList from './services/useSpecialContributionList';
import { useSpecialContributionListColumns } from './UseSpecialContributionListColumns';
import { SpecialContributionDto } from '../../../api/accounting';

export const SpecialContributionList : React.FC = () => {
  const history = useHistory();
  const { tl } = useContext(LanguageContext);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState<string>('ALL');
  const {
    specialContributionList, sortField, setSortingColumn, sortOrder, onLoadSpecialContributionList,
  } = useSpecialContributionList(selectedQuickFilter);

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'specialContribution',
    navItems: subcategorySwitcherItems.accounting,
  };

  const headerRightSide = (
    <Button
      type="primary"
      onClick={() => history.push('/special-contribution/create')}
    >
      {tl(translations.specialContribution.addButton)}
    </Button>
  );

  const smartTable = useSmartTable(
    {
      tableName: 'specialContributionList',
      columns: useSpecialContributionListColumns(),
      dataSource: specialContributionList.data || [],
      propSort: {
        field: sortField,
        order: sortOrder,
        onSortChange: (dataKey: string) => setSortingColumn(dataKey),
      },
      onRow: (record: SpecialContributionDto) => (
        { onClick: () => history.push(`/special-contribution/edit/${record.id}`, { openSectionIndex: 1 }) }),
      contentLoading: specialContributionList.loading,
      infiniteScrollerProps: {
        hasMoreData: !specialContributionList.loading && !specialContributionList.lastPage,
        loadMoreData: () => onLoadSpecialContributionList,
      },
      rowKey: 'id',
    },
  );

  return (
    <div className="SpecialContributionList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={headerRightSide}
      />
      <StepsComponent
        className="SpecialContributionListSteps"
        optionsList={[
          {
            label: tl(translations.specialContribution.filterLabels.ALL),
            value: 'ALL',
          },
          {
            label: tl(translations.specialContribution.filterLabels.OPEN),
            value: 'OPEN',
          },
          {
            label: tl(translations.specialContribution.filterLabels.DONE),
            value: 'DONE',
          },
        ]}
        onChangeStep={(value: string) => setSelectedQuickFilter(value)}
        selectedStep={selectedQuickFilter}
      />
      <SmartTable {...smartTable} />
    </div>
  );
};
