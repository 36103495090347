import { showNotification } from 'lib/Notification';
import { useContext } from 'react';
import { SpecialContributionControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from '../../translations';
import { SpecialContributionCreationContext } from './SpecialContributionCreationContext';

export const useDeleteSpecialContribution = () => {
  const specialContributionContext = useContext(SpecialContributionCreationContext);
  if (specialContributionContext === undefined) {
    throw Error('useDeleteSpecialContribution must be used withing SpecialContributionCreationContextProvider');
  }
  const { setLoading } = specialContributionContext;
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const specialContributionControllerApi = new SpecialContributionControllerApi(apiConfiguration('accounting'));


  const deleteSpecialContribution = (specialContributionId: number) => {
    setLoading(true);
    return specialContributionControllerApi.deleteSpecialContributionUsingDELETE({
      id: specialContributionId,
    })
      .then(() => {
        showNotification({
          key: 'deleteSpecialContributionSuccess',
          message: tl(translations.specialContribution.deleteSpecialContributionSuccess),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'deleteSpecialContributionSuccess',
          message: tl(translations.specialContribution.deleteSpecialContributionError),
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    deleteSpecialContribution,
  };
};
