import React, { useContext } from 'react';
import './MismatchInfo.scss';
import { Link } from 'react-router-dom';
import { Alert } from 'antd';
import moment from 'moment';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { EconomicPlanAccountDistributionAmountValidationDto } from '../../../../api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { formatCurrency } from '../../../../lib/Utils';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons/lib/components/Icon';

interface MismatchInfoProps {
  validations: EconomicPlanAccountDistributionAmountValidationDto[]
  propertyHrId: string
}

export default function MismatchInfo({ validations, propertyHrId }: MismatchInfoProps): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const validationLine = (validation: EconomicPlanAccountDistributionAmountValidationDto) => (
    <div className="validation" key={validation.accountCode}>
      <b>{validation.accountCode}</b>
      &nbsp;
      {tl(economicPlanTranslations.economicPlan.validations.assigned)}
      &nbsp;
      <b>{formatCurrency(validation.accountDistributionAmount)}</b>
      ;&nbsp;
      {tl(economicPlanTranslations.economicPlan.validations.distribution)}
      &nbsp;
      <b>{formatCurrency(validation.sumOfUnitDistributionAmounts)}</b>
      ;&nbsp;
      <b>
        <Link
          className="link"
          to={{ pathname: `/distribution-keys/${propertyHrId}/${moment().get('year')}/${encodeURIComponent(validation.accountCode)}` }}
          target="_blank"
        >
          {tl(economicPlanTranslations.economicPlan.validations.source)}
        </Link>
      </b>
    </div>
  );

  return (
    <Alert
      type="warning"
      className="MismatchInfo"
      showIcon
      icon={<Icon component={ICONS.warning} />}
      message={(
        <div className="alert-message">
          <div className="title">{tl(economicPlanTranslations.economicPlan.validations.title)}</div>
          {validations.map(validationLine)}
        </div>
      )}
      description={(
        <AlertArticleLink
          content={tl(economicPlanTranslations.economicPlan.validations.help)}
          url="https://impower.atlassian.net/servicedesk/customer/portal/1/article/2213969921?src=-183720683"
        />
      )}
    />
  );
}
