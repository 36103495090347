export const translations: any = {
  propertyHrId: {
    en: 'Property ID',
    de: 'Objekt ID',
  },
  internalNr: {
    en: 'Internal property ID',
    de: 'Objekt-Nr Intern',
  },
  name: {
    en: 'Property name',
    de: 'Objektbezeichnung',
  },
};
