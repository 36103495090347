import { TableRowSelection } from 'antd/lib/table/interface';
import { UnitContractProjectionDto } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatDate } from 'lib/Utils';
import { debounce, flatMap, isEmpty } from 'lodash';
import { useAllContractsList } from 'pages/Property/PropertyEditing/services/useAllContractsList';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { usePropertyOwnerContracts } from 'services/PropertyOwnerContractsList/usePropertyOwnerContracts';
import { useScoreStringSearch } from 'services/search/useScoreStringSearch';
import { useActiveContractsList } from 'services/UnitContractsList/useActiveContractsList';
import { useExpandableTableProps } from 'storybook-components/table/ExpandableTableProps/useExpandableTableProps';
import ContractContactCell from './components/ContractContactCell/ContractContactCell';
import { RecipientDatasourceItem } from './interfaces';
import { recipientSelectorModalTranslations } from './translations';

enum CONTRACT_FILTER_OPTIONS {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  ACTIVE_MAIN = 'ACTIVE_MAIN',
  SELECTED = 'SELECTED',
  ALL_SELECTED = 'ALL_SELECTED'
}

export interface SelectedContractContact extends Partial<UnitContractProjectionDto> {
  contractId: number,
  contactId: number,
}

export const useRecipientSelectorModal = ({
  propertyId, propertyHrId, selectedContractContactIds, contractContactIds, visible,
}: { propertyId: number, propertyHrId: string, selectedContractContactIds: SelectedContractContact[], contractContactIds: SelectedContractContact[], visible: boolean }) => {
  const { tl } = useContext(LanguageContext);
  const { allContractsList } = useAllContractsList(propertyId, !visible);
  const { activeContractsList } = useActiveContractsList({ propertyId, isComponentHidden: !visible });
  const { propertyOwnerContracts } = usePropertyOwnerContracts(propertyId, undefined, !visible);
  const { propertyOwnerContracts: activePropertyOwnerContracts } = usePropertyOwnerContracts(propertyId, formatDate(new Date(), 'YYYY-MM-DD'), !visible);

  const [contractFilter, setContractFilter] = useState<CONTRACT_FILTER_OPTIONS>(CONTRACT_FILTER_OPTIONS.ALL_SELECTED);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchString, setSearchString] = useState('');
  const [selectedValues, setSelectedValues] = useState<SelectedContractContact[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>();

  useEffect(() => {
    if (visible) {
      setSelectedValues(selectedContractContactIds);
      setSelectedRowKeys(selectedContractContactIds?.map(cc => `contact-${cc.contractId}-${cc.contactId}`));
    }
  }, [visible]);

  const onSearch = debounce((input: string) => {
    setSearchString(input);
  }, 300);

  const onChangeSearchValue = (val) => {
    setSearchInputValue(val);
    onSearch(val);
  };

  const onChangeContractFilter = (val: CONTRACT_FILTER_OPTIONS) => {
    setContractFilter(val);
  };

  const mapContractsToDatasource = (contracts: UnitContractProjectionDto[]) => contracts?.map(c => ({
    label: `${c.unitNrSharingDeclaration} · ${c.mailingContact.name}`,
    value: c.contacts?.length > 1 ? undefined : { contractId: c.unitContractId, contactId: c.mailingContact.contactId, ...c },
    roles: c.contacts?.length > 1 ? undefined : c.contacts[0].role.map(r => tl(recipientSelectorModalTranslations.role[r])).join(', '),
    rowKey: c.contacts?.length > 1 ? `contract-${c.unitContractId}` : `contact-${c.unitContractId}-${c.mailingContact.contactId}`,
    propertyId: c.propertyId,
    propertyHrId,
    unitId: c.unitId,
    contractId: c.unitContractId,
    searchValue: `${c.unitNrSharingDeclaration} ${c.mailingContact.name}`,
    indent: false,
    children: c.contacts?.length > 1 ? c.contacts?.map(cc => ({
      label: cc.name,
      value: { contractId: c.unitContractId, contactId: cc.contactId, ...c },
      roles: cc.role.map(r => tl(recipientSelectorModalTranslations.role[r])).join(', '),
      searchValue: `${c.unitNrSharingDeclaration} ${cc.name}`,
      rowKey: `contact-${c.unitContractId}-${cc.contactId}`,
      contractId: c.unitContractId,
      indent: true,
      propertyHrId,
    })) : undefined,
  }));

  const mapSelectedToDatasource = (contracts: UnitContractProjectionDto[], selected: SelectedContractContact[]) => {
    const selectedContractIds = selected?.map(cc => cc.contractId);
    return mapContractsToDatasource(contracts?.filter(c => selectedContractIds.includes(c.unitContractId)).map(c => ({
      ...c,
      contacts: c.contacts?.filter(cc => selected?.findIndex(s => s.contractId === c.unitContractId && s.contactId === cc.contactId) !== -1),
    })));
  };

  const getAllContracts = () => {
    const tempContracts = [];
    if (!isEmpty(propertyOwnerContracts)) {
      tempContracts.push(propertyOwnerContracts.map(pc => ({ ...pc, unitNrSharingDeclaration: tl(recipientSelectorModalTranslations.propertyOwner) })));
    }
    if (!isEmpty(allContractsList.data)) {
      tempContracts.push(allContractsList.data);
    }
    return flatMap(tempContracts).filter(c => !c.isVacant);
  };

  const getAllActiveContracts = () => {
    const tempContracts = [];
    if (!isEmpty(activePropertyOwnerContracts)) {
      tempContracts.push(activePropertyOwnerContracts.map(pc => ({ ...pc, unitNrSharingDeclaration: tl(recipientSelectorModalTranslations.propertyOwner) })));
    }
    if (!isEmpty(activeContractsList.data)) {
      tempContracts.push(activeContractsList.data);
    }
    return flatMap(tempContracts).filter(c => !c.isVacant);
  };

  const scoreStringSearch = useScoreStringSearch();

  const datasource = useMemo(() => {
    let tempDataSource = [];
    const allContracts = getAllContracts();
    const allActiveContracts = getAllActiveContracts();

    switch (contractFilter) {
      case CONTRACT_FILTER_OPTIONS.ACTIVE:
        tempDataSource = mapContractsToDatasource(allActiveContracts);
        break;
      case CONTRACT_FILTER_OPTIONS.ALL:
        tempDataSource = mapContractsToDatasource(allContracts);
        break;
      case CONTRACT_FILTER_OPTIONS.ACTIVE_MAIN:
        tempDataSource = allActiveContracts?.map(c => ({
          label: `${c.unitNrSharingDeclaration} · ${c.mailingContact.name}`,
          value: { contractId: c.unitContractId, contactId: c.mailingContact.contactId, ...c },
          propertyId: c.propertyId,
          propertyHrId,
          unitId: c.unitId,
          contractId: c.unitContractId,
          searchValue: `${c.unitNrSharingDeclaration} ${c.mailingContact.name}`,
          rowKey: `contact-${c.unitContractId}-${c.mailingContact.contactId}`,
          indent: false,
        }));
        break;
      case CONTRACT_FILTER_OPTIONS.SELECTED:
        tempDataSource = mapSelectedToDatasource(allContracts, selectedContractContactIds);
        break;
      case CONTRACT_FILTER_OPTIONS.ALL_SELECTED:
        tempDataSource = mapSelectedToDatasource(allContracts, contractContactIds);
        break;
      default:
        tempDataSource = [];
    }

    return scoreStringSearch(tempDataSource, searchString);
  }, [contractFilter, allContractsList.data, propertyOwnerContracts, activeContractsList.data, activePropertyOwnerContracts, selectedContractContactIds, contractContactIds, searchString]);

  const isExpandable = r => r.children;
  const indentRow = r => r.indent;

  const {
    expandedRowKeys, setExpandedRowKeys, onExpand, expandIcon, expandIconCell,
  } = useExpandableTableProps('rowKey', isExpandable, indentRow);

  useEffect(() => {
    const allRowKeys = datasource.flatMap((rc) => {
      const parentRowKey = rc && rc.rowKey ? [rc.rowKey] : [];
      return parentRowKey.concat(rc?.children?.map(c => c.rowKey) ?? []);
    });
    setExpandedRowKeys(allRowKeys);
  }, [datasource]);


  const contractFilterOptions = Object.values(CONTRACT_FILTER_OPTIONS).map(opt => ({
    label: tl(recipientSelectorModalTranslations.filterOptions[opt]),
    value: opt,
  }));

  const columns = [{
    dataIndex: 'label',
    title: tl(recipientSelectorModalTranslations.contractContacts),
    render: (_, record) => expandIconCell(record, expandedRowKeys.includes(record.rowKey), <ContractContactCell record={record} />),
  }];

  const onChangeSelectedValues = (vals) => {
    setSelectedValues(vals);
  };


  const rowSelection: TableRowSelection<RecipientDatasourceItem> = {
    onChange: (rowKeys, rows) => {
      setSelectedValues(rows.filter(sr => sr.value).map(sr => sr.value));
      setSelectedRowKeys(rowKeys as unknown as string[]);
    },
    selectedRowKeys,
    checkStrictly: false,
  };

  return {
    datasource,
    searchValue: searchInputValue,
    contractFilter,
    contractFilterOptions,
    columns,
    loading: allContractsList.loading || activeContractsList.loading,
    onChangeSearchValue,
    onChangeContractFilter,
    selectedValues,
    onChangeSelectedValues,
    rowSelection,
    expandedRowKeys,
    onExpand,
    expandIcon,
    expandIconCell,
  };
};
