import React from 'react';
import Icon from '@ant-design/icons';
import { ICONS } from '../../../components/icons';
import './InfoRow.scss';

interface InfoRowProps {
  label: string
}

export default function InfoRow(props: InfoRowProps) {
  const { label } = props;

  return (
    <div className="InfoRow">
      <Icon component={ICONS.info} />
      <div className="label">
        {label}
      </div>
    </div>
  );
}
