import useDefaultPropertyFilters from 'components/Header/components/data/useDefaultPropertyFilters';
import { useContext } from 'react';
import { Filter } from '../../../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../../elements/Translation/translations';

export default function useEconomicPlanPropertyListFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    ...useDefaultPropertyFilters(false),
    {
      type: 'text',
      name: tl(translations.pages.property.table.headers.address),
      key: 'address',
    },
  ];
}
