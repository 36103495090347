export const translations = {
  integrations: {
    notAvailable: {
      firstSentence: {
        en: 'You don\'t yet have an integration connected to Impower. ',
        de: 'Sie haben bisher noch keine Integration an Impower angeschlossen. ',
      },
      secondSentence: {
        en: 'Check out our Portal connections here.',
        de: 'Erfahren Sie hier mehr über unsere Portal Anbindungen.',
      },
    },
  },
};
