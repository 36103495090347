import React from 'react';
import './EmbeddedPage.scss';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import { useEmbeddedPage } from './useEmbeddedPage';

export const EmbeddedPage: React.FC = () => {
  const { subcategorySwitcherProps, title, embedPath } = useEmbeddedPage();

  return (
    <div className="EmbeddedPage page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
      />
      {embedPath
        ? (
          <iframe
            title={title}
            src={embedPath}
            frameBorder="0"
            width="100%"
            height="100%"
            allowTransparency
          />
        ) : <LoadingScreen />}
    </div>
  );
};
