import React, { useState } from 'react';
import PropTypes from 'prop-types';

export interface FormEditingContextType {
  startLoading: () => void,
  stopLoading: () => void,
  loading: boolean
}

export const FormEditingContext = React.createContext<FormEditingContextType>({
  startLoading: () => {
  },
  stopLoading: () => {
  },
  loading: false,
});

/**
 * <pre>
 * This context should be used by all forms that need it to propagate state from the (sub)sections to the parent form.
 *
 * In simple forms, this context is ***NOT*** needed,
 * since we have one single state that is updated by all sections and kept in the parent form.
 *
 * However, in some forms (eg. PropertyEditing) the sections have their own state and should not update the parent state,
 * since it will trigger a rerender of the entire form, which is computationally expensive.
 * Re-renders of the entire form may also cause an infinite loops in some forms.
 *
 * This context is to be used when we want signal that a subsection is loading/saving data, so that we can show a spinner
 * in the header buttons, without re-rendering the entire form.
 * </pre>
 *
 * ***NOTE** This could also be a good place to store the isDirty state for the entire form.
 */
const FormEditingProvider: React.FC = ({ children }) => {
  // this state is used like a semaphore
  const [numberOfRequestsSentToBackend, setNumberOfRequestsSentToBackend] = useState(0);

  const startLoading = () => {
    setNumberOfRequestsSentToBackend(prev => prev + 1);
  };

  const stopLoading = () => {
    setNumberOfRequestsSentToBackend(prev => Math.max(prev - 1, 0));
  };

  return (
    <FormEditingContext.Provider value={{
      startLoading,
      stopLoading,
      loading: (numberOfRequestsSentToBackend > 0),
    }}
    >
      {children}
    </FormEditingContext.Provider>
  );
};

export default FormEditingProvider;
