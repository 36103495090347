import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';

export const useCompanyListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.contacts.company.table.companyName),
      dataKey: 'companyName',
      render: (text: any, row: any) => (
        row && row.type === 'MANAGEMENT_COMPANY' ? (
          <CellWithTwoLines firstElement={row.companyName} secondElement={tl(translations.pages.contacts.company.table.managementCompany)} />
        ) : (<span>{row.companyName}</span>)
      ),
    },
    {
      title: tl(translations.pages.contacts.company.table.tradeRegisterNumber),
      dataKey: 'tradeRegisterNumber',
    },
    {
      title: tl(translations.pages.contacts.company.table.vatId),
      dataKey: 'vatId',
    },
    {
      title: tl(translations.pages.contacts.company.table.numberOfBankAccounts),
      dataKey: 'numberOfBankAccounts',
      render: (amount: number) => (
        <Amount>{amount}</Amount>
      ),
    },
    {
      title: tl(translations.pages.contacts.company.table.address),
      dataKey: 'address',
      className: 'multi-line',
    },
    {
      title: tl(translations.pages.contacts.company.table.numberOfEmployees),
      dataKey: 'numberOfEmployees',
      render: (amount: number) => (
        <Amount>{amount}</Amount>
      ),
    },
    {
      title: tl(translations.pages.contacts.company.table.website),
      dataKey: 'website',
    },
  ];
};
