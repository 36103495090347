import React, {
  useContext,
  useState,
} from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';

import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import DeleteButton
  from '../../../elements/FormElements/FormSection/DeleteButton';
import {
  BankAccountsImportSection,
} from './BankAccountsImportSection/BankAccountsImportSection';
import { BankAccountImportDto } from './BankAccountsImportSection/interfaces';
import {
  ContactsImportSection,
} from './ContactsImportSection/ContactsImportSection';
import { ContactImportDto } from './ContactsImportSection/interfaces';
import {
  DirectDebitMandateImportSection,
} from './DirectDebitMandateImportSection/DirectDebitMandateImportSection';
import {
  EconomicPlansImportSection,
} from './EconomicPlansImportSection/EconomicPlansImportSection';
import { ImportProgress } from './ImportProgress/ImportProgress';
import { PropertyImportDto } from './PropertiesImportSection/interfaces';
import {
  PropertiesImportSection,
} from './PropertiesImportSection/PropertiesImportSection';
import {
  RentPlansImportSection,
} from './RentPlansImportSection/RentPlansImportSection';
import {
  ResolutionRecordImportSection,
} from './ResolutionRecordImportSection/ResolutionRecordImportSection';
import {
  TransactionsImportSection,
} from './TransactionsImportSection/TransactionsImportSection';
import { translations } from './translations';
import { UnitsImportSection } from './UnitImportSection/UnitsImportSection';
import { useImport } from './useImportEditor';

export const ImportEditor = () => {
  const { tl } = useContext(LanguageContext);
  const { importData, deleteImportedData, progress } = useImport();
  const [properties, setProperties] = useState<PropertyImportDto[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccountImportDto[]>([]);
  const [contacts, setContacts] = useState<ContactImportDto[]>([]);

  return (
    <Page className="ImportEditor">
      <PageHeader
        title={tl(translations.header.title)}
        rightSideComponent={(
          <>
            <DeleteButton onClick={() => deleteImportedData(contacts, properties)} />
            <Button
              onClick={() => importData({
                contacts, properties, bankAccounts,
              })}
              type="primary"
              disabled={progress.started}
            >
              {tl(translations.header.buttonLabel)}
            </Button>
          </>
        )}
        showLogo
      />
      <PageContent>
        {!progress.started ? (
          <>
            <ContactsImportSection onLoaded={(data) => {
              setContacts(data);
            }}
            />
            <PropertiesImportSection
              onLoaded={(data) => {
                setProperties(data);
              }}
            />
            <BankAccountsImportSection
              properties={properties}
              onLoaded={(data) => {
                setBankAccounts(data);
              }}
            />
            <UnitsImportSection />
            <EconomicPlansImportSection />
            <RentPlansImportSection />
            <ResolutionRecordImportSection />
            <DirectDebitMandateImportSection />
            <TransactionsImportSection />
          </>
        ) : (
          <ImportProgress
            contacts={contacts}
            properties={properties}
            bankAccounts={bankAccounts}
            progress={progress}
          />
        )}
      </PageContent>
    </Page>
  );
};
