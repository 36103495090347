import { PropsWithChildren, createContext, useState } from 'react';
import { StoreApi, createStore } from 'zustand';
import { OpsCostReportHiddenAmountsStore } from './interfacesOpsCostReportHiddenAmounts';
import {
  addHiddenRow, clearStoreHiddenRows, initializeStoreWithExistingHiddenValues, removeHiddenRows,
} from './actionsOpsCostReportHiddenAmounts';


/**
 * In the html generator we initialize it with the values from the store when they click finalize.
 */
const createOpsCostReportHiddenAmountsStore = (initValue: OpsCostReportHiddenAmountsStore['values']) => (
  createStore<OpsCostReportHiddenAmountsStore>()(set => ({
    values: initValue ?? {},
    actions: {
      initializeStoreWithExistingHiddenValues: (...args) => initializeStoreWithExistingHiddenValues(set, ...args),
      clearStoreHiddenRows: () => clearStoreHiddenRows(set),
      addHiddenRow: (...args) => addHiddenRow(set, ...args),
      removeHiddenRows: (...args) => removeHiddenRows(set, ...args),
    },
  }))
);

export const OpsCostReportHiddenAmountsContext = createContext<StoreApi<OpsCostReportHiddenAmountsStore> | null>(null);

export const OpsCostReportHiddenAmountsStoreProvider = ({ children, initValue }: PropsWithChildren<{ initValue?: OpsCostReportHiddenAmountsStore['values'] }>) => {
  const [store] = useState(() => createOpsCostReportHiddenAmountsStore(initValue));

  return (
    <OpsCostReportHiddenAmountsContext.Provider value={store}>
      {children}
    </OpsCostReportHiddenAmountsContext.Provider>
  );
};


OpsCostReportHiddenAmountsStoreProvider.defaultProps = {
  initValue: undefined,
};
