import { ContractLegacyControllerApi, GetContractsUsingGETTypeEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useRef } from 'react';
import { translations } from 'pages/Property/PropertyEditing/pages/MvEditing/translations';
import { PropertyOwnerContractsContext } from './PropertyOwnerContractsContext';

export const usePropertyOwnerContracts = (propertyId: number | undefined, atDate?: string, isComponentHidden?: boolean) => {
  const propertyOwnerContractsContext = useContext(PropertyOwnerContractsContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  if (propertyOwnerContractsContext === undefined) {
    throw new Error('usePropertyOwnerContracts must be used within a PropertyOwnerContractsContextProvider');
  }

  const {
    propertyOwnerContractList,
    setPropertyOwnerContractList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  } = propertyOwnerContractsContext;


  const isCacheValid = paramOfCachedValue === propertyId;

  useEffect(() => {
    if (propertyId === undefined || isCacheValid || isComponentHidden) return;


    setParamOfFetchInProgress((prevParamOfFetchInProgress) => {
      // this must be inside the setParamOfFetchInProgress to make sure we have the latest
      // value of `paramOfFetchInProgress`, in case another instance of this hook
      // already triggered the fetch
      const doesCurrentParamMatchTheParamOfTheInProgressFetch = prevParamOfFetchInProgress === propertyId;

      if (prevParamOfFetchInProgress === undefined || !doesCurrentParamMatchTheParamOfTheInProgressFetch) {
        fetchPropertyOwnerContracts();

        return propertyId;
      }

      return prevParamOfFetchInProgress;
    });
  }, [propertyId, isCacheValid, isComponentHidden]);


  const fetchPropertyOwnerContracts = () => {
    if (propertyId === undefined) {
      throw new Error('Trying to fetch ALL unit contracts with undefined propertyId');
    }

    // if params changed since last initiated fetch then abort the in-progress fetch
    abortController.current?.abort();
    // create new abort controllers
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setPropertyOwnerContractList(prev => prev.startLoading());

    contractControllerApi.getContractsUsingGET({
      propertyId,
      type: [GetContractsUsingGETTypeEnum.PROPERTY_OWNER] as unknown as GetContractsUsingGETTypeEnum,
      validAtDate: atDate,
    }, { signal })
      .then((resp) => {
        setPropertyOwnerContractList(prev => prev.load(resp));
        setParamOfCachedValue(propertyId);
      })
      .catch((err) => {
        if (signal.aborted) return;
        showNotification({
          type: 'error',
          message: tl(translations.notifications.loadContractsError),
        });
        setPropertyOwnerContractList(prev => prev.failed(err));
      })
      .finally(() => {
        setParamOfFetchInProgress(undefined);
      });
  };

  return {
    propertyOwnerContracts: propertyOwnerContractList.data,
    loading: propertyOwnerContractList.loading,
  };
};
