export const translations = {
  commentModal: {
    title: {
      en: 'Add a note',
      de: 'Notiz hinzufügen',
    },
    description: {
      en: (appName: string) => `You can add a comment to the link with the ticket in ${appName}. It will be visible in the history of ${appName}.`,
      de: (appName: string) => `Sie können einen Kommentar zu der Verknüpfung des Vorgangs mit ${appName} hinzufügen. Dieser wird im Verlauf in ${appName} sichtbar sein.`,
    },
    placeholder: {
      en: 'Optional',
      de: 'Optional',
    },
    label: {
      en: 'Note',
      de: 'Notiz',
    },
    buttons: {
      assign: {
        en: 'Assign',
        de: 'Zuordnen',
      },
      cancel: {
        en: 'Cancel',
        de: 'Schließen',
      },
    },
  },
};
