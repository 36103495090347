import React, { useContext, useState } from 'react';
import DEFAULT_DATA from '../lib/data';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from './LanguageContext';
import { showNotification } from '../lib/Notification';
import { OverlayContext } from '../services/OverlayContext/OverlayContext';
import { PaymentControllerApi } from '../api/accounting';
import { AuthContext } from './AuthContext';
import { PaymentListContext } from './PaymentListContext';

export const PaymentCreationContext: any = React.createContext({});

export default function PaymentCreationProvider({ children }: any): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);

  const paymentControllerApi = new PaymentControllerApi(apiConfiguration('accounting'));

  const defaultContext: any = {
    payment: {
      executionType: 'asap',
    },
  };

  const { goBack } = useContext(OverlayContext);

  const [payment, setPayment] = useState(DEFAULT_DATA<any>(defaultContext.payment));
  const [isDirty, setDirty] = useState(false);

  const convertToBeModel = (paymentObject: any) => {
    if (paymentObject.executionType === 'asap') {
      paymentObject.executionDate = new Date();
    }
    return {
      ...paymentObject,
      propertyBankAccountId: paymentObject.bankAccountId,
    };
  };

  const onSavePayment = () => {
    setPayment(payment.startLoading());
    const p = paymentControllerApi.createPaymentUsingPOST({
      paymentDto: convertToBeModel(payment.data),
    });
    p.then((response: any) => {
      setDirty(false);
      setPayment(payment.load(response));
      showNotification({
        key: 'savePaymentSuccess',
        message: tl(translations.notifications.paymentCreationContext.saveSuccess.message),
        description: tl(translations.notifications.paymentCreationContext.saveSuccess.description),
        type: 'success',
      });
      goBack();
    });
    p.catch((res) => {
      if (res.title === 'Validation error') {
        setPayment(payment.failed(res));
        showNotification({
          key: 'savePaymentValidationError',
          message: tl(translations.notifications.paymentCreationContext.saveValidationError.message),
          description: tl(translations.notifications.paymentCreationContext.saveValidationError.description),
          type: 'error',
        });
      } else {
        setPayment(payment.failed());
        showNotification({
          key: 'savePaymentError',
          message: tl(translations.notifications.paymentCreationContext.saveError.message),
          description: tl(translations.notifications.paymentCreationContext.saveError.description),
          type: 'error',
        });
      }
    });
    return p;
  };

  const onClearPayment = () => {
    setPayment(payment.load({}));
    setDirty(false);
  };

  return (
    <PaymentCreationContext.Provider
      value={{
        onSavePayment,
        setPayment,
        payment,
        setDirty,
        isDirty,
        onClearPayment,
        defaultContext,
      }}
    >
      {children}
    </PaymentCreationContext.Provider>
  );
}
