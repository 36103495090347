import {
  useContext,
  useEffect,
} from 'react';

import {
  GetUnitContractsUsingGETContractTypesEnum,
  PropertyDisplayDtoAdministrationTypeEnum,
  PropertyLegacyControllerApi,
  UnitContractControllerApi,
  UnitContractProjectionDto,
  UnitLegacyControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { ISO_DATE_FORMAT } from 'lib/Utils';
import {
  isEmpty,
  uniq,
} from 'lodash';
import moment from 'moment';
import {
  useLocation,
  useParams,
} from 'react-router';

import {
  PDF_PREVIEW_WIDTH,
  usePdfWidthAndZooming,
} from '../../../elements/PdfViewer/services/usePdfWidthAndZooming';
import { translations } from '../translations';
import { PagesOrientationType } from './interfaces';
import {
  PdfPageAssignerDataContext,
  PdfPageAssignerUpdatersContext,
} from './PdfPageAssignerContext';
import { useLoadAssignmentDocuments } from './useLoadAssignmentDocuments';

export const usePdfPageAssigner = () => {
  const { tl } = useContext(LanguageContext);
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const unitControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));

  const { search } = useLocation();
  const { propertyId, sourceId, sourceType } = useParams<{ [key: string]: string }>();
  const parsedPropertyId = parseInt(propertyId, 10);


  if (pdfPageAssignerUpdatersContext === undefined || pdfPageAssignerDataContext === undefined) {
    throw new Error('usePdfPageAssigner must be used within a PdfPageAssignerContextProvider');
  }

  const {
    originalDocuments, property, viewMode, contractsWithinDateRange, existingAssignments,
  } = pdfPageAssignerDataContext;

  const {
    setProperty,
    setUnits,
    setContractsWithinDateRange,
    setContractsOutsideOfDateRange,
  } = pdfPageAssignerUpdatersContext;

  const { loadAssignmentDocuments } = useLoadAssignmentDocuments();

  const {
    pdfPreviewWidth,
    onChangeZoom,
    zoomInDisabled,
    zoomOutDisabled,
  } = usePdfWidthAndZooming();


  useEffect(() => {
    loadProperty();
    loadUnits();
  }, [propertyId]);

  useEffect(() => {
    loadContractsWithinDateRange();
  }, [propertyId, property.data, search]);

  useEffect(() => {
    loadAssignmentDocuments();
  }, [propertyId, sourceId, sourceType]);

  useEffect(() => {
    /**
     * For an MV, if we assign some pages to a contract, then we modify the contract's
     * start/end date so that it's no longer active in the WKA's date range, then
     * the contract won't be loaded in `loadContractsWithinDateRange` (that fetches
     * contracts that are active in the given range), so we need to do that here.
     */
    loadContractsOutsideOfDateRange();
  }, [contractsWithinDateRange, existingAssignments]);


  useEffect(() => {
    // if viewmode changes then update the size of the page previews
    onChangeZoom(PDF_PREVIEW_WIDTH[viewMode === PagesOrientationType.GRID ? 1 : 3]);
  }, [viewMode]);


  const loadProperty = () => {
    if (!propertyId) return;

    setProperty(prev => prev.startLoading());
    propertyControllerApi.getPropertyDisplayByIdUsingGET({ propertyId: parsedPropertyId })
      .then((prp) => {
        setProperty(prev => prev.load(prp));
      })
      .catch((e) => {
        setProperty(prev => prev.failed(e));

        console.error(e);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.loadPropertyError),
        });
      });
  };

  const loadUnits = () => {
    if (!propertyId) return;

    setUnits(prev => prev.startLoading());
    unitControllerApi.getPropertyUnitsUsingGET1({ propertyId: parsedPropertyId, isOwnedByWeg: false })
      .then(units => setUnits(prev => prev.load(units)))
      .catch((e) => {
        setUnits(prev => prev.failed(e));
        console.error(e);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.loadUnitsError),
        });
      });
  };

  const loadContractsWithinDateRange = () => {
    if (!propertyId || !property.loaded || parsedPropertyId !== property.data?.id) return;

    const today = moment();
    const searchParams = new URLSearchParams(search);
    const startDate = moment(searchParams.get('startDate') || today).format(ISO_DATE_FORMAT);
    const endDate = moment(searchParams.get('endDate') || today).format(ISO_DATE_FORMAT);

    setContractsWithinDateRange(prev => prev.startLoading());

    const commonRequestParams = {
      propertyId: parsedPropertyId,
      isOwnedByWeg: false,
    };

    // load owner contracts just for WEGs
    let ownerContracts: Promise<UnitContractProjectionDto[]>;
    if (property.data?.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG) {
      const ownerRequestParams = {
        ...commonRequestParams,
        atDate: today.format(ISO_DATE_FORMAT),
        contractTypes: ([
          GetUnitContractsUsingGETContractTypesEnum.OWNER,
        ] as unknown) as GetUnitContractsUsingGETContractTypesEnum,
      };
      ownerContracts = unitContractControllerApi.getUnitContractsUsingGET(ownerRequestParams);
    }


    // load tenant contracts always
    const tenantRequestParams = {
      ...commonRequestParams,
      contractTypes: ([
        GetUnitContractsUsingGETContractTypesEnum.TENANT,
      ] as unknown) as GetUnitContractsUsingGETContractTypesEnum,
      activeStartDate: startDate,
      activeEndDate: endDate,
    };

    const tenantContracts = unitContractControllerApi.getUnitContractsUsingGET(tenantRequestParams);

    Promise.all([ownerContracts, tenantContracts])
      .then(unitContracts => setContractsWithinDateRange(prev => prev
        .load(unitContracts.flatMap(arr => arr)
          .filter(uc => uc?.unitContractId !== undefined))))
      .catch((e) => {
        setContractsWithinDateRange(prev => prev.failed(e));
        console.error(e);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.loadContractsError),
        });
      });
  };

  const loadContractsOutsideOfDateRange = () => {
    if (!contractsWithinDateRange.loaded || !originalDocuments.loaded) {
      /**
       * We will actually be working with `existingAssignments`, but `originalDocuments` and `existingAssignments`
       * are loaded at the same time so existingAssignments.loaded === originalDocuments.loaded.
       */
      return;
    }

    setContractsOutsideOfDateRange(prev => prev.startLoading());

    if (isEmpty(existingAssignments)) {
      /**
       * If there are no existing assignments then we only load the contracts within the date range.
       */
      setContractsOutsideOfDateRange(prev => prev.load([]));
      return;
    }


    const contractIdsOnAssignmentDocuments = uniq(existingAssignments.map(ea => ea.contractId).filter(Boolean));
    const contractsOutsideOfDateRange = contractIdsOnAssignmentDocuments.filter(contractId => contractsWithinDateRange.data.findIndex(c => c.unitContractId === contractId) === -1);

    if (isEmpty(contractsOutsideOfDateRange)) {
      setContractsOutsideOfDateRange(prev => prev.load([]));
      return;
    }

    unitContractControllerApi.getUnitContractsUsingGET({ unitContractIds: contractsOutsideOfDateRange })
      .then(resp => setContractsOutsideOfDateRange(prev => prev.load(resp)))
      .catch(err => setContractsOutsideOfDateRange(prev => prev.failed(err)));
  };


  return {
    originalDocuments,
    viewMode,
    pdfPreviewWidth,
    onChangeZoom,
    zoomInDisabled,
    zoomOutDisabled,
  };
};
