import { CreateUserDto, RoleDto } from 'api/user';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { useState } from 'react';
import { ValidateStatuses } from 'lib/Utils';
import { nanoid } from 'nanoid';

export interface CreateUserUiDto extends CreateUserDto {
  syntheticId: string; // used by antd Table to figure out which row to delete
  validationState : {
    email: typeof ValidateStatuses[number] | undefined;
    password: typeof ValidateStatuses[number] | undefined;
    role: typeof ValidateStatuses[number] | undefined;
  }
}

export interface UsersListContextType {
  usersList: DefaultDataInterface<CreateUserUiDto[]>,
  roles: DefaultDataInterface<RoleDto[]>,
  setUsersList: React.Dispatch<React.SetStateAction<DefaultDataInterface<CreateUserUiDto[]>>>,
  setRoles: React.Dispatch<React.SetStateAction<DefaultDataInterface<RoleDto[]>>>,
  dirty: boolean,
  setDirty: React.Dispatch<React.SetStateAction<boolean>>,
}

export const InviteUsersContext = React.createContext<UsersListContextType | undefined>(undefined);

const initialData = [{
  syntheticId: nanoid(), email: '', password: '', validationState: {},
} as CreateUserUiDto];
const InviteUsersContextProvider = ({ children }) => {
  const [usersList, setUsersList] = useState<DefaultDataInterface<CreateUserUiDto[]>>(DEFAULT_DATA(initialData));
  const [dirty, setDirty] = useState<boolean>(false);
  const [roles, setRoles] = useState<DefaultDataInterface<RoleDto[]>>(DEFAULT_DATA([]));

  return (
    <InviteUsersContext.Provider value={{
      usersList, setUsersList, dirty, setDirty, roles, setRoles,
    }}
    >
      {children}
    </InviteUsersContext.Provider>
  );
};

export default InviteUsersContextProvider;
