import React, { useContext, useMemo } from 'react';
import {
  Alert, Checkbox, Modal, Table,
} from 'antd';
import moment from 'moment';
import { ICONS } from 'components/icons';
import { Icon } from '@material-ui/core';
import { nanoid } from 'nanoid';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { useVT } from 'virtualizedtableforantd4';
import { useResizableColumn } from 'storybook-components/table/ResizableColumn/useResizableColumn';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import Button from '../../Buttons/Button/Button';
import { formatDate, formatToEuro } from '../../../lib/Utils';
import { usePropertiesWithInsufficientFundsColumns } from './usePropertiesWithInsufficientFundsColumns';
import './ExecutionModal.scss';

export interface Criteria {
  field: string
  value: string
}

export interface ExecutionModalProps {
  visible: boolean
  handleDownload: React.MouseEventHandler<HTMLElement>
  handleCancel?: Function
  criteria: Criteria[]
  children?: any
  numberOfTransactions?: number | null,
  amount?: number | null,
  basedOnSelection?: boolean,
  propertiesWithInsufficientFunds?: any,
  loading?: boolean,
  onExclude?: Function,
  excludedIbans?: string[],
  executionDateWarning?: boolean
  sendAmendMandateInfoToBank?: boolean,
  setSendAmendMandateInfoToBank?: Function
  showMandateAmendmentCheckbox: boolean,
  executionDate?: moment.Moment,
}

export default function ExecutionModal(props: ExecutionModalProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    visible, handleDownload, handleCancel, children, numberOfTransactions, amount, basedOnSelection, propertiesWithInsufficientFunds, loading, excludedIbans, onExclude, executionDateWarning,
    setSendAmendMandateInfoToBank, sendAmendMandateInfoToBank, showMandateAmendmentCheckbox, executionDate,
  } = props;

  const tableScrollY = 450;
  const tableData = useMemo(() => propertiesWithInsufficientFunds || [], [propertiesWithInsufficientFunds]);
  const [
    VT,
  ] = useVT(() => ({
    scroll: {
      y: tableScrollY,
    },
  }), []);
  const columns = usePropertiesWithInsufficientFundsColumns();
  const { components, resizableColumns } = useResizableColumn(columns, VT);
  return (
    <div className="PaymentExecutionModal">
      <Modal
        width="80.0rem"
        title={tl(translations.pages.payment.executionModal.title)}
        className="payment-execution-modal"
        visible={visible}
        onCancel={() => handleCancel && handleCancel()}
        footer={[
          <Button key={nanoid()} onClick={() => handleCancel && handleCancel()}>
            {tl(translations.pages.payment.executionModal.cancel)}
          </Button>,
          <Button
            key={nanoid()}
            onClick={handleDownload}
            type="primary"
            disabled={!numberOfTransactions || numberOfTransactions < 1}
          >
            {tl(translations.pages.payment.executionModal.download)}
          </Button>,
        ]}
      >
        {basedOnSelection ? <p>{tl(translations.pages.payment.executionModal.selectionTitle)}</p>
          : (
            <div>
              <p>{tl(translations.pages.payment.executionModal.criteriaTitle)}</p>
              <ul>
                {children}
              </ul>
            </div>
          )}
        <p>
          {tl(translations.pages.payment.executionModal.numberOfTransaction)}
          {' '}
          <span className="accent-text">{numberOfTransactions || 0}</span>
          <br />
          {tl(translations.pages.payment.executionModal.totalAmount)}
          {' '}
          <span className="accent-text">{formatToEuro(amount || 0)}</span>
        </p>
        <p className="insufficient-funds">
          <span className="insufficient-funds-title">{tl(translations.pages.payment.executionModal.insufficientFundsTitle)}</span>
          <br />
          <span className="insufficient-funds-hint">{tl(translations.pages.payment.executionModal.insufficientFundsTableDescription)}</span>
          <div className="table-pad">
            {propertiesWithInsufficientFunds && propertiesWithInsufficientFunds.length > 0 && (
              <Table
                className="PropertiesWithInsufficientFunds table-with-maxHeight"
                id="PropertiesWithInsufficientFunds"
                columns={resizableColumns}
                dataSource={tableData}
                loading={{
                  spinning: loading || false,
                  indicator: <TableContentLoadingIndicator />,
                }}
                rowSelection={{
                  columnWidth: 40,
                  onChange: record => onExclude(record),
                  selectedRowKeys: tableData.filter(row => !excludedIbans.includes(row.iban)).map(row => row.iban),
                }}
                rowKey="iban"
                components={components}
                pagination={false}
                scroll={{ y: tableScrollY }}
              />
            )}
          </div>
        </p>
        {showMandateAmendmentCheckbox && (
          <p>
            <Checkbox
              className="mandate-amend-config"
              checked={sendAmendMandateInfoToBank}
              onChange={() => setSendAmendMandateInfoToBank(prev => !prev)}
            >
              {tl(translations.pages.payment.executionModal.sendAmendMandateInfoToBank)}
            </Checkbox>
          </p>
        )}
        <Alert
          message={tl(translations.pages.payment.executionModal.body)}
          type="info"
          showIcon
          icon={<Icon component={ICONS.info} />}
        />
        {executionDateWarning && (
          <Alert message={tl(translations.pages.payment.executionModal.executionDateWarning)(formatDate(executionDate || moment().add(2, 'd')))} type="warning" />
        )}
      </Modal>
    </div>
  );
}
