import {
  HouseMoneySettlementAggregationControllerApi,
  HouseMoneyUnitSettlementDto,
  PropertyLegacyControllerApi,
  UnitContractControllerApi,
  UnitContractProjectionDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect } from 'react';
import moment from 'moment';
import { AccountSelectionContext, DatasourceContext, HouseMoneySettlementAndPropertyContext } from './HouseMoneySettlementCloseYearContext';
import { TableDataSourceType } from './TableDataSourceType';
import { translations } from '../translations';
import { useCheckPropertyValidity } from '../../../../../services/Property/useCheckPropertyValidity';

export const useHouseMoneySettlementAndProperty = () => {
  const accountSelectionContext = useContext(AccountSelectionContext);
  const hmsContext = useContext(HouseMoneySettlementAndPropertyContext);
  const datasourceContext = useContext(DatasourceContext);
  const { tl } = useContext(LanguageContext);

  if (hmsContext === undefined || datasourceContext === undefined || accountSelectionContext === undefined) {
    throw new Error('useHmsAndProperty must be used within a HouseMoneySettlementCloseYearContextProvider');
  }

  const { apiConfiguration } = useContext(AuthContext);
  const hmsControllerApi = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));

  const {
    initializeDirectionAndProcessingValues,
    setTableDataSource,
    setLoading,
  } = datasourceContext;

  const {
    propertyAndEconomicYearData,
    setPropertyAndEconomicYearData,
  } = hmsContext;

  const {
    formValues,
    setFormValues,
  } = accountSelectionContext;

  const { checkPropertyValidity } = useCheckPropertyValidity();

  useEffect(() => {
    if (formValues.bookingDate && propertyAndEconomicYearData?.propertyHrId) {
      const dateString = formValues.bookingDate?.format('YYYY-MM-DD');
      fetchUnitOwnersAtDate(dateString);
      checkPropertyValidity({
        propertyHrId: propertyAndEconomicYearData?.propertyHrId,
        atDate: dateString,
      });
    }
  }, [formValues.bookingDate, propertyAndEconomicYearData?.propertyHrId]);

  const mapUnitOwnerToRow = (row: TableDataSourceType, unitWithOwner: UnitContractProjectionDto) => ({
    ...row,
    ...unitWithOwner,
    processing: ((row.direction === 'incoming' && unitWithOwner.hasMandateAtDate) || (row.direction === 'outgoing' && !!unitWithOwner.bankAccountIban)) ? 'PLATFORM' : 'SELF_PAYER',
  } as TableDataSourceType);

  const fetchUnitOwnersAtDate = (atDate?: string) => {
    setLoading(true);
    unitContractControllerApi.getUnitContractsUsingGET({
      propertyId: propertyAndEconomicYearData?.propertyId,
      atDate,
    })
      .then((unitOwners: UnitContractProjectionDto[]) => {
        setTableDataSource((prev) => {
          const loadedData = prev.data!.map(
            row => mapUnitOwnerToRow(row, (unitOwners.find(unitOwner => unitOwner.unitId === row.unitId))!),
          ) || [];
          loadedData.sort((a, b) => a.unitRank - b.unitRank);
          initializeDirectionAndProcessingValues(loadedData);
          return prev.load(loadedData);
        });
        setLoading(false);
      })
      .catch(() => {
        setTableDataSource(prev => prev.failed());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapHmsToTableDataSource = (unitSettlements: HouseMoneyUnitSettlementDto[]): TableDataSourceType[] => unitSettlements.map((unit) => {
    const unitHmsAmount: number = unitSettlements.find((hms: HouseMoneyUnitSettlementDto) => hms.unitId === unit.unitId!)!.results?.houseMoneySettlementResult?.unitSubtotalWithPlannedHouseMoneyAndReserveFunds!;
    const directionOption = unitHmsAmount < 0 ? 'outgoing' : 'incoming';
    return {
      unitId: unit.unitId,
      amount: Math.abs(unitHmsAmount),
      processing: 'SELF_PAYER',
      direction: directionOption,
    } as TableDataSourceType;
  });

  const getHouseMoneySettlement = (hmsId: number) => {
    setLoading(true);

    hmsControllerApi.getHouseMoneySettlementByIdUsingGET({
      id: hmsId,
    })
      .then(hms => propertyControllerApi
        .getPropertySimpleByIdUsingGET({
          propertyId: hms.propertyId!,
        })
        .then((propertySimple) => {
          setPropertyAndEconomicYearData({
            propertyId: hms.propertyId,
            propertyName: propertySimple.name,
            propertyIdInternal: propertySimple.propertyIdInternal,
            propertyHrId: propertySimple.propertyHrId,
            economicYear: hms.economicYear,
          });
          return hms;
        }))
      .then((hms) => {
        const tableDataSource: TableDataSourceType[] = mapHmsToTableDataSource(hms.unitSettlements!);
        initializeDirectionAndProcessingValues(tableDataSource);
        setTableDataSource(prev => prev.load(tableDataSource));
        setFormValues((prevState => ({
          ...prevState,
          bookingDate: moment(), // changing the booking date will trigger owner fetch
          bookingText: `Abrechnungsspitze ${hms.economicYear}`,
        })));
      })
      .catch(() => {
        showNotification({
          key: 'loadHMSError',
          message: tl(translations.creationPage.notifications.fetchHouseMoneySettlementError),
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  return {
    getHouseMoneySettlement,
    sectionTitle: propertyAndEconomicYearData?.propertyHrId
      ? `${propertyAndEconomicYearData?.propertyIdInternal} - ${propertyAndEconomicYearData?.propertyName} - ${propertyAndEconomicYearData?.propertyHrId}`
      : '',
  };
};
