import { PdfPageAssignerDataContext } from 'pages/PdfPageAssigner/services/PdfPageAssignerContext';
import { useContext } from 'react';

export const usePdfPreviewSidebar = () => {
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);

  if (pdfPageAssignerDataContext === undefined) {
    throw new Error('usePdfPreviewSidebar must be used within PdfPageAssignerContextProvider');
  }


  const { idOfPageInView } = pdfPageAssignerDataContext;

  return { idOfPageInView };
};
