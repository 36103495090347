import React, {useContext, useMemo} from 'react';
import useSmartTable from '../../../../elements/SmartTable/useSmartTable';
import SmartTable from '../../../../elements/SmartTable/SmartTable';
import {UseTotalAmountColumns} from './UseTotalAmountColumns';
import {LanguageContext} from '../../../../contexts/LanguageContext';
import {translations} from '../../../../elements/Translation/translations';
import {formatCurrency} from '../../../../lib/Utils';
import SummaryRow from '../../../../elements/CustomElements/ExpandableCard/SummaryRow';
import {UseUnitTotalAmountColumns} from './UseUnitTotalAmountColumns';

interface TotalAmountProps {
  dataSource: any,
  loading: boolean,
  isUnitTotal?: boolean,
}

interface TotalAmountTotalProps {
  dataSource: any,
  isUnitTotal?: boolean,
}

export function useRenderTotalAmountTotal(props: TotalAmountTotalProps) {
  const { tl } = useContext(LanguageContext);
  const { dataSource, isUnitTotal } = props;
  const total = useMemo(() => (dataSource ? dataSource.totalExpenses - dataSource.totalIncome : 0), [dataSource]);
  const totalShares = useMemo(() => (dataSource ? dataSource.expensesShare + dataSource.incomeShare + dataSource.reserveFundsShare : 0), [dataSource]);
  const summaryElements = [{
    title: tl(translations.pages.economicPlan.unitAccountBalances.totalAmount.table.headers.total),
    value: formatCurrency(total),
  }];
  if (isUnitTotal) {
    summaryElements.push({
      title: tl(translations.pages.economicPlan.unitAccountBalances.totalAmount.table.headers.share),
      value: formatCurrency(totalShares),
    });
  }
  return (
    <SummaryRow
      rightSideElements={summaryElements}
      title={tl(translations.pages.economicPlan.propertyAccountBalances.totalAmount.total)}
    />
  );
}

export default function TotalAmountTable(props: TotalAmountProps): JSX.Element {
  const { dataSource, loading, isUnitTotal } = props;
  const { tl } = useContext(LanguageContext);
  const tableData = useMemo(() => [
    {
      amountType: tl(translations.pages.economicPlan.propertyAccountBalances.totalAmount.table.fields.expenses),
      amount: dataSource.totalExpenses,
      share: dataSource.expensesShare ? dataSource.expensesShare : 0,
    },
    {
      amountType: tl(translations.pages.economicPlan.propertyAccountBalances.totalAmount.table.fields.income),
      amount: dataSource.totalIncome,
      share: dataSource.incomeShare ? dataSource.incomeShare : 0,
    },
    {
      amountType: tl(translations.pages.economicPlan.propertyAccountBalances.totalAmount.table.fields.reserveFunds),
      amount: dataSource.totalReserveFunds,
      share: dataSource.reserveFundsShare ? dataSource.reserveFundsShare : 0,
    },
  ], [dataSource]);
  const table = useSmartTable({
    tableName: 'totalAmountsTable',
    columns: isUnitTotal ? UseUnitTotalAmountColumns() : UseTotalAmountColumns(),
    dataSource: tableData,
    contentLoading: loading,
    verticallyScrollable: false,
    rowKey: 'amountType',
  });


  return (
    <div className="TotalAmountTable ">
      <SmartTable {...table} />
    </div>
  );
}
