import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import './AddAgendaItemButton.scss';
import AdditionalEntityButton from '../../../../../../../elements/Buttons/AdditionalEntityButton/AdditionalEntityButton';
import { useAddAgendaItems } from '../../services/useAddAgendaItems';
import { ownersMeetingInvitationTranslations } from '../../../../translations/OwnersMeetingInvitationTranslations';
import { OwnersMeetingInvitationDtoStatusEnum } from '../../../../../../../api/accounting';
import { OwnersMeetingInvitationContext } from '../../../../../../../contexts/OwnersMeetingInvitationContext';

const AddAgendaItemButton = () => {
  const { tl } = useContext(LanguageContext);
  const { addAgendaItem } = useAddAgendaItems();
  const { ownersMeeting } = useContext(OwnersMeetingInvitationContext);
  const isDisabled = !ownersMeeting.data
   || ([OwnersMeetingInvitationDtoStatusEnum.SENT,
     OwnersMeetingInvitationDtoStatusEnum.SENDING,
     OwnersMeetingInvitationDtoStatusEnum.DELETED]
     .includes(ownersMeeting.data!.status));

  return (
    <AdditionalEntityButton
      label={tl(ownersMeetingInvitationTranslations.agendaItemsTable.addAgendaItem)}
      className="add-agenda-item"
      onClick={addAgendaItem}
      disabled={isDisabled}
    />
  );
};

export default AddAgendaItemButton;
