import { useContext } from 'react';
import { PropertyLegacyControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { Modal } from 'antd';
import { showNotification } from 'lib/Notification';
import { formatDate } from 'lib/Utils';
import { LanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router';
import { translations } from './translations';


interface Arguments {
  propertyHrId: string,
  atDate?: string,
  onValid?: () => void,
  onCancel?: () => void,
}


export const useCheckPropertyValidity = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const history = useHistory();


  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));


  const checkPropertyValidity = ({
    propertyHrId, onValid, onCancel, atDate,
  }: Arguments) => {
    propertyControllerApi.validatePropertyUsingGET({ propertyHrId, atDate: atDate ?? formatDate(new Date(), 'YYYY-MM-DD') })
      .then(({ valid }) => {
        if (valid) {
          onValid?.();
        } else {
          Modal.confirm({
            title: tl(translations.propertyValidityModal.title),
            content: tl(translations.propertyValidityModal.content),
            okText: tl(translations.propertyValidityModal.okText),
            cancelText: tl(translations.propertyValidityModal.cancelText),
            okButtonProps: { className: 'Button' },
            cancelButtonProps: { className: 'Button' },
            onOk: () => { history.push(`/properties/${propertyHrId}/edit`); },
            onCancel: () => { onCancel?.(); },
            width: 450,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.propertyValidityModal.checkFailed),
        });
      });
  };

  return { checkPropertyValidity };
};
