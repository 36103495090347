import { Configuration, RoleControllerApi, UserControllerApi } from 'api/user';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { inviteUsersTranslations } from 'pages/Users/InviteUsers/inviteUsersTranslations';
import { CreateUserUiDto, InviteUsersContext } from 'pages/Users/InviteUsers/services/InviteUsersContext';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { EMAIL_MATCHER, PASSWORD_MATCHER, range } from 'lib/Utils';
import { nanoid } from 'nanoid';


export const useInviteUsers = () => {
  const inviteUsersContext = useContext(InviteUsersContext);
  if (inviteUsersContext === undefined) {
    throw Error('useInviteUsers can only be used within InviteUsersContextProvider');
  }

  const {
    usersList, setUsersList, dirty, setDirty, setRoles,
  } = inviteUsersContext;

  useEffect(() => { loadUserRoles(); }, []);

  const history = useHistory();
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const usersApi = new UserControllerApi(apiConfiguration('user') as unknown as Configuration);
  const rolesApi = new RoleControllerApi(apiConfiguration('user') as unknown as Configuration);

  const goBack = () => {
    history.push('/users');
  };

  const isDuplicateEmail = (user: CreateUserUiDto, index: number, usersArray: CreateUserUiDto[]) => (
    // NOTE: We must use usersArray instead of usersList.data since usersList.data is not filtered
    !!usersArray.find((u, idx) => u.email.toLowerCase() === user.email.toLowerCase() && idx !== index)
  );

  const validateUser = (user: CreateUserUiDto, index: number, usersArray: CreateUserUiDto[]) => {
    const duplicateEmail = isDuplicateEmail(user, index, usersArray);
    user.validationState.email = EMAIL_MATCHER.test(user.email) && !duplicateEmail ? undefined : 'error';
    user.validationState.password = PASSWORD_MATCHER.test(user.password) ? undefined : 'error';
    user.validationState.role = !user.roleId ? 'error' : undefined;
    return user;
  };

  const validateUsers = () => usersList.data
    .filter(user => user?.email || user?.password)
    .map(validateUser);

  const save = () => {
    const validatedUsers = validateUsers();
    if (validatedUsers.find(user => !!user.validationState.email || !!user.validationState.password || !!user.validationState.role)) {
      setUsersList(prev => prev.load(validatedUsers));
      showNotification({
        key: 'invalidUsersMessage',
        message: tl(inviteUsersTranslations.notifications.cannotSave.message),
        description: tl(inviteUsersTranslations.notifications.cannotSave.description),
        type: 'error',
      });
      return;
    }
    setUsersList(prev => prev.startLoading());
    usersApi.inviteUsersUsingPOST({
      createUserDtos: validatedUsers,
    }).then(() => {
      setDirty(false);
      setUsersList(prevState => prevState.finishLoading());
      showNotification({
        key: 'saveUsersSuccess',
        message: tl(inviteUsersTranslations.notifications.saveSuccess.message),
        description: tl(inviteUsersTranslations.notifications.saveSuccess.description),
        type: 'success',
      });
      goBack();
    })
      .catch((err) => {
        console.error(err);
        setUsersList(prevState => prevState.failed());
        err?.response?.json()
          .then((errorBody) => {
            showNotification({
              key: 'saveUsersError',
              message: tl(inviteUsersTranslations.notifications.saveError),
              description: errorBody.status === 409 ? tl(inviteUsersTranslations.notifications.saveError.duplicateEmail)(errorBody.detail) : undefined,
              type: 'error',
            });
          });
      });
  };


  const addUsers = (value: number) => {
    const newUsers = range(0, value - 1).map(() => ({
      syntheticId: nanoid(), email: '', password: '', validationState: { email: undefined, password: undefined },
    } as CreateUserUiDto));

    setUsersList(prev => prev.load([...prev.data, ...newUsers]));
  };

  const loadUserRoles = () => {
    rolesApi.getRolesUsingGET()
      .then((response) => {
        setRoles(prev => prev.load(response));
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          key: 'loadRolesError',
          type: 'error',
          message: tl(inviteUsersTranslations.notifications.loadRolesError),
        });
      });
  };

  return {
    addUsers,
    goBack,
    save,
    datasource: usersList.data || [],
    loading: usersList.loading,
    dirty,
  };
};
