export const translations = {
  propertyOwnersOverview: {
    title: {
      en: 'Property Owners',
      de: 'Objekteigentümer',
    },
    noContractsMessage: {
      en: "You haven't created any MV owners yet.",
      de: 'Sie haben noch keine Objekteigentümer hinzugefügt.',
    },
    addContractButtonLabel: {
      en: 'Add new property owner',
      de: 'Objekteigentümer hinzufügen',
    },
    table: {
      contractNr: {
        en: 'Contract Nr.',
        de: 'Vertragsnr.',
      },
      contactName: {
        en: 'Contact Name',
        de: 'Kontakt Name',
      },
      startDate: {
        en: 'Start Date',
        de: 'Startdatum',
      },
      endDate: {
        en: 'End Date',
        de: 'Enddatum',
      },
    },
    rowActions: {
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
    },
  },
  notifications: {
    loadContractsError: {
      en: 'Failed to load property owner contracts',
      de: 'Verträge de Objekteigentümern konnten nicht geladen werden',
    },
    deleteError: {
      message: {
        en: 'Failed to delete property owner contract.',
        de: 'Vertrag der Objekteigentümern konnte nicht gelöscht werden.',
      },
      description: {
        en: 'Property owner has bank orders/postings.',
        de: 'Objekteigentümer hat Bankaufträge/Buchungen.',
      },
    },
    deleteSuccess: {
      message: {
        en: 'Contract was deleted successfully.',
        de: 'Der Vertrag wurde erfolgreich gelöscht.',
      },
    },
  },
  deleteModal: {
    title: {
      en: 'Warning',
      de: 'Warnung',
    },
    message: {
      en: 'By deleting the contract the system will automatically delete all related data. This includes postings, transaction allocations, direct debits, accounts and others. This is an irreversible action. Are you sure you want to delete it?',
      de: 'Indem Sie den Vertrag löschen, werden automatisch auch alle damit verbundenen Daten gelöscht. Dies inkludiert Buchungen, die Zuweisung von Bankumsätzen, Lastschriften, buchhalterische Konten und weitere. Dies kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie fortfahren möchten?',
    },
    okText: {
      en: 'Yes',
      de: 'Ja',
    },
    cancelText: {
      en: 'No',
      de: 'Nein',
    },
  }
};
