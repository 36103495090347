export const translations: any = {
  postingList: {
    postingDeletionModal: {
      content: {
        en: 'Do you really want to delete the posting?',
        de: 'Möchtest du diese Buchung und alle verbunden Buchungen wirklich löschen?',
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
    postingRevertingModal: {
      posting: {
        en: 'Posting',
        de: 'Buchung',
      },
      content: {
        en: 'This will create a reversal booking for this booking. Do you want to continue?',
        de: 'Es wird eine Stornobuchung erstellt. Möchten Sie fortfahren?',
      },
      revert: {
        en: 'Reverse',
        de: 'Stornieren',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
  },
  transactionNavigation: {
    loadError: {
      en: 'Transaction could not be found',
      de: 'Transaktion konnte nicht gefunden werden',
    },
  },
};
