import moment from 'moment';
import { useContext } from 'react';
import { EconomicPlanContext } from 'pages/EconomicPlan/services/EconomicPlanContext';
import { EconomicPlanDtoStatusEnum } from 'api/accounting';

export const useShowCorrectionalBookings = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw Error('useIsPastEconomicPlan must be used within EconomicPlanContextProvider');
  }

  const { economicPlan } = economicPlanContext;

  const showCorrectionalBookings = economicPlan?.data?.startDate
      && moment(economicPlan.data.startDate).isBefore(moment())
      && [EconomicPlanDtoStatusEnum.DRAFT, EconomicPlanDtoStatusEnum.PREPARED].includes(economicPlan?.data?.status);

  return {
    showCorrectionalBookings,
  };
};
