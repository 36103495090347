import { InvoiceEpoStateEnum } from 'api/accounting';
import './PaymentTargetInformation.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import DateInput, { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';
import NumberInput from 'elements/Inputs/NumberInput/NumberInput';
import moment from 'moment';
import { useContext } from 'react';
import { translations } from '../../../../translations';
import { usePaymentTargetInformation } from './usePaymentTargetInformation';

const PaymentTargetInformation = () => {
  const { tl } = useContext(LanguageContext);
  const {
    data, onChangePaymentTargetDaysAndDate, getValidationMessage, getValidationState,
  } = usePaymentTargetInformation();

  return (
    <div className="sub-section-content-row">
      <div className="sub-section-content-column">
        <NumberInput
          key="paymentTargetDays"
          label={tl(
            translations.invoiceEditing.sections.section4.subsection0.fields
              .paymentTargetDays,
          )}
          required
          autoFocus
          disabled={data.invoice.state !== InvoiceEpoStateEnum.NEW && data.invoice.state !== InvoiceEpoStateEnum.READY_FOR_APPROVAL}
          value={data.invoice.paymentTargetDays}
          onChange={(value: number) => {
            const paymentTargetDate = moment
              .utc(data.invoice.invoiceDate)
              .add(value, 'days');
            onChangePaymentTargetDaysAndDate(value, paymentTargetDate);
          }}
          validationMessage={getValidationMessage('paymentTargetDays')}
          validationState={getValidationState('paymentTargetDays')}
        />
      </div>
      <div className="sub-section-content-column">
        <DateInput
          key="paymentTargetDate"
          label={tl(
            translations.invoiceEditing.sections.section4.subsection0.fields.paymentTargetDate,
          )}
          required
          disabled={data.invoice.state !== InvoiceEpoStateEnum.NEW && data.invoice.state !== InvoiceEpoStateEnum.READY_FOR_APPROVAL}
          value={data.invoice.paymentTargetDate}
          onChange={(value) => {
            const paymentTargetDays = Math.ceil(
              moment
                .utc(value)
                .diff(data.invoice.invoiceDate, 'days', true),
            );
            onChangePaymentTargetDaysAndDate(paymentTargetDays, value!);
          }}
          validationMessage={getValidationMessage('paymentTargetDate')}
          validationState={getValidationState('paymentTargetDate')}
          softMinDate={MIN_DATE_NORMAL}
          softMaxDate={MAX_DATE_NORMAL}
        />
      </div>
    </div>
  );
};

export default PaymentTargetInformation;
