import TagsGroup from 'storybook-components/TagsGroup/TagsGroup';
import CellWithTwoLines from '../CellWithTwoLines/CellWithTwoLines';
import { CellWithTwoLinesAndTagProps } from './interfaces';

import './CellWithTwoLinesAndTag.scss';

const CellWithTwoLinesAndTag = ({
  tagLabel,
  onClickTag,
  ...restProps
}: CellWithTwoLinesAndTagProps) => (
  <div className="CellWithTwoLinesAndTag">
    <CellWithTwoLines {...restProps} />
    {tagLabel
    && (
      <span onClick={onClickTag} role="button" tabIndex={0} className={onClickTag && 'tag-clickable'}>
        <TagsGroup tags={[tagLabel]} shorten />
      </span>
    )}
  </div>
);

export default CellWithTwoLinesAndTag;
