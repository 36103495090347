import React from 'react';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { getStateColor } from 'storybook-components/headers/utils';


const InvitationStatusCell = ({ record }) => (
  <>
    <ColorDot color={getStateColor(record.status) as DotColorEnum} />
    {record.etvStatus}
  </>
);

export default InvitationStatusCell;
