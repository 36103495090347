import {
  useContext,
  useState,
} from 'react';

import {
  ContractImportCreateDto,
  ContractImportCreateDtoContractTypeEnum,
  ContractImportCreateDtoMandateStateEnum,
  ContractImportCreateDtoVatRelevanceEnum,
  ImportsControllerApi,
  UnitWithContractImportCreateDto,
  UnitWithContractImportCreateDtoUnitTypeEnum,
} from '../../../../api/accounting';
import { AuthContext } from '../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { showNotification } from '../../../../lib/Notification';
import { translations } from './translations';

const unitTypes = {
  Wohnung: UnitWithContractImportCreateDtoUnitTypeEnum.APARTMENT,
  Gewerbe: UnitWithContractImportCreateDtoUnitTypeEnum.COMMERCIAL,
  Parkmöglichkeit: UnitWithContractImportCreateDtoUnitTypeEnum.PARKING,
  Sonstiges: UnitWithContractImportCreateDtoUnitTypeEnum.OTHER,
};

const VAT_RELEVANCE_TYPE = {
  Umsatzsteuerpflichtig: ContractImportCreateDtoVatRelevanceEnum.VAT_RELEVANT,
  'Ermäßigt umsatzsteuerpflichtig': ContractImportCreateDtoVatRelevanceEnum.REDUCED_VAT_RELEVANT,
  'Nicht umsatzsteuerpflichtig': ContractImportCreateDtoVatRelevanceEnum.NOT_RELEVANT,
};

const mapRowToUnitContractsDto = (csvRow: any): any => {
  const contracts: Array<ContractImportCreateDto> = [];
  if (csvRow.ownerContractMailingContactExternalId) {
    // unit owner contract
    const ownerContract: ContractImportCreateDto = {
      contractType: ContractImportCreateDtoContractTypeEnum.OWNER,
      contactExternalId: csvRow.ownerContractMailingContactExternalId,
      contractNumber: csvRow.ownerContractId,
      startDate: csvRow.ownerContractStartDate,
      endDate: csvRow.ownerContractEndDate,
      mandateState: csvRow.ownerDirectDebitMandateActive === 'WAHR' ? ContractImportCreateDtoMandateStateEnum.BOOKED : ContractImportCreateDtoMandateStateEnum.IGNORED,
      directDebitMandateId: csvRow.ownerDirectDebitMandateId,
      directDebitSignedOnDate: csvRow.ownerDirectDebitSignedOnDate,
      directDebitValidFromDate: csvRow.ownerDirectDebitValidFromDate,
      additionalContactExternalIds: [],
    };
    if (csvRow.ownerContact2) {
      ownerContract.additionalContactExternalIds.push(csvRow.ownerContact2);
    }
    if (csvRow.ownerContact3) {
      ownerContract.additionalContactExternalIds.push(csvRow.ownerContact3);
    }
    contracts.push(ownerContract);
  }
  if (csvRow.tenantContractMailingContactExternalId) {
    // unit tenant contract
    const tenantContract: ContractImportCreateDto = {
      contractType: ContractImportCreateDtoContractTypeEnum.TENANT,
      contactExternalId: csvRow.tenantContractMailingContactExternalId,
      contractNumber: csvRow.tenantContractId,
      startDate: csvRow.tenantContractStartDate,
      endDate: csvRow.tenantContractEndDate,
      vatRelevance: VAT_RELEVANCE_TYPE[csvRow.tenantVatRelevance],
      mandateState: csvRow.tenantDirectDebitMandateActive === 'WAHR' ? ContractImportCreateDtoMandateStateEnum.BOOKED : ContractImportCreateDtoMandateStateEnum.IGNORED,
      directDebitMandateId: csvRow.tenantDirectDebitMandateId,
      directDebitSignedOnDate: csvRow.tenantDirectDebitSignedOnDate,
      directDebitValidFromDate: csvRow.tenantDirectDebitValidFromDate,
      additionalContactExternalIds: [],
    };
    if (csvRow.tenantContact2) {
      tenantContract.additionalContactExternalIds.push(csvRow.tenantContact2);
    }
    if (csvRow.tenantContact3) {
      tenantContract.additionalContactExternalIds.push(csvRow.tenantContact3);
    }
    contracts.push(tenantContract);
  }

  return contracts;
};

const mapRowToUnitWithContractDto = (csvRow: any): UnitWithContractImportCreateDto => {
  const unitContractImport = {
    buildingName: csvRow.buildingExternalId,
    externalId: csvRow.unitExternalId,
    floor: csvRow.floor,
    heatingArea: csvRow.heatingArea && parseFloat(csvRow.heatingArea.replace('.', '').replace(',', '.')),
    livingArea: csvRow.livingArea && parseFloat(csvRow.livingArea.replace('.', '').replace(',', '.')),
    persons: csvRow.persons && parseInt(csvRow.persons, 10),
    portalId: csvRow.portalId,
    position: csvRow.location,
    propertyIdExternal: csvRow.propertyExternalId,
    propertyShare: csvRow.propertyShare && parseFloat(csvRow.propertyShare.replace('.', '').replace(',', '.')),
    unitNrSharingDeclaration: csvRow.unitNrSharingDeclaration,
    unitType: unitTypes[csvRow.unitType],
    contracts: mapRowToUnitContractsDto(csvRow),
  };

  return unitContractImport;
};

export const useUnitsImportCsv = () => {
  const [units, setUnits] = useState<UnitWithContractImportCreateDto[]>([]);
  const [unitsValidationIssues, setUnitsValidationIssues] = useState<any[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const importsControllerApi = new ImportsControllerApi(apiConfiguration('accounting'));

  const loadUnitsFromCsvRows = (csvRows: any[]) => {
    const readUnits: UnitWithContractImportCreateDto[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach((csvRow) => {
      const unit = mapRowToUnitWithContractDto(csvRow);
      readUnits.push(unit);
      if (!unit.propertyIdExternal) {
        validationIssues.push({ message: tl(translations.validations.issues.propertyIdInternal), row: csvRow._row });
      }
      if (!unit.unitNrSharingDeclaration) {
        validationIssues.push({ message: tl(translations.validations.issues.unitNrSharingDeclaration), row: csvRow._row });
      }
      if (!unit.unitType) {
        validationIssues.push({ message: tl(translations.validations.issues.unitType), row: csvRow._row });
      }
      unit.contracts.forEach((contract) => {
        validationIssues.push(...getContractValidationIssues(contract, csvRow));
      });
    });
    setUnitsValidationIssues(validationIssues);
    if (validationIssues.filter(issue => typeof issue.type === 'undefined' || issue.type === 'error').length) {
      setUnits([]);
    } else {
      setUnits(readUnits);
    }
  };

  const getContractValidationIssues = (contract: ContractImportCreateDto, csvRow) => {
    const contractValidationIssues: any[] = [];
    if (!contract.mandateState) {
      contractValidationIssues.push({ message: tl(translations.validations.issues.mandateState), row: csvRow._row });
    }
    if (!contract.contactExternalId) {
      contractValidationIssues.push({ type: 'warning', message: tl(translations.validations.issues.contactExternalId), row: csvRow._row });
    }
    if (contract.additionalContactExternalIds && (
      (contract.contactExternalId && contract.additionalContactExternalIds.indexOf(contract.contactExternalId) !== -1)
      || (contract.additionalContactExternalIds.indexOf(contract.additionalContactExternalIds[0]) > 0)
    )) {
      contractValidationIssues.push({ message: tl(translations.validations.issues.duplicateContactReference), row: csvRow._row });
    }
    if (contract.startDate && contract.endDate && contract.startDate > contract.endDate) {
      contractValidationIssues.push({ message: tl(translations.validations.issues.contractDate), row: csvRow._row });
    }
    return contractValidationIssues;
  };

  const uploadUnits = () => {
    if (unitsValidationIssues.filter(issue => typeof issue.type === 'undefined' || issue.type === 'error').length > 0 || units.length < 1) {
      showNotification({
        key: 'unitImportError',
        message: tl(translations.validations.errors),
        type: 'error',
      });
      return;
    }
    setInProgress(true);
    const leftoverUnits = units.slice();
    const sendNextBatch = () => {
      importsControllerApi.createUnitsWithContractsUsingPOST({ units: leftoverUnits.splice(0, 50) })
        .then(() => {
          if (leftoverUnits.length == 0) {
            setInProgress(false);
            setUnits([]);
            setUnitsValidationIssues([]);
            showNotification({
              key: 'unitImportSuccess',
              message: tl(translations.success),
              type: 'success',
            });
          } else {
            console.log(`Left to import ${leftoverUnits.length}`);
            sendNextBatch();
          }
        })
        .catch(() => {
          setInProgress(false);
          showNotification({
            key: 'unitImportError',
            message: tl(translations.error),
            type: 'error',
          });
        });
    };
    sendNextBatch();
  };

  return {
    loadUnitsFromCsvRows,
    units,
    unitsValidationIssues,
    uploadUnits,
    inProgress,
  };
};
