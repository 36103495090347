export const translations = {
  propertyDistributionKey: {
    title: {
      en: 'Distribution key',
      de: 'Verteilerschlüssel',
    },
    opsCost: {
      en: 'Ops cost',
      de: 'Betriebskosten',
    },
    hga: {
      en: 'HGA & EP',
      de: 'HGA & WP',
    },
    hgaLabel: {
      en: 'HGA',
      de: 'HGA',
    },
    incomeHeader: {
      en: 'Income',
      de: 'Einnahmen',
    },
    expensesHeader: {
      en: 'Expenses',
      de: 'Ausgaben',
    },
    reserveFunds: {
      en: 'Reserve Funds',
      de: 'Rücklagen',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    table: {
      headers: {
        accountCode: {
          en: 'Acc. number',
          de: 'Kontonummer',
        },
        accountName: {
          en: 'Name',
          de: 'Kontoname',
        },
        balance: {
          en: 'Balance',
          de: 'Saldo',
        },
        apply: {
          en: 'Apply',
          de: 'Umlagefähig',
        },
        ecoPlan: {
          en: 'Eco plan',
          de: 'Wirts.-plan',
        },
        opsCost: {
          en: 'Ops cost',
          de: 'Betriebskosten',
        },
        settlement: {
          en: 'Settlement',
          de: 'Abrechnung',
        },
        unitsExcluded: {
          en: 'Units excluded',
          de: 'Einheiten ausgeschlossen',
        },
        wkaRelevant: {
          en: 'WKA relevant',
          de: 'WKA relevant',
        },
        notWkaRelevant: {
          en: 'Not WKA relevant',
          de: 'Nicht WKA relevant',
        },
      },
    },
    copyDistributionKeysDropMenu: {
      en: 'Copy Distribution Keys',
      de: 'Verteilerschlüssel kopieren',
    },
    copyModal: {
      title: {
        en: 'Copy yearly Distribution Keys',
        de: 'Alle Verteilerschlüssel kopieren',
      },
      subtitle: {
        en: 'Yearly Distribution keys will be copied to the HGA, EP and Ops cost of the selected destination.',
        de: 'Jährliche Verteilerschlüssel kopieren für HGA, WP und Betriebskosten.',
      },
      button: {
        en: 'Create a copy',
        de: 'Kopie erstellen',
      },
      source: {
        en: 'Source',
        de: 'Von',
      },
      destination: {
        en: 'Destination',
        de: 'Zu',
      },
      warningMessage: {
        en: 'In case you already created Distribution Keys for the year you are copying in, they will be overwritten. Are you sure you want to continue?',
        de: 'Falls für das Jahr bereits Verteilerschlüssel hinterlegt wurden, in welches Sie die Verteilerschlüssel kopieren, werden diese überschrieben. Möchten Sie dennoch fortfahren?',
      },
    },
    individualCopyModal: {
      title: {
        en: 'Copy Distribution Key',
        de: 'Verteilerschlüssel kopieren',
      },
      destinationAccounts: {
        en: 'Destination accounts',
        de: 'Zu den Konten',
      },
      destinationYears: {
        en: 'Destination years',
        de: 'Zu den Jahren',
      },
      selectPlaceholder: {
        en: 'Select...',
        de: 'Wählen...',
      },
      target: {
        en: 'Target',
        de: 'Anwenden auf',
      },
      ep: {
        en: 'EP',
        de: 'WP',
      },
      hga: {
        en: 'HGA',
        de: 'HGA',
      },
      opsCost: {
        en: 'Ops cost',
        de: 'Betriebskosten',
      },
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load accounts.',
          de: 'Konten können nicht geladen werden.',
        },
      },
      warningMessage: {
        en: 'Distribution Keys that already exist for the target year(s) and account(s) you are copying in will be overwritten. Are you sure you want to continue?',
        de: 'Bestehen bereits Verteilerschlüssel für die gewählten Jahre und Konten, in welche Sie kopieren, werden diese überschrieben. Möchten Sie dennoch fortfahren?',
      },
    },
    copySuccess: {
      en: 'Distribution keys copied successfully',
      de: 'Verteilerschlüssel erfolgreich kopiert',
    },
    copyError: {
      en: 'Distribution key copy failed',
      de: 'Verteilerschlüssel kopieren fehlgeschlagen',
    },
    copyInProgress: {
      en: 'Distribution keys copy in progress',
      de: 'Verteilerschlüssel kopieren gestartet',
    },
  },
  propertyDistributionKeyListContext: {
    loadError: {
      en: 'Failed to load some distribution keys',
      de: 'Einige Verteilungsschlüssel konnten nicht geladen werden',
    },
    saveMessage: {
      en: 'Distribution key could not be saved.',
      de: 'Verteilerschlüssel konnte nicht gespeichert werden.',
    },
    saveTagError: {
      en: 'Failed to mark account as WKA relevant',
      de: 'Konto konnte nicht als WKA-relevant markiert werden',
    },
    yearWithDKsloadError: {
      en: 'Cannot load years with distribution keys.',
      de: 'Jahre mit Verteilungsschlüsseln können nicht geladen werden.',
    },
    saveError: {
      message: {
        en: 'Distribution key could not be saved.',
        de: 'Verteilerschlüssel konnte nicht gespeichert werden.',
      },
    },
  },
  enums: {
    distributionMode: {
      MEA: {
        en: 'MEA',
        de: 'MEA',
      },
      UNITS: {
        en: 'Units',
        de: 'Einheiten',
      },
      PERSONS: {
        en: 'People',
        de: 'Personen',
      },
      HEATING_AREA: {
        en: 'Heating area',
        de: 'Heizfläche',
      },
      AREA: {
        en: 'Area',
        de: 'Fläche',
      },
      INDIVIDUAL: {
        en: 'Individual',
        de: 'Individuell',
      },
      DISTRIBUTION_VALUE: {
        en: 'Distribution value',
        de: 'Verteilungswert',
      },
      HEATING_COST: {
        en: 'Heating cost',
        de: 'lt. Heizkostenabr.',
      },
      DIRECT_COST: {
        en: 'Direct cost',
        de: 'Direkte Kosten',
      },
      UNDEFINED: {
        en: 'Undefined',
        de: 'Nicht definiert',
      },
    },
  },
};
