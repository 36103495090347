import { OpsCostReportProjection } from 'api/accounting';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import { OpsCostReportListContextProvider } from './service/OpsCostReportListContext';
import { useOpsCostReportColumns } from './service/useOpsCostReportColumns';
import useOpsCostReportList from './service/useOpsCostReportList';
import { useOpsCostReportListFilter } from './service/useOpsCostReportListFilters';
import { useOpsCostReportListSort } from './service/useOpsCostReportListSort';
import './OpsCostReportList.scss';
import CreateOpsCostReportButton from './components/CreateOpsCostReportButton';

const OpsCostReportList = (): JSX.Element => {
  const {
    opsCostReportList, loadMoreIfOverlayOnTop, onOpenOpsCostReportView,
  } = useOpsCostReportList();

  const { sortField, sortOrder, onChangeSort } = useOpsCostReportListSort();

  const {
    filterState, onChangeFilterState, filters, onSetDefaultFilterFromQueryParams,
  } = useOpsCostReportListFilter();

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'opsCostReport',
    navItems: subcategorySwitcherItems.accounting,
  };

  useGlobalSearch({
    key: 'opsCostReport',
    filterProps: {
      availableFilters: filters,
      setFilter: onChangeFilterState,
    },
    queryParamAsFilter: { filterState, onSetDefaultFilterFromQueryParams },
  });

  const opsCostReportTable = useSmartTable({
    tableName: 'OpsCostReportListTable',
    columns: useOpsCostReportColumns(),
    dataSource: opsCostReportList.data,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => onChangeSort(dataKey as keyof OpsCostReportProjection),
    },
    contentLoading: opsCostReportList.loading,
    infiniteScrollerProps: {
      hasMoreData: !opsCostReportList.lastPage,
      loadMoreData: loadMoreIfOverlayOnTop,
    },
    rowKey: 'id',
    onRow: onOpenOpsCostReportView,
  });


  return (
    <div className="OpsCostReportList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={<CreateOpsCostReportButton />}
      />
      <SmartTable {...opsCostReportTable} />
    </div>
  );
};

const OpsCostReportListWithProvider = () => (
  <OpsCostReportListContextProvider>
    <OpsCostReportList />
  </OpsCostReportListContextProvider>
);

export default OpsCostReportListWithProvider;
