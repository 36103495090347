
import {
  Configuration, DocumentLegacyControllerApi, DocumentCreateDtoSourceTypeEnum, DocumentUpdateLegacyDtoDocumentStateEnum, DocumentUpdateLegacyDtoSourceTypeEnum, TemplateRenderDto,
  PdfDataCreateDto,
} from 'api/document';
import { showNotification } from 'lib/Notification';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatDate } from 'lib/Utils';
import { AuthContext } from 'contexts/AuthContext';
import moment from 'moment';
import { usePrintCss } from 'services/print/usePrintCss';
import { usePrintHtml } from 'services/print/usePrintHtml';
import { isNil } from 'lodash';
import { useActivePropertyOwnerContracts } from 'services/PropertyOwnerContractsList/useActivePropertyOwnerContracts';
import { useRecipientAddressLines } from 'services/useRecipientAddressLines';
import { ProfitAndLossReportContext } from '../../services/ProfitAndLossReportContext';
import { translations } from '../../../translations';
import { useResultTable } from '../ResultSection/components/useResultTable';

export const useCreateProfitAndLossReport = () => {
  const history = useHistory();
  const { tl } = useContext(LanguageContext);
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const [loading, setLoading] = useState(false);
  const { getPrintCss } = usePrintCss();


  const { apiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as Configuration);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useCreateProfitAndLossReport must be used within a ProfitAndLossReportContextProvider');
  }

  const urlParams = useParams<{ documentDbId: string }>();
  const documentDbId = parseInt(urlParams.documentDbId, 10);

  const { reportData } = profitAndLossReportContext;
  const { getPrintHtml } = usePrintHtml();
  const { thirdSum } = useResultTable();


  const handleError = (err) => {
    setLoading(false);
    console.error(err);
    showNotification({
      type: 'error',
      message: tl(translations.report.notifications.errorCreatingReport),
    });
  };

  const redirectToView = ({ docId }: { docId: number }) => {
    setLoading(false);
    history.push(`/profit-and-loss/${docId}/view`);
  };


  const createDocument = () => {
    const documentData = {
      propertyId: reportData.property.id,
      name: reportData.documentName,
      issuedDate: formatDate(moment(), 'YYYY-MM-DD'),
      amount: thirdSum,
      metaData: JSON.stringify({
        startDate: formatDate(reportData.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(reportData.endDate, 'YYYY-MM-DD'),
      }),
    };
    if (documentDbId) {
      // document exists => update document;
      return documentControllerApi.getDocumentByIdUsingGET({ documentId: documentDbId })
        .then(document => documentControllerApi.updateDocumentsUsingPUT({
          updateDtos: [{
            id: documentDbId,
            sourceType: DocumentUpdateLegacyDtoSourceTypeEnum.PROFIT_AND_LOSS,
            documentState: DocumentUpdateLegacyDtoDocumentStateEnum.DRAFT,
            tagIds: document.tags?.filter(tag => !isNil(tag.id)).map(tag => tag.id) || [],
            ...documentData,
          }],
        }))
        .then(response => Promise.resolve({ docId: response[0]?.id }));
    }

    // new document => create document
    return documentControllerApi.createDocumentsUsingPOST({
      documentCreateDtos: [{
        sourceType: DocumentCreateDtoSourceTypeEnum.PROFIT_AND_LOSS,
        ...documentData,
      }],
    }).then(response => Promise.resolve({ docId: response[0]?.id }));
  };

  const { activePropertyOwnerContracts } = useActivePropertyOwnerContracts(reportData.property?.id);
  const recipientAddressLines = useRecipientAddressLines(activePropertyOwnerContracts?.[0]?.mailingContact?.contactId);

  const getPdfData = () => getPrintCss().then(css => ({
    convertToPdfA: true,
    displayHeaderFooter: true,
    css,
    html: getPrintHtml(true),
    withPageNumbering: false,
    useLegacyPdfRenderer: false,
    address: recipientAddressLines.join(' '),
  }));

  const savePdfDataOnDocument = ({ docId }: { docId: number }) => getPdfData()
    .then((pdfData) => {
      const pdfDataCreateDtos: Array<PdfDataCreateDto> = [{
        documentId: docId,
        pdfDataJsonString: JSON.stringify(pdfData),
      }];

      return documentControllerApi
        .saveDocumentsForFileGenerationUsingPUT({ pdfDataCreateDtos })
        .then(response => Promise.resolve({ docId: response[0]?.id }));
    });


  const onCreate = () => {
    setLoading(true);

    createDocument()
      .then(savePdfDataOnDocument)
      .then(redirectToView)
      .catch(handleError);
  };

  return {
    onCreateReport: onCreate,
    loading,
  };
};
