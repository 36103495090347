export const pastBookingsTranslations = {
  columns: {
    bookingDate: {
      en: 'Booking date',
      de: 'Buchungsdatum',
    },
    bookingTitle: {
      en: 'Booking',
      de: 'Buchung',
    },
    bankOrders: {
      en: 'Bank orders',
      de: 'Bankaufträge',
    },
  },
  noBankOrders: {
    en: 'No Bank Order',
    de: 'Keine Bankaufträge',
  },
  houseMoney: {
    en: 'House Money',
    de: 'Hausgeld',
  },
};
