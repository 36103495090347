import { RouteDefinition } from 'components/RenderRoutes';
import { lazy } from 'react';
import DocumentList from './DocumentList/DocumentList';

const DocumentEditor = lazy(() => import('pages/Document/DocumentEditor/DocumentEditor'));

export const DOCUMENT_EDITOR_PATH = '/documents/:documentDbId';

export const getDocumentEditorRoute = (documentDbId: string | number) => (
  DOCUMENT_EDITOR_PATH
    .replace(':documentDbId', `${documentDbId}`)
);

export const DocumentRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/documents',
      component: DocumentList,
      strict: true,
      subRoutes: [{
        path: DOCUMENT_EDITOR_PATH,
        component: DocumentEditor,
        type: 'overlay',
      }],
    },
  ],
};
