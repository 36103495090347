import React, { useContext, useMemo, useRef } from 'react';
import { Checkbox } from 'antd';
import { formatCurrency, formatDate } from 'lib/Utils';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';

export const usePropertiesWithInsufficientFundsColumns = () => {
  const { tl, language } = useContext(LanguageContext);

  const columns = useMemo(() => [
    {
      title: tl(translations.pages.payment.executionModal.propertyName),
      dataKey: 'propertyName',
      dataIndex: 'propertyName',
    },
    {
      title: tl(translations.pages.payment.executionModal.iban),
      dataIndex: 'iban',
    },
    {
      title: tl(translations.pages.payment.executionModal.bankAccountBalance),
      dataIndex: 'bankAccountBalance',
      render: (value: number) => `${formatCurrency(value)}`,
      width: '12.0rem',
    },
    {
      title: tl(translations.pages.payment.executionModal.amount),
      dataIndex: 'totalAmountOfTransactions',
      width: '12.0rem',
      render: (value: number) => formatCurrency(value),
    },
    {
      title: tl(translations.pages.payment.executionModal.updated),
      dataIndex: 'lastConnectionUpdate',
      render: updated => formatDate(updated, 'DD.MM.YYYY'),
    },
  ], [language]);

  return columns;
};
