import _ from 'lodash';
import { anyMatch, createFieldRegExp } from '../../lib/Utils';

const INDEX = '\\[.+\\]\\.';

const ownersMeetingSectionFieldsMap: any = {
  1: [
    `properties${INDEX}propertyId`,
    'properties',
    'subject',
    'body',
  ],
  2: [],
};

const getOwnersMeetingSectionFieldsMap = (savingSectionNumber: number) => {
  const fields: string[] = ownersMeetingSectionFieldsMap[savingSectionNumber];

  return fields.map((field: string) => createFieldRegExp(field));
};

export const getSectionErrors = (sectionNumber: number, errors: any, numberOfProperties: number) => {
  const fields = getOwnersMeetingSectionFieldsMap(sectionNumber);
  const sectionErrors: any = {};

  Object.keys(errors)
    .filter((key: string) => anyMatch(fields, key))
    .forEach((key: string) => {
      // when properties is empty and nothing has been selected
      if (key === 'properties' || (numberOfProperties > 0 && _.isEmpty(errors.entity.properties))) {
        sectionErrors['properties[0].propertyId'] = errors[key];
      }
      // when property input fields have been added but no property has been selected
      if (numberOfProperties > 0 && !_.isEmpty(errors.entity.properties)) {
        for (let i = 0; i < numberOfProperties; i += 1) {
          if (!errors.entity.properties[i]) {
            sectionErrors[`properties[${i}].propertyId`] = errors[key];
          }
        }
      }
      sectionErrors[key] = errors[key];
    });

  return sectionErrors;
};
