import React from 'react';
import moment from 'moment';
import './TimeInputField.scss';
import _ from 'lodash';

interface TimeInputFieldProps {
  className?: string,
  disabled?: boolean,
  hour: number,
  minute: number,
  onChange: (value: moment.Moment) => void
}


export default function TimeInputField(props: TimeInputFieldProps): JSX.Element {
  const {
    minute, hour, onChange, className, disabled,
  } = props;

  const getValidValue = (numberValue: number, limit: number) => {
    if (numberValue < 0) return 0;
    if (numberValue > limit) return limit;
    return numberValue;
  };

  const onDeleteTime = () => {
    onChange(undefined);
  };

  const onChangeHour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if ((_.isNil(value) || _.isEmpty(value) || value === '0') && (_.isNil(minute) || minute === 0)) {
      // if both hour and minute is 0
      onDeleteTime();
    } else {
      onChange(moment(`${getValidValue(Number(value), 23)}:${minute}`, 'HH:mm'));
    }
  };


  const onChangeMinute = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if ((_.isNil(value) || _.isEmpty(value) || value === '0') && (_.isNil(hour) || hour === 0)) {
      // if both hour and minute is 0
      onDeleteTime();
    } else {
      onChange(moment(`${hour}:${getValidValue(Number(value), 59)}`, 'HH:mm'));
    }
  };

  const formatter = (value: number) => value.toString().padStart(2, '0');

  return (
    <div className={`TimeInputField ${className} ${disabled ? 'disabled' : ''}`}>
      <input
        type="number"
        max={23}
        min={0}
        onChange={onChangeHour}
        value={formatter(hour)}
        disabled={disabled}
        onFocus={e => e.target.select()}
      />
      <span>:</span>
      <input
        type="number"
        max={59}
        min={0}
        onChange={onChangeMinute}
        value={formatter(minute)}
        disabled={disabled}
        onFocus={e => e.target.select()}
      />
    </div>
  );
}
