import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

interface QueryParamsAsDefaultFilterProps {
  onSetDefaultFilterFromQueryParams: (searchParams: { [key: string]: any }) => void,
  fallbackDefaulFilters: any,
  syncSearchBarAndQueryParams?: boolean,
}

export default function useQueryParamsAsDefaultFilter({ onSetDefaultFilterFromQueryParams, fallbackDefaulFilters, syncSearchBarAndQueryParams }: QueryParamsAsDefaultFilterProps): any {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [defaultFilters, setDefaultFilters] = useState({});

  useEffect(() => {
    if (!onSetDefaultFilterFromQueryParams) return;

    if (!syncSearchBarAndQueryParams) {
      history.replace(pathname);
    }

    let searchParams = {};
    new URLSearchParams(search).forEach((v, k) => { searchParams[k] = v ? parseJSON(decodeURIComponent(v)) : undefined; });
    searchParams = Object.keys(searchParams).length === 0 ? fallbackDefaulFilters : searchParams;
    setDefaultFilters(searchParams);
    onSetDefaultFilterFromQueryParams(searchParams);
  }, []);

  const parseJSON = (rawJson: any): any => {
    try {
      return JSON.parse(rawJson);
    } catch (err) {
      return rawJson;
    }
  };

  return {
    defaultFilters,
  };
}
