import { useContext } from 'react';

import {
  usePropertyAdministrationTypeFilterOption,
} from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';

import { DirectDebitFilterDtoStatesEnum } from '../../api/accounting';
import {
  Filter,
} from '../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';
import { IBAN_MOD97_PATTERN } from '../../lib/Utils';

export default function useDirectDebitFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);
  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  return [
    {
      type: 'enum',
      name: tl(translations.pages.directDebitList.table.headers.state),
      key: 'states',
      options: Object.values(DirectDebitFilterDtoStatesEnum).map(value => ({
        label: tl(translations.pages.payment.table.paymentStates[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'date',
      name: tl(translations.pages.directDebitList.filter.minExecutionDate),
      key: 'minExecutionDate',
    },
    {
      type: 'date',
      name: tl(translations.pages.directDebitList.filter.maxExecutionDate),
      key: 'maxExecutionDate',
    },
    {
      type: 'decimal',
      name: tl(translations.pages.directDebitList.filter.minAmount),
      key: 'minAmount',
    },
    {
      type: 'decimal',
      name: tl(translations.pages.directDebitList.filter.maxAmount),
      key: 'maxAmount',
    },
    {
      type: 'decimal',
      name: tl(translations.pages.directDebitList.filter.amount),
      key: 'amount',
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.businessPartnerName),
      key: 'counterpartName',
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.businessPartnerIban),
      key: 'counterpartIban',
      matcher: IBAN_MOD97_PATTERN,
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.statement),
      key: 'statement',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.filter.propertyIdInternalList),
      key: 'propertyIdInternalList',
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.propertyName),
      key: 'propertyName',
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.propertyIban),
      key: 'propertyIban',
      matcher: IBAN_MOD97_PATTERN,
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.propertyBic),
      key: 'propertyBic',
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.propertyBankCode),
      key: 'propertyIban',
    },
    {
      type: 'text',
      name: tl(translations.pages.directDebitList.table.headers.propertyAccountInstitute),
      key: 'propertyAccountInstitute',
    },
    administrationTypeFilterOption,
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.accountant),
      key: 'accountantName',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.administrator),
      key: 'supervisorName',
    },
    {
      type: 'text',
      name: tl(translations.pages.payment.table.headers.createdBy),
      key: 'createdBy',
    },
  ];
}
