import React, { useContext } from 'react';
import { ICONS } from 'components/icons';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import defaultSection from './defaultSection';
import { translations } from '../../../../../elements/Translation/translations';
import BankInformation from '../components/BankInformation';

export const useBankInformationSection = (accounts: any) => {
  const { tl } = useContext(LanguageContext);

  return {
    ...defaultSection(1),
    sectionIcon: ICONS.bankInformationSection,
    sectionTitle: tl(translations.pages.contactDetails.bankInformation.title),
    sectionId: 'BankInformationSection',
    onChange: () => { },
    content: [
      {
        content:
            (<BankInformation
              bankAccounts={accounts}
            />),
      },
    ],
  };
};
