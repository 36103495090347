import { formatCurrency } from 'lib/Utils';
import React from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import './UnitAllocationParentRowAmountCell.scss';

type Props = {
  label: string,
  amount: number,
}

const UnitAllocationParentRowAmountCell = ({ label, amount }: Props) => (
  <span className="UnitAllocationParentRowAmountCell">
    {`${label}: `}
    <Amount>{formatCurrency(amount)}</Amount>
  </span>
);

export default UnitAllocationParentRowAmountCell;
