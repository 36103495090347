import React, { useContext } from 'react';
import { Col, Modal, Row } from 'antd';
import { SPACE_SIZES } from 'lib/constants';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import { LanguageContext } from 'contexts/LanguageContext';
import { useAllocationLaborCostModal } from './useAllocationLaborCostModal';
import { translations } from '../../../../../../../../../translations';

interface Props {
  recordKey: string;
  visible: boolean;
  onClose: () => void;
}

const AllocationLaborCostModal = ({ recordKey, onClose, visible }: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    laborCostModal,
  } = translations.bankTransactionAllocation.serviceCompanyAllocation;

  const {
    laborCostTypeOptions,
    onChangeLaborCostAmount,
    onChangeLaborCostType,
    onApply,
    onCancel,
    laborCostAmount,
    laborCostType,
    validationErrors,
  } = useAllocationLaborCostModal(recordKey, onClose);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onApply}
      destroyOnClose
      title={tl(laborCostModal.title)}
      okButtonProps={{ className: 'Button' }}
      cancelButtonProps={{ className: 'Button' }}
    >
      <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.large]}>
        <Col span={24}>
          <SelectInput
            allowClear
            autoFocus
            label={tl(laborCostModal.laborCostType)}
            defaultValue={laborCostType}
            onChange={onChangeLaborCostType}
            options={laborCostTypeOptions}
            validationState={validationErrors.laborCostType?.state}
            validationMessage={validationErrors.laborCostType?.message}
          />
        </Col>
        <Col span={24}>
          <CurrencyInput
            label={tl(laborCostModal.laborCost)}
            defaultValue={laborCostAmount}
            min={0}
            onChange={onChangeLaborCostAmount}
            validationState={validationErrors.laborCostAmount?.state}
            validationMessage={validationErrors.laborCostAmount?.message}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AllocationLaborCostModal;
