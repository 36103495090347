import React, {
  useContext, useMemo, useRef, useState,
} from 'react';
import DEFAULT_DATA from '../lib/data';
import { ContactElement } from '../elements/CustomElements/ContactElement/ContactElement';
import { getContactLabelName } from '../pages/Property/PropertyEditing/util/utils';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { showNotification } from '../lib/Notification';
import { ContactLegacyDtoTypeEnum } from '../api/accounting/models';

const optionObjectFromContact = (contact: any) => {
  const label = (<ContactElement contact={contact} />);
  const simpleLabel = getContactLabelName(contact);
  return {
    value: contact.id, label, simpleLabel, ...contact,
  };
};


export default function useContactOptions(contactType?: ContactLegacyDtoTypeEnum[]) {
  const { tl } = useContext(LanguageContext);
  const [contacts, setContacts] = useState(DEFAULT_DATA<any>([]));
  const lastSearchedFilter = useRef<String | undefined>(undefined);

  const contactOptions = useMemo(() => contacts.data.map(optionObjectFromContact), [contacts]);
  const contactBankAccountMap = useMemo(() => contacts.data.reduce((map: any, obj: any) => {
    map[obj.id] = obj.bankAccounts?.map((ba: any) => ({
      key: ba.id,
      label: `${ba.accountHolderName}-${ba.iban}`,
      value: ba.id,
      iban: ba.iban,
      bic: ba.bic,
      accountHolderName: ba.accountHolderName,
    })) ?? [];
    return map;
  }, {}), [contacts]);
  const endpointUrl = useMemo(() => {
    if (!contactType) {
      return `${endpointUrls.CONTACT}/search`;
    }
    return `${endpointUrls.CONTACT}/search/type`;
  }, [contactType]);

  const searchForContact = (filter: string) => {
    lastSearchedFilter.current = filter;
    setContacts(state => state.startLoading());
    const params: { filter: string, page: number, types?: ContactLegacyDtoTypeEnum[] } = {
      filter,
      page: 0,
    };
    if (contactType) {
      params.types = contactType;
    }
    backend.get(`${endpointUrl}`, params)
      .then((response: any) => {
        if (filter === lastSearchedFilter.current) {
          setContacts(state => state.load(response.content));
        }
      })
      .catch((error) => {
        console.error(error);
        setContacts(state => state.failed());
        showNotification({
          key: 'loadContactsError',
          message: tl(translations.notifications.contactListContext.loadError.message),
          description: tl(translations.notifications.contactListContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onLoadContact = (contactId: number): void => {
    if (contacts.data.filter((co: any) => co.id === contactId).length > 0) return;
    setContacts(state => state.startLoading());
    backend.get(`${endpointUrls.CONTACT}/${contactId}`, {})
      .then((response: any) => {
        if (contacts.data.filter((co: any) => co.id === contactId).length > 0) return;
        setContacts(state => state.load(state.data.concat(response)));
      })
      .catch((error) => {
        console.error(error);
        setContacts(state => state.failed());
        showNotification({
          key: 'loadContactError',
          message: tl(translations.notifications.contactEditingContext.loadError.message),
          description: tl(translations.notifications.contactEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const getContact = (contactId: number) => {
    const contact = contacts.data.filter((co: any) => co.id === contactId);
    if (contact.length > 0) return contact[0];
    return null;
  };

  return {
    contactOptions,
    searchForContact,
    onLoadContact,
    getContact,
    contactBankAccountMap,
  };
}
