import { ContractProjectionDto, PropertyDisplayDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  ReactNode, createContext, useContext, useState,
} from 'react';

const ContractGroupWithPropertiesContext = createContext<|
  {
    contractGroup: DefaultDataInterface<ContractProjectionDto[]>,
    setContractGroup: React.Dispatch<React.SetStateAction<DefaultDataInterface<ContractProjectionDto[]>>>,
    allPropertiesFromContractGroup: DefaultDataInterface<PropertyDisplayDto[]>,
    setAllPropertiesFromContractGroup: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyDisplayDto[]>>>,
  } | undefined>(undefined);

export const useContractGroupWithPropertiesContext = (usageName: string) => {
  const contractGroupWithPropertiesContext = useContext(ContractGroupWithPropertiesContext);

  if (contractGroupWithPropertiesContext === undefined) {
    throw new Error(`${usageName} must be used within an ContractGroupWithPropertiesContext`);
  }

  return contractGroupWithPropertiesContext;
};


const ContractGroupWithPropertiesContextProvider = ({ children }: { children: ReactNode }) => {
  const [contractGroup, setContractGroup] = useState(DEFAULT_DATA<ContractProjectionDto[]>([]));
  const [allPropertiesFromContractGroup, setAllPropertiesFromContractGroup] = useState(DEFAULT_DATA<PropertyDisplayDto[]>([]));

  return (
    <ContractGroupWithPropertiesContext.Provider value={{
      contractGroup, setContractGroup, allPropertiesFromContractGroup, setAllPropertiesFromContractGroup,
    }}
    >
      {children}
    </ContractGroupWithPropertiesContext.Provider>
  );
};

export default ContractGroupWithPropertiesContextProvider;
