import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  PropertyLegacyControllerApi,
  PropertyDisplayDto,
  PropertyDisplayDtoAdministrationTypeEnum,
  ResolutionRecordControllerApi,
  ResolutionRecordDto,
  ResolutionRecordDtoMeetingTypeEnum,
  ResolutionRecordDtoResolutionRecordStatusEnum,
  ResolutionRecordDtoVoteKeyEnum,
  ResolutionRecordDtoVoteMajorityKeyEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';

import { IRRProgress } from '../interfaces';
import { loadCsv } from '../services/loadCsv';
import { executeInParallelBatch } from '../utils/executeInParallelBatch';
import { translations } from './translations';

const RESOLUTION_RECORD_STATUS = {
  Angenommen: ResolutionRecordDtoResolutionRecordStatusEnum.ACCEPTED,
  Abgelehnt: ResolutionRecordDtoResolutionRecordStatusEnum.DECLINED,
  Angefochten: ResolutionRecordDtoResolutionRecordStatusEnum.CHALLENGED,
  Bestandskräftig: ResolutionRecordDtoResolutionRecordStatusEnum.LEGAL_VALID,
  Rechtskräftig: ResolutionRecordDtoResolutionRecordStatusEnum.LEGAL_DECIDED,
};

const MEETING_TYPE = {
  'Ordentliche ETV': ResolutionRecordDtoMeetingTypeEnum.REGULAR,
  'Außerordentliche ETV': ResolutionRecordDtoMeetingTypeEnum.EXTRAORDINARY,
  Umlaufbeschluss: ResolutionRecordDtoMeetingTypeEnum.CIRCULAR_RESOLUTION,
};

const VOTE_KEY = {
  MEA: ResolutionRecordDtoVoteKeyEnum.MEA,
  Kopfprinzip: ResolutionRecordDtoVoteKeyEnum.HEAD,
  Einheitsprinzip: ResolutionRecordDtoVoteKeyEnum.UNIT,
};

const VOTE_MAJORITY_KEY = {
  'Einfache Mehrheit': ResolutionRecordDtoVoteMajorityKeyEnum.QUALIFIED_MAJORITY,
  'Qualifizierte Mehrheit': ResolutionRecordDtoVoteMajorityKeyEnum.ATTENDING_UNANIMOUS_MAJORITY,
  Einstimmigkeit: ResolutionRecordDtoVoteMajorityKeyEnum.ABSOLUTE_MAJORITY,
  Allstimmigkeit: ResolutionRecordDtoVoteMajorityKeyEnum.UNANIMOUS_MAJORITY,
};

const mapRowToResolutionRecordDto = (csvRow: any): ResolutionRecordDto => ({
  propertyId: csvRow.propertyInternalId,
  resolutionRecordNumber: csvRow.resolutionRecordNumber,
  resolutionRecordStatus: RESOLUTION_RECORD_STATUS[csvRow.resolutionRecordStatus],
  resolutionTitle: csvRow.resolutionTitle,
  resolutionText: csvRow.resolutionText,
  agendaItem: csvRow.agendaItem,
  date: csvRow.date,
  meetingType: MEETING_TYPE[csvRow.meetingType],
  location: csvRow.location,
  voteKey: VOTE_KEY[csvRow.voteKey],
  voteMajorityKey: VOTE_MAJORITY_KEY[csvRow.voteMajorityKey],
  numberOfParticipant: parseFloat(csvRow.numberOfParticipants.replace('.', '').replace(',', '.')),
  presentVotes: parseFloat(csvRow.presentVotes.replace('.', '').replace(',', '.')),
  yesVotes: parseFloat(csvRow.yesVotes.replace('.', '').replace(',', '.')),
  noVotes: parseFloat(csvRow.noVotes.replace('.', '').replace(',', '.')),
  abstentionVotes: parseFloat(csvRow.abstentionVotes.replace('.', '').replace(',', '.')),
  manager: csvRow.manager,
  meetingLeader: csvRow.meetingLeader,
  minuteTaker: csvRow.minuteTaker,
});

export const useResolutionRecordsCsv = () => {
  const [properties, setProperties] = useState<PropertyDisplayDto[]>([]);
  const [resolutionRecords, setResolutionRecords] = useState<ResolutionRecordDto[]>([]);
  const [resolutionRecordValidationIssues, setResolutionRecordValidationIssues] = useState<any[]>([]);
  const [progress, setProgress] = useState<IRRProgress>({
    started: false, finished: false, rrsImported: 0, importedIds: [],
  });
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const resolutionRecordController = new ResolutionRecordControllerApi(apiConfiguration('accounting'));
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));


  useEffect(() => {
    propertyControllerApi.findFilteredPropertiesUsingGET({ page: 0, size: 200 })
      .then((response) => {
        setProperties(response.content.filter(p => p.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG));
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          key: 'loadPropertyListError',
          message: tl(translations.notifications.propertyLoadError),
          type: 'error',
        });
      });
  }, []);

  const loadResolutionRecordsFromCsvs = (csvRows: any[]) => {
    const readResolutionRecords: ResolutionRecordDto[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach((csvRow) => {
      const resolutionRecord = mapRowToResolutionRecordDto(csvRow);
      resolutionRecord.propertyId = properties.find(p => p.propertyIdInternal === resolutionRecord.propertyId as unknown as string)?.id;
      readResolutionRecords.push(resolutionRecord);
      if (!resolutionRecord.propertyId) {
        validationIssues.push({ message: tl(translations.validations.issues.propertyIdInternal), row: csvRow._row });
      }
      if (!resolutionRecord.resolutionRecordNumber) {
        validationIssues.push({ message: tl(translations.validations.issues.resolutionRecordNumber), row: csvRow._row });
      }
      if (!resolutionRecord.resolutionRecordStatus) {
        validationIssues.push({ message: tl(translations.validations.issues.resolutionRecordStatus), row: csvRow._row });
      }
      if (!resolutionRecord.resolutionTitle) {
        validationIssues.push({ message: tl(translations.validations.issues.resolutionTitle), row: csvRow._row });
      }
      if (!resolutionRecord.resolutionText) {
        validationIssues.push({ message: tl(translations.validations.issues.resolutionText), row: csvRow._row });
      }
      if (!resolutionRecord.date) {
        validationIssues.push({ message: tl(translations.validations.issues.date), row: csvRow._row });
      }
    });
    setResolutionRecordValidationIssues(validationIssues);
    if (validationIssues.length) {
      setResolutionRecords([]);
    } else {
      setResolutionRecords(readResolutionRecords);
    }
  };

  const loadCsvFile = (event) => {
    loadCsv(event.target.files, 0)
      .then(res => loadResolutionRecordsFromCsvs(res))
      .catch((err) => {
        showNotification({ message: tl(translations.validations.errors), type: 'error' });
        console.error('Error while loading csv', err);
      });
  };

  const importRRs = async () => {
    setProgress({
      started: true,
      finished: false,
      importedIds: [],
      rrsImported: 0,
    });
    try {
      await importResolutionRecords();
      setProgress(prev => ({ ...prev, finished: true }));
    } catch (e) {
      console.error('Import stopped', e, e.response);
      showNotification({
        key: 'importValidation-error',
        duration: 0,
        message: tl(translations.notifications.error.title),
        description: tl(translations.notifications.error.description),
        type: 'error',
      });
      setProgress(prev => ({ ...prev, finished: true }));
    }
  };

  const importResolutionRecords = async () => {
    await executeInParallelBatch(resolutionRecords, 1, async (resolutionRecord: ResolutionRecordDto) => {
      const savedRR = await resolutionRecordController.createResolutionRecordUsingPOST({ resolutionRecord });
      setProgress(prev => ({ ...prev, rrsImported: prev.rrsImported + 1, importedIds: prev.importedIds.concat([savedRR.id]) }));
    });
  };

  const deleteAllImported = async () => {
    await executeInParallelBatch(progress.importedIds, 1, async (id: number) => {
      try {
        await resolutionRecordController.deleteResolutionRecordUsingDELETE({ id });
      } catch (e) {
        console.warn(e);
      }
    });
    setProgress(prev => ({ ...prev, started: false }));
  };

  const percentage: number = useMemo(() => Math.round(progress.rrsImported * 100 / Math.max(resolutionRecords.length, 1)), [progress, resolutionRecords]);

  return {
    loadCsvFile,
    importRRs,
    deleteAllImported,
    resolutionRecords,
    importFinished: progress.finished && progress.importedIds.length > 0,
    importStarted: progress.started,
    percentage,
    validationIssues: resolutionRecordValidationIssues,
  };
};
