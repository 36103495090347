/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorCodeDetailsDto,
} from '../models';
import {
    ErrorCodeDetailsDtoFromJSON,
    ErrorCodeDetailsDtoToJSON,
} from '../models';

export interface ErrorCodeUsingGETRequest {
    value: ErrorCodeUsingGETValueEnum;
}

/**
 * 
 */
export class ErrorCodeApi extends runtime.BaseAPI {

    /**
     * Retrieves the description of a specific error code
     * Get details of a specific error code
     */
    async errorCodeUsingGETRaw(requestParameters: ErrorCodeUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ErrorCodeDetailsDto>> {
        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value','Required parameter requestParameters.value was null or undefined when calling errorCodeUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/error-codes/{value}`.replace(`{${"value"}}`, encodeURIComponent(String(requestParameters.value))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorCodeDetailsDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves the description of a specific error code
     * Get details of a specific error code
     */
    async errorCodeUsingGET(requestParameters: ErrorCodeUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ErrorCodeDetailsDto> {
        const response = await this.errorCodeUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of all predefined error codes
     * Get all predefined error code values
     */
    async errorCodesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/error-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns a list of all predefined error codes
     * Get all predefined error code values
     */
    async errorCodesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.errorCodesUsingGETRaw(initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum ErrorCodeUsingGETValueEnum {
    BAD_CONTACT_ADDRESS = 'BAD_CONTACT_ADDRESS',
    BAD_TYPE_OF_EP = 'BAD_TYPE_OF_EP',
    BAD_STATE_OF_EP = 'BAD_STATE_OF_EP',
    DUPLICATE_START_DATE = 'DUPLICATE_START_DATE',
    MISSING_CORRECTION_BOOKING_DATE = 'MISSING_CORRECTION_BOOKING_DATE',
    INVALID_ACCOUNT_AMOUNTS = 'INVALID_ACCOUNT_AMOUNTS',
    INVALID_PROPERTY = 'INVALID_PROPERTY',
    NOT_FOUND = 'NOT_FOUND',
    MUST_REGENERATE_HGA = 'MUST_REGENERATE_HGA',
    FAILED_RETRIEVING_REQUIRED_DATA = 'FAILED_RETRIEVING_REQUIRED_DATA',
    WATERMARK_PDF_MUST_BE_ONE_PAGE = 'WATERMARK_PDF_MUST_BE_ONE_PAGE',
    DOCUMENT_DOCUMENT_IS_REFERENCED = 'DOCUMENT_DOCUMENT_IS_REFERENCED',
    DS_NAME_NOT_UNIQUE = 'DS_NAME_NOT_UNIQUE',
    DV_INVALID_START_END_DATE_MARKERS = 'DV_INVALID_START_END_DATE_MARKERS',
    DV_INVALID = 'DV_INVALID',
    USER_DOMAIN_ALREADY_EXISTS = 'USER_DOMAIN_ALREADY_EXISTS',
    USER_DOMAIN_REGISTRATION_DISABLED = 'USER_DOMAIN_REGISTRATION_DISABLED',
    COMMON_VALIDATION_INVALID_DATA = 'COMMON_VALIDATION_INVALID_DATA'
}
