import { ColumnType, TableComponents } from 'rc-table/lib/interface';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState, useEffect } from 'react';
import ResizeableColumn from './ResizableColumn';


/**
 * The shenanigans with Table.EXPAND_COLUMN and Table.SELECTION_COLUMN are needed because
 * antd exports these two columns as a constant object and internally checks for object equality,
 * so if the reference changes (e.g. we `.map` over it and return a new object), then the table
 * will not recognize it as this special column and it will not work.
 */


/**
 *
 * @param defaultColumns These columns, before passing it to this hook, must be memoized to avoid infinit re-rendering (See example {@link useUnitContractHistoryColumns} )
 * @param defaultComponents Before passing it to this hook, it must be memoized to avoid infinit re-rendering. See antdesign definition of Table's `components` prop
 * @returns Columns decorated with resizing option
 */
export function useResizableColumn <T>(defaultColumns: ColumnsType<T>, defaultComponents?: TableComponents<T> | undefined) {
  const [resizableColumns, setResizableColumns] = useState<ColumnsType< T>>([]);
  const [components, setComponents] = useState<TableComponents<T>>();

  useEffect(() => {
    setResizableColumns(decorateColumns(defaultColumns));
  }, [defaultColumns]);

  useEffect(() => {
    setComponents({ ...(defaultComponents ?? {}), header: { cell: ResizeableColumn } });
  }, [defaultComponents]);

  const handleResize = (dataKey: string) => (
    (e: MouseEvent, { size }: any) => (
      setResizableColumns(
        (oldColumns: ColumnsType<T>) => oldColumns.map((c: ColumnType<T>) => {
          if (Table.EXPAND_COLUMN === c || Table.SELECTION_COLUMN === c) return c;

          if (c.dataIndex?.toString() === dataKey.toString()) {
            return { ...c, width: size.width };
          }

          return c;
        }),
      )
    )
  );

  const decorateColumns = (cols: ColumnsType<T>): ColumnsType<T> => cols.map((col) => {
    if (Table.EXPAND_COLUMN === col || Table.SELECTION_COLUMN === col) return col;

    return {
      ...col,
      onHeaderCell: (c: any): any => ({
        width: c.width,
        onResize: handleResize(c.dataIndex),
      }),
    };
  });

  return {
    resizableColumns,
    components,
  };
}
