export interface ReportContextReturnType {
  setSelectedEconomicYear: (year: number) => void
  onLoadReportForProperty?: () => void
  onLoadReportForUnit?: () => void
  selectedUnitId: number | undefined | null
  selectedEconomicYear: number | undefined | null

  // this allows not specified props
  [x: string]: any
}

export const ReportContextDefaultReturn: ReportContextReturnType = {
  setSelectedEconomicYear: () => {
  },
  onLoadReportForProperty: () => {
  },
  onLoadReportForUnit: () => {
  },
  selectedEconomicYear: undefined,
  selectedUnitId: undefined,
};
