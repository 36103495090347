import React from 'react';
import SubcategorySwitcher, { SubcategorySwitcherProps } from './components/SubcategorySwitcher/SubcategorySwitcher';
import './MainPageHeader.scss';

export interface MainPageHeaderProps {
  subcategorySwitcherProps: SubcategorySwitcherProps,
  rightSideComponent?: JSX.Element
  className?: string,
}

export default function MainPageHeader(props: MainPageHeaderProps) {
  const { subcategorySwitcherProps, rightSideComponent, className } = props;

  return (
    <div className={`MainPageHeader ${className}`}>
      <SubcategorySwitcher selectedKey={subcategorySwitcherProps.selectedKey} navItems={subcategorySwitcherProps.navItems} />
      {rightSideComponent}
    </div>
  );
}

MainPageHeader.defaultProps = {
  rightSideComponent: undefined,
  className: '',
};
