import './TicketIntegrationSection.scss';

import {
  useContext,
  useEffect,
} from 'react';

import {
  Alert,
  Table,
} from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import TableContentLoadingIndicator
  from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import SearchBar from 'storybook-components/SearchBar/SearchBar';
import SecondarySectionWrapper
  from 'storybook-components/sections/SecondarySectionWrapper/SecondarySectionWrapper';

import Icon from '@ant-design/icons/lib/components/Icon';

import { CommentModal } from './components/CommentModal/CommentModal';
import { UnassignModal } from './components/UnassignModal/UnassignModal';
import { TicketIntegrationSectionProps } from './interfaces';
import { translations } from './translations';

export const TicketIntegrationSection = ({
  columns, datasource, loading, portal, onChangeSearchTerm, suffix = <></>, errorMessage, comment, setComment, commentModalVisible = false, unassignModalVisible = false, onCancelComment, onCancelUnassign, onCommentClickOk, onUnassignClickOk,
}: TicketIntegrationSectionProps) => {
  const { tl } = useContext(LanguageContext);

  // reset filter, reset order of items on unmount
  useEffect(() => (
    () => { onChangeSearchTerm(''); }
  ), []);


  const errorAlert = <Alert message={errorMessage} type="error" />;
  return (
    <SecondarySectionWrapper
      className="TicketIntegrationSection"
      subSectionSuffix={suffix}
      title={tl(translations.sectionTitle[portal])}
      icon={<Icon component={ICONS[portal]} />}
    >
      {errorMessage
        ? errorAlert
        : (
          <>
            <SearchBar
              onSearch={term => onChangeSearchTerm(term)}
              searchPlaceholder={tl(translations.searchPlaceholder)}
            />
            <Table
              columns={columns}
              dataSource={datasource}
              rowKey={issue => issue.id}
              loading={{
                spinning: loading,
                indicator: <TableContentLoadingIndicator />,
              }}
              scroll={{ y: 500 }}
              pagination={false}
            />
          </>
        )}
      <CommentModal
        visible={commentModalVisible}
        onCancel={onCancelComment}
        onOk={onCommentClickOk}
        app={portal}
        setComment={setComment}
        comment={comment}
      />
      <UnassignModal
        visible={unassignModalVisible}
        onCancel={onCancelUnassign}
        onOk={onUnassignClickOk}
        app={portal}
      />
    </SecondarySectionWrapper>
  );
};

TicketIntegrationSection.defaultProps = {
  suffix: <></>,
};
