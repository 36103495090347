import {
  GetVatRatesUsingGETCountryCodeEnum,
  VatRateControllerApi,
  VatRateDtoCountryCodeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import _ from 'lodash';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { useContext, useEffect } from 'react';
import { usePropertyVatRatesContext } from './PropertyVatRatesContext';

export const useInitializePropertyVatRates = ({ propertyHrIds, selectedBankTransactions } : { propertyHrIds: string[], selectedBankTransactions: ExtendedBankTransaction[] }) => {
  const { setPropertyVatRates, propertyVatRates } = usePropertyVatRatesContext('useInitializePropertyVatRates');

  const { apiConfiguration } = useContext(AuthContext);
  const vatRateControllerApi = new VatRateControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    // only load vat rates of property list also changed
    if (_.isEmpty(propertyHrIds) || (!_.isEmpty(propertyVatRates.data) && _.isEqual(propertyHrIds, propertyVatRates.data?.map(vr => vr.propertyHrId)))) return;
    initialize();
  }, [propertyHrIds]);

  const initialize = () => {
    setPropertyVatRates(prev => prev.startLoading());
    const uniqueVatRateCountryCodes = _.uniq(selectedBankTransactions[0]?.propertyList?.map((p => p.vatRateCountryCode))).filter(cc => cc !== undefined && cc !== null);
    const promises = uniqueVatRateCountryCodes?.map(cc => vatRateControllerApi.getVatRatesUsingGET({ countryCode: cc as unknown as GetVatRatesUsingGETCountryCodeEnum }));
    Promise.all(promises)
      .then((resp) => {
        const vatRates = _.flatMap(resp);
        setPropertyVatRates(prev => prev.load(selectedBankTransactions[0]?.propertyList?.map((p) => {
          const pvr = vatRates.find(vr => vr.countryCode === (p.vatRateCountryCode as unknown as VatRateDtoCountryCodeEnum));

          return {
            propertyId: p.propertyId,
            propertyHrId: p.propertyHrId,
            ...pvr,
          };
        })));
      })
      .catch((e) => {
        console.error('vatRateLoadError', e);
        setPropertyVatRates(prev => prev.failed());
      });
  };
};
