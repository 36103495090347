import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { AuthContext } from 'contexts/AuthContext';
import {
  Configuration, RoleControllerApi, RoleDto, UserControllerApi, UserDto,
} from 'api/user';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { showNotification } from 'lib/Notification';
import { userDetailsTranslations } from 'pages/Users/UserDetails/userDetailsTranslations';
import { PASSWORD_MATCHER, ValidateStatuses } from 'lib/Utils';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { useShowDeleteUserModal } from 'pages/Users/services/useShowDeleteUserModal';

export const useUserDetails = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const usersApi = new UserControllerApi(apiConfiguration('user') as unknown as Configuration);
  const rolesApi = new RoleControllerApi(apiConfiguration('user') as unknown as Configuration);
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<DefaultDataInterface<UserDto>>(DEFAULT_DATA({}));
  const [password, setPassword] = useState<string>('');
  const [roles, setRoles] = useState<DefaultDataInterface<RoleDto[]>>(DEFAULT_DATA([]));
  const { showDeleteUserModal } = useShowDeleteUserModal();

  useEffect(() => {
    loadUserRoles();
    loadUser(parseInt(userId, 10));
  }, [userId]);

  const loadUserRoles = () => {
    rolesApi.getRolesUsingGET()
      .then((response) => {
        setRoles(prev => prev.load(response));
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          key: 'loadRolesError',
          type: 'error',
          message: tl(userDetailsTranslations.notifications.loadRolesError),
        });
      });
  };

  const loadUser = (userIdParam: number) => {
    if (userIdParam) {
      setUser(prev => prev.startLoading());
      usersApi.getByIdUsingGET({ id: userIdParam })
        .then((response) => {
          setUser(prev => prev.load(response));
        })
        .catch((e) => {
          console.error(e);
          setUser(prev => prev.failed());
          showNotification({
            key: 'loadUserError',
            message: tl(userDetailsTranslations.notifications.loadUserError),
            type: 'error',
          });
        });
    }
  };

  const goBack = () => {
    history.push('/users');
  };
  const passwordValid = PASSWORD_MATCHER.test(password);

  const save = () => {
    user.startLoading();
    usersApi.updateUserUsingPATCH({
      id: user.data.id,
      updateUserDto: {
        password: passwordValid ? password : undefined,
        roleId: user?.data?.role?.id,
      },
    }).then(() => {
      user.finishLoading();
      goBack();
      showNotification({
        key: 'saveUserSuccess',
        message: tl(userDetailsTranslations.notifications.saveUserSuccess),
        type: 'success',
      });
    })
      .catch((e) => {
        console.error(e);
        setUser(prev => prev.failed());
        showNotification({
          key: 'saveUserError',
          message: tl(userDetailsTranslations.notifications.saveUserError),
          type: 'error',
        });
      });
  };


  const onChangeRole = (roleId) => {
    setUser(prev => prev.load({
      ...prev.data,
      role: roles.data.find(it => it.id === roleId),
    }));
  };


  const roleOptions = roles?.data?.map(role => ({
    label: role.name,
    value: role.id,
    customBody: <CellWithTwoLines firstElement={role.name} secondElement={role.description} />,
  }) as SelectOption);

  return {
    save,
    goBack,
    email: user.data?.email,
    onChangePassword: setPassword,
    onChangeRole,
    canSave: password === '' || passwordValid,
    validationState: !password || passwordValid ? 'success' : 'error' as typeof ValidateStatuses[number],
    loading: user.loading,
    roleOptions,
    roleId: user?.data?.role?.id,
    showDeleteUserModal: () => showDeleteUserModal(user.data.id, goBack),
  };
};
