import { EconomicPlanControllerApi } from 'api/accounting/apis/EconomicPlanControllerApi';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { EconomicPlanContext } from './EconomicPlanContext';

export function useLoadInvalidAmounts() {
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useLoadInvalidAmounts must be used within a EconomicPlanContextProvider');
  }

  const {
    setInvalidAmounts,
  } = economicPlanContext;
  const [, setParamOfInvalidAmountsFetchInProgress] = useState<number>();

  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  const onLoadInvalidAmounts = (economicPlanIdParam: number) => {
    if (!economicPlanIdParam) return;
    setParamOfInvalidAmountsFetchInProgress((paramOfInvalidAmountsFetchInProgress) => {
      const doCurrentParamMatchTheParamOfTheInProgressInvalidAmountsFetch = economicPlanIdParam === paramOfInvalidAmountsFetchInProgress;

      if (!doCurrentParamMatchTheParamOfTheInProgressInvalidAmountsFetch) {
        setInvalidAmounts(prevState => prevState.startLoading());
        economicPlanControllerApi.getInvalidAccountDistributionAmountsUsingGET({ id: economicPlanIdParam })
          .then((response) => {
            setInvalidAmounts(amounts => amounts.load(response?.amountValidations!));
          })
          .catch(() => {
            setInvalidAmounts(amounts => amounts.failed());
          })
          .finally(() => setParamOfInvalidAmountsFetchInProgress(undefined));
        return economicPlanIdParam;
      }
      return paramOfInvalidAmountsFetchInProgress;
    });
  };

  return {
    onLoadInvalidAmounts,
  };
}
