import React, { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { Key } from 'antd/lib/table/interface';


interface Props extends React.RefAttributes<HTMLTableRowElement> {
  index: number,
  movable?: boolean,
  moveRow: (dragItem: {index: number, rowKey: Key}, hoverItem: {index: number, rowKey: Key}) => void,
  className: string,
  children?: React.ReactNode,
}
const defaultProps: Partial<Props> = {
  movable: true,
};

const type = 'DraggableBodyRow';

const VirtualizedDraggableBodyRow = React.forwardRef<HTMLTableRowElement>(({
  index, moveRow, className, children, movable, ...restProps
}: Props, ref) => {
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem<{ index: number, rowKey: string }>() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number, rowKey: string }) => {
      moveRow(item, { index, rowKey: restProps['data-row-key'] });
    },
  });

  const [, drag, preview] = useDrag<{index: number, rowKey: string }>({
    type,
    item: { index, rowKey: restProps['data-row-key'] },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });


  useEffect(() => {
    /**
     * here we call `drop` so that the <tr> becomes a drop zone (for whatever we are dragging)
     * and we call `preview` to say that when we do trigger a drag (from the cell defined below) show this <tr> as the drag preview
     */
    // @ts-ignore
    drop(preview(ref));
    // @ts-ignore
  }, [drop, preview, ref?.current]);

  if (restProps['data-row-key'] === undefined || !movable) {
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        {...restProps}
      >
        {children}
      </tr>
    );
  }

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      {...restProps}
    >
      <td
        className="ant-table-cell drag-n-drop-cell"
        width="5.0rem"
        style={{ cursor: 'move' }}
      >
        <Icon
          // here we say that this component is what triggers a drag (and drop)
          ref={drag}
          className="drag-n-drop-icon"
          component={ICONS.dragNdrop}
        />
      </td>

      {/* we do not render the first child in order to have space for the cell above */}
      {React.Children.map(children, (child: React.ReactChild, idx) => {
        if (idx === 0) return null;
        return child;
      })}
    </tr>
  );
});

VirtualizedDraggableBodyRow.defaultProps = defaultProps;

export default VirtualizedDraggableBodyRow;
