import { TemplateControllerApi } from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { UnitContractProjectionDto } from 'api/accounting';
import { getFooter, getHeader, wrapHtmlWithBoilerplate } from 'pages/OpsCostReport/services/utils';
import { usePrintCss } from 'services/print/usePrintCss';
import { blobToBase64 } from 'services/usePdfUtils';
import { generateDocumentName } from 'lib/Utils';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import { OpsCostReportPreviewLocationState } from '../interfaces';
import { useOpsCostReportPreviewContext } from './OpsCostReportPreviewContext';
import { opsCostTranslation } from '../../translations';

export const useOpsCostReportPreview = () => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation<OpsCostReportPreviewLocationState>();

  const { goBack: navigateBackWithSate } = useContext(OverlayContext);
  const { getPrintCss } = usePrintCss();

  const {
    setPdfBlobForContracts,
    pdfBlobForContracts,
    selectedContractId,
    setSelectedContractId,
  } = useOpsCostReportPreviewContext('useOpsCostReportPreview');

  const { documentApiConfiguration } = useContext(AuthContext);

  const templateController = new TemplateControllerApi(documentApiConfiguration('document'));

  const selectedPdfBlob = useMemo(() => pdfBlobForContracts?.data?.find(pdfBlob => pdfBlob.contractId === selectedContractId)?.pdfBlob, [selectedContractId, pdfBlobForContracts.data]);

  const selectedPdfName = useMemo(() => {
    const separateHtmlForEachContractWithContractDetails = location.state?.separateHtmlForEachContractWithContractDetails?.find(htmlWithContractDetails => htmlWithContractDetails.contractId === selectedContractId);
    const indexAndPreview = `${separateHtmlForEachContractWithContractDetails?.index}_${tl(opsCostTranslation.opsCostReportPreview.preview)}`;
    return generateDocumentName('', '', location.state?.documentName, separateHtmlForEachContractWithContractDetails?.unitNrSharingDeclaration, separateHtmlForEachContractWithContractDetails?.contractName, indexAndPreview);
  }, [selectedContractId, location.state]);

  const objectOfContractIds = {
    contractIds: location.state?.separateHtmlForEachContractWithContractDetails
      ?.map(htmlWithContractDetails => htmlWithContractDetails.contractId),
  };

  const onLoadDocumentPreview = (tenantContractId: number) => {
    const existedDocument = pdfBlobForContracts.data.find(doc => doc.contractId === tenantContractId);

    if (existedDocument) {
      return Promise.resolve({ existedDocument });
    }

    const htmlWithDetailsForSelectedContract = location.state?.separateHtmlForEachContractWithContractDetails?.find(htmlForContract => htmlForContract.contractId === tenantContractId);
    return getPrintCss()
      .then(css => templateController.renderPdfUsingPOSTRaw({
        templateRenderDto:
      {

        html: wrapHtmlWithBoilerplate(htmlWithDetailsForSelectedContract.html),
        css,
        headerTemplate: getHeader(`${location?.state?.documentName} · ${location?.state?.propertyName}`, ''),
        footerTemplate: getFooter(),
        convertToPdfA: true,
        displayHeaderFooter: true,
        withPageNumbering: false,
        useLegacyPdfRenderer: false,
        templateName: '',
      },
      }).then((resp: any) => resp?.raw?.blob()
        .then((blob: Blob) => blobToBase64(blob)
          .then(response => Promise.resolve({ contractId: tenantContractId, pdfBlob: response })))));
  };


  const onSelectTenant = (contract: UnitContractProjectionDto) => {
    setPdfBlobForContracts(prev => prev.startLoading());
    setSelectedContractId(contract.unitContractId);
    onLoadDocumentPreview(contract.unitContractId)
      .then((selectedDoc) => {
        if (selectedDoc) {
          setPdfBlobForContracts(prev => prev.load(
            [
              ...prev.data,
              {
                contractId: contract.unitContractId,
                pdfBlob: selectedDoc?.pdfBlob,
              },
            ],
          ));
        }
      }).catch((err) => {
        console.error(err);
        setPdfBlobForContracts(prev => prev.failed());
        showNotification({
          type: 'error',
          message: tl(opsCostTranslation.notifications.retryError),
        });
      });
  };


  const onClose = () => {
    navigateBackWithSate({
      state: {
        tenantContractId: selectedContractId,
      },
    });
  };


  return {
    onClose,
    pdfBlobForContracts,
    selectedContractId,
    selectedPdfBlob,
    selectedPdfName,
    propertyId: location.state?.propertyId,
    onSelectTenant,
    documentInCreation: pdfBlobForContracts.loading,
    isDocumentInFailedToGenerate: pdfBlobForContracts.error,
    objectOfContractIds,
  };
};
