import React from 'react';
import './PageContent.scss';

interface PageContentProps {
  children: React.ReactNode,
  className?: string,
  id?: string,
}

const PageContent = ({ children, className, id }: PageContentProps) => (
  <div id={id} className={`PageContent ${className ?? ''}`}>
    {children}
  </div>
);


PageContent.defaultProps = {
  className: undefined,
  id: undefined,
};


export default PageContent;
