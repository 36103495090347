import React, { useMemo, useState } from 'react';
import DirtModal from '../DirtModal';


interface Props {
  children: React.ReactChild | React.ReactChildren;
}

export interface DirtModalProps {
  visible: boolean,
  loading: boolean,
  onSave: () => void,
  onDiscard: () => void,
  onCancel: () => void,
}

export const DEFAULT_DIRT_MODAL_STATE: DirtModalProps = {
  visible: false,
  loading: false,
  onSave: () => {},
  onDiscard: () => {},
  onCancel: () => {},
};

export const SimpleDirtModalContext = React.createContext<{
  dirty: boolean,
  setDirty: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<DirtModalProps>>,
} | undefined>(undefined);


const SimpleDirtModalContextProvider = ({ children }: Props) => {
  const [dirty, setDirty] = useState(false);
  const [modalProps, setModalProps] = useState<DirtModalProps>(DEFAULT_DIRT_MODAL_STATE);

  const providerValue = useMemo(() => ({
    dirty,
    setDirty,
    setModalProps,
  }), [dirty, setDirty, setModalProps]);


  return (
    <SimpleDirtModalContext.Provider value={providerValue}>
      {children}
      <DirtModal
        visible={modalProps.visible}
        onSave={modalProps.onSave}
        onDiscard={modalProps.onDiscard}
        onCancel={modalProps.onCancel}
        loading={modalProps.loading}
      />
    </SimpleDirtModalContext.Provider>
  );
};

export default SimpleDirtModalContextProvider;
