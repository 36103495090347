import { endpointUrls, frontend } from 'backend_api';
import * as config from '../../config';

export const usePrintCss = () => {
  const getPrintCss = (removeMediaPrintTags = true) => {
    const cssUrl = `${endpointUrls.STATIC_CSS}/main-print.css`;

    return frontend.getFileByPath(cssUrl, { version: config.version })
      .then((resp) => {
        const cssString = new TextDecoder().decode(resp as unknown as ArrayBuffer);

        const cssWithoutMediaPrintTags = cssString
        // replace all (except first) @media print opening tags
          .replaceAll(/}\s*@media print\s*{/gm, '')
        // replace first @media print opening tags
          .replace(/@media print\s*{/gm, '')
        // replace the last closing curly brace
          .replaceAll(/}\s*\/\*.*/gm, '');

        return removeMediaPrintTags
          ? Promise.resolve(cssWithoutMediaPrintTags)
          : Promise.resolve(cssString);
      });
  };

  return { getPrintCss };
};
