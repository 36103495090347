import React, {
  useContext, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { useQuery } from 'lib/useQuery';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import { getStateColor } from 'storybook-components/headers/utils';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import ErrorMessage from 'storybook-components/feedback/ErrorScreen/ErrorMessages/ErrorMessage';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import { HouseMoneySettlementContext } from '../../../../../../contexts/HouseMoneySettlementContext';
import './HouseMoneySettlementOverview.scss';
import { translations } from '../../../../../../elements/Translation/translations';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../../../../contexts/PropertyListContext';
import ReportOverviewHeader from '../../../../../ReportOverview/ReportOverviewHeader/ReportOverviewHeader';
import usePoll from '../../../../../../services/usePoll';
import BuildingsListContextProvider from '../../../../../../services/BuildingsList/BuildingsListContext';
import ActiveContractsListContextProvider from '../../../../../../services/UnitContractsList/ActiveContractsListContext';
import RightSideComponents from './RightSideComponents/RightSideComponents';
import RightSideButtons from './RightSideButtons/RightSideButtons';


export default function HouseMoneySettlementOverview(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    selectedEconomicYear,
    setSelectedEconomicYear,
    onLoadHouseMoneySettlement,
    houseMoneySettlementState,
    setSelectedUnitId,
    onClearHouseMoneySettlementState,
    inCreation,
    documentsInCreation,
    creationFailed,
    pollStatus,
    pollDocumentStatus,
    documents,
    selectedUnitId,
  }: any = useContext(HouseMoneySettlementContext);
  const query = useQuery();
  const {
    selectedProperty, setSelectedPropertyHrId, setSelectedProperty,
  } = useContext(PropertyListContext);
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const history = useHistory();
  usePoll({
    shouldPoll: inCreation,
    pollFunction: pollStatus,
    timeoutSeconds: 5000,
  });

  usePoll({
    shouldPoll: documentsInCreation,
    pollFunction: pollDocumentStatus,
    timeoutSeconds: 5000,
  });

  useEffect(() => {
    if (propertyHrId) setSelectedPropertyHrId(propertyHrId);
    return () => {
      setSelectedPropertyHrId(null);
      setSelectedProperty(prev => prev.load(null));
    };
  }, [propertyHrId]);

  useEffect(() => {
    const year = query.get('year') ? parseInt(query.get('year'), 10) : moment().get('year') - 1;
    setSelectedEconomicYear(year);
  }, []);

  // clear house money settlement state on unmount
  useEffect(() => () => {
    onClearHouseMoneySettlementState();
  }, []);


  const goBack = () => {
    history.push('/house-money-settlement');
  };

  useEffect(() => {
    if (!_.isEmpty(selectedProperty.data) && selectedEconomicYear) {
      onLoadHouseMoneySettlement();
      checkPropertyValidity({ propertyHrId: selectedProperty.data.propertyHrId, onCancel: goBack });
    }
  }, [selectedProperty, selectedEconomicYear]);

  const settlementStatus = documentsInCreation ? 'IN_CREATION' : houseMoneySettlementState.data?.status;

  const selectedDocument = useMemo(() => {
    if (!selectedEconomicYear || !selectedProperty.data?.id || _.isEmpty(documents)) return undefined;
    return (selectedUnitId ? documents?.find(d => d.unitId === selectedUnitId) : documents?.find(d => d.unitId === undefined));
  }, [documents, selectedUnitId, selectedEconomicYear, selectedProperty.data?.id]);

  return (
    <Page className="HouseMoneySettlementOverview">
      <PageHeader
        title={tl(translations.pages.houseMoneySettlement.title)}
        subtitle={<PropertyInfo property={selectedProperty.data} />}
        rightSideComponent={(
          <RightSideButtons
            settlementStatus={settlementStatus}
          />
        )}
        tagContent={settlementStatus && tl(translations.pages.houseMoneySettlement.table.houseMoneySettlementStatuses[settlementStatus.toLowerCase()])}
        tagColor={getStateColor(settlementStatus) as PageHeaderTagColor}
        showLogo
      />
      <BuildingsListContextProvider>
        <ActiveContractsListContextProvider>
          <ReportOverviewHeader
            setSelectedUnitId={setSelectedUnitId}
            rightSideButtons={() => <RightSideComponents />}
          />
        </ActiveContractsListContextProvider>
      </BuildingsListContextProvider>

      <PageContent className="page-content">
        {inCreation || documentsInCreation || houseMoneySettlementState.loading
          ? (
            <LoadingScreen
              title={inCreation || documentsInCreation ? tl(translations.pages.houseMoneySettlement.generationText.title) : undefined}
              subtitle={inCreation || documentsInCreation ? tl(translations.pages.houseMoneySettlement.generationText.subtitle) : undefined}
            />
          )
          : (creationFailed
            ? (
              <ErrorMessage
                message={(
                  <AlertArticleLink
                    label={tl(translations.pages.houseMoneySettlement.generationErrorMessage)}
                    content={tl(translations.pages.houseMoneySettlement.contactSupport)}
                    url={articleUrls.contactSupport}
                  />
                )}
              />
            )
            : (
              <SimplePdfViewer
                documentId={selectedDocument?.id}
                filename={selectedDocument?.name}
                renderTextLayer
              />
            )
          )
        }
      </PageContent>
    </Page>
  );
}
