import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { useGetProfitAndLossReportData } from '../../services/useGetProfitAndLossReportData';

export const useRecalculateButton = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);

  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useRecalculateButton must be used within a ProfitAndLossReportContextProvider');
  }


  const { accountBalances, reportData } = profitAndLossReportContext;
  const { getProfitAndLossReportData } = useGetProfitAndLossReportData();

  const showRecalculateSuccess = () => {
    showNotification({
      type: 'success',
      message: tl(translations.notifications.recalculateSuccess),
    });
  };

  const showRecalculationError = () => {
    showNotification({
      type: 'error',
      message: tl(translations.notifications.recalculateError),
    });
  };

  const onRecalculate = () => {
    getProfitAndLossReportData({ reportData, onSuccessCb: showRecalculateSuccess, onErrorCb: showRecalculationError });
  };

  return {
    onRecalculate,
    loading: accountBalances.loading,
  };
};
