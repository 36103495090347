import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import React from 'react';
import { useTotalOfUnit } from './useTotalOfUnit';

interface Props {
  unitId: number,
  rowIndex: number,
}

const TotalOfUnit = ({
  unitId,
  rowIndex,
}: Props) => {
  const {
    value,
  } = useTotalOfUnit(unitId, rowIndex);

  return (
    <CurrencyInput
      value={value!}
      onChange={() => {
      }}
      disabled
    />
  );
};

export default TotalOfUnit;
