import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import _ from 'lodash';

export const useLinkSearchBarToQueryParams = (filterState, syncSearchBarAndQueryParams: boolean) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!filterState || !syncSearchBarAndQueryParams) return; // We don't need to link searchBar to queryParams

    const params = Object.keys(filterState)
      .filter(key => ![undefined, null].includes(filterState[key]) && filterState[key].toString()?.trim())
      .map(key => `${key}=${encodeURIComponent(stringifyObject(filterState[key]))}`)
      .join('&');

    history.replace(`${location.pathname}?${params}`);
  }, [filterState]);

  const isPrimitive = (test: any): boolean => test !== Object(test);
  const stringifyObject = (object: any): any => (isPrimitive(object) ? object : JSON.stringify(object));
};
