import React, { useContext, useEffect } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { navigateConcat } from 'navigation/History';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { translations } from 'elements/Translation/translations';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import SmartTable from 'elements/SmartTable/SmartTable';
import { ContactListContext } from 'contexts/ContactListContext';
import { PersonEditingContext } from 'contexts/PersonEditingContext';
import { usePersonListColumns } from './UsePersonListColumns';
import MainPageHeader from '../../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../../../services/useSiteMap/useSiteMap';
import useContactFilters from '../../services/UseContactFilters';
import { ContactLegacyDtoTypeEnum } from '../../../../api/accounting/models';


export default function PersonList(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const contactListContext: any = useContext(ContactListContext);
  useGlobalSearch({
    key: 'persons',
    filterProps: {
      availableFilters: useContactFilters(ContactLegacyDtoTypeEnum.PERSON),
      setFilter: (key: string, value: string) => contactListContext.setPersonListFilters(
        (currentFilter: any) => ({
          ...currentFilter,
          [key]: value,
        }),
      ),
    },
    queryParamAsFilter: {
      filterState: contactListContext.personListFilters,
      onSetDefaultFilterFromQueryParams: contactListContext.updatePersonListFilters,
    },
  });

  const { setDirty } = useContext(PersonEditingContext);

  useEffect(() => {
    setDirty(false);
    return () => {
      contactListContext.onClearPersonListFilters();
    };
  }, []);

  const smartTable = useSmartTable(
    {
      tableName: 'personList',
      columns: usePersonListColumns(),
      dataSource: contactListContext.personListState.data,
      contentLoading: contactListContext.personListState.loading,
      onRow: (record: any) => ({
        onClick: () => {
          navigateConcat(`/view-person/${record.id}`);
        },
      }),
      infiniteScrollerProps: {
        hasMoreData: !contactListContext.personListState.lastPage,
        loadMoreData: contactListContext.onLoadPersonList,
      },
      rowKey: 'id',
    },
  );

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'contactPerson',
    navItems: subcategorySwitcherItems.contacts,
  };

  return (
    <div className="PersonList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button
            type="primary"
            onClick={() => navigateConcat('/create-person')}
          >
            {tl(translations.pages.contacts.contact.add)}
          </Button>
        )}
      />
      <SmartTable {...smartTable} />
    </div>
  );
}
