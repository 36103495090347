import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../../elements/Translation/translations';
import { FORMAT } from '../../../../elements/SmartTable/SmartTable';

export const UseUnitReserveFundsColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.headers.account),
      dataKey: 'accountName',
      defaultWidth: '25%',
    },
    {
      title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.headers.distribution),
      dataKey: 'economicPlanDistributionMode',
      defaultWidth: '15%',
      render: (item: any) => (
        <span>{item ? tl(translations.enums.distributionMode[`${item}`]) : ''}</span>
      ),
    },
    {
      title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.settlementAmount),
      defaultWidth: '30%',
      children: [
        {
          title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.headers.total),
          dataKey: 'newTotalShares',
          defaultWidth: '15%',
        },
        {
          title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.headers.share),
          dataKey: 'newUnitShares',
          defaultWidth: '15%',
        }],
    },
    {
      title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.contribution),
      defaultWidth: '30%',
      children: [
        {
          title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.headers.total),
          dataKey: 'newTotalAmount',
          className: 'currency',
          format: FORMAT.CURRENCY,
          defaultWidth: '15%',
        },
        {
          title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.table.headers.share),
          dataKey: 'newUnitAmount',
          className: 'currency',
          format: FORMAT.CURRENCY_DASH,
          defaultWidth: '15%',
        }],
    },
  ];
};
