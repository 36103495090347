import './AgendaItemDetails.scss';

import React, { useContext } from 'react';

import {
  Col,
  Row,
} from 'antd';
import { AgendaItemDtoAgendaItemTypeEnum } from 'api/accounting/models';
import RichTextEditor from 'elements/Inputs/RichTextEditor/RichTextEditor';

import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import SelectInput
  from '../../../../../../../elements/Inputs/SelectInput/SelectInput';
import {
  OptionInterface,
} from '../../../../../../../elements/Inputs/SmartSearch/SmartSearch';
import TextInput
  from '../../../../../../../elements/Inputs/TextInput/TextInput';
import { useAgendaItemDetails } from '../services/useAgendaItemDetails';
import { translations as agendaItemTranslations } from '../translations';

const AgendaItemDetailsBody = () => {
  const { tl } = useContext(LanguageContext);

  const {
    selectedAgendaItem, onChange, onChangeTemplate, templateAgendaItems, isDisabled, isDiscussionTopic,
  } = useAgendaItemDetails();
  const templateAgendaOptions: OptionInterface[] = templateAgendaItems.map(item => ({
    label: item.label,
    value: item.label,
  }));

  const currentTemplate = templateAgendaItems.find(item => item?.value?.resolutionRecordText === (selectedAgendaItem?.data?.resolutionRecordText)?.replace(/<[^>]*>?/gm, ''))?.value?.title ?? undefined;
  return (
    <div className="AgendaItemDetailsBody">
      <Row gutter={[40, 20]}>
        <Col span={12}>
          <SelectInput
            value={selectedAgendaItem?.data?.agendaItemType || AgendaItemDtoAgendaItemTypeEnum.PROPOSAL}
            label={tl(agendaItemTranslations.agendaItemDetails?.typeField)}
            placeholder={tl(agendaItemTranslations.agendaItemDetails?.placeholder)}
            dropdownMatchSelectWidth={false}
            options={[
              {
                value: AgendaItemDtoAgendaItemTypeEnum.PROPOSAL,
                label: tl(agendaItemTranslations.agendaItemDetails?.typeOptions?.proposal),
              },
              {
                value: AgendaItemDtoAgendaItemTypeEnum.POINT_OF_ORDER,
                label: tl(agendaItemTranslations.agendaItemDetails?.typeOptions?.pointOfOrder),
              },
              {
                value: AgendaItemDtoAgendaItemTypeEnum.DISCUSSION_TOPIC,
                label: tl(agendaItemTranslations.agendaItemDetails?.typeOptions?.discussionTopic),
              },

            ]}
            onChange={(val: string) => { onChange('agendaItemType', val); }}
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <SelectInput
            value={currentTemplate}
            label={tl(agendaItemTranslations.fields.templateSelector)}
            placeholder={tl(agendaItemTranslations.agendaItemDetails?.placeholder)}
            dropdownMatchSelectWidth={false}
            options={templateAgendaOptions}
            onChange={(value) => {
              onChangeTemplate(value);
            }}
            disabled={isDisabled}
          />
        </Col>
        <Col span={24}>
          <TextInput
            value={selectedAgendaItem?.data?.title || ''}
            label={tl(agendaItemTranslations.fields.title)}
            onChange={(val: string) => { onChange('title', val); }}
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={24}>
          <RichTextEditor
            value={selectedAgendaItem?.data?.comment || ' '}
            onChange={(val: string) => { onChange('comment', val); }}
            label={tl(agendaItemTranslations.fields.description)}
            className="input"
            disabled={isDisabled}
          />
        </Col>
        <Col span={24}>
          <RichTextEditor
            resizable
            rows={8}
            value={isDiscussionTopic ? ' ' : (selectedAgendaItem?.data?.resolutionRecordText || ' ')}
            onChange={(val: string) => { onChange('resolutionRecordText', val); }}
            label={tl(agendaItemTranslations.fields.resolutionRecordText)}
            className="input"
            disabled={isDisabled || isDiscussionTopic}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AgendaItemDetailsBody;
