import ActionsDropdown from 'elements/SmartTable/components/OptionsMenu/ActionsDropdown';
import { useContactPropertiesActionsMenu } from './useContactPropertiesActionsMenu';
import { ContractDataSource } from '../../services/ContactPropertiesContext';


type ContactPropertiesActionsMenuProps = {
    record: ContractDataSource,
  }
export const ContactPropertiesActionsMenu = ({ record } : ContactPropertiesActionsMenuProps) => {
  const { actionsMenu } = useContactPropertiesActionsMenu(record);

  return (
    <ActionsDropdown actions={actionsMenu} record={record} tableVerticallyScrollable={false} />
  );
};
