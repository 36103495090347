import { AgendaItemsControllerApi } from 'api/accounting';
import { AgendaItemsContext } from 'contexts/AgendaItemsContext';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { OwnersMeetingProtocolContext } from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';
import { useContext } from 'react';
import {
  translations as ownersMeetingProtocolAgendaItemsTranslations,
} from '../translations';


export const useLoadAgendaItemsList = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const { ownersMeeting } = useContext(OwnersMeetingProtocolContext);
  const agendaItemsContext = useContext(AgendaItemsContext);
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));


  if (agendaItemsContext === undefined) {
    throw new Error('useLoadAgendaItemsList must be used within a AgendaItemsContextProvider');
  }
  const {
    agendaItemsList, setAgendaItemsList,
  } = agendaItemsContext;


  const onLoadAgendaItemsList = (resetPage: boolean = false) => {
    setAgendaItemsList((prev) => {
      if (!prev.loading) {
        loadAgendaItemsList(resetPage);
        return prev.startLoading();
      }
      return prev;
    });
  };

  const loadAgendaItemsList = (resetPage: boolean = false) => {
    agendaItemsControllerApi.getAllAgendaItemsForAProtocolUsingGET({
      ownersMeetingProtocolId: ownersMeeting.data.id,
      page: resetPage ? 0 : agendaItemsList.page,
      size: 60,
    })
      .then((response) => {
        setAgendaItemsList(prev => prev.loadPaged(response.content.sort((a, b) => a.index - b.index), resetPage, response.last!));
      })
      .catch((err) => {
        showNotification({
          type: 'error',
          message: tl(ownersMeetingProtocolAgendaItemsTranslations.agendaItemsTable.loadAgendaItemsError),
        });
        setAgendaItemsList(prev => prev.failed(err));
      });
  };


  return {
    onLoadAgendaItemsList,
  };
};
