export const inviteUsersTranslations = {
  header: {
    title: {
      en: 'Add users',
      de: 'Benutzer hinzufügen',
    },
    buttons: {
      save: {
        en: 'Save',
        de: 'Speichern',
      },
      close: {
        en: 'Close',
        de: 'Schließen',
      },
      add: {
        en: 'Add',
        de: 'Hinzufügen',
      },
    },
  },
  section: {
    title: {
      en: 'Credentials',
      de: 'Anmeldeinformationen',
    },
    alert: {
      message: {
        en: 'Password must contain:',
        de: 'Das Passwort muss enthalten:',
      },
      description: {
        en: [
          'at least 8 characters',
          'one uppercase letter',
          'one lowercase letter',
          'one digit',

        ],
        de: [
          'mindestens 8 Zeichen',
          'einen Großbuchstaben',
          'einen Kleinbuchstaben',
          'eine Ziffer',
        ],
      },
    },
  },
  columns: {
    email: {
      en: 'Email',
      de: 'Email',
    },
    password: {
      en: 'Password',
      de: 'Passwort',
    },
    role: {
      en: 'Role',
      de: 'Rolle',
    },
  },
  noUsers: {
    en: "You haven't added any users yet.\nEnter a number of users to add via button above.",
    de: 'Sie haben noch keine Benutzer erstellt.\nGeben Sie die gewünschte Anzahl an Benutzer ein und fügen Sie diese über den Button hinzu.',
  },
  notifications: {
    loadRolesError: {
      en: 'User Roles could not be loaded.',
      de: 'Benutzerrollen konnten nicht geladen werden.',
    },
    saveSuccess: {
      message: {
        en: 'Users have been invited',
        de: 'Benutzer wurden eingeladen',
      },
      description: {
        en: 'You need to send the credentials to the invited uses so that they can log into their accounts.',
        de: 'Sie müssen die Anmeldeinformationen an die eingeladenen Benutzer senden, damit sie sich bei ihren Konten anmelden können.',
      },
    },
    saveError: {
      en: 'Users could not be created.',
      de: 'Benutzer konnten nicht erstellt werden.',

      duplicateEmail: {
        en: email => `The following user already exists: ${email}`,
        de: email => `Der folgende Benutzer ist bereits erstellt: ${email}`,
      },
    },
    cannotSave: {
      message: {
        en: 'Users could not be saved.',
        de: 'Benutzer konnten nicht gespeichert werden.',
      },
      description: {
        en: 'Passwords must meet the strength requirements, duplicate emails are not allowed and each user must have a role.',
        de: 'Passwörter müssen die Sicherheitsanforderungen erfüllen, doppelte E-Mails sind nicht erlaubt und jeder Benutzer muss eine Rolle haben.',
      },
    },
  },
};
