import React from 'react';
import { DataIndex } from 'rc-table/lib/interface';
import { ExtendedColumn } from './services/useTableColumnSelectorExtendedColumns';
import TableColumnSelector from './components/TableColumnSelector/TableColumnSelector';
import './TableColumnSelectorWrapper.scss';


interface Props<T> {
  children: React.ReactChild,
  columns: ExtendedColumn<T>[]
  changeColumnVisibility: (dataIndex: DataIndex) => void,
}

function TableColumnSelectorWrapper <T>({ children, columns, changeColumnVisibility }: Props<T>) {
  return (
    <div className="TableColumnSelectorWrapper">
      {children}
      <div className="column-selector-wrapper">
        <TableColumnSelector columns={columns} changeColumnVisibility={changeColumnVisibility} />
      </div>
    </div>
  );
}

export default TableColumnSelectorWrapper;
