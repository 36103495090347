import moment from 'moment';
import './RangeInput.scss';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import Icon from '@ant-design/icons';
import { Form } from 'antd';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Inputs/DateInput/translations';
import InputProps from '../InputProps';
import { ICONS } from '../../../components/icons';

type DateRange = [moment.Moment | undefined, moment.Moment | undefined] | undefined;

interface RangeProps extends InputProps<DateRange> {
  format?: string,
  noPopupContainer?: boolean
}

export default function RangeInput(props: RangeProps): JSX.Element {
  const {
    className, label, required, disabled, validationState, validationMessage, inputClassName, format, value, autoFocus, onChange,
  } = props;
  const { tl } = useContext(LanguageContext);

  const placeholder: string = tl(translations.placeholder);

  const valueFrom = useMemo(() => (value && value[0] ? value[0] : null), [value]);
  const valueTo = useMemo(() => (value && value[1] ? value[1] : null), [value]);
  const [pickerFromOpen, setPickerFromOpen] = useState(false);
  const [pickerToOpen, setPickerToOpen] = useState(false);

  const onChangeFrom = useCallback((date: moment.Moment | null) => {
    if (date) {
      if (date.isValid()) {
        onChange([date.add(date.utcOffset(), 'minutes'), valueTo || undefined]);
      }
    } else {
      onChange([undefined, valueTo || undefined]);
    }
  }, [valueTo, onChange]);

  const onChangeTo = useCallback((date: moment.Moment | null) => {
    if (date) {
      if (date.isValid()) {
        onChange([valueFrom || undefined, date.add(date.utcOffset(), 'minutes')]);
      }
    } else {
      onChange([valueFrom || undefined, undefined]);
    }
  }, [valueFrom, onChange]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: 0 | 1) => {
    if (e.key === 'Enter') {
      if (index === 0) {
        setPickerFromOpen(true);
      } else if (index === 1) {
        setPickerToOpen(true);
      }
      e.stopPropagation();
    }
  };

  return (
    <div className={`RangeInput ${className} ${disabled ? 'read-only' : ''}`}>
      <Form.Item
        className={`${pickerFromOpen || pickerToOpen ? 'picker-open' : ''}`}
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <KeyboardDatePicker
          className={`date-picker ${inputClassName} ${disabled ? 'read-only' : ''}`}
          onChange={onChangeFrom}
          format={format}
          value={valueFrom}
          disabled={disabled}
          variant="inline"
          placeholder={placeholder}
          invalidDateMessage={null}
          minDateMessage=""
          maxDateMessage=""
          autoFocus={autoFocus}
          disableToolbar
          onKeyDown={e => onKeyDown(e, 0)}
          open={pickerFromOpen}
          onClose={() => {
            setPickerFromOpen(false);
          }}
          onOpen={() => {
            setPickerFromOpen(true);
          }}
          KeyboardButtonProps={
            { tabIndex: -1 }
          }
          rightArrowIcon={<Icon component={ICONS.calendarArrowRight} />}
          leftArrowIcon={<Icon component={ICONS.calendarArrowLeft} />}
        />
        <Icon className="date-picker-separator " component={ICONS.calendarArrowRight} />
        <KeyboardDatePicker
          className={`date-picker ${inputClassName} ${disabled ? 'read-only' : ''}`}
          onChange={onChangeTo}
          format={format}
          value={valueTo}
          disabled={disabled}
          variant="inline"
          placeholder={placeholder}
          invalidDateMessage={null}
          minDateMessage=""
          maxDateMessage=""
          disableToolbar
          onKeyDown={e => onKeyDown(e, 1)}
          open={pickerToOpen}
          onClose={() => {
            setPickerToOpen(false);
          }}
          onOpen={() => {
            setPickerToOpen(true);
          }}
          KeyboardButtonProps={
            { tabIndex: -1 }
          }
          rightArrowIcon={<Icon component={ICONS.calendarArrowRight} />}
          leftArrowIcon={<Icon component={ICONS.calendarArrowLeft} />}
        />
      </Form.Item>
    </div>
  );
}

RangeInput.defaultProps = {
  className: '',
  inputClassName: '',
  disabled: false,
  required: false,
  format: 'DD.MM.YYYY',
};
