import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  ContactLegacyControllerApi,
  ContactSimpleProjection,
  ContactSimpleProjectionTypeEnum,
  ContractContactProjectionDto,
  ContractContactProjectionDtoRoleEnum,
  OwnersMeetingProtocolRecipientExtendedDisplayDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _ from 'lodash';
import {
  translations as ownersMeetingProtocolTranslations,
} from 'pages/OwnersMeetingProtocol/translations';

import {
  OwnersMeetingProtocolContext,
} from '../../../../services/OwnersMeetingProtocolContext';

export default function useSimpleAttendeeTable() {
  const { ownersMeeting } = useContext(OwnersMeetingProtocolContext);
  const [attendees, setAttendees] = useState([]);
  const { tl } = useContext(LanguageContext);
  const [contacts, setContacts] = useState<ContactSimpleProjection[]>([]);
  const { apiConfiguration } = useContext(AuthContext);
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const getContactIds = (protocolRecipients: OwnersMeetingProtocolRecipientExtendedDisplayDto[]) => {
    const contactIdsList = [];
    protocolRecipients.forEach((protocolRecipient) => {
      protocolRecipient.contractProjectionDto?.contacts.map((contact) => {
        if (contactIdsList.indexOf(contact?.contactId) === -1) contactIdsList.push(contact?.contactId);
      });
    });
    return contactIdsList;
  };
  useEffect(() => {
    const protocolRecipients = ownersMeeting?.data?.protocolRecipients;
    if (!_.isEmpty(protocolRecipients)) {
      const contactIds = getContactIds(protocolRecipients);
      loadContactsForRecipients(contactIds);
    }
  }, [ownersMeeting?.data?.protocolRecipients]);

  const loadContactsForRecipients = (contactsIds: number[]) => {
    if (_.isEmpty(contactsIds)) return;
    contactControllerApi
      .getSimpleContactUsingGET({ contacts: contactsIds })
      .then((response: ContactSimpleProjection[]) => {
        setContacts(response);
      })
      .catch(() => {
        showNotification({
          key: 'loadOwnersMeetingProtocolRecipientError',
          message: tl(ownersMeetingProtocolTranslations.notifications.loadOwnersMeetingProtocolRecipientError),
          type: 'error',
        });
      });
  };

  const getNameOfContact = (id: number, contactsList: ContactSimpleProjection[]) => {
    const contact: ContactSimpleProjection = contactsList.find(c => c.id === id);
    if (contact.type === ContactSimpleProjectionTypeEnum.COMPANY) {
      return contact.companyName;
    }
    return `${contact.lastName}, ${contact.firstName}`;
  };


  useEffect(() => {
    if (!_.isEmpty(contacts)) {
      let protocolRecipients: OwnersMeetingProtocolRecipientExtendedDisplayDto[] = ownersMeeting?.data?.protocolRecipients?.filter(protocolRecipient => protocolRecipient.contractProjectionDto) || [];
      protocolRecipients.sort((a: OwnersMeetingProtocolRecipientExtendedDisplayDto, b: OwnersMeetingProtocolRecipientExtendedDisplayDto) => {
        // sort by ownerName and unitNrSharingDeclaration
        if (a.contractProjectionDto?.mailingContact?.name === b.contractProjectionDto?.mailingContact?.name) {
          return a.contractProjectionDto?.unitNrSharingDeclaration?.length > b.contractProjectionDto?.unitNrSharingDeclaration?.length ? 1 : a.contractProjectionDto?.unitNrSharingDeclaration?.localeCompare(b.contractProjectionDto?.unitNrSharingDeclaration);
        }
        return getNameOfContact(a.contractProjectionDto?.mailingContact?.contactId, contacts).localeCompare(getNameOfContact(b.contractProjectionDto?.mailingContact?.contactId, contacts));
      });

      protocolRecipients = protocolRecipients.map((pr) => {
        const ownerContacts = pr.contractProjectionDto.contacts?.filter(contact => contact?.role?.some(role => [
          ContractContactProjectionDtoRoleEnum.OWNER,
          ContractContactProjectionDtoRoleEnum.LEGAL_GUARDIAN,
          ContractContactProjectionDtoRoleEnum.HEAD_OF_COUNCIL,
          ContractContactProjectionDtoRoleEnum.COUNCIL,
        ].includes(role)));

        return {
          ...pr,
          contractProjectionDto: {
            ...pr.contractProjectionDto,
            contacts: ownerContacts,
          },
        };
      });

      // sort inside contacts
      protocolRecipients = protocolRecipients?.map((pr: OwnersMeetingProtocolRecipientExtendedDisplayDto) => ({
        ...pr,
        contractProjectionDto: {
          ...pr.contractProjectionDto,
          contacts: pr.contractProjectionDto?.contacts?.map(contact => ({ ...contact, name: getNameOfContact(contact.contactId, contacts) })).sort((a: ContractContactProjectionDto, b: ContractContactProjectionDto) => a.name.localeCompare(b.name)),
        },
      }));

      // show the contact once if he has multiple units
      const attendanceList: OwnersMeetingProtocolRecipientExtendedDisplayDto[] = [];
      protocolRecipients?.forEach((element) => {
        const prevAttendance = attendanceList?.length > 0 ? attendanceList.find(attendance => element.contractProjectionDto
          && element.contractProjectionDto?.contacts?.length === attendance.contractProjectionDto?.contacts?.length
          && element.contractProjectionDto?.contacts?.every((o, idx) => o.contactId === attendance.contractProjectionDto?.contacts[idx].contactId))
          : undefined;
        if (prevAttendance) {
          const indexOfAttendance = attendanceList.indexOf(prevAttendance);
          attendanceList[indexOfAttendance] = {
            ...prevAttendance,
            contractProjectionDto: {
              ...prevAttendance.contractProjectionDto,
              unitNrSharingDeclaration: `${prevAttendance?.contractProjectionDto?.unitNrSharingDeclaration}, ${element?.contractProjectionDto?.unitNrSharingDeclaration}`,
              propertyShare: Number.parseFloat(((prevAttendance?.contractProjectionDto?.propertyShare || 0) + (element?.contractProjectionDto?.propertyShare || 0)).toFixed(5)),
            },
          };
        } else {
          attendanceList.push(element);
        }
      });
      setAttendees(attendanceList);
    }
  }, [contacts]);

  return {
    attendees,
  };
}
