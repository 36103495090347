import React, { useContext } from 'react';

import { ContactLegacyDto } from 'api/accounting';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';

import { ContactIntegrations } from '../ContactIntegrationsSection';
import defaultSection from '../defaultSection';
import { translations } from '../translations';

export const useContactIntegrationSection = ({
  contact,
}: { contact: ContactLegacyDto }) => {
  const { tl } = useContext(LanguageContext);
  return ({
    ...defaultSection(4),
    sectionTitle: tl(translations.integrations.sectionTitle),
    sectionIcon: ICONS.link,
    sectionId: 'integrationSection',
    hideSaveButton: true,
    content: [
      {
        sectionId: 'IntegrationsBody',
        content: <ContactIntegrations contact={contact} />,
      },
    ],
  });
};
