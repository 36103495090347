import '../../BankTransactionAllocation.scss';

import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Button from 'elements/Buttons/Button/Button';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { LanguageContext } from 'contexts/LanguageContext';
import { BankTransactionAllocationContext } from 'pages/BankTransactions/services/BankTransactionAllocationContext';
import { AllocationItemDto, BankTransactionProjectionDtoTransactionPaymentStatusEnum } from 'api/accounting';
import {
  AccountAllocation, ALLOCATION_TYPES, ExtendedBankTransaction, FEAccountBalanceDto,
} from 'pages/BankTransactions/interfaces';
import { formatCurrencyToNumber, round2dec } from 'lib/Utils';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { translations } from 'pages/BankTransactions/translations';
import SmartTable from 'elements/SmartTable/SmartTable';
import { useUnitAccounts } from '../../services/useUnitAccounts';
import BankTransactionAllocationDetails from '../BankTransactionAllocationDetails/BankTransactionAllocationDetails';
import AllocationSearchBar from '../BankTransactionAllocationHeader/components/AllocationSearchBar/AllocationSearchBar';
import MenuButton from '../MenuButton';


interface UnitAllocationProps {
    selectedBankTransactions: ExtendedBankTransaction[],
              resetTransaction: () => void,
              markTransactionNotBookable: () => void,
              markTransactionDuplicate: () => void,
  parentHeight: number;
  parentComponentId: string;
  onAllocateAccounts: (isUnitTab: boolean, selectedAccounts: AccountAllocation[], onSuccess: Function, onTabChange?: boolean, callback?: () => void) => void;
  unitTabDirty: boolean;
  setUnitTabDirty: React.Dispatch<React.SetStateAction<boolean>>;
  allocationProposals: AllocationItemDto[];
  selectedType: ALLOCATION_TYPES;
    setSelectedType: React.Dispatch<React.SetStateAction<ALLOCATION_TYPES>>;
    types: {label: string, value: string}[];
}

export default function UnitAllocation({
  selectedBankTransactions,
  resetTransaction,
  markTransactionNotBookable,
  markTransactionDuplicate,
  parentHeight,
  parentComponentId,
  onAllocateAccounts,
  unitTabDirty,
  setUnitTabDirty,
  allocationProposals,
  selectedType,
  setSelectedType,
  types,
}: UnitAllocationProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    allocationContentLoading, setAllocationContentLoading, unitAllocationAmounts,
  } = useContext(BankTransactionAllocationContext);

  const [showOnlyMarkedRows, setShowOnlyMarkedRows] = useState(false);

  const transactionStatements = useMemo(() => selectedBankTransactions.map(tx => ({
    bankTransactionId: tx.bankTransactionId,
    postingText: tx.purpose,
  })), [selectedBankTransactions.length, selectedBankTransactions[0]?.allocationGroupId]);

  const onChangeAllocationType = (type: ALLOCATION_TYPES) => {
    setSelectedType(type);
  };

  const parentComponent = document.getElementById(parentComponentId);

  const {
    unitAccountTable,
    unitAccountMap,
    resetPreviouslyAllocatedAmounts,
    setAppliedPreviousAllocationsOrProposal: setAppliedPreviousUnitAllocations,
    setUnitAccountMap,
    onSearch,
  } = useUnitAccounts({
    allocationGroupId: selectedBankTransactions[0]?.allocationGroupId,
    transaction: selectedBankTransactions[0] || {},
    bankTransactions: selectedBankTransactions,
    unitAllocationAmounts: unitAllocationAmounts || [],
    showOnlyMarkedRows,
    statements: transactionStatements,
    allocationProposals,
    parentHeight,
    parentComponent,
    setUnitTabDirty,
    selectedType,
  });
  const [allocatedSum, setAllocatedSum] = useState(0);
  const [alreadyAllocatedAmountOnTab, setAlreadyAllocatedAmountOnTab] = useState(0);

  const isUnitOrPrpOwnerAllocation = () => [ALLOCATION_TYPES.UNIT_OWNER, ALLOCATION_TYPES.TENANT, ALLOCATION_TYPES.PRP_OWNER].includes(selectedType);
  const isTransactionAllocated = selectedBankTransactions[0] && (selectedBankTransactions[0].transactionPaymentStatus === BankTransactionProjectionDtoTransactionPaymentStatusEnum.ASSIGNED);

  useEffect(() => {
    if (!selectedBankTransactions[0]?.allocationGroupId) return;

    if (isTransactionAllocated || !_.isEmpty(allocationProposals)) {
      setShowOnlyMarkedRows(true);
    }
  }, [isTransactionAllocated, allocationProposals]);


  useEffect(() => {
    setAppliedPreviousUnitAllocations(false);
    setUnitAccountMap(prev => prev.load({} as Map<string, FEAccountBalanceDto>).startLoading());
    setShowOnlyMarkedRows(false);
  }, [selectedBankTransactions[0]?.allocationGroupId]);


  const unsavedRemaining = useMemo(() => (
    Math.abs(selectedBankTransactions[0]?.remainingAmount!)
    + Math.abs(alreadyAllocatedAmountOnTab)
    - Math.abs(allocatedSum)
  ), [
    selectedBankTransactions[0]?.remainingAmount,
    alreadyAllocatedAmountOnTab,
    allocatedSum,
  ]);

  useEffect(() => {
    switch (selectedType) {
      case ALLOCATION_TYPES.PRP_OWNER:
      case ALLOCATION_TYPES.UNIT_OWNER:
      case ALLOCATION_TYPES.TENANT:
        setAlreadyAllocatedAmountOnTab(parseFloat(unitAllocationAmounts?.reduce((agg, account) => (account && account.sumToAllocate ? agg + Math.abs(account.sumToAllocate) : agg), 0).toFixed(2) || '0.00'));
        break;
      default:
        setAlreadyAllocatedAmountOnTab(0);
    }
  }, [selectedType, unitAllocationAmounts, selectedBankTransactions[0]?.remainingAmount]);


  useEffect(() => {
    if (isUnitOrPrpOwnerAllocation()) {
      let allocated: number = 0;
      try {
        allocated = Object.values(unitAccountMap.data || {})
          .filter(x => !!x.sumToAllocate)
          .map(row => (typeof row.sumToAllocate === 'string' ? formatCurrencyToNumber(row.sumToAllocate) : row.sumToAllocate))
          .reduce((sum, val) => sum + val, 0);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('update allocated: ', e);
      }
      setAllocatedSum(parseFloat(allocated.toFixed(2)));
    }
  }, [selectedType, unitAccountMap]);


  const onChangeShowOnlyMarkedRows = () => {
    setShowOnlyMarkedRows(oldState => !oldState);
  };

  const onBookUnitTransactionSuccess = (remAmount: number) => {
    if (remAmount !== 0) {
      resetPreviouslyAllocatedAmounts();
    }
    setAllocationContentLoading(false);
  };

  const onSubmitAllocation = (onTabChange?: boolean, callback?: () => void) => {
    setAllocationContentLoading(true);
    if (isUnitOrPrpOwnerAllocation()) {
      onAllocateAccounts(true, Object.values(unitAccountMap.data || {}) || [], onBookUnitTransactionSuccess, onTabChange, callback);
    }
  };

  const isUnitTabDirty = (isUnitOrPrpOwnerAllocation()) && unitTabDirty;

  const shouldDisableExecute = round2dec(unsavedRemaining) < 0
    || (allocatedSum === 0 && [
      BankTransactionProjectionDtoTransactionPaymentStatusEnum.UNASSIGNED,
      BankTransactionProjectionDtoTransactionPaymentStatusEnum.WONT_BE_ALLOCATED,
    ].includes(selectedBankTransactions[0]?.transactionPaymentStatus as BankTransactionProjectionDtoTransactionPaymentStatusEnum))
    || !isUnitTabDirty;


  const onClickSubmitAllocationOnTabChange = (callback: () => void) => onSubmitAllocation(true, callback);
  const onClickSubmitAllocationOnClickButton = () => onSubmitAllocation(false);

  const typeSelectorDisabled = allocationContentLoading;

  /** Because of a virtualization bug we need to force a re-render after datasource was updated
   *  or if the table becomes empty (via searching). */
  const tableKey = useMemo(() => nanoid(), [unitAccountMap.loaded, unitAccountTable.dataSource?.length === 0]);
  return (

    <div className="table-wrapper">
      <BankTransactionAllocationDetails
        bankTransaction={selectedBankTransactions[0] || {}}
        remainingAmount={parseFloat(unsavedRemaining.toFixed(2))}
        amountAllocated={allocatedSum}
        numberOfTransactions={selectedBankTransactions.length}
      />
      <div className="input-row">
        <SelectInput
          onChange={(type) => {
            if (isUnitTabDirty) {
              // save only if there were changes
              onClickSubmitAllocationOnTabChange(() => onChangeAllocationType(type));
            } else {
              onChangeAllocationType(type);
            }
          }}
          value={selectedType}
          options={types}
          className="dropDown"
          disabled={typeSelectorDisabled}
        />
        <AllocationSearchBar
          onSearch={onSearch}
          checkboxValue={showOnlyMarkedRows}
          onChangeCheckbox={onChangeShowOnlyMarkedRows}
          checkboxDisabled={allocationContentLoading}
          checkboxLabel={`${tl(translations.bankTransactionAllocation.showMarked)} ${tl(translations.bankTransactionAllocation.markedAccounts)}`}
        />

        <MenuButton
          transactionState={selectedBankTransactions[0]?.transactionPaymentStatus}
          resetTransaction={resetTransaction}
          markTransactionNotBookable={markTransactionNotBookable}
          markTransactionDuplicate={markTransactionDuplicate}
        />

        <Button
          className="section-submit"
          type="primary"
          disabled={shouldDisableExecute}
          loading={allocationContentLoading}
          onClick={() => {
            onClickSubmitAllocationOnClickButton();
          }}
        >
          {tl(translations.bankTransactionAllocation.allocateButtonLabel)}
        </Button>
      </div>
      <SmartTable key={tableKey} {...unitAccountTable} />
    </div>
  );
}
