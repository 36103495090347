import _ from 'lodash';
import { useContext } from 'react';
import {
  EconomicPlanSimpleProjection,
  HouseMoneySettlementSimpleProjection,
  OwnersMeetingProtocolDtoStatusEnum,
  WkaProjectionDto,
} from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import useMoreForm from 'elements/FormElements/FormSection/UseMoreForm';
import moment from 'moment';
import { ownersMeetingInvitationTranslations } from '../../translations/OwnersMeetingInvitationTranslations';

export const UseUnitSpecificDocuments = (ownersMeeting: any, setDirty: (dirty: boolean) => void, setOwnersMeeting: any, houseMoneySettlementEconomicYears: HouseMoneySettlementSimpleProjection[], economicPlans: EconomicPlanSimpleProjection[], wkas: WkaProjectionDto[]) => {
  const { tl } = useContext(LanguageContext);
  const isSent = [OwnersMeetingProtocolDtoStatusEnum.SENT, OwnersMeetingProtocolDtoStatusEnum.DONE].includes(ownersMeeting.status);


  const duplicateDocumentType = (index: number) => {
    const { name } = ownersMeeting.documentTypes[index];

    return ownersMeeting.documentTypes
      .filter((documentType: any) => documentType && documentType.saved && documentType.name === name)
      .length > 0;
  };

  const documentTypeInput = (i: number) => {
    let disabled = (ownersMeeting.documentTypes && ownersMeeting.documentTypes[i]) ? ownersMeeting.documentTypes[i].saved : false;
    if (isSent) {
      disabled = true;
    }
    const content: any = [{
      type: 'text',
      key: `documentTypes[${i}].name`,
      props: {
        label: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.content.documentTypes),
        disabled,
      },
    }];
    if (!(ownersMeeting.documentTypes && ownersMeeting.documentTypes[i]) || !ownersMeeting.documentTypes[i].saved) {
      // if not saved, make a save button
      content.push({
        type: 'button',
        key: `documentTypes[${i}].saved`,
        props: {
          children: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.content.save),
          onClick: () => {
            const tempOwnersMeeting = _.cloneDeep(ownersMeeting);
            tempOwnersMeeting.documentTypes[i].saved = true;
            setOwnersMeeting((om: any) => om.load(tempOwnersMeeting));
            setDirty(true);
          },
          disabled: !(ownersMeeting.documentTypes && ownersMeeting.documentTypes[i]) || _.isEmpty(ownersMeeting.documentTypes[i].name) || duplicateDocumentType(i),
        },
      });
    } else {
      // if saved, create delete button
      content.push({
        type: 'button',
        key: `documentTypes[${i}].saved`,
        props: {
          children: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.content.delete),
          onClick: () => {
            // delete additional documents with this type from units
            const tempOwnersMeeting = _.cloneDeep(ownersMeeting);
            tempOwnersMeeting.units.forEach((unit: any) => {
              unit.additionalDocuments = unit.additionalDocuments.filter((additionalDocument: any) => additionalDocument.typeName !== ownersMeeting.documentTypes[i].name);
            });
            // delete the documentType
            tempOwnersMeeting.documentTypes.splice(i, 1);
            // avoid setting it to -1
            if (tempOwnersMeeting.numberOfDocumentTypes > 0) {
              tempOwnersMeeting.numberOfDocumentTypes -= 1;
            }
            setOwnersMeeting((om: any) => om.load(tempOwnersMeeting));
            setDirty(true);
          },
        },
      });
    }

    content.push({});
    return [content];
  };

  const documentTypes = useMoreForm({
    value: ownersMeeting,
    key: 'numberOfDocumentTypes',
    content: documentTypeInput,
  });

  const houseMoneySettlement = () => [({
    type: 'select',
    key: 'houseMoneySettlementId',
    props: {
      allowClear: true,
      onChange: (id:number) => {
        if (id === undefined) {
          setOwnersMeeting((om: any) => om.load({ ...om.data, houseMoneySettlementId: null }));
          return;
        }
        const { economicYear } = houseMoneySettlementEconomicYears.filter(hga => hga.id === id)[0]!;
        const wkaId = wkas.filter(wka => moment(wka.endDate).year().toString() === economicYear && moment(wka.startDate).year().toString() === economicYear)[0]?.id;
        setOwnersMeeting((om: any) => om.load({ ...om.data, houseMoneySettlementId: id, wkaId }));
      },
      label: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.content.houseMoneySettlement.houseMoneySettlementEconomicYear),
      options: houseMoneySettlementEconomicYears ? houseMoneySettlementEconomicYears.map((houseMoneySettlementSimpleProjection: HouseMoneySettlementSimpleProjection) => ({
        value: houseMoneySettlementSimpleProjection.id,
        label: houseMoneySettlementSimpleProjection.economicYear,
      })) : [],
      disabled: isSent,
    },
  }),
  {},
  {}];


  const economicPlan = () => [({
    type: 'select',
    key: 'economicPlanId',
    props: {
      label: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.content.economicPlan.economicPlanTitle),
      options: economicPlans.map((ep: EconomicPlanSimpleProjection) => ({
        value: ep.id,
        label: ep.title,
      })),
      allowClear: true,
      disabled: isSent,
    },
  }),
  {},
  {}];

  const wkaInput = () => [({
    type: 'select',
    key: 'wkaId',
    props: {
      label: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.content.wkaTitle),
      options: wkas.map((wka: WkaProjectionDto) => ({
        value: wka.id,
        label: wka.title,
      })),
      allowClear: true,
      disabled: isSent,
    },
  }),
  {},
  {}];

  return {
    sectionId: 'unitSpecificDocuments',
    title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.title),
    content: [
      economicPlan(),
      houseMoneySettlement(),
      wkaInput(),
      ...documentTypes,
      [
        {
          type: 'addButton',
          key: 'numberOfDocumentTypes',
          props: {
            label: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.unitSpecificDocuments.content.addMoreDocumentType),
            disabled: isSent,
          },
        },
      ],
    ],
  };
};
