import { ExtendedExchangeProjectionTypeEnum, PropertyLegacyDtoVatRelevanceEnum } from 'api/accounting';
import { formatCurrency } from 'lib/Utils';
import { ExtendedBankTransactionProperty } from 'pages/BankTransactions/interfaces';
import { useContext, useRef, useState } from 'react';
import {
  ServiceCompanyAllocationValues, AllocationVatEligibilityValuesStateContext, SelectInputValuesStateContext, AllocationAmountsStateContext,
} from '../../../../services/ServiceCompanyAllocationContext';
import { calculateVatAmount, calculateVatEligibilityAmount } from '../../../../services/serviceCompanyAllocationUtils';

export const useVatEligibilityCell = (record: ServiceCompanyAllocationValues, properties: ExtendedBankTransactionProperty[]) => {
  const allocationVatEligibilityValues = useContext(AllocationVatEligibilityValuesStateContext);
  const selectInputValues = useContext(SelectInputValuesStateContext);
  const allocationAmounts = useContext(AllocationAmountsStateContext);

  const [modalVisible, setModalVisible] = useState(false);

  const previouslyFocusedElement = useRef(null);


  if (allocationVatEligibilityValues === undefined || selectInputValues === undefined || allocationAmounts === undefined) {
    throw new Error('useVatEligibilityCell must be used within a ServiceCompanyAllocationContextProvider');
  }

  const { key, type: exchangeType } = record;

  const { vatEligibilityPercentage } = allocationVatEligibilityValues?.find(alloc => alloc.key === key) ?? {};

  const { vatPercentage, propertyHrId } = selectInputValues?.find(s => s.key === key) ?? {};
  const amount = allocationAmounts?.find(alloc => alloc.key === key)?.currentAmount;


  const commaSeparatedPercentageValues = Array.isArray(record.vatEligibilityPercentage)
    ? record.vatEligibilityPercentage.filter(v => v !== undefined).map(vat => `${vat} %`).join(', ')
    : `${record.vatEligibilityPercentage || '-'} %`;


  const commaSeparatedAmountValues = Array.isArray(record.vatEligibilityAmount)
    ? record.vatEligibilityAmount.filter(v => v !== undefined).map(a => `${formatCurrency(Math.abs(a))} `).join(', ')
    : formatCurrency(Math.abs(record.vatEligibilityAmount));

  const onClickCell = () => {
    const selectedPropertyIsVatRelevant = [PropertyLegacyDtoVatRelevanceEnum.PARTIALLY_RELEVANT].includes(properties.find(p => (p.propertyHrId === record.propertyHrId || p.propertyHrId === propertyHrId))?.vatRelevance);

    if (exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED && selectedPropertyIsVatRelevant) {
      // store in a ref the element that triggered the modal opening (i.e. that had focus)
      previouslyFocusedElement.current = document.activeElement;
      setModalVisible(true);
    }
  };

  const onCloseModal = () => {
    setModalVisible(false);

    setTimeout(() => {
      // focus the element that was active when the modal was opened (for keyboard navigation)
      previouslyFocusedElement.current?.focus();
      previouslyFocusedElement.current = null;
    });
  };

  const vatAmount = calculateVatAmount(vatPercentage, amount, 1); // this is only for display, we do not care about the sign
  const vatEligibilityAmount = calculateVatEligibilityAmount(vatEligibilityPercentage, vatAmount, 1);
  const vatEligibilityPercentages = exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? `${vatEligibilityPercentage ?? 0} %` : commaSeparatedPercentageValues;
  const vatEligibilityAmounts = exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? formatCurrency(Math.abs(vatEligibilityAmount ?? 0), '0,00 €') : commaSeparatedAmountValues;

  return {
    vatEligibilityAmounts,
    vatEligibilityPercentages,
    onClickCell: exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? onClickCell : undefined,
    modalVisible,
    onCloseModal,
  };
};
