import React, { useContext } from 'react';
import './AnalyticsDashboard.scss';
import { NavigationTiles } from 'pages/Dashboard/components/NavigationTiles';
import { NavigationTileItem } from 'pages/Dashboard/components/NavigationTiles/interfaces';
import { LanguageContext } from 'contexts/LanguageContext';
import Heading from 'storybook-components/typography/Heading/Heading';
import { useAnalyticsDomainDataList } from '../analyticsDomainDataList';

export const AnalyticsDashboard: React.FC = () => {
  const { tl } = useContext(LanguageContext);
  const { analyticsDomainData } = useAnalyticsDomainDataList();
  const analyticsTiles: NavigationTileItem[] = [];
  if (analyticsDomainData) {
    analyticsDomainData.flatMap(group => group.pages).forEach((page) => {
      analyticsTiles.push({
        icon: '',
        title: tl(page.title),
        key: tl(page.title),
        url: page.path,
      });
    });
  }

  return (
    <div className="AnalyticsDashboard page">
      <div className="container">
        <div className="dashboard-header-row">
          <Heading level={1}>
            Analytics
          </Heading>
        </div>
        <NavigationTiles tileItems={analyticsTiles} />
      </div>
    </div>
  );
};
