import { Modal } from 'antd';
import { Configuration, DocumentLegacyControllerApi } from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useContext } from 'react';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { translations } from './translations';

export default function useWatermarkEditorHeader(watermarkId: number, isWatermarkDirty: boolean, newFileUploaded: boolean) {
  const { goBack } = useContext(OverlayContext);
  const { tl } = useContext(LanguageContext);

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document') as unknown as Configuration);

  const onClose = () => {
    if (watermarkId && isWatermarkDirty) {
      Modal.confirm({
        title: tl(translations.confirmDialog.close.confirmationTitle),
        content: tl(translations.confirmDialog.close.confirmationText),
        okText: tl(translations.confirmDialog.ok),
        cancelText: tl(translations.confirmDialog.cancel),
        onOk: async () => {
          // WatermarkId is a string but needs to be cast to number for the backend API
          const documentIds = [watermarkId];

          try {
            if (newFileUploaded) {
              // delete document only if file was updated
              await documentControllerApi.deleteDocumentUsingDELETE({ documentIds });
            }
            goBack();
            // Do not display any notification on success -> The delete should happen invisibly to the user.
          } catch (err) {
            console.error(err);
            showNotification({
              key: 'discardWatermarkChangesError',
              message: tl(translations.error.couldNotDiscardChangesMessage),
              description: tl(translations.error.couldNotDiscardChangesDescription),
              type: 'error',
            });
          }
        },
        okButtonProps: { className: 'Button' },
        cancelButtonProps: { className: 'Button' },
        icon: false,
        width: 450,
      });
    } else {
      goBack();
    }
  };

  return {
    onClose,
  };
}
