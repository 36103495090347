import { useState } from 'react';
import { useMessagesSelectionContext } from '../../service/MessagesListContext';

export const useMessagesActionBar = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const {
    selectedRowKeysCurrent,
    selectedRowKeysTotal,
    showAllSelected,
    setSelectedRowKeysCurrent,
    setSelectedRowKeysTotal,
    setShowAllSelected,
  } = useMessagesSelectionContext('useMessagesActionBar');

  const nrSelectedMessagesCurrent = selectedRowKeysCurrent.length;
  const nrSelectedMessagesTotal = selectedRowKeysTotal.length;

  const onUnselectAll = () => {
    setSelectedRowKeysCurrent([]);
    setSelectedRowKeysTotal([]);
    setShowAllSelected(false);
  };

  const onChangeShowAllSelected = (checked: boolean) => {
    setShowAllSelected(checked);
  };
  const onChangeTooltipVisible = (v: boolean) => {
    setTooltipVisible(v);
  };

  return {
    nrSelectedMessagesCurrent,
    nrSelectedMessagesTotal,
    actionBarVisible: nrSelectedMessagesTotal > 0,
    showWarningIcon: selectedRowKeysCurrent.length !== selectedRowKeysTotal.length,
    showAllSelected,
    onChangeShowAllSelected,
    onUnselectAll,
    tooltipVisible,
    onChangeTooltipVisible,
  };
};
