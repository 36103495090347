import { GetUnitContractsUsingGETContractTypesEnum, UnitContractControllerApi, UnitContractProjectionDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import DEFAULT_DATA from 'lib/data';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useRef } from 'react';
import { useAllContractsForMultiplePropertiesContext } from './AllContractsForMultiplePropertiesContex';
import _ from 'lodash';
import { translations } from '../translations';


const DEFAULT_EMPTY_LOADING = DEFAULT_DATA<UnitContractProjectionDto[]>([]).startLoading();

type Props = {
  propertyIds: number[] | undefined,
  contractTypes?: GetUnitContractsUsingGETContractTypesEnum[],
}
export const useAllContractsForMultipleProperties = ({
  propertyIds,
  contractTypes,
}: Props) => {

  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  const {
    allContractsForMultipleProperties,
    setAllContractsForMultipleProperties,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  } = useAllContractsForMultiplePropertiesContext('useAllContractsForMultipleProperties');

  const isCacheValid = _.isEqual(paramOfCachedValue, propertyIds);


  useEffect(() => {
    if (_.isEmpty(propertyIds) || isCacheValid) return;

    setParamOfFetchInProgress((prevParamOfFetchInProgress) => {
      // this must be inside the setParamOfFetchInProgress to make sure we have the latest
      // value of `paramOfFetchInProgress`, in case another instance of this hook
      // already triggered the fetch
      const doesCurrentParamMatchTheParamOfTheInProgressFetch = _.isEqual(prevParamOfFetchInProgress, propertyIds);

      if (prevParamOfFetchInProgress === undefined || !doesCurrentParamMatchTheParamOfTheInProgressFetch) {
        fetchContracts();

        return propertyIds;
      }

      return prevParamOfFetchInProgress;
    });
  }, [propertyIds, isCacheValid]);


  const fetchContracts = () => {
    if (_.isEmpty(propertyIds)) {
      throw new Error('Trying to fetch unit contracts with undefined propertyId');
    }

    abortController.current?.abort();
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setAllContractsForMultipleProperties(prev => prev.startLoading());
    const unitContractPromises = propertyIds.map(pId => unitContractControllerApi.getUnitContractsUsingGET({ propertyId: pId, contractTypes: contractTypes as unknown as GetUnitContractsUsingGETContractTypesEnum }));
    Promise.all(unitContractPromises)
      .then((results) => {
        const tempContracts: UnitContractProjectionDto[] = [];
        results.forEach((res) => {
          tempContracts.push(...res);
        });
        setAllContractsForMultipleProperties(prev => prev.load(tempContracts));
        setParamOfCachedValue(propertyIds);
      }).catch((err) => {
        if (signal.aborted) return;
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.loadContractsError),
        });
        setAllContractsForMultipleProperties(prev => prev.failed(err));
      })
      .finally(() => {
        setParamOfFetchInProgress(undefined);
      });;
  };

  return {
    allContractsForMultipleProperties: isCacheValid || allContractsForMultipleProperties.error
      ? allContractsForMultipleProperties
      : DEFAULT_EMPTY_LOADING,
  };
};
