import { EconomicPlanContext } from 'pages/EconomicPlan/services/EconomicPlanContext';
import { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  EconomicPlanControllerApi,
  EconomicPlanDto,
  EconomicPlanDtoTypeEnum,
  EconomicPlanSimpleProjection,
  EconomicPlanSimpleProjectionStatusEnum,
} from '../../../../api/accounting';
import { AuthContext } from '../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../../contexts/PropertyListContext';
import useSmartTable from '../../../../elements/SmartTable/useSmartTable';
import { showNotification } from '../../../../lib/Notification';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { useEconomicPlanListColumns } from './useEconomicPlanListColumns';
import { Action } from '../../../../elements/SmartTable/data';
import { useCopyEconomicPlan } from '../../services/useCopyEconomicPlan';
import { useDeleteEconomicPlan } from '../../services/useDeleteEconomicPlan';

export default function useEconomicPlanList() {
  const { tl } = useContext(LanguageContext);
  const location = useLocation();
  const history = useHistory();
  const economicPlanContext = useContext(EconomicPlanContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const { copyEconomicPlan } = useCopyEconomicPlan();
  const { deleteEconomicPlan } = useDeleteEconomicPlan();

  if (economicPlanContext === undefined) {
    throw new Error('useEconomicPlanList must be used within a EconomicPlanContextProvider');
  }

  const {
    selectedProperty,
  } = useContext(PropertyListContext);

  const {
    economicPlanList,
    setEconomicPlanList,
  } = economicPlanContext;

  useEffect(() => {
    loadEconomicPlanList();
  }, [selectedProperty.data?.id]);

  const loadEconomicPlanList = (resetPage: boolean = true) => {
    const propertyId = selectedProperty.data?.id;
    if (propertyId) {
      setEconomicPlanList(state => state.startLoading());
      economicPlanControllerApi.getAllUsingGET({
        propertyId,
        page: resetPage ? 0 : economicPlanList.page,
        size: 50,
      })
        .then((response) => {
          response.content.sort((a, b) => a.startDate.localeCompare(b.startDate));
          setEconomicPlanList(state => state.loadPaged(
            response.content, resetPage, response.last,
          ));
        })
        .catch(() => {
          setEconomicPlanList(state => state.failed());
          showNotification({
            key: 'loadEconomicPlanListError',
            message: tl(economicPlanTranslations.economicPlanList.loadEconomicPlanListError.message),
            description: tl(economicPlanTranslations.economicPlanList.loadEconomicPlanListError.description),
            type: 'error',
          });
        });
    }
  };

  const dataSource = useMemo(() => (economicPlanList.data ? economicPlanList.data.map((economicPlan: EconomicPlanSimpleProjection) => ({
    ...economicPlan,
    translatedStatus: economicPlan.status ? tl(economicPlanTranslations.economicPlanList.status[economicPlan.status]) : '',
  })) : []), [economicPlanList.data]);

  const actionsMenu: Action[] = [
    {
      label: tl(economicPlanTranslations.actions.copy),
      onAction: (record: EconomicPlanSimpleProjection) => {
        copyEconomicPlan(record.id);
      },
      actionSupported: () => true,
    },
    {
      label: tl(economicPlanTranslations.actions.delete),
      onAction: (record: EconomicPlanSimpleProjection) => {
        deleteEconomicPlan(record.id);
      },
      actionSupported: (record: EconomicPlanSimpleProjection) => record.status === EconomicPlanSimpleProjectionStatusEnum.DRAFT,
    },
  ];


  const smartTable = useSmartTable({
    tableName: 'economicPlanList',
    columns: useEconomicPlanListColumns(),
    dataSource,
    contentLoading: economicPlanList.loading,
    onRow: (economicPlan: EconomicPlanDto) => ({
      onClick: (() => {
        if (economicPlan.type === EconomicPlanDtoTypeEnum.ACCOUNT_DISTRIBUTION) {
          history.push(`${location.pathname}/view/${economicPlan.id}`);
        } else {
          history.push(`${location.pathname}/unit-figures/${economicPlan.id}`);
        }
      }),
    }),
    infiniteScrollerProps: {
      hasMoreData: !economicPlanList.lastPage,
      loadMoreData: () => {
      },
    },
    rowKey: 'id',
    actionsMenu,
  });


  return {
    smartTable,
    economicPlanList,
    loadEconomicPlanList,
  };
}
