import './ReportDocumentCover.scss';

import ReactDOM from 'react-dom';

import { ReportDocumentCover } from './ReportDocumentCover';

interface ReportDocumentCoverWithPortalProps {
  mountNodeId: string,
  managementCompanyName?: string,
  managementCompanyAddress?: string,
  recipientAddressLines?: string[],
  additionalData?: {label: string, value: string}[],
  title: string,
  propertyName?: string,
  reportInfo?: { label: string, value: string }[],
  introductionText?: string[]
}


export const ReportDocumentCoverWithPortal = ({
  mountNodeId,
  managementCompanyName,
  managementCompanyAddress,
  recipientAddressLines,
  additionalData,
  title,
  reportInfo,
  introductionText,
}: ReportDocumentCoverWithPortalProps) => {
  const content = (
    <ReportDocumentCover
      managementCompanyName={managementCompanyName}
      managementCompanyAddress={managementCompanyAddress}
      recipientAddressLines={recipientAddressLines}
      additionalData={additionalData}
      title={title}
      reportInfo={reportInfo}
      introductionText={introductionText}
    />
  );
  return ReactDOM.createPortal(content, document.getElementById(mountNodeId) ?? document.getElementById('print'));
};

ReportDocumentCoverWithPortal.defaultProps = {
  additionalData: undefined,
  reportInfo: undefined,
  introductionText: undefined,
};
