import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import { useContext } from 'react';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import Page from 'storybook-components/layout/Page/Page';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import ErrorMessage from 'storybook-components/feedback/ErrorScreen/ErrorMessages/ErrorMessage';
import { documentPreviewTranslations } from './translations';
import { useDocumentPreviewPage } from './useDocumentPreviewPage';
import './DocumentPreviewPage.scss';


const DocumentPreviewPage = (): JSX.Element => {
  const {
    title, subtitle, url, filename, goBack, loading, inGeneration, generationFailed,
  } = useDocumentPreviewPage();
  const { tl } = useContext(LanguageContext);

  const getPageContent = () => {
    if (generationFailed) {
      return (
        <ErrorMessage message={tl(documentPreviewTranslations.generationError)} />
      );
    }
    if (loading || inGeneration) {
      return (
        <LoadingScreen
          title={inGeneration ? tl(documentPreviewTranslations.generationText.title) : undefined}
          subtitle={inGeneration ? tl(documentPreviewTranslations.generationText.subtitle) : undefined}
        />
      );
    }

    return <SimplePdfViewer url={url} filename={filename} renderTextLayer />;
  };

  return (
    <Page className="DocumentPreviewPage">
      <PageHeader
        title={title}
        subtitle={subtitle}
        rightSideComponent={(
          <Button
            type="text"
            className="back-button"
            onClick={goBack}
          >
            {tl(documentPreviewTranslations.back)}
          </Button>
        )}
      />
      <PageContent className="pdf-wrapper">
        {getPageContent()}
      </PageContent>
    </Page>
  );
};


export default DocumentPreviewPage;
