import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { economicPlanAccountEditingTranslations } from '../../economicPlanAccountEditingTranslations';
import defaultSection from '../defaultSection';
import ReserveFundsTable from './ReserveFundsTable';
import { useSaveAccountAmounts } from '../../services/useSaveAccountAmounts';

interface useReserveFundsSection3Props {
  addDirt: () => void,
  economicPlan: any,
  economicPlanCreationData: any,
  setEconomicPlan: (value: any) => void,
}

export default function useReserveFundsSection3({
  addDirt, economicPlan, setEconomicPlan, economicPlanCreationData,
}: useReserveFundsSection3Props) {
  const { tl } = useContext(LanguageContext);

  const { saveAccountAmounts } = useSaveAccountAmounts();

  const onChange = (accountCode: string, value: number) => {
    addDirt();
    setEconomicPlan((ep: any) => {
      const newEconomicPlan = { ...ep.data };
      newEconomicPlan.reserveFunds[accountCode] = value;

      return ep.load(newEconomicPlan);
    });
  };

  const dataSource = useMemo(() => {
    const ds = economicPlanCreationData.data?.reserveFundAccounts || [];
    ds.map((reserveAccount: any) => {
      reserveAccount.account = `${(reserveAccount.accountCode)}${reserveAccount.accountName ? `-${reserveAccount.accountName}` : ''}`;
      reserveAccount.value = economicPlan.data?.reserveFunds ? economicPlan.data?.reserveFunds[reserveAccount.accountCode] || 0 : 0;
    });
    return _.cloneDeep(ds);
  }, [economicPlanCreationData, economicPlan]);

  return {
    ...defaultSection({
      index: 2, openingIndex: 2, economicPlan, economicPlanCreationData,
    }),
    sectionTitle: tl(economicPlanAccountEditingTranslations.sections.reserveFundsSection.title),
    sectionId: 'ReserveFunds',
    onSubmit: saveAccountAmounts,
    content: [
      {
        sectionId: 'ReserveFundsSubSection',
        content: <ReserveFundsTable
          dataSource={dataSource}
          loading={economicPlanCreationData.loading}
          onChange={onChange}
        />,
      },
    ],
  };
}
