import moment from 'moment';
import { useContext } from 'react';
import { nanoid } from 'nanoid';
import { EconomicPlanContext } from '../../../../services/EconomicPlanContext';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { pastBookingsTranslations } from '../pastBookingsTranslations';

export const usePastBookings = () => {
  const economicPlanContext = useContext(EconomicPlanContext);

  if (economicPlanContext === undefined) {
    throw new Error('usePastBookings must be used within a EconomicPlanContextProvider');
  }

  const { economicPlan } = economicPlanContext;
  const { tl, language } = useContext(LanguageContext);

  const now = moment();

  const dataSource = [];
  const dateIterator = moment(economicPlan.data.startDate).locale(language);
  for (; dateIterator.isBefore(now); dateIterator.add(1, 'months')) {
    dataSource.push({
      key: nanoid(),
      bookingDate: moment(dateIterator),
      bookingText: `${tl(pastBookingsTranslations.houseMoney)} ${dateIterator.format('MMMM')} ${dateIterator.year()}`,
      bankOrder: tl(pastBookingsTranslations.noBankOrders),
    });
  }

  return {
    dataSource,
  };
};
