export const translations: any = {
  modal: {
    title: {
      en: ' refers to a year with a finalized HGA. Proceeding with the actions might render the generated HGA  invalid.',
      de: ' bezieht sich auf ein Jahr mit einer beschlossenen HGA. Wenn Sie fortfahren, könnte die generierte HGA ungültig werden.',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    review: {
      en: 'Review HGA',
      de: 'HGA einsehen',
    },
    proceed: {
      en: 'Agree & Proceed',
      de: 'Zustimmen & Fortfahren',
    },
    checkError: {
      en: 'Status of HGA could not be determined for given year.',
      de: 'HGA Status konnte nicht bestimmt werden für dieses Jahr.',
    },
  },

};
