import { useContext } from 'react';

import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';
import useDefaultPropertyFilters from '../../../components/Header/components/data/useDefaultPropertyFilters';


export const ACCOUNT_BALANCE_DEFAULT_FILTERS = {
  administrationTypes: [
    PropertyLegacyDtoAdministrationTypeEnum.WEG,
    PropertyLegacyDtoAdministrationTypeEnum.MV,
  ],
};


export default function useAccountBalancesFilter(): Filter[] {
  const { tl } = useContext(LanguageContext);
  return [
    ...useDefaultPropertyFilters(),
    {
      type: 'text',
      name: tl(translations.pages.accountBalances.table.headers.supervisor),
      key: 'supervisorName',
    },
    {
      type: 'text',
      name: tl(translations.pages.accountBalances.table.headers.accountant),
      key: 'accountantName',
    },
  ];
}
