import { ContractProjectionDto, GetMessagesUsingGETSourceTypeEnum, SpecialContributionControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { buildDocumentRecipients, MAX_PAGE_SIZE } from 'lib/messageUtils';
import { showNotification } from 'lib/Notification';
import { isEmpty, uniq } from 'lodash';
import { MessageSendingInitializationStateType } from 'pages/MessageSendingPage/interfaces';
import { useAllContractsList } from 'pages/Property/PropertyEditing/services/useAllContractsList';
import { getSpecialContributionSendingRoute } from 'pages/SpecialContribution/routes';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { LanguageContext } from 'contexts/LanguageContext';
import { getMessagePath } from 'pages/Messages/routes';
import { DocumentApi, GetDocumentsByFilterUsingGETOrderEnum, GetDocumentsByFilterUsingGETSourceTypeEnum } from 'api/public';
import { SpecialContributionCreationContext } from './SpecialContributionCreationContext';
import { translations } from '../../translations';

export const useSendSpecialContribution = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const specialContributionContext = useContext(SpecialContributionCreationContext);
  if (specialContributionContext === undefined) {
    throw Error('useSendSpecialContribution must be used withing SpecialContributionCreationContextProvider');
  }

  const {
    specialContribution, documents, setLoading, setDocuments,
  } = specialContributionContext;

  const { allContractsList } = useAllContractsList(specialContribution?.propertyId);

  const { apiConfiguration, publicApiConfiguration } = useContext(AuthContext);
  const specialContributionControllerApi = new SpecialContributionControllerApi(apiConfiguration('accounting'));
  const documentControllerApi = new DocumentApi(publicApiConfiguration('public'));

  const onSend = () => {
    if (isEmpty(documents)) {
      setLoading(true);
      // if no documents, trigger generation
      specialContributionControllerApi.generateSpecialContributionDocumentUsingPOST({ id: specialContribution?.id })
        .then(() => {
          documentControllerApi.getDocumentsByFilterUsingGET({
            sourceType: GetDocumentsByFilterUsingGETSourceTypeEnum.SPECIAL_CONTRIBUTION,
            sourceId: specialContribution?.id,
            sort: 'unitRank',
            order: GetDocumentsByFilterUsingGETOrderEnum.ASC,
            size: MAX_PAGE_SIZE,
          })
            .then((resp) => {
              setDocuments(resp.content);
              navigateToSend(resp.content);
            });
        }).catch(() => {
          showNotification({
            key: 'generationTriggered',
            message: tl(translations.specialContribution.regenerateDocumentError),
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    } else {
      navigateToSend(documents);
    }
  };

  const navigateToSend = (docs) => {
    const contractIds = uniq(docs?.filter(d => d.contractId).map(d => d.contractId));
    const documentRecipients = docs?.filter(d => d.contractId !== undefined).map((doc) => {
      const contract = allContractsList.data?.find(c => c.unitContractId === doc.contractId);
      return buildDocumentRecipients(doc?.id, [contract as unknown as ContractProjectionDto]);
    });

    const overview = docs?.find(d => !d.contractId);
    if (overview) {
      documentRecipients.push(buildDocumentRecipients(overview?.id, allContractsList.data?.filter(c => contractIds.includes(c.unitContractId)) as unknown as ContractProjectionDto[]));
    }

    const messageSendingNavigationState: MessageSendingInitializationStateType = {
      sourceType: 'SPECIAL_CONTRIBUTION',
      sourceId: specialContribution?.id,
      properties: [{
        propertyId: specialContribution?.propertyId,
        documents: [...documentRecipients],
      }],
    };

    const sendUrl = getSpecialContributionSendingRoute({ specialContributionId: specialContribution?.id });
    history.replace(sendUrl, messageSendingNavigationState);
  };

  const onCheckMessages = () => {
    history.push(getMessagePath(specialContribution?.id, GetMessagesUsingGETSourceTypeEnum.SPECIAL_CONTRIBUTION));
  };

  return {
    onSend,
    onCheckMessages,
  };
};
