import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { useSectionManager } from 'services/SectionManager/useSectionManager';

import { translations } from '../../../translations';
import {
  useRecalculateButton,
} from '../ProfitAndLossReportEditorForm/components/ProfitAndLossReportRecalculateButton/useRecalculateButton';
import { useCreateProfitAndLossReport } from './useCreateProfitAndLossReport';

const ProfitAndLossReportEditorHeaderButtons = () => {
  const { goBack } = useContext(OverlayContext);
  const { tl } = useContext(LanguageContext);
  const { openSectionId } = useSectionManager();
  const { onRecalculate, loading: loadingRecalculate } = useRecalculateButton();
  const { onCreateReport, loading: loadingCreate } = useCreateProfitAndLossReport();

  return (
    <>
      <Button
        type="text"
        onClick={goBack}
      >
        {tl(translations.report.header.closeButton)}
      </Button>
      <Button
        onClick={onRecalculate}
        loading={loadingRecalculate}
        disabled={openSectionId !== 1}
      >
        {tl(translations.report.header.recalculateButton)}
      </Button>
      <Button
        type="primary"
        disabled={openSectionId !== 1}
        loading={loadingCreate}
        onClick={onCreateReport}
      >
        {tl(translations.report.header.saveButton)}
      </Button>
    </>
  );
};

export default ProfitAndLossReportEditorHeaderButtons;
