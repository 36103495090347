import { ContractContactCreationDtoRoleEnum } from 'api/accounting';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';


export const translations = {
  sectionTitles: {
    coreInformation: {
      en: 'Core Information',
      de: 'Vertragsdetails',
    },
    rentPlan: {
      en: 'Rent Plan',
      de: 'Mietzahlung',
    },
    securityDeposit: {
      en: 'Security Deposit',
      de: 'Kaution',
    },
    contractDistribution: {
      en: 'Contract distribution',
      de: 'Vertragsverteilung',
    },
  },
  header: {
    title: {
      OWNER: {
        en: 'Create owner contract',
        de: 'Eigentümervertrag erstellen',
      },
      TENANT: {
        en: 'Create tenant contract',
        de: 'Mietvertrag erstellen',
      },
      PROPERTY_OWNER: {
        en: (administrationType: string) => `${administrationType} Owner Contract`,
        de: (administrationType: string) => `${administrationType} Eigentümervertrag`,
      },
      VACANCY: {
        en: 'Vacancy',
        de: 'Leerstand',
      },
    },
    saveAndClose: {
      en: 'Save & Close',
      de: 'Speichern & Schließen',
    },
    saveAndOpenNext: {
      en: (nextUnit: string) => `Save & Open next Unit ${nextUnit}`,
      de: (nextUnit: string) => `Speichern & Nächste Einheit ${nextUnit} öffnen`,
    },
    openNext: {
      en: (nextUnit: string) => `Open next Unit ${nextUnit}`,
      de: (nextUnit: string) => `Nächste Einheit ${nextUnit} öffnen`,
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    findUnitError: {
      en: 'Unit not found',
      de: 'Einheit nicht gefunden',
    },
  },
  subSectionTitles: {
    contacts: {
      en: 'Contacts',
      de: 'Kontakte',
    },
    contractDetails: {
      en: 'Contract details',
      de: 'Vertragsdetails',
    },
    vacancyDetails: {
      en: 'Details',
      de: 'Details',
    },
  },
  prompt: {
    en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
    de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie weiter?',
  },
  table: {
    headers: {
      contacts: {
        en: 'Contacts',
        de: 'Kontakte',
      },
      contactType: {
        en: 'Roles',
        de: 'Rolle',
      },
    },
    empty: {
      en: "You haven't added any contacts yet.\nAdd contacts via button above.",
      de: 'Sie haben noch keine Kontakte hinzugefügt.\nBitte fügen Sie Kontakte über den Knopf oben rechts hinzu.',
    },
    contactType: {
      [ContractContactCreationDtoRoleEnum.OWNER]: {
        en: 'Owner',
        de: 'Eigentümer',
      },
      [ContractContactCreationDtoRoleEnum.TENANT]: {
        en: 'Tenant',
        de: 'Mieter',
      },
      [ContractContactCreationDtoRoleEnum.COUNCIL]: {
        en: 'Council',
        de: 'Beirat',
      },
      [ContractContactCreationDtoRoleEnum.HEAD_OF_COUNCIL]: {
        en: 'Head of Council',
        de: 'Beiratsvorsitz',
      },
      [ContractContactCreationDtoRoleEnum.PROPERTY_OWNER]: {
        en: 'Property Owner',
        de: 'Objekteigentümer',
      },
      [ContractContactCreationDtoRoleEnum.LEGAL_REPRESENTATIVE]: {
        en: 'Legal Representative',
        de: 'Bevollmächtigte',
      },
      [ContractContactCreationDtoRoleEnum.SUBTENANT]: {
        en: 'Subtenant',
        de: 'Untermieter',
      },
      [ContractContactCreationDtoRoleEnum.RESIDENT]: {
        en: 'Resident',
        de: 'Bewohner',
      },
      [ContractContactCreationDtoRoleEnum.LEGAL_GUARDIAN]: {
        en: 'Legal Guardian',
        de: 'Vormund',
      },
    },
  },
  saveAndContinue: {
    en: 'Save and Continue',
    de: 'Speichern und Weiter',
  },
  addContactButtonLabel: {
    en: 'Add contact',
    de: 'Kontakt hinzufügen',
  },
  contractNumber: {
    en: 'Contract-ID',
    de: 'Vertragsnr.',
  },
  sevFlag: {
    label: {
      en: 'SEV relevant',
      de: 'SEV relevant',
    },
    options: {
      yes: {
        en: 'Yes',
        de: 'Ja',
      },
      no: {
        en: 'No',
        de: 'Nein',
      },
    },
    tooltip: {
      en: 'Select "Yes" if you handle the accounting on the contract.',
      de: 'Wählen Sie “Ja”, wenn Sie die Buchhaltung für den Vertrag abwickeln.',
    },
  },
  startDate: {
    en: 'Start date',
    de: 'Startdatum',
  },
  endDate: {
    en: 'End date',
    de: 'Enddatum',
  },
  signedDate: {
    en: 'Signed date',
    de: 'Unterzeichnet am',
  },
  mailingContact: {
    en: 'Contact/Shipping address',
    de: 'Kontakt/Versandadresse',
  },
  bankAccount: {
    en: 'Bank account',
    de: 'Bankkonto',
  },
  vatRelevantLabel: {
    en: 'VAT relevant',
    de: 'Umsatzsteuerpflichtig',
  },
  reducedVatRelevant: {
    en: 'Reduced VAT relevant',
    de: 'Ermäßigt umsatzsteuerpflichtig',
  },
  vatRelevant: {
    en: 'VAT relevant',
    de: 'Umsatzsteuerpflichtig',
  },
  notVatRelevant: {
    en: 'Not VAT relevant',
    de: 'Nicht umsatzsteuerpflichtig',
  },
  vatRelevanceTooltip: {
    en: 'If you select VAT relevance for this contract, all postings including the allocation of bank transactions will include VAT. Also in the reports the VAT will be shown separately. The selection cannot be deleted after saving.',
    de: 'Wenn Sie für diesen Vertrag Mehrwertsteuerrelevanz auswählen, wird bei allen Buchungen inklusive dem Zuweisen von Banktransaktionen MwSt mit verbucht. Zusätzlich wird in den Abrechnungen die MwSt separat ausgewiesen. Die Auswahl kann nach dem Speichern nicht mehr gelöscht werden.',
  },
  loadBankAccountsError: {
    en: 'Failed to load bank accounts',
    de: 'Fehler beim Laden der Bankkonten',
  },
  selectPlaceholder: {
    en: 'Please select...',
    de: 'Bitte auswählen...',
  },
  addMandate: {
    en: 'Add mandate',
    de: 'Lastschriftmandat hinzufügen',
  },
  dunningLevel: {
    dunningLevelOptions: {
      noDunning: {
        en: 'No Dunning',
        de: 'Kein Mahnlauf',
      },
      levelOne: {
        en: '1. Dunning Level',
        de: '1. Mahnstufe',
      },
      levelTwo: {
        en: '2. Dunning Level',
        de: '2. Mahnstufe',
      },
      levelThree: {
        en: '3. Dunning Level',
        de: '3. Mahnstufe',
      },
      excluded: {
        en: 'Excluded',
        de: 'Ausgeschlossen',
      },
    },
    label: {
      en: 'Dunning level',
      de: 'Mahnwesen',
    },
    tooltip: {
      en: 'When excluded, you cannot select the contract in the dunning anymore. You can change this setting any time.',
      de: 'Durch das Ausschließen kann der Vertrag im Mahnwesen nicht mehr ausgewählt werden. Sie können diese Einstellung jederzeit ändern.',
    },
  },
  closePreviousModal: {
    title: {
      en: 'Updating active contract',
      de: 'Aktualisierung des aktiven Vertrags',
    },
    content: {
      en: (name: string, endDate: string) => `Would you like to update the previous contracts end date of ${name} to ${endDate}?`,
      de: (name: string, endDate: string) => `Möchten Sie das Enddatum des vorangegangenen Vertrages von ${name} auf den ${endDate} abändern?`,
    },
    yes: {
      en: 'Yes',
      de: 'Ja',
    },
    no: {
      en: 'No',
      de: 'Nein',
    },
  },
  notifications: {
    INVALID_ROLE_OF_CONTACT: {
      en: (contractType: string) => `There should be at least one contact with the ${contractType} role`,
      de: (contractType: string) => `Es sollte mindestens ein Kontakt mit der Rolle des ${contractType} bestehen`,
    },
    loadBankAccountsError: {
      en: 'Error loading bank accounts',
      de: 'Fehler beim Laden der Bankkonten',
    },
    loadOwnerContractError: {
      en: 'Failed to load SEV owner contract.',
      de: 'Eigentümervertrag konnte nicht geladen werden.',
    },
    loadContractGroupError: {
      en: 'Failed to load unit contract',
      de: 'Verträge der Einheit konnten nicht geladen werden',
    },
    saveSuccess: {
      en: 'Unit contract saved successfully.',
      de: 'Vertrag erfolgreich gespeichert.',
    },
    saveError: {
      en: 'Could not save unit contract.',
      de: 'Vertrag konnte nicht gespeichert werden.',
    },
    MISSING_ADDRESS: {
      en: 'Could not save unit contract, mailing contact does not have and address',
      de: 'Der Vertrag konnte nicht gespeichert werden, da für den Kontakt keine Adresse hinterlegt ist.',
    },
    loadUnitContractError: {
      en: 'Failed to load unit contract',
      de: 'Verträge der Einheit konnten nicht geladen werden',
    },
    loadUnitDetailsError: {
      en: 'Failed to load unit details',
      de: 'Der Einheit konnten nicht geladen werden',
    },
    mandateSaveError: {
      en: 'Failed to save direct debit mandate data',
      de: 'Daten des Lastschriftmandats konnten nicht gespeichert werden.',
    },
    mandateDeactivateSuccess: {
      en: 'Mandate deactivated successfully',
      de: 'Lastschriftmandat wurde erfolgreich deaktiviert.',
    },
    mandateDeactivateError: {
      en: 'Failed to deactivate mandate',
      de: 'Lastschriftmandat konnte nicht deaktiviert werden.',
    },
    downloadError: {
      en: 'Download failed',
      de: 'Download fehlgeschlagen',
    },
    confirmStopRentPlan: {
      title: {
        en: 'Information',
        de: 'Sind Sie sicher, dass Sie fortfahren möchten?',
      },
      okText: {
        en: 'Yes',
        de: 'Speichern',
      },
      cancelText: {
        en: 'No',
        de: 'Abbrechen',
      },
      content: {
        en: "Upon stopping this rent plan already created postings and payments won't be updated, only upcoming ones. Therefore please check the correctness of related postings and payments. If there are incorrect cases, please revert the postings/payments and create new ones.\nDo you want to continue?",
        de: 'Beim Stoppen der Miete werden bereits erstellte Buchungen und Zahlungen nicht aktualisiert, sondern nur zukünftige. Bitte prüfen Sie daher selbstständig die Richtigkeit der anstehenden zugehörigen Buchungen und Zahlungen. Falls inkorrekte Fälle vorliegen, setzen Sie die Buchungen bzw. Zahlungen bitte zurück und erstellen Sie neue.',
      },
    },
    confirmDepositInstallmentOutOfMandateValidity: {
      title: {
        en: 'Information',
        de: 'Sind Sie sicher, dass Sie fortfahren möchten?',
      },
      okText: {
        en: 'Yes',
        de: 'Speichern',
      },
      cancelText: {
        en: 'No',
        de: 'Abbrechen',
      },
      content: {
        en: 'At least one direct debit installment is outside of the boundaries of the DD mandate. Do you want to save the installment as a self-payer?',
        de: 'Mindestens eine Rate liegt außerhalb der Gültigkeit des Lastschriftmandats. Möchten Sie die Rate als Selbstzahler speichern?',
      },
    },
    rentPlanCopySuccess: {
      en: 'Successfully created a copy of the rent plan.',
      de: 'Kopie der Mietzahlung wurde erfolgreich erstellt.',
    },
    rentPlanCopyError: {
      en: 'Failed to create a copy of the rent plan.',
      de: 'Kopie der Mietzahlung konnte nicht erstellt werden.',
    },
  },
  warnings: {
    subtitle: {
      en: 'Confirm your changes',
      de: 'Bitte bestätigen Sie Ihre Änderungen an',
    },
    startDate: {
      en: 'Start date',
      de: 'Startdatum des Vertrags',
    },
    endDate: {
      en: 'End date',
      de: 'Enddatum des Vertrags',
    },
    bankAccount: {
      en: 'Bank account',
      de: 'Bankkonto',
    },
    directDebitMandate: {
      en: 'Direct Debit Mandate',
      de: 'Lastschriftmandats',
    },
    rentPlanUpdate: {
      en: 'The end date of active rent plans will be updated or stopped.',
      de: 'Das Enddatum von aktiven Mietplänen wird erneuert oder gestoppt.',
    },
    noPostingOrBankOrderUpdateWarning: {
      en: 'Existing postings and payments remain unchanged, affecting only future ones. Revert and create new postings if incorrect.',
      de: 'Existierende Buchungen und Zahlungen bleiben unverändert. Diese Änderungen betreffen nur zukünftige Buchungen. Falls nötig, stornieren Sie bestehende und erstellen Sie neue Buchungen.',
    },
    noOwnerChange: {
      en: 'The contract\'s contacts. For an owner change, we recommend to create a new contract via the unit contracts table.',
      de: 'Kontakte der Verträge. Bei einem Eigentümerwechsel empfehlen wir die Erstellung eines neuen Vertrags über die Einheiten-Tabelle.',
    },
    noTenantChange: {
      en: 'The contract\'s contacts. For a tenant change, we recommend to create a new contract via the unit contracts table.',
      de: 'Kontakte der Verträge. Bei einem Mieterwechsel empfehlen wir die Erstellung eines neuen Vertrags über die Einheiten-Tabelle.',
    },
    contractCausingOverlap: {
      en: 'This contract overlaps with the following contracts. You can update the start & end dates of these contracts or continue to save this contract anyway.',
      de: 'Dieser Vertrag überschneidet sich mit den folgenden Verträgen. Sie können die Start- und Enddaten dieser Verträge aktualisieren oder diesen Vertrag trotzdem speichern',
    },
    vacancyLabel: {
      en: 'Vacancy',
      de: 'Leerstand',
    },
    warningModalActions: {
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      save: {
        en: 'Save contract',
        de: 'Vertrag Speichern',
      },
    },
    vatRelevanceUpdate: {
      en: 'The changes you made to the vat-relevance will be reflected in the eligible share of the property. However, the updated eligible share will be applied to future postings. Existing postings will remain as they are.',
      de: 'Änderungen der Umsatzsteuerrelevanz können auch den Anteil der anrechenbaren Umsatzsteuer ändern. Bitte beachten Sie, dass ab sofort mit dem neuen Wert gebucht wird. Bereits existierende Buchungen bleiben bestehen.',
    },
    changeContractVatRelevanceModal: {
      title: {
        en: 'Updated eligible share',
        de: 'Aktualisierung des anrechenbaren USt.-Anteils',
      },
      contentLine1: {
        en: 'The changes you made to the vat-relevance of the vacany will be reflected in the eligible share of the property.',
        de: 'Änderungen der Umsatzsteuerrelevanz bei Leerständen können auch den Anteil der anrechenbaren Umsatzsteuer ändern.',
      },
      contentLine2: {
        en: 'However, the updated eligible share will be applied to future postings. Existing postings will remain as they are.',
        de: 'Bitte beachten Sie, dass ab sofort mit dem neuen Wert gebucht wird. Bereits existierende Buchungen bleiben bestehen.',
      },
      okText: {
        en: 'Confirm',
        de: 'Bestätigen',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
    etg24ExportInfo: {
      tenant: {
        en: 'ETG24 doesn’t support the "Resident" and "Subtenant" roles. Therefore, if the contact is assigned only one or both of these roles, it will not appear in the portal. If another role is assigned to the contact along with these roles, it will appear in the portal. Not assigning any role will also cause the contact not to appear in the portal.',
        de: 'ETG24 unterstützt die Rollen "Bewohner" und "Untermieter" nicht. Wenn dem Kontakt also nur eine oder beide dieser Rollen zugewiesen sind, wird er nicht im Portal angezeigt. Wenn dem Kontakt neben diesen Rollen eine weitere Rolle zugewiesen wird, erscheint er im Portal. Wenn Sie gar keine Rolle zuweisen, wird der Kontakt ebenfalls nicht im Portal angezeigt.',
      },
      deleteWarning: {
        en: 'If you later delete all assigned roles or assign only unsupported roles, the contact will be deleted from the portal.',
        de: 'Wenn Sie später alle zugewiesenen Rollen löschen oder nur “nicht unterstützte” Rollen zuweisen, wird der Kontakt aus dem Portal gelöscht.',
      },
      overlappingContractsWarning: {
        en: (
          <p>
            {'Overlapping contracts will not appear in the portal. Because ETG24 does not support overlapping. You can resolve the overlaps or '}
            <a href={articleUrls.unitContractsHistory.overlappingContracts} target="blank">read our article on alternative solutions</a>
            {' .'}
          </p>
        ),
        de: (
          <p>
            {'Sich überschneidende Verträge werden im Portal nicht angezeigt da ETG24 dies nicht unterstützt. Sie können die Überschneidungen anpassen oder sich '}
            <a href={articleUrls.unitContractsHistory.overlappingContracts} target="blank">in diesem Supportartikel</a>
            über weitere Lösungen informieren.
          </p>
        ),
      },
    },
  },
  directDebitMandate: {
    title: {
      en: 'Direct Debit Mandate',
      de: 'Lastschriftmandate',
    },
    mandateStatus: {
      en: 'Mandate status',
      de: 'Mandats-Status',
    },
    useThisMandate: {
      en: 'Use this Mandate',
      de: 'Lastschriftmandat nutzen',
    },
    mandateId: {
      en: 'Mandate id',
      de: 'Mandatsnr.',
    },
    validFrom: {
      en: 'Valid from',
      de: 'Gültig ab',
    },
    signedAt: {
      en: 'Signed at',
      de: 'Unterschrieben am',
    },
    options: {
      download: {
        en: 'Download Direct Debit Mandate',
        de: 'Lastschriftmandat herunterladen',
      },
      deactivate: {
        en: 'Deactivate',
        de: 'Deaktivieren',
      },
    },
    modal: {
      title: {
        en: 'Mandate Deactivation',
        de: 'Deaktivieren',
      },
      confirmDeactivateMessagePart1: {
        en: "Please note that by updating the Direct Debit Mandate selection already created payments won't be updated, only upcoming ones. Therefore please check the correctness of upcoming payments. Additionally upon deactivating the mandate it won't be used anymore over all contracts. These are the contracts for which this mandate is currently in use:",
        de: 'Bitte beachten Sie, dass bei der Änderung der Auswahl des Lastschriftmandats bereits erstellte Zahlungen nicht aktualisiert werden sondern nur zukünftige. Bitte prüfen Sie daher selbstständig die Richtigkeit der anstehenden Zahlungen. Weiterhin wird das Mandat bei Deaktivierung für keinen Vertrag mehr genutzt. Dies sind die Verträge, für welches das Mandat aktuell genutzt wird:',
      },
      confirmDeactivateMessagePart2: {
        en: 'Are you sure you want to proceed?',
        de: 'Sind Sie sicher, dass Sie fortfahren möchten?',
      },
      yes: {
        en: 'Yes',
        de: 'Ja',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      currentlyOpen: {
        en: ' (currently open)',
        de: ' (aktuell offen)',
      },
    },
  },
  rentPlanOverview: {
    overview: {
      en: 'Overview',
      de: 'Übersicht',
    },
    addPlan: {
      en: 'Add plan',
      de: 'Miete hinzufügen',
    },
    createlan: {
      en: 'Create rent plan',
      de: 'Miete hinzufügen',
    },
    notifications: {
      getRentPlansError: {
        en: 'Failed to load rent plans.',
        de: 'Mietzahlungen konnten nicht geladen werden.',
      },
      getBookableRentAccountsError: {
        en: 'Failed to load rent accounts.',
        de: 'Mietkonten konnten nicht geladen werden.',
      },
      rentPlanActivationError: {
        en: 'Failed to activate rent plan.',
        de: 'Mietzahlung konnte nicht aktiviert werden.',
      },
      rentPlanActivationBankAccountError: {
        en: 'To activate the rent plan, you need to create a rent bank account for the property.',
        de: 'Um die Mietzahlung zu aktivieren, müssen Sie ein Bankkonto der Kategorie Miete für das Objekt erstellen.',
      },
      rentPlanDateValidationErrorOnActivation: {
        description: {
          en: (startDate: string, endDate: string) => `The rent plan has to be within the runtime of the tenant contract. The start date is ${startDate} and the end date is ${endDate}. You can adapt these dates any time.`,
          de: (startDate: string, endDate: string) => `Die Mietzahlung muss innerhalb der Laufzeit des Mietvertrags liegen. Das Startdatum ist ${startDate} und das Enddatum ist ${endDate}. Sie können diese Daten jederzeit anpassen.`,
        },
        notDefined: {
          en: 'not defined',
          de: 'nicht definiert',
        },
      },
      rentPlanDeletionError: {
        en: 'Failed to delete rent plan',
        de: 'Mietzahlung konnte nicht gelöscht werden.',
      },
      rentPlanStoppingError: {
        en: 'Failed to stop rent plan',
        de: 'Mietzahlung konnte nicht gestoppt werden.',
      },
      rentPlanRevertingToDraftError: {
        en: 'Failed to revert rent plan to draft.',
        de: 'Mietzahlung konnte nicht auf Entwurf zurückgesetzt werden.',
      },
    },
    rentPlanActivationModal: {
      title: {
        en: 'Confirm rent plan activation',
        de: 'Bestätigen Sie die Aktivierung der Mietzahlung',
      },
      content: {
        en: (endDateForPreviousPlan: string, postingStartDate: string, paymentStartDate: string) => `If there is a previously active rent plan then it's end date will be updated to ${endDateForPreviousPlan}.\nThe debt postings for this rent plan will be generated starting from ${postingStartDate} and the payments starting from ${paymentStartDate}.`,
        de: (endDateForPreviousPlan: string, postingStartDate: string, paymentStartDate: string) => `Sofern es bereits eine aktive Miete gibt, wird das Enddatum auf ${endDateForPreviousPlan} aktualisiert.\nDie Sollstellungen für diese Miete werden ab dem ${postingStartDate} erstellt, die Zahlungen ab dem ${paymentStartDate}.`,
      },
      activate: {
        en: 'Activate',
        de: 'Aktivieren',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
    table: {
      headers: {
        total: {
          en: 'Total',
          de: 'Gesamt',
        },
        status: {
          en: 'Status',
          de: 'Status',
        },
        startDate: {
          en: 'Change Date',
          de: 'Änderungsdatum',
        },
        nextGenerationDate: {
          en: 'Next generation date',
          de: 'Nächste Ausführung',
        },
        lastGenerationDate: {
          en: 'Last generation date',
          de: 'Letzte Ausführung',
        },
      },
      statuses: {
        DRAFT: {
          en: 'Draft',
          de: 'Entwurf',
        },
        FINISHED: {
          en: 'Finished',
          de: 'Abgeschlossen',
        },
        STOPPED: {
          en: 'Stopped',
          de: 'Gestoppt',
        },
        ACTIVATED: {
          en: 'Active',
          de: 'Aktiv',
        },
        FUTURE: {
          en: 'Future',
          de: 'Zukünftig',
        },
      },
      options: {
        open: {
          en: 'Open',
          de: 'Öffnen',
        },
        edit: {
          en: 'Edit',
          de: 'Bearbeiten',
        },
        delete: {
          en: 'Delete',
          de: 'Löschen',
        },
        activate: {
          en: 'Activate',
          de: 'Aktivieren',
        },
        revertToDraft: {
          en: 'Revert to draft',
          de: 'Auf Entwurf zurücksetzen',
        },
        stop: {
          en: 'Stop',
          de: 'Stoppen',
        },
        copy: {
          en: 'Create a copy',
          de: 'Kopie erstellen',
        },
      },
      deleteDisabledTooltip: {
        en: 'Cannot be deleted as transactions already have occurred. ',
        de: 'Mietplan kann nicht gelöscht werden da bereits Zahlungen vorhanden.',
      },
      checkTransactions: {
        en: 'Check out transactions here.',
        de: 'Sehen Sie sich die Transaktionen hier.',
      },
      emptyMessage: {
        en: "You haven't added any rent plans yet.\nAdd a rent plan via button above.",
        de: 'Sie haben noch keine Mietzahlung erstellt.\nFügen Sie diese über den Button oben hinzu.',
      },
    },
    deleteModal: {
      title: {
        en: 'Warning',
        de: 'Warnung',
      },
      message1: {
        en: 'The rentplan and connected bookings will be deleted.',
        de: 'Der Mietplan und damit verbundene Buchungen werden gelöscht.',
      },
      message2: {
        en: 'Please note that booked bank deposits from self-payers will not be deleted. These must be reverted separately.',
        de: 'Bitte beachten Sie, dass verbuchte Bankeinzahlungen von Selbstzahlern nicht gelöscht werden. Diese müssen separat storniert werden.',
      },
      message3: {
        en: 'Do you want to continue?',
        de: 'Möchten Sie fortfahren?',
      },
      okText: {
        en: 'Delete',
        de: 'Löschen',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    }
  },
  securityDeposit: {
    subSectionTitle: {
      en: 'Type',
      de: 'Art',
    },
    depositTypes: {
      cashDeposit: {
        en: 'Cash deposit',
        de: 'Barkaution',
      },
      transaction: {
        en: 'Transaction',
        de: 'Überweisung',
      },
      guarantee: {
        en: 'Guarantee',
        de: 'Bankbürgschaft',
      },
      pledge: {
        en: 'Pledged savings book',
        de: 'Verpfändetes Sparbuch',
      },
      noDeposit: {
        en: 'No deposit',
        de: 'Keine Kaution',
      },
    },
    addInstallmentButtonLabel: {
      en: 'Add Installment',
      de: 'Rate hinzufügen',
    },
    table: {
      emptyMessage: {
        en: 'You haven’t created any installments yet.\n Add installments via button above.',
        de: 'Sie haben noch keine Rate hinzugefügt.\n Sie können diese über den Button oben hinzufügen.',
      },
      columns: {
        installmentNr: {
          en: 'Installment',
          de: 'Rate',
        },
        dueDate: {
          en: 'Due date',
          de: 'Fälligkeitsdatum',
        },
        amount: {
          en: 'Amount',
          de: 'Betrag',
        },
        processing: {
          en: 'Processing',
          de: 'Abwicklung',
        },
      },
    },
    noGenerateOrderAlert: {
      message: {
        en: 'Direct Debits cannot be generated.',
        de: 'Lastschriftzahlungen können nicht generiert werden.',
      },
      noDepositBankAccountForProperty: {
        en: 'The property has no deposit bank account.',
        de: 'Für das Objekt ist kein Kautionskonto hinterlegt.',
      },
      noMandateForContract: {
        en: 'The contract has no active Direct Debit Mandate.',
        de: 'Der Vertrag hat kein aktives Lastschriftmandat.',
      },
      noDepositBankAccountForProperties: {
        en: 'The following properties have no deposit bank account:',
        de: 'Für die folgenden Objekte ist kein Kautionskonto hinterlegt:',
      },
      noMandateForContracts: {
        en: 'The following contracts have no active Direct Debit Mandate:',
        de: 'Die folgenden Verträge haben kein aktives Lastschriftmandat:',
      },
      endStatement: {
        en: 'If you want Direct Debits to be generated, please adjust these inputs.',
        de: 'Wenn Sie möchten, dass Lastschriften generiert werden, passen Sie bitte diese Eingaben an.',
      },
    },
    processingTypes: {
      NOT_NEEDED: {
        en: 'Self payer',
        de: 'Selbstzahler',
      },
      GENERATE_ORDER: {
        en: 'Direct debit',
        de: 'Lastschriftzahlung',
      },
    },
    notifications: {
      getDepositsError: {
        en: 'Failed to load installments',
        de: 'Raten konnten nicht geladen werden',
      },
      deleteInstallmentError: {
        en: 'Failed to delete installment.',
        de: 'Raten konnten nicht gelöscht werden.',
      },
      saveInstallmentError: {
        en: 'Failed to save installment.',
        de: 'Raten konnten nicht gespeichert werden.',
      },
      saveInstallmentDepositAccountError: {
        en: 'Property needs to have a bank account of type Deposit, please create one first.',
        de: 'Das Objekt benötigt ein Kautionskonto. Bitte erstellen Sie dieses zuerst.',
      },
    },
    deleteInstallmentConfirmation: {
      title: {
        en: 'Warning',
        de: 'Warnung',
      },
      content: {
        en: "Please note that upon deletion postings get corrected, but already executed payments won't be updated. Therefore please check the correctness of payments before execution and revert them if necessary. Would you like to continue?",
        de: 'Bitte beachten Sie, dass bei der Löschung Buchungen korrigiert werden, bereits ausgeführte Zahlungen jedoch nicht aktualisiert werden. Bitte prüfen Sie daher selbstständig die Richtigkeit der anstehenden Zahlungen und setzen diese bei Bedarf zurück. Möchten Sie fortfahren?',
      },
      okText: {
        en: 'Yes',
        de: 'Ja',
      },
      cancelText: {
        en: 'No',
        de: 'Nein',
      },
    },
    footer: {
      save: {
        en: 'Save',
        de: 'Speichern',
      },
      saveAndContinue: {
        en: 'Save and Continue',
        de: 'Speichern und Weiter',
      },
    },
  },
  rentChange: {
    title: {
      en: 'Rent modification',
      de: 'Mieterhöhungen',
    },
    type: {
      en: 'Type',
      de: 'Typ',
    },
    typeOptions: {
      scaleRent: {
        en: 'Scale rent',
        de: 'Staffelmiete',
      },
      indexRent: {
        en: 'Index rent', de: 'Indexmiete',
      },
      localComparableRent: {
        en: 'Local Comparable Rent', de: 'Ortsübliche Vergleichsmiete',
      },
      accordingToAgreement: {
        en: 'According to agreement', de: 'Nach Vereinbarung',
      },
      none: {
        en: 'None', de: 'Keine',
      },
    },
    notifications: {
      getError: {
        en: 'Failed to load rent change',
        de: 'Fehler beim Laden der Mieterhöhungen',
      },
      saveError: {
        en: 'Failed to save rent change',
        de: 'Mieterhöhungen konnten nicht gespeichert werden',
      },
    },
  },
  contractDistribution: {
    subSectionTitle: {
      en: 'Excluded accounts',
      de: 'Ausgeschlossene Konten',
    },
    subSectionInfoIconText: {
      en: 'The costs of the specified accounts will be split among all contracts, however the contracts share will not be applied to him and also not appear in his reports.',
      de: 'Die Kosten für die ausgewählten Konten werden an alle Verträge verteilt, der Anteil dieses Vertrags wird jedoch nicht an ihn ausgewiesen und erscheint auch auf keinen Berichten.',
    },
    buttons: {
      addAccountButton: {
        en: 'Exclude account',
        de: 'Konto ausschließen',
      },
      saveButton: {
        en: 'Save',
        de: 'Speichern',
      },
    },
    table: {
      emptyMessage: {
        en: "You haven't added any accounts yet.\nAdd an account via button above.",
        de: 'Sie haben noch keine Konten ausgewählt.\nFügen Sie diese über den Button open hinzu.',
      },
      accountNumber: {
        en: 'Account-Nr.',
        de: 'Konto-Nr.',
      },
      accountName: {
        en: 'Name',
        de: 'Name',
      },
      accountSelectorPlaceholder: {
        en: 'Select account...',
        de: 'Konto auswählen...',
      },
    },
    notifications: {
      errorOnAccountsFetching: {
        en: 'Contract Distribution Accounts could not be loaded.',
        de: 'Vertragsverteilung konnte nicht geladen werden.',
      },
      errorOnSave: {
        en: 'Contract Distribution Accounts could not be saved.',
        de: 'Vertragsverteilung konnte nicht gespeichert werden.',
      },
      successOnSave: {
        en: 'Contract Distribution Accounts successfully saved.',
        de: 'Vertragsverteilung wurde erfolgreich gespeichert.',
      },
    },
    submitModal: {
      title: {
        en: 'Changes on Contract Distribution',
        de: 'Änderungen an der Vertragsverteilung',
      },
      contentLine1: {
        en: 'Changing the accounts a contract is excluded of results in changing the account distribution of the accounts for this contract.',
        de: 'Durch das Ändern der Konten, von welchen der Vertrag ausgeschlossen ist, ändert sich auch die Verteilung der Konten für diesen Vertrag.',
      },
      contentLine2: {
        en: 'If you have reports generated, you need to re-create them to receive an updated version.',
        de: 'Bereits erstellte Berichte müssen erneut erstellt werden, um eine aktuelle Version zu erhalten.',
      },
      contentLine3: {
        en: 'Are you sure you want to change the excluded accounts?',
        de: 'Sind Sie sicher, dass Sie die Auswahl der Konten ändern möchten?',
      },
      okText: {
        en: 'Change accounts',
        de: 'Konten ändern',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
  },
  validation: {
    endDateBeforeStartDateError: {
      en: 'Invalid date range.',
      de: 'Ungültiger Datumsbereich.',
    },
  },
};
