import { formatCurrency } from 'lib/Utils';
import React from 'react';
import './StrikethroughAmount.scss';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
  totalAmount: number,
  remainingAmount: number | undefined,
  direction?: 'incoming' | 'outgoing' | undefined,
}

const StrikethroughAmount = ({ totalAmount, remainingAmount, direction }: Props) => {
  const isPartiallyAllocated = remainingAmount && totalAmount !== remainingAmount;
  const clsName = isPartiallyAllocated ? 'partially-allocated' : 'unallocated';

  return (
    <Amount className="StrikethroughAmount">
      {isPartiallyAllocated ? <span className={`main-amount ${direction ?? ''} ${clsName}`}>{formatCurrency(Math.abs(remainingAmount!))}</span> : null}
      <span className={`${isPartiallyAllocated ? 'strikethrough-amount' : (direction ?? '')} ${clsName}`}>{formatCurrency(Math.abs(totalAmount))}</span>
    </Amount>
  );
};


StrikethroughAmount.defaultProps = {
  direction: undefined,
};


export default StrikethroughAmount;
