import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import OpenTenantAccountsTableSummary from './OpenTenantAccountsTableSummary';
import { useOpenTenantAccountsTable } from './useOpenTenantAccountsTable';


const OpenTenantAccountsTable = () => {
  const {
    columns,
    firstDataSource, firstPaidSum, firstShouldPaySum, firstBalanceWithinDateRangeSum, firstStartBalanceSum, firstEndBalanceSum,
    secondDataSource, secondPaidSum, secondShouldPaySum, secondBalanceWithinDateRangeSum, secondStartBalanceSum, secondEndBalanceSum,
  } = useOpenTenantAccountsTable();
  const { tl } = useContext(LanguageContext);

  return (
    <div className="OpenTenantAccountsTable">
      <Table
        columns={columns}
        dataSource={firstDataSource}
        pagination={false}
        summary={() => (
          <OpenTenantAccountsTableSummary
            title={tl(translations.report.sections.openTenantAccountsSection.firstSummary)}
            paidSum={firstPaidSum}
            shouldPaySum={firstShouldPaySum}
            balanceWithinDateRangeSum={firstBalanceWithinDateRangeSum}
            startBalanceSum={firstStartBalanceSum}
            endBalanceSum={firstEndBalanceSum}
          />
        )}
        rowKey="accountCode"
      />
      {secondDataSource.length !== 0
        && (
          <Table
            className="NoHeaderTable"
            columns={columns}
            dataSource={secondDataSource}
            pagination={false}
            summary={() => (
              <OpenTenantAccountsTableSummary
                title={tl(translations.report.sections.openTenantAccountsSection.secondSummary)}
                paidSum={secondPaidSum}
                shouldPaySum={secondShouldPaySum}
                balanceWithinDateRangeSum={secondBalanceWithinDateRangeSum}
                startBalanceSum={secondStartBalanceSum}
                endBalanceSum={secondEndBalanceSum}
              />
            )}
            rowKey="accountCode"
          />
        )
      }
    </div>
  );
};

export default OpenTenantAccountsTable;
