import { Modal } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';

interface StandingOrderStopModalProps {
  visible: boolean,
  onClickCancel: () => void,
  onClickDelete: () => void,
}

export function StandingOrderStopModal(props: StandingOrderStopModalProps) {
  const {
    visible, onClickCancel, onClickDelete,
  } = props;
  const { tl } = useContext(LanguageContext);
  return (
    <Modal
      visible={visible}
      title={tl(translations.standingOrderOverview.stopModal.title)}
      onCancel={onClickCancel}
      footer={[
        <Button key="cancel" onClick={onClickCancel}>
          {tl(translations.standingOrderOverview.stopModal.cancel)}
        </Button>,
        <Button
          key="delete"
          type="primary"
          onClick={onClickDelete}
        >
          {tl(translations.standingOrderOverview.stopModal.delete)}
        </Button>,
      ]}
    >
      <p>{tl(translations.standingOrderOverview.stopModal.body)}</p>
    </Modal>
  );
}
