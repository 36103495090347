import React, { useContext } from 'react';
import './BackNavigationButton.scss';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { ICONS } from '../../../components/icons';
import { OverlayContext } from '../../../services/OverlayContext/OverlayContext';

export default function BackNavigationButton(): JSX.Element {
  const { goBack } = useContext(OverlayContext);

  return (
    <Button className="BackNavigationButton" onClick={goBack}>
      <Icon component={ICONS.arrowBack} />
    </Button>
  );
}
