import ActionsDropdown from 'elements/SmartTable/components/OptionsMenu/ActionsDropdown';
import { DebtorBalancesGroupedType } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { useContractBalanceActions } from './useContractBalanceActions';

export type ContractBalanceActionsProps = {
  record: DebtorBalancesGroupedType,
}

export const ContractBalanceActions = ({ record } : ContractBalanceActionsProps) => {
  const { actions } = useContractBalanceActions(record);
  if (!record.isRootAccount) {
    return null;
  }

  return (
    <ActionsDropdown actions={actions} record={record} tableVerticallyScrollable={false} />
  );
};
