import Icon from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import './DropdownButton.scss';

export interface MenuItem {
  key: string,
  onClick?: () => void,
  label: string | React.ReactNode,
  disabled?: boolean,
  children?: MenuItem[],
  'data-testid'?: string,
}

interface DropdownButtonProps {
    buttonLabel: string,
    menuItems: MenuItem[],
    dropdownClassName?: string,
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement,
    buttonType?: ButtonType,
    dropdownTrigger?: ('click' | 'hover' | 'contextMenu')[],
    dropdownPlacement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight',
    icon?: React.FC,
    showIcon?: boolean,
    disabled?: boolean,
}

const getMenuItems = (menuItems: MenuItem[]) => (
  menuItems.map((option, index) => {
    if (option.children) {
      const addDivider = index > 0;

      return (
        <>
          {addDivider && <Menu.Divider />}
          <Menu.ItemGroup
            key={option.key}
            title={option.label}
          >
            {getMenuItems(option.children)}
          </Menu.ItemGroup>
        </>
      );
    }

    return (
      <Menu.Item
        key={option.key}
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
          option.onClick();
        }}
        disabled={option.disabled}
        data-testid={option['data-testid']}
      >
        {option.label}
      </Menu.Item>
    );
  })
);

export const DropdownButton = ({
  buttonLabel, dropdownClassName, menuItems, buttonType, dropdownTrigger, dropdownPlacement,
  icon, getPopupContainer, showIcon, disabled,
} : DropdownButtonProps) => {
  const menu = (
    <Menu getPopupContainer={getPopupContainer} inlineIndent={0}>
      {getMenuItems(menuItems)}
    </Menu>
  );

  return (
    <Dropdown
      trigger={dropdownTrigger}
      className={`DropdownButton ${dropdownClassName ?? ''} ${showIcon ? 'with-icon' : ''}`}
      overlay={menu}
      placement={dropdownPlacement}
      overlayClassName="DropdownButtonOverlay"
      getPopupContainer={getPopupContainer}
      disabled={disabled}
    >
      <Button type={buttonType}>
        {buttonLabel}
        {showIcon && <Icon component={icon} />}
      </Button>
    </Dropdown>
  );
};

DropdownButton.defaultProps = {
  dropdownClassName: undefined,
  buttonType: 'primary',
  dropdownTrigger: ['click'],
  dropdownPlacement: 'bottomRight',
  icon: ICONS.triangleDown,
  getPopupContainer: () => (document.body) as HTMLElement,
  showIcon: true,
  disabled: false,
};
