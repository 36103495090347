import { useContext } from 'react';
import { OwnersMeetingInvitationContext } from '../../../../contexts/OwnersMeetingInvitationContext';

export default function useDefaultSection(index: number, openingIndex: number) {
  const ownersMeetingContext: any = useContext(OwnersMeetingInvitationContext);
  return {
    sectionNumber: index + 1,
    openingNumber: openingIndex + 1,
    buttonLoading: ownersMeetingContext.ownersMeeting.loading,
    value: ownersMeetingContext.ownersMeeting.data,
  };
}
