import React from 'react';
import { capitalize } from 'lodash';
import './InformationBar.scss';

export interface InformationBarContent {
  label: string,
  value: number | string,
}

export interface InformationBarProps {
  content: InformationBarContent[];
  delimiter?: '‧' | '|';
  color?: 'green' | 'orange' | 'red' | 'gray';
  vertical?: boolean;
}

export const InformationBar = ({
  content,
  delimiter,
  color,
  vertical,
}: InformationBarProps) => {
  const className = `InformationBar InformationBar${capitalize(color)} ${vertical ? 'vertical' : ''}`;

  return (
    <div className={className}>
      {content.map((element: any, index: number) => (
        <>
          <div className="information-element" key={`info-${element?.label}`}>
            <span className="label">{`${element?.label}: `}</span>
            <span className="value">{`${element?.value}`}</span>
          </div>

          {index !== content.length - 1 && (
            <div className="delimiter">
              {vertical ? (<hr />) : delimiter}
            </div>
          )}
        </>
      ))}
    </div>
  );
};


export default InformationBar;


InformationBar.defaultProps = {
  color: 'gray',
  delimiter: '‧',
  vertical: false,
};
