import { ContractProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';


interface Props {
  children: React.ReactNode;
}


export const ActivePropertyOwnerContractsContext = createContext<{
  activePropertyOwnerContractList: DefaultDataInterface<ContractProjectionDto[]>,
  setActivePropertyOwnerContractList: React.Dispatch<React.SetStateAction<DefaultDataInterface<ContractProjectionDto[]>>>,
  paramOfCachedValue: number | undefined,
  paramOfFetchInProgress: number | undefined,
  setParamOfCachedValue: React.Dispatch<React.SetStateAction<number | undefined>>,
  setParamOfFetchInProgress: React.Dispatch<React.SetStateAction<number | undefined>>,
} | undefined>(undefined);


const ActivePropertyOwnerContractsProvider = ({ children }: Props) => {
  const [activePropertyOwnerContractList, setActivePropertyOwnerContractList] = useState(DEFAULT_DATA<ContractProjectionDto[]>([]));
  const [paramOfCachedValue, setParamOfCachedValue] = useState<number | undefined>(undefined);
  const [paramOfFetchInProgress, setParamOfFetchInProgress] = useState<number | undefined>(undefined);

  // putting them in objects so they are easier to destructure when using the contexts
  // no need to memoize since they're all in the same object
  const providerValue = {
    activePropertyOwnerContractList,
    setActivePropertyOwnerContractList,
    paramOfCachedValue,
    paramOfFetchInProgress,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  };

  return (
    <ActivePropertyOwnerContractsContext.Provider value={providerValue}>
      {children}
    </ActivePropertyOwnerContractsContext.Provider>
  );
};

export default ActivePropertyOwnerContractsProvider;
