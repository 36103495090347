import { Link } from 'react-router-dom';
import { useContractLink } from './useContractLink';
import { PropertyLegacyDto, UnitContractProjectionDto } from 'api/accounting';

const ContractLink = ({ property, contract } : {property: PropertyLegacyDto, contract: UnitContractProjectionDto}) => {
  const { label, url } = useContractLink(property, contract);
  return (
    <Link to={url} target="_blank">{label}</Link>
  );
};

export default ContractLink;
