import { ContractContactProjectionDtoRoleEnum } from 'api/accounting';
import { ContractState, ContractWarnings } from 'pages/UnitContractHistory/interfaces';

export const translations = {
  unit: {
    en: 'Unit',
    de: 'Einheit',
  },
  contracts: {
    en: 'contracts',
    de: 'Verträge',
  },
  noContractsMessage: {
    en: 'There is no contract created for this unit yet. You can add it via the button above.',
    de: 'Für diese Einheit wurde noch kein Vertrag erstellt. Über den Button oben können Sie einen Vertrag hinzufügen.',
  },
  header: {
    title: {
      en: 'All contracts',
      de: 'Alle Verträge',
    },
    findUnitError: {
      en: 'Unit not found',
      de: 'Einheit nicht gefunden',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
  },
  table: {
    status: {
      en: 'Status',
      de: 'Status',
    },
    contractNr: {
      en: 'Contract Nr.',
      de: 'Vertragsnr.',
    },
    contactName: {
      en: 'Contact Name',
      de: 'Kontakt Name',
    },
    startDate: {
      en: 'Start Date',
      de: 'Startdatum',
    },
    endDate: {
      en: 'End Date',
      de: 'Enddatum',
    },
    warnings: {
      en: 'Warnings',
      de: 'Warnungen',
    },
    rentPlan: {
      en: 'Rent',
      de: 'Mietzahlung',
    },
    SEV: {
      en: 'SEV',
      de: 'SEV',
    },
    economicPlan: {
      en: 'Economic plan',
      de: 'Wirtschaftsplan',
    },
    activeRentPlan: {
      en: 'Active',
      de: 'Aktiv',
    },
    notActive: {
      en: 'Not active',
      de: 'Nicht aktiv',
    },
    rentAmount: {
      en: 'Rent amount',
      de: 'Mietbetrag',
    },
    getRentPlansError: {
      en: 'Failed to load rent plans.',
      de: 'Mietzahlungen konnten nicht geladen werden.',
    },
    vacancy: {
      en: 'Vacancy',
      de: 'Leerstand',
    },
    groupHeader: {
      en: 'Contracts in etg24 (don\'t exist in impower)',
      de: 'Verträge in etg24, die nicht in Impower existieren',
    },
    overLappingContractsWarning: {
      message: {
        en: 'ETG24 doesn\'t support overlapping contracts, so they won\'t appear in the portal. To resolve the conflict, use the button in the "•••" menu or',
        de: 'ETG24 unterstützt keine sich überschneidenden Verträge. Sie werden daher nicht im Portal angezeigt. Um dies zu lösen, verwenden Sie die Schaltfläche im Menü "•••" oder',
      },
      articleLinkLabel: {
        en: 'read the article about other solutions.',
        de: 'lesen Sie diesen Artikel über andere Lösungsmöglichkeiten.',
      },
    },
    loadEtg24ContractsErrorMessage: {
      en: 'An error occured and the etg24 contracts which are not yet in Impower could not be loaded.',
      de: 'Ein Fehler ist aufgetreten. Die etg24 Verträge, welche noch nicht in Impower sind, konnten nicht geladen werden.',
    },
    vacantOwnerImportNotSupportedError: {
      en: 'Import failed. Impower does not support vacant owner contracts.',
      de: 'Import des Vertrages aus etg24 ist fehlgeschlagen. Impower unterstützt bei Eigentümerverträgen keinen Leerstand.'
    },
    importContractError: {
      en: 'An error occured and the etg24 contract could not be imported.',
      de: 'Import des Vertrages aus etg24 ist fehlgeschlagen.',
    },
    mergeContractError: {
      en: 'An error occured. Contracts could not be merged.',
      de: 'Zusammenführung mit einem Impower-Vertrag ist fehlgeschlagen.',
    },
  },
  SEVsModal: {
    title: {
      en: 'SEVs',
      de: 'SEVs',
    },
    okButtonText: {
      en: 'Open contract',
      de: 'Vertrag öffnen',
    },
    columns: {
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      owner: {
        en: 'Owner',
        de: 'Eigentümer',
      },
    },
  },
  warningTypes: {
    [ContractWarnings.GAP]: {
      en: 'Gap',
      de: 'Lücke',
    },
    [ContractWarnings.OVERRIDDEN]: {
      en: 'Overridden',
      de: 'Überschrieben',
    },
    [ContractWarnings.OVERWRITING]: {
      en: 'Overwriting',
      de: 'Überschreibt',
    },
    excludedContract: {
      en: 'This contract was excluded from the dunning.',
      de: 'Dieser Vertrag wurde vom Mahnwesen ausgeschlossen.',
    },
    etg24ActionNeeded: {
      en: 'Action needed',
      de: 'Aktion erforderlich',
    },
  },
  statuses: {
    [ContractState.ACTIVE]: {
      en: 'Active',
      de: 'Aktiv',
    },
    [ContractState.OVERRIDEN]: {
      en: 'Overridden',
      de: 'Überschrieben',
    },
    [ContractState.FUTURE]: {
      en: 'Upcoming',
      de: 'Bevorstehend',
    },
    [ContractState.PAST]: {
      en: 'Past',
      de: 'Vergangen',
    },
  },
  addContractButtonLabel: {
    en: 'Add new contract',
    de: 'Vertrag hinzufügen',
  },
  copyFromWeg: {
    en: 'Copy Tenant contract from WEG',
    de: 'Mietvertrag von der WEG kopieren',
  },
  ownerContract: {
    en: 'Owner',
    de: 'Eigentümer',
  },
  tenantContract: {
    en: 'Tenant',
    de: 'Mieter',
  },
  vacantContract: {
    en: 'Vacancy',
    de: 'Leerstand ',
  },
  rowActions: {
    edit: {
      en: 'Edit contract',
      de: 'Vertrag bearbeiten',
    },
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
    download: {
      en: 'Download',
      de: 'Herunterladen',
    },
    openContract: {
      en: 'Open contract',
      de: 'Vertrag in ETG24 öffnen',
    },
    resolveConflict: {
      en: 'Resolve conflict',
      de: 'Konflikt auflösen',
    },
    manageContract: {
      en: 'Manage contract',
      de: 'Vertrag verwalten',
    },
  },
  manageContractModal: {
    manageContractTitle: {
      en: 'Manage contract',
      de: 'Vertrag verwalten',
    },
    resolveConflictsTitle: {
      en: 'Resolve contract conflict',
      de: 'Vertrag Konflikt auflösen',
    },
    importContractLabel: {
      en: 'Import contract to Impower',
      de: 'Vertrag nach Impower importieren',
    },
    mergeContractLabel: {
      en: 'Merge with Impower contract',
      de: 'Zusammenführen mit einem Impower-Vertrag',
    },
    deleteContractLabel: {
      en: 'Delete contract in ETG24 via the Portal UI',
      de: 'Vertrag in ETG24 über das Portal löschen',
    },
    importContractDescription: {
      en: 'Create a new contract in Impower connected to the ETG24 contract.',
      de: 'Einen neuen Vertrag erstellen in Impower, der mit dem ETG24-Vertrag verbunden ist.',
    },
    mergeContractDescriptionFirst: {
      en: 'The start & end date will be corrected and contacts from both systems will be added to the selected contract.',
      de: 'Das Start- und Enddatum wird korrigiert und die Kontakte aus beiden Systemen werden dem ausgewählten Vertrag hinzugefügt.',
    },
    mergeContractDescriptionSecond: {
      en: 'Contacts with the same name will be merged, and if different, they will be added to Contacts as a new person',
      de: 'Kontakte mit demselben Namen werden zusammengeführt, und wenn sie sich unterscheiden, werden sie den Kontakten als neue Person hinzugefügt.',
    },
    mergeSelectorTitle: {
      en: 'Select the contract to merge',
      de: 'Zusammenführenden Vertrag auswählen'
    },
    deleteContractDescription: {
      en: 'All documents, conversations, tickets and events related to the contract will be deleted.',
      de: 'Alle Dokumente, Konversationen, Tickets und Events mit Bezug zu diesem Vertrag werden gelöscht. Diese Aktion ist irreversibel.',
    },
    deleteContractLinkLabel: {
      en: 'Open contract in etg24',
      de: 'Vertrag in etg24 öffnen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    onOkImportContract: {
      en: 'Import contract',
      de: 'Vertrag importieren',
    },
    onOkMergeContract: {
      en: 'Merge contract',
      de: 'Verträge zusammenführen',
    },
    onOkDeleteContract: {
      en: 'Done',
      de: 'Fertig',
    },
  },
  downloadModal: {
    title: {
      en: (unit: string, contactName: string) => `Owner change document for Unit: ${unit}, Owner: ${contactName}`,
      de: (unit: string, contactName: string) => `Eigentümerwechseldokument für Einheit: ${unit}, Eigentümer: ${contactName}`,
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    download: {
      en: 'Download',
      de: 'Herunterladen',
    },
    watermark: {
      en: 'Watermark',
      de: 'Briefpapier',
    },
    signingPerson: {
      en: 'Signing person',
      de: 'Person für das Anschreiben',
    },
    downloadError: {
      en: 'Failed to download document',
      de: 'Fehler beim Herunterladen des Dokuments.',
    },
    noManagementCompanyError: {
      en: 'Property does not have a management company.',
      de: 'Objekte hat keine Verwaltungunternehmen.',
    },
  },
  notifications: {
    noActiveContractError: {
      en: 'This unit currently does not have an active contract.',
      de: 'Diese Einheit hat aktuell keinen aktiven Vertrag.',
    },
    sevPropertiesErrorNotification: {
      en: 'Could not load SEVs.',
      de: 'SEVs konnten nicht geladen werden.',
    },
    deleteError: {
      message: {
        en: 'Failed to delete unit contract.',
        de: 'Vertrag der Objekteigentümern konnte nicht gelöscht werden.',
      },
      description: {
        en: 'Contract has bank orders/postings.',
        de: 'Vertrag hat Bankaufträge/Buchungen.',
      },
    },
    deleteSuccess: {
      message: {
        en: 'Contract was deleted successfully.',
        de: 'Der Vertrag wurde erfolgreich gelöscht.',
      },
    },
  },
  deleteModalWithSevContext: {
    title: {
      en: 'Delete contract',
      de: 'Vertrag löschen',
    },
    message: {
      intro: {
        en: 'Where should this contract be deleted?',
        de: 'Von welchen Objekten soll der Vertrag gelöscht werden??',
      },
      linkedProperties: {
        en: 'It will be deleted in the following properties:',
        de: 'Der Vertrag wird von folgenden Objekten gelöscht:',
      },
      contextOption: {
        onlyHere: {
          en: 'Only here',
          de: 'Nur hier',
        },
        everywhere: {
          en: 'Everywhere',
          de: 'Überall',
        },
      },
    },
    okText: {
      en: 'Delete contract',
      de: 'Vertrag löschen',
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
  deleteModal: {
    title: {
      en: 'Delete contract',
      de: 'Vertrag löschen',
    },
    message: {
      intro: {
        en: (contractName: string) => `Before proceeding, please note that deleting the contract ${contractName} is irreversible.`,
        de: (contractName: string) => `Bevor Sie fortfahren beachten Sie bitte, dass das Löschen von ${contractName} unwiderruflich ist.`,
      },
      linkedProperties: {
        en: 'The contract will be deleted for all connected properties:',
        de: 'Der Vertrag wird von den von Ihnen ausgewählten verknüpften Objekten gelöscht:',
      },
      deletedEntities: {
        en: 'Please note that upon deletion, the following associated data will automatically be deleted:',
        de: 'Bitte beachten Sie, dass mit dem Löschen folgende verknüpfte Daten automatisch gelöscht werden:',
        entities: [
          {
            en: 'Postings',
            de: 'Buchungen',
          },
          {
            en: 'Transaction allocations',
            de: 'Verbuchungen von Banktransaktionen',
          },
          {
            en: 'Bank orders',
            de: 'Bankaufträge',
          },
          {
            en: 'Messages',
            de: 'Nachrichten',
          },
        ],
      },
      linkedSEVs: {
        en: 'The following linked SEVs will be unlinked, their data won\'t change:',
        de: 'Die Verlinkung zu verknüpften SEVs wird aufgehoben, die Daten jedoch nicht verändert:',
      },
      outro: {
        part1: {
          en: 'If you have reports generated, you need to re-create them to receive an updated version.',
          de: 'Bereits erstellte Berichte müssen erneut erstellt werden, um eine aktuelle Version zu erhalten.',
        },
        part2: {
          en: 'Are you sure you want to delete the contract?',
          de: 'Sind Sie sicher, dass Sie den Vertrag löschen möchten?',
        },
      },
    },
    okText: {
      en: 'Yes',
      de: 'Ja',
    },
    cancelText: {
      en: 'No',
      de: 'Nein',
    },
  },
  role: {
    [ContractContactProjectionDtoRoleEnum.OWNER]: {
      en: 'Owner',
      de: 'Eigentümer',
    },
    [ContractContactProjectionDtoRoleEnum.TENANT]: {
      en: 'Tenant',
      de: 'Mieter',
    },
    [ContractContactProjectionDtoRoleEnum.COUNCIL]: {
      en: 'Council',
      de: 'Beirat',
    },
    [ContractContactProjectionDtoRoleEnum.HEAD_OF_COUNCIL]: {
      en: 'Head of Council',
      de: 'Beiratsvorsitz',
    },
    [ContractContactProjectionDtoRoleEnum.PROPERTY_OWNER]: {
      en: 'Property Owner',
      de: 'Objekteigentümer',
    },
    [ContractContactProjectionDtoRoleEnum.LEGAL_REPRESENTATIVE]: {
      en: 'Legal Representative',
      de: 'Bevollmächtigte',
    },
    [ContractContactProjectionDtoRoleEnum.SUBTENANT]: {
      en: 'Subtenant',
      de: 'Untermieter',
    },
    [ContractContactProjectionDtoRoleEnum.RESIDENT]: {
      en: 'Resident',
      de: 'Bewohner',
    },
    [ContractContactProjectionDtoRoleEnum.LEGAL_GUARDIAN]: {
      en: 'Legal Guardian',
      de: 'Vormund',
    },
  },
  loadingMessage: {
    en: 'Contract deletion in progress.',
    de: 'Der Vertrag und alle zugehörigen Daten werden gelöscht.',
  },
  economicPlansErrorNotification: {
    en: 'Could not load economic plans.',
    de: 'Wirtschaftsplan konnten nicht geladen werden.',
  },
};
