import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';


interface ExpensesSectionProps {
  tl,
  openSectionId,
  children
}


const ExpensesSection = ({
  tl,
  openSectionId,
  children,
}: ExpensesSectionProps) => (
  <PrimarySectionWrapper
    sectionId="ExpensesSection"
    className="ExpensesSection"
    sectionNumber={2}
    sectionTitle={tl(opsCostTranslation.report.sections.expensesSection.expensesSectionTitle)}
    isOpen={openSectionId === 1}
    sectionDisabled={openSectionId !== 1}
  >
    {children}
  </PrimarySectionWrapper>
);


export default ExpensesSection;
