import { useContext } from 'react';

import { Tooltip } from 'antd';
import {
  getPropertyCellSubtitleInList,
} from 'lib/utilGetPropertyCellSubtitleInList';
import CellWithTwoLines
  from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

import { LanguageContext } from '../../contexts/LanguageContext';
import { FORMAT } from '../../elements/SmartTable/SmartTable';
import { translations } from '../../elements/Translation/translations';
import { DirectDebitStateCell } from './components/DirectDebitStateCell';

export const useDirectDebitColumns = () => {
  const { tl } = useContext(LanguageContext);
  return [
    {
      title: tl(translations.pages.directDebitList.table.headers.state),
      dataKey: 'translatedState',
      render: (_, record) => <DirectDebitStateCell record={record} />,
    },


    {
      title: tl(translations.pages.payment.table.headers.property),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.statement),
      dataKey: 'statement',
    },
    {
      title: tl(translations.pages.payment.table.headers.businessPartner),
      dataKey: 'counterpartName',
      render: (_, record) => (
        <Tooltip title={`${record.counterpartName || '—'}\n${record.counterpartIban || '—'}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.counterpartName || '—'}
              secondElement={record.counterpartIban || '—'}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.executionDate),
      dataKey: 'executionDate',
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.amount),
      format: FORMAT.CURRENCY,
      dataKey: 'amount',
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.mandateId),
      dataKey: 'mandateId',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.propertyIban),
      dataKey: 'propertyIban',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.propertyAccountInstitute),
      dataKey: 'propertyAccountInstitute',
      defaultVisible: false,
      sortable: false,
      render: (_, record) => (
        <Tooltip title={`${record.propertyAccountInstitute || '—'}\n${record.propertyBic || '—'}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyAccountInstitute || '—'}
              secondElement={record.propertyBic || '—'}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.creditorId),
      dataKey: 'creditorId',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.createdBy),
      dataKey: 'createdBy',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(translations.pages.directDebitList.table.headers.createdDate),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.payment.table.headers.accountant),
      dataKey: 'accountantName',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(translations.pages.payment.table.headers.administrator),
      dataKey: 'supervisorName',
      defaultVisible: false,
      sortable: false,
    },
  ];
};
