import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import './ReportOverviewHeader.scss';
import useUnitSelector from 'lib/legacy/useUnitSelector';
import { useUnitsList } from 'services/UnitsList/useUnitsList';
import { GetUnitContractsUsingGETContractTypesEnum } from 'api/accounting';
import SelectInput from '../../../elements/Inputs/SelectInput/SelectInput';
import { translations } from '../../../elements/Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../contexts/PropertyListContext';
import { useActiveContractsList } from '../../../services/UnitContractsList/useActiveContractsList';
import { useBuildingsList } from '../../../services/BuildingsList/useBuildingsList';

interface ReportOverviewHeaderProps {
  rightSideButtons: () => JSX.Element
  setSelectedUnitId: Function
}

export default function ReportOverviewHeader(props: ReportOverviewHeaderProps): JSX.Element {
  const {
    rightSideButtons, setSelectedUnitId,
  } = props;

  const { selectedProperty }: any = useContext(PropertyListContext);
  const propertyId: number | undefined = selectedProperty?.data?.id;
  const { unitsList } = useUnitsList({ propertyId, isOwnedByWeg: false });
  const { activeContractsList } = useActiveContractsList({ propertyId, contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER] });
  const { buildingsList } = useBuildingsList(propertyId);
  const units = unitsList.data!;
  const unitsLoading = unitsList.loading;
  const { tl } = useContext(LanguageContext);

  const {
    selectedUnitId, onChange, options, filterOption,
  } = useUnitSelector({
    units,
    activeContracts: activeContractsList.data!,
    buildings: buildingsList.data!,
    validationErrors: [],
    includePropertyOption: true,
    propertyLabel: tl(translations.pages.reportOverview.autocompleteEntire),
  });

  useEffect(() => {
    if (_.isEmpty(units)) return;

    if (selectedUnitId && selectedUnitId > 0) {
      setSelectedUnitId(selectedUnitId);
    } else if (selectedUnitId === 0) {
      setSelectedUnitId(null);
    }
  }, [selectedUnitId]);


  return (
    <div className="ReportOverviewHeader">
      <SelectInput
        inputClassName="unit-selector"
        onChange={onChange}
        value={selectedUnitId}
        options={options}
        filterOption={filterOption}
        size="large"
        showSearch
        loading={unitsLoading}
      />

      <div className="right-side">
        {rightSideButtons()}
      </div>
    </div>
  );
}
