import { DocumentProjectionDto } from 'api/document';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';

interface Props {
  children: React.ReactNode;
}


export const ProfitAndLossReportViewContext = createContext<{
  profitAndLossReport: DefaultDataInterface<DocumentProjectionDto>,
  setProfitAndLossReport: React.Dispatch<React.SetStateAction<DefaultDataInterface<DocumentProjectionDto>>>,
} | undefined>(undefined);


const ProfitAndLossReportViewContextProvider = ({ children }: Props) => {
  const [profitAndLossReport, setProfitAndLossReport] = useState(DEFAULT_DATA<DocumentProjectionDto>(undefined));

  return (
    <ProfitAndLossReportViewContext.Provider value={{
      profitAndLossReport,
      setProfitAndLossReport,
    }}
    >
      {children}
    </ProfitAndLossReportViewContext.Provider>
  );
};

export default ProfitAndLossReportViewContextProvider;
