import React, { useContext } from 'react';

import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'pages/ProfitAndLossReport/translations';

import ResultTableSubSummary from './ResultTableSubSummary';
import ResultTableSummary from './ResultTableSummary';
import { useResultTable } from './useResultTable';

const ResultTable = () => {
  const {
    columns, firstDataSource, firstSum, secondDataSource, secondSum, thirdDataSource, thirdSum, fourthDataSource, fourthSum,
  } = useResultTable();
  const { tl } = useContext(LanguageContext);

  return (
    <div className="ResultTable">
      <Table
        columns={columns}
        dataSource={firstDataSource}
        pagination={false}
        summary={() => (
          <ResultTableSubSummary
            title={tl(translations.report.sections.resultSection.debitResult)}
            value={firstSum}
            nrTotalColumns={columns.length}
          />
        )}
        rowKey="label"
      />

      <Table
        className="NoHeaderTable"
        columns={columns}
        dataSource={secondDataSource}
        pagination={false}
        summary={() => (
          <ResultTableSubSummary
            title={tl(translations.report.sections.resultSection.actualResult)}
            value={secondSum}
            nrTotalColumns={columns.length}
          />
        )}
        rowKey="label"
      />

      <Table
        className="NoHeaderTable"
        columns={columns}
        dataSource={thirdDataSource}
        pagination={false}
        summary={() => (
          <ResultTableSubSummary
            title={tl(translations.report.sections.resultSection.calculatedChangeBeforeDeferrals)}
            value={thirdSum}
            nrTotalColumns={columns.length}
          />
        )}
        rowKey="label"
      />

      <Table
        className="NoHeaderTable"
        columns={columns}
        dataSource={fourthDataSource}
        pagination={false}
        summary={() => (
          <ResultTableSummary
            title={tl(translations.report.sections.resultSection.calculatedChange)}
            value={fourthSum}
            nrTotalColumns={columns.length}
          />
        )}
        rowKey="label"
      />
    </div>
  );
};

export default ResultTable;
