import { useDocumentContext, useRecipientUpdaterContext } from 'pages/MessageSendingPage/services/MessageSendingContext';

export const useMessageDocumentTableOptions = () => {
  const recipientUpdaterContext = useRecipientUpdaterContext('useMessageDocumentTableOptions');
  const documentContext = useDocumentContext('useMessageDocumentTableOptions');

  const { setDocumentDatasource } = documentContext;
  const { setDocumentRecipients } = recipientUpdaterContext;

  const onRemove = (record) => {
    if (record.id && !record.children) {
      setDocumentDatasource((prev) => {
        const newData = [...prev.data];
        const propertyIndex = newData.findIndex(prp => prp.propertyId === record.propertyId);
        const sourceIndex = newData[propertyIndex].children?.findIndex(sc => sc.sourceType === record.sourceType && sc.sourceId === record.sourceId);
        // there might be documents without source type or source id
        if (sourceIndex !== -1) {
          if (newData[propertyIndex]?.children[sourceIndex]?.id === record.id) {
            newData[propertyIndex].children?.splice(sourceIndex, 1);
          } else {
            const docIndex = newData[propertyIndex].children[sourceIndex].children?.findIndex(d => d.id === record.id);
            newData[propertyIndex].children[sourceIndex].children?.splice(docIndex, 1);
          }
        } else {
          const docIndex = newData[propertyIndex].children?.findIndex(sc => !sc.children && sc.id === record.id);
          newData[propertyIndex].children?.splice(docIndex, 1);
        }

        return prev.load(newData);
      });
      setDocumentRecipients(prev => [...prev].filter(d => d.documentId !== record.id));
    } else {
      setDocumentDatasource((prev) => {
        const newData = [...prev.data];
        const propertyIndex = newData.findIndex(prp => prp.propertyId === record.propertyId);
        const sourceIndex = newData[propertyIndex]?.children.findIndex(sc => sc.sourceType === record.sourceType && sc.sourceId === record.sourceId);
        newData[propertyIndex].children?.splice(sourceIndex, 1);
        return prev.load(newData);
      });
      const childrenIds = record.children.map(c => c.id);
      setDocumentRecipients(prev => prev.filter(d => !childrenIds.includes(d.documentId)));
    }
  };

  return {
    onRemove,
  };
};
