import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import DEFAULT_DATA from '../lib/data';
import { LanguageContext } from './LanguageContext';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { showNotification } from '../lib/Notification';
import { PropertyLegacyDtoPropertyStateEnum } from '../api/accounting/models';

const PAGE_SIZE = 30;

export const AccountSalesListContext: any = React.createContext({});

export default function AccountSalesListProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);

  const defaultAccountSalesListContext: any = [];

  const defaultToAssignList: { iban: string, toAssign: number }[] = [];

  const [accountSalesListState, setAccountSalesListState] = useState(DEFAULT_DATA<any>(defaultAccountSalesListContext));
  const [toAssignList, setToAssignList] = useState(defaultToAssignList);
  const [accountSalesFilterState, setAccountSalesFilterState] = useState<any>({});
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const lastRequestTimestamp = useRef<number | null>(null);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadAccountSalesList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [accountSalesFilterState]);

  const updateFilterState = (data: object) => {
    setAccountSalesFilterState({
      ...accountSalesFilterState,
      ...data,
    });
  };

  function convertToAccountSales(property: any) {
    const bankAccountList: any[] = [];
    property.bankAccounts.forEach((bankAccount: any) => {
      if (bankAccountList.filter((prevBankAccount: any) => prevBankAccount.iban === bankAccount.iban).length === 0) {
        bankAccountList.push(bankAccount);
      }
    });

    backend.get(`${endpointUrls.TRANSACTION}/number-of-unassigned-transactions`, { ibans: bankAccountList.map(ba => ba.iban) })
      .then((response: any) => {
        const feModel = response.map((res: any) => ({ iban: res.iban, toAssign: res.numberOfTransactions }));
        setToAssignList(prevState => prevState.concat(feModel));
      });
    return {
      name: property.name,
      propertyHrId: property.propertyHrId,
      propertyIdInternal: property.propertyIdInternal,
      bankAccountList,
    };
  }

  function convertToAccountSalesList(property: any) {
    return property.map((property: any) => convertToAccountSales(property));
  }

  const onLoadAccountSalesList = (resetPage: boolean = false) => {
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setAccountSalesListState(accountSalesListState.startLoading());
    backend.get(`${endpointUrls.PROPERTY}/filter`,
      {
        ...accountSalesFilterState,
        page: resetPage ? 0 : accountSalesListState.page,
        size: PAGE_SIZE,
        propertyStates: [PropertyLegacyDtoPropertyStateEnum.READY, PropertyLegacyDtoPropertyStateEnum.OFFBOARDED],
      })
      .then((response: any) => {
        const accountSalesList = convertToAccountSalesList(response.content);

        // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        setAccountSalesListState(accountSalesListState.loadPaged(accountSalesList, resetPage, response.last));
      })
      .catch(() => {
        setAccountSalesListState({
          ...accountSalesListState.failed(),
        });
        showNotification({
          key: 'loadAccountSalesListStateError',
          message: tl(translations.notifications.accountSalesListContext.accountLoadError.message),
          type: 'error',
        });
      });
  };

  const onClearAccountSalesListState = () => {
    setAccountSalesListState(() => DEFAULT_DATA<any>(defaultAccountSalesListContext));
  };

  const onClearAccountSalesFilterState = () => {
    setAccountSalesFilterState({});
  };

  return (
    <AccountSalesListContext.Provider value={{
      ...accountSalesListState,
      toAssignList,
      accountSalesFilterState,
      setAccountSalesFilterState,
      onClearAccountSalesListState,
      onClearAccountSalesFilterState,
      onLoadAccountSalesList,
      updateFilterState,
    }}
    >
      {children}
    </AccountSalesListContext.Provider>
  );
}
