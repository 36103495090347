import { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';


/**
 * Even though the values of PageHeaderTagColor and DotColorEnum overlap ('GREEN', 'YELLOW', ...)
 * they are a different type and TypeScript will not allow us to use one in place of the other.
 * To work around this we cast the values to the const string equivalent of the enums (hover HeaderTagColorKeys)
 * and then cast them back to the appropriate enum (PageHeaderTagColor or DotColorEnum) where we use getStateColor.
 */
export type HeaderTagColorKeys = keyof typeof PageHeaderTagColor;


export function getStateColor(state: string): HeaderTagColorKeys {
  switch (state) {
  case 'OPEN':
  case 'IN_GENERATION':
    return PageHeaderTagColor.YELLOW as HeaderTagColorKeys;
  case 'PENDING':
  case 'PREPARED':
  case 'DECIDED':
  case 'FUTURE':
  case 'SENDING':
  case 'SENT_FOR_SIGNATURE':
  case 'BOOKED':
    return PageHeaderTagColor.BLUE as HeaderTagColorKeys;
  case 'DONE':
  case 'FINALIZED':
  case 'ACTIVE':
  case 'READY':
  case 'ACTIVATED':
  case 'SENT':
  case 'EXECUTED':
    return PageHeaderTagColor.GREEN as HeaderTagColorKeys;
  case 'OFFBOARDED':
  case 'FINISHED':
  case 'UNSAVED':
  case 'NOT_EXECUTED':
  case 'DRAFT':
    return PageHeaderTagColor.GRAY as HeaderTagColorKeys;
  case 'STOPPED':
    return PageHeaderTagColor.PURPLE as HeaderTagColorKeys;
  case 'ERROR':
  case 'FAILED':
  case 'DOCUMENT_GENERATION_FAILED':
    return PageHeaderTagColor.RED as HeaderTagColorKeys;
  default:
    return PageHeaderTagColor.GRAY as HeaderTagColorKeys;
  }
}
