import { UserDto } from 'api/user';
import { LanguageContext } from 'contexts/LanguageContext';
import { Action } from 'elements/SmartTable/data';
import { Column } from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { UsersListContext } from 'pages/Users/UserList/services/UsersListContext';
import { useLoadUsers } from 'pages/Users/UserList/services/useLoadUsers';
import { userListTranslations } from 'pages/Users/UserList/userListTranslations';
import React, { useContext, useEffect, useMemo } from 'react';
import { navigateConcat } from 'navigation/History';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import { useShowDeleteUserModal } from 'pages/Users/services/useShowDeleteUserModal';
import { checkUserHasPermission, Permissions } from 'lib/userPermissionUtils';
import { showNotification } from 'lib/Notification';

export const useUsersTable = () => {
  const usersContext = useContext(UsersListContext);
  if (usersContext === undefined) {
    throw Error('useUsersTable can only be used withing UsersContextProvider');
  }
  const {
    usersList, filterState, sortOrder, setSortOrder, setUsersList,
  } = usersContext;
  const { loadUserList } = useLoadUsers();
  const { showDeleteUserModal } = useShowDeleteUserModal();
  const { tl } = useContext(LanguageContext);
  const isAdminUser = useMemo(() => checkUserHasPermission(Permissions.PERM_USER_MANAGE), []);

  useEffect(() => {
    loadUserList(true);
  }, [filterState, sortOrder]);

  const columns = [
    {
      title: tl(userListTranslations.email),
      dataKey: 'email',
      defaultWidth: '100%',
      render: (email, user) => (
        <CellWithTwoLines
          firstElement={email}
          secondElement={(
            <PopoverTruncatingText value={user.role.name} tooltipMessage={user.role.description} />
          )}
        />
      ),
    } as Column,
  ];

  const actionsMenu: Action[] = [
    {
      label: tl(userListTranslations.actions.changePassword),
      onAction: (record: UserDto) => {
        navigateConcat(`/${record.id}/edit`);
      },
      actionSupported: () => isAdminUser,
    },
    {
      label: tl(userListTranslations.actions.delete),
      onAction: (record: UserDto) => {
        showDeleteUserModal(record.id, () => setUsersList(prev => prev.load(prev.data.filter(user => user.id !== record.id))));
      },
      actionSupported: () => isAdminUser,
    },
  ];

  const usersTable = useSmartTable({
    tableName: 'UsersListTable',
    columns,
    dataSource: usersList.data ?? [],
    contentLoading: usersList.loading,
    infiniteScrollerProps: {
      hasMoreData: !usersList.lastPage,
      loadMoreData: loadUserList,
    },
    propSort: {
      field: 'email',
      order: sortOrder,
      onSortChange: () => setSortOrder(prev => prev * (-1)),
    },
    onRow: record => ({
      onClick: () => {
        if (isAdminUser) navigateConcat(`/${record.id}/edit`);
      },
    }),
    rowKey: 'id',
    actionsMenu,
  });

  const onInviteUsers = () => {
    if (isAdminUser) {
      navigateConcat('/invite');
    } else {
      showNotification({
        key: 'limitedAccess',
        type: 'info',
        message: tl(userListTranslations.notifications.limitedAccess.message),
        description: tl(userListTranslations.notifications.limitedAccess.description),
      });
    }
  };

  return {
    usersTable,
    onInviteUsers,
  };
};
