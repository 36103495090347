

export const getOpenBalanceAmountColor = (balance: number) => {
// If balance is zero, there is no direction (no styling)
  let amountColor;
  // a greater than zero balance means the property is owed money -> user needs to take action -> red
  if (balance > 0) amountColor = 'red';
  // a less than zero balance means the property owes money -> green
  if (balance < 0) amountColor = 'green';
  // a zero balance -> gray
  if (balance === 0) amountColor = 'gray';

  return amountColor;
};
