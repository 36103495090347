// https://github.com/jacobbuck/react-beforeunload/blob/master/src/useBeforeunload.js

/* eslint-disable */
import { useEffect, useRef } from 'react';

const useBeforeUnload = (handler: Function) => {
  const eventListenerRef = useRef<Function>();

  useEffect(() => {
    eventListenerRef.current = (event: Event) => {
      const returnValue = handler?.(event);
      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === 'string') {
        // @ts-ignore
        return (event.returnValue = returnValue);
      }
      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        // @ts-ignore
        return (event.returnValue = '');
      }
    };
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: Event) => {
      eventListenerRef?.current?.(event);
    };
    window.addEventListener('beforeunload', eventListener);
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, []);
};

export default useBeforeUnload;
