import { useContext } from 'react';
import useDefaultPropertyFilters from 'components/Header/components/data/useDefaultPropertyFilters';
import { translations } from '../../../elements/Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { PropertyLegacyDtoClientCategoryEnum, PropertyLegacyDtoPropertyStateEnum } from '../../../api/accounting/models';
import { Filter } from '../../../components/Header/components/SearchBar/services/GlobalSearchContext';

export default function usePropertyFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    {
      type: 'enum',
      name: tl(translations.pages.property.table.headers.clientCategory),
      key: 'clientCategories',
      options: Object.keys(PropertyLegacyDtoClientCategoryEnum).map(value => ({
        label: value,
        value,
      })),
      multiValue: true,
    },
    {
      type: 'enum',
      name: tl(translations.pages.property.table.headers.status),
      key: 'propertyStates',
      options: Object.keys(PropertyLegacyDtoPropertyStateEnum).map(value => ({
        label: tl(translations.pages.property.table.propertyStates[value]),
        value,
      })),
      multiValue: true,
    },
    ...useDefaultPropertyFilters(),
    {
      type: 'text',
      name: tl(translations.pages.property.table.headers.address),
      key: 'address',
    },
    {
      type: 'text',
      name: tl(translations.pages.property.table.headers.administrator),
      key: 'supervisorName',
    },
    {
      type: 'text',
      name: tl(translations.pages.property.table.headers.accountant),
      key: 'accountantName',
    },
    {
      type: 'text',
      name: tl(translations.pages.property.table.headers.createdBy),
      key: 'createdBy',
    },
  ];
}
