import React, {
  useContext,
} from 'react';
import { Col, Modal, Row } from 'antd';
import Icon from '@ant-design/icons';
import _ from 'lodash';
import { SPACE_SIZES } from 'lib/constants';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import Button from '../../../../elements/Buttons/Button/Button';
import { ICONS } from '../../../../components/icons';
import TextInput from '../../../../elements/Inputs/TextInput/TextInput';
import RadioGroupInput from '../../../../elements/Inputs/RadioGroupInput/RadioGroupInput';
import SelectInput from '../../../../elements/Inputs/SelectInput/SelectInput';
import CheckboxInput from '../../../../elements/Inputs/CheckboxInput/CheckboxInput';
import './IndividualDistributionKeyCopyModal.scss';
import {
  AccountDistributionKeyCopyRequestDtoSourceEnum, AccountDistributionKeyTreeDto,
} from '../../../../api/accounting';
import { useIndividualDistributionKeyCopyModal } from './useIndividualDistributionKeyCopyModal';

interface ModalProps {
    sourceADK: AccountDistributionKeyTreeDto,
    sourceEconomicYear: string,
    yearsWithDistributionKeys: number[],
    modalVisible: boolean,
    setModalVisible: Function,
}

export function IndividualDistributionKeyCopyModal({
  sourceADK, sourceEconomicYear, yearsWithDistributionKeys, modalVisible, setModalVisible,
}: ModalProps) {
  const { tl } = useContext(LanguageContext);

  const destinationYears = yearsWithDistributionKeys.map((yearValue: number) => ({
    value: yearValue,
    label: yearValue.toString(10),
  }));

  const {
    sourceDocumentType,
    setSourceDocumentType,
    selectedYears,
    setSelectedYears,
    selectedAccounts,
    setSelectedAccounts,
    targetEPType,
    setTargetEPType,
    targetHMSType,
    setTargetHMSType,
    targetAllType,
    setTargetAllType,
    onOk,
    onCancel,
    destinationAccounts,
    isDistributionForOpsCost,
  } = useIndividualDistributionKeyCopyModal({
    adkId: sourceADK?.id!,
    setModalVisible,
  });

  const sourceDKValues = ''.concat(sourceEconomicYear, ' - ',
    sourceADK?.accountCode ?? '', ' ',
    sourceADK?.accountName ?? '');

  const isButtonDisabled = _.isEmpty(selectedYears) || _.isEmpty(selectedAccounts) || (!isDistributionForOpsCost && !targetHMSType && !targetEPType) || (isDistributionForOpsCost && !targetHMSType && !targetEPType && !targetAllType);

  return (
    <Modal
      className="IndividualDistributionKeyCopyModal"
      title={tl(translations.propertyDistributionKey.individualCopyModal.title)}
      visible={modalVisible}
      onCancel={onCancel}
      footer={(
        <div className="footerWrapper">
          <div className="warningMessage">
            <Icon component={ICONS.yellowInfo} />
            <span>{tl(translations.propertyDistributionKey.individualCopyModal.warningMessage)}</span>
          </div>
          <Button
            type="primary"
            onClick={onOk}
            disabled={isButtonDisabled}
          >
            {tl(translations.propertyDistributionKey.copyModal.button)}
          </Button>
        </div>
      )}
    >
      <Row gutter={[0, SPACE_SIZES.large]}>
        <Col span={24}>
          <TextInput
            disabled
            onChange={() => {}}
            label={tl(translations.propertyDistributionKey.copyModal.source)}
            value={sourceDKValues || ''}
          />
        </Col>
        <Col span={24}>
          {isDistributionForOpsCost
            ? (
              <div className="source-section">
                <CheckboxInput
                  onChange={() => {}}
                  disabled
                  value
                  label={tl(translations.propertyDistributionKey.individualCopyModal.hga)}
                />
                <CheckboxInput
                  onChange={() => {}}
                  value
                  disabled
                  label={tl(translations.propertyDistributionKey.individualCopyModal.ep)}
                />
                <CheckboxInput
                  onChange={() => {}}
                  value
                  disabled
                  label={tl(translations.propertyDistributionKey.individualCopyModal.opsCost)}
                />
              </div>
            )
            : (
              <RadioGroupInput
                label=""
                onChange={val => setSourceDocumentType(val)}
                value={sourceDocumentType}
                className="radio-group"
                inputClassName="radio"
                options={
                  [{
                    value: AccountDistributionKeyCopyRequestDtoSourceEnum.HMS,
                    label: tl(translations.propertyDistributionKey.individualCopyModal.hga),
                  },
                  {
                    value: AccountDistributionKeyCopyRequestDtoSourceEnum.EP,
                    label: tl(translations.propertyDistributionKey.individualCopyModal.ep),
                  },
                  ]}
              />
            )
          }
        </Col>
        <Col span={24}>
          <SelectInput
            required
            label={tl(translations.propertyDistributionKey.individualCopyModal.destinationAccounts)}
            mode="multiple"
            onChange={setSelectedAccounts}
            value={selectedAccounts}
            options={destinationAccounts}
            showSearch
            placeholder={tl(translations.propertyDistributionKey.individualCopyModal.selectPlaceholder)}
          />
        </Col>

        <Col span={24}>
          <SelectInput
            required
            label={tl(translations.propertyDistributionKey.individualCopyModal.destinationYears)}
            mode="multiple"
            onChange={setSelectedYears}
            value={selectedYears}
            options={destinationYears}
            showSearch
            placeholder={tl(translations.propertyDistributionKey.individualCopyModal.selectPlaceholder)}
          />
        </Col>
        <Col span={24}>
          <div className="input-section">
            <div className="format-label">
              {tl(translations.propertyDistributionKey.individualCopyModal.target)}
              {' *'}
            </div>
            <div className="target-section">
              <CheckboxInput
                onChange={() => setTargetHMSType(!targetHMSType)}
                disabled={isDistributionForOpsCost}
                value={targetHMSType}
                label={tl(translations.propertyDistributionKey.individualCopyModal.hga)}
              />
              <CheckboxInput
                onChange={() => setTargetEPType(!targetEPType)}
                value={targetEPType}
                disabled={isDistributionForOpsCost}
                label={tl(translations.propertyDistributionKey.individualCopyModal.ep)}
              />
              {isDistributionForOpsCost && (
                <CheckboxInput
                  onChange={() => setTargetAllType(!targetAllType)}
                  value={targetAllType}
                  disabled={isDistributionForOpsCost}
                  label={tl(translations.propertyDistributionKey.individualCopyModal.opsCost)}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
