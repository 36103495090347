import React, {
  forwardRef, useEffect, useLayoutEffect, useRef,
} from 'react';
import { Jodit } from 'jodit';
import { Config } from 'jodit/types/config.d';
import 'jodit/build/jodit.min.css';

export const CustomJoditEditor = forwardRef(
  ({
    value, config, onChange, onBlur, tabIndex, name,
  }: { value: any, config, onChange: any, onBlur: any, tabIndex: any, name: any }, ref) => {
    const textArea = useRef<any>(null);

    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(textArea.current);
        } else {
          // @ts-ignore
          ref.current = textArea.current;
        }
      }
    }, [textArea]);

    useEffect(() => {
      const blurHandler = (val: any) => {
        if (onBlur) onBlur(val);
      };

      const changeHandler = (val: any) => {
        if (onChange) {
          onChange(val);
        }
      };

      const element = textArea.current;

      textArea.current = Jodit.make(element, config);
      if (textArea.current) {
          textArea.current!.value = value;
          textArea.current!.events.on('blur', () => blurHandler(textArea.current?.value));
          textArea.current!.events.on('change', () => changeHandler(textArea.current?.value));
          textArea.current!.workplace.tabIndex = tabIndex || -1;
      }
    }, []);

    useEffect(() => {
      if(textArea?.current instanceof  Jodit) {
        textArea.current.setReadOnly(config.readonly);
      }
    }, [config.readonly]);

    useEffect(() => {
      if (!!value && textArea?.current?.value !== value) {
          textArea.current!.value = value;
      }
    }, [value]);

    return <textarea ref={textArea} name={name} />;
  },
);
