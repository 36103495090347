import { range } from '../../../lib/Utils';

interface UseMoreFormProps {
  value: any,
  key: string,
  content: Function,
  minValue?: number,
  contentProps?: any
}

export default function useMoreForm(props: UseMoreFormProps) {
  if (!props.value) {
    return [];
  }
  const minValue = props.minValue !== undefined ? props.minValue : 1;
  const numberOfElements = (props.value[props.key] && props.value[props.key] > 0) ? props.value[props.key] : minValue;
  return range(0, numberOfElements - 1).reduce((accumulator: Array<any>, elementNr: number) => {
    const content = props.contentProps ? props.content(elementNr, props.contentProps) : props.content(elementNr);
    accumulator = accumulator.concat(content);
    return accumulator;
  }, []);
}
