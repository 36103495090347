/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectionLegacyDto } from './ConnectionLegacyDto';
import {
    ConnectionLegacyDtoFromJSON,
    ConnectionLegacyDtoFromJSONTyped,
    ConnectionLegacyDtoToJSON,
} from './ConnectionLegacyDto';
import type { Pageable } from './Pageable';
import {
    PageableFromJSON,
    PageableFromJSONTyped,
    PageableToJSON,
} from './Pageable';
import type { Sort } from './Sort';
import {
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './Sort';

/**
 * 
 * @export
 * @interface PageOfConnectionLegacyDto
 */
export interface PageOfConnectionLegacyDto {
    /**
     * 
     * @type {Array<ConnectionLegacyDto>}
     * @memberof PageOfConnectionLegacyDto
     */
    content?: Array<ConnectionLegacyDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfConnectionLegacyDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfConnectionLegacyDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfConnectionLegacyDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOfConnectionLegacyDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfConnectionLegacyDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfConnectionLegacyDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageOfConnectionLegacyDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfConnectionLegacyDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfConnectionLegacyDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfConnectionLegacyDto
     */
    totalPages?: number;
}

/**
 * Check if a given object implements the PageOfConnectionLegacyDto interface.
 */
export function instanceOfPageOfConnectionLegacyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageOfConnectionLegacyDtoFromJSON(json: any): PageOfConnectionLegacyDto {
    return PageOfConnectionLegacyDtoFromJSONTyped(json, false);
}

export function PageOfConnectionLegacyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageOfConnectionLegacyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(ConnectionLegacyDtoFromJSON)),
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableFromJSON(json['pageable']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
    };
}

export function PageOfConnectionLegacyDtoToJSON(value?: PageOfConnectionLegacyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(ConnectionLegacyDtoToJSON)),
        'empty': value.empty,
        'first': value.first,
        'last': value.last,
        'number': value.number,
        'numberOfElements': value.numberOfElements,
        'pageable': PageableToJSON(value.pageable),
        'size': value.size,
        'sort': SortToJSON(value.sort),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

