import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { useContext } from 'react';
import { ContactProjection, ContactProjectionTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { AccountsAndContactsStateContext } from 'pages/BankTransactions/BankTransactionAllocation/services/AccountsAndContactsContext';
import {
  PostingTextsContext,
  SetPostingTextsContext,
  PostingText,
  SelectInputValuesStateContext,
  ServiceCompanyAllocationValues,
} from '../../../../services/ServiceCompanyAllocationContext';
import { translations } from '../../../../../../../translations';


const getCounterpartName = (contacts: ContactProjection[] | undefined, counterpartContactId: number | undefined) => {
  const matchingContact = contacts?.find(contact => contact.id === counterpartContactId);

  if (matchingContact === undefined) return undefined;

  const {
    type, companyName, firstName, lastName,
  } = matchingContact;

  return (
    type === ContactProjectionTypeEnum.PERSON
      ? [firstName, lastName].filter(Boolean).join(' ')
      : companyName
  );
};


export const usePostingTexts = (record: ServiceCompanyAllocationValues, selectedTransactions: ExtendedBankTransaction[]) => {
  const setPostingTexts = useContext(SetPostingTextsContext);
  const accountsAndContactsStateContext = useContext(AccountsAndContactsStateContext);
  const selectInputValuesState = useContext(SelectInputValuesStateContext);
  const postingTexts = useContext(PostingTextsContext);
  const { tl } = useContext(LanguageContext);

  if (setPostingTexts === undefined || postingTexts === undefined || selectInputValuesState === undefined) {
    throw new Error('usePostingTexts must be used within a ServiceCompanyAllocationContextProvider');
  }

  if (accountsAndContactsStateContext === undefined) {
    throw new Error('usePostingTexts must be used within an AccountsAndContactsProvider');
  }

  const { accounts, contacts } = accountsAndContactsStateContext;

  const matchingPostingTexts = postingTexts.filter(pt => pt.key === record.key && selectedTransactions.map(tx => tx.bankTransactionId!).includes(pt.transactionId!));

  const { counterpartContactId, accountCode } = selectInputValuesState.find(inputValue => inputValue.key === record.key) ?? {};

  const counterpartName = getCounterpartName(contacts.data, counterpartContactId);
  const accountName = accounts.data?.find(acc => acc.code === accountCode?.[0])?.name;

  const modalSubTitle = [counterpartName, accountCode, accountName].filter(Boolean).join(' · ');
  const modalTitle = tl(translations.bankTransactionAllocation.modal.bookingText);


  const onChangeMultiplePostingTexts = (newPostingTexts: PostingText[]) => {
    setPostingTexts(prev => prev?.map((pt) => {
      const matchingPt = newPostingTexts.find(npt => npt.key === pt.key && npt.transactionId === pt.transactionId);

      if (matchingPt) {
        return matchingPt;
      }

      return pt;
    }));
  };

  return {
    postingTexts: matchingPostingTexts,
    onChangeMultiplePostingTexts,
    modalTitle,
    modalSubTitle,
  };
};
