import { ColumnsType } from 'antd/lib/table';

export const useDragNDropTableColumns = (columns: ColumnsType<any>) => [
  {
    title: '',
    dataIndex: 'dragNdrop',
    width: 50,
    render: () => null,
  },
  ...columns,
];
