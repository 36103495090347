import './DownloadButton.scss';
import React from 'react';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { ICONS } from '../../../components/icons';

export interface DownloadButtonProps {
  buttonLabel: string
  onClick: () => void
  loading?: boolean
  disabled?: boolean
}

export default function DownloadButton(props: DownloadButtonProps): JSX.Element {
  const {
    buttonLabel, onClick, loading, disabled,
  } = props;
  return (
    <Button
      className="DownloadButton"
      onClick={onClick}
      loading={loading}
      disabled={disabled}
    >
      {!loading && <Icon component={ICONS.download} />}
      {buttonLabel}
    </Button>
  );
}
