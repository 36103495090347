import React from 'react';
import { Table, ConfigProvider } from 'antd';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { useBasicDragNDropTable } from 'storybook-components/table/TableDragNDropSorting/basic/useBasicDragNDropTable';
import { navigateConcat } from 'navigation/History';
import { AgendaItemDto } from 'api/accounting';
import InfiniteScroller from 'elements/InfiniteScroller/InfiniteScroller';
import { useAgendaItemsList } from '../services/useAgendaItemsList';
import { useAgendaItemsTableColumns } from '../services/useAgendaItemsTableColumns';
import EmptyAgendaItemsListComponent from './EmptyAgendaItemsListComponent/EmptyAgendaItemsListComponent';
import { useLoadAgendaItemsList } from '../services/useLoadAgendaItemsList';


const AgendaItemsTable = () => {
  const {
    agendaItemsList, setDragNDropValuesDispatcher,
  } = useAgendaItemsList();
  const { onLoadAgendaItemsList } = useLoadAgendaItemsList();
  const { columns } = useAgendaItemsTableColumns();
  const { onRow, components } = useBasicDragNDropTable<AgendaItemDto>({ setDataSource: setDragNDropValuesDispatcher });

  const contentElement: any = document.querySelector(`.${'AgendaItemsListProtocol'} .ant-table-body`) || {};

  return (
    <div className="AgendaItemsListProtocol page">
      <ConfigProvider renderEmpty={EmptyAgendaItemsListComponent}>
        <InfiniteScroller
          loadMore={onLoadAgendaItemsList}
          hasMore={!agendaItemsList.lastPage}
          threshold={200}
          loading={agendaItemsList.loading}
          contentElement={contentElement}
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={agendaItemsList.data}
            loading={{
              spinning: false,
              indicator: <TableContentLoadingIndicator />,
            }}
            scroll={{ y: 500 }}
            pagination={false}
            // @ts-ignore
            onRow={(record, index) => ({
              ...onRow?.(record, index),
              onClick: () => {
                navigateConcat(`/agenda-item/${record.id}`);
              },
            })}
            components={components}
          />
        </InfiniteScroller>
      </ConfigProvider>
    </div>
  );
};

export default AgendaItemsTable;

AgendaItemsTable.defaultProps = {
  onOrderingChange: undefined,
};
