import { useContext } from 'react';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { Alert, Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { TableSummaryIconCell } from 'elements/TableSummaryIconCell/TableSummaryIconCell';
import { formatCurrency } from 'lib/Utils';
import Amount from 'storybook-components/typography/Amount/Amount';
import { DebtorBalancesGroupedType } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { getOpenBalanceAmountColor } from 'lib/utilGetOpenBalanceAmountColor';
import { useOpenAmountCellWithBankOrderWarning } from './useOpenAmountCellWithBankOrderWarning';
import OpenAmountCell from '../../../OpenAmountCell/OpenAmountCell';
import './OpenAmountCellWithBankOrderWarning.scss';
import { getOpenTransactionUrl } from '../OpenTransactionsCell/useOpenTransactionsCell';

type OpenAmountCellProps = {
  record: DebtorBalancesGroupedType
}

const OpenAmountCellWithBankOrderWarning = ({ record }: OpenAmountCellProps) => {
  const {
    todaysAccountBalance,
    filteredAccountBalance,
    isRootAccount,
    propertyIdInternal,
  } = record;

  const { tl } = useContext(LanguageContext);
  const {
    hasOpenTransactions, onOpenModal, columns, dataSource, summaryValue, shouldShowWarning, disabled,
  } = useOpenAmountCellWithBankOrderWarning(record);

  const message = (
    <>
      {tl(orderOpenBalancesTranslations.openOrdersModal.alert.firstPart)}
      <a href={getOpenTransactionUrl(propertyIdInternal)} target="_blank" rel="noreferrer">{tl(orderOpenBalancesTranslations.openOrdersModal.alert.linkText)}</a>
      {tl(orderOpenBalancesTranslations.openOrdersModal.alert.secondPart)}
    </>
  );


  const modalContent = (
    <div>
      {hasOpenTransactions && (
        <Alert
          type="warning"
          showIcon
          icon={<Icon component={ICONS.warning} />}
          message={message}
        />
      )
      }

      <Table
        rowKey="category"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <TableSummaryIconCell colSpan={1} content={tl(orderOpenBalancesTranslations.openOrdersModal.table.calculatedBalance)} />

              <Table.Summary.Cell index={1} className="column-align-right">
                <Amount color={getOpenBalanceAmountColor(summaryValue)}>{formatCurrency(summaryValue)}</Amount>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );


  if (isRootAccount && shouldShowWarning) {
    return (
      <div className="OpenAmountCellWithBankOrderWarning">
        <Icon component={ICONS.warning} onClick={() => onOpenModal(tl(orderOpenBalancesTranslations.openOrdersModal.title), modalContent)} />
        <OpenAmountCell filteredBalance={filteredAccountBalance} todaysBalance={todaysAccountBalance} disabled={disabled} />
      </div>
    );
  }

  return <OpenAmountCell filteredBalance={filteredAccountBalance} todaysBalance={todaysAccountBalance} disabled={disabled} />;
};

export default OpenAmountCellWithBankOrderWarning;
