import React from 'react';
import _ from 'lodash';
import Link from '../../elements/FormElements/FormSidebar/Link/Link';
import SectionSubElements, { SectionMenuSubElement } from './SectionSubElements';

export interface SectionMenuGroupElement {
  title: string,
  sectionId: string,
  subElements?: SectionMenuSubElement[],
}


interface SectionGroupElementsProps {
  elements: SectionMenuGroupElement[],
  open: boolean,
  activeElementHref: string | undefined,
  onScrollToSection: (id: string) => void,
  parentSectionId: string,
}
export default function SectionGroupElements(props: SectionGroupElementsProps) {
  const {
    open, elements, activeElementHref, onScrollToSection, parentSectionId,
  } = props;

  if (!open || _.isEmpty(elements)) return null;
  return (
    <>
      {elements.map((groupElement: SectionMenuGroupElement, index: number) => (
        <Link
          className="sub-link"
          key={`group-link-${parentSectionId}-${groupElement.sectionId}`}
          level={2}
          active={groupElement.sectionId === activeElementHref}
          onClick={() => onScrollToSection(groupElement.sectionId)}
          title={`${index + 1}\u00A0\u00A0\u00A0\u00A0${groupElement.title}`
          }
        >
          <SectionSubElements
            elements={groupElement.subElements || []}
            open={!!groupElement.subElements}
            activeElementHref={activeElementHref}
            onScrollToSection={onScrollToSection}
            parentSectionId={`${parentSectionId}-${groupElement.sectionId}`}
          />
        </Link>
      ))
      }
    </>
  );
}
