import { UnitEpoUnitTypeEnum } from '../../api/accounting';

export const linkableSections = ['heating', 'energy', 'ac', 'tv', 'internet', 'lock', 'legionnaire', 'water', 'extinguisher', 'detector', 'smoke', 'ventilation', 'garage', 'services', 'care', 'insurance'];
export const insuranceTypes = ['heating', 'houseOwnerLiability', 'fire', 'storm', 'naturalDisaster', 'water'];

export enum UnitPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  MIDDLE = 'MIDDLE',
  SIDE_WING = 'SIDE_WING'
}

export const unitTypeToBuildingUnitNr = (type: UnitEpoUnitTypeEnum): string => {
  switch (type) {
  case UnitEpoUnitTypeEnum.APARTMENT:
    return 'apartmentNr';
  case UnitEpoUnitTypeEnum.PARKING:
    return 'parkingNr';
  case UnitEpoUnitTypeEnum.OTHER:
    return 'storageNr';
  case UnitEpoUnitTypeEnum.COMMERCIAL:
    return 'commercialNr';
  default:
    return '';
  }
};
