import React, { useContext, useState } from 'react';
import {
  Form, Input, Checkbox, notification,
} from 'antd';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import { useHistory } from 'react-router';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import CheckboxGroupInput from 'elements/Inputs/CheckboxGroupInput/CheckboxGroupInput';
import Heading from 'storybook-components/typography/Heading/Heading';
import Paragraph from 'storybook-components/typography/Paragraph/Paragraph';
import { translations } from './translations';
import './Register.scss';
import { useRegister } from './useRegister';

export default function Register(): JSX.Element {
  const [form] = Form.useForm();
  const authContext: any = useContext(AuthContext);
  const { register } = useRegister();

  const { tl } = useContext(LanguageContext);
  const history = useHistory();


  if (authContext.isLoggedIn()) history.replace('/');

  return (
    <div className="Register container">
      <div className="register-container col-xs-12 col-sm-10 col-md-8 col-lg-6">
        <div className="big-logo">
          <Icon component={ICONS.impower} />
        </div>
        <div className="title-section">
          <Heading level={1} className="title">
            {tl(translations.title)}
          </Heading>
          <Paragraph level={1} type="secondary" className="sub-title">
            {tl(translations.subTitle)}
          </Paragraph>
        </div>
        <Form className="register-form" form={form}>
          <Form.Item
            name="domain"
            className="register-form-item"
            rules={[{
              required: true,
              pattern: /^[a-z0-9]([-a-z0-9]){2,}$/,
              message: tl(translations.registrationForm.domainValidation),
            }]}
          >
            <Input
              className="Input"
              placeholder={tl(translations.registrationForm.domainPlaceholder)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            className="register-form-item"
            rules={[
              {
                required: true,
                type: 'email',
                message: tl(translations.registrationForm.emailValidation),
              },
            ]}
          >
            <Input
              className="Input"
              placeholder={tl(translations.registrationForm.emailPlaceholder)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="register-form-item"
            rules={[
              {
                required: true,
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                message: tl(translations.registrationForm.passwordValidation),
              },
            ]}
          >
            <Input
              className="Input"
              placeholder={tl(translations.registrationForm.passwordPlaceholder)}
              type="password"
            />
          </Form.Item>
          <Form.Item
            name="acceptedTc"
            className="register-form-item"
            rules={[{
              required: true,
              message: tl(translations.registrationForm.acceptTcValidation),
            },
            ]}
          >
            <Checkbox.Group>
              <Checkbox>
                <span dangerouslySetInnerHTML={{ __html: tl(translations.registrationForm.acceptTcLabel) }} />
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                form.validateFields().then((form:{domain:string, email:string, password:string, acceptedTc:boolean}) => {
                  register(form.domain, form.email, form.password, form.acceptedTc);
                })
                  .catch((e) => {
                    console.warn('Invalid registration data', e);
                    notification.error({
                      message: tl(translations.registrationForm.register.errors.errorCodes.COMMON_VALIDATION_INVALID_DATA),
                    });
                  });
              }}
              loading={authContext.loading}
              htmlType="submit"
              className="register-button"
            >
              {tl(translations.registrationForm.registerButton)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
