import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { useContext, useMemo } from 'react';
import { Table } from 'antd';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { DunningFeeCell } from '../DunningFeeCell/DunningFeeCell';
import { AccountNameCell } from './components/AccountNameCell/AccountNameCell';
import { DunningLevelSelectorCell } from './components/DunningLevelSelectorCell/DunningLevelSelectorCell';
import OpenAmountCellWithBankOrderWarning from './components/OpenAmountCellWithBankOrderWarning/OpenAmountCellWithBankOrderWarning';
import AccountCodeCell from './components/AccountCodeCell/AccountCodeCell';
import ContractLastDunningDateCell from './components/ContractLastDunningDateCell';
import { DebtorBalancesGroupedType } from '../../services/interfaces';
import { ContractBalanceActions } from './components/ContractBalanceActions/ContractBalanceActions';

export const useContractBalanceTableColumns = (
  WIDTH_COL_1: number,
  WIDTH_COL_2: number,
  WIDTH_COL_3: number,
  WIDTH_COL_4: number,
) => {
  const { tl } = useContext(LanguageContext);

  const dunningLevelColumnTitle = (
    <div>
      {tl(orderOpenBalancesTranslations.innerTableColumns.upcomingDunningLevel)}
      <InfoIcon
        popupText={(
          <AlertArticleLink
            label={tl(orderOpenBalancesTranslations.innerTableColumns.articleLinkLabel)}
            content={tl(orderOpenBalancesTranslations.innerTableColumns.articleLinkContent)}
            url={articleUrls.orderOpenBalances.dunningLevel}
          />
        )}
        tooltipMaxWidth="unset"
        size="small"
      />
    </div>
  );

  const columns = useMemo(() => ([
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: tl(orderOpenBalancesTranslations.innerTableColumns.accountNr),
      fixed: 'left' as const,
      dataIndex: 'accountCode',
      render: (_, record) => (
        <AccountCodeCell
          propertyHrId={record.propertyHrId}
          accountCode={record.accountCode}
        />
      ),
      width: WIDTH_COL_1,
    },
    {
      title: tl(orderOpenBalancesTranslations.innerTableColumns.accountName),
      fixed: 'left' as const,
      dataIndex: 'accountNameCell',
      render: (_, record) => (
        <AccountNameCell
          accountName={record.accountName}
          accountCode={record.accountCode}
          propertyHrId={record.propertyHrId}
        />
      ),
      width: WIDTH_COL_2,
    },
    {
      title: tl(orderOpenBalancesTranslations.innerTableColumns.openBalance),
      fixed: 'left' as const,
      dataIndex: 'filteredAccountBalance',
      sorter: (a, b) => a.filteredAccountBalance - b.filteredAccountBalance,
      showSorterTooltip: false,
      className: 'column-align-right column-padding-medium',
      render: (_, record) => <OpenAmountCellWithBankOrderWarning record={record} />,
      width: WIDTH_COL_3,
    },
    {
      title: tl(orderOpenBalancesTranslations.outerTableColumns.dunningFeeNet),
      dataIndex: 'dunningFeeNet',
      render: (_, record: any) => (
        <DunningFeeCell
          accountCode={record.accountCode}
          propertyId={record.propertyId}
          contractId={record.contractId}
          contractDunningLevel={record.contractDunningLevel}
        />
      ),
      width: WIDTH_COL_4,
    },
    {
      title: dunningLevelColumnTitle,
      titleString: tl(orderOpenBalancesTranslations.innerTableColumns.upcomingDunningLevel),
      dataIndex: 'dunningLevelCell',
      className: 'column-with-input',
      render: (_, record: any) => <DunningLevelSelectorCell record={record} />,
    },
    {
      title: tl(orderOpenBalancesTranslations.innerTableColumns.lastDunningNote),
      dataIndex: 'lastDunningDate',
      render: (_, record: any) => <ContractLastDunningDateCell record={record} />,
      width: 200,
    },
    {
      dataIndex: 'options',
      render: (_, record: any) => (<ContractBalanceActions record={record} />),
      width: 50,
    },
  ] as ColumnsType<DebtorBalancesGroupedType>), [tl]);


  return { columns };
};
