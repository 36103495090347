import { RecipientDatasource } from 'pages/MessageSendingPage/interfaces';
import { Link } from 'react-router-dom';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { useContractContactCell } from './useContractContactCell';

const ContractContactCell = ({ record } : {record: RecipientDatasource}) => {
  const { url } = useContractContactCell(record);

  if (record.roles === undefined) {
    return (
      <Link to={url} target="_blank">{record.name}</Link>
    );
  }

  return (
    <Link to={url} target="_blank">
      <CellWithTwoLines
        firstElement={record.name}
        secondElement={record.roles}
      />
    </Link>
  );
};

export default ContractContactCell;
