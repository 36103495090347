import { useContext } from 'react';
import { DirectDebitMandateListContext } from './DirectDebitMandateListContext';

// TODO use this on mandate creation page
export const useInvalidateDirectDebitMandateListCache = () => {
  const directDebitMandateListContext = useContext(DirectDebitMandateListContext);

  if (directDebitMandateListContext === undefined) {
    throw new Error('useInvalidateDirectDebitMandateListCache must be used within a DirectDebitMandateListContextProvider!');
  }


  const {
    setParamOfCachedValue,
  } = directDebitMandateListContext;


  const invalidateCache = () => {
    setParamOfCachedValue(undefined);
  };


  return {
    invalidateCache,
  };
};
