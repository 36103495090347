import {
  ContractProjectionDtoDunningLevelEnum, DirectDebitProjectionDto, GetMessagesUsingGETStateEnum, PaymentProjectionDto, PropertyContractsBalanceDto,
} from 'api/accounting';

export enum DunningLevelEnum {
    PAY_OUT = 'payingOutMoneyDoc',
    SEPA_DIRECT_DEBIT = 'drawMoneyDoc',
    PAYMENT_REMINDER = 'dunningLevel1Doc',
    PAYMENT_REMINDER_TAKEOVER_PROPERTY = 'dunningLevel1.1Doc',
    DUNNING_NOTE = 'dunningLevel2Doc',
    LAST_DUNNING_NOTE = 'dunningLevel3Doc',
    NO_MESSAGE = '',
  }

export interface ContractDunningLevel {
    contractId: number,
    dunningLevel: DunningLevelEnum
  }

//  FE Objects
export interface AccountBalanceGroupData {
    contractId: number;
    contractHasMandate: boolean;
    accountCode: string;
    overallAccountBalance: number;
    todaysAccountBalance: number;
    filteredAccountBalance: number;
    overallAccountVatBalance: number;
    todaysAccountVatBalance: number;
    filteredAccountVatBalance: number;
    id: string;
    propertyId: number;
    propertyHrId: string;
    propertyIdInternal: string;
    bankAccountId?: number;
    children?: AccountBalanceGroupData[];
    contractDunningLevel: ContractProjectionDtoDunningLevelEnum;
    unitRank?: number;
    lastDunningState?: GetMessagesUsingGETStateEnum;
    lastDunningDate?: string;
    lastDunningId?: number;
  }

export interface DebtorBalancesGroupedType extends AccountBalanceGroupData {
    isRootAccount: boolean;
    contractId: number;
    unitRank?: number;
    bankAccountId?: number;
    accountName?: string;
  }

export interface PropertyListWithOpenAccounts extends PropertyContractsBalanceDto {
    debtorBalancesGrouped: DebtorBalancesGroupedType[];
    allKeysOfDebtorBalancesGrouped: string[];
  }


export interface OpenBankOrderAndFutureBalance {
    contractIban: string,
    managementCompanyId: number,
    sumDirectDebits: number,
    sumPayments: number,
    directDebits: DirectDebitProjectionDto[],
    payments: PaymentProjectionDto[],
    todaysAccountBalance: number,
    overallAccountBalance: number,
  }

// Key is contractId
export type OpenBankOrdersAndFutureBalance = Map<number, OpenBankOrderAndFutureBalance>;

export interface DunningFee {
    propertyId?: number,
    contractId: number,
    dunningFeeNet: number,
    propertyHrId: string,
    accountCode: string,
    vatAmount?: number,
  }

export interface OpenTransactionNumber {
    [key: string]: number,
  }

// BE Object
export interface PropertyContractsDebtorBalances {
    unitRank: number;
    accountId: number;
    contractId: number;
    unitNrSharingDeclaration: string;
    accountCode: string;
    accountName: string;
    overallAccountBalance: number;
    todaysAccountBalance: number;
    filteredAccountBalance: number;
    overallAccountVatBalance: number;
    todaysAccountVatBalance: number;
    filteredAccountVatBalance: number;
    bankAccountId: number;
    contractDunningLevel: ContractProjectionDtoDunningLevelEnum,
    contractIsActive: boolean;
    contractHasMandate: boolean;
    lastDunningState?: GetMessagesUsingGETStateEnum;
    lastDunningDate?: string;
    lastDunningId?: number;
  }
