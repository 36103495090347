import Icon from '@ant-design/icons';
import { Alert } from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { CorrectionalBookings } from './CorrectionalBookings/CorrectionalBookings';
import { correctionsWrapperTranslations } from './correctionsWrapperTranslations';
import { PastBookings } from './PastBookings/PastBookings';
import { useCorrectionsWrapper } from './useCorrectionsWrapper';

export const CorrectionsWrapper = () => {
  const { tl } = useContext(LanguageContext);
  const {
    hasOverlaps,
    economicPlanSaved,
  } = useCorrectionsWrapper();

  if (!economicPlanSaved) {
    return (
      <Alert
        message={tl(correctionsWrapperTranslations.saveEconomicPlanInfoText)}
        type="info"
        showIcon
        icon={<Icon component={ICONS.info} />}
      />
    );
  }

  return (
    hasOverlaps ? <CorrectionalBookings /> : <PastBookings />
  );
};
