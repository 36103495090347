import React, { useContext } from 'react';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import { economicPlanTranslations } from '../../../economicPlanTranslations';
import Button from '../../../../../elements/Buttons/Button/Button';
import StateChangeButtons from '../../../components/StateChangeButtons/StateChangeButtons';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { useSaveUnitFiguresEconomicPlan } from '../../services/useSaveUnitFiguresEconomicPlan';
import { useEconomicPlanHouseMoneyHeader } from './useEconomicPlanHouseMoneyHeader';
import { DropMenu } from '../../../../../components/DropMenu/DropMenu';
import './EconomicPlanHouseMoneyHeader.scss';

export const EconomicPlanHouseMoneyHeader = () => {
  const { tl } = useContext(LanguageContext);

  const {
    goBack,
    stateColor,
    isCreationFlow,
    property,
    stateName,
    title,
    dropMenuOptionList,
  } = useEconomicPlanHouseMoneyHeader();

  const {
    saveAndOpenNextSection: save,
    canSave,
  } = useSaveUnitFiguresEconomicPlan();

  return (
    <PageHeader
      title={title ?? tl(economicPlanTranslations.economicPlan.header.title)}
      subtitle={<PropertyInfo property={property} />}
      tagContent={stateName}
      tagColor={stateColor as PageHeaderTagColor}
      showLogo={isCreationFlow}
      rightSideComponent={(
        <div className="header-buttons">
          <Button
            type="text"
            onClick={goBack}
          >
            {tl(economicPlanTranslations.economicPlan.header.buttons.close)}
          </Button>

          {!isCreationFlow && <DropMenu options={dropMenuOptionList} />}

          <Button
            type="ghost"
            onClick={save}
            disabled={!canSave}
          >
            {tl(economicPlanTranslations.economicPlan.header.buttons.save)}
          </Button>
          <StateChangeButtons saveBeforeMarkingPrepared={save} />
        </div>
      )}
    />
  );
};
