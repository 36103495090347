import {
  useContext, useState, useEffect, Context,
} from 'react';
import { useParams } from 'react-router';
import { AuthContext } from 'contexts/AuthContext';
import { MetabaseApi } from 'api/app/apis/MetabaseApi';
import { Configuration } from 'api/app/runtime';
import './EmbeddedPage.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { GlobalSearchContext } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { useAnalyticsDomainDataList } from '../analyticsDomainDataList';
import { getEnvVar } from 'lib/getEnvVar';

const NOT_FOUND = {
  title: { en: '404', de: '404' },
  resource: 'not:found',
  resourceId: 404,
  path: 'not:found',
};

export const useEmbeddedPage = () => {
  const { tl } = useContext(LanguageContext);
  const { setHideGlobalSearch } = useContext(GlobalSearchContext);
  const { subcategorySwitcherItems } = useSiteMap();
  const { apiConfiguration } = useContext<{apiConfiguration:(string)=>Configuration}>(AuthContext as unknown as Context<{apiConfiguration:(string)=>Configuration}>);
  const metabaseApi = new MetabaseApi(apiConfiguration('app'));

  const { analyticsDomainData } = useAnalyticsDomainDataList();
  const { resourceParam, resourceIdParam } = useParams<{ resourceParam: string, resourceIdParam: string }>();
  const [embedPath, setEmbedPath] = useState<string>('');

  const dashboardPath = `/analytics/${resourceParam}/${resourceIdParam}`;


  useEffect(() => {
    // hide search on mount
    setHideGlobalSearch(true);

    return () => {
      // unhide search bar on unmount
      setHideGlobalSearch(false);
    };
  }, []);

  const {
    title, resource, resourceId, path,
  } = !analyticsDomainData ? NOT_FOUND : (analyticsDomainData
    .flatMap(g => g.pages)
    .filter(page => page.path === dashboardPath)[0] || NOT_FOUND);
  useEffect(() => {
    if (resource === 'not:found') { return; }
    (async () => {
      const METABASE_BASE_URL = getEnvVar('metabase.baseUrl', 'https://metabase.develop.impower.de') as string;
      const { token } = await metabaseApi.generateResourceTokenUsingPOST({ resource, resourceId });
      setEmbedPath(`${METABASE_BASE_URL}/embed/${resource}/${token}#bordered=false&titled=false`);
    })();
  }, [path]);

  const subcategorySwitcherProps = {
    selectedKey: dashboardPath,
    navItems: subcategorySwitcherItems.analytics,
  };
  return {
    subcategorySwitcherProps,
    title: tl(title),
    embedPath,
  };
};
