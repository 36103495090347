import {
  useContext,
  useState,
} from 'react';

import {
  BankingBankAccountControllerApi,
  ContactLegacyControllerApi,
  ContractLegacyControllerApi,
  PropertyBankAccountControllerApi,
  PropertyLegacyControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';

import { BankAccountImportDto } from './BankAccountsImportSection/interfaces';
import {
  importBankAccounts,
} from './BankAccountsImportSection/useBankAccountsImportCsv';
import { ContactImportDto } from './ContactsImportSection/interfaces';
import { importContacts } from './ContactsImportSection/useContactsImportCsv';
import { IProgress } from './interfaces';
import { PropertyImportDto } from './PropertiesImportSection/interfaces';
import {
  importProperties,
} from './PropertiesImportSection/usePropertiesImportCsv';
import { translations } from './translations';
import { executeInParallelBatch } from './utils/executeInParallelBatch';

const DEFAULT_PROGRESS = {
  started: false,
  finished: false,
  properties: [],
  propertiesImported: 0,
  bankAccounts: [],
  bankAccountsImported: 0,
  contacts: [],
  contactsImported: 0,
};

export const useImport = () => {
  const [progress, setProgress] = useState<IProgress>(DEFAULT_PROGRESS);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const bankingBankAccountControllerApi = new BankingBankAccountControllerApi(apiConfiguration('accounting'));
  const propertyBankAccountControllerApi = new PropertyBankAccountControllerApi(apiConfiguration('accounting'));

  const importData = async ({
    contacts, properties, bankAccounts,
  }: { contacts: ContactImportDto[], properties: PropertyImportDto[], bankAccounts: BankAccountImportDto[] }) => {
    const prgrs = {
      started: true,
      finished: false,
      contacts,
      contactsImported: 0,
      properties,
      propertiesImported: 0,
      bankAccounts,
      bankAccountsImported: 0,
    };
    setProgress(prgrs);
    try {
      await importContacts(contacts, contactControllerApi, setProgress);
      if (properties.length) {
        await importProperties(properties, bankAccounts, propertyControllerApi, bankingBankAccountControllerApi, propertyBankAccountControllerApi, contactControllerApi, contractControllerApi, setProgress);
      }
      if (bankAccounts.every(ba => ba.propertyId)) {
        await importBankAccounts(bankAccounts, bankingBankAccountControllerApi, propertyBankAccountControllerApi, setProgress);
      }
    } catch (e) {
      console.error('Import stopped', e, e.response);
      showNotification({
        key: 'importValidation-error',
        duration: 0,
        message: tl(translations.notifications.error.title),
        description: tl(translations.notifications.error.description),
        type: 'error',
      });
      setProgress(prev => ({ ...prev, finished: true }));
    }
  };
  /**
   * Delete the data the has been just imported
   */
  const deleteImportedData = async (contacts, properties) => {
    await executeInParallelBatch(contacts.filter(e => e.importedObject), 10,
      async (e) => { try { await contactControllerApi.deleteContactUsingDELETE({ contactId: e.importedObject.id }); } catch (e1) { console.warn(e1); } });
    await executeInParallelBatch(properties.filter(e => e.importedObject), 10,
      async (e) => { try { await propertyControllerApi.deletePropertyUsingDELETE({ propertyId: e.importedObject.id }); } catch (e1) { console.warn(e1); } });
  };
  return {
    importData,
    deleteImportedData,
    progress,
  };
};
