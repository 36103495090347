import React, { useContext } from 'react';
import { useTransactionImports } from 'pages/TransactionImports/CamtImports/services/useTransactionImports';
import { useFileUploadModal } from 'elements/Modals/FileUploadModal/useFileUploadModal';
import FileUploadModal from 'elements/Modals/FileUploadModal/FileUploadModal';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from './translations';

export const TransactionImportActions = () => {
  const { tl } = useContext(LanguageContext);
  const { upload, loading } = useTransactionImports();

  const importTransactionsModal = useFileUploadModal({
    onUpload: upload,
    accept: 'text/xml',
    name: 'import-transactions',
    body: tl(translations.fileUploadModal.body),
    title: tl(translations.fileUploadModal.title),
    upload: tl(translations.fileUploadModal.upload),
    multiple: true,
  });

  return (
    <div>
      <Button
        type="primary"
        onClick={importTransactionsModal.showModal}
        loading={loading}
      >
        {tl(translations.uploadCamtXml)}
      </Button>
      <FileUploadModal {...importTransactionsModal} />
    </div>
  );
};
