import { RouteDefinition } from 'components/RenderRoutes';
import { Dashboard } from './Dashboard';

export const DashboardRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/dashboard',
      component: Dashboard,
    },
  ],
};
