import './AgendaItemVotingResult.scss';

import React, { useContext, useEffect } from 'react';

import {
  Col,
  Row,
} from 'antd';
import RadioGroupInput from 'elements/Inputs/RadioGroupInput/RadioGroupInput';

import {
  AgendaItemVoteDtoStatusEnum,
  AgendaItemVoteDtoVoteKeyEnum,
  AgendaItemVoteDtoVoteMajorityKeyEnum,
} from '../../../../../../api/accounting';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import NumberInput
  from '../../../../../../elements/Inputs/NumberInput/NumberInput';
import SelectInput
  from '../../../../../../elements/Inputs/SelectInput/SelectInput';
import TextAreaInput
  from '../../../../../../elements/Inputs/TextAreaInput/TextAreaInput';
import {
  OwnersMeetingProtocolContext,
} from '../../../../services/OwnersMeetingProtocolContext';
import {
  useAgendaItemDetails,
} from '../OwnersMeetingProtocolAgendaItems/services/useAgendaItemDetails';
import {
  useAgendaItemVotingResult,
} from './services/useAgendaItemVotingResult';
import { translations } from './translations';

function AgendaItemVotingResult(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    selectedAgendaItemVote, onChangeVoteResult, isDisabled, isDiscussionTopic,
  } = useAgendaItemDetails();
  const { votingStatus, isStandardVoteDetails, toggleStandardVoteDetails } = useAgendaItemVotingResult();
  const {
    ownersMeeting,
  } = useContext(OwnersMeetingProtocolContext);

  const countingMethod = selectedAgendaItemVote?.data?.voteKey ? selectedAgendaItemVote?.data?.voteKey : (ownersMeeting?.data?.votingRights || '');

  return (
    <div className="AgendaItemVotingResult">
      <Row gutter={[40, 20]}>
        <Col span={12}>
          <SelectInput
            value={isDiscussionTopic ? AgendaItemVoteDtoStatusEnum.DRAFT : (selectedAgendaItemVote?.data?.status || AgendaItemVoteDtoStatusEnum.DRAFT)}
            label={tl(translations.votingResult.resolutionStatus)}
            placeholder={tl(translations.votingResult?.placeholder)}
            dropdownMatchSelectWidth={false}
            options={votingStatus}
            disabled={isDisabled || isDiscussionTopic}
            onChange={(val: string) => { onChangeVoteResult('status', val); }}
            required
          />
        </Col>
        <Col span={12} />
        <Col span={12}>
          <RadioGroupInput
            label={tl(translations.votingResult.radioGroup.label)}
            onChange={toggleStandardVoteDetails}
            options={[
              {
                value: true,
                label: tl(translations.votingResult.radioGroup.options.standard),
              },
              {
                value: false,
                label: tl(translations.votingResult.radioGroup.options.alternative),
              },
            ]}
            value={isStandardVoteDetails}
            disabled={isDisabled || isDiscussionTopic}
            required={!isDiscussionTopic}
          />
        </Col>
        <Col span={24}>
          <TextAreaInput
            value={isDiscussionTopic ? '' : (selectedAgendaItemVote?.data?.voteDetails || '')}
            onChange={(val: string) => { onChangeVoteResult('voteDetails', val); }}
            label={tl(translations.votingResult.voteDetails)}
            className="vote-details input"
            disabled={isDisabled || isDiscussionTopic || isStandardVoteDetails}
            required={!isStandardVoteDetails}
          />
        </Col>
        <Col span={12}>
          <SelectInput
            value={isDiscussionTopic ? '' : countingMethod}
            label={tl(translations.votingResult.countingMethod)}
            placeholder={tl(translations.votingResult?.placeholder)}
            dropdownMatchSelectWidth={false}
            options={[
              {
                value: AgendaItemVoteDtoVoteKeyEnum.MEA,
                label: tl(translations.votingResult.countingMethods.mea),
              },
              {
                value: AgendaItemVoteDtoVoteKeyEnum.HEAD,
                label: tl(translations.votingResult.countingMethods.peopleHeadCount),
              },
              {
                value: AgendaItemVoteDtoVoteKeyEnum.UNIT,
                label: tl(translations.votingResult.countingMethods.numberOfUnits),
              },
            ]}
            disabled={isDisabled || isDiscussionTopic}
            onChange={(val: string) => { onChangeVoteResult('voteKey', val); }}
          />
        </Col>
        <Col span={12}>
          <SelectInput
            dropdownClassName="voteMajorityKeySelectInput"
            value={isDiscussionTopic ? '' : (selectedAgendaItemVote?.data?.voteMajorityKey || '')}
            label={tl(translations.votingResult.majorityRequirement)}
            placeholder={tl(translations.votingResult?.placeholder)}
            dropdownMatchSelectWidth={false}
            options={[
              {
                value: AgendaItemVoteDtoVoteMajorityKeyEnum.ABSOLUTE_MAJORITY,
                label: tl(translations.votingResult.majorityRequirementOptions.absolute_majority),
              },
              {
                value: AgendaItemVoteDtoVoteMajorityKeyEnum.ATTENDING_UNANIMOUS_MAJORITY,
                label: tl(translations.votingResult.majorityRequirementOptions.attending_unanimous_majority),
              },
              {
                value: AgendaItemVoteDtoVoteMajorityKeyEnum.QUALIFIED_MAJORITY,
                label: tl(translations.votingResult.majorityRequirementOptions.qualified_majority),
              },
              {
                value: AgendaItemVoteDtoVoteMajorityKeyEnum.UNANIMOUS_MAJORITY,
                label: tl(translations.votingResult.majorityRequirementOptions.unanimous_majority),
              },
            ]}
            disabled={isDisabled || isDiscussionTopic}
            onChange={(val: string) => { onChangeVoteResult('voteMajorityKey', val); }}
          />
        </Col>

        <Col span={6}>
          <NumberInput
            onChange={(val: number) => { onChangeVoteResult('presenceCount', val); }}
            label={tl(translations.votingResult.present)}
            value={isDiscussionTopic ? null : (selectedAgendaItemVote?.data?.presenceCount || null)}
            className="input"
            disabled={isDisabled || isDiscussionTopic}
            precision={4}
          />
        </Col>
        <Col span={6}>
          <NumberInput
            onChange={(val: number) => { onChangeVoteResult('voteYesCount', val); }}
            label={tl(translations.votingResult.yes)}
            value={isDiscussionTopic ? null : (selectedAgendaItemVote?.data?.voteYesCount || null)}
            className="input"
            disabled={isDisabled || isDiscussionTopic}
            precision={4}
            min={0}
          />
        </Col>
        <Col span={6}>
          <NumberInput
            onChange={(val: number) => { onChangeVoteResult('voteNoCount', val); }}
            label={tl(translations.votingResult.no)}
            value={isDiscussionTopic ? null : (selectedAgendaItemVote?.data?.voteNoCount || null)}
            className="input"
            disabled={isDisabled || isDiscussionTopic}
            precision={4}
            min={0}
          />
        </Col>
        <Col span={6}>
          <NumberInput
            onChange={(val: number) => { onChangeVoteResult('voteAbstentionCount', val); }}
            label={tl(translations.votingResult.abstention)}
            value={isDiscussionTopic ? null : (selectedAgendaItemVote?.data?.voteAbstentionCount || null)}
            className="input"
            disabled={isDisabled || isDiscussionTopic}
            precision={4}
            min={0}
          />
        </Col>

      </Row>
    </div>
  );
}

export default AgendaItemVotingResult;
