import { AccountDto, PostingControllerApi, PostingDto, PropertyDisplayDtoVatRelevanceEnum } from 'api/accounting';
import Big from 'big.js';
import { AccountBooking, AccountBookingContext, ValidationErrors } from 'contexts/AccountBookingContext';
import { AccountListContext } from 'contexts/AccountListContext';
import { AccountsBalanceContext } from 'contexts/AccountsBalanceContext';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { NotificationObject, showNotification } from 'lib/Notification';
import _ from 'lodash';
import { useContext } from 'react';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { useGetPropertyByHrId } from 'services/useGetPropertyByHrId';
import { translations } from '../../../elements/Translation/translations';

export const useAccountBookingForm = () => {
  const accountBookingFormContext = useContext(AccountBookingContext);

  if (accountBookingFormContext === undefined) {
    throw new Error('useAccountBookingForm must be used within a AccountBookingContext provider');
  }

  const {
    accountBookings, setAccountBookings, formDirty, setFormDirty, propertyHrId, setPropertyHrId, bookingPressed, setBookingPressed, setValidationErrors,
  } = accountBookingFormContext;

  const { property } = useGetPropertyByHrId(propertyHrId);
  const vatRelevantProperty = property?.vatRelevance !== undefined && property?.vatRelevance !== PropertyDisplayDtoVatRelevanceEnum.NOT_RELEVANT;

  const { tl } = useContext(LanguageContext);

  const { goBack } = useContext(OverlayContext);

  const { getAccountByCode } = useContext(AccountListContext);
  const { getInitialAccountBalances } = useContext(AccountsBalanceContext);

  const { apiConfiguration } = useContext(AuthContext);
  const postingControllerApi = new PostingControllerApi(apiConfiguration('accounting'));

  const onClearBookings = () => {
    setAccountBookings(DEFAULT_DATA<AccountBooking[]>([{}]));
    setFormDirty(false);
  };

  function convertToBEData(booking: AccountBooking, accounts: AccountDto[]) {
    const debitAccount = accounts.filter((acc: AccountDto) => acc.code === booking.debitAccount).length > 0 ? accounts.filter((acc: AccountDto) => acc.code === booking.debitAccount)[0] : {};
    const creditAccount = accounts.filter((acc: AccountDto) => acc.code === booking.creditAccount).length > 0 ? accounts.filter((acc: AccountDto) => acc.code === booking.creditAccount)[0] : {};
    if (!debitAccount || !creditAccount) {
      showNotification({
        key: 'loadAccountError',
        message: `${!debitAccount ? booking.debitAccount : booking.creditAccount} ${tl(translations.notifications.accountBookingContext.loadAccountError.messageNotFound)}`,
        type: 'error',
      });
      return { error: 'Account not found' };
    }
    return {
      propertyHrId,
      postDate: booking.postDate,
      reference: '',
      postingItems: [
        {
          bookingText: booking.bookingText,
          amount: Math.abs(booking.amount),
          laborCost: Math.abs(booking.laborCost),
          laborCostType: booking.laborCostType,
          vatAmount: Math.abs(booking.vatAmount),
          vatPercentage: booking.vatPercentage,
          vatEligibilityPercentage: booking.vatEligibilityPercentage,
          vatEligibilityAmount: booking.vatEligibilityPercentage !== undefined ? Math.abs(booking.vatEligibilityAmount) : undefined,
          account: { id: debitAccount.id, code: debitAccount.code },
        },
        {
          bookingText: booking.bookingText,
          amount: (-1) * Math.abs(booking.amount),
          laborCost: (-1) * Math.abs(booking.laborCost),
          laborCostType: booking.laborCostType,
          vatAmount: (-1) * Math.abs(booking.vatAmount),
          vatPercentage: booking.vatPercentage,
          vatEligibilityPercentage: booking.vatEligibilityPercentage,
          vatEligibilityAmount: booking.vatEligibilityPercentage !== undefined ? (-1) * Math.abs(booking.vatEligibilityAmount) : undefined,
          account: { id: creditAccount.id, code: creditAccount.code },
        },
      ],
    };
  }

  const validateBookings = (): boolean => {
    const formValidationErrors = accountBookings.data.map((booking: AccountBooking) => {
      const validationErrors: ValidationErrors = {};
      if (!booking.amount) {
        validationErrors.amount = {
          validationState: 'error',
          validationMessage: tl(translations.validations.mandatory),
        };
      }

      if (!booking.creditAccount) {
        validationErrors.creditAccount = {
          validationState: 'error',
          validationMessage: tl(translations.validations.mandatory),
        };
      }

      if (!booking.debitAccount) {
        validationErrors.debitAccount = {
          validationState: 'error',
          validationMessage: tl(translations.validations.mandatory),
        };
      }

      if (!booking.postDate) {
        validationErrors.postDate = {
          validationState: 'error',
          validationMessage: tl(translations.validations.mandatory),
        };
      }

      if (!booking.laborCostType && booking.laborCost) {
        validationErrors.laborCostType = {
          validationState: 'error',
          validationMessage: tl(translations.validations.mandatory),
        };
      }

      const laborCostAmountTooBig = booking.laborCost && booking.laborCost > ((booking.amount ?? 0) + (booking.vatAmount ?? 0));

      if (laborCostAmountTooBig) {
        validationErrors.laborCost = {
          validationState: 'error',
          validationMessage: tl(translations.validations.tooBig),
        };
      }

      const vatAmountTooBig = booking.vatAmount && booking.vatAmount > booking.amount;

      if (vatAmountTooBig) {
        validationErrors.vatAmount = {
          validationState: 'error',
          validationMessage: tl(translations.validations.tooBig),
        };
      }

      return validationErrors;
    });

    setValidationErrors(formValidationErrors);
    return formValidationErrors.every(rowValidationErros => _.isEmpty(rowValidationErros));
  };

  const onBook = () => {
    if (!validateBookings()) {
      showNotification({
        key: 'createBookingsError',
        message: tl(translations.notifications.accountBookingContext.validationError.message),
        type: 'error',
      });
      return;
    }
    setFormDirty(false);
    setBookingPressed(true);
    const promises: any = [];
    accountBookings.data.forEach((booking: AccountBooking) => {
      if (!_.isEmpty(booking)) {
        promises.push(getAccountByCode(booking.debitAccount, propertyHrId));
        promises.push(getAccountByCode(booking.creditAccount, propertyHrId));
      }
    });
    Promise.all(promises)
      .then((responses: AccountDto[]) => {
        const postings: PostingDto[] = [];
        accountBookings.data.forEach((booking: AccountBooking) => {
          const data = convertToBEData(booking, responses);
          if (!Object.keys(data).includes('error') && !_.isEmpty(booking)) {
            // @ts-ignore
            postings.push(data);
          }
        });
        if (postings.length > 0) {
          createPostings(postings);
        }
        onClearBookings();
      })
      .catch(() => {
        showNotification({
          key: 'loadAccountListError',
          message: tl(translations.notifications.accountContext.loadAccountError.message),
          description: tl(translations.notifications.accountContext.loadAccountError.description),
          type: 'error',
        });
      });
  };

  const createPostings = async (postings: PostingDto[]) => {
    try {
      await postingControllerApi.createPostingsUsingPOST({ postings });
      const successMessage: NotificationObject = {
        key: 'createBookingsSuccess',
        message: tl(translations.notifications.accountBookingContext.saveSuccess.message),
        type: 'success',
      };
      showNotification(successMessage);
      setBookingPressed(false);
      setFormDirty(false);
      getInitialAccountBalances();
      goBack();
    } catch (e) {
      console.error('Failed to create postings', e);
      setBookingPressed(false);
      showNotification({
        key: 'createBookingsError',
        message: tl(translations.notifications.accountBookingContext.saveError.message),
        type: 'error',
      });
    }
  };

  const addBooking = () => {
    const newBookings = accountBookings.data.slice();
    newBookings.push({});
    setAccountBookings((bookings: DefaultDataInterface<AccountBooking[]>) => bookings.load(newBookings));
  };

  const deleteBooking = (index: number) => {
    setAccountBookings((bookings) => {
      const newBookings = bookings.data;
      newBookings.splice(index, 1);
      return bookings.load(newBookings);
    });
  };

  const onChangeBooking = (inputValue: any, key: string, index: number) => {
    setAccountBookings((oldBookings: DefaultDataInterface<AccountBooking[]>) => {
      let newBookings: AccountBooking[] = oldBookings.data;

      newBookings = oldBookings.data.map((bk, idx) => {
        if (idx === index) {
          return { ...bk, [key]: inputValue };
        }
        return bk;
      });

      return oldBookings.load(newBookings);
    });
    setFormDirty(true);
  };

  const onVisibilityChange = (visible: boolean, keys: string[], index: number) => {
    if (!visible) {
      const keysToReset = keys.reduce((acc, key) => ({
        ...acc,
        [key]: undefined,
      }), {});

      setAccountBookings((oldBookings: DefaultDataInterface<AccountBooking[]>) => {
        const newBookings = oldBookings.data.map((bk, idx) => {
          if (idx === index) {
            return { ...bk, ...keysToReset };
          }
          return bk;
        });

        return oldBookings.load(newBookings);
      });
      setFormDirty(true);
    } else if (keys.includes('vatPercentage') && vatRelevantProperty) {
      setAccountBookings((oldBookings: DefaultDataInterface<AccountBooking[]>) => {
        const newBookings = oldBookings.data.map((bk, idx) => {
          if (idx === index) {
            return { ...bk };
          }
          return bk;
        });
        return oldBookings.load(newBookings);
      });
    }
  };

  const onChangeVatPercentage = (vatPercentage: number, index: number) => {
    if (vatPercentage !== null && vatPercentage !== undefined) {
      const vatPercentageBig = new Big(vatPercentage);
      const totalGrossAmountBig = new Big(accountBookings.data[index].amount ?? 0);
      const vatAmountBig = vatPercentageBig.times(totalGrossAmountBig).div(new Big(100).add(vatPercentageBig));
      const vatAmount = parseFloat(vatAmountBig.toFixed(2));
      const vatEligibilityPercentageBig = accountBookings.data[index].vatEligibilityPercentage !== undefined
        ? new Big(accountBookings.data[index].vatEligibilityPercentage)
        : undefined;
      const vatEligibilityAmount = vatEligibilityPercentageBig !== undefined
        ? parseFloat(vatAmountBig.times(vatEligibilityPercentageBig).div(new Big(100)).toFixed(2))
        : undefined;

      setAccountBookings((oldBookings) => {
        const newBookings = oldBookings.data.map((bk, idx) => {
          if (idx === index) {
            return {
              ...bk,
              vatAmount,
              vatPercentage,
              vatEligibilityAmount,
            };
          }
          return bk;
        });

        return oldBookings.load(newBookings);
      });
    } else {
      setAccountBookings((oldBookings) => {
        const newBookings = oldBookings.data.map((bk, idx) => {
          if (idx === index) {
            return {
              ...bk,
              vatAmount: undefined,
              vatPercentage: undefined,
              vatEligibilityPercentage: undefined,
              vatEligibilityAmount: undefined,
            };
          }
          return bk;
        });

        return oldBookings.load(newBookings);
      });
    }
  };

  const onChangeGrossAmount = (totalGrossAmount: number, index: number) => {
    if (totalGrossAmount !== null && totalGrossAmount !== undefined) {
      const totalGrossAmountBig = new Big(totalGrossAmount);
      const vatPercentageBig = new Big(accountBookings.data[index].vatPercentage ?? 0);
      const vatAmountBig = vatPercentageBig.times(totalGrossAmountBig).div(new Big(100).add(vatPercentageBig));
      const vatAmount = parseFloat(vatAmountBig.toFixed(2));
      const vatEligibilityPercentageBig = accountBookings.data[index].vatEligibilityPercentage !== undefined
        ? new Big(accountBookings.data[index].vatEligibilityPercentage)
        : undefined;
      const vatEligibilityAmount = vatEligibilityPercentageBig !== undefined
        ? parseFloat(vatAmountBig.times(vatEligibilityPercentageBig).div(new Big(100)).toFixed(2))
        : undefined;

      setAccountBookings((oldBookings) => {
        const newBookings = oldBookings.data.map((bk, idx) => {
          if (idx === index) {
            return {
              ...bk,
              amount: totalGrossAmount,
              vatAmount,
              vatEligibilityAmount,
            };
          }
          return bk;
        });

        return oldBookings.load(newBookings);
      });
    }
  };

  const vatOptions = [
    {
      value: 19,
      label: '19%',
    },
    {
      value: 7,
      label: '7%',
    },
    {
      value: 16,
      label: '16%',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 20,
      label: '20%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 0,
      label: '0%',
    },
  ];

  const onChangeVatEligibilityPercentage = (percentage: number, index: number) => {
    const vatEligibilityPercentageBig = percentage !== undefined ? new Big(percentage) : undefined;
    const vatAmountBig = new Big(accountBookings.data[index].vatAmount ?? 0);
    const vatEligibilityAmount = percentage !== undefined
      ? parseFloat(vatAmountBig.times(vatEligibilityPercentageBig).div(new Big(100)).toFixed(2))
      : undefined;

    setAccountBookings((oldBookings) => {
      const newBookings = oldBookings.data.map((bk, idx) => {
        if (idx === index) {
          return {
            ...bk,
            vatEligibilityPercentage: percentage,
            vatEligibilityAmount,
          };
        }
        return bk;
      });

      return oldBookings.load(newBookings);
    });
  };

  return {
    setAccountBookings,
    onClearBookings,
    accountBookings,
    formDirty,
    setFormDirty,
    setPropertyHrId,
    onBook,
    bookingPressed,
    addBooking,
    deleteBooking,
    onChangeBooking,
    onVisibilityChange,
    onChangeVatPercentage,
    onChangeGrossAmount,
    vatOptions,
    onChangeVatEligibilityPercentage,
    vatRelevantProperty,
    propertyLoaded: property !== undefined,
  };
};
