import { useContext } from 'react';
import _ from 'lodash';
import { AmountDirectionAndProcessingValuesContext } from './HouseMoneySettlementCloseYearContext';


export const useInfoBarValues = () => {
  const amountDirectionAndProcessingValues = useContext(AmountDirectionAndProcessingValuesContext);

  const totalAmount = amountDirectionAndProcessingValues?.reduce((acc, curr) => acc + (curr.amount ?? 0), 0) ?? 0;

  const [countPlatform, countSelfPayout, totalAmountPayout, totalAmountPull] = amountDirectionAndProcessingValues?.filter(d => !_.isNil(d))
    .reduce(([countPlatformAcc, countSelfPayoutAcc, totalAmountPayoutAcc, totalAmountPullAcc], value) => [
      countPlatformAcc + (value.processing === 'PLATFORM' ? 1 : 0),
      countSelfPayoutAcc + (value.processing === 'SELF_PAYER' ? 1 : 0),
      totalAmountPayoutAcc + (!_.isNil(value.amount) && value.direction === 'outgoing' ? value.amount : 0),
      totalAmountPullAcc + (!_.isNil(value.amount) && value.direction === 'incoming' ? value.amount : 0),
    ], [0, 0, 0, 0]) ?? [0, 0, 0, 0, 0];


  return {
    totalAmount, countPlatform, countSelfPayout, totalAmountPayout, totalAmountPull,
  };
};
