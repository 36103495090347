import { Dispatch, SetStateAction, TdHTMLAttributes } from 'react';
import BasicDraggableBodyRow from './BasicDraggableBodyRow';

export const useBasicDragNDropTable = <T>({ setDataSource, onOrderingChange, isDisabled }: {setDataSource: Dispatch<SetStateAction<T[]>>, onOrderingChange?: () => void, isDisabled?: boolean}) => {
  const moveRow = (dragIndex, hoverIndex) => {
    setDataSource((prevData) => {
      const dragRow = prevData[dragIndex];
      const newData = [...prevData];
      newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, dragRow);
      onOrderingChange?.();
      return newData;
    });
  };

  const onRow = (record: T, index: number) => ({
    index,
    moveRow,
    isDisabled,
  } as TdHTMLAttributes<T>);

  return {
    components: {
      body: {
        row: BasicDraggableBodyRow,
      },
    },
    onRow,
  };
};
