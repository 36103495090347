import React from 'react';
import './FocusView.scss';

export default function FocusView({ children, zIndex }: any): JSX.Element {
  const overlay = document.getElementsByClassName('overlay-wrapper');
  return (
    <div className="focus-wrapper" style={{ zIndex }}>
      <div className={`FocusView ${overlay && overlay.length > 0 ? 'overlay' : ''}`}>
        {children}
      </div>
    </div>
  );
}
