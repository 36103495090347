import React, { useContext } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LanguageContext } from './LanguageContext';
import 'moment/locale/de';
import { ConfigProvider } from 'antd';
import deLocale from 'antd/es/locale/de_DE';
import enLocale from 'antd/es/locale/en_US';

interface Props {
  children: React.ReactNode,
}

const MuiPickersUtilsAndLocaleProvider = ({ children }: Props) => {
  const { language } = useContext(LanguageContext);
  const antdLocale = language === 'de' ? deLocale : enLocale;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
      <ConfigProvider locale={antdLocale}>
        {children}
      </ConfigProvider>
    </MuiPickersUtilsProvider>
  );
};

export default MuiPickersUtilsAndLocaleProvider;
