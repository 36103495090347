import React, { useContext } from 'react';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { economicPlanAccountEditingTranslations } from '../../economicPlanAccountEditingTranslations';
import { translations as generalTranslations } from '../../../../../elements/Translation/translations';
import { CurrencyInput } from '../../../../../elements/Inputs/CurrencyInput/CurrencyInput';
import { formatCurrency } from '../../../../../lib/Utils';

export const UseReserveFundsColumns = ({ onChange }: any) => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(economicPlanAccountEditingTranslations.sections.reserveFundsSection.table.account),
      dataIndex: 'account',
      defaultWidth: '40%',
    },
    {
      title: tl(economicPlanAccountEditingTranslations.sections.reserveFundsSection.table.distribution),
      dataIndex: 'economicPlanDistributionMode',
      defaultWidth: '30%',
      render: (item: any) => (
        <span>{item ? tl(generalTranslations.enums.distributionMode[`${item}`]) : ''}</span>
      ),
    },
    {
      title: tl(economicPlanAccountEditingTranslations.sections.reserveFundsSection.table.value),
      dataIndex: 'value',
      className: 'currency',
      defaultWidth: '30%',
      render: (item: any, record: any) => (
        onChange
          ? (
            <CurrencyInput
              onChange={value => onChange(record.accountCode, value)}
              label=""
              value={record.value}
            />
          )
          : <span>{formatCurrency(record.value)}</span>
      ),
    },
  ];
};
