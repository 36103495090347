import { useContext, useEffect, useState } from 'react';
import { usePdfViewer, UsePdfViewerArgs } from 'elements/PdfViewer/services/usePdfViewer';
import { range } from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { PdfPageAssignerDataContext, PdfPageAssignerUpdatersContext } from '../../services/PdfPageAssignerContext';
import { translations } from '../../translations';


interface UsePdfViewerWithAssignmentsArgs extends Pick<UsePdfViewerArgs, 'url' | 'filename'>{
  documentId: number,
}


export const usePdfViewerWithAssignments = ({ url, filename, documentId } : UsePdfViewerWithAssignmentsArgs) => {
  const { tl } = useContext(LanguageContext);
  const [pages, setPages] = useState([]);
  const { setLoadedPdfsData } = useContext(PdfPageAssignerUpdatersContext);
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);

  const { pdf } = usePdfViewer({ url, filename });
  const { viewMode } = pdfPageAssignerDataContext;


  useEffect(() => {
    if (!pdf) return;

    setLoadedPdfsData(prev => [
      ...prev.filter(p => p.documentId !== documentId),
      { documentId, nrPages: pdf.numPages },
    ]);
  }, [pdf]);

  useEffect(() => {
    if (!pdf) return;

    const promises = range(1, pdf.numPages + 1).map(pageNr => pdf.getPage(pageNr));

    Promise.allSettled(promises)
      .then((promiseResults) => {
        if (promiseResults.some(pr => pr.status === 'rejected')) {
          showNotification({
            key: 'renderPdfError',
            message: tl(translations.notifications.renderPdfPageError),
            type: 'error',
          });

          return;
        }

        /**
         * Reason for this @ts-ignore:
         *
         * A resolved promise can be fulfilled or rejected. The promise object only has the value property if it is fulfilled.
         * In line 41 i check if any of them have been rejected and return if yes.
         * So it will only get here if all of them have been fulfilled, but typescript doesn't understand this.
         * So the @ts-ignore is just me telling typescript: "thanks for the warning, but i know better".
         */
        setPages(
          // @ts-ignore
          promiseResults.map(pr => pr.value),
        );
      });
  }, [pdf, tl]);


  return {
    viewMode,
    pages,
    pdfFingerprint: pdf?.fingerprints?.[0],
  };
};
