import React, { createContext, ReactChild, ReactChildren } from 'react';
import { matchPath } from 'react-router';


export const CurrentOverlayInfoContext = createContext<{ isOverlayOnTop: boolean } | undefined>(undefined);

interface Props {
  children: ReactChild | ReactChildren;
  currentPathname: string;
  pathPattern: string | readonly string[];
}

const CurrentOverlayInfoProvider = ({ children, currentPathname, pathPattern }: Props) => {
  const isOverlayOnTop = !!matchPath(currentPathname, { path: pathPattern })?.isExact;

  return (
    <CurrentOverlayInfoContext.Provider value={{ isOverlayOnTop }}>
      {children}
    </CurrentOverlayInfoContext.Provider>
  );
};

export default CurrentOverlayInfoProvider;
