import { useContext } from 'react';
import { DEFAULT_DIRT_MODAL_STATE, SimpleDirtModalContext } from './SimpleDirtModalContext';

export interface ShowModalProps {
  onSave: () => Promise<any>,
  onDiscard: () => Promise<any>,
  onProceedCallback?: () => void,
}

export const useSimpleDirtModal = () => {
  const simpleDirtModalContext = useContext(SimpleDirtModalContext);
  if (simpleDirtModalContext === undefined) {
    throw Error('useSimpleDirtModal must be used withing SimpleDirtModalContextProvider');
  }

  const {
    dirty, setDirty, setModalProps,
  } = simpleDirtModalContext;

  const addDirt = () => {
    setDirty(true);
  };

  const clearDirty = () => {
    setDirty(false);
  };

  const startLoading = () => {
    setModalProps(prevState => ({ ...prevState, loading: true }));
  };

  const showModal = ({ onSave, onDiscard, onProceedCallback }: ShowModalProps) => {
    const onCancel = () => setModalProps(prev => ({ ...prev, visible: false }));

    const save = () => {
      startLoading();
      onSave()
        .then(() => {
          setDirty(false);
          setModalProps(DEFAULT_DIRT_MODAL_STATE);
          onProceedCallback?.();
        });
    };


    const discard = () => {
      startLoading();
      onDiscard()
        .then(() => {
          setDirty(false);
          setModalProps(DEFAULT_DIRT_MODAL_STATE);
          onProceedCallback?.();
        });
    };

    setModalProps({
      visible: true,
      loading: false,
      onSave: save,
      onDiscard: discard,
      onCancel,
    });
  };

  const closeModal = () => {
    setDirty(false);
    setModalProps(DEFAULT_DIRT_MODAL_STATE);
  };

  return {
    dirty,
    addDirt,
    clearDirty,
    showModal,
    closeModal,
  };
};
