/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data describing a sync-data update
 * @export
 * @interface SyncDataUpdateDto
 */
export interface SyncDataUpdateDto {
    /**
     * New external-id value of the sync data
     * @type {string}
     * @memberof SyncDataUpdateDto
     */
    externalId?: string;
    /**
     * State resulted after data sync
     * @type {string}
     * @memberof SyncDataUpdateDto
     */
    state?: SyncDataUpdateDtoStateEnum;
    /**
     * Details of the sync process
     * @type {string}
     * @memberof SyncDataUpdateDto
     */
    stateData?: string;
    /**
     * Human-readable description of the sync process
     * @type {string}
     * @memberof SyncDataUpdateDto
     */
    stateText?: string;
    /**
     * Time in which the last sync was attempted
     * @type {Date}
     * @memberof SyncDataUpdateDto
     */
    syncTimestamp?: Date;
}

/**
* @export
* @enum {string}
*/
export enum SyncDataUpdateDtoStateEnum {
    READY = 'READY',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}


/**
 * Check if a given object implements the SyncDataUpdateDto interface.
 */
export function instanceOfSyncDataUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SyncDataUpdateDtoFromJSON(json: any): SyncDataUpdateDto {
    return SyncDataUpdateDtoFromJSONTyped(json, false);
}

export function SyncDataUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncDataUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'stateData': !exists(json, 'stateData') ? undefined : json['stateData'],
        'stateText': !exists(json, 'stateText') ? undefined : json['stateText'],
        'syncTimestamp': !exists(json, 'syncTimestamp') ? undefined : (new Date(json['syncTimestamp'])),
    };
}

export function SyncDataUpdateDtoToJSON(value?: SyncDataUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'state': value.state,
        'stateData': value.stateData,
        'stateText': value.stateText,
        'syncTimestamp': value.syncTimestamp === undefined ? undefined : (value.syncTimestamp.toISOString()),
    };
}

