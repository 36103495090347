import {
  Col, Row,
} from 'antd';
import { useContext } from 'react';
import DateInput, { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';
import { EconomicPlanDtoStatusEnum } from '../../../../api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import SmartSearch from '../../../../elements/Inputs/SmartSearch/SmartSearch';
import TextInput from '../../../../elements/Inputs/TextInput/TextInput';
import usePropertyOptions from '../../../../services/usePropertyOptions';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import './EconomicPlanData.scss';
import { useEconomicPlanData } from './services/useEconomicPlanData';

export const EconomicPlanData = () => {
  const { tl } = useContext(LanguageContext);

  const {
    title,
    startDate,
    setStartDate,
    setTitle,
    economicPlanState,
    selectedPropertyId,
  } = useEconomicPlanData();

  const {
    propertyOptions,
    searchForProperty,
    onLoadProperty,
  } = usePropertyOptions(false);

  const options = propertyOptions.map(propertyOption => ({
    key: propertyOption?.value,
    value: propertyOption?.value,
    label: propertyOption?.simpleLabel,
  }));

  return (
    <>
      <div className="EconomicPlanData">
        <Row gutter={32}>
          <Col flex="auto" span={12}>
            <SmartSearch
              label={tl(economicPlanTranslations.property)}
              disabled
              value={selectedPropertyId}
              searchFunction={searchForProperty}
              getOneFunction={(id: string) => onLoadProperty(parseInt(id, 10))}
              onChange={() => {
              }}
              options={options}
            />
          </Col>
          <Col flex="auto" span={12}>
            <DateInput
              onChange={setStartDate}
              label={tl(economicPlanTranslations.economicPlan.startDate.label)}
              value={startDate}
              format="DD.MM.YYYY"
              disabled={economicPlanState !== EconomicPlanDtoStatusEnum.DRAFT}
              views={['month']}
              softMinDate={MIN_DATE_NORMAL}
              softMaxDate={MAX_DATE_NORMAL}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TextInput
              onChange={setTitle}
              value={title}
              required
              label={tl(economicPlanTranslations.economicPlan.title)}
              disabled={economicPlanState !== EconomicPlanDtoStatusEnum.DRAFT}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
