import React, { useContext } from 'react';
import InformationBar from 'storybook-components/feedback/InformationBar/InformationBar';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatToEuro } from 'lib/Utils';
import { useInfoBarValues } from '../services/useInfoBarValues';
import { translations } from '../translations';

const InformationBarWrapper = () => {
  const {
    countPlatform, countSelfPayout, totalAmountPayout, totalAmountPull,
  } = useInfoBarValues();
  const { tl } = useContext(LanguageContext);
  const infoBarTranslations = translations.creationPage.sections.section2.infoBar;

  return (
    <InformationBar
      content={[{ label: tl(infoBarTranslations.totalAmountPayout), value: formatToEuro(totalAmountPayout) },
        { label: tl(infoBarTranslations.totalAmountPull), value: formatToEuro(totalAmountPull) },
        { label: tl(infoBarTranslations.bankOrders), value: countPlatform ?? 0 },
        { label: tl(translations.creationPage.sections.section2.selectOptionsLabels.selfPayer), value: countSelfPayout ?? 0 }]}
      delimiter="‧"
    />
  );
};

export default InformationBarWrapper;
