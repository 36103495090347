import StandingOrderOverview from './StandingOrderOverview/StandingOrderOverview';
import { RouteDefinition } from '../../components/RenderRoutes';
import InvoiceEditingRouter from '../Invoice/InvoiceEditing/InvoiceEditingRouter';

export const StandingOrderRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/standing-orders',
      component: StandingOrderOverview,
      subRoutes: [
        {
          path: '/standing-orders/invoice/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
      ],
    },
    {
      path: '*/:propertyHrId/standing-orders',
      component: StandingOrderOverview,
      type: 'overlay',
      width: '90%',
      subRoutes: [
        {
          path: '*/:propertyHrId/standing-orders/:standingOrderId/invoice/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
        {
          path: '*/:propertyHrId/standing-orders/invoice/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
      ],
    },
  ],
};
