import { UnitContractProjectionDto } from 'api/accounting';
import { SyncDataDto } from 'api/app/models/SyncDataDto';
import { Etg24Contract } from 'api/etg24/interfaces';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { UnitContractsHistoryDatasourceType } from '../components/UnitContractHistoryTable/services/useUnitContractsHistoryDatasource';
import { isEmpty } from 'lodash';
import { getContractTypeAndVacancyFlagForEtg24Contract, getImpowerUnitIdForEtg24Contract, getMailingContactForEtg24Contract } from './utilMapEtg24Contracts';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'pages/UnitContractHistory/translations';
import { ConnectionLegacyDto } from 'api/app';

export const Etg24UnitContractsContext = createContext<{
  etg24Contracts: DefaultDataInterface<Etg24Contract[]>,
  setEtg24Contracts: React.Dispatch<React.SetStateAction<DefaultDataInterface<Etg24Contract[]>>>
  contractsSyncData: DefaultDataInterface<SyncDataDto[]>,
  setContractsSyncData: React.Dispatch<React.SetStateAction<DefaultDataInterface<SyncDataDto[]>>>,
  unitsSyncData: DefaultDataInterface<SyncDataDto[]>,
  setUnitsSyncData: React.Dispatch<React.SetStateAction<DefaultDataInterface<SyncDataDto[]>>>,
  propertySyncData: DefaultDataInterface<SyncDataDto[]>,
  setPropertySyncData: React.Dispatch<React.SetStateAction<DefaultDataInterface<SyncDataDto[]>>>,
  unsyncedContracts: DefaultDataInterface<UnitContractProjectionDto[]>,
  setUnsyncedContracts: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>
  notImportedEtg24Contracts: UnitContractsHistoryDatasourceType[],
  etg24ConnectionWithConflictUIEnabled: DefaultDataInterface<ConnectionLegacyDto>,
  setEtg24ConnectionWithConflictUIEnabled: React.Dispatch<React.SetStateAction<DefaultDataInterface<ConnectionLegacyDto>>>,

} | undefined>(undefined);

export const useEtg24ContractsContext = (usageName: string) => {
  const etg24UnitContractsContext = useContext(Etg24UnitContractsContext);

  if (etg24UnitContractsContext === undefined) {
    throw new Error(`${usageName} must be used within a Etg24UnitContractsContextProvider`);
  }

  return etg24UnitContractsContext;
};

export const Etg24UnitContractsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [etg24Contracts, setEtg24Contracts] = useState(DEFAULT_DATA<Etg24Contract[]>([]));
  const [contractsSyncData, setContractsSyncData] = useState(DEFAULT_DATA<SyncDataDto[]>([]));
  const [unitsSyncData, setUnitsSyncData] = useState(DEFAULT_DATA<SyncDataDto[]>([]));
  const [propertySyncData, setPropertySyncData] = useState(DEFAULT_DATA<SyncDataDto[]>([]));
  const [unsyncedContracts, setUnsyncedContracts] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>(undefined));
  const [etg24ConnectionWithConflictUIEnabled, setEtg24ConnectionWithConflictUIEnabled] = useState(DEFAULT_DATA<ConnectionLegacyDto>(undefined));

  const { tl } = useContext(LanguageContext);

  // NOT ETG24 IMPORTED contracts
  const notImportedEtg24Contracts: UnitContractsHistoryDatasourceType[] = useMemo(() => {
    if (isEmpty(etg24Contracts.data) || !contractsSyncData.loaded || !unitsSyncData.loaded) {
      return [];
    }

    const externalIds = contractsSyncData.data
      .map((item) => {
        const parsed = JSON.parse(item.externalEntityId);
        return parsed.contractExternalId;
      });

    // check which ETG24 contract has no corresponding sync data
    const notImportedContracts = etg24Contracts.data.filter(contract => !externalIds.includes(contract.id));

    const notImportedContractsMapped = notImportedContracts.map(etg24Contract => ({
      // IMPOWER CONTRACT STRUCTURE - easier to display, etg data used only for logic
      startDate: etg24Contract.validity?.validFrom || undefined,
      endDate: etg24Contract.validity?.validTo || undefined,
      ...getContractTypeAndVacancyFlagForEtg24Contract(etg24Contract),
      mailingContact: getMailingContactForEtg24Contract(etg24Contract, tl(translations.table.vacancy)),
      unitId: getImpowerUnitIdForEtg24Contract(etg24Contract, unitsSyncData.data),

      // ETG24 CONTRACT
      etgContractData: etg24Contract,

      key: etg24Contract.id,
    }));

    return notImportedContractsMapped;
  }, [etg24Contracts.data, contractsSyncData.data, unitsSyncData.data]);


  return (
    <Etg24UnitContractsContext.Provider value={{
      etg24Contracts,
      setEtg24Contracts,
      contractsSyncData,
      setContractsSyncData,
      unitsSyncData,
      setUnitsSyncData,
      propertySyncData,
      setPropertySyncData,
      unsyncedContracts,
      setUnsyncedContracts,
      notImportedEtg24Contracts,
      etg24ConnectionWithConflictUIEnabled,
      setEtg24ConnectionWithConflictUIEnabled,
    }}
    >
      {children}
    </Etg24UnitContractsContext.Provider>
  );
};

export default Etg24UnitContractsContextProvider;
