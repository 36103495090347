import { useContext } from 'react';
import { useStore } from 'zustand';
import { OpsCostReportHiddenAmountsStore } from './interfacesOpsCostReportHiddenAmounts';
import { OpsCostReportHiddenAmountsContext } from './storeOpsCostReportHiddenAmounts';

export type AllHiddenKeysGroupedByContractId = Record<string, {
  yourPrepaymentsKeys: string[],
  individualTenantCostsKeys: string[],
  otherOrTransferableCostsKeys: string[],
}>;

// ! do not export !
const useHiddenAmountsStore = <T, >(selector: (state: OpsCostReportHiddenAmountsStore) => T): T => {
  const store = useContext(OpsCostReportHiddenAmountsContext);

  if (!store) {
    throw new Error('Missing OpsCostReportHiddenAmountsStoreProvider.');
  }

  return useStore(store, selector);
};

export const useOpsCostReportHiddenAmountsActions = () => useHiddenAmountsStore(state => state.actions);

export const useAllHiddenKeysGroupedByContractId = () => useHiddenAmountsStore(state => (
  Object.entries(state.values).reduce((acc, [contractId, { yourPrepaymentsKeys, individualTenantCostsKeys, otherOrTransferableCostsKeys }]) => ({
    ...acc,
    [contractId]: {
      yourPrepaymentsKeys,
      individualTenantCostsKeys,
      otherOrTransferableCostsKeys,
    },
  }), {} as AllHiddenKeysGroupedByContractId)
));
