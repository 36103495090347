import { RouteDefinition } from 'components/RenderRoutes';
import { EInvoiceReportPage } from './EInvoiceReportPage/EInvoiceReportPage';

export const EInvoiceRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/einvoices/render',
      component: EInvoiceReportPage,
      type: 'main',
    },
  ],
};
