import moment from 'moment';
import { useCallback, useContext } from 'react';
import { EconomicPlanSimpleProjectionStatusEnum } from '../../../api/accounting';
import { EconomicPlanContext } from './EconomicPlanContext';


const isOverlapping = (startDate: moment.Moment) => ep => EconomicPlanSimpleProjectionStatusEnum.ACTIVE === ep.status
    || (EconomicPlanSimpleProjectionStatusEnum.EXPIRED === ep.status && moment(ep.deactivatedOn).isAfter(startDate));

export const useFindOverlappingEconomicPlans = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw Error('useShowCorrectionsSection cannot be used outside of EconomicPlanContext');
  }

  const { economicPlan, economicPlanList } = economicPlanContext;

  const findOverlappingEconomicPlans = useCallback(() => {
    if (economicPlan === undefined) {
      throw Error('You must first select an economic plan before checking for overlaps');
    }

    if (economicPlan?.data?.startDate === undefined) {
      throw Error('Selected Economic Plan must have a start date before checking for overlaps');
    }

    const startDate = moment(economicPlan.data?.startDate);
    const overlappingEconomicPlans = economicPlanList?.data?.filter(isOverlapping(startDate)) || [];

    return overlappingEconomicPlans;
  }, [economicPlan?.data?.startDate, economicPlanList?.data]);


  return {
    findOverlappingEconomicPlans,
  };
};
