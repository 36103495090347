import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import { Key, useState } from 'react';
import './ExpandableTableProps.scss';

export function useExpandableTableProps<T>(rowKey: string, isExpandable: (record: T) => boolean, indentRow?: (record: T) => boolean) {
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>();

  const expandIcon = ({ record, expanded }) => {
    if (isExpandable(record)) {
      return (
        <span className={`ExpandableTableIcon ${expanded ? 'open' : 'closed'}`}>
          <Icon component={ICONS.expandRow} />
        </span>
      );
    }

    return <></>;
  };


  const onExpand = (expanded: boolean, record) => {
    if (isExpandable(record)) {
      if (expanded) {
        setExpandedRowKeys(prev => [...prev, record[rowKey]]);
      } else {
        setExpandedRowKeys((prev) => {
          const newRowKeys = [...prev];
          const idx = newRowKeys.findIndex(rk => rk === record[rowKey]);
          newRowKeys.splice(idx, 1);
          return newRowKeys;
        });
      }
    }
  };


  /**
   * In case the cell's content breaks/overflows into a second row, the default antd behavior
   * is that the second row is not indented. We need the wrapper and the placeholder to make
   * sure that the first and second rows are aligned.
   */
  const expandIconCell = (record, expanded, children) => {
    if (isExpandable(record)) {
      return (
        <div className="ExpandableTableIconWrapper">
          <span className={`ExpandableTableIcon ${expanded ? 'open' : 'closed'}`}>
            <Icon component={ICONS.expandRow} />
          </span>
          {children}
        </div>
      );
    }

    if (record.children || !indentRow(record)) {
      return <>{children}</>;
    }

    return (
      <div className="ExpandableTableIconPlaceholderWrapper">
        <span className="ExpandableTableIconPlaceholder" />
        {children}
      </div>
    );
  };

  return {
    expandedRowKeys,
    setExpandedRowKeys,
    expandIcon,
    expandIconCell,
    onExpand,
  };
}
