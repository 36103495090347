import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import _ from 'lodash';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';


interface Props {
  index: number,
  moveRow: (dragIndex: number, hoverIndex: number) => void,
  className: string,
  style: any,
  children?: React.ReactNode,
  isDisabled?: boolean
}

const type = 'DraggableBodyRow';

const BasicDraggableBodyRow = ({
  index, moveRow, isDisabled, className, style, children, ...restProps
}: Props) => {
  const ref = useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem<{ index: number }>() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!isDisabled) {
    drop(drag(ref));
  }


  if (_.isEmpty(restProps)) {
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        {...restProps}
      >
        {children}
      </tr>
    );
  }

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      {...restProps}
    >
      <td
        className="ant-table-cell drag-n-drop-cell"
        width="5.0rem"
        style={{ cursor: 'move' }}
      >

        {!isDisabled
   && (
     <Icon
     // here we say that this component is what triggers a drag (and drop)
       ref={drag}
       className="drag-n-drop-icon"
       component={ICONS.dragNdrop}
     />
   )}
      </td>
      {React.Children.map(children, (child: React.ReactChild, idx) => {
        if (idx === 0) return null;
        return child;
      })}
    </tr>
  );
};

BasicDraggableBodyRow.defaultProps = {
  children: undefined,
  isDisabled: false,
};

export default BasicDraggableBodyRow;
