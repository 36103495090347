import { useContext } from 'react';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import { AuthContext } from 'contexts/AuthContext';
import { SpecialContributionControllerApi } from 'api/accounting';
import { SpecialContributionCreationContext } from './SpecialContributionCreationContext';
import { translations } from '../../translations';
import { Modal } from 'antd';

export const useExecuteSpecialContribution = () => {
  const specialContributionContext = useContext(SpecialContributionCreationContext);
  if (specialContributionContext === undefined) {
    throw Error('useLoadSpecialContribution must be used withing SpecialContributionCreationContextProvider');
  }
  const { setLoading, setDirty, setSpecialContribution } = specialContributionContext;
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const specialContributionControllerApi = new SpecialContributionControllerApi(apiConfiguration('accounting'));

  const executeSpecialContribution = (specialContributionId: number) => {
    const onExecute = () => {
    setLoading(true);
    return specialContributionControllerApi.executeUsingPOST({
      id: specialContributionId,
    })
      .then((response) => {
        setSpecialContribution(response);
        setDirty(false);
        showNotification({
          key: 'executeSpecialContributionSuccess',
          message: tl(translations.specialContribution.executeSpecialContributionSuccess),
          type: 'success',
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          specialContributionControllerApi.getAllValidationMessagesUsingGET({
            id: specialContributionId,
          })
            .then((response) => {
              showNotification({
                key: 'executeSpecialContributionError',
                message: tl(translations.specialContribution.validations.general),
                description: Object.keys(response)
                  .map(validationMessageKey => tl(translations.specialContribution.validations[validationMessageKey][response[validationMessageKey]])),
                type: 'error',
              });
            })
            .catch(() => {
              showNotification({
                key: 'executeSpecialContributionError',
                message: tl(translations.specialContribution.executeSpecialContributionError),
                type: 'error',
              });
            });
        } else {
          showNotification({
            key: 'executeSpecialContributionError',
            message: tl(translations.specialContribution.executeSpecialContributionError),
            type: 'error',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }

    Modal.confirm({
      title: tl(translations.specialContribution.confirmationModal.title),
      content: <p>{tl(translations.specialContribution.confirmationModal.content)}</p>,
      okText: tl(translations.specialContribution.confirmationModal.onOk),
      cancelText: tl(translations.specialContribution.confirmationModal.onCancel),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: onExecute,
      width: 450,
    });
  };

  return {
    executeSpecialContribution,
  };
};
