import React, { useContext } from 'react';
import RadioGroup from 'antd/es/radio/group';
import './RadioGroupWithBox.scss';
import { Form, Input, Radio } from 'antd';
import InputProps from '../InputProps';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';

interface radioGroupWithBoxProps extends InputProps<boolean> {
  optionClassName?: string,
  options: {
    value: any,
    label: string,
    secondaryLabel: string,
    boxLabel: string | JSX.Element,
    disabled?: boolean
  }[],
  noOptionsMessage: string,
}

export default function RadioGroupWithBox(props: radioGroupWithBoxProps): JSX.Element {
  const {
    value, className, label, required, disabled, validationState, validationMessage, onChange, options, optionClassName, noOptionsMessage,
  } = props;
  const { tl } = useContext(LanguageContext);

  return (
    <div className={`RadioGroupWithBox ${className}`}>
      <Form.Item
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <RadioGroup
          className={`${className}`}
          onChange={event => onChange(event.target.value)}
          value={value}
          disabled={disabled || false}
        >
          {options.map(option => (
            <div className="option-wrapper" key={option.value}>
              <div className="option-labels">
                <span>
                  {option.label}
                </span>
                {option.value === value && <span className="selected-text">{tl(translations.elements.radioGroup.selected)}</span>}
              </div>
              <Radio
                className={`option ${option.value === value ? 'selected' : ''} ${optionClassName || ''} ${option.disabled || disabled ? 'disabled' : ''}`}
                disabled={!!option.disabled || !!disabled}
                value={option.value}
                key={option.value}
              >
                <div className="box-label">
                  {option.boxLabel}
                </div>
                {
                  option.secondaryLabel
                  && (
                    <span className="secondary-label">
                      {option.secondaryLabel}
                    </span>
                  )
                }
              </Radio>
            </div>
          ))}
          {options.length === 0 && noOptionsMessage}
        </RadioGroup>
      </Form.Item>
    </div>
  );
}

RadioGroupWithBox.defaultProps = {
  className: '',
  inputClassName: '',
  disabled: false,
  required: false,
};
