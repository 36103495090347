import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { getStateColor } from 'storybook-components/headers/utils';
import { SectionManagerProvider } from 'services/SectionManager/SectionManagerContext';
import { Prompt, useLocation } from 'react-router';
import ReactDOM from 'react-dom';
import { ReportDocumentCoverWithPortal } from 'storybook-components/ReportDocumentCover/ReportDocumentCoverWithPortal';
import PropertyOwnerContractsProvider from 'services/PropertyOwnerContractsList/PropertyOwnerContractsContext';
import { reportDocumentTranslations } from 'storybook-components/ReportDocumentCover/translations';
import { ConfigProvider } from 'antd';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import ActivePropertyOwnerContractsProvider from 'services/PropertyOwnerContractsList/ActivePropertyOwnerContractsContext';
import { translations } from '../translations';
import ProfitAndLossReportContextProvider from './services/ProfitAndLossReportContext';
import { useProfitAndLossReportEditor } from './services/useProfitAndLossReportEditor';
import ProfitAndLossReportEditorHeaderButtons from './components/ProfitAndLossReportEditorHeaderButtons/ProfitAndLossReportEditorHeaderButtons';
import ProfitAndLossReportEditorForm from './components/ProfitAndLossReportEditorForm/ProfitAndLossReportEditorForm';
import RentIncomeSection from './components/RentIncomeSection/RentIncomeSection';
import PropertyOwnerPaymentsSection from './components/PropertyOwnerPaymentsSection/PropertyOwnerPaymentsSection';
import './ProfitAndLossReportEditor.scss';
import OpenTenantAccountsSection from './components/OpenTenantAccountSection/OpenTenantAccountsSection';
import ResultSection from './components/ResultSection/ResultSection';
import BankAccountsSection from './components/BankAccountsSection/BankAccountsSection';
import ExpenseSection from './components/ExpenseSection/ExpenseSection';
import AssetReportSection from './components/AssetReportSection/AssetReportSection';
import AnnexSection from './components/AnnexSection/AnnexSection';
import { getFooterHtmlString, getHeaderHtmlString } from './components/ProfitAndLossHeaderAndFooter/ProfitAndLossPrintHeaderAndFooter';

const PROFIT_AND_LOSS_VIEW_ROUTE_REGEX = /profit-and-loss\/\d+\/view/;


const ProfitAndLossReportEditor = () => {
  const { tl } = useContext(LanguageContext);

  const location = useLocation();
  const {
    isDirty, title, propertyName, reportInterval, propertyOwnerNames, additionalReportInfo,
    managementCompanyName, managementCompanyAddress, recipientAddressLines,
  } = useProfitAndLossReportEditor();

  const reportInfo = [
    { label: tl(reportDocumentTranslations.propertyName), value: propertyName },
    { label: tl(reportDocumentTranslations.reportInterval), value: reportInterval },
    { label: tl(reportDocumentTranslations.propertyOwners), value: propertyOwnerNames?.join(', ') },
  ];

  return (
    <>
      <Page className="ProfitAndLossReportEditor">
        <Prompt
          when={isDirty}
          message={loc => ((
            location.pathname === loc.pathname
            || PROFIT_AND_LOSS_VIEW_ROUTE_REGEX.test(loc.pathname)
          ) ? true : tl(translations.notifications.prompt))}
        />
        <PageHeader
          title={tl(translations.report.header.title)}
          tagContent={tl(translations.report.header.status)}
          tagColor={getStateColor('UNSAVED') as PageHeaderTagColor}
          rightSideComponent={<ProfitAndLossReportEditorHeaderButtons />}
          showLogo
        />
        <ReportDocumentCoverWithPortal
          mountNodeId="ProfitAndLossReportEditor"
          additionalData={additionalReportInfo}
          title={title}
          reportInfo={reportInfo}
          managementCompanyName={managementCompanyName}
          managementCompanyAddress={managementCompanyAddress}
          recipientAddressLines={recipientAddressLines}
        />
        <PageContent>
          <ProfitAndLossReportEditorForm />
          <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(translations.report.sections.emptyTableMessage)} />}>
            <RentIncomeSection sectionNumber={1} />
            <ExpenseSection sectionNumber={2} />
            <OpenTenantAccountsSection sectionNumber={3} />
            <PropertyOwnerPaymentsSection sectionNumber={4} />
            <ResultSection sectionNumber={5} />
            <BankAccountsSection sectionNumber={6} />
            <AssetReportSection sectionNumber={7} />
            <AnnexSection />
          </ConfigProvider>
        </PageContent>
      </Page>
      {/**
       * We are using data-content for header and footer because we don't want this content to be rendered normally.
       * We will get these elements in pdf-proxy and use the content to add as header and footer for the puppetteer to render in the pdf
       * */}
      {ReactDOM.createPortal(<header id="headerTemplate" data-content={getHeaderHtmlString(`${title} · ${propertyName}`, '')} />, document.getElementById('print'))}
      {ReactDOM.createPortal(<footer id="footerTemplate" data-content={getFooterHtmlString()} />, document.getElementById('print'))}
      {ReactDOM.createPortal(<div id="ProfitAndLossReportEditor" />, document.getElementById('print'))}
    </>
  );
};

export default () => (
  <SectionManagerProvider initialOpenSectionId={0}>
    <ProfitAndLossReportContextProvider>
      <PropertyOwnerContractsProvider>
        <ActivePropertyOwnerContractsProvider>
          <ProfitAndLossReportEditor />
        </ActivePropertyOwnerContractsProvider>
      </PropertyOwnerContractsProvider>
    </ProfitAndLossReportContextProvider>
  </SectionManagerProvider>
);
