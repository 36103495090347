import { AccountDto } from 'api/accounting';
import React, { useState } from 'react';
import DEFAULT_DATA, { DefaultDataInterface } from '../lib/data';

interface ValidationError {
  validationState: undefined | 'error',
  validationMessage: undefined |string
}

export interface ValidationErrors {
  [key: string] : ValidationError
}

export interface AccountBooking {
  amount?: number,
  debitAccount?: string | AccountDto | undefined,
  creditAccount?: string | AccountDto | undefined,
  bookingText?: string,
  postDate?: moment.Moment,
  vatPercentage?: number,
  vatAmount?: number,
  vatEligibilityPercentage?: number,
  vatEligibilityAmount?: number,
  laborCostType?: string,
  laborCost?: number,
}

interface AccountBookingContextType {
  setAccountBookings: React.Dispatch<React.SetStateAction<DefaultDataInterface<AccountBooking[]>>>;
  accountBookings: DefaultDataInterface<AccountBooking[]>;
  formDirty: boolean;
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>,
  propertyHrId: string;
  setPropertyHrId: React.Dispatch<React.SetStateAction<string>>;
  bookingPressed: boolean;
  setBookingPressed: React.Dispatch<React.SetStateAction<boolean>>,
  validationErrors: ValidationErrors[],
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors[]>>,
}

export const AccountBookingContext = React.createContext<AccountBookingContextType | undefined>(undefined);

export default function AccountBookingProvider({ children }: any): JSX.Element {
  const [accountBookings, setAccountBookings] = useState(DEFAULT_DATA<AccountBooking[]>([{}]));
  const [propertyHrId, setPropertyHrId] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [bookingPressed, setBookingPressed] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors[]>([]);

  return (
    <AccountBookingContext.Provider
      value={{
        setAccountBookings,
        accountBookings,
        formDirty,
        setFormDirty,
        propertyHrId,
        setPropertyHrId,
        bookingPressed,
        setBookingPressed,
        validationErrors,
        setValidationErrors,
      }}
    >
      {children}
    </AccountBookingContext.Provider>
  );
}
