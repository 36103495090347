import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import { DocumentDatasource } from 'pages/MessageSendingPage/interfaces';
import AllContractsListContextProvider from 'pages/Property/PropertyEditing/services/AllContractsListContext';
import PropertyOwnerContractsProvider from 'services/PropertyOwnerContractsList/PropertyOwnerContractsContext';
import ActiveContractsListContextProvider from 'services/UnitContractsList/ActiveContractsListContext';
import { RecipientSelectorModal } from 'storybook-components/modals/RecipientSelectorModal/RecipientSelectorModal';
import './RecipientCell.scss';
import { useRecipientCell } from './useRecipientCell';

const RecipientCell = ({ record } : {record: DocumentDatasource}) => {
  const {
    numberOfRecipients,
    onClick,
    modalVisible,
    propertyId,
    propertyHrId,
    selectedContractContactsForDocument,
    allSelectedContractContacts,
    onCancel,
    onApply,
  } = useRecipientCell(record);

  return (
    <>
      <Button
        className="RecipientCell"
        type="ghost"
        icon={<Icon component={ICONS.personPlus} />}
        onClick={onClick}
      >
        {numberOfRecipients}
      </Button>
      <PropertyOwnerContractsProvider>
        <AllContractsListContextProvider>
          <ActiveContractsListContextProvider>
            <RecipientSelectorModal
              visible={modalVisible}
              propertyId={propertyId}
              propertyHrId={propertyHrId}
              selectedContractContactIds={selectedContractContactsForDocument}
              contractContactIds={allSelectedContractContacts}
              subtitle={record.name}
              onCancel={onCancel}
              onApply={onApply}
            />
          </ActiveContractsListContextProvider>
        </AllContractsListContextProvider>
      </PropertyOwnerContractsProvider>
    </>
  );
};

export default RecipientCell;
