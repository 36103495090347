import _ from 'lodash';
import { PropertyContractsBalanceDto } from 'api/accounting';
import { useOrderOpenBalancesContext } from './OrderOpenBalancesContext';
import { PropertyListWithOpenAccounts } from './interfaces';


export const mapFESortColumnToBESortColumn = (field: keyof PropertyListWithOpenAccounts) => {
  switch (field) {
  case 'debtorBalancesGrouped': return 'contracts_count';
  case 'propertyName': return 'name';
  default: return _.snakeCase(field);
  }
};

export const useOrderOpenBalancesListSort = () => {
  const { sortState, setSortState } = useOrderOpenBalancesContext('useOrderOpenBalancesListSort');


  const onChangeSort = (field: keyof PropertyContractsBalanceDto) => {
    setSortState((currentState) => {
      const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
      return {
        field,
        order,
      };
    });
  };

  return {
    sortField: sortState.field as string,
    sortOrder: sortState.order,
    onChangeSort,
  };
};
