import React from 'react';
import { LoadingIndicator } from '../../../LoadingIndicator/LoadingIndicator';
import './TableContentLoadingIndicator.scss';

export default function TableContentLoadingIndicator({ message }: { message?: string }): JSX.Element {
  return (
    <div className="TableContentLoadingIndicator">
      <div className="wrapper">
        <LoadingIndicator />
      </div>
      {message}
    </div>
  );
}
