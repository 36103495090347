import { Col, Row, Select } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { useContext, useMemo, useRef } from 'react';

import './ContractSelector.scss';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import { EmptyState } from 'storybook-components/feedback/EmptyState/EmptyState';
import { ContractSelectorProps, useContractSelector } from './useContractSelector';
import { contractSelectorTranslations } from './translations';


const ContractSelector = (props: ContractSelectorProps) => {
  const { tl } = useContext(LanguageContext);
  const { contractType, value } = props;
  const {
    loading,
    options,
    noData,
    onSearch,
    onSelect,
  } = useContractSelector(props);


  const optionsDropdownWrapperRef = useRef(null);

  const wrapperClassname = 'ContractSelectorWrapper';

  const optionElements = useMemo(() => {
    if (isEmpty(options)) {
      return [];
    }

    return options.map(option => (
      <Select.Option
        key={option.value ?? option?.label}
        value={option.value}
        title={option.label}
      >
        {option.customBody ? option.customBody : <div className="select-menu-item">{option.label}</div>}
      </Select.Option>
    ));
  }, [options]);

  const getPopupContainer = () => optionsDropdownWrapperRef.current ?? document.body;

  return (
    <div className={wrapperClassname} ref={optionsDropdownWrapperRef}>
      <Select
        className="ContractSelector"
        onChange={onSelect}
        disabled={loading}
        value={value}
        onSearch={onSearch}
        filterOption={false}
        notFoundContent={(
          <EmptyState
            className="empty-results-contract-selector"
            icon={<Icon component={ICONS.file} />}
            title={noData ? tl(contractSelectorTranslations.noDataAvailable.title) : tl(contractSelectorTranslations.noSearchData.title)}
            subTitle={noData ? '' : tl(contractSelectorTranslations.noSearchData.subtitle)}
          />
        )}
        size="large"
        dropdownRender={menu => (
          <>
            <Row className="dropdown-header">
              <Col span={8}>{tl(contractSelectorTranslations.unitId)}</Col>
              <Col span={16}>{tl(contractSelectorTranslations.contractType[contractType])}</Col>
            </Row>
            {menu}
          </>
        )}
        showSearch
        getPopupContainer={getPopupContainer}
        dropdownClassName="contract-selector-dropdown"
        placeholder={tl(contractSelectorTranslations.searchPlaceholder)}
        suffixIcon={<Icon component={ICONS.triangleDown} />}
      >
        {optionElements}
      </Select>
    </div>
  );
};

export default ContractSelector;
