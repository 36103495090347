const invoiceSectionFieldsMap: any = {
  1: [
    'propertyHrId',
    'invoiceNumber',
    'invoiceDate',
    'servicePeriod',
  ],
  2: ['counterpartContactId', 'counterpartIban'],
  3: (invoiceBookingsLength: number) => {
    let fields: any[] = [];
    const invoiceBookingFields: string[] = [
      'accountCode',
      'bookingText',
      'amount',
      'netAmount',
      'vatAmount',
      'vatPercentage',
      'laborCost',
      'laborCostPercentage',
      'laborCostType',
    ];
    for (let i = 0; i < invoiceBookingsLength; i += 1) {
      fields = fields.concat(invoiceBookingFields.map((field: string) => `invoiceBookings[${i}].${field}`));
    }
    fields = fields.concat(['totalNet', 'totalGross']);
    return fields;
  },
  4: (paymentsNr: number) => {
    let fields: any[] = [];

    for (let i = 0; i < paymentsNr; i += 1) {
      fields = fields.concat([
        `invoicePayments[${i}].amount`,
        `invoicePayments[${i}].statement`,
        `invoicePayments[${i}].executionDate`,
      ]);
    }
    fields = fields.concat([
      'paymentTargetDays',
      'paymentTargetDate',
      'paymentType',
      'executionDateType',
      'bankAccountId',
      'notes',
      'invoiceStandingOrder.startDate',
      'invoiceStandingOrder.plannedEndDate',
      'invoiceStandingOrder.statement',
      'invoiceStandingOrder.interval',
    ]);
    return fields;
  },
};

export const getInvoiceSectionFieldsMap = (sectionNumber: number, invoice: any) => {
  switch (sectionNumber) {
  case 3:
    return invoiceSectionFieldsMap[3](invoice.invoiceBookings.length || 0);
  case 4:
    return invoiceSectionFieldsMap[sectionNumber](invoice.invoicePayments.length);
  default:
    return invoiceSectionFieldsMap[sectionNumber];
  }
};
