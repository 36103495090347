import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  BuildingProjection, UnitContractProjectionDto, UnitProjectionDto, UnitSimpleDto,
} from 'api/accounting';
import { EntitySelectorOption } from '../../elements/Inputs/EntitySelector/EntitySelector';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';
import { getUnitTitle } from './getUnitTitle';

interface AdditionalInformation {
  unitId: number,
  info: any,
}

interface UnitSelectorProps {
  units: UnitProjectionDto[] | UnitSimpleDto[],
  activeContracts: UnitContractProjectionDto[],
  buildings: BuildingProjection[],
  validationErrors: any,
  includePropertyOption: boolean,
  propertyLabel?: string
  initialSelectedUnit?: number | null,
  optionClassName?: string,
  additionalInformation?: AdditionalInformation[]
}

export default function useUnitSelector(props: UnitSelectorProps) {
  const {
    units, activeContracts, buildings, validationErrors, includePropertyOption, propertyLabel, initialSelectedUnit, optionClassName, additionalInformation,
  } = props;
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>();
  const [optionsState, setOptionsState] = useState<any[]>([]);

  const { tl } = useContext(LanguageContext);

  useEffect(() => {
    if (units === undefined || activeContracts === undefined || buildings === undefined) {
      return;
    }

    if (!selectedUnitId) {
      initSelectedUnit();
    }

    const currentNumberOfOptions = optionsState.length;

    generateOptions();

    const numberOfUnits = units ? units.length : 0;

    if (currentNumberOfOptions !== 0) {
      // a unit has been deleted
      if (currentNumberOfOptions > numberOfUnits) {
        // select the first unit
        initSelectedUnit();
      }
    }
  }, [units, activeContracts, buildings, additionalInformation]);

  const initSelectedUnit = () => {
    try {
      if (initialSelectedUnit || initialSelectedUnit === null) {
        setSelectedUnitId(initialSelectedUnit);
      } else if (includePropertyOption) {
        setSelectedUnitId(0);
      } else {
        setSelectedUnitId(units[0].id);
      }
    } catch (e) {

    }
  };

  const generateOptions = () => {
    const ops: EntitySelectorOption[] = [];
    try {
      units.forEach((unit: any, unitIdx: number) => {
        const unitsContract = activeContracts.find(contract => contract.unitId === unit.id);
        const owner: string | undefined = unitsContract?.mailingContact?.name;
        const validationError = Object.keys(validationErrors).filter(key => key.startsWith(`units[${unitIdx}]`)).length > 0;

        const additionalInfoForUnit = additionalInformation ? additionalInformation.filter(info => info.unitId === unit.id) : null;
        let buildingName: string | undefined;
        if (unit.buildingId) {
          const unitsBuilding = buildings.find(b => b.id === unit.buildingId);
          buildingName = unitsBuilding?.name;
        }
        ops.push({
          value: unit.id,
          label: getUnitTitle(unit, tl),
          entity: {
            ...unit,
            building: buildingName,
            index: unitIdx,
            owner,
          },
          customBody: (
            <div className={`option-label-wrapper ${optionClassName}`}>
              <div className="option-label">
                <div className="label-rows">
                  <div>{getUnitTitle(unit, tl)}</div>
                  <div className="small">
                    {`${tl(translations.pages.property.editPage.sections.section6.building)}: ${buildingName}`}
                  </div>
                  {owner !== undefined
                    && <div className="small">{`${tl(translations.pages.property.editPage.sections.section6.owner)}: ${owner}`}</div>}
                  {validationError
                    && <div className="validation-error">{tl(translations.pages.property.editPage.sections.section6.unitValidationMessage)}</div>}
                </div>
                {additionalInfoForUnit
                  && <div className="info-column">{additionalInfoForUnit.map(info => <div key={unit.id + info.info}>{info.info}</div>)}</div>}
              </div>
            </div>
          ),
        });
      });
    } catch {

    }

    if (includePropertyOption) {
      ops.unshift({
        value: 0,
        label: propertyLabel || 'Property',
        entity: {},
        customBody: (
          <div className="option-label-wrapper">
            <div className="option-label">
              <div>{propertyLabel || 'Property'}</div>
            </div>
          </div>
        ),
      });
    }

    setOptionsState(ops);
  };

  const unitIndex = useMemo(() => {
    try {
      return optionsState.filter((option: EntitySelectorOption) => option.value === selectedUnitId)[0].entity.index;
    } catch (e) {
      return -1;
    }
  }, [selectedUnitId, optionsState]);

  const previousDisabled = useMemo(() => unitIndex === 0, [unitIndex]);
  const nextDisabled = useMemo(() => optionsState[optionsState.length - 1]?.entity?.index === unitIndex, [unitIndex]);

  const onChange = (value: number) => {
    setSelectedUnitId(value);
  };

  const onPreviousClick = () => {
    if (!previousDisabled) {
      setSelectedUnitId((prevValue) => {
        const currentSelectionIndex = optionsState.findIndex(option => option.value === selectedUnitId);
        if (currentSelectionIndex === -1) {
          console.error('Did not find currently selected unit');
          return prevValue;
        }
        if (currentSelectionIndex === 0) {
          console.error('Currently selected unit is the first in list');
          return prevValue;
        }
        return optionsState[currentSelectionIndex - 1].value;
      });
    }
  };

  const onNextClick = () => {
    if (!nextDisabled) {
      setSelectedUnitId((prevValue) => {
        const currentSelectionIndex = optionsState.findIndex(option => option.value === selectedUnitId);
        if (currentSelectionIndex === -1) {
          console.error('Did not find currently selected unit');
          return prevValue;
        }
        if (currentSelectionIndex === optionsState.length - 1) {
          console.error('Currently selected unit is the last in list');
          return prevValue;
        }
        return optionsState[currentSelectionIndex + 1].value;
      });
    }
  };

  const filterOption = (input: any, option: any) => {
    const lowerCaseInput = input.toLowerCase();

    // in case of property
    if (includePropertyOption && propertyLabel && option.props.value === 0) {
      return propertyLabel.toLowerCase().includes(lowerCaseInput);
    }
    const { entity } = optionsState.filter(o => o.value === option.props.value)[0];
    return (entity.unitNrSharingDeclaration && entity.unitNrSharingDeclaration.toLowerCase().startsWith(lowerCaseInput))
        || entity.building?.toLowerCase().includes(lowerCaseInput)
        || (entity.owner && entity.owner.toLowerCase().includes(lowerCaseInput));
  };


  return {
    unitIndex,
    selectedUnitId,
    setSelectedUnitId,
    onChange,
    options: optionsState,
    previousDisabled,
    nextDisabled,
    filterOption,
    onPreviousClick,
    onNextClick,
  };
}
