import { ContractProjectionDtoDunningLevelEnum, GetMessagesUsingGETSourceTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatDate } from 'lib/Utils';
import { getMessagePath } from 'pages/Messages/routes';
import { DebtorBalancesGroupedType } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

type ContractLastDunningDateCellProps = {
  record: DebtorBalancesGroupedType
}

const ContractLastDunningDateCell = ({ record }: ContractLastDunningDateCellProps) => {
  const { tl } = useContext(LanguageContext);

  if (!record.isRootAccount) {
    return null;
  }

  if (!record.lastDunningDate) {
    return <>—</>;
  }

  return (
    <CellWithTwoLines
      firstElement={(
        <Link
          to={getMessagePath(record.lastDunningId, GetMessagesUsingGETSourceTypeEnum.DUNNING)}
          target="_blank"
        >
          {formatDate(new Date(record.lastDunningDate))}
        </Link>
      )}
      secondElement={tl(orderOpenBalancesTranslations.innerTableColumns.lastDunningMessageStates[record.lastDunningState])}
      disabled={record.contractDunningLevel === ContractProjectionDtoDunningLevelEnum.EXCLUDED}
    />
  );
};

export default ContractLastDunningDateCell;
