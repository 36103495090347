import React, {
  useContext,
} from 'react';
import { Col, Modal, Row } from 'antd';
import Icon from '@ant-design/icons';
import _ from 'lodash';
import { SPACE_SIZES } from 'lib/constants';
import { AccountDistributionProjectionDto } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { accountDistributionTranslations } from '../../translations';
import { useAccountDistributionCopyModal } from './useAccountDistributionCopyModal';
import './AccountDistributionCopyModal.scss';


interface ModalProps {
  sourceADK: AccountDistributionProjectionDto,
  propertyHrId: string,
  economicYear: string,
  modalVisible: boolean,
  closeModal: () => void,
}

export function AccountDistributionCopyModal({
  sourceADK, propertyHrId, economicYear, modalVisible, closeModal,
}: ModalProps) {
  const { tl } = useContext(LanguageContext);


  const {
    selectedYears,
    setSelectedYears,
    selectedAccounts,
    setSelectedAccounts,
    onOk,
    onCancel,
    destinationAccounts,
    economicYearOptions,
    loading,
  } = useAccountDistributionCopyModal({
    accountDistribution: sourceADK,
    propertyHrId,
    closeModal,
  });

  const sourceDKValues = ''.concat(economicYear, ' - ',
    sourceADK?.accountCode ?? '', ' ',
    sourceADK?.accountName ?? '');

  const isButtonDisabled = _.isEmpty(selectedYears) || _.isEmpty(selectedAccounts);

  return (
    <Modal
      className="AccountDistributionCopyModal"
      title={tl(accountDistributionTranslations.copyModal.title)}
      visible={modalVisible}
      onCancel={onCancel}
      footer={(
        <div className="footerWrapper">
          <div className="warningMessage">
            <Icon component={ICONS.yellowInfo} />
            <span>{tl(accountDistributionTranslations.copyModal.warningMessage)}</span>
          </div>
          <Button
            type="primary"
            onClick={onOk}
            disabled={isButtonDisabled}
            loading={loading}
          >
            {tl(accountDistributionTranslations.copyModal.copyButton)}
          </Button>
        </div>
      )}
    >
      <Row gutter={[0, SPACE_SIZES.large]}>
        <Col span={24}>
          <TextInput
            disabled
            onChange={() => {}}
            label={tl(accountDistributionTranslations.copyModal.source)}
            value={sourceDKValues || ''}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            required
            label={tl(accountDistributionTranslations.copyModal.destinationAccounts)}
            mode="multiple"
            onChange={setSelectedAccounts}
            value={selectedAccounts}
            options={destinationAccounts}
            showSearch
            placeholder={tl(accountDistributionTranslations.copyModal.selectPlaceholder)}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            required
            label={tl(accountDistributionTranslations.copyModal.destinationYears)}
            mode="multiple"
            onChange={setSelectedYears}
            value={selectedYears}
            options={economicYearOptions}
            showSearch
            placeholder={tl(accountDistributionTranslations.copyModal.selectPlaceholder)}
          />
        </Col>
      </Row>
    </Modal>
  );
}
