import { getEnvVar } from 'lib/getEnvVar';

import {
  FaciliooContact,
  FaciliooFileUpload,
  FaciliooPage,
  FaciliooProperty,
  FaciliooTicket,
  FaciliooTimelineEntry,
  LoginResponse,
} from './interfaces';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const buildFetchOptions = (method: HttpMethod, token?: string, body?: any): RequestInit => {
  const fetchOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json; charset=utf-8',
    },
    credentials: 'include', // include, same-origin, *omit
    mode: 'cors', // no-cors, *same-origin
    redirect: 'follow', // *manual, error
    referrer: 'no-referrer', // *client
  } as RequestInit;
  if (token) {
    fetchOptions.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    };
  }
  if (body) {
    fetchOptions.body = JSON.stringify(body);
  }
  return fetchOptions;
};

// const faciliooBaseURL = getEnvVar('facilioo.apiBaseUrl', 'https://mocks.app.impower.de/services/facilioo') as string;
// const faciliooBaseURL = getEnvVar('facilioo.apiBaseUrl', 'https://stage-api.facilioo.de') as string;
const faciliooBaseURL = getEnvVar('facilioo.apiBaseUrl', 'http://localhost:9100/services/facilioo') as string;

export interface IFaciliooAPI {
  getPartyByExternalId: (partyExternalId: string) => Promise<FaciliooContact>;
  getPropertyByExternalId: (propertyExternalId: string) => Promise<FaciliooProperty>;
  getAllTicketsBy: (params: { partyId?: number, propertyId?: number }) => Promise<FaciliooTicket[]>;
}


export class FaciliooAPI implements IFaciliooAPI {
  private serverUrl: string;

  private email: string = 'email';

  private password: string = 'password';

  private legacyKey: string;

  constructor({
    serverUrl, email, password, legacyKey,
  }: { serverUrl?: string, email: string, password: string, legacyKey: string }) {
    this.serverUrl = serverUrl ?? faciliooBaseURL;
    this.email = email;
    this.password = password;
    this.legacyKey = legacyKey;
  }

  authenticate() {
    throw new Error(`Use Integrator for retrieving token; Backend set to ${this.serverUrl}`);
  }

  async login(): Promise<LoginResponse> {
    const response = await fetch(`${this.serverUrl}/api/auth/login`,
      buildFetchOptions('POST', null, { email: this.email, password: this.password, skipMultiFactorAuthentication: true }));
    return response.json();
  }

  async getToken(): Promise<string> {
    const loginResponse = await this.login();
    return loginResponse.accessToken;
  }

  async getPartyByExternalId(partyExternalId: string): Promise<FaciliooContact> {
    const response = await fetch(`${this.serverUrl}/api/parties/search`,
      buildFetchOptions('POST', await this.getToken(), { externalId: partyExternalId }));
    const contactsPage = (await response.json()) as FaciliooPage<FaciliooContact>;
    const contact = contactsPage.items[0];
    if (contactsPage.items.length > 1) {
      console.warn('Multiple contacts found; Proceeding with the first matching');
    } else if (!contact) {
      throw 'Contact not found in facilioo';
    }
    return contact;
  }

  async getPropertyByExternalId(propertyExternalId: string): Promise<FaciliooProperty> {
    const token = await this.getToken();
    const response = await fetch(`${this.serverUrl}/api/properties/search`,
      buildFetchOptions('POST', token, { externalId: propertyExternalId }));
    const propertiesPage = (await response.json()) as FaciliooPage<FaciliooProperty>;
    const property = propertiesPage.items[0];
    if (propertiesPage.items.length > 1) {
      console.warn('Multiple properties found; Proceeding with the first matching');
    } else if (!property) {
      throw 'Property not found in facilioo';
    }
    return property;
  }

  async getPropertiesPageBy(token:string, PageNumber?: number, PageSize?: number): Promise<FaciliooPage<FaciliooProperty>> {
    const response = await fetch(`${this.serverUrl}/api/properties?PageNumber=${PageNumber}&PageSize=${PageSize}`,
      buildFetchOptions('GET', token));
    return response.json() as Promise<FaciliooPage<FaciliooProperty>>;
  }

  async getAllProperties(): Promise<FaciliooProperty[]> {
    const token = await this.getToken();
    const firstPage = await this.getPropertiesPageBy(token, 1, 100);
    const restOfTheProperties = await this.getFollowingPages(firstPage, prevPage => this.getPropertiesPageBy(token, prevPage.pageNumber + 1, 100));
    const allProperties = [...firstPage.items, ...restOfTheProperties];
    return allProperties;
  }

  async getTicketPageBy(token: string, searchBody: { partyId?: number, propertyId?: number }, PageNumber?: number): Promise<FaciliooPage<FaciliooTicket>> {
    const response = await fetch(`${this.serverUrl}/api/processes/search?PageNumber=${PageNumber}`,
      buildFetchOptions('POST', token, searchBody));
    return response.json() as Promise<FaciliooPage<FaciliooTicket>>;
  }

  async getAllTicketsBy(searchBody: { partyId?: number, propertyId?: number }): Promise<FaciliooTicket[]> {
    const token = await this.getToken();
    const firstPage = await this.getTicketPageBy(token, searchBody, 1);
    const restOfTheTickets = await this.getFollowingPages(firstPage, prevPage => this.getTicketPageBy(token, { ...searchBody }, prevPage.pageNumber + 1));
    const allTickets = [...firstPage.items, ...restOfTheTickets];
    return allTickets;
  }

  async getFollowingPages<T>(currentPage: FaciliooPage<T>, getNext: (prevPage: FaciliooPage<T>) => (Promise<FaciliooPage<T>>)): Promise<T[]> {
    if (!currentPage || !currentPage.hasNextPage) {
      return [];
    }
    const nextPage = await getNext(currentPage);

    // recuresively load next pages concatenating lists
    return [...nextPage.items, ...(await this.getFollowingPages(nextPage, getNext))];
  }


  // legacy

  async addOrderTimelineEntry(orderId: number, text: string) {
    const response = await fetch(`${this.serverUrl}/order/addTimelineEntry?id=${orderId}&text=${encodeURIComponent(text)}&source=5&apikey=${this.legacyKey}`, buildFetchOptions('POST'));
    return response.json() as Promise<FaciliooTimelineEntry>;
  }

  async addOrderTimelineEntryFile(timelineEntryId:number, fileID: number) {
    const response = await fetch(`${this.serverUrl}/order/addTimelineEntryFile?id=${timelineEntryId}&fileID=${fileID}&apikey=${this.legacyKey}`, buildFetchOptions('POST'));
    return response;
  }

  async addFile(fileName: string, fileExtension: string) {
    const response = await fetch(`${this.serverUrl}/file/add?fileName=${fileName}&fileExtension=${fileExtension}&apikey=${this.legacyKey}`, buildFetchOptions('POST'));
    return response.json() as Promise<FaciliooFileUpload>;
  }
}
