import moment from 'moment';

// more info here: https://github.com/puppeteer/puppeteer/issues/2089
const headerAndFooterStyling = `
<style>
  header, footer {
    font-family: 'IBM Plex Sans';
    font-size: 9px;
    line-height: 12px;
    color: #595959;
    width: 100%;
  }

  @media (orientation: portrait) {
    header, footer {
      margin-left: 23mm;
      margin-right: 12mm;
    }
  }

  @media (orientation: landscape) {
    header, footer {
      margin-left: 12mm;
      margin-right: 18mm;
    }
  }
</style>
`;

export const getHeaderHtmlString = (headerLeft: string, headerRight: string) => (`
${headerAndFooterStyling}
<header>
  <span style="float: left;">${headerLeft}</span>
  <span style="float: right;">${headerRight}</span>
</header>`
);

// the classnames `pageNumber` and `totalPages` are used to inject values
// https://pptr.dev/next/api/puppeteer.pdfoptions.headertemplate/
export const getFooterHtmlString = () => (`
${headerAndFooterStyling}
<footer>
  <div style="float: left;">${moment().format('DD.MM.YYYY')}</div>
  <span style="float: right;"><span class='pageNumber'></span>/<span class='totalPages'></span></span>
</footer>`
);
