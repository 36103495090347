import React from 'react';
import Link from '../../elements/FormElements/FormSidebar/Link/Link';
import _ from "lodash";

export interface SectionMenuSubElement {
  title: string,
  sectionId: string
}

interface SectionSubElementsProps {
  elements: SectionMenuSubElement[],
  open: boolean,
  activeElementHref: string | undefined,
  onScrollToSection: (id: string) => void,
  parentSectionId: string,
}
export default function SectionSubElements(props: SectionSubElementsProps) {
  const {
    open, elements, activeElementHref, onScrollToSection, parentSectionId,
  } = props;

  if (!open || _.isEmpty(elements)) return null;
  return (
    <>
      {elements.map((subElement: SectionMenuSubElement) => (
        <Link
          className="sub-link"
          key={`sub-link-${parentSectionId}-${subElement.sectionId}`}
          level={2}
          active={subElement.sectionId === activeElementHref}
          onClick={() => onScrollToSection(subElement.sectionId)}
          title={subElement.title}
        />
      ))}
    </>
  );
}
