import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Modal, Row,
} from 'antd';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { useManagementCompanyList } from 'pages/PaymentList/services/useManagementCompanyList';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import { LoadingIndicator } from 'elements/LoadingIndicator/LoadingIndicator';
import { useFinApiBankOptions } from 'pages/TransactionImports/Bankconnections/services/useFinApiBankOptions';
import Button from 'elements/Buttons/Button/Button';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { SPACE_SIZES } from 'lib/constants';
import { LanguageContext } from 'contexts/LanguageContext';
import { BankConnectionImportDtoConnectionInterfaceEnum } from 'api/accounting/models/BankConnectionImportDto';
import { translations } from '../../services/translations';
import useBankConnections from '../../services/useBankConnections';
import './BankConnectionModal.scss';

interface BankConnectionImportModalProps {
  visible: boolean,
  onClose: Function,
}

// https://docs.finapi.io/#post-/api/webForms/bankConnectionImport
const FINAPI_BANK_ACCOUNT_NAME_MAX_LENGTH = 64;

export default function BankConnectionImportModal(props: BankConnectionImportModalProps): JSX.Element {
  const {
    visible, onClose,
  } = props;
  const { tl } = useContext(LanguageContext);
  const [selectedBankId, setSelectedBankId] = useState<number>(-1);
  const [connectionName, setConnectionName] = useState<string>('');
  const [connectionInterface, setConnectionInterface] = useState<BankConnectionImportDtoConnectionInterfaceEnum>(BankConnectionImportDtoConnectionInterfaceEnum.XS2A);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(-1);
  const { importBankConnection } = useBankConnections();
  const { bankOptions, bankOptionsLoading, bankConnectionInterfaceOptions } = useFinApiBankOptions();
  const managementCompanies = useManagementCompanyList().map(mc => ({ value: mc.id || -1, label: mc.companyName || '' }));
  useEffect(() => setSelectedCompanyId(managementCompanies[0]?.value), [managementCompanies]);
  const currentBankConnectionInterfaceOptions = bankConnectionInterfaceOptions[selectedBankId] || [];
  return (
    <Modal
      width="50%"
      title={tl(translations.bankConnectionActions.bankConnectionImportModal.title)}
      className="BankConnectionImportModal"
      visible={visible}
      onCancel={() => onClose()}
      footer={[
        <Button onClick={() => onClose()} type="text" key="cancel">
          {tl(translations.bankConnectionActions.bankConnectionImportModal.cancel)}
        </Button>,
        <Button onClick={() => importBankConnection(selectedCompanyId, selectedBankId, connectionName, connectionInterface)} type="default" key="import">
          {tl(translations.bankConnectionActions.bankConnectionImportModal.import)}
        </Button>,
      ]}
    >
      <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.large]}>
        <Col span={24}>
          <SelectInput
            label={tl(translations.bankConnectionActions.bankConnectionImportModal.managementCompany)}
            showSearch
            onChange={e => setSelectedCompanyId(e)}
            placeholder={tl(translations.bankConnectionActions.bankConnectionImportModal.selectCompany)}
            defaultValue={managementCompanies[0]?.value}
            options={managementCompanies}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            label={tl(translations.bankConnectionActions.bankConnectionImportModal.bank)}
            showSearch
            onChange={(e) => {
              setSelectedBankId(e);
              setConnectionName(bankOptions.filter(b => b.value === e)[0]?.label);
            }}
            placeholder={tl(translations.bankConnectionActions.bankConnectionImportModal.selectBank)}
            notFoundContent={bankOptionsLoading ? <LoadingIndicator size="large" /> : null}
            options={bankOptions}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            showSearch
            label={tl(translations.bankConnectionActions.bankConnectionImportModal.bankingInterface)}
            onChange={(e) => {
              setConnectionInterface(e);
            }}
            defaultValue={BankConnectionImportDtoConnectionInterfaceEnum.XS2A}
            placeholder={tl(translations.bankConnectionActions.bankConnectionImportModal.bankingInterface)}
            options={currentBankConnectionInterfaceOptions}
          />
        </Col>
        <Col span={24}>
          <TextInput
            label={tl(translations.bankConnectionActions.bankConnectionImportModal.connectionName)}
            placeholder={tl(translations.bankConnectionActions.bankConnectionImportModal.connectionName)}
            onChange={e => setConnectionName(e)}
            value={connectionName}
            maxLength={FINAPI_BANK_ACCOUNT_NAME_MAX_LENGTH}
            showCount
          />
        </Col>
        <Col span={24}>
          <Button
            className="how-to-button"
            type="text"
            target="blank"
            href={articleUrls.bankConnections.finApi}
            icon={<Icon component={ICONS.howToIcon!} />}
          >
            {tl(translations.documentationLink)}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
