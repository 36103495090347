import React, { useContext } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import SelectInput
  from '../../../../../../../elements/Inputs/SelectInput/SelectInput';
import { translations } from '../../../../../translations';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import './UnitSelectionColumns.scss';
import {
  UnitDistributionSetDtoDistributionModeEnum,
  SpecialContributionDetailsDtoTypeEnum,
  UnitPaymentPreferenceDtoPaymentPreferenceEnum,
} from '../../../../../../../api/accounting';
import { UnitSelectionTableData } from '../../model/UnitSelectionTableData';
import { formatCurrency, isBaseDistribution } from '../../../../../../../lib/Utils';
import NumberInputWithSuffix from '../../../../../../../elements/Inputs/NumberInputWithSuffix/NumberInputWithSuffix';

const floatToFormattedString = (num: number = 0) => formatCurrency(num, '0,00',
  false);

export const UnitSelectionColumns = (typeSC: SpecialContributionDetailsDtoTypeEnum | undefined,
  transactionsExecutionAllowed: boolean,
  onChangePaymentPreference: (unitNrSharingDeclaration: string,
    paymentPreference: UnitPaymentPreferenceDtoPaymentPreferenceEnum) => void,
  onChangeDkValue: (unitNrSharingDeclaration: string,
    dkValue: number) => void,
  onClickWrapper: (selectedRow: UnitSelectionTableData) => void,
  computeUnitAmounts: (distributionMode: UnitDistributionSetDtoDistributionModeEnum | undefined, total: number | undefined) => void,
  distributionMode: UnitDistributionSetDtoDistributionModeEnum | undefined,
  totalShares: number,
  totalAmount: number,
  disabled: boolean = false) => {
  const { tl } = useContext(LanguageContext);

  const getSymbol = () => {
    switch (distributionMode) {
    case UnitDistributionSetDtoDistributionModeEnum.INDIVIDUAL:
      return '%';
    case UnitDistributionSetDtoDistributionModeEnum.HEATING_COST:
      return '€';
    case UnitDistributionSetDtoDistributionModeEnum.DIRECT_COST:
      return '€';
    default:
      return ' ';
    }
  };

  function dkSuffix() {
    return (
      <Amount className="suffixCls">
        /
        {floatToFormattedString(totalShares)}
        {getSymbol()}
      </Amount>
    );
  }

  function onChangeValue() {
    computeUnitAmounts(distributionMode, totalAmount);
  }

  function renderDistributionShare(row: UnitSelectionTableData) {
    return (
      <NumberInputWithSuffix
        className={`${!row.selected || isBaseDistribution(distributionMode)
          ? ' disabled' : ''} dk-value`}
        onChange={(newValue) => {
          row.dkValue = newValue;
          onChangeDkValue(row.unitNrSharingDeclaration, row.dkValue);
          onChangeValue();
        }}
        id={row.unitNrSharingDeclaration}
        onClickWrapper={() => {
          row.selected = true;
          onClickWrapper(row);
        }}
        suffix={dkSuffix()}
        defaultValue="-"
        isDefaultValue={!row.selected && isBaseDistribution(distributionMode)}
        value={row.dkValue}
        disableInput={!row.selected || isBaseDistribution(distributionMode) || disabled}
        disabledOnClickWrapper={isBaseDistribution(distributionMode)}
        precision={distributionMode === UnitDistributionSetDtoDistributionModeEnum.MEA ? 5 : 2}
      />
    );
  }

  function hasBankAgreement(row: UnitSelectionTableData) {
    return (typeSC === SpecialContributionDetailsDtoTypeEnum.CONTRIBUTION && row.hasMandate) || (typeSC
      === SpecialContributionDetailsDtoTypeEnum.DISTRIBUTION && row.iban);
  }

  return [
    {
      title: `${tl(translations.specialContribution.section3.unitSelection.header.unitId)}`,
      dataIndex: 'unitNrSharingDeclaration',
      width: '10%',
      render: (text: any) => (
        <span>{text}</span>
      ),
    },
    {
      title: `${tl(translations.specialContribution.section3.unitSelection.header.unitType)}`,
      dataIndex: 'unitType',
      render: (text: any, row: any) => (<span>{tl(translations.specialContribution.section3.unitTypes[row.unitType])}</span>),
      width: '12%',
    },
    {
      title: `${tl(translations.specialContribution.section3.unitSelection.header.owner)}`,
      dataIndex: 'ownerName',
      width: '13%',
    },
    {
      title: `${tl(translations.specialContribution.section3.unitSelection.header.amountToDistribute)}`,
      dataIndex: 'unitAmount',
      render: (value: number) => (
        <CurrencyInput
          onChange={() => { }}
          value={value}
          disabled
        />
      ),
      width: '20%',
    },
    {
      title: `${tl(translations.specialContribution.section3.unitSelection.header.dkShare)}`,
      dataIndex: 'dkValue',
      render: (text: any, row: any) => renderDistributionShare(row),
      width: '25%',
    },
    {
      title: `${tl(translations.specialContribution.section3.unitSelection.header.processing)}`,
      dataIndex: 'paymentPreference',
      width: '20%',
      render: (paymentPreference: UnitPaymentPreferenceDtoPaymentPreferenceEnum, row: UnitSelectionTableData) => (
        <SelectInput
          className="payment-processing-input"
          onChange={(newPaymentPreference: UnitPaymentPreferenceDtoPaymentPreferenceEnum) => onChangePaymentPreference(
            row.unitNrSharingDeclaration, newPaymentPreference,
          )}
          disabled={!row.selected || !hasBankAgreement(row) || !transactionsExecutionAllowed || disabled}
          value={row.paymentPreference}
          options={[
            {
              value: UnitPaymentPreferenceDtoPaymentPreferenceEnum.SYSTEM_MANAGED,
              label: tl(translations.specialContribution.section3.unitSelection.processingOptions[typeSC!].SYSTEM_MANAGED),
            },
            {
              value: UnitPaymentPreferenceDtoPaymentPreferenceEnum.SELF_MANAGED,
              label: tl(translations.specialContribution.section3.unitSelection.processingOptions[typeSC!].SELF_MANAGED),
            },
          ]}
        />
      ),
    },
  ];
};
