import { InvoiceLegacyControllerApi, InvoiceLegacyDtoStateEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { InvoiceListContext } from 'contexts/InvoiceListContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { showNotification } from 'lib/Notification';
import { useContext } from 'react';
import { useLoadInvoice } from './useLoadInvoice';

export const useRevertInvoice = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));


  const invoiceEditingContext = useContext(InvoiceEditingContext);
  const invoiceListContext: any = useContext(InvoiceListContext);

  const { addInvoiceToList } = invoiceListContext;
  const { data } = invoiceEditingContext;


  const { onLoadInvoice } = useLoadInvoice();

  const onRevertInvoice = (invoiceId: number) => {
    invoiceControllerApi.revertInvoiceUsingPOST({ invoiceId })
      .then(() => {
        showNotification({
          key: 'revertSuccess',
          message: tl(translations.notifications.invoiceListContext.revertSuccess),
          type: 'success',
        });
        onLoadInvoice(data.invoice?.invoiceHrId);
        addInvoiceToList({
          ...data.invoice,
          state: InvoiceLegacyDtoStateEnum.NEW,
          exchangeState: undefined,
          paymentState: undefined,
        });
      })
      .catch(() => {
        showNotification({
          key: 'revertError',
          message: tl(translations.notifications.invoiceListContext.revertError),
          type: 'error',
        });
      });
  };


  return {
    onRevertInvoice,
  };
};
