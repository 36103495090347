import { ReactNode } from 'react';
import './ErrorMessage.scss';

export default function ErrorMessage({ message }: { message: string | ReactNode }): JSX.Element {
  return (
      <div className="failed-message">
        {message}
      </div>
  );
}
