import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import React, { useContext, useMemo } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { Alert } from 'antd/es';
import { DownloadMenu } from 'components/DownloadMenu/DownloadMenu';
import { MessagesListContextProvider } from './service/MessagesListContext';
import { useMessagesColumns } from './service/useMessagesColumns';
import useMessagesList from './service/useMessagesList';
import { useMessagesListFilters } from './service/useMessagesListFilters';
import './MessagesList.scss';
import { messagesListTranslations } from './translations';
import useSmartTable from '../../../elements/SmartTable/useSmartTable';
import SmartTable from '../../../elements/SmartTable/SmartTable';
import { useMessagesListSort } from './service/useMessagesListSort';
import { MessageListProjection, MessageListProjectionStateEnum } from '../../../api/accounting';
import { Action } from '../../../elements/SmartTable/data';
import { getFileNameForMessageListProjection } from '../../../lib/Utils';
import { useMessagePdfDownload } from './service/useMessagePdfDownload';
import { useMessageListCheckboxes } from './service/useMessageListCheckboxes';
import MessagesActionBar from './components/MessagesActionBar/MessagesActionBar';


const MessagesList = (): JSX.Element => {
  const {
    messages, totalMessages, onLoadMessagesList, onRetryMessage, messageDatasource,
  } = useMessagesList();
  const { downloadPdf, downloadMenuOptions } = useMessagePdfDownload();
  const { sortField, sortOrder, onChangeSort } = useMessagesListSort();
  const { tl } = useContext(LanguageContext);
  const {
    filters, filterState, onSetDefaultFilterFromQueryParams, onChangeFilterState,
  } = useMessagesListFilters();

  useGlobalSearch({
    key: 'messages',
    filterProps: {
      availableFilters: filters,
      setFilter: onChangeFilterState,
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams,
    },
  });


  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'messages-overview',
    navItems: subcategorySwitcherItems.communication,
  };

  const filterIsEmpty = useMemo(() => isEmpty(filterState) || Object.values(filterState).every(x => !x), [filterState]);

  const headerRightSideComponent = (
    <div className="messages-header-component">
      {!filterIsEmpty ? <Alert className="total-number" message={totalMessages} type="info" /> : <span />}
      <DownloadMenu
        type="primary"
        loading={messages.loading}
        options={downloadMenuOptions}
      />
    </div>
  );

  const actionsMenu: Action[] = [{
    label: tl(messagesListTranslations.tableOptionMenu.download),
    actionSupported: record => record.state !== MessageListProjectionStateEnum.GENERATING,
    onAction: (record) => {
      downloadPdf(record.id, getFileNameForMessageListProjection(record));
    },
  },
  {
    label: tl(messagesListTranslations.tableOptionMenu.retry),
    onAction: (record) => {
      onRetryMessage(record.id);
    },
    actionSupported: record => record.state === MessageListProjectionStateEnum.FAILED,
  },
  ];

  const { onChangeSelectedRowKeys, selectedRowKeysCurrent, shouldDisableCheckbox } = useMessageListCheckboxes();

  const messageTable = useSmartTable({
    tableName: 'MessagesListTable',
    columns: useMessagesColumns(),
    dataSource: messageDatasource,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => onChangeSort(dataKey as keyof MessageListProjection),
    },
    contentLoading: messages.loading,
    infiniteScrollerProps: {
      hasMoreData: !messages.lastPage,
      loadMoreData: onLoadMessagesList,
    },
    rowKey: 'id',
    actionsMenu,
    supportBatchActions: true,
    shouldDisableCheckbox,
    hideActionBar: true,
    selectedRowKeys: selectedRowKeysCurrent,
    onChangeSelectedRowKeys: keys => onChangeSelectedRowKeys(keys),
  });

  return (
    <div className="MessagesList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        // filters
        rightSideComponent={headerRightSideComponent}
      />
      <SmartTable {...messageTable} />
      <MessagesActionBar />
    </div>
  );
};
const MessagesListWithProvider = () => (
  <MessagesListContextProvider>
    <MessagesList />
  </MessagesListContextProvider>
);


export default MessagesListWithProvider;
