import _ from 'lodash';
import { useSearchContext } from 'pages/MessageSendingPage/services/MessageSendingContext';
import { useState } from 'react';

export const useRecipientSearchBar = () => {
  const [searchInputValue, setSearchInputValue] = useState('');

  const searchContext = useSearchContext('useRecipientSearchBar');
  const { setRecipientSearchString: setSearchString } = searchContext;

  const onSearch = _.debounce((input: string) => {
    setSearchString(input);
  }, 200);

  const onChangeSearchValue = (val) => {
    setSearchInputValue(val);
    onSearch(val);
  };

  return {
    searchInputValue,
    onChangeSearchValue,
  };
};
