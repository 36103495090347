import { useEffect, useState } from 'react';

/**
 * Because of a bug with the antd Select component we have to manually style the virtualized scrollbar.
 * To do this we must do `display: block !important;`.
 * This causes a problem in case there aren't enough options to overflow (as in to require a scrollbar)
 * so we must dynamically add&remove a classname to show/hide the scrollbar.
 */
export const useControlScrollbarVisibility = (optionsDropdownWrapperRef, dropdownOpen) => {
  const [areOptionsOverflowing, setAreOptionsOverflowing] = useState(false);

  // useEffect reacts to a change in the visibility of the dropdown
  useEffect(() => {
    // setTimeout needed to delay this calculation until the list of options actually rendered
    // otherwise the calculation will be incorrect
    setTimeout(() => {
      const optionsDiv = optionsDropdownWrapperRef.current?.getElementsByClassName?.('rc-virtual-list-holder')?.[0];
      const isScrollable = optionsDiv?.scrollHeight > optionsDiv?.clientHeight;
      setAreOptionsOverflowing(isScrollable);
    }, 100)
  }, [dropdownOpen])


  const selectComponentClassName = `${areOptionsOverflowing ? 'is-overflowing' : ''}`

  return {selectComponentClassName};
}