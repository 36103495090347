import { DocumentProjectionDto } from 'api/document';
import { useContext } from 'react';
import { ProfitAndLossReportListContext } from './ProfitAndLossReportListContext';

export const useProfitAndLossReportListSort = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportListContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useProfitAndLossReportListSort must be used within a ProfitAndLossReportListContextProvider');
  }

  const { sortState, setSortState } = profitAndLossReportContext;

  const onChangeSort = (field: keyof DocumentProjectionDto) => {
    setSortState((currentState) => {
      const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
      return {
        field,
        order,
      };
    });
  };

  return {
    sortField: sortState.field,
    sortOrder: sortState.order,
    onChangeSort,
  };
};
