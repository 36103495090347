import * as React from 'react';
import RcInputNumber from 'rc-input-number';

import Icon from '@ant-design/icons';


import { ConfigConsumer, ConfigConsumerProps } from 'antd/lib/config-provider';
import { createPortal } from 'react-dom';
import { isNil } from 'lodash';
import { ICONS } from '../../../components/icons';


type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
// omitting this attrs because they conflicts with the ones defined in InputNumberProps
export type OmitAttrs = 'onInput' | 'defaultValue' | 'onInput' | 'onChange';

export interface InputNumberProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, OmitAttrs> {
  prefixCls?: string;
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  tabIndex?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  readOnly?: boolean;
  formatter?: (value: number | string | undefined) => string;
  parser?: (displayValue: string | undefined) => number;
  decimalSeparator?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  name?: string;
  id?: string;
  precision?: number;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  defaultValue?: number,
  controls?: boolean,
  suffix?: string;
  align?: 'left' | 'right';
}

const InputNumber = React.forwardRef<unknown, InputNumberProps>((props) => {
  /**
   * `suffixTargetRef` is used to render the suffix in the correct position. The component doesn't
   * support a suffix prop, so we need to render it manually.
   * Since we're using a portal to render the suffix, we need to make sure the target element exists
   * before rendering the icon.
   */
  const suffixTargetRef = React.useRef(null);
  const [isTargetReady, setIsTargetReady] = React.useState(false);

  React.useEffect(() => {
    if (suffixTargetRef.current) {
      setIsTargetReady(true);
    }
  }, [suffixTargetRef.current]);


  const renderInputNumber = ({ getPrefixCls }: ConfigConsumerProps) => {
    const {
      className,
      prefixCls: customizePrefixCls,
      readOnly,
      defaultValue,
      suffix,
      align,
      controls,
      ...others
    } = props;
    const prefixCls = getPrefixCls('input-number', customizePrefixCls);
    const upIcon = <Icon component={ICONS.increment} />;
    const downIcon = <Icon component={ICONS.decrement} />;

    const onChange = (value: number) => {
      if (others.onChange && typeof value !== 'string') others.onChange(value);
    };
    return (
      <>
        <RcInputNumber
          ref={suffixTargetRef}
          className={`InputNumber ${className} ${align === 'right' ? 'InputNumber-align-right' : ''}`}
          upHandler={upIcon}
          defaultValue={defaultValue}
          downHandler={downIcon}
          prefixCls={prefixCls}
          readOnly={readOnly}
          controls={controls}
          {...others}
          // @ts-ignore
          onChange={onChange}
        />
        {isTargetReady && (!isNil(suffix)) && createPortal(
          <span className={`suffix ${controls ? 'suffix-with-controls' : ''}`}>{suffix}</span>,
          suffixTargetRef.current?.parentElement,
        )}
      </>
    );
  };

  return <ConfigConsumer>{renderInputNumber}</ConfigConsumer>;
});

InputNumber.defaultProps = {
  step: 1,
  align: 'left',
  controls: true,
};

export default InputNumber;
