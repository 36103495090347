import { useContext } from 'react';
import { PropertyBankAccountControllerApi, UnitContractControllerApi, UnitContractProjectionDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import _ from 'lodash';
import { useOpsCostReportContext } from 'pages/OpsCostReport/OpsCostReportEditing/services/OpsCostReportContext';
import { formatDate } from 'lib/Utils';


interface ContractValidityCheckProps {
  propertyId?: number,
  startDate?: string,
  endDate?: string,
  signal: AbortSignal,
}


interface BankAccountValidityCheckProps {
  propertyId?: number,
  signal: AbortSignal,
}

export enum OPS_COST_VALIDATION_ERROR {
  missingBankAccount = 'missingBankAccount',
  invalidContracts = 'invalidContracts',
  networkError = 'networkError',
}


export const useOpsCostReportValidityChecks = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const {
    reportData,
  } = useOpsCostReportContext('useOpsCostReportValidityChecks');

  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const propertyBankAccountControllerApi = new PropertyBankAccountControllerApi(apiConfiguration('accounting'));


  const validateBankAccountsAndContracts = (signal: AbortSignal) => (
    checkPropertyBankAccountExists({
      propertyId: reportData.property.id,
      signal,
    })
      .then(() => checkContractsValidity({
        propertyId: reportData.property?.id,
        startDate: formatDate(reportData.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(reportData.endDate, 'YYYY-MM-DD'),
        signal,
      }))
      .then(() => ({ bankAccountsAndContractsAreValid: true }))
      .catch((err) => {
        if (signal?.aborted) {
          return { aborted: true };
        }

        if (OPS_COST_VALIDATION_ERROR[err.message]) {
          return { error: err.message as OPS_COST_VALIDATION_ERROR };
        }

        return { error: OPS_COST_VALIDATION_ERROR.networkError };
      })
  );


  const checkContractsValidity = ({
    propertyId, signal, startDate, endDate,
  }: ContractValidityCheckProps) => (
    unitContractControllerApi.validateContractsUsingGET({ propertyId, startDate, endDate }, { signal })
      .then(({ valid }) => {
        if (!valid) {
          throw new Error(OPS_COST_VALIDATION_ERROR.invalidContracts);
        }
      })
      // ! do not add catch
  );

  const checkPropertyBankAccountExists = ({ propertyId, signal }: BankAccountValidityCheckProps) => propertyBankAccountControllerApi
    .filterPropertyBankAccountsUsingGET({ propertyIds: [propertyId] }, { signal })
    .then((resp) => {
      if (!resp?.length) {
        throw new Error(OPS_COST_VALIDATION_ERROR.missingBankAccount);
      }
    });
    // ! do not add catch


  return { validateBankAccountsAndContracts };
};
