import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { useBankAccountsTable } from './useBankAccountsTable';
import BankAccountsTableSummary from './BankAccountsTableSummary';

const BankAccountsTable = () => {
  const {
    columns,
    firstDataSource, firstStartBalanceSum, firstDebitSum, firstCreditSum, firstBalanceWithinDateRangeSum, firstEndBalanceSum,
    secondDataSource,
  } = useBankAccountsTable();
  const { tl } = useContext(LanguageContext);


  const summaryFirstTable = () => (
    <BankAccountsTableSummary
      title={tl(translations.report.sections.bankAccountsSection.summary)}
      startBalanceSum={firstStartBalanceSum}
      debitSum={firstDebitSum}
      creditSum={firstCreditSum}
      balanceWithinDateRangeSum={firstBalanceWithinDateRangeSum}
      endBalanceSum={firstEndBalanceSum}
    />
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={firstDataSource}
        pagination={false}
        summary={summaryFirstTable}
        rowKey="accountCode"
      />
      <Table
        className="NoHeaderTable"
        columns={columns}
        dataSource={secondDataSource}
        pagination={false}
        // we need to add the summary here as well so that the column widths stay aligned;
        // we hide this summary thrugh css in BankAccountsSection.scss
        summary={summaryFirstTable}
        rowKey="accountCode"
      />
    </>
  );
};

export default BankAccountsTable;
