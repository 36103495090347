import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import './SummaryRow.scss';

interface summaryRowInterface {
  title?: string,
  rightSideElements: {
    title: string,
    value: React.ReactNode
  }[]
}

export default function SummaryRow({ rightSideElements, title }: summaryRowInterface) {
  const { tl } = useContext(LanguageContext);
  return (
    <div className="SummaryRow">
      <div className="title">
        {title || tl(translations.elements.summaryRow.title)}
      </div>
      <div className="right-side">
        {rightSideElements.map(rightSide => (
          <div className="right-side-element" key={rightSide.title}>
            <div className="element-title">
              {rightSide.title}
            </div>
            <div className="element-value">
              {rightSide.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
