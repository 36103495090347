import React, { ReactNode, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import Button, { CustomButtonProps } from 'elements/Buttons/Button/Button';
import './DownloadMenu.scss';
import { ICONS } from '../icons';

interface DownloadMenuProps {
  loading?: boolean,
  label?: ReactNode,
  options: MenuOption[],
  disabled?: boolean,
  tooltipText?: string,
  type?: CustomButtonProps['type'],
}

interface MenuOption {
  label: string,
  onClick: () => void,
}

export const DownloadMenu: React.FC<DownloadMenuProps> = (props) => {
  const {
    loading, options, disabled, label, tooltipText, type,
  } = props;

  const menu = useMemo(() => (
    <Menu>
      {options.map(option => (
        <Menu.Item
          key={option.label}
          className="download-menu-option-item"
          onClick={() => option.onClick()}
        >
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  ), [options]);

  return (
    <Dropdown
      className={`download-menu ${disabled ? 'disabled' : ''}`}
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      disabled={disabled}
    >
      {tooltipText ? (
        <Tooltip title={tooltipText} placement="bottom">
          <Button
            type={type}
            className={`download-menu-button ${disabled ? 'btn-disabled' : ''}`}
            loading={loading}
          >
            {label}
            <Icon className="arrow-icon" component={ICONS.dropdownArrow} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          type={type}
          className="download-menu-button"
          loading={loading}
        >
          {label}
          <span className="separator" />
          <Icon className="arrow-icon" component={ICONS.dropdownArrow} />
        </Button>
      )}
    </Dropdown>
  );
};

DownloadMenu.defaultProps = {
  label: <Icon className="download-icon" component={ICONS.download} />,
  type: 'ghost',
};
