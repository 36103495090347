import React, {
  createContext,
  useState,
} from 'react';
import { AccountAllocation } from '../interfaces';

export const BankTransactionAllocationContext = createContext<{
  allocationContentLoading: boolean,
  setAllocationContentLoading: React.Dispatch<React.SetStateAction<boolean>>,
  transactionActionInProgress: boolean,
  setTransactionActionInProgress: React.Dispatch<React.SetStateAction<boolean>>,
  allocatedPropertyHrIdsOnServiceCompanyTab: string[],
  setAllocatedPropertyHrIdsOnServiceCompanyTab: React.Dispatch<React.SetStateAction<string[]>>,
  unitAllocationAmounts: AccountAllocation[],
  setUnitAllocationAmounts: React.Dispatch<React.SetStateAction<AccountAllocation[]>>,
} | undefined >(undefined);


const BankTransactionAllocationProvider = ({ children }: any) => {
  const [allocationContentLoading, setAllocationContentLoading] = useState(false);
  const [transactionActionInProgress, setTransactionActionInProgress] = useState(false);
  const [allocatedPropertyHrIdsOnServiceCompanyTab, setAllocatedPropertyHrIdsOnServiceCompanyTab] = useState<string[]>();
  const [unitAllocationAmounts, setUnitAllocationAmounts] = useState<AccountAllocation[]>([]);

  const value = {
    allocationContentLoading, setAllocationContentLoading, 
    transactionActionInProgress, setTransactionActionInProgress,
    allocatedPropertyHrIdsOnServiceCompanyTab, setAllocatedPropertyHrIdsOnServiceCompanyTab,
    unitAllocationAmounts, setUnitAllocationAmounts,
  };

  return (
    <BankTransactionAllocationContext.Provider value={value}>
      {children}
    </BankTransactionAllocationContext.Provider>
  );
};

export default BankTransactionAllocationProvider;
