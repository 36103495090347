import { RouteDefinition } from 'components/RenderRoutes';
import OwnersMeetingProtocolList from './pages/OwnersMeetingProtocolList/OwnersMeetingProtocolList';
import OwnersMeetingProtocolEditing from './pages/OwnersMeetingProtocolEditing/OwnersMeetingProtocolEditing';
import { OwnersMeetingProtocolProvider } from './services/OwnersMeetingProtocolContext';

import { OwnersMeetingProtocolAttendance } from './pages/OwnersMeetingProtocolEditing/pages/OwnersMeetingProtocolAttendance/OwnersMeetingProtocolAttendance';
import PowerOfAttorneyDocument from './pages/OwnersMeetingProtocolEditing/pages/PowerOfAttorneyDocument/PowerOffAttorneyDocument';
import AgendaItemDetails from './pages/OwnersMeetingProtocolEditing/pages/OwnersMeetingProtocolAgendaItems/components/AgendaItemDetails';

export const OwnersMeetingProtocolRoutes: RouteDefinition = {
  context: OwnersMeetingProtocolProvider,
  subRoutes: [
    {
      path: '/owners-meeting/protocol',
      component: OwnersMeetingProtocolList,
    },
    {
      path: '/owners-meeting/protocol/view/:etvId',
      type: 'focus',
      component: OwnersMeetingProtocolEditing,
      subRoutes: [
        {
          path: ['/owners-meeting/protocol/view/:etvId/agenda-item/:agendaItemId'],
          component: AgendaItemDetails,
          type: 'overlay',
        },
      ],
    },
    {
      path: '/owners-meeting/protocol/create',
      type: 'focus',
      component: OwnersMeetingProtocolEditing,
    },
    {
      path: '/owners-meeting/protocol/edit/:etvId',
      type: 'focus',
      component: OwnersMeetingProtocolEditing,
      subRoutes: [
        {
          path: ['/owners-meeting/protocol/edit/:etvId/agenda-item/:agendaItemId', '/owners-meeting/protocol/edit/:etvId/agenda-item/create'],
          component: AgendaItemDetails,
          type: 'overlay',
        },
        {
          path: '/owners-meeting/protocol/edit/:etvId/power-of-attorney/:attendeeId',
          component: PowerOfAttorneyDocument,
          type: 'focus',
        },
        {
          path: '/owners-meeting/protocol/edit/:etvId/attendance/:topicType/:index',
          type: 'overlay',
          component: OwnersMeetingProtocolAttendance,
        },
      ],
    },
    {
      path: '*/:propertyHrId/owners-meeting/protocol',
      component: OwnersMeetingProtocolList,
      type: 'overlay',
      width: '90%',
    },
    {
      path: '*/:propertyHrId/owners-meeting/protocol/view/:etvId',
      type: 'focus',
      component: OwnersMeetingProtocolEditing,
    },
    {
      path: '*/:propertyHrId/owners-meeting/protocol/edit/:etvId',
      type: 'focus',
      component: OwnersMeetingProtocolEditing,
      subRoutes: [
        {
          path: '*/:propertyHrId/owners-meeting/protocol/edit/:etvId/power-of-attorney/:attendeeId',
          component: PowerOfAttorneyDocument,
          type: 'focus',
        },
        {
          path: '*/:propertyHrId/owners-meeting/protocol/edit/:etvId/attendance/:topicType/:index',
          type: 'overlay',
          component: OwnersMeetingProtocolAttendance,
        },
      ],
    },
  ],
};
