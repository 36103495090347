import React, { useContext } from 'react';
import { Modal } from 'antd';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from './translations';
import Button from '../../../elements/Buttons/Button/Button';

export interface PostingWarningModalProps {
  visible: boolean,
  onCancel: () => void,
  onProceed: () => void,
  onReview: () => void,
  children?: React.ReactNode,

}

export const PostingWarningModal = (props: PostingWarningModalProps) => {
  const {
    visible, onReview, onProceed, onCancel, children,
  } = props;
  const { tl } = useContext(LanguageContext);

  return (
    <Modal
      onCancel={onCancel}
      className="PostingWarningModal"
      visible={visible}
      footer={[
        <Button key="cancel" type="text" onClick={onCancel}>
          {tl(translations.modal.cancel)}
        </Button>,
        <Button key="review" type="default" onClick={onReview}>
          {tl(translations.modal.review)}
        </Button>,
        <Button key="proceed" type="primary" onClick={onProceed}>
          {tl(translations.modal.proceed)}
        </Button>,
      ]}
    >
      <span>
        {children}
        {tl(translations.modal.title)}
      </span>
    </Modal>

  );
};
