import { InvoiceEpo, InvoiceLegacyControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { convertToFEModel } from 'contexts/functions/InvoiceEditingFunctions';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { showNotification } from 'lib/Notification';
import { useContext } from 'react';

export const useLoadInvoice = () => {
  const { tl } = useContext(LanguageContext);

  const invoiceEditingContext = useContext(InvoiceEditingContext);

  const {
    setInvoiceState, changeInvoiceBookings, updateInvoiceState,
  } = invoiceEditingContext;

  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));

  const onLoadInvoice = (invoiceHrId: string) => {
    setInvoiceState(prev => prev.startLoading());

    return invoiceControllerApi.getInvoiceUsingGET({ invoiceHrId })
      .then((response: InvoiceEpo) => {
        updateInvoiceState({
          invoice: convertToFEModel(response),
        }, {}, false);
        changeInvoiceBookings({ type: 'setDefault', value: response.invoiceBookings });

        return Promise.resolve(response);
      })
      .catch((err) => {
        setInvoiceState(prev => prev.failed());
        showNotification({
          key: 'loadInvoiceError',
          message: tl(translations.notifications.invoiceEditingContext.loadError.message),
          description: tl(translations.notifications.invoiceEditingContext.loadError.description),
          type: 'error',
        });
        return Promise.reject(err);
      });
  };

  return {
    onLoadInvoice,
  };
};
