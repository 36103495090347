import { Table } from 'antd';
import { TableSummaryIconCell } from 'elements/TableSummaryIconCell/TableSummaryIconCell';
import { formatCurrency } from 'lib/Utils';
import { useOpsCostPrintMode, useYourPrepaymentsManualValuesTotals } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';
import Amount from 'storybook-components/typography/Amount/Amount';
import { ReturnTypeOfYourPrepaymentsHook } from '../YourPrepaymentsSection';

interface Props {
  title: string,
  getPrepaymentsTotalIncludingManualAmounts: ReturnTypeOfYourPrepaymentsHook['getPrepaymentsTotalIncludingManualAmounts'],
  nrTotalColumns: number,
  isContractVatRelevant,
  contractId: number,
}


const YourPrepaymentsTableSummary = ({
  title, getPrepaymentsTotalIncludingManualAmounts, nrTotalColumns, isContractVatRelevant, contractId,
}: Props) => {
  /* if we move this dependency any higher up in the tree, then the entire table will re-render on every keystroke */
  const summaryFromManualAmounts = useYourPrepaymentsManualValuesTotals(contractId);
  const summaryData = getPrepaymentsTotalIncludingManualAmounts(summaryFromManualAmounts);
  const printMode = useOpsCostPrintMode();
  const addPlaceholderColumnForDocuent = printMode ? 1 : 0;

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <TableSummaryIconCell colSpan={isContractVatRelevant ? nrTotalColumns + addPlaceholderColumnForDocuent - 4 : nrTotalColumns + addPlaceholderColumnForDocuent - 2} content={title} />

        {isContractVatRelevant && (
          <>
            <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
              <Amount>{formatCurrency(summaryData.net, '-', false)}</Amount>
            </Table.Summary.Cell>

            <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
              <Amount>{formatCurrency(summaryData.vat, '-', false)}</Amount>
            </Table.Summary.Cell>
          </>
        )}

        <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
          <Amount>{formatCurrency(summaryData.gross, '-', false)}</Amount>
        </Table.Summary.Cell>

        {!printMode && (
          <Table.Summary.Cell className="" index={1} colSpan={1}>
            {/* empty cell for RowActionColumn */}
          </Table.Summary.Cell>
        )}

      </Table.Summary.Row>
    </Table.Summary>
  );
};

export default YourPrepaymentsTableSummary;
