import { Link } from 'react-router-dom';
import { PropertyLegacyDto } from 'api/accounting';
import { usePropertyLink } from './usePropertyLink';

const PropertyLink = ({ property } : { property: PropertyLegacyDto }) => {
  const { label, url } = usePropertyLink(property);
  return (
    <Link to={url} target="_blank">{label}</Link>
  );
};

export default PropertyLink;
