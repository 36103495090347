import { useContext, useEffect, useState } from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import { Overlay, OverlayContext } from './OverlayContext';
import { GlobalSearchContext } from '../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { BreadcrumbInterface } from '../../components/Header/components/SearchBar/components/Breadcrumbs/Breadcrumbs';


interface UseOverlayContextInterface {
  (overlay: Overlay): OverlayData
}

interface OverlayData {
  level: number,
  zIndex: number,
  lastFocusLevel: number,
  previousUrl: string,
  previousPath: string,
}

export const useOverlayContext: UseOverlayContextInterface = (overlay) => {
  const { addOverlay, popOverlay } = useContext(OverlayContext);
  const {
    addBreadcrumb, removeBreadcrumb, setSelectedPropertyHrId, setCurrentlyEditedFilter,
  } = useContext(GlobalSearchContext);
  const [visible, setVisible] = useState<Boolean>(false);
  const [overlayData, setOverlayData] = useState<OverlayData>({
    level: 0, zIndex: 200, lastFocusLevel: 0, previousUrl: '', previousPath: '',
  });
  const routerMatch = useRouteMatch(overlay);
  const location = useLocation();

  /**
   * I have absolutely no idea why, but in the third useEffect (with dependencies [visible]) the
   * location.search is empty, even though it is not empty in the first useEffect (with dependencies [routerMatch])
   * which happens BEFORE that one. So we need to store the search state in a separate variable.
   */
  const [searchState, setSearchState] = useState<string>('');


  useEffect(() => {
    if (routerMatch !== null && !visible) {
      setSearchState(location.search);
      setVisible(true);
    } else if (routerMatch === null && visible) {
      setSearchState('');
      setVisible(false);
    }
  }, [routerMatch]);

  useEffect(() => {
    // update the previous url if the parameters changed in the current url and the previous also should contain them
    // 'previousUrl' is used on back navigation (clicking on the darkened side)
    setOverlayData((prev) => {
      const matchObj = matchPath(location.pathname, { path: prev.previousPath, exact: false, strict: false });
      if (matchObj !== null && matchObj.url !== prev.previousUrl) {
        return { ...prev, previousUrl: matchObj.url };
      }
      return prev;
    });
  }, [location]);

  const getSearchParamForThisOverlay = () => {
    // if this overlay is an intermediary overlay between where we navigate from and where we navigate to,
    // then we should not take into consideration the search params
    // (i.e. if opening 2 overlays at once then the first overlay should not cache the search params of the second overlay)
    if (routerMatch?.isExact) return searchState;
    return '';
  };

  // effect that adds/removes overlays and breadcrumbs for them; sets the selected property for the global search if there is one.
  // FIXME: find a better solution/place for setting the selected property
  useEffect(() => {
    if (visible) {
      // this timeout makes the pop take the priority if both pop and push needs to happen
      setTimeout(() => {
        overlay.url = routerMatch ? routerMatch.url : '';

        const [lvl, zIndex, lastFocusLevel, previousUrl, previousPath, label] = addOverlay({
          ...overlay,
          search: getSearchParamForThisOverlay(),
        });

        if (overlay.url !== '/dashboard' && overlay.addBreadcrumb !== false) {
          addBreadcrumb({
            breadcrumbType: 'LINK',
            label,
            path: overlay.path,
            url: overlay.url,
          });
        }

        setCurrentlyEditedFilter(null);
        if (overlay.path === '/properties/:propertyHrId/edit') {
          const propertyHrId = overlay.url.split('/')[overlay.url.split('/').length - 2];
          setSelectedPropertyHrId(propertyHrId);
        }
        setOverlayData({
          level: lvl, zIndex, lastFocusLevel, previousUrl, previousPath,
        });
      }, 20);
    } else if (overlayData.level !== 0) {
      popOverlay(overlay.path);
      setCurrentlyEditedFilter(null);

      if (overlay.path === '/properties/:propertyHrId/edit') {
        setSelectedPropertyHrId(null);
      }

      if (overlay.addBreadcrumb !== false) {
        removeBreadcrumb((breadcrumb: BreadcrumbInterface) => breadcrumb.path === overlay.path);
      }
    }
  }, [visible]);

  return overlayData;
};
