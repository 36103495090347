import { RouteDefinition } from 'components/RenderRoutes';
import { ImportEditor } from './ImportEditor';
import { ImportList } from './ImportList/ImportList';

export const ImportRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/imports',
      component: ImportList,
      subRoutes: [
        {
          path: '/imports/new',
          component: ImportEditor,
          type: 'focus',
          exact: true,
        },
      ],
    },
  ],
};
