import React, { useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import Icon from '@ant-design/icons';
import { AuthContext } from 'contexts/AuthContext';
import { InvoiceLegacyControllerApi } from 'api/accounting';
import { useHistory } from 'react-router';
import { ICONS } from '../../../../components/icons';
import { ExtendedBankTransaction } from '../../interfaces';
import { DropMenu, MenuOption } from '../../../../components/DropMenu/DropMenu';


interface Props {
  row: ExtendedBankTransaction,
}


const getOptions = (row: ExtendedBankTransaction, setLoading: React.Dispatch<React.SetStateAction<boolean>>, invoiceControllerApi: InvoiceLegacyControllerApi, onNavigateToInvoice: (hrId: string) => void): MenuOption[] => {
  const { allocatedInvoices } = row;

  if (!allocatedInvoices) return [];

  return allocatedInvoices.map(({ invoiceId, invoiceHrId }) => {
    if (invoiceHrId) {
      return {
        label: invoiceHrId,
        onClick: (e) => {
          e?.preventDefault();
          e?.stopPropagation();
          onNavigateToInvoice(invoiceHrId);
        },
      };
    }

    return {
      label: `INV${invoiceId}`,
      onClick: (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        setLoading(true);
        invoiceControllerApi.getInvoiceHrIdByIdUsingGET({ invoiceId })
          .then(hrId => onNavigateToInvoice(hrId))
          .finally(() => setLoading(false));
      },
    };
  });
};


const InvoiceDropMenu = ({ row }: Props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));

  if (isEmpty(row.allocatedInvoices)) {
    return null;
  }

  const onNavigateToInvoice = (invoiceHrId: string) => {
    history.push(`/bank-transactions/${row.allocationGroupId}/invoice/${invoiceHrId}`);
  };

  return (
    <DropMenu
      className="invoice-icon-wrapper"
      loading={loading}
      options={getOptions(row, setLoading, invoiceControllerApi, onNavigateToInvoice)}
    >
      {
        loading
          ? null
          : (
            <>
              <Icon
                className="invoice-icon"
                component={ICONS.invoice}
              />
              {row.allocatedInvoices?.length}
            </>
          )
      }
    </DropMenu>
  );
};

export default InvoiceDropMenu;
