import { MAX_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../translations';
import { AccountSelectionContext, HouseMoneySettlementAndPropertyContext, ValidationErrors } from './HouseMoneySettlementCloseYearContext';

const REQUIRED_FIELDS = ['bookingDate', 'bankAccount'];
export const useValidationErrors = () => {
  const { tl } = useContext(LanguageContext);
  const accountSelectionContext = useContext(AccountSelectionContext);
  const hmsPropertyContext = useContext(HouseMoneySettlementAndPropertyContext);

  if (accountSelectionContext === undefined) {
    throw new Error('useValidationErrors must be used within a HouseMoneySettlementCloseYearContextProvider');
  }

  const { validationErrors, setValidationErrors, formValues } = accountSelectionContext;
  const { propertyAndEconomicYearData } = hmsPropertyContext;

  const isExchangeValid = () => {
    const newErrors: ValidationErrors = {};
    // check required fields
    REQUIRED_FIELDS.forEach((requiredFieldKey: string) => {
      // @ts-ignore
      const field = formValues[requiredFieldKey];
      if (field === undefined || field === null || field === '') {
        newErrors[requiredFieldKey] = {
          state: 'error',
          errorMessage: tl(translations.creationPage.notifications.mandatory),
        };
      }
    });

    setValidationErrors(newErrors);

    return _.isEmpty(Object.keys(newErrors));
  };

  const bookingDateValidation = {
    // for now we will not check for the exact economic year
    // the year of the booking date and the economic year of the HGA will be compared
    isBeforeReportEndDate: formValues.bookingDate?.year() <= parseInt(propertyAndEconomicYearData?.economicYear, 10),
    isFarInFuture: formValues.bookingDate?.isAfter(MAX_DATE_NORMAL),
  };

  return {
    validationErrors,
    isExchangeValid,
    bookingDateValidation,
  };
};
