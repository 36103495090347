import { Table } from 'antd';
import { RowExpandIcon } from 'elements/SmartTable/components/RowExpandIcon/RowExpandIcon';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { useEffect, useState } from 'react';
import TableColumnSelectorWrapper from 'storybook-components/table/TableColumnSelector/TableColumnSelectorWrapper';
import { useVT } from 'virtualizedtableforantd4';
import { ContractBalanceTableRowSelectionCell } from './components/ContractBalanceTableRowSelectionCell';
import { DebtorBalancesGroupedType } from '../../services/interfaces';

// TODO define
type Props = {
  propertyId: number,
  dataSource: DebtorBalancesGroupedType[],
  resizableComponents,
  columns,
  visibleColumns,
  changeColumnVisibility,
  selectedRowKeys,
  onChangeSelectedRowKeys,
}

export const INNER_TABLE_HEIGHT = 500;

const ContractBalanceTable = ({
  propertyId,
  dataSource: dataSourceProp,
  resizableComponents,
  columns,
  visibleColumns,
  changeColumnVisibility,
  selectedRowKeys,
  onChangeSelectedRowKeys,
}: Props) => {
  /**
   * we must call `useVT` separately for each inner table, otherwise the rows will not be
   * shown/hidden correctly when expanding/collapsing the outer table.
   */
  const [VT, setComponents] = useVT(() => ({ scroll: { y: INNER_TABLE_HEIGHT } }), []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    /**
     * since we have to keep the column widths in sync between the inner tables, we need to
     * use the same `resizableComponents` object for all inner tables; and since we also
     * need to use the `VT` components for virtualization, we must call `setComponents` here
     */
    setComponents(resizableComponents);

    /**
     * The problem with calling `setComponents` is that it doesn't trigger a re-render,
     * so the table headers will remain non-resizable; this is why we need to force the rerender.
     * And until then we show a loading indicator, otherwise the flicker caused by the rerender
     * will be more noticable.
     */
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [resizableComponents]);

  /**
   * A simple re-render isn't enough for the table's headers to rerender, the whole table must be
   * re-rendered, and we force that by setting the `key` prop and changing its value.
   */
  const tableKey = JSON.stringify(loading);

  /**
   * For some reason, before the forced re-render, the table will only render the first row,
   * and that looks weird, so until the re-render is forced, we show an empty table,
   * then we remove the loading state and show the actual data.
   */
  const dataSource = loading ? [] : dataSourceProp;

  return (
    <TableColumnSelectorWrapper columns={columns} changeColumnVisibility={changeColumnVisibility}>
      <Table
        rowKey="id"
        key={tableKey}
        className="ContractBalanceTable"
        columns={visibleColumns}
        components={VT}
        loading={{
          spinning: loading,
          indicator: <TableContentLoadingIndicator />,
        }}
        pagination={false}
        scroll={{ x: 500, y: INNER_TABLE_HEIGHT }}
        dataSource={dataSource}
        rowSelection={{
          columnTitle: <></>,
          selectedRowKeys,
          // making it wider to indent the inner table
          columnWidth: 104,
          onChange: selRows => onChangeSelectedRowKeys(propertyId, selRows),
          checkStrictly: false,
          getCheckboxProps: () => ({
            className: `batch-checkbox ${selectedRowKeys?.length > 0 ? 'visible' : ''}`,
          }),
          renderCell: (_value, record, _index, originNode) => (
            <ContractBalanceTableRowSelectionCell record={record} originNode={originNode} />
          ),
        }}
        expandable={{
          expandRowByClick: false,
          expandIcon: (prps: any) => <RowExpandIcon needIndentSpaced {...prps} />,
          indentSize: 16,
        }}
      />
    </TableColumnSelectorWrapper>
  );
};

export default ContractBalanceTable;
