import { ContractProjectionDto } from 'api/accounting';
import {
  useMemo,
} from 'react';
import { useContractGroupUtil } from 'services/ContractGroup/useContractGroupUtil';
import { useContractGroupWithPropertiesContext } from './ContractGroupWithPropertiesContext';

export const useContractGroupWithProperties = () => {
  const { contractGroup, allPropertiesFromContractGroup } = useContractGroupWithPropertiesContext('useContractGroup');
  const { getGroupForContract: getGroupForContractUtil } = useContractGroupUtil();

  const isContractInEditorPartOfGroup = useMemo(() => contractGroup && contractGroup?.data?.length > 1, [contractGroup]);

  const getGroupForContract = (contractId: number | undefined): Promise<ContractProjectionDto[]> => {
    if ((!contractId)) return Promise.resolve([]);

    // If we request the same group as the one open in editor, then return it.
    if (contractGroup && contractGroup?.data?.length && contractGroup?.data?.map(c => c.unitContractId).includes(contractId)) {
      return Promise.resolve(contractGroup?.data);
    }

    return getGroupForContractUtil(contractId);
  };


  return {
    contractGroupOfContractInEditor: contractGroup.data,
    isContractGroupLoaded: contractGroup.loaded,
    getGroupForContract,
    isContractInEditorPartOfGroup,
    allPropertiesFromContractGroup: allPropertiesFromContractGroup.data,
    allPropertiesFromContractGroupLoading: allPropertiesFromContractGroup.loading,
  };
};
