import { useState } from 'react';

export const useBankConnectionModal = () => {
  const [bankConnectionImportModalVisible, setImportConnectionModalVisible] = useState(false);
  const showBankConnectionImportModal = () => setImportConnectionModalVisible(true);
  const hideBankConnectionImportModal = () => setImportConnectionModalVisible(false);
  return {
    bankConnectionImportModalVisible,
    showBankConnectionImportModal,
    hideBankConnectionImportModal,
  };
};
