import './Note.scss';

import { ICONS } from 'components/icons';
import { useModal } from 'elements/Modals/useModal/useModal';

import Icon from '@ant-design/icons';

import { NoteModal } from './noteModal/NoteModal';

interface NoteProps {
    content: string,
}

export const Note = ({ content }: NoteProps) => {
  const { visible, showModal, onClickCancel } = useModal({ onOk: () => {} });

  return (
    <>
      <div className="Note" onClick={showModal} role="button" tabIndex={0}>
        <Icon
          className="icon"
          component={ICONS.mail}
        />
        <div
          className="html-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Icon
          className="icon"
          component={ICONS.expandModal}
        />
      </div>
      <NoteModal
        content={content}
        onCancel={onClickCancel}
        visible={visible}
      />
    </>
  );
};

export default Note;
