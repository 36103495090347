import { useContext } from 'react';
import { Account, useAccountList } from 'services/useAccountList';
import { InvoiceBookingDto } from 'api/accounting';
import ComponentVisibilityToggler from 'components/ComponentVisibilityToggler/ComponentVisibilityToggler';
import DateInput from 'elements/Inputs/DateInput/DateInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getChartOfAccountsArticleUrl } from 'storybook-components/feedback/AlertArticleLink/ArticleUrlUtils';
import { getAdministrationTypeFromPropertyHrIdForArticles } from 'services/Property/usePropertyUtils';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import { InvoiceBookingNS } from '../../Interfaces/InvoiceBookingInterfaces';
import { AccountFilterComponent } from '../../../../../elements/Inputs/AccountFilterComponent/AccountFilterComponent';
import NetAndVatAmount from './components/NetAndVatAmount/NetAndVatAmount';
import InvoiceBookingSummaryInfoBar from './components/InvoiceBookingSummaryInfoBar/InvoiceBookingSummaryInfoBar';
import InvoiceTotalGrossAmount from './components/InvoiceTotalGrossAmount/InvoiceTotalGrossAmount';
import LaborCostRow from './components/LaborCostRow/LaborCostRow';

const COMPANY_ACCOUNT_PREFIX = '442';
const OWNER_UNIT_ACCOUNT_PREFIX = '2000';
const TENANT_UNIT_ACCOUNT_PREFIX = '2001';
const PROPERTY_BANK_ACCOUNT_PREFIX = '27';

export function useInvoiceBookingSection({
  sectionNumber,
  propertyHrId,
  invoiceBookings,
  dispatch,
  laborCostTypeOptions,
  disabled,
  bookingDateOverwriteDisabled,
  invoiceDate,
  viewMode,
}: InvoiceBookingNS.InvoiceBookingSectionProps) {
  const { tl } = useContext(LanguageContext);
  const { allAccounts } = useAccountList({ propertyHrId });

  const administrationType = getAdministrationTypeFromPropertyHrIdForArticles(propertyHrId);

  const infoText = propertyHrId
    ? (
      <Link target="_blank" to={{ pathname: getChartOfAccountsArticleUrl(administrationType) }}>
        {tl(translations.invoiceEditing.sections.section3.subsection3.accountArticleLabel)}
      </Link>
    )
    : undefined;

  const bookableAccounts = allAccounts.data?.filter((account: Account) => !account.code.startsWith(COMPANY_ACCOUNT_PREFIX)
    && !account.code.startsWith(OWNER_UNIT_ACCOUNT_PREFIX)
      && !account.code.startsWith(TENANT_UNIT_ACCOUNT_PREFIX)
      && !account.code.startsWith(PROPERTY_BANK_ACCOUNT_PREFIX)
      && account.leaf);

  // Input definitions for repeatable part
  const generateSplitForm = (invoiceBooking: InvoiceBookingDto, idx: number) => {
    const { accountCode, bookingText } = invoiceBooking;
    return ({
      sectionId: `splitForm${idx}`,
      content: [[
        {
          key: `invoiceBookings[${idx}].accountCode`,
          component: AccountFilterComponent,
          props: {
            hideParents: true,
            propertyHrId,
            allAccounts: bookableAccounts,
            label: tl(translations.invoiceEditing.sections.section3.subsection3.fields.account) + (idx + 1),
            infoText,
            onChange: (value: any) => dispatch({
              type: 'update', key: 'accountCode', idx, value,
            }),
            value: accountCode,
            required: true,
            autoFocus: idx !== 0,
            disabled,
          },
        },
        // only add extra empty column if viewmode is fullscreen
        ...(viewMode === 'fullscreen' ? [{}] : []),
      ],
      [
        {
          type: 'text',
          minWidth: '20.0rem',
          key: `invoiceBookings[${idx}].bookingText`,
          props: {
            label: tl(translations.invoiceEditing.sections.section3.subsection3.fields.bookingText),
            value: bookingText,
            onChange: (value: any) => dispatch({
              type: 'update', key: 'bookingText', idx, value,
            }),
            precision: 2,
            disabled,
          },
        },
      ],
      [{
        component: NetAndVatAmount,
        props: {
          index: idx,
          disabled,
        },
      }],
      [
        {
          component: LaborCostRow,
          props: {
            index: idx,
            invoiceBooking,
            dispatch,
            laborCostTypeOptions,
            disabled,
          },
        },
      ],
      idx > 0 ? [
        {
          type: 'deleteButton',
          minWidth: '20.0rem',
          props: {
            dataKey: idx,
            nrKey: idx,
            disabled: idx === 0 || disabled,
          },
        },
      ] : null,
      idx === invoiceBookings.length - 1 ? [
        {
          type: 'addButton',
          minWidth: '20.0rem',
          props: {
            label: tl(translations.invoiceEditing.sections.section3.subsection3.fields.split),
            onClick: () => dispatch({ type: 'addSplit' }),
            disabled,
          },
        },
      ] : null,
      ],
    });
  };

  return {
    sectionNumber,
    openingNumber: sectionNumber,
    buttonLoading: false,
    sectionTitle: tl(translations.invoiceEditing.sections.section3.title),
    sectionId: 'CostAssignment',
    deleteFunction: (idx: number) => dispatch({ type: 'removeSplit', idx }),
    content: [
      {
        sectionId: 'totalNetAmount',
        content: (
          <>
            <div className="sub-section-content-row">
              <div className="sub-section-content-column" style={{ minWidth: '25.0rem' }}>
                <InvoiceTotalGrossAmount />
              </div>
              {viewMode === 'fullscreen' && <div className="sub-section-content-column" style={{ minWidth: '25.0rem' }} />}
            </div>
            <div className="sub-section-content-row">
              <div className="sub-section-content-column" style={{ minWidth: '25.0rem' }}>
                <ComponentVisibilityToggler
                  labelWhenClosed={tl(translations.invoiceEditing.sections.section3.subsection1.fields.overwriteBookingDate)}
                  labelWhenOpen={tl(translations.invoiceEditing.sections.section3.subsection1.fields.overwriteBookingDate)}
                  defaultIsVisible={invoiceBookings[0]?.overwriteBookingDate}
                  hidable={!invoiceBookings[0]?.overwriteBookingDate || (invoiceBookings[0]?.overwriteBookingDate && !disabled)}
                  onVisibilityChange={(value: any) => {
                    for (let index = 0; index < invoiceBookings.length; index += 1) {
                    // still needed for BE logic
                      dispatch({
                        type: 'update', key: 'overwriteBookingDate', idx: index, value,
                      });
                      if (!value) {
                        dispatch({
                          type: 'update', key: 'bookingDate', idx: index, value: invoiceDate,
                        });
                      }
                    }
                  }}
                >
                  <DateInput
                    label={tl(translations.invoiceEditing.sections.section3.subsection1.fields.bookingDate)}
                    value={moment(invoiceBookings[0]?.bookingDate)}
                    onChange={(value: any) => {
                      for (let index = 0; index < invoiceBookings.length; index += 1) {
                        dispatch({
                          type: 'update', key: 'bookingDate', idx: index, value,
                        });
                      }
                    }}
                    disabled={bookingDateOverwriteDisabled}
                  />
                </ComponentVisibilityToggler>
              </div>
              <div className="sub-section-content-column" style={{ minWidth: '25.0rem' }} />
            </div>
          </>),
      },
      ...invoiceBookings.map((invBooking: InvoiceBookingDto, idx: number) => generateSplitForm(invBooking, idx)),
      {
        sectionId: 'summaryInfoBar',
        content: <InvoiceBookingSummaryInfoBar />,
      },
    ],
  };
}
