import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import TableSummaryTotalAmount from 'elements/TableSummaryTotalAmount/TableSummaryTotalAmount';
import { formatToEuro } from 'lib/Utils';
import { translations } from '../../translations';
import { useHouseMoneySettlementTableSummary } from '../../services/useHouseMoneySettlementTableSummary';


const TableSummary = () => {
  const {
    totalAmount,
  } = useHouseMoneySettlementTableSummary();
  const { tl } = useContext(LanguageContext);


  return (
    TableSummaryTotalAmount({
      amountLabel: tl(translations.creationPage.sections.section2.totalAmountLabel),
      totalAmount: formatToEuro(totalAmount),
      amountCellColSpan: 4,
    })
  );
};


export default TableSummary;
