import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router';
import moment from 'moment';
import { SelectOption } from '../../../../../elements/Inputs/SelectInput/SelectInput';
import { GlobalSearchFilterProps } from './useGlobalSearch';
import { BreadcrumbInterface } from '../components/Breadcrumbs/Breadcrumbs';
import { useFilters } from './services/useFilters';
import { useBreadcrumbs } from './services/useBreadcrumbs';
import useNavigationItems, { NavItem } from './services/useNavigationItems';
import { usePropertySuggestions } from './services/usePropertySuggestions';
import { DefaultDataInterface } from '../../../../../lib/data';
import { PropertySimpleDto } from '../../../../../api/accounting/models';

export const GlobalSearchContext = React.createContext<{
  registerPage:(key: string, filterProps?: GlobalSearchFilterProps) => any;
  deregisterPage: (key: string) => any;
  onSearchValueChanged: any;
  suggestedFilters: SuggestedFilter[];
  searchSuggestions: SearchSuggestion[];
  getPage: (key: string) => Page;
  pages: Page[];
  breadcrumbs: BreadcrumbInterface[];
  addBreadcrumb: (breadcrumb: BreadcrumbInterface) => void;
  replaceBreadcrumb: (index: number, breadcrumb: BreadcrumbInterface) => void;
  removeBreadcrumb: (predicate: (breadcrumb: BreadcrumbInterface) => boolean) => void;
  loadPropertyList: (filter: string) => void;
  propertyList: DefaultDataInterface<PropertySimpleDto[]>;
  setPropertyList: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertySimpleDto[]>>>;
  selectedProperty: PropertySimpleDto | null;
  setSelectedProperty: React.Dispatch<React.SetStateAction<PropertySimpleDto | null>>;
  currentPage: string | null;
  setSelectedPropertyByIdAndNavigate: (propertyId: number) => void;
  setSelectedPropertyHrId: React.Dispatch<React.SetStateAction<string | null>>;
  setNavigationItemFilter: React.Dispatch<React.SetStateAction<string>>;
  currentlyEditedFilter: SuggestedFilter | null;
  setCurrentlyEditedFilter: React.Dispatch<React.SetStateAction<SuggestedFilter | null>>;
  dropdownVisible: boolean,
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  filterDropdownVisible: boolean;
  setFilterDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentlyEditedBreadcrumb: SuggestedFilter | null,
  setCurrentlyEditedBreadcrumb: React.Dispatch<React.SetStateAction<SuggestedFilter | null>>;
  selectedSuggestionIndex: number,
  setSelectedSuggestionIndex: React.Dispatch<React.SetStateAction<number>>;
  hideGlobalSearch: boolean;
  setHideGlobalSearch: React.Dispatch<React.SetStateAction<boolean>>;
  updateAvailableFilters: (key: string, filterProps?: GlobalSearchFilterProps) => void;
    } | undefined>(undefined);


export interface Filter {
  type?: FilterValueType['type']
  matchingValue?: FilterValueType['matchingValue']
  name?: string
  key?: string
  multiValue?: boolean
  matcher?: RegExp // suggest this filter if the entered value matches
  options?: SelectOption[]
  trueLabel?: string
  falseLabel?: string
  maxDate?: moment.Moment,
  minDate?: moment.Moment,
  // when you want the filter to be visible and clearable but not selectable by user from the dropdown
  visibleInSearchDropdown?: boolean,
  // when you want to show all options for a multi-value filter
  visibleAllOptions?: boolean,
}

export type FilterValueType = {
  type: 'text', matchingValue: string
} | {
  type: 'number', matchingValue: number
} | {
  type: 'date', matchingValue: moment.Moment | string
} | {
  type: 'enum', matchingValue: string | string[] | number | number[]
} | {
  type: 'boolean', matchingValue: boolean
} | {
  type: 'decimal', matchingValue: number
}

export interface SearchSuggestion {
  value: string,
  filterProps?: SuggestedFilter,
  propertyId?: number,
  navigationPath?: string,
  absolutePath?: boolean,
  type: 'filter' | 'navigationItem' | 'property'
}

export interface SuggestedFilter extends Filter {
  pageKey?: string
  translatedValue?: string
}

export interface Page {
  key: string,
  filterProps?: GlobalSearchFilterProps
}

export const GlobalSearchContextProvider: React.FC = ({ children }) => {
  const [pages, setPages] = useState<Array<Page>>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(0);
  const location = useLocation();
  const [hideGlobalSearch, setHideGlobalSearch] = useState(false);

  const {
    breadcrumbs, setBreadcrumbs, addBreadcrumb, addDefaultBreadcrumbs, removeBreadcrumb, replaceBreadcrumb,
  } = useBreadcrumbs();
  // Suggested filter values
  const {
    suggestedFilters, setSuggestedFilters, updateSuggestedFilters, currentlyEditedFilter, setCurrentlyEditedFilter,
  } = useFilters(pages, breadcrumbs);
  // Suggested property values
  const {
    propertyList, setPropertyList, selectedProperty, setSelectedProperty, setSelectedPropertyHrId, setSelectedPropertyByIdAndNavigate, loadPropertyList,
  } = usePropertySuggestions();
  // Suggested navigation items
  const { filteredNavigationItems, setNavigationItemFilter } = useNavigationItems({ currentPage: location.pathname });
  // All suggestions
  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestion[]>([]);
  const [currentlyEditedBreadcrumb, setCurrentlyEditedBreadcrumb] = useState<SuggestedFilter | null>(null);

  const currentPage = useMemo(() => (pages.slice(-1)[0] ? pages.slice(-1)[0].key : null), [pages]);

  const [pageKeys, setPageKeys] = useState<string[]>([]);
  useEffect(() => {
    const newKeys = pages.map(p => p.key);
    // update only if they changed, in order to not trigger the next useEffect wrongly
    if (!_.isEqual(_.sortBy(newKeys), _.sortBy(pageKeys))) {
      setPageKeys(newKeys);
    }
  }, [pages]);

  useEffect(() => {
    updateSuggestedFilters('');
    setBreadcrumbs((currentBreadcrumbs: BreadcrumbInterface[]) => _.cloneDeep(currentBreadcrumbs)
      .filter((breadcrumb: BreadcrumbInterface) => breadcrumb.breadcrumbType !== 'FILTER'));
  }, [pageKeys]);

  useEffect(() => {
    if (!filterDropdownVisible) {
      let newSuggestions: SearchSuggestion[] = [];
      propertyList.data!.forEach((prp) => {
        newSuggestions.push({
          value: `WA${prp.propertyIdInternal} - ${prp.name}`,
          propertyId: prp.id,
          type: 'property',
        });
      });
      newSuggestions = newSuggestions.concat(suggestedFilters.map(filter => ({
        value: `${filter.name}`,
        filterProps: filter,
        type: 'filter',
      })));
      newSuggestions = newSuggestions.concat(filteredNavigationItems.map((navigationItem: NavItem) => ({
        value: navigationItem.name,
        absolutePath: navigationItem.absolute,
        navigationPath: navigationItem.path,
        type: 'navigationItem',
      })));

      // show only the first 10 suggestion
      setSearchSuggestions(newSuggestions);
    }
  }, [suggestedFilters, propertyList, filteredNavigationItems]);

  useEffect(() => {
    if (filterDropdownVisible) {
      let newSuggestions: SearchSuggestion[] = [];
      newSuggestions = newSuggestions.concat(suggestedFilters.filter((f: SuggestedFilter) => f.name === currentlyEditedBreadcrumb?.name && f.type === 'enum').map(filter => ({
        value: `${filter.name}`,
        filterProps: filter,
        type: 'filter',
      })));

      // show only the first 10 suggestion
      setSearchSuggestions(newSuggestions);
    }
  }, [filterDropdownVisible, currentlyEditedBreadcrumb]);

  const updateAvailableFilters = (key: string, filterProps?: GlobalSearchFilterProps) => {
    setPages((prevValue) => {
      const newValue = prevValue.slice();
      const pageIdx = _.findIndex(newValue, pg => pg.key === key);
      if (pageIdx !== -1) {
        if (newValue[pageIdx].filterProps) newValue[pageIdx].filterProps!.availableFilters = filterProps?.availableFilters ?? [];
      }
      return newValue;
    });
  };

  const registerPage = (key: string, filterProps?: GlobalSearchFilterProps) => {
    setPages((prevValue) => {
      const newValue = prevValue.slice();
      newValue.push({
        key,
        filterProps,
      });
      return newValue;
    });
    if (filterProps && filterProps.defaultFilters) {
      addDefaultBreadcrumbs(filterProps);
    }
  };

  const deregisterPage = (key: string) => {
    setPages((prevValue) => {
      const newValue = prevValue.slice();
      const idx = prevValue.map(page => page.key).indexOf(key);
      if (idx < 0) {
        throw Error(`No page with key ${key}`);
      }
      newValue.splice(idx, 1);
      return newValue;
    });
    setSuggestedFilters((prevValue) => {
      let newValue = prevValue.slice();
      newValue = newValue.filter((filter: SuggestedFilter) => filter.pageKey !== key);
      return newValue;
    });
    // if dashboard is deregistered, clear property list
    if (key === 'dashboard') {
      setPropertyList((prevState: DefaultDataInterface<PropertySimpleDto[]>) => prevState.load([]));
    }
  };

  const onSearchValueChanged = useCallback((value: string) => {
    // property search enabled only on Dashboard
    if (pages.length > 0 && pages[pages.length - 1].key === 'dashboard') {
      if (value !== '') loadPropertyList(value);
      setNavigationItemFilter(value);
    } else {
      setNavigationItemFilter(value);
      updateSuggestedFilters(value);
    }
  }, [pages, breadcrumbs]);

  const getPage = (key: string) => {
    const filteredPages = pages.filter(page => page.key === key);
    return filteredPages.length !== 0 ? filteredPages[0] : null;
  };

  const breadcrumbsWithClassName = useMemo(() => {
    const lastLinkIndex = breadcrumbs.map(breadcrumb => breadcrumb.breadcrumbType)
      .lastIndexOf('LINK');

    return breadcrumbs.map((breadcrumb: BreadcrumbInterface, index: number) => {
      const breadcrumbWithClassName = _.cloneDeep(breadcrumb);
      if (index >= lastLinkIndex) {
        breadcrumbWithClassName.className += ' bold';
      }
      return breadcrumbWithClassName;
    });
  }, [breadcrumbs]);


  return (
    <GlobalSearchContext.Provider
      value={{
        registerPage,
        deregisterPage,
        onSearchValueChanged,
        suggestedFilters,
        searchSuggestions,
        getPage,
        pages,
        breadcrumbs: breadcrumbsWithClassName,
        addBreadcrumb,
        replaceBreadcrumb,
        removeBreadcrumb,
        loadPropertyList,
        propertyList,
        setPropertyList,
        selectedProperty,
        setSelectedProperty,
        currentPage,
        setSelectedPropertyByIdAndNavigate,
        setSelectedPropertyHrId,
        setNavigationItemFilter,
        currentlyEditedFilter,
        setCurrentlyEditedFilter,
        dropdownVisible,
        setDropdownVisible,
        filterDropdownVisible,
        setFilterDropdownVisible,
        currentlyEditedBreadcrumb,
        setCurrentlyEditedBreadcrumb,
        selectedSuggestionIndex,
        setSelectedSuggestionIndex,
        hideGlobalSearch,
        setHideGlobalSearch,
        updateAvailableFilters,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
};
