import { ContractProjectionDto, PropertyLegacyDto } from 'api/accounting';
import React, {
  ReactNode, createContext, useContext, useState,
} from 'react';

const SevOwnerContext = createContext<|
  {
    sevOwnerContract: ContractProjectionDto,
    setSevOwnerContract: React.Dispatch<React.SetStateAction<ContractProjectionDto>>,
    sevOwnerProperty: PropertyLegacyDto,
    setSevOwnerProperty: React.Dispatch<React.SetStateAction<PropertyLegacyDto>>,
  } | undefined>(undefined);

export const useSevOwnerContext = (usageName: string) => {
  const sevEditingContext = useContext(SevOwnerContext);

  if (sevEditingContext === undefined) {
    throw new Error(`${usageName} must be used within an SevOwnerContextProvider`);
  }

  return sevEditingContext;
};


const SevOwnerContextProvider = ({ children }: { children: ReactNode }) => {
  const [sevOwnerContract, setSevOwnerContract] = useState<ContractProjectionDto>(undefined);
  const [sevOwnerProperty, setSevOwnerProperty] = useState<PropertyLegacyDto>(undefined);

  return (
    <SevOwnerContext.Provider value={{
      sevOwnerContract, sevOwnerProperty, setSevOwnerContract, setSevOwnerProperty,
    }}
    >
      {children}
    </SevOwnerContext.Provider>
  );
};

export default SevOwnerContextProvider;
