import Icon from '@ant-design/icons';
import { Alert } from 'antd';
import { ICONS } from 'components/icons';
import { OpsCostSectionKeys } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportHiddenAmounts/interfacesOpsCostReportHiddenAmounts';
import { useOpsCostReportNumberOfInvalidFieldsForContractAndSection } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';

import { opsCostTranslation } from 'pages/OpsCostReport/translations';

export interface InvalidManualAmountsAlertProps {
    tl:any,
    contractId:number,
    section:OpsCostSectionKeys,
  }


export const InvalidManualAmountsAlert = ({
  tl, contractId, section,
} : InvalidManualAmountsAlertProps) => {
  const numberOfInvalidFields = useOpsCostReportNumberOfInvalidFieldsForContractAndSection(contractId, section);

  if (numberOfInvalidFields === 0 || numberOfInvalidFields === undefined) {
    return <></>;
  }


  return (
    <Alert
      message={tl(opsCostTranslation.invalidFields)}
      type="error"
      showIcon
      icon={<Icon component={ICONS.error} />}
    />
  );
};
