import { useContext, useMemo } from 'react';
import { useAnalyticsMenuItems } from 'pages/Analytics/useAnalyticsMenuItems';
import { AuthContext } from 'contexts/AuthContext';
import { OPS_COST_REPORTS_PATH } from 'pages/OpsCostReport/routes';
import { ORDER_OPEN_BALANCES_NEW_PATH } from 'pages/OrderOpenBalances/routes';
import { DIRECT_DEBIT_LIST_URL } from 'pages/DirectDebitList/routes';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from './translations';
import { BurgerMenuItem, SiteMap, SubcategorySwitcherItems } from './interfaces';

export default function useSiteMap(): SiteMap {
  const { tl, language } = useContext(LanguageContext);
  const { onLogout } = useContext(AuthContext);
  const analyticsMenuData = useAnalyticsMenuItems();

  const burgerMenuItems: BurgerMenuItem[] = useMemo(() => {
    const items: BurgerMenuItem[] = [
      {
        key: 'propertyData',
        title: tl(translations.propertyData.title),
        subMenuPoints: [
          {
            key: 'property',
            title: tl(translations.propertyData.subMenuPoints.property.title),
            navItems: [
              {
                key: 'propertyOverview',
                url: '/properties',
                title: tl(translations.propertyData.subMenuPoints.property.navItems.propertyOverview),
              },
            ],
          },
        ],
      },
      {
        key: 'accounting',
        title: tl(translations.accounting.title),
        subMenuPoints: [
          {
            key: 'bookings',
            title: tl(translations.accounting.subMenuPoints.bookings.title),
            navItems: [
              {
                key: 'invoices',
                title: tl(translations.accounting.subMenuPoints.bookings.navItems.invoices),
                url: '/invoice',
              },
              {
                key: 'bankTransactions',
                title: tl(translations.accounting.subMenuPoints.bookings.navItems.bankTransactions),
                url: '/bank-transactions',
              },
              {
                key: 'standingOrders',
                title: tl(translations.accounting.subMenuPoints.bookings.navItems.standingOrders),
                url: '/standing-orders',
              },
              {
                key: 'manualExchanges',
                title: tl(translations.accounting.subMenuPoints.bookings.navItems.manualExchange),
                url: '/exchange/create',
              },
            ],
          },
          {
            key: 'accounts',
            title: tl(translations.accounting.subMenuPoints.accounts.title),
            navItems: [
              {
                key: 'accountBalances',
                title: tl(translations.accounting.subMenuPoints.accounts.navItems.accountBalances),
                url: '/account-balances',
              },
              {
                key: 'distributionKeys',
                title: tl(translations.accounting.subMenuPoints.accounts.navItems.distributionKeys),
                url: '/distribution-keys',
              },
            ],
          },
          {
            key: 'planningAndReporting',
            title: tl(translations.accounting.subMenuPoints.planningAndReporting.title),
            navItems: [
              {
                key: 'orderOpenBalances',
                title: tl(translations.accounting.subMenuPoints.accounts.navItems.orderOpenBalances),
                url: ORDER_OPEN_BALANCES_NEW_PATH,
              },
              {
                key: 'houseMoneySettlement',
                title: tl(translations.accounting.subMenuPoints.planningAndReporting.navItems.houseMoneySettlement),
                url: '/house-money-settlement',
              },
              {
                key: 'economicPlan',
                title: tl(translations.accounting.subMenuPoints.planningAndReporting.navItems.economicPlan),
                url: '/economic-plan',
              },
              {
                key: 'specialContribution',
                title: tl(translations.accounting.subMenuPoints.planningAndReporting.navItems.specialContribution),
                url: '/special-contribution',
              },
              {
                key: 'wka',
                title: tl(translations.accounting.subMenuPoints.planningAndReporting.navItems.wka),
                url: '/wka',
              },
              {
                key: 'profitAndLossList',
                title: tl(translations.accounting.subMenuPoints.planningAndReporting.navItems.profitAndLoss),
                url: '/profit-and-loss',
              },
            ],
          },
          {
            key: 'payment',
            title: tl(translations.accounting.subMenuPoints.payment.title),
            navItems: [
              {
                key: 'bankAccounts',
                title: tl(translations.accounting.subMenuPoints.payment.navItems.bankAccounts),
                url: '/bank-account',
              },
              {
                key: 'paymentRun',
                title: tl(translations.accounting.subMenuPoints.payment.navItems.paymentRun),
                url: '/payments',
              },
              {
                key: 'directDebits',
                title: tl(translations.accounting.subMenuPoints.payment.navItems.directDebits),
                url: DIRECT_DEBIT_LIST_URL,
              },
              {
                key: 'transactionImports',
                title: tl(translations.accounting.subMenuPoints.payment.navItems.transactionImports),
                url: '/transaction-imports/manual',
              },
            ],
          },
        ],
      },
      {
        key: 'meetings',
        title: tl(translations.meetings.title),
        subMenuPoints: [
          {
            key: 'ownersMeeting',
            title: tl(translations.meetings.subMenuPoints.ownersMeeting.title),
            navItems: [
              {
                key: 'ownersMeetingInvitations',
                title: tl(translations.meetings.subMenuPoints.ownersMeeting.navItems.ownersMeetingInvitations),
                url: '/owners-meeting/invitation',
              },
              // hidden by PMP-10218
              {
                key: 'ownersMeetingProtocol',
                title: tl(translations.meetings.subMenuPoints.ownersMeeting.navItems.ownersMeetingProtocol),
                url: '/owners-meeting/protocol',
              },
            ],
          },
          {
            key: 'resolutionRecord',
            title: tl(translations.meetings.subMenuPoints.resolutionRecord.title),
            navItems: [
              {
                key: 'resolutionRecordOverview',
                title: tl(translations.meetings.subMenuPoints.resolutionRecord.navItems.resolutionRecordOverview),
                url: '/resolution-records',
              },
            ],
          },
        ],
      },
      {
        key: 'contacts',
        title: tl(translations.contacts.title),
        subMenuPoints: [
          {
            key: 'contacts',
            title: tl(translations.contacts.subMenuPoints.contacts.title),
            navItems: [
              {
                key: 'contactPerson',
                title: tl(translations.contacts.subMenuPoints.contacts.navItems.contactPerson),
                url: '/persons',
              },
              {
                key: 'contactCompany',
                title: tl(translations.contacts.subMenuPoints.contacts.navItems.contactCompany),
                url: '/companies',
              },
            ],
          },
        ],
      },
      {
        key: 'communication',
        title: tl(translations.communication.title),
        subMenuPoints: [{
          key: 'letters',
          title: tl(translations.communication.submenuPoints.letters.title),
          navItems: [
            {
              key: 'serial-letters',
              title: tl(translations.communication.submenuPoints.letters.navItems.serialLetters),
              url: '/letters',
            },
          ],
        },
        {
          key: 'messages',
          title: tl(translations.communication.submenuPoints.messages.title),
          navItems: [
            {
              key: 'messages-overview',
              title: tl(translations.communication.submenuPoints.messages.title),
              url: '/messages',
            },
          ],
        },
        ],
      },
      {
        key: 'settings',
        title: tl(translations.settings.title),
        subMenuPoints: [{
          key: 'settings',
          title: tl(translations.settings.title),
          navItems: [
            {
              key: 'watermarks',
              title: tl(translations.settings.subMenuPoints.watermarks.navItems.settings),
              url: '/watermarks',
            },
            {
              key: 'users',
              title: tl(translations.contacts.subMenuPoints.contacts.navItems.users),
              url: '/users',
            },
          ],
        }],
      },
      {
        key: 'documents',
        title: tl(translations.documents.title),
        subMenuPoints: [
          {
            key: 'documents',
            title: tl(translations.documents.title),
            navItems: [
              {
                key: 'documents-overview',
                url: '/documents',
                title: tl(translations.documents.subMenuPoints.navItems),
              },
            ],
          },
        ],
      },
      {
        key: 'logout',
        title: tl(translations.logout.title),
        action: () => {
          onLogout();
        },
        subMenuPoints: [],
      },
    ];
    if (analyticsMenuData) {
      items.splice(5, 0,
        analyticsMenuData);
    }
    items[1].subMenuPoints[2]?.navItems?.push({
      key: 'opsCostReport',
      title: tl(translations.accounting.subMenuPoints.planningAndReporting.navItems.opsCostReport),
      url: OPS_COST_REPORTS_PATH,
    });
    return items;
  }, [language, analyticsMenuData]);


  const subcategorySwitcherItems: SubcategorySwitcherItems = useMemo(() => burgerMenuItems
    .reduce<SubcategorySwitcherItems>((obj, burgerItem) => {
      obj[burgerItem.key] = burgerItem.subMenuPoints.flatMap(subMenuPoint => subMenuPoint.navItems);
      return obj;
    }, {}), [burgerMenuItems]);

  return {
    burgerMenuItems,
    subcategorySwitcherItems,
  };
}
