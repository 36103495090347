import { useContext } from 'react';
import { ICONS } from 'components/icons';
import { translations } from '../../../../../elements/Translation/translations';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import defaultSection from './defaultSection';

export const useInvoicesSection = (counterpartContactId?: string) => {
  const { tl } = useContext(LanguageContext);

  const onNavigateToInvoiceList = () => {
    window.open(`/invoice?counterpartContactId=${counterpartContactId}`);
  };
  return {
    ...defaultSection(5),
    sectionIcon: ICONS.invoicesSection,
    sectionTitle: tl(translations.pages.contactDetails.invoicesSection.title),
    sectionId: 'InvoiceSectionsd',
    onChange: () => {
    },
    open: false,
    onHoverDisplayButton: true,
    hoverButtonFunction: onNavigateToInvoiceList,
    hoverButtonText: tl(translations.pages.contactDetails.invoicesSection.button),
    content: [],
    onClickHeader: onNavigateToInvoiceList,
  };
};
