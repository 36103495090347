import React from 'react';
import { useOpsCostPrintMode, useOtherOrTransferableCostsManualValuesTotals } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';
import ExpenseTableSummary, { ExpenseTableSummaryProps } from '../../components/ExpenseTableSummary';
import { ReturnTypeOfOtherOpsCostsHook } from '../OtherOpsCostOrTransferableCostsFromWegSection ';


interface OtherOrTransferableCostsTableSummaryProps extends Omit<ExpenseTableSummaryProps, 'totalSummaryData'> {
  contractId: number;
  getTotalIncludingManualAmounts: ReturnTypeOfOtherOpsCostsHook['getTotalIncludingManualAmounts']
}


const OtherOrTransferableCostsTableSummary = (props: OtherOrTransferableCostsTableSummaryProps) => {
  const { getTotalIncludingManualAmounts, contractId, ...restProps } = props;

  /* if we move this dependency any higher up in the tree, then the entire table will re-render on every keystroke */
  const summaryFromManualAmounts = useOtherOrTransferableCostsManualValuesTotals(contractId);
  const totalSummaryDataWithManualAmounts = getTotalIncludingManualAmounts(summaryFromManualAmounts);
  const printMode = useOpsCostPrintMode();

  return (
    <ExpenseTableSummary
      {...restProps}
      totalSummaryData={totalSummaryDataWithManualAmounts}
      addPlaceholderLastColumn={!printMode}
    />
  );
};

export default OtherOrTransferableCostsTableSummary;
