import Amount from 'storybook-components/typography/Amount/Amount';
import CellWithTwoAmounts from 'storybook-components/table/CellWithTwoAmounts/CellWithTwoAmounts';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { formatCurrency } from 'lib/Utils';
import { getOpenBalanceAmountColor } from 'lib/utilGetOpenBalanceAmountColor';
import { useOpenAmountCell } from './useOpenAmountCell';
import './OpenAmountCell.scss';


interface OpenAmountCellProps {
  todaysBalance: number;
  filteredBalance: number;
  disabled?: boolean;
}


const OpenAmountCell = ({ todaysBalance, filteredBalance, disabled }: OpenAmountCellProps) => {
  const { tl } = useContext(LanguageContext);
  const { postDateFilterInactive } = useOpenAmountCell();
  const sign = todaysBalance > 0 ? '+ ' : '';
  const color = disabled ? 'gray' : getOpenBalanceAmountColor(todaysBalance);

  if (postDateFilterInactive) {
    return (
      <div className="OpenAmountCellSingleAmount">
        <Amount color={color}>
          {`${sign}${formatCurrency(todaysBalance)}`}
        </Amount>
      </div>
    );
  }


  return (
    <CellWithTwoAmounts
      title={tl(orderOpenBalancesTranslations.openBalanceTitle)}
      firstAmount={{ amount: filteredBalance, color, showSignForPositiveAmount: true }}
      secondAmount={postDateFilterInactive ? undefined : ({ amount: todaysBalance, showSignForPositiveAmount: true })}
      disabled={disabled}
    />
  );
};

OpenAmountCell.defaultProps = {
  disabled: false,
};

export default OpenAmountCell;
