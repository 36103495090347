import { useContext } from 'react';
import { AmountToDistributeInputValuesContext } from './ManualExchangeContext';


export const useDistributionAmountValues = () => {
  const distributionValues = useContext(AmountToDistributeInputValuesContext);

  const totalAmount = distributionValues?.reduce((acc, curr) => acc + (curr.selected ? (curr.amount ?? 0) : 0), 0) ?? 0;

  return { totalAmount, distributionValues };
};
