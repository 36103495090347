import { UnitLegacyControllerApi, UnitProjectionDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useRef } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import DEFAULT_DATA from 'lib/data';
import { UnitsListContext } from './UnitsListContext';
import { translations } from './translations';


const DEFAULT_EMPTY_LOADING = DEFAULT_DATA<UnitProjectionDto[]>([]).startLoading();

type Props = {
  propertyId: number,
  isOwnedByWeg?: boolean
}


export const useUnitsList = ({ propertyId, isOwnedByWeg }: Props) => {
  const unitsListContext = useContext(UnitsListContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const unitsControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  if (unitsListContext === undefined) {
    throw new Error('useUnitsList must be used within a UnitsListContextProvider');
  }

  const {
    setUnitsList,
    unitsList,
    paramOfCachedValue,
    setParamOfCachedValue,
  } = unitsListContext;


  // isOwnedByWeg === false and isOwnedByWeg === undefined are not the same thing
  // isOwnedByWeg === undefined means all units and isOwnedByWeg === false means units not onwed by weg

  const isCacheValid = paramOfCachedValue?.propertyId === propertyId && paramOfCachedValue?.isOwnedByWeg === isOwnedByWeg;

  useEffect(() => {
    if (propertyId === undefined) return;

    if (!isCacheValid) {
      fetchUnitsList();
    }
    // all these dependencies are necessary because `isCacheValid` might stay as `false` after
    // some of it's dependencies (propertyId, isOwnedByWeg) changed but we still need to trigger the useEffect
  }, [paramOfCachedValue, propertyId, isOwnedByWeg]);


  const fetchUnitsList = () => {
    if (propertyId === undefined) {
      throw new Error('Tried to fetch units list with undefined parameter(s)');
    }

    // if params changed since last initiated fetch then abort the in-progress fetch
    abortController.current?.abort();
    // create new abort controllers
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setUnitsList(prev => prev.startLoading());

    unitsControllerApi.getSimpleUnitsUsingGET({ propertyId, isOwnedByWeg }, { signal })
      .then((resp) => {
        setUnitsList(prev => prev.load(resp));
        setParamOfCachedValue({ propertyId, isOwnedByWeg });
      })
      .catch((err) => {
        if (signal.aborted) return;
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.loadUnitsError),
        });
        setUnitsList(prev => prev.failed(err));
      });
  };


  return {
    unitsList: isCacheValid || unitsList.error
      ? unitsList
      : DEFAULT_EMPTY_LOADING,
  };
};
