import { useContext, useEffect, useState } from 'react';

import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';

import DEFAULT_DATA from 'lib/data';
import { PropertyLegacyDtoAdministrationTypeEnum, UnitContractControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import {
  useConnections,
} from '../../../../PropertyPortalImport/service/useConnections';
import useDefaultSection from '../defaultSection';
import { InvoiceIntegrations } from './components/InvoiceIntegrations';
import { translations } from './translations';

export const useIntegrationIsSupported = ():boolean => {
  const { connections } = useConnections();

  const faciliooAppId = 1;
  const casaviId = 5;
  const etg24Id = 6;
  const supportedAppIds = [casaviId, faciliooAppId, etg24Id];
  const integrationIsSupported = connections
    .filter(conn => supportedAppIds.indexOf(conn.appId) !== -1)
    .length > 0;
  return connections && integrationIsSupported;
};


export const useInvoiceIntegrationsSection = (
  propertyId: number,
  propertyAdministrationType: PropertyLegacyDtoAdministrationTypeEnum,
) => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);

  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const [connectedWegId, setConnectedWegId] = useState(DEFAULT_DATA<number>(undefined));

  const isSevProperty = propertyAdministrationType === PropertyLegacyDtoAdministrationTypeEnum.SEV;

  useEffect(() => {
    if (isSevProperty) {
      // in case of SEV with WEG connection we shall show the integration items of the connected WEG
      setConnectedWegId(prev => prev.resetToInitial().startLoading());

      unitContractControllerApi.getUnitContractsUsingGET({ propertyId })
        .then((contractsOfSev) => {
          const idOfWegTenantContract = contractsOfSev.find(contract => contract.contractGroupId !== undefined)?.contractGroupId;

          if (idOfWegTenantContract === undefined) {
            setConnectedWegId(prev => prev.load(undefined));
          }

          unitContractControllerApi.getUnitContractsUsingGET({ unitContractIds: [idOfWegTenantContract] })
            .then((wegTenantContract) => {
              setConnectedWegId(prev => prev.load(wegTenantContract[0]?.propertyId));
            });
        })
        .catch(() => {
          console.error('Failed to get the WEG connected to this SEV.');
          setConnectedWegId(prev => prev.resetToInitial());
        });
    } else {
      setConnectedWegId(prev => prev.resetToInitial());
    }
  }, [propertyId, propertyAdministrationType]);


  return ({
    ...useDefaultSection(4),
    sectionTitle: tl(translations.integrations.title),
    sectionIcon: ICONS.link,
    hideSaveButton: true,
    content: [
      {
        sectionId: 'InvoiceIntegrationsSection',
        content: <InvoiceIntegrations propertyId={isSevProperty ? connectedWegId.data : propertyId} />,
      },
    ],
  });
};
