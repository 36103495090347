export const translations = {
  deleteModal: {
    title: {
      en: 'Delete bank account',
      de: 'Bankkonto löschen',
    },
    content: {
      en: 'By deleting the bank account the system will automatically remove it from contracts if it was used. If there are open direct debits or payments the system will NOT delete it. All standing orders assigned to this bank account will be stopped. Bank orders that were already generated by the system will not be deleted, therefore please check them before the next execution. Are you sure you want to delete it?',
      de: 'Durch das Löschen des Bankkontos wird dieses automatisch auch von Verträgen entfernt, für welche es genutzt wurde. Offene Lastschriften oder Zahlungen werden nicht gelöscht. Alle Daueraufträge, die mit diesem Bankkonto verbunden sind, werden gestoppt. Bereits durch das System generierte Bankaufträge werden nicht gelöscht, bitte prüfen Sie diese daher vor der nächsten Ausführung. Sind Sie sicher, dass Sie fortfahren möchten?',
    },
    okText: {
      en: 'Delete',
      de: 'Löschen',
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
  deleteSuccess: {
    en: 'Bank account deleted successfully.',
    de: 'Bankkonto wurde erfolgreich gelöscht.',
  },
  deleteError: {
    en: 'Failed to delete bank account.',
    de: 'Bankkonto konnte nicht gelöscht werden.',
  },
};
