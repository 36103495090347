import { ContactProjectionTypeEnum } from 'api/accounting';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { isEmpty } from 'lodash';
import { useAccountsAndContacts } from 'pages/BankTransactions/BankTransactionAllocation/services/AccountsAndContactsContext';
import React, { useContext, useMemo } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from '../../../../../../../translations';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import useCounterpartCell from './useCounterpartCell';

interface Props {
  record: ServiceCompanyAllocationValues,
}

const CounterpartCell = ({ record }: Props) => {
  const { tl } = useContext(LanguageContext);
  const { contacts } = useAccountsAndContacts();
  const { currentValue, onChange } = useCounterpartCell(record.key);
  const headerTranslations = translations.bankTransactionAllocation.serviceCompanyAllocation.headers;

  const selectOptions = useMemo(() => (contacts.data?.map(({
    id, firstName, lastName, companyName, type,
  }) => ({
    value: id,
    label: type === ContactProjectionTypeEnum.PERSON ? `${firstName} ${lastName}` : companyName!,
  })) || []), [contacts.data]);


  return (
    <SelectInput
      size="large"
      showSearch
      placeholder={tl(headerTranslations.counterpart)}
      value={currentValue}
      onChange={onChange}
      disabled={isEmpty(contacts.data) || contacts.loading || contacts.error}
      defaultValue={record.counterpartContactId}
      options={selectOptions}
      dropdownClassName="large-dropdown"
    />
  );
};

export default CounterpartCell;
