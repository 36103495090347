import { useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useRevertToDraftOption } from 'pages/EconomicPlan/components/StateChangeButtons/useRevertToDraftOption';
import { PropertyListContext } from '../../../../../contexts/PropertyListContext';
import { EconomicPlanContext } from '../../../services/EconomicPlanContext';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { economicPlanTranslations } from '../../../economicPlanTranslations';
import { getStateColor } from '../../../../../storybook-components/headers/utils';
import { EconomicPlanDtoStatusEnum } from '../../../../../api/accounting';
import { useCopyEconomicPlan } from '../../../services/useCopyEconomicPlan';
import { useDeleteEconomicPlan } from '../../../services/useDeleteEconomicPlan';
import { useSimpleDirtModal } from '../../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';


export const useEconomicPlanHouseMoneyHeader = () => {
  const { tl } = useContext(LanguageContext);
  const { economicPlanId } = useParams<{ economicPlanId: string }>();
  const {
    setEconomicPlan,
    economicPlan,
  } = useContext(EconomicPlanContext);

  const {
    clearDirty,
  } = useSimpleDirtModal();

  const {
    selectedProperty,
  } = useContext(PropertyListContext);

  const history = useHistory();

  const goBack = () => {
    setEconomicPlan(prevState => prevState.load(undefined));
    clearDirty();
    history.push(`/economic-plan/property/${selectedProperty?.data?.propertyHrId}`);
  };


  const economicPlanState = economicPlan.data?.status;

  const { copyEconomicPlan } = useCopyEconomicPlan();
  const { deleteEconomicPlan } = useDeleteEconomicPlan();
  const revertToDraftOption = useRevertToDraftOption();

  const dropMenuOptionList = [];

  if (economicPlan.data?.id) {
    dropMenuOptionList.push({
      label: tl(economicPlanTranslations.actions.copy),
      onClick: () => {
        copyEconomicPlan(economicPlan.data?.id);
      },
    });
  }

  if (economicPlanState === EconomicPlanDtoStatusEnum.DRAFT) {
    dropMenuOptionList.push({
      label: tl(economicPlanTranslations.actions.delete),
      onClick: () => {
        deleteEconomicPlan(economicPlan.data?.id);
      },
    });
  }

  if ([EconomicPlanDtoStatusEnum.PREPARED, EconomicPlanDtoStatusEnum.DECIDED].includes(economicPlanState)) {
    dropMenuOptionList.push(revertToDraftOption);
  }

  return {
    dropMenuOptionList,
    stateName: economicPlanState ? tl(economicPlanTranslations.economicPlanList.status[economicPlanState]) : '',
    goBack,
    stateColor: getStateColor(economicPlanState),
    isCreationFlow: !economicPlanId,
    property: selectedProperty.data,
    title: economicPlan.data?.title,
  };
};
