import React, { useContext } from 'react';
import _ from 'lodash';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { economicPlanAccountEditingTranslations } from '../../economicPlanAccountEditingTranslations';
import defaultSection from '../defaultSection';
import EconomicPlanAmountsTable from './EconomicPlanAmountsTable/EconomicPlanAmountsTable';
import EconomicPlanTotalTable from './EconomicPlanTotalTable/EconomicPlanTotalTable';
import { ECONOMIC_PLAN_ACCOUNT_TYPES } from '../../../services/ECONOMIC_PLAN_ACCOUNT_TYPES';
import { useSaveAccountAmounts } from '../../services/useSaveAccountAmounts';

interface useEditAmountsSection2Props {
  addDirt: Function,
  setEconomicPlan: (number: any) => void,
  economicPlanCreationData: any,
  economicPlan: any,
}


export default function useEditAmountsSection2({
  addDirt, setEconomicPlan, economicPlanCreationData, economicPlan,
}: useEditAmountsSection2Props) {
  const { tl } = useContext(LanguageContext);
  const { saveAccountAmounts } = useSaveAccountAmounts();

  const updateAccountAmount = (accountCode: string, value: number, accountTypes: ECONOMIC_PLAN_ACCOUNT_TYPES[]) => {
    if (accountTypes.length === 1) {
      setEconomicPlan((ep: any) => {
        const newEconomicPlan = { ...ep.data };
        newEconomicPlan[accountTypes[0]][accountCode] = value;

        return ep.load(newEconomicPlan);
      });
    }

    const accountType = accountTypes[0];

    if (!_.isEmpty(economicPlan.data[accountType]) && economicPlan.data[accountType][accountCode] !== undefined) {
      setEconomicPlan((ep: any) => {
        const newEconomicPlan = { ...ep.data };
        newEconomicPlan[accountType][accountCode] = value;


        return ep.load(newEconomicPlan);
      });
    } else {
      return updateAccountAmount(accountCode, value, accountTypes.slice(1));
    }
  };

  const onValueChange = (accountCode: string, value: number, accountTypes: ECONOMIC_PLAN_ACCOUNT_TYPES[]) => {
    addDirt();
    return updateAccountAmount(accountCode, value, accountTypes);
  };

  return {
    ...defaultSection({
      economicPlan, economicPlanCreationData, index: 1, openingIndex: 1,
    }),
    sectionTitle: tl(economicPlanAccountEditingTranslations.sections.editAmounts.title),
    sectionId: 'editAmounts',
    onSubmit: saveAccountAmounts,
    content: [
      {
        sectionId: 'applicableCosts',
        title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.applicableCosts.title),
        content: <EconomicPlanAmountsTable
          id="applicableExpenses"
          loading={economicPlanCreationData.loading}
          dataSource={economicPlanCreationData.data?.applicableExpenses || []}
          onChange={onValueChange}
          accountTypes={[ECONOMIC_PLAN_ACCOUNT_TYPES.applicableExpenses, ECONOMIC_PLAN_ACCOUNT_TYPES.notApplicableExpenses]}
          economicPlan={economicPlan}
        />,
      },
      {
        sectionId: 'notApplicableCosts',
        title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.notApplicableCosts.title),
        content: <EconomicPlanAmountsTable
          id="notApplicableCosts"
          loading={economicPlanCreationData.loading}
          dataSource={economicPlanCreationData.data?.notApplicableExpenses || []}
          onChange={onValueChange}
          accountTypes={[ECONOMIC_PLAN_ACCOUNT_TYPES.applicableExpenses, ECONOMIC_PLAN_ACCOUNT_TYPES.notApplicableExpenses]}
          economicPlan={economicPlan}
        />,
      },
      {
        sectionId: 'income',
        title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.income.title),
        content: <EconomicPlanAmountsTable
          id="income"
          loading={economicPlanCreationData.loading}
          dataSource={economicPlanCreationData.data?.income || []}
          onChange={onValueChange}
          accountTypes={[ECONOMIC_PLAN_ACCOUNT_TYPES.income]}
          economicPlan={economicPlan}
        />,
      },
      {
        sectionId: 'totals',
        title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.totals.title),
        content: <EconomicPlanTotalTable economicPlan={economicPlan} />,
      },
    ],
  };
}
