import { Divider, Modal } from 'antd';
import { EconomicPlanDto, EconomicPlanDtoStatusEnum } from 'api/accounting/models';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { useContext } from 'react';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { useEconomicPlanActivationModal } from './useEconomicPlanActivationModal';
import { useSendEconomicPlan } from './useSendEconomicPlan';
import { useStateChangeButtons } from './useStateChangeButtons';

export interface StateChangeButtonsProps {
  saveBeforeMarkingPrepared?: () => Promise<EconomicPlanDto>;
  showSend?: boolean,
  messagesExist?: boolean,
  messagesGenerating?: boolean,
  documentCreationFailed?: boolean
}

export default function StateChangeButtons({
  saveBeforeMarkingPrepared, showSend, messagesExist, messagesGenerating, documentCreationFailed,
}: StateChangeButtonsProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    onMarkEconomicPlan,
    economicPlanStatus,
    economicPlanId,
    invalidAmounts,
    loading,
  } = useStateChangeButtons();

  const { onSend, onCheckMessages } = useSendEconomicPlan();
  const { hasHouseMoneyBankAccount, listItems } = useEconomicPlanActivationModal();

  const showConfirmationModalOnMarkDecided = () => {
    Modal.confirm({
      icon: <></>,
      title: tl(economicPlanTranslations.economicPlan.activationModal.title),
      content: (
        <>
          <Divider />
          <ol>
            {listItems.map(row => <li>{row}</li>)}
          </ol>
          {hasHouseMoneyBankAccount ? '' : tl(economicPlanTranslations.economicPlan.activationModal.missingHouseMoneyBankAccountConfirmation)}
        </>
      ),
      okText: tl(economicPlanTranslations.economicPlan.activationModal.confirmText),
      cancelText: tl(economicPlanTranslations.economicPlan.activationModal.cancelText),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: () => onMarkEconomicPlan(EconomicPlanDtoStatusEnum.DECIDED, economicPlanId),
      width: 750,
    });
  };

  const markPrepared = () => {
    const onProceed = () => {
      if (saveBeforeMarkingPrepared !== undefined) {
        saveBeforeMarkingPrepared()
          .then(savedEconomicPlan => onMarkEconomicPlan(EconomicPlanDtoStatusEnum.PREPARED, savedEconomicPlan.id));
      } else {
        onMarkEconomicPlan(EconomicPlanDtoStatusEnum.PREPARED, economicPlanId);
      }
    };

    onProceed();
  };

  const prepareButton = (
    <Button
      type="primary"
      className="right-side-button icon"
      loading={loading}
      onClick={markPrepared}
      disabled={messagesGenerating}
      tooltipText={messagesGenerating ? tl(economicPlanTranslations.economicPlan.header.messagesGeneratingTooltip) : undefined}
    >
      {tl(economicPlanTranslations.economicPlan.header.primaryButton.prepare)}
    </Button>
  );
  const finalizeButton = (
    <Button
      type="primary"
      className="right-side-button icon"
      loading={loading}
      disabled={!economicPlanId}
      onClick={showConfirmationModalOnMarkDecided}
    >
      {tl(economicPlanTranslations.economicPlan.header.primaryButton.finalize)}
    </Button>
  );

  const sendButton = (
    <Button
      type={economicPlanStatus === EconomicPlanDtoStatusEnum.PREPARED ? 'ghost' : 'primary'}
      className="right-side-button icon"
      loading={loading}
      onClick={onSend}
    >
      {tl(economicPlanTranslations.economicPlan.header.buttons.send)}
    </Button>
  );

  const checkMessagesButton = (
    <Button
      type={economicPlanStatus === EconomicPlanDtoStatusEnum.PREPARED ? 'ghost' : 'primary'}
      className="right-side-button icon"
      loading={loading}
      onClick={onCheckMessages}
    >
      {tl(economicPlanTranslations.economicPlan.header.buttons.checkMessages)}
    </Button>
  );
  return (
    <>
      {showSend && !messagesExist && !documentCreationFailed && ([EconomicPlanDtoStatusEnum.ACTIVE, EconomicPlanDtoStatusEnum.DECIDED, EconomicPlanDtoStatusEnum.PREPARED].includes(economicPlanStatus)) && sendButton}
      {showSend && messagesExist && ([EconomicPlanDtoStatusEnum.ACTIVE, EconomicPlanDtoStatusEnum.DECIDED, EconomicPlanDtoStatusEnum.PREPARED].includes(economicPlanStatus)) && checkMessagesButton}
      {(economicPlanStatus === EconomicPlanDtoStatusEnum.PREPARED) && invalidAmounts.data?.length === 0 && finalizeButton}
      {(economicPlanStatus === EconomicPlanDtoStatusEnum.DRAFT) && invalidAmounts.data?.length === 0 && prepareButton}
    </>
  );
}

StateChangeButtons.defaultProps = {
  saveBeforeMarkingPrepared: undefined,
  showSend: false,
  messagesExist: false,
  messagesGenerating: false,
};
