import { ExtendedExchangeProjectionTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import { useContext, useRef, useState } from 'react';
import { AllocationLaborCostValuesStateContext, ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import { translations } from '../../../../../../../translations';


export const EXCHANGE_LIST_ID = 'ExchangeList';


const useLaborCostCell = (record: ServiceCompanyAllocationValues) => {
  const { tl } = useContext(LanguageContext);
  const allocationLaborCostValues = useContext(AllocationLaborCostValuesStateContext);
  const [modalVisible, setModalVisible] = useState(false);
  const laborCostTranlations = translations.bankTransactionAllocation.serviceCompanyAllocation.laborCostTypes;
  const previouslyFocusedElement = useRef(null);


  if (allocationLaborCostValues === undefined) {
    throw new Error('useLaborCostCell must be used within a ServiceCompanyAllocationContextProvider');
  }

  const { key, type: exchangeType, laborCostType: laborCostTypeFromRecord } = record;

  const { laborCostAmount, laborCostType: laborCostTypeFromContext } = allocationLaborCostValues?.find(alloc => alloc.key === key) ?? {};


  const onClickCell = () => {
    // store in a ref the element that triggered the modal opening (i.e. that had focus)
    previouslyFocusedElement.current = document.activeElement;

    setModalVisible(true);
  };

  const onCloseModal = () => {
    setModalVisible(false);

    setTimeout(() => {
      // focus the element that was active when the modal was opened (for keyboard navigation)
      previouslyFocusedElement.current?.focus();
      previouslyFocusedElement.current = null;
    });
  };

  const laborCostType = (
    exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED
      ? laborCostTypeFromContext
      : laborCostTypeFromRecord
  ) ?? 'noLaborCost';


  return {
    laborCostAmount: formatCurrency(Math.abs(laborCostAmount ?? 0)),
    laborCostType: tl(laborCostTranlations[laborCostType]),
    onClickCell: exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED ? onClickCell : undefined,
    modalVisible,
    onCloseModal,
  };
};

export default useLaborCostCell;
