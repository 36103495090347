import { ErrorCodeUsingGETValueEnum } from 'api/accounting';

export const translations = {
  error: {
    getWatermarksError: {
      message: {
        en: 'Error while fetching watermarks',
        de: 'Fehler beim Abrufen von Wasserzeichen',
      },
      description: {
        en: 'There was an unexpected error while trying to fetch watermarks.',
        de: 'Beim Versuch, Wasserzeichen abzurufen, ist ein unerwarteter Fehler aufgetreten.',
      },
    },
    deleteWatermarkError: {
      message: {
        generic: {
          en: 'Error while deleting watermark',
          de: 'Fehler beim Löschen des Wasserzeichens',
        },
      },
      description: {
        generic: {
          en: 'There was an unexpected error while deleting the selected watermark.',
          de: 'Fehler beim Löschen des Briefpapiers. Beim Löschen des ausgewählten Briefpapiers ist ein unerwarteter Fehler aufgetreten.',
        },
        [ErrorCodeUsingGETValueEnum.DOCUMENT_DOCUMENT_IS_REFERENCED]: {
          en: 'The watermark could not be deleted. It is being used by other processes such as Serial Letter or Owners Meeting Invitation.',
          de: 'Das Briefpapier konnte nicht gelöscht werden. Es wurde in anderen Vorgängen wie Serienbrief oder ETV-Einladung verwendet.',
        },
      },
    },
  },
  successNotification: {
    deleteWatermark: {
      en: 'Watermark deleted successfully',
      de: 'Briefpapier erfolgreich gelöscht',
    },
  },
  confirmModal: {
    deleteConfirm: {
      confirmationTitle: {
        en: 'Delete watermark',
        de: 'Briefpapier löschen',
      },
      confirmationText: {
        en: 'This watermark will be completely deleted and the action cannot be undone.',
        de: 'Dieses Briefpapier wird vollständig gelöscht und die Aktion kann nicht rückgängig gemacht werden.',
      },
    },
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
};
