import {
  ListOpsCostReportUsingGETOrderEnum, OpsCostReportControllerApi, OpsCostReportProjection, OpsCostReportProjectionStatusEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { useHistory, useLocation } from 'react-router';
import { OPS_COST_REPORTS_PATH, getOpsCostReportEditPath, getOpsCostReportViewPath } from 'pages/OpsCostReport/routes';
import { opsCostTranslation } from '../../translations';
import { OpsCostReportListContext } from './OpsCostReportListContext';

const PAGE_SIZE = 30;
export default function useOpsCostReportList() {
  const opsCostReportListContext = useContext(OpsCostReportListContext);
  const { tl } = useContext(LanguageContext);

  if (opsCostReportListContext === undefined) {
    throw new Error('useOpsCostReportList must be used within a OpsCostReportListContextProvider');
  }

  const { apiConfiguration } = useContext(AuthContext);
  const {
    opsCostReportList, setOpsCostReportList, filterState, sortState,
  } = opsCostReportListContext;
  const opsCostReportControllerApi = new OpsCostReportControllerApi(apiConfiguration('accounting'));
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === OPS_COST_REPORTS_PATH || !opsCostReportList.loaded) {
      onLoadOpsCostReports(true);
    }
  }, [filterState, sortState, location.pathname === OPS_COST_REPORTS_PATH]);

  const loadMoreIfOverlayOnTop = () => {
    if (location.pathname === OPS_COST_REPORTS_PATH) {
      onLoadOpsCostReports();
    }
  };


  const onLoadOpsCostReports = (resetPage: boolean = false) => {
    setOpsCostReportList(prev => prev.startLoading());
    opsCostReportControllerApi.listOpsCostReportUsingGET({
      page: resetPage ? 0 : opsCostReportList.page,
      size: PAGE_SIZE,
      sort: sortState.field,
      ...filterState,
      order: sortState.order > 0 ? ListOpsCostReportUsingGETOrderEnum.ASC : ListOpsCostReportUsingGETOrderEnum.DESC,
    }).then((response) => {
      setOpsCostReportList(prev => prev.loadPaged(response.content, resetPage, response.last));
    }).catch((err) => {
      console.error(err);
      setOpsCostReportList(prev => prev.failed(err));
      showNotification({
        key: 'loadOpsCostReportsError',
        message: tl(opsCostTranslation.notifications.loadListError),
        type: 'error',
      });
    });
  };

  const onOpenOpsCostReportView = (record: OpsCostReportProjection) => ({
    onClick: () => {
      if (record.status === OpsCostReportProjectionStatusEnum.DRAFT) {
        history.push(getOpsCostReportEditPath(record.id));
        return;
      }

      history.push(getOpsCostReportViewPath(record.id));
    },
  });

  return {
    opsCostReportList,
    setOpsCostReportList,
    onLoadOpsCostReports,
    loadMoreIfOverlayOnTop,
    onOpenOpsCostReportView,
  };
}
