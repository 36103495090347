import { useContext, useMemo } from 'react';
import SelectOptionWithTwoLines from 'storybook-components/inputs/select/SelectOptionWithTwoLines/SelectOptionWithTwoLines';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { useOrderOpenBalancesContext } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/OrderOpenBalancesContext';
import { DunningLevelEnum } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { isEmpty } from 'lodash';
import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';

const getContractHasMandate = (contract) => {
  if (isEmpty(contract.children)) {
    return contract.contractHasMandate;
  }
  return getContractHasMandate(contract.children[0]);
};

export const useDunningLevelSelector = (record) => {
  const { tl } = useContext(LanguageContext);
  const { contractDunningLevels, setContractDunningLevels } = useOrderOpenBalancesContext('useDunningLevelSelector');

  const { children } = record;
  const { contractId } = children ? children[0] : record;

  const isMvProperty = record.accountCode.startsWith('2001/');

  const commonOptions = [
    {
      label: tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAY_OUT].label),
      value: DunningLevelEnum.PAY_OUT,
      customBody: <SelectOptionWithTwoLines
        primaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAY_OUT].label)}
        secondaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAY_OUT].description)}
      />,
    },
    {
      label: tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.SEPA_DIRECT_DEBIT].label),
      value: DunningLevelEnum.SEPA_DIRECT_DEBIT,
      customBody: <SelectOptionWithTwoLines
        primaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.SEPA_DIRECT_DEBIT].label)}
        secondaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.SEPA_DIRECT_DEBIT].description)}
      />,
      disabled: !getContractHasMandate(record),
    },
    {
      label: tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAYMENT_REMINDER].label),
      value: DunningLevelEnum.PAYMENT_REMINDER,
      customBody: <SelectOptionWithTwoLines
        primaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAYMENT_REMINDER].label)}
        secondaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAYMENT_REMINDER].description)}
      />,
    },
    {
      label: tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.DUNNING_NOTE].label),
      value: DunningLevelEnum.DUNNING_NOTE,
      customBody: <SelectOptionWithTwoLines
        primaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.DUNNING_NOTE].label)}
        secondaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.DUNNING_NOTE].description)}
      />,
    },
    {
      label: tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.LAST_DUNNING_NOTE].label),
      value: DunningLevelEnum.LAST_DUNNING_NOTE,
      customBody: <SelectOptionWithTwoLines
        primaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.LAST_DUNNING_NOTE].label)}
        secondaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.LAST_DUNNING_NOTE].description)}
      />,
    },
    {
      label: tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.NO_MESSAGE].label),
      value: DunningLevelEnum.NO_MESSAGE,
      customBody: <SelectOptionWithTwoLines
        primaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.NO_MESSAGE].label)}
        secondaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.NO_MESSAGE].description)}
      />,
    },
  ];

  const getDunningLevelOptions = () => {
    if (isMvProperty) {
      return commonOptions;
    }
    const wegOptions = [...commonOptions];
    wegOptions.splice(3, 0,
      {
        label: tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAYMENT_REMINDER_TAKEOVER_PROPERTY].label),
        value: DunningLevelEnum.PAYMENT_REMINDER_TAKEOVER_PROPERTY,
        customBody: <SelectOptionWithTwoLines
          primaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAYMENT_REMINDER_TAKEOVER_PROPERTY].label)}
          secondaryText={tl(orderOpenBalancesTranslations.dunningLevelSelectorLabels[DunningLevelEnum.PAYMENT_REMINDER_TAKEOVER_PROPERTY].description)}
        />,
      });
    return wegOptions;
  };

  const options = getDunningLevelOptions();

  const onChange = (value: DunningLevelEnum) => {
    setContractDunningLevels(prev => prev.map(contractDunningLevel => (
      contractDunningLevel.contractId === contractId
        ? { ...contractDunningLevel, dunningLevel: value }
        : contractDunningLevel
    )));
  };

  const value = useMemo(() => contractDunningLevels.find(cdl => cdl.contractId === contractId)?.dunningLevel, [contractId, contractDunningLevels]);

  const disabled = record.contractDunningLevel === ContractProjectionDtoDunningLevelEnum.EXCLUDED;

  return {
    options,
    onChange,
    value,
    disabled,
  };
};
