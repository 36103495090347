import { ContractProjectionDto, GetMessagesUsingGETSourceTypeEnum } from 'api/accounting';
import { buildDocumentRecipients } from 'lib/messageUtils';
import { uniq } from 'lodash';
import { useEconomicPlanDocuments } from 'pages/EconomicPlan/EconomicPlanOverview/services/useEconcomicPlanDocuments';
import { getEconomicPlanMessageSendingPath } from 'pages/EconomicPlan/routes';
import { EconomicPlanContext } from 'pages/EconomicPlan/services/EconomicPlanContext';
import { getMessagePath } from 'pages/Messages/routes';
import { MessageSendingInitializationStateType } from 'pages/MessageSendingPage/interfaces';
import { useAllContractsList } from 'pages/Property/PropertyEditing/services/useAllContractsList';
import { useContext } from 'react';
import { useHistory } from 'react-router';

export const useSendEconomicPlan = () => {
  const history = useHistory();
  const economicPlanContext = useContext(EconomicPlanContext);

  if (economicPlanContext === undefined) {
    throw new Error('useSendEconomicPlan must be used within a EconomicPlanContextProvider');
  }

  const { economicPlan } = economicPlanContext;
  const { allContractsList } = useAllContractsList(economicPlan.data?.propertyId);

  const { documents } = useEconomicPlanDocuments();

  const onSend = () => {
    const contractIds = uniq(documents.data?.filter(d => d.contractId).map(d => d.contractId));
    const documentRecipients = documents.data?.filter(d => d.contractId !== undefined).map((doc) => {
      const contract = allContractsList.data?.find(c => c.unitContractId === doc.contractId);
      return buildDocumentRecipients(doc?.id, [contract as unknown as ContractProjectionDto]);
    });

    const overview = documents.data?.find(d => !d.contractId);
    if (overview) {
      documentRecipients.push(buildDocumentRecipients(overview?.id, allContractsList.data?.filter(c => contractIds.includes(c.unitContractId)) as unknown as ContractProjectionDto[]));
    }

    const messageSendingNavigationState: MessageSendingInitializationStateType = {
      sourceType: 'ECONOMIC_PLAN',
      sourceId: economicPlan.data?.id,
      properties: [{
        propertyId: economicPlan.data?.propertyId,
        documents: [...documentRecipients],
      }],
    };

    const sendUrl = getEconomicPlanMessageSendingPath({ propertyHrId: economicPlan.data?.propertyHrId, economicPlanId: economicPlan.data?.id });
    history.replace(sendUrl, messageSendingNavigationState);
  };

  const onCheckMessages = () => {
    history.push(getMessagePath(economicPlan.data?.id, GetMessagesUsingGETSourceTypeEnum.ECONOMIC_PLAN));
  };

  return {
    onSend,
    onCheckMessages,
  };
};
