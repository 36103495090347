import { useContext } from 'react';
import { SectionManagerContext } from './SectionManagerContext';


export const useSectionManager = () => {
  const sectionManagerContext = useContext(SectionManagerContext);

  if (sectionManagerContext === undefined) {
    throw new Error('useSectionManager must be used within a SectionManagerProvider');
  }

  const { openSectionId, setOpenSectionId } = sectionManagerContext;


  const onOpenNextSection = () => {
    setOpenSectionId(prev => (prev ?? -1) + 1);
  };

  // close the section if it was already open
  const onOpenSpecificSection = (sectionId: number) => { setOpenSectionId(prev => (prev === sectionId ? undefined : sectionId)); };

  const onCloseOpenSection = () => { setOpenSectionId(undefined); };

  return {
    openSectionId,
    onOpenNextSection,
    onCloseOpenSection,
    onOpenSpecificSection,
  };
};
