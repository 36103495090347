import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import './AlertArticleLink.scss';
import { alertArticleLinkTranslations } from './translations';

export interface AlertArticleLinkProps {
  content: string
  url: string
  label?: string
  showIcon?: boolean
}

export const AlertArticleLink = ({ content, url, label, showIcon }: AlertArticleLinkProps) => {
  const { tl } = useContext(LanguageContext);

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="AlertArticleLink"
    >
      {showIcon && <Icon component={ICONS.article} />}
      <span className="article-text">{label ?? tl(alertArticleLinkTranslations.articleLabel)}</span>
      <span className="underlined-content">{content}</span>
    </a>
  );
};

AlertArticleLink.defaultProps = {
  label: undefined,
  showIcon: true,
};
