import React, { useContext } from 'react';
import { Moment } from 'moment';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { pastBookingsTranslations } from '../pastBookingsTranslations';
import DateInput from '../../../../../../elements/Inputs/DateInput/DateInput';
import TextInput from '../../../../../../elements/Inputs/TextInput/TextInput';
import SelectInput from '../../../../../../elements/Inputs/SelectInput/SelectInput';

export const usePastBookingsColumns = () => {
  const { tl } = useContext(LanguageContext);
  return [
    {
      title: tl(pastBookingsTranslations.columns.bookingDate),
      dataIndex: 'bookingDate',
      width: 200,
      render: (bookingDate: Moment) => (
        <DateInput
          onChange={() => {
          }}
          disabled
          value={bookingDate}
        />
      ),
    },
    {
      title: tl(pastBookingsTranslations.columns.bookingTitle),
      dataIndex: 'bookingText',
      width: 'auto',
      render: (bookingText: string) => (
        <TextInput
          onChange={() => {
          }}
          disabled
          value={bookingText}
        />
      ),
    },
    {
      title: tl(pastBookingsTranslations.columns.bankOrders),
      dataIndex: 'bankOrder',
      width: 200,
      render: (bankOrder: string) => (<SelectInput defaultValue={bankOrder} options={[]} onChange={() => {}} disabled />),
    },
  ];
};
