export const houseMoneySettlementStateChangeButtonsTranslations = {
  revertModal: {
    title: {
      en: 'Revert House Money Settlement to Draft.',
      de: 'Hausgeldabrechnung auf Entwurf zurücksetzen.',
    },
    text: {
      en: 'Reverting the House Money Settlement to Draft will also revert all postings and Bank Orders generated by the Year End Peak. Are you sure you want to proceed?',
      de: 'Wenn Sie die Hausgeldabrechnung auf Entwurf zurücksetzen, werden auch alle Buchungen und Bankaufträge, die durch die Abrechnungsspitze generiert wurden, zurückgesetzt. Sind Sie sicher, dass Sie fortfahren möchten?',
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    confirmText: {
      en: 'Confirm',
      de: 'Bestätigen',
    },
  },
};
