import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { LanguageContext } from 'contexts/LanguageContext';
import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import { translations as generalTranslations } from 'elements/Translation/translations';
import { Link } from 'react-router-dom';
import { getPropertyAccountBalancePath } from 'pages/Account/routes';
import { economicPlanAccountEditingTranslations } from '../../../economicPlanAccountEditingTranslations';
import { ECONOMIC_PLAN_ACCOUNT_TYPES } from '../../../../services/ECONOMIC_PLAN_ACCOUNT_TYPES';

interface economicPlanAmountsTableColumnsProps {
  onNewYearChange: Function,
  accountTypes: ECONOMIC_PLAN_ACCOUNT_TYPES[],
  economicPlan: any,
}

export const UseEconomicPlanAmountsTableColumns = ({
  onNewYearChange, accountTypes, economicPlan,
}: economicPlanAmountsTableColumnsProps) => {
  const { tl } = useContext(LanguageContext);
  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  /*
    Amounts of a given account are summed up from all accountTypes (param)
    to not lose the value in case the apply flag on the
    distribution key is changed, as the save economic plan is not kept in sync with that.

    Example:
    1. on EP creation the account is applicable
    2. flag is changed, so this account code is in 'notApplicable' in creationData (kept in sync with DK changes)
    3. when displaying the notApplicableCosts table:
      search both 'applicable' and 'notApplicable' objects in ep
      ep['notApplicable'][accountBalance.accountCode] -> is undefined -> 0
      ep['applicable'][accountBalance.accountCode] -> has the value x
      0 + x = x -> displayed correctly and the avlue entered before the apply flag change is not lost
  */
  const calculateSumOfChildren = (accountBalance: any, ep: any) => {
    if (!accountBalance.children) {
      return accountTypes
        .map(accountType => (ep && ep[accountType] && ep[accountType][accountBalance.accountCode]
          ? ep[accountType][accountBalance.accountCode]
          : 0))
        .reduce((sum: number, ab: number) => sum + ab, 0);
    }
    return accountBalance.children.reduce((sum: number, ab: any) => sum + calculateSumOfChildren(ab, ep), 0);
  };

  return [
    {
      title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.table.account),
      dataIndex: 'accountCode',
      defaultWidth: '55%',
      render: (accountCode: string, accountBalance: any) => (
        <Link to={getPropertyAccountBalancePath(propertyHrId, accountCode)} target="_blank">
          {`${(accountBalance.accountCode)} ${accountBalance.accountName}`}
        </Link>
      ),
    },
    {
      title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.table.distribution),
      dataIndex: 'economicPlanDistributionMode',
      defaultWidth: '15%',
      render: (item: any) => (
        <span>{item ? tl(generalTranslations.enums.distributionMode[`${item}`]) : ''}</span>
      ),
    },
    {
      title: tl(economicPlanAccountEditingTranslations.sections.editAmounts.table.newYear),
      dataIndex: 'accountCode',
      className: 'currency',
      defaultWidth: '30%',
      render: (accountCode: any, accountBalance: any) => (
        // disable if not leaf account
        <CurrencyInput
          onChange={value => onNewYearChange(accountCode, value, accountTypes)}
          label=""
          value={calculateSumOfChildren(accountBalance, economicPlan.data)}
          disabled={!!accountBalance.children}
        />
      ),
    },
  ];
};
