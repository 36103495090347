import React, { RefObject, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface useAnimateHeightProps {
    animatedComponentRef: RefObject<any>,
    initialClosed?: boolean,
}

export function useAnimateHeight(props: useAnimateHeightProps) {
  const animationTime = 200;

  const { animatedComponentRef, initialClosed } = props;
  const [height, setHeight]: [any, Function] = React.useState('auto');
  const [tempHeight, setTempHeight] = React.useState(null);


  /**
     * Get initial height of the component
     */

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: any) => {
      const containerHeight = entries[0].contentRect.height;
      if (containerHeight !== 0) {
        setTempHeight(containerHeight);
        setHeight((prevHeight: any) => {
          if (prevHeight !== containerHeight && prevHeight !== 0 && prevHeight !== 'auto') {
            return containerHeight;
          }

          return prevHeight;
        });
      }
    });
    if (!!animatedComponentRef.current && tempHeight !== animatedComponentRef.current.getBoundingClientRect().height) {
      const originalHeight = animatedComponentRef.current.getBoundingClientRect().height;
      resizeObserver.observe(animatedComponentRef.current.childNodes[0]);
      animatedComponentRef.current.style.transition = `max-height ${animationTime}ms ease-in`;
      animatedComponentRef.current.style.overflow = 'hidden';
      animatedComponentRef.current.style.maxHeight = initialClosed ? 0 : `${originalHeight}px`;
      setHeight(initialClosed ? 0 : `${originalHeight}px`);
    }
    return () => {
      if (animatedComponentRef.current) {
        resizeObserver.unobserve(animatedComponentRef.current.childNodes[0]);
      }
    };
  }, [animatedComponentRef]);

  useEffect(() => {
    if (!animatedComponentRef.current || height === 'auto') return;

    // "Workaround" to get any height animation to 0 working.
    if (height === 0) {
      setTimeout(() => {
        if (animatedComponentRef.current != null) {
          animatedComponentRef.current.style.maxHeight = '0';
        }
      }, 0);
    } else {
      animatedComponentRef.current.style.maxHeight = `${height}px`;
    }
  }, [height]);

  const toggleHeight = () => {
    if (tempHeight === null) return;
    setHeight(height === 0 ? tempHeight : 0);
  };

  return { toggleHeight, expanded: height !== 0 };
}

useAnimateHeight.defaultProps = {
  initialClosed: false,
};
