import { useContext } from 'react';
import _ from 'lodash';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { userListTranslations } from 'pages/Users/UserList/userListTranslations';
import { LanguageContext } from 'contexts/LanguageContext';
import { UsersListContext } from 'pages/Users/UserList/services/UsersListContext';

export const useUsersListFilters = () => {
  const { tl } = useContext(LanguageContext);
  const usersListContext = useContext(UsersListContext);
  if (usersListContext === undefined) {
    throw Error('useUsersListFilters must be used within UnitsListContextProvider');
  }

  const { filterState, setFilterState } = usersListContext;

  const availableFilters: Filter[] = [
    {
      type: 'text',
      name: tl(userListTranslations.email),
      key: 'email',
    },
  ];

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  const onChangeFilter = (key: string, value: string) => setFilterState(
    (currentFilter: any) => {
      const newFilter = _.cloneDeep(currentFilter);
      if (value === undefined || value === null) {
        // @ts-ignore
        delete newFilter[key];
      } else {
        // @ts-ignore
        newFilter[key] = value;
      }
      return newFilter;
    },
  );

  return {
    availableFilters,
    onChangeFilter,
    filterState,
    onSetDefaultFilterFromQueryParams,
  };
};
