import { useContext } from 'react';
import { CurrentOverlayInfoContext } from './CurrentOverlayInfoContext';

export const useCurrentOverlayInfo = () => {
  const currentOverlayInfoContext = useContext(CurrentOverlayInfoContext);

  if (currentOverlayInfoContext === undefined) {
    throw new Error('useCurrentOverlayInfo must be used within CurrentOverlayInfoProvider');
  }

  const { isOverlayOnTop } = currentOverlayInfoContext;

  return { isOverlayOnTop };
};
