import { useMemo, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';
import { useOrderOpenBalancesSelectionContext } from '../../services/OrderOpenBalancesContext';


const ROOT_TENANT_OR_OWNER_ACCOUNT_REGEX = /^(\d+-\d+)\/(\d+)/;

const getNrRootTenantOrOwnerAccounts = (selectedRowKeys: string[]) => {
  const grouped = groupBy(selectedRowKeys, id => id.match(ROOT_TENANT_OR_OWNER_ACCOUNT_REGEX)[0]);

  return Object.keys(grouped).length;
};


export const useDunningActionBar = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const {
    innerTableSelectedRowKeysCurrent,
    outerTableSelectedRowKeysCurrent,
    innerTableSelectedRowKeysTotal,
    showAllSelected,
    expandedRowKeys,
    setInnerTableSelectedRowKeysCurrent,
    setInnerTableSelectedRowKeysTotal,
    setOuterTableSelectedRowKeysCurrent,
    setOuterTableSelectedRowKeysTotal,
    setShowAllSelected,
    setExpandedRowKeys,
  } = useOrderOpenBalancesSelectionContext('useDunningActionBar');


  const nrSelectedContractsCurrent = useMemo(() => (
    getNrRootTenantOrOwnerAccounts(innerTableSelectedRowKeysCurrent)
  ), [innerTableSelectedRowKeysCurrent]);

  const nrSelectedContractsTotal = useMemo(() => (
    getNrRootTenantOrOwnerAccounts(innerTableSelectedRowKeysTotal)
  ), [innerTableSelectedRowKeysTotal]);


  const onUnselectAll = () => {
    setInnerTableSelectedRowKeysCurrent([]);
    setInnerTableSelectedRowKeysTotal([]);
    setOuterTableSelectedRowKeysCurrent([]);
    setOuterTableSelectedRowKeysTotal([]);

    setShowAllSelected(false);
  };

  const onChangeShowAllSelected = (checked: boolean) => {
    setShowAllSelected(checked);
  };

  const allSelectedKeysAreExpanded = useMemo(() => outerTableSelectedRowKeysCurrent.every(v => expandedRowKeys.includes(v)), [outerTableSelectedRowKeysCurrent, expandedRowKeys]);

  const onCollapseAllSelected = () => {
    setExpandedRowKeys(prev => prev.filter(key => !outerTableSelectedRowKeysCurrent.includes(key)));
  };

  const onExpandAllSelected = () => {
    setExpandedRowKeys((prev) => {
      // we need filtering because we don't want to have duplicate keys and we want to keep the previous state
      const newExpandedKeys = outerTableSelectedRowKeysCurrent.filter(key => !prev.includes(key));
      if (isEmpty(newExpandedKeys)) {
        return prev;
      }
      return [...prev, ...newExpandedKeys];
    });
  };


  return {
    nrSelectedContractsCurrent,
    nrSelectedContractsTotal,
    onUnselectAll,
    showWarningIcon: innerTableSelectedRowKeysCurrent.length !== innerTableSelectedRowKeysTotal.length,
    actionBarVisible: nrSelectedContractsTotal > 0,
    showAllSelected,
    onChangeShowAllSelected,
    tooltipVisible,
    onChangeTooltipVisible: (visible: boolean) => setTooltipVisible(visible),
    onExpandAllSelected,
    onCollapseAllSelected,
    allSelectedKeysAreExpanded,
  };
};
