import { RouteDefinition } from 'components/RenderRoutes';
import InviteUsers from 'pages/Users/InviteUsers/InviteUsers';
import UserList from 'pages/Users/UserList/UsersList';
import { UserDetails } from 'pages/Users/UserDetails/UserDetails';


export const UserRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/users',
      component: UserList,
      exact: true,
      subRoutes: [
        {
          path: '/users/invite',
          component: InviteUsers,
          type: 'focus',
        },
        {
          path: '/users/:userId/edit',
          component: UserDetails,
          type: 'focus',
        },
      ],
    },
  ],
};
