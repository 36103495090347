import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useSyncScroll } from './UseSyncScroll';
import { StickyRowProps } from './StickyRowInterface';
import { round2dec, stringWidthToNumber } from '../../../../lib/Utils';


export const StickyLastRow: React.FC<StickyRowProps> = ({
  stickyRowData, visibleColumns, tableName, contentElement, loading = false,
}) => {
  const className = `${tableName} sticky-row sticky-last-row ${loading ? 'loading' : ''}`;
  const summaryElement: any = document.querySelector(`.${tableName}.sticky-last-row`) || {};
  useSyncScroll(contentElement, summaryElement);

  const measureRow: any = document.querySelector(`.${tableName} tr.ant-table-measure-row`) || {};
  if (!stickyRowData) return null;

  return (
    <div className={className}>
      {visibleColumns
        .flatMap(column => (column.children ? column.children : column))
        .map((col: ColumnProps<any>, index) => {
          const colData = stickyRowData.find(sD => sD.dataKey === col.dataIndex);

          return (
            <div
              key={`${col.dataIndex}-${index}`}
              className={`sticky-row-cell ${colData?.className || ''}`}
              // @ts-ignore
              style={{ width: round2dec(stringWidthToNumber(col.width, measureRow.clientWidth)) }}
            >
              {colData ? colData.value : ''}
            </div>
          );
        })}
    </div>
  );
};

export default StickyLastRow;
