import {
  EconomicPlanAccountDistributionAmountValidationDto,
  EconomicPlanCreationDto,
  EconomicPlanDto,
  EconomicPlanSimpleProjection,
} from 'api/accounting';
import { DocumentDto } from 'api/public';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';


type Props = {
  children: React.ReactNode;
};

export const EconomicPlanContext = createContext<{
  invalidAmounts: DefaultDataInterface<EconomicPlanAccountDistributionAmountValidationDto[]>,
  setInvalidAmounts: React.Dispatch<React.SetStateAction<DefaultDataInterface<EconomicPlanAccountDistributionAmountValidationDto[]>>>,
  economicPlan: DefaultDataInterface<EconomicPlanDto>,
  setEconomicPlan: React.Dispatch<React.SetStateAction<DefaultDataInterface<EconomicPlanDto>>>,
  economicPlanList: DefaultDataInterface<EconomicPlanSimpleProjection[]>,
  setEconomicPlanList: React.Dispatch<React.SetStateAction<DefaultDataInterface<EconomicPlanSimpleProjection[]>>>,
  selectedUnitId: number | null,
  setSelectedUnitId: React.Dispatch<React.SetStateAction<number | null>>,
  economicPlanCreationData: DefaultDataInterface<EconomicPlanCreationDto>,
  setEconomicPlanCreationData: React.Dispatch<React.SetStateAction<DefaultDataInterface<EconomicPlanCreationDto>>>,
  unitEconomicPlan: DefaultDataInterface<any>,
  setUnitEconomicPlan: React.Dispatch<React.SetStateAction<DefaultDataInterface<any>>>,
  mergedDocumentModalVisible: boolean;
  setMergedDocumentModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  documents: DefaultDataInterface<DocumentDto[]>;
  setDocuments: React.Dispatch<React.SetStateAction<DefaultDataInterface<DocumentDto[]>>>;
} | undefined>(undefined);

const EconomicPlanContextProvider = ({ children }: Props) => {
  const [economicPlan, setEconomicPlan] = useState(DEFAULT_DATA<EconomicPlanDto | undefined>(undefined));
  const [invalidAmounts, setInvalidAmounts] = useState(DEFAULT_DATA<EconomicPlanAccountDistributionAmountValidationDto[]>([]));
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [economicPlanCreationData, setEconomicPlanCreationData] = useState(DEFAULT_DATA<EconomicPlanCreationDto>(undefined));
  const [unitEconomicPlan, setUnitEconomicPlan] = useState(DEFAULT_DATA<any>(undefined));
  const [economicPlanList, setEconomicPlanList] = useState<DefaultDataInterface<EconomicPlanSimpleProjection[]>>(DEFAULT_DATA<EconomicPlanSimpleProjection[]>([]));
  const [documents, setDocuments] = useState<DefaultDataInterface<DocumentDto[]>>(DEFAULT_DATA([]));
  const [mergedDocumentModalVisible, setMergedDocumentModalVisible] = useState(false);

  const providerValue = {
    invalidAmounts,
    setInvalidAmounts,
    economicPlan,
    setEconomicPlan,
    economicPlanList,
    setEconomicPlanList,
    selectedUnitId,
    setSelectedUnitId,
    economicPlanCreationData,
    setEconomicPlanCreationData,
    unitEconomicPlan,
    setUnitEconomicPlan,
    documents,
    setDocuments,
    mergedDocumentModalVisible,
    setMergedDocumentModalVisible,
  };

  return (
    <EconomicPlanContext.Provider value={providerValue}>
      {children}
    </EconomicPlanContext.Provider>
  );
};

export default EconomicPlanContextProvider;
