import { UnitContractProjectionDto } from 'api/accounting';
import { isNil } from 'lodash';
import moment from 'moment';

export const DISTRIBUTION_FOR_OPS_COST = 'OpsCost';
export const DISTRIBUTION_FOR_HGA_EP = 'HGAEP';

export const isContractValidInSelectedEconomicYear = (contract:UnitContractProjectionDto, economicYearStart:Date, economicYearEnd: Date) => {
  if (!isNil(contract.endDate) && moment(contract.endDate).isBefore(economicYearStart)) {
    return false;
  }
  if (!isNil(contract.startDate) && moment(contract.startDate).isAfter(economicYearEnd)) {
    return false;
  }
  return true;
};
