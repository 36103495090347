import { ContractContactProjectionDtoRoleEnum, UnitProjectionDtoUnitTypeEnum } from 'api/accounting';

export const contactPropertiesTranslations = {
  loadingError: {
    en: 'Could not load properties of this contact.',
    de: 'Eigenschaften dieses Kontakts konnten nicht geladen werden.',
  },
  properties: {
    en: 'Properties',
    de: 'Objekte',
  },
  propertyTable: {
    columns: {
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      role: {
        en: 'Role',
        de: 'Rolle',
      },
      accountBalance: {
        en: 'Account balance',
        de: 'Kontensaldo',
      },
      status: {
        en: 'Status',
        de: 'Status',
      },
    },
    states: {
      DRAFT: {
        en: 'Draft',
        de: 'Entwurf',
      },
      READY: {
        // legacy term not changed due to the cost of updating all support articles & videos
        en: 'Bookable',
        de: 'Bereit zur Verbuchung',
      },
      COMPLETED: {
        en: 'Completed',
        de: 'Vollständig',
      },
      OFFBOARDED: {
        en: 'Offboarded',
        de: 'Abgegeben',
      },
      ACTIVE: {
        en: 'Active',
        de: 'Aktiv',
      },
      FUTURE: {
        en: 'Upcoming',
        de: 'Bevorstehend',
      },
      PAST: {
        en: 'Past',
        de: 'Vergangen',
      },
      OVERRIDEN: {
        en: 'Overridden',
        de: 'Überschrieben',
      },
    },
    role: {
      [ContractContactProjectionDtoRoleEnum.OWNER]: {
        en: 'Owner',
        de: 'Eigentümer',
      },
      [ContractContactProjectionDtoRoleEnum.TENANT]: {
        en: 'Tenant',
        de: 'Mieter',
      },
      [ContractContactProjectionDtoRoleEnum.COUNCIL]: {
        en: 'Council',
        de: 'Beirat',
      },
      [ContractContactProjectionDtoRoleEnum.HEAD_OF_COUNCIL]: {
        en: 'Head of Council',
        de: 'Beiratsvorsitz',
      },
      [ContractContactProjectionDtoRoleEnum.PROPERTY_OWNER]: {
        en: 'Property Owner',
        de: 'Objekteigentümer',
      },
      [ContractContactProjectionDtoRoleEnum.LEGAL_REPRESENTATIVE]: {
        en: 'Legal Representative',
        de: 'Bevollmächtigte',
      },
      [ContractContactProjectionDtoRoleEnum.SUBTENANT]: {
        en: 'Subtenant',
        de: 'Untermieter',
      },
      [ContractContactProjectionDtoRoleEnum.RESIDENT]: {
        en: 'Resident',
        de: 'Bewohner',
      },
      [ContractContactProjectionDtoRoleEnum.LEGAL_GUARDIAN]: {
        en: 'Legal Guardian',
        de: 'Vormund',
      },
    },
    tooltipText: {
      en: 'This contract was excluded from the dunning.',
      de: 'Dieser Vertrag wurde vom Mahnwesen ausgeschlossen.',
    },
    unitTypes: {
      [UnitProjectionDtoUnitTypeEnum.APARTMENT]: {
        en: 'Apartment',
        de: 'Wohnung',
      },
      [UnitProjectionDtoUnitTypeEnum.COMMERCIAL]: {
        en: 'Commercial',
        de: 'Gewerbeeinheit',
      },
      [UnitProjectionDtoUnitTypeEnum.PARKING]: {
        en: 'Parking',
        de: 'Parkmöglichkeit',
      },
      [UnitProjectionDtoUnitTypeEnum.OTHER]: {
        en: 'Other',
        de: 'Sonstiges',
      },
    },
  },
  actionsMenu: {
    viewContract: {
      en: 'View contract details',
      de: 'Vertragsdetails anzeigen',
    },
    viewAccountBalance: {
      en: 'View account balances',
      de: 'Kontosaldo anzeigen',
    },
    viewProperty: {
      en: 'View property details',
      de: 'Objektdetails anzeigen',
    },
    excludeContract: {
      en: 'Exclude from dunning',
      de: 'Von Mahnläufen ausschließen',
    },
    includeContract: {
      en: 'Include to dunning',
      de: 'In Mahnläufe einschließen ',
    },
    modal: {
      title: {
        en: 'Exclude contract',
        de: 'Vertrag ausschließen',
      },
      line1: {
        en: 'This contract will be excluded from the dunning process and you won´t be able to select it anymore.',
        de: 'Dieser Vertrag wird aus dem Mahnwesen ausgeschlossen und Sie können ihn nicht mehr auswählen.',
      },
      line2: {
        en: 'You can include the contract again here at any point in time.',
        de: 'Sie können den Vertrag jederzeit hier wieder einschließen.',
      },
      ok: {
        en: 'Exclude contract',
        de: 'Vertrag ausschließen',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
  },
};
