import { EconomicPlanControllerApi } from 'api/accounting';
import {
  DocumentApi, DocumentDtoStateEnum, GetDocumentsByFilterUsingGETOrderEnum, GetDocumentsByFilterUsingGETSourceTypeEnum,
} from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { MAX_PAGE_SIZE } from 'lib/messageUtils';
import _ from 'lodash';
import { EconomicPlanContext } from 'pages/EconomicPlan/services/EconomicPlanContext';
import { useContext } from 'react';

export const useEconomicPlanDocuments = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useEconomicPlanDocuments must be used within a EconomicPlanContextProvider');
  }

  const { setDocuments, documents } = economicPlanContext;

  const { apiConfiguration, publicApiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const documentApi = new DocumentApi(publicApiConfiguration('public'));


  const onLoadEconomicPlanDocuments = (economicPlanId: number) => {
    if (economicPlanId === undefined) return;
    setDocuments(prev => prev.startLoading());
    documentApi.getDocumentsByFilterUsingGET({
      sourceId: economicPlanId,
      sourceType: GetDocumentsByFilterUsingGETSourceTypeEnum.ECONOMIC_PLAN,
      size: MAX_PAGE_SIZE,
      sort: 'unitRank',
      order: GetDocumentsByFilterUsingGETOrderEnum.ASC,
    })
      .then((resp) => {
        setDocuments(prev => prev.load(resp.content));
      })
      .catch((err) => {
        setDocuments(prev => prev.failed());
        console.error(err);
      });
  };

  const generateDocuments = (economicPlanId: number) => {
    setDocuments(prev => prev.startLoading());
    economicPlanControllerApi.generateEconomicPlanDocumentsUsingPOST({ id: economicPlanId })
      .then(() => {
        onLoadEconomicPlanDocuments(economicPlanId);
      })
      .catch((err) => {
        console.error(err);
        setDocuments(prev => prev.failed());
      });
  };

  const documentsGenerating = !_.isEmpty(documents.data?.filter(d => [DocumentDtoStateEnum.GENERATING, DocumentDtoStateEnum.DRAFT].includes(d.state)));
  const documentCreationFailed = documents.data?.some(d => [DocumentDtoStateEnum.FAILED].includes(d.state));

  return {
    documents,
    documentsGenerating,
    onLoadEconomicPlanDocuments,
    generateDocuments,
    documentCreationFailed,
  };
};
