import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { useHistory } from 'react-router';
import { TabLink } from 'storybook-components/TabLink/TabLink';
import Button from 'elements/Buttons/Button/Button';
import { orderOpenBalancesTranslations } from '../translations';
import { useDunningList } from './services/useDunningList';
import { useDunningColumns } from './services/useDunningColumns';
import DunningListContextProvider, { DunningProjectionFE } from './services/DunningListContext';
import useDunningListFilters from './services/useDunningListFilters';
import { getOrderOpenbalanceViewPath } from '../routes';
import { useDunningTabLinks } from '../useDunningTabLinks';

const DunningList = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  const {
    dunningList,
    onLoadDunningList,
    sortField,
    sortOrder,
    onChangeSort,
  } = useDunningList();

  const tabLinks = useDunningTabLinks();

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'orderOpenBalances',
    navItems: subcategorySwitcherItems.accounting,
  };

  const {
    createNewDunningPageFilters, dunningListFilterState, setFilter, onSetDefaultFilterFromQueryParams,
  } = useDunningListFilters();

  useGlobalSearch({
    key: 'dunningList',
    filterProps: {
      availableFilters: createNewDunningPageFilters,
      setFilter,
    },
    queryParamAsFilter: {
      onSetDefaultFilterFromQueryParams,
      filterState: dunningListFilterState,
    },
  });

  const dunningTable = useSmartTable({
    tableName: 'DunningListTable',
    columns: useDunningColumns(),
    dataSource: dunningList.data,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => onChangeSort(dataKey as keyof DunningProjectionFE),
    },
    contentLoading: dunningList.loading,
    infiniteScrollerProps: {
      hasMoreData: !dunningList.lastPage,
      loadMoreData: onLoadDunningList,
    },
    rowKey: 'id',
    onRow: (record: DunningProjectionFE) => ({
      onClick: () => history.push(getOrderOpenbalanceViewPath(record.id)),
    }),
  });

  return (
    <div className="DunningList">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button type="primary" disabled>
            {tl(orderOpenBalancesTranslations.header.button)}
          </Button>
        )}
      />
      <TabLink links={tabLinks} />
      <SmartTable {...dunningTable} />
    </div>
  );
};


export const DunningListWithProvider = () => (
  <DunningListContextProvider>
    <DunningList />
  </DunningListContextProvider>
);
