import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { ownersMeetingInvitationTranslations } from '../../../../translations/OwnersMeetingInvitationTranslations';

const EmptyAgendaItemsListComponent = () => {
  const { tl } = useContext(LanguageContext);

  return (
    <EmptyTableMultilineMessage description={tl(ownersMeetingInvitationTranslations.agendaItemsTable.noItems)} />
  );
};

export default EmptyAgendaItemsListComponent;
