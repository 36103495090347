import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import MainPageHeader from '../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import SmartTable from '../../../elements/SmartTable/SmartTable';
import useSiteMap from '../../../services/useSiteMap/useSiteMap';
import useSmartTable from '../../../elements/SmartTable/useSmartTable';
import { useEconomicPlanPropertyListColumns } from './services/useEconomicPlanPropertyListColumns';
import usePropertyList from '../../../services/usePropertyList';
import { PropertyDisplayDto } from '../../../api/accounting';
import { useGlobalSearch } from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import useEconomicPlanPropertyListFilters from './services/useEconomicPlanPropertyListFilters';

export default function EconomicPlanPropertyList(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const { filters, propertyList, loadPropertyList, updateFilterState } = usePropertyList();

  useGlobalSearch({
    key: 'economicPlanPropertyList',
    filterProps: {
      availableFilters: useEconomicPlanPropertyListFilters(),
      setFilter: (key: string, value: any) => updateFilterState({ [key]: value }),
    },
    queryParamAsFilter: {
      filterState: filters,
      onSetDefaultFilterFromQueryParams: updateFilterState,
    },
  });

  const smartTable = useSmartTable({
    tableName: 'economicPlanPropertyList',
    columns: useEconomicPlanPropertyListColumns(),
    dataSource: propertyList.data || [],
    contentLoading: propertyList.loading,
    onRow: (property: PropertyDisplayDto) => ({
      onClick: () => {
        history.push(`${location.pathname}/property/${property.propertyHrId}`);
      },
    }),
    infiniteScrollerProps: {
      hasMoreData: !propertyList.lastPage,
      loadMoreData: loadPropertyList,
    },
    rowKey: 'id',
  });


  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'economicPlan',
    navItems: subcategorySwitcherItems.accounting,
  };
  return (
    <div className="EconomicPlanPropertyList page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      <SmartTable {...smartTable} />
    </div>
  );
}
