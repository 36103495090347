import { useContext, useMemo } from 'react';
import {  DocumentCreateDtoSourceTypeEnum } from 'api/document';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { OwnersMeetingProtocolDtoStatusEnum } from '../../../../../api/accounting';
import { ownersMeetingInvitationTranslations } from '../../translations/OwnersMeetingInvitationTranslations';

export const UseStandardDocuments = (ownersMeeting: any) => {
  const { tl, language } = useContext(LanguageContext);
  const isSent = [OwnersMeetingProtocolDtoStatusEnum.SENT, OwnersMeetingProtocolDtoStatusEnum.DONE].includes(ownersMeeting.status);

  enum SYSTEM_GENERATED_DOCUMENTS {
    COVER_LETTER = 'coverLetter',
    POWER_OF_ATTORNEY = 'powerOfAttorney',
    AGENDA = 'agenda'
}

const documentCheckboxes = useMemo(
  () =>
    Object.values(SYSTEM_GENERATED_DOCUMENTS).map((documentKey) => ({
      type: 'checkbox',
      key: `with${documentKey.charAt(0).toUpperCase() + documentKey.slice(1)}`,
      props: {
        label: tl(
          ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content[documentKey].description
        ),
        disabled: documentKey === SYSTEM_GENERATED_DOCUMENTS.COVER_LETTER || documentKey === SYSTEM_GENERATED_DOCUMENTS.AGENDA,
      },
    })),
  [language]
);

  function customPdfs() {
    const documents = ownersMeeting.documents ? ownersMeeting.documents : [];
    const uploadedFiles: any[] = [];
    documents.forEach((document: any, i: number) => {
      uploadedFiles.push([{
        type: 'fileLine',
        key: `documents[${i}]`,
        value: document,
        props: {
          descriptionLabel: document.name,
          nameKey: 'name',
          urlKey: 'url',
          name: 'newDocument',
          disabled: isSent,
          sourceType: DocumentCreateDtoSourceTypeEnum.SERIAL_LETTER,
        },
      }]);
    });
    const customFiles = uploadedFiles;
    customFiles.push([
      {
        type: 'fileLine',
        key: `documents[${documents.length}]`,
        props: {
          descriptionLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.addNew.description),
          buttonLabel: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.content.addNew.buttonLabel),
          nameKey: 'name',
          urlKey: 'url',
          name: `documents[${documents.length}]`,
          accept: '.pdf',
          disabled: isSent,
          sourceType: DocumentCreateDtoSourceTypeEnum.SERIAL_LETTER,
        },
      },
    ]);
    return customFiles;
  }

  return {
    sectionId: 'standardDocuments',
    title: tl(ownersMeetingInvitationTranslations.editPage.sections.documentsSection.subsections.standardDocuments.title),
    content: [
      [
        ...documentCheckboxes,
      ]
    ,
      ...customPdfs(),
    ],
  };
};
