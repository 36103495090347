import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { ContactLegacyControllerApi, InvoiceLegacyControllerApi, UnitContractControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { showNotification } from 'lib/Notification';
import { ContactListContext } from 'contexts/ContactListContext';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { translations } from '../../translations';

export const useContactDeleteButton = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { goBack } = useContext(OverlayContext);

  const contactDeleteControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const contractLegacyControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));
  const contactListContext: any = useContext(ContactListContext);
  const { setPersonListState, setCompanyListState } = contactListContext;

  const onDelete = (contactId: number) => {
    contactDeleteControllerApi.deleteContactUsingDELETE({ contactId })
      .then(() => {
        goBack();
        setPersonListState(prev => prev.load(prev.data.filter(c => c.id !== contactId)));
        setCompanyListState(prev => prev.load(prev.data.filter(c => c.id !== contactId)));
        showNotification({
          key: 'contactDeleteSuccess',
          message: tl(translations.deleteModal.successNotification),
          type: 'success',
        });
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          key: 'contactDeleteError',
          message: tl(translations.deleteModal.errorNotification),
          type: 'error',
        });
      });
  };

  const onClickDelete = (contactName: string, contactId: number) => {
    Promise.allSettled([
      contractLegacyControllerApi.getUnitContractsUsingGET({ contactId }),
      invoiceControllerApi.findFilteredInvoicesUsingGET({ counterpartContactId: contactId }),
    ])
      .then(([contractLinkedTotContact, invoicesLinkedToContact]) => {
        // @ts-ignore
        const isAnyContractLinkedToContact = contractLinkedTotContact.value?.length !== 0;
        // @ts-ignore
        const isAnyInvoiceLinkedToContact = invoicesLinkedToContact.value?.content?.length !== 0;
        const isDeleteButtonDisabled = isAnyContractLinkedToContact || isAnyInvoiceLinkedToContact;

        Modal.confirm({
          title: tl(translations.deleteModal.title),
          content: (
            <div>
              <p>{tl(translations.deleteModal.line1)(contactName)}</p>
              { (isAnyContractLinkedToContact || isAnyInvoiceLinkedToContact)
              && <>{tl(translations.deleteModal.line2)}</>
              }
              <ul>
                {
                  isAnyContractLinkedToContact
                && <li>{tl(translations.deleteModal.userActionItems.contracts)}</li>
                }
                {
                  isAnyInvoiceLinkedToContact
                && <li>{tl(translations.deleteModal.userActionItems.invoices)}</li>
                }
              </ul>
              <p>{tl(translations.deleteModal.line3)}</p>
              <ul>
                {
                  Object.keys(translations.deleteModal.deletedAndUnlinkedItems).map(key => (
                    <li>{tl(translations.deleteModal.deletedAndUnlinkedItems[key])}</li>
                  ))
                }
              </ul>
              <p>{tl(translations.deleteModal.line4)}</p>
            </div>),
          okText: tl(translations.deleteModal.ok),
          cancelText: tl(translations.deleteModal.cancel),
          okButtonProps: { className: 'Button', disabled: isDeleteButtonDisabled },
          cancelButtonProps: { className: 'Button' },
          onOk: () => onDelete(contactId),
          closable: true,
          maskClosable: true,
          width: 512,
          centered: true,
        });
      });
  };

  return {
    onClickDelete,
  };
};
