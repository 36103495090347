import _ from 'lodash';
import {
  useContext, useEffect,
} from 'react';
import { useParams } from 'react-router';
import { getStateColor } from 'storybook-components/headers/utils';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import usePoll from 'services/usePoll';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import { Alert } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { formatDate } from '../../../lib/Utils';
import BuildingsListContextProvider from '../../../services/BuildingsList/BuildingsListContext';
import ActiveContractsListContextProvider from '../../../services/UnitContractsList/ActiveContractsListContext';
import ReportOverviewHeader from '../../ReportOverview/ReportOverviewHeader/ReportOverviewHeader';
import { economicPlanTranslations } from '../economicPlanTranslations';
import './EconomicPlanOverview.scss';
import MismatchInfo from './MismatchInfo/MismatchInfo';
import PropertyEconomicPlan from './PropertyEconomicPlan/PropertyEconomicPlan';
import RightSideButtons from './RightSideHeaderButtons/RightSideHeaderButtons';
import useEconomicPlanOverview from './services/useEconomicPlanOverview';
import UnitEconomicPlan from './UnitEconomicPlan/UnitEconomicPlan';
import { useClearEconomicPlanContext } from '../services/useClearEconomicPlanContext';
import { useLoadInvalidAmounts } from '../services/useLoadInvalidAmounts';
import { useLoadAccountDistributionEconomicPlan } from '../services/useLoadAccountDistributionEconomicPlan';
import { useEconomicPlanDocuments } from './services/useEconcomicPlanDocuments';

export default function EconomicPlanOverview(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    economicPlan, unitEconomicPlan, setSelectedUnitId, selectedUnitId,
    invalidAmounts, selectedProperty, setSelectedPropertyHrId,
  } = useEconomicPlanOverview();
  const { onClearEconomicPlan } = useClearEconomicPlanContext();
  const { onLoadInvalidAmounts } = useLoadInvalidAmounts();
  const { onLoadEconomicPlan } = useLoadAccountDistributionEconomicPlan();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { economicPlanId } = useParams<{ economicPlanId: string }>();

  const { onLoadEconomicPlanDocuments, documentsGenerating, documentCreationFailed } = useEconomicPlanDocuments();

  useEffect(() => {
    if (propertyHrId && !selectedProperty.data?.propertyHrId) {
      setSelectedPropertyHrId(propertyHrId);
    }
    // onClear => overview shows empty data it instead of wrong EP until data is loaded
    return () => onClearEconomicPlan();
  }, [propertyHrId]);

  useEffect(() => {
    if (economicPlanId) {
      onLoadEconomicPlanDocuments(parseInt(economicPlanId, 10));
    }
  }, [economicPlanId]);

  usePoll({
    shouldPoll: documentsGenerating,
    pollFunction: () => onLoadEconomicPlanDocuments(parseInt(economicPlanId, 10)),
    timeoutSeconds: 3000,
  });

  // This useEffect is NOT inside the hook because otherwise it will be called several times
  useEffect(() => {
    // We set the economic.data?.id to null when we leave the edit and overview pages
    // This way the overview reloads any changes
    if (economicPlanId && !economicPlan?.data?.id) {
      onLoadEconomicPlan(parseInt(economicPlanId, 10));
      onLoadInvalidAmounts(parseInt(economicPlanId, 10));
    }
  }, [economicPlanId, economicPlan?.data?.id]);


  const rightSideComponent = (
    <>
      <span>
        {economicPlan.data?.documentGeneratedOn
          ? (`${tl(economicPlanTranslations.economicPlan.header.generatedOn)} ${formatDate(new Date(economicPlan.data.documentGeneratedOn!))}`) : ''
        }
      </span>
    </>
  );

  return (
    <Page className="EconomicPlanOverview">
      <PageHeader
        title={economicPlan.data?.title ?? tl(economicPlanTranslations.economicPlan.header.title)}
        subtitle={<PropertyInfo property={selectedProperty.data} />}
        tagContent={economicPlan.data?.status ? tl(economicPlanTranslations.economicPlanList.status[economicPlan.data?.status]) : ''}
        tagColor={getStateColor(economicPlan.data?.status) as PageHeaderTagColor}
        rightSideComponent={<RightSideButtons />}
      />
      <BuildingsListContextProvider>
        <ActiveContractsListContextProvider>
          {!economicPlan.loading
            && (
              <ReportOverviewHeader
                setSelectedUnitId={setSelectedUnitId}
                rightSideButtons={() => rightSideComponent}
              />
            )
          }
        </ActiveContractsListContextProvider>
      </BuildingsListContextProvider>
      <PageContent>
        {!_.isEmpty(invalidAmounts.data)
        && !invalidAmounts.loading
        && !economicPlan.loading
        && (
          <MismatchInfo
            validations={invalidAmounts.data!}
            propertyHrId={economicPlan.data!.propertyHrId!}
          />
        )}
        {
          documentCreationFailed
          && (
            <Alert
              type="warning"
              showIcon
              icon={<Icon component={ICONS.warning} />}
              message={(
                <AlertArticleLink
                  label={tl(economicPlanTranslations.economicPlan.header.generationErrorMessage)}
                  content={tl(economicPlanTranslations.economicPlan.header.contactSupport)}
                  url={articleUrls.contactSupport}
                  showIcon={false}
                />
              )}
            />
          )
        }
        <div className="page-content">
          {selectedUnitId
            ? (
              <UnitEconomicPlan
                headerChildren={<div />}
                economicPlan={economicPlan}
                unitEconomicPlan={unitEconomicPlan}
              />
            )
            : (
              <PropertyEconomicPlan
                economicPlan={economicPlan}
              />
            )
          }
        </div>
      </PageContent>
    </Page>
  );
}
