import React from 'react';
import './AllocationSearchBar.scss';
import SearchBar from 'storybook-components/SearchBar/SearchBar';
import { useAllocationSearchBar } from './useAllocationSearchBar';


interface Props {
  onSearch: (input: string) => void;
  checkboxValue: boolean,
  onChangeCheckbox: () => void,
  checkboxLabel: string
  checkboxDisabled?: boolean,
}


const AllocationSearchBar = ({
  onSearch, onChangeCheckbox, checkboxValue, checkboxLabel, checkboxDisabled,
}: Props) => {
  const { inputValue, onChange } = useAllocationSearchBar(onSearch);

  return (
    <SearchBar
      searchValue={inputValue}
      onSearch={onChange}
      onChangeCheckboxValue={onChangeCheckbox}
      checkboxValue={checkboxValue}
      checkboxLabel={checkboxLabel}
      checkboxDisabled={checkboxDisabled}
      className="transaction-search-bar"
    />
  );
};

export default AllocationSearchBar;

AllocationSearchBar.defaultProps = {
  checkboxDisabled: false,
};
