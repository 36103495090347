import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
  title: string,
  creditSum: number,
  debitSum: number,
  balanceWithinDateRangeSum: number,
  startBalanceSum: number,
  endBalanceSum: number,
}
const BankAccountsTableSummary = ({
  title, creditSum,
  debitSum,
  balanceWithinDateRangeSum,
  startBalanceSum,
  endBalanceSum,
}: Props) => (
  <Table.Summary>
    <Table.Summary.Row className="ProfitAndLossSummary">
      <Table.Summary.Cell index={0} colSpan={1}>
        {title}
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={2} colSpan={1}>
        <Amount>{formatCurrency(startBalanceSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={3} colSpan={1}>
        <Amount>{formatCurrency(debitSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={4} colSpan={1}>
        <Amount>{formatCurrency(creditSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={5} colSpan={1}>
        <Amount>{formatCurrency(balanceWithinDateRangeSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={6} colSpan={1}>
        <Amount>{formatCurrency(endBalanceSum, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default BankAccountsTableSummary;
