import { useContext } from 'react';
import { EconomicPlanContext } from './EconomicPlanContext';
import {
  EconomicPlanControllerApi,
  EconomicPlanCreateDtoTypeEnum,
  EconomicPlanDto,
  EconomicPlanDtoStatusEnum,
  EconomicPlanDtoTypeEnum,
} from '../../../api/accounting';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { PropertyListContext } from '../../../contexts/PropertyListContext';
import { showNotification } from '../../../lib/Notification';
import { economicPlanTranslations } from '../economicPlanTranslations';
import { convertEconomicPlanDtoToEconomicPlanSimpleProjection } from './economicPlanUtils';
import { useSimpleDirtModal } from '../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export const useSaveEconomicPlan = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanContext = useContext(EconomicPlanContext);
  const { clearDirty } = useSimpleDirtModal();
  const { selectedProperty } = useContext(PropertyListContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  if (economicPlanContext === undefined) {
    throw new Error('useSaveEconomicPlan must be used within a EconomicPlanContext and PropertyListContext');
  }

  const {
    economicPlan,
    setEconomicPlan,
    setEconomicPlanList,
  } = economicPlanContext;

  const {
    data: property,
  } = selectedProperty;

  function addToEconomicPlanList(savedEconomicPlan: EconomicPlanDto) {
    setEconomicPlanList((prevState) => {
      const economicPlanList = prevState.data.filter(it => it.id !== savedEconomicPlan.id);
      economicPlanList.push(convertEconomicPlanDtoToEconomicPlanSimpleProjection(savedEconomicPlan));
      economicPlanList.sort((a, b) => a.startDate.localeCompare(b.startDate)
          || a.updated.getTime() - b.updated.getTime());
      return prevState.load(economicPlanList);
    });
  }

  const getEconomicPlanCreationDtoEnumType = () => (
    economicPlan.data?.type === EconomicPlanDtoTypeEnum.UNIT_FIGURES
      ? EconomicPlanCreateDtoTypeEnum.UNIT_FIGURES
      : EconomicPlanCreateDtoTypeEnum.ACCOUNT_DISTRIBUTION
  );

  const createOrUpdateEp = () => ((economicPlan.data?.id)
    ? (economicPlanControllerApi.updateEntityUsingPUT({
      id: economicPlan.data.id,
      dto: {
        title: economicPlan.data?.title,
        startDate: economicPlan.data?.startDate,
        correctionBookingDate: economicPlan.data?.correctionBookingDate,
      },
    }))
    : (economicPlanControllerApi.createEconomicPlanUsingPOST({
      dto: {
        type: getEconomicPlanCreationDtoEnumType(),
        startDate: economicPlan.data?.startDate,
        propertyId: property.id,
        title: economicPlan.data?.title,
      },
    })
    )
  );

  const saveEconomicPlan = () => {
    setEconomicPlan(prevState => prevState.startLoading());
    return (
      createOrUpdateEp()
        .then((savedEconomicPlan) => {
          setEconomicPlan((prevState) => {
            clearDirty();
            return prevState.load({
              // we must use this merging not to override the account amounts added dynamically
              ...prevState.data,
              ...savedEconomicPlan,
            }, {}, true);
          });

          return savedEconomicPlan;
        })
        .then((savedEconomicPlan) => {
          addToEconomicPlanList(savedEconomicPlan);
          return savedEconomicPlan;
        })
        .catch(() => {
          setEconomicPlan(prevState => prevState.failed());
          showNotification({
            key: 'saveEPError',
            message: tl(economicPlanTranslations.notifications.saveError.message),
            type: 'error',
          });
          return Promise.reject();
        })
    );
  };

  return {
    saveEconomicPlan,
    canSave: economicPlan.data?.status === EconomicPlanDtoStatusEnum.DRAFT
      && !economicPlan.loading
      && !!economicPlan.data?.startDate
      && !!economicPlan.data?.title,
  };
};
