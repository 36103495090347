import React, { useContext } from 'react';
import './AccountBookingForm.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { range } from 'lib/Utils';
import AdditionalEntityButton from 'elements/Buttons/AdditionalEntityButton/AdditionalEntityButton';
import { useParams } from 'react-router';
import { useAccountList } from 'services/useAccountList';
import { useAccountBookingForm } from '../../useAccountBookingForm';
import BookingRow from './components/BookingRow';


export default function AccountBookingForm(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { accountBookings, addBooking } = useAccountBookingForm();
  const numberOfBookings = accountBookings.data.length;
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { allAccounts } = useAccountList({ propertyHrId });

  return (
    <div className="AccountBookingForm page">
      <div className="credit-section" />
      {range(0, numberOfBookings - 1).map(bookingNr => (
        <BookingRow
          bookingNr={bookingNr}
          allAccounts={allAccounts}
        />
      ))}
      <div className="additional-button">
        <AdditionalEntityButton
          onClick={addBooking}
          label={tl(translations.pages.accountBooking.form.splitAmount)}
        />
      </div>
    </div>
  );
}
