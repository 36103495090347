import React, { useContext, useMemo } from 'react';
import { ownerPaymentTableTranslations } from '../ownerPaymentTableTranslations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { PaymentDirection, PaymentDirectionSwitch } from '../../inputs/PaymentDirectionSwitch/PaymentDirectionSwitch';
import { ProcessingSelector } from '../../inputs/ProcessingSelector/ProcessingSelector';
import { OwnerPaymentsTableRow } from './useOwnerPaymentsTable';
import { CurrencyInput } from '../../../elements/Inputs/CurrencyInput/CurrencyInput';


export const useOwnerPaymentTableColumns = () => {
  const { tl } = useContext(LanguageContext);
  return useMemo(() => ([
    {
      title: tl(ownerPaymentTableTranslations.columns.unitNrSharingDeclaration),
      dataIndex: 'unitNrSharingDeclaration',
      width: 100,
    },
    {
      title: tl(ownerPaymentTableTranslations.columns.ownerName),
      dataIndex: 'ownerName',
      render: (_value, record) => (record.contacts.map(contact => contact.name).join(', ')),
      width: 300,
    },
    {
      title: tl(ownerPaymentTableTranslations.columns.amount),
      dataIndex: 'amount',
      width: 250,
      render: value => <CurrencyInput onChange={() => {}} value={value} disabled />,
    },
    {
      title: tl(ownerPaymentTableTranslations.columns.direction),
      dataIndex: 'direction',
      render: (value: PaymentDirection) => (
        <PaymentDirectionSwitch value={value} disabled />
      ),
      width: 150,
    },
    {
      title: tl(ownerPaymentTableTranslations.columns.processing),
      dataIndex: 'processing',
      render: (_value, record: OwnerPaymentsTableRow) => (<ProcessingSelector contract={record} direction={record.direction} disabled />),
      width: 200,
    },
  ]), []);
};
