import csvParser from 'csv-parser';
import fileReaderStream from 'filereader-stream';


const isEmptyLine = csvRowObject => Object.keys(csvRowObject)
  .every((key) => {
    const isEmptyValue = !csvRowObject[key] || !csvRowObject[key].trim();
    return isEmptyValue;
  });
/**
 * Read a CSV file into objects.
 * The keys of the objects will be the first lines of the CSV
 * `_row` is persisted onto the object representing the row number in the CSV
 */
export const loadCsv = (files: FileList, rowsToSkip: number = 1): Promise<any[]> => {
  const csvRows: any[] = [];
  const readCsvPromise = new Promise<any[]>(async (resolve, reject) => {
    Array.prototype.map.call(files, async (file: File) => {
      // const csvRawData = await file.text();
      // const csvRows = csvRawData.split(/\r?\n/);
      // console.log(csvRows);
      let rowIdx = 0;
      return fileReaderStream(file)
        .pipe(csvParser({ skipLines: 0 }))
        .on('data', (csvRow) => {
          rowIdx += 1;
          if (isEmptyLine(csvRow)) {
            console.warn('Empty line', csvRow);
            return;
          }
          if (rowIdx <= rowsToSkip) {
            // skip the first row
            return;
          }
          csvRow._row = rowIdx;
          csvRows.push(csvRow);
        })
        .on('end', async () => {
          console.log('Loaded {} rows', csvRows.length);
          resolve(csvRows);
        });
    });
  });
  return readCsvPromise;
};
