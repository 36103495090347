import React from 'react';
import { Tag } from 'antd';
import './TagsGroup.scss';
import { isNil } from 'lodash';

type TagsGroupProps = {
    tags: string[],
    shorten: boolean,
  }

const TagsGroup = ({ tags, shorten }: TagsGroupProps) => {
  if (isNil(tags) || tags.length === 0) {
    return <div className="TagsGroup"><Tag>—</Tag></div>;
  }

  if (shorten) {
    return (
      <div className="TagsGroup shorten" title={tags?.join(',')}>
        <Tag>{tags[0]}</Tag>
        {tags.length > 1 && <Tag>{`+${tags.length - 1}`}</Tag>}
      </div>
    );
  }

  return (
    <div className="TagsGroup">
      {tags.map(tag => <Tag>{tag}</Tag>)}
    </div>
  );
};


export default TagsGroup;
