import './InvoiceIntegrations.scss';

import {
  ReactNode,
  useContext,
  useMemo,
} from 'react';

import { Alert } from 'antd';
import { DocumentDtoSourceTypeEnum } from 'api/public';
import { ICONS } from 'components/icons';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  useConnections,
} from 'pages/PropertyPortalImport/service/useConnections';
import {
  NoPortalAvailableMessage,
} from 'storybook-components/TicketIntegrationSection/components/NoPortalAvailableMessage/NoPortalAvailableMessage';
import {
  PortalDocumentProps,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  CasaviInvoiceTicketSection,
} from 'storybook-components/TicketIntegrationSection/portals/casavi/CasaviInvoiceTicketSection';
import {
  Etg24InvoiceTicketSection,
} from 'storybook-components/TicketIntegrationSection/portals/etg24/Etg24InvoiceTicketSection';
import {
  FaciliooInvoiceTicketSection,
} from 'storybook-components/TicketIntegrationSection/portals/facilioo/FaciliooInvoiceTicketSection';

import Icon from '@ant-design/icons';

import { useGetInvoiceDocumentId } from '../services/useGetInvoiceDocumentId';
import { translations } from '../translations';

export const InvoiceIntegrations = ({ propertyId }: {propertyId: number}) => {
  const { tl } = useContext(LanguageContext);
  const invoiceEditingContext = useContext(InvoiceEditingContext);
  const { connections, loading } = useConnections();

  const { documentId } = useGetInvoiceDocumentId(invoiceEditingContext.data.invoice?.id);

  const documentProps: PortalDocumentProps = useMemo(() => ({
    fileName: invoiceEditingContext.data.invoice?.invoiceHrId,
    entityId: documentId,
    sourceId: invoiceEditingContext.data.invoice?.id,
    sourceType: DocumentDtoSourceTypeEnum.INVOICE,
  }), [invoiceEditingContext.data.invoice?.invoiceHrId, documentId, invoiceEditingContext.data.invoice?.id]);

  const integrations: ReactNode[] = useMemo(() => connections.map((connection) => {
    switch (connection.appId) {
    // casavi
    case 5:
      return (
        <CasaviInvoiceTicketSection
          key={`casavi-connection-${connection.id}`}
          connectionId={connection.id}
          propertyId={propertyId}
          documentProps={documentProps}
        />
      );
    // facilioo
    case 1:
      return (
        <FaciliooInvoiceTicketSection
          key={`facilioo-connection-${connection.id}`}
          propertyId={propertyId}
          connectionId={connection.id}
          documentProps={documentProps}
        />
      );
    // etg24
    case 6:
      return (
        <Etg24InvoiceTicketSection
          key={`etg24-connection-${connection.id}`}
          propertyId={propertyId}
          connectionId={connection.id}
          documentProps={documentProps}
        />
      );
    default:
      console.warn(`Unsupported app ID: ${connection.appId}`);
      return <div />;
    }
  }), [connections, propertyId, documentProps]);

  if (!propertyId) {
    return <Alert type="info" showIcon icon={<Icon component={ICONS.info} />} message={tl(translations.integrations.selectProperty)} />;
  }

  if ((connections.length === 0 && !loading) || !propertyId) {
    return (<Alert message={<NoPortalAvailableMessage />} />);
  }


  return (
    <div className="InvoiceIntegrations">
      {integrations}
    </div>
  );
};

export default InvoiceIntegrations;
