import { snakeToCamel } from 'lib/Utils';
import { translations } from '../../elements/Translation/translations';

export const getUnitTitle = (u: any, tl: any) => {
  if (!u || !u.unitType) {
    return '';
  }
  const unitType = tl(translations.pages.property.editPage.sections.section6.subsection.typeTitles[snakeToCamel(u.unitType.toLowerCase())]);
  return `${u.unitNrSharingDeclaration ? `${u.unitNrSharingDeclaration} - ` : ''}${unitType}`;
};
