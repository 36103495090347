import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { getErrorCodeFromURI } from '../../../lib/ErrorCodesUtils';
import { getRouteBasedOnEntityType } from '../../../navigation/service/useGetRouteBasedOnEntityType';
import { showNotification } from '../../../lib/Notification';
import { economicPlanTranslations } from '../economicPlanTranslations';
import { ErrorCodeUsingGETValueEnum } from '../../../api/accounting';

function getLinkToErroredEntity(errorCode, errorBody) {
  let link = errorBody?.link;
  if (errorCode === ErrorCodeUsingGETValueEnum.BAD_CONTACT_ADDRESS) {
    link = getRouteBasedOnEntityType(errorBody.errorEntityType, errorBody.errorEntityId);
  }
  return link;
}

export const useHandleErrorWithType = () => {
  const { tl } = useContext(LanguageContext);

  const handleErrorWithType = (errorBody, errorTitle) => {
    // food for thought: if type is missing, just display notification without description
    if (!errorBody.type) {
      throw Error('handleErrorWithType can only be called for errors that have a type defined');
    }
    const errorCodeURI = errorBody?.type;
    const errorCode = getErrorCodeFromURI(errorCodeURI);
    const link = getLinkToErroredEntity(errorCode, errorBody);
    const errorDescription = tl(economicPlanTranslations.economicPlan.notification.errorCodes[errorCode])(errorBody);
    showNotification({
      key: errorCode,
      message: errorTitle,
      description: (
        <>
          {link
            ? (
              <a href={link} target="_blank" rel="noreferrer">
                {errorDescription}
              </a>
            )
            : (
              <>{errorDescription}</>
            )
          }
        </>
      ),
      duration: 15,
      type: 'error',
    });
  };

  return { handleErrorWithType };
};
