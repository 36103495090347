import { AccountBookingContext } from 'contexts/AccountBookingContext';
import { useContext } from 'react';

export const useBookingRow = (index: number) => {
  const { accountBookings, validationErrors } = useContext(AccountBookingContext);
  const value = accountBookings.data[index] ?? {};
  const rowValidationErrors = validationErrors[index] ?? {};

  return {
    value,
    rowValidationErrors,
  };
};
