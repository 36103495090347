import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { range } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { translations } from 'elements/Translation/translations';
import { nanoid } from 'nanoid';


export const useSimplePdfViewer = (pdf) => {
  const [pages, setPages] = useState([]);
  // needed in order to support multiple pdf viewers rendered on top of each other (used when scrolling)
  const [pdfViewerId] = useState(() => nanoid());
  const [scaleModifier, setScaleModifier] = useState(0);
  const { tl } = useContext(LanguageContext);
  const ZOOM_STEP = 0.2;

  useEffect(() => {
    if (!pdf) return;

    const promises = range(1, pdf.numPages + 1).map(pageNr => pdf.getPage(pageNr));

    Promise.allSettled(promises)
      .then((promiseResults) => {
        if (promiseResults.some(pr => pr.status === 'rejected')) {
          showNotification({
            key: 'renderPdfError',
            message: tl(translations.notifications.pdfViewer.failedToLoad),
            type: 'error',
          });

          return;
        }

        setPages(
          // @ts-ignore
          promiseResults.map(pr => pr.value),
        );
      });
  }, [pdf, tl]);


  const onChangeScaleModifier = (type: 'increment' | 'decrement') => {
    if (type === 'increment') {
      setScaleModifier(prev => prev + ZOOM_STEP);
    } else {
      setScaleModifier(prev => prev - ZOOM_STEP);
    }
  };


  const scrollToPage = (pageNumber: number) => {
    const elm = document.getElementById(`${pdfViewerId}-page-${pageNumber}`);
    if (elm) {
      elm.scrollIntoView({ behavior: 'auto' });
    }
  };


  return {
    pages,
    scaleModifier,
    onChangeScaleModifier,
    scrollToPage,
    pdfViewerId,
  };
};
