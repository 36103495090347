import React from 'react';

import {
  Col,
  Row,
} from 'antd';
import CellWithTwoLines
  from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

type PdfPageAssignerActionBarSelectOptionProps = {
  unitNr: React.ReactNode;
  contract: React.ReactNode;
  contractType?: string,
  usagePeriodStart: string;
  usagePeriodEnd: string;
  errors: {
    contract?: boolean;
    timePeriod?: boolean;
  };
};


const PdfPageAssignerActionBarSelectOption = ({
  unitNr,
  contract,
  contractType,
  usagePeriodStart,
  usagePeriodEnd,
  errors,
}: PdfPageAssignerActionBarSelectOptionProps) => (
  <Row>
    <Col span={4}>{unitNr}</Col>
    <Col className={`small ${errors.contract ? 'has-error' : ''}`} span={12}>
      <CellWithTwoLines
        firstElement={contract}
        secondElement={!!contractType && <span className="contract-type">{contractType}</span>}
      />
    </Col>
    <Col
      span={8}
      className={`small ${errors.timePeriod ? 'has-error' : ''}`}
    >
      {usagePeriodStart}
      <span className="time-period-divider">─</span>
      {usagePeriodEnd}
    </Col>
  </Row>
);

export default PdfPageAssignerActionBarSelectOption;
