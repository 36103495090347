import { useContext } from 'react';

import {
  Filter,
} from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';

import useDefaultPropertyFilters
  from '../../../components/Header/components/data/useDefaultPropertyFilters';
import { translations } from '../../../elements/Translation/translations';

export default function useResolutionRecordFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    ...useDefaultPropertyFilters(false),
    {
      type: 'text',
      name: tl(translations.pages.resolutionRecordPropertyList.table.headers.supervisorName),
      key: 'supervisorName',
    },
  ];
}
