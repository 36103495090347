import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  createContext, Dispatch, SetStateAction, useMemo, useState,
} from 'react';
import { HouseMoneyDto } from '../../../api/accounting';

export interface OpsCostItem {
  unitId: number,
  buildingId?: number,
  amount?: number,
  action?: string,
  id?: number,
}

export interface ReserveFundAmount {
  unitId: number,
  buildingId?: number,
  reserveFundId: number,
  amount?: number,
  action?: string,
  id?: number,
}

export const HouseMoneyListContext = createContext<| {
  houseMoneyList: DefaultDataInterface<HouseMoneyDto[]>;
}
  | undefined>(undefined);

export const OpsCostContext = createContext<| {
  opsCosts: OpsCostItem[];
}
  | undefined>(undefined);

export const ReserveFundAmountContext = createContext<| {
  reserveFundAmounts: ReserveFundAmount[][];
}
  | undefined>(undefined);

export const HouseMoneySectionUpdatersContext = createContext<{
  setOpsCosts: Dispatch<SetStateAction<OpsCostItem[]>>;
  setReserveFundAmounts: Dispatch<SetStateAction<ReserveFundAmount[][]>>;
  setHouseMoneyList: Dispatch<SetStateAction<DefaultDataInterface<HouseMoneyDto[]>>>;
} | undefined>(undefined);


export const HouseMoneySectionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [houseMoneyList, setHouseMoneyList] = useState<DefaultDataInterface<HouseMoneyDto[]>>(DEFAULT_DATA(undefined));
  const [opsCosts, setOpsCosts] = useState<OpsCostItem[]>([]);
  const [reserveFundAmounts, setReserveFundAmounts] = useState<ReserveFundAmount[][]>([]);

  const houseMoneyListContextValue = useMemo(() => ({
    houseMoneyList,
  }), [houseMoneyList]);
  const opsCostContextValue = useMemo(() => ({ opsCosts }), [opsCosts]);
  const reserveFundAmountsContextValue = useMemo(() => ({ reserveFundAmounts }), [reserveFundAmounts]);

  const updatersValue = useMemo(() => ({
    setHouseMoneyList,
    setOpsCosts,
    setReserveFundAmounts,
  }), [setHouseMoneyList, setOpsCosts, setReserveFundAmounts]);


  return (
    <HouseMoneyListContext.Provider value={houseMoneyListContextValue}>
      <OpsCostContext.Provider value={opsCostContextValue}>
        <ReserveFundAmountContext.Provider value={reserveFundAmountsContextValue}>
          <HouseMoneySectionUpdatersContext.Provider value={updatersValue}>
            {children}
          </HouseMoneySectionUpdatersContext.Provider>
        </ReserveFundAmountContext.Provider>
      </OpsCostContext.Provider>
    </HouseMoneyListContext.Provider>
  );
};
