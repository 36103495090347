import React, { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import './BankConnectionActions.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from '../services/translations';
import BankConnectionImportModal from './components/BankConnectionImportModal';
import { useBankConnectionModal } from './services/useBankConnectionModal';

export const BankConnectionActions = ({ finApiEnabled }) => {
  const { tl } = useContext(LanguageContext);
  const { bankConnectionImportModalVisible, showBankConnectionImportModal, hideBankConnectionImportModal } = useBankConnectionModal();

  return (
    <div className="BankConnectionActions">
      <Button
        disabled={!finApiEnabled}
        onClick={() => showBankConnectionImportModal()}
      >
        {tl(translations.bankConnectionActions.addConnection)}
      </Button>
      <BankConnectionImportModal
        visible={bankConnectionImportModalVisible}
        onClose={() => hideBankConnectionImportModal()}
      />
    </div>
  );
};
