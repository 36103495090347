import React, { useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router';
import { useManagementCompanySelection } from 'services/useManagementCompanySelection';
import moment from 'moment';
import { translations } from '../../elements/Translation/translations';
import { LanguageContext } from '../../contexts/LanguageContext';
import './PaymentList.scss';
import PaymentListProvider, { PaymentListContext } from '../../contexts/PaymentListContext';
import useSmartTable from '../../elements/SmartTable/useSmartTable';
import SmartTable from '../../elements/SmartTable/SmartTable';
import { Action } from '../../elements/SmartTable/data';
import { usePaymentListColumns } from './UsePaymentListColumns';
import { useExecutionModal } from '../../elements/Modals/ExecutionModal/useExecutionModal';
import PaymentExecutionModal from './PaymentExecutionModal';
import MainPageHeader from '../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../services/useSiteMap/useSiteMap';
import { useGlobalSearch } from '../../components/Header/components/SearchBar/services/useGlobalSearch';
import FileUploadModal from '../../elements/Modals/FileUploadModal/FileUploadModal';
import { useFileUploadModal } from '../../elements/Modals/FileUploadModal/useFileUploadModal';
import { usePaymentFilters } from './UsePaymentFilters';
import { PaymentDto, PaymentDtoPaymentStateEnum, PropertyBankAccountInsufficientFundsDto } from '../../api/accounting';
import { PaymentHeaderButtons } from '../../components/PaymentHeaderButtons/PaymentHeaderButtons';

export default function PaymentList(): JSX.Element {
  const paymentListContext: any = useContext(PaymentListContext);
  const { tl } = useContext(LanguageContext);

  const history = useHistory();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  const { filters, onChangeFilterState } = usePaymentFilters();

  const { managementCompanies, selectedManagementCompany, onSelectCompany } = useManagementCompanySelection({
    onSelected: (id) => {
      paymentListContext.updatePaymentFilterState({
        managementCompanyId: id,
      });
    },
    defaultValue: paymentListContext.paymentFilterState.managementCompanyId,
  });

  useGlobalSearch({
    key: 'payments',
    filterProps: {
      availableFilters: filters,
      setFilter: onChangeFilterState,
    },
    queryParamAsFilter: {
      filterState: paymentListContext.paymentFilterState,
      onSetDefaultFilterFromQueryParams: paymentListContext.updatePaymentFilterState,
      defaultFilters: paymentListContext.defaultFilters,
    },
  });

  const paymentEbicsRepairModal = useFileUploadModal({
    onUpload: paymentListContext.onRepairEbicsUpload,
    name: 'payment-ebics-repair',
    body: tl(translations.pages.payment.fileUploadModal.body),
    title: tl(translations.pages.payment.fileUploadModal.title),
    upload: tl(translations.pages.payment.fileUploadModal.upload),
  });


  useEffect(() => () => {
    paymentListContext.onClearPaymentList();
    paymentListContext.onClearFilterState();
  }, []);

  useEffect(() => {
    if (propertyHrId) {
      paymentListContext.updatePaymentFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  useEffect(() => {
    if (smartTable.onUnselectAll) smartTable.onUnselectAll();
    // reset selection when updating the filter
  }, [paymentListContext.paymentFilterState]);

  const actionsMenu: Action[] = [{
    label: tl(translations.pages.payment.mark),
    onAction: (record: PaymentDto) => {
      const param = [record.id!];
      paymentListContext.onMarkAsSent(param);
    },
    onBatchAction: (records: PaymentDto[]) => {
      const params = records.map(record => record.id!);
      paymentListContext.onMarkAsSent(params);
    },
    actionSupported: (record: PaymentDto) => record.paymentState !== PaymentDtoPaymentStateEnum.SENT,
  },
  {
    label: tl(translations.pages.payment.delete),
    onAction: (record: PaymentDto) => {
      const param = [record.id!];
      paymentListContext.onDelete(param);
    },
    onBatchAction: (records: PaymentDto[]) => {
      const params = records.map(record => record.id!);
      paymentListContext.onDelete(params);
    },
    actionSupported: (record: PaymentDto) => record.paymentState !== PaymentDtoPaymentStateEnum.DELETED,
  },
  {
    label: tl(translations.pages.payment.reset),
    onAction: (record: PaymentDto) => {
      const param = [record.id!];
      paymentListContext.onSetToNew(param);
    },
    onBatchAction: (records: PaymentDto[]) => {
      const params = records.map(record => record.id!);
      paymentListContext.onSetToNew(params);
    },
    actionSupported: (record: PaymentDto) => record.paymentState !== PaymentDtoPaymentStateEnum.NEW,
  }];

  const toTableData = (payment: any): any => ({
    ...payment,
    key: payment.id,
    translatedPaymentState: payment.paymentState ? tl(translations.pages.payment.table.paymentStates[payment.paymentState]) : null,
  });

  const tableData = useMemo(() => paymentListContext.data.paymentList.map(toTableData), [paymentListContext.data.paymentList]);

  const smartTable = useSmartTable(
    {
      tableName: 'paymentList',
      columns: usePaymentListColumns(),
      dataSource: tableData,
      propSort: {
        field: paymentListContext.sortField,
        order: paymentListContext.sortOrder,
        onSortChange: (dataKey: string) => paymentListContext.setSortField(dataKey),
      },
      onRow: (record: any) => ({
        onClick: () => {
          if (record.invoiceHrId) {
            if (propertyHrId) {
              history.push(`/properties/${propertyHrId}/edit/payments/${record.invoiceHrId}`);
            } else {
              history.push(`/payments/invoice/${record.invoiceHrId}`);
            }
          }
        },
      }),
      contentLoading: paymentListContext.loading,
      infiniteScrollerProps: {
        hasMoreData: !paymentListContext.lastPage,
        loadMoreData: paymentListContext.onLoadPaymentList,
      },
      rowKey: 'id',
      supportBatchActions: true,
      actionsMenu,
    },
  );

  const totalAmountOfExcludedPayments = useMemo(() => {
    let sum = 0;
    try {
      paymentListContext.excludedIbans.forEach((iban: string) => {
        sum += paymentListContext.downloadData.data.insufficientFundsProperties.filter((prp: PropertyBankAccountInsufficientFundsDto) => prp.iban === iban)[0].totalAmountOfTransactions;
      });
    } catch (e) {
      // NOOP;
    }
    return sum;
  }, [paymentListContext.excludedIbans]);

  const totalNumberOfExcludedPayments = useMemo(() => {
    let num = 0;
    try {
      paymentListContext.excludedIbans.forEach((iban: string) => {
        num += paymentListContext.downloadData.data.insufficientFundsProperties.filter((prp: PropertyBankAccountInsufficientFundsDto) => prp.iban === iban)[0].totalNumberOfTransactions;
      });
    } catch (e) {
      // NOOP;
    }
    return num;
  }, [paymentListContext.excludedIbans]);

  const executionDateWarning = useMemo(() => {
    const today = moment();
    try {
      if (smartTable.selectedRecords!.length > 0) {
        return smartTable.selectedRecords!.reduce((acc, record) => acc || moment(record.executionDate, 'DD.MM.YYYY').isBefore(today, 'day'), false);
      }
      return paymentListContext.downloadData.data.executionDateWarning;
    } catch (e) {
      return null;
    }
  }, [paymentListContext.downloadData, smartTable.selectedRecords]);

  const onExcludeIbans = (selectedIbans: string[]) => {
    {
      paymentListContext.setExcludedIbans((list: string[]) => {
        const newList = list.slice();
        const allIbans = paymentListContext.downloadData.data.insufficientFundsProperties.map(insufficientFundsPropertie => insufficientFundsPropertie.iban);
        allIbans.forEach((iban) => {
          if (selectedIbans.includes(iban) && newList.includes(iban)) {
            const idx = newList.indexOf(iban);
            newList.splice(idx, 1);
          } else if (!selectedIbans.includes(iban) && !newList.includes(iban)) {
            newList.push(iban);
          }
        });
        return newList;
      });
    }
  };
  const paymentExecutionModal = useExecutionModal({
    onDownload: () => {
      if (smartTable.selectedRecords && smartTable.selectedRecords.length > 0) {
        paymentListContext.onDownloadPaymentsByIdAsEbics(smartTable.selectedRecords.map(record => record.id), smartTable.onUnselectAll!);
      } else paymentListContext.onDownloadAllAsEbics();
    },
    // TODO mgmtCompanyId ?
    filter: paymentListContext.paymentFilterState,
    numberOfTransactions: paymentListContext.downloadData.data ? paymentListContext.downloadData.data.totalNumberOfPayments - totalNumberOfExcludedPayments : null,
    amount: paymentListContext.downloadData.data ? paymentListContext.downloadData.data.totalAmount - totalAmountOfExcludedPayments : null,
    basedOnSelection: smartTable.selectedRecords && smartTable.selectedRecords.length > 0,
    propertiesWithInsufficientFunds: paymentListContext.downloadData.data ? paymentListContext.downloadData.data.insufficientFundsProperties : [],
    onExclude: onExcludeIbans,
    excludedIbans: paymentListContext.excludedIbans,
    tl,
    managementCompanyName: selectedManagementCompany?.companyName,
    executionDateWarning,
    showMandateAmendmentCheckbox: false,
    executionDate: moment(),
  });
  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'paymentRun',
    navItems: subcategorySwitcherItems.accounting,
  };

  const onDownloadPayments = () => {
    if (smartTable.selectedRecords && smartTable.selectedRecords.length > 0) {
      paymentListContext.onLoadDownloadDataByIds(smartTable.selectedRecords.map(payment => payment.id), paymentExecutionModal.showModal);
    } else {
      paymentListContext.onLoadDownloadDataByFilters(paymentExecutionModal.showModal);
    }
  };

  return (
    <div className="PaymentList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <PaymentHeaderButtons
            loading={paymentListContext.downloadData.loading || paymentListContext.loadingEbics || paymentListContext.loadingEbics}
            onRepairEbics={paymentEbicsRepairModal.showModal}
            onDownloadSelected={onDownloadPayments}
            downloadLabel={smartTable.selectedRecords && smartTable.selectedRecords.length > 0
              ? tl(translations.pages.payment.sepaSelect)
              : tl(translations.pages.payment.sepaAll)}
            managementCompanyList={managementCompanies}
            managementCompanyLabel={selectedManagementCompany?.companyName || ''}
            managementCompanyLoading={selectedManagementCompany === null}
            onSelectCompany={onSelectCompany}
          />
        )}
      />
      <SmartTable {...smartTable} />
      <PaymentExecutionModal {...paymentExecutionModal} />
      <FileUploadModal {...paymentEbicsRepairModal} />
    </div>
  );
}

export const PaymentListWithProvider = () => (
  <PaymentListProvider>
    <PaymentList />
  </PaymentListProvider>
);
