import { useContext } from 'react';
import {
  AccountControllerApi, PropertyLegacyControllerApi, FindFilteredPropertiesUsingGETPropertyStatesEnum, FindFilteredPropertiesUsingGETAdministrationTypesEnum, PropertyBankAccountControllerApi, ContractProjectionDtoTypeEnum, ContractLegacyControllerApi, PropertyDisplayDtoAdministrationTypeEnum, UnitContractControllerApi, UnitContractProjectionDto, UnitContractProjectionDtoTypeEnum, GetUnitContractsUsingGETContractTypesEnum, GetContractsUsingGETTypeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { showNotification } from 'lib/Notification';
import {
  AccountSelectionContext,
  AccountsType,
  AmountDistributionUpdatersAndDatasourceContext,
  FORM_DEFAULT_VALUES,
  FormValues,
  Property,
} from './ManualExchangeContext';
import { formatDate } from '../../../lib/Utils';
import { translations } from '../translations';
import { LanguageContext } from '../../../contexts/LanguageContext';


const HARDCODED_UNIT_OWNER_TAGS = [
  { label: 'Hausgeld', value: '/1' },
  { label: 'Mehrleistung Verwalter', value: '/3' },
  { label: 'Sonderumlage', value: '/4' },
  { label: 'Abrechnungsspitze VJ', value: '/5' },
  { label: 'Mahngebühren', value: '/6' },
  { label: 'Rücklastschriftgebühren', value: '/7' },
  { label: 'Direkte Belastung', value: '/8' },
];

const HARDCODED_PROPERTY_OWNER_TAGS = [
  { label: 'Zahlungen an Eigentümer', value: '/1' },
  { label: 'Zahlungen von Eigentümer', value: '/2' },
];

const HARDCODED_TENANT_TAGS = [
  { label: 'Mietkaution', value: '/4' },
];

export const usePropertiesAndAccounts = () => {
  const { tl } = useContext(LanguageContext);
  const accountSelectionContext = useContext(AccountSelectionContext);
  const amountDistributionUpdatersAndDatasourceContext = useContext(AmountDistributionUpdatersAndDatasourceContext);

  if (accountSelectionContext === undefined || amountDistributionUpdatersAndDatasourceContext === undefined) {
    throw new Error('usePropertiesAndAccounts must be used within a ManualExchangeContextProvider');
  }

  const { apiConfiguration } = useContext(AuthContext);
  const { goBack } = useContext(OverlayContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const propertyBankAccountControllerApi = new PropertyBankAccountControllerApi(apiConfiguration('accounting'));

  const {
    properties, setProperties, accounts, setAccounts, formValues, setFormValues,
  } = accountSelectionContext;
  const { initializeDistributionValues, setTableDataSource } = amountDistributionUpdatersAndDatasourceContext;
  const { checkPropertyValidity } = useCheckPropertyValidity();

  const getProperties = (administrationTypes: FindFilteredPropertiesUsingGETAdministrationTypesEnum[]) => {
    setProperties(prev => prev.startLoading());
    propertyControllerApi.findFilteredPropertiesUsingGET({
      administrationTypes: administrationTypes as unknown as FindFilteredPropertiesUsingGETAdministrationTypesEnum,
      propertyStates: [FindFilteredPropertiesUsingGETPropertyStatesEnum.READY, FindFilteredPropertiesUsingGETPropertyStatesEnum.OFFBOARDED] as unknown as FindFilteredPropertiesUsingGETPropertyStatesEnum,
      excludeFields: ['bankAccounts', 'managementCompany'],
      size: 10000,
    })
      .then((resp) => {
        setProperties(prev => prev.load(resp.content));
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          key: 'loadPropertiesError',
          message: tl(translations.creationPage.notifications.loadError.message),
          type: 'error',
        });
        setProperties(prev => prev.failed(err));
      });
  };

  const noOrderOption = {
    label: tl(translations.creationPage.sections.section1.noOrder),
    value: -1,
  };


  const getAccountsAndContracts = async (property: Property, contractType: ContractProjectionDtoTypeEnum) => {
    setAccounts(prev => prev.startLoading());

    try {
      const accountResp = await accountControllerApi
        .getAccountsMatchingRegexUsingGET({
          propertyHrIds: [property.propertyHrId],
          // accountCodeRegex: '^(3|440|6|8|9).*',
          accountCodeRegex: '^(3|440|(60/[0-3])|69|895).*', // TODO PMP-21305: Delete this row and uncomment the one above when allocation is fixed
        });

      const bankAccountResp = await propertyBankAccountControllerApi
        .filterPropertyBankAccountsUsingGET({ propertyHrId: property.propertyHrId });
      const initialValue: AccountsType = { counterAccounts: [], bankAccounts: [], accountTags: [] };

      const parsedAccounts = accountResp.reduce((acc, {
        code, name, leaf,
      }) => {
        if (leaf === false) {
          if (code?.startsWith('33/') && contractType === ContractProjectionDtoTypeEnum.OWNER) {
            acc.accountTags!.push({ value: `/2${code.substr(2)}`, label: name! });
          }
          return acc;
        }

        if (code?.startsWith('60/') && contractType === ContractProjectionDtoTypeEnum.TENANT) {
          acc.accountTags.push({ value: code.replace('60/', '/'), label: name });
        }

        if ((code?.startsWith('3') && !code?.startsWith('301')) || code?.startsWith('6')
          || code?.startsWith('8') || code?.startsWith('9') || code?.startsWith('440')) {
          acc.counterAccounts!.push({ label: `${code} - ${name!}`, value: code });
        }

        return acc;
      }, initialValue);

      bankAccountResp.forEach(ba => parsedAccounts.bankAccounts.push({ label: `${ba.accountName} - ${ba.iban}`, value: ba.bankAccountId }));
      parsedAccounts.bankAccounts?.push(noOrderOption);
      if (contractType === ContractProjectionDtoTypeEnum.TENANT) {
        parsedAccounts.accountTags = [...HARDCODED_TENANT_TAGS, ...parsedAccounts.accountTags];
      } else if (contractType === ContractProjectionDtoTypeEnum.PROPERTY_OWNER) {
        parsedAccounts.accountTags = HARDCODED_PROPERTY_OWNER_TAGS;
      } else {
        parsedAccounts.accountTags = [...HARDCODED_UNIT_OWNER_TAGS, ...parsedAccounts.accountTags!];
      }
      setAccounts(prev => prev.load(parsedAccounts));
    } catch (err) {
      console.error(err);
      setAccounts(prev => prev.failed(err));
    }

    if (contractType === ContractProjectionDtoTypeEnum.PROPERTY_OWNER) {
      // /api/v1/contracts -> ContractLegacyController
      contractControllerApi.getContractsUsingGET({
        propertyId: property.id,
        validAtDate: formatDate(new Date(), 'YYYY-MM-DD'),
        type: [contractType] as unknown as GetContractsUsingGETTypeEnum,
      })
        .then((resp) => {
          const activeContracts = resp
            .filter(uc => uc.unitContractId !== undefined && !uc.isVacant)
            .map(c => c as unknown as UnitContractProjectionDto);
          initializeDistributionValues(activeContracts);
          setTableDataSource(prev => prev.load(activeContracts.map(contract => ({ ...contract, key: contract.unitContractId!, selected: true }))));
        })
        .catch((err) => {
          console.error(err);
          showNotification({
            key: 'loadContractsError',
            message: tl(translations.creationPage.notifications.loadError.message),
            type: 'error',
          });
          setTableDataSource(prev => prev.failed());
        });
    } else {
      // /api/v1/unit-contract -> UnitContractController
      unitContractControllerApi.getUnitContractsUsingGET({
        propertyId: property.id,
        atDate: formatDate(new Date(), 'YYYY-MM-DD'),
        contractTypes: [contractType] as unknown as GetUnitContractsUsingGETContractTypesEnum,
      })
        .then((resp) => {
          const activeContracts = resp.filter(uc => uc.unitContractId !== undefined && !uc.isVacant);
          initializeDistributionValues(activeContracts);
          setTableDataSource(prev => prev.load(activeContracts.map(contract => ({ ...contract, key: contract.unitContractId!, selected: true }))));
        })
        .catch((err) => {
          console.error(err);
          showNotification({
            key: 'loadContractsError',
            message: tl(translations.creationPage.notifications.loadError.message),
            type: 'error',
          });
          setTableDataSource(prev => prev.failed());
        });
    }
  };


  const onChangeProperty = (value: Property) => {
    const onValid = () => {
      setFormValues(() => ({
        // if property selection changes then reset other fields
        ...FORM_DEFAULT_VALUES,
        property: (value),
      }));
      setTableDataSource(prev => prev.load([]));
      if (value.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG) {
        onChange('contractType', ContractProjectionDtoTypeEnum.OWNER);
      }
    };

    const onCancel = () => { goBack(); };


    checkPropertyValidity({ propertyHrId: value.propertyHrId, onCancel, onValid });
  };

  const onChange = (key: keyof FormValues, value: FormValues[keyof FormValues]) => {
    if (key === 'property') {
      onChangeProperty(value as Property);
    } else if (key === 'contractType') {
      setFormValues((prev) => {
        getAccountsAndContracts(prev.property, value as ContractProjectionDtoTypeEnum);
        return ({ ...FORM_DEFAULT_VALUES, property: prev.property, [key]: value as UnitContractProjectionDtoTypeEnum });
      });
    } else {
      setFormValues(prev => ({ ...prev, [key]: value }));
    }
  };

  return {
    getProperties,
    properties,
    accounts,
    onChange,
    formValues,
  };
};
