import { Tooltip } from 'antd';
import { OpsCostReportProjection, OpsCostReportProjectionStatusEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { getDotColor } from 'pages/OpsCostReport/services/utils';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

interface Props {
    record: OpsCostReportProjection
}


export const OpsCostReportStatusCell = ({ record }: Props) => {
  const { tl } = useContext(LanguageContext);

  const { status } = record;

  const stateColor = getDotColor(status);

  if ([OpsCostReportProjectionStatusEnum.BOOKED, OpsCostReportProjectionStatusEnum.SENT].includes(status)) {
    const secondElement = status === OpsCostReportProjectionStatusEnum.BOOKED
      ? tl(opsCostTranslation.statusCellDescription.notSent)
      : tl(opsCostTranslation.statusCellDescription.notBooked);
    return (
      <ColorDot color={stateColor as DotColorEnum}>
        <CellWithTwoLines firstElement={tl(opsCostTranslation.statuses[status])} secondElement={secondElement} />
      </ColorDot>
    );
  }

  if (status !== OpsCostReportProjectionStatusEnum.FAILED) {
    return (
      <ColorDot color={stateColor as DotColorEnum}>
        {tl(opsCostTranslation.statuses[status])}
      </ColorDot>
    );
  }

  const tooltip = record.numberOfFailedMessages ? (
    <>
      <div>{`${record.numberOfFailedMessages} ${tl(opsCostTranslation.failedMessagesTooltip)}`}</div>
      <Link target="_blank" to={`/messages?sourceId=${record.id}`}>
        {tl(opsCostTranslation.failedMessagesTooltipLink)}
      </Link>
    </>
  ) : <div>{tl(opsCostTranslation.opsCostReportView.errorMessage)}</div>;

  return (
    <ColorDot color={stateColor as DotColorEnum}>
      <Tooltip placement="bottomRight" title={tooltip}>
        {tl(opsCostTranslation.statuses[status])}
      </Tooltip>
    </ColorDot>
  );
};
