import React, { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from './translations';
import { OverlayContext } from '../../services/OverlayContext/OverlayContext';
import { useValidationErrors } from './services/useValidationErrors';
import { showNotification } from '../../lib/Notification';
import { useExecuteManualExchange } from './services/useExecuteManualExchange';
import { PostingWarningModal } from '../../storybook-components/modals/PostingWarningModal/PostingWarningModal';
import usePostingHGAValidation from '../../storybook-components/modals/PostingWarningModal/usePostingHGAValidation';
import { useOccurrences } from './services/useOccurrences';

export default function ManualExchangeCreationHeaderButtons(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);

  const { isExchangeValid, isVatRateApplied } = useValidationErrors();
  const { onExecute, loading } = useExecuteManualExchange();

  const occurrences = useOccurrences();

  const date = occurrences.occurrenceValues.map(value => value.bookingDate).sort((a, b) => a.diff(b))[0];
  const {
    visible, showModal, onClickProceed, onClickReview, onClickCancel, isHgaClosed,
  } = usePostingHGAValidation({ propertyId: occurrences.formValues.property?.id, date });

  const execute = () => {
    if (!isVatRateApplied()) {
      showNotification({
        key: 'executeExchangeVatError',
        message: tl(translations.creationPage.notifications.vatRateNotApplied),
        type: 'error',
      });
      return;
    }

    if (isExchangeValid()) {
      if (isHgaClosed) {
        showModal(() => onExecute());
      } else {
        onExecute();
      }
    } else {
      showNotification({
        key: 'executeExchangeValidationError',
        message: tl(translations.creationPage.notifications.validationError),
        type: 'error',
      });
    }
  };

  return (
    <div className="buttons">
      <Button
        className="cancel"
        type="ghost"
        loading={loading}
        onClick={goBack}
      >
        {tl(translations.creationPage.cancel)}
      </Button>
      <Button
        className="execute"
        type="primary"
        loading={loading}
        onClick={() => execute()}
      >
        {tl(translations.creationPage.execute)}
      </Button>
      <PostingWarningModal
        visible={visible}
        onCancel={onClickCancel}
        onProceed={onClickProceed}
        onReview={onClickReview}
      >
        {tl(translations.creationPage.title)}
      </PostingWarningModal>
    </div>
  );
}
