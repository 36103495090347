import {
  ContactLegacyDto,
  ContractWkaDto,
  PostingDto, UnitContractProjectionDto,
  UnitProjectionDto,
} from 'api/accounting';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { OpsCostReportDistributionValidationError } from 'services/DistributionCalculation/AccountDistributionCalculationContext';
import { OpsCostReportCreationData, OpsCostReportValidationErrors } from './interfaces';


export const TENANT_ACCOUNT_CODES_REGEX = '^2001\/\\d+\/1\/(?!1$)\\d+';
export const EXPENSE_ACCOUNT_CODE_REGEX = '^8.*';


interface Props {
  children: React.ReactNode
}


export const OpsCostReportContext = createContext<{
  reportData: OpsCostReportCreationData,
  setReportData: React.Dispatch<React.SetStateAction<OpsCostReportCreationData>>,
  formValidationErrors: OpsCostReportValidationErrors,
  setFormValidationErrors: React.Dispatch<React.SetStateAction<OpsCostReportValidationErrors>>,
  allTenantContracts: UnitContractProjectionDto[],
  tenantContracts: DefaultDataInterface<UnitContractProjectionDto[]>,
  setTenantContracts: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  ownerContracts: DefaultDataInterface<UnitContractProjectionDto[]>,
  setOwnerContracts: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  tenantContractWithFlatrateRentOrVacancy: DefaultDataInterface<UnitContractProjectionDto[]>,
  setTenantContractWithFlatrateRentOrVacancy: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  wkas: DefaultDataInterface<ContractWkaDto[]>,
  setWkas: React.Dispatch<React.SetStateAction<DefaultDataInterface<ContractWkaDto[]>>>,
  postings: DefaultDataInterface<PostingDto[]>,
  setPostings: React.Dispatch<React.SetStateAction<DefaultDataInterface<PostingDto[]>>>,
  propertyUnits: DefaultDataInterface<UnitProjectionDto[]>,
  setPropertyUnits: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitProjectionDto[]>>>,
  mailingContacts: DefaultDataInterface<ContactLegacyDto[]>,
  setMailingContacts: React.Dispatch<React.SetStateAction<DefaultDataInterface<ContactLegacyDto[]>>>,
  calculateInProgress: boolean,
  setCalculateInProgress: React.Dispatch<React.SetStateAction<boolean>>,
  areWkaValuesCorrect: Boolean,
  setAreWkaValuesCorrect: React.Dispatch<React.SetStateAction<Boolean>>,
  invalidAccountDistributions: OpsCostReportDistributionValidationError[],
  setInvalidAccountDistributions: React.Dispatch<React.SetStateAction<OpsCostReportDistributionValidationError[]>>,
  noWkaSelectedIssue: Boolean, // If no wka is selected but there is WKA relevant balance
  setNoWkaSelectedIssue: React.Dispatch<React.SetStateAction<Boolean>>,
  selectedTenantContractIds: number[],
  setSelectedTenantContractIds: React.Dispatch<React.SetStateAction<number[]>>,
  calculateDisabled: boolean,
  setCalculateDisabled: React.Dispatch<React.SetStateAction<boolean>>,
} | undefined>(undefined);
OpsCostReportContext.displayName = 'OpsCostReportContext';


export const useOpsCostReportContext = (usageName: string) => {
  const distributionSetContext = useContext(OpsCostReportContext);

  if (distributionSetContext === undefined) {
    throw new Error(`${usageName} must be used within an OpsCostReportContextProvider`);
  }

  return distributionSetContext;
};


const OpsCostReportContextProvider = ({ children }: Props) => {
  const [reportData, setReportData] = useState<OpsCostReportCreationData>({});
  const [formValidationErrors, setFormValidationErrors] = useState<OpsCostReportValidationErrors>({});
  const [tenantContracts, setTenantContracts] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([])); // contracts with breakdown rent
  const [tenantContractWithFlatrateRentOrVacancy, setTenantContractWithFlatrateRentOrVacancy] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([])); // contracts with vacancy or flat rate rent + breakdown rent
  const [ownerContracts, setOwnerContracts] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([])); // owner contracts
  const [wkas, setWkas] = useState(DEFAULT_DATA<ContractWkaDto[]>([]));
  const [postings, setPostings] = useState(DEFAULT_DATA<PostingDto[]>([]));
  const [propertyUnits, setPropertyUnits] = useState<DefaultDataInterface<UnitProjectionDto[]>>(DEFAULT_DATA([]));
  const [mailingContacts, setMailingContacts] = useState<DefaultDataInterface<ContactLegacyDto[]>>(DEFAULT_DATA([]));
  const [calculateInProgress, setCalculateInProgress] = useState(false);
  // wka values are correct if sum(wka values) = sum(wka relevant account balances) for a given time period
  const [areWkaValuesCorrect, setAreWkaValuesCorrect] = useState<Boolean>(true);
  const [noWkaSelectedIssue, setNoWkaSelectedIssue] = useState<Boolean>(false);
  const [selectedTenantContractIds, setSelectedTenantContractIds] = useState<number[]>([]);
  const [calculateDisabled, setCalculateDisabled] = useState(true);
  const [invalidAccountDistributions, setInvalidAccountDistributions] = useState<OpsCostReportDistributionValidationError[]>([]);

  const allTenantContracts = useMemo(() => (
    [...tenantContracts.data, ...tenantContractWithFlatrateRentOrVacancy.data]
  ), [tenantContracts, tenantContractWithFlatrateRentOrVacancy]);

  const opsCostReportContextValue = {
    reportData,
    setReportData,
    formValidationErrors,
    setFormValidationErrors,
    ownerContracts,
    setOwnerContracts,
    allTenantContracts,
    tenantContracts,
    setTenantContracts,
    tenantContractWithFlatrateRentOrVacancy,
    setTenantContractWithFlatrateRentOrVacancy,
    wkas,
    setWkas,
    postings,
    setPostings,
    propertyUnits,
    setPropertyUnits,
    mailingContacts,
    setMailingContacts,
    calculateInProgress,
    setCalculateInProgress,
    areWkaValuesCorrect,
    setAreWkaValuesCorrect,
    noWkaSelectedIssue,
    setNoWkaSelectedIssue,
    selectedTenantContractIds,
    setSelectedTenantContractIds,
    calculateDisabled,
    setCalculateDisabled,
    invalidAccountDistributions,
    setInvalidAccountDistributions,
  };

  return (
    <OpsCostReportContext.Provider value={opsCostReportContextValue}>
      {children}
    </OpsCostReportContext.Provider>
  );
};

export default OpsCostReportContextProvider;
