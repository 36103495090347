import { useState } from 'react';

// eslint-disable-next-line no-shadow
export enum Order {
  'ASC' = 1,
  'DESC' = -1
}

interface UseSortProps {
  initialField: string,
  initialOrder?: Order
}

interface UseSortState {
  field: string,
  order: Order
}

export const useSort = ({ initialField, initialOrder }: UseSortProps) => {
  const [state, setState] = useState<UseSortState>({ field: initialField, order: initialOrder! });
  const setSortField = (field: string) => {
    const order = state.field === field ? state.order * (-1) : 1;
    setState({
      field,
      order,
    });
  };

  return {
    ...state,
    setSortField,
  };
};

useSort.defaultProps = {
  initialOrder: 'ASC',
};
