import React from 'react';
import './Layout.scss';
import { Header } from '../Header/Header';
import { GlobalSearchContextProvider } from '../Header/components/SearchBar/services/GlobalSearchContext';
import { PAGE_CONTENT_ID, PAGE_HEADER_ID } from './data';

/**
 * React.FC automatically defines types for children, etc
 */
export const Layout: React.FC = ({ children }) => (
  <div className="layout">
    <GlobalSearchContextProvider>
      <Header id={PAGE_HEADER_ID} />
      <div id={PAGE_CONTENT_ID} className="content">
        {children}
      </div>
    </GlobalSearchContextProvider>
  </div>
);
