import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import AssetReportTableSummary from './components/AssetReportTableSummary';
import { useAssetReportTable } from './services/useAssetReportTable';
import AssetReportTableSubSummary from './components/AssetReportTableSubSummary';

const AssetReportTable = () => {
  const {
    columns, firstDataSource, firstActiveSum, firstPassiveSum, secondDataSource, secondActiveSum, secondPassiveSum, totalActiveSum, totalPassiveSum,
  } = useAssetReportTable();
  const { tl } = useContext(LanguageContext);

  return (
    <div className="AssetReportTable">
      <Table
        columns={columns}
        dataSource={firstDataSource}
        pagination={false}
        summary={() => (
          <AssetReportTableSubSummary
            title={tl(translations.report.sections.assetReportSection.summaryPropertyAccounts)}
            activeValue={firstActiveSum}
            passiveValue={firstPassiveSum}
            nrTotalColumns={columns.length}
          />
        )}
        rowKey="accountCode"
      />

      <Table
        className="NoHeaderTable"
        columns={columns}
        dataSource={secondDataSource}
        pagination={false}
        summary={() => (
          <>
            <AssetReportTableSubSummary
              title={tl(translations.report.sections.assetReportSection.summaryDeposits)}
              activeValue={secondActiveSum}
              passiveValue={secondPassiveSum}
              nrTotalColumns={columns.length}
            />
            <AssetReportTableSummary
              title={tl(translations.report.sections.assetReportSection.summary)}
              activeValue={totalActiveSum}
              passiveValue={totalPassiveSum}
              nrTotalColumns={columns.length}
            />
          </>
        )}
        rowKey="accountCode"
      />
    </div>
  );
};

export default AssetReportTable;
