import React, { useContext } from 'react';
import { useSectionManager } from 'services/SectionManager/useSectionManager';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import ReactDOM from 'react-dom';
import PropertyOwnerPaymentsTable from './components/PropertyOwnerPaymentsTable';
import { getSectionPortalMountNode } from '../../services/utils';


const PropertyOwnerPaymentsSection = ({ sectionNumber }: {sectionNumber: number}) => {
  const { openSectionId } = useSectionManager();
  const { tl } = useContext(LanguageContext);

  const renderContent = (
    <PrimarySectionWrapper
      sectionId="propertyOwnerPaymentsSection"
      sectionNumber={sectionNumber}
      sectionTitle={tl(translations.report.sections.propertyOwnerPaymentsSection.title)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
    >
      <PropertyOwnerPaymentsTable />
    </PrimarySectionWrapper>
  );

  return (
    <>
      {renderContent}
      {ReactDOM.createPortal(renderContent, getSectionPortalMountNode())}
    </>
  );
};

export default PropertyOwnerPaymentsSection;
