import { useState } from 'react';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';


export const useEconomicPlanAccountEditingSectionsManager = (save, discard) => {
  const [openSectionIndex, setOpenSectionIndex] = useState(0);

  const {
    dirty: isDirty,
    showModal,
  } = useSimpleDirtModal();


  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (openSectionIndex === nextSectionIndex) {
      // if the clicked section is the current one, close the sections
      nextSectionIndex = -1;
    }
    if (isDirty) {
      showModal({
        onProceedCallback: () => setOpenSectionIndex(nextSectionIndex),
        onSave: save,
        onDiscard: discard,
      });
    } else {
      setOpenSectionIndex(nextSectionIndex);
    }
  };

  return {
    openSectionIndex,
    handleOpenSectionChange,
    setOpenSectionIndex,
  };
};
