import React, {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import {
  AccountControllerApi, AccountDto, ContactLegacyControllerApi, ContactProjection,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { translations } from '../../translations';

const AccountsAndContactsStateContext = createContext<
  | {
    accounts: DefaultDataInterface<Array<AccountDto>>,
    contacts: DefaultDataInterface<Array<ContactProjection>>,
  }
  | undefined
>(undefined);
const AccountsAndContactsUpdaterContext = createContext<
  | {
    setAccounts: React.Dispatch<React.SetStateAction<DefaultDataInterface<AccountDto[]>>>,
    setContacts: React.Dispatch<React.SetStateAction<DefaultDataInterface<ContactProjection[]>>>,
  }
  | undefined
>(undefined);


const useAccountsAndContactsInitializer = (propertyHrIds: string[]) => {
  const { tl } = useContext(LanguageContext);
  const context = React.useContext(AccountsAndContactsUpdaterContext);

  if (context === undefined) {
    throw new Error('useManualAllocationExchangeRows must be used within a ManualAllocationExchangeProvider');
  }

  const {
    setAccounts,
    setContacts,
  } = context;


  const { apiConfiguration } = useContext(AuthContext);
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));


  const initialize = () => {
    setAccounts(prev => prev.load([]).startLoading());
    accountControllerApi.getAccountsMatchingRegexUsingGET({
      propertyHrIds,
      /**
       * Accounts that are allowed to be selected as categorization accounts.
       * Relevant BE validator is in AccountRepository:270 #findValidCategorisationAccounts
       */
      accountCodeRegex: '^((2[689]|(?!301)3|4[19]|6|8|9)).*',
    })

    // TODO PMP-18264
      // In the next filter we need to specifically remove account 33 because it is the root account of reserve accounts.
      // In the root account 33 there should never be a booking/exchange.
      // When task PMP-18264 is done remove the `acc.code !== '33'` part of the next filter
      // because account 33 will never be a leaf account so the add.leaf filter will remove it
      .then(response => setAccounts(prev => prev.load(response.filter(acc => acc.leaf && acc.code !== '33'))))
      .catch((error) => {
        console.error(error);
        showNotification({
          key: 'loadAccountsError',
          message: tl(translations.notifications.loadAccountsError),
          type: 'error',
        });
        setAccounts(prev => prev.failed(error));
      });

    setContacts(prev => prev.load([]).startLoading());
    contactControllerApi.getAllContactsUsingGET()
      .then(response => setContacts(prev => prev.load(response)))
      .catch((error) => {
        console.error(error);
        showNotification({
          key: 'loadContactsError',
          message: tl(translations.notifications.loadContactsError),
          type: 'error',
        });
        setContacts(prev => prev.failed(error));
      });
  };


  return {
    initialize,
  };
};

const useAccountsAndContacts = () => {
  const context = React.useContext(AccountsAndContactsStateContext);

  if (context === undefined) {
    throw new Error('useManualAllocationExchangeRows must be used within a ManualAllocationExchangeProvider');
  }

  const {
    accounts,
    contacts,
  } = context;

  return {
    accounts,
    contacts,
  };
};


const AccountsAndContactsProvider = ({ children }: { children: ReactNode }) => {
  const [accounts, setAccounts] = useState(DEFAULT_DATA<Array<AccountDto>>([]));
  const [contacts, setContacts] = useState(DEFAULT_DATA<Array<ContactProjection>>([]));


  const state = useMemo(() => ({ accounts, contacts }), [accounts, contacts]);
  const updaters = useMemo(() => ({ setAccounts, setContacts }), [setAccounts, setContacts]);

  return (
    <AccountsAndContactsStateContext.Provider value={state}>
      <AccountsAndContactsUpdaterContext.Provider value={updaters}>
        {children}
      </AccountsAndContactsUpdaterContext.Provider>
    </AccountsAndContactsStateContext.Provider>
  );
};

export {
  AccountsAndContactsStateContext,
  AccountsAndContactsProvider,
  useAccountsAndContacts,
  useAccountsAndContactsInitializer,
};
