import React from 'react';
import { Table } from 'antd';
import { RowExpandIcon } from 'elements/SmartTable/components/RowExpandIcon/RowExpandIcon';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { UseEconomicPlanAmountsTableColumns } from './UseEconomicPlanAmountsTableColumns';
import { ECONOMIC_PLAN_ACCOUNT_TYPES } from '../../../../services/ECONOMIC_PLAN_ACCOUNT_TYPES';

interface EconomicPlanAmountsTableProps {
  id: string
  loading: boolean
  onChange: (accountCode: string, value: number, accountTypes: ECONOMIC_PLAN_ACCOUNT_TYPES[]) => void,
  dataSource: any[],
  accountTypes: ECONOMIC_PLAN_ACCOUNT_TYPES[],
  economicPlan: any
}

export default function EconomicPlanAmountsTable(props: EconomicPlanAmountsTableProps): JSX.Element {
  const {
    id, loading, onChange, dataSource, accountTypes, economicPlan,
  } = props;


  return (
    <div className={`EconomicPlanAmountsTable ${id}`}>
      <Table
        dataSource={dataSource}
        columns={UseEconomicPlanAmountsTableColumns({ onNewYearChange: onChange, accountTypes, economicPlan })}
        loading={{
          spinning: loading,
          indicator: <TableContentLoadingIndicator />,
        }}
        pagination={false}
        rowKey="accountCode"
        expandable={{
          expandRowByClick: false,
          expandIcon: (prps: any) => <RowExpandIcon needIndentSpaced {...prps} />,
          indentSize: 16,
        }}
      />
    </div>
  );
}
