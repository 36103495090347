import {
  createRef, useContext, useEffect, useMemo, useRef,
} from 'react';
import { mergeListsRemovingDuplicates } from 'lib/utilMergeListsRemovingDuplicates';
import {
  ListSpecialContributionUsingGETOrderEnum, MessageControllerApi, MessageListProjectionDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import { messagesListTranslations } from '../translations';

import { MessagesListContext, useMessagesSelectionContext } from './MessagesListContext';

const PAGE_SIZE = 30;


export default function useMessagesList() {
  const messagesContext = useContext(MessagesListContext);
  if (messagesContext === undefined) {
    throw new Error('useMessagesList must be used within a MessagesListContextProvider');
  }

  const { tl } = useContext(LanguageContext);
  const tableRef = createRef<HTMLDivElement>();
  const abortController = useRef<AbortController | undefined>(undefined);

  const { apiConfiguration } = useContext(AuthContext);
  const {
    setMessages, setTotalMessages, totalMessages, filterState, sortState, messages, setTableHeight, tableHeight,
  } = messagesContext;
  const messageControllerApi = new MessageControllerApi(apiConfiguration('accounting'));

  const {
    showAllSelected,
    selectedRowKeysTotal,
    setSelectedRowKeysCurrent,
  } = useMessagesSelectionContext('useMessagesList');

  useEffect(() => {
    onLoadMessagesList(true);
  }, [sortState, filterState]);

  const calcTableHeight = () => {
    let verticalPosition: number = 0;
    if (tableRef.current) {
      verticalPosition = tableRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
    }
    const containerHeight = document.body.getBoundingClientRect().height;
    return containerHeight - verticalPosition - 40;
  };

  useEffect(() => {
    setTableHeight(calcTableHeight());
  }, [tableRef.current]);

  const onLoadMessagesList = async (resetPage: boolean = false) => {
    setMessages(state => state.startLoading());

    abortController.current?.abort();
    abortController.current = new AbortController();

    const { signal } = abortController.current;
    messageControllerApi.getMessagesUsingGET({
      page: resetPage ? 0 : messages.page,
      size: PAGE_SIZE,
      sort: sortState.field,
      ...filterState,
      order: sortState.order > 0 ? ListSpecialContributionUsingGETOrderEnum.ASC : ListSpecialContributionUsingGETOrderEnum.DESC,
    }, { signal })
      .then((response) => {
        setMessages(state => state.loadPagedNoConcat(
          mergeListsRemovingDuplicates<MessageListProjectionDto>(state.data, response.content, 'id', resetPage),
          resetPage,
          response.last,
        ));
        setTotalMessages(response.totalElements);
        const keysOfNewlyLoadedMessages = response.content?.map(item => item.id) ?? [];
        if (resetPage) {
          setSelectedRowKeysCurrent(keysOfNewlyLoadedMessages.filter(id => selectedRowKeysTotal.includes(id)));
        } else {
          setSelectedRowKeysCurrent(prevKeys => ([
            ...prevKeys,
            ...keysOfNewlyLoadedMessages.filter(key => selectedRowKeysTotal.includes(key)),
          ]));
        }
      })
      .catch(() => {
        if (signal.aborted) return;
        setMessages(state => state.failed());
        setTotalMessages(0);
        showNotification({
          key: 'loadMessagesError',
          message: tl(messagesListTranslations.notifications.loadError.message),
          type: 'error',
        });
      });
  };

  const onRetryMessage = (messageId: number) => {
    messageControllerApi.retryMessageUsingPOST({
      messageId,
    })
      .then((response) => {
        setMessages(prev => prev.loadPaged(prev.data.map((item) => {
          if (item.id === messageId) {
            return {
              ...item,
              ...response,
            };
          }
          return item;
        }), true, prev.lastPage));
        showNotification({
          key: 'retryMessageSuccess',
          message: tl(messagesListTranslations.notifications.retry.retrySuccess.message),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'retryMessageError',
          message: tl(messagesListTranslations.notifications.retry.retryError.message),
          type: 'error',
        });
      });
  };

  const messageDatasource = useMemo(() => {
    if (!showAllSelected) return messages.data;
    return messages.data?.filter(({ id }) => selectedRowKeysTotal.includes(id));
  }, [messages.data, showAllSelected]);


  return {
    setMessages,
    messages,
    totalMessages,
    sortField: sortState.field,
    sortOrder: sortState.order,
    onLoadMessagesList,
    tableHeight,
    onRetryMessage,
    messageDatasource,
  };
}
