import { ReactNode } from 'react';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';


export const getLabelForInput = (label: ReactNode, infoText:ReactNode, required: boolean) => {
  if (!label && !infoText) {
    // otherwise we'll always return a fragment, so we'll always have a label
    return null;
  }

  return (
    <>
      {
        (typeof label === 'string' && label) ? (
          <span>
            {label}
            <span>{`${required ? ' *' : ''}`}</span>
          </span>
        ) : label
      }
      {infoText && <InfoIcon popupText={infoText} size="small" />}
    </>
  );
};
