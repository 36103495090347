import { EconomicPlanUnitResultDto } from 'api/accounting';
import _ from 'lodash';
import { economicPlanTranslations } from 'pages/EconomicPlan/economicPlanTranslations';
import {
  useContext, useEffect,
} from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../../contexts/PropertyListContext';
import { showNotification } from '../../../../lib/Notification';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';

export default function useEconomicPlanOverview() {
  const { tl } = useContext(LanguageContext);
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useEconomicPlanOverview must be used within a EconomicPlanContextProvider');
  }

  const {
    economicPlan, setEconomicPlan, selectedUnitId, setSelectedUnitId, unitEconomicPlan, setUnitEconomicPlan, invalidAmounts,
  } = economicPlanContext;

  const {
    selectedProperty,
    setSelectedPropertyHrId,
  } = useContext(PropertyListContext);


  useEffect(() => {
    onLoadEconomicPlanForUnit(economicPlan?.data);
  }, [selectedUnitId]);

  const onLoadEconomicPlanForUnit = (ecPlan: any) => {
    if (!selectedUnitId || !ecPlan) return;
    const unitResults = ecPlan.results?.unitResults?.filter((unitRes: any) => unitRes.unit.id === selectedUnitId);
    if (!_.isEmpty(unitResults)) {
      setUnitEconomicPlan(unitEconomicPlan.load(convertUnitEconomicPlanToFEModel(unitResults[0])));
    } else {
      showNotification({
        key: 'loadUnitEconomicPlanError',
        message: tl(economicPlanTranslations.notifications.loadUnitEconomicPlanError.message),
        type: 'error',
      });
    }
  };

  const convertUnitEconomicPlanToFEModel = (unitEp: EconomicPlanUnitResultDto) => {
    const result = { ...unitEp };
    if (unitEp.applicableExpenseResults) {
      result.applicableExpenseResults = unitEp.applicableExpenseResults.map(convertUnitEconomicPlanAccountBalanceToFeModel);
    }
    if (unitEp.notApplicableExpenseResults) {
      result.notApplicableExpenseResults = unitEp.notApplicableExpenseResults.map(convertUnitEconomicPlanAccountBalanceToFeModel);
    }
    if (unitEp.incomeResults) {
      result.incomeResults = unitEp.incomeResults.map(convertUnitEconomicPlanAccountBalanceToFeModel);
    }
    if (unitEp.reserveFundResults) {
      result.reserveFundResults = unitEp.reserveFundResults.map(convertUnitEconomicPlanAccountBalanceToFeModel);
    }
    return result;
  };

  const convertUnitEconomicPlanAccountBalanceToFeModel = (data: any) => ({
    ...data,
    accountCode: data.accountCode,
    accountName: data.accountName,
    economicPlanDistributionMode: data.economicPlanDistributionMode,
    houseMoneyDistributionMode: data.houseMoneyDistributionMode,
    totalShares: data.newTotalShares,
    unitShares: data.newUnitShares,
    totalSum: data.newTotalAmount,
    unitSum: data.newUnitAmount,
  });


  return {
    invalidAmounts,
    economicPlan,
    loading: economicPlan.loading,
    setEconomicPlan,
    selectedProperty,
    setSelectedPropertyHrId,
    unitEconomicPlan,
    setSelectedUnitId,
    selectedUnitId,
  };
}
