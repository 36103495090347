import React, { useMemo, useState } from 'react';
import { PostingWarningModal } from 'storybook-components/modals/PostingWarningModal/PostingWarningModal';

interface Props {
  children: React.ReactChild | React.ReactChildren;
}

export interface HgaValidationProps {
  title: string,
  onSave: () => void,
  onReview: () => void,
  onCancel: () => void,
}

export const DEFAULT_MODAL_STATE: HgaValidationProps = {
  title: '',
  onSave: () => {},
  onReview: () => {},
  onCancel: () => {},
};

export const HgaValidationContext = React.createContext<{
  visible: boolean,
  setVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<HgaValidationProps>>,
} | undefined>(undefined);


const HgaValidationContextProvider = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);
  const [modalProps, setModalProps] = useState<HgaValidationProps>(DEFAULT_MODAL_STATE);

  const providerValue = useMemo(() => ({
    visible,
    setVisible,
    setModalProps,
  }), [visible, setVisible, setModalProps]);


  return (
    <HgaValidationContext.Provider value={providerValue}>
      {children}
      <PostingWarningModal
        visible={visible}
        onCancel={modalProps.onCancel}
        onProceed={modalProps.onSave}
        onReview={modalProps.onReview}
      >
        {modalProps.title}
      </PostingWarningModal>
    </HgaValidationContext.Provider>
  );
};

export default HgaValidationContextProvider;
