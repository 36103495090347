import { getEnvVar } from 'lib/getEnvVar';

export interface AnalyticsDashboard {
  // type: string, for now metabase only
  title: { en: string, de: string },
  path?: string,
  resource: string,
  resourceId: number,
}
export interface AnalyticsGroup {
  title: { en: string, de: string },
  pages: AnalyticsDashboard[];
}

export const useAnalyticsDomainDataList = () => {
  const notDefinedAnalytics: AnalyticsGroup = {
    title: { en: 'No analytics groups defined', de: 'Keine Analysegruppen definiert' },
    pages: [{
      title: { en: 'No analytics defined', de: 'Keine Analyse definiert' },
      resource: 'question',
      resourceId: 90,
    }],
  };

  const analyticsGroups = getEnvVar('metabase.analyticsGroups', [notDefinedAnalytics]) as AnalyticsGroup[];

  return {
    analyticsDomainData: analyticsGroups.map((group) => {
      group.pages.forEach((page) => { page.path = `/analytics/${page.resource}/${page.resourceId}`; });
      return group;
    }),
  };
};
