import { useContext } from 'react';
import {
  ExchangeListAndPreviousAllocationValuesContext, PropertyVatEligibilityInfoContext, SelectInputValuesStateContext, ServiceCompanyAllocationUpdatersContext,
} from '../../../../services/ServiceCompanyAllocationContext';

export const usePropertyCell = (key: string) => {
  const selectInputValues = useContext(SelectInputValuesStateContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);
  const exchangeListAndPreviousAllocationValuesContext = useContext(ExchangeListAndPreviousAllocationValuesContext);
  const propertyVatEligibilityInfoContext = useContext(PropertyVatEligibilityInfoContext);

  if (updatersContext === undefined || exchangeListAndPreviousAllocationValuesContext === undefined || selectInputValues === undefined || propertyVatEligibilityInfoContext === undefined) {
    throw new Error('usePropertyCell must be used within a ServiceCompanyAllocationContextProvider!');
  }

  const { setSelectInputValues, setAllocationVatEligibilityValues } = updatersContext;

  const { setExchangeList } = exchangeListAndPreviousAllocationValuesContext;

  const { propertyVatEligibilityInfo } = propertyVatEligibilityInfoContext;

  const currentValue = selectInputValues?.find(alloc => alloc.key === key)?.propertyHrId;

  const onChange = (value: string) => {
    const vatEligibility = value !== undefined ? propertyVatEligibilityInfo.find(p => p.propertyHrId === value)?.vatEligibilityShare : undefined;
    setAllocationVatEligibilityValues(
      prev => prev.map(ve => (
        ve.key === key
          ? { ...ve, vatEligibilityPercentage: vatEligibility }
          : ve
      )),
    );

    setSelectInputValues(prev => prev.map(selectInput => (
      selectInput.key === key
        ? { ...selectInput, propertyHrId: value, accountCode: [] }
        : selectInput
    )));
    setExchangeList((prev) => {
      const newXcs = [...prev.data];
      newXcs.map(xc => (
        xc.key === key ? { ...xc, propertyHrId: value } : xc
      ));
      return prev.load(newXcs);
    });
  };

  return { currentValue, onChange };
};
