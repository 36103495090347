import React from 'react';
import { Table, ConfigProvider } from 'antd';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import InfiniteScroller from 'elements/InfiniteScroller/InfiniteScroller';
import EmptyAgendaItemsListComponent from './EmptyAgendaItemsListComponent/EmptyAgendaItemsListComponent';
import { useAgendaItemsTableColumns } from '../services/useAgendaItemsTableColumns';
import { useAgendaItemsList } from '../services/useAgendaItemsList';
import { AgendaItemDto } from '../../../../../../api/accounting';
import { useBasicDragNDropTable } from '../../../../../../storybook-components/table/TableDragNDropSorting/basic/useBasicDragNDropTable';
import { navigateConcat } from '../../../../../../navigation/History';
import { useLoadAgendaItems } from '../services/useLoadAgendaItems';

type Props = {
  isDisabled: boolean,
};

const AgendaItemsTable = ({ isDisabled }: Props) => {
  const {
    agendaItemsList, setDragNDropValuesDispatcher,
  } = useAgendaItemsList();
  const { onLoadAgendaItemsList } = useLoadAgendaItems();
  const { columns } = useAgendaItemsTableColumns();
  const { onRow, components } = useBasicDragNDropTable<AgendaItemDto>({ setDataSource: setDragNDropValuesDispatcher, isDisabled });

  const contentElement: any = document.querySelector(`.${'AgendaItemsList'} .ant-table-body`) || {};
  return (
    <div className="AgendaItemsList page">
      <ConfigProvider renderEmpty={EmptyAgendaItemsListComponent}>
        <InfiniteScroller
          loadMore={onLoadAgendaItemsList}
          hasMore={!agendaItemsList.lastPage}
          threshold={200}
          loading={agendaItemsList.loading}
          contentElement={contentElement}
        >
          <Table
            className="AgendaItemsListTable"
            columns={columns}
            dataSource={agendaItemsList.data}
            loading={{
              spinning: false,
              indicator: <TableContentLoadingIndicator />,
            }}
            scroll={{ y: 500 }}
            pagination={false}
            // @ts-ignore
            onRow={(record, index) => ({
              ...onRow?.(record, index),
              onClick: () => {
                navigateConcat(`/agenda-item/${record.id}`);
              },
            })}
            components={components}
          />
        </InfiniteScroller>
      </ConfigProvider>
    </div>
  );
};

export default AgendaItemsTable;

AgendaItemsTable.defaultProps = {
  onOrderingChange: undefined,
};
