import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import { translations } from 'elements/Translation/translations';
import { showNotification } from './Notification';
import { ScriptCache } from './scriptCache';


const PDF_JS_VERSION = '2.12.313';


/**
 * This hook takes care of loading the pdfJs package. The ScriptCache adds it as a <script> to
 * the document. When you use this hook, you will still need to write `declare let pdfjsLib: any;`
 * and through this variable you'll be able to use the package (because that's how the variable
 * is named in the imported script, you can check it in the source code below at the URL of .../pdf.min.js)
 */
export const usePdfJsLib = () => {
  const [pdfJsLibLoaded, setPdfJsLibLoaded] = useState(false);
  const { tl } = useContext(LanguageContext);

  useEffect(() => loadScript(), []);

  const loadScript = (callback?: () => void) => {
    // The global scriptcache makes sure I only load the library once
    const cache = ScriptCache({
      pdfjs: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDF_JS_VERSION}/pdf.min.js`,
      // This did not work from the documentation: pdfjsLib.GlobalWorkerOptions.workerSrc = '//the.url'
      pdfjsWorker: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDF_JS_VERSION}/pdf.worker.min.js`,
    });
    cache.pdfjs.onLoad((error: any) => {
      if (error) {
        showNotification({
          key: 'loadPdfError',
          message: tl(translations.notifications.pdfViewer.failedToLoad),
          type: 'error',
        });
        return;
      }

      setPdfJsLibLoaded(true);

      if (callback) {
        callback();
      }
    });
  };

  return { pdfJsLibLoaded };
};
