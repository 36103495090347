export const invoiceListTranslations = {
  title: {
    en: 'Invoice',
    de: 'Rechnungen',
  },
  add: {
    en: 'Add',
    de: 'Hinzufügen',
  },
  upload: {
    en: 'Upload',
    de: 'Rechnungen hochladen',
  },
  manual: {
    en: 'Add manually',
    de: 'Rechnung manuell anlegen',
  },
  email: {
    buttonLabel: {
      en: 'Invoices via Email',
      de: 'Rechnungen via Email',
    },
    subject: {
      en: 'Invoices to Impower',
      de: 'Rechnungen an Impower',
    },
    body: {
      en: 'To create invoices: Send PDF documents to: ',
      de: 'Um Rechnunge anzulegen: Schicken Sie PDF-Dateien per Email an: ',
    },
  },
  delete: {
    en: 'Delete',
    de: 'Löschen',
  },
  revertToDraft: {
    en: 'Revert to draft',
    de: 'Auf Entwurf zurücksetzen',
  },
  stopRecurring: {
    en: 'Stop recurring',
    de: 'Dauerauftrag stoppen',
  },
  reject: {
    en: 'Reject',
    de: 'Ablehnen',
  },
  copy: {
    en: 'Create a copy',
    de: 'Kopie erstellen',
  },
  searchBar: {
    direction: {
      en: 'Direction',
      de: 'Richtung',
    },
    directions: {
      incoming: {
        en: 'Incoming',
        de: 'Eingehend',
      },
      outgoing: {
        en: 'Outgoing',
        de: 'Ausgehend',
      },
    },
    invoiceDateFrom: {
      en: 'Invoice date from',
      de: 'Rechnungsdatum von',
    },
    invoiceDateTo: {
      en: 'Invoice date to',
      de: 'Rechnungsdatum bis',
    },
  },
  table: {
    headers: {
      internalNr: {
        en: 'Property Number',
        de: 'Objekt-Nr',
      },
      administrator: {
        en: 'Administrator',
        de: 'Objektbetreuer',
      },
      accountant: {
        en: 'Accountant',
        de: 'Objektbuchhalter',
      },
      invoiceHrId: {
        en: 'Invoice ID',
        de: 'Rechnungs-ID',
      },
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      invoiceNumber: {
        en: 'Invoice number',
        de: 'Rechnungs-Nr',
      },
      propertyPrivateId: {
        en: 'Property private ID',
        de: 'Objekt private ID',
      },
      businessPartner: {
        en: 'Business partner',
        de: 'Geschäftspartner',
      },
      businessPartnerName: {
        en: 'Business partner name',
        de: 'Geschäftspartner Name',
      },
      counterpartContactId: {
        en: 'Creditor ID',
        de: 'Rechnungssteller ID',
      },
      amount: {
        en: 'Amount',
        de: 'Betrag',
      },
      dueDate: {
        en: 'Due date',
        de: 'Zahlungsziel',
      },
      created: {
        en: 'Created on',
        de: 'Erstellt am',
      },
      createdBy: {
        en: 'Created by',
        de: 'Erstellt von',
      },
      invoiceDate: {
        en: 'Invoice date',
        de: 'Beleg-/Rechnungs-Datum',
      },
      state: {
        en: 'State',
        de: 'Status',
      },
      receiverIban: {
        en: 'Business partner IBAN',
        de: 'Geschäftspartner IBAN',
      },
    },
    states: {
      NEW: {
        en: 'Draft',
        de: 'Entwurf',
      },
      READY_FOR_APPROVAL: {
        en: 'Ready for approval',
        de: 'Bereit zur Freigabe',
      },
      APPROVED: {
        en: 'Approved',
        de: 'Freigegeben',
      },
      SENT_TO_BANK: {
        en: 'Sent to Bank',
        de: 'An Bank übermittelt',
      },
      PAID: {
        en: 'Paid',
        de: 'Bezahlt',
      },
      RECURRING: {
        en: 'Recurring',
        de: 'Dauerauftrag',
      },
      RECURRING_STOPPED: {
        en: 'Recurring stopped',
        de: 'Gestoppt',
      },
      SUPPLIER: {
        en: 'Supplier',
        de: 'Versorger',
      },
      DELETED: {
        en: 'Deleted',
        de: 'Gelöscht',
      },
      OVERDUE: {
        en: 'Overdue',
        de: 'Überfällig',
      },
      CANCELLED: {
        en: 'Cancelled',
        de: 'Abgebrochen',
      },
    },
    lastItem: {
      en: 'No more invoice to load',
      de: 'Kein Rechnungen mehr zu laden',
    },
  },
};
