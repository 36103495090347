import { RouteDefinition } from 'components/RenderRoutes';
import { DOCUMENT_PREVIEW_PATH } from 'pages/DocumentPreview/routes';
import { lazy } from 'react';
import MessageSendingPageWithProvider from './MessageSendingPage';

const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));


export const MESSAGE_SENDING_PATH = '/message-sending';


export const MessageSendingRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: MESSAGE_SENDING_PATH,
      component: MessageSendingPageWithProvider,
      subRoutes: [
        {
          path: `${MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
          component: DocumentPreviewPage,
          type: 'overlay',
        },
      ],
    },
  ],
};
