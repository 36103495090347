import { Key } from 'antd/lib/table/interface';
import {
  Dispatch, SetStateAction, TdHTMLAttributes, useEffect,
} from 'react';
import { useVT } from 'virtualizedtableforantd4';
import VirtualizedDraggableBodyRow from './VirtualizedDraggableBodyRow';

interface Props<T> {
  setDataSource?: Dispatch<SetStateAction<T[]>>,
  onOrderingChange?: (newOrder?: T[]) => void,
  customMoveRow?: (dragItem: {index: number, rowKey: Key}, hoverItem: {index: number, rowKey: Key}) => void,
}

export function useVirtualizedDragNDropTable <T>({ setDataSource, onOrderingChange, customMoveRow } :Props<T>) {
  const [
    components,
    setVT,
  ] = useVT(() => ({ scroll: { y: 500 } }), []);


  useEffect(() => {
    setVT({
      body: {
        row: VirtualizedDraggableBodyRow,
      },
    });
  }, []);

  const moveRow = (dragItem: {index: number, rowKey: Key}, hoverItem: {index: number, rowKey: Key}) => {
    setDataSource((prevData) => {
      const dragRow = prevData[dragItem?.index];
      const newData = [...prevData];
      newData.splice(dragItem?.index, 1);
      newData.splice(hoverItem?.index, 0, dragRow);

      onOrderingChange?.(newData);
      return newData;
    });
  };

  const onRow = (record: T, index: number, movable?: boolean) => ({
    index,
    moveRow: customMoveRow ?? moveRow,
    movable,
  } as TdHTMLAttributes<T>);

  return {
    components,
    onRow,
  };
}
