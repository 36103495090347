
export const usePrintHtml = () => {
  const getPrintHtml = (noHeaderOnFirstPage?: boolean) => {
    const printHtml: string = document.getElementById('print').innerHTML;

    const html = `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="UTF-8"/>
            <link rel="stylesheet" type="text/css" media="all" href="style.css"/>
          </head>
          
          <body ${noHeaderOnFirstPage ? 'print-no-header-first-page' : ''}>
            ${printHtml}
          </body>
        </html>`;

    return html;
  };

  return { getPrintHtml };
};
