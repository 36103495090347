import React, {
  useContext,
  useState,
} from 'react';

import {
  BankingBankAccountControllerApi,
  BankingBankAccountDto,
  ContactLegacyDto,
  ContractContactCreationDtoRoleEnum,
  ContractCreationDto,
  ContractCreationDtoTypeEnum,
  ContractCreationDtoVatRelevanceEnum,
  ContractImportCreateDto,
  ContractImportCreateDtoContractTypeEnum,
  ContractLegacyControllerApi,
  PropertyBankAccountControllerApi,
  PropertyBankAccountCreateDto,
  PropertyBankAccountCreateDtoPropertyAccountTypesEnum,
  PropertyBankAccountCreateDtoStateEnum,
  PropertyCreationDtoAdministrationTypeEnum,
  PropertyLegacyControllerApi,
  PropertyLegacyDto,
  PropertyLegacyDtoVatRelevanceEnum,
  PropertyUpdateDto,
  PropertyUpdateDtoAdministrationTypeEnum,
  PropertyUpdateDtoVatEligibilityTypeEnum,
  PropertyUpdateDtoVatRateCountryCodeEnum,
  PropertyUpdateDtoVatRelevanceEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';

import moment from 'moment';
import { ISO_DATE_FORMAT } from 'lib/Utils';
import {
  ContactLegacyControllerApi,
  SearchContactsUsingGETTypesEnum,
} from '../../../../api/accounting/apis/ContactLegacyControllerApi';
import { BankAccountImportDto } from '../BankAccountsImportSection/interfaces';
import { IProgress } from '../interfaces';
import { executeInParallelBatch } from '../utils/executeInParallelBatch';
import { PropertyImportDto } from './interfaces';
import { translations } from './translations';

const MAX_PAGE_SIZE = 2147483647;

const PROPERTY_ADMINISTRATION_TYPE = {
  WEG: PropertyUpdateDtoAdministrationTypeEnum.WEG,
  MV: PropertyUpdateDtoAdministrationTypeEnum.MV,
  'SEV ohne WEG': PropertyUpdateDtoAdministrationTypeEnum.SEV_MV,
};

const VAT_RELEVANCE_TYPE = {
  'Voll umsatzsteuerpflichtig': PropertyUpdateDtoVatRelevanceEnum.FULLY_RELEVANT,
  'Teilweise umsatzsteuerpflichtig': PropertyUpdateDtoVatRelevanceEnum.PARTIALLY_RELEVANT,
  'Nicht umsatzsteuerpflichtig': PropertyUpdateDtoVatRelevanceEnum.NOT_RELEVANT,
};

const VAT_RATE_COUNTRY_CODE_TYPE = {
  'Schweiz - 7,70%': PropertyUpdateDtoVatRateCountryCodeEnum.CH,
  'Österreich - 20,00%': PropertyUpdateDtoVatRateCountryCodeEnum.AT,
  'Deutschland - 19,00%': PropertyUpdateDtoVatRateCountryCodeEnum.DE,
};

const VAT_ELIGIBILITY_TYPES = {
  Fläche: PropertyUpdateDtoVatEligibilityTypeEnum.AREA,
  'Fester Prozentsatz': PropertyUpdateDtoVatEligibilityTypeEnum.FIXED_RATE,
};

const mapRowToPropertyOwnerContract = (csvRow: any): ContractImportCreateDto => {
  if (!csvRow.prpOwnerContractMailingContactExternalId) {
    return undefined;
  }
  const prpOwnerContract: ContractImportCreateDto = {
    contractType: ContractImportCreateDtoContractTypeEnum.PROPERTY_OWNER,
    contactExternalId: csvRow.prpOwnerContractMailingContactExternalId,
    contractNumber: csvRow.prpOwnerContractId,
    startDate: csvRow.prpOwnerContractStartDate,
    endDate: csvRow.prpOwnerContractEndDate,
    additionalContactExternalIds: [],
  };
  if (csvRow.prpOwnerContact2) {
    prpOwnerContract.additionalContactExternalIds.push(csvRow.prpOwnerContact2);
  }
  if (csvRow.prpOwnerContact3) {
    prpOwnerContract.additionalContactExternalIds.push(csvRow.prpOwnerContact3);
  }
  return prpOwnerContract;
};

const mapRowToPropertyDto = (csvRow: any): PropertyImportDto => ({
  administrationType: PROPERTY_ADMINISTRATION_TYPE[csvRow.administrationType],
  propertyIdInternal: csvRow.propertyIdInternal,
  casaviId: csvRow.portalId,
  name: csvRow.name,
  country: csvRow.country,
  city: csvRow.city,
  street: csvRow.street,
  number: csvRow.number,
  postalCode: csvRow.postalCode,
  economicYearStart: /^\d{4}-\d{2}-\d{2}$/.test(csvRow.economicYearStart) ? new Date(csvRow.economicYearStart || 'T00:00:00.000Z') : null,
  creditorId: csvRow.creditorId,
  managementCompanyReference: csvRow.managementCompanyReference,
  vatRelevance: csvRow.administrationType === PropertyUpdateDtoAdministrationTypeEnum.WEG ? PropertyLegacyDtoVatRelevanceEnum.NOT_RELEVANT : VAT_RELEVANCE_TYPE[csvRow.vatRelevance],
  vatRateCountryCode: VAT_RATE_COUNTRY_CODE_TYPE[csvRow.vatRateCountryCode],
  vatEligibilityType: VAT_ELIGIBILITY_TYPES[csvRow.vatEligibilityType],
  vatEligibility: csvRow.vatEligibility && parseFloat(csvRow.vatEligibility.replace('.', '').replace(',', '.')),
  // this is saved for legacy purposes
  // it is not used to connect data. the propertyIdInternal is used to connect
  // units / etc to the imported property
  externalId: csvRow.propertyReference,
  propertyOwnerContract: mapRowToPropertyOwnerContract(csvRow),
});

export const useImportPropertiesCsv = () => {
  const [properties, setProperties] = useState<PropertyImportDto[]>([]);
  const [propertiesValidationIssues, setPropertiesValidationIssues] = useState<any[]>([]);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const contactController = new ContactLegacyControllerApi(apiConfiguration('accounting'));


  const loadPropertiesFromCsvRows = async (csvRows: any[]) => {
    const props: PropertyImportDto[] = [];
    const validationIssues: any[] = [];
    const managementCompanies = (await contactController.searchContactsUsingGET({ types: SearchContactsUsingGETTypesEnum.MANAGEMENT_COMPANY })).content;

    csvRows.forEach((csvRow) => {
      const propertyDto = mapRowToPropertyDto(csvRow);


      if (csvRow.managementCompanyReference) {
        const selectedCompany = managementCompanies.filter(mc => mc.companyName === csvRow.managementCompanyReference)[0];
        if (!selectedCompany) {
          validationIssues.push({ message: tl(translations.validations.issues.managementCompany), row: csvRow._row });
        }
      } else {
        propertyDto.managementCompany = managementCompanies[0];
      }

      props.push(propertyDto);
      if (!propertyDto.name) {
        validationIssues.push({ message: tl(translations.validations.issues.name), row: csvRow._row });
      }
      if (!propertyDto.propertyIdInternal) {
        validationIssues.push({ message: tl(translations.validations.issues.propertyIdInternal), row: csvRow._row });
      }
      if (!propertyDto.administrationType) {
        validationIssues.push({ message: tl(translations.validations.issues.administrationType), row: csvRow._row });
      }
      if (!/^[A-Z]{2}$/.test(propertyDto.country)) {
        validationIssues.push({ message: tl(translations.validations.issues.country), row: csvRow._row });
      }
      if (!propertyDto.city) {
        validationIssues.push({ message: tl(translations.validations.issues.city), row: csvRow._row });
      }
      if (!propertyDto.street) {
        validationIssues.push({ message: tl(translations.validations.issues.street), row: csvRow._row });
      }
      if (!propertyDto.number) {
        validationIssues.push({ message: tl(translations.validations.issues.number), row: csvRow._row });
      }
      if (!propertyDto.economicYearStart) {
        validationIssues.push({ message: tl(translations.validations.issues.economicYearStart), row: csvRow._row });
      }
      if (!propertyDto.vatRelevance && ([PropertyCreationDtoAdministrationTypeEnum.MV, PropertyCreationDtoAdministrationTypeEnum.SEV, PropertyCreationDtoAdministrationTypeEnum.SEV_MV].includes(propertyDto.administrationType))) {
        validationIssues.push({ message: tl(translations.validations.issues.vatRelevance), row: csvRow._row });
      }
      if (!propertyDto.vatRateCountryCode && propertyDto.vatRelevance !== PropertyUpdateDtoVatRelevanceEnum.NOT_RELEVANT && ([PropertyCreationDtoAdministrationTypeEnum.MV, PropertyCreationDtoAdministrationTypeEnum.SEV, PropertyCreationDtoAdministrationTypeEnum.SEV_MV].includes(propertyDto.administrationType))) {
        validationIssues.push({ message: tl(translations.validations.issues.vatRateCountryCode), row: csvRow._row });
      }
      if (propertyDto.vatEligibilityType === PropertyUpdateDtoVatEligibilityTypeEnum.AREA && propertyDto.vatEligibility) {
        validationIssues.push({ message: tl(translations.validations.issues.vatEligibilityError), row: csvRow._row });
      }
    });
    setPropertiesValidationIssues(validationIssues);
    if (validationIssues.length) {
      setProperties([]);
    } else {
      setProperties(props);
    }
  };
  return {
    loadPropertiesFromCsvRows,
    properties,
    propertiesValidationIssues,
  };
};


export async function importProperties(
  properties: PropertyImportDto[],
  bankAccounts: BankAccountImportDto[],
  propertyControllerApi: PropertyLegacyControllerApi,
  bankingBankAccountControllerApi: BankingBankAccountControllerApi,
  propertyBankAccountControllerApi: PropertyBankAccountControllerApi,
  contactControllerApi: ContactLegacyControllerApi,
  contractControllerApi: ContractLegacyControllerApi,
  setProgress: React.Dispatch<React.SetStateAction<IProgress>>,
) {
  const propertyOwnerContactsMap: Map<string, ContactLegacyDto> = await getContactsMapNeededForPropertyOwners(properties, contactControllerApi);
  await executeInParallelBatch(properties, 1 /* letting loose parallel property creations crashed production :( */, async (property: PropertyImportDto) => {
    const createdProperty: PropertyLegacyDto = await propertyControllerApi.createPropertyUsingPOST({
      propertyCreationDto: {
        ...property,
      },
    });
    const propertyBankAccounts = bankAccounts
      // only this property's bank accounts
      .filter(ba => ba.propertyReference === property.externalId);
    const uniquePropertyBankAccounts = propertyBankAccounts
      // eliminate duplicate ibans on the same property
      .filter((ba, index, a) => a.findIndex(ba2 => (ba2.iban === ba.iban)) === index);

    // check for already existing bank accounts
    let existingBankingBankAccounts: BankingBankAccountDto[] = [];
    if (uniquePropertyBankAccounts.length > 0) {
      existingBankingBankAccounts = await bankingBankAccountControllerApi.getBankingBankAccountsUsingGET({ ibans: uniquePropertyBankAccounts.map(ba => ba.iban) });
    }

    const bankingBankAccountPromises = uniquePropertyBankAccounts
      // eliminate already existing bank accounts
      .filter(pba => existingBankingBankAccounts.findIndex(eba => (pba.iban === eba.iban)) === -1)
      .map(ba => bankingBankAccountControllerApi.createBankingBankAccountUsingPOST({
        bankingBankAccountCreateDto: {
          iban: ba.iban?.replaceAll(' ', ''),
          bic: ba.bic?.replaceAll(' ', ''),
          accountHolderName: ba.accountHolderName,
          accountInstitute: ba.accountInstitute,
        },
      }));
    const createdBankingBankAccounts: BankingBankAccountDto[] = await Promise.all(bankingBankAccountPromises);

    const allBankAccounts: BankingBankAccountDto[] = existingBankingBankAccounts.concat(createdBankingBankAccounts);

    const propertyBankAccountCreateDtos: PropertyBankAccountCreateDto[] = allBankAccounts.map((ba) => {
      const importBa = uniquePropertyBankAccounts.find(pba => pba.iban?.replaceAll(' ', '') === ba.iban);

      const propertyAccountTypes = [...Array.from(new Set(propertyBankAccounts.filter(pba => pba.iban === importBa.iban).flatMap(pba => pba.propertyAccountTypes)))];

      return {
        bankAccountId: ba.id,
        propertyId: createdProperty.id,
        accountName: importBa.accountName ? importBa.accountName : ba.accountHolderName,
        propertyAccountTypes: propertyAccountTypes as unknown as Array<PropertyBankAccountCreateDtoPropertyAccountTypesEnum>,
        state: PropertyBankAccountCreateDtoStateEnum.BOOKED,
      };
    });

    propertyBankAccountControllerApi.createPropertyBankAccountUsingPOST({ propertyBankAccountCreateDtos });

    let updatedProperty: PropertyLegacyDto;
    const addressUpdate = { ...createdProperty.propertyAddress };
    delete addressUpdate.id;
    try {
      const propertyUpdateDto: PropertyUpdateDto = {
        ...(createdProperty as unknown as PropertyUpdateDto),
        ...addressUpdate,
        vatEligibilityType: property.vatEligibilityType,
        vatEligibility: property.vatEligibility,
        vatRelevance: property.vatRelevance,
        vatRateCountryCode: property.vatRateCountryCode,
        externalId: property.externalId,
        casaviId: property.casaviId,
        buildings: [],
        reserveAccountDrafts: [],
        managementCompany: property.managementCompany,
        creditorId: property.creditorId,
        economicYearStart: property.economicYearStart,
      };
      updatedProperty = await propertyControllerApi.updatePropertyUsingPUT({
        propertyUpdateDto,
      });
    } catch (validationResponse) {
      if (validationResponse.response.status === 400) {
        const vRespBody = await validationResponse.response.json();
        updatedProperty = vRespBody.savedEntity;
      } else {
        throw validationResponse;
      }
    }
    property.importedObject = updatedProperty;
    propertyBankAccounts.forEach((ba) => {
      [ba.importedObject] = updatedProperty.bankAccounts.filter(iba => iba.iban === ba.iban);
    });
    if (property.propertyOwnerContract) {
      await savePropertyOwnerContract(propertyOwnerContactsMap, property.propertyOwnerContract, createdProperty.id, contractControllerApi);
    }
    setProgress(prev => ({
      ...prev,
      propertiesImported: prev.propertiesImported + 1,
      bankAccountsImported: prev.bankAccountsImported + propertyBankAccounts.length,
    }));
  });
}

/**
 * produces a map of externalId: contact so it's easy to find the contact of each externalId
 * @param properties list of properties for which we want the contacts based on their externalId. property.
 * @param contactController
 * @returns Map<string, ContactLegacyDto> AKA Map<externalId, contact>
 */
const getContactsMapNeededForPropertyOwners = async (properties: PropertyImportDto[], contactController: ContactLegacyControllerApi) => {
  const contactExternalIds: Array<string> = properties.reduce((curr, property) => {
    const tempCurr = [...curr];
    if (property.propertyOwnerContract) {
      if (!tempCurr.includes(property.propertyOwnerContract.contactExternalId)) {
        tempCurr.push(property.propertyOwnerContract.contactExternalId);
      }
      property.propertyOwnerContract.additionalContactExternalIds.forEach((additionalContactExternalId) => {
        if (!tempCurr.includes(additionalContactExternalId)) {
          tempCurr.push(additionalContactExternalId);
        }
      });
    }
    return tempCurr;
  }, []);
  if (contactExternalIds.length === 0) {
    return Promise.resolve(new Map());
  }

  return contactController.searchContactsUsingGET({ externalIds: contactExternalIds, size: MAX_PAGE_SIZE })
    .then(resp => resp.content.reduce((map, contact) => {
      map[contact.externalId] = contact;
      return map;
    }, new Map()));
};

const savePropertyOwnerContract = (
  contactsMap: Map<string, ContactLegacyDto>,
  propertyOwnerContract: ContractImportCreateDto,
  propertyId: number,
  contractControllerApi: ContractLegacyControllerApi,
) => {
  const externalContactIds = [propertyOwnerContract.contactExternalId]
    .concat(propertyOwnerContract.additionalContactExternalIds || []);

  const contract: ContractCreationDto = {
    propertyId,
    contacts: externalContactIds.map(externalId => ({
      contactId: contactsMap[externalId].id,
      role: [ContractContactCreationDtoRoleEnum.PROPERTY_OWNER],
    })),
    mailingContactId: contactsMap[propertyOwnerContract.contactExternalId].id,
    bankAccountId: contactsMap[propertyOwnerContract.contactExternalId]?.bankAccounts[0]?.id || undefined,
    contractNumber: propertyOwnerContract.contractNumber,
    startDate: propertyOwnerContract.startDate || undefined,
    endDate: propertyOwnerContract.endDate || undefined,
    signedDate: propertyOwnerContract.startDate || moment().format(ISO_DATE_FORMAT),
    type: ContractCreationDtoTypeEnum.PROPERTY_OWNER,
    vatRelevance: ContractCreationDtoVatRelevanceEnum.NOT_RELEVANT,
  };
  contractControllerApi.createContractsUsingPOST({ contractCreationDtos: [contract] });
};
