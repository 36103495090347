import React, { useContext } from 'react';
import _ from 'lodash';
import Button from 'elements/Buttons/Button/Button';
import ComponentVisibilityToggler from 'components/ComponentVisibilityToggler/ComponentVisibilityToggler';
import InfoRow from '../../Inputs/InfoRow/InfoRow';
import AdditionalEntityButton from '../../Buttons/AdditionalEntityButton/AdditionalEntityButton';
import ToggleButton from '../../Buttons/ToggleButton/ToggleButton';
import SubsectionTitle from '../../Inputs/SubsectionTitle/SubsectionTitle';
import InputTypes from '../../Inputs/InputTypes';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import DeleteButton from './DeleteButton';


const InputRenderer = (props: any): any => {
  const { tl } = useContext(LanguageContext);
  const {
    input0, value, validationErrors, onChange, onAddEntity, onToggle, setDeletionKey,
  } = props;

  let disabled: boolean;
  if (input0.props && input0.props.alwaysEnabled) {
    disabled = false;
  } else if (input0.props) {
    disabled = props.disabled || input0.props.disabled;
  } else {
    disabled = props.disabled;
  }
  const isValidationError = !_.isEmpty(validationErrors) && !_.isEmpty(validationErrors[input0.key]);
  const validationState = isValidationError ? 'error' : '';
  const validationMessage = isValidationError ? tl(translations.validations[validationErrors[input0.key]] || translations.validations.defaultError) : '';
  if ('type' in input0) {
    if (input0.type === 'info') {
      return <InfoRow {...input0.props} />;
    }
    if (input0.type === 'addButton') {
      return !disabled
        ? (
          <AdditionalEntityButton
            onClick={() => onAddEntity(input0.key, input0.firstValue)}
            {...input0.props}
          />
        ) : null;
    }
    if (input0.type === 'deleteButton') {
      return <DeleteButton onClick={setDeletionKey} {...input0.props} />;
    }
    if (input0.type === 'toggleButton') {
      return !disabled
        ? <ToggleButton {...input0.props} onClick={() => onToggle(input0.key)} /> : null;
    }
    if (input0.type === 'title') {
      return <SubsectionTitle {...input0.props} />;
    }

    if (input0.type === 'button') {
      return <Button {...input0.props} />;
    }

    if (input0.type === 'collapsibleInput') {
      const noValue = ([undefined, null].includes(value) || _.isEmpty(value)) && ([undefined, null].includes(input0.props.value) && _.isEmpty(input0.props.value));
      if (noValue && disabled) {
        return null;
      }
      const hidable = noValue || (!noValue && !disabled);
      const defaultVisible = !noValue;

      return (
        <ComponentVisibilityToggler
          labelWhenClosed={input0.props.labelWhenClosed!}
          labelWhenOpen={input0.props.labelWhenOpen!}
          onVisibilityChange={input0.props.onVisibilityChange}
          hidable={hidable}
          defaultIsVisible={defaultVisible}
        >
          {React.isValidElement(input0.props.content) ? input0.props.content
            : (
              <InputRenderer
                {...props}
                {...input0.props.content}
                input0={input0.props.content}
              />
            )
          }
        </ComponentVisibilityToggler>
      );
    }

    /**
     * props.disabled = true => VIEW MODE
     * props.disabled = false => EDIT MODE
     */
    const Element = input0.type ? InputTypes[input0.type] : input0.component;

    const onInputChange = (val: any) => {
      onChange(val, input0.key);

      if (input0.props.onChangeSideEffect) {
        input0.props.onChangeSideEffect(val);
      }
    };
    return (
      <Element
        validationState={validationState}
        validationMessage={validationMessage}
        placeholder={input0.props.placeholder}
        value={value}
        onChange={onInputChange}
        {...input0.props}
        disabled={disabled}
      />
    );
  }
  if ('component' in input0) {
    const Comp = input0.component;
    return (
      <Comp
        validationState={validationState}
        validationMessage={validationMessage}
        {...input0.props}
      />
    );
  }
  return null;
};

export default InputRenderer;
