import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';
import { AuthContext } from 'contexts/AuthContext';
import {
  FindFilteredPropertiesUsingGETAdministrationTypesEnum, FindFilteredPropertiesUsingGETPropertyStatesEnum, PropertyDisplayDto, PropertyLegacyControllerApi,
} from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { ProfitAndLossReportContext } from '../../../services/ProfitAndLossReportContext';
import { useProfitAndLossReportForm } from './useProfitAndLossReportForm';
import { translations } from '../../../../translations';

const PAGE_SIZE = 10000;

export const useInitializeProfitAndLossReportEditor = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useInitializeProfitAndLossReportEditor must be used within ProfitAndLossReportContext');
  }

  const {
    setReportData, setPnLCompatibleProperties,
  } = profitAndLossReportContext;

  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);

  const { onCalculateFromArguments } = useProfitAndLossReportForm();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const propertyId = urlSearchParams.get('propertyId');
  const startDate = urlSearchParams.get('startDate');
  const endDate = urlSearchParams.get('endDate');
  const name = urlSearchParams.get('name');
  const instantCalculate = urlSearchParams.get('instantCalculate');

  useEffect(() => {
    initializePnLReportData();
  }, []);

  const initializePnLReportData = () => {
    loadPnLCompatibleProperties()
      .then(resp => setFormDataFromUrl(resp))
      .then(reportData => calculateIfInstantCalculateIsSet(reportData));
  };

  const loadPnLCompatibleProperties = async () => {
    setPnLCompatibleProperties(prev => prev.startLoading());
    return propertyControllerApi.findFilteredPropertiesUsingGET({
      administrationTypes: [FindFilteredPropertiesUsingGETAdministrationTypesEnum.MV, FindFilteredPropertiesUsingGETAdministrationTypesEnum.SEV_MV, FindFilteredPropertiesUsingGETAdministrationTypesEnum.SEV] as unknown as FindFilteredPropertiesUsingGETAdministrationTypesEnum,
      propertyStates: FindFilteredPropertiesUsingGETPropertyStatesEnum.READY,
      excludeFields: ['bankAccounts'],
      size: PAGE_SIZE,
    })
      .then((resp) => {
        setPnLCompatibleProperties(prev => prev.load(resp.content));
        return resp.content;
      })
      .catch((error) => {
        console.error(error);
        showNotification({
          key: 'loadPropertiesError',
          message: tl(translations.notifications.propertyLoadError),
          type: 'error',
        });
        setPnLCompatibleProperties(prev => prev.failed(error));
        return [];
      });
  };

  const setFormDataFromUrl = (properties: Array<PropertyDisplayDto>) => {
    const parsedPropertyId = propertyId ? parseInt(propertyId, 10) : undefined;
    const property = properties.find(p => p.id === parsedPropertyId);

    const newReportData = {
      property,
      startDate: startDate ? moment(startDate) : undefined,
      endDate: endDate ? moment(endDate) : undefined,
      documentName: name ?? undefined,
    };
    setReportData(newReportData);
    return newReportData;
  };

  const calculateIfInstantCalculateIsSet = (reportData) => {
    if (instantCalculate === 'true') {
      onCalculateFromArguments(reportData, true);
    }
  };
};
