import { useContext, useMemo } from 'react';
import { OptionInterface } from '../elements/Inputs/SmartSearch/SmartSearch';
import { PropertyListContext } from '../contexts/PropertyListContext';
import { PropertyDisplayDto } from '../api/accounting/models';


export default function usePropertyManagementCompanyEmployeeOptions(multiple?: boolean) {
  const { selectedDisplayProperty, selectedDisplayPropertyList } = useContext(PropertyListContext);

  const properties = useMemo(() => {
    if (multiple) {
      return selectedDisplayPropertyList.data;
    }
    if (Object.keys(selectedDisplayProperty.data).length !== 0) {
      return [selectedDisplayProperty.data];
    }
    return [];
  }, [selectedDisplayProperty.data, selectedDisplayPropertyList.data, multiple]);

  const managementCompanyEmployees = useMemo(() => {
    let contactOptions: any = [];
    try {
      properties.forEach((property: PropertyDisplayDto) => {
        property!.managementCompany!.linkedByContacts!.forEach((linkedContact: any) => {
          if (contactOptions.filter((contact: OptionInterface) => contact.value === linkedContact.contactId).length === 0) {
            contactOptions.push({
              value: linkedContact.contactId,
              label: `${linkedContact.contactFirstName} ${linkedContact.contactLastName}`,
            });
          }
        });
      });
    } catch (e) {
      contactOptions = [];
    }

    return contactOptions;
  }, [properties]);


  return {
    managementCompanyEmployees,
  };
}
