import { MessageListProjection } from 'api/accounting/models';
import { useContext } from 'react';
import { MessagesListContext } from './MessagesListContext';

export const useMessagesListSort = () => {
  const messagesListContext = useContext(MessagesListContext);

  if (messagesListContext === undefined) {
    throw new Error('useMessagesListSort must be used within a MessagesListContextProvider');
  }

  const { sortState, setSortState } = messagesListContext;

  const onChangeSort = (field: keyof MessageListProjection) => {
    setSortState((currentState) => {
      const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
      return {
        field,
        order,
      };
    });
  };

  return {
    sortField: sortState.field,
    sortOrder: sortState.order,
    onChangeSort,
  };
};
