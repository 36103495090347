import { DocumentLegacyDto, DocumentCreateDtoSourceTypeEnum } from 'api/document';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { isEmpty } from 'lodash';
import { PdfPageAssignerDataContext, PdfPageAssignerUpdatersContext } from 'pages/PdfPageAssigner/services/PdfPageAssignerContext';
import { useContext } from 'react';
import { useLocation, useParams } from 'react-router';
import { useUploadFiles } from 'services/useUploadFiles';
import { translations } from '../../translations';

export const usePdfPageAssignerAddFilesButton = () => {
  const { tl } = useContext(LanguageContext);
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);
  const { propertyId, sourceId, sourceType } = useParams<{ [key: string]: string }>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const uploadDocumentName = queryParams.get('uploadDocumentName');
  const { uploadFiles } = useUploadFiles();

  if (pdfPageAssignerDataContext === undefined || pdfPageAssignerUpdatersContext === undefined) {
    throw new Error('usePdfPageAssignerAddFilesButton must be used within a PdfPageAssignerContextProvider');
  }


  const { loading, originalDocuments } = pdfPageAssignerDataContext;
  const { setLoading, setOriginalDocuments } = pdfPageAssignerUpdatersContext;

  const onAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setLoading(true);

      const files = Array.from(event.target.files).map(f => ({
        file: f,
        sourceType: sourceType as DocumentCreateDtoSourceTypeEnum,
        propertyId: parseInt(propertyId, 10),
        sourceId: parseInt(sourceId, 10),
        name: uploadDocumentName,
      }));

      const promises = uploadFiles(files);

      Promise.allSettled(promises)
        .then((resolutions) => {
          const failedUploads = resolutions.filter(res => res.status === 'rejected');
          // @ts-ignore
          const successfulUploads = resolutions.filter(res => res.status === 'fulfilled').map(res => res.value as DocumentLegacyDto);

          if (!isEmpty(successfulUploads)) {
            setOriginalDocuments(prev => prev.load([
              ...successfulUploads.map(({
                id, name, url, metaData,
              }) => ({
                id, name, url, metaData: metaData ? JSON.parse(metaData) : undefined,
              })),
              ...prev.data,
            ]));

            setTimeout(() => document.querySelector('.PdfPageAssigner .content-wrapper')?.scrollTo(0, 0), 0);
          }
          if (!isEmpty(failedUploads)) {
            showErrorNotificationForFailedUploads(failedUploads);
          } else {
            showNotification({
              type: 'success',
              message: tl(translations.notifications.uploadSuccess),
            });
          }

          setLoading(false);
        });
    }

    // type is `any` because PromiseSettledResult is not exported from the typescript library :(
    const showErrorNotificationForFailedUploads = (failedUploads: any) => {
      const failureReasons = failedUploads.map(promise => promise?.reason?.json?.());
      Promise.all(failureReasons)
        .then((reasons) => {
          const fileNames = reasons.map(({ errorEntityName }) => errorEntityName).filter(Boolean).join(', ');

          if (!isEmpty(fileNames)) {
            showNotification({
              type: 'error',
              message: tl(translations.notifications.uploadError.specific)(fileNames),
            });
          } else {
            showNotification({
              type: 'error',
              message: tl(translations.notifications.uploadError.generic),
            });
          }
        })
        .catch(() => {
          showNotification({
            type: 'error',
            message: tl(translations.notifications.uploadError.generic),
          });
        });
    };
  };


  const noUploadedFiles = originalDocuments.data.length === 0;


  return { onAddFiles, loading, noUploadedFiles };
};
