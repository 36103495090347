export const translations = {
  note: {
    title: {
      en: 'Email details',
      de: 'Email Details',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
  },
};
