import React, {
  useContext,
  useEffect,
} from 'react';

import Button from 'elements/Buttons/Button/Button';
import { Prompt } from 'react-router-dom';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';

import { LanguageContext } from '../../contexts/LanguageContext';
import { PaymentCreationContext } from '../../contexts/PaymentCreationContext';
import { translations } from '../../elements/Translation/translations';
import {
  deleteKey,
  setValue,
} from '../../lib/Utils';
import PaymentForm from './PaymentForm/PaymentForm';

export default function Payment(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const paymentCreationContext: any = useContext(PaymentCreationContext);

  useEffect(() => {
    paymentCreationContext.setPayment(paymentCreationContext.payment.load(paymentCreationContext.defaultContext.payment));
  }, []);

  const onChange = (v: any, key: string) => {
    const newValue: any = { ...paymentCreationContext.payment.data };
    if (!(typeof v === 'undefined' || v === '' || v === null || v.length === 0)) {
      setValue(newValue, key, v);
    } else {
      deleteKey(newValue, key);
    }
    paymentCreationContext.setPayment(paymentCreationContext.payment.load(newValue));
    if (v) {
      paymentCreationContext.setDirty(true);
    }
  };

  const savePaymentButton = (
    <Button
      type="primary"
      onClick={paymentCreationContext.onSavePayment}
    >
      {tl(translations.pages.bankAccount.payment.header.create)}
    </Button>
  );

  return (
    <Page className="Payment">
      <Prompt when={paymentCreationContext.isDirty} message={tl(translations.elements.prompt)} />
      <PageHeader
        title={tl(translations.pages.bankAccount.payment.header.title)}
        rightSideComponent={savePaymentButton}
      />
      <PageContent>
        <PaymentForm
          onChange={onChange}
        />
      </PageContent>
    </Page>
  );
}
