export const translations = {
  bankAccountSubsection: {
    bicWillBeGenerated: {
      en: 'System will generate',
      de: ' Wird durch System generiert',
    },
    bicNotGenerated: {
      en: 'Couldn\'t find BIC, please fill out',
      de: 'Bitte füllen Sie BIC aus',
    },
  },
};
