import { useState } from 'react';

import { useModal } from 'elements/Modals/useModal/useModal';
import {
  PortalDocumentProps,
  TICKET_COLUMN_TYPE,
} from 'storybook-components/TicketIntegrationSection/interfaces';

import { useFaciliooTicketColumns } from './useFaciliooTicketColumns';
import { useFaciliooTickets } from './useFaciliooTickets';

export const useFaciliooTicketIntegrationSection = (connectionId: number,
  flowType: TICKET_COLUMN_TYPE, { propertyId, contactId }:
  {propertyId?: number, contactId?: number}, documentProps?: PortalDocumentProps, getPortalComment?: (string) => string) => {
  const {
    assignTicket, errorMessage, isAssigned, issues, loading, onChangeSearchTerm, unassignTicket, faciliooLink, timestamp,
  } = useFaciliooTickets(connectionId, { propertyId, contactId }, documentProps);
  const [comment, setComment] = useState<string>('');


  const onSubmit = (ticketId: number) => {
    const portalComment = getPortalComment(comment);
    assignTicket(ticketId, portalComment);
    setComment('');
  };

  const commentModal = useModal({ onOk: onSubmit });
  const unassignModal = useModal({ onOk: unassignTicket });

  const { columns } = useFaciliooTicketColumns({
    type: flowType,
    isTicketAssigned: isAssigned,
    showCommentModal: commentModal.showModal,
    showUnassignModal: unassignModal.showModal,
  });

  const onChangeComment = (comm: string) => setComment(comm);

  return {
    columns,
    errorMessage,
    issues,
    loading,
    onChangeSearchTerm,
    comment,
    setComment: onChangeComment,
    faciliooLink,
    timestamp,
    isAssigned,
    onCancelComment: commentModal.onClickCancel,
    onCancelUnassign: unassignModal.onClickCancel,
    onCommentClickOk: commentModal.onClickOk,
    onUnassignClickOk: unassignModal.onClickOk,
    commentModalVisible: commentModal.visible,
    unassignModalVisible: unassignModal.visible,
    onCommentShow: commentModal.showModal,
    onUnassignShow: unassignModal.showModal,
  };
};
