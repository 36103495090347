import { ContractProjectionDtoDunningLevelEnum, UnitContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { useUpdateContractDunningLevel } from 'services/useUpdateContractDunningLevel';
import { Modal } from 'antd';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Action } from 'elements/SmartTable/data';
import { getPropertyAccountBalancePath } from 'pages/Account/routes';
import { getContractEditingRoute, getPropertyEditingRoute } from 'pages/Property/routes';
import { useContactPropertiesContext } from '../../services/ContactPropertiesContext';
import { contactPropertiesTranslations } from '../../translation';


export const useContactPropertiesActionsMenu = (record) => {
  const { tl } = useContext(LanguageContext);
  const { updateContractDunningLevel } = useUpdateContractDunningLevel();
  const { setContactPropertiesDataSource } = useContactPropertiesContext('useContactPropertiesActionsMenu');

  const onRedirectToContract = () => {
    window.open(getContractEditingRoute({ propertyHrId: record.propertyHrId, unitDbId: record.unitId, contractDbId: record.unitContractId }));
  };

  const onRedirectToAccountBalances = () => {
    window.open(getPropertyAccountBalancePath(record.propertyHrId, record.accountCode));
  };

  const onRedirectToProperty = () => {
    window.open(getPropertyEditingRoute(record.propertyHrId));
  };

  const newDunningLevel = record.dunningLevel !== UnitContractProjectionDtoDunningLevelEnum.EXCLUDED
    ? UnitContractProjectionDtoDunningLevelEnum.EXCLUDED
    : null;

  const onUpdateDunningLevel = () => {
    updateContractDunningLevel(record.unitContractId, newDunningLevel)
      .then(([response]) => {
        setContactPropertiesDataSource(prev => prev.load(
          prev.data.map(dataSource => ({
            ...dataSource,
            children: dataSource.children.map(child => ({
              ...child,
              dunningLevel: child.unitContractId === response.unitContractId ? newDunningLevel : child?.dunningLevel,
            })),
          })),
        ));
      });
  };

  const onClickUpdateDunningLevel = () => {
    if (newDunningLevel === UnitContractProjectionDtoDunningLevelEnum.EXCLUDED) {
      Modal.confirm({
        title: tl(contactPropertiesTranslations.actionsMenu.modal.title),
        okText: tl(contactPropertiesTranslations.actionsMenu.modal.ok),
        cancelText: tl(contactPropertiesTranslations.actionsMenu.modal.cancel),
        onOk: onUpdateDunningLevel,
        content: (
          <>
            <p>{tl(contactPropertiesTranslations.actionsMenu.modal.line1)}</p>
            <p>{tl(contactPropertiesTranslations.actionsMenu.modal.line2)}</p>
          </>),
        okButtonProps: { className: 'Button' },
        cancelButtonProps: { className: 'Button' },
        closable: true,
      });
    } else {
      onUpdateDunningLevel();
    }
  };


  const labelForDunningLevelChange = record.dunningLevel !== ContractProjectionDtoDunningLevelEnum.EXCLUDED
    ? tl(contactPropertiesTranslations.actionsMenu.excludeContract)
    : tl(contactPropertiesTranslations.actionsMenu.includeContract);


  const actionsMenuForContract:Action[] = [
    {
      label: tl(contactPropertiesTranslations.actionsMenu.viewContract),
      onAction: onRedirectToContract,
    },
    {
      label: tl(contactPropertiesTranslations.actionsMenu.viewAccountBalance),
      onAction: onRedirectToAccountBalances,
    },
    {
      label: labelForDunningLevelChange,
      onAction: onClickUpdateDunningLevel,
    },
  ];

  const actionsMenuForProperty: Action[] = [
    {
      label: tl(contactPropertiesTranslations.actionsMenu.viewProperty),
      onAction: onRedirectToProperty,

    },
    {
      label: tl(contactPropertiesTranslations.actionsMenu.viewAccountBalance),
      onAction: onRedirectToAccountBalances,
    },

  ];

  if (record.children) {
    return { actionsMenu: actionsMenuForProperty };
  }

  return {
    actionsMenu: actionsMenuForContract,
  };
};
