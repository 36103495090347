import { range } from 'lodash';
import moment from 'moment';

import { translations } from '../translations';
import {
  EndConditionType,
  Errors,
  ErrorType,
  FrequencyType,
  LabelsType,
  STATE_TYPE_KEYS,
  StateType,
} from './interfaces';

export const parseRRule = (rrule: string) => (
  rrule
    .split('RRULE:')[1]
    .split(';')
    .map((keyValueString) => {
      const [key, value] = keyValueString.split('=');

      if (!STATE_TYPE_KEYS.includes(key)) {
        throw new Error(`Invalid RRule: unknown key '${key}'`);
      }

      if (['INTERVAL', 'COUNT', 'BYMONTHDAY', 'BYMONTH'].includes(key)) {
        return { [key]: parseInt(value, 10) };
      }

      return { [key]: value };
    })
    .reduce((acc, keyValueObj) => ({
      ...acc,
      ...keyValueObj,
    }), {}) as unknown as StateType
);

export const getDefaultEndCondition = (rrule: string) => {
  const rruleObj = parseRRule(rrule);

  if (rruleObj.COUNT !== undefined) {
    return 'afterCount';
  }
  if (rruleObj.UNTIL !== undefined) {
    return 'onDate';
  }
  return 'never';
};

export const formatDateForRRule = (date: moment.Moment | undefined) => date?.format('YYYYMMDD').concat('T000000Z');

export const getErrors = (state: StateType, DTSTART: string | undefined, endCondition: EndConditionType, tl: (obj: any) => string) => {
  const errors: Errors = {};
  const requiredError = { message: tl(translations.validation.required), status: 'error' } as ErrorType;
  const positiveNumberError = { message: tl(translations.validation.positive), status: 'error' } as ErrorType;
  const skippedGenerationWarning = { message: tl(translations.validation.skippedGenerationWarning.short), status: 'warning' } as ErrorType;
  const endDateBeforeStartDateError = { message: tl(translations.validation.endDateBeforeStartDateError), status: 'error' } as ErrorType;

  if (endCondition === 'afterCount' && (state.COUNT || 0) <= 0) {
    errors.COUNT = requiredError;
  } else if (endCondition === 'onDate') {
    if (state.UNTIL === undefined) {
      errors.UNTIL = requiredError;
    } else if (DTSTART !== undefined && moment(state.UNTIL).isBefore(moment(DTSTART))) {
      errors.UNTIL = endDateBeforeStartDateError;
    }
  }
  if (DTSTART === undefined) {
    errors.DTSTART = requiredError;
  }
  if (state.INTERVAL === undefined || state.INTERVAL <= 0) {
    errors.INTERVAL = positiveNumberError;
  }
  if (state.BYMONTHDAY > 28) {
    errors.BYMONTHDAY = skippedGenerationWarning;
  }

  return errors;
};

export const FREQUENCY_OPTIONS = (tl: (obj: any) => string) => [
  { label: tl(translations.selectOptions.frequency.yearly), value: 'YEARLY' },
  { label: tl(translations.selectOptions.frequency.monthly), value: 'MONTHLY' },
];

export const VALIDITY_END_OPTIONS = (tl: (obj: any) => string) => [
  // { label: tl(translations.selectOptions.endCondition.after), value: 'afterCount' as EndConditionType },
  { label: tl(translations.selectOptions.endCondition.onDate), value: 'onDate' as EndConditionType },
  { label: tl(translations.selectOptions.endCondition.never), value: 'never' as EndConditionType },
];


export const DAY_OPTIONS = (tl: (obj: any) => string, frequency: FrequencyType) => [
  ...range(1, 32).map(num => ({ label: num, value: num })),
  ...(
    frequency === 'MONTHLY'
      ? [{ label: tl(translations.selectOptions.days.lastDay), value: -1 }]
      : []
  ),
];


export const MONTH_OPTIONS = (tl: (obj: any) => string) => ([
  {
    value: 1,
    label: tl(translations.months.january),
  },
  {
    value: 2,
    label: tl(translations.months.february),
  },
  {
    value: 3,
    label: tl(translations.months.march),
  },
  {
    value: 4,
    label: tl(translations.months.april),
  },
  {
    value: 5,
    label: tl(translations.months.may),
  },
  {
    value: 6,
    label: tl(translations.months.june),
  },
  {
    value: 7,
    label: tl(translations.months.july),
  },
  {
    value: 8,
    label: tl(translations.months.august),
  },
  {
    value: 9,
    label: tl(translations.months.september),
  },
  {
    value: 10,
    label: tl(translations.months.october),
  },
  {
    value: 11,
    label: tl(translations.months.november),
  },
  {
    value: 12,
    label: tl(translations.months.december),
  },
]);


export const getLabels = (tl: (obj: any) => string, labelsProp: LabelsType) => ({
  count: labelsProp?.count ?? tl(translations.defaultLabels.count),
  dayAndMonth: labelsProp?.dayAndMonth ?? tl(translations.defaultLabels.dayAndMonth),
  frequency: labelsProp?.frequency ?? tl(translations.defaultLabels.frequency),
  startDate: labelsProp?.startDate ?? tl(translations.defaultLabels.startDate),
  until: labelsProp?.until ?? tl(translations.defaultLabels.until),
  endCondition: labelsProp?.endCondition ?? tl(translations.defaultLabels.endCondition),
  skippedGenerationWarningDetailed: labelsProp?.skippedGenerationWarningDetailed ?? tl(translations.validation.skippedGenerationWarning.detailed),
} as LabelsType);

export const generateRRule = (props: StateType) => 'RRULE:'.concat(Object.keys(props)
  .filter(key => !!props[key])
  .map(key => `${key}=${props[key]}`)
  .join(';'));
