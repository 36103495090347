import React, { useContext, useEffect, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import Button from 'elements/Buttons/Button/Button';
import { translations } from 'elements/Translation/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { BankAccountListContext } from 'contexts/BankAccountListContext';
import { AccountBookingContext } from 'contexts/AccountBookingContext';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import AccountBookingForm from './components/AccountBookingForm/AccountBookingForm';
import { OverlayContext } from '../../../services/OverlayContext/OverlayContext';
import { PostingWarningModal } from '../../../storybook-components/modals/PostingWarningModal/PostingWarningModal';
import usePostingHGAValidation from '../../../storybook-components/modals/PostingWarningModal/usePostingHGAValidation';
import { useAccountBookingForm } from './useAccountBookingForm';

export default function AccountBooking(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const bankAccountListContext: any = useContext(BankAccountListContext);
  const accountBookingContext: any = useContext(AccountBookingContext);
  const {
    onClearBookings, setPropertyHrId, onBook, bookingPressed, formDirty,
  } = useAccountBookingForm();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const [property, setProperty] = useState<any>();
  const { goBack } = useContext(OverlayContext);

  const date = accountBookingContext.accountBookings.data.map(value => value.postDate).sort((a, b) => a.diff(b))[0];
  const {
    visible,
    showModal,
    onClickProceed,
    isHgaClosed,
    onClickCancel,
    onClickReview,
  } = usePostingHGAValidation({ propertyId: property?.propertyId, date });

  useEffect(() => onClearBookings(), []);
  useEffect(() => {
    if (typeof propertyHrId === 'undefined') return;
    setPropertyHrId(propertyHrId);
    const tempProperty = bankAccountListContext.getBankAccountsForProperty(propertyHrId);
    if (tempProperty) {
      setProperty(tempProperty);
    } else if (!bankAccountListContext.error) {
      bankAccountListContext.onLoadBankAccountsForProperty(propertyHrId);
    }
  }, [propertyHrId, bankAccountListContext]);

  const onClickBook = () => {
    if (isHgaClosed) {
      showModal(() => onBook());
    } else {
      onBook();
    }
  };

  const headerButtons = (
    <>
      <Button
        type="text"
        className="header-button"
        onClick={goBack}
      >
        {tl(translations.pages.accountBooking.header.cancel)}
      </Button>
      <Button
        disabled={bookingPressed}
        type="primary"
        onClick={onClickBook}
      >
        {tl(translations.pages.accountBooking.header.book)}
      </Button>
    </>
  );
  return (
    <Page className="AccountBooking">
      <Prompt message={tl(translations.elements.prompt)} when={formDirty} />
      <PageHeader
        title={tl(translations.pages.accountBooking.title)}
        subtitle={<PropertyInfo property={property} />}
        rightSideComponent={headerButtons}
      />
      <PageContent>
        <AccountBookingForm />
        <PostingWarningModal
          visible={visible}
          onCancel={onClickCancel}
          onProceed={onClickProceed}
          onReview={onClickReview}
        >
          {tl(translations.pages.accountBooking.form.booking)}
        </PostingWarningModal>
      </PageContent>
    </Page>
  );
}
