import { EconomicPlanControllerApi, EconomicPlanDto, EconomicPlanDtoTypeEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { economicPlanPageTranslations } from './EconomicPlanPageTranslations';
import { getEconomicPlanAccountBasedViewPath, getUnitBasedEconomicPlanViewPath } from '../routes';

/**
 * Redirects to economic plan page based on economic plan type (account based / unit figures)
 */
export const EconomicPlanRedirectPage = () => {
  const [redirectPath, setRedirectPath] = useState('');
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const { economicPlanId: economicPlanIdString } = useParams<{ economicPlanId: string }>();
  const economicPlanId = parseInt(economicPlanIdString, 10);

  const loadRedirectPath = () => {
    economicPlanControllerApi.getByIdUsingGET({ id: economicPlanId })
      .then((economicPlan: EconomicPlanDto) => {
        const { propertyHrId, type } = economicPlan;
        if (type === EconomicPlanDtoTypeEnum.ACCOUNT_DISTRIBUTION) {
          setRedirectPath(getEconomicPlanAccountBasedViewPath({ propertyHrId, economicPlanId }));
        } else {
          setRedirectPath(getUnitBasedEconomicPlanViewPath({ propertyHrId, economicPlanId }));
        }
      }).catch(() => {
        showNotification({ type: 'warning', message: tl(economicPlanPageTranslations.economicPlanNotFound) });
        setRedirectPath('/economic-plan');
      });
  };

  useEffect(loadRedirectPath, [economicPlanId]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (<LoadingScreen />);
};
