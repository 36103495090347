export const translations = {
  title: {
    en: 'Units',
    de: 'Einheiten',
  },
  import: { en: 'Import', de: 'Importieren' },
  description: {
    en: 'List of units to upload',
    de: 'Liste der Einheiten zum hochladen.',
  },
  upload: {
    en: 'Upload units',
    de: 'Einheiten hochladen',
  },
  success: {
    en: 'Success',
    de: 'Erfolgreich',
  },
  error: {
    en: 'Failed',
    de: 'Fehler',
  },
  validations: {
    success: {
      en: 'Units have been verified and ready for import.',
      de: 'Einheiten wurden geprüft und sind bereit zum Importieren',
    },
    errors: {
      en: 'Issues found in the CSV',
      de: 'Es wurden Fehlern gefunden',
    },
    warnings: {
      en: 'You are about to import data with warnings',
      de: 'Wir möchten Sie auf die folgenden Punkte hinweisen',
    },
    issues: {
      unitNrSharingDeclaration: {
        en: 'Invalid unit number',
        de: 'Ungültiges Einheitsnummer',
      },
      propertyIdInternal: {
        en: 'Invalid property number',
        de: 'Ungültiges Objektsreferenz',
      },
      unitType: {
        en: 'Invalid unit type',
        de: 'Ungültiges Einheitstyp',
      },
      contactExternalId: {
        en: 'Unit without contact (owner/tenant)',
        de: 'Einheit ohne Kontaktreferenz (Eigentümer/Mieter)',
      },
      duplicateContactReference: {
        en: 'An owner or a tenant cannot reference the same contact twice',
        de: 'Ein Eigentümer oder ein Mieter kann nicht mehrmals mit dem selben Kontakt verbunden sein',
      },
      mandateState: {
        en: 'Invalid mandate state',
        de: 'Ungültiges Lastschriftmandat Status',
      },
      contractDate: {
        en: 'Start date should be before end date',
        de: 'Vertrag: Anfangsdatum soll vor Enddatum sein',
      },
    },
  },
};
