import React, { useContext } from 'react';

import Amount from 'storybook-components/typography/Amount/Amount';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { Tooltip } from 'antd';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { FORMAT } from '../../../elements/SmartTable/SmartTable';
import { formatCurrency } from '../../../lib/Utils';
import {
  ColorDot,
  DotColorEnum,
} from '../../../storybook-components/ColorDot/ColorDot';
import { invoiceListTranslations } from './translations';

function getDotColor(status: string) {
  switch (status) {
  case 'APPROVED':
    return DotColorEnum.BLUE;
  case 'READY_FOR_APPROVAL':
    return DotColorEnum.YELLOW;
  case 'PAID':
    return DotColorEnum.GREEN;
  case 'SENT_TO_BANK':
    return DotColorEnum.CYAN;
  case 'RECURRING':
    return DotColorEnum.PURPLE;
  case 'RECURRING_STOPPED':
    return DotColorEnum.BLACK;
  case 'OVERDUE':
    return DotColorEnum.RED;
  default:
    return DotColorEnum.GRAY;
  }
}

export const useInvoiceListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(invoiceListTranslations.table.headers.state),
      dataKey: 'state',
      render: (text: any, row: any) => (
        <ColorDot color={getDotColor(row.statusRaw)}>
          {row.status}
        </ColorDot>
      ),
    },
    {
      title: tl(invoiceListTranslations.table.headers.property),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(invoiceListTranslations.table.headers.invoiceNumber),
      dataKey: 'invoiceNumber',
    },
    {
      title: tl(invoiceListTranslations.table.headers.invoiceHrId),
      dataKey: 'invoiceHrId',
      defaultVisible: false,
    },
    {
      title: tl(invoiceListTranslations.table.headers.businessPartner),
      dataKey: 'counterpartName',
      defaultWidth: '22.0rem',
      render: (_, record) => (
        <Tooltip title={`${record.counterpartName || '—'}\n${record.counterpartIban || '—'}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.counterpartName || '—'}
              secondElement={record.counterpartIban || '—'}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(invoiceListTranslations.table.headers.amount),
      className: 'currency',
      dataKey: 'totalGross',
      render: (text: string, row: any) => {
        const isInvoice = row.totalGross < 0;
        const sign = isInvoice ? '−' : '+';
        const className = isInvoice ? 'outgoing' : 'incoming';
        return (
          row.totalGross && <Amount><span className={className}>{`${sign} ${formatCurrency(Math.abs(row.totalGross))}`}</span></Amount>
        );
      },
    },
    {
      title: tl(invoiceListTranslations.table.headers.dueDate),
      dataKey: 'paymentTargetDate',
    },
    {
      title: tl(invoiceListTranslations.table.headers.accountant),
      dataKey: 'accountant',
      render: (_, row) => row.accountant,
    },
    {
      title: tl(invoiceListTranslations.table.headers.administrator),
      dataKey: 'administrator',
      render: (_, row) => row.administrator,
      defaultVisible: false,
    },
    {
      title: tl(invoiceListTranslations.table.headers.createdBy),
      dataKey: 'createdBy',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(invoiceListTranslations.table.headers.created),
      dataKey: 'created',
      sortable: true,
      defaultVisible: false,
    },
    {
      title: tl(invoiceListTranslations.table.headers.invoiceDate),
      dataKey: 'invoiceDate',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
  ];
};
