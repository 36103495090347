import { useBoolean } from 'ahooks';
import FileSaver from 'file-saver';
import { useContext } from 'react';
import backend, { endpointUrls } from '../../../../backend_api';
import { showNotification } from '../../../../lib/Notification';
import { translations } from '../../../../elements/Translation/translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { formatDate, printBlob } from '../../../../lib/Utils';

export const useAccountBalanceAndPostingsCsvDownload = () => {
  const [loadingDownload, { setTrue: startLoading, setFalse: stopLoading }] = useBoolean(false);
  const [loadingPrint, { setTrue: startLoadingPrint, setFalse: stopLoadingPrint }] = useBoolean(false);
  const { tl } = useContext(LanguageContext);

  const onDownloadAccountBalanceAndPostingsCsv = (propertyHrId: string, startDate: Date | null, endDate: Date | null, accountCodes: Array<string>, includeCorrectional: boolean) => {
    if (!startDate || !endDate) return;
    startLoading();
    const filename = `${propertyHrId}-KontenSalden.xlsx`;
    backend.getFileByPath(`${endpointUrls.ACCOUNTING}/account-balances/${propertyHrId}/download/xlsx`, {
      startDate: formatDate(startDate, 'YYYY-MM-DD'),
      endDate: formatDate(endDate, 'YYYY-MM-DD'),
      accountCodes,
      includeCorrectional,
    })
      .then((response: any) => {
        if (response.byteLength > 0) {
          const blob: any = new Blob([response], { type: 'application/xlsx' });
          FileSaver.saveAs(blob, filename);
        } else {
          showNotification({
            key: 'noContentDownload',
            message: tl(translations.elements.fileUpload.noContentDownload.message),
            type: 'warning',
          });
        }
        stopLoading();
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          key: 'fileDownloadError',
          message: tl(translations.elements.fileUpload.downloadError.message),
          type: 'error',
        });
        stopLoading();
      });
  };

  const onDownloadAccountBalanceAndPostingsPDF = (propertyHrId: string, startDate: Date | null, endDate: Date | null, accountCodes: Array<string>, printing: boolean, includeCorrectional: boolean) => {
    if (!startDate || !endDate) return;
    printing ? startLoadingPrint() : startLoading();
    const filename = `${propertyHrId}-KontenSalden.pdf`;
    backend.getFileByPath(`${endpointUrls.ACCOUNTING}/account-balances/${propertyHrId}/download/pdf`, {
      startDate: formatDate(startDate, 'YYYY-MM-DD'),
      endDate: formatDate(endDate, 'YYYY-MM-DD'),
      accountCodes,
      includeCorrectional,
    })
      .then((response: any) => {
        if (response.byteLength > 0) {
          const blob: any = new Blob([response], { type: 'application/pdf' });
          if (printing) {
            printBlob(blob);
          } else {
            FileSaver.saveAs(blob, filename);
          }
        } else {
          showNotification({
            key: 'noContentDownload',
            message: tl(translations.elements.fileUpload.noContentDownload.message),
            type: 'warning',
          });
        }
        printing ? stopLoadingPrint() : stopLoading();
      }).catch((e) => {
        console.error(e);
        showNotification({
          key: 'fileDownloadError',
          message: tl(translations.elements.fileUpload.downloadError.message),
          type: 'error',
        });
        printing ? stopLoadingPrint() : stopLoading();
      });
  };

  return {
    onDownloadAccountBalanceAndPostingsCsv,
    onDownloadAccountBalanceAndPostingsPDF,
    loadingDownload,
    loadingPrint,
  };
};
