export const useEpostCountryName = () => {
  const regionNames = new Intl.DisplayNames('de', { type: 'region' });

  const getCountryName = (countryCode: string) => {
    if (!countryCode) {
      return '';
    }
    return regionNames.of(countryCode);
  };

  return {
    getCountryName,
  };
};
