import React, { useContext } from 'react';
import './CompanyData.scss';
import { Card } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import { useLocation } from 'react-router';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../../../../../elements/Translation/translations';
import { PersonEditingContext } from '../../../../../contexts/PersonEditingContext';
import { navigateConcat } from '../../../../../navigation/History';

export default function CompanyData(): JSX.Element {
  const location = useLocation();
  const { tl } = useContext(LanguageContext);
  const contactEditingContext: any = useContext(PersonEditingContext);
  const { contact } = contactEditingContext.data;

  const company: any = contact && contact.linkedToContacts && contact.linkedToContacts.length > 0 && contact.linkedToContacts[0];

  const companyName = company.linkedContactCompanyName ? company.linkedContactCompanyName : '—';
  return (
    <div className="CompanyData component">
      <div className="title">
        {tl(translations.pages.contactDetails.companyData.title)}
      </div>
      <Card className="data-card">
        <div className="basic-info section">
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.companyData.fields.name)}
          </div>
          {new RegExp('view-company.*view-person').test(location.pathname)
            ? (
              <div className="value">
                {companyName}
              </div>
            )
            : (
              <Button
                type="link"
                className="company-name value"
                onClick={() => navigateConcat(`/view-company/${company.linkedContactId}`)}
              >
                {companyName}
              </Button>
            )
          }
        </div>
        <div className="address-info section">
          <div className="label">
            {tl(translations.pages.contactDetails.companyData.fields.address)}
          </div>
          {company.linkedContactAddresses && company.linkedContactAddresses[0]
            && (
              <div className="value address-value">
                <div>{`${company.linkedContactAddresses[0].street} ${company.linkedContactAddresses[0].number}`}</div>
                <div>{`${company.linkedContactAddresses[0].postalCode} ${company.linkedContactAddresses[0].city}`}</div>
                <div>{company.linkedContactAddresses[0].country}</div>
              </div>
            )}
        </div>
        <div className="role section">
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.companyData.fields.role)}
          </div>
          <div className="value">
            {company.role ? company.role : '—'}
          </div>
        </div>
      </Card>
    </div>
  );
}
