import React, { useContext, useState } from 'react';
import moment from 'moment';
import { ISO_DATE_FORMAT, getCurrentEconomicYear, REQUEST_DATE_FORMAT } from 'lib/Utils';
import { useHistory } from 'react-router';
import backend, { endpointUrls } from '../backend_api';
import DEFAULT_DATA from '../lib/data';
import { PropertyListContext } from './PropertyListContext';
import { LanguageContext } from './LanguageContext';
import { translations } from '../elements/Translation/translations';
import { showNotification } from '../lib/Notification';
import { GetAccountBalancesUsingGETAccountTypeEnum } from '../api/accounting';

export const AccountsBalanceContext: any = React.createContext({});

export interface AccountBalance {
  accountCode: string,
  accountName: string,
  accountType: string,
  parentAccountCode?: string,
  economicYear?: string,
  debitSum: number,
  creditSum: number,
  balance: number,
  subAccounts?: AccountBalance[],
}

const defaultFilter: {
  dateRange: [moment.Moment | undefined, moment.Moment | undefined],
  accountDateRange: [moment.Moment | undefined, moment.Moment | undefined] | undefined,
  includeCorrectional: boolean,
} = {
  dateRange: [undefined, undefined],
  accountDateRange: undefined,
  includeCorrectional: false,
};

export function AccountsBalanceProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);
  const { selectedProperty } = useContext(PropertyListContext);
  const [accountsBalances, setAccountsBalances] = useState(DEFAULT_DATA<any>([]));
  const [filterState, setFilterState] = useState(defaultFilter);
  const resetFilter = () => setFilterState(defaultFilter);
  const history = useHistory();

  const getEconomicYear = () => {
    if (!filterState.dateRange[0] || !filterState.dateRange[1]) {
      const startDate: Date = new Date(selectedProperty.data.economicYearStart);

      return getCurrentEconomicYear(startDate);
    }

    return {
      economicYearStart: new Date(moment(filterState.dateRange![0]).format(REQUEST_DATE_FORMAT)),
      economicYearEnd: new Date(moment(filterState.dateRange![1]).format(REQUEST_DATE_FORMAT)),
    };
  };


  const getInitialAccountBalances = () => {
    const { economicYearStart, economicYearEnd } = getEconomicYear();
    getAccountBalancesForAllAccountTypes(economicYearStart, economicYearEnd);
  };

  const getAccountBalancesForAllAccountTypes = (startDate: Date, endDate: Date) => {
    if (!selectedProperty.data?.propertyHrId) return;
    setAccountsBalances(ab => ab.startLoading());
    const promises = [];
    Object.values(GetAccountBalancesUsingGETAccountTypeEnum).forEach((accountType) => {
      promises.push(getAccountBalancesForYear(new Date(startDate.getTime()), new Date(endDate.getTime()), selectedProperty.data?.propertyHrId, accountType));
    });

    startDate.setFullYear(startDate.getFullYear() - 1);
    endDate.setFullYear(endDate.getFullYear() - 1);

    Object.values(GetAccountBalancesUsingGETAccountTypeEnum).forEach((accountType) => {
      promises.push(getAccountBalancesForYear(startDate, endDate, selectedProperty.data.propertyHrId, accountType));
    });

    Promise.all(promises)
      .then((responses: any) => {
        const prevYear = responses.filter((resp: any) => resp.economicYear === startDate.getFullYear()).map((yearData: any) => yearData.accountData).flat();
        const currentYear = responses.filter((resp: any) => resp.economicYear === startDate.getFullYear() + 1).map((yearData: any) => yearData.accountData).flat();
        setAccountsBalances(accountsBalances.load({
          [startDate.getFullYear()]: prevYear,
          [startDate.getFullYear() + 1]: currentYear,
        }));
      })
      .catch((e: any) => {
        console.error('Account balance load error', e);
        setAccountsBalances(ab => ab.failed());
        showNotification({
          key: 'accountsLoadError',
          message: tl(translations.notifications.accountsBalanceContext.loadError),
          type: 'warning',
        });
      });
  };

  // @ts-ignore
  const getAccountBalancesForYear = async (startDate: Date, endDate: Date, propertyHrId: string, accountType: GetAccountBalancesUsingGETAccountTypeEnum) => backend.get(`${endpointUrls.ACCOUNTING}/account-balances/${propertyHrId}`, {
    startDate: moment(startDate).format(REQUEST_DATE_FORMAT),
    endDate: moment(endDate).format(REQUEST_DATE_FORMAT),
    accountType: GetAccountBalancesUsingGETAccountTypeEnum[accountType],
  })
    .then((resp: any) => ({
      economicYear: startDate.getFullYear(),
      accountData: resp,
    }));

  const updateUrlSearchParams = (values: any) => {
    const startDateParam = moment(values[0]).format(ISO_DATE_FORMAT);
    const endDateParam = moment(values[1]).format(ISO_DATE_FORMAT);
    const urlWithParams = `${history.location.pathname}?startDate=${startDateParam}&endDate=${endDateParam}`;
    history.replace(urlWithParams);
  };

  const setRangeValues = (values: any) => {
    updateUrlSearchParams(values);

    setFilterState((state: any) => ({ ...state, dateRange: values }));
  };

  const setAccountDateRange = (values: any) => {
    updateUrlSearchParams(values);

    setFilterState((state: any) => ({ ...state, accountDateRange: values }));
  };

  const resetAccountDateRange = () => {
    setFilterState((state: any) => ({ ...state, accountDateRange: undefined }));
  };

  const setIncludeCorrectional = (value: any) => {
    setFilterState((state: any) => ({ ...state, includeCorrectional: value }));
  };

  return (
    <AccountsBalanceContext.Provider value={{
      accountsBalances,
      getInitialAccountBalances,
      getAccountBalancesForAllAccountTypes,
      filterState,
      setFilterState,
      resetFilter,
      setRangeValues,
      setIncludeCorrectional,
      setAccountDateRange,
      resetAccountDateRange,
      setAccountsBalances,
    }}
    >
      {children}
    </AccountsBalanceContext.Provider>
  );
}
