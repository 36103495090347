export const translations = {
  unassignModal: {
    title: {
      en: 'Remove assignment',
      de: 'Zuordnung entfernen',
    },
    description: {
      en: (appName: string) => `The comment on ${appName} will not be removed automatically.`,
      de: (appName: string) => `Der Kommentar wird nicht automatisch auf ${appName} entfernt.`,
    },
    buttons: {
      unassign: {
        en: 'Unassign',
        de: 'Entfernen',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
  },
};
