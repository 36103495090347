import { StoreApi, createStore } from 'zustand';
import { PropsWithChildren, createContext, useState } from 'react';
import { OpsCostReportManualAmountsStore } from './interfacesOpsCostReportManualAmounts';
import {
  addRow, clearStore, initializeStoreWithExistingValues, onChange, onShowValidationErrors, removeRow,
} from './actionsOpsCostReportManualAmounts';

/**
 * In the html generator we initialize it with the values from the store when they click finalize.
 */
const createOpsCostReportManualAmountsStore = (initValue: OpsCostReportManualAmountsStore['values'], printMode = false) => (
  createStore<OpsCostReportManualAmountsStore>()(set => ({
    values: initValue ?? {}, // more initialization happens in `addRow` action
    validation: {
      values: {},
      showValidationErrors: false,
    },
    printMode,
    actions: {
      initializeStoreWithExistingValues: (...args) => initializeStoreWithExistingValues(set, ...args),
      clearStore: () => clearStore(set),
      addRow: (...args) => addRow(set, ...args),
      removeRow: (...args) => removeRow(set, ...args),
      onChange: (...args) => onChange(set, ...args),
      onShowValidationErrors: () => onShowValidationErrors(set),
    },
  }))
);


export const OpsCostReportManualAmountsContext = createContext<StoreApi<OpsCostReportManualAmountsStore> | null>(null);

export const OpsCostReportManualAmountsStoreProvider = ({ children, initValue, printMode = false }: PropsWithChildren<{ initValue?: OpsCostReportManualAmountsStore['values'], printMode?: boolean }>) => {
  const [store] = useState(() => createOpsCostReportManualAmountsStore(initValue, printMode));

  return (
    <OpsCostReportManualAmountsContext.Provider value={store}>
      {children}
    </OpsCostReportManualAmountsContext.Provider>
  );
};


OpsCostReportManualAmountsStoreProvider.defaultProps = {
  initValue: undefined,
};
