export const translations = {
  lastSync: {
    en: 'Last sync',
    de: 'Letzter Sync',
  },
  property: {
    en: 'Property',
    de: 'Objekt',
  },
  contact: {
    casavi: {
      en: 'Contact',
      de: 'Kontakt',
    },
    facilioo: {
      en: 'Contact',
      de: 'Nutzer',
    },
    etg24: {
      en: 'Contact',
      de: 'Kontakt',
    },
  },
};
