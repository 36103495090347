import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { TabLinkConfig } from 'storybook-components/TabLink/TabLink';
import {translations} from './translations';

export const useTransactionImportsSectionLinks = () => {
  const { tl } = useContext(LanguageContext);
  const transactionImportSectionLinks:TabLinkConfig[] = [{
    label: tl(translations.tabTitles.imports),
    link: '/transaction-imports/manual',
  }, {
    label: tl(translations.tabTitles.connections),
    link: '/transaction-imports/connections',
  }];
  return {
    transactionImportSectionLinks,
  };
};
