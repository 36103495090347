import React from 'react';
import { Tooltip } from 'antd';
import './PopoverTruncatingText.scss';
import { TooltipPlacement } from 'antd/lib/tooltip';


interface PopoverTruncatingTextProps {
  value: string;
  tooltipMessage?: string,
  containerClassName?: string;
  placement?: TooltipPlacement;
  className?: string;
}


const PopoverTruncatingText = ({
  value,
  tooltipMessage,
  placement,
  className,
  containerClassName,
} : PopoverTruncatingTextProps) => (
  <Tooltip
    getPopupContainer={() => (containerClassName && document.getElementsByClassName(containerClassName)?.[0] as HTMLElement) ?? document.body}
    title={tooltipMessage ?? value}
    placement={placement}
    className={`PopoverTruncatingText ${className ?? ''}`}
    overlayInnerStyle={{
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: '2.2rem',
    }}
  >
    {value}
  </Tooltip>
);

export default PopoverTruncatingText;


PopoverTruncatingText.defaultProps = {
  placement: 'topLeft',
  tooltipMessage: undefined,
  className: undefined,
  containerClassName: undefined,
};
