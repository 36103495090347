import './ResolutionRecordEditing.scss';

import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ResolutionRecordContext } from 'contexts/ResolutionRecordContext';
import AdditionalEntityButton
  from 'elements/Buttons/AdditionalEntityButton/AdditionalEntityButton';
import Button from 'elements/Buttons/Button/Button';
import ToggleButton from 'elements/Buttons/ToggleButton/ToggleButton';
import SubSectionTitle
  from 'elements/FormElements/SubSectionTitle/SubSectionTitle';
import DateInput from 'elements/Inputs/DateInput/DateInput';
import NumberInput from 'elements/Inputs/NumberInput/NumberInput';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import {
  deleteKey,
  range,
  setValue,
} from 'lib/Utils';
import {
  Prompt,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';

import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import {
  ResolutionRecordDtoVoteMajorityKeyEnum,
} from '../../../api/accounting';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../contexts/PropertyListContext';
import RichTextEditor
  from '../../../elements/Inputs/RichTextEditor/RichTextEditor';
import PropertyInfo from '../../../elements/PropertyInfo/PropertyInfo';
import { translations } from '../../../elements/Translation/translations';
import {
  OverlayContext,
} from '../../../services/OverlayContext/OverlayContext';
import { removeLastPartOfUrl } from '../../Property/PropertyEditing/util/utils';
import { translations as resolutionRecordTranslations } from '../translations';

export default function ResolutionRecordEditing(): JSX.Element {
  const { tl, language } = useContext(LanguageContext);
  const propertyListContext: any = useContext(PropertyListContext);
  const resolutionRecordContext: any = useContext(ResolutionRecordContext);
  const location = useLocation();
  const history = useHistory();
  const { propertyHrId, serialNr } = useParams<{ propertyHrId: string, serialNr: string }>();
  const [showLegalEvent, setShowLegalEvents] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const { goBack } = useContext(OverlayContext);
  const { resolutionRecord } = resolutionRecordContext;

  useEffect(() => {
    if (propertyHrId) {
      propertyListContext.setSelectedPropertyHrId(propertyHrId);
    }
  }, [propertyHrId]);

  useEffect(() => {
    if (propertyListContext.selectedProperty.loaded) {
      resolutionRecordContext.setPropertyId(propertyListContext.selectedProperty.data.id);
    }
  }, [propertyListContext.selectedProperty]);

  useEffect(() => {
    if (serialNr) {
      setViewMode(true);
      if (resolutionRecordContext.propertyId) resolutionRecordContext.onLoadResolutionRecordBySerialNr(serialNr);
    }
  }, [serialNr, resolutionRecordContext.propertyId]);

  useEffect(() => {
    if (serialNr && resolutionRecordContext.resolutionRecord.validationErrors && Object.keys(resolutionRecordContext.resolutionRecord.validationErrors).length > 0) {
      setViewMode(false);
    }
  }, [resolutionRecordContext.resolutionRecord]);

  useEffect(() => {
    resolutionRecordContext.onClearResolutionRecord();
    return () => {
      resolutionRecordContext.onClearResolutionRecord();
    };
  }, []);

  const voteMajorityKeyOptions = useMemo(() => Object.values(ResolutionRecordDtoVoteMajorityKeyEnum).map((v: string) => ({
    value: v,
    label: tl(translations.pages.resolutionRecordEditing.section3.voteMajorityKey[v]),
  })), [language]);

  const expandLegalEventSection = () => {
    setShowLegalEvents(!showLegalEvent);
  };

  const onChange = (v: any, key: string) => {
    resolutionRecordContext.setResolutionRecord((resolutionRecord) => {
      const newValue: any = { ...resolutionRecord.data };
      if (newValue[key] !== v) {
        if (!(typeof v === 'undefined' || v === '' || v === null || v.length === 0)) {
          setValue(newValue, key, v);
        } else {
          deleteKey(newValue, key);
        }
        return { ...resolutionRecord, data: newValue, saved: false };
      }

      return { ...resolutionRecord, saved: true };
    });
  };

  const onAddLegalEvent = () => {
    resolutionRecordContext.setNumberOfLegalEvents(resolutionRecordContext.numberOfLegalEvents + 1);
    const newValue: any = { ...resolutionRecordContext.resolutionRecord.data };
    newValue.legalEvents.push({});
    resolutionRecordContext.setResolutionRecord(resolutionRecordContext.resolutionRecord.load(newValue));
  };

  const dynamicFields = (eventNumber: number) => {
    if (resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].legalEventType === 'COURT_DECISION') {
      return (
        <>
          <div className="input-row legal-event-row">
            <TextInput
              label={tl(translations.pages.resolutionRecordEditing.section4.verdict)}
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].resolution`)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].resolution}
              className="input"
              disabled={viewMode}
            />
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].court`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.court)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].court}
              className="input"
              disabled={viewMode}
            />
          </div>
          <div className="input-row legal-event-row">
            <DateInput
              onChange={value => onChange(value, `legalEvents[${eventNumber}].date`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.date)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].date}
              className="input"
              disabled={viewMode}
            />
            <NumberInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].docketNumber`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.docketNumber)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].docketNumber}
              className="input"
              disabled={viewMode}
            />
          </div>
          <div className="input-row legal-event-row">
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].involvedParties`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.involvedParties)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].involvedParties}
              className="input"
              disabled={viewMode}
            />
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].verdictCosts`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.verdictCosts)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].verdictCosts}
              className="input"
              disabled={viewMode}
            />
          </div>
          <div className="input-row legal-event-row">
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].verdictEnforceability`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.verdictEnforceability)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].verdictEnforceability}
              className="input"
              disabled={viewMode}
            />
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].note`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.note)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].note}
              className="input"
              disabled={viewMode}
            />
          </div>
        </>
      );
    }
    if (resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].legalEventType === 'CHALLENGE') {
      return (
        <>
          <div className="input-row legal-event-row">
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].challengingParties`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.challengingParties)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].challengingParties}
              className="input"
              disabled={viewMode}
            />
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].challengeReason`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.challengeReason)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].challengeReason}
              className="input"
              disabled={viewMode}
            />
          </div>
        </>
      );
    }
    if (resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].legalEventType === 'OTHER') {
      return (
        <div className="input-row legal-event-row">
          <TextInput
            onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].note`)}
            label={tl(translations.pages.resolutionRecordEditing.section4.note)}
            value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].note}
            className="input"
            disabled={viewMode}
          />
          <div
            className="input"
          />
        </div>
      );
    }
    return <div />;
  };

  const isValidationError = (key: any): boolean => !!resolutionRecordContext.resolutionRecord.validationErrors && !!resolutionRecordContext.resolutionRecord.validationErrors[key];

  const legalEventFields = (numberOfLegalEvents: number) => {
    const eventFields = range(0, numberOfLegalEvents).map(eventNumber => (
      <div key={`legal-event-${eventNumber}`} className="legal-event">
        <div className="number-box">
          <div className="number">
            {(eventNumber + 1)}
          </div>
        </div>
        <div className="legal-event-inputs">
          <div className="input-row legal-event-row">
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].manager`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.manager)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].manager}
              className="input"
              disabled={viewMode}
            />
            <TextInput
              onChange={(value: any) => onChange(value, `legalEvents[${eventNumber}].creatingPerson`)}
              label={tl(translations.pages.resolutionRecordEditing.section4.createdBy)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].creatingPerson}
              className="input"
              disabled={viewMode}
            />
          </div>
          <div className="input-row legal-event-row">
            <DateInput
              label={tl(translations.pages.resolutionRecordEditing.section4.dateOfCreation)}
              onChange={value => onChange(value, `legalEvents[${eventNumber}].creationDate`)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].creationDate}
              className="input"
              disabled={viewMode}
            />
            <SelectInput
              onChange={value => onChange(value, `legalEvents[${eventNumber}].legalEventType`)}
              value={resolutionRecordContext.resolutionRecord.data.legalEvents[eventNumber].legalEventType}
              label={tl(translations.pages.resolutionRecordEditing.section4.eventType)}
              required
              options={[
                {
                  value: 'COURT_DECISION',
                  label: tl(translations.pages.resolutionRecordEditing.section4.eventTypes.courtDecision),
                },
                {
                  value: 'CHALLENGE',
                  label: tl(translations.pages.resolutionRecordEditing.section4.eventTypes.challenge),
                },
                {
                  value: 'OTHER',
                  label: tl(translations.pages.resolutionRecordEditing.section4.eventTypes.else),
                },
              ]}
              className="input"
              disabled={viewMode}
              validationState={isValidationError(`legalEvents[${eventNumber}].legalEventType`) ? 'error' : 'success'}
              validationMessage={isValidationError(`legalEvents[${eventNumber}].legalEventType`) ? tl(translations.validations.notNull) : ''}
            />
          </div>
          {dynamicFields(eventNumber)}
        </div>
      </div>
    ));
    if (!viewMode) {
      eventFields.push(
        <div key="add-record" className="input-row">
          <AdditionalEntityButton
            onClick={onAddLegalEvent}
            label={tl(translations.pages.resolutionRecordEditing.section4.add)}
            disabled={viewMode}
          />
        </div>,
      );
    }
    return eventFields;
  };

  const saveButton = (
    <Button
      className="header-button"
      type="primary"
      onClick={async () => {
        if (serialNr) setViewMode(true);
        const response = await resolutionRecordContext.onSaveResolutionRecord(serialNr);
        if (serialNr && serialNr !== response.resolutionRecordNumber) history.push(`${removeLastPartOfUrl(location.pathname)}/${response.resolutionRecordNumber}`);
        else if (!serialNr) goBack();
      }}
      loading={resolutionRecord.loading}
    >
      {tl(translations.pages.resolutionRecordEditing.save)}
    </Button>
  );

  const onClickEdit = () => {
    setViewMode(false);
  };
  const editButton = (
    <Button
      className="header-button"
      type="primary"
      onClick={onClickEdit}
    >
      {tl(translations.pages.resolutionRecordEditing.edit)}
    </Button>
  );

  return (
    <Page id="scrollElement" className="ResolutionRecordEditing">
      <Prompt
        message={tl(translations.elements.prompt)}
        when={!resolutionRecordContext.resolutionRecord.saved}
      />
      <PageHeader
        title={tl(translations.pages.resolutionRecordEditing.titleCreate)}
        subtitle={<PropertyInfo property={propertyListContext.selectedProperty.data} />}
        rightSideComponent={viewMode ? editButton : saveButton}
      />
      <PageContent>
        <div className="section">
          <SubSectionTitle
            title={tl(translations.pages.resolutionRecordEditing.section1.sectionTitle)}
          />
          <div className="input-row">
            <TextInput
              onChange={value => onChange(value, 'manager')}
              label={tl(translations.pages.resolutionRecordEditing.section1.manager)}
              value={resolutionRecordContext.resolutionRecord.data.manager}
              className="input"
              disabled={viewMode}
            />
            <TextInput
              onChange={value => onChange(value, 'meetingLeader')}
              label={tl(translations.pages.resolutionRecordEditing.section1.leader)}
              value={resolutionRecordContext.resolutionRecord.data.meetingLeader}
              className="input"
              disabled={viewMode}
            />
          </div>
          <div className="input-row">
            <TextInput
              onChange={value => onChange(value, 'minuteTaker')}
              label={tl(translations.pages.resolutionRecordEditing.section1.minuteTaker)}
              value={resolutionRecordContext.resolutionRecord.data.minuteTaker}
              className="input"
              disabled={viewMode}
            />
            <div
              className="input"
            />
          </div>
          <SubSectionTitle
            title={tl(translations.pages.resolutionRecordEditing.section2.sectionTitle)}
          />
          <div className="input-row">
            <NumberInput
              onChange={value => onChange(value, 'resolutionRecordNumber')}
              label={tl(translations.pages.resolutionRecordEditing.section2.resolutionNumber)}
              value={resolutionRecordContext.resolutionRecord.data.resolutionRecordNumber}
              validationState={isValidationError('resolutionRecordNumber') ? 'error' : 'success'}
              validationMessage={isValidationError('resolutionRecordNumber') ? tl(resolutionRecordTranslations.validations[resolutionRecordContext.resolutionRecord.validationErrors.resolutionRecordNumber] || resolutionRecordTranslations.validations.defaultError) : ''}
              className="input"
              required
              disabled={viewMode}
            />
            <SelectInput
              onChange={value => onChange(value, 'meetingType')}
              value={resolutionRecordContext.resolutionRecord.data.meetingType}
              label={tl(translations.pages.resolutionRecordEditing.section2.meetingType)}
              validationState={isValidationError('meetingType') ? 'error' : 'success'}
              validationMessage={isValidationError('meetingType') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.meetingType] || resolutionRecordTranslations.validations.defaultError) : ''}
              options={[
                {
                  value: 'REGULAR',
                  label: tl(translations.pages.resolutionRecordEditing.section2.meetingTypes.ordinary),
                },
                {
                  value: 'EXTRAORDINARY',
                  label: tl(translations.pages.resolutionRecordEditing.section2.meetingTypes.extraordinary),
                },
                {
                  value: 'CIRCULAR_RESOLUTION',
                  label: tl(translations.pages.resolutionRecordEditing.section2.meetingTypes.circular),
                },
              ]}
              className="input"
              disabled={viewMode}
            />
          </div>
          <div className="input-row">
            <DateInput
              onChange={value => onChange(value, 'date')}
              label={tl(translations.pages.resolutionRecordEditing.section2.date)}
              value={resolutionRecordContext.resolutionRecord.data.date}
              className="input"
              disabled={viewMode}
              required
              validationState={isValidationError('date') ? 'error' : 'success'}
              validationMessage={isValidationError('date') ? tl(translations.validations.invalidDate) : ''}
            />
            <TextInput
              onChange={value => onChange(value, 'location')}
              label={tl(translations.pages.resolutionRecordEditing.section2.location)}
              value={resolutionRecordContext.resolutionRecord.data.location}
              className="input"
              disabled={viewMode}
              validationState={isValidationError('location') ? 'error' : 'success'}
              validationMessage={isValidationError('location') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.location] || resolutionRecordTranslations.validations.defaultError) : ''}
            />
          </div>
          <div className="input-row">
            <TextInput
              onChange={value => onChange(value, 'resolutionTitle')}
              label={tl(translations.pages.resolutionRecordEditing.section2.resolutionTitle)}
              value={resolutionRecordContext.resolutionRecord.data.resolutionTitle}
              className="input"
              disabled={viewMode}
              required
              validationState={isValidationError('resolutionTitle') ? 'error' : 'success'}
              validationMessage={isValidationError('resolutionTitle') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.resolutionTitle] || resolutionRecordTranslations.validations.defaultError) : ''}
            />
            <TextInput
              onChange={value => onChange(value, 'agendaItem')}
              label={tl(translations.pages.resolutionRecordEditing.section2.agendaItem)}
              value={resolutionRecordContext.resolutionRecord.data.agendaItem}
              className="input"
              disabled={viewMode}
              validationState={isValidationError('agendaItem') ? 'error' : 'success'}
              validationMessage={isValidationError('agendaItem') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.agendaItem] || resolutionRecordTranslations.validations.defaultError) : ''}
            />
          </div>
          <div className="input-row">
            <RichTextEditor
              onChange={value => onChange(value, 'resolutionText')}
              label={tl(translations.pages.resolutionRecordEditing.section2.resolutionText)}
              value={resolutionRecordContext.resolutionRecord.data.resolutionText}
              disabled={viewMode}
              required
              validationState={isValidationError('resolutionText') ? 'error' : 'success'}
              validationMessage={isValidationError('resolutionText') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.resolutionText] || resolutionRecordTranslations.validations.defaultError) : ''}
            />
          </div>
          <SubSectionTitle
            title={tl(translations.pages.resolutionRecordEditing.section3.sectionTitle)}
          />
          <div className="input-row">
            <SelectInput
              onChange={value => onChange(value, 'voteKey')}
              value={resolutionRecordContext.resolutionRecord.data.voteKey}
              label={tl(translations.pages.resolutionRecordEditing.section3.voteKey)}
              validationState={isValidationError('voteKey') ? 'error' : 'success'}
              validationMessage={isValidationError('voteKey') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.voteKey] || resolutionRecordTranslations.validations.defaultError) : ''}
              options={[
                {
                  value: 'MEA',
                  label: tl(translations.pages.resolutionRecordEditing.section3.countingMethods.mea),
                },
                {
                  value: 'HEAD',
                  label: tl(translations.pages.resolutionRecordEditing.section3.countingMethods.peopleHeadCount),
                },
                {
                  value: 'UNIT',
                  label: tl(translations.pages.resolutionRecordEditing.section3.countingMethods.numberOfUnits),
                },
              ]}
              className="input"
              disabled={viewMode}
            />
            <SelectInput
              onChange={value => onChange(value, 'voteMajorityKey')}
              value={resolutionRecordContext.resolutionRecord.data.voteMajorityKey}
              label={tl(translations.pages.resolutionRecordEditing.section3.voteMajorityKey.label)}
              options={voteMajorityKeyOptions}
              className="input"
              disabled={viewMode}
              validationState={isValidationError('voteMajorityKey') ? 'error' : 'success'}
              validationMessage={isValidationError('voteMajorityKey') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.voteMajorityKey] || resolutionRecordTranslations.validations.defaultError) : ''}
            />
          </div>
          <div className="input-row">
            <div className="half-row">
              <NumberInput
                value={resolutionRecordContext.resolutionRecord.data.presentVotes}
                onChange={value => onChange(value, 'presentVotes')}
                label={tl(translations.pages.resolutionRecordEditing.section3.present)}
                className="input"
                min={0}
                disabled={viewMode}
                precision={4}
                validationState={isValidationError('presentVotes') ? 'error' : 'success'}
                validationMessage={isValidationError('presentVotes') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.numberOfParticipant] || resolutionRecordTranslations.validations.defaultError) : ''}
              />
              <NumberInput
                value={resolutionRecordContext.resolutionRecord.data.yesVotes}
                onChange={value => onChange(value, 'yesVotes')}
                label={tl(translations.pages.resolutionRecordEditing.section3.yes)}
                className="input"
                min={0}
                precision={4}
                validationState={isValidationError('numberOfParticipant') ? 'error' : 'success'}
                validationMessage={isValidationError('numberOfParticipant') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.numberOfParticipant] || resolutionRecordTranslations.validations.defaultError) : ''}
                disabled={viewMode}
              />
            </div>
            <div className="half-row">
              <NumberInput
                value={resolutionRecordContext.resolutionRecord.data.noVotes}
                onChange={value => onChange(value, 'noVotes')}
                label={tl(translations.pages.resolutionRecordEditing.section3.no)}
                className="input"
                min={0}
                disabled={viewMode}
                precision={4}
                validationState={isValidationError('numberOfParticipant') ? 'error' : 'success'}
                validationMessage={isValidationError('numberOfParticipant') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.numberOfParticipant] || resolutionRecordTranslations.validations.defaultError) : ''}
              />
              <NumberInput
                value={resolutionRecordContext.resolutionRecord.data.abstentionVotes}
                onChange={value => onChange(value, 'abstentionVotes')}
                label={tl(translations.pages.resolutionRecordEditing.section3.abstention)}
                className="input"
                min={0}
                disabled={viewMode}
                precision={4}
                validationState={isValidationError('numberOfParticipant') ? 'error' : 'success'}
                validationMessage={isValidationError('numberOfParticipant') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.numberOfParticipant] || resolutionRecordTranslations.validations.defaultError) : ''}
              />
            </div>
          </div>
          <div className="input-row">
            <SelectInput
              onChange={value => onChange(value, 'resolutionRecordStatus')}
              value={resolutionRecordContext.resolutionRecord.data.resolutionRecordStatus}
              label={tl(translations.pages.resolutionRecordEditing.section3.resolutionStatus)}
              required
              validationState={isValidationError('resolutionRecordStatus') ? 'error' : 'success'}
              validationMessage={isValidationError('resolutionRecordStatus') ? tl(translations.validations[resolutionRecordContext.resolutionRecord.validationErrors.resolutionRecordStatus] || resolutionRecordTranslations.validations.defaultError) : ''}
              options={[
                {
                  value: 'ACCEPTED',
                  label: tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.accepted),
                },
                {
                  value: 'DECLINED',
                  label: tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.declined),
                },
                {
                  value: 'CHALLENGED',
                  label: tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.challenged),
                },
                {
                  value: 'LEGAL_VALID',
                  label: tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.legalValid),
                },
                {
                  value: 'LEGAL_DECIDED',
                  label: tl(translations.pages.resolutionRecordEditing.section3.resolutionStatuses.legalDecided),
                },
              ]}
              className="input"
              disabled={viewMode}
            />
            <div
              className="input"
            />
          </div>
          {(!viewMode || resolutionRecordContext.numberOfLegalEvents > 0) && (
            <div className="events-title">
              <SubSectionTitle
                title={tl(translations.pages.resolutionRecordEditing.section4.sectionTitle)}
              />
              <ToggleButton
                onClick={expandLegalEventSection}
                label={!showLegalEvent ? tl(translations.pages.resolutionRecordEditing.section4.expand) : tl(translations.pages.resolutionRecordEditing.section4.collapse)}
              />
            </div>
          )}
          {showLegalEvent && legalEventFields(resolutionRecordContext.numberOfLegalEvents - 1)}
        </div>
      </PageContent>
    </Page>
  );
}
