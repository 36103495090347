import _ from 'lodash';

/**
 * Interface to handle data loaded from APIs
 * Data can be editable, but it can also be something that is displayed on the UI,
 * line the list of units. Hence this interface shall not contain any logic related to <strong> editing </strong>
 * the loaded data.
 */
export interface DefaultDataInterface<T> {
  data: T | undefined,
  saved: boolean, // Should be removed. Do not use in the future
  loading: boolean,
  loaded: boolean,
  error: boolean,
  validationErrors: any,
  page: number,
  lastPage: boolean,
  load: (loadedData: T, validationErrors?: any, saved?: boolean) => DefaultDataInterface<T>
  /**
   * @deprecated This doesn't remove duplicates when concatenating the new page. Use `mergeListsRemovingDuplicates` with `loadPagedNoConcat` instead.
   */
  loadPaged: (loadedData: any, resetPage: boolean, lastPage: boolean, validationErrors?: any, saved?: boolean) => DefaultDataInterface<T>,
  loadPagedNoConcat: (loadedData: any, resetPage: boolean, lastPage: boolean, validationErrors?: any, saved?: boolean) => DefaultDataInterface<T>,
  failed: (validationErrors?: any) => DefaultDataInterface<T>,
  startLoading: (loaded?: boolean) => DefaultDataInterface<T>,
  finishLoading: () => DefaultDataInterface<T>,
  resetToInitial: () => DefaultDataInterface<T>,
  copyFieldsWithoutData: Function,
}

const INITIAL_STATE = {
  saved: false,
  loading: false,
  loaded: false,
  error: false,
  validationErrors: {},
  page: 0,
  lastPage: false,
};

export default function DEFAULT_DATA<T>(initialData: any): DefaultDataInterface<T> {
  return {
    data: initialData,
    ...INITIAL_STATE,
    // this is useful to not copy the data itself because it can be a big object
    copyFieldsWithoutData() {
      return {
        saved: this.saved,
        loading: this.loading,
        loaded: this.loaded,
        error: this.error,
        validationErrors: this.validationErrors,
        page: this.page,
        lastPage: this.lastPage,
        load: this.load,
        loadPaged: this.loadPaged,
        loadPagedNoConcat: this.loadPagedNoConcat,
        failed: this.failed,
        startLoading: this.startLoading,
        finishLoading: this.finishLoading,
        copyFieldsWithoutData: this.copyFieldsWithoutData,
        resetToInitial: this.resetToInitial,
      };
    },
    load(loadedData: any, validationErrors?: any, saved?: boolean) {
      return {
        ...this.copyFieldsWithoutData(),
        saved: saved || false,
        data: loadedData,
        loaded: true,
        loading: false,
        error: false,
        validationErrors: validationErrors || {},
      };
    },
    loadPaged(loadedData: any, resetPage: boolean, lastPage: boolean, validationErrors?: any, saved?: boolean) {
      const oldList: unknown = this.data;

      let data = this.data ? [...(oldList as [])] : [];
      if (resetPage || !data) {
        data = loadedData;
      } else {
        data = data.concat(loadedData);
      }
      return this.loadPagedNoConcat(data, resetPage, lastPage, validationErrors, saved);
    },
    loadPagedNoConcat(loadedData: any, resetPage: boolean, lastPage: boolean, validationErrors?: any, saved?: boolean) {
      return <DefaultDataInterface<T>>{
        ...this.copyFieldsWithoutData(),
        saved: saved || false,
        data: (loadedData as unknown) as T,
        loaded: true,
        loading: false,
        error: false,
        validationErrors: validationErrors || {},
        // if resetPage is true then the page number needs to be 1 because the 0th page is currently being loaded
        page: resetPage ? 1 : this.page + 1,
        lastPage,
      };
    },
    failed(validationErrors?: any) {
      return {
        ...this,
        saved: false,
        error: true,
        loading: false,
        loaded: false,
        validationErrors,
        lastPage: true,
      };
    },
    startLoading(loaded?: boolean) {
      return {
        ...this,
        loaded: _.isBoolean(loaded) ? loaded : false,
        error: false,
        loading: true,
        saved: false,
      };
    },
    finishLoading() {
      return {
        ...this,
        loading: false,
        saved: true,
      };
    },
    resetToInitial() {
      return {
        ...this,
        data: initialData,
        ...INITIAL_STATE,
      };
    },
  };
}
