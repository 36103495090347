import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import SmartTable from '../../../../../../../elements/SmartTable/SmartTable';
import useSmartTable from '../../../../../../../elements/SmartTable/useSmartTable';
import { translations } from '../../../../../../../elements/Translation/translations';
import './PropertyExpensesCard.scss';
import SummaryRow from '../../../../../../../elements/CustomElements/ExpandableCard/SummaryRow';
import { formatCurrency } from '../../../../../../../lib/Utils';
import { usePropertyCardColumns } from '../usePropertyCardColumns';
import Amount from 'storybook-components/typography/Amount/Amount';

interface expansesCard {
  applicableExpenses: any[],
  notApplicableExpenses: any[],
  labelsPrefix: string,
  loading: boolean,
  showShares?: boolean,
}

export function useRenderPropertyExpensesTotal(props: expansesCard) {
  const { tl } = useContext(LanguageContext);
  const {
    applicableExpenses, notApplicableExpenses, labelsPrefix,
  } = props;
  const [totalCosts, setTotalCosts] = useState(0);

  useEffect(() => {
    const applicableTotal = applicableExpenses.reduce((aggregator: any, expense: any) => aggregator + (expense.totalSum || 0), 0);
    const notApplicableTotal = notApplicableExpenses.reduce((aggregator: any, expense: any) => aggregator + (expense.totalSum || 0), 0);
    setTotalCosts(applicableTotal + notApplicableTotal);
  }, [applicableExpenses, notApplicableExpenses]);

  const summaryElements = [{
    title: tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.summary.costs),
    value: <Amount>{formatCurrency(totalCosts)}</Amount>,
  }];
  return (
    <SummaryRow rightSideElements={summaryElements} />
  );
}

export default function PropertyExpensesCard(props: expansesCard) {
  const { tl } = useContext(LanguageContext);
  const {
    applicableExpenses, notApplicableExpenses, labelsPrefix, loading, showShares,
  } = props;
  const isEconomicPlan: boolean = useMemo<boolean>(() => labelsPrefix.indexOf('economicPlan') !== -1, [labelsPrefix]);
  const defaultSubtotal = {
    account: tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.subtotal),
    totalSum: 0,
  };

  const applicableSubtotal = useMemo(() => {
    if (!applicableExpenses) return defaultSubtotal;
    return {
      account: tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.subtotal),
      totalSum: applicableExpenses.reduce((aggregator: number, expense: any) => aggregator + (expense.totalSum || 0), 0),
    };
  }, [applicableExpenses]);

  const notApplicableSubtotal = useMemo(() => {
    if (!notApplicableExpenses) return defaultSubtotal;
    return {
      account: tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.subtotal),
      totalSum: notApplicableExpenses.reduce((aggregator: number, expense: any) => aggregator + (expense.totalSum || 0), 0),
    };
  }, [notApplicableExpenses]);

  const applicableDataSource = useMemo(() => (applicableExpenses ? applicableExpenses.concat(applicableSubtotal) : []), [applicableExpenses]);

  const applicableExpensesSmartTable = useSmartTable({
    tableName: 'propertyAccountApplicableExpenses',
    columns: usePropertyCardColumns({ showShares, isEconomicPlan }),
    dataSource: applicableDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'accountCode',
  });

  const notApplicableDataSource = useMemo(() => (notApplicableExpenses ? notApplicableExpenses.concat(notApplicableSubtotal) : []), [notApplicableExpenses]);

  const notApplicableExpensesSmartTable = useSmartTable({
    tableName: 'propertyAccountNotApplicableExpenses',
    columns: usePropertyCardColumns({ showShares, isEconomicPlan }),
    dataSource: notApplicableDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'accountCode',
  });

  return (
    <div className="PropertyExpensesCard">
      <div className="subtitle" id="applicableExpenses">
        {tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.applicableExpenses)}
      </div>
      <SmartTable {...applicableExpensesSmartTable} />
      <div className="subtitle" id="notApplicableExpenses">
        {tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.notApplicableExpenses)}
      </div>
      <SmartTable {...notApplicableExpensesSmartTable} />
    </div>
  );
}
