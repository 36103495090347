import { ColumnsType } from 'antd/lib/table/interface';
import { LanguageContext } from 'contexts/LanguageContext';
import { InviteUsersCell } from 'pages/Users/InviteUsers/components/InviteUsersCell/InviteUsersCell';
import { RemoveUserButton } from 'pages/Users/InviteUsers/components/RemoveUserButton/RemoveUserButton';
import { inviteUsersTranslations } from 'pages/Users/InviteUsers/inviteUsersTranslations';
import { CreateUserUiDto } from 'pages/Users/InviteUsers/services/InviteUsersContext';
import React, { useContext } from 'react';
import { SelectUserRole } from 'pages/Users/InviteUsers/components/SelectUserRole/SelectUserRole';

export const useInviteUsersColumns = () => {
  const { tl } = useContext(LanguageContext);

  return ([
    {
      dataIndex: 'email',
      title: tl(inviteUsersTranslations.columns.email),
      width: '31%',
      render: (value, record) => <InviteUsersCell type="email" id={record.syntheticId} />,
    },
    {
      dataIndex: 'password',
      title: (tl(inviteUsersTranslations.columns.password)),
      width: '31%',
      render: (value, record) => <InviteUsersCell type="password" id={record.syntheticId} />,
    },
    {
      dataIndex: 'roleId',
      title: (tl(inviteUsersTranslations.columns.role)),
      render: (value, record) => <SelectUserRole id={record.syntheticId} />,
    },
    {
      width: '5%',
      render: (value, record, index) => <RemoveUserButton index={index} />,
    },
  ]) as ColumnsType<CreateUserUiDto>;
};
