import { DunningLevelEnum } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { ContractDunningData, PropertyContractDunningData } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/useOrderOpenBalancesNavigate';
import { DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { isTypePropertyContractDunningData } from '../../services/utilIsTypePropertyContractDunningData';

export enum DunningProcessingEnum {
  NO_BANK_ORDER = 'NO_BANK_ORDER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  PAYOUT = 'PAYOUT'
}

export const useContractDunningProcessingCell = (record: PropertyContractDunningData | ContractDunningData) => {
  const getDotColor = (processing: DunningProcessingEnum) => {
    switch (processing) {
    case DunningProcessingEnum.NO_BANK_ORDER: return DotColorEnum.GRAY;
    case DunningProcessingEnum.DIRECT_DEBIT: return DotColorEnum.GREEN;
    case DunningProcessingEnum.PAYOUT: return DotColorEnum.RED;
    default: return DotColorEnum.GRAY;
    }
  };

  const getProcessing = (dunningLevel: DunningLevelEnum, bankAccountId: number, contractHasMandate: boolean, openAmount: number) => {
    if ((dunningLevel === DunningLevelEnum.SEPA_DIRECT_DEBIT && openAmount > 0 && contractHasMandate)
        || (dunningLevel === DunningLevelEnum.NO_MESSAGE && openAmount > 0 && contractHasMandate)
    ) return DunningProcessingEnum.DIRECT_DEBIT;

    if ((dunningLevel === DunningLevelEnum.PAY_OUT && bankAccountId)
        || (dunningLevel === DunningLevelEnum.NO_MESSAGE && openAmount < 0 && bankAccountId)
    ) return DunningProcessingEnum.PAYOUT;

    return DunningProcessingEnum.NO_BANK_ORDER;
  };

  const processing = !isTypePropertyContractDunningData(record)
    ? getProcessing(record?.dunningLevel?.dunningLevel, record.contractBankAccountId, record.contractHasMandate, record.openAmount)
    : undefined;

  const dotColor = !isTypePropertyContractDunningData(record) ? getDotColor(processing) : undefined;

  return {
    processing,
    dotColor,
  };
};
