import { useContext } from 'react';
import { useHistory } from 'react-router';
import { SpecialContributionControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { translations } from '../../translations';
import { SpecialContributionCreationContext } from './SpecialContributionCreationContext';

export const useLoadSpecialContribution = () => {
  const specialContributionContext = useContext(SpecialContributionCreationContext);
  if (specialContributionContext === undefined) {
    throw Error('useLoadSpecialContribution must be used withing SpecialContributionCreationContextProvider');
  }
  const { setLoading, setDirty, setSpecialContribution } = specialContributionContext;
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const { apiConfiguration } = useContext(AuthContext);
  const specialContributionControllerApi = new SpecialContributionControllerApi(apiConfiguration('accounting'));


  const goBack = () => {
    history.push('/special-contribution');
  };

  const loadSpecialContribution = (specialContributionId: number) => {
    setLoading(true);
    return specialContributionControllerApi.getByIdUsingGET1({
      id: specialContributionId,
    })
      .then((response) => {
        checkPropertyValidity({ propertyHrId: response.propertyHrId!, onCancel: goBack });
        setSpecialContribution({ ...response });
      })
      .catch(() => {
        showNotification({
          key: 'loadSpecialContributionError',
          message: tl(translations.specialContribution.loadSpecialContributionError.message),
          description: tl(translations.specialContribution.loadSpecialContributionError.description),
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setDirty(false);
      });
  };

  return {
    loadSpecialContribution,
  };
};
