import React from 'react';

type ParagraphProps = {
  level?: 1 | 2,
  type?: 'default' | 'secondary',
  children: React.ReactNode,
  className?: string,
}


/**
 * Styling is in src/styles/_typography.scss
 */


const Paragraph = ({
  level,
  type,
  children,
  className: classNameProp,
}: ParagraphProps) => {
  const commonClassname = `paragraph-${type} ${classNameProp ?? ''}`;

  if (level === 1) return <p className={commonClassname}>{children}</p>;

  // level === 2
  return <p className={`paragraph-level-2 ${commonClassname}`}>{children}</p>;
};


Paragraph.defaultProps = {
  level: 1,
  type: 'default',
  className: undefined,
};


export default Paragraph;
