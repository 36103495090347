import { useContext } from 'react';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { ExtendedExchangeProjectionTypeEnum } from 'api/accounting';
import { AllocationAmountsStateContext } from './ServiceCompanyAllocationContext';


/**
 * Scenario: I select one TX from the group, so I can allocate to an INVOICE based exchange;
 * If then, before clicking execute, I select a second transaction,
 * then the allocation to the INVOICE based exchange should not be submitted on execution
 * (if there are multiple txs selected), so it should not be counted
 * in the totalAllocatedAmount either.
 */
const getAmountToAddToTotal = (amount: number | undefined, exchangeType: ExtendedExchangeProjectionTypeEnum | undefined, selectedTransactions: ExtendedBankTransaction[]) => {
  if (selectedTransactions.length === 1 || exchangeType === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
    return Math.abs(amount ?? 0);
  }

  return 0;
};


export const useServiceCompanyAllocationAmounts = (selectedTransactions: ExtendedBankTransaction[]) => {
  const allocationAmounts = useContext(AllocationAmountsStateContext);

  if (allocationAmounts === undefined) {
    throw new Error('useServiceCompanyAllocationAmounts must be used within an ServiceCompanyAllocationContextProvider');
  }

  const [totalAllocatedAmount, previouslyAllocatedAmount] = allocationAmounts?.reduce(
    (
      [currentAmountAcc, prevAmountAcc],
      { currentAmount, previousAmount, exchangeType },
    ) => [
      currentAmountAcc + getAmountToAddToTotal(currentAmount, exchangeType, selectedTransactions),
      prevAmountAcc + getAmountToAddToTotal(previousAmount, exchangeType, selectedTransactions),
    ],
    [0, 0],
  ) ?? [0, 0];


  return {
    totalAllocatedAmount,
    previouslyAllocatedAmount,
  };
};
