import { CompanyEditingContext } from 'contexts/CompanyEditingContext';
import { useContext } from 'react';


export const useAddBankAccountButton = () => {
  const companyEditingContext: any = useContext(CompanyEditingContext);

  if (companyEditingContext === undefined) {
    throw new Error('useAddBankAccountButton must be used within a CompanyEditingContext');
  }

  const { setCompany } = companyEditingContext;
  const onAddNewBankAccount = () => {
    setCompany((prevState: any) => {
      const newData = { ...prevState.data };
      if (newData.bankAccountNumber !== undefined) {
        newData.bankAccountNumber += 1;
      } else {
        newData.bankAccountNumber = 1;
      }
      return prevState.load(newData);
    });
  };

  return {
    onAddNewBankAccount,
  };
};
