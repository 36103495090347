import {
  useContext,
  useMemo,
  useState,
} from 'react';

import _ from 'lodash';

import {
  ContractLegacyControllerApi,
  DirectDebitControllerApi,
} from '../../api/accounting';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import DEFAULT_DATA from '../../lib/data';
import { showNotification } from '../../lib/Notification';
import {
  deleteKey,
  formatDate,
  setValue,
} from '../../lib/Utils';
import { OverlayContext } from '../../services/OverlayContext/OverlayContext';
import { translations } from './translations';

const defaultContext: any = {
  executionType: 'asap',
};

export default function useDirectDebitCreation() {
  const [dirty, setDirty] = useState(false);
  const [directDebit, setDirectDebit] = useState(DEFAULT_DATA<any>(defaultContext));
  const [contractsList, setContractsList] = useState<any>([]);
  const { apiConfiguration } = useContext(AuthContext);
  const directDebitControllerApi = new DirectDebitControllerApi(apiConfiguration('accounting'));
  const contractLegacyControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));


  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);

  const onChange = (v: any, key: string) => {
    const newValue: any = { ...directDebit.data };
    if (!(typeof v === 'undefined' || v === '' || v === null || v.length === 0)) {
      if (key === 'propertyId' && v !== newValue.propertyId) {
        deleteKey(newValue, 'bankAccountId');
        deleteKey(newValue, 'counterpartContactId');
        deleteKey(newValue, 'counterpartBankAccountId');
      }
      setValue(newValue, key, v);
    } else {
      deleteKey(newValue, key);
    }
    setDirectDebit(directDebit.load(newValue));
    setDirty(true);
  };

  const convertToBeModel = (directDebitObject: any) => {
    if (directDebitObject.executionType === 'asap') {
      directDebitObject.executionDate = new Date();
    }
    return {
      ...directDebitObject,
      propertyBankAccountId: directDebitObject.bankAccountId,
    };
  };

  const onSaveDirectDebit = () => {
    setDirectDebit(directDebit.startLoading());
    setDirty(false);
    directDebitControllerApi.createDirectDebitUsingPOST({
      directDebitDto: convertToBeModel(directDebit.data),
    })
      .then(() => {
        showNotification({
          key: 'savePaymentSuccess',
          message: tl(translations.notifications.saveSuccess.message),
          type: 'success',
        });
        // dd list is reloaded upon navigating back, no need to trigger load here
        goBack();
      })
      .catch((response: any) => {
        setDirty(true);
        if (response.title === 'Validation error') {
          setDirectDebit(directDebit.failed(response));
          showNotification({
            key: 'savePaymentValidationError',
            message: tl(translations.notifications.saveValidationError.message),
            description: tl(translations.notifications.saveValidationError.description),
            type: 'error',
          });
        } else {
          setDirectDebit(directDebit.failed());
          showNotification({
            key: 'savePaymentError',
            message: tl(translations.notifications.saveError.message),
            type: 'error',
          });
        }
      });
  };

  const onClearDirectDebit = () => {
    setDirectDebit(directDebit.load(defaultContext));
    setDirty(false);
  };

  const loadOwners = (propertyId?: number) => {
    if (propertyId) {
      contractLegacyControllerApi.getContractsUsingGET({ propertyId, validAtDate: formatDate(new Date(), 'YYYY-MM-DD') })
        .then((response) => {
          setContractsList(response.filter(value => value.hasMandateAtDate));
        })
        .catch(() => {
          setContractsList([]);
        });
    } else {
      setContractsList([]);
    }
  };

  const ownerOptions: any = useMemo(() => _.uniqBy(contractsList
    .map((contract: any) => ({
      value: contract.mailingContact.contactId,
      label: contract.mailingContact.name,
    })), 'value'), [contractsList]);

  const ownerBankAccountOptions: any = useMemo(() => {
    if (directDebit.data.counterpartContactId && !_.isEmpty(contractsList)) {
      return _.uniqBy(contractsList.filter((owner: any) => owner.mailingContact.contactId === directDebit.data.counterpartContactId).map((owner: any) => (
        {
          value: owner.bankAccountId,
          label: owner.bankAccountIban,
        })), 'value');
    }
    return [];
  }, [contractsList, directDebit.data.counterpartContactId]);

  return {
    directDebit,
    onChange,
    onSaveDirectDebit,
    onClearDirectDebit,
    loadOwners,
    ownerOptions,
    ownerBankAccountOptions,
    setDirty,
    isDirty: dirty,
  };
}
