import React, {
  createContext,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import {
  PropertyDisplayDto,
  UnitContractProjectionDto,
  UnitLegacyDto,
} from 'api/accounting';
import DEFAULT_DATA from 'lib/data';

import {
  CurrentAssignment,
  ExistingAssignment,
  LoadedPdfData,
  PagesOrientationType,
  PdfAssignmentDocumentType,
  PdfPageAssignerCurrentAssignmentsContextType,
  PdfPageAssignerDataContextType,
  PdfPageAssignerSelectedPagesContextType,
  PdfPageAssignerUpdatersContextType,
  SelectedPage,
} from './interfaces';

interface PdfPageAssignerContextProviderProps {
  children: ReactNode
}


export const PdfPageAssignerUpdatersContext = createContext<PdfPageAssignerUpdatersContextType | undefined>(undefined);
PdfPageAssignerUpdatersContext.displayName = 'PdfPageAssignerUpdatersContext';

export const PdfPageAssignerCurrentAssignmentsContext = createContext<PdfPageAssignerCurrentAssignmentsContextType | undefined>(undefined);
PdfPageAssignerCurrentAssignmentsContext.displayName = 'PdfPageAssignerCurrentAssignmentsContext';

export const PdfPageAssignerSelectedPagesContext = createContext<PdfPageAssignerSelectedPagesContextType | undefined>(undefined);
PdfPageAssignerSelectedPagesContext.displayName = 'PdfPageAssignerSelectedPagesContext';

export const PdfPageAssignerDataContext = createContext<PdfPageAssignerDataContextType | undefined>(undefined);
PdfPageAssignerDataContext.displayName = 'PdfPageAssignerDataContext';


const PdfPageAssignerContextProvider = ({ children }: PdfPageAssignerContextProviderProps) => {
  const [viewMode, setViewMode] = useState<PagesOrientationType>(PagesOrientationType.GRID);
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState(DEFAULT_DATA<PropertyDisplayDto>(undefined));

  const [units, setUnits] = useState(DEFAULT_DATA<UnitLegacyDto[]>([]));
  const [contractsWithinDateRange, setContractsWithinDateRange] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([]));
  const [contractsOutsideOfDateRange, setContractsOutsideOfDateRange] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([]));

  const [loadedPdfsData, setLoadedPdfsData] = useState<LoadedPdfData[]>([]);
  const [originalDocuments, setOriginalDocuments] = useState(DEFAULT_DATA<PdfAssignmentDocumentType[]>([]));
  const [existingAssignments, setExistingAssignments] = useState<ExistingAssignment[]>([]);
  const [currentAssignments, setCurrentAssignments] = useState<CurrentAssignment[]>([]);
  const [selectedPages, setSelectedPages] = useState<SelectedPage[]>([]);
  const [showWarningForSelectedPages, setShowWarningForSelectedPages] = useState<boolean>(false);
  /**
   * We set the value in an IntersectionObserver in PdfPageWithAssignment when pages scroll into view
   * and read the value in PdfPreviewSidebar to apply the highlight styling.
   */
  const [idOfPageInView, setIdOfPageInView] = useState('');


  const updaters = useMemo(() => ({
    setViewMode,
    setIdOfPageInView,
    setLoading,
    setProperty,
    setUnits,
    setContractsWithinDateRange,
    setContractsOutsideOfDateRange,
    setOriginalDocuments,
    setExistingAssignments,
    setCurrentAssignments,
    setSelectedPages,
    setLoadedPdfsData,
    setShowWarningForSelectedPages,
  }), []);

  const data = useMemo(() => ({
    viewMode,
    idOfPageInView,
    loading,
    property,
    units,
    contractsWithinDateRange,
    contractsOutsideOfDateRange,
    originalDocuments,
    existingAssignments,
    loadedPdfsData,
    showWarningForSelectedPages,
  }), [
    loading,
    viewMode,
    idOfPageInView,
    property,
    units,
    contractsWithinDateRange,
    contractsOutsideOfDateRange,
    originalDocuments,
    existingAssignments,
    loadedPdfsData,
    showWarningForSelectedPages,
  ]);


  return (
    <PdfPageAssignerUpdatersContext.Provider value={updaters}>
      <PdfPageAssignerCurrentAssignmentsContext.Provider value={currentAssignments}>
        <PdfPageAssignerSelectedPagesContext.Provider value={selectedPages}>
          <PdfPageAssignerDataContext.Provider value={data}>
            {children}
          </PdfPageAssignerDataContext.Provider>
        </PdfPageAssignerSelectedPagesContext.Provider>
      </PdfPageAssignerCurrentAssignmentsContext.Provider>
    </PdfPageAssignerUpdatersContext.Provider>
  );
};

export default PdfPageAssignerContextProvider;
