import {
  PropertyBankAccountProjectionDto, PropertyBankAccountControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect } from 'react';
import _ from 'lodash';
import {
  AccountSelectionContext, FormValues, HouseMoneySettlementAndPropertyContext,
} from './HouseMoneySettlementCloseYearContext';
import { translations } from '../translations';
import { LanguageContext } from '../../../../../contexts/LanguageContext';

export const useAccounts = () => {
  const accountSelectionContext = useContext(AccountSelectionContext);
  const hmsAndPropertyContext = useContext(HouseMoneySettlementAndPropertyContext);

  if (accountSelectionContext === undefined || hmsAndPropertyContext === undefined) {
    throw new Error('useAccounts must be used within a HouseMoneySettlementCloseYearContextProvider');
  }

  const { apiConfiguration } = useContext(AuthContext);
  const propertyBankAccountControllerApi = new PropertyBankAccountControllerApi(apiConfiguration('accounting'));

  const { propertyAndEconomicYearData } = hmsAndPropertyContext;
  const { tl } = useContext(LanguageContext);
  const {
    formValues, setFormValues, bankAccountOptions, setBankAccountOptions, setDirty,
  } = accountSelectionContext;

  const noOrderOption = { label: tl(translations.creationPage.sections.section1.noOrder), value: -1 };

  useEffect(() => {
    if (propertyAndEconomicYearData?.propertyHrId) {
      getBankAccounts();
    }
  }, [propertyAndEconomicYearData?.propertyHrId]);

  const selectDefaultBankAccount = (bankAccounts: Array<{label: string, value: number}>) => {
    const houseMoneyAccounts = bankAccounts?.filter(bankAccountOption => bankAccountOption.label.indexOf('Haus') > -1 || bankAccountOption.label.indexOf('Giro') > -1);
    if (!_.isEmpty(houseMoneyAccounts)) {
      setFormValues(prevState => ({ ...prevState, bankAccount: houseMoneyAccounts[0].value }));
    }
  };

  const getBankAccounts = () => {
    setBankAccountOptions(prev => prev.startLoading());
    propertyBankAccountControllerApi
      .filterPropertyBankAccountsUsingGET({ propertyHrId: propertyAndEconomicYearData?.propertyHrId })
      .then((bankAccounts: PropertyBankAccountProjectionDto[]) => {
        const parsedBankAccounts = bankAccounts.map((bankAccount: PropertyBankAccountProjectionDto) => ({ label: `${bankAccount.accountName} - ${bankAccount.iban}`, value: bankAccount.bankAccountId }));
        parsedBankAccounts.push(noOrderOption);
        setBankAccountOptions(prev => prev.load(parsedBankAccounts));
        selectDefaultBankAccount(parsedBankAccounts);
      })
      .catch((error) => {
        setBankAccountOptions(prev => prev.failed(error));
      });
  };

  const onChange = (key: keyof FormValues, value: FormValues[keyof FormValues]) => {
    setDirty(true);
    setFormValues(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return {
    onChange,
    formValues,
    bankAccountOptions,
  };
};
