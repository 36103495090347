import { customQuerystring } from 'contexts/AuthContext';

// Official max URL lenght in chrome
const MAX_URL_LENGTH :number = 2083;
// Random number allocated for host + path name
const ALLOCATED_URI_LENGTH :number = 183;
// query string length we can use.
const ALLOWED_QUERY_STRING_LENGTH :number = MAX_URL_LENGTH - ALLOCATED_URI_LENGTH;

const splitArray = (listParamToSplit, parts) => {
  const chunkSize = Math.ceil(listParamToSplit.length / parts);
  const result = [];
  for (let i = 0; i < listParamToSplit.length; i += chunkSize) {
    const chunk = listParamToSplit.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
};

export function useQueryStringSplitter() {
  const splitRequestParam = (listParamToSplit: any[], listParamName: string, commonProperties: any = {}): any[][] => {
    const fullQueryString = customQuerystring({ ...commonProperties, [listParamName]: listParamToSplit });
    const commonQueryString = customQuerystring({ ...commonProperties });

    const listParamQueryStrLength: number = fullQueryString.length - commonQueryString.length;

    const numberOfRequests = (listParamQueryStrLength / ALLOWED_QUERY_STRING_LENGTH) + 1;

    return splitArray(listParamToSplit, numberOfRequests);
  };

  return {
    splitRequestParam,
  };
}
