import { Alert } from 'antd';
import { isEmpty } from 'lodash';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { OpsCostReportAccountValidationError, OpsCostReportDistributionValidationError } from 'services/DistributionCalculation/AccountDistributionCalculationContext';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { DefaultDataInterface } from 'lib/data';
import { AccountDistributionProjectionDto } from 'api/accounting/models/AccountDistributionProjectionDto';
import { useDistributionCalculationAlerts } from './useDistributionCalculationAlerts';

interface DistributionCalculationAlertsProps {
    tl: any;
    distributionCalculationAlertsProps: {
        accountDistributions: DefaultDataInterface<AccountDistributionProjectionDto[]>;
        incorrectDirectCosts: OpsCostReportAccountValidationError[];
        accountsWithMissingDistributionSets: OpsCostReportAccountValidationError[];
        accountsWithZeroValueDistributionSets: OpsCostReportDistributionValidationError[];
        getAccountDistributionPath: (accountCode: string) => string,
        getAccountDistributionOverviewPath: () => string;
        reportEndDate: number;
        relevantAccountBalancesExist: boolean;
        onChangeIncorrectDirectCosts: React.Dispatch<React.SetStateAction<OpsCostReportAccountValidationError[]>>;
        printMode: boolean;
    };
}

const DistributionCalculationAlerts = ({
  tl,
  distributionCalculationAlertsProps,
}: DistributionCalculationAlertsProps) => {
  const {
    accountDistributions,
    incorrectDirectCosts,
    accountsWithMissingDistributionSets,
    accountsWithZeroValueDistributionSets,
    getAccountDistributionPath,
    getAccountDistributionOverviewPath,
    reportEndDate,
    relevantAccountBalancesExist,
    onChangeIncorrectDirectCosts,
    printMode,
  } = distributionCalculationAlertsProps;

  const {
    getMissingAccountDistributionsMessage,
    getIncorrectDirectCostAlertDescription,
    getMissingDSAlertDescription,
    getDSWithZeroValueDescription,
  } = useDistributionCalculationAlerts(getAccountDistributionPath, getAccountDistributionOverviewPath, reportEndDate, tl);

  return (
    <>
      {relevantAccountBalancesExist && isEmpty(accountDistributions.data) && (
        <Alert
          message={getMissingAccountDistributionsMessage()}
          type="error"
          showIcon
          icon={<Icon component={ICONS.error} />}
        />
      )}
      {(!isEmpty(incorrectDirectCosts) && !printMode)
        && (
          <Alert
            message={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.incorrectDirectCosts)}
            description={getIncorrectDirectCostAlertDescription(incorrectDirectCosts)}
            type="warning"
            showIcon
            icon={<Icon component={ICONS.error} />}
            closable
            closeIcon={<Icon component={ICONS.closeForAlert} />}
            onClose={() => onChangeIncorrectDirectCosts([])}
          />
        )
      }
      {!isEmpty(accountsWithMissingDistributionSets)
        && (
          <Alert
            message={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.missingDistributionSets)}
            description={getMissingDSAlertDescription(accountsWithMissingDistributionSets)}
            type="error"
            showIcon
            icon={<Icon component={ICONS.error} />}
          />
        )
      }
      {!isEmpty(accountsWithZeroValueDistributionSets)
        && (
          <Alert
            message={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.alerts.distributionSetsWithZeroValues)}
            description={getDSWithZeroValueDescription(accountsWithZeroValueDistributionSets)}
            type="error"
            showIcon
            icon={<Icon component={ICONS.error} />}
          />
        )
      }
    </>
  );
};

export default DistributionCalculationAlerts;
