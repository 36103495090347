export const articleUrls = {
  opsCostReport: {
    incorrectWka: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2485780481/Wie+erstelle+ich+eine+Betriebskostenabrechnung?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=de-DE#Fall-1%3A-Warnung-zu-Heizkosten',
    missingWka: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2485780481/Wie+erstelle+ich+eine+Betriebskostenabrechnung?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=de-DE#Fall-2%3A-Warnung-zu-fehlender-WKA',
    incorrectDirectCost: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2485780481/Wie+erstelle+ich+eine+Betriebskostenabrechnung?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=de-DE#Fall-3%3A-Warnung-zu-Direkten-Kosten',
    missingDistributionSet: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2485780481/Wie+erstelle+ich+eine+Betriebskostenabrechnung?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=de-DE#Fall-4%3A-Warnung-zu-Verteilerschl%C3%BCsseln',
    generalDistributionSet: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2485780481',
  },
  messaging: {
    missingAddress: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2419425286/Neuen+Personenkontakt+anlegen?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=de-DE#Kontaktdaten',
    missingDispatchType: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2419425286/Neuen+Personenkontakt+anlegen?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=de-DE#Kontaktdaten',
    contractAndContactSyncIssues: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2847801352',
    propertyNotSynced: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2140831749',
  },
  orderOpenBalances: {
    dunningLevel: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2505506817',
    excludedContract: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2507145217/Offene+Salden?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=de-DE#3.--Vertr%C3%A4ge-von-Mahnl%C3%A4ufen-ausschlie%C3%9Fen',
  },
  documentManagementSystem: {
    labels: 'https://support.docs.impower.de/servicedesk/customer/portal/1/article/2503573518',
    documentDownload: 'https://support.docs.impower.de/servicedesk/customer/portal/1/article/2544435212',
  },
  contacts: {
    integrations: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2497511425',
    delete: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2520842245',
  },
  chartOfAccounts: {
    weg: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2006089737',
    mv: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2511077377',
    rentAccounts: 'https://support.docs.impower.de/servicedesk/customer/portal/1/article/2704441353',
  },
  contactSupport: 'https://support.docs.impower.de/servicedesk/customer/portal/1/group/-1',
  vatEligibility: 'https://support.docs.impower.de/servicedesk/customer/portal/1/article/2762047489',
  unitContractsHistory: {
    overlappingContracts: 'https://support.docs.impower.de/servicedesk/customer/portal/1/article/2847801352',
  },
  bankConnections: {
    finApi: 'https://support.docs.impower.de/servicedesk/customer/portal/1/article/2157772803',
  },
};
