import { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { EconomicPlanCreationDataAccountBalanceDto } from '../../../../api/accounting/models';
import backend, { endpointUrls } from '../../../../backend_api';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../../contexts/PropertyListContext';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { showNotification } from '../../../../lib/Notification';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { useLoadAccountDistributionEconomicPlan } from '../../services/useLoadAccountDistributionEconomicPlan';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export default function useEconomicPlanAccountEditing() {
  const { tl } = useContext(LanguageContext);
  const { economicPlanId } = useParams<{ economicPlanId: string }>();
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useEconomicPlanAccountEditing must be used within a EconomicPlanContextProvider');
  }

  const {
    economicPlan,
    setEconomicPlan,
    economicPlanCreationData,
    setEconomicPlanCreationData,
  } = economicPlanContext;
  const { onLoadEconomicPlan } = useLoadAccountDistributionEconomicPlan();

  const {
    dirty: isDirty,
    addDirt,
    clearDirty,
  } = useSimpleDirtModal();

  const {
    selectedDisplayProperty,
  } = useContext(PropertyListContext);
  const selectedPropertyId = selectedDisplayProperty.data ? selectedDisplayProperty.data.id : null;

  useEffect(() => {
    if (selectedPropertyId && economicPlan.data?.startDate) {
      onLoadEconomicPlanCreationData(!economicPlanId);
    }
  }, [selectedPropertyId, economicPlan.data?.startDate]);


  useEffect(() => {
    // NOTE: This hook is called one more time, with economicPlanId undefined, before the component is destroyed
    onLoadEconomicPlan(parseInt(economicPlanId, 10));
    clearDirty();
  }, [economicPlanId]);


  const convertEconomicPlanCreationDataToFEModel = (data: EconomicPlanCreationDataAccountBalanceDto[]): any[] => data.map((accountBalance: any) => {
    let children;
    if (accountBalance.subAccounts) {
      children = convertEconomicPlanCreationDataToFEModel(accountBalance.subAccounts);
    }
    return {
      ...accountBalance,
      account: `${accountBalance.accountCode} ${accountBalance.accountName}`,
      children,
      currentTotalSum: children ? children.reduce((total, ab) => total + (ab.currentTotalSum || 0), 0) : accountBalance.currentTotalSum,
      previousTotalSum: children ? children.reduce((total, ab) => total + (ab.previousTotalSum || 0), 0) : accountBalance.previousTotalSum,
    };
  });

  const convertEconomicPlanCreationDataListToFEModel = (economicPlanCreationDataList: any) => {
    const result = { ...economicPlanCreationDataList };
    if (economicPlanCreationDataList.applicableExpenses) {
      result.applicableExpenses = convertEconomicPlanCreationDataToFEModel(economicPlanCreationDataList.applicableExpenses);
    }
    if (economicPlanCreationDataList.notApplicableExpenses) {
      result.notApplicableExpenses = convertEconomicPlanCreationDataToFEModel(economicPlanCreationDataList.notApplicableExpenses);
    }
    if (economicPlanCreationDataList.income) {
      result.income = convertEconomicPlanCreationDataToFEModel(economicPlanCreationDataList.income);
    }
    return result;
  };

  const setInitialAccountValues = (creationData: any) => {
    setEconomicPlan((ecPlan) => {
      const newEcPlan: any = {
        ...ecPlan.data,
        applicableExpenses: {},
        notApplicableExpenses: {},
        income: {},
        reserveFunds: {},
      };
      if (creationData.applicableExpenses) {
        newEcPlan.applicableExpenses = calcInitialValuesFromTree(creationData.applicableExpenses);
      }
      if (creationData.notApplicableExpenses) {
        newEcPlan.notApplicableExpenses = calcInitialValuesFromTree(creationData.notApplicableExpenses);
      }
      if (creationData.income) {
        newEcPlan.income = calcInitialValuesFromTree(creationData.income);
      }

      if (creationData.reserveFundAccounts) {
        creationData.reserveFundAccounts.forEach((account: any) => {
          newEcPlan.reserveFunds[account.accountCode] = 0;
        });
      }
      return ecPlan.load(newEcPlan);
    });
  };

  const calcInitialValuesFromTree = (tree: EconomicPlanCreationDataAccountBalanceDto[]) => {
    let initialValues = {};
    tree.forEach((accountBalance) => {
      if (accountBalance.subAccounts) {
        initialValues = {
          ...initialValues,
          ...calcInitialValuesFromTree(accountBalance.subAccounts),
        };
      } else {
        // leaf
        initialValues = {
          ...initialValues,
          [accountBalance.accountCode!]: accountBalance.previousTotalSum || 0,
        };
      }
    });
    return initialValues;
  };

  const creationDataCache = useRef<any>({});

  function fetchCreationData() {
    const { startDate } = economicPlan.data!;
    const economicYear = moment(startDate).year();
    const cacheKey = `${selectedPropertyId}-${economicYear}`;
    if (creationDataCache.current[cacheKey]) {
      return Promise.resolve(creationDataCache.current[cacheKey]);
    }

    return backend.get(`${endpointUrls.ECONOMIC_PLAN}/creation`, {
      propertyId: selectedPropertyId,
      economicYear,
    })
      .then((response) => {
        creationDataCache.current[cacheKey] = response;
        return response;
      });
  }

  const onLoadEconomicPlanCreationData = (initializeAccounts: boolean = true) => {
    if (selectedPropertyId && economicPlan.data?.startDate) {
      setEconomicPlanCreationData(economicPlanCreationData.startLoading());
      fetchCreationData()
        .then((response) => {
          const data = convertEconomicPlanCreationDataListToFEModel(response);
          setEconomicPlanCreationData(economicPlanCreationData.load(data));
          if (initializeAccounts) {
            setInitialAccountValues(data);
          }
        })
        .catch(() => {
          setEconomicPlanCreationData(economicPlanCreationData.failed());
          showNotification({
            key: 'loadEconomicPlanCreationDataError',
            message: tl(economicPlanTranslations.notifications.economicPlanContext.loadEconomicPlanCreationDataError.message),
            type: 'error',
          });
        });
    }
  };

  const discardChanges = () => {
    onLoadEconomicPlan(economicPlan.data?.id);
    return Promise.resolve();
  };

  return {
    economicPlan,
    isDirty,
    setEconomicPlan,
    economicPlanCreationData,
    addDirt,
    discardChanges,
  };
}
