import {
  EconomicPlanDtoStatusEnum, EconomicPlanDtoTypeEnum, GetUnitContractsUsingGETContractTypesEnum, HouseMoneyDto, PropertyLegacyDto, UnitProjectionDto,
} from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { ColumnsType } from 'antd/es/table';
import { Input, Tooltip } from 'antd';
import OpsCostCell from '../components/OpsCostCell/OpsCostCell';
import ReserveFundCell from '../components/ReserveFundCell/ReserveFundCell';
import { HouseMoneyListContext, HouseMoneySectionUpdatersContext, OpsCostItem } from '../../../services/HouseMoneyContext';
import { economicPlanHouseMoneyTableTranslations } from '../economicPlanHouseMoneyTableTranslations';
import TotalOfUnitCell from '../components/TotalOfUnitCell/TotalOfUnitCell';
import { PropertyListContext } from '../../../../../contexts/PropertyListContext';
import { useUnitsList } from '../../../../../services/UnitsList/useUnitsList';
import { useActiveContractsList } from '../../../../../services/UnitContractsList/useActiveContractsList';
import { ActionTypes } from './ActionTypes';
import { EconomicPlanContext } from '../../../services/EconomicPlanContext';


export const useHouseMoneyTable = () => {
  const { tl } = useContext(LanguageContext);
  const { selectedProperty } = useContext(PropertyListContext);
  const property: PropertyLegacyDto = selectedProperty.data;
  const { unitsList } = useUnitsList({ propertyId: property.id, isOwnedByWeg: false });
  const { activeContractsList } = useActiveContractsList({ propertyId: property.id, contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER] });
  const hmListContext = useContext(HouseMoneyListContext);
  const updatersContext = useContext(HouseMoneySectionUpdatersContext);
  const economicPlanContext = useContext(EconomicPlanContext);
  const [allNecessaryDataIsReady, setAllNecessaryDataIsReady] = useState(false);

  if (hmListContext === undefined || updatersContext === undefined || economicPlanContext === undefined) {
    throw new Error('useReserveFundCell must be used within a HouseMoneyListContextProvider and EconomicPlanContextProvider');
  }

  const { economicPlan } = economicPlanContext;
  const isEditable = economicPlan?.data?.type === EconomicPlanDtoTypeEnum.UNIT_FIGURES
    && economicPlan?.data?.status === EconomicPlanDtoStatusEnum.DRAFT;

  const {
    setReserveFundAmounts,
    setOpsCosts,
  } = updatersContext;

  const {
    houseMoneyList,
  } = hmListContext;

  useEffect(() => {
    if (unitsList.loaded && activeContractsList.loaded && houseMoneyList.loaded) {
      setOpsCostValues(houseMoneyList.data);
      setReserveFundAmountValues(houseMoneyList.data);
      setAllNecessaryDataIsReady(true);
    }
  }, [unitsList, activeContractsList, houseMoneyList.data]);


  const setReserveFundAmountValues = (houseMoneyArray: HouseMoneyDto[]) => {
    setReserveFundAmounts(
      unitsList.data?.map(
        unit => reserveAccountDrafts?.map(acc => ({
          unitId: unit.id,
          buildingId: unit.buildingId,
          reserveFundId: acc.id,
          amount: houseMoneyArray?.find(houseMoney => houseMoney.unitId === unit.id)
            ?.houseMoneyAmounts
            ?.find(houseMoneyAmount => houseMoneyAmount.reserveFundId === acc.id)?.amount ?? 0,
          id: houseMoneyArray.find(houseMoney => houseMoney.unitId === unit.id)
            ?.houseMoneyAmounts
            ?.find(houseMoneyAmount => houseMoneyAmount.reserveFundId === acc.id)?.id ?? undefined,
          action: houseMoneyArray.find(houseMoney => houseMoney.unitId === unit.id)
            ?.houseMoneyAmounts
            ?.find(houseMoneyAmount => houseMoneyAmount.reserveFundId === acc.id)?.id ? undefined : ActionTypes.CREATE,
        })) ?? [],
      ) ?? [],
    );
  };

  const setOpsCostValues = (houseMoneyArray: HouseMoneyDto[]) => {
    setOpsCosts(
      (unitsList.data?.map(
        unit => ({
          unitId: unit.id,
          buildingId: unit.buildingId,
          amount: houseMoneyArray.find(houseMoney => houseMoney.unitId === unit.id)
            ?.houseMoneyAmounts
            ?.find(houseMoneyAmount => !houseMoneyAmount.reserveFundId)?.amount ?? 0,
          id: houseMoneyArray?.find(houseMoney => houseMoney.unitId === unit.id)
            ?.houseMoneyAmounts
            ?.find(houseMoneyAmount => !houseMoneyAmount.reserveFundId)?.id ?? undefined,
          action: houseMoneyArray?.find(houseMoney => houseMoney.unitId === unit.id)
            ?.houseMoneyAmounts
            ?.find(houseMoneyAmount => houseMoneyAmount.reserveFundId === undefined)?.id ? undefined : ActionTypes.CREATE,
        }) ?? [],
      ) ?? []) as OpsCostItem[],
    );
  };

  const { reserveAccountDrafts } = property;

  const columns: ColumnsType<UnitProjectionDto> = useMemo(() => {
    const reserveFundColumns = reserveAccountDrafts?.map((rad, colIndex) => ({
      title: rad.name,
      dataIndex: `reserveAccount-${rad.id}`,
      width: 200,
      render: (_: any, _record: UnitProjectionDto, rowIndex: number) => (
        <ReserveFundCell
          isEditable={isEditable}
          rowIndex={rowIndex}
          columnIndex={colIndex}
        />
      ),
    })) ?? [];

    return [
      {
        title: tl(economicPlanHouseMoneyTableTranslations.houseMoneyTable.header.unitId),
        dataIndex: 'id',
        width: 200,
        fixed: 'left',
        render: (_unitId: number, unit: UnitProjectionDto) => {
          const unitContract = activeContractsList.data?.find(ctr => ctr.unitId === unit.id);
          const unitInfo = unit.unitNrSharingDeclaration?.concat(' - ', unitContract?.mailingContact?.name ?? '');
          return (
            <Tooltip
              title={unitInfo}
              placement="topLeft"
              className="truncated-text"
            >
              <Input
                className="Input InputOwnerName"
                defaultValue={unitInfo}
                onChange={() => {
                }}
                title={unitInfo}
                value={unitInfo}
                disabled
              />
            </Tooltip>
          );
        },
      },
      {
        title: tl(economicPlanHouseMoneyTableTranslations.houseMoneyTable.header.opsCost),
        dataIndex: 'opsCost',
        width: 200,
        render: (_, unit) => (
          <OpsCostCell unitId={unit.id} isEditable={isEditable} />
        ),
      },
      ...reserveFundColumns,
      {
        title: tl(economicPlanHouseMoneyTableTranslations.houseMoneyTable.header.totalOfUnit),
        dataIndex: '',
        width: 200,
        fixed: 'right',
        render: (_, unit, rowIndex) => (
          <TotalOfUnitCell unitId={unit.id} rowIndex={rowIndex} />
        ),
      },
    ];
  }, [reserveAccountDrafts, unitsList, activeContractsList, isEditable]);

  // because setting opsCost happens in a useEffect in this file, that means that dataSource would be set to unitsList
  // and the table would start rendering before the opsCost values get set; this causes the first (or first few, depends)
  // lines to appear with amount 0 initially, and you need to scroll away and back for the component to re-render and
  // show the correct value; this is a temporary fix
  const dataSource = allNecessaryDataIsReady
    ? unitsList.data
    : [];

  return {
    columns,
    dataSource,
    loading: houseMoneyList.loading || unitsList.loading || activeContractsList.loading,
    economicPlanId: economicPlan?.data?.id,
  };
};
