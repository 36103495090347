import { LanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { getDocumentPreviewRoute } from 'pages/DocumentPreview/routes';
import {
  useDocumentContext, useSearchContext, useMessageSendingPropertyContext, useMessageSendingContext,
} from 'pages/MessageSendingPage/services/MessageSendingContext';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useScoreStringSearch } from 'services/search/useScoreStringSearch';
import { useExpandableTableProps } from 'storybook-components/table/ExpandableTableProps/useExpandableTableProps';
import { useDragNDropTableColumns } from 'storybook-components/table/TableDragNDropSorting/useDragNDropTableColumns';
import { useVirtualizedDragNDropTable } from 'storybook-components/table/TableDragNDropSorting/virtualized/useVirtualizedDragNDropTable';
import RecipientCell from './components/RecipientCell/RecipientCell';
import MessageDocumentTableOptions from './components/TableOptions/MessageDocumentTableOptions';

export const useMessageDocumentTable = () => {
  const { tl } = useContext(LanguageContext);
  const [expandedRowKeysInitialized, setExpandedRowKeysInitialized] = useState<boolean>(false);
  const location = useLocation();
  const propertyContext = useMessageSendingPropertyContext('useMessageDocumentTable');
  const documentContext = useDocumentContext('useMessageDocumentTable');
  const messageSendingContext = useMessageSendingContext('useMessageDocumentTable');
  const searchContext = useSearchContext('');

  const { properties } = propertyContext;
  const { setDocumentIndexes, documentDatasource, setDocumentDatasource } = documentContext;
  const { documentSearchString: searchString } = searchContext;
  const { setDirty } = messageSendingContext;

  const isExpandable = record => record.movable && record.children;
  const indentRow = record => !record.movable && !record.children;

  const {
    expandedRowKeys, setExpandedRowKeys, onExpand, expandIconCell,
  } = useExpandableTableProps('rowKey', isExpandable, indentRow);

  useEffect(() => {
    if (!isEmpty(documentDatasource.data) && !expandedRowKeysInitialized) {
      setExpandedRowKeys(documentDatasource.data?.flatMap((rc) => {
        const parentRowKey = rc && rc.rowKey ? [rc.rowKey] : [];
        return parentRowKey.concat(rc?.children?.map(c => c.rowKey) ?? []);
      }));
      setExpandedRowKeysInitialized(true);
    }
  }, [documentDatasource, expandedRowKeysInitialized]);

  useEffect(() => {
    setDocumentIndexes((prev) => {
      const docs = documentDatasource.data?.flatMap(prp => prp.children).flatMap(d => d?.children || d);
      const newIndexes = {};
      docs?.filter(d => d !== undefined).forEach((d, idx) => { newIndexes[d.id] = idx; });

      return ({
        ...prev,
        ...newIndexes,
      });
    });
  }, [documentDatasource]);


  const scoreStringSearch = useScoreStringSearch();

  const filteredDatasource = useMemo(() => scoreStringSearch(documentDatasource.data, searchString), [documentDatasource.data, searchString]);


  const customMoveRow = (dragItem, hoverItem) => {
    setDocumentDatasource((prev) => {
      const flatData = prev.data?.flatMap(d => d.children || d);
      const realDragIndex = flatData.findIndex(d => d.rowKey === dragItem.rowKey);
      const dragRow = flatData[realDragIndex];
      const realHoverIndex = flatData.findIndex(d => d.rowKey === hoverItem.rowKey);
      flatData.splice(realDragIndex, 1);
      flatData.splice(realHoverIndex, 0, dragRow);
      const newData = prev.data?.map(prp => ({
        ...prp,
        children: !isEmpty(prp.children) ? flatData.filter(c => c.propertyId === prp.propertyId) : undefined,
      }));
      setDirty(true);
      return prev.load(newData);
    });
  };

  const { onRow, components } = useVirtualizedDragNDropTable({ customMoveRow });


  const columns = [
    {
      dataIndex: 'displayName',
      title: tl(messageSendingTranslations.documentTable.columns.fileOrProperty),
      width: '80%',
      render: (_, record) => expandIconCell(
        record,
        expandedRowKeys?.includes(record.rowKey),
        (record.children
          ? <span>{record.displayName}</span>
          : <Link to={`${location.pathname}${getDocumentPreviewRoute(record.id)}`}>{record.displayName}</Link>
        ),
      ),
    },
    {
      dataIndex: 'recipients',
      title: tl(messageSendingTranslations.documentTable.columns.recipients),
      width: '15%',
      render: (_, record) => (record.children ? <span /> : <RecipientCell record={record} />),
    },
    {
      render: (_, record) => <MessageDocumentTableOptions record={record} />,
      width: 50,
    },
  ];

  const emptyTableText = !isEmpty(searchString) ? tl(messageSendingTranslations.emptyTableText) : '';

  return {
    datasource: filteredDatasource,
    columns: useDragNDropTableColumns(columns),
    loading: documentDatasource.loading || properties.loading,
    isExpandable,
    onRow,
    components,
    expandedRowKeys,
    onExpand,
    emptyTableText,
  };
};
