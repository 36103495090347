import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { useAnnexTable } from './useAnnexTable';
import AnnexTableSummary from './AnnexTableSummary';


const AnnexTable = ({ print }: { print: boolean }) => {
  const {
    columns, dataSource, sums, vatRelevantProperty,
  } = useAnnexTable();
  const { tl } = useContext(LanguageContext);

  return (
    <div className="AnnexTable">
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        // need to specify scrollX for the fixed columns to work as expected
        // components needs to know if it's printed or not, because scroll={{x: 2000}} makes the height if the table 200.0rem in pdf
        scroll={{ x: print ? 'auto' : 2000 }}
        summary={() => (
          <AnnexTableSummary
            title={tl(translations.report.sections.annexSection.total)}
            displayVat={vatRelevantProperty}
            {...sums}
          />
        )}
        rowKey="unitNrSharingDeclaration"
      />
    </div>
  );
};

export default AnnexTable;
