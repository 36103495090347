import { createBrowserHistory } from 'history';

function createClientHistory() {
  if (typeof document === 'undefined') return null;
  return createBrowserHistory();
}

export const history: any = createClientHistory();

export function navigateConcat(path: String, state?: any) {
  if (history) history.push(history.location.pathname + path, state);
}
