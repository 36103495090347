import { ContractContactProjectionDtoRoleEnum } from 'api/accounting';

export const messageSendingTranslations = {
  title: {
    en: 'Message sending',
    de: 'Nachrichtenversand',
  },
  cancel: {
    en: 'Cancel',
    de: 'Abbrechen',
  },
  sourceType: {
    PROFIT_AND_LOSS: {
      en: 'Profit and Loss report',
      de: 'Eigentümerbericht',
    },
    OPS_COST_REPORT: {
      en: 'Ops cost report',
      de: 'Betriebskostenabrechnung',
    },
    SERIAL_LETTER: {
      en: 'Serial letter',
      de: 'Serienbrief',
    },
    HEATING_COST_DISTRIBUTION: {
      en: 'WKA',
      de: 'WKA',
    },
    DUNNING_ANNEX: {
      en: 'Account Overview',
      de: 'Kontenübersicht',
    },
    DUNNING: {
      en: 'Dunning note',
      de: 'Mahnschreiben',
    },
    DUNNING_DD_MANDATE: {
      en: 'Direct Debit Mandate',
      de: 'SEPA-Lastschriftmandat',
    },
    ECONOMIC_PLAN: {
      en: 'Economic plan',
      de: 'Wirtschaftsplan',
    },
    HOUSE_MONEY_SETTLEMENT: {
      en: 'House Money Settlement',
      de: 'Hausgeldabrechnung',
    },
    SPECIAL_CONTRIBUTION: {
      en: 'Special contributions',
      de: 'Sonderumlage',
    },
    OM_INVITE: {
      en: 'Owners meeting invitation',
      de: 'Eigentümerversammlung',
    },
    OWNERS_MEETING_INVITATION: {
      en: 'Owners meeting invitation',
      de: 'Eigentümerversammlung',
    },
  },
  execute: {
    en: 'Execute',
    de: 'Ausführen',
  },
  recipientSelector: {
    en: 'Recipients overview',
    de: 'Empfänger Übersicht',
  },
  addRecipient: {
    en: 'Add recipient',
    de: 'Empfänger hinzufügen',
  },
  preference: {
    en: 'By preference',
    de: 'Kontaktpräferenz',
  },
  selection: {
    en: 'Based on selection',
    de: 'Anhand der Auswahl',
  },
  distribute: {
    en: 'Distribution options',
    de: 'Zustellung',
  },
  applyToAll: {
    en: 'Apply to all',
    de: 'Für alle anwenden',
  },
  portal: {
    en: 'Portal',
    de: 'Portal ',
  },
  portalDisabledtooltipText: {
    en: 'Check out our Portal connections here.',
    de: 'Erfahren Sie hier mehr über unsere Portal Anbindungen.',
  },
  differentSettingTooltipText: {
    en: (properties: string) => `Can only be applied to contacts of ${properties}.`,
    de: (properties: string) => `Kann nur für Kontakte aus ${properties} genutzt werden.`,
  },
  epost: {
    en: 'E-Post',
    de: 'E-Post',
  },
  epostDisabledTooltipText: {
    en: 'Check out how to connect E-POST here.',
    de: 'Erfahren Sie hier, wie Sie E-POST anbinden.',
  },
  epostCredentialsInvalidText: {
    en: 'E-Post credentials are invalid, please contact you administrator.',
    de: 'Die E-Post Integration ist fehlerhaft. Bitte kontaktieren Sie ihren Admin',
  },
  manual: {
    en: 'Manual',
    de: 'Manuell',
  },
  columns: {
    recipient: {
      en: 'Recipient',
      de: 'Empfänger',
    },
    distribution: {
      en: 'Distribution',
      de: 'Versandweg',
    },
    files: {
      en: 'Files',
      de: 'Dokumente',
    },
  },
  distributionOptions: {
    epost: {
      en: 'E-Post',
      de: 'E-Post',
    },
    portal: {
      en: 'Portal',
      de: 'Portal',
    },
    manual: {
      en: 'Manual',
      de: 'Manuell',
    },
  },
  confirmationModal: {
    title: {
      en: 'Send out messages',
      de: 'Nachrichten versenden',
    },
    content: {
      en: 'Upon sending all contacts will receive the message via the selected distribution ways.',
      de: 'Beim Versenden erhalten alle Kontakte die Nachricht über die gewählten Versandwege.',
    },
    send: {
      en: 'Send',
      de: 'Versenden',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
  documentTable: {
    title: {
      en: 'Files',
      de: 'Dokumente',
    },
    columns: {
      fileOrProperty: {
        en: 'Files / Properties',
        de: 'Dokumente / Objekte',
      },
      recipients: {
        en: 'Recipients',
        de: 'Empfänger',
      },
    },
    options: {
      remove: {
        en: 'Remove',
        de: 'Entfernen',
      },
    },
    info: {
      en: 'The document in the preview contains the address of the currently active contract. If you select other recipients for it, their address will be printed on the document upon sending via E-Post.',
      de: 'Das Dokument in der Vorschau enthält die Adresse des aktuell aktiven Vertrags. Wenn Sie andere Empfänger für das Dokument auswählen, wird beim Versand über E-Post deren Adresse auf dem Dokument abgedruckt.',
    },
  },
  recipientFiles: {
    title: {
      en: 'File overview',
      de: 'Dokumentenübersicht',
    },
    files: {
      en: 'Files',
      de: 'Dokumente',
    },
    selectedFiles: {
      en: 'Selected files',
      de: 'Ausgewählte Dokumente',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    done: {
      en: 'Done',
      de: 'Erledigt',
    },
  },
  notifications: {
    errorLoadingDocument: {
      en: 'Failed to load document.',
      de: 'Dokument konnte nicht geladen werden.',
    },
    sendError: {
      en: 'Failed to create messages.',
      de: 'Nachrichten konnten nicht erstellt werden.',
    },
    sendSuccess: {
      en: 'Messages successfully created.',
      de: 'Nachrichten wurden erfolgreich erstellt.',
    },
    loadConnectionError: {
      en: 'Connection could not be loaded.',
      de: 'Verbindung wurde nicht gefunden.',
    },
    loadContractsError: {
      en: 'Failed to load contracts.',
      de: 'Verträge konnten nicht geladen werden.',
    },
    errorLoadingContractSyncData: {
      en: 'Failed to load contract sync data',
      de: 'Datenzugriff fehlgeschlagen, Synchronisation mit dem Portal konnte nicht überprüft werden.'
    },
    errorLoadingContactSyncData: {
      en: 'Failed to load contact sync data',
      de: 'Datenzugriff fehlgeschlagen, Synchronisation mit dem Portal konnte nicht überprüft werden.'
    },
    loadPropertyError: {
      en: 'Failed to load properties',
      de: 'Objekten konnten nicht geladen werden.',
    },
    missingRecipients: {
      en: 'Some files do not have a recipient assigned.',
      de: 'Für einige Dokumente wurde noch kein Empfänger ausgewählt.',
    },
    missingDispatchTypes: {
      en: 'Some recipients do not have a dispatch type selected.',
      de: 'Für einige Empfänger ist noch kein Versandweg ausgewählt.',
    },
    missingAddresses: {
      en: 'Some contacts do not have an address.',
      de: 'Für einige Kontakte ist keine Addresse hinterlegt.',
    },
    invalidEpostAddresses: {
      en: 'Some contacts do not have a valid address.',
      de: 'Einige Kontakte haben keine gültige Adresse.',
    },
    contractNotSynced: {
      en: 'Some contracts are not correctly exported to the portal',
      de: 'Einige Verträge wurden nicht korrekt ins Portal exportiert.',
    },
    contactNotSynced: {
      en: 'Some contacts are not correctly exported to the portal.',
      de: 'Einige Kontakte wurden nicht korrekt ins Portal exportiert.',
    },
    propertyNotSynced: {
      en: 'Some proeprties are not correctly exported to the portal',
      de: '', // TODO DE
    },
    loadContactsError: {
      en: 'Failed to load contacts.',
      de: 'Kontakte konnten nicht geladen werden.',
    },
  },
  warnings: {
    missingRecipientTitle: {
      en: 'Missing recipients',
      de: 'Fehlende Empfänger',
    },
    missingRecipientLine1: {
      en: 'The following files don´t have a recipient assigned:',
      de: 'Den folgenden Dateien wurden keine Empfänger zugewiesen:',
    },
    missingRecipientLine2: {
      en: 'Please add recipients by clicking on the recipient icon next to the file name or remove the files via the option in the three dot menu.',
      de: 'Fügen Sie bitte Empfänger hinzu, indem Sie auf das Empfänger Symbol neben dem Dateinamen klicken oder löschen Sie die Dateien über die Option im Drei-Punkte-Menü.',
    },
    missingDispatchTypeTitle: {
      en: 'Missing dispatch type',
      de: 'Fehlender Versandweg',
    },
    missingDispatchTypeLine1: {
      en: 'The following recipients don´t have a dispatch type selected:',
      de: 'Für folgende Empfänger ist kein Versandweg ausgewählt:',
    },
    missingDispatchTypeLine2: {
      en: 'Please add a dispatch type by selecting an option in the drop down menu or remove the recipient via the option in the three dot menu.',
      de: 'Fügen Sie bitte den Versandweg hinzu, indem Sie das Auswahlmenü öffnen oder entfernen Sie den Empfänger über die Option im Drei-Punkte-Menü.',
    },
    missingDispatchTypeArticeTitle: {
      en: 'Manage dispatch preference of contacts',
      de: 'Kontaktpräferenz von Kontakten pflegen',
    },
    missingAddressTitle: {
      en: 'Contacts without address',
      de: 'Kontakte ohne Addresse',
    },
    missingAddressLine1: {
      en: 'The following contacts don´t have an address:',
      de: 'Für folgende Kontakte ist keine Addresse hinterlegt:',
    },
    missingAddressLine2: {
      en: 'Please add a mailing address for the contacts listed.',
      de: 'Fügen Sie bitte die Postanschrift für die aufgelisteten Kontakte hinzu.',
    },
    invalidEpostAddresss: {
      en: 'Contacts without valid address.',
      de: 'Kontakte ohne gültige Adresse.',
    },
    invalidEpostAddressLine1: {
      en: 'The following contacts don´t have a valid address:',
      de: 'Die folgenden Kontakte haben keine gültige Adresse:',
    },
    invalidEpostAddressLine2: {
      en: 'Please add a correct address for the contacts listed.',
      de: 'Bitte fügen Sie eine gültige Adresse für folgende Kontakte hinzu.',
    },
    contractNotSyncedTitle: {
      en: 'Contracts not correctly exported to the portal.',
      de: 'Verträge wurden nicht korrekt ins Portal exportiert.',
    },
    contractNotSyncedLine1: {
      en: 'The following contracts are not correctly exported to the portal:',
      de: 'Folgende Verträge wurden nicht korrekt ins Portal exportiert:',
    },
    contractNotSyncedLine2: {
      en: 'Please verify the help article for possible causes.',
      de: 'Bitte überprüfen Sie die in der Hilfeartikel aufgelisteten möglichen Ursachen hierfür.',
    },
    contactNotSyncedTitle: {
      en: 'Contracts with non-exported contacts.',
      de: 'Verträge ohne exportierte Kontakte.',
    },
    contactNotSyncedLine1: {
      en: 'The following contracts contain contacts that were not exported to the portal:',
      de: 'Die folgenden Verträge enthalten Kontakte, die nicht ins Portal exportiert wurden:',
    },
    contactNotSyncedLine2: {
      en: 'Please refer to the help article for possible causes.',
      de: 'Bitte beachten Sie den Support-Artikel für mögliche Ursachen.',
    },
    missingAddressArticleTitle: {
      en: 'Contact mailing address',
      de: 'Postanschrift von Kontakten',
    },
    syncProblemsArticleTitle: {
      en: 'Data Conflicts and Export Issues',
      de: 'Datenkonflikte und Export-Probleme',
    },
    propertyNotSyncedTitle: {
      en: 'Properties not correctly exported to the portal.',
      de: 'Objekte wurden nicht korrekt ins Portal exportiert',
    },
    propertyNotSyncedLine1: {
      en: 'The following properties are not correctly exported to the portal.',
      de: 'Die folgenden Objekte wurden nicht korrekt ins Portal exportiert.',
    },
    propertyNotSyncedLine2: {
      en: 'Please refer to the help article for possible causes.',
      de: 'Bitte beachten Sie den Support-Artikel für mögliche Ursachen.',
    },
    moreActivePortalsTitle: {
      en: 'Multiple active portal connections',
      de: 'Mehrere aktive Portal-Anbindungen',
    },
    moreActivePortalsLine1: {
      en: 'The following portals are active:',
      de: 'Folgende Anbindungen sind aktiv:',
    },
    moreActivePortalsLine2Start: {
      en: 'Please either select another dispatch option than “Portal” or ',
      de: 'Um mit dem Versenden fortzufahren, wählen Sie bitte entweder einen anderen Versandweg als “Portal” oder ',
    },
    moreActivePortalsSupport: {
      en: 'contact our support team',
      de: 'kontaktieren Sie unser Support Team',
    },
    moreActivePortalsLine2End: {
      en: ' to deactivate one of the connections for the management company.',
      de: '.',
    },
  },
  emptyTableText: {
    en: 'No results could be found for your search terms. Please try again using different terms.',
    de: 'Für Ihre Suche konnten keine Ergebnisse gefunden werden. Bitte versuchen Sie es mit anderen Begriffen erneut.',
  },
  role: {
    [ContractContactProjectionDtoRoleEnum.OWNER]: {
      en: 'Owner',
      de: 'Eigentümer',
    },
    [ContractContactProjectionDtoRoleEnum.TENANT]: {
      en: 'Tenant',
      de: 'Mieter',
    },
    [ContractContactProjectionDtoRoleEnum.COUNCIL]: {
      en: 'Council',
      de: 'Beirat',
    },
    [ContractContactProjectionDtoRoleEnum.HEAD_OF_COUNCIL]: {
      en: 'Head of Council',
      de: 'Beiratsvorsitz',
    },
    [ContractContactProjectionDtoRoleEnum.PROPERTY_OWNER]: {
      en: 'Property Owner',
      de: 'Objekteigentümer',
    },
    [ContractContactProjectionDtoRoleEnum.LEGAL_REPRESENTATIVE]: {
      en: 'Legal Representative',
      de: 'Bevollmächtigte',
    },
    [ContractContactProjectionDtoRoleEnum.SUBTENANT]: {
      en: 'Subtenant',
      de: 'Untermieter',
    },
    [ContractContactProjectionDtoRoleEnum.RESIDENT]: {
      en: 'Resident',
      de: 'Bewohner',
    },
    [ContractContactProjectionDtoRoleEnum.LEGAL_GUARDIAN]: {
      en: 'Legal Guardian',
      de: 'Vormund',
    },
  },
};
