import React, { useContext, useEffect } from 'react';
import { Alert, Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import Icon from '@ant-design/icons';
import { useHouseMoneyTable } from './services/useHouseMoneyTable';
import TableSummary from './components/TableSummary/TableSummary';
import TableContentLoadingIndicator from '../../../../elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { useLoadHouseMoney } from './services/useLoadHouseMoney';
import './HouseMoneyTable.scss';
import { ICONS } from '../../../../components/icons';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { economicPlanHouseMoneyTableTranslations } from './economicPlanHouseMoneyTableTranslations';

export const HouseMoneyTable = () => {
  const { tl } = useContext(LanguageContext);
  const {
    dataSource,
    columns,
    loading,
    economicPlanId,
  } = useHouseMoneyTable();

  const { loadHouseMoneyAmounts } = useLoadHouseMoney();

  useEffect(() => {
    loadHouseMoneyAmounts(economicPlanId);
  }, [economicPlanId]);

  const tableHeight = 450;
  const [
    VT,
  ] = useVT(() => ({
    scroll: {
      y: tableHeight,
    },
  }), []);

  if (!economicPlanId) {
    return (
      <Alert
        message={tl(economicPlanHouseMoneyTableTranslations.saveEconomicPlanInfoText)}
        type="info"
        showIcon
        icon={<Icon component={ICONS.info} />}
      />
    );
  }

  return (
    <Table
      className="HouseMoneyTable"
      loading={{
        indicator: <TableContentLoadingIndicator />,
        spinning: loading,
      }}
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ y: tableHeight }}
      components={VT}
      summary={TableSummary}
    />
  );
};
