import { useContext, useMemo } from 'react';

import { CompanyEditingContext } from 'contexts/CompanyEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { OptionInterface } from 'elements/Inputs/SmartSearch/SmartSearch';
import { translations } from 'elements/Translation/translations';
import { range } from 'lib/Utils';
import useCountryOptions from 'services/useCountryOptions';

import defaultSection from './defaultSection';
import { ContactLegacyDtoDispatchPreferencesEnum } from 'api/accounting';

interface ContactSectionProps {
  addressNumber: number
  duplicate: boolean
}

export default function useContactSection(props: ContactSectionProps) {
  const { addressNumber, duplicate } = props;
  const { tl, language } = useContext(LanguageContext);
  const companyEditingContext: any = useContext(CompanyEditingContext);
  const company = companyEditingContext.company ? companyEditingContext.company.data : {};

  const dispatchOptions = useMemo(
    () =>
      Object.values(ContactLegacyDtoDispatchPreferencesEnum).map(
        (dispatchType) => ({
          value: dispatchType,
          label: tl(
            translations.pages.contactEditing.contactSection.contactSection
              .dispatchTypes[dispatchType]
          ),
        })
      ),
    [language]
  );

  const addAddressButton = [
    {
      type: 'addButton',
      key: 'addressNumber',
      props: {
        label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.add),
      },
    },
  ];

  const duplicateInfo = [
    {
      type: 'info',
      key: '',
      props: {
        label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.companyDuplicate),
      },
    },
  ];

  function addressInputs(index: number, isLast: boolean, countryOptions: OptionInterface[]) {
    const isInvoicingAddress = company && company.addresses && company.addresses.length > index && company.addresses[index].forInvoice;
    const section = [{
      sectionId: `address${index}`,
      title: isInvoicingAddress || index === 1 ? tl(translations.pages.contactEditing.contactSection.addressSection.invoicingAddress) : tl(translations.pages.contactEditing.contactSection.addressSection.mailingAddress),
      content: [
        [
          {
            type: 'text',
            key: `addresses[${index}].recipient`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.recipient),
            },
          },
          {},
        ],
        [
          {
            type: 'text',
            key: `addresses[${index}].street`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.street),
            },
          },
          {
            type: 'text',
            key: `addresses[${index}].number`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.number),
            },
          },
        ],
        [
          {
            type: 'text',
            key: `addresses[${index}].additionalAddress1`,
            props: {
              label: `${tl(translations.pages.contactEditing.contactSection.addressSection.fields.additional)} 1`,
            },
          },
          {
            type: 'text',
            key: `addresses[${index}].additionalAddress2`,
            props: {
              label: `${tl(translations.pages.contactEditing.contactSection.addressSection.fields.additional)} 2`,
            },
          },
        ],
        [
          {
            type: 'text',
            key: `addresses[${index}].postalCode`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.postalCode),
            },
          },
          {
            type: 'text',
            key: `addresses[${index}].city`,
            props: {
              required: true,
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.location),
            },
          },
        ],
        [
          {
            type: 'select',
            key: `addresses[${index}].country`,
            props: {
              label: tl(translations.pages.contactEditing.contactSection.addressSection.fields.country.title),
              required: true,
              showSearch: true,
              options: countryOptions,
            },
          },
          {},
        ],
      ],
    }];
    if (isLast && index === 0) section[0].content.push(addAddressButton);
    if (isLast && duplicate) section[0].content.push(duplicateInfo);
    return section;
  }

  function useGetAddressInputs(numberAddresses: number) {
    const { countryOptions } = useCountryOptions();
    const displayNumber = Math.max(0, numberAddresses - 1) || 0;
    return range(0, displayNumber).reduce((accumulator: Array<any>, number: number) => accumulator.concat(addressInputs(number, number === displayNumber, countryOptions)), []);
  }


  return {
    ...defaultSection(0),
    sectionTitle: tl(translations.pages.companyEditing.contactSection.infoSection.title),
    sectionId: 'contactData',
    content: [
      {
        sectionId: 'info',
        title: tl(translations.pages.companyEditing.contactSection.infoSection.title),
        content: [
          [
            {
              type: 'text',
              key: 'companyName',
              props: {
                required: true,
                label: tl(translations.pages.companyEditing.contactSection.infoSection.fields.name),
              },
            },
            {
              type: 'text',
              key: 'tradeRegisterNumber',
              props: {
                label: tl(translations.pages.companyEditing.contactSection.infoSection.fields.registrationNumber),
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'vatId',
              props: {
                label: tl(translations.pages.companyEditing.contactSection.infoSection.fields.vatId),
              },
            },
            {
              type: 'text',
              key: 'website',
              props: {
                label: tl(translations.pages.companyEditing.contactSection.infoSection.fields.website),
              },
            },
          ],
          [{
            type: 'textArea',
            key: 'notes',
            props: {
              label: tl(translations.pages.companyEditing.contactSection.infoSection.fields.note),
            },
          }, {}],
        ],
      },
      ...useGetAddressInputs(addressNumber),
      {
        sectionId: 'phones',
        title: tl(translations.pages.contactEditing.contactSection.phoneSection.title),
        content: [
          [
            {
              type: 'text',
              key: 'email',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.email),
              },
            },
            {
              type: 'text',
              key: 'phoneBusiness',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.phoneBusiness),
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'phoneMobile',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.phoneMobile),
              },
            },
            {
              type: 'text',
              key: 'phonePrivate',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.phonePrivate),
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'fax',
              props: {
                label: tl(translations.pages.contactEditing.contactSection.phoneSection.fields.fax),
              },
            },
            {},
          ],
        ],
      },
      {
        sectionId: 'contact',
        title: `${tl(translations.pages.contactEditing.contactSection.contactSection.title)}`,
        content: [
          [
            {
              type: 'checkboxGroup',
              key: 'dispatchPreferences',
              props: {
                label: '',
                options: dispatchOptions,
              },
            },
            {},
          ],
        ],
      },
    ],
  };
}
