import React, { useContext } from 'react';

import { ColumnsType } from 'antd/lib/table/interface';
import { AgendaItemDto } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  useDragNDropTableColumns,
} from 'storybook-components/table/TableDragNDropSorting/useDragNDropTableColumns';

import Amount from 'storybook-components/typography/Amount/Amount';
import AgendaItemTableOptionsMenu
  from '../components/AgendaItemTableOptionsMenu/AgendaItemTableOptionsMenu';
import {
  translations as ownersMeetingProtocolTranslations,
} from '../translations';

export const useAgendaItemsTableColumns = () => {
  const { tl } = useContext(LanguageContext);

  const columns: ColumnsType<AgendaItemDto> = [
    {
      title: tl(ownersMeetingProtocolTranslations.agendaItemsTable.table.topicNr),
      dataIndex: 'topicNumber',
      width: '10%',
      render: (amount: number) => (
        <Amount>{amount}</Amount>
      ),
    },
    {
      title: tl(ownersMeetingProtocolTranslations.agendaItemsTable.table.title),
      dataIndex: 'title',
      width: '70%',
    },
    {
      title: tl(ownersMeetingProtocolTranslations.agendaItemsTable.table.voteStatus.title),
      render: (_, record) => (record.agendaItemVote?.status ? tl(ownersMeetingProtocolTranslations.agendaItemsTable.table.voteStatus.statusOptions[record.agendaItemVote?.status]) : tl(ownersMeetingProtocolTranslations.agendaItemsTable.table.voteStatus.statusOptions.DRAFT)),
      width: '14%',
    },
    {
      title: '',
      dataIndex: 'optionsMenu',
      width: '6%',
      render: (_, record) => <AgendaItemTableOptionsMenu record={record} />,
    },
  ];

  return {
    columns: useDragNDropTableColumns(columns),
  };
};
