export const translations = {
  title: { en: 'Properties', de: 'Objekte' },
  description: { en: 'List of properties to upload', de: 'Liste der Objekte zum hochladen' },
  upload: { en: 'Upload properties', de: 'Objekte hochladen' },
  validations: {
    success: { en: 'Properties have been verified and ready for import.', de: 'Objektdaten wuden geprüft und sind bereit zum Importieren' },
    errors: { en: 'Issues found in the CSV', de: 'Es wurden Fehlern gefunden' },
    issues: {
      name: { en: 'Invalid name', de: '' },
      propertyIdInternal: { en: 'Invalid propertyIdInternal', de: '' },
      managementCompany: { en: 'Cannot find referenced management company', de: '' },
      administrationType: { en: 'Invalid administrationType', de: '' },
      country: { en: 'Invalid country code. Only ISO values are supported.', de: 'Nur 2 Buchstaben / ISO Landreferenzen dürfen verwendet werden. z.B.: DE für Deutschland' },
      city: { en: 'Invalid city', de: 'Ungültiges Ort' },
      street: { en: 'Invalid street', de: 'Ungültige Straße' },
      number: { en: 'Invalid number', de: 'Ungültiges Hausnummer' },
      economicYearStart: { en: 'Invalid economic year start. Must be in ISO format: yyyy-mm-dd', de: '' },
      vatRelevance: {
        en: 'Invalid VAT rate',
        de: 'Ungültiges Umsatzsteuerpflicht',
      },
      vatRateCountryCode: {
        en: 'Invalid VAT country',
        de: 'Ungültiges Umsatzsteuer Land',
      },
      vatEligibilityError: {
        en: 'VAT eligibility cannot be specified if VAT eligibility type is area',
        de: 'Die anrechenbare Umsatzsteuer kann nicht angegeben werden, wenn als Art die Option "Fläche" ausgewählt ist.',
      },
    },
  },
};
