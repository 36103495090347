import { BankTransactionProjectionDtoTransactionPaymentStatusEnum, GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum } from 'api/accounting';
import { DefaultDataInterface } from 'lib/data';
import _, { isEmpty } from 'lodash';
import { Key, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { BankTransactionListLocationStateType, ExtendedAllocationGroup, ExtendedBankTransaction } from '../interfaces';
import { useBankTransactionListContext, useBankTransactionListDrawerContext } from './BankTransactionListContext';

export const useSelectedTransactions = () => {
  const history = useHistory();
  const location = useLocation<BankTransactionListLocationStateType>();
  
  const params = useParams<{ allocationGroupId: string | undefined }>();

  const {
    selectedTransactions,
    setSelectedTransactions,
    selectedRowKeys,
    setSelectedRowKeys,
    setAllocationVisible,
  } = useBankTransactionListDrawerContext('useSelectedTransactions');

  const {
    filterState,
    bankTransactionListState,
    setBankTransactionListState,
  } = useBankTransactionListContext('useSelectedTransactions');

  const onChangeSelectedRowKeys = (newKeys: Key[]) => {
    setSelectedRowKeys(newKeys);

    const records = bankTransactionListState.data?.flatMap(ds => (!_.isEmpty(ds.children) ? ds.children : ds))
      .filter(row => newKeys.includes(row.rowKey));
    setSelectedTransactions(records);

    if (isEmpty(newKeys)) {
      history.replace('/bank-transactions', { navigatedFromApp: location.state?.navigatedFromApp });
      removePartiallyAllocatedTransactionsFromListIfOnDoneTab();
      removeAllocatedTransactionsFromListIfOnOpenTab();
      setAllocationVisible(false);
    }
  };


  const removePartiallyAllocatedTransactionsFromListIfOnDoneTab = () => {
    if (!_.isEmpty(filterState.transactionPaymentStatuses) && !filterState.transactionPaymentStatuses?.includes(GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.PARTIALLY_BOOKED)) {
      // partially is not in filter
      setBankTransactionListState((oldList: DefaultDataInterface<ExtendedAllocationGroup[]>) => {
        const newList: ExtendedAllocationGroup[] = _.cloneDeep(oldList.data) || [];
        return oldList.load(newList.filter(tx => tx.transactionPaymentStatus !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.PARTIALLY_BOOKED));
      });
    }
  };

  const removeAllocatedTransactionsFromListIfOnOpenTab = () => {
    const statusesToRemove: GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum[] = [];
    if (!_.isEmpty(filterState.transactionPaymentStatuses) && !filterState.transactionPaymentStatuses?.includes(GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.ASSIGNED)) {
      // if assigned is not in filter
      statusesToRemove.push(GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.ASSIGNED);
    }
    if (!_.isEmpty(filterState.transactionPaymentStatuses) && !filterState.transactionPaymentStatuses?.includes(GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.WONT_BE_ALLOCATED)) {
      // if assigned is not in filter
      statusesToRemove.push(GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum.WONT_BE_ALLOCATED);
    }

    setBankTransactionListState((oldList: DefaultDataInterface<ExtendedAllocationGroup[]>) => {
      const newList = oldList.load(oldList.data!.filter(tx => !statusesToRemove.includes(tx.transactionPaymentStatus! as unknown as GetAllocationGroupsUsingGETTransactionPaymentStatusesEnum)));

      if (oldList.loading) {
        // if we select a TX and refresh, then we fully allocate that TX => we will start loading a new list of TXs;
        // we need this `if` to make sure we keep the loading state
        return newList.startLoading();
      }

      return newList;
    });
  };

  const bankTransactionsInCurrentlySelectedGroup = useMemo(() => {
    const { allocationGroupId: allocationGroupIdString } = params;
    const allocationGroupId = allocationGroupIdString ? parseInt(allocationGroupIdString, 10) : undefined;
    const index = bankTransactionListState.data?.findIndex(tx => tx.allocationGroupId === allocationGroupId);

    if (bankTransactionListState.data === undefined || index === undefined || index === -1) return [];

    if (!_.isEmpty(bankTransactionListState.data[index].children)) {
      return bankTransactionListState.data[index].children?.map(bt => ({ ...bt, propertyList: JSON.parse(bt.properties || '[]') })) ?? [];
    }

    return [bankTransactionListState.data[index] as ExtendedBankTransaction].map(bt => ({ ...bt, propertyList: JSON.parse(bt.properties || '[]') })) ?? [];
  }, [bankTransactionListState.data, params.allocationGroupId]);

  return {
    selectedTransactions,
    bankTransactionsInCurrentlySelectedGroup,
    selectedRowKeys,
    onChangeSelectedRowKeys,
    removePartiallyAllocatedTransactionsFromListIfOnDoneTab,
    removeAllocatedTransactionsFromListIfOnOpenTab,
  };
};
