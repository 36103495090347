import { useContext, useMemo } from 'react';
// Polyfill needed to use Intl.DisplayNames in Safari < 14.1
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/de';
import { LanguageContext } from '../contexts/LanguageContext';
import { countryCodes } from '../lib/countries';

export default function useCountryOptions() {
  const { language } = useContext(LanguageContext);

  const regionNames = useMemo(() => new Intl.DisplayNames([language], { type: 'region' }), [language]);

  const countryOptions = countryCodes.map(code => ({
    value: code,
    label: `${regionNames.of(code)} • ${code}`,
  }));

  const translateCountry = (countryCode: string) => {
    const neededOption = countryCodes.find(code => code === countryCode);
    if (neededOption) {
      return regionNames.of(neededOption);
    }
    return null;
  };

  return {
    countryOptions,
    translateCountry,
  };
}
