import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
    title: string,
    ownerPaymentsFromSum: number,
    ownerPaymentsToSum: number,
    ownerPaymentsBalanceSum: number,
}

const PropertyOwnerPaymentsTableSummary = ({
  title, ownerPaymentsFromSum, ownerPaymentsToSum, ownerPaymentsBalanceSum,
}: Props) => (
  <Table.Summary>
    <Table.Summary.Row className="ProfitAndLossSummary">
      <Table.Summary.Cell index={0} colSpan={1}>
        {title}
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={2}>
        <Amount>{formatCurrency(ownerPaymentsFromSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={3}>
        <Amount>{formatCurrency(ownerPaymentsToSum, '-', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={4}>
        <Amount>{formatCurrency(ownerPaymentsBalanceSum, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default PropertyOwnerPaymentsTableSummary;
