import { useContext } from 'react';
import { InvoiceEditingContext } from '../../../../contexts/InvoiceEditingContext';

export default function useDefaultSection(index: number) {
  const invoiceEditingContext = useContext(InvoiceEditingContext);

  return {
    sectionNumber: index + 1,
    openingNumber: index + 1,
    buttonLoading: invoiceEditingContext.loading,
    value: invoiceEditingContext.data.invoice,
  };
}
