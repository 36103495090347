export const translations = {
  send: {
    en: 'Send out',
    de: 'Versenden',
  },
  download: {
    en: 'Download',
    de: 'Herunterladen',
  },
  preview: {
    en: 'Preview',
    de: 'Vorschau',
  },
  close: {
    en: 'Close',
    de: 'Schließen',
  },
  save: {
    en: 'Save',
    de: 'Speichern',
  },
  sendLetter: {
    en: 'Preview and send',
    de: 'Vorschau & Versenden',
  },
  viewMessages: {
    en: 'View messages',
    de: 'Nachrichten ansehen',
  },
  confirmModal: {
    confirmationTitle: {
      en: 'Are you sure you want to send the letter?',
      de: 'Sind Sie sicher, dass sie den Brief versenden wollen?',
    },
    confirmationText: {
      en: 'This will send out the letter for every selected owner',
      de: 'Nach ihrer Bestätigung wird der Brief an alle Eigentümer versendet.',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    ok: {
      en: 'Yes',
      de: 'Ja',
    },
  },
};
