import { useContext } from 'react';
import _ from 'lodash';
import { OpsCostContext, ReserveFundAmountContext } from '../../../../services/HouseMoneyContext';
import { economicPlanHouseMoneyTableTranslations } from '../../economicPlanHouseMoneyTableTranslations';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { SummaryRowCell } from './SummaryRowCell';
import { floatToFormattedString } from '../../../../../../lib/Utils';
import { PropertyListContext } from '../../../../../../contexts/PropertyListContext';
import { PropertyLegacyDto } from '../../../../../../api/accounting';
import { useUnitsList } from '../../../../../../services/UnitsList/useUnitsList';


export const useTableSummary = () => {
  const reserveFundAmountContext = useContext(ReserveFundAmountContext);
  const opsCostContext = useContext(OpsCostContext);

  if (reserveFundAmountContext === undefined || opsCostContext === undefined) {
    throw new Error('useTableSummary must be used within a HouseMoneyListContextProvider');
  }

  const { reserveFundAmounts } = reserveFundAmountContext;
  const { opsCosts } = opsCostContext;
  const { tl } = useContext(LanguageContext);
  const { selectedProperty } = useContext(PropertyListContext);
  const property: PropertyLegacyDto = selectedProperty?.data;

  const { unitsList } = useUnitsList({ propertyId: property?.id, isOwnedByWeg: false });

  const {
    reserveAccountDrafts,
  } = property;

  function sumOpsCost() {
    let total = 0;
    opsCosts.forEach((row) => {
      total = parseFloat(total?.toFixed(12)) + parseFloat(row.amount?.toFixed(12)!);
    });
    return total;
  }

  function sumReserveFund(columnIndex: number) {
    let total = 0;
    reserveFundAmounts.forEach((row) => {
      total = parseFloat(total?.toFixed(12)) + parseFloat(row[columnIndex]?.amount?.toFixed(12)!);
    });
    return total;
  }

  function sumTotalOfUnit(unitId: number) {
    return parseFloat(opsCosts.find(ops => ops.unitId === unitId)
      ?.amount
      ?.toFixed(12)!)
      + reserveFundAmounts.find(reserveFundAmount => reserveFundAmount[0].unitId === unitId)
        ?.reduce((total, rf) => parseFloat(total.toFixed(12)) + parseFloat(rf.amount!.toFixed(12))!, 0)!;
  }

  function sumTotalOfUnits() {
    return unitsList.data?.reduce((total, unit) => total + sumTotalOfUnit(unit.id!), 0);
  }

  const totalSummaryData: SummaryRowCell[] = [
    {
      id: 0,
      label: tl(economicPlanHouseMoneyTableTranslations.houseMoneyTable.summaryRow.label),
      total: `${floatToFormattedString(sumOpsCost()) || 0} €`,

    },
    ..._.range(reserveAccountDrafts?.length || 0)
      ?.map(index => (
        {
          id: index + 1,
          label: tl(economicPlanHouseMoneyTableTranslations.houseMoneyTable.summaryRow.label),
          total:
            `${floatToFormattedString(sumReserveFund(index)) || 0} €`,
        })),
    {
      id: (reserveAccountDrafts?.length || 0) + 1,
      label: tl(economicPlanHouseMoneyTableTranslations.houseMoneyTable.summaryRow.label),
      total: `${floatToFormattedString(sumTotalOfUnits()) || 0} €`,
    },

  ];


  return { totalSummaryData };
};
