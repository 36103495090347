import React from 'react';
import { PagesOrientationType } from 'pages/PdfPageAssigner/services/interfaces';
import PdfPageWrapper from './PdfPageWrapper';
import VirtualizePdfPageWrapper from './VirtualizePdfPageWrapper';


const PDF_PREVIEW_DEFAULT_WIDTH = 56;


interface PdfPreviewProps {
  pdf: any,
  pages: any[],
  virtualize?: boolean,
  scrollToPage: (pageNr: number) => void,
}


const PdfPreview = ({
  pdf, pages, virtualize, scrollToPage,
}: PdfPreviewProps) => {
  const PageComponent = virtualize ? VirtualizePdfPageWrapper : PdfPageWrapper;

  return (
    <div className="pdf-preview-container">
      <div className="pdf-preview-wrapper">
        {pages.map((page: any) => (
          <PageComponent
            page={page}
            key={pdf.fingerprints[0] + page._pageIndex}
            pdfPreviewWidth={PDF_PREVIEW_DEFAULT_WIDTH}
            parentWidth={PDF_PREVIEW_DEFAULT_WIDTH}
            selectableText={false}
            onClick={() => scrollToPage(page._pageIndex)}
            // no text layer for preview list
            renderTextLayer={false}
            viewMode={PagesOrientationType.LIST}
          />
        ))}
      </div>
    </div>
  );
};

export default PdfPreview;
