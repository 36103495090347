import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import CellWithTwoLinesAndTag from 'storybook-components/table/CellWithTwoLinesAndTag/CellWithTwoLinesAndTag';
import { useBankTransactionListPropertyColumn } from './useBankTransactionListPropertyColumn';

type BankTransactionListPropertyColumnProps = {
  propertyList: ExtendedBankTransaction['propertyList']
}

const BankTransactionListPropertyColumn = ({ propertyList }: BankTransactionListPropertyColumnProps) => {
  const initiallyVisibleProperty = useBankTransactionListPropertyColumn(propertyList);


  return (
    <CellWithTwoLinesAndTag
      firstElement={initiallyVisibleProperty?.propertyName}
      secondElement={getPropertyCellSubtitleInList(initiallyVisibleProperty, 'propertyAdministrationType')}
      tagLabel={propertyList?.length > 1 ? `+${propertyList?.length - 1}` : undefined}
    />
  );
};

export default BankTransactionListPropertyColumn;
