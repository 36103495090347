import { RouteDefinition } from '../../components/RenderRoutes';
import BankSettings from './BankSettings';

export const BankSettingsRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/settings',
      component: BankSettings,
    },
  ],
};
