import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { economicPlanTranslations } from '../../economicPlanTranslations';

function getDotColor(status: string) {
  switch (status) {
  case 'DRAFT':
    return DotColorEnum.GRAY;
  case 'DECIDED':
    return DotColorEnum.BLUE;
  case 'ACTIVE':
    return DotColorEnum.GREEN;
  case 'EXPIRED':
    return DotColorEnum.CREAM;
  case 'DELETED':
    return DotColorEnum.CREAM;
  default:
    return DotColorEnum.GRAY;
  }
}

export const useEconomicPlanListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(economicPlanTranslations.economicPlanList.column.status),
      dataKey: 'translatedStatus',
      render: (translatedStatus: string, economicPlan: any) => (
        <ColorDot color={getDotColor(economicPlan.status)}>
          {translatedStatus}
        </ColorDot>
      ),
    },
    {
      title: tl(economicPlanTranslations.economicPlanList.column.type),
      dataKey: 'type',
      render: (type: any) => (
        <>
          {type ? tl(economicPlanTranslations.economicPlanList[type]) : '-'}
        </>
      ),
    },
    {
      title: tl(economicPlanTranslations.economicPlanList.column.startDate),
      dataKey: 'startDate',
      format: FORMAT.DATE,
    },
    {
      title: tl(economicPlanTranslations.economicPlanList.column.title),
      dataKey: 'title',
    },
    {
      title: tl(economicPlanTranslations.economicPlanList.column.updatedBy),
      dataKey: 'updatedBy',
    },
    {
      title: tl(economicPlanTranslations.economicPlanList.column.updated),
      dataKey: 'updated',
      format: FORMAT.DATE,
    },
  ];
};
