import { CasaviConnectionControllerApi } from 'api/accounting';
import { ConnectionDto } from 'api/app';

/**
 * Since casavi token creation takes 20s:
 * 1. We check the local storage if we have a cached external token
 * 2. If yes: return otherwise create a token and cache it
 *
 * Caching works for 1 day
 *
 * We could do this automatically every day on the first usage, and then it wouldn't have an impact on UX
 * #that'dbeanimprovement #fridayforfuture #hackaday
 */
export const getCasaviToken = async (apiConfiguration, connection: ConnectionDto): Promise<string> => {
  let casaviToken = '';
  const localCasaviDataString = localStorage.getItem('casavi');
  if (localCasaviDataString) {
    try {
      const localCasaviData = JSON.parse(localCasaviDataString);
      const tokenIsOlderThanADay = +new Date() - localCasaviData.created > 24 * 3600 * 1000;
      if (!tokenIsOlderThanADay) {
        casaviToken = localCasaviData.token;
      }
    } catch (e) {
      // clear local casavi storage
      localStorage.removeItem('casavi');
    }
  }
  if (!casaviToken) {
    const casaviConnectionApi = new CasaviConnectionControllerApi(apiConfiguration('accounting'));
    const portalTokenWrapper = await casaviConnectionApi.authenticateUsingPOST({ connectionId: connection.id });
    localStorage.setItem('casavi', JSON.stringify({ token: portalTokenWrapper.token, created: +new Date() }));
    casaviToken = portalTokenWrapper.token;
  }
  return casaviToken;
};
