import React, { useContext } from 'react';
import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { translations } from '../../translations';

interface PostingDeletionOrReversionModalProps {
  visible: boolean,
  onClickCancel: () => void,
  onClickOk: () => void,
  loading?: boolean,
  type: string,
}


const PostingDeletionOrReversionModal = ({
  onClickOk, onClickCancel, visible, loading, type,
}: PostingDeletionOrReversionModalProps) => {
  const { tl } = useContext(LanguageContext);

  return (
    <Modal
      visible={visible}
      onCancel={onClickCancel}
      className="confirm-posting-deletion-modal"
      footer={[
        <Button key="cancel" type="text" onClick={onClickCancel}>
          {tl(translations.postingList[type].cancel)}
        </Button>,
        <Button
          key="delete"
          type="primary"
          onClick={onClickOk}
          loading={loading}
        >
          {translations.postingList[type].delete ? tl(translations.postingList[type].delete) : tl(translations.postingList[type].revert)}
        </Button>,
      ]}
    >
      <p>{tl(translations.postingList[type].content)}</p>
    </Modal>
  );
};

export default PostingDeletionOrReversionModal;

PostingDeletionOrReversionModal.defaultProps = {
  loading: false,
};
