import {
  useContext,
  useState,
} from 'react';

import {
  BankTransactionControllerApi,
  TransactionCreateDto,
} from '../../../../api/accounting';
import { AuthContext } from '../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { showNotification } from '../../../../lib/Notification';
import { translations } from './translations';

const mapRowToTransactionDto = (csvRow: any): TransactionCreateDto => ({
  amount: csvRow.amount,
  balance: csvRow.balance,
  bankBookingDate: csvRow.bankBookingDate,
  counterpartIban: csvRow.counterpartIban,
  propertyIban: csvRow.propertyIban,
  statement: csvRow.statement,
});

export const useTransactionsImportCsv = () => {
  const [transactions, setTransactions] = useState<TransactionCreateDto[]>([]);
  const [transactionsValidationIssues, setTransactionsValidationIssues] = useState<any[]>([]);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));

  const loadTransactionsFromCsvRows = (csvRows: any[]) => {
    const readTransactions: TransactionCreateDto[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach((csvRow) => {
      const transaction = mapRowToTransactionDto(csvRow);
      readTransactions.push(transaction);
      if (!transaction.amount) {
        validationIssues.push({ message: tl(translations.validations.issues.amount), row: csvRow._row });
      }
      if (!transaction.bankBookingDate) {
        validationIssues.push({ message: tl(translations.validations.issues.bankBookingDate), row: csvRow._row });
      }
      if (!transaction.propertyIban) {
        validationIssues.push({ message: tl(translations.validations.issues.propertyIban), row: csvRow._row });
      }
      if (!transaction.balance) {
        validationIssues.push({ message: tl(translations.validations.issues.balance), row: csvRow._row });
      }
    });
    setTransactionsValidationIssues(validationIssues);
    if (validationIssues.length) {
      setTransactions([]);
    } else {
      setTransactions(readTransactions);
    }
  };

  const uploadTransactions = () => {
    if (transactionsValidationIssues.length > 0 || transactions.length < 1) {
      showNotification({
        key: 'transactionImportError',
        message: tl(translations.validations.errors),
        type: 'error',
      });
      return;
    }
    bankTransactionControllerApi.createTransactionsUsingPOST({ transactionCreateDtos: transactions })
      .then(() => {
        setTransactions([]);
        setTransactionsValidationIssues([]);
        showNotification({
          key: 'transactionImportSuccess',
          message: tl(translations.success),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'transactionImportError',
          message: tl(translations.error),
          type: 'error',
        });
      });
  };
  return {
    loadTransactionsFromCsvRows,
    transactions,
    transactionsValidationIssues,
    uploadTransactions,
  };
};
