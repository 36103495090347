import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import 'isomorphic-fetch';
import { ContactLegacyControllerApi, SearchContactsUsingGETTypesEnum } from 'api/accounting';
import _, { isEqual } from 'lodash';
import DEFAULT_DATA from '../lib/data';
import { LanguageContext } from './LanguageContext';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { getContactLabelName } from '../pages/Property/PropertyEditing/util/utils';
import { showNotification } from '../lib/Notification';
import { AuthContext } from './AuthContext';

export const ContactListContext: any = React.createContext({});

/**
 * Context to handle Contact.
 * personOptions - contains only PERSON type of Contacts
 * companyOptions - contains only COMPANY type of Contacts
 */
export default function ContactListProvider({ children }: any) {
  const [contactListState, setContactListState] = useState(DEFAULT_DATA<any>([]));
  const [personListState, setPersonListState] = useState(DEFAULT_DATA<any>([]));
  const [companyListState, setCompanyListState] = useState(DEFAULT_DATA<any>([]));
  const [personListFilters, setPersonListFilters] = useState({});
  const initialPersonListFilterUpdateRef = useRef(true);
  const [companyListFilters, setCompanyListFilters] = useState({});
  const initialCompanyListFilterUpdateRef = useRef(true);
  const { apiConfiguration } = useContext(AuthContext);
  const [currentlyLoadingContactIds, setCurrentlyLoadingContactIds] = useState(new Set<number>());
  const [, setPersonListParamOfFetchInProgress] = useState<any>(undefined);
  const [, setCompanyListParamOfFetchInProgress] = useState<any>(undefined);
  const personListAbortController = useRef<AbortController | undefined>(undefined);
  const companyListAbortController = useRef<AbortController | undefined>(undefined);

  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);

  useEffect(() => {
    if (initialPersonListFilterUpdateRef.current) {
      initialPersonListFilterUpdateRef.current = false;
      return;
    }
    onLoadPersonList(true);
  }, [personListFilters]);

  useEffect(() => {
    if (initialCompanyListFilterUpdateRef.current) {
      initialCompanyListFilterUpdateRef.current = false;
      return;
    }
    onLoadCompanyList(true);
  }, [companyListFilters]);


  const updateCompanyListFilters = (data: object) => {
    setCompanyListFilters((old: any) => ({
      ...old,
      ...data,
    }));
  };

  const updatePersonListFilters = (data: object) => {
    setPersonListFilters((old: any) => ({
      ...old,
      ...data,
    }));
  };


  // this function loads both persons and companies. It is mainly used for smart searches
  const onLoadContactList = (filter: string, resetPage: boolean = true) => {
    setContactListState(state => state.startLoading());
    backend.get(`${endpointUrls.CONTACT}/search`, {
      filter,
      page: resetPage ? 0 : contactListState.page,
    })
      .then((response: any) => {
        setContactListState(state => state.loadPaged(response.content, resetPage, response.last));
      })
      .catch((error) => {
        console.error(error);
        setContactListState(state => state.failed());
        showNotification({
          key: 'loadContactsError',
          message: tl(translations.notifications.contactListContext.loadError.message),
          description: tl(translations.notifications.contactListContext.loadError.description),
          type: 'error',
        });
      });
  };

  // this function loads the list of persons. Used in the person list page
  const onLoadPersonList = async (resetPage: boolean = false) => {
    setPersonListParamOfFetchInProgress((prevFetchParams) => {
      // add filters
      // _.pickBy(obj,_.identity) removes the null values
      const currentFetchParams = _.pickBy(personListFilters, _.identity);
      if (isEqual(currentFetchParams, prevFetchParams)) {
        return prevFetchParams;
      }


      setPersonListState(state => state.startLoading());

      // Abort any companyRequest
      companyListAbortController.current?.abort();
      // if params changed since last initiated fetch then abort the in-progress fetch
      personListAbortController.current?.abort();
      // create new abort controller
      personListAbortController.current = new AbortController();
      const { signal } = personListAbortController.current;

      contactControllerApi.searchContactsUsingGET({
        types: SearchContactsUsingGETTypesEnum.PERSON,
        ...currentFetchParams,
        page: resetPage ? 0 : personListState.page,
        size: 30,
      }, { signal })
        .then((response) => {
          setPersonListState(state => state.loadPaged(response.content, resetPage, response.last!));
        })
        .catch(() => {
          if (signal.aborted) return;

          setPersonListState(state => state.failed());
          showNotification({
            key: 'loadPersonsError',
            message: tl(translations.notifications.contactListContext.loadError.message),
            description: tl(translations.notifications.contactListContext.loadError.description),
            type: 'error',
          });
        })
        .finally(() => {
          setPersonListParamOfFetchInProgress(undefined);
          setPersonListState(state => state.finishLoading());
        });

      return currentFetchParams;
    });
  };

  const onClearPersonListFilters = () => {
    setPersonListFilters({});
  };

  const onClearCompanyListFilters = () => {
    setCompanyListFilters({});
  };

  // this function loads the list of persons. Used in the person list page
  const onLoadCompanyList = async (resetPage: boolean = false) => {
    setCompanyListParamOfFetchInProgress((prevFetchParams) => {
      const currentFetchParams = _.pickBy(companyListFilters, _.identity);
      if (isEqual(currentFetchParams, prevFetchParams)) {
        return prevFetchParams;
      }

      setCompanyListState(state => state.startLoading());

      // Abort any Person request
      personListAbortController.current?.abort();
      // if params changed since last initiated fetch then abort the in-progress fetch
      companyListAbortController.current?.abort();
      // create new abort controller
      companyListAbortController.current = new AbortController();
      const { signal } = companyListAbortController.current;


      contactControllerApi
        .searchContactsUsingGET({
          // @ts-ignore
          types: [SearchContactsUsingGETTypesEnum.MANAGEMENT_COMPANY, SearchContactsUsingGETTypesEnum.COMPANY],
          ..._.pickBy(companyListFilters, _.identity),
          page: resetPage ? 0 : companyListState.page,
          size: 30,
        }, { signal })
        .then((resp) => {
          setCompanyListState(prev => prev.loadPaged(resp.content, resetPage, resp.last));
        })
        .catch((error) => {
          if (signal.aborted) return;

          console.error(error);
          setCompanyListState(state => state.failed());
          showNotification({
            key: 'loadCompaniesError',
            message: tl(translations.notifications.contactListContext.loadError.message),
            description: tl(translations.notifications.contactListContext.loadError.description),
            type: 'error',
          });
        })
        .finally(() => {
          setCompanyListParamOfFetchInProgress(undefined);
          setCompanyListState(state => state.finishLoading());
        });

      return currentFetchParams;
    });
  };

  const onLoadContact = (contactId: number): void => {
    if ((contactListState.data.filter((contact: any) => contact.id === contactId).length > 0) || currentlyLoadingContactIds.has(contactId)) return;
    setCurrentlyLoadingContactIds(state => state.add(contactId));
    setContactListState(contactListState.startLoading());
    backend.get(`${endpointUrls.CONTACT}/${contactId}`, {})
      .then((response: any) => {
        setContactListState(state => state.load(state.data.concat(response)));
      })
      .catch(() => {
        setContactListState(contactListState.failed());
        showNotification({
          key: 'loadContactError',
          message: tl(translations.notifications.contactEditingContext.loadError.message),
          description: tl(translations.notifications.contactEditingContext.loadError.description),
          type: 'error',
        });
      })
      .finally(() => setCurrentlyLoadingContactIds((state) => {
        state.delete(contactId);
        return new Set(state);
      }));
  };

  const getCompany = (id: number) => {
    const filteredList = companyListState.data.filter((object: any) => object.value === id);
    if (filteredList.length === 1) {
      return filteredList[0];
    }
    return null;
  };

  const getObject = (id: number) => contactListState.data.filter((object: any) => object.value === id)[0];

  const getName = (id: number): string => getContactLabelName(getObject(id));

  const containsContact = (contactId: string): boolean => contactListState.data.filter((c: any) => c.value === contactId).length > 0;

  return (
    <ContactListContext.Provider value={{
      contactListState,
      personListState,
      setPersonListState,
      companyListState,
      setCompanyListState,
      getCompany,
      onLoadContactList,
      onLoadPersonList,
      onLoadCompanyList,
      onLoadContact,
      getName,
      containsContact,
      personListFilters,
      setPersonListFilters,
      onClearPersonListFilters,
      updatePersonListFilters,
      companyListFilters,
      setCompanyListFilters,
      onClearCompanyListFilters,
      updateCompanyListFilters,
    }}
    >
      {children}
    </ContactListContext.Provider>
  );
}

ContactListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
