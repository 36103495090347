export const calculateVatAmount = (vatPercentage: number | undefined, amount: number, transactionSign: number) => {
  if (vatPercentage === undefined) {
    return undefined;
  }

  return amount * vatPercentage / (100 + vatPercentage) * transactionSign;
};

export const calculateVatEligibilityAmount = (vatEligibilityPercentage: number | undefined, vatAmount: number, transactionSign: number) => {
  if (vatEligibilityPercentage === undefined) {
    return undefined;
  }

  return vatAmount * vatEligibilityPercentage / (100) * transactionSign;
};
