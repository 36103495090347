import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import { useAccountBookingForm } from 'pages/Account/AccountBooking/useAccountBookingForm';
import { translations } from 'elements/Translation/translations';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Col } from 'antd';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { PercentageInput } from 'elements/Inputs/PercentageInput/PercentageInput';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { Link } from 'react-router-dom';

interface VatInputProps {
  value: any,
  bookingNr: number,
  validationState?: 'error',
  validationMessage?: string,
}


const VatInput = ({
  value, bookingNr, validationState, validationMessage,
}: VatInputProps) => {
  const {
    onChangeVatPercentage, vatOptions, onChangeVatEligibilityPercentage, vatRelevantProperty, propertyLoaded
  } = useAccountBookingForm();
  const { tl } = useContext(LanguageContext);

  if (!propertyLoaded) return <></>;
  return (
    <>
      <Col span={12}>
        <SelectInput
          size="large"
          allowClear
          showSearch
          className="input"
          label={tl(translations.pages.accountBooking.form.percentageLabel)}
          value={value?.vatPercentage || undefined}
          onChange={val => onChangeVatPercentage(val, bookingNr)}
          validationState={validationState}
          validationMessage={validationMessage}
          options={vatOptions}
          dropdownClassName="large-dropdown"
        />
      </Col>
      <Col span={12}>
        <CurrencyInput
          className="input"
          disabled
          label={tl(translations.pages.accountBooking.form.netAmountLabel)}
          value={value?.vatPercentage ? value?.amount - value?.vatAmount : value?.amount}
          onChange={() => { }}
        />
      </Col>
      {vatRelevantProperty
      && (
        <Col span={12}>
          <PercentageInput
            className="input"
            label={tl(translations.pages.accountBooking.form.vatEligibilityPercentage)}
            infoText={(
              <div>
                {tl(translations.pages.accountBooking.form.vatEligibilityTooltip)}
                <Link target="_blank" to={{ pathname: articleUrls.vatEligibility }}>
                  {tl(translations.pages.accountBooking.form.vatEligibilityTooltipLink)}
                </Link>
              </div>
            )}
            value={value?.vatEligibilityPercentage}
            onChange={val => onChangeVatEligibilityPercentage(val, bookingNr)}
          />
        </Col>
      )
      }
      {vatRelevantProperty
      && (
        <Col span={12}>
          <CurrencyInput
            className="input"
            label={tl(translations.pages.accountBooking.form.vatEligibilityAmount)}
            value={value?.vatEligibilityAmount}
            onChange={() => {}}
            disabled
          />
        </Col>
      )
      }
    </>
  );
};

export default VatInput;

VatInput.defaultProps = {
  validationState: undefined,
  validationMessage: undefined,
};
