import React from 'react';
import './DropdownOptionElement.scss';

interface DropdownOptionProps {
  label: string,
  additionalLabelInfo?: string
}

export function DropdownOptionElement({ label, additionalLabelInfo }: DropdownOptionProps): JSX.Element {
  return (
    <div className="DropdownOptionElement">
      <div className="label">
        {label}
      </div>
      {additionalLabelInfo && (
        <div className="additional-info">
          {additionalLabelInfo}
        </div>
      )}
    </div>
  );
}
