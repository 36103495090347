import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import defaultSection from './defaultSection';
import { translations } from '../../../../../elements/Translation/translations';

export const useMassagesSection = (contactId: string) => {
  const { tl } = useContext(LanguageContext);

  const onNavigateToMessageList = () => {
    window.open(`/messages?contactId=${contactId}`);
  };
  return {
    ...defaultSection(5),
    sectionIcon: ICONS.messagesSection,
    sectionTitle: tl(translations.pages.contactDetails.messagesSection.title),
    sectionId: 'MessagesSectionId',
    onChange: () => {},
    open: false,
    onHoverDisplayButton: true,
    hoverButtonFunction: onNavigateToMessageList,
    hoverButtonText: tl(translations.pages.contactDetails.messagesSection.button),
    content: [],
    onClickHeader: onNavigateToMessageList,
  };
};
