import React, { useContext, useState } from 'react';
import { Form, Input } from 'antd';
import './TextAreaInput.scss';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import InputProps from '../InputProps';


type InputPropsWithoutValue = Omit<InputProps<string>, 'value'>

interface TextAreaInputProps<T> extends InputPropsWithoutValue {
  value?: T,
}


export default function TextAreaInput(props: TextAreaInputProps<string>): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    value, className, label, required, inputClassName, disabled, validationState, validationMessage, showPlaceholderWhenDisabled, allowEdit,
    autoFocus, onFocus, placeholder, onKeyDown, readOnly, rows, resizable,
  } = props;
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(true);
  const onChange = (event: any) => {
    props.onChange(event.target.value);
  };

  const toggleEditing = () => setFieldDisabled(prevValue => !prevValue);

  let newPlaceholder = placeholder || tl(translations.elements.textAreaInput.placeholder);
  if (disabled && !showPlaceholderWhenDisabled) {
    newPlaceholder = '';
  }

  const computedClassName = `Input ${inputClassName} ${disabled ? 'read-only' : ''} ${!resizable ? 'no-resize' : ''}`;

  return (
    <div className={`TextAreaInput ${className}`}>
      <Form.Item
        label={`${label || ''}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <Input.TextArea
          className={computedClassName}
          placeholder={newPlaceholder}
          onChange={onChange}
          value={value}
          disabled={disabled || (allowEdit && fieldDisabled) || false}
          autoSize={!rows}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          rows={rows}
        />
        {/* Hide edit button once it has been enabled */}
        {(allowEdit && fieldDisabled) && (
          <button className="edit" onClick={toggleEditing}>
            {tl(translations.elements.textAreaInput.edit)}
          </button>
        )}
      </Form.Item>
    </div>
  );
}

TextAreaInput.defaultProps = {
  required: false,
  className: '',
  inputClassName: '',
  value: undefined,
  resizable: false,
};
