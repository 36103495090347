import React from 'react';
import './Page.scss';


interface PageProps {
  children: React.ReactNode,
  className?: string,
  id?: string,
}

const Page = ({ children, className, id }: PageProps) => (
  <div id={id} className={`Page ${className ?? ''}`}>
    {children}
  </div>
);


Page.defaultProps = {
  className: undefined,
  id: undefined,
};


export default Page;
