export const translations: any = {
  validations: {
    mustBeUnique: {
      en: 'Value needs to be unique',
      de: 'Wert muss einmalig sein',
    },
    defaultError: {
      en: 'Field is incorrect',
      de: 'Das Feld ist falsch',
    },
    mandatory: {
      en: 'Field is required',
      de: 'Feld ist erforderlich',
    },
    notNull: {
      en: 'Field is required',
      de: 'Feld ist erforderlich',
    },
    notEmpty: {
      en: 'Field is required',
      de: 'Feld ist erforderlich',
    },
    nonNumericValues: {
      en: 'Field must not contain non-numeric values',
      de: 'Das Feld darf keine nicht numerischen Werte enthalten',
    },
  },
};
