import React from 'react';

import { notification } from 'antd';
import {
  ArgsProps,
  IconType,
} from 'antd/lib/notification';
import { ICONS } from 'components/icons';

export interface NotificationObject extends ArgsProps {
  message: string
  description?: string | string[] | React.ReactNode,
}

const parseDescriptionList = (description: string[]) => (
  <div className="description-list">
    {
      description.map((desc: string) => (
        <div className="description-row">
          {desc}
        </div>
      ))
    }
  </div>
);

const getNotificationIcon = (notificationType?: IconType): React.ReactNode => {
  let Element: React.FC | null = null;
  switch (notificationType) {
  case 'success':
    Element = ICONS.success;
    break;
  case 'warning':
    Element = ICONS.warning;
    break;
  case 'error':
    Element = ICONS.error;
    break;
  case 'info':
    Element = ICONS.info;
    break;
  default:
    return <React.Fragment />;
  }
  return <Element />;
};

export function showNotification(notificationObject: NotificationObject) {
  const extendedNotificationObject: NotificationObject = notificationObject;
  if (notificationObject.key) {
    // if there is a key specified for the notification, close the previous notification with the same key
    notification.close(notificationObject.key);
  }
  if (!extendedNotificationObject.key) {
    // if there is no key specified, create a random one to be able to close it on click
    extendedNotificationObject.key = Math.random().toString().substr(2);
  }
  if (extendedNotificationObject.description && Array.isArray(extendedNotificationObject.description)) {
    // if description is a list, parse it into a div with multiple rows
    extendedNotificationObject.description = parseDescriptionList(extendedNotificationObject.description as string[]);
  }
  if (extendedNotificationObject.type && extendedNotificationObject.className) {
    extendedNotificationObject.className = `${extendedNotificationObject.className} ${extendedNotificationObject.type}`;
  } else if (extendedNotificationObject.type) {
    extendedNotificationObject.className = extendedNotificationObject.type;
  }
  extendedNotificationObject.onClick = () => notification.close(extendedNotificationObject.key as string);
  extendedNotificationObject.icon = getNotificationIcon(extendedNotificationObject.type);
  // timeout needed to close the previous notification
  setTimeout(() => {
    notification.open(extendedNotificationObject);
  }, 0);
}
