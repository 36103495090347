import React, { useContext, useEffect, useState } from 'react';
import '../ContactDetails.scss';
import { useParams } from 'react-router';
import FormSection from 'elements/FormElements/FormSection/FormSection';
import { ContactLegacyDto, ContactLegacyDtoTypeEnum } from 'api/accounting';
import { ContactHeaderButtons } from 'pages/Contacts/components/ContactHeaderButtons/ContactHeaderButtons';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import PersonData from './components/PersonData';
import { PersonEditingContext } from '../../../../contexts/PersonEditingContext';
import CompanyData from './components/CompanyData';
import { useInvoicesSection } from './sections/InvoicesSection';
import { usePropertiesSection } from './sections/PropertiesSection';
import { useBankInformationSection } from './sections/BankInformationSection';
import { useContactIntegrationSection } from './sections/services/useContactIntegrationSection';
import { useMassagesSection } from './sections/MessagesSection';
import { useDocumentsSection } from './sections/DocumentsSection';

export default function PersonDetails(): JSX.Element {
  const { goBack }: any = useContext(OverlayContext);

  const { personId } = useParams<{ personId: string }>();
  const contactEditingContext: any = useContext(PersonEditingContext);
  const contact = contactEditingContext.savedContactState.data;
  const [openSectionIndex, setOpenSectionIndex] = useState(-1);
  const personName = `${contact.firstName} ${contact.lastName}`;

  useEffect(() => {
    contactEditingContext.onLoadContact(personId);
    contactEditingContext.setDirty(false);
  }, []);


  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (openSectionIndex === nextSectionIndex) {
      // if the clicked section is the current one, close the sections
      nextSectionIndex = -1;
    }
    setOpenSectionIndex(nextSectionIndex);
  };


  const company: any = contact && contact.linkedToContacts && contact.linkedToContacts.length > 0 && contact.linkedToContacts[0];
  const bankAccounts: any = contact && contact.bankAccounts && contact.bankAccounts.length > 0 ? contact.bankAccounts : [];

  useEffect(() => {
    if (openSectionIndex >= 0) {
      const sectionId = sections[openSectionIndex] ? sections[openSectionIndex].sectionId : null;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openSectionIndex]);


  let sections: any = [
    usePropertiesSection(parseInt(personId, 10)),
    useBankInformationSection(bankAccounts),
    useContactIntegrationSection({ contact: contact as ContactLegacyDto }),
    useInvoicesSection(personId),
    useMassagesSection(personId),
    useDocumentsSection(personId),
  ].filter(s => s);

  return (
    <Page id="scrollElement" className="PersonDetails contact-details">
      <PageHeader
        title={personName}
        rightSideComponent={(
          <ContactHeaderButtons
            contactType={ContactLegacyDtoTypeEnum.PERSON}
            contactId={parseInt(personId, 10)}
            contactName={personName}
            editEnabled={false}
            onGoBack={goBack}
          />
        )}
      />
      <PageContent>
        <PersonData />
        {company && <CompanyData />}
        <div className="page-divider" />
        <div className="form-content">
          <div className="form-section">
            {sections.map((section: any) => (
              <FormSection
                key={section.sectionId}
                open={(openSectionIndex === section.sectionNumber - 1)}
                onClickHeader={() => handleOpenSectionChange(section.sectionNumber - 1)}
                hideSaveButton
                {...section}
              />
            ))}
            {/* <PortalSection contact={contact} /> */}
          </div>
        </div>
      </PageContent>
    </Page>
  );
}
