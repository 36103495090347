import { ContractProjectionDtoTypeEnum, PropertyDisplayDtoAdministrationTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import _ from 'lodash';
import { AccountSelectionContext } from 'pages/ManualExchange/services/ManualExchangeContext';
import { useContext } from 'react';
import { translations } from '../../translations';

export const useManualExchangeSection1 = () => {
  const { tl } = useContext(LanguageContext);

  const accountSelectionContext = useContext(AccountSelectionContext);

  if (accountSelectionContext === undefined) {
    throw new Error('useManualExchangeSection1 must be used within a ManualExchangeContextProvider');
  }

  const { formValues } = accountSelectionContext;

  const getContractTypes = () => {
    const administrationType = formValues.property?.administrationType;
    if (administrationType === PropertyDisplayDtoAdministrationTypeEnum.MV || administrationType === PropertyDisplayDtoAdministrationTypeEnum.SEV || administrationType === PropertyDisplayDtoAdministrationTypeEnum.SEV_MV) {
      return [
        { label: tl(translations.creationPage.sections.section1.propertyOwner), value: ContractProjectionDtoTypeEnum.PROPERTY_OWNER },
        { label: tl(translations.creationPage.sections.section1.tenant), value: ContractProjectionDtoTypeEnum.TENANT },
      ];
    }
    if (administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG) {
      return [{ label: tl(translations.creationPage.sections.section1.owner), value: ContractProjectionDtoTypeEnum.OWNER }];
    }
    return [];
  };

  const contractTypeSelectorDisabled = _.isEmpty(getContractTypes()) || formValues.property?.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG;

  return {
    getContractTypes,
    contractTypeSelectorDisabled,
  };
};
