import { ContractContactProjectionDtoRoleEnum } from 'api/accounting';

export const recipientSelectorModalTranslations = {
  title: {
    en: 'Select recipients',
    de: 'Empfänger auswählen',
  },
  apply: {
    en: 'Apply',
    de: 'Speichern',
  },
  cancel: {
    en: 'Cancel',
    de: 'Abbrechen',
  },
  contactSelection: {
    en: 'Contact selection',
    de: 'Kontaktauswahl',
  },
  contractContacts: {
    en: 'Contracts/contacts',
    de: 'Verträge/Kontakte',
  },
  propertyOwner: {
    en: 'Property Owner',
    de: 'Objekteigentümer',
  },
  filterOptions: {
    ALL: {
      en: 'All contracts',
      de: 'Alle Verträge',
    },
    ACTIVE: {
      en: 'All active contract',
      de: 'Alle aktiven Verträge',
    },
    ACTIVE_MAIN: {
      en: 'Main of active contracts',
      de: 'Versandkontakt aktiver Verträge',
    },
    ALL_SELECTED: {
      en: 'Message recipients',
      de: 'Nachrichtenempfänger',
    },
    SELECTED: {
      en: 'Selected contracts',
      de: 'Ausgewählte Verträge',
    },
  },
  summaryLabel: {
    en: 'Selected recipients',
    de: 'Ausgewählte Empfänger',
  },
  role: {
    [ContractContactProjectionDtoRoleEnum.OWNER]: {
      en: 'Owner',
      de: 'Eigentümer',
    },
    [ContractContactProjectionDtoRoleEnum.TENANT]: {
      en: 'Tenant',
      de: 'Mieter',
    },
    [ContractContactProjectionDtoRoleEnum.COUNCIL]: {
      en: 'Council',
      de: 'Beirat',
    },
    [ContractContactProjectionDtoRoleEnum.HEAD_OF_COUNCIL]: {
      en: 'Head of Council',
      de: 'Beiratsvorsitz',
    },
    [ContractContactProjectionDtoRoleEnum.PROPERTY_OWNER]: {
      en: 'Property Owner',
      de: 'Objekteigentümer',
    },
    [ContractContactProjectionDtoRoleEnum.LEGAL_REPRESENTATIVE]: {
      en: 'Legal Representative',
      de: 'Bevollmächtigte',
    },
    [ContractContactProjectionDtoRoleEnum.SUBTENANT]: {
      en: 'Subtenant',
      de: 'Untermieter',
    },
    [ContractContactProjectionDtoRoleEnum.RESIDENT]: {
      en: 'Resident',
      de: 'Bewohner',
    },
    [ContractContactProjectionDtoRoleEnum.LEGAL_GUARDIAN]: {
      en: 'Legal Guardian',
      de: 'Vormund',
    },
  },
};
