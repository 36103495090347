import { EconomicPlanDtoStatusEnum, ErrorCodeUsingGETValueEnum } from 'api/accounting';
import Button from 'elements/Buttons/Button/Button';
import _ from 'lodash';
import { navigateConcat } from 'navigation/History';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { showNotification } from 'lib/Notification';
import { DocumentDownloadModal } from 'storybook-components/modals/DocumentDownloadModal/DocumentDownloadModal';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import '../EconomicPlanOverview.scss';
import StateChangeButtons from '../../components/StateChangeButtons/StateChangeButtons';
import { useRightSideButtons } from './useRightSideHeaderButtons';
import { DropMenu } from '../../../../components/DropMenu/DropMenu';

export default function RightSideButtons() {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  const {
    recalculate,
    loading,
    invalidAmounts,
    economicPlanStatus,
    propertyHrId,
    dropMenuOptionList,
    mergedDocumentModalVisible,
    setMergedDocumentModalVisible,
    messagesExist,
    messagesGenerating,
    documentCreationFailed,
    selectedDocumentId,
    documents,
    startLoading,
    stopLoading,
  } = useRightSideButtons();

  const draftButtons = () => {
    if (economicPlanStatus === EconomicPlanDtoStatusEnum.DRAFT) {
      return (
        <>
          <Button
            type="ghost"
            loading={loading}
            onClick={() => navigateConcat('/edit?type=ACCOUNT_DISTRIBUTION')}
          >
            {tl(economicPlanTranslations.economicPlan.header.buttons.edit)}
          </Button>
          <Button
            type="ghost"
            loading={loading}
            disabled={messagesGenerating}
            tooltipText={messagesGenerating ? tl(economicPlanTranslations.economicPlan.header.messagesGeneratingTooltip) : undefined}
            onClick={() => {
              if (_.isEmpty(invalidAmounts.data)) {
                recalculate();
              } else {
                // This can be thrown by recalculate itself, but we will make one more useless request
                showNotification({
                  key: 'invalidAccountAmountsError',
                  message: tl(economicPlanTranslations.economicPlan.notification.errorCodes[ErrorCodeUsingGETValueEnum.INVALID_ACCOUNT_AMOUNTS]),
                  type: 'error',
                });
              }
            }}
          >
            {tl(economicPlanTranslations.economicPlan.header.buttons.recreate)}
          </Button>
        </>
      );
    }
    return <></>;
  };


  return (
    <>
      <div className="header-buttons">
        <Button
          type="text"
          loading={loading}
          onClick={() => history.push(`/economic-plan/property/${propertyHrId}`)}
        >
          {tl(economicPlanTranslations.economicPlan.header.buttons.close)}
        </Button>
        <DropMenu
          options={dropMenuOptionList}
        />
        {draftButtons()}
        <StateChangeButtons
          showSend
          messagesExist={messagesExist}
          messagesGenerating={messagesGenerating}
          documentCreationFailed={documentCreationFailed}
        />
      </div>
      <DocumentDownloadModal
        visible={mergedDocumentModalVisible}
        onChangeVisible={setMergedDocumentModalVisible}
        loading={loading}
        selectedDocumentId={selectedDocumentId}
        documents={documents.data}
        startLoading={startLoading}
        stopLoading={stopLoading}
      />
    </>
  );
}
