import { useEffect, useRef } from 'react';

export default function usePoll({ shouldPoll, pollFunction, timeoutSeconds }: { shouldPoll: boolean, pollFunction: Function, timeoutSeconds: number }) {
  const shouldPollRef = useRef<boolean>(shouldPoll);
  const timer = useRef<any>();

  useEffect(() => () => {
    clearTimeout(timer.current);
  }, []);

  useEffect(() => {
    shouldPollRef.current = shouldPoll;
    if (shouldPoll) {
      callPollFunction();
    }
  }, [shouldPoll]);

  const callPollFunction = () => {
    pollFunction();
    timer.current = setTimeout(() => {
      if (shouldPollRef.current) {
        callPollFunction();
      }
    }, timeoutSeconds);
  };
}
