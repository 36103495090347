import { UnitContractProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useContext, useState } from 'react';


interface Props {
  children: React.ReactNode;
}


export const AllContractsForMultiplePropertiesContext = createContext<{
  allContractsForMultipleProperties: DefaultDataInterface<UnitContractProjectionDto[]>,
  setAllContractsForMultipleProperties: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  paramOfCachedValue: number[] | undefined,
  setParamOfCachedValue: React.Dispatch<React.SetStateAction<number[] | undefined>>,
  setParamOfFetchInProgress: React.Dispatch<React.SetStateAction<number[] | undefined>>,
} | undefined>(undefined);

export const useAllContractsForMultiplePropertiesContext = (usageName: string) => {
  const allContractsForMultiplePropertiesContext = useContext(AllContractsForMultiplePropertiesContext);

  if (allContractsForMultiplePropertiesContext === undefined) {
    throw new Error(`${usageName} must be used within a AllContractsForMultiplePropertiesContextProvider`);
  }

  return allContractsForMultiplePropertiesContext;
};

const AllContractsForMultiplePropertiesContextProvider = ({ children }: Props) => {
  const [allContractsForMultipleProperties, setAllContractsForMultipleProperties] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([]));
  const [paramOfCachedValue, setParamOfCachedValue] = useState<number[] | undefined>(undefined);
  const [paramOfFetchInProgress, setParamOfFetchInProgress] = useState<number[] | undefined>(undefined);

  // don't need to export paramOfFetchInProgress because it's value is stale,
  // we're using the setState(previuesValue => {...}) version of setState to get the up-to-date value
  const providerValue = {
    allContractsForMultipleProperties,
    setAllContractsForMultipleProperties,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  };

  return (
    <AllContractsForMultiplePropertiesContext.Provider value={providerValue}>
      {children}
    </AllContractsForMultiplePropertiesContext.Provider>
  );
};

export default AllContractsForMultiplePropertiesContextProvider;
