import { Table } from 'antd';
import './DunningAnnexAccountBalanceTableSummary.scss';
import { formatCurrency } from 'lib/Utils';
import Amount from 'storybook-components/typography/Amount/Amount';

 interface TotalSummaryData{
  month: string
  title: string
  totalCreditDebitNote: number,
  totalYourPayments: number,
  total: number
 }
export const DunningAnnexAccountBalanceTableSummary = ({
  month,
  title,
  totalCreditDebitNote,
  totalYourPayments,
  total,
} : TotalSummaryData) => (
  <Table.Summary>
    <Table.Summary.Row className="DunningAnnexAccountBalanceTableSummary">
      <Table.Summary.Cell index={0} colSpan={1} className="month-cell">
        {month}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} colSpan={1}>
        {title}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2} className="column-align-right" colSpan={1}>
        <Amount>{formatCurrency(totalCreditDebitNote, '0,00', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} className="column-align-right" colSpan={1}>
        <Amount>{formatCurrency(totalYourPayments, '0,00', false)}</Amount>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4} className="column-align-right" colSpan={1}>
        <Amount>{formatCurrency(total, '0,00', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);
