import React from 'react';
import { Link } from 'react-router-dom';
import { useAccountCodeCell } from './useAccountCodeCell';

interface AccountCodeCellProps {
  propertyHrId: string;
  accountCode: string;
}

const AccountCodeCell = ({ propertyHrId, accountCode }: AccountCodeCellProps) => {
  const linkToAccountSheet = useAccountCodeCell(propertyHrId, accountCode);

  return (
    <Link to={linkToAccountSheet} target="_blank">
      {accountCode}
    </Link>
  );
};

export default AccountCodeCell;
