import { RouteDefinition } from 'components/RenderRoutes';
import MessagesListWithProvider from './MessagesList/MessagesList';

const MESSAGE_BASE_PATH = '/messages';

export const getMessagePath = (sourceId: number, sourceType: string) => `${MESSAGE_BASE_PATH}?sourceId=${sourceId}&sourceType=${sourceType}`;

export const MessagesRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: MESSAGE_BASE_PATH,
      component: MessagesListWithProvider,
      strict: true,
    },
  ],
};
