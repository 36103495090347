export const economicPlanHouseMoneyTranslations = {
  general: {
    en: 'General',
    de: 'Allgemein',
  },
  unitAmounts: {
    en: 'Unit Amounts',
    de: 'Beträge',
  },
  pastBookings: {
    en: 'Past corrections',
    de: 'Buchungen für die Vergangenheit',
  },
  corrections: {
    en: 'Correctional Bookings',
    de: 'Korrekturbuchungen',
  },
};
