import React, {
  useContext,
  useMemo,
} from 'react';

import { ColumnType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  groupBy,
  isEmpty,
} from 'lodash';
import { nanoid } from 'nanoid';
import {
  RecipientDatasource,
  RecipientDistribution,
} from 'pages/MessageSendingPage/interfaces';
import { useScoreStringSearch } from 'services/search/useScoreStringSearch';

import {
  useMessageSendingPropertyContext,
  useRecipientContext,
  useSearchContext,
} from '../../services/MessageSendingContext';
import { messageSendingTranslations } from '../../translations';
import ContractContactCell
  from './components/ContractContactCell/ContractContactCell';
import DistributionModeCell
  from './components/DistributionModeCell/DistributionModeCell';
import RecipientFilesCell
  from './components/RecipientFilesCell/RecipientFilesCell';
import RecipientTableOptions
  from './components/RecipientTableOptions/RecipientTableOptions';

export const useRecipientOverview = () => {
  const { tl } = useContext(LanguageContext);
  const recipientContext = useRecipientContext('useRecipientOverview');
  const propertyContext = useMessageSendingPropertyContext('useRecipientOverview');
  const searchContext = useSearchContext('useRecipientOverview');

  const {
    recipientDistributions, documentRecipients, contracts, contactsLoading,
  } = recipientContext;
  const { properties } = propertyContext;
  const { recipientSearchString } = searchContext;


  const columns: ColumnType<RecipientDatasource>[] = [
    {
      dataIndex: 'name',
      title: tl(messageSendingTranslations.columns.recipient),
      width: '60%',
      render: (_, record) => <ContractContactCell record={record} />,
    },
    {
      dataIndex: 'distributionType',
      title: tl(messageSendingTranslations.columns.distribution),
      width: '30%',
      render: (_: string, record: RecipientDatasource) => (!record.children ? <DistributionModeCell recordKey={record.key} /> : <span />),
    },
    {
      dataIndex: 'nrOfDocuments',
      title: tl(messageSendingTranslations.columns.files),
      render: (_, record) => <RecipientFilesCell record={record} />,
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'delete',
      width: 50,
      render: (_, record) => <RecipientTableOptions record={record} />,
    },
  ];


  const scoreStringSearch = useScoreStringSearch();

  const dataSource = useMemo(() => {
    const groupedByContract = groupBy(recipientDistributions, 'contractId');
    const tempDatasource = Object.values(groupedByContract).map((rs: RecipientDistribution[]) => {
      const contract = contracts.data?.find(c => c.unitContractId === rs[0]?.contractId);
      const property = properties.data?.find(p => p.id === contract?.propertyId);
      return {
        ...contract,
        key: nanoid(),
        propertyHrId: property?.propertyHrId,
        name: `${property?.propertyIdInternal} · ${property?.name} · ${contract?.unitNrSharingDeclaration ? `${contract?.unitNrSharingDeclaration} · ` : ''}${contract?.mailingContact.name}`,
        searchValue: `${property?.propertyIdInternal} ${property?.name} ${contract?.unitNrSharingDeclaration ?? ''} ${contract?.contacts.map(cc => cc.name).join(' ')}`.toLowerCase(),
        children: rs.map((r) => {
          const nrOfDocuments = documentRecipients.filter(dr => dr.contractId === r.contractId && dr.contactId === r.contactId).length;
          const cc = contract?.contacts.find(c => c.contactId === r.contactId);
          const name = cc?.name;
          const roles = cc?.role?.map(rl => tl(messageSendingTranslations.role[rl])).join(', ');
          return ({
            ...r,
            ...contract,
            propertyHrId: property?.propertyHrId,
            name,
            roles,
            nrOfDocuments,
            searchValue: `${property?.propertyIdInternal} ${property?.name} ${contract?.unitNrSharingDeclaration ?? ''} ${name}`.toLowerCase(),
          });
        }),
      };
    });

    return scoreStringSearch(tempDatasource, recipientSearchString);
  }, [recipientDistributions, documentRecipients, properties, contracts, recipientSearchString]);


  const expandedRowKeys = dataSource.map(d => d.key);

  const emptyTableText = !isEmpty(recipientSearchString) ? tl(messageSendingTranslations.emptyTableText) : '';

  return {
    dataSource,
    columns,
    loading: contactsLoading,
    expandedRowKeys,
    emptyTableText,
  };
};
