import { useContext } from 'react';
import { usePropertyAdministrationTypeFilterOption } from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';
import { Filter } from '../../../components/Header/components/SearchBar/services/GlobalSearchContext';
import useDefaultPropertyFilters from '../../../components/Header/components/data/useDefaultPropertyFilters';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../translations';

export default function useStandingOrderFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);
  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  return useDefaultPropertyFilters(false)
    .concat([
      administrationTypeFilterOption,
      {
        type: 'boolean',
        name: tl(translations.standingOrderOverview.filters.showStopped.label),
        key: 'showStopped',
        trueLabel: tl(translations.standingOrderOverview.filters.showStopped.show),
        falseLabel: tl(translations.standingOrderOverview.filters.showStopped.hide),
      },
      {
        type: 'text',
        name: tl(translations.standingOrderOverview.filters.businessPartnerName),
        key: 'counterpartName',
      },
      {
        type: 'text',
        name: tl(translations.standingOrderOverview.filters.businessPartnerIban),
        key: 'counterpartIban',
      },
      {
        type: 'text',
        name: tl(translations.standingOrderOverview.filters.administrator),
        key: 'administrator',
      },
      {
        type: 'text',
        name: tl(translations.standingOrderOverview.filters.createdBy),
        key: 'createdBy',
      },
    ]);
}
