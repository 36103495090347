import {
  PortalLink,
} from 'storybook-components/TicketIntegrationSection/components/PotalLink/PortalLink';
import {
  INTEGRATION_APPS,
  TICKET_COLUMN_TYPE,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  TicketIntegrationSection,
} from 'storybook-components/TicketIntegrationSection/TicketIntegrationSection';

import { useCasaviTicketColumns } from './useCasaviTicketColumns';
import {
  useCasaviTicketIntegrationSection,
} from './useCasaviTicketIntegrationSection';

export const CasaviContactTicketSection = ({
  connectionId,
  contactId,
} : {connectionId: number,
      contactId: number}) => {
  const {
    errorMessage, loading, onChangeSearchTerm, issues, casaviLink, timestamp,
  } = useCasaviTicketIntegrationSection(connectionId,
    TICKET_COLUMN_TYPE.CONTACT, { contactId });
  const { columns } = useCasaviTicketColumns({
    type: TICKET_COLUMN_TYPE.CONTACT,
  });

  const suffix = (
    <PortalLink
      type={TICKET_COLUMN_TYPE.CONTACT}
      timestamp={timestamp}
      portal={INTEGRATION_APPS.CASAVI}
      link={casaviLink}
    />
  );

  return (
    <TicketIntegrationSection
      loading={loading}
      columns={columns}
      errorMessage={errorMessage}
      onChangeSearchTerm={onChangeSearchTerm}
      datasource={issues}
      portal={INTEGRATION_APPS.CASAVI}
      suffix={suffix}
    />
  );
};
