import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import backend, { endpointUrls } from 'backend_api';
import DEFAULT_DATA from 'lib/data';
import { translations as ownersMeetingProtocolTranslations } from 'pages/OwnersMeetingProtocol/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import moment from 'moment';
import FileSaver from 'file-saver';
import {
  AgendaItemVoteDto,
  OwnersMeetingProtocolRecipientExtendedDisplayDto,
  OwnersMeetingProtocolRecipientExtendedDisplayDtoDispatchTypesEnum,
  OwnersMeetingProtocolControllerApi,
  OwnersMeetingProtocolDto,
  OwnersMeetingProtocolDtoVotingRightsEnum,
  OwnersMeetingProtocolDtoOwnersMeetingInvitationTypeEnum,
} from 'api/accounting';
import { DATE_FORMAT, formatDate, ISO_DATE_FORMAT } from 'lib/Utils';
import { getSectionErrors, getVotingErrors, TopicIndex } from 'contexts/util/OwnersMeetingProtocolSectionFieldsConfiguration';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import _ from 'lodash';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { useDefaultProtocolTexts } from '../pages/OwnersMeetingProtocolEditing/services/useDefaultProtocolTexts';
import { convertToBEModel, convertToFeModel } from './OwnersMeetingProtocolEditingFunctions';
import { OwnersMeetingProtocol, OwnersMeetingProtocolRecipient } from '../interfaces';
import { defaultProtocolTexts } from '../utils';
import { AuthContext } from '../../../contexts/AuthContext';

export const OwnersMeetingProtocolContext: any = React.createContext({});

const PAGE_SIZE = 30;

export function OwnersMeetingProtocolProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);

  const defaultFilterState = {};

  const defaultOwnersMeeting = {
    ownersMeetingInvitationType: OwnersMeetingProtocolDtoOwnersMeetingInvitationTypeEnum.REGULAR,
  };

  const { setSelectedDisplayPropertyId } = useContext(PropertyListContext);
  const [ownersMeetingProtocolListState, setOwnersMeetingProtocolListState] = useState(DEFAULT_DATA<OwnersMeetingProtocol[]>([]));
  const [ownersMeeting, setOwnersMeeting] = useState(DEFAULT_DATA<OwnersMeetingProtocol>(defaultOwnersMeeting));
  const [selectedOwnersMeetingId, setSelectedOwnersMeetingId] = useState(null);

  const [filterState, setFilterState] = useState<any>(defaultFilterState);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const [sort, setSort] = useState({
    field: 'date',
    order: -1,
  });
  const sortRef: any = useRef();
  sortRef.current = sort;
  const [isDirty, setDirty] = useState(false);
  const [openSectionIndex, setOpenSectionIndex] = useState(0);
  const [loadingRecipients, setLoadingRecipients] = useState(false);


  const { goBack } = useContext(OverlayContext);

  const { apiConfiguration } = useContext(AuthContext);
  const ownersMeetingProtocolControllerApi = new OwnersMeetingProtocolControllerApi(apiConfiguration('accounting'));

  const {
    roleOptions, votingSchemeOptions, votingRightsOptions,
  } = useDefaultProtocolTexts();

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadOwnersMeetingProtocolList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState, sort]);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (selectedOwnersMeetingId) {
      onLoadOwnersMeetingProtocol();
      setOpenSectionIndex(0);
    }
  }, [selectedOwnersMeetingId]);

  useEffect(() => {
    if (ownersMeeting!.data!.propertyId) {
      setSelectedDisplayPropertyId(ownersMeeting.data.propertyId);
    }
  }, [ownersMeeting!.data!.propertyId]);

  const setSortField = (field: string) => {
    const order = sortRef.current.field === field ? sortRef.current.order * (-1) : 1;
    setSort({
      field,
      order,
    });
  };

  const { getDefaultProtocolText } = useDefaultProtocolTexts();

  const onLoadOwnersMeetingProtocolList = (resetPage: boolean = false, sorting: any = sortRef.current) => {
    setOwnersMeetingProtocolListState(ownersMeetingProtocolListState.startLoading());
    backend.get(`${endpointUrls.OWNERS_MEETING_PROTOCOL}`, {
      ...filterState,
      page: resetPage ? 0 : ownersMeetingProtocolListState.page,
      size: PAGE_SIZE,
      order: sorting.order > 0 ? 'ASC' : 'DESC',
      sort: sorting.field,
    })
      .then((response: any) => {
        const { content, last } = response;
        setOwnersMeetingProtocolListState(ownersMeetingProtocolListState.loadPaged(content, resetPage, last));
      })
      .catch(() => {
        setOwnersMeetingProtocolListState(ownersMeetingProtocolListState.failed());
        showNotification({
          key: 'loadOwnersMeetingListError',
          message: tl(ownersMeetingProtocolTranslations.notifications.loadOwnersMeetingListError.message),
          type: 'error',
        });
      });
  };

  const onLoadOwnersMeetingProtocol = () => {
    if (!selectedOwnersMeetingId) return;
    setOwnersMeeting(ownersMeeting.startLoading());

    onLoadProtocolFields();
    onLoadProtocolRecipientsFields();
  };

  const onLoadProtocolFields = () => {
    ownersMeetingProtocolControllerApi
      .getOwnersMeetingProtocolByIdUsingGET({ ownersMeetingProtocolId: selectedOwnersMeetingId })
      .then((response: any) => {
        setOwnersMeeting(ownersM => ownersM.load(convertToFeModel(response, defaultProtocolTexts, getDefaultProtocolText)));
      })
      .catch(() => {
        setOwnersMeeting(prev => prev.failed());
        showNotification({
          key: 'loadOwnersMeetingError',
          message: tl(ownersMeetingProtocolTranslations.notifications.loadOwnersMeetingError),
          type: 'error',
        });
      });
  };

  const onLoadProtocolRecipientsFields = () => {
    setLoadingRecipients(true);
    ownersMeetingProtocolControllerApi
      .populateOwnersMeetingProtocolRecipientsUsingPUT({ ownersMeetingProtocolId: selectedOwnersMeetingId })
      .then((response: OwnersMeetingProtocolDto) => {
        setOwnersMeeting(prev => prev.load({
          ...prev.data,
          protocolRecipients: response.protocolRecipients,
          lastSigningIndex: -1,
        }));
      })
      .catch(() => {
        setOwnersMeeting(prev => prev.failed());
        showNotification({
          key: 'loadOwnersMeetingProtocolRecipientError',
          message: tl(ownersMeetingProtocolTranslations.notifications.loadOwnersMeetingProtocolRecipientError),
          type: 'error',
        });
      }).finally(() => setLoadingRecipients(false));
  };

  const onSaveVote = (topicVote: AgendaItemVoteDto, topicIdx: number) => {
    const omProtocol = _.cloneDeep(ownersMeeting.data);
    onSaveOwnersMeetingProtocol({ index: topicIdx }, goBack, false, omProtocol!);
  };

  const successNotification = () => {
    showNotification({
      key: 'saveOwnersMeetingSuccess',
      message: tl(ownersMeetingProtocolTranslations.notifications.saveSuccess),
      type: 'success',
    });
  };

  const validationErrorNotification = () => {
    showNotification({
      key: 'saveOwnersMeetingWithValidationErrors',
      message: tl(ownersMeetingProtocolTranslations.notifications.validationError),
      type: 'warning',
    });
  };

  const errorNotification = () => {
    showNotification({
      key: 'saveError',
      message: tl(ownersMeetingProtocolTranslations.notifications.saveError),
      type: 'error',
    });
  };

  const createOwnerMeetingProtocol = (data: OwnersMeetingProtocol, savingSectionIdentifier: number, onSuccess?: Function) => {
    backend.post(`${endpointUrls.OWNERS_MEETING_PROTOCOL}`, data)
      .then((response: any) => {
        setDirty(false);
        setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(response, defaultProtocolTexts, getDefaultProtocolText), {}, true));
        setIsValid(true);
        if (onSuccess) {
          onSuccess();
        }
        successNotification();
      })
      .catch((err) => {
        if (err.title === 'Validation error') {
          setDirty(false);
          setIsValid(false);
          const currentSectionErrors = getSectionErrors(savingSectionIdentifier, err);
          setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(err.savedEntity, defaultProtocolTexts, getDefaultProtocolText), currentSectionErrors, true));
          validationErrorNotification();
        } else {
          console.error(err);
          setOwnersMeeting(ownersMeeting.failed());
          errorNotification();
        }
      });
  };


  const updateOwnerMeetingProtocol = (data: OwnersMeetingProtocol, savingSectionIdentifier: any, onSuccess?: Function, executeOnSuccessOnValidationErrors?: boolean) => {
    backend.put(`${endpointUrls.OWNERS_MEETING_PROTOCOL}`, data).then((response: any) => {
      setDirty(false);
      setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(response, defaultProtocolTexts, getDefaultProtocolText), {}, true));
      setIsValid(true);
      successNotification();
      if (onSuccess) {
        onSuccess();
      }
    })
      .catch((err) => {
        if (err.title === 'Validation error') {
          setDirty(false);
          setIsValid(false);

          let currentSectionErrors: any;

          // if number            -> section saved
          // if TopicIndex        -> voting saved
          if (typeof savingSectionIdentifier === 'number') {
            currentSectionErrors = getSectionErrors(savingSectionIdentifier, err);
          } else {
            currentSectionErrors = getVotingErrors(savingSectionIdentifier, err);
          }
          if (!_.isEmpty(currentSectionErrors)) {
            setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(err.savedEntity, defaultProtocolTexts, getDefaultProtocolText), currentSectionErrors, true));
            validationErrorNotification();
            if (executeOnSuccessOnValidationErrors && onSuccess) {
              onSuccess();
            }
          } else {
            setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(err.savedEntity, defaultProtocolTexts, getDefaultProtocolText), {}, true));
            successNotification();
            if (onSuccess) {
              onSuccess();
            }
          }
        } else {
          console.error(err);
          setOwnersMeeting(ownersMeeting.failed());
          errorNotification();
        }
      });
  };
  const onSaveOwnersMeetingProtocol = (savingSectionIdentifier: number | TopicIndex, onSuccess?: Function, executeOnSuccessOnValidationErrors?: boolean, ownersMeetingProtocol?: OwnersMeetingProtocol) => {
    setOwnersMeeting(ownersMeeting.startLoading());
    let data;
    if (ownersMeetingProtocol) {
      // if given by parameter use that to save, else use the object from the context
      data = ownersMeetingProtocol;
    } else {
      data = _.cloneDeep(ownersMeeting.data) || {};
    }


    // @ts-ignore
    const beModel = convertToBEModel(data);
    let p;
    if (!data?.id) {
      p = createOwnerMeetingProtocol(beModel, 1, onSuccess);
    } else {
      p = updateOwnerMeetingProtocol(beModel, savingSectionIdentifier, onSuccess, executeOnSuccessOnValidationErrors);
    }
    return p;
  };

  const updateOwnersMeetingProtocol = (callback: (currentValue: any) => any) => {
    setOwnersMeeting((currentValue: any) => {
      const newValue = callback(currentValue.data);
      return currentValue.load(newValue);
    });
  };

  const onClearOwnersMeeting = () => {
    setOwnersMeeting(DEFAULT_DATA<any>(defaultOwnersMeeting));
    setSelectedOwnersMeetingId(null);
  };

  const clearFilter = () => {
    setFilterState(defaultFilterState);
  };

  const clearData = () => {
    setOwnersMeetingProtocolListState(DEFAULT_DATA<any>([]));
  };

  const onDeleteOwnersMeetingProtocol = (id: number, successCallback?: Function) => {
    setOwnersMeetingProtocolListState(state => state.startLoading());
    backend.delete(`${endpointUrls.OWNERS_MEETING_PROTOCOL}/${id}`, {})
      .then(() => {
        setOwnersMeetingProtocolListState((state) => {
          if (!state.data) return state;
          const tempOwnersMeetingProtocolList = state.data.filter((omi: OwnersMeetingProtocol) => omi.id !== id);
          return state.load(tempOwnersMeetingProtocolList);
        });
        showNotification({
          key: 'deleteOwnersMeetingProtocolSuccess',
          message: tl(ownersMeetingProtocolTranslations.notifications.deleteSuccess),
          type: 'success',
        });
        if (successCallback) {
          successCallback();
        }
      })
      .catch(() => {
        setOwnersMeetingProtocolListState(state => state.failed());
        showNotification({
          key: 'deleteOwnersMeetingProtocolError',
          message: tl(ownersMeetingProtocolTranslations.notifications.deleteError),
          type: 'error',
        });
      });
  };

  const onDownloadAttendeeListDocument = (etvId: string) => {
    backend.getFileByPath(`${endpointUrls.OWNERS_MEETING_PROTOCOL}/${etvId}/attendee-list/document`)
      .then((resp: any) => {
        const fileName = `${formatDate(moment(), DATE_FORMAT)}_Anwesenheit.pdf`;
        const blob = new Blob([resp], { type: 'application/pdf' });
        FileSaver.saveAs(blob, fileName);
      })
      .catch((error: any) => {
        console.error(error);
        showNotification({
          type: 'error',
          message: tl(ownersMeetingProtocolTranslations.notifications.failedToDownloadPdf.message),
        });
      });
  };

  const onSaveAndDownloadAttendeeListDocument = () => {
    let id: string = '';
    if (ownersMeeting.data && ownersMeeting.data.id) {
      id = ownersMeeting.data.id.toString();
    }
    if (isDirty) {
      onSaveOwnersMeetingProtocol(4, () => onDownloadAttendeeListDocument(id), true);
    } else {
      onDownloadAttendeeListDocument(id);
    }
  };

  const onDownloadDocument = (etvId: string, forConfirmation: boolean) => {
    setOwnersMeeting(prev => prev.startLoading());
    backend.getFileByPath(`${endpointUrls.OWNERS_MEETING_PROTOCOL}/${etvId}/document${forConfirmation ? '/confirmation' : ''}`)
      .then((resp: any) => {
        const fileName = `${formatDate(moment(), ISO_DATE_FORMAT)}_${ownersMeeting.data!.propertyIdInternal}_ETVProtokoll.pdf`;
        const blob = new Blob([resp], { type: 'application/pdf' });
        FileSaver.saveAs(blob, fileName);
      })
      .catch((error: any) => {
        if (error.statusCode === 400) {
          showNotification({
            type: 'error',
            message: tl(ownersMeetingProtocolTranslations.notifications.failedToDownloadPdf.invalidDataMessage),
          });
        } else {
          console.error(error);
          showNotification({
            type: 'error',
            message: tl(ownersMeetingProtocolTranslations.notifications.failedToDownloadPdf.message),
          });
        }
      }).finally(() => {
        setOwnersMeeting(prev => prev.finishLoading());
      });
  };

  const onSaveAndDownloadDocument = (etvId: string, forConfirmation: boolean) => {
    let id: string = '';
    if (ownersMeeting.data && ownersMeeting.data.id) {
      id = ownersMeeting.data.id.toString();
    }
    if (isDirty) {
      onSaveOwnersMeetingProtocol(5, () => onDownloadDocument(id, forConfirmation));
    } else {
      onDownloadDocument(id, forConfirmation);
    }
  };

  const onSaveAndSendForSigning = () => {
    if (isDirty) {
      onSaveOwnersMeetingProtocol(5, () => onSend(`${endpointUrls.OWNERS_MEETING_PROTOCOL}/${selectedOwnersMeetingId}/send-for-signing`));
    } else {
      onSend(`${endpointUrls.OWNERS_MEETING_PROTOCOL}/${selectedOwnersMeetingId}/send-for-signing`);
    }
  };

  const onSaveAndSendToAllOwners = () => {
    if (isDirty) {
      onSaveOwnersMeetingProtocol(5, () => onSend(`${endpointUrls.OWNERS_MEETING_PROTOCOL}/${selectedOwnersMeetingId}/send-to-owners`));
    } else {
      onSend(`${endpointUrls.OWNERS_MEETING_PROTOCOL}/${selectedOwnersMeetingId}/send-to-owners`);
    }
  };

  const onSend = (path: string) => {
    if (!selectedOwnersMeetingId) return;
    setOwnersMeeting(ownersMeeting.startLoading());
    backend.post(path, {})
      .then((response: any) => {
        if (response.isError) {
          setOwnersMeeting(ownersMeeting.failed());
          showNotification({
            key: 'sendOut',
            message: tl(ownersMeetingProtocolTranslations.notifications.sendOutError.message),
            type: 'error',
          });
        } else {
          setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(response, defaultProtocolTexts, getDefaultProtocolText), {}, true));
          setIsValid(true);
          showNotification({
            key: 'sendOut',
            message: tl(ownersMeetingProtocolTranslations.notifications.sendSuccess.message),
            type: 'success',
          });
        }
      })
      .catch((error) => {
        setOwnersMeeting(ownersMeeting.failed());

        if (error.detail === 'invalidEpostConfig') {
          showNotification({
            key: 'invalidEpostConfig',
            message: tl(ownersMeetingProtocolTranslations.notifications.sendOutError.invalidEpostConfig),
            type: 'error',
          });
          return;
        }

        if (error.detail === 'invalidCasaviConfig') {
          showNotification({
            key: 'invalidCasaviConfig',
            message: tl(ownersMeetingProtocolTranslations.notifications.sendOutError.invalidCasaviConfig),
            type: 'error',
          });
          return;
        }

        showNotification({
          key: 'sendOut',
          message: tl(ownersMeetingProtocolTranslations.notifications.sendOutError.message),
          type: 'error',
        });
      });
  };

  const updateFilterState = (data: object) => {
    setFilterState({
      ...filterState,
      ...data,
    });
  };

  const setDispatchTypeForOwnersTo = (dispatchType: OwnersMeetingProtocolRecipientExtendedDisplayDtoDispatchTypesEnum, value: boolean) => {
    try {
      setOwnersMeeting((state) => {
        const data = _.cloneDeep(state.data);
        data!.protocolRecipients!.forEach((recipient: OwnersMeetingProtocolRecipientExtendedDisplayDto) => {
          if (value) {
            if (!recipient.dispatchTypes) {
              recipient.dispatchTypes = [dispatchType];
            } else if (!recipient.dispatchTypes.includes(dispatchType)) {
              recipient.dispatchTypes.push(dispatchType);
            }
          } else if (recipient.dispatchTypes) {
            recipient.dispatchTypes = recipient.dispatchTypes.filter(dt => dt !== dispatchType);
          }
        });
        return state.load(data!);
      });
    } catch {
      // NOOP
    }
  };

  const onChangeDispatchBasedOnPreference = (dispatchBasedOnPreference: boolean) => {
    if (dispatchBasedOnPreference) {
      try {
        setOwnersMeeting((state) => {
          const data = _.cloneDeep(state.data);
          data!.protocolRecipients!.forEach((recipient: OwnersMeetingProtocolRecipient) => {
            recipient.dispatchTypes = _.clone(recipient.contractProjectionDto?.mailingContact.dispatchPreferences) as unknown as OwnersMeetingProtocolRecipientExtendedDisplayDtoDispatchTypesEnum[] | undefined;
          });
          data!.dispatchBasedOnPreference = true;
          return state.load(data!);
        });
        // eslint-disable-next-line no-empty
      } catch {
      }
    } else {
      setOwnersMeeting((state) => {
        const data = _.cloneDeep(state.data);
        data!.dispatchBasedOnPreference = false;
        return state.load(data!);
      });
    }
  };

  const distributionOptions = [
    {
      value: OwnersMeetingProtocolDtoVotingRightsEnum.MEA,
      label: tl(ownersMeetingProtocolTranslations.distributionOptions.mea),
    },
    {
      value: OwnersMeetingProtocolDtoVotingRightsEnum.UNIT,
      label: tl(ownersMeetingProtocolTranslations.distributionOptions.units),
    },
    {
      value: OwnersMeetingProtocolDtoVotingRightsEnum.HEAD,
      label: tl(ownersMeetingProtocolTranslations.distributionOptions.headcount),
    },
  ];


  const onFinalize = () => {
    setOwnersMeeting(ownersMeeting.startLoading());
    ownersMeetingProtocolControllerApi.markOwnersMeetingProtocolFinalizedUsingPOST({ ownersMeetingProtocolId: selectedOwnersMeetingId })
      .then((response: any) => {
        setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(response, defaultProtocolTexts, getDefaultProtocolText), {}, true));
        setIsValid(true);
      })
      .catch(() => {
        setOwnersMeeting(ownersMeeting.failed());

        showNotification({
          key: 'finalizeOwnersMeetingProtocolError',
          message: tl(ownersMeetingProtocolTranslations.notifications.finalizeOwnersMeetingProtocolError),
          type: 'error',
        });
      });
  };

  const onValidateOwnersMeetingProtocol = (ownersMeetingProtocolId: number) => {
    setOwnersMeeting(prev => prev.startLoading());
    ownersMeetingProtocolControllerApi.validateOwnersMeetingProtocolUsingGET({ ownersMeetingProtocolId })
      .then((response) => {
        setIsValid(response.valid);
        setOwnersMeeting(prev => prev.finishLoading());
      }).catch(() => {
        setOwnersMeeting(prev => prev.finishLoading());
      });
  };

  const onFinalizeOwnersMeetingProtocol = () => {
    setOwnersMeeting(ownersMeeting.startLoading());

    if (isDirty) {
      onSaveOwnersMeetingProtocol(5, () => onFinalize());
    } else {
      onFinalize();
    }
  };

  const onRevertToDraft = (successCallback?: Function) => {
    setOwnersMeeting(ownersMeeting.startLoading());
    ownersMeetingProtocolControllerApi.revertOwnersMeetingProtocolToDraftUsingPOST({ ownersMeetingProtocolId: selectedOwnersMeetingId })
      .then((response: any) => {
        setOwnersMeeting((ownersM: any) => ownersM.load(convertToFeModel(response, defaultProtocolTexts, getDefaultProtocolText), {}, true));
        onLoadProtocolRecipientsFields();
        setIsValid(true);
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error) => {
        console.error(error);
        setOwnersMeeting(ownersMeeting.failed());

        showNotification({
          key: 'revertOwnersMeetingProtocolToDraftError',
          message: tl(ownersMeetingProtocolTranslations.notifications.revertOwnersMeetingProtocolErrorToDraft),
          type: 'error',
        });
      });
  };

  return (
    <OwnersMeetingProtocolContext.Provider value={{
      ownersMeetingProtocolListState,
      ownersMeeting,
      selectedOwnersMeetingId,
      setSelectedOwnersMeetingId,
      isValid,
      filterState,
      setFilterState,
      distributionOptions,
      roleOptions,
      votingSchemeOptions,
      votingRightsOptions,
      clearFilter,
      clearData,
      onLoadOwnersMeetingProtocolList,
      onLoadOwnersMeetingProtocol,
      onSaveOwnersMeetingProtocol,
      onClearOwnersMeeting,
      onDeleteOwnersMeetingProtocol,
      updateOwnersMeetingProtocol,
      onSaveAndDownloadAttendeeListDocument,
      onSaveVote,
      isDirty,
      setDirty,
      onSaveAndDownloadDocument,
      onSaveAndSendForSigning,
      onSaveAndSendToAllOwners,
      updateFilterState,
      onChangeDispatchBasedOnPreference,
      setDispatchTypeForOwnersTo,
      openSectionIndex,
      setOpenSectionIndex,
      setSortField,
      sortField: sortRef.current.field,
      sortOrder: sortRef.current.order,
      onFinalizeOwnersMeetingProtocol,
      onRevertToDraft,
      loadingRecipients,
      onValidateOwnersMeetingProtocol,
    }}
    >
      {children}
    </OwnersMeetingProtocolContext.Provider>
  );
}
