import { useContext } from 'react';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { Tooltip } from 'antd';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';

export const useDistributionKeyTableColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.distributionKeyTable.table.headers.property),
      dataKey: 'name',
      render: (_, record) => (
        <Tooltip title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.name || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.distributionKeyTable.table.headers.accountant),
      dataKey: 'accountantName',
    },
    {
      title: tl(translations.pages.distributionKeyTable.table.headers.administrator),
      dataKey: 'supervisorName',
    },

  ];
};
