import React, {
  ChangeEvent, useRef,
} from 'react';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import { ButtonType } from 'antd/es/button';


export type FileEventTarget = EventTarget & { files: FileList };


export const FileUploadButton = ({
  label, disabled = false, onChange = (() => {}), accept = '', type, showIcon,
}:{label:string, disabled?:boolean, onChange:(e:ChangeEvent<FileEventTarget>)=>any, type?:ButtonType, accept?:string, showIcon?: boolean}) => {
  const fileUploadRef = useRef<HTMLInputElement>();
  return (
    <Button
      disabled={disabled}
      onClick={() => {
        if (fileUploadRef.current) {
          // clear value of fileupload, so if user selects the same file again they can upload it
          // otherwise there is not onChange triggered on the input
          fileUploadRef.current.value = '';
          fileUploadRef.current.click();
        }
      }}
      type={type}
      icon={showIcon ? <Icon component={ICONS.upload} /> : undefined}
    >
      {label}
      <input
        type="file"
        accept={accept}
        onChange={onChange}
        disabled={disabled}
        ref={fileUploadRef}
        style={{
          display: 'none',
        }}
      />
    </Button>
  );
};

FileUploadButton.defaultProps = {
  showIcon: true,
  accept: 'application/pdf',
  type: 'primary',
  disabled: false,
};
