import React, { useContext } from 'react';
import { HouseMoneySettlementDtoStatusEnum } from 'api/accounting/models';
import { DropMenu } from 'components/DropMenu/DropMenu';
import { DocumentDownloadModal } from 'storybook-components/modals/DocumentDownloadModal/DocumentDownloadModal';
import Button from '../../../../../../../elements/Buttons/Button/Button';
import { translations } from '../../../../../../../elements/Translation/translations';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import { useRightSideButtons } from './useRightSideButtons';
import StateChangeButtons from '../StateChangeButtons/StateChangeButtons';

interface RightSideButtonsParams {
  settlementStatus: HouseMoneySettlementDtoStatusEnum
}

export default function RightSideButtons(props: RightSideButtonsParams) {
  const { tl } = useContext(LanguageContext);
  const {
    goBack,
    buttonTooltip,
    houseMoneySettlementState,
    selectedProperty,
    onRefreshHouseMoneySettlement,
    inCreation,
    creationFailed,
    mergedDocumentModalVisible,
    setMergedDocumentModalVisible,
    dropMenuOptions,
    messagesExist,
    onSend,
    onCheckMessages,
    selectedDocumentId,
    documents,
    startLoading,
    stopLoading,
    messagesGenerating,
  } = useRightSideButtons();
  const { settlementStatus } = props;

  if (creationFailed) {
    return (
      <>
        <Button
          type="text"
          onClick={goBack}
        >
          {tl(translations.pages.houseMoneySettlement.overview.close)}
        </Button>
        <Button
          disabled={selectedProperty.loading || houseMoneySettlementState.loading || inCreation || messagesGenerating}
          tooltipText={buttonTooltip}
          type="primary"
          className="right-side-button icon"
          onClick={() => {
            onRefreshHouseMoneySettlement();
          }}
        >
          {tl(translations.pages.houseMoneySettlement.overview.recreate)}
        </Button>
      </>
    );
  }
  return (
    <>
      <Button
        type="text"
        onClick={goBack}
      >
        {tl(translations.pages.houseMoneySettlement.overview.close)}
      </Button>
      <DropMenu
        disabled={selectedProperty.loading || houseMoneySettlementState.loading || inCreation || creationFailed}
        options={dropMenuOptions}
      />
      {(settlementStatus === HouseMoneySettlementDtoStatusEnum.DRAFT || settlementStatus === HouseMoneySettlementDtoStatusEnum.FAILED) && (
        <Button
          disabled={selectedProperty.loading || houseMoneySettlementState.loading || inCreation || messagesGenerating}
          tooltipText={buttonTooltip}
          type="ghost"
          className="right-side-button icon"
          onClick={() => {
            onRefreshHouseMoneySettlement();
          }}
        >
          {tl(translations.pages.houseMoneySettlement.overview.recreate)}
        </Button>
      )}
      { settlementStatus !== HouseMoneySettlementDtoStatusEnum.FINALIZED // for finalized HGAs will be behind the 3 dots menu
      && (
        messagesExist
          ? (
            <Button
              disabled={selectedProperty.loading || houseMoneySettlementState.loading || inCreation || creationFailed}
              type="ghost"
              className="right-side-button icon"
              onClick={onCheckMessages}
            >
              {tl(translations.pages.houseMoneySettlement.overview.checkMessages)}
            </Button>
          )
          : (
            <Button
              disabled={selectedProperty.loading || houseMoneySettlementState.loading || inCreation || creationFailed}
              type="ghost"
              className="right-side-button icon"
              onClick={onSend}
            >
              {tl(translations.pages.houseMoneySettlement.overview.send)}
            </Button>
          )
      )
      }
      <StateChangeButtons messagesExist={messagesExist} />
      <DocumentDownloadModal
        visible={mergedDocumentModalVisible}
        onChangeVisible={setMergedDocumentModalVisible}
        loading={houseMoneySettlementState.loading}
        selectedDocumentId={selectedDocumentId}
        documents={documents}
        startLoading={startLoading}
        stopLoading={stopLoading}
      />
    </>
  );
}
