export const translations: any = {
  title: {
    en: 'Attendance List',
    de: 'Anwesenheitsliste',
  },
  close: {
    en: 'Close',
    de: 'Schließen',
  },
};
