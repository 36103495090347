import React, { useContext } from 'react';
import { ICONS } from 'components/icons';
import { ContactPropertiesContextProvider } from 'pages/Contacts/components/ContactProperties/services/ContactPropertiesContext';
import ContactProperties from 'pages/Contacts/components/ContactProperties/ContactProperties';
import { contactPropertiesTranslations } from 'pages/Contacts/components/ContactProperties/translation';
import { LanguageContext } from '../../../../../contexts/LanguageContext';

import defaultSection from './defaultSection';

export const usePropertiesSection = (personId: number) => {
  const { tl } = useContext(LanguageContext);

  return {
    ...defaultSection(0),
    sectionIcon: ICONS.propertiesSection,
    sectionTitle: tl(contactPropertiesTranslations.properties),
    sectionId: 'PropertySection',
    onChange: () => { },
    content: [
      {
        content: (
          <ContactPropertiesContextProvider>
            <ContactProperties contactId={personId} />
          </ContactPropertiesContextProvider>
        ),
      },
    ],
  };
}
