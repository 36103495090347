import React from 'react';
import { Table } from 'antd';
import TableSummaryCellWithTwoLines from 'storybook-components/table/TableSummaryCellWithTwoLines/TableSummaryCellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';
import { useTableSummary } from './useTableSummary';
import { TableSummaryIconCell } from '../../../../../../elements/TableSummaryIconCell/TableSummaryIconCell';


export default function TableSummary() {
  const { totalSummaryData } = useTableSummary();
  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <TableSummaryIconCell />
        {totalSummaryData.map((data, index) => (
          <TableSummaryCellWithTwoLines
            index={index + 1}
            firstElement={<Amount>{data.total}</Amount>}
            secondElement={data.label}
            offsetContentForColumnWithInputs
          />
        ))}
      </Table.Summary.Row>
    </Table.Summary>
  );
}
