import React from 'react';
import './ToggleButton.scss';
import Button from '../Button/Button';


interface additionalEntityButtonProps {
  label?: string,
  onClick: () => void,
}

export default function ToggleButton(props: additionalEntityButtonProps): JSX.Element {
  return (
    <Button className="ToggleButton" onClick={props.onClick} type="text">
      {props.label}
    </Button>
  );
}
