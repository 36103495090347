import React, {
  useContext,
  useState,
} from 'react';

import _ from 'lodash';

import {
  AccountControllerApi,
  AccountDistributionControllerApi,
  AccountDistributionCreateDto,
  AccountDistributionKeyControllerApi,
  BuildingControllerApi,
  DistributionSetControllerApi,
  DistributionSetDtoDistributionModeEnum,
  PropertyLegacyDtoAdministrationTypeEnum,
  UnitContractControllerApi,
  UnitLegacyControllerApi,
} from '../api/accounting';
import DEFAULT_DATA from '../lib/data';
import { showNotification } from '../lib/Notification';
import { formatDate } from '../lib/Utils';
import { translations } from '../pages/PropertyDistributionKeys/translations';
import { AuthContext } from './AuthContext';
import { LanguageContext } from './LanguageContext';

export const PropertyDistributionKeyListContext: any = React.createContext({});

export default function PropertyDistributionKeyListProvider({ children }: any): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const defaultPropertyDistributionState = {
    income: [],
    expenses: [],
    reserveFunds: [],
  };
  const { apiConfiguration } = useContext(AuthContext);

  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const distributionSetControllerApi = new DistributionSetControllerApi(apiConfiguration('accounting'));
  const accountDistributionControllerApi = new AccountDistributionControllerApi(apiConfiguration('accounting'));
  const accountDistributionKeyControllerApi = new AccountDistributionKeyControllerApi(apiConfiguration('accounting'));

  const [propertyDistributionKeyListState, setPropertyDistributionKeyListState] = useState(DEFAULT_DATA<any>(defaultPropertyDistributionState));
  const [propertyId, setPropertyId]: [number | null, any] = useState(null);
  const [propertyHrId, setPropertyHrId] = useState(null);
  const [selectedEconomicYear, setSelectedEconomicYear] = useState<number | undefined>();
  const [yearsWithDistributionKeys, setYearsWithDistributionKeys] = useState<number[]>([]);
  const [yearsWithDistributionKeysLoaded, setYearsWithDistributionKeysLoaded] = useState(false);

  // @ts-ignore
  const getAccountName = async (keyCode: string, specificPropertyHrId?: string) => {
    if (specificPropertyHrId) {
      return accountControllerApi.getAccountUsingGET({
        accountCode: keyCode,
        propertyHrId: specificPropertyHrId,
      });
    }
    if (!propertyHrId) {
      return '';
    }
    return accountControllerApi.getAccountUsingGET({
      accountCode: keyCode,
      propertyHrId: propertyHrId!,
    });
  };

  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));

  const onLoadActiveUnitContractsOfProperty = async (propertyIdParam: number) => unitContractControllerApi.getUnitContractsUsingGET({
    propertyId: propertyIdParam,
    atDate: formatDate(new Date(), 'YYYY-MM-DD'),
    isOwnedByWeg: false,
  });

  const unitControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const onLoadUnitsOfProperty = async (propertyIdParam: number) => unitControllerApi.getSimpleUnitsUsingGET({ propertyId: propertyIdParam });

  const buildingControllerApi = new BuildingControllerApi(apiConfiguration('accounting'));
  const onLoadBuildingsOfProperty = async (propertyIdParam: number) => (await buildingControllerApi.findBuildingsUsingGET({ propertyId: propertyIdParam })).content;

  const onLoadYearsWithDistributionKeys = (propId: number) => {
    if (!propId) return;
    accountDistributionKeyControllerApi.getDistributionKeyYearsForPropertyUsingGET({
      propertyId: propId,
    })
      .then((response: number[]) => {
        response.sort((a, b) => a - b);
        setYearsWithDistributionKeys(response);
        setYearsWithDistributionKeysLoaded(true);
      })
      .catch(() => {
        showNotification({
          key: 'yearWithDKsloadError',
          message: tl(translations.propertyDistributionKeyListContext.loadError),
          description: tl(translations.propertyDistributionKeyListContext.yearWithDKsloadError),
          type: 'error',
        });
      });
  };


  const mapToLeafAccounts = (account: any) => {
    if (!account.children) return account;

    return account.children.flatMap((child: any) => mapToLeafAccounts(child));
  };


  const copyDistributionKeysForWEG = (sourceYear: number, destinationYear: number) => (
    accountDistributionKeyControllerApi.copyAccountDistributionKeysUsingPOST({
      dto: {
        srcYear: sourceYear.toString(),
        dstYear: destinationYear.toString(),
        propertyId,
      },
    })
  );

  const copyDistributionKeysForMV = (sourceYear: number, destinationYear: number) => (
    // NOTE: we don't need to call delete first, the CREATE endpoint overwrites existing ones
    accountDistributionControllerApi.getAccountDistributionsFilteredUsingGET({ propertyId, economicYear: sourceYear.toString() })
      .then((resp) => {
        distributionSetControllerApi.getDistributionSetsUsingGET({ propertyId, distributionSetIds: resp.map(ad => ad.distributionSetId).filter(Boolean) })
          .then((distributionSets) => {
            const accountDistributions: AccountDistributionCreateDto[] = resp.map(accountDistribution => ({
              propertyId,
              accountCode: accountDistribution.accountCode,
              apply: accountDistribution.apply,
              economicYear: destinationYear.toString(),
              unitSetId: accountDistribution.unitSetId,
              heatingCenterId: accountDistribution.heatingCenterId,
              distributionSetId: distributionSets.find(ds => ds.id === accountDistribution.distributionSetId)?.distributionMode === DistributionSetDtoDistributionModeEnum.DIRECT_COST
                ? null
                : accountDistribution.distributionSetId,
            }));

            return accountDistributionControllerApi.createAccountDistributionsUsingPOST({ accountDistributions });
          });
      })
  );

  const copyDKs = (administrationType: PropertyLegacyDtoAdministrationTypeEnum, sourceYear: number, destinationYear: number) => {
    if (!propertyId) return;

    showNotification({
      key: 'copyInProgress',
      message: tl(translations.propertyDistributionKey.copyInProgress),
      type: 'warning',
    });

    (
      administrationType === PropertyLegacyDtoAdministrationTypeEnum.WEG
        ? copyDistributionKeysForWEG(sourceYear, destinationYear)
        : copyDistributionKeysForMV(sourceYear, destinationYear)
    )
      .then(() => {
        setYearsWithDistributionKeys((yearsWithDistributionList) => {
          if (!yearsWithDistributionList.includes(destinationYear)) {
            return [...yearsWithDistributionList, destinationYear].sort((a, b) => a - b);
          }
          return yearsWithDistributionList;
        });

        showNotification({
          key: 'copySuccess',
          message: tl(translations.propertyDistributionKey.copySuccess),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'copyError',
          message: tl(translations.propertyDistributionKey.copyError),
          type: 'error',
        });
      });
  };

  return (
    <PropertyDistributionKeyListContext.Provider
      value={{
        propertyId,
        setPropertyId,
        setPropertyHrId,
        propertyDistributionKeyListState,
        setPropertyDistributionKeyListState,
        getAccountName,
        onLoadUnitsOfProperty,
        onLoadActiveUnitContractsOfProperty,
        onLoadBuildingsOfProperty,
        selectedEconomicYear,
        setSelectedEconomicYear,
        yearsWithDistributionKeys,
        yearsWithDistributionKeysLoaded,
        onLoadYearsWithDistributionKeys,
        copyDKs,
        mapToLeafAccounts,
      }}
    >
      {children}
    </PropertyDistributionKeyListContext.Provider>
  );
}
