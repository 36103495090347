import { useEffect } from 'react';

export default function useEvent(event: string, target: any, handler: (e: any) => void, passive = false) {
  useEffect(() => {
    if (!!target && !!target.addEventListener) {
      // initiate the event handler
      target.addEventListener(event, handler, passive);

      // this will clean up the event every time the component is re-rendered
      return function cleanup() {
        target.removeEventListener(event, handler);
      };
    }
  });
}
