import './PersonData.scss';

import React, { useContext } from 'react';

import { Card } from 'antd';
import {
  isEmpty,
  isNil,
} from 'lodash';
import moment from 'moment';

import { LanguageContext } from '../../../../../contexts/LanguageContext';
import {
  PersonEditingContext,
} from '../../../../../contexts/PersonEditingContext';
import { translations } from '../../../../../elements/Translation/translations';
import useCountryOptions from '../../../../../services/useCountryOptions';

export default function PersonData(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const contactEditingContext: any = useContext(PersonEditingContext);
  const contact = contactEditingContext.savedContactState.data;
  const { translateCountry } = useCountryOptions();

  const contactVia = () => {
    if (contact.dispatchPreferences) {
      return contact.dispatchPreferences
        .map((dispatchType: string) => tl(translations.pages.contactEditing.contactSection.contactSection.dispatchTypes[dispatchType]))
        .join(', ');
    }
    return '—';
  };

  const addressComponent = (address: any, index: number) => (
    <div key={`${address.city}-${address.number}`}>
      <div className="label">
        {contactEditingContext.data && contactEditingContext.data.contact && contactEditingContext.data.contact.addresses && contactEditingContext.data.contact.addresses.length >= index && contactEditingContext.data.contact.addresses[index - 1].forInvoice
          ? tl(translations.pages.contactDetails.contactData.fields.invoicingAddress) : tl(translations.pages.contactDetails.contactData.fields.mailingAddress)}
      </div>
      <div className="value address-value">
        <div>{`${address.street} ${address.number}`}</div>
        <div>{`${address.postalCode} ${address.city}`}</div>
        <div>{translateCountry(address.country)}</div>
        <div className="additional-addresses">
          <div>{address.additionalAddress1 ? address.additionalAddress1 : '—'}</div>
          <div>{address.additionalAddress2 ? address.additionalAddress2 : '—'}</div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="PersonData component">
      <div className="title">
        {tl(translations.pages.contactDetails.contactData.title)}
      </div>
      <Card className="data-card">
        <div className="basic-info section">
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.title)}
          </div>
          <div className="value">
            {contact.title ? contact.title : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.salutation)}
          </div>
          <div className="value">
            {isNil(contact.salutation) || isEmpty(contact.salutation.trim())
              ? '—'
              : tl(translations.pages.contactEditing.contactSection.personSection.fields.salutation[contact.salutation.toLowerCase()])}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.firstName)}
          </div>
          <div className="value">
            {contact.firstName ? contact.firstName : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.lastName)}
          </div>
          <div className="value">
            {contact.lastName ? contact.lastName : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.birthday)}
          </div>
          <div className="value">
            {contact.dateOfBirth ? moment(contact.dateOfBirth).format('DD.MM.YYYY') : '—'}
          </div>
        </div>
        <div className="contact-info section">
          <div className="left-side">
            <div
              className="label"
            >
              {tl(translations.pages.contactDetails.contactData.fields.email)}
            </div>
            <div className="value">
              {contact.email
                ? (
                  <a className="value" href={`mailto:${contact.email}`}>
                    {contact.email}
                  </a>
                )
                : '—'}
            </div>
            <div
              className="label"
            >
              {tl(translations.pages.contactDetails.contactData.fields.officeTel)}
            </div>
            <div className="value">
              {contact.phoneBusiness
                ? (
                  <a className="value" href={`tel:${contact.phoneBusiness}`}>
                    {contact.phoneBusiness}
                  </a>
                )
                : '—'}
            </div>
            <div
              className="label"
            >
              {tl(translations.pages.contactDetails.contactData.fields.mobileTel)}
            </div>
            <div className="value">
              {contact.phoneMobile
                ? (
                  <a className="value" href={`tel:${contact.phoneMobile}`}>
                    {contact.phoneMobile}
                  </a>
                )
                : '—'}
            </div>
            <div
              className="label"
            >
              {tl(translations.pages.contactDetails.contactData.fields.privateTel)}
            </div>
            <div className="value">
              {contact.phonePrivate
                ? (
                  <a className="value" href={`tel:${contact.phonePrivate}`}>
                    {contact.phonePrivate}
                  </a>
                )
                : '—'}
            </div>
            <div
              className="label"
            >
              {tl(translations.pages.contactDetails.contactData.fields.fax)}
            </div>
            <div className="value">
              {contact.fax ? contact.fax : '—'}
            </div>
          </div>
          <div className="right-side">
            <div
              className="label"
            >
              {tl(translations.pages.contactDetails.contactData.fields.contactVia)}
            </div>
            <div className="value">
              {contactVia()}
            </div>
          </div>
        </div>
        <div className="address-info section">
          {contact.addresses && contact.addresses.map((address: any, idx: number) => addressComponent(address, idx + 1))}
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.note)}
          </div>
          <div className="value">
            {contact.notes ? contact.notes : '—'}
          </div>
        </div>
      </Card>
    </div>
  );
}
