import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  OwnersMeetingProtocolCreationDtoOwnersMeetingInvitationTypeEnum,
  OwnersMeetingProtocolDto,
  OwnersMeetingProtocolDtoStatusEnum,
} from 'api/accounting';
import _ from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import SmartTable from 'elements/SmartTable/SmartTable';
import { Action } from 'elements/SmartTable/data';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import { Modal } from 'antd';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import useOwnersMeetingProtocolFilters from './UseOwnersMeetingProtocolFilters';
import { translations, translations as ownersMeetingProtocolTranslations } from '../../translations';
import { useOwnersMeetingProtocolListColumns } from './services/useOwnersMeetingProtocolListColumns';
import { OwnersMeetingProtocolContext } from '../../services/OwnersMeetingProtocolContext';
import Button from '../../../../elements/Buttons/Button/Button';

import './OwnersMeetingProtocolList.scss';

export default function OwnersMeetingProtocolList(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const ownersMeetingProtocolContext: any = useContext(OwnersMeetingProtocolContext);
  const history = useHistory();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  const {
    ownersMeetingProtocolListState,
    onLoadOwnersMeetingProtocolList,
    clearFilter,
    filterState,
    setFilterState,
    updateFilterState,
    setSortField,
    sortField,
    sortOrder,
  } = ownersMeetingProtocolContext;

  useGlobalSearch({
    key: 'ownersMeetingProtocol',
    filterProps: {
      availableFilters: useOwnersMeetingProtocolFilters(),
      setFilter: (key: string, value: any) => setFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);
          newFilter[key] = value;
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams: updateFilterState,
    },
  });

  useEffect(() => {
    onLoadOwnersMeetingProtocolList();
    return () => clearFilter();
  }, []);

  useEffect(() => {
    if (propertyHrId) {
      updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  const toTableData = (data: any) => ({
    ...data,
    propertyHrId: data.propertyHrId,
    propertyIdInternal: data.propertyIdInternal,
    propertyName: data.propertyName,
    protocolStatus: tl(translations.states[data.status ?? OwnersMeetingProtocolDtoStatusEnum.OPEN]),
    ownersMeetingInvitationType: data.ownersMeetingInvitationType
      ? tl(translations.list[data.ownersMeetingInvitationType.toLowerCase()])
      : '—',
  });

  const actionsMenu: Action[] = [{
    label: tl(translations.actions.delete),
    onAction: row => Modal.confirm({
      title: tl(ownersMeetingProtocolTranslations.deleteModal.title),
      content: tl(ownersMeetingProtocolTranslations.deleteModal.content),
      okText: tl(ownersMeetingProtocolTranslations.deleteModal.okText),
      cancelText: tl(ownersMeetingProtocolTranslations.deleteModal.cancelText),
      okButtonProps: { className: 'Button', danger: true },
      cancelButtonProps: { className: 'Button' },
      onOk: () => ownersMeetingProtocolContext.onDeleteOwnersMeetingProtocol(row.id),
      width: 450,
      icon: <Icon component={ICONS.statusError} />,
    }),
    actionSupported: ((record: OwnersMeetingProtocolDto) => record.status === OwnersMeetingProtocolDtoStatusEnum.OPEN),
  }];

  const tableData = useMemo(() => ownersMeetingProtocolListState.data.map(toTableData), [ownersMeetingProtocolListState.data]);
  const smartTable = useSmartTable({
    tableName: 'OwnersMeetingProtocolListTable',
    columns: useOwnersMeetingProtocolListColumns(),
    dataSource: tableData,
    contentLoading: ownersMeetingProtocolListState.loading,
    onRow: (record: any) => ({
      onClick: () => {
        const navPrefix = propertyHrId ? `/properties/${propertyHrId}/edit` : '';
        if (record.status === OwnersMeetingProtocolDtoStatusEnum.DONE || record.status === OwnersMeetingProtocolDtoStatusEnum.DELETED) {
          history.push(`${navPrefix}/owners-meeting/protocol/view/${record.id}`);
        } else history.push(`${navPrefix}/owners-meeting/protocol/edit/${record.id}`);
      },
    }),
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    infiniteScrollerProps: {
      hasMoreData: !ownersMeetingProtocolListState.lastPage,
      loadMoreData: onLoadOwnersMeetingProtocolList,
    },
    rowKey: 'id',
    actionsMenu,
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'ownersMeetingProtocol',
    navItems: subcategorySwitcherItems.meetings,
  };

  return (
    <div className="OwnersMeetingProtocolList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button
            type="primary"
            onClick={() => {
              history.push('/owners-meeting/protocol/create');
            }}
          >
            {tl(ownersMeetingProtocolTranslations.actions.create)}
          </Button>
        )}
      />
      <SmartTable {...smartTable} />
    </div>
  );
}
