import React, {
  useContext, useMemo,
} from 'react';
import _, { isEmpty } from 'lodash';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { useHistory } from 'react-router';
import { Modal } from 'antd';
import { showNotification } from 'lib/Notification';
import defaultSection from '../../components/DefaultSection';
import { translations } from '../../../../translations';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { OwnersMeetingProtocolContext } from '../../../../services/OwnersMeetingProtocolContext';
import { OwnersMeetingProtocol, OwnersMeetingProtocolRecipient } from '../../../../interfaces';
import AdditionalEntityButton from '../../../../../../elements/Buttons/AdditionalEntityButton/AdditionalEntityButton';
import './confirmProtocolSection.scss';
import { ICONS } from '../../../../../../components/icons';
import {
  OwnersMeetingProtocolDtoStatusEnum,
  OwnersMeetingProtocolRecipientExtendedDisplayDto,
} from '../../../../../../api/accounting';
import { translations as generalTranslations } from '../../../../../../elements/Translation/translations';
import { SigningPersonsTable } from './SigningPersonsTable/SigningPersonsTable';
import { DocumentCreateDtoOcrStateEnum, DocumentCreateDtoSourceTypeEnum } from 'api/document';

interface ConfirmProtocolSectionProps {
  index: number,
  etvId?: string,
  setDirty: (val: boolean) => void,
  isSectionDisabled: boolean,
}

export default function useConfirmProtocolSection({
  index, etvId, setDirty, isSectionDisabled,
}: ConfirmProtocolSectionProps) {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const {
    ownersMeeting, updateOwnersMeetingProtocol, onSaveAndDownloadDocument, onSaveAndSendForSigning, loadingRecipients,
  } = useContext(OwnersMeetingProtocolContext);
  const disabled = ownersMeeting.data.status !== OwnersMeetingProtocolDtoStatusEnum.OPEN && ownersMeeting.data.status !== OwnersMeetingProtocolDtoStatusEnum.SENT_FOR_SIGNATURE;
  const loading = loadingRecipients || ownersMeeting.loading;

  /**
   *  Finds the first not signing owner from the attendee list and sets the isSigningPerson to TRUE
   */
  const onAddSingingPerson = () => {
    setDirty(true);
    updateOwnersMeetingProtocol((currentState: OwnersMeetingProtocol) => {
      const newOwnersMeeting: OwnersMeetingProtocol | null = _.cloneDeep(currentState);
      try {
        const firstNotSigningPerson: OwnersMeetingProtocolRecipient = newOwnersMeeting!.protocolRecipients!
          .filter((attendee: OwnersMeetingProtocolRecipientExtendedDisplayDto, index, self) => !attendee.isSigningPerson && index === self.findIndex(value => attendee.contractProjectionDto?.mailingContact?.name === value.contractProjectionDto?.mailingContact?.name))[0] as OwnersMeetingProtocolRecipient;

        firstNotSigningPerson.isSigningPerson = true;

        // singingIndex is needed to keep the order consistent
        newOwnersMeeting!.lastSigningIndex += 1;
        firstNotSigningPerson.signingIndex = newOwnersMeeting!.lastSigningIndex || -1;
      } catch (e) {
      }
      return newOwnersMeeting;
    });
  };

  const onClickDownloadButton = () => {
    onSaveAndDownloadDocument(etvId, true);
  };

  const signingPersonsList = useMemo(() => ownersMeeting?.data?.protocolRecipients?.filter((protocolRecipient: OwnersMeetingProtocolRecipientExtendedDisplayDto) => protocolRecipient.isSigningPerson
        && protocolRecipient.contractProjectionDto && protocolRecipient.contractProjectionDto.mailingContact) || [],
  [ownersMeeting?.data?.protocolRecipients]);

  const onCancelModal = (e) => {
    if (typeof e === 'function') {
      e();
    }
  };

  const onClickSendButton = () => {
    // cannot send protocol if no signing person is defined
    if (isEmpty(signingPersonsList)) {
      showNotification({
        key: 'sendForSigning',
        message: tl(translations.notifications.sendOutError.message),
        description: tl(translations.notifications.sendOutError.noSigningPerson),
        type: 'warning',
      });
      return;
    }

    // cannot send protocol if no dispatch type is defined
    const noDispatchTypeDefined = !isEmpty(signingPersonsList.filter(signingPerson => isEmpty(signingPerson.signingDispatchTypes)));
    if (noDispatchTypeDefined) {
      showNotification({
        key: 'sendForSigning',
        message: tl(translations.notifications.sendOutError.message),
        description: tl(translations.notifications.sendOutError.noDispatchType),
        type: 'warning',
      });
      return;
    }


    Modal.confirm({
      title: tl(translations.editPage.sections.sendProtocol.sendModal.title),
      content: tl(translations.editPage.sections.confirmProtocol.submitForSignature.confirmationText),
      okText: tl(translations.editPage.sections.sendProtocol.sendModal.ok),
      cancelText: tl(translations.editPage.sections.sendProtocol.sendModal.cancel),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: onSaveAndSendForSigning,
      onCancel: onCancelModal,
      width: 520,
    });
  };

  const onCheckMessages = () => {
    history.push(`/messages?sourceId=${ownersMeeting.data.id}`);
  };

  const displayCheckMessagesButton: boolean = ownersMeeting.data?.status === OwnersMeetingProtocolDtoStatusEnum.SENT_FOR_SIGNATURE || ownersMeeting.data?.status === OwnersMeetingProtocolDtoStatusEnum.DONE;

  return {
    ...defaultSection(index, index),
    sectionTitle: tl(translations.editPage.sections.confirmProtocol.title),
    sectionId: 'confirmProtocol',
    disabled: isSectionDisabled,
    content: [
      {
        title: tl(translations.editPage.sections.confirmProtocol.submitForSignature.title),
        sectionId: 'submitForSignature',
        additionalButton:
            (
              <div className="additional-buttons">
                <Button onClick={onClickDownloadButton} loading={ownersMeeting.loading}>
                  {!ownersMeeting.loading && <Icon component={ICONS.download} />}
                </Button>
                { !disabled && (
                  <Button
                    onClick={onClickSendButton}
                    loading={ownersMeeting.loading}
                  >
                    {tl(translations.editPage.sections.sendProtocol.protocolSending.send)}
                  </Button>
                )}
                { displayCheckMessagesButton && (
                  <Button
                    key="checkMessagesButton"
                    type="primary"
                    onClick={onCheckMessages}
                    loading={loading}
                  >
                    {tl(translations.editPage.checkMessages)}
                  </Button>
                )}
              </div>),
        content:
          (ownersMeeting.data.status !== OwnersMeetingProtocolDtoStatusEnum.DONE && ownersMeeting.data.status !== OwnersMeetingProtocolDtoStatusEnum.SENT && (
            <>
              <SigningPersonsTable />
              <AdditionalEntityButton
                label={tl(translations.editPage.sections.confirmProtocol.submitForSignature.addSigningPerson)}
                className="add-signing-person"
                onClick={onAddSingingPerson}
                disabled={disabled}
              />
            </>
          )),
      },
      {
        sectionId: 'protocolSignedConfirmation',
        title: tl(translations.editPage.sections.sendProtocol.protocolSignedConfirmation.title),
        content: [
          [
            {
              type: 'radioGroup',
              key: 'protocolSigned',
              props: {
                label: '',
                options: [
                  {
                    value: false,
                    label: tl(translations.editPage.sections.sendProtocol.protocolSignedConfirmation.protocolSigned.no),
                  },
                  {
                    value: true,
                    label: tl(translations.editPage.sections.sendProtocol.protocolSignedConfirmation.protocolSigned.yes),
                  },
                ],
              },
            },
          ],
        ],
      },
      {
        sectionId: 'originalScan',
        content: [
          [
            {
              type: 'file',
              key: 'originalScan',
              props: {
                descriptionLabel: tl(translations.editPage.sections.sendProtocol.protocolSignedConfirmation.originalScan),
                buttonLabel: tl(generalTranslations.elements.fileUpload.buttonLabel),
                name: 'originalScan',
                hideDeleteButton: disabled,
                sourceType: DocumentCreateDtoSourceTypeEnum.OTHER,
                ocrState: DocumentCreateDtoOcrStateEnum.NO_OCR,
                onDeleteFile: () => {
                  setDirty(true);
                  updateOwnersMeetingProtocol((currentOwnersMeeting: OwnersMeetingProtocol) => {
                    const { originalScan, ...rest } = currentOwnersMeeting;
                    return { ...rest };
                  });
                },
              },
            },
          ],
        ],
      },
    ],
  };
}
