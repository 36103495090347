export const reportDocumentTranslations = {
  propertyName: {
    en: 'Property name',
    de: 'Objektbezeichnung',
  },
  reportInterval: {
    en: 'Report interval',
    de: 'Abrechnungszeitraum',
  },
  propertyOwners: {
    en: 'Property owners',
    de: 'Objekteigentümer',
  },
  property: {
    en: 'Property',
    de: 'Objekt',
  },
  unit: {
    en: 'Unit',
    de: 'Einheit',
  },
  yourUsagePeriod: {
    en: 'Your usage period',
    de: 'Ihr Nutzungszeitraum',
  },
  vacancy: {
    en: 'Vacancy',
    de: 'Leerstand',
  },
};
