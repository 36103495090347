import './CommentModal.scss';

import {
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import TextAreaInput from 'elements/Inputs/TextAreaInput/TextAreaInput';
import {
  INTEGRATION_APPS,
} from 'storybook-components/TicketIntegrationSection/interfaces';

import { translations } from './translations';

interface CommentModalProps {
    visible: boolean,
    onCancel: () => void,
    onOk: (string) => void,
    app: INTEGRATION_APPS,
    setComment: Dispatch<SetStateAction<string>>;
    comment: string,
}

export const CommentModal = ({
  onCancel, visible, onOk, app, setComment, comment,
}: CommentModalProps) => {
  const { tl } = useContext(LanguageContext);

  return (
    <Modal
      className="CommentModal"
      visible={visible}
      onCancel={onCancel}
      title={tl(translations.commentModal.title)}
      footer={[
        <Button
          key="cancel"
          type="ghost"
          onClick={onCancel}
        >
          {tl(translations.commentModal.buttons.cancel)}
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={onOk}
        >
          {tl(translations.commentModal.buttons.assign)}
        </Button>,
      ]}
    >
      <p className="modal-description">
        {tl(translations.commentModal.description)(app)}
      </p>
      <TextAreaInput
        value={comment}
        label={tl(translations.commentModal.label)}
        className="comment-input"
        placeholder={tl(translations.commentModal.placeholder)}
        onChange={setComment}
      />
    </Modal>
  );
};
