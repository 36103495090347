import { ContractContactProjectionDtoRoleEnum, UnitContractProjectionDtoTypeEnum } from "api/accounting";
import { SyncDataDto } from "api/app";
import { Etg24Contract, Etg24ContractTypeEnum } from "api/etg24/interfaces";
import { isEmpty } from "lodash";

export const getImpowerUnitIdForEtg24Contract = (etg24Contract: Etg24Contract, unitsSyncData: SyncDataDto[]) => {
  const unitSyncDataOfContract =  unitsSyncData.find(
    unitSyncData => JSON.parse(unitSyncData.externalEntityId).unitId === etg24Contract.unitId,
  );

  // set unit id only for the contracts which are on synced units
  if (unitSyncDataOfContract) {
    return unitSyncDataOfContract.entityId;
  }
  return undefined;
}

export const getContractTypeAndVacancyFlagForEtg24Contract = (contract: Etg24Contract) => {
  if (isEmpty(contract.signatories)) {
    return {
      type: contract.contractType === Etg24ContractTypeEnum.OWNER ? UnitContractProjectionDtoTypeEnum.OWNER : UnitContractProjectionDtoTypeEnum.TENANT,
      isVacant: true,
    }
  }

  return {
    type: contract.contractType === Etg24ContractTypeEnum.OWNER ? UnitContractProjectionDtoTypeEnum.OWNER : UnitContractProjectionDtoTypeEnum.TENANT,
    isVacant: false,
  }
}

const getRoleFromEtg24Contract = (contract: Etg24Contract) => {
    if (contract.signatories[0].agent) {
      return ContractContactProjectionDtoRoleEnum.LEGAL_REPRESENTATIVE;
    }
  
    if (contract.contractType === Etg24ContractTypeEnum.OWNER) {
      return ContractContactProjectionDtoRoleEnum.OWNER;
    }
  
    return ContractContactProjectionDtoRoleEnum.TENANT;
  };

export const getMailingContactForEtg24Contract = (contract: Etg24Contract, vacancyLabel: string) => {
  if (isEmpty(contract.signatories)) {
    return {
      name: vacancyLabel,
      role: null
    }
  }

  return {
    name: `${contract.signatories[0]?.firstName} ${contract.signatories[0]?.lastName}`,
    role: [getRoleFromEtg24Contract(contract)],
  }

}

export const getEtg24ContractEditingUrl = (url: string) => (url + '/edit');