import {
  useContext, useEffect,
} from 'react';
import { AccountsBalanceContext } from 'contexts/AccountsBalanceContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import moment from 'moment';
import { useLocation, useParams } from 'react-router';
import { AccountListContext } from 'contexts/AccountListContext';
import DEFAULT_DATA from 'lib/data';
import _ from 'lodash';
import { getCurrentEconomicYear, getDateRangeForEconomicYear } from 'lib/Utils';
import { useCurrentOverlayInfo } from 'components/OverlayRoute/services/useCurrentOverlayInfo';

export function useAccountSheet() {
  const {
    currentEconomicYear, previousEconomicYear, selectedProperty, setSelectedPropertyHrId,
  } = useContext(PropertyListContext);
  const {
    accountsBalances, filterState, setAccountDateRange, getInitialAccountBalances, setAccountsBalances, setRangeValues,
  } = useContext(AccountsBalanceContext);
  const { selectedAccount } = useContext(AccountListContext);
  const { dateRange, accountDateRange } = filterState;
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { isOverlayOnTop } = useCurrentOverlayInfo();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  useEffect(() => {
    if (selectedProperty.loaded && !!selectedProperty.data && selectedProperty.data.propertyHrId === propertyHrId && _.isEmpty(accountsBalances.data)) {
      getInitialAccountBalances();
      return () => {
        setAccountsBalances(DEFAULT_DATA<any>([]));
      };
    }
  }, [selectedProperty]);


  useEffect(() => {
    if (!selectedProperty.loaded) {
      return;
    }

    const { economicYearStart } = selectedProperty.data;

    const economicYear = urlSearchParams.get('economicYear');
    const adjustedEconomicYear = economicYear
      ? getDateRangeForEconomicYear(economicYearStart, parseInt(economicYear, 10))
      : getCurrentEconomicYear(economicYearStart);

    const economicYearStartDate = moment(adjustedEconomicYear.economicYearStart);
    const economicYearEndDate = moment(adjustedEconomicYear.economicYearEnd);

    // we should update when overlay is on top and when accountDateRange is undefined
    if (!filterState.accountDateRange?.[0] && !filterState.accountDateRange?.[1] && isOverlayOnTop) {
      let startDateParam = moment(urlSearchParams.get('startDate') ?? '');
      let endDateParam = moment(urlSearchParams.get('endDate') ?? '');


      // if search params contains invalid dates then we set the property' economicYearStartDate and endDate
      if (!startDateParam.isValid()) {
        startDateParam = economicYearStartDate;
      }
      if (!endDateParam.isValid()) {
        endDateParam = economicYearEndDate;
      }

      setAccountDateRange([startDateParam, endDateParam]);

      // to load the data we need the daterange to be set
      if (!dateRange?.[0] && !dateRange?.[1]) {
        setRangeValues([startDateParam, endDateParam]);
      }
    }
  }, [isOverlayOnTop, selectedProperty.data]);


  return {
    currentEconomicYear, previousEconomicYear, selectedProperty, setSelectedPropertyHrId, accountsBalances, filterState, selectedAccount, dateRange, accountDateRange,
  };
}
