import React from 'react';
import { StickyRowProps } from './StickyRowInterface';
import { useSyncScroll } from './UseSyncScroll';
import { round2dec, stringWidthToNumber } from '../../../../lib/Utils';


export const StickyFirstRow: React.FC<StickyRowProps> = ({
  stickyRowData, visibleColumns, tableName, contentElement, loading = false, nestedHeader,
}) => {
  const className = `${tableName} sticky-row sticky-first-row ${loading ? 'loading' : ''}`;
  const topFixedRowElement: any = document.querySelector(`.${tableName}.sticky-first-row`) || {};
  useSyncScroll(contentElement, topFixedRowElement);

  if (!stickyRowData) return null;

  const measureRow: any = document.querySelector(`.${tableName} tr.ant-table-measure-row`) || {};

  return (
    <div
      className={className}
      style={nestedHeader ? { marginBottom: '-13.0rem', marginTop: '7.3rem' } : { marginBottom: '-9.4rem', marginTop: '3.7rem' }}
    >
      {visibleColumns
        .flatMap(column => (column.children ? column.children : column))
        .map((col: any, index) => {
          const colData = stickyRowData.find(sD => sD.dataKey === col.dataIndex);

          return (
            <div
              key={`${col.dataIndex}-${index}`}
              className={`sticky-row-cell ${colData?.className || ''}`}
              style={{ width: round2dec(stringWidthToNumber(col.width, measureRow.clientWidth)) }}
            >
              {colData ? colData.value : ''}
            </div>
          );
        })}
    </div>
  );
};

export default StickyFirstRow;
