import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { ExtendedAccountBalanceDto } from 'api/accounting';
import Amount from 'storybook-components/typography/Amount/Amount';


const AMOUNT_COLUMNS_WIDTH = '13%';


export const useBankAccountsTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useBankAccountsTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances } = profitAndLossReportContext;

  const firstDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    const matchingAccountCodes = Object.keys(accountBalances.data)
      .filter(accCode => (
        accCode.match(/^27\/[^5].*$/)
        && accountBalances.data[accCode].leaf
      ));

    const ds = matchingAccountCodes.map(code => accountBalances.data[code])
      .filter(accBalance => accBalance.normalizedBalanceAtEndOfDateRange !== 0
        || accBalance.normalizedBalanceWithinDateRange !== 0)
      .sort((a, b) => a.accountCode.localeCompare(b.accountCode));

    return ds;
  }, [accountBalances.data]);


  // to only iterate through the firstDataSource once we calculate them all in one go
  const firstSums = firstDataSource.reduce((acc, curr) => ({
    startBalanceSum: acc.startBalanceSum + curr.normalizedBalanceBeforeDateRange,
    debitSum: acc.debitSum + curr.debitAmountWithinDateRange,
    creditSum: acc.creditSum - curr.creditAmountWithinDateRange,
    balanceWithinDateRangeSum: acc.balanceWithinDateRangeSum + curr.normalizedBalanceWithinDateRange,
    endBalanceSum: acc.endBalanceSum + curr.normalizedBalanceAtEndOfDateRange,
  }), {
    startBalanceSum: 0,
    debitSum: 0,
    creditSum: 0,
    balanceWithinDateRangeSum: 0,
    endBalanceSum: 0,
  });

  const secondDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    return [
      // we need to add the datasource from the first table here as well so that the column widths stay aligned;
      // we hide these rows through css in BankAccountsSection.scss
      ...firstDataSource,
      {
        ...accountBalances.data['27/5'],
        accountCode: '27/5/x',
        accountName: tl(translations.report.sections.bankAccountsSection.datasourceNames.depositBankAccounts),
      },
    ];
  }, [accountBalances.data]);


  const columns: ColumnsType<ExtendedAccountBalanceDto> = [
    {
      title: tl(translations.report.sections.bankAccountsSection.columns.account),
      dataIndex: 'accountCode',
      className: 'white-space-pre-wrap-td',
      render: (_, rec) => `${rec.accountCode}\n${rec.accountName}`,
    },
    {
      title: tl(translations.report.sections.bankAccountsSection.columns.startBalance),
      dataIndex: 'normalizedBalanceBeforeDateRange',
      className: 'column-align-right no-wrap-td',
      width: AMOUNT_COLUMNS_WIDTH,
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.bankAccountsSection.columns.debit),
      dataIndex: 'debitAmountWithinDateRange',
      className: 'column-align-right no-wrap-td',
      width: AMOUNT_COLUMNS_WIDTH,
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.bankAccountsSection.columns.credit),
      dataIndex: 'creditAmountWithinDateRange',
      className: 'column-align-right no-wrap-td',
      width: AMOUNT_COLUMNS_WIDTH,
      render: (num: number) => <Amount>{formatCurrency((-1 * num), '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.bankAccountsSection.columns.balanceWithinPeriod),
      dataIndex: 'normalizedBalanceWithinDateRange',
      className: 'column-align-right no-wrap-td multiline-table-header',
      width: AMOUNT_COLUMNS_WIDTH,
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.bankAccountsSection.columns.endBalance),
      dataIndex: 'normalizedBalanceAtEndOfDateRange',
      className: 'column-align-right no-wrap-td',
      width: AMOUNT_COLUMNS_WIDTH,
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  return {
    columns,
    firstDataSource,
    firstStartBalanceSum: firstSums.startBalanceSum,
    firstDebitSum: firstSums.debitSum,
    firstCreditSum: firstSums.creditSum,
    firstBalanceWithinDateRangeSum: firstSums.balanceWithinDateRangeSum,
    firstEndBalanceSum: firstSums.endBalanceSum,
    secondDataSource,
  };
};
