import Icon from '@ant-design/icons';
import React from 'react';
import { ICONS } from '../../../../components/icons';
import './BooleanCell.scss';

export default function BooleanCell(value: boolean | undefined): JSX.Element {
  return (
    <div className="BooleanCell">
      {
        typeof value !== 'undefined'
        && <Icon component={value ? ICONS.checkMark : ICONS.close} />
      }
    </div>
  );
}
