import React, { useEffect, useState } from 'react';
import './InfiniteScroller.scss';
import useEvent from '../../lib/useEvent';

interface InfiniteScrollerProps {
  className?: string,
  hasMore: boolean,
  threshold: number,
  loadMore: () => void,
  contentElement?: Element
  loading: boolean
  children: any
}

export default function InfiniteScroller(props: InfiniteScrollerProps): JSX.Element {
  const [prevChildren, setPrevChildren] = useState(null);

  useEffect(() => {
    /**
     * calling setTimeout (with time 0ms) to put this function call to the end of the event loop;
     * otherwise this check would happen AFTER loading becomes false BUT BEFORE the content had time to render
     * so it was triggering a second loadMore() in some cases (bank transaction list)
     */
    setTimeout(() => {
      // fill the screen with data
      if (props.contentElement && props.contentElement.clientHeight > 0) {
        const maxScroll = props.contentElement.scrollHeight - props.contentElement.clientHeight;
        const height = props.contentElement.clientHeight;
        const { scrollHeight } = props.contentElement;

        if (maxScroll <= height && scrollHeight <= window.innerHeight && !props.loading && props.hasMore) {
          props.loadMore();
        }
      }
    }, 0);
  }, [props.contentElement]);

  useEvent('scroll', props.contentElement, (event) => {
    const maxScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentScroll = event.target.scrollTop;

    if ((currentScroll + props.threshold) >= maxScroll && props.hasMore && !props.loading && props.children !== prevChildren) {
      setPrevChildren(props.children);
      props.loadMore();
    }
  });


  return (
    <div className={`InfiniteScroller ${props.className}`}>
      {props.children}
    </div>
  );
}
