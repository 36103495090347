/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConnectionAuthRequestDto,
  ConnectionCreateDto,
  ConnectionDto,
  ConnectionLegacyDto,
  ConnectionStateUpdateDto,
  ConnectionUpdateLegacyDto,
  JWTToken,
  PageOfConnectionLegacyDto,
} from '../models';
import {
    ConnectionAuthRequestDtoFromJSON,
    ConnectionAuthRequestDtoToJSON,
    ConnectionCreateDtoFromJSON,
    ConnectionCreateDtoToJSON,
    ConnectionDtoFromJSON,
    ConnectionDtoToJSON,
    ConnectionLegacyDtoFromJSON,
    ConnectionLegacyDtoToJSON,
    ConnectionStateUpdateDtoFromJSON,
    ConnectionStateUpdateDtoToJSON,
    ConnectionUpdateLegacyDtoFromJSON,
    ConnectionUpdateLegacyDtoToJSON,
    JWTTokenFromJSON,
    JWTTokenToJSON,
    PageOfConnectionLegacyDtoFromJSON,
    PageOfConnectionLegacyDtoToJSON,
} from '../models';

export interface AuthenticateConnectionUsingPOSTRequest {
    connectionId: number;
    connectionAuthRequestDto?: ConnectionAuthRequestDto;
}

export interface CreateConnectionUsingPOSTRequest {
    dto?: ConnectionCreateDto;
}

export interface GetConnectionUsingGETRequest {
    id: number;
}

export interface GetConnectionsUsingGETRequest {
    appId?: number;
    domainId?: number;
    domainName?: string;
    externalConfig?: string;
    order?: GetConnectionsUsingGETOrderEnum;
    page?: number;
    size?: number;
    sort?: string;
    state?: GetConnectionsUsingGETStateEnum;
}

export interface UpdateConnectionStateUsingPOSTRequest {
    connectionId: number;
    connectionStateUpdateDto?: ConnectionStateUpdateDto;
}

export interface UpdateConnectionUsingPUTRequest {
    connectionId: number;
    updateDto?: ConnectionUpdateLegacyDto;
}

/**
 * 
 */
export class ConnectionsApi extends runtime.BaseAPI {

    /**
     * Authenticates an application
     */
    async authenticateConnectionUsingPOSTRaw(requestParameters: AuthenticateConnectionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JWTToken>> {
        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling authenticateConnectionUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/connections/{connectionId}/authenticate`.replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectionAuthRequestDtoToJSON(requestParameters.connectionAuthRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JWTTokenFromJSON(jsonValue));
    }

    /**
     * Authenticates an application
     */
    async authenticateConnectionUsingPOST(requestParameters: AuthenticateConnectionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JWTToken> {
        const response = await this.authenticateConnectionUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an application connection
     */
    async createConnectionUsingPOSTRaw(requestParameters: CreateConnectionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/connections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectionCreateDtoToJSON(requestParameters.dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionDtoFromJSON(jsonValue));
    }

    /**
     * Creates an application connection
     */
    async createConnectionUsingPOST(requestParameters: CreateConnectionUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectionDto> {
        const response = await this.createConnectionUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getConnection
     */
    async getConnectionUsingGETRaw(requestParameters: GetConnectionUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectionLegacyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConnectionUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/connections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionLegacyDtoFromJSON(jsonValue));
    }

    /**
     * getConnection
     */
    async getConnectionUsingGET(requestParameters: GetConnectionUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectionLegacyDto> {
        const response = await this.getConnectionUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a page of application connections
     */
    async getConnectionsUsingGETRaw(requestParameters: GetConnectionsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageOfConnectionLegacyDto>> {
        const queryParameters: any = {};

        if (requestParameters.appId !== undefined) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.domainId !== undefined) {
            queryParameters['domainId'] = requestParameters.domainId;
        }

        if (requestParameters.domainName !== undefined) {
            queryParameters['domainName'] = requestParameters.domainName;
        }

        if (requestParameters.externalConfig !== undefined) {
            queryParameters['externalConfig'] = requestParameters.externalConfig;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/connections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOfConnectionLegacyDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a page of application connections
     */
    async getConnectionsUsingGET(requestParameters: GetConnectionsUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageOfConnectionLegacyDto> {
        const response = await this.getConnectionsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a connection\'s state
     */
    async updateConnectionStateUsingPOSTRaw(requestParameters: UpdateConnectionStateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectionLegacyDto>> {
        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling updateConnectionStateUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/connections/{connectionId}/update-state`.replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectionStateUpdateDtoToJSON(requestParameters.connectionStateUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionLegacyDtoFromJSON(jsonValue));
    }

    /**
     * Updates a connection\'s state
     */
    async updateConnectionStateUsingPOST(requestParameters: UpdateConnectionStateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectionLegacyDto> {
        const response = await this.updateConnectionStateUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateConnection
     */
    async updateConnectionUsingPUTRaw(requestParameters: UpdateConnectionUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectionLegacyDto>> {
        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling updateConnectionUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/connections/{connectionId}`.replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectionUpdateLegacyDtoToJSON(requestParameters.updateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionLegacyDtoFromJSON(jsonValue));
    }

    /**
     * updateConnection
     */
    async updateConnectionUsingPUT(requestParameters: UpdateConnectionUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectionLegacyDto> {
        const response = await this.updateConnectionUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum GetConnectionsUsingGETOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
  * @export
  * @enum {string}
  */
export enum GetConnectionsUsingGETStateEnum {
    DISABLED = 'DISABLED',
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS'
}
