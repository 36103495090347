import { RouteDefinition } from 'components/RenderRoutes';
import PaymentList, { PaymentListWithProvider } from './PaymentList';
import InvoiceEditingRouter from '../Invoice/InvoiceEditing/InvoiceEditingRouter';
import PaymentView from '../PaymentView/PaymentView';

export const PAYMENT_LIST_URL = '/payments';

export const PaymentRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: `${PAYMENT_LIST_URL}`,
      component: PaymentListWithProvider,
      subRoutes: [
        {
          path: `${PAYMENT_LIST_URL}/new`,
          component: PaymentView,
          type: 'overlay',
          width: '60.0rem',
          exact: true,
        },
      ],
    },
    {
      path: `${PAYMENT_LIST_URL}/invoice/:invoiceHrId`,
      component: InvoiceEditingRouter,
      type: 'focus',
    },
    {
      path: `*/:propertyHrId${PAYMENT_LIST_URL}`,
      component: PaymentList,
      type: 'overlay',
      width: '90%',
    },
    {
      path: `*/:propertyHrId${PAYMENT_LIST_URL}/:invoiceHrId`,
      component: InvoiceEditingRouter,
      type: 'focus',
    },
  ],
};
