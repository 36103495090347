import { useContext } from 'react';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../../elements/Translation/translations';

export const useBankAccountTableColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.bankAccount.table.headers.accountType),
      dataKey: 'type',
      sortable: false,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.accountName),
      dataKey: 'name',
      sortable: false,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.accountIban),
      dataKey: 'iban',
      sortable: false,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.calculatedAccountBalance),
      dataKey: 'calculatedBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.actualAccountBalance),
      dataKey: 'balance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
  ];
};
