import React, { useContext } from 'react';
import { Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import { LanguageContext } from '../../contexts/LanguageContext';
import './PropertyInfo.scss';
import { translationsPropertyInfo } from './translationsPropertyInfo';

// They must all be optional, otherwise we'd run into ts-errors everywhere because our
// swagger-generated types (Dto's) mark almost everything as optional.
interface PropertyInfoProps {
  property?: {
    propertyIdInternal?: string;
    name?: string;
    supervisorName?: string;
  };
}


export default function PropertyInfo({ property }: PropertyInfoProps): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const { propertyIdInternal, name, supervisorName } = property ?? {};

  const text = [
    propertyIdInternal,
    name,
    supervisorName ? `${tl(translationsPropertyInfo.administrator)} ${supervisorName}` : undefined,
  ].filter(Boolean).join(' · ');


  return (
    <Skeleton
      className="PropertyInfo"
      loading={isEmpty(property)}
      active
      title={false}
      paragraph={{ rows: 1, width: '30rem' }}
    >
      {text}
    </Skeleton>
  );
}


PropertyInfo.defaultProps = {
  property: undefined,
};
