import React from 'react';
import './PercentageInput.scss';
import { Form } from 'antd';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import InputProps from '../InputProps';
import InputNumber from '../NumberInput/InputNumber';

interface PercentageInputProps extends InputProps<number> {
  controls?: boolean;
  precisions?:number,
}

export function PercentageInput(props: PercentageInputProps): JSX.Element {
  const {
    inputClassName,
    value,
    label,
    className,
    validationState,
    validationMessage,
    required,
    disabled,
    maxValue,
    infoText,
    controls,
    precisions,
  } = props;

  const onChange = (v: number | undefined) => {
    if (typeof v === 'undefined') props.onChange(Number.NaN);
    else props.onChange(v);
  };

  function onBlur(e) {
    if (props.onBlur) {
      props.onBlur(e);
    } else if (!value) {
      onChange(0);
    }
  }

  return (
    <div className={`PercentageInput ${className}`}>
      <Form.Item
        label={label ? (
          <span>
            <span>{`${label}${required ? ' *' : ''}`}</span>
            {infoText && <InfoIcon popupText={infoText} size="small" />}
          </span>
        ) : null}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <InputNumber
          className={`Input ${inputClassName}`}
          value={value}
          precision={precisions ?? 2}
          decimalSeparator=","
          step={0.01}
          min={0}
          max={maxValue || 100}
          onChange={props.onChange}
          disabled={disabled || false}
          onFocus={e => e.target.select()}
          onBlur={e => onBlur(e)}
          controls={controls}
          suffix="%"
        />
      </Form.Item>
    </div>
  );
}
