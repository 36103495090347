import { LanguageContext } from 'contexts/LanguageContext';
import AdditionalEntityButton from 'elements/Buttons/AdditionalEntityButton/AdditionalEntityButton';
import { translations } from 'elements/Translation/translations';
import React, { useContext } from 'react';
import { useAddBankAccountButton } from './useAddBankAccountButton';

export const AddBankAccountButton = () => {
  const { tl } = useContext(LanguageContext);
  const {
    onAddNewBankAccount,
  } = useAddBankAccountButton();

  return (
    <div className="row">
      <AdditionalEntityButton
        label={tl(translations.pages.contactEditing.contactSection.bankSection.fields.addButton)}
        onClick={onAddNewBankAccount}
      />
    </div>

  );
};
