import { ExtendedBankTransactionProperty } from 'pages/BankTransactions/interfaces';
import React from 'react';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import { useVatEligibilityCell } from './useVatEligibilityCell';
import VatEligibilityModal from './VatEligibilityModal/VatEligibilityModal';

interface Props {
  record: ServiceCompanyAllocationValues,
  properties: ExtendedBankTransactionProperty[],
}

const VatEligibilityCell = ({ record, properties }: Props) => {
  const {
    vatEligibilityAmounts, vatEligibilityPercentages, onClickCell, modalVisible, onCloseModal,
  } = useVatEligibilityCell(record, properties);


  return (
    <>
      <CellWithTwoLines
        firstElement={vatEligibilityPercentages}
        secondElement={<Amount>{vatEligibilityAmounts}</Amount>}
        onClickWrapperElement={onClickCell}
      />
      <VatEligibilityModal recordKey={record.key} visible={modalVisible} onClose={onCloseModal} />
    </>
  );
};

export default VatEligibilityCell;
