import React from 'react';
import './Link.scss';

interface LinkProps {
  className?: string,
  onClick: () => void,
  level: number,
  title: string,
  active?: boolean,
  showArrow?: boolean,
  onArrowClick?: () => void,
  open?: boolean,
  children?: any,
  disabled?: boolean,
}

export default function Link(props: LinkProps): JSX.Element {
  const {
    className: classNameProp, title, level, active, showArrow, onClick, onArrowClick, open, children, disabled,
  } = props;

  const className = `Link ${classNameProp} ${disabled ? 'LinkDisabled' : ''}`;

  return (
    <div className={className}>
      <div
        className={`link-row level${level} ${active ? 'active' : ''} ${!showArrow ? 'arrow-placeholder' : ''}`}
      >
        {!!showArrow && (
          <div
            className={`arrow-wrapper ${open ? 'open' : ''}`}
            onClick={onArrowClick}
            role="button"
            tabIndex={-1}
          >
            <span className="arrow">&#x25b6;</span>
          </div>
        )}
        <div
          className="link-title"
          onClick={onClick}
          role="button"
          tabIndex={-1}
        >
          {title}
        </div>
      </div>
      {children}
    </div>
  );
}
