import React, { useContext, useEffect, useState } from 'react';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'components/Header/translations';
import useEvent from '../../../../lib/useEvent';
import './QuickAddMenu.scss';

export const QuickAddMenu: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  useEffect(() => {
    if (open) {
      setSelectedIndex(0);
    }
  }, [open]);

  useEvent('click', document, () => {
    if (open) {
      setOpen(false);
    }
  });

  useEvent('keydown', document, (e) => {
    if (open) {
      switch (e.keyCode) {
      case 27: // if ESC pressed
        setOpen(false);
        break;
      case 38: // up arrow
        handleUpArrowPressed();
        break;
      case 40: // down arrow
        handleDownArrowPressed();
        break;
      case 13: // enter
        navigateAway(e, buttonOptions[selectedIndex].url);
        break;
      default:
        if (e.key >= 1 && e.key <= buttonOptions.length) {
          navigateAway(e, buttonOptions[e.key - 1].url);
        }
      }
    }
  });

  const handleUpArrowPressed = () => {
    setSelectedIndex((currentSelectedIndex) => {
      if (currentSelectedIndex !== 0) {
        return currentSelectedIndex - 1;
      }
      return buttonOptions.length - 1;
    });
  };

  const handleDownArrowPressed = () => {
    setSelectedIndex((currentSelectedIndex) => {
      if (currentSelectedIndex !== (buttonOptions.length - 1)) {
        return currentSelectedIndex + 1;
      }
      return 0;
    });
  };

  const toggleOpen: () => void = () => {
    setOpen(prevValue => !prevValue);
  };

  const navigateAway: (e: React.SyntheticEvent, url: string) => void = (e, url) => {
    e.preventDefault();
    toggleOpen();
    history.push(url);
  };

  return (
    <div className="QuickAddMenu">
      <button
        className={`add-button ${open ? 'open' : ''}`}
        onClick={toggleOpen}
      >
        <Icon className="add-icon" component={ICONS.plusNew} />
      </button>
      <div className={`dark-background ${open ? 'open' : ''}`}>
        <dialog className="menu-items-container">
          <div className="header">
            {tl(translations.quickAddMenu.create)}
            <span className="shortcut-hint">↑↓ to browse  ·  ENTER to open</span>
          </div>
          {buttonOptions
            .filter(buttonOptions => !buttonOptions.hidden)
            .map((buttonOption, index) => (
              <a
                key={buttonOption.key}
                className={`menu-item ${selectedIndex === index ? 'selected' : ''}`}
                href={buttonOption.url}
                onClick={e => navigateAway(e, buttonOption.url)}
              >
                <Icon component={buttonOption.icon} />
                {tl(translations.quickAddMenu.menuOptions[buttonOption.key])}
                <span className="shortcut-hint">{index + 1}</span>
              </a>
            ))}
        </dialog>
      </div>
    </div>
  );
};


interface AddButtonOptions {
  key: string,
  url: string,
  icon: React.FC,
  hidden?: boolean,
}

const buttonOptions: AddButtonOptions[] = [
  {
    key: 'property',
    url: '/properties/create',
    icon: ICONS.property,
  },
  {
    key: 'invoiceUpload',
    url: '/invoice/overview?upload=true',
    icon: ICONS.invoiceUpload,
  },
  {
    key: 'invoiceManual',
    url: '/invoice/create',
    icon: ICONS.invoiceUploadManual,
  },
  {
    key: 'ownersMeeting',
    url: '/owners-meeting/invitation/create',
    icon: ICONS.meetings,
  },
  {
    key: 'specialContribution',
    url: '/special-contribution/create',
    icon: ICONS.specialContribution,
  },
  {
    key: 'contactCompany',
    url: '/companies/create-company',
    icon: ICONS.company,
  },
  {
    key: 'contactPerson',
    url: '/persons/create-person',
    icon: ICONS.contacts,
  },
];
