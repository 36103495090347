import { useCurrentOverlayInfo } from 'components/OverlayRoute/services/useCurrentOverlayInfo';
import { useQuery } from 'lib/useQuery';
import React, { createContext, useState } from 'react';

interface Props {
  children: React.ReactNode;
  initialOpenSectionId?: number;
}

interface SectionManagerValue {
  openSectionId: number | undefined;
  setOpenSectionId: React.Dispatch<React.SetStateAction<number>>,
}

export const SectionManagerContext = createContext<SectionManagerValue | undefined>(undefined);

export const SectionManagerProvider = ({ children, initialOpenSectionId }: Props) => {
  const { isOverlayOnTop } = useCurrentOverlayInfo();
  const query = useQuery();
  const openSectionIdFromQuery = query.get('openSectionId');
  const parsedOpenSectionIdFromQuery = openSectionIdFromQuery ? Number(openSectionIdFromQuery) : undefined;

  /**
   * Only initialize the state with teh value from the query params if the overlay is on top,
   * otherwise, if you have multiple overlays open and you refresh, then it'll affect the
   * overlays 'below' the topmost one.
   */
  const [openSectionId, setOpenSectionId] = useState<number | undefined>(
    isOverlayOnTop
      ? parsedOpenSectionIdFromQuery ?? initialOpenSectionId
      : initialOpenSectionId,
  );


  const providerValue = {
    openSectionId,
    setOpenSectionId,
  } as SectionManagerValue;

  return (
    <SectionManagerContext.Provider value={providerValue}>
      {children}
    </SectionManagerContext.Provider>
  );
};


SectionManagerProvider.defaultProps = {
  initialOpenSectionId: undefined,
};
