import { useContext } from 'react';
import { nanoid } from 'nanoid';
import {
  AccountSelectionContext, DEFAULT_OCCURRENCE_VALUES, OccurrenceValues, OccurrenceValuesContext,
} from './ManualExchangeContext';

export const useOccurrences = () => {
  const occurrenceContext = useContext(OccurrenceValuesContext);
  const selectionContext = useContext(AccountSelectionContext);

  if (occurrenceContext === undefined || selectionContext === undefined) {
    throw new Error('useOccurrences must be used within a ManualExchangeContextProvider');
  }

  const { occurrenceValues, setOccurrenceValues } = occurrenceContext;
  const { formValues } = selectionContext;


  const deleteOccurrence = (id: string) => setOccurrenceValues(occurrences => occurrences.filter(occ => occ.id !== id));

  const addOccurrence = () => setOccurrenceValues(occurrences => occurrences.concat({ ...DEFAULT_OCCURRENCE_VALUES[0], id: nanoid() }));

  const onChange = (id: string, key: keyof OccurrenceValues, value: OccurrenceValues[keyof OccurrenceValues]) => {
    setOccurrenceValues(values => values.map(occValue => (occValue.id === id ? { ...occValue, [key]: value } : occValue)));
  };


  return {
    deleteOccurrence, addOccurrence, onChange, formValues, occurrenceValues,
  };
};
