import ErrorMessage from 'storybook-components/feedback/ErrorScreen/ErrorMessages/ErrorMessage';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import ContractSelector from 'storybook-components/inputs/select/ContractSelector/ContractSelector';
import Page from 'storybook-components/layout/Page/Page';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import OpsCostReportPreviewContextProvider from './services/OpsCostReportPreviewContext';
import { useOpsCostReportPreview } from './services/useOpsCostReportPreview';
import { useInitOpsCostReportPreview } from './services/useInitOpsCostReportPreview';
import OpsCostReportPreviewHeader from './components/OpsCostReportPreviwHeader/OpsCostReportPreviewHeader';
import './OpsCostReportPreview.scss';
import { opsCostTranslation } from '../translations';


const OpsCostReportPreview = (): JSX.Element => {
  const { tl } = useContext(LanguageContext);

  const {
    selectedContractId,
    selectedPdfBlob,
    onSelectTenant,
    onClose,
    selectedPdfName,
    documentInCreation,
    isDocumentInFailedToGenerate,
    propertyId,
    objectOfContractIds,
  } = useOpsCostReportPreview();


  useInitOpsCostReportPreview(onClose, onSelectTenant);

  if (isDocumentInFailedToGenerate) {
    return (
      <Page className="OpsCostReportPreview">
        <OpsCostReportPreviewHeader onClose={onClose} />
        <ErrorMessage message={(
          <AlertArticleLink
            label={tl(opsCostTranslation.opsCostReportPreview.generationErrorMessage)}
            content={tl(opsCostTranslation.opsCostReportPreview.contactSupport)}
            url={articleUrls.contactSupport}
          />
        )
        }
        />
      </Page>
    );
  }

  if (documentInCreation) {
    return (
      <LoadingScreen
        title={tl(opsCostTranslation.opsCostReportPreview.generatingPreviewLoadingMessage.title)}
        subtitle={tl(opsCostTranslation.opsCostReportPreview.generatingPreviewLoadingMessage.subtitle)}
      />
    );
  }

  return (
    <Page className="OpsCostReportPreview">
      <OpsCostReportPreviewHeader onClose={onClose} />
      <SimplePdfViewer
        filename={selectedPdfName}
        base64Url={selectedPdfBlob}
      />
      <ContractSelector
        value={selectedContractId}
        onSelect={onSelectTenant}
        contractType="TENANT"
        showVacant
        propertyId={propertyId}
        contractFilters={objectOfContractIds}
      />
    </Page>
  );
};

const OpsCostReportPreviewWithProvider = () => (
  <OpsCostReportPreviewContextProvider>
    <OpsCostReportPreview />
  </OpsCostReportPreviewContextProvider>
);

export default OpsCostReportPreviewWithProvider;
