import { Form, Checkbox } from 'antd';
import React from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import './CheckboxGroupInput.scss';
import InputProps from '../InputProps';

interface checkboxGroup extends InputProps<CheckboxValueType[]> {
  onChange: (checkedValues: CheckboxValueType[] | null) => void
  options: {
    value: CheckboxValueType,
    label: string,
  }[]
}

export default function CheckboxGroupInput(props: checkboxGroup) {
  const {
    className, label, required, disabled, options, onChange, validationState, validationMessage, value,
  } = props;
  return (
    <div className={`CheckboxGroupInput ${className}`}>
      <Form.Item
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <Checkbox.Group
          className={`${className}`}
          onChange={checkedValues => onChange(checkedValues)}
          value={value}
          disabled={disabled || false}
          options={options}
        />
      </Form.Item>
    </div>
  );
}

CheckboxGroupInput.defaultProps = {
  className: '',
  inputClassName: '',
  disabled: false,
  required: false,
};
