import { useContext } from 'react';
import { SelectInputValuesStateContext, ServiceCompanyAllocationUpdatersContext } from '../../../../services/ServiceCompanyAllocationContext';


const useCounterpartCell = (key: string) => {
  const selectInputValues = useContext(SelectInputValuesStateContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);

  if (updatersContext === undefined) {
    throw new Error('useCounterpartCell must be used within a ServiceCompanyAllocationContextProvider!');
  }

  const { setSelectInputValues } = updatersContext;

  const currentValue = selectInputValues?.find(alloc => alloc.key === key)?.counterpartContactId;

  const onChange = (value: number) => {
    setSelectInputValues(prev => prev.map(selectInput => (
      selectInput.key === key
        ? { ...selectInput, counterpartContactId: value }
        : selectInput
    )));
  };

  return { currentValue, onChange };
};

export default useCounterpartCell;
