import { useContext } from 'react';
import { HouseMoneySectionUpdatersContext, ReserveFundAmountContext } from '../../../../services/HouseMoneyContext';
import { ActionTypes } from '../../services/ActionTypes';
import { useSimpleDirtModal } from '../../../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';


export const useReserveFundCell = (rowIndex: number, columnIndex: number) => {
  const reserveFundAmountContext = useContext(ReserveFundAmountContext);
  const updatersContext = useContext(HouseMoneySectionUpdatersContext);

  if (reserveFundAmountContext === undefined || updatersContext === undefined) {
    throw new Error('useReserveFundCell must be used within a HouseMoneyListContextProvider');
  }

  const { addDirt } = useSimpleDirtModal();
  const { setReserveFundAmounts } = updatersContext;
  const { reserveFundAmounts } = reserveFundAmountContext;
  const value = reserveFundAmounts?.[rowIndex]?.[columnIndex];
  const onChange = (newVal: number) => setReserveFundAmounts(prevRows => prevRows.map((row, prevRowIndex) => {
    if (prevRowIndex === rowIndex) {
      return row.map((col, prevColIndex) => {
        if (prevColIndex === columnIndex) {
          return {
            ...col,
            amount: newVal,
            action: !col.action ? ActionTypes.UPDATE : col.action,
          };
        }
        return col;
      });
    }
    addDirt();
    return row;
  }));

  return {
    value: value?.amount ?? 0,
    onChange,
  };
};
