import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useEffect, useMemo } from 'react';
import { showNotification } from 'lib/Notification';
import { useHistory, useParams } from 'react-router';
import { translations } from 'pages/ProfitAndLossReport/translations';
import {
  Configuration, DocumentLegacyControllerApi, DocumentProjectionDto, DocumentProjectionDtoSourceTypeEnum, DocumentProjectionDtoStateEnum,
} from 'api/document';
import usePoll from 'services/usePoll';
import { ProfitAndLossReportViewContext } from './ProfitAndLossReportViewContext';

export const useInitializeProfitAndLossReportView = () => {
  const { documentDbId } = useParams<{ documentDbId: string }>();
  const profitAndLossReportViewContext = useContext(ProfitAndLossReportViewContext);

  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  if (profitAndLossReportViewContext === undefined) {
    throw new Error('useInitializeProfitAndLossReportView must be used within a ProfitAndLossReportViewContextProvider');
  }

  const { setProfitAndLossReport, profitAndLossReport } = profitAndLossReportViewContext;

  const { apiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as Configuration);


  useEffect(() => {
    if (documentDbId !== undefined) {
      initialize(parseInt(documentDbId, 10));
    }
  }, [documentDbId]);

  const isGenerating = useMemo(() => profitAndLossReport?.data?.state === DocumentProjectionDtoStateEnum.GENERATING,
    [profitAndLossReport?.data]);

  const generationFailed = useMemo(() => profitAndLossReport?.data?.state === DocumentProjectionDtoStateEnum.FAILED,
    [profitAndLossReport?.data]);


  const initialize = (documentId: number) => {
    setProfitAndLossReport(prev => prev.startLoading());
    documentControllerApi.getDocumentByIdUsingGET({ documentId })
      .then((report: DocumentProjectionDto) => {
        if (report.sourceType !== DocumentProjectionDtoSourceTypeEnum.PROFIT_AND_LOSS) {
          setProfitAndLossReport(prev => prev.failed());
          showNotification({
            type: 'error',
            message: tl(translations.notifications.notFoundError),
          });
          history.push('/profit-and-loss');
        } else {
          setProfitAndLossReport(prev => prev.load(report));
        }
      })
      .catch((e) => {
        setProfitAndLossReport(prev => prev.failed(e));
        console.error(e);
        if (e.response?.status === 404) {
          showNotification({
            type: 'error',
            message: tl(translations.notifications.notFoundError),
          });
        } else {
          showNotification({
            type: 'error',
            message: tl(translations.notifications.loadViewError),
          });
        }
        history.push('/profit-and-loss');
      });
  };

  usePoll({
    shouldPoll: isGenerating,
    pollFunction: () => initialize(parseInt(documentDbId, 10)),
    timeoutSeconds: 5000,
  });

  return {
    isGenerating,
    generationFailed,
  };
};
