import React, {
  MutableRefObject, useContext, useEffect, useState,
} from 'react';
import Icon from '@ant-design/icons';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { ICONS } from 'components/icons';
import _ from 'lodash';
import { translations } from '../../../translations';
import './EditPostingTextModal.scss';
import { ExtendedBankTransaction, PostingText } from '../../../interfaces';
import { formatDate } from '../../../../../lib/Utils';
import TextAreaInput from '../../../../../elements/Inputs/TextAreaInput/TextAreaInput';


interface CommonProps {
  defaultTexts: PostingText[],
  onSubmit: (values: PostingText[]) => void,
  disabled?: boolean,
  bankTransactionsRef: MutableRefObject<ExtendedBankTransaction[]>,
}


type ConditionalProps =
    | {
  type: 'unit',
  unitNr: number,
  ownerName: string,
  accountCode: string,
  accountName: string,
  creditor?: never,
  invoiceId?: never,
} | {
  type: 'manual',
  unitNr?: never,
  ownerName?: never,
  accountCode: string,
  accountName: string,
  creditor?: never,
  invoiceId?: never,
} | {
  type: 'invoice',
  unitNr?: never,
  ownerName?: never,
  accountCode: string,
  accountName: string,
  creditor: string,
  invoiceId: string,
}


type EditPostingTextModalProps = CommonProps & ConditionalProps;

/**
 * @deprecated
 * too much custom logic, for exchanges another EditPostingTextModal was created for manual allocation
 */
const EditPostingTextModal = (props: EditPostingTextModalProps) => {
  const {
    unitNr, ownerName, defaultTexts, accountCode, accountName, onSubmit, disabled, type, creditor, invoiceId, bankTransactionsRef,
  } = props;
  const { tl } = useContext(LanguageContext);

  const [values, setValues] = useState<PostingText[]>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setValues(defaultTexts);
  }, [defaultTexts]);
  let title;
  let subtitle;

  useEffect(() => () => {
    setValues([]);
  }, []);
  switch (type) {
  case 'unit':
    title = `${unitNr} - ${ownerName}`;
    subtitle = [accountCode, accountName].filter(Boolean).join(' · ');
    break;
  case 'manual':
    title = accountName;
    subtitle = accountCode;
    break;
  case 'invoice':
    title = invoiceId;
    subtitle = [creditor, accountCode, accountName].filter(Boolean).join(' · ');
    break;
  default:
    title = '';
    subtitle = '';
  }

  const onChange = (bankTransactionId: number, value: string) => {
    setValues((oldList) => {
      const newList = oldList.slice();
      const idx = _.findIndex(newList, t => t.bankTransactionId === bankTransactionId);
      if (idx !== -1) {
        newList[idx].postingText = value;
      } else {
        newList.push({
          bankTransactionId,
          postingText: value,
        });
      }
      return newList;
    });
  };

  return (
    <>
      <Button disabled={disabled} size="large" onClick={() => setVisible(true)}>
        <Icon className={`${disabled ? 'icon-disabled' : ''}`} component={ICONS.bookingText} />
      </Button>
      <ModalWithSubtitle
        className="edit-posting-text-modal"
        title={title}
        subtitle={subtitle}
        visible={visible}
        onCancel={() => { setValues(defaultTexts); setVisible(false); }}
        footer={type !== 'invoice' ? (
          <Button
            type="primary"
            onClick={() => { setVisible(false); onSubmit(values); }}
          >
            {tl(translations.bankTransactionAllocation.modal.save)}
          </Button>
        ) : null}
      >
        {
          bankTransactionsRef.current.map((transaction, idx) => (
            <TextAreaInput
              key={transaction.bankTransactionId}
              className="booking-text-input"
              label={`${tl(translations.bankTransactionAllocation.modal.bookingText)} ${formatDate(transaction.bankBookingDate!)}`}
              value={values.filter(v => v.bankTransactionId === transaction.bankTransactionId)[0]?.postingText || ''}
              onChange={v => onChange(transaction.bankTransactionId!, v)}
              readOnly={type === 'invoice'}
              autoFocus={type !== 'invoice' && idx === 0} // focus first input
              onFocus={e => e.target.select()}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  setVisible(false);
                  onSubmit(values);
                }
              }}
            />
          ))
        }
      </ModalWithSubtitle>
    </>
  );
};


EditPostingTextModal.defaultProps = {
  disabled: false,
};


export default EditPostingTextModal;
