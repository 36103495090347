import {
  Alert,
  Progress,
} from 'antd';
import Heading from 'storybook-components/typography/Heading/Heading';

export interface ProgressProps {
    label: string,
    percentage: number,
}

export interface ProgressBarProps {
    title: string,
    description: string,
    data: ProgressProps[],
}

export const ProgressBar = ({ data, description, title }: ProgressBarProps) => (
  <>
    <Alert message={title} description={description} />
    {data.map((p:ProgressProps) => (
      <>
        <Heading level={2}>{p.label}</Heading>
        <Progress percent={p.percentage} />
      </>
    ))}
  </>
);

export default ProgressBar;
