import { useState } from 'react';

export const useAnnouncementAlert = (identifier: string) => {
  const alertCache = JSON.parse(localStorage.getItem('AnnouncementAlert') ?? '{}');
  const initialVisible = identifier !== undefined
    && identifier !== null
    && identifier !== ''
    && !alertCache[identifier]?.dismissed;

  // state needed because changing localStorage won't trigger a rerender
  const [visible, setVisible] = useState(initialVisible);

  const onDismiss = () => {
    setVisible(false);

    localStorage.setItem('AnnouncementAlert', JSON.stringify({
      ...alertCache,
      [identifier]: { dismissed: true },
    }));
  };

  return {
    visible,
    onDismiss,
  };
};
