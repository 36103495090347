import { useContext } from 'react';
import { AgendaItemsContext } from '../../../../../../contexts/AgendaItemsContext';
import { ownersMeetingInvitationTranslations } from '../../../translations/OwnersMeetingInvitationTranslations';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import { AgendaItemsControllerApi } from '../../../../../../api/accounting';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { showNotification } from '../../../../../../lib/Notification';
import { useLoadAgendaItems } from './useLoadAgendaItems';

export const useDeleteActionOnAgendaItemsList = (agendaItemId: number) => {
  const { tl } = useContext(LanguageContext);

  const { apiConfiguration } = useContext(AuthContext);
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));
  const agendaTopicContext = useContext(AgendaItemsContext);
  if (agendaTopicContext === undefined) {
    throw new Error('useAgendaTopicsList must be used within a AgendaItemsContextProvider');
  }
  const { loadAgendaItemsList } = useLoadAgendaItems();

  const onDeleteAgendaItem = () => {
    agendaItemsControllerApi.deleteAgendaItemUsingDELETE({ agendaItemId })
      .then(() => {
        loadAgendaItemsList(true);
        showNotification({
          key: 'deleteAgendaItemSuccess',
          message: tl(ownersMeetingInvitationTranslations.agendaItemDetails.notifications.deleteSuccess),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'deleteAgendaItemError',
          message: tl(ownersMeetingInvitationTranslations.agendaItemDetails.notifications.deleteError),
          type: 'error',
        });
      });
  };

  return {
    onDeleteAgendaItem,
  };
};
