import { useContext } from 'react';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router';
import { OPS_COST_REPORTS_PATH } from 'pages/OpsCostReport/routes';
import { OpsCostReportProjectionStatusEnum } from 'api/accounting';
import { OpsCostReportFilter, OpsCostReportListContext } from './OpsCostReportListContext';
import { opsCostTranslation } from '../../translations';
import { PropertyMVAndSEVAdministrationTypes } from '../interfaces';

export const useOpsCostReportListFilter = () => {
  const opsCostReportListContext = useContext(OpsCostReportListContext);
  const { tl } = useContext(LanguageContext);
  const { location } = useHistory();
  if (opsCostReportListContext === undefined) {
    throw new Error('useOpsCostReportListFilter must be used within a OpsCostReportListContextProvider');
  }

  const { filterState, setFilterState } = opsCostReportListContext;

  const onChangeFilterState = (key: keyof OpsCostReportFilter, value: OpsCostReportFilter[keyof OpsCostReportFilter]) => {
    setFilterState((prev) => {
      if (value === null) {
        return ({
          ...prev,
          [key]: undefined,
        });
      }
      return ({
        ...prev,
        [key]: value,
      });
    });
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    if (location.pathname === OPS_COST_REPORTS_PATH) {
      setFilterState(prev => ({
        ...prev,
        ...searchParams,
      }));
    }
  };

  const filters: Filter[] = [
    {
      type: 'text',
      name: tl(opsCostTranslation.tableHeader.title),
      key: 'name',
    },
    {
      type: 'text',
      name: tl(opsCostTranslation.tableHeader.property),
      key: 'property',
    },
    {
      type: 'text',
      name: tl(opsCostTranslation.tableHeader.creationBy),
      key: 'createdBy',
    },
    {
      type: 'date',
      name: tl(opsCostTranslation.tableHeader.minCreated),
      key: 'fromCreated',
    },
    {
      type: 'date',
      name: tl(opsCostTranslation.tableHeader.maxCreated),
      key: 'untilCreated',
    },
    {
      type: 'date',
      name: tl(opsCostTranslation.tableHeader.startDate),
      key: 'startDate',
    },
    {
      type: 'date',
      name: tl(opsCostTranslation.tableHeader.endDate),
      key: 'endDate',
    },
    {
      type: 'text',
      name: tl(opsCostTranslation.tableHeader.administrator),
      key: 'administrator',
    },
    {
      type: 'text',
      name: tl(opsCostTranslation.tableHeader.accountant),
      key: 'accountant',
    },
    {
      type: 'enum',
      name: tl(opsCostTranslation.tableHeader.status),
      key: 'statuses',
      options: Object.values(OpsCostReportProjectionStatusEnum).map(value => ({
        label: tl(opsCostTranslation.statuses[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'enum',
      name: `${tl(opsCostTranslation.tableHeader.propertyAdministrationTypes)}`,
      key: 'propertyAdministrationTypes',
      options:

      Object.keys(PropertyMVAndSEVAdministrationTypes).map(value => ({
        label: tl(opsCostTranslation.administrationTypes[value]),
        value,
      })),
      multiValue: true,
    },
  ];

  return {
    filters,
    onSetDefaultFilterFromQueryParams,
    filterState,
    onChangeFilterState,
  };
};
