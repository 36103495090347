import './PdfPageAssignerActionBar.scss';

import React, {
  useContext,
  useMemo,
  useRef,
} from 'react';

import { Select } from 'antd';
import LegacyActionBar from 'components/LegacyActionBar/LegacyActionBar';
import { LanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';

import { translations } from '../../translations';
import { DropdownHeader } from './components/DropdownHeader';
import {
  EmptyContractDropdownComponent,
} from './components/EmptyContractsDropdownComponent';
import { useControlHoverStates } from './services/useControlHoverStates';
import {
  useControlScrollbarVisibility,
} from './services/useControlScrollbarVisibility';
import {
  usePdfPageAssignerActionBar,
} from './services/usePdfPageAssignerActionBar';

export interface SelectOption {
  value?: any,
  label: string,
  customBody?: JSX.Element,
  disabled?: boolean,
  title?: string,
  options?: SelectOption[]
}
const PdfPageAssignerActionBar = () => {
  const { tl } = useContext(LanguageContext);

  const optionsDropdownWrapperRef = useRef(null);

  const {
    loading,
    optionList,
    filterOption,
    addressConcatenation,
    onSelectUnitOrContract,
    onUnselectAll,
    selectedPages,
    onSelectProperty,
    dropdownOpen,
    onDropdownVisibleChange,
  } = usePdfPageAssignerActionBar();

  const { selectComponentClassName } = useControlScrollbarVisibility(optionsDropdownWrapperRef, dropdownOpen);

  const {
    onMouseLeavePropertyOption,
    onMouseEnterUnitOptions,
    lastHoverOnProperty,
  } = useControlHoverStates();

  const optionElements = useMemo(() => {
    if (isEmpty(optionList)) {
      return [];
    }

    return optionList.map(option => (
      <Select.Option
        key={option.value ?? option?.label}
        value={option.value}
        title={option.label}
        // extra details are needed in the onChange in case there's a unit with missing contract;
        // lowercase to avoid console warning
        optionobject={option}
        onMouseEnter={onMouseEnterUnitOptions}
      >
        {option.customBody ? option.customBody : <div className="select-menu-item">{option.label}</div>}
      </Select.Option>
    ));
  }, [optionList]);

  const getPopupContainer = () => optionsDropdownWrapperRef.current ?? document.body;

  const wrapperClassname = `PdfPageAssignerActionBar ${(selectedPages?.length ?? 0) === 0 ? 'fadeOut' : 'fadeIn'}`;


  return (
    <div className={wrapperClassname}>
      <LegacyActionBar
        actions={[
          {
            label: '',
            onAction: () => { },
            element: (
              <div className={selectComponentClassName} ref={optionsDropdownWrapperRef}>
                <Select
                  className="action-selector"
                  onChange={onSelectUnitOrContract}
                  disabled={loading}
                  value={null}
                  // open
                  open={dropdownOpen}
                  onDropdownVisibleChange={onDropdownVisibleChange}
                  filterOption={filterOption}
                  notFoundContent={<EmptyContractDropdownComponent noData={optionList.length === 0} />}
                  size="large"
                  dropdownRender={menu => (
                    <>
                      <DropdownHeader
                        addressConcatenation={addressConcatenation}
                        onSelectProperty={onSelectProperty}
                        onMouseLeavePropertyOption={onMouseLeavePropertyOption}
                        lastHoverOnPropertyOption={lastHoverOnProperty}
                      />
                      {menu}
                    </>
                  )}
                  showSearch
                  getPopupContainer={getPopupContainer}
                  dropdownClassName={`contract-selector-dropdown ${lastHoverOnProperty ? 'property-option-active' : ''}`}
                  placeholder={tl(translations.actionBar.searchPlaceHolder)}
                >
                  {optionElements}
                </Select>
              </div>
            ),
          },
        ]}
        onUnselectAll={onUnselectAll}
        records={selectedPages || []}
      />
    </div>
  );
};

export default PdfPageAssignerActionBar;
