import { DunningProjectionStateEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { useContext } from 'react';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { DunningProjectionFE } from '../../services/DunningListContext';

const mapSTateToColor = (state: DunningProjectionStateEnum) => {
  switch (state) {
  case DunningProjectionStateEnum.DRAFT: return DotColorEnum.GRAY;
  case DunningProjectionStateEnum.BOOKED: return DotColorEnum.GREEN;
  case DunningProjectionStateEnum.SENDING: return DotColorEnum.BLUE;
  case DunningProjectionStateEnum.SENT: return DotColorEnum.GREEN;
  case DunningProjectionStateEnum.FAILED: return DotColorEnum.RED;
  default: return DotColorEnum.GRAY;
  }
};

export const DunningStateCell = ({ record } : { record: DunningProjectionFE }) => {
  const { tl } = useContext(LanguageContext);

  return (
    <ColorDot color={mapSTateToColor(record.state)}>
      <span>{tl(orderOpenBalancesTranslations.dunningList.tableColumns.state[record.state])}</span>
    </ColorDot>
  );
};
