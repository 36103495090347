import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import SmartTable from '../../../../../../../elements/SmartTable/SmartTable';
import useSmartTable from '../../../../../../../elements/SmartTable/useSmartTable';
import { useUnitAccountBalancesColumns } from '../useUnitAccountBalancesColumns';
import SummaryRow from '../../../../../../../elements/CustomElements/ExpandableCard/SummaryRow';
import { translations } from '../../../../../../../elements/Translation/translations';
import { formatCurrency } from '../../../../../../../lib/Utils';
import './UnitIncomeCard.scss';

interface incomeCard {
  incomes: any[],
  labelsPrefix: string,
  loading: boolean,
  summaryTitle?: string
}

export function useRenderUnitIncomeTotal(props: incomeCard) {
  const { tl } = useContext(LanguageContext);
  const [totalCosts, setTotalCosts] = useState(0);
  const [unitCosts, setUnitCosts] = useState(0);
  const { labelsPrefix, incomes, summaryTitle } = props;

  useEffect(() => {
    if (incomes) {
      setTotalCosts(incomes.reduce((aggregator: any, income: any) => aggregator + (income.newTotalAmount || 0), 0));
      setUnitCosts(incomes.reduce((aggregator: any, income: any) => aggregator + (income.newUnitAmount || 0), 0));
    }
  }, [incomes]);

  const summaryElements = [{
    title: tl(translations.pages[labelsPrefix].unitAccountBalances.income.summary.totalCosts),
    value: <Amount>{formatCurrency(totalCosts)}</Amount>,
  },
  {
    title: tl(translations.pages[labelsPrefix].unitAccountBalances.expenses.summary.yourShare),
    value: <Amount>{formatCurrency(unitCosts)}</Amount>,
  }];
  return (
    <SummaryRow
      rightSideElements={summaryElements}
      title={summaryTitle && summaryTitle}
    />
  );
}


export default function UnitIncomeCard(props: incomeCard) {
  const { incomes, loading, labelsPrefix } = props;
  const isEconomicPlan: boolean = useMemo<boolean>(() => labelsPrefix.indexOf('economicPlan') !== -1, [labelsPrefix]);

  const smartTable = useSmartTable({
    tableName: 'unitAccountIncome',
    columns: useUnitAccountBalancesColumns(true, isEconomicPlan),
    dataSource: incomes,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'account',
  });

  return (
    <div className="UnitIncomeCard">
      <SmartTable {...smartTable} />
    </div>
  );
}
