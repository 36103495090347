import React, {
  useContext,
  useMemo,
} from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import MenuButton
  from 'pages/BankTransactions/BankTransactionAllocation/components/MenuButton';
import {
  useAllocationTypeSelector,
} from 'pages/BankTransactions/BankTransactionAllocation/services/AllocationTypeSelectorContext';
import {
  UpdateListElementFunction,
} from 'pages/BankTransactions/BankTransactionAllocation/services/useTransactionAllocation';
import {
  AccountAllocation,
  ExtendedBankTransaction,
} from 'pages/BankTransactions/interfaces';

import { translations } from '../../../../translations';
import {
  useSearchExchangeList,
} from '../../ServiceCompanyAllocation/services/useSearchExchangeList';
import AllocationSearchBar from './AllocationSearchBar/AllocationSearchBar';

export type OnSubmitType = (
    triggeredByTabChange: boolean,
    updateListElement: UpdateListElementFunction,
    callback?: () => void,
) => void;


interface Props {
  selectedBankTransactions: ExtendedBankTransaction[],
  bankTransactionsInGroup: ExtendedBankTransaction[],
  resetTransaction: () => void;
  markTransactionNotBookable: () => void;
  markTransactionDuplicate: () => void;
  propertyHrIds: string[],
  unitAllocations: AccountAllocation[],
  updateListElement: UpdateListElementFunction,
  useControls: (propertyHrIds: string[], selectedTransactions?: ExtendedBankTransaction[], bankTransactionsInGroup?: ExtendedBankTransaction[], unitAllocations?: AccountAllocation[]) => { onSubmit: OnSubmitType, isDirty: boolean, loading: boolean, isValid?: boolean };
}


// TODO when refactoring the other two tabs (invoice, unit)
// then take resetTransaction and markTransactionNotBookable from a context


const BankTransactionAllocationControlsRow = ({
  selectedBankTransactions,
  bankTransactionsInGroup,
  resetTransaction,
  markTransactionNotBookable,
  markTransactionDuplicate,
  propertyHrIds,
  unitAllocations,
  updateListElement,
  useControls,
}: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    onSubmit, isDirty, isValid, loading,
  } = useControls(propertyHrIds, selectedBankTransactions, bankTransactionsInGroup, unitAllocations);

  const { onSearch } = useSearchExchangeList();

  const propertyAdministrationTypes = useMemo(() => selectedBankTransactions[0]?.propertyList?.map(p => p.propertyAdministrationType) || [], [selectedBankTransactions[0]?.propertyList]);
  const { selectedType, setSelectedType, types } = useAllocationTypeSelector(propertyAdministrationTypes);

  const disabled = !(isDirty && (isValid ?? true));

  const onClickSubmit = (triggeredByTabChange: boolean, updateListEl: UpdateListElementFunction, callback?: () => void) => {
    onSubmit(triggeredByTabChange, updateListEl, callback);
  };

  const typeSelectorDisabled = loading;

  const getPopupContainer = () => (document.getElementsByClassName('ServiceCompanyAllocation')?.[0] ?? document.body) as HTMLElement;

  return (
    <div className="input-row">
      <SelectInput
        onChange={(type) => {
          // TODO automatic submission if dirty
          if (isDirty) {
            // save only if there were changes
            onClickSubmit(true, updateListElement, () => setSelectedType(type));
          } else {
            setSelectedType(type);
          }
        }}
        value={selectedType}
        options={types}
        className="dropDown"
        getPopupContainer={getPopupContainer}
        disabled={typeSelectorDisabled}
      />
      <AllocationSearchBar
        onSearch={onSearch}
        checkboxValue={false}
        onChangeCheckbox={() => {}}
        checkboxDisabled
        checkboxLabel={`${tl(translations.bankTransactionAllocation.showMarked)} ${tl(translations.bankTransactionAllocation.markedAccounts)}`}
      />

      <MenuButton
        transactionState={selectedBankTransactions[0]?.transactionPaymentStatus}
        resetTransaction={resetTransaction}
        markTransactionNotBookable={markTransactionNotBookable}
        markTransactionDuplicate={markTransactionDuplicate}
      />

      <Button
        className="section-submit"
        type="primary"
        disabled={disabled}
        loading={loading}
        onClick={() => onClickSubmit(false, updateListElement)}
      >
        {tl(translations.bankTransactionAllocation.allocateButtonLabel)}
      </Button>
    </div>
  );
};

export default BankTransactionAllocationControlsRow;
