import { HouseMoneySettlementAggregationControllerApi, HouseMoneySettlementStatusDto, HouseMoneySettlementStatusDtoStatusEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { translations } from '../../translations';
import { HgaValidationContext } from './HgaValidationContext';

export const useHgaValidation = () => {
  const { tl } = useContext(LanguageContext);
  const hgaValidationContext = useContext(HgaValidationContext);

  if (hgaValidationContext === undefined) {
    throw Error('useHgaValidation must be used within HgaValidationContextProvider');
  }

  const { setVisible, setModalProps } = hgaValidationContext;
  const { apiConfiguration } = useContext(AuthContext);
  const houseMoneySettlementApi = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));

  const { PREPARED, FINALIZED } = HouseMoneySettlementStatusDtoStatusEnum;

  const checkIfHgaIsClosed = (pId: number, propertyHrId: string, economicYear: number, onProceed: () => void, onCancelCb: () => void) => {
    if (!pId || !economicYear) return;

    houseMoneySettlementApi.getHouseMoneySettlementStatusUsingGET({ propertyId: pId, economicYear })
      .then((response: HouseMoneySettlementStatusDto) => {
        if (Array.of(PREPARED, FINALIZED).includes(response?.status)) {
          onShowModal(propertyHrId, economicYear, onProceed, onCancelCb);
        } else {
          onProceed();
        }
      })
      .catch(() => {
        onProceed();
      });
  };

  const onShowModal = (propertyHrId: string, economicYear: number, onProceed: () => void, onCancelCb: () => void) => {
    setModalProps({
      onCancel: () => onCancel(onCancelCb),
      onReview: () => onClickReview(propertyHrId, economicYear),
      onSave: () => onSave(onProceed),
      title: tl(translations.bankTransactionAllocation.modal.transaction),
    });

    setVisible(true);
  };

  const onCancel = (cb: () => void) => {
    setVisible(false);
    cb();
  };
  const onClickReview = (propertyHrId: string, economicYear: number) => {
    window.open(`${window.location.origin}/house-money-settlement/${propertyHrId}?year=${economicYear}`);
  };

  const onSave = (onProceed: () => void) => {
    setVisible(false);
    onProceed();
  };

  return {
    checkIfHgaIsClosed,
  };
};
