import { CasaviImportIdDtoAdministrationTypeEnum } from 'api/accounting';

export interface PortalPropertyInterface {
    key: string,
    number: string,
    name: string,
    city: string,
    status: PropertyImportStatus,
    type: CasaviImportIdDtoAdministrationTypeEnum,
    errorDetails: string,
  }

export enum PropertyImportStatus {
  IDLE = 'IDLE',
  NO_IMPORT = 'NO_IMPORT',
  PENDING = 'PENDING',
  IMPORTING = 'IMPORTING',
  IMPORTED = 'IMPORTED',
  FAILED = 'FAILED',
  EXISTING = 'EXISTING',
}
