import { useContext, useEffect, useState } from 'react';
import {
  AllocationAmountsStateContext, AllocationVatEligibilityValuesStateContext, SelectInputValuesStateContext, ServiceCompanyAllocationUpdatersContext,
} from 'pages/BankTransactions/BankTransactionAllocation/components/ServiceCompanyAllocation/services/ServiceCompanyAllocationContext';
import { calculateVatAmount, calculateVatEligibilityAmount } from 'pages/BankTransactions/BankTransactionAllocation/components/ServiceCompanyAllocation/services/serviceCompanyAllocationUtils';

export const useVatEligibilityModal = (key: string, onClose: () => void) => {
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);
  const vatEligibilityValues = useContext(AllocationVatEligibilityValuesStateContext);
  const selectInputValues = useContext(SelectInputValuesStateContext);
  const allocationAmounts = useContext(AllocationAmountsStateContext);

  if (updatersContext === undefined || vatEligibilityValues === undefined || selectInputValues === undefined || allocationAmounts === undefined) {
    throw new Error('useVatEligibilityModal must be used within a ServiceCompanyAllocationContextProvider');
  }


  const { setAllocationVatEligibilityValues } = updatersContext;
  const { vatEligibilityPercentage } = vatEligibilityValues?.find(alloc => alloc.key === key) ?? {};

  const { vatPercentage } = selectInputValues?.find(s => s.key === key) ?? {};
  const amount = allocationAmounts?.find(alloc => alloc.key === key)?.currentAmount;
  const [vatEligibilityPercentageLocalValue, setVatEligibilityPercentageLocalValue] = useState<number>(vatEligibilityPercentage);

  useEffect(() => {
    // this is needed when the tx can be allocated to multiple properties and the default is different
    if (vatEligibilityPercentage !== vatEligibilityPercentageLocalValue) {
      setVatEligibilityPercentageLocalValue(vatEligibilityPercentage);
    }
  }, [vatEligibilityPercentage]);

  const onApply = () => {
    setAllocationVatEligibilityValues(prev => prev.map((vatEligibility) => {
      if (vatEligibility.key === key) {
        return {
          ...vatEligibility,
          vatEligibilityPercentage: vatEligibilityPercentageLocalValue,
        };
      }

      return vatEligibility;
    }));

    onClose();
  };


  const onChangeVatEligibilityPercentage = (val: number) => {
    setVatEligibilityPercentageLocalValue(val);
  };

  const vatAmount = calculateVatAmount(vatPercentage, amount, 1); // this is only for display, we do not care about the sign
  const vatEligibilityAmount = calculateVatEligibilityAmount(vatEligibilityPercentageLocalValue, vatAmount, 1);

  return {
    onApply,
    onCancel: onClose,
    onChangeVatEligibilityPercentage,
    vatEligibilityAmount: Math.abs(vatEligibilityAmount),
    vatEligibilityPercentage: vatEligibilityPercentageLocalValue,
  };
};
