export const translations = {
  title: {
    en: 'Economic plans',
    de: 'Wirtschaftspläne',
  },
  description: {
    en: 'Economic plan data to upload',
    de: 'Wirtschaftsplan Daten zum hochladen',
  },
  upload: {
    en: 'Upload economic plans',
    de: 'Wirtschaftspläne',
  },
  import: {
    button: {
      en: 'Import',
      de: 'Importieren',
    },
    description: { en: 'Please do not refresh the page until the process finishes', de: 'Bitte abwarten bis das Prozess erfolgreich endet. Bitte navigieren sie nicht weg.' },
    title: { en: 'Importing', de: 'Importieren' },
  },
  notifications: {
    propertyLoadError: {
      en: 'Error loading properties',
      de: 'Objekte konnten nicht geladen werden',
    },
    error: {
      title: { en: 'Error importing CSV', de: 'Fehler' },
      description: { en: 'Import failed half way through', de: 'Import Prozess fehlgeschlagen' },
    },
  },
  validations: {
    success: {
      en: 'Unit Economic plans have been verified and ready for import.',
      de: 'Einheitswirtschaftspläne wurden geprüft und sind bereit zum Importieren',
    },
    errors: {
      en: 'Issues found in the CSV',
      de: 'Es wurden Fehlern gefunden',
    },
    issues: {
      property: {
        en: 'Invalid property id',
        de: 'Ungültiges Objektreferenz',
      },
      unit: {
        en: 'Invalid unit id',
        de: 'Ungültiges Einheitsreferenz',
      },
      reserve: {
        en: 'Invalid reserve value ',
        de: 'Ungültiges Rücklage ',
      },
    },
  },
};
