export const translations = {
  standingOrderOverview: {
    stopModal: {
      title: {
        en: 'Delete standing order',
        de: 'Dauerauftrag löschen',
      },
      body: {
        en: 'By clicking on “Delete” the standing order will be deleted and no more payments will be created from now on. Payments already made in the payment run are retained. If an invoice is linked to the standing order, it will be marked as “Stopped” and no longer will be booked. This step cannot be undone.',
        de: 'Mit Klick auf “Löschen” wird der Dauerauftrag gelöscht und von jetzt an keine Zahlung mehr erstellt. Bereits erstellte Zahlungen im Zahlungslauf bleiben erhalten. Falls eine Rechnung mit dem Dauerauftrag verbunden ist, wird diese auf den Status “Gestoppt” gesetzt und nicht mehr verbucht.  Dieser Schritt kann nicht rückgängig gemacht werden.',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
    },
    filters: {
      showStopped: {
        label: {
          en: 'Stopped',
          de: 'Gestoppt',
        },
        show: {
          en: 'Show',
          de: 'Anzeigen',
        },
        hide: {
          en: 'Hide',
          de: 'Ausblenden',
        },
      },
      businessPartnerName: {
        en: 'Business partner name',
        de: 'Geschäftspartnern Name',
      },
      businessPartnerIban: {
        en: 'Business partner IBAN',
        de: 'Geschäftspartner IBAN',
      },
      administrator: {
        en: 'Administrator',
        de: 'Betreuer',
      },
      createdBy: {
        en: 'Created by',
        de: 'Erstellt von',
      },
    },
  },
};
