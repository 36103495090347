import { PropertyLegacyDtoVatRelevanceEnum } from 'api/accounting';
import _ from 'lodash';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';

export const shouldShowPropertyDataInExchangeList = (selectedTransactions: ExtendedBankTransaction[]) => (
  selectedTransactions[0]?.propertyList?.length > 1
);

// only display column if there is at least one partially relevant prp, leaving the array if requirements change after customer feedback
export const shouldShowVatEligibilityColumnInExchangeList = (selectedTransactions: ExtendedBankTransaction[]) => (
  !_.isEmpty(selectedTransactions[0]?.propertyList?.filter(p => [PropertyLegacyDtoVatRelevanceEnum.PARTIALLY_RELEVANT].includes(p.vatRelevance)))
);
