import { DunningControllerApi, DunningProjection, ListSpecialContributionUsingGETOrderEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useEffect, useRef } from 'react';
import { showNotification } from 'lib/Notification';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import _ from 'lodash';
import {
  DunningMessageDto, DunningProjectionFE, DunningPropertyDto, useDunningListContext,
} from './DunningListContext';

const PAGE_SIZE = 20;


export const useDunningList = () => {
  const { overlays } = useContext(OverlayContext);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const dunningController = new DunningControllerApi(apiConfiguration('accounting'));

  const {
    dunningList,
    setDunningList,
    dunningListFilterState,
    sortState,
    setSortState,
  } = useDunningListContext('useDunningList');

  const onChangeSort = (field: keyof DunningProjectionFE) => {
    setSortState((currentState) => {
      const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
      return {
        field,
        order,
      };
    });
  };

  const dunningListAbortController = useRef<AbortController | undefined>(undefined);

  useEffect(() => {
    if (overlays.length === 1) {
      onLoadDunningList(true);
    }
  }, [overlays.length, dunningListFilterState, sortState]);

  const mapToFEObject = (response: DunningProjection[]): DunningProjectionFE [] => response.map((d) => {
    const properties: DunningPropertyDto[] = JSON.parse(d.properties ?? '[]');
    const messages: DunningMessageDto[] = JSON.parse(d.messages ?? '[]');

    let propertyAdministrator;
    let propertyAccountant;
    if (properties.length === 1) {
      [{ propertyAdministrator }] = properties;
      [{ propertyAccountant }] = properties;
    }
    if (properties.length > 1) {
      propertyAdministrator = tl(orderOpenBalancesTranslations.dunningList.tableColumns.multiple);
      propertyAccountant = tl(orderOpenBalancesTranslations.dunningList.tableColumns.multiple);
    }

    return {
      ...d,
      properties,
      propertyManager: propertyAdministrator,
      propertyAccountant,
      messages,
      numberOfAllMessages: messages.length,
      numberOfFailedMessages: messages.filter(m => m.state === 'FAILED').length,
    } as DunningProjectionFE;
  });

  const onLoadDunningList = (resetPage: boolean = false) => {
    setDunningList(prev => prev.startLoading());

    // if params changed since last initiated fetch then abort the in-progress fetch
    dunningListAbortController.current?.abort();
    // create new abort controller
    dunningListAbortController.current = new AbortController();
    const { signal } = dunningListAbortController.current;

    dunningController.listDunningDtoUsingGET({
      ...dunningListFilterState,
      page: resetPage ? 0 : dunningList.page,
      size: PAGE_SIZE,
      sort: _.snakeCase(sortState.field),
      order: sortState.order > 0 ? ListSpecialContributionUsingGETOrderEnum.ASC : ListSpecialContributionUsingGETOrderEnum.DESC,
    }, { signal })
      .then((response) => {
        setDunningList(prev => prev.loadPaged(mapToFEObject(response.content), resetPage, response.last));
      }).catch((error) => {
        if (signal?.aborted) return;
        console.error(error);
        setDunningList(listState => (listState.failed()));
        showNotification({
          key: 'loadDunningListError',
          message: tl(orderOpenBalancesTranslations.dunningList.notifications.loadListError),
          type: 'error',
        });
      });
  };

  return {
    dunningList,
    onLoadDunningList,
    sortField: sortState.field,
    sortOrder: sortState.order,
    onChangeSort,
  };
};
