import React, { useContext, useMemo, useRef } from 'react';
import CurrencyInputWithCheckbox from 'components/CurrencyInputWithCheckbox/CurrencyInputWithCheckbox';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';
import { Select } from 'antd';
import { PropertyLegacyDtoVatRelevanceEnum } from 'api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import { formatCurrency } from '../../../../lib/Utils';
import SeparatingColumnIcon from '../components/SeparatingColumnIcon/SeparatingColumnIcon';
import EditPostingTextModal from '../components/EditPostingTextModal/EditPostingTextModal';
import LinkToAccountSheet from '../components/LinkToAccountSheet/LinkToAccountSheet';
import { ExtendedBankTransaction, PostingText } from '../../interfaces';
import UnitAllocationParentRowAmountCell from '../components/UnitAllocationParentRowAmountCell/UnitAllocationParentRowAmountCell';
import UnitAllocationVatCell from '../components/UnitAllocationVatCell/UnitAllocationVatCell';


export const useUnitAccountTableColumns = ({
  onChangeAllocatedAmount, onChangePostingText, onChangeVatPercentage, isDebit, statements, bankTransactions,
}: {
  onChangeAllocatedAmount: (unitAccountKey: string, value: number) => void,
  onChangeVatPercentage: (unitAccountKey: string, values: number) => void,
  onChangePostingText: (unitAccountKey: string, values: PostingText[]) => void,
  isDebit: boolean,
  statements?: PostingText[]
  bankTransactions: ExtendedBankTransaction[],
}) => {
  const { tl } = useContext(LanguageContext);
  const bankTransactionsRef = useRef<ExtendedBankTransaction[]>([]);
  bankTransactionsRef.current = useMemo(() => bankTransactions, [bankTransactions]);

  const statementsRef = useRef<PostingText[] | undefined>([]);

  statementsRef.current = useMemo(() => statements, [statements]);

  const columns = [
    {
      title: tl(translations.bankTransactionAllocation.unitAccountTableHeader.unitName),
      dataKey: 'unitNrSharingDeclaration',
      defaultWidth: '10%',
    },
    {
      title: tl(translations.bankTransactionAllocation.unitAccountTableHeader.owner),
      dataKey: 'accountOwnerFullName',
      defaultWidth: '20%',
    },
    // TODO: instead of accountCode + accountName have 1 cell with both info. Ex: rent plan creation
    {
      title: tl(translations.bankTransactionAllocation.unitAccountTableHeader.accountCode),
      dataKey: 'accountCode',
      defaultWidth: '10%',
      render: (text: string, record: any) => (!record.childRows ? (
        <LinkToAccountSheet propertyHrId={record.propertyHrId} accountCode={record.code}>{text}</LinkToAccountSheet>
      ) : text),
    },
    {
      title: tl(translations.bankTransactionAllocation.unitAccountTableHeader.accountName),
      dataKey: 'name',
      defaultWidth: '20%',
      render: (text: string, record: any) => (!record.childRows ? (
        <LinkToAccountSheet propertyHrId={record.propertyHrId} accountCode={record.code}>{text}</LinkToAccountSheet>
      ) : text),
    },
    {
      title: isDebit
        ? tl(translations.bankTransactionAllocation.unitAccountTableHeader.lastDebit)
        : tl(translations.bankTransactionAllocation.unitAccountTableHeader.lastCredit),
      dataKey: 'bookingAmount',
      className: 'column-align-right',
      defaultWidth: '15%',
      render: (text: string, record: any) => (!record.childRows ? (
        <Amount>
          {record.lastBookingAmount ? formatCurrency(Math.abs(record.lastBookingAmount)) : formatCurrency(0)}
        </Amount>
      ) : (
        <span>
          {record.houseMoneyAmount
            ? (
              <UnitAllocationParentRowAmountCell
                label={tl(translations.bankTransactionAllocation.unitAccountTableHeader.houseMoney)}
                amount={record.houseMoneyAmount}
              />
            )
            : ''}
        </span>
      )),
    },
    {
      title: '',
      dataKey: 'icon-key',
      className: 'column-separating-icon-cell',
      defaultWidth: 40,
      render: (text: string, record: any) => (<SeparatingColumnIcon record={record} />),
    },
    {
      title: tl(translations.bankTransactionAllocation.unitAccountTableHeader.sumToAllocate),
      dataKey: 'sumToAllocate',
      defaultWidth: '15%',
      className: 'sum-to-allocate-column',
      render: (text: string, record: any) => (!record.childRows ? (
        <CurrencyInputWithCheckbox
          suggestedValue={Math.abs(record.lastBookingAmount ?? 0)}
          previousValue={record.previouslyAllocatedAmount}
          currentValue={record.sumToAllocate}
          onChange={value => onChangeAllocatedAmount(record.unitAccountKey, value)}
        />
      ) : (
        <span>
          {record.reserveFundAmount
            ? (
              <UnitAllocationParentRowAmountCell
                label={tl(translations.bankTransactionAllocation.unitAccountTableHeader.reserveFund)}
                amount={record.reserveFundAmount}
              />
            )
            : ''}
        </span>
      )),
    },
    {
      title: '',
      dataKey: 'edit-posting-text-button',
      className: 'edit-posting-text-button',
      defaultWidth: 60,
      render: (text: string, record: any) => (!record.childRows ? (
        <EditPostingTextModal
          type="unit"
          disabled={!(record.sumToAllocate > 0)}
          unitNr={record.unitId}
          ownerName={record.accountOwnerFullName}
          accountCode={record.accountCode}
          accountName={record.name}
          bankTransactionsRef={bankTransactionsRef}
          defaultTexts={record.postingTexts || statementsRef.current}
          onSubmit={values => onChangePostingText(record, values)}
        />
      ) : null),
    },
  ];

  const displayVatSelectorColumn = bankTransactions[0]?.propertyList?.findIndex(prp => prp.vatRelevance !== PropertyLegacyDtoVatRelevanceEnum.NOT_RELEVANT) >= 0;

  if (displayVatSelectorColumn) {
    columns.splice(4, 0, {
      title: tl(translations.bankTransactionAllocation.unitAccountTableHeader.vat),
      dataKey: 'vat',
      defaultWidth: '10.0rem',
      render: (_, record) => (!record.childRows ? (
        <UnitAllocationVatCell
          contractVatRelevance={record.vatRelevance}
          propertyVatRate={record.vatRatesForAccount}
          value={record.vatPercentageToAllocate ?? record.defaultVatPercentage}
          onChange={value => onChangeVatPercentage(record.unitAccountKey, value)}
          disabled={!(record.sumToAllocate > 0)}
        />
      ) : null),
    });
  }

  if (bankTransactions[0]?.propertyList?.length > 1) {
    columns.splice(0, 0, {
      title: tl(translations.bankTransactionAllocation.unitAccountTableHeader.property),
      dataKey: 'propertyIdInternal',
      defaultWidth: '20.0rem',
      render: (_, record) => (
        <CellWithTwoLines firstElement={record.propertyIdInternal} secondElement={record.propertyName} />),
    });
  }
  return columns;
};
