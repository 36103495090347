import {
  ContractProjectionDto, DocumentProjectionDto, SerialLetterControllerApi, SerialLetterDto, SerialLetterDtoShippingStatusEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { DefaultDataInterface } from 'lib/data';
import { buildDocumentRecipients } from 'lib/messageUtils';
import { showNotification } from 'lib/Notification';
import { isNil } from 'lodash';
import { MessageSendingInitializationStateType } from 'pages/MessageSendingPage/interfaces';
import { getSendRouteForSerialLetter } from 'pages/SerialLetters/routes';
import { translations } from 'pages/SerialLetters/translations';
import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useAllContractsForMultipleProperties } from 'services/UnitContractsList/ContractsForMultipleProperties/useAllContractsForMultipleProperties';

export const useSendOutSerialLetter = ({ serialLetter, setSerialLetter, setSaveResponseValid }
    : {serialLetter: DefaultDataInterface<SerialLetterDto>,
        setSerialLetter: React.Dispatch<React.SetStateAction<DefaultDataInterface<SerialLetterDto>>>,
        setSaveResponseValid: (v: boolean) => void,
     }) => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const { apiConfiguration } = useContext(AuthContext);
  const serialLetterControllerApi = new SerialLetterControllerApi(apiConfiguration('accounting'));

  const memoPropertyIds = useMemo(()=> (
    serialLetter.data?.properties?.filter(p => !isNil(p)).map(p => p.propertyId)
  ), [serialLetter.data?.properties]);
  const { allContractsForMultipleProperties } = useAllContractsForMultipleProperties({ propertyIds: memoPropertyIds });

  const navigateToMessageSending = (letterId: number, documents: DocumentProjectionDto[]) => {
    const messageSendingNavigationState: MessageSendingInitializationStateType = {
      sourceType: 'SERIAL_LETTER',
      sourceId: letterId,
      properties: serialLetter.data?.properties.map((p) => {
        const documentRecipients = documents
          ?.filter(d => d.contractId !== undefined && d.propertyId === p.propertyId)
          .map((doc) => {
            const contract = allContractsForMultipleProperties.data?.find(c => c.unitContractId === doc.contractId);
            return { ...buildDocumentRecipients(doc?.id, [contract as unknown as ContractProjectionDto]), unitRank: contract?.unitRank };
          })
          .sort((a, b) => a.unitRank - b.unitRank);
        return ({
          propertyId: p?.propertyId,
          documents: [...documentRecipients],
        });
      }),
    };

    const sendUrl = getSendRouteForSerialLetter(letterId);
    history.push(sendUrl, messageSendingNavigationState);
  };

  const onSendOutSerialLetter = (letterId?: number) => {
    setSerialLetter(prev => prev.startLoading());
    // Here generating documents for serial letter right after 'Preview and send' is clicked
    serialLetterControllerApi.generateDocumentsForSerialLetterUsingPOST({ serialLetterId: letterId })
      .then((documents) => {
        setSerialLetter(prev => prev.finishLoading());
        navigateToMessageSending(letterId, documents);
      })
      .catch((error) => {
        console.error(error);
        setSerialLetter(prev => prev.failed());
        showNotification({
          key: 'sendOut',
          message: tl(translations.notifications.generateDocumentError),
          type: 'error',
        });
      });
  };

  const onMarkLetterSent = (letterId?: number) => {
    setSerialLetter(prev => prev.startLoading());
    serialLetterControllerApi.markSerialLetterAsSentUsingPOST({ serialLetterId: letterId })
      .then((resp) => {
        setSerialLetter(prevState => prevState.load({ ...prevState.data, shippingStatus: resp.shippingStatus }));
        if (resp.shippingStatus === SerialLetterDtoShippingStatusEnum.ERROR) {
          showNotification({
            key: 'sendOut',
            message: tl(translations.notifications.sendOutError.message),
            type: 'error',
          });
        } else {
          setSaveResponseValid(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setSerialLetter(prev => prev.failed());
        showNotification({
          key: 'sendOut',
          message: tl(translations.notifications.sendOutError.message),
          type: 'error',
        });
      });
  };

  return {
    onSendOutSerialLetter,
    onMarkLetterSent,
  };
};
