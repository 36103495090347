import { Modal } from 'antd';
import { EconomicPlanControllerApi, EconomicPlanDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { DefaultDataInterface } from 'lib/data';
import { showNotification } from 'lib/Notification';
import useEconomicPlanList from 'pages/EconomicPlan/EconomicPlanList/services/useEconomicPlanList';
import { economicPlanTranslations } from 'pages/EconomicPlan/economicPlanTranslations';
import { EconomicPlanContext } from 'pages/EconomicPlan/services/EconomicPlanContext';
import { useHandleErrorWithType } from 'pages/EconomicPlan/services/useHandleErrorWithType';
import { useContext } from 'react';

export const useRevertToDraftOption = () => {
  const economicPlanContext = useContext(EconomicPlanContext);

  if (economicPlanContext === undefined) {
    throw new Error('useRevertToDraftOption must be used within a EconomicPlanContextProvider');
  }

  const {
    economicPlan,
    setEconomicPlan,
  } = economicPlanContext;

  const { loadEconomicPlanList } = useEconomicPlanList();
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const { handleErrorWithType } = useHandleErrorWithType();

  const mergeWithPrev = (response: EconomicPlanDto, prev: DefaultDataInterface<EconomicPlanDto>) => ({
    ...prev.data,
    ...response,
  });

  const onMarkEconomicPlanDraft = (id: number) => {
    setEconomicPlan(economicPlan.startLoading());
    economicPlanControllerApi.revertToDraftUsingPOST({ id })
      .then((response: EconomicPlanDto) => {
        setEconomicPlan(prev => prev.load(mergeWithPrev(response, prev)));
        loadEconomicPlanList(true);
      })
      .catch((error) => {
        setEconomicPlan(economicPlan.failed());
        error.response.json()
          .then((errorBody) => {
            if (errorBody.type) {
              handleErrorWithType(errorBody, tl(economicPlanTranslations.economicPlan.notification.statusChangeError) + tl(economicPlanTranslations.economicPlanList.status.DRAFT));
            } else {
              showNotification({
                key: 'changeEconomicPlanStatusError',
                message: tl(economicPlanTranslations.economicPlan.notification.statusChangeError) + tl(economicPlanTranslations.economicPlanList.status.DRAFT),
                type: 'error',
              });
            }
          });
      });
  };

  const showConfirmationModalOnRevertToDraft = () => {
    Modal.confirm({
      icon: <></>,
      title: tl(economicPlanTranslations.economicPlan.revertModal.title),
      content: tl(economicPlanTranslations.economicPlan.revertModal.text),
      okText: tl(economicPlanTranslations.economicPlan.revertModal.confirmText),
      cancelText: tl(economicPlanTranslations.economicPlan.revertModal.cancelText),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: () => onMarkEconomicPlanDraft(economicPlan.data?.id),
      width: 750,
    });
  };

  return {
    label: tl(economicPlanTranslations.economicPlan.header.buttons.revertToDraft),
    onClick: showConfirmationModalOnRevertToDraft,
  };
};
