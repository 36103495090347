export const translations = {
  title: { en: 'Rent plans', de: 'Mietverträge' },
  description: { en: 'List of rent plans to upload', de: 'Liste der Mietverträge zum hochladen.' },
  upload: { en: 'Upload Rent plans', de: 'Mietverträge hochladen' },
  import: {
    button: {
      en: 'Import',
      de: 'Importieren',
    },
    description: { en: 'Please do not refresh the page until the process finishes', de: 'Bitte abwarten bis das Prozess erfolgreich endet. Bitte navigieren sie nicht weg.' },
    title: { en: 'Importing', de: 'Importieren' },
  },
  notifications: {
    propertyLoadError: {
      en: 'Error loading properties',
      de: 'Objekte konnten nicht geladen werden',
    },
    error: {
      title: { en: 'Error importing CSV', de: 'Fehler' },
      description: { en: 'Import failed half way through', de: 'Import Prozess fehlgeschlagen' },
    },
  },
  validations: {
    success: { en: 'Rent plans have been verified and ready for import.', de: 'Mietverträge wurden geprüft und sind bereit zum Importieren' },
    errors: { en: 'Issues found in the CSV', de: 'Es wurden Fehlern gefunden' },
    issues: {
      property: {
        en: 'Invalid property id',
        de: 'Ungültiges Objektreferenz',
      },
      unit: {
        en: 'Invalid unit id',
        de: 'Ungültiges Einheitsreferenz',
      },
      rentType: {
        en: 'Invalid unit id',
        de: 'Ungültiges Einheitsreferenz',
      },
      occurrenceInterval: {
        en: 'Invalid occurrence interval',
        de: 'Ungültige Häufigkeit Intervall',
      },
      occurrence: {
        en: 'Invalid occurrence',
        de: 'Ungültige Häufigkeit',
      },
      occurrenceDay: {
        en: 'Invalid occurrence day',
        de: 'Ungültiges Tag',
      },
      occurrenceMonth: {
        en: 'Invalid month',
        de: 'Ungültiges Monat',
      },
      startDate: {
        en: 'Invalid start date',
        de: 'Ungültiges Startdatum',
      },
      type: {
        en: 'Invalid type',
        de: 'Ungültiges Mietart',
      },
      total: {
        en: 'Invalid total',
        de: 'Ungültiges Bruttomiete',
      },
      breakdown: {
        en: 'Invalid cost breakdown',
        de: 'Ungültige Aufgliederung',
      },
    },
  },
};
