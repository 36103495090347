import FileSaver from 'file-saver';
import { useContext } from 'react';
import {
  DocumentLegacyControllerApi, FindDocumentsFilteredUsingGETOrderEnum, FindDocumentsFilteredUsingGETSourceTypesEnum,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { isEmpty } from 'lodash';
import { DATE_FORMAT, formatDate } from 'lib/Utils';
import moment from 'moment';
import { ListSpecialContributionUsingGETOrderEnum, MessageControllerApi } from 'api/accounting';
import { MAX_PAGE_SIZE } from 'lib/messageUtils';
import { showNotification } from '../../../../lib/Notification';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { messagesListTranslations } from '../translations';
import { useMessagesListContext, useMessagesSelectionContext } from './MessagesListContext';

export const useMessagePdfDownload = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration, documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));
  const messageControllerApi = new MessageControllerApi(apiConfiguration('accounting'));

  const {
    selectedRowKeysTotal,
  } = useMessagesSelectionContext('useMessagePdfDownload');

  const { filterState, sortState } = useMessagesListContext('useMessagePdfDownload');

  const onDownloadError = (err) => {
    console.error(err);
    showNotification({
      key: 'fileDownloadError',
      message: tl(messagesListTranslations.notifications.downloadError.message),
      type: 'error',
    });
  };

  const downloadPdf = (messageId: number, fileName: string) => {
    documentControllerApi.findDocumentsFilteredUsingGET({
      sourceIds: [messageId],
      sourceTypes: [FindDocumentsFilteredUsingGETSourceTypesEnum.MESSAGE] as unknown as FindDocumentsFilteredUsingGETSourceTypesEnum,
    })
      .then((documentPage) => {
        const documentId = documentPage.content?.[0]?.id;
        if (documentId) {
          saveFile(documentControllerApi.downloadUsingGET1Raw({ documentId }), fileName, 'pdf');
        } else {
          onDownloadError('No document id.');
        }
      });
  };

  const onDownload = (messageIds: number[], zip: boolean) => {
    const fileName = zip ? `${formatDate(moment(), DATE_FORMAT)}_Dokumente.zip` : `${formatDate(moment(), DATE_FORMAT)}_Dokumente.pdf`;
    documentControllerApi.findDocumentsFilteredUsingGET({
      sourceIds: messageIds,
      sourceTypes: [FindDocumentsFilteredUsingGETSourceTypesEnum.MESSAGE] as unknown as FindDocumentsFilteredUsingGETSourceTypesEnum,
      sort: 'unitRank',
      order: FindDocumentsFilteredUsingGETOrderEnum.ASC,
      size: MAX_PAGE_SIZE,
    }).then((documentPage) => {
      const documentIds = documentPage.content?.map(d => d.id);
      if (!isEmpty(documentIds)) {
        if (zip) {
          saveFile(documentControllerApi.downloadZipUsingGETRaw({ documentIds }), fileName, 'zip');
        } else {
          saveFile(documentControllerApi.downloadConcatenatedUsingGETRaw({ documentIds }), fileName, 'pdf');
        }
      } else {
        onDownloadError('No document id.');
      }
    })
      .catch(onDownloadError);
  };

  const onDownloadAll = (zip: boolean) => {
    messageControllerApi.getMessagesUsingGET({
      size: MAX_PAGE_SIZE,
      sort: sortState.field,
      ...filterState,
      order: sortState.order > 0 ? ListSpecialContributionUsingGETOrderEnum.ASC : ListSpecialContributionUsingGETOrderEnum.DESC,
    })
      .then((resp) => {
        const messageIds = resp.content?.map(m => m.id);
        onDownload(messageIds, zip);
      })
      .catch(onDownloadError);
  };

  const saveFile = (promise, fileName, ext) => {
    promise.then((resp: any) => {
      resp?.raw?.blob()
        .then((blob: Blob) => new Response(blob).arrayBuffer())
        .then((arrayBuff) => {
          const pdf = new Blob([arrayBuff], { type: `application/${ext}` });
          FileSaver.saveAs(pdf, fileName);
        });
    })
      .catch(onDownloadError);
  };
  const getDownloadMenuOptions = () => {
    const defaultOptions = [
      {
        label: tl(messagesListTranslations.downloadAllMerged),
        onClick: () => onDownloadAll(false),
      },
      {
        label: tl(messagesListTranslations.downloadAllZip),
        onClick: () => onDownloadAll(true),
      },
    ];

    if (!isEmpty(selectedRowKeysTotal)) {
      defaultOptions.push({
        label: tl(messagesListTranslations.downloadSelectedMerged),
        onClick: () => onDownload(selectedRowKeysTotal, false),
      },
      {
        label: tl(messagesListTranslations.downloadSelectedZip),
        onClick: () => onDownload(selectedRowKeysTotal, true),
      });
    }

    return defaultOptions;
  };

  const downloadMenuOptions = getDownloadMenuOptions();

  return { downloadPdf, downloadMenuOptions };
};
