import React, { useMemo, useState } from 'react';
import { escapeRegExp } from 'lodash';
import SmartSearch, { OptionInterface } from '../SmartSearch/SmartSearch';
import { Account, AccountFE } from '../../../services/useAccountList';
import InputProps from '../InputProps';
import { AccountDto } from '../../../api/accounting/models';

interface AccountFilterComponentProps extends InputProps<string | AccountDto | undefined> {
  propertyHrId: string | undefined,
  optionContainsPath?: boolean,
  valueKey?: string,
  value: string | AccountDto | undefined,
  allAccounts: Account[] | undefined,
  hideParents: boolean,
  transformFunction?: (code: string) => void;
}

export function AccountFilterComponent(props: AccountFilterComponentProps): JSX.Element {
  const {
    transformFunction, hideParents, optionContainsPath, onChange, label, required, autoFocus, value, valueKey, infoText,
    className, allAccounts, disabled, validationMessage, validationState,
  } = props;
  const [filter, setFilter] = useState('');
  const filteredAccounts = useMemo(() => {
    const allAccountsMap = allAccounts.reduce((acc, account) => {
      acc[account.code] = account;
      return acc;
    }, {});

    let accountFes: AccountFE[] = allAccounts!
      .map((account: any) => {
        const accountFe: AccountFE = account as AccountFE;
        let levels = 2;
        accountFe.pathString = ' / ';
        const parentCode = account.parentAccountCode;
        while (--levels > 0) {
          const parent = allAccountsMap[parentCode] as AccountFE;
          if (parent) {
            parent.isParent = true;
            accountFe.pathString += `${parent.code} ${parent.name} / `;
          }
        }

        const filterRegExp = new RegExp(escapeRegExp(filter), 'i');
        accountFe.matchScore = ((filterRegExp.test(accountFe.code) ? 1 : 0) * 5)
          + ((filterRegExp.test(accountFe.code.replaceAll('/', '')) ? 1 : 0) * 5)
          + ((filterRegExp.test(accountFe.name) ? 1 : 0) * 3)
          + (accountFe.pathString ? (filterRegExp.test(accountFe.pathString) ? 1 : 0) * 1 : 0);
        return accountFe;
      });

    accountFes = accountFes
    // only show leaves & do not show 33 accounts (is leaf until prp is DRAFT - PMP-18264)
    // do not allow creating postings in 301, because
    //    1. prp cannot be READY without prp owners
    //    2. there will be 2 accounts created per prp owner => it is not straightforward in which one should the postings be moved
      .filter(account => (!account.isParent && account.code !== '33' && account.code != '301') || !hideParents)
    // show matches
      .filter(account => !filter || account.matchScore > 0);
    accountFes.sort((a, b) => a.matchScore - b.matchScore);
    const partialAccountFes = accountFes.slice(0, 30);
    return partialAccountFes;
  }, [allAccounts, filter]);

  const accountOptions: OptionInterface[] | null = useMemo(() => (
    filteredAccounts
      ? filteredAccounts.map((account0: any) => {
        const account: AccountFE = account0 as AccountFE;
        return {
          value: account.code,
          label: optionContainsPath ? `${account.pathString} ${account.code} ${account.name}` : `${account.code} ${account.name}`,
          divisible: account.divisible,
        };
      })
      : []
  ), [filteredAccounts]);

  const onFilter = (newFilter: string) => {
    setFilter(newFilter);
  };

  return (
    <SmartSearch
      delay={0}
      options={accountOptions}
      searchFunction={onFilter}
      value={value}
      transformFunction={transformFunction}
      autoFocus={autoFocus}
      getOneFunction={onFilter}
      required={required}
      label={label}
      onChange={onChange}
      valueKey={valueKey}
      infoText={infoText}
      className={className}
      disabled={disabled}
      validationMessage={validationMessage}
      validationState={validationState}
    />
  );
}
