import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import React from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
  title: string,
  value: number,
  nrTotalColumns: number,
}

const ResultTableSubSummary = ({ title, value, nrTotalColumns }: Props) => (
  <Table.Summary>
    <Table.Summary.Row className="ResultsTableSubSummary">
      <Table.Summary.Cell index={0} colSpan={nrTotalColumns - 1}>
        {title}
      </Table.Summary.Cell>
      <Table.Summary.Cell className="column-align-right no-wrap" index={nrTotalColumns - 1} colSpan={1}>
        <Amount>{formatCurrency(value, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default ResultTableSubSummary;
