import { useContext } from 'react';
import './PdfPageSafeZoneWrapper.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from '../../Translation/translations';

const PdfSafeZoneWrapper = ({ children, pageNumber }) => {
  const { tl } = useContext(LanguageContext);

  return (
    <div className="pdf-safe-zone-wrapper">
      {children}
      {
        pageNumber === 0 ? (
          <>
            <div className="safe-zone-overlay-first-page-top-left" />
            <div className="safe-zone-overlay-first-page-top-right" />
            <div className="safe-zone-overlay-first-page-top-right-bottom" />
            <div className="safe-zone-overlay-first-page-bottom">
              <span className="safe-zone-text">{tl(translations.pdfViewer.safeArea)}</span>
            </div>
          </>
        ) : (
          <div className="safe-zone-overlay" />
        )
      }
    </div>
  );
};

export default PdfSafeZoneWrapper;
