import React, { useContext, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { Modal } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import { fileUploadProps } from '../FileUpload/FileUpload';
import { useFileUpload } from '../FileUpload/UseFileUpload';
import { ICONS } from '../../../components/icons';
import './FileUploadLine.scss';
import { translations } from '../../Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';

interface fileUploadLineProps extends fileUploadProps {
  disabledButtonLabel?: string
  nameKey?: string
  urlKey?: string
  value: any
  accept?: string,
}

export default function FileUploadLine(props: fileUploadLineProps) {
  const { tl } = useContext(LanguageContext);

  const {
    disabled, nameKey, urlKey, descriptionLabel, name, buttonLabel, accept, disabledButtonLabel, propertyId, documentTag, sourceType, ocrState,
  } = props;

  const fileUploader = useFileUpload('', sourceType, ocrState);

  const onChange = (event: any) => {
    const fileLocationAndName = event.target.value.split('\\');
    const fileName = fileLocationAndName[fileLocationAndName.length - 1];

    fileUploader.onChange(event, (url: string) => {
      let returnValue: any;
      if (nameKey && urlKey) {
        returnValue = {};
        returnValue[nameKey] = fileName;
        returnValue[urlKey] = url;
      } else {
        returnValue = url;
      }
      props.onChange(returnValue);
    }, propertyId, documentTag);
  };

  const onDelete = () => {
    fileUploader.onDeleteFile();
    props.onChange('');
  };

  const value = useMemo(() => {
    if (!props.value) return undefined;
    if (urlKey) return props.value[urlKey];
    return props.value;
  }, [props.value, urlKey]);

  const onDownload = (e: any) => {
    e.preventDefault();
    if (value) fileUploader.onDownload(value);
  };

  return (
    <div className={`FileUploadLine ${disabled ? 'disabled' : ''}`}>
      <div className="line">
        {fileUploader.uploading ? <LoadingIndicator />
          : (
            <>
              <Icon component={ICONS.file} />
              <div className="file-name">
                {value ? (
                  <a href={value} onClick={onDownload} tabIndex={value ? 0 : -1}>
                    {descriptionLabel}
                  </a>
                ) : (
                  <div tabIndex={value ? 0 : -1}>
                    {descriptionLabel}
                  </div>
                )}
              </div>
            </>
          )}
        {value ? (
          <button className="delete-button" onClick={fileUploader.onDisplayModal}>
            <Icon component={ICONS.bin} />
          </button>
        )
          : (!disabled
            ? (
              <div>
                <label
                  htmlFor={`${name}-file-input`}
                  className="file-input-label"
                  tabIndex={0}
                >
                  <Icon component={ICONS.upload} />
                  {buttonLabel || tl(translations.elements.fileUpload.buttonLabelLine)}
                </label>
                <input
                  id={`${name}-file-input`}
                  className="file-input"
                  type="file"
                  value=""
                  accept={accept}
                  onChange={onChange}
                  disabled={disabled || false}
                />
              </div>
            )
            : disabledButtonLabel && (<div className="file-input-label disabled">{disabledButtonLabel}</div>)
          )}
      </div>
      <Modal
        visible={fileUploader.showModal}
        title={tl(translations.elements.fileUpload.modal.title)}
        onCancel={fileUploader.onHideModal}
        footer={[
          <Button key="cancel" type="text" onClick={fileUploader.onHideModal}>
            {tl(translations.elements.fileUpload.modal.cancelButton)}
          </Button>,
          <Button
            key="delete"
            type="primary"
            onClick={() => {
              onDelete();
              fileUploader.onHideModal();
            }}
          >
            {tl(translations.elements.fileUpload.modal.deleteButton)}
          </Button>,
        ]}
      >
        {tl(translations.elements.fileUpload.modal.description)}
      </Modal>
    </div>
  );
}

FileUploadLine.defaultProps = {
  accept: '*',
};
