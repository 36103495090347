import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../../elements/Translation/translations';
import { FORMAT } from '../../../../elements/SmartTable/SmartTable';

export const UseUnitTotalAmountColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: '',
      dataKey: 'amountType',
      defaultWidth: '50%',
    },
    {
      title: tl(translations.pages.economicPlan.unitAccountBalances.totalAmount.table.headers.total),
      dataKey: 'amount',
      className: 'currency',
      defaultWidth: '25%',
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.economicPlan.unitAccountBalances.totalAmount.table.headers.share),
      dataKey: 'share',
      className: 'currency',
      defaultWidth: '25%',
      format: FORMAT.CURRENCY,
    },
  ];
};
