import { SPACE_SIZES } from 'lib/constants';
import {
  Alert, Col, Modal, Row,
} from 'antd';
import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import RadioGroupInput from 'elements/Inputs/RadioGroupInput/RadioGroupInput';
import { useContext } from 'react';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import './DunningFeeModal.scss';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { Link } from 'react-router-dom';
import { DunningFee } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { useDunningFeeModal } from './useDunningFeeModal';

interface DunningFeeModalProps {
  accountCode: string;
  dunningFeeLocalValue: DunningFee;
  onCloseModal: () => void,
  modalVisible: boolean;
}

export const DunningFeeModal = ({
  accountCode, dunningFeeLocalValue, onCloseModal, modalVisible,
}: DunningFeeModalProps) => {
  const { tl } = useContext(LanguageContext);

  const {
    onApply, onCancel, onChangeAmount, amount, onChangeApplyTheFeeChange, applyTheFeeChange, options, infoAlertIsDisplayed, propertyHrId, loading,
  } = useDunningFeeModal({
    accountCode, dunningFeeLocalValue, onCloseModal,
  });

  const linkButton = (
    <Link to={`/properties/${propertyHrId}/edit`} target="_blank">
      {tl(orderOpenBalancesTranslations.dunningFeeModal.infoAlertText.linkText)}
    </Link>
  );


  return (
    <Modal
      visible={modalVisible}
      className="DunningFeeModal"
      onCancel={onCancel}
      onOk={onApply}
      destroyOnClose
      title={tl(orderOpenBalancesTranslations.dunningFeeModal.title)}
      okButtonProps={{ className: 'Button', loading }}
      okText={tl(orderOpenBalancesTranslations.dunningFeeModal.applyButton)}
      cancelButtonProps={{ className: 'Button', loading }}
      cancelText={tl(orderOpenBalancesTranslations.dunningFeeModal.cancelButton)}
      getContainer={() => document.getElementById('root') ?? document.body}
    >
      <Row gutter={[SPACE_SIZES.medium, SPACE_SIZES.medium]} className="DunningFeeCell">
        <Col span={24}>
          <CurrencyInput
            className="input"
            label={tl(orderOpenBalancesTranslations.dunningFeeModal.amountInputTitle)}
            value={amount ?? 0}
            onChange={onChangeAmount}
          />
        </Col>
        <Col span={24}>
          <RadioGroupInput
            label={tl(orderOpenBalancesTranslations.dunningFeeModal.radioButtonTitle)}
            onChange={(value) => { onChangeApplyTheFeeChange(value); }}
            value={applyTheFeeChange}
            className="RadioGroup"
            options={options}
          />
        </Col>
        {infoAlertIsDisplayed && (
          <Col>
            <Alert
              message={(
                <>
                  {tl(orderOpenBalancesTranslations.dunningFeeModal.infoAlertText.firstPart)}
                  {linkButton}
                  {tl(orderOpenBalancesTranslations.dunningFeeModal.infoAlertText.lastPart)}
                </>
              )}
              type="info"
              showIcon
              icon={<Icon component={ICONS.info} />}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};
