let counter = 0;

const scriptMap = new Map();

/**
 * As far as I can understand it, what this does is it takes links to packages
 * and adds them as <script>'s to the document body.
 * The benefit, I believe, is that for big npm packages we wouldn't bundle it with
 * the app every time it is loaded, only on the pages where it is needed.
 * E.g. pdfJs is a ~30MB library, so we only want to download it when working with PDFs.
 */
export const ScriptCache = (global => function scriptCache(scripts: any) {
  const Cache :any = {};

  Cache._onLoad = (key: any) => (cb: any) => {
    const stored = scriptMap.get(key);
    if (stored) {
      stored.promise.then(() => {
        stored.error
          ? cb(stored.error)
          : cb(null, stored);
      });
    }
  };

  Cache._scriptTag = (key: any, src:any) => {
    if (!scriptMap.has(key)) {
      const tag = document.createElement('script');
      const promise = new Promise((resolve:any, reject:any) => {
        const body = document.getElementsByTagName('body')[0];

        tag.type = 'text/javascript';
        tag.async = false;

        const cbName = `loaderCB${counter}${Date.now()}`;
        counter += 1;

        const handleResult:any = (state: any) => (evt:any) => {
          const stored = scriptMap.get(key);
          if (state === 'loaded') {
            stored.resolved = true;
            resolve(src);
          } else if (state === 'error') {
            stored.errored = true;
            reject(evt);
          }

          // cleanup();
        };
        tag.onload = handleResult('loaded');
        tag.onerror = handleResult('error');

        // Pick off callback, if there is one
        if (src.match(/callback=CALLBACK_NAME/)) {
          src = src.replace(/(callback=)[^&]+/, `$1${cbName}`);
        } else {
          tag.addEventListener('load', () => { handleResult('loaded'); });
        }
        tag.addEventListener('error', () => { handleResult('error'); });

        tag.src = src;
        body.appendChild(tag);
        return tag;
      });
      const initialState = {
        loaded: false,
        error: false,
        promise,
        tag,
      };
      scriptMap.set(key, initialState);
    }
    return scriptMap.get(key);
  };

  Object.keys(scripts).forEach((key) => {
    const script = scripts[key];
    Cache[key] = {
      tag: Cache._scriptTag(key, script),
      onLoad: Cache._onLoad(key),
    };
  });

  return Cache;
})();
