import { BankDetailsDto } from 'api/accounting';
import _ from 'lodash';

export enum CONTACT_PREFERENCES { EMAIL = 'EMAIL', POST = 'POST', PORTAL = 'PORTAL'}

export function convertToBEModel(contact: any) {
  const modifiedContact = _.cloneDeep(contact);
  modifiedContact.contactByEmail = false;
  modifiedContact.contactByPost = false;
  modifiedContact.contactByPortal = false;
  if (modifiedContact.contactPreferences) {
    modifiedContact.contactByEmail = modifiedContact.contactPreferences.indexOf(CONTACT_PREFERENCES.EMAIL) > -1;
    modifiedContact.contactByPost = modifiedContact.contactPreferences.indexOf(CONTACT_PREFERENCES.POST) > -1;
    modifiedContact.contactByPortal = modifiedContact.contactPreferences.indexOf(CONTACT_PREFERENCES.PORTAL) > -1;
    delete modifiedContact.contactPreferences;
  }
  if (modifiedContact.type === 'PERSON') delete modifiedContact.linkedByContacts;
  if (modifiedContact.type === 'COMPANY') delete modifiedContact.linkedToContacts;
  if (modifiedContact.addresses && modifiedContact.addresses.length === 2) {
    if (!modifiedContact.addresses[0].forMail && !modifiedContact.addresses[0].forInvoice) {
      modifiedContact.addresses[0].forMail = true;
    }
    if (!modifiedContact.addresses[1].forMail && !modifiedContact.addresses[1].forInvoice) {
      modifiedContact.addresses[1].forInvoice = true;
    }
  }
  if (modifiedContact.addresses && modifiedContact.addresses.length > 0) {
    // make it possible to delete addresses
    // delete invoicing address if it is empty
    if (modifiedContact.addresses.length > 0 && _.isEqual(modifiedContact.addresses[1], { forInvoice: true })) {
      modifiedContact.addresses.splice(1, 1);
    }
    // delete mailing address if it is empty and if there is no invoicing address added
    if (modifiedContact.addresses.length === 1 && _.isEqual(modifiedContact.addresses[0], { forMail: true })) {
      modifiedContact.addresses.splice(0, 1);
    }
  }

  if (modifiedContact.bankAccounts && modifiedContact.bankAccounts.length > 0) {
    // make it possible to delete bank accounts
    for (let i = 0; i < modifiedContact.bankAccounts.length; i += 1) {
      if (_.isEmpty(modifiedContact.bankAccounts[i])) {
        // delete empty bankAccount object
        modifiedContact.bankAccounts.splice(i, 1);
        i -= 1;
      }
    }

    modifiedContact.bankAccounts = modifiedContact.bankAccounts.map((ba: BankDetailsDto) => ({ ...ba, iban: ba?.iban?.replaceAll(/\s/g, '')?.toUpperCase() }));
  }
  return modifiedContact;
}

export function convertToFEModel(contact: any) {
  const modifiedContact = contact;
  modifiedContact.contactPreferences = [];
  if (modifiedContact.contactByEmail) modifiedContact.contactPreferences.push(CONTACT_PREFERENCES.EMAIL);
  if (modifiedContact.contactByPost) modifiedContact.contactPreferences.push(CONTACT_PREFERENCES.POST);
  if (modifiedContact.contactByPortal) modifiedContact.contactPreferences.push(CONTACT_PREFERENCES.PORTAL);
  modifiedContact.addressNumber = modifiedContact.addresses ? modifiedContact.addresses.length : 1;
  modifiedContact.bankAccountNumber = modifiedContact.bankAccounts ? modifiedContact.bankAccounts.length : 0;
  modifiedContact.companyNumber = modifiedContact.linkedToContacts ? modifiedContact.linkedToContacts.length : 1;
  modifiedContact.employeeNumber = modifiedContact.linkedByContacts ? modifiedContact.linkedByContacts.length : 1;
  return modifiedContact;
}
