import React, { useContext } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../../../translations';
import { formatCurrency } from '../../../../../lib/Utils';
import './BankTransactionAllocationDetails.scss';
import { ExtendedBankTransaction } from '../../../interfaces';

export default function BankTransactionAllocationDetails({
  bankTransaction, remainingAmount, amountAllocated, numberOfTransactions,
}: { bankTransaction: ExtendedBankTransaction, remainingAmount: number, amountAllocated: number, numberOfTransactions: number, }) {
  const { tl } = useContext(LanguageContext);
  const {
    amount,
  } = bankTransaction || {};

  const transactionDirection = amount !== undefined && amount < 0 ? 'outgoing' : 'incoming';


  let icon;
  if (remainingAmount === 0) {
    icon = <CheckCircleFilled />;
  } else if (remainingAmount < 0) {
    icon = <CloseCircleFilled />;
  } else {
    icon = null;
  }

  const openOrExceededLabel = remainingAmount >= 0
    ? tl(translations.bankTransactionAllocation.header.open)
    : tl(translations.bankTransactionAllocation.header.exceeded);

  return (
    <div className="BankTransactionAllocationDetails">
      <div className="left">
        <div className={transactionDirection}>
          {tl(translations.table[transactionDirection])}
        </div>
      </div>

      <div className="right">
        {icon}
        <div className="pair">
          <span className="label-light">{`${openOrExceededLabel}:`}</span>
          <Amount className="value">{formatCurrency(Math.abs(remainingAmount))}</Amount>
        </div>
        <span className="separator">|</span>
        <div className="pair">
          <span className="label-dark">{`${tl(translations.bankTransactionAllocation.header.allocating)}:`}</span>
          <Amount className="value">{numberOfTransactions > 1 ? `${numberOfTransactions} × ${formatCurrency(amountAllocated ?? 0)}` : formatCurrency(amountAllocated ?? 0)}</Amount>
        </div>
      </div>
    </div>
  );
}
