/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data that describes an existing connection
 * @export
 * @interface ConnectionUpdateLegacyDto
 */
export interface ConnectionUpdateLegacyDto {
    /**
     * Authentication credentials for the external app's platform, if the integration requires it.
     * @type {string}
     * @memberof ConnectionUpdateLegacyDto
     */
    externalConfig?: string;
    /**
     * Management company identifier, if the integration requires it.
     * @type {number}
     * @memberof ConnectionUpdateLegacyDto
     */
    managementCompanyId?: number;
    /**
     * Name of the connection as it shall be presented in the UI.
     * If not specified, will be defaulted to the name of the application 
     * @type {string}
     * @memberof ConnectionUpdateLegacyDto
     */
    name?: string;
    /**
     * The URL will be called with an authorization token you may validate and the following body: {"connectionId":123,"entityType":"properties","entityId":123,"eventType": "UPDATE"}
     * @type {string}
     * @memberof ConnectionUpdateLegacyDto
     */
    webhookUrl?: string;
}

/**
 * Check if a given object implements the ConnectionUpdateLegacyDto interface.
 */
export function instanceOfConnectionUpdateLegacyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectionUpdateLegacyDtoFromJSON(json: any): ConnectionUpdateLegacyDto {
    return ConnectionUpdateLegacyDtoFromJSONTyped(json, false);
}

export function ConnectionUpdateLegacyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionUpdateLegacyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalConfig': !exists(json, 'externalConfig') ? undefined : json['externalConfig'],
        'managementCompanyId': !exists(json, 'managementCompanyId') ? undefined : json['managementCompanyId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'webhookUrl': !exists(json, 'webhookUrl') ? undefined : json['webhookUrl'],
    };
}

export function ConnectionUpdateLegacyDtoToJSON(value?: ConnectionUpdateLegacyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalConfig': value.externalConfig,
        'managementCompanyId': value.managementCompanyId,
        'name': value.name,
        'webhookUrl': value.webhookUrl,
    };
}

