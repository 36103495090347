import { useContext } from 'react';
import { HouseMoneySectionUpdatersContext, OpsCostContext } from '../../../../services/HouseMoneyContext';
import { ActionTypes } from '../../services/ActionTypes';
import { useSimpleDirtModal } from '../../../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export const useOpsCostCell = (unitId: number) => {
  const updatersContext = useContext(HouseMoneySectionUpdatersContext);
  const opsCostContext = useContext(OpsCostContext);

  if (updatersContext === undefined || opsCostContext === undefined) {
    throw new Error('useOpsCostCell must be used within a HouseMoneyListContextProvider');
  }

  const { setOpsCosts } = updatersContext;
  const { addDirt } = useSimpleDirtModal();
  const value = opsCostContext.opsCosts.find(opsCost => opsCost.unitId === unitId);
  const onChange = (newVal: number) => {
    setOpsCosts(prevState => prevState.map((opsCost) => {
      if (opsCost.unitId === unitId) {
        return {
          ...opsCost,
          amount: newVal,
          action: !opsCost.action ? ActionTypes.UPDATE : opsCost.action,
        };
      }
      return opsCost;
    }));
    addDirt();
  };


  return {
    value: value?.amount ?? 0,
    onChange,
  };
};
