import React, { useContext, useMemo } from 'react';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import SmartTable from '../../../../../../elements/SmartTable/SmartTable';
import useSmartTable from '../../../../../../elements/SmartTable/useSmartTable';
import { translations } from '../../../../../../elements/Translation/translations';
import { useTotalAmountsOverviewColumns } from '../useTotalAmountsOverviewColumns';
import './IncomeExpensesOverviewCard.scss';

interface incomeExpensesOverviewCard {
  applicableExpenses: any[],
  notApplicableExpenses: any[],
  income: any[],
  loading: boolean,
}

export default function IncomeExpensesOverviewCard(props: incomeExpensesOverviewCard) {
  const { tl } = useContext(LanguageContext);
  const {
    applicableExpenses, notApplicableExpenses, income, loading,
  } = props;

  const toTableData = (data: any) => ({
    ...data,
    account: `${data.accountCode} ${data.accountName}`,
  });

  const applicableDataSource = useMemo(() => (applicableExpenses ? applicableExpenses.map(toTableData) : []), [applicableExpenses]);

  const applicableExpensesSmartTable = useSmartTable({
    tableName: 'applicableExpensesTable',
    columns: useTotalAmountsOverviewColumns(),
    dataSource: applicableDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'accountCode',
  });

  const notApplicableDataSource = useMemo(() => (notApplicableExpenses ? notApplicableExpenses.map(toTableData) : []), [notApplicableExpenses]);

  const notApplicableExpensesSmartTable = useSmartTable({
    tableName: 'notApplicableExpensesTable',
    columns: useTotalAmountsOverviewColumns(),
    dataSource: notApplicableDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'accountCode',
  });

  const incomeDataSource = useMemo(() => (income ? income.map(toTableData) : []), [income]);
  const incomeTable = useSmartTable({
    tableName: 'incomeTable',
    columns: useTotalAmountsOverviewColumns(),
    dataSource: incomeDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'accountCode',
  });

  return (
    <div className="IncomeExpensesOverviewCard">
      <div className="subtitle" id="applicableExpensesOverview">
        {tl(translations.pages.economicPlan.propertyAccountBalances.expenses.applicableExpenses)}
      </div>
      <SmartTable {...applicableExpensesSmartTable} />
      <div className="subtitle" id="notApplicableExpensesOverview">
        {tl(translations.pages.economicPlan.propertyAccountBalances.expenses.notApplicableExpenses)}
      </div>
      <SmartTable {...notApplicableExpensesSmartTable} />
      <div className="subtitle" id="incomeOverview">
        {tl(translations.pages.economicPlan.propertyAccountBalances.income.title)}
      </div>
      <SmartTable {...incomeTable} />
    </div>
  );
}
