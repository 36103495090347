import React from 'react';
import { DefaultSectionSummaryContent } from '../services/interfaces';
import { instanceOfDefaultSectionSummaryContent } from '../services/utils';

interface Props {
  item: React.ReactNode | DefaultSectionSummaryContent;
}

const SectionSummaryContent = ({ item }: Props) => {
  if (instanceOfDefaultSectionSummaryContent(item)) {
    return (
      <div className="default-summary-content">
        {item.title && <div className="summary-content-title">{item.title}</div>}
        {item.value && <div className="summary-content-value">{item.value}</div>}
      </div>
    );
  }

  return <>{item}</>;
};

export default SectionSummaryContent;
