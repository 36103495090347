import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import './YourSettlementSection.scss';
import { ConfigProvider, Table } from 'antd';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import YourSettlementTableSummary from './components/YourSettlementTableSummary';
import { useYourSettlementSection as hook } from './useYourSettlementSection';
import { useIndividualTenantCostsManualTotalFromStore, useOtherOrTransferableCostsManualTotalFromStore, useYourPrepaymentsManualTotalFromStore } from '../../services/stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';

type ReturnTypeOfHook = ReturnType<typeof hook>;
export type GetYourSettlementSectionProps = ReturnTypeOfHook['getYourSettlementSectionProps'];
export type ReturnTypeOfYourSettlementHook = ReturnType<GetYourSettlementSectionProps>

interface YourSettlementSectionProps {
  tl: any,
  openSectionId: number,
  useYourSettlementSection: ReturnTypeOfYourSettlementHook,
  contractId: number,
}


const YourSettlementSection = ({
  tl,
  openSectionId,
  useYourSettlementSection,
  contractId,
}: YourSettlementSectionProps) => {
  const {
    columns,
    getSettlementDataSourceIncludingManualAmounts,
    getTotalSummaryDataIncludingManualAmounts,
    loading,
  } = useYourSettlementSection;

  const prepaymentsManualTotal = useYourPrepaymentsManualTotalFromStore(contractId);
  const otherOrTransferableCostsManualTotal = useOtherOrTransferableCostsManualTotalFromStore(contractId);
  const individualTenantCostsManualTotal = useIndividualTenantCostsManualTotalFromStore(contractId);

  const settlementDataSource = getSettlementDataSourceIncludingManualAmounts(prepaymentsManualTotal, otherOrTransferableCostsManualTotal, individualTenantCostsManualTotal);
  const totalSummaryData = getTotalSummaryDataIncludingManualAmounts(prepaymentsManualTotal, otherOrTransferableCostsManualTotal, individualTenantCostsManualTotal);

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(settlementDataSource) ? { y: 200 } : undefined;

  return (
    <PrimarySectionWrapper
      sectionId="YourSettlementSection"
      className="YourSettlementSection"
      sectionNumber={1}
      sectionTitle={tl(opsCostTranslation.report.sections.yourSettlementSection.yourSettlementSectionTitle)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
    >
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <Table
          columns={columns}
          dataSource={settlementDataSource}
          rowKey="label"
          pagination={false}
          scroll={tableHeight}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <YourSettlementTableSummary
              title={tl(opsCostTranslation.report.sections.yourSettlementSection.rowLabels.total)}
              totalSummaryData={totalSummaryData}
            />
          )}
        />
      </ConfigProvider>
    </PrimarySectionWrapper>
  );
};


export default YourSettlementSection;
