import { GetVatRatesUsingGETCountryCodeEnum, VatRateControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatDate } from 'lib/Utils';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { translations } from 'pages/ManualExchange/translations';
import { showNotification } from 'lib/Notification';
import { AccountSelectionContext, AmountDistributionUpdatersAndDatasourceContext, OccurrenceValuesContext } from './ManualExchangeContext';

export const useManualExchangeTableData = () => {
  const amountDistributionUpdatersAndDatasourceContext = useContext(AmountDistributionUpdatersAndDatasourceContext);
  const accountSelectionContext = useContext(AccountSelectionContext);
  const occurrenceValuesContext = useContext(OccurrenceValuesContext);

  if (amountDistributionUpdatersAndDatasourceContext === undefined || accountSelectionContext === undefined || occurrenceValuesContext === undefined) {
    throw new Error('useManualExchangeTableData must be used within a ManualExchangeContextProvider');
  }

  const { tableDataSource, setTableDataSource, onChangeSelectedKeys } = amountDistributionUpdatersAndDatasourceContext;
  const { formValues, setVatRate } = accountSelectionContext;

  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const vatRateControllerApi = new VatRateControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (formValues.property?.vatRateCountryCode !== undefined) {
      vatRateControllerApi.getVatRatesUsingGET({
        activeOn: formatDate(moment(), 'YYYY-MM-DD'),
        countryCode: (formValues.property?.vatRateCountryCode as unknown as GetVatRatesUsingGETCountryCodeEnum),
      })
        .then((resp) => {
          if (!_.isEmpty(resp)) {
            setVatRate(resp[0]);
          }
        })
        .catch((e) => {
          console.error(e);
          showNotification({
            key: 'vatLoadError',
            message: tl(translations.creationPage.notifications.loadVatRateError),
            type: 'error',
          });
        });
    }
  }, [formValues.property?.vatRateCountryCode]);

  const onChangeSelectedRowKeys = (selectedRowKeys: any[]) => {
    setTableDataSource(prev => prev.load(
      prev.data?.map(contract => ({ ...contract, selected: selectedRowKeys.includes(contract.unitContractId!) })) ?? [],
    ));
    onChangeSelectedKeys(selectedRowKeys);
  };


  return { tableDataSource, onChangeSelectedRowKeys };
};
