import { Table } from 'antd';
import { TableSummaryIconCell } from 'elements/TableSummaryIconCell/TableSummaryIconCell';
import { formatCurrency } from 'lib/Utils';
import { isNil } from 'lodash';
import { SummaryAmounts } from 'pages/OpsCostReport/OpsCostReportEditing/services/interfaces';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
    title: string,
    totalSummaryData: SummaryAmounts,
  }

const YourSettlementTableSummary = ({
  title, totalSummaryData,
} : Props) => (
  <Table.Summary>
    <Table.Summary.Row>
      <TableSummaryIconCell colSpan={1} content={title}/>
      {!isNil(totalSummaryData.net) && (
        <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
          <Amount>{formatCurrency(totalSummaryData.net, '-', false)}</Amount>
        </Table.Summary.Cell>
      )}

      {!isNil(totalSummaryData.vat) && (
        <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
          <Amount>{formatCurrency(totalSummaryData.vat, '-', false)}</Amount>
        </Table.Summary.Cell>
      )}

      <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
        <Amount>{formatCurrency(totalSummaryData.gross, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default YourSettlementTableSummary;
