import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import './EmptyResultsHint.scss';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { ICONS } from '../icons';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from './translations';

interface EmptyResultsHintProps {
  className?: string,
  title?: string,
  subTitle?: string,
  /**
   * null: no icon will be shown
   * undefined: defaults to ICONS.magnifyingGlass
   * otherwise: provide an icon component
   */
  icon?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>,
}


export const EmptyResultsHint: React.FC<EmptyResultsHintProps> = (props) => {
  const {
    className, title, subTitle, icon, children,
  } = props;

  const { tl } = useContext(LanguageContext);
  const showIcon = icon !== null;
  return (
    <div
      className={`empty-results ${className ?? ''}`}
    >
      <div className="empty-results-wrapper">
        <div className="circle1" />
        <div className="circle2" />
        {showIcon ? <Icon component={icon || ICONS.magnifyingGlass} /> : undefined}
        <div className="title">
          {title ?? tl(translations.title)}
        </div>
        <div className="sub-title">
          {subTitle ?? tl(translations.subtitle)}
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};
