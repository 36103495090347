import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { useAllContractsList } from 'pages/Property/PropertyEditing/services/useAllContractsList';
import { MessageSendingInitializationStateType } from 'pages/MessageSendingPage/interfaces';
import { buildDocumentRecipients } from 'lib/messageUtils';
import { isEmpty, uniq } from 'lodash';
import { getHouseMoneySettlementSendingRoute } from 'pages/HouseMoneySettlement/routes';
import { useActiveContractsList } from 'services/UnitContractsList/useActiveContractsList';
import { getMessagePath } from 'pages/Messages/routes';
import { HouseMoneySettlementContext } from '../../../../../../../contexts/HouseMoneySettlementContext';
import { showNotification } from '../../../../../../../lib/Notification';
import { translations } from '../../../../../../../elements/Translation/translations';
import {
  ContractProjectionDto, GetMessagesUsingGETSourceTypeEnum, GetUnitContractsUsingGETContractTypesEnum, HouseMoneySettlementAggregationControllerApi,
} from '../../../../../../../api/accounting';
import { AuthContext } from '../../../../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import { houseMoneySettlementStateChangeButtonsTranslations } from './houseMoneySettlementStateChangeButtonsTranslations';

export function useStateChangeButtons() {
  const {
    houseMoneySettlementState,
    setHouseMoneySettlementState,
    documents,
  } = useContext(HouseMoneySettlementContext);
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);

  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  const history = useHistory();
  const houseMoneySettlementController = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));

  const settlementStatus = houseMoneySettlementState.data?.status;
  const settlementId = houseMoneySettlementState.data?.id;

  const onMarkHouseMoney = (id: number, state: 'PREPARED' | 'FINALIZED' | 'DRAFT') => {
    setHouseMoneySettlementState(currentState => currentState.startLoading());
    if (state === 'DRAFT') {
      houseMoneySettlementController.revertHouseMoneySettlementToDraftUsingPOST({ id })
        .then(response => setHouseMoneySettlementState(houseMoneySettlementState.load(response)))
        .catch(() => {
          setHouseMoneySettlementState(currentState => currentState.failed());
          showNotification({
            key: 'loadHouseMoneySettlementError',
            message: tl(translations.notifications.houseMoneySettlementContext.loadHouseMoneySettlementError.message) + tl(translations.notifications.houseMoneySettlementContext.status[state]),
            type: 'error',
          });
        });
    } else if (state === 'FINALIZED') {
      history.push(`/house-money-settlement/${id}/finalize`);
    } else {
      houseMoneySettlementController.markHouseMoneySettlementPreparedUsingPOST({ id })
        .then(response => setHouseMoneySettlementState(houseMoneySettlementState.load(response)))
        .catch(() => {
          setHouseMoneySettlementState(currentState => currentState.failed());
          showNotification({
            key: 'loadHouseMoneySettlementError',
            message: tl(translations.notifications.houseMoneySettlementContext.loadHouseMoneySettlementError.message) + tl(translations.notifications.houseMoneySettlementContext.status[state]),
            type: 'error',
          });
        });
    }
  };

  const onMarkHouseMoneySettlementPrepared = (id: number) => {
    onMarkHouseMoney(id, 'PREPARED');
  };
  const onMarkHouseMoneySettlementFinalized = (id: number) => {
    onMarkHouseMoney(id, 'FINALIZED');
  };
  const onRevertHouseMoneySettlementToDraft = (id: number) => {
    Modal.confirm({
      icon: <></>,
      title: tl(houseMoneySettlementStateChangeButtonsTranslations.revertModal.title),
      content: tl(houseMoneySettlementStateChangeButtonsTranslations.revertModal.text),
      okText: tl(houseMoneySettlementStateChangeButtonsTranslations.revertModal.confirmText),
      cancelText: tl(houseMoneySettlementStateChangeButtonsTranslations.revertModal.cancelText),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: () => onMarkHouseMoney(id, 'DRAFT'),
      width: 750,
    });
  };

  const { allContractsList } = useAllContractsList(houseMoneySettlementState.data?.propertyId);
  const { activeContractsList } = useActiveContractsList({ propertyId: houseMoneySettlementState.data?.propertyId, contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER] });

  const onSend = () => {
    const contractIds = uniq(documents?.filter(d => d.contractId).map(d => d.contractId));
    const documentRecipients = documents?.filter(d => d.contractId !== undefined || d.unitId !== undefined).map((doc) => {
      const contract = doc.contractId ? allContractsList.data?.find(c => c.unitContractId === doc.contractId) : activeContractsList.data?.find(c => c.unitId === doc.unitId);
      return buildDocumentRecipients(doc?.id, [contract as unknown as ContractProjectionDto]);
    });

    const overview = documents?.find(d => !d.contractId && !d.unitId);

    if (overview) {
      // older hga documents only referenece unit
      if (isEmpty(contractIds)) {
        documentRecipients.push(buildDocumentRecipients(overview?.id, activeContractsList.data as unknown as ContractProjectionDto[]));
      }
      documentRecipients.push(buildDocumentRecipients(overview?.id, allContractsList.data?.filter(c => contractIds.includes(c.unitContractId)) as unknown as ContractProjectionDto[]));
    }

    const messageSendingNavigationState: MessageSendingInitializationStateType = {
      sourceType: 'HOUSE_MONEY_SETTLEMENT',
      sourceId: settlementId,
      properties: [{
        propertyId: houseMoneySettlementState.data?.propertyId,
        documents: [...documentRecipients],
      }],
    };

    const sendUrl = getHouseMoneySettlementSendingRoute({ propertyHrId });
    history.replace(sendUrl, messageSendingNavigationState);
  };

  const onCheckMessages = () => {
    history.push(getMessagePath(settlementId, GetMessagesUsingGETSourceTypeEnum.HOUSE_MONEY_SETTLEMENT));
  };

  return {
    settlementStatus,
    onMarkHouseMoneySettlementFinalized,
    onMarkHouseMoneySettlementPrepared,
    onRevertHouseMoneySettlementToDraft,
    settlementId,
    onSend,
    onCheckMessages,
  };
}
