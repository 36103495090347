export const translations: any = {
  searchBar: {
    filter: {
      booleanValues: {
        true: {
          en: 'True',
          de: 'Wahr',
        },
        false: {
          en: 'False',
          de: 'Falsch',
        },
      },
    },
    placeholder: {
      en: 'Search',
      de: 'Suche',
    },
    dropdown: {
      hint: {
        en: '↑↓ Select · ENTER Open · / Next Query',
        de: '↑↓ Wählen · ENTER Öffnen · / Nächste Abfrage',
      },
    },
  },
  quickAddMenu: {
    create: {
      en: 'Create',
      de: 'Erstellen',
    },
    shortcutHint: {
      en: '↑↓ to browse  ·  ENTER to open',
      de: '↑↓ durchsuchen  ·  ENTER öffnen',
    },
    menuOptions: {
      property: {
        de: 'Objekt',
        en: 'Property',
      },
      invoiceUpload: {
        en: 'Invoice Upload',
        de: 'Rechnung hochladen',
      },
      invoiceManual: {
        en: 'Invoice manually',
        de: 'Rechnung manuell',
      },
      ownersMeeting: {
        en: 'Owners meeting invitation',
        de: 'ETV',
      },
      ownersMeetingProtocol: {
        en: 'Owners meeting protocol',
        de: 'ETV Übersicht',
      },
      economicPlan: {
        en: 'Economic plan',
        de: 'WP',
      },
      contactCompany: {
        en: 'Contact: Company',
        de: 'Kontakt: Unternehmen',
      },
      contactPerson: {
        en: 'Contact: Person',
        de: 'Kontakt: Person',
      },
      specialContribution: {
        en: 'Special Contribution',
        de: 'Sonderumlage',
      },
      opsCostReport: {
        en: 'Ops Cost Reports',
        de: 'Betriebskostenabrechnung',
      },
    },
  },
};
