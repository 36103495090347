import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import Button from 'elements/Buttons/Button/Button';
import {
  Prompt, useHistory, useLocation, useParams,
} from 'react-router';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import './OwnersMeetingProtocolEditing.scss';
import { generateMenuFromSections } from '../../../../lib/Utils';
import { OwnersMeetingProtocolContext } from '../../services/OwnersMeetingProtocolContext';
import FormSidebar from '../../../../elements/FormElements/FormSidebar/FormSidebar';
import { translations as generalTranslations } from '../../../../elements/Translation/translations';
import FormSection from '../../../../elements/FormElements/FormSection/FormSection';
import useProtocolSetupSection from './services/useProtocolSetupSection';
import useConfirmProtocolSection from './services/confirmProtocolSection/useConfirmProtocolSection';
import { OwnersMeetingProtocolDtoStatusEnum } from '../../../../api/accounting';
import { useAgendaItemsSection } from './pages/OwnersMeetingProtocolAgendaItems/useAgendaItemsSection';
import { OwnersMeetingProtocolAttendance } from './pages/OwnersMeetingProtocolAttendance/OwnersMeetingProtocolAttendance';
import OwnerMeetingProtocolEditingHeader from './components/OwnerMeetingProtocolEditingHeader/OwnerMeetingProtocolEditingHeader';
import useProtocolGeneralDataSection from './services/useProtocolGeneralDataSection';

export default function OwnersMeetingProtocolEditing(): JSX.Element {
  const { etvId } = useParams<{ etvId: string }>();
  const location = useLocation();
  const viewMode = location.pathname.split('/')[3] === 'view';
  const createMode = location.pathname.split('/')[3] === 'create';

  const [dirtModalVisible, setDirtModalVisible] = useState(false);
  const [dirtModalSection, setDirtModalSection] = useState(-1);
  const openNextSectionOnSuccessRef = useRef(false);
  const [editEnabled, setEditEnabled] = useState(!viewMode);
  const { tl } = useContext(LanguageContext);
  const ownersMeetingProtocolContext: any = useContext(OwnersMeetingProtocolContext);

  const {
    setSelectedOwnersMeetingId, ownersMeeting, onSaveOwnersMeetingProtocol, onClearOwnersMeeting, updateOwnersMeetingProtocol, isDirty, setDirty,
    openSectionIndex, setOpenSectionIndex, onFinalizeOwnersMeetingProtocol, onRevertToDraft, onValidateOwnersMeetingProtocol,
  } = ownersMeetingProtocolContext;
  const sectionIsDisabled = ownersMeeting.data.id === undefined || createMode;
  const history = useHistory();

  const onClickFinalize = () => {
    onFinalizeOwnersMeetingProtocol();
    setOpenSectionIndex(-1);
    setEditEnabled(false);
  };

  useEffect(() => {
    if (etvId) setSelectedOwnersMeetingId(etvId);
    if (createMode) {
      setOpenSectionIndex(0);
    }
  }, [etvId]);

  // Clear ETV protocol data when leaving the page
  useEffect(() => () => {
    /**
     * If the user is creating a new protocol, the etvId is not set yet.
     * And if we call `onClearOwnersMeeting` on creation then after navigating to
     * the edit page the etvId will not be set correctly
     */
    if (etvId) {
      onClearOwnersMeeting();
    }
  }, []);

  useEffect(() => {
    if (createMode && ownersMeeting.data.id) {
      history.replace(`/owners-meeting/protocol/edit/${ownersMeeting.data.id}`);
    }
    if (ownersMeeting.data.id) {
      onValidateOwnersMeetingProtocol(ownersMeeting.data.id);
    }
  }, [ownersMeeting.data.id]);

  const onSuccessfulRevertToDraft = () => {
    if (viewMode) {
      history.replace(location.pathname.replace('view', 'edit'));
    }
  };

  const onSuccessfulSave = () => {
    setDirty(false);

    if (viewMode) {
      setEditEnabled(false);
      setOpenSectionIndex(-1);
    } else if (openNextSectionOnSuccessRef.current) {
      if (dirtModalSection !== -1) {
        setOpenSectionIndex(dirtModalSection);
        setDirtModalSection(-1);
      } else {
        setOpenSectionIndex((idx: number) => idx + 1);
      }
    } else {
      setOpenSectionIndex(-1);
    }

    setDirtModalVisible(false);
  };

  useEffect(() => {
    if (ownersMeeting.data) {
      if (ownersMeeting.data.status === OwnersMeetingProtocolDtoStatusEnum.SENT || ownersMeeting.data.status === OwnersMeetingProtocolDtoStatusEnum.DONE) {
        setEditEnabled(false);
      } else {
        setEditEnabled(true);
      }
    }
  }, [ownersMeeting.data]);

  const openNextSection = () => {
    setOpenSectionIndex(openSectionIndex + 1);
  };
  const sections = [
    useProtocolGeneralDataSection({ index: 0, setDirty }),
    useProtocolSetupSection({ index: 1, disabled: sectionIsDisabled }),
    useAgendaItemsSection({
      index: 2,
      openNextSection,
      showAddButton: editEnabled && openSectionIndex === 2,
      disabled: sectionIsDisabled,
    }),
    useConfirmProtocolSection({
      index: 3, etvId, setDirty, isSectionDisabled: sectionIsDisabled,
    }),
    // useSendProtocolSection({ index: 3 }),
  ];

  const save = (openNextSectionOnSuccess: boolean): void => {
    openNextSectionOnSuccessRef.current = openNextSectionOnSuccess;
    setDirtModalSection(-1);
    onSaveOwnersMeetingProtocol(openSectionIndex + 1, onSuccessfulSave);
  };

  const handleDirtModalDiscard = (): void => {
    setDirtModalVisible(false);
    setDirty(false);
    if (etvId) {
      setSelectedOwnersMeetingId(etvId);
    } else {
      onClearOwnersMeeting();
    }

    if (openSectionIndex === dirtModalSection) setOpenSectionIndex(-1); else setOpenSectionIndex(dirtModalSection);
  };

  const handleDirtModalSave = (): void => {
    openNextSectionOnSuccessRef.current = true;
    onSaveOwnersMeetingProtocol(openSectionIndex + 1, onSuccessfulSave);
  };

  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (openSectionIndex === nextSectionIndex) {
      // if the clicked section is the current one, close the sections
      nextSectionIndex = -1;
    }
    if (isDirty) {
      setDirtModalVisible(true);
      setDirtModalSection(nextSectionIndex);
    } else {
      setOpenSectionIndex(nextSectionIndex);
    }
  };

  const menuElements: any[] = generateMenuFromSections({
    sections,
    openSectionIndex,
  });

  const isProtocolDone = useMemo(() => ownersMeeting.data && (ownersMeeting.data.status === 'SENT' || ownersMeeting.data.status === 'DONE'), [ownersMeeting.data]);


  return (
    <>
      <Page className="OwnersMeetingProtocolEditing">
        <Prompt
          when={isDirty}
          message={loc => (location.pathname === loc.pathname
            || location.pathname.includes('attendance')
            || loc.pathname.includes('attendance')
            || loc.pathname.includes('voting')
            || location.pathname.includes('voting')
            || location.pathname.includes('power-of-attorney')
            || loc.pathname.includes('power-of-attorney')
            ? true : tl(generalTranslations.elements.prompt))}
        />

        <OwnerMeetingProtocolEditingHeader
          onClickFinalize={onClickFinalize}
          onRevertToDraft={() => { onRevertToDraft(onSuccessfulRevertToDraft); }}
          save={save}
          editMode={!viewMode}
          isProtocolDone={isProtocolDone}
        />

        <PageContent className="page-content edit-mode">
          <div className="form-content">
            <div id="scrollElement">
              {sections.map((section: any) => {
                const prevSectionIndex = section.openingNumber - 1;
                const onChange = (value: any) => {
                  updateOwnersMeetingProtocol(() => value);
                };

                return (
                  <FormSection
                    key={section.openingNumber}
                    open={(openSectionIndex === prevSectionIndex)}
                    addDirt={() => setDirty(true)}
                    onClickHeader={() => handleOpenSectionChange(prevSectionIndex)}
                    onSubmit={() => save(true)}
                    onChange={onChange}
                    inputsDisabled={!editEnabled}
                    hideSaveButton={viewMode}
                    loading={ownersMeeting.loading}
                    validationErrors={ownersMeeting.validationErrors}
                    sectionDisabled={section.disabled}
                    {...section}
                  />
                );
              })}
            </div>
          </div>
          <FormSidebar
            elements={menuElements}
            openSection={openSectionIndex}
            onOpenSection={handleOpenSectionChange}
            scrollTarget={document.getElementsByClassName('form-content')[0]}
          />
        </PageContent>
        <Modal
          visible={dirtModalVisible}
          title={tl(generalTranslations.elements.dirtModal.title)}
          onCancel={() => setDirtModalVisible(false)}
          footer={[
            <Button key="cancel" type="text" onClick={() => setDirtModalVisible(false)}>
              {tl(generalTranslations.elements.dirtModal.cancel)}
            </Button>,
            <Button key="discard" type="ghost" onClick={handleDirtModalDiscard}>
              {tl(generalTranslations.elements.dirtModal.discard)}
            </Button>,
            <Button key="save" type="primary" onClick={handleDirtModalSave} loading={ownersMeeting.loading}>
              {tl(generalTranslations.elements.dirtModal.save)}
            </Button>,
          ]}
        >
          <p>{tl(generalTranslations.elements.dirtModal.description)}</p>
        </Modal>
      </Page>
      {ReactDOM.createPortal(<div id="OwnersMeetingProtocolAttendance"><OwnersMeetingProtocolAttendance /></div>, document.getElementById('print'))}
    </>
  );
}
