import React, { useContext } from 'react';
import _, { isEmpty } from 'lodash';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { RowExpandIcon } from 'elements/SmartTable/components/RowExpandIcon/RowExpandIcon';

import { LoadingIndicator } from 'elements/LoadingIndicator/LoadingIndicator';
import { Table } from 'antd';
import { useContactPropertiesColumns } from './services/useContactPropertiesColumns';
import './ContactProperties.scss';
import useContactProperties from './services/useContactProperties';


export default function ContactProperties({ contactId }: { contactId: number }): JSX.Element {
  const { contactPropertiesDataSource } = useContactProperties(contactId);
  const columns = useContactPropertiesColumns();


  if (contactPropertiesDataSource.loading) {
    return (
      <div className="ContactProperties">
        <div className="wrapper-loading">
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  if (!contactId || isEmpty(contactPropertiesDataSource.data)) {
    return null;
  }

  return (
    <div className="ContactProperties">
      <Table
        className="BasicTable"
        columns={columns}
        loading={{
          spinning: contactPropertiesDataSource.loading,
          indicator: <TableContentLoadingIndicator />,
        }}
        dataSource={contactPropertiesDataSource.data}
        pagination={false}
        expandable={{
          expandIcon: (prps: any) => <RowExpandIcon {...prps} />,
          indentSize: 50,
        }}
        scroll={{ y: 500 }}
      />
    </div>
  );
}
