import { useState } from 'react';
import { convertSizeToNumberInDocument } from '../../lib/Utils';

export default function useResizeableDrawerSize({ placement, defaultSize } : {placement: 'top' | 'right' | 'bottom' | 'left', defaultSize: number | string }) {
  const direction: 'vertical' | 'horizontal' = ['top', 'bottom'].includes(placement) ? 'vertical' : 'horizontal';

  const [height, setHeight] = useState(convertSizeToNumberInDocument(defaultSize!, direction));
  const [width, setWidth] = useState(convertSizeToNumberInDocument(defaultSize!, direction));

  return {
    height,
    setHeight,
    width,
    setWidth,
    direction,
  };
}
