import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import {
  AlertArticleLink,
} from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import {
  articleUrls,
} from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';

import { translations } from './translations';

export const NoPortalAvailableMessage = () => {
  const { tl } = useContext(LanguageContext);

  return (
    <>
      <p>
        {tl(translations.integrations.notAvailable.firstSentence)}
      </p>
      <AlertArticleLink
        url={articleUrls.contacts.integrations}
        content={tl(translations.integrations.notAvailable.secondSentence)}
      />
    </>
  );
};
