import {
  UnitContractProjectionDto,
  UnitContractProjectionDtoTypeEnum, PropertyDisplayDto, PropertyDisplayDtoAdministrationTypeEnum, PropertyDisplayDtoVatRateCountryCodeEnum, VatRateDto, UnitContractProjectionDtoVatRelevanceEnum,
} from 'api/accounting';
import React, {
  createContext, ReactNode, useMemo, useState,
} from 'react';
import { nanoid } from 'nanoid';
import moment from 'moment';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { getDefaultVatPercentageForContract } from 'lib/vatUtils';
import { ValidateStatuses } from '../../../lib/Utils';


export interface AccountsType {
  counterAccounts: Array<{ label: string; value: string }> | null;
  bankAccounts: Array<{ label: string; value: number }> | null;
  accountTags: Array<{ label: string; value: string }> | null;
}

export interface AmountToDistribute {
  accountCode: string,
  unitId: number,
  unitContractId: number,
  amount: number | undefined,
  vatPercentage: number,
  selected: boolean,
}

export interface DirectionAndProcessing {
  accountCode: string,
  direction: 'incoming' | 'outgoing',
  processing: 'PLATFORM' | 'SELF_PAYER',
  unitBankAccountId: number,
  unitContractId: number,
}

export type Distribution = AmountToDistribute & DirectionAndProcessing & { vatRelevance?: UnitContractProjectionDtoVatRelevanceEnum };

export interface Property {
  propertyHrId: string,
  id: number,
  administrationType: PropertyDisplayDtoAdministrationTypeEnum,
  vatRateCountryCode?: PropertyDisplayDtoVatRateCountryCodeEnum,
}

export interface OccurrenceValues {
  id: string,
  bookingDate: moment.Moment | undefined,
  bookingText: string | undefined,
  bankPurposeText: string | undefined,
  errors: ValidationErrors,
}

export interface FormValues {
  property: Property | undefined,
  counterAccount: string | undefined,
  contractType: UnitContractProjectionDtoTypeEnum | undefined,
  accountTag: string | undefined, // TODO enum?
  bankAccount: number | undefined,
  wasBankPurposeEditedSeparately: boolean,
}

export interface ValidationErrorValue {
  state: (typeof ValidateStatuses)[number],
  errorMessage: string,
}

export interface ValidationErrors {
  [key: string]: ValidationErrorValue,
}

export const FORM_DEFAULT_VALUES: FormValues = {
  property: undefined,
  contractType: undefined,
  counterAccount: undefined,
  accountTag: undefined,
  bankAccount: undefined,
  wasBankPurposeEditedSeparately: false,
};

export const DEFAULT_OCCURRENCE_VALUES: Array<OccurrenceValues> = [{
  id: nanoid(),
  bookingDate: undefined,
  bookingText: undefined,
  bankPurposeText: undefined,
  errors: {},
}];


const AccountSelectionContext = createContext<| {
  properties: DefaultDataInterface<PropertyDisplayDto[]>,
  setProperties: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyDisplayDto[]>>>,

  accounts: DefaultDataInterface<AccountsType>,
  setAccounts: React.Dispatch<React.SetStateAction<DefaultDataInterface<AccountsType>>>,

  formValues: FormValues,
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,

  validationErrors: ValidationErrors,
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,

  vatRate: VatRateDto,
  setVatRate: React.Dispatch<React.SetStateAction<VatRateDto>>,

  contracts: Array<UnitContractProjectionDto> | undefined,
  setContracts: React.Dispatch<React.SetStateAction<Array<UnitContractProjectionDto> | undefined>>
}
  | undefined>(undefined);


export interface TableDataSourceType extends UnitContractProjectionDto {
  selected?: boolean,
}


const AmountDistributionUpdatersAndDatasourceContext = createContext<| {
  onChangeSelectedKeys: (selectedRowKeys: any[]) => void,
  initializeDistributionValues: (unitOwners: UnitContractProjectionDto[]) => void,
  onChangeDistributionValue: (unitAccountCode: string, field: keyof Distribution, value: string | number, record: UnitContractProjectionDto) => void,
  tableDataSource: DefaultDataInterface<Array<TableDataSourceType>>,
  setTableDataSource: React.Dispatch<React.SetStateAction<DefaultDataInterface<Array<TableDataSourceType>>>>,
}
  | undefined>(undefined);


const AmountToDistributeInputValuesContext = createContext<AmountToDistribute[] | undefined>(undefined);
const OccurrenceValuesContext = createContext<{
  occurrenceValues: OccurrenceValues[],
  setOccurrenceValues: React.Dispatch<React.SetStateAction<OccurrenceValues[]>>,

} | undefined>(undefined);
const DirectionAndProcessingInputValuesContext = createContext<DirectionAndProcessing[] | undefined>(undefined);


/**
 * amountToDistributeInputValues and directionAndProcessingInputValues had to be split because
 * the value of the ProcessingSelector depends on another input value (the direction)
 * therefore creating a dependency between the two, which means that whenever the input value
 * changes then a rerender is triggered (for the whole list), and it was causing the amount input
 * to be laggy
 */


const ManualExchangeContextProvider = ({ children }: { children: ReactNode }) => {
  const [accounts, setAccounts] = useState(DEFAULT_DATA<AccountsType>({ counterAccounts: null, bankAccounts: null, accountTags: null }));
  const [properties, setProperties] = useState(DEFAULT_DATA<Array<PropertyDisplayDto>>([]));
  const [contracts, setContracts] = useState<Array<UnitContractProjectionDto> | undefined>(undefined);
  const [formValues, setFormValues] = useState(FORM_DEFAULT_VALUES);
  const [amountToDistributeInputValues, setAmountToDistributeInputValues] = useState<Array<AmountToDistribute> | undefined>(undefined);
  const [occurrenceValues, setOccurrenceValues] = useState<Array<OccurrenceValues>>(DEFAULT_OCCURRENCE_VALUES);
  const [directionAndProcessingInputValues, setDirectionAndProcessingInputValues] = useState<Array<DirectionAndProcessing> | undefined>(undefined);
  const [tableDataSource, setTableDataSource] = useState(DEFAULT_DATA<Array<UnitContractProjectionDto>>(undefined));
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [vatRate, setVatRate] = useState<VatRateDto>(undefined);

  const memoizedDistributionProviderValues = useMemo(() => {
    const onChangeDistributionValue = (accountCode: string, field: keyof Distribution, value: string | number, record: UnitContractProjectionDto) => {
      if (['amount', 'selected'].includes(field)) {
        setAmountToDistributeInputValues(prev => prev?.map(item => (item.accountCode === accountCode ? {
          ...item,
          [field]: value,
        } : item)) ?? []);
      } else {
        setDirectionAndProcessingInputValues(prev => prev?.map((item) => {
          if (item.accountCode !== accountCode) return item;

          if (field !== 'direction') return { ...item, [field]: value };

          // field is direction
          const processing = (value === 'incoming' && !record.hasMandateAtDate) || (value === 'outgoing' && !record.bankAccountIban)
            ? 'SELF_PAYER'
            : item.processing;

          return {
            ...item,
            [field]: value as 'incoming' | 'outgoing',
            processing,
          };
        }));
      }
    };

    const onChangeSelectedKeys = (selectedRowKeys: any[]) => {
      setAmountToDistributeInputValues(prev => prev?.map(item => ({ ...item, selected: selectedRowKeys.includes(item.unitContractId!), amount: !selectedRowKeys.includes(item.unitContractId!) ? 0 : item.amount })));
    };

    const initializeDistributionValues = (contractsParam: UnitContractProjectionDto[]) => {
      const reduceInitialValue: [AmountToDistribute[], DirectionAndProcessing[]] = [[], []];

      const [initialAmountsToDistribute, initialDirectionAndProcessing] = contractsParam.reduce((acc, contract) => {
        const [amountAndSelected, directionAndProcessing] = acc;
        const vatPercentage = vatRate
          ? getDefaultVatPercentageForContract(vatRate, contract.vatRelevance)
          : 0;

        amountAndSelected.push({
          accountCode: contract.accountCode!,
          unitId: contract.unitId!,
          unitContractId: contract.unitContractId!,
          amount: undefined,
          vatPercentage,
          selected: true,
        });

        directionAndProcessing.push({
          accountCode: contract.accountCode!,
          unitBankAccountId: contract.bankAccountId!,
          unitContractId: contract.unitContractId!,
          direction: 'incoming',
          processing: contract.hasMandateAtDate ? 'PLATFORM' : 'SELF_PAYER',
        });

        return acc;
      }, reduceInitialValue);

      setContracts(contractsParam);
      setAmountToDistributeInputValues(initialAmountsToDistribute);
      setDirectionAndProcessingInputValues(initialDirectionAndProcessing);
    };

    return {
      initializeDistributionValues, onChangeDistributionValue, onChangeSelectedKeys, tableDataSource, setTableDataSource,
    };
  }, [setAmountToDistributeInputValues, setDirectionAndProcessingInputValues, tableDataSource, setTableDataSource, vatRate]);

  const memoizedAccountSelectionContextValues = useMemo(() => ({
    accounts, setAccounts, properties, setProperties, formValues, setFormValues, validationErrors, setValidationErrors, vatRate, setVatRate, contracts, setContracts,
  }), [accounts, setAccounts, properties, setProperties, formValues, setFormValues, validationErrors, setValidationErrors, vatRate, setVatRate, contracts, setContracts]);

  const memoizedOccurenceContextValues = useMemo(() => ({ occurrenceValues, setOccurrenceValues }), [occurrenceValues, setOccurrenceValues]);

  return (
    <AccountSelectionContext.Provider value={memoizedAccountSelectionContextValues}>
      <AmountDistributionUpdatersAndDatasourceContext.Provider value={memoizedDistributionProviderValues}>
        <AmountToDistributeInputValuesContext.Provider value={amountToDistributeInputValues}>
          <OccurrenceValuesContext.Provider value={memoizedOccurenceContextValues}>
            <DirectionAndProcessingInputValuesContext.Provider value={directionAndProcessingInputValues}>
              {children}
            </DirectionAndProcessingInputValuesContext.Provider>
          </OccurrenceValuesContext.Provider>
        </AmountToDistributeInputValuesContext.Provider>
      </AmountDistributionUpdatersAndDatasourceContext.Provider>
    </AccountSelectionContext.Provider>
  );
};

export {
  ManualExchangeContextProvider,
  AccountSelectionContext,
  OccurrenceValuesContext,
  AmountDistributionUpdatersAndDatasourceContext,
  AmountToDistributeInputValuesContext,
  DirectionAndProcessingInputValuesContext,
};
