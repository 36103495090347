import Heading from 'storybook-components/typography/Heading/Heading';
import './ReportDocumentCover.scss';
import _ from 'lodash';
import { ReportInfo } from './components/ReportInfo';

interface ReportDocumentCoverProps {
  managementCompanyName?: string,
  managementCompanyAddress?: string,
  recipientAddressLines?: string[],
  additionalData?: { label: string, value: string }[],
  title: string,
  reportInfo?: { label: string, value: string }[]
  introductionText?: string[],
}

export const ReportDocumentCover = ({
  managementCompanyName,
  managementCompanyAddress,
  recipientAddressLines,
  additionalData,
  title,
  reportInfo,
  introductionText,
}: ReportDocumentCoverProps) => (
  <div className="ReportDocumentCover">
    {_.isEmpty(recipientAddressLines) ? <div className="address-placeholder" />
      : (
        // divs are needed to have the same structure as the address.html fragment on BE
        // otherwise it won't be compatible with the messaging logic
        <div className="address">
          <div>
            <div className="recipient-address-zone">
              <div className="management-company">
                {`${managementCompanyName || ''} ${managementCompanyName && managementCompanyAddress ? '|' : ''} ${managementCompanyAddress || ''}`}
              </div>
              {recipientAddressLines && (
                <>
                  <div className="restricted-area" />
                  <div className="recipient-address">
                    {recipientAddressLines?.filter(line => line !== undefined && line !== '').map(address => (
                      <div className="recipient-address-line">{address}</div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="address-end" />
        </div>
      )}

    <div className="additional-data-zone">
      <div className="additional-data-item-wrapper">
        {additionalData?.map(data => (
          <div key={data.label} className="additional-data-item">
            {`${data.label}  ${data.value}`}
          </div>
        ))
        }
      </div>
    </div>

    <Heading level={1}>{title}</Heading>

    {introductionText?.length
      && (
        <div className="introduction-text">
          {introductionText?.map(explanationRow => (
            <div key={explanationRow}>{explanationRow}</div>
          ))}
        </div>
      )}

    {reportInfo?.map(info => (
      <ReportInfo key={info.label} label={info.label} value={info.value} />
    ))
    }
  </div>
);

ReportDocumentCover.defaultProps = {
  managementCompanyName: undefined,
  managementCompanyAddress: undefined,
  recipientAddressLines: undefined,
  additionalData: undefined,
  reportInfo: undefined,
  introductionText: undefined,
};
