import { ColumnProps } from 'antd/lib/table';
import { UnitContractProjectionDto } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import ProcessingSelector from '../components/HouseMoneySettlementCloseYearSection2/ProcessingSelector';
import { translations } from '../translations';
import { formatCurrency } from '../../../../../lib/Utils';
import { PaymentDirection, PaymentDirectionSwitch } from '../../../../../storybook-components/inputs/PaymentDirectionSwitch/PaymentDirectionSwitch';
import Amount from 'storybook-components/typography/Amount/Amount';

export const useHouseMoneySettlementCloseYearSection2Columns: () => ColumnProps<UnitContractProjectionDto>[] = () => {
  const { tl } = useContext(LanguageContext);


  return [
    {
      title: tl(translations.creationPage.sections.section2.unitNumber),
      dataIndex: 'unitNrSharingDeclaration',
      width: '10%',
      render: (value: string) => (
        <PopoverTruncatingText
          containerClassName="HouseMoneySettlementCloseYearCreation"
          value={value}
        />
      ),
    },
    {
      title: tl(translations.creationPage.sections.section2.ownerContract),
      dataIndex: ['mailingContact', 'name'],
      width: '30%',
    },
    {
      title: tl(translations.creationPage.sections.section2.amountToDistribute),
      dataIndex: 'amount',
      render: (value: number) => <Amount>{formatCurrency(value)}</Amount>,
      width: '25%',
    },
    {
      title: tl(translations.creationPage.sections.section2.direction),
      dataIndex: 'direction',
      render: (value: PaymentDirection) => (
        <PaymentDirectionSwitch
          value={value}
          disabled
        />
      ),
      width: '15%',
    },
    {
      title: tl(translations.creationPage.sections.section2.processing),
      dataIndex: 'processing',
      render: (_, rec) => <ProcessingSelector record={rec} />,
      width: '20%',
    },
  ];
};
