import React, { useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import './SubcategorySwitcher.scss';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { ICONS } from 'components/icons';
import { NavItem } from 'services/useSiteMap/interfaces';
import Heading from 'storybook-components/typography/Heading/Heading';


export interface SubcategorySwitcherProps {
  selectedKey: string,
  navItems: NavItem[]
}

export default function SubcategorySwitcher(props: SubcategorySwitcherProps) {
  const { selectedKey, navItems } = props;
  const history = useHistory();
  const location = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const selectedNavItem = useMemo(() => navItems.find(item => item.key === selectedKey), [navItems, selectedKey]);


  // Property view context logic;
  const PROPERTY_VIEW_URL: string = '/properties/:propertyHrId/edit';
  const match = useRouteMatch(PROPERTY_VIEW_URL);

  const onMenuItemClick = (url: string) => {
    if (location.pathname !== url) {
      if (match) { // Property in context
        history.push(match.url + url);
      } else {
        history.push(url);
      }
    }
    // hide dropdown after click
    setDropdownVisible(false);
  };
  const menuItem = (navItem: NavItem) => (
    <Menu.Item
      key={navItem.key}
      onClick={() => onMenuItemClick(navItem.url)}
      className={`subcategory-switcher-menu-item ${navItem.key === selectedKey ? 'selected' : ''}`}
    >
      {navItem.title}
    </Menu.Item>
  );

  const menu = (
    <Menu className="subcategory-switcher-menu">
      {navItems
        .filter(navItem => !navItem.hidden)
        .map(navItem => menuItem(navItem))
      }
    </Menu>
  );

  return (
    <div className={`SubcategorySwitcher ${dropdownVisible ? 'visible' : ''}`}>
      <Dropdown
        className="subcategory-switcher-dropdown"
        overlay={menu}
        placement="bottomLeft"
        trigger={['click']}
        onVisibleChange={setDropdownVisible}
      >
        <div>
          <Heading level={1} className="title">
            {selectedNavItem ? selectedNavItem.title : ''}
            <Icon className="title-icon" component={dropdownVisible ? ICONS.arrowsClose : ICONS.arrowsOpen} />
          </Heading>
          <div className={`content-hider ${dropdownVisible ? 'visible' : ''}`} />
        </div>
      </Dropdown>
    </div>
  );
}
