import {
  INTEGRATION_APPS,
  PortalDocumentProps,
  TICKET_COLUMN_TYPE,
} from 'storybook-components/TicketIntegrationSection/interfaces';
import {
  useGetInvoiceCommentForPortal,
} from 'storybook-components/TicketIntegrationSection/services/useGetInvoiceCommentForPortal';
import {
  TicketIntegrationSection,
} from 'storybook-components/TicketIntegrationSection/TicketIntegrationSection';

import { useFaciliooTicketColumns } from './useFaciliooTicketColumns';
import {
  useFaciliooTicketIntegrationSection,
} from './useFaciliooTicketIntegrationSection';

export const FaciliooInvoiceTicketSection = ({
  connectionId,
  propertyId,
  documentProps,
} : {connectionId: number,
          propertyId: number,
      documentProps: PortalDocumentProps}) => {
  const { getCommentText } = useGetInvoiceCommentForPortal(true);
  const {
    errorMessage, loading, onChangeSearchTerm, issues, isAssigned,
    onCommentClickOk, onUnassignClickOk, comment, setComment,
    commentModalVisible, unassignModalVisible,
    onCancelComment, onCancelUnassign, onCommentShow, onUnassignShow,
  } = useFaciliooTicketIntegrationSection(connectionId,
    TICKET_COLUMN_TYPE.INVOICE, { propertyId },
    documentProps, getCommentText);
  const { columns } = useFaciliooTicketColumns({
    type: TICKET_COLUMN_TYPE.INVOICE,
    isTicketAssigned: isAssigned,
    showCommentModal: onCommentShow,
    showUnassignModal: onUnassignShow,
  });

  return (
    <TicketIntegrationSection
      loading={loading}
      columns={columns}
      errorMessage={errorMessage}
      onChangeSearchTerm={onChangeSearchTerm}
      datasource={issues}
      portal={INTEGRATION_APPS.FACILIOO}
      comment={comment}
      setComment={setComment}
      commentModalVisible={commentModalVisible}
      unassignModalVisible={unassignModalVisible}
      onCancelComment={onCancelComment}
      onCancelUnassign={onCancelUnassign}
      onCommentClickOk={onCommentClickOk}
      onUnassignClickOk={onUnassignClickOk}
    />
  );
};
