import './SecondarySectionWrapper.scss';

import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { ICONS } from 'components/icons';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import Heading from 'storybook-components/typography/Heading/Heading';

import Icon from '@ant-design/icons/lib/components/Icon';
import { Collapse } from 'antd';
import { nanoid } from 'nanoid';
import { isEmpty } from 'lodash';


interface Props {
  id?: string,
  icon?: React.ReactNode,
  title?: string,
  subTitle?: string,
  subSectionSuffix?: React.ReactNode,
  subSectionOpenSuffix?: React.ReactNode,
  subSectionClosedSuffix?: React.ReactNode,
  children?: React.ReactNode,
  className?: string,
  infoIconText?: string,
  collapseEnabled?: boolean,
  // defaultCollapsed is used to initialize the collapsed value.
  // If defaultCollapsed changes this component does not react to that change
  // if you want to control the collapsed state from outside than use isCollapsed instead
  defaultCollapsed?: boolean,
  isCollapsed?: boolean,
  destroyOnCollapse?: boolean,
}

const SecondarySectionWrapper = ({
  id,
  icon,
  title,
  subTitle,
  subSectionSuffix,
  subSectionOpenSuffix,
  subSectionClosedSuffix,
  children,
  className,
  infoIconText,
  collapseEnabled,
  defaultCollapsed,
  isCollapsed: isCollapsedProp,
  destroyOnCollapse,
}: Props) => {
  /* `Collapse` component requires a key to manage the collapse state. */
  const [sectionKeyForCollapse] = useState(nanoid());
  const [isCollapsedInner, setIsCollapsedInner] = useState(defaultCollapsed);


  /**
   * `targetRef` is used to render the collapse icon in the correct position. Without it the icon
   * would be rendered to the right of the suffix.
   * Since we're using a portal to render the icon, we need to make sure the target element exists
   * before rendering the icon.
   */
  const targetRef = useRef(null);
  const [isTargetReady, setIsTargetReady] = useState(false);

  useEffect(() => {
    if (targetRef.current) {
      setIsTargetReady(true);
    }
  }, [targetRef.current]);

  /* If it becomes not collapsible, then it should be expanded. */
  useEffect(() => {
    if (!collapseEnabled) {
      setIsCollapsedInner(false);
    }
  }, [collapseEnabled]);


  const isCollapsed = isCollapsedProp ?? isCollapsedInner;
  const defaultActiveKey = (defaultCollapsed && collapseEnabled) ? [] : [sectionKeyForCollapse];


  let suffix;
  if (subSectionSuffix !== undefined) {
    suffix = subSectionSuffix;
  } else if (subSectionOpenSuffix && !isCollapsed) {
    suffix = subSectionOpenSuffix;
  } else if (subSectionClosedSuffix && isCollapsed) {
    suffix = subSectionClosedSuffix;
  }


  const header = (
    <Heading level={3} className="subsection-title-wrapper">
      {icon && <span className="icon">{icon}</span>}
      {title && (
        <span className="title">
          {title}
          {subTitle && (
            <span className="sub-title">
              &nbsp;
              {`· ${subTitle}`}
            </span>
          )}
          {infoIconText ? (<InfoIcon popupText={infoIconText} placement="top" />) : ''}

          {/* container/target for the collapse icon */}
          <span className="collapse-icon-wrapper" ref={targetRef} />
        </span>
      )}
      {suffix}
    </Heading>
  );


  const expandIcon = ({ isActive }) => {
    if (!collapseEnabled || !isTargetReady) return null;

    return ReactDOM.createPortal(
      <Icon component={isActive ? ICONS.collapseArrowUp : ICONS.collapseArrowDown} />,
      targetRef.current,
    );
  };


  const wrapperClassName = `SecondarySectionWrapper ${
    title === undefined ? 'SecondarySectionWrapperNoTitle' : ''
  } ${
    className ?? ''
  }`;

  return (
    <div id={id} className={wrapperClassName}>
      <Collapse
        ghost
        expandIconPosition="right"
        expandIcon={expandIcon}
        className="ant-collapse-subsection-wrapper"
        destroyInactivePanel={destroyOnCollapse}
        activeKey={isCollapsed ? [] : [sectionKeyForCollapse]}
        defaultActiveKey={defaultActiveKey}
        onChange={key => setIsCollapsedInner(isEmpty(key))}
        collapsible={collapseEnabled ? undefined : 'disabled'}
      >
        <Collapse.Panel header={header} key={sectionKeyForCollapse}>
          {children}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default SecondarySectionWrapper;

SecondarySectionWrapper.defaultProps = {
  id: undefined,
  title: undefined,
  subTitle: undefined,
  icon: undefined,
  subSectionSuffix: undefined,
  subSectionOpenSuffix: undefined,
  subSectionClosedSuffix: undefined,
  children: undefined,
  className: undefined,
  infoIconText: undefined,
  collapseEnabled: false,
  defaultCollapsed: false,
  isCollapsed: undefined,
  destroyOnCollapse: false,
};
