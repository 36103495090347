import { useEffect, useRef } from 'react';
import useEvent from '../lib/useEvent';

interface FullPageScrollProps {
  tableName: string,
  beforeElement: Element
  className: string
}

export default function useFullPageScroll({ tableName, beforeElement, className }: FullPageScrollProps, dependencies: any = []) {
  const tableScrollElement = useRef<Element | null>(null);

  useEffect(() => {
    if (!tableName) return;
    tableScrollElement.current = document.querySelector(`.${tableName} .ant-table-body`);
    if (!tableScrollElement.current) {
      setTimeout(() => {
        tableScrollElement.current = document.querySelector(`.${tableName} .ant-table-body`);
      }, 2000);
    }
  }, [...dependencies]);


  useEvent('scroll', tableScrollElement.current, (e) => {
    // We hide the element only if the table is big enough to have a scrollbar even after the hidden element
    if ((className === 'hidden' && e.target.scrollHeight > e.target.clientHeight + beforeElement.clientHeight) || (className !== 'hidden')) {
      // when the header is collapsed then the content fits entirely onto the page and is no longer scrollable => don't remove the class if the content is not scrollable
      const contentIsScrollable = e.target.scrollHeight !== e.target.clientHeight;
      if (e.target.scrollTop === 0 && contentIsScrollable && beforeElement.classList.contains(className)) {
        beforeElement.classList.remove(className);
      } else if (e.target.scrollTop > 0 && !beforeElement.classList.contains(className)) {
        beforeElement.classList.add(className);
      }
    }
  });
}
