import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../../elements/Translation/translations';
import { Column, FORMAT } from '../../../elements/SmartTable/SmartTable';

export const UseAccountsOverviewColumns = (): (Column)[] => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.accountsOverview.table.headers.code),
      dataKey: 'accountCode',
    },
    {
      title: tl(translations.pages.accountsOverview.table.headers.name),
      dataKey: 'accountName',
    },
    {
      title: tl(translations.pages.accountsOverview.table.groupHeaders.current),
      children: [
        {
          title: tl(translations.pages.accountsOverview.table.headers.debitSum),
          format: FORMAT.CURRENCY,
          dataKey: 'debitSum',
        },
        {
          title: tl(translations.pages.accountsOverview.table.headers.creditSum),
          format: FORMAT.CURRENCY,
          dataKey: 'creditSum',
        },
        {
          title: tl(translations.pages.accountsOverview.table.headers.balance),
          format: FORMAT.CURRENCY,
          dataKey: 'balance',
        },
      ],
    },
    {
      title: tl(translations.pages.accountsOverview.table.groupHeaders.comparison),
      children: [
        {
          title: tl(translations.pages.accountsOverview.table.headers.prevBalance),
          format: FORMAT.CURRENCY,
          dataKey: 'prevBalance',
        },
      ],
    },
    {
      title: tl(translations.pages.accountsOverview.table.groupHeaders.difference),
      children: [
        {
          title: tl(translations.pages.accountsOverview.table.headers.prevBalance),
          format: FORMAT.PERCENTAGE,
          dataKey: 'prevPercent',
        },
      ],
    },


    // TODO extend with the missing columns
  ];
};
