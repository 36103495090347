import Icon from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { DataIndex } from 'rc-table/lib/interface';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import CheckboxInput from 'elements/Inputs/CheckboxInput/CheckboxInput';
import React, { useContext } from 'react';
import { translations } from '../../translations';


import './TableColumnSelector.scss';
import { useTableColumnSelector } from '../../services/useTableColumnSelector';
import { ExtendedColumn } from '../../services/useTableColumnSelectorExtendedColumns';


export default function TableColumnSelector<T>({ columns, changeColumnVisibility } : {columns: ExtendedColumn<T>[], changeColumnVisibility: (dataIndex: DataIndex) => void}): JSX.Element {
  const {
    allChecked,
    someChecked,
    onSelectAll,
  } = useTableColumnSelector(columns, changeColumnVisibility);

  const { tl } = useContext(LanguageContext);


  const columnsMenu = (): JSX.Element => {
    if (columns) {
      return (
        <Menu>
          <Menu.Item key="all">
            <CheckboxInput
              className="all-selector"
              label={tl(translations.all)}
              value={allChecked}
              onChange={onSelectAll}
              indeterminate={!allChecked && someChecked}
            />
          </Menu.Item>
          {columns.flatMap((column: ExtendedColumn<T>) => (column.children ? column.children : column))
            .filter((column: ExtendedColumn<T>) => !column.alwaysVisible && column.title && column.dataIndex)
            .map((column: ExtendedColumn<T>) => (
              <Menu.Item key={`${column.dataIndex}`}>
                <CheckboxInput
                  label={`${column.titleString ?? column.title ?? ''}`}
                  value={column.visible ?? true}
                  onChange={() => changeColumnVisibility(column.dataIndex!)}
                />
              </Menu.Item>
            ))}
        </Menu>
      );
    }
    return (<></>);
  };

  return (
    <div className="TableColumnSelector">
      <Dropdown
        overlay={columnsMenu}
        overlayClassName="column-selector-dropdown"
        trigger={['click']}
        placement="bottomRight"
      >
        <Icon component={ICONS.menuDots} />
      </Dropdown>
    </div>
  );
}
