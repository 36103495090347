import React from 'react';
import PdfPageWrapper from 'elements/PdfViewer/components/PdfPageWrapper';
import VirtualizePdfPageWrapper from 'elements/PdfViewer/components/VirtualizePdfPageWrapper';
import './PdfPreviewSidebar.scss';
import { getDocumentIdByFingerprintAndPageNumber } from 'pages/PdfPageAssigner/services/utils';
import { PagesOrientationType } from 'pages/PdfPageAssigner/services/interfaces';
import { usePdfPreviewSidebar } from './usePdfPreviewSidebar';

const PDF_SIDEBAR_PREVIEW_WIDTH = 56;

type Props = {
  virtualize: boolean,
  pages: any[],
  pdfFingerprint: any,
}

const PdfPreviewSidebar = ({ virtualize, pdfFingerprint, pages }: Props) => {
  const PageComponent = virtualize ? VirtualizePdfPageWrapper : PdfPageWrapper;

  const { idOfPageInView } = usePdfPreviewSidebar();


  const scrollToPage = (fingerprint, pageNumber: number) => {
    const elm = document.getElementById(getDocumentIdByFingerprintAndPageNumber(fingerprint, pageNumber));
    if (elm) {
      elm.scrollIntoView({ behavior: 'auto' });
    }
  };


  const getPageClassName = (page: any) => {
    const pageId = getDocumentIdByFingerprintAndPageNumber(pdfFingerprint, page._pageIndex);
    if (pageId === idOfPageInView) {
      return 'highlight';
    }
    return '';
  };

  return (
    <div className="PdfPreviewSidebar">
      {pages.map(page => (
        <PageComponent
          key={pdfFingerprint + page._pageIndex}
          className={getPageClassName(page)}
          page={page}
          parentWidth={PDF_SIDEBAR_PREVIEW_WIDTH}
          onClick={() => scrollToPage(pdfFingerprint, page._pageIndex)}
          pdfPreviewWidth={PDF_SIDEBAR_PREVIEW_WIDTH}
          renderTextLayer={false}
          viewMode={PagesOrientationType.GRID}
        />
      ))}
    </div>
  );
};

export default PdfPreviewSidebar;
