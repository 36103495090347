import { useEffect } from 'react';

export const useSyncScroll = (elementA: any, elementB: any) => {
  useEffect(() => {
    if (
      !elementB
      || !elementA
      || !elementB.addEventListener
      || !elementA.addEventListener
    ) { return; }

    elementB.scrollLeft = elementA.scrollLeft;

    elementB.addEventListener('scroll', (e: any) => {
      elementA.scrollLeft = e.target.scrollLeft;
    });

    // in case of scrolling without clicking the scrollbar
    elementA.addEventListener('scroll', (e: any) => {
      elementB.scrollLeft = e.target.scrollLeft;
    });
  }, [elementA, elementB]);
};
