import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import TextAreaInput from 'elements/Inputs/TextAreaInput/TextAreaInput';
import { formatDate } from 'lib/Utils';
import { usePostingTexts } from './usePostingTextsModal';
import { PostingText, ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import { translations } from '../../../../../../../translations';

interface Props {
  record: ServiceCompanyAllocationValues,
  selectedTransactions: ExtendedBankTransaction[],
}

const PurposeEditorModal = ({ selectedTransactions, record }: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    postingTexts: initialPostingTexts, onChangeMultiplePostingTexts, modalTitle, modalSubTitle,
  } = usePostingTexts(record, selectedTransactions);
  const [modalVisible, setModalVisible] = useState(false);

  const selectedTransactionIds = selectedTransactions.map(tx => tx.bankTransactionId!);
  const [localValues, setLocalValues] = useState<PostingText[]>(initialPostingTexts);


  const onCancel = () => {
    setLocalValues(initialPostingTexts);
    setModalVisible(false);
  };


  /**
   * Example: there is a group of 5 txs, of which 3 are selected
   *
   * Scenario 1: User deselects a tx (2 remain selected)
   * In this case we need to filter out the no-longer-selected tx from the localValues
   *
   * Scenario 2: User selects a 4th tx.
   * In this case `selectedTransaction` changes, which triggers an update for `postingTexts`
   * (see comment at useExchangeList::addPotentiallyMissingPostingTexts for details)
   */
  useEffect(() => {
    setLocalValues((prevLocalValues) => {
      const transactionIdsInLocalValues = prevLocalValues.map(pt => pt.transactionId!);
      const missingPostingTexts = initialPostingTexts.filter(pt => !transactionIdsInLocalValues.includes(pt.transactionId!));
      const previousPostingTextsForStillSelectedTransactions = prevLocalValues.filter(pt => selectedTransactionIds.includes(pt.transactionId!));

      return [
        ...previousPostingTextsForStillSelectedTransactions,
        ...missingPostingTexts,
      ];
    });
  }, [selectedTransactions, initialPostingTexts.length]);


  const footer = (
    <Button
      type="primary"
      onClick={() => {
        setModalVisible(false);
        onChangeMultiplePostingTexts(localValues);
      }}
    >
      {tl(translations.bankTransactionAllocation.modal.save)}
    </Button>
  );


  return (
    <>
      <Button size="large" onClick={() => setModalVisible(true)} icon={<Icon component={ICONS.bookingText} />}>
        {tl(translations.bankTransactionAllocation.serviceCompanyAllocation.changePurpose)}
      </Button>
      <ModalWithSubtitle
        className="edit-posting-text-modal"
        title={modalTitle}
        subtitle={modalSubTitle}
        visible={modalVisible}
        onCancel={onCancel}
        footer={footer}
        destroyOnClose
      >
        {
          localValues.map(({ transactionId, postingText }, idx) => (
            <TextAreaInput
              key={transactionId}
              className="booking-text-input"
              label={`${tl(translations.bankTransactionAllocation.modal.bookingText)} ${formatDate(selectedTransactions.find(tx => tx.bankTransactionId === transactionId)?.bankBookingDate!)}`}
              value={postingText || ''}
              onChange={(v) => { setLocalValues(prev => prev.map(prevVal => (prevVal.transactionId === transactionId ? { ...prevVal, postingText: v } : prevVal))); }}
              autoFocus={idx === 0} // focus first input
              onFocus={e => e.target.select()}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  setModalVisible(false);
                  onChangeMultiplePostingTexts(localValues);
                }
              }}
            />
          ))
        }
      </ModalWithSubtitle>
    </>
  );
};

export default PurposeEditorModal;
