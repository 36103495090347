import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import SmartTable from '../../../../../../../elements/SmartTable/SmartTable';
import useSmartTable from '../../../../../../../elements/SmartTable/useSmartTable';
import { useUnitAccountBalancesColumns } from '../useUnitAccountBalancesColumns';
import { translations } from '../../../../../../../elements/Translation/translations';
import './UnitExpensesCard.scss';
import SummaryRow from '../../../../../../../elements/CustomElements/ExpandableCard/SummaryRow';
import { formatCurrency } from '../../../../../../../lib/Utils';

interface expansesCard {
  labelsPrefix: string,
  loading: boolean,
  applicableExpenses: any[],
  notApplicableExpenses: any[],
  summaryTitle?: string
}

export function useRenderUnitExpensesTotal(props: expansesCard) {
  const { tl } = useContext(LanguageContext);
  const [totalCosts, setTotalCosts] = useState(0);
  const [unitCosts, setUnitCosts] = useState(0);
  const {
    labelsPrefix, applicableExpenses, notApplicableExpenses, summaryTitle,
  } = props;

  useEffect(() => {
    const applicableTotal = applicableExpenses.reduce((aggregator: any, expense: any) => aggregator + (expense.totalSum || 0), 0);
    const notApplicableTotal = notApplicableExpenses.reduce((aggregator: any, expense: any) => aggregator + (expense.totalSum || 0), 0);
    const applicableTotalUnit = applicableExpenses.reduce((aggregator: any, expense: any) => aggregator + (expense.unitSum || 0), 0);
    const notApplicableTotalUnit = notApplicableExpenses.reduce((aggregator: any, expense: any) => aggregator + (expense.unitSum || 0), 0);
    setTotalCosts(applicableTotal + notApplicableTotal);
    setUnitCosts(applicableTotalUnit + notApplicableTotalUnit);
  }, [applicableExpenses, notApplicableExpenses]);

  const summaryElements = [{
    title: tl(translations.pages[labelsPrefix].unitAccountBalances.expenses.summary.totalCosts),
    value: <Amount>{formatCurrency(totalCosts)}</Amount>,
  },
  {
    title: tl(translations.pages[labelsPrefix].unitAccountBalances.expenses.summary.yourShare),
    value: <Amount>{formatCurrency(unitCosts)}</Amount>,
  }];
  return (
    <SummaryRow
      rightSideElements={summaryElements}
      title={summaryTitle && summaryTitle}
    />
  );
}

export default function UnitExpensesCard(props: expansesCard) {
  const { tl } = useContext(LanguageContext);
  const {
    labelsPrefix, loading, applicableExpenses, notApplicableExpenses,
  } = props;
  const isEconomicPlan: boolean = useMemo<boolean>(() => labelsPrefix.indexOf('economicPlan') !== -1, [labelsPrefix]);
  const defaultSubtotal = {
    account: tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.subtotal),
    totalSum: 0,
  };


  const applicableSubtotal = useMemo(() => {
    if (!applicableExpenses) return defaultSubtotal;
    return {
      account: tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.subtotal),
      newTotalAmount: applicableExpenses.reduce((aggregator: number, expense: any) => aggregator + (expense.newTotalAmount || 0), 0),
      newUnitAmount: applicableExpenses.reduce((aggregator: number, expense: any) => aggregator + (expense.newUnitAmount || 0), 0),
    };
  }, [applicableExpenses]);

  const notApplicableSubtotal = useMemo(() => {
    if (!notApplicableExpenses) return defaultSubtotal;
    return {
      account: tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.subtotal),
      newTotalAmount: notApplicableExpenses.reduce((aggregator: number, expense: any) => aggregator + (expense.newTotalAmount || 0), 0),
      newUnitAmount: notApplicableExpenses.reduce((aggregator: number, expense: any) => aggregator + (expense.newUnitAmount || 0), 0),
    };
  }, [notApplicableExpenses]);

  const applicableDataSource = useMemo(() => (applicableExpenses ? applicableExpenses.concat(applicableSubtotal) : []), [applicableExpenses]);
  const applicableExpensesSmartTable = useSmartTable({
    tableName: 'unitAccountApplicableExpenses',
    columns: useUnitAccountBalancesColumns(false, isEconomicPlan),
    dataSource: applicableDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'account',
  });

  const notApplicableDataSource = useMemo(() => (notApplicableExpenses ? notApplicableExpenses.concat(notApplicableSubtotal) : []), [notApplicableExpenses]);
  const notApplicableExpensesSmartTable = useSmartTable({
    tableName: 'unitAccountNotApplicableExpenses',
    columns: useUnitAccountBalancesColumns(false, isEconomicPlan),
    dataSource: notApplicableDataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'account',
  });

  return (
    <div className="UnitExpensesCard">
      <div className="subtitle" id="applicableExpenses">
        {tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.applicableExpenses)}
      </div>
      <SmartTable {...applicableExpensesSmartTable} />
      <div className="subtitle" id="notApplicableExpenses">
        {tl(translations.pages[labelsPrefix].propertyAccountBalances.expenses.notApplicableExpenses)}
      </div>
      <SmartTable {...notApplicableExpensesSmartTable} />
    </div>
  );
}
