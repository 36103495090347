import { UnitContractProjectionDto } from '../../../api/accounting';

export interface OwnerPaymentsTableRow extends UnitContractProjectionDto {
  amount: number,
  direction: 'incoming' | 'outgoing',
}

export const useOwnerPaymentsTable = (activeContractsList: Array<UnitContractProjectionDto>, amounts: Array<{unitId: number, amount: number}>) => {
  const datasource = activeContractsList?.map((contract) => {
    const contractAmount = amounts.find(amount => amount.unitId === contract.unitId)?.amount || 0;
    const direction = contractAmount < 0 ? 'outgoing' : 'incoming';
    return ({
      ...contract,
      key: contract.contractNumber,
      amount: Math.abs(contractAmount),
      direction,
    }) as OwnerPaymentsTableRow;
  });

  const totalAmount = amounts.reduce((acc, val) => acc + (val?.amount ?? 0), 0);
  return ({
    datasource,
    totalAmount,
  });
};
