import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { Link } from 'react-router-dom';
import { mapPropertyAdministrationTypeToFrontend } from 'lib/utilMapPropertyAdministrationTypeToFrontend';
import { getPropertyEditingRoute } from 'pages/Property/routes';
import { ContactPropertiesDataSource } from '../../services/ContactPropertiesContext';
import { useContactPropertiesNameCell } from './useContactPropertiesNameCell';


const ContactPropertiesNameCell = ({ record } : {record: ContactPropertiesDataSource}) => {
  const { url } = useContactPropertiesNameCell(record);

  const link = (
    <Link to={url} target="_blank">
      {record.name}
    </Link>
  );


  if (!record.children) {
    return link;
  }


  const secondElement = (
    <>
      <span>{`${mapPropertyAdministrationTypeToFrontend(record.administrationType)} · ${record.propertyIdInternal}`}</span>
      {
        record.linkedProperty
          ? (
            <>
              <span> · </span>
              <Link to={getPropertyEditingRoute(record.linkedProperty.propertyHrId)} target="_blank">
                {`${record.linkedProperty.propertyIdInternal} ${record.linkedProperty.name}`}
              </Link>
            </>
          )
          : null
      }
    </>
  );

  return (
    <CellWithTwoLines
      firstElement={link}
      secondElement={secondElement}
    />
  );
};

export default ContactPropertiesNameCell;
