import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { EInvoiceReportPageView } from './components/EInvoiceReportPageView';
import { EInvoiceData } from './types';



export const EInvoiceReportPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const invoice: EInvoiceData = JSON.parse(searchParams.get('invoice'));
  const content = (<EInvoiceReportPageView invoice={invoice} />);
  // render content into the #print root element declared in index.html
  return ReactDOM.createPortal(content, document.getElementById('print'));
};
