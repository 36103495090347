import { DunningProjectionStateEnum } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useMemo, useState } from 'react';
import { Order } from 'services/useSort';

type DunningListContextProps = {
    children: React.ReactNode,
  }

export interface DunningPropertyDto {
    propertyId: number,
    propertyIdInternal: string,
    propertyHrId: string,
    propertyName: string,
    propertyAdministrator: string,
    propertyAccountant: string,
}

export interface DunningMessageDto {
    messageId: number,
    state: string,
}

export interface DunningProjectionFE {
    id: number,
    domainId: number,
    state: DunningProjectionStateEnum,
    totalAmount: number,
    numberOfContracts: number,
    propertyManager: string,
    propertyAccountant: string,
    properties: DunningPropertyDto[],
    messages: DunningMessageDto[],
    numberOfAllMessages: number,
    numberOfFailedMessages: number,
    executionDate: string,
}

const DEFAULT_SORT: { field: keyof DunningProjectionFE, order: Order } = {
  field: 'executionDate',
  order: -1,
};

const DunningListContext = createContext<{
    dunningList: DefaultDataInterface<DunningProjectionFE[]>,
    setDunningList: React.Dispatch<React.SetStateAction<DefaultDataInterface<DunningProjectionFE[]>>>,
    dunningListFilterState: any,
    setDunningListFilterState: React.Dispatch<React.SetStateAction<any>>
    sortState: { field: keyof DunningProjectionFE, order: Order },
    setSortState: React.Dispatch<React.SetStateAction<{ field: keyof DunningProjectionFE, order: Order }>>,
  }>(undefined);

DunningListContext.displayName = 'DunningListContext';

export const useDunningListContext = (usageName: string) => {
  const context = React.useContext(DunningListContext);

  if (context === undefined) {
    throw new Error(`${usageName} must be used within an DunningListContextProvider`);
  }

  return context;
};


const DunningListContextProvider = ({ children }: DunningListContextProps) => {
  const [dunningList, setDunningList] = useState(DEFAULT_DATA<DunningProjectionFE[]>([]));
  const [dunningListFilterState, setDunningListFilterState] = useState<any>(undefined);
  const [sortState, setSortState] = useState(DEFAULT_SORT);

  const dunningListProviderValue = useMemo(() => ({
    dunningList,
    setDunningList,
    dunningListFilterState,
    setDunningListFilterState,
    sortState,
    setSortState,
  }),
  [
    dunningList,
    dunningListFilterState,
    sortState,
  ]);

  return (
    <DunningListContext.Provider value={dunningListProviderValue}>
      {children}
    </DunningListContext.Provider>
  );
};

export default DunningListContextProvider;
