import { BankConnectionInterfaceInterfaceEnum, BankConnectionUpdateStatusEnum } from 'api/accounting';

export const translations = {
  updateConnectionModalTitle: {
    de: 'Verbindung aktualisieren',
    en: 'Update connection',
  },
  documentationLink: {
    de: 'FinAPI: Bankkonten hinzufügen und synchronisieren',
    en: 'FinAPI: Add and synchronize bank accounts ',
  },
  columns: {
    state: {
      de: 'Status',
      en: 'Status',
    },
    name: {
      de: 'Name',
      en: 'Name',
    },
    accounts: {
      de: 'Anzahl Konten',
      en: 'Number of accounts',
    },
    interface: {
      de: 'Protokoll',
      en: 'Interface',
    },
    lastUpdate: {
      de: 'Letzte Aktualisierung',
      en: 'Last Update',
    },
  },
  listActions: {
    downloadDetectedAccounts: {
      de: 'Erkannte Bankkonten liste',
      en: 'Detected bankaccounts list',
    },
  },
  enableFinAPI: {
    title: {
      en: 'FinAPI is not enabled on your domain',
      de: 'FinAPI ist nicht freigeschalten',
    },
    description: {
      en: 'Please contact support to have online-banking enabled.',
      de: 'Bitte kontaktieren Sie unseren Support, um Onlinebanking freizuschalten.',
    },
    contactSupportAction: {
      de: 'Support kontaktieren',
      en: 'Contact support',
    },
  },
  sateErrorTooltip: {
    en: 'FinAPI reported error',
    de: 'FinAPI meldete Fehler',
  },
  interfaces: {
    [BankConnectionInterfaceInterfaceEnum.FINTS_SERVER]: {
      de: 'FINTS (HBCI)',
      en: 'FINTS (HBCI)',
    },
    [BankConnectionInterfaceInterfaceEnum.XS2A]: {
      de: 'XS2A',
      en: 'XS2A',
    },
  },
  states: {
    error: {
      de: 'Fehler',
      en: 'Error',
    },
    [BankConnectionUpdateStatusEnum.IN_PROGRESS]: {
      en: 'Udating',
      de: 'Aktualisierung im Gange',
    },
    [BankConnectionUpdateStatusEnum.READY]: {
      en: 'Ready',
      de: 'Bereit',
    },
  },
  bankConnectionActions: {
    synchronize: {
      en: 'Synchronize',
      de: 'Synchronisieren',
    },
    addConnection: {
      en: 'Add connection',
      de: 'Bankverbindung hinzufügen',
    },
    bankConnectionImportModal: {
      title: {
        en: 'Add FinAPI bank connection',
        de: 'FinAPI Bankverbindung hinzufügen',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      import: {
        en: 'Import',
        de: 'Hinzufügen',
      },
      managementCompany: {
        en: 'Management company',
        de: 'Verwalterfirma',
      },
      bankingInterface: {
        en: 'The protocol FinAPI shall connect to the bank',
        de: 'Bankverbindungs Protokoll',
      },
      bank: {
        en: 'Bank',
        de: 'Bank',
      },
      selectBank: {
        en: 'Select Bank',
        de: 'Bank auswählen',
      },
      selectCompany: {
        en: 'Choose managing company',
        de: 'Verwaltungsfirma auswählen',
      },
      connectionName: {
        en: 'Bank connection name',
        de: 'Bankverbindungsname',
      },
    },
    useBankConnections: {
      updateSuccess: {
        message: {
          en: 'Synchronization successfully started but will take some time to process.',
          de: 'Die Synchronisation wurde erfolgreich gestartet, wird aber einige Zeit in Anspruch nehmen.',
        },
      },
      updateError: {
        message: {
          en: 'Could not update the connection',
          de: 'Fehler beim Aktualisieren der Bankverbindung',
        },
      },
      addError: {
        message: {
          en: 'Could not add connection. Ensure all fields are filled correctly.',
          de: 'Fehler beim hinzufügen der Bankverbindung. Bitte stellen Sie sicher, dass alle Felder ausgefüllt sind.',
        },
      },
    },
    updateModal: {
      help: {
        en: `<dl>
        <dt>Import transactions</dt>
        <dd>
          <p>The import of bank transactions will be started.</p>
          <p>As soon as FinAPI reads and provides the data from the bank, the transactions will be displayed automatically 
          as long as the IBANs are stored with any of the objects.</p>
          <p>For some banks, no TAN entry is required for this. 
        </p>
        </dd> 
        
        <dt>Synchronize bank connection</dt>
        <dd>
          <p>This process is necessary to detect and add new bank accounts.
          Two-factor authentication (e.g., TAN entry) will be required to authorize FinAPI's access. 
          </p>
        <dd>
        <dl>`,
        de: `<dl>
          <dt>Umsätze importieren</dt>
          <dd>
            <p>Der Import der Bankumsätze wird gestartet.</p>
            <p>Sobald FinAPI die Daten von der Bank ausgelesen und bereitgestellt hat, werden die Umsätze automatisch angezeigt 
            solange die IBANs bei eine der Objekte hinterlegt sind.</p>
            <p>Bei manchen Banken ist dafür keine TAN-Eingabe nötig.
            </p>
          </dd> 
          <dt>Bankverbindung neu synchronisieren</dt>
          <dd>
            <p>Dieser Vorgang ist notwendig, um neue Bankkonten zu erkennen und hinzuzufügen. 
            Eine Zwei-Faktor-Authentifizierung (z. B. TAN-Eingabe) wird notwendig, um FinAPI den Zugriff zu autorisieren.
            </p>
          </dd>
          </dl>`,
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      synchronize: {
        en: 'Synchronize',
        de: 'Synchronisieren',
      },
      import: {
        en: 'Import',
        de: 'Importieren',
      },
    },
  },
  cannotDownloadAccountsCsv: {
    de: 'Bankkonten konnten nicht heruntergeladen werden',
    en: 'Could not download bank accounts list',
  },
};
