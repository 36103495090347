import React, { useContext } from 'react';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import './TransactionExecution.scss';
import { translations } from '../../../../../translations';
import RadioGroupInput from '../../../../../../../elements/Inputs/RadioGroupInput/RadioGroupInput';

export default function TransactionExecution({
  onChange, value, disabled,
}: { onChange: (newValue: boolean) => void, value: boolean, disabled: boolean }): JSX.Element {
  const { tl } = useContext(LanguageContext);
  return (
    <div className="transaction-execution-wrapper">
      <div className="transaction-execution-text">
        <div className="transaction-execution-title">
          {`${tl(translations.specialContribution.section3.transactionExecution.question)}`}
        </div>
        <div className="transaction-execution-subtitle">
          {`${tl(translations.specialContribution.section3.transactionExecution.details)}`}
        </div>
      </div>

      <div className="radio-column">
        <RadioGroupInput
          label=""
          onChange={val => onChange(val)}
          value={value}
          options={[{
            value: true,
            label: `${tl(translations.specialContribution.section3.transactionExecution.radioGroup.yes)}`,
          },
          {
            value: false,
            label: `${tl(translations.specialContribution.section3.transactionExecution.radioGroup.no)}`,
          },
          ]}
          className="applicable-radio-group"
          disabled={disabled}
        />
      </div>
    </div>
  );
}
