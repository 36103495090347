import {
  DocumentLegacyControllerApi, DocumentProjectionDto, DocumentProjectionDtoOcrStateEnum, FindDocumentsFilteredUsingGETRequest, FindDocumentsFilteredUsingGETSourceTypesEnum,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useState } from 'react';
import usePoll from './usePoll';

interface Props {
  documentId?: number,
  sourceId?: number,
  sourceType?: FindDocumentsFilteredUsingGETSourceTypesEnum,
  onReadyCallback?: (doc?: DocumentProjectionDto) => void
}

export const useOcrProgressPolling = ({
  documentId, sourceId, sourceType, onReadyCallback,
} : Props) => {
  const { tl } = useContext(LanguageContext);
  const [document, setDocument] = useState<DocumentProjectionDto>();
  const [ocrInProgress, setOcrInProgress] = useState<boolean>(false);

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));

  const filter: FindDocumentsFilteredUsingGETRequest = documentId ? { documentIds: [documentId] } : { sourceIds: [sourceId], sourceTypes: sourceType };

  useEffect(() => {
    if (!documentId && (!sourceId || !sourceType)) return;
    loadDocument();
  }, [documentId, sourceId, sourceType]);

  const loadDocument = () => documentApi.findDocumentsFilteredUsingGET(filter)
    .then((response) => {
      setDocument(response.content?.[0]);
      const inProgress = [DocumentProjectionDtoOcrStateEnum.OCR_REQUESTED, DocumentProjectionDtoOcrStateEnum.OCR_IN_PROGRESS].includes(response.content?.[0]?.ocrState);
      setOcrInProgress(inProgress);
      if (!inProgress) {
        onReadyCallback?.(document);
      }
    })
    .catch((err) => {
      console.error(err);
    });

  const pollOcrProgress = () => {
    documentApi.findDocumentsFilteredUsingGET(filter)
      .then((response) => {
        const inProgress = [DocumentProjectionDtoOcrStateEnum.OCR_REQUESTED, DocumentProjectionDtoOcrStateEnum.OCR_IN_PROGRESS].includes(response.content?.[0]?.ocrState);
        setOcrInProgress(inProgress);
        if (!inProgress) {
          onReadyCallback?.(document);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  usePoll({
    shouldPoll: ocrInProgress,
    pollFunction: pollOcrProgress,
    timeoutSeconds: 2000,
  });

  const customOnLoadCatch = () => {
    if (ocrInProgress) {
      /**
       * In case of invoice loading sometimes we start loading the invoice, but then the OCR finishes
       * before the file loading finishes, and that means that the initial file will be deleted
       * (because it's overwritten by the OCR result - extended with text layer). And in that case
       * we don't want to show the error notification, and we must enable a way to do that.
       */
      return;
    }

    showNotification({
      key: 'loadDocumentError',
      message: tl(translations.notifications.pdfViewer.failedToLoad),
      type: 'error',
    });
  };

  return {
    document,
    ocrInProgress,
    customOnLoadCatch,
  };
};
