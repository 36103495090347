import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useCallback, useContext, useMemo } from 'react';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { generateReportDocumentName } from 'lib/Utils';
import { DocumentCreateDtoSourceTypeEnum } from 'api/document';

export const usePNLPropertySelector = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('usePNLPropertySelector must be used within ProfitAndLossReportContext');
  }
  const {
    reportData,
    setReportData,
    setValidationErrors,
    pnLCompatibleProperties,
  } = profitAndLossReportContext;

  const options: SelectOption[] = useMemo(() => (pnLCompatibleProperties.data.map(prp => ({
    label: `${prp.propertyIdInternal} - ${prp.name}`,
    value: prp.id!,
  } as SelectOption))), [pnLCompatibleProperties.data]);

  const onFilterOption = useCallback((inputValue: any, option?: any) => (
    option.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  ), []);

  const onChange = useCallback((val) => {
    const prp = pnLCompatibleProperties.data.find(p => p.id === val);
    setReportData((prevReportData) => {
      const newState = { ...prevReportData, property: prp };
      const newDocumentName = prevReportData.isDocumentNameTouched ? prevReportData.documentName : generateReportDocumentName(DocumentCreateDtoSourceTypeEnum.PROFIT_AND_LOSS, newState?.startDate, newState?.endDate);

      setValidationErrors(prevValidation => ({
        ...prevValidation,
        property: undefined,
        documentName: prevReportData.isDocumentNameTouched ? prevValidation.documentName : undefined,
      }));

      return ({ ...newState, documentName: newDocumentName });
    });
  }, [setReportData, pnLCompatibleProperties, setValidationErrors]);

  const memorizedReturnValue = useMemo(() => ({
    value: reportData?.property?.id,
    onChange,
    options,
    onFilterOption,
  }), [reportData?.property, onChange, options, onFilterOption]);

  return memorizedReturnValue;
};
