import { useContext } from 'react';
import { DatasourceContext } from './HouseMoneySettlementCloseYearContext';


export const useIsDirtyValue = () => {
  const dataSourceContext = useContext(DatasourceContext);

  if (dataSourceContext === undefined) {
    throw new Error('useIsDirtyValue must be used within a HouseMoneySettlementCloseYearContextProvider');
  }

  const { isDirty } = dataSourceContext;

  return { isDirty };
};
