import { RouteDefinition } from 'components/RenderRoutes';
import { ContractPage } from './ContractPage';

export const ContractRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/contracts/:contractId',
      component: ContractPage,
      strict: true,
      exact: true,
    },
  ],
};
