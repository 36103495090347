import '../ContactDetails.scss';

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { ContactLegacyDto } from 'api/accounting';
import FormSection from 'elements/FormElements/FormSection/FormSection';
import {
  ContactHeaderButtons,
} from 'pages/Contacts/components/ContactHeaderButtons/ContactHeaderButtons';
import { useParams } from 'react-router';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';

import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import {
  CompanyEditingContext,
} from '../../../../contexts/CompanyEditingContext';
import {
  useBankInformationSection,
} from '../PersonDetails/sections/BankInformationSection';
import {
  useDocumentsSection,
} from '../PersonDetails/sections/DocumentsSection';
import { useInvoicesSection } from '../PersonDetails/sections/InvoicesSection';
import { useMassagesSection } from '../PersonDetails/sections/MessagesSection';
import {
  usePropertiesSection,
} from '../PersonDetails/sections/PropertiesSection';
import {
  useContactIntegrationSection,
} from '../PersonDetails/sections/services/useContactIntegrationSection';
import CompanyData from './components/CompanyData';

export default function CompanyDetails(): JSX.Element {
  const { goBack }: any = useContext(OverlayContext);
  const { companyId } = useParams<{companyId:string}>();
  const companyEditingContext: any = useContext(CompanyEditingContext);
  const company = companyEditingContext.savedCompany ? companyEditingContext.savedCompany.data : {};
  const [openSectionIndex, setOpenSectionIndex] = useState(-1);

  useEffect(() => {
    companyEditingContext.onLoadCompany(companyId);
    companyEditingContext.setDirty(false);
  }, []);


  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (openSectionIndex === nextSectionIndex) {
      // if the clicked section is the current one, close the sections
      nextSectionIndex = -1;
    }
    setOpenSectionIndex(nextSectionIndex);
  };


  useEffect(() => {
    if (openSectionIndex >= 0) {
      const sectionId = sections[openSectionIndex] ? sections[openSectionIndex].sectionId : null;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openSectionIndex]);

  const bankAccounts: any = company && company.bankAccounts ? company.bankAccounts : [];

  let sections: any = [
    usePropertiesSection(parseInt(companyId, 10)),
    useBankInformationSection(bankAccounts),
    useContactIntegrationSection({ contact: company as ContactLegacyDto }),
    useInvoicesSection(companyId),
    useMassagesSection(companyId),
    useDocumentsSection(companyId),
  ];

  return (
    <Page id="scrollElement" className="CompanyDetails contact-details">
      <PageHeader
        title={`${company.companyName}`}
        rightSideComponent={(
          <ContactHeaderButtons
            contactType={company.type}
            contactId={parseInt(companyId, 10)}
            contactName={company?.companyName}
            editEnabled={false}
            onGoBack={goBack}
          />
        )}
      />
      <PageContent>
        <CompanyData />
        <div className="form-content">
          <div className="form-section">
            {sections.map((section: any) => (
              <FormSection
                key={section.sectionId}
                open={(openSectionIndex === section.sectionNumber - 1)}
                onClickHeader={() => handleOpenSectionChange(section.sectionNumber - 1)}
                hideSaveButton
                {...section}
              />
            ))}
          </div>
        </div>
      </PageContent>
    </Page>
  );
}
