export const economicPlanAccountEditingTranslations = {
  header: {
    save: {
      en: 'Save',
      de: 'Speichern',
    },
    cancel: {
      en: 'Close',
      de: 'Schließen',
    },
    headerTitle: {
      en: 'Create new economic plan',
      de: 'Neuen Wirtschaftsplan erstellen',
    },
  },
  notifications: {
    saveSuccess: {
      message: {
        en: 'Economic plan saved successfully.',
        de: 'Wirtschaftsplan erfolgreich gespeichert.',
      },
    },
  },

  sections: {
    propertySection: {
      title: {
        en: 'WEG-data',
        de: 'WEG-Daten',
      },
      subtitle: {
        en: 'Economic data',
        de: 'Wirtschaftsdaten',
      },
      fields: {
        property: {
          en: 'Choose property',
          de: 'Objekt auswählen',
        },
      },
    },
    editAmounts: {
      title: {
        en: 'Expenses/Income',
        de: 'Ausgaben / Einnahmen',
      },
      increaseAmounts: {
        title: {
          en: 'Adjustment of amounts (basis: previous years amounts, combination possible)',
          de: 'Generelle Anpassung der Werte (Grundlage: Vorjahreswerte, Kombination möglich)',
        },
        hint: {
          en: 'Hinweis',
          de: 'Hint',
        },
        percent: {
          en: 'percentage',
          de: 'Prozentsatz',
        },
        changeValue: {
          en: 'Change of amount by',
          de: 'Änderung der Aufwendungen um',
        },
        roundUp: {
          en: 'Round up to next ...',
          de: 'Aufrunden auf die nächsten',
        },
      },
      applicableCosts: {
        title: {
          en: 'Applicable costs',
          de: 'Umlagefähige Kosten',
        },
      },
      notApplicableCosts: {
        title: {
          en: 'Not applicable costs',
          de: 'Nicht umlagefähige Kosten',
        },
      },
      income: {
        title: {
          en: 'Income',
          de: 'Einnahmen',
        },
      },
      totals: {
        title: {
          en: 'Totals',
          de: 'Gesamtbeträge',
        },
      },
      table: {
        account: {
          en: 'Account',
          de: 'Sachkonto',
        },
        distribution: {
          en: 'Distribution',
          de: 'Verteilung',
        },
        previousYear: {
          en: 'Previous',
          de: 'Vorjahr',
        },
        currentYear: {
          en: 'Current',
          de: 'Aktuell',
        },
        newYear: {
          en: 'New (€)',
          de: 'Neu (€)',
        },
      },
      total: {
        totalQuantity: {
          en: 'Total',
          de: 'Gesamtbeträge',
        },
        expenses: {
          en: 'Expenses',
          de: 'Ausgaben',
        },
        incomes: {
          en: 'Incomes',
          de: 'Einnahmen',
        },
        total: {
          en: 'Total',
          de: 'Gesamt',
        },
      },
    },
    reserveFundsSection: {
      title: {
        en: 'Added value to reserve funds',
        de: 'Zuführung zu den Instandhaltungsrücklagen',
      },
      table: {
        account: {
          en: 'Account',
          de: 'Sachkonto',
        },
        distribution: {
          en: 'Distribution',
          de: 'Verteilung',
        },
        value: {
          en: 'Added value (€)',
          de: 'Zuführung (€)',
        },
      },
    },
    results: {
      sectionTitle: {
        en: 'Result',
        de: 'Ergebnis',
      },
      savingText: {
        en: 'In oder to the see the house money results, please save the Economic Plan first',
        de: 'Bitte speichern Sie zuerst den Wirtschaftsplan, um die Ergebnisse des Hausgeldes zu sehen',
      },
    },
    corrections: {
      sectionTitle: {
        en: 'Correctional Bookings',
        de: 'Korrekturbuchungen',
      },
    },
  },
};
