import Icon from '@ant-design/icons';
import { Alert } from 'antd';
import { ICONS } from 'components/icons';
import { OpsCostSectionKeys } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/interfacesOpsCostReportManualAmounts';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { isEmpty } from 'lodash';
import { useMissingHiddenAccountCodeAlert } from './useMissingHiddenAccountCodeAlert';

export interface MissingHiddenAccountCodeAlertProps {
    tl:any,
    missingHidddenAccountCodes:string[],
    contractId: number,
    sectionName: OpsCostSectionKeys,
  }

export const MissingHiddenAccountCodeAlert = ({
  tl, missingHidddenAccountCodes, contractId, sectionName,
} : MissingHiddenAccountCodeAlertProps) => {
  const { onCloseMissingHiddenAccountCodesAlert } = useMissingHiddenAccountCodeAlert();

  const missingHiddenAccountsCodeInfo = (
    <ul>
      {
        missingHidddenAccountCodes?.map(accountCode => (
          <li key={accountCode}>
            {accountCode}
          </li>
        ))
      }
    </ul>
  );

  const missingHiddenAccountsAlertDescription = (
    <>
      {missingHiddenAccountsCodeInfo}
      {tl(opsCostTranslation.alerts.missingHiddenAccountSecond)}
    </>
  );

  if (isEmpty(missingHidddenAccountCodes)) {
    return <></>;
  }

  return (
    <Alert
      message={tl(opsCostTranslation.alerts.missingHiddenAccountFirst)}
      description={missingHiddenAccountsAlertDescription}
      type="warning"
      showIcon
      closable
      closeIcon={<Icon component={ICONS.closeForAlert} />}
      onClose={() => onCloseMissingHiddenAccountCodesAlert(contractId, missingHidddenAccountCodes, sectionName)}
      icon={<Icon component={ICONS.warning} />}
    />
  );
};
