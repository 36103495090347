import { LanguageContext } from 'contexts/LanguageContext';
import { setValue } from 'lib/Utils';
import { useContext, useState } from 'react';
import { useBankInformation } from 'services/useBankInformation';
import { translations } from 'pages/Contacts/pages/CompanyEditing/translations';
import { CompanyEditingContext } from 'contexts/CompanyEditingContext';
import { useBankAccountValidations } from 'services/useBankAccountValidations';
import { BankDataDto } from 'api/accounting';

export interface BankAccountFormValues {
    accountHolderName: string | undefined,
    iban: string | undefined,
    bic: string | undefined,
    accountInstitute: string | undefined,
}

export const useBankAccountSubsection = (bankAccountIndex: number) => {
  const { tl } = useContext(LanguageContext);
  const companyEditingContext: any = useContext(CompanyEditingContext);

  if (companyEditingContext === undefined) {
    throw new Error('useBankAccountSubsection must be used within a CompanyEditingContext');
  }

  const { setCompany } = companyEditingContext;
  const bankAccounts = companyEditingContext.company?.data?.bankAccounts ?? [];
  const companyId = companyEditingContext.company?.data?.id;

  const bankAccount = bankAccounts ? bankAccounts[bankAccountIndex] : {};


  const { validationErrors } = useBankAccountValidations(companyEditingContext.company.validationErrors, bankAccountIndex);

  const onChange = (key: keyof BankAccountFormValues, value: BankAccountFormValues[keyof BankAccountFormValues]) => {
    setCompany((prevState: any) => {
      const newData = { ...prevState.data };
      setValue(newData, `bankAccounts[${bankAccountIndex}].${key}`, value);
      return prevState.load(newData);
    });
  };


  const { fetchBankInformation } = useBankInformation();
  const [bicPlaceHolder, setBicPlaceholder] = useState(tl(translations.bankAccountSubsection.bicWillBeGenerated));


  const callback = (bankInformation: BankDataDto) => {
    onChange('accountInstitute', bankInformation?.bankName ?? '');
    onChange('bic', bankInformation?.bic ?? '');

    if (!bankInformation || bankInformation.bic === undefined) {
      setBicPlaceholder(tl(translations.bankAccountSubsection.bicNotGenerated));
    }
  };

  const onBlurIban = () => {
    fetchBankInformation(bankAccount?.iban, callback);
  };

  const removeBankAccountFromList = (index: number) => {
    setCompany((prevState: any) => {
      const newData = { ...prevState.data };
      newData.bankAccounts?.splice(index, 1);
      newData.bankAccountNumber -= 1;

      return prevState.load(newData);
    });
  };

  return {
    bankAccount,
    bicPlaceHolder,
    onChange,
    onBlurIban,
    validationErrors,
    companyId,
    removeBankAccountFromList,
  };
};
