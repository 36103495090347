import React, { useContext } from 'react';
import moment from 'moment';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';
import ExecutionModal, { Criteria, ExecutionModalProps } from '../../elements/Modals/ExecutionModal/ExecutionModal';
import { formatDate } from '../../lib/Utils';


export default function PaymentExecutionModal(props: ExecutionModalProps): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const {
    criteria,
    basedOnSelection,
  } = props;

  return (
    <ExecutionModal {...props}>
      {!basedOnSelection && criteria.map((crt: Criteria) => {
        const value = crt.field.indexOf('Date') > 0 || crt.field.indexOf('created') > 0 || crt.field.indexOf('updated') > 0 ? formatDate(moment(crt.value)) : crt.value;
        return (
          <li key={crt.field}>
            {tl(translations.pages.payment.filter[crt.field])}
            {': '}
            <span className="accent-text">{value}</span>
          </li>
        );
      })}
    </ExecutionModal>
  );
}
