import React, { ReactNode, useState } from 'react';
import Button from 'elements/Buttons/Button/Button';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import './ComponentVisibilityToggler.scss';


interface ComponentVisibilityTogglerProps {
  labelWhenClosed: ReactNode,
  labelWhenOpen: ReactNode,
  defaultIsVisible?: boolean,
  isVisible?: boolean,
  hidable?: boolean,
  disabled?: boolean,
  onVisibilityChange?: (value: boolean) => void,
  children: ReactNode,
}


const ComponentVisibilityToggler = ({
  labelWhenOpen,
  labelWhenClosed,
  defaultIsVisible,
  isVisible,
  children,
  onVisibilityChange,
  hidable,
  disabled: disabledProp,
}: ComponentVisibilityTogglerProps) => {
  const className = 'component-visibility-toggler';
  const [showComponent, setShowComponent] = useState(defaultIsVisible ?? false);

  const icon = isVisible ?? showComponent ? ICONS.close : ICONS.plus;
  const label = isVisible ?? showComponent ? labelWhenOpen : labelWhenClosed;
  const content = isVisible ?? showComponent ? children : null;
  const disabled = disabledProp || ((isVisible ?? showComponent) && !hidable);

  const onClickLabel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setShowComponent((prev) => {
      if (onVisibilityChange) onVisibilityChange(!prev);
      return !prev;
    });
  };

  return (
    <div className={className}>
      <Button type="text" onClick={onClickLabel} disabled={disabled}>
        <Icon component={icon} />
        {label}
      </Button>
      {content}
    </div>
  );
};


export default ComponentVisibilityToggler;

ComponentVisibilityToggler.defaultProps = {
  defaultIsVisible: undefined,
  isVisible: undefined,
  onVisibilityChange: undefined,
  hidable: true,
  disabled: undefined,
};
