import React, { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { useCreateProfitAndLossReportButton } from './useCreateProfitAndLossReportButton';


const CreateProfitAndLossReportButton = () => {
  const { tl } = useContext(LanguageContext);
  const { onCreateProfitAndLossReport } = useCreateProfitAndLossReportButton();

  return (
    <Button onClick={onCreateProfitAndLossReport} type="primary">
      {tl(translations.createProfitAndLossButtonLabel)}
    </Button>
  );
};

export default CreateProfitAndLossReportButton;
