import { PropertyLegacyDto } from 'api/accounting';
import { getPropertyEditingRoute } from 'pages/Property/routes';

export const usePropertyLink = (property: PropertyLegacyDto) => {
  const url = getPropertyEditingRoute(property.propertyHrId);
  const label = `${property?.propertyIdInternal} · ${property?.name}`;

  return {
    label,
    url,
  };
};
