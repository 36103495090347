import {
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { AgendaItemsContext } from 'contexts/AgendaItemsContext';
import { templateAgendaItems } from 'contexts/util/AgendaTemplateItem';
import {
  OwnersMeetingProtocolContext,
} from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';
import {
  useHistory,
  useLocation,
} from 'react-router';
import { useParams } from 'react-router-dom';

import {
  AgendaItemDto,
  AgendaItemDtoAgendaItemTypeEnum,
  AgendaItemsControllerApi,
  AgendaItemUpdateDtoAgendaItemTypeEnum,
  AgendaItemVoteDtoStatusEnum,
  OwnersMeetingProtocolDtoStatusEnum,
} from '../../../../../../../api/accounting';
import { AuthContext } from '../../../../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import { showNotification } from '../../../../../../../lib/Notification';
import {
  OverlayContext,
} from '../../../../../../../services/OverlayContext/OverlayContext';
import {
  useAgendaItemVotingResult,
} from '../../OwnersMeetingProtocolVoting/services/useAgendaItemVotingResult';
import {
  translations as ownersMeetingProtocolTranslations,
} from '../translations';
import { AgendaItemDetailsContext } from './AgendaItemDetailsContext';

export const useAgendaItemDetails = () => {
  const { agendaItemId } = useParams<{ agendaItemId: string }>();
  const { etvId } = useParams<{ etvId: string }>();
  const selectedAgendaItemId = parseInt(agendaItemId, 10);
  const ownersMeetingProtocolId = parseInt(etvId, 10);

  const location = useLocation();
  const history = useHistory();
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);
  const { apiConfiguration } = useContext(AuthContext);
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));

  const agendaItemsContext = useContext(AgendaItemsContext);
  if (agendaItemsContext === undefined) {
    throw new Error('useAgendaItemDetails must be used within a AgendaItemsContextProvider');
  }

  const agendaItemDetailsContext = useContext(AgendaItemDetailsContext);
  if (agendaItemDetailsContext === undefined) {
    throw new Error('useAgendaItemDetails must be used within a AgendaItemDetailsContextProvider');
  }

  const {
    selectedAgendaItem,
    setSelectedAgendaItem,
    setDirty,
    setDirtModalVisible,
    dirty,
    dirtModalVisible,
  } = agendaItemDetailsContext;

  const {
    selectedAgendaItemVote,
    setSelectedAgendaItemVote,
    onSaveAgendaItemVotingResult,
    voteIsDirty,
    onChangeVoteResult,
    isStandardVoteDetails,
    toggleStandardVoteDetails,
    setVoteIsDirty,
  } = useAgendaItemVotingResult();

  const { agendaItemsList } = agendaItemsContext;
  const { ownersMeeting } = useContext(OwnersMeetingProtocolContext);

  const loading = selectedAgendaItem?.loading || selectedAgendaItemVote?.loading;
  const isDisabled = location.pathname.includes('view') || loading || !ownersMeeting.data || (ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.SENT || ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.DELETED
    || ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.DONE);
  const isDiscussionTopic = selectedAgendaItem?.data?.agendaItemType === AgendaItemDtoAgendaItemTypeEnum.DISCUSSION_TOPIC;

  const nextAgendaItem = useMemo(() => (selectedAgendaItem ? agendaItemsList.data?.find(ai => ai?.index > selectedAgendaItem?.data?.index) : undefined), [selectedAgendaItem.data]);

  const onClickSaveAndOpenNext = () => {
    if (!nextAgendaItem) {
      return;
    }
    onSaveAgendaItem(() => {
      const nextAgendaItemUrl = location.pathname.replace(selectedAgendaItem?.data?.id?.toString(), nextAgendaItem?.id?.toString());
      history.push(nextAgendaItemUrl);
    });
  };

  const onSaveAgendaItem = (callback?: () => void) => {
    if (location.pathname.includes('create')) {
      onCreateAgendaItem(callback);
    } else {
      onUpdateAgendaItem(callback);
    }
  };
  const updateAgendaItemPromise = () => {
    const agendaItem = { ...selectedAgendaItem.data };
    if (agendaItem?.resolutionRecordText?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
      agendaItem.resolutionRecordText = agendaItem.resolutionRecordText.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
    }

    if (agendaItem?.comment?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
      agendaItem.comment = agendaItem.comment.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
    }
    return agendaItemsControllerApi.updateAgendaItemUsingPUT({
      agendaItemId: selectedAgendaItemId,
      agendaItemUpdateDto: {
        ...agendaItem,
        index: agendaItem.index,
        agendaItemType: agendaItem.agendaItemType as unknown as AgendaItemUpdateDtoAgendaItemTypeEnum,
      },
    });
  };

  const onUpdateAgendaItem = (callback?: () => void) => {
    setSelectedAgendaItem(prev => prev.startLoading());
    if (!dirty && !voteIsDirty) {
      callback?.();
    }
    if (dirty && voteIsDirty) {
      const promises = [];
      promises.push(updateAgendaItemPromise());
      promises.push(onSaveAgendaItemVotingResult(selectedAgendaItem.data.id));
      Promise.allSettled(promises)
        .then(([agendaItemPromiseResult, agendaItemVotePromiseResult]) => {
          if (agendaItemPromiseResult.status === 'fulfilled' && agendaItemVotePromiseResult?.status === 'fulfilled') {
            setSelectedAgendaItem(prev => prev.load(agendaItemPromiseResult.value));
          } else {
            onAgendaItemError(agendaItemPromiseResult);
          }
          if (agendaItemVotePromiseResult?.status === 'fulfilled') {
            setSelectedAgendaItemVote(prev => prev.load(agendaItemVotePromiseResult.value));
          } else {
            onAgendaItemError(agendaItemVotePromiseResult);
          }
          onAgendaItemSuccess(callback);
        });
    } else if (dirty) {
      updateAgendaItemPromise().then((response) => {
        setSelectedAgendaItem(prev => prev.load(response));
        onAgendaItemSuccess(callback);
      }).catch((error) => {
        onAgendaItemError(error);
      });
    } else if (!selectedAgendaItemVote?.data?.id || voteIsDirty) {
      onSaveAgendaItemVotingResult(selectedAgendaItem.data.id).then((response) => {
        setSelectedAgendaItemVote(prev => prev.load(response));
        onAgendaItemSuccess(callback);
      }).catch((error) => {
        onAgendaItemError(error);
      });
    } else {
      setSelectedAgendaItem(prev => prev.finishLoading());
    }
  };

  const onCreateAgendaItem = (callback?: () => void) => {
    setSelectedAgendaItem(prev => prev.startLoading());
    agendaItemsControllerApi.createAgendaItemForProtocolUsingPOST({
      agendaItemDto: {
        ...selectedAgendaItem.data,
        agendaItemType: selectedAgendaItem.data?.agendaItemType ? selectedAgendaItem.data?.agendaItemType : AgendaItemDtoAgendaItemTypeEnum.PROPOSAL,
      },
      ownersMeetingProtocolId,
    }).then((response) => {
      setSelectedAgendaItem(prev => prev.load(response));
      onSaveAgendaItemVotingResult(response.id).then(() => {
        setSelectedAgendaItemVote(prev => prev.load({ status: AgendaItemVoteDtoStatusEnum.DRAFT }));
      }).catch((error) => {
        onAgendaItemError(error);
      });
      onAgendaItemSuccess(callback);
    }).catch((error) => {
      onAgendaItemError(error);
    });
  };

  const onAgendaItemSuccess = (callback?: () => void) => {
    showNotification({
      key: 'saveAgendaItemSuccess',
      message: tl(ownersMeetingProtocolTranslations.agendaItemDetails.notifications.saveSuccess.message),
      type: 'success',
    });
    setSelectedAgendaItem(prev => prev.finishLoading());
    setDirty(false);
    callback?.();
  };

  const onAgendaItemError = (error) => {
    setSelectedAgendaItem(prev => prev.finishLoading());
    if (error.response.status === 422) {
      showNotification({
        key: 'saveAgendaItemError',
        message: tl(ownersMeetingProtocolTranslations.agendaItemDetails.notifications.fieldValidationWarning),
        type: 'error',
      });
    } else {
      showNotification({
        key: 'saveAgendaItemError',
        message: tl(ownersMeetingProtocolTranslations.agendaItemDetails.notifications.saveError.message),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (selectedAgendaItemId) {
      setSelectedAgendaItem(prev => prev.resetToInitial().startLoading());
      agendaItemsControllerApi.getAgendaItemByIdUsingGET({ agendaItemId: selectedAgendaItemId })
        .then((response: any) => {
          setSelectedAgendaItem(prev => prev.load(response).finishLoading());
        })
        .catch(() => {
          setSelectedAgendaItem(prev => prev.failed());
          showNotification({
            key: 'loadAgendaItemError',
            message: tl(ownersMeetingProtocolTranslations.agendaItemDetails.notifications.loadError.message),
            description: tl(ownersMeetingProtocolTranslations.agendaItemDetails.notifications.loadError.description),
            type: 'warning',
          });
        });
    }
  }, [selectedAgendaItemId]);

  const onChange = (key: keyof AgendaItemDto, value: AgendaItemDto[keyof AgendaItemDto]) => {
    setSelectedAgendaItem(prev => prev.load({ ...prev.data, [key]: value }));

    // If discussion topic is selected reset all voting result data (except db id)
    if (key === 'agendaItemType' && value === AgendaItemDtoAgendaItemTypeEnum.DISCUSSION_TOPIC) {
      setSelectedAgendaItemVote(prev => prev.load({
        id: prev.data.id,
        status: AgendaItemVoteDtoStatusEnum.DRAFT,
      }));
      setVoteIsDirty(true);

      if (!isStandardVoteDetails) {
        // standardVoteDetails is `true` by default. Resetting it to true
        toggleStandardVoteDetails(true);
      }
    }
    setDirty(true);
  };

  const onChangeTemplate = (value: AgendaItemDto[keyof AgendaItemDto]) => {
    const templateItem = templateAgendaItems.find(item => item.label === value).value;
    setSelectedAgendaItem(prev => prev.load({ ...prev.data, title: templateItem.title, resolutionRecordText: templateItem.resolutionRecordText }));
    setDirty(true);
  };

  const onClickClose = () => {
    if (dirty || voteIsDirty) {
      setDirtModalVisible(true);
    } else {
      goBack();
    }
  };

  return {
    onClickClose,
    selectedAgendaItem,
    setSelectedAgendaItem,
    onSaveAgendaItem,
    onClickSaveAndOpenNext,
    nextAgendaItem,
    onChange,
    templateAgendaItems,
    loading,
    onChangeTemplate,
    dirty,
    setDirtModalVisible,
    dirtModalVisible,
    goBack,
    selectedAgendaItemVote,
    setSelectedAgendaItemVote,
    onChangeVoteResult,
    isDisabled,
    isDiscussionTopic,
  };
};
