import { Modal } from 'antd';
import { ContactLegacyControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useContext } from 'react';
import { translations } from './translations';

export const useDeleteBankAccountButton = (removeFromList: (idx: number) => void, index: number, contactId?: number, id?: number) => {
  const { tl } = useContext(LanguageContext);

  const { apiConfiguration } = useContext(AuthContext);
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));

  const onClick = () => {
    if (id === undefined) {
      removeFromList(index);
    } else {
      Modal.confirm({
        title: tl(translations.deleteModal.title),
        content: tl(translations.deleteModal.content),
        okText: tl(translations.deleteModal.okText),
        cancelText: tl(translations.deleteModal.cancelText),
        okButtonProps: { className: 'Button' },
        cancelButtonProps: { className: 'Button' },
        onOk: onDelete,
        width: 450,
      });
    }
  };

  const onDelete = () => {
    contactControllerApi.deleteContactBankAccountUsingDELETE({ contactId, bankAccountId: id })
      .then(() => {
        removeFromList(index);
        showNotification({
          key: 'deleteBankAccountSuccess',
          message: tl(translations.deleteSuccess),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'deleteBankAccountError',
          message: tl(translations.deleteError),
          type: 'error',
        });
      });
  };

  return {
    onClick,
  };
};
