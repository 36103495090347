import { VatRateDto } from 'api/accounting/models/VatRateDto';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import {
  MutableRefObject,
  ReactNode, createContext, useContext, useRef, useState,
} from 'react';


const VatRatesContext = createContext<|
  {
    vatRates: DefaultDataInterface<VatRateDto[]>,
    setVatRates: React.Dispatch<React.SetStateAction<DefaultDataInterface<VatRateDto[]>>>,
    initVatRatesTriggered: MutableRefObject<boolean>;
  } | undefined>(undefined);

export const useVatRatesContext = (usageName: string) => {
  const vatRatesContext = useContext(VatRatesContext);

  if (vatRatesContext === undefined) {
    throw new Error(`${usageName} must be used within an VatRateContextProvider`);
  }

  return vatRatesContext;
};


const VatRatesContextProvider = ({ children }: { children: ReactNode }) => {
  const [vatRates, setVatRates] = useState(DEFAULT_DATA<VatRateDto[]>(undefined));
  const initVatRatesTriggered = useRef<boolean>(false);

  return (
    <VatRatesContext.Provider value={{ vatRates, setVatRates, initVatRatesTriggered }}>
      {children}
    </VatRatesContext.Provider>
  );
};

export default VatRatesContextProvider;
