import {
  AllocationGroupDto, BankTransactionControllerApi, GetAllocationGroupsUsingGETOrderEnum, SliceOfAllocationGroupDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import querystring from 'query-string';
import _ from 'lodash';
import {
  useContext, useEffect, useMemo, useRef,
} from 'react';
import { useLocation, useParams } from 'react-router';
import { mergeListsRemovingDuplicates } from 'lib/utilMergeListsRemovingDuplicates';
import { translations } from '../translations';
import { useBankTransactionListContext, useBankTransactionListDrawerContext } from './BankTransactionListContext';
import { BankTransactionListLocationStateType } from '../interfaces';

const PAGE_SIZE = 60;


export const useInitializeBankTransactionList = () => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation<BankTransactionListLocationStateType>();
  const params = useParams<{ allocationGroupId: string | undefined }>();
  const txListAbortController = useRef<AbortController | undefined>(undefined);
  const initialSortUpdateRef = useRef(true);
  const bankTransactionListLoading = useRef(false);


  const { apiConfiguration } = useContext(AuthContext);
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));

  const {
    initialFilterUpdate,
    setInitialFilterUpdate,
    sortState,
    filterState,
    bankTransactionListState,
    setFilterState,
    setBankTransactionListState,
  } = useBankTransactionListContext('useInitializeBankTransactionList');

  const { transactionIdsFromUrlParams, setTransactionIdsFromUrlParams } = useBankTransactionListDrawerContext('useInitializeBankTransactionList');

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadBankTransactions(true);
    } else if (!_.isEmpty(filterState)) {
      setInitialFilterUpdate(false);
    }
  }, [filterState]);

  useEffect(() => {
    if (!initialSortUpdateRef.current) {
      onLoadBankTransactions(true);
    } else {
      initialSortUpdateRef.current = false;
    }
  }, [sortState]);

  useEffect(() => {
    const { transactionIds } = querystring.parse(location.search, { arrayFormat: 'comma' });
    const parsedTransactionIds = (typeof transactionIds === 'string' ? [transactionIds] : transactionIds)?.map(txId => parseInt(txId, 10));

    setTransactionIdsFromUrlParams(parsedTransactionIds);

    if (transactionIds) {
      const { allocationGroupId: allocationGroupIdString } = params;
      const allocationGroupId = allocationGroupIdString ? parseInt(allocationGroupIdString, 10) : undefined;
      setFilterState(filter => ({
        ...filter,
        allocationGroupId,
      }));
    }
  }, [location.search]);


  const mapTransactionGroups = (groups: AllocationGroupDto[] | undefined) => groups?.map((group) => {
    if (group.transactions) {
      if (group.transactions.length === 1) {
        return {
          allocationGroupId: group.allocationGroupId,
          ...group.transactions[0],
          rowKey: `${group.transactions[0].bankTransactionId}`,
          transactionDate: group.transactions[0].bankBookingDate,
          allocatedInvoices: JSON.parse(group.transactions[0].allocatedInvoices ?? '[]'),
          translatedStatus: group.transactions[0].transactionPaymentStatus && tl(translations.statuses[group.transactions[0].transactionPaymentStatus]) ? tl(translations.statuses[group.transactions[0].transactionPaymentStatus]) : '',
          propertyList: JSON.parse(group.transactions[0].properties || '[]'),
        };
      }
      return {
        allocationGroupId: group.allocationGroupId,
        ...group.transactions[0],
        allocatedInvoices: JSON.parse(group.transactions[0].allocatedInvoices ?? '[]'),
        transactionDate: group.transactions[0].bankBookingDate,
        translatedStatus: tl(translations.statuses[group.transactions[0]?.transactionPaymentStatus]) || '',
        propertyList: JSON.parse(group.transactions[0].properties || '[]'),
        children: group.transactions.map(tx => (
          {
            ...tx,
            allocationGroupId: group.allocationGroupId,
            allocatedInvoices: JSON.parse(tx.allocatedInvoices ?? '[]'),
            transactionDate: tx.bankBookingDate,
            rowKey: `${tx.bankTransactionId}`,
            translatedStatus: tl(translations.statuses[tx.transactionPaymentStatus]) || '',
            propertyList: JSON.parse(tx.properties || '[]'),
          })),
        rowKey: `${group.transactions?.map(tx => tx.bankTransactionId).join('-')}`,
      };
    }
    return {};
  });


  const onLoadBankTransactions = async (resetPage: boolean = false) => {
    setBankTransactionListState(listState => listState.startLoading(resetPage ? false : listState.loaded));
    // introducing the ref because most of the time the above setState doesn't finish early enough to prevent another load

    // if params changed since last initiated fetch then abort the in-progress fetch
    txListAbortController.current?.abort();
    // create new abort controller
    txListAbortController.current = new AbortController();
    const { signal } = txListAbortController.current;

    const navigatedFromApp = location.state?.navigatedFromApp ?? false;

    const filter = { ...filterState };
    let lastPage: boolean | undefined;
    if ((bankTransactionListState.data?.length ?? 0) !== 0 || params.allocationGroupId === undefined || navigatedFromApp) {
      if (location.search) {
        lastPage = true;
        delete filter.transactionPaymentStatuses;
      } else {
        delete filter.allocationGroupId;
      }
    } else {
      lastPage = true;
      delete filter.transactionPaymentStatuses;
    }

    // @ts-ignore
    bankTransactionControllerApi.getAllocationGroupsUsingGET({
      ...filter,
      page: resetPage ? 0 : bankTransactionListState.page,
      size: PAGE_SIZE,
      sort: sortState.field,
      order: sortState.order > 0 ? GetAllocationGroupsUsingGETOrderEnum.ASC : GetAllocationGroupsUsingGETOrderEnum.DESC,
    }, { signal })
      .then((response: SliceOfAllocationGroupDto) => {
        setBankTransactionListState(prev => prev.loadPagedNoConcat(
          mergeListsRemovingDuplicates(prev.data, mapTransactionGroups(response.content), 'allocationGroupId', resetPage),
          resetPage,
          lastPage ?? response.last,
        ));
      }).catch((error) => {
        if (signal?.aborted) return;
        console.error(error);
        setBankTransactionListState(listState => (listState.failed()));
        showNotification({
          key: 'loadBankTransactionError',
          message: tl(translations.notifications.loadListError.message),
          type: 'error',
        });
      })
      .finally(() => {
        bankTransactionListLoading.current = false;
      });
  };

  const datasource = useMemo(() => (bankTransactionListState.data
    ? bankTransactionListState.data
    : []), [bankTransactionListState.data]);

  return {
    datasource,
    lastPage: bankTransactionListState.lastPage,
    loading: bankTransactionListState.loading,
    loaded: bankTransactionListState.loaded,
    error: bankTransactionListState.error,
    transactionIdsFromUrlParams,
    setTransactionIdsFromUrlParams,
    onLoadBankTransactions,
  };
};
