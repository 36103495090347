import { ContractContactProjectionDtoRoleEnum, OpsCostReportProjectionStatusEnum, UnitProjectionDtoUnitTypeEnum } from 'api/accounting';
import { OPS_COST_VALIDATION_ERROR } from './OpsCostReportEditing/components/OpsCostReportEditorForm/services/useOpsCostReportValidityChecks';

export const opsCostTranslation = {
  opsCostReportView: {
    header: {
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
      download: {
        en: 'Download',
        de: 'Herunterladen',
      },
      checkMessages: {
        en: 'View messages',
        de: 'Nachrichten ansehen',
      },
      createCopy: {
        en: 'Create copy',
        de: 'Kopie erstellen',
      },
      revertToDraft: {
        en: 'Revert to draft',
        de: 'Auf Entwurf zurücksetzen',
      },
      retry: {
        en: 'Recreate',
        de: 'Neu erstellen',
      },
      execute: {
        en: 'Book',
        de: 'Buchen',
      },
      close: {
        en: 'Close',
        de: 'Schließen',
      },
      send: {
        en: 'Preview and send',
        de: 'Vorschau & Versenden',
      },
    },
    vacancy: {
      en: 'Vacancy',
      de: 'Leerstand',
    },
    generatingReportLoadingMessage: {
      title: {
        en: 'Generating report…',
        de: 'Abrechnung wird erstellt…',
      },
      subtitle: {
        en: 'The report may take a few minutes to generate, depending on the number of contracts. In the meantime, you can leave and come back later.',
        de: 'Da die Erstellung der Abrechnung einige Minuten dauern kann, können Sie diese Seite verlassen und später zurückkehren, um die Ergebnisse zu überprüfen.',
      },
    },
    // this is a tooltip text
    errorMessage: {
      en: 'Report generation failed. Please try to recreate or contact support.',
      de: 'Die Abrechnung konnte nicht erstellt werden. Bitte erstellen Sie die Abrechnung neu oder kontaktieren Sie unser Support Team.',
    },
    generationErrorMessage: {
      en: 'Report generation failed. Please try to recreate or',
      de: 'Die Abrechnung konnte nicht erstellt werden. Bitte erstellen Sie die Abrechnung neu oder',
    },
    contactSupport: {
      en: 'contact support.',
      de: 'kontaktieren Sie unser Support Team.',
    },
    notFound: {
      title: {
        en: 'Report not found',
        de: 'Bericht wurde nicht gefunden',
      },
      subtitle: {
        en: 'This Ops Cost Report could not be found. It may have been deleted or overwritten.',
        de: 'Diese Betriebskostenabrechnung wurde nicht gefunden. Sie wurde möglicherweise gelöscht oder überschrieben.',
      },
    },
    deleteModal: {
      deleteButton: {
        en: 'Delete',
        de: 'Löschen',
      },
      content: {
        en: 'You cannot undo the action after deleting the Ops cost report. However, you can generate a report again.',
        de: 'Das Löschen der Betriebskostenabrechnung kann nicht mehr rückgängig gemacht werden. Sie können jedoch erneut einen Bericht erstellen.',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      title: {
        en: 'Delete report',
        de: 'Löschen Betriebskostenabrechnung',
      },
    },
    copyOf: {
      en: 'Copy of',
      de: 'Kopie von',
    },
  },
  opsCostReportPreview: {
    header: {
      close: {
        en: 'Close',
        de: 'Schließen',
      },
      status: {
        UNSAVED: {
          en: 'Unsaved',
          de: 'Nicht gespeichert',
        },
        DRAFT: {
          en: 'Draft',
          de: 'Entwurf',
        },
      },
    },
    generatingPreviewLoadingMessage: {
      title: {
        en: 'Generating preview...',
        de: 'Vorschau wird erstellt ...',
      },
      subtitle: {
        en: 'The preview may take a few minutes to generate, depending on the number of contracts.',
        de: 'Abhängig von der Anzahl der Verträge kann die Erstellung einige Minuten  dauern.',
      },
    },
    generationErrorMessage: {
      en: 'Preview generation failed. Please try again or',
      de: 'Erstellung der Vorschau fehlgeschlagen. Bitte versuchen Sie es erneut',
    },
    contactSupport: {
      en: 'contact support.',
      de: 'kontaktieren Sie unser Support Team.',
    },
    preview: {
      en: 'Preview',
      de: 'Vorschau',
    },
  },
  administrationTypes: {
    MV: {
      en: 'Mietverwaltung (MV)',
      de: 'Mietverwaltung (MV)',
    },
    SEV_MV: {
      en: 'SEV without WEG connection',
      de: 'SEV ohne WEG-Verbindung',
    },
  },
  tableHeader: {
    propertyAdministrationTypes: {
      en: 'Administration type',
      de: 'Verwaltungsart',
    },
    status: {
      en: 'Status',
      de: 'Status',
    },
    title: {
      en: 'Title',
      de: 'Titel',
    },
    property: {
      en: 'Property',
      de: 'Objekt',
    },
    dateRange: {
      en: 'Date range',
      de: 'Zeitraum',
    },
    creationDate: {
      en: 'Created on',
      de: 'Erstellt am',
    },
    creationBy: {
      en: 'Created by',
      de: 'Bearbeiter',
    },
    minCreated: {
      en: 'Create date from',
      de: 'Erstellt von',
    },
    maxCreated: {
      en: 'Create date until',
      de: 'Erstellt bis',
    },
    startDate: {
      en: 'Start date',
      de: 'Startdatum',
    },
    endDate: {
      en: 'End date',
      de: 'Enddatum',
    },
    administrator: {
      en: 'Administrator',
      de: 'Objektbetreuer',
    },
    accountant: {
      en: 'Accountant',
      de: 'Objektbuchhalter',
    },
  },
  statusCellDescription: {
    notSent: {
      en: 'Not sent out',
      de: 'Nicht versendet',
    },
    notBooked: {
      en: 'Not booked',
      de: 'Nicht gebucht',
    },
  },
  notifications: {
    previewWhileActiveAlerts: {
      message: {
        en: 'Ops Cost Report couldn´t be previewed',
        de: 'Betriebskostenabrechnung konnte nicht angezeigt werden ',
      },
      description: {
        en: 'Please review and fix the errors listed to continue with the preview.',
        de: 'Bitte prüfen und beheben Sie die aufgeführten Fehler, um die Vorschau zu erstellen.',
      },
    },
    loadListError: {
      en: 'Error loading ops cost reports.',
      de: 'Fehler beim Laden der Betriebskostenabrechnungen.',
    },
    loadReportError: {
      en: 'Error loading ops cost report.',
      de: 'Fehler beim Laden der Betriebskostenabrechnung.',
    },
    propertyLoadError: {
      en: 'Error loading properties.',
      de: 'Objekte konnten nicht geladen werden.',
    },
    loadContractsError: {
      en: 'Failed to load tenant contracts list.',
      de: 'Mietvertrage konnten nicht geladen werden.',
    },
    loadWkasError: {
      en: 'Failed to load wkas',
      de: 'WKA konnte nicht geladen werden.',
    },
    errorSavingReport: {
      en: "Ops Cost Report couldn't be saved.",
      de: 'Betriebskostenabrechnung konnte nicht gespeichert werden.',
    },
    errorGeneratingReport: {
      en: "Ops Cost Report couldn't be generated.",
      de: 'Betriebskostenabrechnung konnte nicht erstellt werden.',
    },
    saveWhileActiveAlerts: {
      message: {
        en: 'Ops Cost Report couldn´t be generated',
        de: 'Betriebskostenabrechnung konnte nicht erstellt werden',
      },
      description: {
        en: 'Please review and fix the warnings listed to continue with the generation.',
        de: 'Um den Bericht zu erstellen, sehen Sie sich bitte die aufgelisteten Warnungen an und lösen Sie diese.',
      },
    },
    loadViewError: {
      en: 'Failed to load ops cost report.',
      de: 'Fehler beim Laden der Betriebskostenabrechnung.',
    },
    previewError: {
      en: 'Failed to preview ops cost report.',
      de: 'Fehler zur Vorschau der Betriebskostenabrechnung.',
    },
    notFoundError: {
      en: 'Ops cost report not found.',
      de: 'Betriebskostenabrechnung wird nicht gefunden.',
    },
    deletionError: {
      en: 'Failed to delete ops cost report.',
      de: 'Betriebskostenabrechnung konnten nicht gelöscht werden.',
    },
    copyError: {
      en: 'Failed to copy ops cost report.',
      de: 'Betriebskostenabrechnung konnte nicht kopiert werden.',
    },
    deletionSuccess: {
      en: 'Ops cost report deleted successfully.',
      de: 'Betriebskostenabrechnung wurde erfolgreich gelöscht.',
    },
    downloadError: {
      en: 'Download failed.',
      de: 'Download fehlgeschlagen.',
    },
    bookingNotPossibleWarning: {
      title: {
        en: 'Booking not possible',
        de: 'Buchen nicht möglich',
      },
      descrption: {
        en: 'Booking is not possible due to missing accounting data for the chosen tenant contracts.',
        de: 'Buchen ist nicht möglich da für die ausgewählten Mieter keine Buchhaltungsdaten in Impower existieren.',
      },
    },
    revertToDraftSuccess: {
      en: 'Ops cost report successfully reverted to DRAFT.',
      de: 'Betriebskostenabrechnung erfolgreich auf Entwurf zurückgesetzt.',
    },
    revertToDraftError: {
      en: 'Ops cost report could not be reverted to DRAFT.',
      de: 'Betriebskostenabrechnung konnte nicht auf Entwurf zurückgesetzt werden.',
    },
    retryError: {
      en: 'Ops cost report generation failed.',
      de: 'Betriebskostenabrechnung konnte nicht erstellt werden.',
    },
    loadPrepaymentsError: {
      en: 'Failed to load prepayments.',
      de: 'Vorauszahlungen konnte nicht geladen werden.',
    },
    loadAccountDistributionsError: {
      en: 'Failed to load account distribution.',
      de: 'Fehler beim Laden der Verteilerschlüssel.',
    },
    saveSuccess: {
      en: 'Ops cost report saved successfully.',
      de: 'Betriebskostenabrechnung wurde erfolgreich gespeichert.',
    },
  },
  createButton: {
    en: 'Create',
    de: 'Erstellen',
  },
  report: {
    changingPropertyConfirmationModal: {
      title: {
        en: 'Loss of added rows',
        de: 'Verlust hinzugefügter Zeilen',
      },
      description: {
        en: 'Selecting another property will cause manually added rows and hidden rows to disappear.',
        de: 'Durch das Auswählen eines anderen Objekts gehen manuell hinzugefügte oder ausgeblendete Zeilen verloren.',
      },
      okText: {
        en: 'Confirm',
        de: 'Bestätigen',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
    alertInavlidDS: {
      firstPart: {
        en: 'This property is using HGA DK with individual settings for Ops cost. Therefore you cannot generate a report. Please set a custom DK or change the DK in HGA for the following DKs: ',
        de: 'Dieses Objekt verwendet HGA-spezifische Verteilerschlüssel für die Betriebskostenabrechnung. Da diese nur für die HGA anwendbar sind, können Sie damit keinen Bericht erzeugen. Bitte erstellen Sie einen nutzerspezifischen Verteilerschlüssel für die BKA oder ändern Sie den Schlüssel in der HGA ab für:',
      },
      firstLink: {
        en: ' custom DK ',
        de: ' Verteilerschlüssel BKA ',
      },
      secondLink: {
        en: ' DK in HGA ',
        de: ' Verteilerschlüssel HGA ',
      },
    },
    header: {
      title: {
        en: 'Ops Cost Report',
        de: 'Betriebskostenabrechnung',
      },
      status: {
        UNSAVED: {
          en: 'Unsaved',
          de: 'Nicht gespeichert',
        },
        DRAFT: {
          en: 'Draft',
          de: 'Entwurf',
        },
      },
      alertTooltip: {
        en: 'There are issues in this report. You will find further information in the according sections.',
        de: 'In diesem Bericht sind Probleme aufgetreten. Weitere Informationen finden Sie in den jeweiligen Sektionen.',
      },
      closeButton: {
        en: 'Close',
        de: 'Schließen',
      },
      recalculateButton: {
        en: 'Recalculate',
        de: 'Neu berechnen',
      },
      saveButton: {
        en: 'Save as draft',
        de: 'Als Entwurf speichern',
      },
      previewButton: {
        en: 'Preview',
        de: 'Vorschau',
      },
      deleteButton: {
        en: 'Delete',
        de: 'Löschen',
      },
      generateReport: {
        en: 'Generate report',
        de: 'Bericht erstellen',
      },
    },
    form: {
      title: {
        en: 'General data',
        de: 'Allgemein',
      },
      info: {
        en: 'Since flat rate contracts do not pay ops costs, we will not generate reports for them.',
        de: 'Da Mietverträge mit einer Pauschalmiete keine Betriebskosten zahlen, erstellen wir für diese Mieter keine Betriebskostenabrechnung.',
      },
      labels: {
        property: {
          en: 'Property',
          de: 'Objekt',
        },
        wka: {
          en: 'WKA',
          de: 'Wärmekostenabrechnung',
        },
        starteDate: {
          en: 'Start date',
          de: 'Startdatum',
        },
        endDate: {
          en: 'End date',
          de: 'Enddatum',
        },
        docTitle: {
          en: 'Document title',
          de: 'Titel des Dokuments',
        },
        calculate: {
          en: 'Calculate',
          de: 'Berechnen',
        },
        createNewWka: {
          en: 'Create new WKA',
          de: 'Neue WKA erstellen',
        },
      },
      validation: {
        required: {
          en: 'Field is required',
          de: 'Feld ist erforderlich',
        },
        invalidDate: {
          en: 'Invalid date range',
          de: 'Ungültiger Datumsbereich',
        },
      },
      sectionSummary: {
        select: {
          en: 'Property',
          de: 'Objekt',
        },
        starteDate: {
          en: 'Start date',
          de: 'Startdatum',
        },
        endDate: {
          en: 'End date',
          de: 'Enddatum',
        },
        docTitle: {
          en: 'Document title',
          de: 'Titel des Dokuments',
        },
      },
      contractSelectionTable: {
        unitNr: {
          en: 'Unit-Nr',
          de: 'Einheits-Nr',
        },
        category: {
          en: 'Category',
          de: 'Kategorie',
        },
        owner: {
          en: 'Owner',
          de: 'Eigentümer',
        },
        wou: {
          en: 'WOU',
          de: 'WEE',
        },
        tooltipTextWOU: {
          en: 'WEG owned unit',
          de: 'WEG-eigene Einheit',
        },
        tooltipTextSEV: {
          en: 'Special property management',
          de: 'Sondereigentumsverwaltung',
        },
        tenant: {
          en: 'Tenant',
          de: 'Mieter',
        },
        rentalPeriod: {
          en: 'Rental period',
          de: 'Mietzeitraum',
        },
        vacancy: {
          en: 'Vacancy',
          de: 'Leerstand',
        },
        roles: {
          [ContractContactProjectionDtoRoleEnum.OWNER]: {
            en: 'Owner',
            de: 'Eigentümer',
          },
          [ContractContactProjectionDtoRoleEnum.TENANT]: {
            en: 'Tenant',
            de: 'Mieter',
          },
          [ContractContactProjectionDtoRoleEnum.COUNCIL]: {
            en: 'Council',
            de: 'Beirat',
          },
          [ContractContactProjectionDtoRoleEnum.HEAD_OF_COUNCIL]: {
            en: 'Head of Council',
            de: 'Beiratsvorsitz',
          },
          [ContractContactProjectionDtoRoleEnum.PROPERTY_OWNER]: {
            en: 'Property Owner',
            de: 'Objekteigentümer',
          },
          [ContractContactProjectionDtoRoleEnum.LEGAL_REPRESENTATIVE]: {
            en: 'Legal Representative',
            de: 'Bevollmächtigte',
          },
          [ContractContactProjectionDtoRoleEnum.SUBTENANT]: {
            en: 'Subtenant',
            de: 'Untermieter',
          },
          [ContractContactProjectionDtoRoleEnum.RESIDENT]: {
            en: 'Resident',
            de: 'Bewohner',
          },
          [ContractContactProjectionDtoRoleEnum.LEGAL_GUARDIAN]: {
            en: 'Legal Guardian',
            de: 'Vormund',
          },
        },
        unitTypes: {
          [UnitProjectionDtoUnitTypeEnum.APARTMENT]: {
            en: 'Apartment',
            de: 'Wohnung',
          },
          [UnitProjectionDtoUnitTypeEnum.COMMERCIAL]: {
            en: 'Commercial',
            de: 'Gewerbeeinheit',
          },
          [UnitProjectionDtoUnitTypeEnum.PARKING]: {
            en: 'Parking',
            de: 'Parkmöglichkeit',
          },
          [UnitProjectionDtoUnitTypeEnum.OTHER]: {
            en: 'Other',
            de: 'Sonstiges',
          },
        },
        emptyTable: {
          en: 'There are no active contracts within the reports time period.',
          de: 'Es gibt keine aktiven Verträge innerhalb des Berichtszeitraums.',
        },
        noResultsMatchingFilter: {
          en: 'No results matching the applied filters.',
          de: 'Keine Ergebnisse für die angewendeten Filter.',
        },
        noPropertySelected: {
          en: 'Please select a property to view the contracts.',
          de: 'Bitte wählen Sie ein Objekt, um die Verträge anzuzeigen.',
        },
        checkboxLabel: {
          en: 'Show selected',
          de: 'Nur ausgewählte anzeigen',
        },
        validationErrors: {
          goToPropertyButton: {
            en: 'Go to property',
            de: 'Zum Objekt',
          },
          [OPS_COST_VALIDATION_ERROR.invalidContracts]: {
            title: {
              en: 'Invalid contracts',
              de: 'Ungültige Verträge',
            },
            message: {
              en: 'Please make sure that there are no gaps or overlaps in the period of the contracts.',
              de: 'Stellen Sie sicher, dass es keine Lücken oder Überschneidungen bei den Zeiträumen der Verträge gibt.',
            },
          },
          [OPS_COST_VALIDATION_ERROR.missingBankAccount]: {
            title: {
              en: 'Missing bank account',
              de: 'Fehlendes Bankkonto',
            },
            message: {
              en: 'Please make sure that the property has a bank account assigned.',
              de: 'Stellen Sie sicher, dass dem Objekt ein Bankkonto zugewiesen ist.',
            },
          },
          [OPS_COST_VALIDATION_ERROR.networkError]: {
            title: {
              en: 'Network error',
              de: 'Netzwerkfehler',
            },
            message: {
              en: 'Communication with the server failed.',
              de: 'Kommunikation mit dem Server fehlgeschlagen.',
            },
          },
        },
      },
    },
    sections: {
      yourSettlementSection: {
        yourSettlementSectionTitle: {
          en: 'Your Settlement',
          de: 'Ihre Abrechnung',
        },
        columns: {
          valueExplanationColumn: {
            en: 'Calculation of your Share',
            de: 'Berechnung Ihres Anteils',
          },
          amount: {
            en: 'Your share',
            de: 'Ihr Betrag €',
          },
          net: {
            en: 'Net',
            de: 'Netto',
          },
          gross: {
            en: 'Gross',
            de: 'Brutto',
          },
          vat: {
            en: 'Vat',
            de: 'Ust',
          },
        },
        rowLabels: {
          expensesTotal: {
            en: 'Sum Expenses',
            de: 'Summe Ausgaben',
          },
          prepaymentsTotal: {
            en: 'Sum Prepayments (Actual)',
            de: 'Summe geleistete Vorauszahlungen',
          },
          total: {
            en: 'Your result: Credit note (-) / Additional payment (+)',
            de: 'Ihr Ergebnis: Guthaben (-) / Nachzahlung (+)',
          },
        },
        expenseSubSection: {
          title: {
            en: 'Heating Cost Settlement',
            de: 'Heizkostenabrechnung',
          },
          columns: {
            accountCode: {
              en: 'Account-Nr.',
              de: 'Konto-Nr.',
            },
            accountName: {
              en: 'Account name',
              de: 'Kontobezeichnung',
            },
            distributionKey: {
              en: 'Distribution key',
              de: 'Umlageschlüssel',
            },
            yourShareVat: {
              en: 'Your share',
              de: 'Ihr Anteil',
            },
            total: {
              en: 'Total amount',
              de: 'Gesamtbetrag €',
            },
            yourTotal: {
              en: 'Your amount',
              de: 'Ihr-Betrag €',
            },
            net: {
              en: 'Net',
              de: 'Netto',
            },
            gross: {
              en: 'Gross',
              de: 'Brutto',
            },
            vat: {
              en: 'Vat',
              de: 'Ust',
            },
          },
          subtotal: {
            en: 'Subtotal',
            de: 'Zwischensumme',
          },
          distributionKeyValue: {
            en: 'Fixed amount according to the WKA',
            de: 'Festbetrag gemäß Heizkostenabrechnung',
          },
          accountBalanceLoadingError: {
            en: 'Failed to load account balances.',
            de: 'Laden der Sachkonten fehlgeschlagen.',
          },
          alerts: {
            wkaValuesAreIncorrectFirst: {
              en: 'Heating Costs: Values of ',
              de: 'Heizkosten: Die Werte der WKA ',
            },
            wkaValuesAreIncorrectSecond: {
              en: 'don\'t add up to the total balance of the WKA relevant accounts. You can fix the listed warnings or still continue to generate the report.',
              de: 'entsprechen nicht der Summe der Salden der WKA  relevanten Konten. Falls notwendig, passen Sie diese Werte entsprechend an. Ansonsten können Sie mit der Erstellung des Berichts fortfahren.',
            },
            noWkaSelectedIssue: {
              en: 'Missing WKA: There is no WKA selected to distribute the WKA relevant accounts. Please select or create a new WKA or change the accounts to not WKA relevant:',
              de: 'Fehlende WKA: Für die Verteilung der WKA relevanten Konten ist keine WKA ausgewählt. Bitte wählen Sie eine WKA aus, erstellen Sie eine neue oder ändern Sie die Verteilung der Konten zu nicht WKA relevant ab:',
            },
            incorrectWkaArticleLinkTitle: {
              en: 'Warning on heating costs',
              de: 'Warnung zu Heizkosten',
            },
            missingWkaArticleLinkTitle: {
              en: 'Missing heating costs warning',
              de: 'Warnung zu fehlender WKA',
            },
          },
        },
      },
      expensesSection: {
        expensesSectionTitle: {
          en: 'Expenses',
          de: 'Ausgaben',
        },
        expenseSubSection: {
          title: {
            en: 'Heating Cost Settlement',
            de: 'Heizkostenabrechnung',
          },
          columns: {
            accountCode: {
              en: 'Account-Nr',
              de: 'Konto-Nr.',
            },
            accountName: {
              en: 'Account name',
              de: 'Kontobezeichnung',
            },
            distributionKey: {
              en: 'Distribution key',
              de: 'Umlageschlüssel',
            },
            yourShare: {
              en: 'Your share',
              de: 'Ihr Anteil',
            },
            total: {
              en: 'Total',
              de: 'Gesamt-Betrag €',
            },
            yourTotal: {
              en: 'Your total',
              de: 'Ihr-Betrag €',
            },
          },
          subtotal: {
            en: 'Subtotal:',
            de: 'Zwischensumme:',
          },
          distributionKeyValue: {
            en: 'WKA',
            de: 'Verbrauch',
          },
          yourShare: {
            en: 'your heating cost',
            de: 'siehe Heizkostenabrechnung',
          },
          accountBalanceLoadingError: {
            en: 'Failed to load account balances.',
            de: 'Laden der Sachkonten fehlgeschlagen.',
          },
        },
        otherOpsCostSubSection: {
          otherOpsCostTitle: {
            en: 'Other Ops Costs',
            de: 'Sonstige Betriebskosten',
          },
          transferableCostsFromWeg: {
            en: 'Transferable Costs from WEG',
            de: 'Umlegbare Kosten der WEG',
          },
          columns: {
            accountCode: {
              en: 'Account-Nr.',
              de: 'Konto-Nr.',
            },
            accountName: {
              en: 'Account name',
              de: 'Kontobezeichnung',
            },
            distributionKey: {
              en: 'Distribution key',
              de: 'Umlageschlüssel',
            },
            yourAmount: {
              en: 'Your share',
              de: 'Ihr Betrag €',
            },
            totalAmount: {
              en: 'Total amount',
              de: 'Gesamtbetrag €',
            },
            net: {
              en: 'Net',
              de: 'Netto',
            },
            gross: {
              en: 'Gross',
              de: 'Brutto',
            },
            vat: {
              en: 'Vat',
              de: 'Ust',
            },
          },
          subtotal: {
            en: 'Subtotal',
            de: 'Zwischensumme',
          },
          alerts: {
            incorrectDirectCosts: {
              en: 'Direct Costs: Distributed values don\'t match the balance on the accounts for. You can fix the listed warnings or still continue to generate the report.',
              de: 'Direkte Kosten: Die verteilten Beträge entsprechen nicht dem Saldo der Konten. Falls notwendig, passen Sie diese Werte entsprechend an. Ansonsten können Sie mit der Erstellung des Berichts fortfahren.',
            },
            missingDistributionSets: {
              en: "Missing Distribution Set: The following transferable accounts don't have a distribution set assigned:",
              de: 'Fehlende Verteilerschlüssel: Den folgenden umlagefähigen Konten ist kein Verteilerschlüssel zugewiesen:',
            },
            incorrectDirectCostArticleLinkTitle: {
              en: 'Direct Cost Warning',
              de: 'Warnung zu Direkten Kosten',
            },
            missingDistributionSetArticleLinkTitle: {
              en: 'Other operating costs',
              de: 'Sonstige Betriebskosten',
            },
            missingAccountDistributions: {
              en: (endDate: number) => `Missing Account Distributions: There is no account distribution set for the reporting time period. Please first copy one of the existing distribution sets to the year that includes the end date of the report, ${endDate}, by clicking on the time range indicator in the `,
              de: (endDate: number) => `Fehlende Kontenverteilung: Für den Zeitraum des Berichts ist keine Kontenverteilung hinterlegt. Kopieren Sie bitte zunächst die bestehenden Verteilerschlüssel in das Jahr, welches das Enddatum des Berichts, ${endDate}, enthält, indem Sie auf das Feld mit der Zeitspanne in der `,
            },
            missingAccountDistributionsLink: {
              en: 'Distribution Sets overview',
              de: 'Übersicht der Verteilerschlüssel',
            },
            distributionSetsWithZeroValues: {
              en: 'Distribution Sets with distribution value of 0: The following distribution sets are used to distribute costs, but there are no distribution values set for them. To execute this report, please add the distribution values or change the distribution set on the accounts:',
              de: 'Verteilerschlüssel mit Verteilungswert 0: Die folgenden Verteilerschlüssel haben keine Werte hinterlegt, werden aber verwendet, um Kosten zu verteilen. Um den Bericht auszuführen, fügen Sie bitte entweder Werte zu den Verteilerschlüsseln hinzu oder verändern Sie die Verteilung der Konten:',
            },
            generalDistributionSetArticleLinkTitle: {
              en: 'Warning on Distribution Sets',
              de: 'Warnung zu Verteilerschlüssel',
            },
          },
        },
        individualTenantCostSubSection: {
          title: {
            en: 'Individual Tenant Costs',
            de: 'Individuelle Mieterkosten ',
          },
          columns: {
            accountCode: {
              en: 'Account-Nr.',
              de: 'Konto-Nr.',
            },
            accountName: {
              en: 'Account name',
              de: 'Kontobezeichnung',
            },
            yourAmount: {
              en: 'Your share',
              de: 'Ihr Betrag €',
            },
            totalAmount: {
              en: 'Total amount',
              de: 'Gesamtbetrag €',
            },
            net: {
              en: 'Net',
              de: 'Netto',
            },
            gross: {
              en: 'Gross',
              de: 'Brutto',
            },
            vat: {
              en: 'Vat',
              de: 'Ust',
            },
          },
          subtotal: {
            en: 'Subtotal',
            de: 'Zwischensumme',
          },
        },
        distributionSetsSubSection: {
          title: {
            en: 'Distribution Sets',
            de: 'Umlageschlüssel',
          },
          noDistributionSets: {
            en: 'There are no distribution sets.',
            de: 'Es gibt keine Umlageschlüssel.',
          },
          columns: {
            name: {
              en: 'Name',
              de: 'Bezeichnung',
            },
            totalShare: {
              en: 'Total Share',
              de: 'Gesamtanteil',
            },
            yourShare: {
              en: 'Your Share',
              de: 'Einzelanteil',
            },
            timePeriod: {
              en: 'Time Period',
              de: 'Zeitanteil',
            },
          },
        },
        totalExpensesSubSection: {
          title: {
            en: 'Total Expenses',
            de: 'Gesamte Ausgaben',
          },
          columns: {
            position: {
              en: 'Position',
              de: 'Position',
            },
            totalAmount: {
              en: 'Total amount',
              de: 'Gesamtbetrag €',
            },
            yourAmount: {
              en: 'Your share',
              de: 'Ihr Betrag €',
            },
            net: {
              en: 'Net',
              de: 'Netto',
            },
            gross: {
              en: 'Gross',
              de: 'Brutto',
            },
            vat: {
              en: 'Vat',
              de: 'Ust',
            },
          },
          positionValues: {
            heatingCost: {
              en: 'Heating Cost Settlement',
              de: 'Heizkostenabrechnung',
            },
            otherOpsCosts: {
              en: 'Other Ops Costs',
              de: 'Sonstige Betriebskosten',
            },
            costsFromWeg: {
              en: 'Costs from WEG',
              de: 'Umlegbare Kosten der WEG',
            },
            individualTenantCosts: {
              en: 'Individual Tenant Costs',
              de: 'Individuelle Mieterkosten ',
            },
          },
          total: {
            en: 'Total',
            de: 'Gesamt',
          },
        },
      },
      yourPrepaymentsSection: {
        titel: {
          en: 'Your Operational Costs Prepayments',
          de: 'Ihre Betriebskosten Vorauszahlungen',
        },
        postingsLoadingError: {
          en: 'Failed to load postings.',
          de: 'Laden der Sachkontenbuchungen fehlgeschlagen.',
        },
        columns: {
          date: {
            en: 'Date',
            de: 'Datum',
          },
          bookingText: {
            en: 'Booking text',
            de: 'Buchungstext',
          },
          amount: {
            en: 'Paid',
            de: 'Gezahlt',
          },
          total: {
            en: 'Sum',
            de: 'Summe',
          },
          net: {
            en: 'Net',
            de: 'Netto',
          },
          gross: {
            en: 'Gross',
            de: 'Brutto',
          },
          vat: {
            en: 'Vat',
            de: 'Ust',
          },
        },
      },
      annex35aSection: {
        title: {
          en: 'Annex to the Ops Cost Report: Statement to the labor cost according to § 35a',
          de: 'Anlage zur Betriebskostenabrechnung: Ausweis der Lohnkosten nach § 35a',
        },
        columns: {
          accountNumber: {
            en: 'Account-Nr.',
            de: 'Konto-Nr.',
          },
          accountName: {
            en: 'Account name',
            de: 'Kontobezeichnung',
          },
          distributionKey: {
            en: 'Distribution key',
            de: 'Umlageschlüssel',
          },
          sumOfRelevantInvoices: {
            en: 'Sum of relevant invoices',
            de: 'Summe relevanter Rechnungen €',
          },
          thereofLaborCost: {
            en: 'thereof labor cost according to § 35a',
            de: 'davon Lohnkosten gem § 35a €',
          },
          yourShare: {
            en: 'Your share labor cost €',
            de: 'Ihr Anteil Lohnkosten €',
          },
        },
        summaryLabels: {
          householdRelatedServices: {
            en: 'A1: Sum Household Related Services',
            de: 'A1: Summe Haushaltsnahe Dienstleistungen',
          },
          technicianService: {
            en: 'A2: Sum Technician Services',
            de: 'A2: Summe Dienstleistungsanteil Handwerkerrechnungen',
          },
          marginalEmployment: {
            en: 'A3: Sum Marginal Employments',
            de: 'A3: Summe Geringfügige Beschäftigungen',
          },
          insurableEmployment: {
            en: 'A4: Sum Insurable Employments',
            de: 'A4: Summe Sozialversicherungspflichtige Beschäftigungen',
          },
          total: {
            en: 'Sum of all labor costs according to § 35a (A1 - A4)',
            de: 'Summe der Lohnkosten nach §35a (A1-A4)',
          },
        },
        distributionKeyLabels: {
          DIRECT_COST: {
            en: 'Fixed amount according to direct costs',
            de: 'Festbetrag gemäß direkter Kosten',
          },
          MULTI_ACCOUNT_DIRECT_COST: {
            en: 'Fixed amount according to the WKA',
            de: 'Festbetrag gemäß Heizkostenabrechnung',
          },
        },
      },
      distributionKeyLabels: {
        DIRECT_COST: {
          en: 'Fixed amount according to direct costs',
          de: 'Festbetrag gemäß direkter Kosten',
        },
        MULTI_ACCOUNT_DIRECT_COST: {
          en: 'Fixed amount according to the WKA',
          de: 'Festbetrag gemäß Heizkostenabrechnung',
        },
      },
      common: {
        addButton: {
          en: 'Add',
          de: 'Hinzufügen',
        },
        addRowDescription: {
          en: "Added values won't be booked.",
          de: 'Hinzugefügte Werte werden nicht gebucht.',
        },
        includeToReport: {
          en: 'Include to report',
          de: 'In den Bericht einschließen',
        },
        excludeFromReport: {
          en: 'Exclude from report',
          de: 'Von Bericht ausschließen',
        },
        deleteRowModal: {
          title: {
            en: 'Delete custom value',
            de: 'Benutzerdefinierten Wert löschen',
          },
          content: {
            en: 'This value will be deleted permanently. However, you can add a new value again.',
            de: 'Dieser Wert wird dauerhaft gelöscht. Sie können jedoch wieder einen neuen Wert hinzufügen.',
          },
          okText: {
            en: 'Delete',
            de: 'Löschen',
          },
          cancelText: {
            en: 'Cancel',
            de: 'Abbrechen',
          },
        },
      },
    },
    emptyTable: {
      common: {
        en: 'There are no accounts with relevant balances for this category within the reports time period.',
        de: 'Es gibt für diese Kategorie keine Konten mit relevanten Salden innerhalb des Zeitraums des Berichts.',
      },
      distributionSets: {
        en: 'No distribution sets are used for this report.',
        de: 'Für diesen Bericht wurden keine Umlageschlüssel genutzt.',
      },
      annex: {
        en: 'There were no relevant §35a costs within the reports time period.',
        de: 'Es sind keine relevanten Lohnkosten gemäß §35a innerhalb des Zeitraums des Berichts angefallen.',
      },
      yourPayments: {
        en: 'There are no Prepayments registered within the reports timerange.',
        de: 'Es sind keine Vorauszahlungen innerhalb des Zeitraums des Berichts registriert worden.',
      },
    },
  },
  modals: {
    contractsValidity: {
      title: {
        en: 'Invalid contracts',
        de: 'Ungültige Verträge',
      },
      description: {
        en: 'Not all units of this property have valid contracts, therefore you cannot execute this process.',
        de: 'Nicht alle Einheiten dieses Objekts haben gültige Verträge, deshalb kann der Prozess nicht ausgeführt werden.',
      },
      okText: {
        en: 'Edit contracts',
        de: 'Verträge bearbeiten',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      checkFailed: {
        en: 'Failed to check contracts validity.',
        de: 'Verträge konnten nicht geprüft werden.',
      },
    },
    breakdownRentContractsValidity: {
      title: {
        en: 'No ops cost postings',
        de: 'Keine Verbuchung von Betriebskosten',
      },
      description: {
        en: 'Within the selected timerange there are no ops cost related postings on the tenant contracts. Therefore no ops cost report can be generated. Please review the rent plans of the tenant contracts and adapt them.',
        de: 'Innerhalb des ausgewählten Zeitraums bestehen keine Buchungen von Betriebskosten für die Mietverträge. Deshalb kann keine Betriebskostenabrechnung generiert werden. Bitte sehen Sie sich die Mietzahlungen der Mietverträge an und passen diese entsprechend an.',
      },
      okText: {
        en: 'View contracts',
        de: 'Verträge ansehen',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      checkFailed: {
        en: 'Failed to check validity.',
        de: 'Verträge konnten nicht geprüft werden.',
      },
    },
    missingPropertyBankAccount: {
      title: {
        en: 'Missing bank account',
        de: 'Fehlendes Bankkonto',
      },
      description: {
        en: 'The property does not have a bank account, therefore you cannot execute this process.',
        de: 'Die Objekt verfügt über kein Bankkonto, deshalb kann der Prozess nicht ausgeführt werden.',
      },
      okText: {
        en: 'Manage bank accounts',
        de: 'Bankkonten bearbeiten',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      checkFailed: {
        en: 'Failed to check property bank account.',
        de: 'Bankkonten konnten nicht geprüft werden.',
      },
    },
    overlapWarning: {
      title: {
        en: 'Overlap',
        de: 'Überschneidung',
      },
      description: {
        en: 'This ops cost report overlaps with another report. Are you sure you want to create another one?',
        de: 'Dieser Bericht überschneidet sich zeitlich mit einem anderen Bericht. Sind Sie sicher, dass Sie einen weiteren erstellen wollen?',
      },
      descriptionExecuted: {
        en: 'This report overlaps with the timeframe of another report whos result was already booked. Are you sure you want to book another settlement result?',
        de: 'Dieser Bericht überschneidet sich zeitlich mit einem anderen Bericht, dessen Ergebnis bereits gebucht wurde. Sind Sie sicher, dass Sie ein weiteres Abrechnungsergebnis buchen wollen?',
      },
      okText: {
        en: 'Yes',
        de: 'Ja',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
  },
  opsCostReportDocument: {
    introductionText: {
      salutation: {
        en: 'Ladies and Gentlemen,',
        de: 'Sehr geehrte Damen und Herren,',
      },
      explanation: {
        en: 'Below you will find the utility bill for:',
        de: 'nachfolgend erhalten Sie die Betriebskostenabrechnung für:',
      },
    },
    paymentInformation: {
      additionalPaymentWithMandateInformation: {
        en: 'In case we have a SEPA-Mandate, the additional payment will be automatically drawn.',
        de: 'Liegt uns ein SEPA-Lastschriftmandat vor, wird die Nachzahlung automatisch eingezogen.',
      },
      additionalPaymentWithoutMandateInformation: {
        en: 'The additional payment is due within the next 4 weeks and has to be transferred to the following bank account:',
        de: 'Die Nachzahlung ist innerhalb der nächsten 4 Wochen fällig und muss auf das nachfolgende Bankkonto erfolgen:',
      },
      payoutWithBankAccountInformation: {
        en: 'Your credit note will be transferred to the following bank connection within the next 4 weeks:',
        de: 'Ihr Guthaben wird innerhalb der nächsten 4 Wochen an das folgende Bankkonto überwiesen:',
      },
      payoutWithoutBankAccountInformation: {
        en: 'Please forward us your bank account details so we can transfer your credit note.',
        de: 'Bitte teilen Sie uns Ihre Bankdaten mit, damit wir Ihr Guthaben auszahlen können.',
      },
      ibanLabel: {
        en: 'IBAN:',
        de: 'IBAN:',
      },
      accountHolderLabel: {
        en: 'Recipient:',
        de: 'Empfänger:',
      },
    },
    regards: {
      en: 'Best regards',
      de: 'Mit freundlichen Grüßen',
    },
    dateLabel: {
      en: 'Date',
      de: 'Datum',
    },
    propertyInternalIdLabel: {
      en: 'Property internal ID',
      de: 'Objekt-Nr.',
    },
  },
  statuses: {
    [OpsCostReportProjectionStatusEnum.BOOKED]: {
      en: 'Booked',
      de: 'Gebucht',
    },
    [OpsCostReportProjectionStatusEnum.SENT]: {
      en: 'Sent',
      de: 'Gesendet',
    },
    [OpsCostReportProjectionStatusEnum.DRAFT]: {
      en: 'Draft',
      de: 'Entwurf',
    },
    [OpsCostReportProjectionStatusEnum.IN_GENERATION]: {
      en: 'Generating',
      de: 'Wird erstellt',
    },
    [OpsCostReportProjectionStatusEnum.FAILED]: {
      en: 'Failed',
      de: 'Fehlgeschlagen',
    },
    [OpsCostReportProjectionStatusEnum.DONE]: {
      en: 'Done',
      de: 'Erledigt',
    },
    [OpsCostReportProjectionStatusEnum.READY]: {
      en: 'Ready',
      de: 'Bereit',
    },
  },
  failedMessagesTooltip: {
    en: 'messages failed.',
    de: 'Nachrichten fehlgeschlagen.',
  },
  failedMessagesTooltipLink: {
    en: 'View failed messages.',
    de: 'Fehlgeschlagene Nachrichten ansehen.',
  },
  alerts: {
    missingHiddenAccountFirst: {
      en: 'The following hidden account',
      de: 'Die folgenden ausgeblendetes Sachkonten',
    },
    missingHiddenAccountSecond: {
      en: 'got deleted or was split. Please check whether this has consequences for your report.',
      de: 'wurden gelöscht oder auf weitere Konten unterteilt. Prüfen Sie bitte, ob dies Auswirkungen auf den Bericht hat.',
    },
  },
  invalidFields: {
    en: 'The following fields must be filled out in order to guarantee a completed Report.',
    de: 'Die folgenden Felder müssen ausgefüllt werden um eine BKA mit vollständigen Werten zu gewährleisten.',
  },
};
