import React, {
  useContext,
  useMemo,
  useRef,
} from 'react';

import {
  OwnersMeetingProtocolDtoMeetingLeaderSigningDispatchTypesEnum,
  OwnersMeetingProtocolDtoStatusEnum,
  OwnersMeetingProtocolRecipientExtendedDisplayDto,
  OwnersMeetingProtocolRecipientExtendedDisplayDtoDispatchTypesEnum,
  OwnersMeetingProtocolRecipientExtendedDisplayDtoSigningRoleEnum,
} from 'api/accounting';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import SelectInput, {
  SelectOption,
} from 'elements/Inputs/SelectInput/SelectInput';
import {
  translations as generalTranslations,
} from 'elements/Translation/translations';
import { setValue } from 'lib/Utils';
import { cloneDeep, isEmpty } from 'lodash';
import {
  OwnersMeetingProtocol,
  OwnersMeetingProtocolRecipient,
} from 'pages/OwnersMeetingProtocol/interfaces';
import {
  OwnersMeetingProtocolContext,
} from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';
import { translations } from 'pages/OwnersMeetingProtocol/translations';

import Icon from '@ant-design/icons';


export const useSigningPersonsTable = () => {
  const {
    ownersMeeting, updateOwnersMeetingProtocol, loadingRecipients, setDirty,
  } = useContext(OwnersMeetingProtocolContext);
  const { tl, language } = useContext(LanguageContext);
  const disabled = ![OwnersMeetingProtocolDtoStatusEnum.OPEN, OwnersMeetingProtocolDtoStatusEnum.SENT_FOR_SIGNATURE].includes(ownersMeeting.data.status);
  const disabledRef = useRef<boolean>(true);
  disabledRef.current = useMemo(() => disabled, [disabled]);
  const ownerOptionsRef = useRef<SelectOption[]>([]);
  const loading = loadingRecipients || ownersMeeting.loading;


  ownerOptionsRef.current = useMemo(() => (
    ownersMeeting?.data?.protocolRecipients?.filter((attendee:OwnersMeetingProtocolRecipientExtendedDisplayDto, index, self) => !attendee.isSigningPerson && attendee.contractProjectionDto
    && index === self.findIndex(value => attendee.contractProjectionDto?.mailingContact?.name === value.contractProjectionDto?.mailingContact?.name))
      .map((attendee: OwnersMeetingProtocolRecipientExtendedDisplayDto) => ({
        label: attendee.contractProjectionDto.mailingContact?.name,
        value: attendee.contractId,
      })).sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []),
  [ownersMeeting?.data?.protocolRecipients]);
  // eslint-disable-next-line no-undef

  const signingRoleOptions = useMemo(() => Object.values(OwnersMeetingProtocolRecipientExtendedDisplayDtoSigningRoleEnum).map((role: string) => ({
    label: tl(translations.editPage.sections.confirmProtocol.submitForSignature.signingRoles[role]),
    value: role,
  })), [language]);

  const signingDispatchTypeOptions = useMemo(() => Object.values(OwnersMeetingProtocolRecipientExtendedDisplayDtoDispatchTypesEnum).map((dispatchType: string) => ({
    label: tl(translations.editPage.sections.confirmProtocol.submitForSignature.signingDispatchTypes[dispatchType]),
    value: dispatchType,
  })), [language]);

  const meetingLeaderSigningDispatchTypeRef = useRef<OwnersMeetingProtocolDtoMeetingLeaderSigningDispatchTypesEnum | null>(null);
  meetingLeaderSigningDispatchTypeRef.current = useMemo(() => ownersMeeting.data.meetingLeaderSigningDispatchTypes, [ownersMeeting]);

  const validationErrorsRef = useRef<any>(null);
  validationErrorsRef.current = useMemo(() => ownersMeeting.validationErrors, [ownersMeeting]);

  const changeSigningPerson = (oldOwnerId: number, newOwnerId: number) => {
    setDirty(true);
    updateOwnersMeetingProtocol((currentState: OwnersMeetingProtocol) => {
      const newOwnersMeeting: OwnersMeetingProtocol | null = cloneDeep(currentState);
      const oldSingingPerson: OwnersMeetingProtocolRecipient = newOwnersMeeting!.protocolRecipients!.filter((attendee: OwnersMeetingProtocolRecipient) => attendee.contractId === oldOwnerId)[0];
      const newSingingPerson: OwnersMeetingProtocolRecipient = newOwnersMeeting!.protocolRecipients!.filter((attendee: OwnersMeetingProtocolRecipient) => attendee.contractId === newOwnerId)[0];

      newSingingPerson.isSigningPerson = true;
      oldSingingPerson.isSigningPerson = false;
      newSingingPerson.signingIndex = oldSingingPerson.signingIndex;
      delete oldSingingPerson.signingIndex;
      return newOwnersMeeting;
    });
  };

  const onChangeProtocolRecipients = (contractId: number, value: any, field:string) => {
    setDirty(true);
    updateOwnersMeetingProtocol((currentState: OwnersMeetingProtocol) => {
      const newOwnersMeeting: OwnersMeetingProtocol | null = cloneDeep(currentState);
      const index = newOwnersMeeting?.protocolRecipients?.findIndex(protocol => protocol.contractId === contractId);
      const key = contractId ? `protocolRecipients[${index}].${field}` : 'meetingLeaderSigningDispatchTypes';
      setValue(newOwnersMeeting, key, value);
      return newOwnersMeeting;
    });
  };

  const onRemoveSigningPerson = (contractId: number) => {
    setDirty(true);
    updateOwnersMeetingProtocol((currentState: OwnersMeetingProtocol) => {
      const newOwnersMeeting: OwnersMeetingProtocol | null = cloneDeep(currentState);
      const singingPerson: OwnersMeetingProtocolRecipient = newOwnersMeeting!.protocolRecipients!.filter((attendee: OwnersMeetingProtocolRecipient) => attendee.contractId === contractId)[0];
      singingPerson.isSigningPerson = false;
      delete singingPerson.signingIndex;
      return newOwnersMeeting;
    });
  };

  const columns = [
    {
      title: tl(translations.editPage.sections.attendeeList.columns.name),
      dataIndex: 'ownerName',
      defaultWidth: '40%',
      render: (_, protocolRecipient: OwnersMeetingProtocolRecipient) => (
        protocolRecipient?.contractId ? (
          <SelectInput
            showSearch
            onChange={(value: number) => changeSigningPerson(protocolRecipient?.contractId, value)}
            value={protocolRecipient.contractId}
            disabled={disabledRef.current}
            options={ownerOptionsRef.current?.concat([{
              value: protocolRecipient?.contractId,
              label: protocolRecipient?.ownerName || '',
            }]) ?? []}
          />
        ) : protocolRecipient?.ownerName
      ),
    },
    {
      title: tl(translations.editPage.sections.confirmProtocol.submitForSignature.columns.role),
      dataIndex: 'signingRole',
      defaultWidth: '25%',
      render: (signingRole: OwnersMeetingProtocolRecipientExtendedDisplayDtoSigningRoleEnum, attendee: OwnersMeetingProtocolRecipient, index: number) => {
        const isValidationError = !isEmpty(validationErrorsRef?.current) && !isEmpty(validationErrorsRef?.current[`protocolRecipients[${index}].signingRole`]);
        return (
          attendee.contractId ? (
            <SelectInput
              onChange={(value: number) => onChangeProtocolRecipients(attendee.contractId, value, 'signingRole')}
              value={attendee.signingRole}
              disabled={disabledRef.current}
              options={signingRoleOptions}
              validationState={isValidationError ? 'error' : ''}
              validationMessage={isValidationError ? tl(generalTranslations.validations[validationErrorsRef.current[`protocolRecipients[${index}].signingRole`]] || generalTranslations.validations.defaultError) : ''}
            />
          ) : attendee.signingRole
        );
      },
    },
    {
      title: tl(translations.editPage.sections.confirmProtocol.submitForSignature.columns.dispatchType),
      dataIndex: 'signingDispatchTypes',
      defaultWidth: '30%',
      render: (signingDispatchTypes: any, attendee: OwnersMeetingProtocolRecipient, index: number) => {
        const key = attendee.contractId ? `protocolRecipients[${index}].signingDispatchTypes` : 'meetingLeaderSigningDispatchTypes';
        const isValidationError = attendee.contractId ? (!isEmpty(validationErrorsRef.current) && !isEmpty(validationErrorsRef.current[`protocolRecipients[${index}].signingDispatchType`]))
          : (!isEmpty(validationErrorsRef.current) && !isEmpty(validationErrorsRef.current.meetingLeaderSigningDispatchTypes));

        return (
          <SelectInput
            onChange={(value: number) => (onChangeProtocolRecipients(attendee.contractId, value, 'signingDispatchTypes'))}
            value={attendee.contractId ? signingDispatchTypes : meetingLeaderSigningDispatchTypeRef.current}
            mode="multiple"
            disabled={disabledRef.current}
            options={signingDispatchTypeOptions}
            validationState={isValidationError ? 'error' : ''}
            validationMessage={isValidationError ? tl(generalTranslations.validations[validationErrorsRef.current[key]] || generalTranslations.validations.defaultError) : ''}
          />
        );
      },
    },
    {
      dataIndex: 'contractId',
      defaultWidth: '7%',
      className: 'align-right',
      render: ((contactId: number) => (
        contactId ? (
          <Button
            onClick={() => onRemoveSigningPerson(contactId)}
            size="small"
            disabled={disabledRef.current}
          >
            <Icon component={ICONS.bin} />
          </Button>
        ) : (<></>)
      )),
    },
  ];

  const dataSource = useMemo(() => {
    try {
      return ownersMeeting?.data?.protocolRecipients
        .filter((protocolRecipient: OwnersMeetingProtocolRecipientExtendedDisplayDto) => protocolRecipient.isSigningPerson
            && protocolRecipient.contractProjectionDto && protocolRecipient.contractProjectionDto.mailingContact)
        .map((attendee: OwnersMeetingProtocolRecipientExtendedDisplayDto) => ({
          ...attendee,
          ownerName: attendee.contractProjectionDto.mailingContact.name,
          id: attendee.id,
        }))
        .sort((a: OwnersMeetingProtocolRecipient, b: OwnersMeetingProtocolRecipient) => (a.signingIndex! - b.signingIndex!));
    } catch (e) {
      return [];
    }
  }, [ownersMeeting?.data?.protocolRecipients]);

  return { columns, dataSource, loading };
};
