import { AgendaItemsControllerApi } from 'api/accounting';
import { AgendaItemsContext } from 'contexts/AgendaItemsContext';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import {
  useContext,
} from 'react';
import { translations } from '../translations';
import { useLoadAgendaItemsList } from './useLoadAgendaItemsList';


export const useDeleteActionOnAgendaItemsList = (agendaItemId: number) => {
  const { tl } = useContext(LanguageContext);
  const agendaItemsContext = useContext(AgendaItemsContext);
  if (agendaItemsContext === undefined) {
    throw new Error('useDeleteActionOnAgendaItemsList must be used within a AgendaItemsContextProvider');
  }
  const { apiConfiguration } = useContext(AuthContext);
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));

  const { onLoadAgendaItemsList } = useLoadAgendaItemsList();


  const onDeleteAgendaItem = () => {
    agendaItemsControllerApi.deleteAgendaItemUsingDELETE({ agendaItemId })
      .then(() => {
        onLoadAgendaItemsList(true);
        showNotification({
          key: 'deleteAgendaItemSuccess',
          message: tl(translations.notifications.deleteSuccess),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'deleteAgendaItemError',
          message: tl(translations.notifications.deleteError),
          type: 'error',
        });
      });
  };

  return {
    onDeleteAgendaItem,
  };
};
