import { useContext } from 'react';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { useFindOverlappingEconomicPlans } from '../../services/useFindOverlappingEconomicPlans';


export const useCorrectionsWrapper = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) { // NOTE: houseMoneyListContext is undefined in case of account_distribution EPs and that is expected
    throw new Error('useCorrectionsWrapper must be used within a EconomicPlanContextProvider');
  }

  const { economicPlan } = economicPlanContext;
  const { findOverlappingEconomicPlans } = useFindOverlappingEconomicPlans();

  if (!economicPlan?.data?.id) {
    return ({
      economicPlanSaved: false,
    });
  }

  const hasOverlaps = findOverlappingEconomicPlans().length > 0;

  return ({
    hasOverlaps,
    economicPlanSaved: true,
  });
};
