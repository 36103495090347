import React, { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { translations } from '../translations';
import { useExecuteHouseMoneyYearEndExchange } from '../services/useExecuteHouseMoneyYearEndExchange';
import { useValidationErrors } from '../services/useValidationErrors';


export default function HouseMoneySettlementCloseYearHeaderButtons(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { loading, onExecute, goBack } = useExecuteHouseMoneyYearEndExchange();
  const { isExchangeValid } = useValidationErrors();

  const execute = () => {
    if (isExchangeValid()) {
      onExecute();
    } else {
      showNotification({
        key: 'executeExchangeValidationError',
        message: tl(translations.creationPage.notifications.validationError),
        type: 'error',
      });
    }
  };
  return (
    <div className="buttons">
      <Button
        className="cancel"
        type="ghost"
        loading={loading}
        onClick={goBack}
      >
        {tl(translations.creationPage.cancel)}
      </Button>
      <Button
        className="execute"
        type="primary"
        loading={loading}
        onClick={execute}
      >
        {tl(translations.creationPage.execute)}
      </Button>
    </div>
  );
}
