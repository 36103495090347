import { ContractLegacyControllerApi, ContractProjectionDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { translations } from 'pages/ContractEditor/translations';
import { useContext } from 'react';

export const useContractGroupUtil = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const contractLegacyApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));

  const { tl } = useContext(LanguageContext);

  const getGroupForContract = (contractId: number | undefined): Promise<ContractProjectionDto[]> => {
    if ((!contractId)) return Promise.resolve([]);

    return contractLegacyApi.getContractsUsingGET({ contractIds: [contractId] })
      .then((fetchedContracts) => {
        if (!fetchedContracts || !fetchedContracts.length) return Promise.resolve([]);
        // If no contractGroupID => this is the only one in the group
        if (!fetchedContracts[0].contractGroupId) return Promise.resolve(fetchedContracts);
        // Fetch whole group
        return contractLegacyApi.getContractsUsingGET({ contractGroupIds: [fetchedContracts[0].contractGroupId] });
      })
      .catch(() => {
        showNotification({
          key: 'loadPropertyError',
          type: 'error',
          message: tl(translations.notifications.loadContractGroupError),
        });
        return [];
      });
  };

  return {
    getGroupForContract,
  };
};
