import React from 'react';
import { Link } from 'react-router-dom';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { RecipientDatasourceItem } from '../../interfaces';
import { useContractContactCell } from './useContractContactCell';


const ContractContactCell = ({ record } : {record: RecipientDatasourceItem}) => {
  const { url } = useContractContactCell(record);

  if (record.roles === undefined) {
    return (
      <Link to={url} target="_blank">{record.label}</Link>
    );
  }

  return (
    <Link to={url} target="_blank">
      <CellWithTwoLines
        firstElement={record.label}
        secondElement={record.roles}
      />
    </Link>
  );
};

export default ContractContactCell;
