import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import defaultSection from './defaultSection';
import { translations } from '../../../../../elements/Translation/translations';

export const useDocumentsSection = (contactId: string) => {
  const { tl } = useContext(LanguageContext);

  const onNavigateToDocumentList = () => {
    window.open(`/documents?contactId=${contactId}`);
  };
  return {
    ...defaultSection(6),
    sectionIcon: ICONS.documentsSection,
    sectionTitle: tl(translations.pages.contactDetails.documentsSection.title),
    sectionId: 'DocumentsSectionId',
    onChange: () => {},
    open: false,
    onHoverDisplayButton: true,
    hoverButtonFunction: onNavigateToDocumentList,
    hoverButtonText: tl(translations.pages.contactDetails.documentsSection.button),
    content: [],
    onClickHeader: onNavigateToDocumentList,
  };
};
