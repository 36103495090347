import { InviteUsersContext } from 'pages/Users/InviteUsers/services/InviteUsersContext';
import { useContext } from 'react';
import { ValidateStatuses } from 'lib/Utils';


export const useInviteUsersCell = (id: string, field: 'email' | 'password') => {
  const inviteUsersContext = useContext(InviteUsersContext);
  if (inviteUsersContext === undefined) {
    throw Error('useInviteUsersCell not wrapped in InviteUsersContextProvider');
  }
  const { usersList, setUsersList, setDirty } = inviteUsersContext;

  const user = usersList.data.find(it => it.syntheticId === id);
  if (user === undefined) {
    throw Error(`No user available with id ${id}`);
  }

  const onChange = (value: string) => {
    setUsersList(prev => prev.load(
      prev.data.map(usr => (
        id !== usr.syntheticId ? usr
          : ({
            ...usr,
            [field]: value,
            validationState: {
              ...usr.validationState,
              [field]: undefined,
            },
          })
      )),
    ));
    setDirty(true);
  };

  const validationMessage = user.validationState?.[field];

  return {
    onChange,
    validationState: (validationMessage ? 'error' : '') as typeof ValidateStatuses[number],
  };
};
