import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import CreateProfitAndLossReportButton from './components/CreateProfitAndLossReportButton/CreateProfitAndLossReportButton';
import ProfitAndLossReportListContextProvider from './services/ProfitAndLossReportListContext';
import { useProfitAndLossReportColumns } from './services/useProfitAndLossReportColumns';
import { useProfitAndLossReportList } from './services/useProfitAndLossReportList';
import { useProfitAndLossReportListFilters } from './services/useProfitAndLossReportListFilters';
import { useProfitAndLossReportListSort } from './services/useProfitAndLossReportListSort';

const ProfitAndLossList = (): JSX.Element => {
  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'profitAndLossList',
    navItems: subcategorySwitcherItems.accounting,
  };

  const { profitAndLossReportList, loadProfitAndLossReports, openProfitAndLossReportView } = useProfitAndLossReportList();
  const columns = useProfitAndLossReportColumns();
  const { sortField, sortOrder, onChangeSort } = useProfitAndLossReportListSort();
  const {
    filters, filterState, onChangeFilterState, updateFilterState, defaultFilters
  } = useProfitAndLossReportListFilters();

  useGlobalSearch({
    key: 'profitAndLossReports',
    filterProps: {
      availableFilters: filters,
      setFilter: onChangeFilterState,
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams: updateFilterState,
      defaultFilters,
    },
  });


  const smartTable = useSmartTable({
    tableName: 'profitAndLossReports',
    columns,
    dataSource: profitAndLossReportList.data,
    contentLoading: profitAndLossReportList.loading,
    infiniteScrollerProps: {
      hasMoreData: !profitAndLossReportList.lastPage,
      loadMoreData: loadProfitAndLossReports,
    },
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: onChangeSort,
    },
    rowKey: 'id',
    onRow: openProfitAndLossReportView,
  });

  return (
    <div className="ProfitAndLossList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={<CreateProfitAndLossReportButton />}
      />
      <SmartTable
        {...smartTable}
      />
    </div>
  );
};

const ProfitAndLossReportListWithProvider = () => (
  <ProfitAndLossReportListContextProvider>
    <ProfitAndLossList />
  </ProfitAndLossReportListContextProvider>
);

export default ProfitAndLossReportListWithProvider;
