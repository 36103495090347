import React, { useContext } from 'react';

import {
  AccountAllocation,
  ExtendedBankTransaction,
} from 'pages/BankTransactions/interfaces';
import {
  BankTransactionAllocationContext,
} from 'pages/BankTransactions/services/BankTransactionAllocationContext';

import {
  UpdateListElementFunction,
} from '../../services/useTransactionAllocation';
import BankTransactionAllocationHeader
  from '../BankTransactionAllocationHeader/BankTransactionAllocationHeader';
import ExchangeList from './components/ExchangeList/ExchangeList';
import {
  useServiceCompanyAllocationAmounts,
} from './services/useServiceCompanyAllocationAmounts';
import {
  useSubmitServiceCompanyAllocation,
} from './services/useSubmitServiceCompanyAllocation';
import { useInitializePropertyVatEligibilityInfo } from './services/useInitializePropertyVatEligibilityInfo';
/* !!!Keep this import in last position */
import './ServiceCompanyAllocation.scss';

interface Props {
  bankTransactionsInGroup: ExtendedBankTransaction[];
  selectedBankTransactions: ExtendedBankTransaction[];
  propertyHrIds: string[];
  contentMaxHeight: number;
  resetTransaction: () => void;
  markTransactionNotBookable: () => void;
  markTransactionDuplicate: () => void;
  updateListElement: UpdateListElementFunction;
  unitAllocations: AccountAllocation[];
}

const ServiceCompanyAllocation = ({
  bankTransactionsInGroup,
  selectedBankTransactions,
  propertyHrIds,
  contentMaxHeight,
  resetTransaction,
  markTransactionNotBookable,
  markTransactionDuplicate,
  updateListElement,
  unitAllocations,
}: Props) => {
  const { transactionActionInProgress } = useContext(BankTransactionAllocationContext);
  
  useInitializePropertyVatEligibilityInfo(propertyHrIds, selectedBankTransactions);

  return (
    <div className="ServiceCompanyAllocation table-wrapper">
      <BankTransactionAllocationHeader
        selectedBankTransactions={selectedBankTransactions}
        bankTransactionsInGroup={bankTransactionsInGroup}
        resetTransaction={resetTransaction}
        markTransactionNotBookable={markTransactionNotBookable}
        markTransactionDuplicate={markTransactionDuplicate}
        updateListElement={updateListElement}
        propertyHrIds={propertyHrIds}
        useAmounts={useServiceCompanyAllocationAmounts}
        useControls={useSubmitServiceCompanyAllocation}
        unitAllocations={unitAllocations}
      />
      {selectedBankTransactions[0] && (
        <ExchangeList
          propertyHrIds={propertyHrIds}
          selectedBankTransactions={selectedBankTransactions}
          bankTransactionsInGroup={bankTransactionsInGroup}
          contentMaxHeight={contentMaxHeight}
          transactionActionInProgress={transactionActionInProgress}
        />
      )}
    </div>
  );
};

export default ServiceCompanyAllocation;
