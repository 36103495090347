import React, { useContext, useEffect } from 'react';
import './DirectDebitForm.scss';
import { Form } from 'antd';
import { translations } from '../translations';
import { translations as validationTranslations } from '../../../elements/Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import SelectInput from '../../../elements/Inputs/SelectInput/SelectInput';
import SmartSearch from '../../../elements/Inputs/SmartSearch/SmartSearch';
import { CurrencyInput } from '../../../elements/Inputs/CurrencyInput/CurrencyInput';
import TextAreaInput from '../../../elements/Inputs/TextAreaInput/TextAreaInput';
import DateInput from '../../../elements/Inputs/DateInput/DateInput';
import RadioGroupInput from '../../../elements/Inputs/RadioGroupInput/RadioGroupInput';
import usePropertyOptions from '../../../services/usePropertyOptions';
import usePropertyBankAccountOptions from '../../../services/usePropertyBankAccountOptions';

interface FormProps {
  onChange: (value: any, key: string) => void,
  directDebit: any,
  onClearDirectDebit: () => void,
  loadOwners: (propertyId?: number) => void,
  ownerOptions: { value: number, label: string }[],
  ownerBankAccountOptions: { value: number, label: string }[],
}

export default function DirectDebitForm(props: FormProps) {
  const { tl } = useContext(LanguageContext);
  const {
    directDebit, onClearDirectDebit, onChange, loadOwners, ownerOptions, ownerBankAccountOptions,
  } = props;

  const { searchForProperty, propertyOptions } = usePropertyOptions(false);

  const { onLoadBankAccounts, bankAccountOptions } = usePropertyBankAccountOptions();

  useEffect(() => () => {
    // cleanup
    onClearDirectDebit();
  }, []);

  useEffect(() => {
    onChange(null, 'bankAccountId');
    onChange(null, 'counterpartContactId');
    onChange(null, 'counterpartBankAccountId');
    if (directDebit.data.propertyId) {
      onLoadBankAccounts(directDebit.data.propertyId);
    }
    loadOwners(directDebit.data?.propertyId);
  }, [directDebit.data.propertyId]);

  useEffect(() => {
    onChange(null, 'counterpartBankAccountId');
  }, [directDebit.data.counterpartContactId]);

  const propOptionList = propertyOptions.map(propertyOption => ({
    key: propertyOption?.value,
    value: propertyOption?.value,
    label: propertyOption?.simpleLabel,
  }));


  const isValidationError = (key: any): boolean => !!directDebit.validationErrors && !!directDebit.validationErrors[key];

  return (
    <div id="scrollElement" className="DirectDebitForm">
      <Form className="form">
        <SmartSearch
          label={tl(translations.form.property)}
          onChange={(value: any) => onChange(value, 'propertyId')}
          value={directDebit.data.propertyId}
          required
          options={propOptionList}
          validationState={isValidationError('propertyId') ? 'error' : 'success'}
          validationMessage={isValidationError('propertyId') ? tl(validationTranslations.validations[directDebit.validationErrors.propertyHrId] || validationTranslations.validations.defaultError) : ''}
          className="input"
          searchFunction={searchForProperty}
        />
        <SelectInput
          label={tl(translations.form.bankAccount)}
          onChange={(value: any) => onChange(value, 'bankAccountId')}
          value={directDebit.data.bankAccountId}
          required
          options={bankAccountOptions ?? []}
          validationState={isValidationError('bankAccountId') ? 'error' : 'success'}
          validationMessage={isValidationError('bankAccountId') ? tl(validationTranslations.validations[directDebit.validationErrors.propertyIban] || validationTranslations.validations.defaultError) : ''}
          className="input"
        />
        <SelectInput
          label={tl(translations.form.counterpart)}
          onChange={(value: any) => {
            onChange(value, 'counterpartContactId');
          }}
          value={directDebit.data.counterpartContactId}
          options={ownerOptions}
          showSearch
          required
          validationState={isValidationError('counterpartContactId') ? 'error' : 'success'}
          validationMessage={isValidationError('counterpartContactId') ? tl(validationTranslations.validations[directDebit.validationErrors.counterpartContactId] || validationTranslations.validations.defaultError) : ''}
          className="input"
        />
        <SelectInput
          label={tl(translations.form.counterpartBankAccount)}
          onChange={(value: any) => onChange(value, 'counterpartBankAccountId')}
          value={directDebit.data.counterpartBankAccountId}
          options={ownerBankAccountOptions}
          validationState={isValidationError('counterpartBankAccountId') ? 'error' : 'success'}
          validationMessage={isValidationError('counterpartBankAccountId') ? tl(validationTranslations.validations[directDebit.validationErrors.counterpartBankAccountId] || validationTranslations.validations.defaultError) : ''}
          required
          className="input"
        />
        <CurrencyInput
          onChange={(value: any) => onChange(value, 'amount')}
          label={tl(translations.form.amount)}
          value={directDebit.data.amount}
          required
          validationState={isValidationError('amount') ? 'error' : 'success'}
          validationMessage={isValidationError('amount') ? tl(validationTranslations.validations[directDebit.validationErrors.amount] || validationTranslations.validations.defaultError) : ''}
          className="input"
        />
        <TextAreaInput
          onChange={(value: any) => onChange(value, 'statement')}
          label={tl(translations.form.text)}
          value={directDebit.data.statement}
          required
          validationState={isValidationError('statement') ? 'error' : 'success'}
          validationMessage={isValidationError('statement') ? tl(validationTranslations.validations[directDebit.validationErrors.statement] || validationTranslations.validations.defaultError) : ''}
          className="input"
        />
        <div className="execution-date">
          <RadioGroupInput
            label={tl(translations.form.execution)}
            onChange={(value: any) => onChange(value, 'executionType')}
            value={directDebit.data.executionType}
            className="radio-group"
            inputClassName="radio"
            options={
              [{
                value: 'asap',
                label: tl(translations.form.asap),
              },
              {
                value: 'date',
                label: tl(translations.form.date),
              },
              ]}
          />
          <DateInput
            label=""
            onChange={(value: any) => onChange(value, 'executionDate')}
            value={directDebit.data.executionDate}
            className={`date-picker ${directDebit.data.executionType !== 'date' ? 'hidden' : ''}`}
            validationState={isValidationError('executionDate') ? 'error' : 'success'}
            validationMessage={isValidationError('executionDate') ? tl(validationTranslations.validations[directDebit.validationErrors.executionDate] || validationTranslations.validations.defaultError) : ''}
            disabled={directDebit.data.executionType !== 'date'}
          />
        </div>
      </Form>
    </div>
  );
}
