import './PaymentForm.scss';

import React, {
  useContext,
  useEffect,
} from 'react';

import { Form } from 'antd';
import useContactOptions from 'services/useContactOptions';

import { ContactLegacyDtoTypeEnum } from '../../../api/accounting';
import { LanguageContext } from '../../../contexts/LanguageContext';
import {
  PaymentCreationContext,
} from '../../../contexts/PaymentCreationContext';
import {
  CurrencyInput,
} from '../../../elements/Inputs/CurrencyInput/CurrencyInput';
import DateInput from '../../../elements/Inputs/DateInput/DateInput';
import RadioGroupInput
  from '../../../elements/Inputs/RadioGroupInput/RadioGroupInput';
import SelectInput from '../../../elements/Inputs/SelectInput/SelectInput';
import SmartSearch from '../../../elements/Inputs/SmartSearch/SmartSearch';
import TextAreaInput
  from '../../../elements/Inputs/TextAreaInput/TextAreaInput';
import { translations } from '../../../elements/Translation/translations';
import usePropertyBankAccountOptions
  from '../../../services/usePropertyBankAccountOptions';
import usePropertyOptions from '../../../services/usePropertyOptions';

interface FormProps {
  onChange: (value: any, key: string) => void
}

export default function PaymentForm(props: FormProps) {
  const { tl } = useContext(LanguageContext);
  const paymentCreationContext: any = useContext(PaymentCreationContext);
  const recipientContacts = useContactOptions([ContactLegacyDtoTypeEnum.COMPANY, ContactLegacyDtoTypeEnum.MANAGEMENT_COMPANY, ContactLegacyDtoTypeEnum.PERSON]);

  const { searchForProperty, propertyOptions } = usePropertyOptions(false);

  const { onLoadBankAccounts, bankAccountOptions } = usePropertyBankAccountOptions();

  useEffect(() => () => {
    // cleanup
    paymentCreationContext.onClearPayment();
  },
  []);

  useEffect(() => {
    props.onChange(null, 'bankAccountId');
    if (paymentCreationContext.payment.data.propertyId) {
      onLoadBankAccounts(paymentCreationContext.payment.data.propertyId);
    }
  }, [paymentCreationContext.payment.data.propertyId]);

  useEffect(() => {
    props.onChange(null, 'counterpartBankAccountId');
  }, [paymentCreationContext.payment.data.counterpartContactId]);

  const propOptionList = propertyOptions.map(propertyOption => ({
    key: propertyOption?.value,
    value: propertyOption?.value,
    label: propertyOption?.simpleLabel,
  }));


  const isValidationError = (key: any): boolean => !!paymentCreationContext.payment.validationErrors && !!paymentCreationContext.payment.validationErrors[key];

  return (
    <div id="scrollElement" className="PaymentForm">
      <Form className="form">
        <SmartSearch
          label={tl(translations.pages.bankAccount.payment.form.property)}
          onChange={(value: any) => props.onChange(value, 'propertyId')}
          value={paymentCreationContext.payment.data.propertyId}
          required
          options={propOptionList}
          validationState={isValidationError('propertyId') ? 'error' : 'success'}
          validationMessage={isValidationError('propertyId') ? tl(translations.validations[paymentCreationContext.payment.validationErrors.propertyHrId] || translations.validations.defaultError) : ''}
          className="input"
          searchFunction={searchForProperty}
        />
        <SelectInput
          label={tl(translations.pages.bankAccount.payment.form.bankAccount)}
          onChange={(value: any) => props.onChange(value, 'bankAccountId')}
          value={paymentCreationContext.payment.data.bankAccountId}
          required
          options={bankAccountOptions ?? []}
          validationState={isValidationError('bankAccountId') ? 'error' : 'success'}
          validationMessage={isValidationError('bankAccountId') ? tl(translations.validations[paymentCreationContext.payment.validationErrors.propertyIban] || translations.validations.defaultError) : ''}
          className="input"
        />
        <SmartSearch
          label={tl(translations.pages.bankAccount.payment.form.counterpart)}
          onChange={(value: any) => {
            props.onChange(value, 'counterpartContactId');
          }}
          value={paymentCreationContext.payment.data.counterpartContactId}
          searchFunction={recipientContacts.searchForContact}
          options={recipientContacts.contactOptions}
          getOneFunction={(id: string) => recipientContacts.onLoadContact(parseInt(id, 10))}
          loading={false}
          required
          valueKey=""
          className="input"
        />
        <SelectInput
          label={tl(translations.pages.bankAccount.payment.form.counterpartBankAccount)}
          onChange={(value: any) => props.onChange(value, 'counterpartBankAccountId')}
          value={paymentCreationContext.payment.data.counterpartBankAccountId}
          options={recipientContacts.contactBankAccountMap && recipientContacts.contactBankAccountMap[paymentCreationContext.payment.data.counterpartContactId] ? recipientContacts.contactBankAccountMap[paymentCreationContext.payment.data.counterpartContactId] : []}
          validationState={isValidationError('counterpartBankAccountId') ? 'error' : 'success'}
          validationMessage={isValidationError('counterpartBankAccountId') ? tl(translations.validations[paymentCreationContext.payment.validationErrors.counterpartBankAccountId] || translations.validations.defaultError) : ''}
          required
          className="input"
        />
        <CurrencyInput
          onChange={(value: any) => props.onChange(value, 'amount')}
          label={tl(translations.pages.bankAccount.payment.form.amount)}
          value={paymentCreationContext.payment.data.amount}
          required
          validationState={isValidationError('amount') ? 'error' : 'success'}
          validationMessage={isValidationError('amount') ? tl(translations.validations[paymentCreationContext.payment.validationErrors.amount] || translations.validations.defaultError) : ''}
          className="input"
        />
        <TextAreaInput
          onChange={(value: any) => props.onChange(value, 'statement')}
          label={tl(translations.pages.bankAccount.payment.form.text)}
          value={paymentCreationContext.payment.data.statement}
          required
          validationState={isValidationError('statement') ? 'error' : 'success'}
          validationMessage={isValidationError('statement') ? tl(translations.validations[paymentCreationContext.payment.validationErrors.statement] || translations.validations.defaultError) : ''}
          className="input"
        />
        <div className="execution-date">
          <RadioGroupInput
            label={tl(translations.pages.bankAccount.payment.form.execution)}
            onChange={(value: any) => props.onChange(value, 'executionType')}
            value={paymentCreationContext.payment.data.executionType}
            className="radio-group"
            inputClassName="radio"
            options={
              [{
                value: 'asap',
                label: tl(translations.pages.bankAccount.payment.form.asap),
              },
              {
                value: 'date',
                label: tl(translations.pages.bankAccount.payment.form.date),
              },
              ]}
          />
          <DateInput
            label=""
            onChange={(value: any) => props.onChange(value, 'executionDate')}
            value={paymentCreationContext.payment.data.executionDate}
            className={`date-picker ${paymentCreationContext.payment.data.executionType !== 'date' ? 'hidden' : ''}`}
            validationState={isValidationError('executionDate') ? 'error' : 'success'}
            validationMessage={isValidationError('executionDate') ? tl(translations.validations[paymentCreationContext.payment.validationErrors.executionDate] || translations.validations.defaultError) : ''}
            disabled={paymentCreationContext.payment.data.executionType !== 'date'}
          />
        </div>
      </Form>
    </div>
  );
}
