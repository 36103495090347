export const userListTranslations = {
  email: {
    en: 'Email',
    de: 'Email',
  },
  add: {
    en: 'Add',
    de: 'Hinzufügen',
  },

  actions: {
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
    changePassword: {
      en: 'Edit',
      de: 'Bearbeiten',
    },
  },

  notifications: {
    loadUsersListError: {
      message: {
        en: 'Resource loading error.',
        de: 'Fehler beim Laden der Ressource.',
      },
      description: {
        en: 'Cannot load users.',
        de: 'Benutzer konnten nicht geladen werden.',
      },
    },
    limitedAccess: {
      message: {
        en: 'Limited Access',
        de: 'Eingeschränkter Zugriff',
      },
      description: {
        en: 'You do not have permission to manage users. Please contact the admin if you require further assistance.',
        de: 'Sie sind nicht berechtigt, Benutzer zu verwalten. Bitte wenden Sie sich an den Administrator, wenn Sie weitere Hilfe benötigen.',
      },
    },
  },
};
