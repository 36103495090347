import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import { DocumentProjectionDto, DocumentProjectionDtoPropertyAdministrationTypeEnum } from 'api/document';
import { DocumentDto, GetDocumentsByFilterUsingGETRequest } from 'api/public';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { createContext, useContext, useState } from 'react';
import { Order } from 'services/useSort';

export interface DocumentListContextProviderProps {
    children: React.ReactChild | React.ReactChildren,
  }

export interface DocumentListEntry extends DocumentProjectionDto {
  propertyImpowerHrId?: string,
  sourceProcessUrl?: string,
  unitIdOfContract?: number,
  contractName?: string,
  contractContactId?: number,
  contractContactType?: ContactLegacyDtoTypeEnum,
  contactName?: string,
  contactType?: ContactLegacyDtoTypeEnum,
  propertyAdministrationType: DocumentProjectionDtoPropertyAdministrationTypeEnum,
}

const DocumentListContext = createContext<{
    documentList: DefaultDataInterface<DocumentListEntry[]>,
    setDocumentList: React.Dispatch<React.SetStateAction<DefaultDataInterface<DocumentListEntry[]>>>,
    filterState: GetDocumentsByFilterUsingGETRequest,
    setFilterState: React.Dispatch<React.SetStateAction<GetDocumentsByFilterUsingGETRequest>>,
    sortState: { field: keyof DocumentDto, order: Order },
    setSortState: React.Dispatch<React.SetStateAction<{ field: keyof DocumentDto, order: Order }>>,
}>(undefined);
DocumentListContext.displayName = 'DocumentListContext';


export const useDocumentListContext = (usageName: string) => {
  const documentListContext = useContext(DocumentListContext);

  if (documentListContext === undefined) {
    throw new Error(`${usageName} must be used within a DocumentListContextProvider`);
  }

  return documentListContext;
};

const DEFAULT_SORT: { field: keyof DocumentDto, order: Order } = {
  field: 'updated',
  order: -1,
};

const DocumentListContextProvider = ({ children }: DocumentListContextProviderProps) => {
  const [documentList, setDocumentList] = useState(DEFAULT_DATA<DocumentListEntry[]>([]));
  const [sortState, setSortState] = useState(DEFAULT_SORT);
  const [filterState, setFilterState] = useState({});

  return (
    <DocumentListContext.Provider value={{
      documentList,
      setDocumentList,
      sortState,
      setSortState,
      filterState,
      setFilterState,
    }}
    >
      {children}
    </DocumentListContext.Provider>
  );
};

export { DocumentListContext, DocumentListContextProvider };
