import YourPrepaymentsSection, { GetYourPrepaymentsSectionProps } from 'pages/OpsCostReport/OpsCostReportEditing/components/YourPrepaymentsSection/YourPrepaymentsSection';
import YourSettlementSection, { GetYourSettlementSectionProps } from 'pages/OpsCostReport/OpsCostReportEditing/components/YourSettlementSection/YourSettlementSection';
import { renderToString } from 'react-dom/server';
import PageBreakWrapper from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/components/PageBreakWrapper/PageBreakWrapper';
import { LanguageContext } from 'contexts/LanguageContext';
import IsolatedDirtModalContextProvider from 'elements/Modals/DirtModal/IsolatedSectionsDirtModal/IsolatedSectionsDirtModalContext';
import { GetOpsCostReportDocumentCoverProps, OpsCostReportDocumentCover } from '../components/OpsCostReportEditorForm/components/OpsCostReportDocumnetCover/OpsCostReportDocumentCover';
import { HtmlAndDetailsForContract } from './interfaces';
import ExpensesSection from '../components/OpsCostReportEditorForm/ExpensesSection/ExpensesSection';
import TotalExpensesSection, { GetTotalExpensesSectionProps } from '../components/OpsCostReportEditorForm/ExpensesSection/TotalExpensesSection/TotalExpensesSection';
import DistributionSetsSection, { GetDistributionSetSectionProps } from '../components/OpsCostReportEditorForm/ExpensesSection/DistributionSetsSection/DistributionSetsSection';
import HeatingCostSettlementSection, { GetHeatingCostSettlementSectionProps } from '../components/OpsCostReportEditorForm/ExpensesSection/HeatingCostSettlementSection/HeatingCostSettlementSection';
import Annex35aSection, { GetAnnex35aSectionProps } from '../components/Annex35aSection/Annex35aSection';
import BankAccountInformationSection, { GetOpsCostReportNonSectionElementsData } from '../components/OpsCostReportDocumentNonSectionElements/BankAccountInformationSection';
import IndividualTenantCostsSection, { GetIndividualTenantCostsSectionProps } from '../components/OpsCostReportEditorForm/ExpensesSection/IndividualTenantCosts/IndividualTenantCostsSection';
import OtherOpsCostOrTransferableCostsFromWegSection, { GetOtherOpsCostOrTransferableCostsFromWegSectionProps } from '../components/OpsCostReportEditorForm/ExpensesSection/OtherOpsCostOrTransferableCostsFromWegSection /OtherOpsCostOrTransferableCostsFromWegSection ';
import { OpsCostReportManualAmountsStoreProvider } from './stores/opsCostReportManualAmountsStore/storeOpsCostReportManualAmounts';
import { OpsCostReportHiddenAmountsStoreProvider } from './stores/opsCostReportHiddenAmounts/storeOpsCostReportHiddenAmounts';
import { selectIndividualTenantCostsManualTotalFromStore, selectOtherOrTransferableCostsManualTotalFromStore, selectYourPrepaymentsManualTotalFromStore } from './stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';


type GetSectionsForOneContractProps = {
  getYourSettlementSectionProps: GetYourSettlementSectionProps,
  getHeatingCostSettlementSectionProps: GetHeatingCostSettlementSectionProps,
  getYourPrepaymentsSectionProps: GetYourPrepaymentsSectionProps,
  getReportDocumnetCoverProps: GetOpsCostReportDocumentCoverProps,
  getOtherOpsCostOrTransferableCostsFromWegSectionProps: GetOtherOpsCostOrTransferableCostsFromWegSectionProps,
  getIndividualTenantCostsSectionProps: GetIndividualTenantCostsSectionProps,
  getDistributionSetSectionProps: GetDistributionSetSectionProps,
  getTotalExpensesSectionProps: GetTotalExpensesSectionProps,
  getAnnex35aSectionProps: GetAnnex35aSectionProps,
  getOpsCostReportNonSectionElementsData: GetOpsCostReportNonSectionElementsData,
  openSectionId,
  tenantContract,
  isWegPropertySelected,
}


export const useOpsCostReportHtmlGenerator = (eventData) => {
  // OPS_COST_REPORT template render logic

  const {
    data, language, sourceId, allKeysGroupedByContractId, allHiddenKeysGroupedByContractId, storeInitialValue,
  } = eventData;


  const tl = (obj) => {
    try {
      return obj[language];
    } catch (error) {
      return 'tl-err';
    }
  };

  const getSectionsForOneContract = (props: GetSectionsForOneContractProps) => {
    const {
      getYourSettlementSectionProps,
      getHeatingCostSettlementSectionProps,
      getYourPrepaymentsSectionProps,
      getReportDocumnetCoverProps,
      getOtherOpsCostOrTransferableCostsFromWegSectionProps,
      getIndividualTenantCostsSectionProps,
      getDistributionSetSectionProps,
      getTotalExpensesSectionProps,
      getAnnex35aSectionProps,
      getOpsCostReportNonSectionElementsData,
      openSectionId,
      tenantContract,
      isWegPropertySelected,
    } = props;
    const reportDocumentCoverSectionProps = getReportDocumnetCoverProps(tenantContract.unitContractId);
    const yourPrepaymentsSectionProps = getYourPrepaymentsSectionProps(tenantContract.unitContractId, allKeysGroupedByContractId, allHiddenKeysGroupedByContractId, true);
    const heatingCostSettlementSectionProps = getHeatingCostSettlementSectionProps(tenantContract.unitContractId);
    const otherOpsCostOrTransferableCostsFromWegSectionProps = getOtherOpsCostOrTransferableCostsFromWegSectionProps(tenantContract.unitContractId, allKeysGroupedByContractId, allHiddenKeysGroupedByContractId, isWegPropertySelected, true);
    const individualTenantCostsSectionProps = getIndividualTenantCostsSectionProps(tenantContract.unitContractId, allKeysGroupedByContractId, allHiddenKeysGroupedByContractId, true);
    const totalExpensesSectionProps = getTotalExpensesSectionProps(heatingCostSettlementSectionProps.heatingCostSectionTotal, otherOpsCostOrTransferableCostsFromWegSectionProps.getTotalIncludingManualAmounts, individualTenantCostsSectionProps.getTotalIncludingManualAmounts, tenantContract.unitContractId, false, isWegPropertySelected);
    const yourSettlementSectionProps = getYourSettlementSectionProps(yourPrepaymentsSectionProps.getPrepaymentsTotalIncludingManualAmounts, totalExpensesSectionProps.getTotalExpensesSummaryData, tenantContract.unitContractId, false);
    const distributionSetsProps = getDistributionSetSectionProps(tenantContract.unitContractId, allHiddenKeysGroupedByContractId);
    const annex35aSectionProps = getAnnex35aSectionProps(tenantContract.unitContractId);
    const { recipientAddressLines } = reportDocumentCoverSectionProps;
    const contractAddress = recipientAddressLines.join(' ');

    const totalSummaryData = yourSettlementSectionProps.getTotalSummaryDataIncludingManualAmounts(
      selectYourPrepaymentsManualTotalFromStore(storeInitialValue, tenantContract.unitContractId),
      selectOtherOrTransferableCostsManualTotalFromStore(storeInitialValue, tenantContract.unitContractId),
      selectIndividualTenantCostsManualTotalFromStore(storeInitialValue, tenantContract.unitContractId),
    );
    const reportDocumentCoverSection = (
      <OpsCostReportDocumentCover
        useReportDocumentCover={reportDocumentCoverSectionProps}
      />
    );
    const opsCostReportNonSectionElementsData = getOpsCostReportNonSectionElementsData(tenantContract.unitContractId);

    const settlementSection = (
      <YourSettlementSection
        tl={tl}
        openSectionId={openSectionId}
        useYourSettlementSection={yourSettlementSectionProps}
        contractId={tenantContract.unitContractId}
      />
    );

    const bankAccountInformationSection = (
      <BankAccountInformationSection
        tl={tl}
        useOpsCostReportNonSectionElementsData={opsCostReportNonSectionElementsData}
        settlementTotal={totalSummaryData.gross}
      />
    );

    const expensesSection = (
      <ExpensesSection
        tl={tl}
        openSectionId={openSectionId}
      >
        <DistributionSetsSection tl={tl} useDistributionSetsSection={distributionSetsProps} />
        <HeatingCostSettlementSection sectionNumber={2} tl={tl} useHeatingCostSettlementSection={heatingCostSettlementSectionProps} />
        <OtherOpsCostOrTransferableCostsFromWegSection sectionNumber={2} tl={tl} useOtherOpsCostOrTransferableCostsFromWegSection={otherOpsCostOrTransferableCostsFromWegSectionProps} />
        {isWegPropertySelected && <IndividualTenantCostsSection sectionNumber={2} tl={tl} useIndividualTenantCostsSection={individualTenantCostsSectionProps} />}
        <TotalExpensesSection tl={tl} useTotalExpensesSection={totalExpensesSectionProps} sectionNumber={2} contractId={tenantContract.unitContractId} />
      </ExpensesSection>
    );

    const prepaymentsSection = (
      <YourPrepaymentsSection openSectionId={openSectionId} tl={tl} useYourPrepaymentsSection={yourPrepaymentsSectionProps} />
    );

    const annexSection = (
      <PageBreakWrapper breakBefore>
        <Annex35aSection openSectionId={openSectionId} tl={tl} useAnnex35aSection={annex35aSectionProps} />
      </PageBreakWrapper>
    );

    return {
      settlementSection,
      expensesSection,
      prepaymentsSection,
      totalSummaryData,
      tenantContract,
      reportDocumentCoverSection,
      annexSection,
      bankAccountInformationSection,
      contractAddress,
    };
  };

  const getContractName = (tenantContract) => {
    if (tenantContract.isVacant) {
      // this is important, otherwise the document name will have the string `undefined` in it
      // and then the PdfViewer won't load that document (because it filters out documents with "undefined" in the name)
      return tl({ en: 'Vacancy', de: 'Leerstand' });
    }

    return tenantContract?.mailingContact?.name;
  };

  const sectionsSeparateForEachContract: HtmlAndDetailsForContract[] = data.reduce((list, props) => {
    const {
      settlementSection, expensesSection, prepaymentsSection, tenantContract, reportDocumentCoverSection, bankAccountInformationSection, annexSection, totalSummaryData, contractAddress,
    } = getSectionsForOneContract(props);
    const sections = (

      <LanguageContext.Provider value={{ tl, language: 'de' }}>
        <IsolatedDirtModalContextProvider>
          <OpsCostReportManualAmountsStoreProvider initValue={storeInitialValue} printMode>
            <OpsCostReportHiddenAmountsStoreProvider initValue={allHiddenKeysGroupedByContractId}>
              {reportDocumentCoverSection}
              {settlementSection}
              {bankAccountInformationSection}
              {expensesSection}
              {prepaymentsSection}
              {annexSection}
            </OpsCostReportHiddenAmountsStoreProvider>
          </OpsCostReportManualAmountsStoreProvider>
        </IsolatedDirtModalContextProvider>
      </LanguageContext.Provider>
    );
    const numberOfContractsWithTheSameName: HtmlAndDetailsForContract[] = list?.filter(section => section.contractName === tenantContract?.contractName);
    const sectionsForContract: HtmlAndDetailsForContract = {
      contractId: tenantContract?.unitContractId,
      contractName: getContractName(tenantContract),
      index: numberOfContractsWithTheSameName?.length,
      unitId: tenantContract.unitId,
      unitNrSharingDeclaration: tenantContract?.unitNrSharingDeclaration,
      html: renderToString(sections),
      amount: totalSummaryData.gross,
      contractAddress,
    };
    list.push(sectionsForContract);
    return list;
  }, []);


  return { separateHtmlForEachContractWithContractDetails: sectionsSeparateForEachContract, sourceId };
};
