export const translations = {
  title: {
    en: 'Direct Debit Mandates',
    de: 'Lastschriftmandate',
  },
  description: {
    en: 'Direct Debit Mandate data to upload',
    de: 'Lastschriftmandatsdaten zum hochladen',
  },
  upload: {
    en: 'Upload Direct Debit Mandates',
    de: 'Lastschriftmandate',
  },
  import: {
    button: {
      en: 'Import',
      de: 'Importieren',
    },
    description: {
      en: 'Please do not refresh the page until the process finishes',
      de: 'Bitte abwarten bis das Prozess erfolgreich endet. Bitte navigieren sie nicht weg.',
    },
    title: {
      en: 'Importing',
      de: 'Importieren',
    },
  },
  notifications: {
    error: {
      title: { en: 'Error importing CSV', de: 'Fehler' },
      description: { en: 'Import failed half way through', de: 'Import Prozess fehlgeschlagen' },
    },
  },
  validations: {
    success: {
      en: 'Direct Debit mandates have been verified and are ready for import.',
      de: 'Lastschriftmandate wurden geprüft und sind bereit zum Importieren',
    },
    errors: {
      en: 'Issues found in the CSV',
      de: 'Es wurden Fehlern gefunden',
    },
    issues: {
      prefilled: {
        en: 'Prefilled data is missing.',
        de: 'Vorgefüllte Felder fehlen.',
      },
      data: {
        en: 'Mandatory fields are empty.',
        de: 'Pflichtfelder fehlen.',
      },
      duplicate: {
        en: 'Duplicate mandate id.',
        de: 'Dupliziertes Mandatsreferenznummer.',
      },
      sameContract: {
        en: 'Multiple mandates for same unit.',
        de: 'Mehrere Mandate für die selbe Einheit.',
      },
    },
  },

};
