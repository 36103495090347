import { useContext } from 'react';
import { CompanyEditingContext } from 'contexts/CompanyEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';


export default function useDefaultSection(index: number) {
  const { tl } = useContext(LanguageContext);

  const companyEditingContext: any = useContext(CompanyEditingContext);

  return {
    sectionNumber: index + 1,
    openingNumber: index + 1,
    buttonLoading: companyEditingContext.company.loading,
    value: companyEditingContext.company.data,
  };
}
