import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { FORMAT } from '../../../../elements/SmartTable/SmartTable';
import { economicPlanOverviewTranslations } from '../economicPlanOverviewTranslations';

export const UseMonthlyPaymentTableColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.name),
      dataKey: 'name',
      defaultWidth: '25%',
    },
    {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.previous),
      dataKey: 'previous',
      format: FORMAT.CURRENCY,
      defaultWidth: '20%',
    }, {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.newAmount),
      dataKey: 'newAmount',
      format: FORMAT.CURRENCY,
      defaultWidth: '20%',
    },
    {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.difference),
      dataKey: 'difference',
      format: FORMAT.CURRENCY,
      defaultWidth: '20%',
    },
    {
      title: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.due),
      dataKey: 'due',
    },
  ];
};
