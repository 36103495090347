import './NoteModal.scss';

import { useContext } from 'react';

import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';

import { translations } from '../translations';

interface NoteModalProps {
    content: string,
    visible: boolean,
    onCancel: () => void,
}

export const NoteModal = ({ content, visible, onCancel }: NoteModalProps) => {
  const { tl } = useContext(LanguageContext);
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className="NoteModal"
      title={tl(translations.note.title)}
      footer={[
        <Button
          key="cancel"
          type="ghost"
          onClick={onCancel}
        >
          {tl(translations.note.close)}
        </Button>,
      ]}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>
  );
};

export default NoteModal;
