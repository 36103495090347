import './MessageSendingPage.scss';

import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { translations } from 'elements/Translation/translations';
import {
  Prompt,
  useLocation,
} from 'react-router';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';

import ExecuteButton from './components/ExecuteButton/ExecuteButton';
import MessageDocumentTable
  from './components/MessageDocumentTable/MessageDocumentTable';
import RecipientOverview
  from './components/RecipientOverview/RecipientOverview';
import MessageSendingContextProvider from './services/MessageSendingContext';
import {
  useInitializeMessageSendingPage,
} from './services/useInitializeMessageSendingPage';
import { useMessageSendingPage } from './services/useMessageSendingPage';
import { messageSendingTranslations } from './translations';

const MessageSendingPage = (): JSX.Element => {
  const location = useLocation();
  useInitializeMessageSendingPage();
  const {
    title, subtitle, dirty, goBack,
  } = useMessageSendingPage();
  const { tl } = useContext(LanguageContext);

  const noPromptPathRegexp = new RegExp('(/preview/.+$)');

  const headerButtons = (
    <div className="header-buttons">
      <Button
        type="text"
        className="header-button"
        onClick={goBack}
      >
        {tl(messageSendingTranslations.cancel)}
      </Button>
      <ExecuteButton />
    </div>
  );

  return (
    <Page className="MessageSendingPage">
      <Prompt
        when={dirty}
        message={newLocation => (
          location.pathname.match(noPromptPathRegexp)
          || newLocation.pathname.match(noPromptPathRegexp)
            ? true
            : tl(translations.elements.prompt)
        )}
      />
      <PageHeader
        title={title}
        subtitle={subtitle}
        rightSideComponent={headerButtons}
      />
      <PageContent>
        <MessageDocumentTable />
        <RecipientOverview />
      </PageContent>
    </Page>
  );
};

const MessageSendingPageWithProvider = () => (
  <MessageSendingContextProvider>
    <MessageSendingPage />
  </MessageSendingContextProvider>
);

export default MessageSendingPageWithProvider;
