import React, { useContext } from 'react';
import StrikethroughAmount from 'components/StrikethroughAmount/StrikethroughAmount';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { translations } from '../../translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { FORMAT } from '../../../../elements/SmartTable/SmartTable';
import { ExtendedBankTransaction } from '../../interfaces';
import InvoiceDropMenu from '../components/InvoiceDropMenu';
import BankTransactionListPropertyColumn from '../components/BankTransactionListPropertyColumn/BankTransactionListPropertyColumn';


export const useTransactionListColumns = () => {
  const { tl } = useContext(LanguageContext);

  function getDotColor(status: string) {
    switch (status) {
      case 'UNASSIGNED':
        return DotColorEnum.BLUE;
      case 'ASSIGNED':
        return DotColorEnum.GREEN;
      case 'WONT_BE_ALLOCATED':
        return DotColorEnum.GRAY;
      case 'PARTIALLY_BOOKED':
        return DotColorEnum.YELLOW;
      case 'DUPLICATE':
        return DotColorEnum.RED;
      default:
        return DotColorEnum.GRAY;
    }
  }

  return ([
    {
      title: tl(translations.table.status),
      dataKey: 'translatedStatus',
      defaultWidth: '15%',
      sortable: false,
      render: (text: string, row: ExtendedBankTransaction) => (
        <ColorDot color={getDotColor(row.transactionPaymentStatus || '')}>
          {row.translatedStatus}
        </ColorDot>
      ),
    },
    {
      title: tl(translations.table.property),
      dataKey: 'propertyList',
      defaultWidth: '20%',
      sortable: false,

      render: (propertyList: ExtendedBankTransaction['propertyList']) => <BankTransactionListPropertyColumn propertyList={propertyList} />,
    },
    {
      title: tl(translations.table.statement),
      dataKey: 'purpose',
      defaultWidth: '25%',
      sortable: false,
    },
    {
      title: tl(translations.table.counterpartName),
      dataKey: 'counterpartName',
      defaultWidth: '20%',
      sortable: false,
      render: (_, record: ExtendedBankTransaction) => (
        <CellWithTwoLines
          firstElement={record.counterpartName || '—'}
          secondElement={record.counterpartIban || '—'}
        />
      ),
    },
    {
      title: tl(translations.table.amount),
      dataKey: 'amounts',
      format: FORMAT.CURRENCY,
      className: 'amount-column',
      defaultWidth: '10%',
      sortable: false,
      render: (text: string, row: ExtendedBankTransaction) => (
        <span className="tx-amount-column">
          {
            row.children
            && <span className="tx-nr-indicator">{`${row.children.length}×`}</span>
          }
          <StrikethroughAmount totalAmount={row.amount!} remainingAmount={row.remainingAmount} direction={row.amount! < 0 ? 'outgoing' : 'incoming'} />
        </span>
      ),
    },
    {
      title: tl(translations.table.date),
      dataKey: 'transactionDate',
      format: FORMAT.DATE,
      defaultWidth: '10%',
    },
    {
      title: tl(translations.table.accountantName),
      dataKey: 'accountantName',
      sortable: false,
      defaultVisible: false,
      render: (_, record) => {
        if (record.propertyList.length === 1) {
          return record.propertyList[0].accountantName;
        }
        return tl(translations.table.multipleProperties);
      },
    },
    {
      title: tl(translations.table.administrator),
      dataKey: 'administrator',
      sortable: false,
      defaultVisible: false,
      render: (_, record) => {
        if (record.propertyList.length === 1) {
          return record.propertyList[0].supervisorName;
        }
        return tl(translations.table.multipleProperties);
      },
    },
    {
      title: tl(translations.table.propertyIban),
      dataKey: 'propertyIban',
      defaultWidth: '20%',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(translations.table.receipt),
      dataKey: 'invoiceInternalRefNr',
      className: 'middle invoice-icon-column',
      defaultWidth: '5%',
      sortable: false,
      render: (text: string, row: ExtendedBankTransaction) => <InvoiceDropMenu row={row} />,
    },
  ]);
};
