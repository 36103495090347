import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { usePropertyOwnerPaymentsTable } from './usePropertyOwnerPaymentsTable';
import PropertyOwnerPaymentsTableSummary from './PropertyOwnerPaymentsTableSummary';

const PropertyOwnerPaymentsTable = () => {
  const {
    columns, dataSource, ownerPaymentsFromSum, ownerPaymentsToSum, ownerPaymentsBalanceSum,
  } = usePropertyOwnerPaymentsTable();
  const { tl } = useContext(LanguageContext);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      summary={() => (
        <PropertyOwnerPaymentsTableSummary
          title={tl(translations.report.sections.propertyOwnerPaymentsSection.summaryRow.title)}
          ownerPaymentsFromSum={ownerPaymentsFromSum}
          ownerPaymentsToSum={ownerPaymentsToSum}
          ownerPaymentsBalanceSum={ownerPaymentsBalanceSum}
        />
      )}
      rowKey="owner"
    />
  );
};

export default PropertyOwnerPaymentsTable;
