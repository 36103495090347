
export const processingSelectorTranslations = {
  selfPayer: {
    en: 'Self payer',
    de: 'Selbstzahler',
  },
  paymentRun: {
    en: 'Payment run',
    de: 'Zahlungslauf',
  },
  directDebit: {
    en: 'Direct debit',
    de: 'Lastschriftzahlung',
  },
};
