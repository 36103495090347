import './ExchangeList.scss';

import React, {
  useContext,
  useEffect,
} from 'react';

import { Table } from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import TableContentLoadingIndicator
  from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import _ from 'lodash';
import {
  useAccountsAndContactsInitializer,
} from 'pages/BankTransactions/BankTransactionAllocation/services/AccountsAndContactsContext';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import {
  useResizableColumn,
} from 'storybook-components/table/ResizableColumn/useResizableColumn';
import { useVT } from 'virtualizedtableforantd4';

import Icon from '@ant-design/icons';

import { translations } from '../../../../../translations';
import {
  useInitializeExchangeList,
} from '../../services/useInitializeExchangeList';
import { EXCHANGE_LIST_ID } from './components/LaborCostCell/useLaborCostCell';
import { useExchangeList } from './services/useExchangeList';
import { useExchangeListColumns } from './services/useExchangeListColumns';

interface Props {
  propertyHrIds: string[],
  contentMaxHeight: number,
  bankTransactionsInGroup: ExtendedBankTransaction[];
  selectedBankTransactions: ExtendedBankTransaction[];
  transactionActionInProgress: boolean,
}

const ExchangeList = ({
  propertyHrIds, selectedBankTransactions, bankTransactionsInGroup, contentMaxHeight, transactionActionInProgress,
}: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    exchanges, dataSource, addNewExchange, addPotentiallyMissingPostingTexts,
  } = useExchangeList(selectedBankTransactions);
  const { initialize } = useInitializeExchangeList();
  const tableColumns = useExchangeListColumns(selectedBankTransactions);

  const [
    VT,
  ] = useVT(() => ({ scroll: { y: contentMaxHeight - 65 } }), []);

  const { components, resizableColumns } = useResizableColumn(tableColumns, VT);

  const { initialize: initializeAccountsAndContacts } = useAccountsAndContactsInitializer(propertyHrIds);
  const allocationGroupId = bankTransactionsInGroup?.[0]?.allocationGroupId;

  const { overlays } = useContext(OverlayContext);

  useEffect(() => {
    const propertyHrIdsOnTx = bankTransactionsInGroup?.[0]?.propertyList?.map(p => p.propertyHrId) || [];
    if (overlays.length === 1) {
      initialize(propertyHrIdsOnTx, bankTransactionsInGroup);
    }
  }, [allocationGroupId, overlays]);

  useEffect(() => {
    if (_.isEmpty(propertyHrIds)) return;
    initializeAccountsAndContacts();
  }, [propertyHrIds]);

  useEffect(() => {
    // See explanation of why this is needed at method declaration
    addPotentiallyMissingPostingTexts(selectedBankTransactions);
  }, [selectedBankTransactions]);


  const tableScrollY = contentMaxHeight;

  const spinning = exchanges.loading || transactionActionInProgress;

  return (
    <div className="ExchangeList" id={EXCHANGE_LIST_ID}>
      <Button
        className={`create-button ${spinning ? 'faded' : ''}`}
        onClick={() => addNewExchange(selectedBankTransactions)}
        icon={<Icon component={ICONS.plusFilled!} />}
      >
        {tl(translations.bankTransactionAllocation.serviceCompanyAllocation.createNewOrder)}
      </Button>
      <Table
        className="ExchangeListTable"
        dataSource={dataSource ?? []}
        loading={{
          indicator: <TableContentLoadingIndicator />,
          spinning,
        }}
        columns={resizableColumns}
        components={components}
        pagination={false}
        scroll={{ y: tableScrollY }}
      />
    </div>
  );
};

export default ExchangeList;
