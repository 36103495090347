/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data that describes an existing connection
 * @export
 * @interface ConnectionLegacyDto
 */
export interface ConnectionLegacyDto {
    /**
     * Id of an application
     * @type {number}
     * @memberof ConnectionLegacyDto
     */
    appId?: number;
    /**
     * Name of the application of the connection
     * @type {string}
     * @memberof ConnectionLegacyDto
     */
    appName?: string;
    /**
     * Creation time of the entity.
     * @type {Date}
     * @memberof ConnectionLegacyDto
     */
    created?: Date;
    /**
     * User reference that created the entity.
     * @type {string}
     * @memberof ConnectionLegacyDto
     */
    createdBy?: string;
    /**
     * Impower domain identifier
     * @type {number}
     * @memberof ConnectionLegacyDto
     */
    domainId?: number;
    /**
     * Configuration data specific to the external party; JSON or plain string
     * @type {string}
     * @memberof ConnectionLegacyDto
     */
    externalConfig?: string;
    /**
     * Id of the entity.
     * @type {number}
     * @memberof ConnectionLegacyDto
     */
    id?: number;
    /**
     * Management company identifier
     * @type {number}
     * @memberof ConnectionLegacyDto
     */
    managementCompanyId?: number;
    /**
     * Name of the connection
     * @type {string}
     * @memberof ConnectionLegacyDto
     */
    name?: string;
    /**
     * State of connection
     * @type {string}
     * @memberof ConnectionLegacyDto
     */
    state?: ConnectionLegacyDtoStateEnum;
    /**
     * Last update time of the entity.
     * @type {Date}
     * @memberof ConnectionLegacyDto
     */
    updated?: Date;
    /**
     * User reference that last updated the entity.
     * @type {string}
     * @memberof ConnectionLegacyDto
     */
    updatedBy?: string;
    /**
     * Impower user identifier that owns the connection
     * @type {number}
     * @memberof ConnectionLegacyDto
     */
    userId?: number;
    /**
     * Webhook of the connection that will be called like
     * ```
     * POST https://your.webhook.url/path/somewhere
     * Authorization: Bearer TOKEN
     * 
     * {
     *   "connectionId": 0,
     *   "entityType": "properties|contacts|invoices|etc.",
     *   "entityId": 0,
     *   "eventType": "CREATE|UPDATE|DELETE"
     * }```
     * @type {string}
     * @memberof ConnectionLegacyDto
     */
    webhookUrl?: string;
}

/**
* @export
* @enum {string}
*/
export enum ConnectionLegacyDtoStateEnum {
    DISABLED = 'DISABLED',
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS'
}


/**
 * Check if a given object implements the ConnectionLegacyDto interface.
 */
export function instanceOfConnectionLegacyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectionLegacyDtoFromJSON(json: any): ConnectionLegacyDto {
    return ConnectionLegacyDtoFromJSONTyped(json, false);
}

export function ConnectionLegacyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionLegacyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appId': !exists(json, 'appId') ? undefined : json['appId'],
        'appName': !exists(json, 'appName') ? undefined : json['appName'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'externalConfig': !exists(json, 'externalConfig') ? undefined : json['externalConfig'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'managementCompanyId': !exists(json, 'managementCompanyId') ? undefined : json['managementCompanyId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'webhookUrl': !exists(json, 'webhookUrl') ? undefined : json['webhookUrl'],
    };
}

export function ConnectionLegacyDtoToJSON(value?: ConnectionLegacyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appId': value.appId,
        'appName': value.appName,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'createdBy': value.createdBy,
        'domainId': value.domainId,
        'externalConfig': value.externalConfig,
        'id': value.id,
        'managementCompanyId': value.managementCompanyId,
        'name': value.name,
        'state': value.state,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'updatedBy': value.updatedBy,
        'userId': value.userId,
        'webhookUrl': value.webhookUrl,
    };
}

