import { AuthContext } from 'contexts/AuthContext';
import {
  isEmpty,
} from 'lodash';
import {
  AccountControllerApi,
} from 'api/accounting';
import {
  useContext, useEffect, useMemo,
} from 'react';
import { showNotification } from 'lib/Notification';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  PropertyListWithOpenAccounts,
} from './interfaces';
import {
  useOrderOpenBalancesContext,
  useOrderOpenBalancesSelectionContext,
} from './OrderOpenBalancesContext';
import { orderOpenBalancesTranslations } from '../../translations';
import { useOnLoadPropertyAndContractBalances } from './useOnLoadPropertyAndContractBalances';


export const getInitialDunningFeeNet = (balance: number, contractHasMandate: boolean, propertyDunningFee: number) => {
  // dunning fee is 0 if property owes tenant/owner
  if (balance < 0) {
    return 0;
  }

  // dunning fee is 0 if tenant/owner owes the property BUT contract has mandate
  if (contractHasMandate) {
    return 0;
  }

  return propertyDunningFee;
};


/**
 * Filter out potential duplicates:
 * keep all balances in current view and remove duplicates from the cached balances,
 * because the current view has more up-to-date data.
 * e.g. if some postings were created in the meanwhile, then the cached balances will not have the updated balance
 * BUT we need to do this on a per-contract basis, because the previous filters might contain some contracts of a property,
 * and the current filter might contain other contracts of the same property.
 */
export const getNewAndOldPropertyBalancesMerged = (
  balancesSelectedInCurrentView: PropertyListWithOpenAccounts[],
  previouslySelectedBalancesThatAreStillSelected: PropertyListWithOpenAccounts[],
): PropertyListWithOpenAccounts[] => {
  const commonProperties = previouslySelectedBalancesThatAreStillSelected.filter(({ propertyId }) => (
    balancesSelectedInCurrentView.some(b => b.propertyId === propertyId)
  ));

  const mergedCommonBalances: PropertyListWithOpenAccounts[] = commonProperties.map((propertyBalanceObject) => {
    const { propertyId } = propertyBalanceObject;
    const oldBalancesOfProperty = previouslySelectedBalancesThatAreStillSelected.find(b => b.propertyId === propertyId).debtorBalancesGrouped;
    const newBalancesOfProperty = balancesSelectedInCurrentView.find(b => b.propertyId === propertyId).debtorBalancesGrouped;

    const balancesOfContractsNotInCurrentView = oldBalancesOfProperty.filter(({ contractId }) => (
      !newBalancesOfProperty.some(b => b.contractId === contractId)
    ));

    return {
      ...propertyBalanceObject,
      debtorBalancesGrouped: [
        ...newBalancesOfProperty,
        ...balancesOfContractsNotInCurrentView,
      ],
    };
  });


  const balancesOfPreviouslySelectedPropertiesNotInCurrentView = previouslySelectedBalancesThatAreStillSelected.filter(({ propertyId }) => (
    !commonProperties.some(b => b.propertyId === propertyId)
  ));

  const balancesOfSelectedPropertiesNotInOldSelection = balancesSelectedInCurrentView.filter(({ propertyId }) => (
    !commonProperties.some(b => b.propertyId === propertyId)
  ));

  return [
    ...mergedCommonBalances,
    ...balancesOfPreviouslySelectedPropertiesNotInCurrentView,
    ...balancesOfSelectedPropertiesNotInOldSelection,
  ];
};


export const useOrderOpenBalancesList = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));

  const {
    propertyAndContractBalanceList,
    accounts,
    setAccounts,
    orderOpenbalanceFilterState,
    sortState,
  } = useOrderOpenBalancesContext('useOrderOpenBalancesList');

  const {
    innerTableSelectedRowKeysTotal,
    outerTableSelectedRowKeysTotal,
    expandedRowKeys,
    setExpandedRowKeys,
    showAllSelected,
  } = useOrderOpenBalancesSelectionContext('useOrderOpenBalancesList');


  const { onLoadPropertyAndContractBalances } = useOnLoadPropertyAndContractBalances();

  useEffect(() => {
    onLoadPropertyAndContractBalances(true);
  }, [orderOpenbalanceFilterState, sortState]);


  const onLoadAccounts = (propertyIds: number[]) => {
    setAccounts(prev => prev.startLoading());
    accountControllerApi.getAccountsMatchingRegexUsingGET({
      // eslint-disable-next-line no-useless-escape
      accountCodeRegex: '^(2000\\/\\d+\\/2|2001\\/\\d+\\/[01234]|200[01]\\/\\d+)$',
      propertyHrIds: [],
      propertyIds,
    })
      .then((resp) => {
        setAccounts(prev => prev.load([...prev.data, ...resp]));
      })
      .catch((err) => {
        console.error(err);
        setAccounts(prev => prev.failed());
        showNotification({
          key: 'loadContractsError',
          message: tl(orderOpenBalancesTranslations.notifications.loadAccountsError),
          type: 'error',
        });
      });
  };

  // if `showAllSelected` is true, then we need to filter the data source to only show the selected items
  const outerTableFilteredDataSource = useMemo(() => (
    !showAllSelected
      ? propertyAndContractBalanceList.data
      : propertyAndContractBalanceList.data
        .filter(({ propertyId }) => outerTableSelectedRowKeysTotal.includes(propertyId))
        .map((property) => {
          const { debtorBalancesGrouped, ...rest } = property;
          return {
            ...rest,
            debtorBalancesGrouped: debtorBalancesGrouped.filter(db => innerTableSelectedRowKeysTotal.includes(db.id)),
          };
        })
  ), [showAllSelected, propertyAndContractBalanceList.data, outerTableSelectedRowKeysTotal, innerTableSelectedRowKeysTotal]);


  const proceedDisabled = isEmpty(innerTableSelectedRowKeysTotal) && !accounts.loaded;


  return {
    outerTableFilteredDataSource,
    propertyAndContractBalanceList,
    onLoadPropertyAndContractBalances,
    onLoadAccounts,
    expandedRowKeys,
    onChangeExpandedRowKeys: setExpandedRowKeys,
    proceedDisabled,
  };
};
