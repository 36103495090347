import { Table, ConfigProvider } from 'antd';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { useDunningAnnexAccountBalanceTable as hook } from './useDunningAnnexAccountBalanceTable ';
import { DunningAnnexAccountBalanceTableSummary } from './components/DunningAnnexAccountBalanceTableSummary/DunningAnnexAccountBalanceTableSummary';
import { DunningAnnexAccountBalanceTableTotalSummary } from './components/DunningAnnexAccountBalanceTableTotalSummary';
import './DunningAnnexAccountBalanceTable.scss';

type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfDunningAnnexAccountBalanceTableHook = ReturnType<ReturnTypeOfHook['getDunningAnnexAccountBalanceTableProps']>

interface DunningAnnexAccountBalanceTableProps {
  tl: any,
  useDunningAnnexAccountBalanceTable: ReturnTypeOfDunningAnnexAccountBalanceTableHook
}
const DunningAnnexAccountBalanceTable = ({ tl, useDunningAnnexAccountBalanceTable }:DunningAnnexAccountBalanceTableProps) => {
  const {
    dataSource, columns, summaryData, totalSummaryData,
  } = useDunningAnnexAccountBalanceTable;

  const tableWithSummary = (values, index, withHeader) => (
    <ConfigProvider renderEmpty={() => null}>
      <Table
        dataSource={values}
        columns={columns}
        className={`${withHeader === false && 'NoHeaderTable'}`}
        pagination={false}
        summary={() => <DunningAnnexAccountBalanceTableSummary {...summaryData[index]} />}
      />
    </ConfigProvider>
  );

  const tableWithTotalSummaryRow = (
    <ConfigProvider renderEmpty={() => null}>
      <Table
        dataSource={[]}
        columns={columns}
        pagination={false}
        className="NoHeaderTable"
        summary={() => DunningAnnexAccountBalanceTableTotalSummary({
          totalSummaryLabel: tl(orderOpenBalancesTranslations.dunningAnnexAccountBalanceSummaryRow.titleResult),
          totalSummaryAmount: totalSummaryData,
        })}
      />
    </ConfigProvider>
  );

  if (dataSource.length === 1) {
    return (
      <div className="DunningAnnexAccountBalanceTable">
        <p>{tl(orderOpenBalancesTranslations.dunningAnnexExplanatoryText)}</p>
        {tableWithSummary(dataSource[0], 0, true)}
        {tableWithTotalSummaryRow}
      </div>
    );
  }

  return (
    <div className="DunningAnnexAccountBalanceTable">
      <p>{tl(orderOpenBalancesTranslations.dunningAnnexExplanatoryText)}</p>
      {tableWithSummary(dataSource[0], 0, true)}

      {
        dataSource.slice(1, dataSource.length).map((values, index) => (
          tableWithSummary(values, index + 1, false)
        ))
      }

      {tableWithTotalSummaryRow}
    </div>
  );
};

export default DunningAnnexAccountBalanceTable;
