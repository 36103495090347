import { ICONS } from 'components/icons';
import Button from 'elements/Buttons/Button/Button';
import Icon from '@ant-design/icons';
import React, { useContext } from 'react';
import './AnnouncementAlert.scss';
import { Col, Space } from 'antd';
import { SPACE_SIZES } from 'lib/constants';
import Heading from 'storybook-components/typography/Heading/Heading';
import { LanguageContext } from 'contexts/LanguageContext';
import { useAnnouncementAlert } from './useAnnouncementAlert';
import { translationsAnnouncementAlert } from './translationsAnnouncementAlert';

type Props = {
  identifier: string,
  title: string,
  description?: string,
  descriptionList?: Array<string>, // Description list will be rendered below the description as an unordered list.
  readMoreLink?: string,
}


const AnnouncementAlert = ({
  identifier,
  title,
  description,
  descriptionList,
  readMoreLink,
}: Props) => {
  const { tl } = useContext(LanguageContext);
  const { onDismiss, visible } = useAnnouncementAlert(identifier);

  if (!visible) return null;

  return (
    <div className="AnnouncementAlert">
      <Icon component={ICONS.alertStar} />

      <Col className="main-text">
        <Heading level={3} className="title">{title}</Heading>
        {typeof descriptionList === 'object' && <ul className="description">{descriptionList.map(d => <li key={d}>{d}</li>)}</ul>}
        {typeof description === 'string' && <p className="description">{description}</p>}
      </Col>

      <Space
        className="buttons-container"
        size={SPACE_SIZES.medium}
        align="start"
      >
        {readMoreLink && (
          <Button
            className="read-more-button"
            type="primary"
            href={readMoreLink}
            target="_blank"
          >
            {tl(translationsAnnouncementAlert.readMore)}
          </Button>
        )}

        <Button className="close-button" onClick={onDismiss}>
          <Icon component={ICONS.close} />
        </Button>
      </Space>
    </div>
  );
};

export default AnnouncementAlert;


AnnouncementAlert.defaultProps = {
  description: undefined,
  descriptionList: undefined,
  readMoreLink: undefined,
};
