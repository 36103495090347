import Icon from '@ant-design/icons';
import { Modal } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import React, { useContext } from 'react';
import { ICONS } from 'components/icons';
import { translations } from '../../Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import './DataExportModal.scss';

interface DataExportModalProps {
  visible: boolean,
  handleCancel: () => void,
  onClickDownload: Function,
  onClickPrint: Function,
  loadingPrint: boolean,
  loadingDownload: boolean,
  children: any,
  disabledDownloadButton: boolean,
  disabledPrintButton: boolean,
}

export function DataExportModal(props: DataExportModalProps) {
  const {
    visible, onClickDownload, onClickPrint, handleCancel, children, disabledPrintButton, disabledDownloadButton, loadingPrint, loadingDownload,
  } = props;
  const { tl } = useContext(LanguageContext);

  return (
    <Modal
      className="data-export-modal"
      visible={visible}
      title={tl(translations.elements.dataExportModal.title)}
      onCancel={handleCancel}
      footer={[
        <Button
          key="print"
          size="small"
          type="ghost"
          onClick={() => {
            onClickPrint();
          }}
          className="print-button"
          loading={loadingPrint}
          disabled={disabledPrintButton}
        >
          {!loadingPrint && <Icon component={disabledPrintButton ? ICONS.printIconDisabled : ICONS.printIcon} />}
        </Button>,
        <Button
          size="small"
          key="download"
          type="primary"
          onClick={() => {
            onClickDownload();
          }}
          loading={loadingDownload}
          disabled={disabledDownloadButton}
        >
          {tl(translations.elements.dataExportModal.download)}
        </Button>,

      ]}
    >
      {children}
    </Modal>
  );
}
