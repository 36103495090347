import React, { useContext } from 'react';
import { AutoSelectFunctionType, PersonEditingContext } from 'contexts/PersonEditingContext';
import useContactOptions from 'services/useContactOptions';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { range } from 'lib/Utils';
import { navigateConcat } from 'navigation/History';
import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import defaultSection from './defaultSection';

interface EmployeeSectionProps {
  employeeNumber: number,
  autoSelectContactAfterCreation: AutoSelectFunctionType,
}

export default function useEmployeeSection(props: EmployeeSectionProps) {
  const { employeeNumber, autoSelectContactAfterCreation } = props;
  const { tl } = useContext(LanguageContext);
  const linkedContacts: any = useContactOptions([ContactLegacyDtoTypeEnum.PERSON]);
  const contactEditingContext: any = useContext(PersonEditingContext);

  function employeeInputs(index: number) {
    return [[
      {
        type: 'smartSearch',
        key: `linkedByContacts[${index}].contactId`,
        props: {
          label: `${tl(translations.pages.companyEditing.employeeSection.data.fields.person)} ${index + 1}`,
          searchFunction: linkedContacts.searchForContact,
          options: linkedContacts.contactOptions,
          getOneFunction: linkedContacts.onLoadContact,
          addButtons: [{
            label: tl(translations.elements.smartSearch.addMore),
            onClick: () => {
              contactEditingContext.setAutoSelectContactAfterCreation(() => autoSelectContactAfterCreation);
              navigateConcat('/create-person');
            },
          }],
        },
      },
    ],
    [
      {
        type: 'select',
        key: `linkedByContacts[${index}].role`,
        props: {
          label: `${tl(translations.pages.companyEditing.employeeSection.data.fields.role)} ${index + 1}`,
          options: [
            {
              value: 'Manager',
              label: 'Manager',
            },
            {
              value: 'Employee',
              label: 'Employee',
            },
          ],
        },
      },
    ]];
  }

  function getEmployeeFields(numberEmployees: number) {
    const displayNumber = Math.max(0, numberEmployees - 1) || 0;
    return range(0, displayNumber).reduce((accumulator: Array<any>, number: number) => accumulator.concat(employeeInputs(number)), []);
  }

  return {
    ...defaultSection(2),
    sectionTitle: tl(translations.pages.companyEditing.employeeSection.title),
    sectionId: 'employeeData',
    content: [{
      sectionId: 'data',
      title: tl(translations.pages.companyEditing.employeeSection.data.title),
      content: [
        ...getEmployeeFields(employeeNumber),
        [
          {
            type: 'addButton',
            key: 'employeeNumber',
            props: {
              label: tl(translations.pages.companyEditing.employeeSection.data.fields.add),
            },
          },
        ],
      ],
    }],
  };
}
