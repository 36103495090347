import { ContractState } from 'pages/UnitContractHistory/interfaces';

export const SEVPropertyCreationModalTranslation = {
  create: {
    title: {
      en: 'Create SEV',
      de: 'SEV erstellen',
    },
    subtitle: {
      en: 'Select from which WEG to copy the data from',
      de: 'Bitte wählen Sie die WEG aus, von welcher Sie die Daten kopieren möchten',
    },
  },
  copy: {
    title: {
      en: 'Copy contracts to SEV',
      de: 'Verträge in die SEV kopieren',
    },
    subtitle: {
      en: 'Select the tenant contracts you want to copy',
      de: 'Bitte wählen Sie den Mietvertrag aus, den Sie kopieren möchten',
    },
  },
  wegSelect: {
    en: 'WEG',
    de: 'WEG',
  },
  ownerContract: {
    en: 'Owner contract',
    de: 'Eigentümervertrag',
  },
  contractStates: {
    [ContractState.ACTIVE]: {
      en: 'Active',
      de: 'Aktiv',
    },
    [ContractState.FUTURE]: {
      en: 'Upcoming',
      de: 'Bevorstehend',
    },
    [ContractState.PAST]: {
      en: 'Past',
      de: 'Vergangen',
    },
    [ContractState.OVERRIDEN]: {
      en: 'Overridden',
      de: 'Überschrieben',
    },
  },
  tenantContract: {
    en: 'Tenant contract',
    de: 'Mietvertrag',
  },
  cancelButton: {
    en: 'Cancel',
    de: 'Abbrechen',
  },
  okButton: {
    en: 'Proceed',
    de: 'Fortfahren',
  },
  vacancy: {
    en: 'Vacancy',
    de: 'Leerstand',
  },
  warningAlertOwner: {
    firstPart: {
      en: 'For this owner of the selected property the SEV ',
      de: 'Für diesen Eigentümer des ausgewählten Objekts existiert bereits die SEV ',
    },
    secondPart: {
      en: ' already exists.',
      de: '.',
    },
  },
  warningAlertVat: {
    en: 'Cannot copy VAT relevant contract in non VAT relevant SEV. Change the VAT setting for the SEV first.',
    de: '"Es ist nicht möglich einen umsatzsteuerpflichtigen Vertrag in eine nicht-umsatzsteuerpflichtige SEV zu kopieren. Bitte ändern Sie dafür zuerst die Einstellungen für die Umsatzsteuer der SEV',
  },
  notifications: {
    propertyLoadError: {
      en: 'Error loading properties.',
      de: 'Objekte konnten nicht geladen werden.',
    },
    loadTenantContractsError: {
      en: 'Failed to load tenant contracts list.',
      de: 'Mietvertrage konnten nicht geladen werden.',
    },
    loadOwnerContractsError: {
      en: 'Failed to load owner contracts list.',
      de: 'Eigentümervertrag konnten nicht geladen werden.',
    },
    saveValidationError: {
      en: 'Saved property with missing or invalid fields.',
      de: 'Objekt wurde mit fehlenden oder nicht validen Werten gespeichert.',
    },
  },
};
