import Button from 'elements/Buttons/Button/Button';
import React, { useContext } from 'react';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import SmartTable from 'elements/SmartTable/SmartTable';
import { useTableColumnSelectorExtendedColumns } from 'storybook-components/table/TableColumnSelector/services/useTableColumnSelectorExtendedColumns';
import { useResizableColumn } from 'storybook-components/table/ResizableColumn/useResizableColumn';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { LanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { EmptyResultsHint } from 'components/EmptyResults/EmptyResultsHint';
import { TabLink } from 'storybook-components/TabLink/TabLink';
import { PropertyContractsBalanceDto } from 'api/accounting';
import OrderOpenBalancesContextProvider from './services/OrderOpenBalancesContext';
import { useOrderOpenBalancesList } from './services/useOrderOpenBalancesList';
import { useOrderOpenBalancesColumns } from './services/useOrderOpenBalancesColumns';
import ContractBalanceTable from './components/ContractBalanceTable/ContractBalanceTable';
import { useContractBalanceTableColumns } from './components/ContractBalanceTable/useContractBalanceTableColumns';
import { useOrderOpenBalancesCheckboxes } from './services/useOrderOpenBalancesCheckboxes';
import useOrderOpenbalanceFilters from './services/useOrderOpenbalanceFilters';
import { useOrderOpenBalancesListSort } from './services/useOrderOpenBalanceListSort';
import { orderOpenBalancesTranslations } from '../translations';
import { useOrderOpenBalancesNavigate } from './services/useOrderOpenBalancesNavigate';
import './OrderOpenBalancesList.scss';
import DunningActionBar from './components/DunningActionBar/DunningActionBar';
import { useDunningTabLinks } from '../useDunningTabLinks';


const WIDTH_COL_1 = 200;
const WIDTH_COL_2 = 250;
const WIDTH_COL_3 = 200;
const WIDTH_COL_4 = 200;


const OrderOpenBalancesList = () => {
  const { tl } = useContext(LanguageContext);
  const {
    outerTableFilteredDataSource,
    propertyAndContractBalanceList,
    onLoadPropertyAndContractBalances,
    expandedRowKeys,
    onChangeExpandedRowKeys,
    proceedDisabled,
  } = useOrderOpenBalancesList();

  const tabLinks = useDunningTabLinks();

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'orderOpenBalances',
    navItems: subcategorySwitcherItems.accounting,
  };


  /**
   * We have to define the columns of the inner table here because in order to keep the widths
   * of the columns in sync (when resizing) we need to pass the same `components` object to all tables.
   */
  const { columns: defaultColumns } = useContractBalanceTableColumns(WIDTH_COL_1, WIDTH_COL_2, WIDTH_COL_3, WIDTH_COL_4);
  const { visibleColumns, columns, changeColumnVisibility } = useTableColumnSelectorExtendedColumns({ columns: defaultColumns });
  const { components: resizableComponents, resizableColumns: resizableVisibleColumns } = useResizableColumn(visibleColumns);

  const {
    innerTableSelectedRowKeysCurrent,
    outerTableSelectedRowKeysCurrent,
    onChangeInnerTableSelectedRowKeys,
    onChangeOuterTableSelectedRowKeys,
    getCheckboxProps,
    onSelectAll,
  } = useOrderOpenBalancesCheckboxes();

  const { onNavigateToOverview } = useOrderOpenBalancesNavigate();

  const {
    sortField, sortOrder, onChangeSort,
  } = useOrderOpenBalancesListSort();

  const {
    createNewDunningPageFilters, orderOpenbalanceFilterState, setOrderOpenbalanceFilterState, onSetDefaultFilterFromQueryParams,
  } = useOrderOpenbalanceFilters();

  useGlobalSearch({
    key: 'orderOpenBalance',
    filterProps: {
      availableFilters: createNewDunningPageFilters,
      setFilter: (key: string, value: string) => setOrderOpenbalanceFilterState(
        (currentFilter: any) => {
          const newFilter = { ...currentFilter };
          if (value === undefined || value === null) {
            // @ts-ignore
            delete newFilter[key];
          } else {
            newFilter[key] = value;
          }
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      onSetDefaultFilterFromQueryParams,
      filterState: orderOpenbalanceFilterState,
    },
  });


  const nonEmptyClassName = !isEmpty(propertyAndContractBalanceList.data) ? 'openBalanceListTableWithData' : undefined;


  const orderOpenBalancesTable = useSmartTable({
    tableName: 'OpenBalanceListTable',
    className: nonEmptyClassName,
    columns: useOrderOpenBalancesColumns(WIDTH_COL_1, WIDTH_COL_2, WIDTH_COL_3, WIDTH_COL_4),
    dataSource: outerTableFilteredDataSource,
    emptyResultsComponent: <EmptyResultsHint
      title={tl(orderOpenBalancesTranslations.emptyTableMessage.heading)}
      subTitle={tl(orderOpenBalancesTranslations.emptyTableMessage.message)}
    />,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => onChangeSort(dataKey as keyof PropertyContractsBalanceDto),
    },
    contentLoading: propertyAndContractBalanceList.loading,
    infiniteScrollerProps: {
      hasMoreData: !propertyAndContractBalanceList.lastPage,
      loadMoreData: onLoadPropertyAndContractBalances,
    },
    rowKey: 'propertyId',
    expandRowByClick: false,
    showHeaderCheckbox: true,
    onAllRowsSelected: onSelectAll,
    expandedRowRender: (record: PropertyContractsBalanceDto) => (
      <ContractBalanceTable
        propertyId={record.propertyId}
        // TODO fix ts error
        // @ts-ignore
        dataSource={record.debtorBalancesGrouped}
        columns={columns}
        visibleColumns={resizableVisibleColumns}
        resizableComponents={resizableComponents}
        changeColumnVisibility={changeColumnVisibility}
        selectedRowKeys={innerTableSelectedRowKeysCurrent}
        onChangeSelectedRowKeys={onChangeInnerTableSelectedRowKeys}
      />
    ),
    supportBatchActions: true,
    hideActionBar: true,
    selectedRowKeys: outerTableSelectedRowKeysCurrent,
    onChangeSelectedRowKeys: (keys, record) => onChangeOuterTableSelectedRowKeys(keys, record),
    expandedRowKeys,
    onChangeExpandedRowKeys,
    getCheckboxProps,
  });


  return (
    <div className="OrderOpenBalancesList">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button type="primary" onClick={onNavigateToOverview} disabled={proceedDisabled}>
            {tl(orderOpenBalancesTranslations.header.button)}
          </Button>
        )}
      />
      <TabLink links={tabLinks} />
      <div className="OrderOpenBalancesTableWrapper">
        <SmartTable {...orderOpenBalancesTable} />
      </div>
      <DunningActionBar />
    </div>
  );
};

export const OrderOpenBalancesListWithProvider = () => (
  <OrderOpenBalancesContextProvider>
    <OrderOpenBalancesList />
  </OrderOpenBalancesContextProvider>
);
