import React, {
  useContext, useState,
} from 'react';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { BankTransactionAllocationContext } from '../../services/BankTransactionAllocationContext';
import { DropMenu } from '../../../../components/DropMenu/DropMenu';
import { BankTransactionProjectionDtoTransactionPaymentStatusEnum } from '../../../../api/accounting';
import { translations } from '../../translations';
import ConfirmResetAllocationModal from './ConfirmResetAllocationModal/ConfirmResetAllocationModal';


interface Props {
  transactionState: BankTransactionProjectionDtoTransactionPaymentStatusEnum | undefined,
  resetTransaction: () => void,
  markTransactionNotBookable: () => void,
  markTransactionDuplicate: () => void,
}

const MODAL_DEFAULT_STATE = {
  isVisible: false,
  onCancel: () => {
  },
  onConfirm: () => {
  },
};

const MenuButton = ({
  transactionState,
  resetTransaction,
  markTransactionNotBookable,
  markTransactionDuplicate,
}: Props) => {
  const [confirmResetAllocationModal, setConfirmResetAllocationModal] = useState(MODAL_DEFAULT_STATE);
  const { tl } = useContext(LanguageContext);
  const { allocationContentLoading } = useContext(BankTransactionAllocationContext);

  const onAction = (onConfirm: () => void) => {
    if ([BankTransactionProjectionDtoTransactionPaymentStatusEnum.ASSIGNED, BankTransactionProjectionDtoTransactionPaymentStatusEnum.PARTIALLY_BOOKED].includes(transactionState!)) {
      setConfirmResetAllocationModal({
        isVisible: true,
        onCancel: () => setConfirmResetAllocationModal(MODAL_DEFAULT_STATE),
        onConfirm: () => {
          setConfirmResetAllocationModal(MODAL_DEFAULT_STATE);
          onConfirm();
        },
      });
    } else {
      onConfirm();
    }
  };

  const getOptions = () => {
    const options = [];
    if (transactionState !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.WONT_BE_ALLOCATED) {
      options.push({
        label: tl(translations.table.dontAllocate),
        onClick: () => onAction(markTransactionNotBookable),
      });
    }
    if (transactionState !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.UNASSIGNED) {
      options.push({
        label: tl(translations.table.resetAllocation),
        onClick: () => onAction(resetTransaction),
      });
    }
    if (transactionState !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.DUPLICATE) {
      options.push({
        label: tl(translations.table.markDuplicate),
        onClick: () => onAction(markTransactionDuplicate),
      });
    }

    return options;
  };
  return (
    <DropMenu
      options={getOptions()}
      disabled={allocationContentLoading}
    >
      <Icon component={ICONS.menuDots} />
      <ConfirmResetAllocationModal {...confirmResetAllocationModal} />
    </DropMenu>
  );
};


export default MenuButton;
