import React, { createContext, ReactNode, useState } from 'react';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { AgendaItemDto } from '../api/accounting';

export const AgendaItemsContext = createContext<{
    agendaItemsList: DefaultDataInterface<AgendaItemDto[]>,
    setAgendaItemsList: React.Dispatch<React.SetStateAction<DefaultDataInterface<AgendaItemDto[]>>>,
} | undefined>(undefined);

const AgendaItemsContextProvider = ({ children }: { children: ReactNode }) => {
  const [agendaItemsList, setAgendaItemsList] = useState<DefaultDataInterface<AgendaItemDto[]>>(DEFAULT_DATA<AgendaItemDto[]>([]));
  const providerValue = {
    agendaItemsList,
    setAgendaItemsList,
  };

  return (
    <AgendaItemsContext.Provider value={providerValue}>
      {children}
    </AgendaItemsContext.Provider>
  );
};

export default AgendaItemsContextProvider;
