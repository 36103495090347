import React, {
  useContext,
} from 'react';

import { OwnersMeetingInvitationDtoStatusEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { useParams } from 'react-router';
import { getStateColor } from 'storybook-components/headers/utils';

import { DropMenu } from 'components/DropMenu/DropMenu';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { Modal } from 'antd';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import {
  ownersMeetingInvitationTranslations,
} from '../../translations/OwnersMeetingInvitationTranslations';
import {
  useOwnerMeetingInvitationHeaderEditing,
} from './service/useOwnerMeetingInvitationHeaderEditing';

interface OwnerMeetingInvitationHeaderProps {
  save: (openNextSection: boolean) => void,
  editMode: boolean,
  onSend: any,
}

const OwnerMeetingInvitationEditingHeader = ({
  save, editMode, onSend,
}: OwnerMeetingInvitationHeaderProps) => {
  const { tl } = useContext(LanguageContext);
  const { etvId } = useParams<{ etvId: string }>();

  const {
    onClose,
    ownersMeeting,
    loading,
    isValid,
    onCheckMessages,
    onDelete,
    showSubtitle,
  } = useOwnerMeetingInvitationHeaderEditing();

  const closeButton = (
    <Button
      key="backButton"
      type="text"
      onClick={onClose}
      loading={loading}
    >
      {tl(ownersMeetingInvitationTranslations.editPage.close)}
    </Button>
  );
  const { goBack } = useContext(OverlayContext);

  const menuButton = (
    <DropMenu
      key="ownersMeetingProtocolDeletionModal"
      options={[{
        label: tl(ownersMeetingInvitationTranslations.editPage.deleteButton),
        onClick: () => Modal.confirm({
          title: tl(ownersMeetingInvitationTranslations.deleteModal.title),
          content: tl(ownersMeetingInvitationTranslations.deleteModal.content),
          okText: tl(ownersMeetingInvitationTranslations.deleteModal.okText),
          cancelText: tl(ownersMeetingInvitationTranslations.deleteModal.cancelText),
          okButtonProps: { className: 'Button', danger: true },
          cancelButtonProps: { className: 'Button' },
          onOk: () => { onDelete(ownersMeeting.id, goBack); },
          width: 450,
          icon: <Icon component={ICONS.statusError} />,
        }),
      }]}
    />
  );

  const saveButton = (
    <Button
      key="saveAllButton"
      onClick={() => save(false)}
      loading={loading}
    >
      {tl(ownersMeetingInvitationTranslations.editPage.saveAll)}
    </Button>
  );

  const sendOutButton = (
    <Button
      key="sendOutButton"
      type="primary"
      onClick={onSend}
      loading={loading}
      disabled={!isValid}
    >
      {tl(ownersMeetingInvitationTranslations.editPage.sendOut)}
    </Button>
  );

  const checkMessagesButton = (
    <Button
      key="checkMessagesButton"
      type="primary"
      onClick={onCheckMessages}
      loading={loading}
    >
      {tl(ownersMeetingInvitationTranslations.editPage.checkMessages)}
    </Button>
  );


  const rightSideButtons = [closeButton];

  // secondary action
  if (editMode) {
    rightSideButtons.push(menuButton);
    rightSideButtons.push(saveButton);
  }

  if (ownersMeeting.status === OwnersMeetingInvitationDtoStatusEnum.ERROR || editMode) {
    rightSideButtons.push(sendOutButton);
  }

  if (ownersMeeting.status === OwnersMeetingInvitationDtoStatusEnum.SENT) {
    rightSideButtons.push(checkMessagesButton);
  }

  const titleForCreatedEtv = editMode ? tl(ownersMeetingInvitationTranslations.editPage.editTitle) : tl(ownersMeetingInvitationTranslations.editPage.viewTitle);
  return (
    <PageHeader
      title={etvId ? titleForCreatedEtv : tl(ownersMeetingInvitationTranslations.editPage.createTitle)}
      subtitle={showSubtitle && <PropertyInfo property={ownersMeeting.property} />}
      rightSideComponent={rightSideButtons}
      tagContent={ownersMeeting.status && ownersMeeting.property !== undefined ? tl(ownersMeetingInvitationTranslations.ownersMeetingStates[ownersMeeting.status]) : ''}
      tagColor={getStateColor(ownersMeeting?.status) as PageHeaderTagColor}
      showLogo
    />
  );
};

export default OwnerMeetingInvitationEditingHeader;
