import { useContext, useMemo } from 'react';
import useContactOptions from 'services/useContactOptions';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { range } from 'lib/Utils';
import { navigateConcat } from 'navigation/History';
import { AutoSelectFunctionType, PersonEditingContext } from 'contexts/PersonEditingContext';
import { CompanyEditingContext } from 'contexts/CompanyEditingContext';
import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import defaultSection from './defaultSection';

interface CompanySectionProps {
  companyNumber: number,
  autoSelectCompanyAfterCreation: AutoSelectFunctionType,
}

export default function useCompanySection(props: CompanySectionProps) {
  const { companyNumber, autoSelectCompanyAfterCreation } = props;
  const { tl } = useContext(LanguageContext);
  const { searchForContact, onLoadContact, contactOptions } = useContactOptions([ContactLegacyDtoTypeEnum.COMPANY]);
  const companyEditingContext: any = useContext(CompanyEditingContext);
  const { data }: any = useContext(PersonEditingContext);

  const companyOptions = useMemo(() => {
    if (!data.contact.linkedToContacts) {
      return contactOptions;
    }
    const linkedToContactIds = data.contact.linkedToContacts.map((c: any) => c.linkedContactId);
    return contactOptions.filter((contact: any) => !linkedToContactIds.includes(contact.id));
  }, [data.contact.linkedToContacts, contactOptions]);

  function companyInputs(index: number) {
    let companyOptionsWithCurrentValue;

    try {
      companyOptionsWithCurrentValue = contactOptions
        .filter((c: any) => c.id === data.contact.linkedToContacts[index].linkedContactId)
        .concat(companyOptions);
    } catch (e) {
      companyOptionsWithCurrentValue = companyOptions;
    }

    return [[
      {
        type: 'smartSearch',
        key: `linkedToContacts[${index}].linkedContactId`,
        props: {
          label: `${tl(translations.pages.contactEditing.companySection.dataSection.fields.company)} ${index + 1}`,
          searchFunction: searchForContact,
          options: companyOptionsWithCurrentValue,
          getOneFunction: onLoadContact,
          addButtons: [{
            label: tl(translations.elements.smartSearch.addMore),
            onClick: (e: any) => {
              e.stopPropagation();
              companyEditingContext.setAutoSelectCompanyAfterCreation(() => autoSelectCompanyAfterCreation);
              navigateConcat('/create-company');
            },
          }],
        },
      },

    ],
    [
      {
        type: 'select',
        key: `linkedToContacts[${index}].role`,
        props: {
          label: tl(translations.pages.contactEditing.companySection.dataSection.fields.role.title),
          options: [
            {
              value: 'Manager',
              label: 'Manager',
            },
            {
              value: 'Employee',
              label: 'Employee',
            },
          ],
        },
      },
    ],
    ];
  }

  function getCompanyFields(numberCompanies: number) {
    const displayNumber = Math.max(0, numberCompanies - 1);
    return range(0, displayNumber).reduce((accumulator: Array<any>, number: number) => accumulator.concat(companyInputs(number)), []);
  }

  return {
    ...defaultSection(1),
    sectionTitle: tl(translations.pages.contactEditing.companySection.title),
    sectionId: 'companyData',
    content: [
      {
        sectionId: 'data',
        title: tl(translations.pages.contactEditing.companySection.dataSection.title),
        content: [
          ...getCompanyFields(companyNumber),
          [
            {
              type: 'addButton',
              key: 'companyNumber',
              props: {
                label: tl(translations.pages.contactEditing.companySection.dataSection.fields.add),
              },
            },
          ],
        ],
      },
    ],
  };
}
