import { useContext } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { translations } from '../../translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import usePropertyOptionsHrIdVersion from '../../../../services/usePropertyOptionsHrIdVersion';
import { showNotification } from '../../../../lib/Notification';
import { AuthContext } from '../../../../contexts/AuthContext';
import {
  SpecialContributionControllerApi, SpecialContributionDetailsDto, SpecialContributionDto, FindFilteredPropertiesUsingGETAdministrationTypesEnum,
} from '../../../../api/accounting';


export default function Section1(
  setDirty: (dirty: boolean) => void,
  setLoading: (value: boolean) => void,
  specialContributionDetails: SpecialContributionDetailsDto | undefined,
  setSpecialContributionDetails: (specialContributionDetailsDto: SpecialContributionDetailsDto) => void,
) {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { goBack } = useContext(OverlayContext);
  const api = new SpecialContributionControllerApi(apiConfiguration('accounting'));
  const history = useHistory();
  const { specialContributionId } = useParams<{ specialContributionId: string }>();
  const { checkPropertyValidity } = useCheckPropertyValidity();

  const {
    propertyOptions,
    searchForProperty,
    onLoadProperty,
  } = usePropertyOptionsHrIdVersion();

  const save = () => {
    setLoading(true);
    return api.createSpecialContributionUsingPOST({
      creationData: {
        propertyHrId: specialContributionDetails?.propertyHrId,
      },
    })
      .then((response: SpecialContributionDto) => {
        history.replace(`/special-contribution/edit/${response.id}`, { openSectionIndex: 1 });
      })
      .catch(() => {
        showNotification({
          key: 'saveSpecialContributionError',
          message: tl(translations.specialContribution.saveSpecialContributionError),
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  return {
    sectionTitle: tl(translations.specialContribution.section.propertySectionTitle),
    sectionId: 'propertySectionId',
    sectionNumber: 1,
    openingNumber: 0,
    inputsDisabled: !!specialContributionId,
    onSubmit: save,
    content: [
      {
        sectionId: 'PropertySelection',
        content: [
          [
            {
              type: 'smartSearch',
              key: 'specialContributionDetails.propertyHrId',
              props: {
                label: tl(translations.specialContribution.section.selectProperty),
                searchFunction: (filter) => { searchForProperty(filter, [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG]); },
                getOneFunction: onLoadProperty,
                options: propertyOptions,
                required: true,
                autoFocus: true,
                value: specialContributionDetails?.propertyHrId,
                onChange: (newValue: string) => {
                  if (newValue) {
                    checkPropertyValidity({ propertyHrId: newValue, onCancel: goBack });
                  }
                  if (newValue !== specialContributionDetails?.propertyHrId) {
                    setDirty(true);
                  }
                  setSpecialContributionDetails({
                    ...specialContributionDetails,
                    propertyHrId: newValue,
                  });
                },
              },
            },
          ],
        ],
      },
    ],
  };
}
