import React, { useContext } from 'react';
import './SubsectionTitle.scss';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';

interface subSectionTitleProps {
  label: string,
  optional?: boolean,
  description?: string,
  className?:string
}


export default function SubsectionTitle(props: subSectionTitleProps): JSX.Element {
  const { tl } = useContext(LanguageContext);

  return (
    <div className={`SubsectionTitle ${props.className}`}>
      <label>
        {props.label}
        {' '}
        {props.optional
        && (
          <span className="optional">
(
            {tl(translations.elements.section.subsectionTitle.optional)}
)
          </span>
        )}
      </label>
      {!!props.description && <div className="description">{props.description}</div>}
    </div>
  );
}

SubsectionTitle.defaultProps = {
  label: 'Title',
  optional: false,
  className: '',
};
