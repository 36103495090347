import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import Amount from 'storybook-components/typography/Amount/Amount';
import useSmartTable from '../../../../elements/SmartTable/useSmartTable';
import SmartTable from '../../../../elements/SmartTable/SmartTable';
import { UseUnitResultsTableColumns } from './UseUnitResultsTableColumns';
import { formatCurrency } from '../../../../lib/Utils';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import SummaryRow from '../../../../elements/CustomElements/ExpandableCard/SummaryRow';
import './UnitResultsTable.scss';
import { economicPlanOverviewTranslations } from '../economicPlanOverviewTranslations';

interface UnitResultsTableProps {
  dataSource: any[]
  loading: boolean
}

interface UnitResultsTotalProps {
  dataSource: any[]
}

const HOUSE_MONEY = 'Hausgeld';

export function ResultsTable(props: UnitResultsTableProps) {
  const { dataSource, loading } = props;
  const { tl } = useContext(LanguageContext);

  const renderMultipleRows = (data: any, key: string, isCurrency?: boolean) => (
    <div>
      {data && data.map((info: any, idx: number) => (
        <div key={idx.toString() + info.name + info[key]}>
          {isCurrency ? formatCurrency(info[key]) : info[key]}
          <br />
        </div>
      ))}
    </div>
  );

  const renderName = (names: any) => (
    <div>
      {names.map((name: string, idx: number) => (
        <div key={`${idx.toString()} name`}>
          {name === HOUSE_MONEY
            ? tl(economicPlanOverviewTranslations.unitResultsTable.houseMoney)
            : name}
          <br />
        </div>
      ))}
    </div>
  );

  const calculateSubtotal = (values: any[], key: string) => values.reduce((accumulator: any, val: any) => {
    // eslint-disable-next-line no-param-reassign
    if (val) accumulator += val[key];
    return accumulator;
  }, 0);

  const addSubTotalRow = (data: any) => {
    const newData: any[] = [];
    data.forEach((item: any) => {
      newData.push(item);
      newData.push({
        unit: {
          id: item.unit.unitNrSharingDeclaration,
          unitNrSharingDeclaration: tl(economicPlanOverviewTranslations.unitResultsTable.headers.subtotal),
        },
        tableData: [{
          previousAmount: item.tableData ? calculateSubtotal(item.tableData, 'previousAmount') : 0,
          amount: item.tableData ? calculateSubtotal(item.tableData, 'amount') : 0,
          backPayCredit: item.tableData ? calculateSubtotal(item.tableData, 'backPayCredit') : 0,
          name: '',
          fromDate: '',
        }],
      });
    });
    return newData;
  };

  const toTableData = (data: any) => ({
    id: data.unit.unitNrSharingDeclaration + (data.unit.id || ''),
    unit: data.unit.unitNrSharingDeclaration,
    accountName: renderName(data.tableData ? data.tableData.map((td: any) => td.name) : []),
    amountPrevious: renderMultipleRows(data.tableData, 'previousAmount', true),
    newUnitAmount: renderMultipleRows(data.tableData, 'amount', true),
    fromDate: renderMultipleRows(data.tableData, 'fromDate'),
    backPayCredit: renderMultipleRows(data.tableData, 'backPayCredit', true),
  });


  const unitResults = useMemo(() => (dataSource ? addSubTotalRow(concatEconomicPlanResultArrays(dataSource)).map((data: any) => toTableData(data)) : []), [dataSource]);
  const table = useSmartTable({
    tableName: 'unitResults',
    columns: UseUnitResultsTableColumns(),
    dataSource: unitResults,
    rowClassName: (record, index) => (index % 2 ? 'subtotal-row' : ''),
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'id',
  });

  return (
    <div className="ResultsTable">
      <SmartTable {...table} />
    </div>
  );
}

const concatEconomicPlanResultArrays = (data: any) => {
  if (!data.length || !data[0].reserveFundResults.length) return [];
  const fromDate: string = moment(data[0].reserveFundResults[0].fromDate).format('DD.MM.YYYY');
  return data.reduce((accumulator: any, item: any) => {
    accumulator.push({
      unit: item.unit,
      tableData: item.reserveFundResults.map((account: any) => ({
        name: account.accountName,
        fromDate,
        amount: account.newUnitAmount,
        backPayCredit: account.backPayCredit,
        previousAmount: account.amountPrevious,
      })).concat([{
        name: HOUSE_MONEY,
        fromDate,
        amount: item.yearlyHouseMoney,
        backPayCredit: 0,
        previousAmount: 0,
      }]),
    });
    return accumulator;
  }, []);
};

export function RenderResultsTotal(props: UnitResultsTotalProps) {
  const { tl } = useContext(LanguageContext);
  const { dataSource } = props;
  const defaultSummary = {
    amountPrevious: 0,
    newUnitAmount: 0,
    backPayCredit: 0,
  };

  const summaryValues = useMemo(() => (dataSource ? concatEconomicPlanResultArrays(dataSource).reduce((accumulator: any, currentItem: any) => {
    const subtotal = currentItem.tableData.reduce((acc: any, tableData: any) => {
      acc.amountPrevious += tableData.previousAmount;
      acc.newUnitAmount += tableData.amount;
      acc.backPayCredit += tableData.backPayCredit;
      return acc;
    }, {
      amountPrevious: 0,
      newUnitAmount: 0,
      backPayCredit: 0,
    });
    accumulator.amountPrevious += subtotal.amountPrevious;
    accumulator.newUnitAmount += subtotal.newUnitAmount;
    accumulator.backPayCredit += subtotal.backPayCredit;
    return accumulator;
  }, defaultSummary) : defaultSummary), [dataSource]);

  const summaryElements = [
    {
      title: tl(economicPlanOverviewTranslations.unitResultsTable.headers.previousAmount),
      value: <Amount>{formatCurrency(summaryValues.amountPrevious)}</Amount>,
    },
    {
      title: tl(economicPlanOverviewTranslations.unitResultsTable.headers.newAmount),
      value: <Amount>{formatCurrency(summaryValues.newUnitAmount)}</Amount>,
    },
    {
      title: tl(economicPlanOverviewTranslations.unitResultsTable.headers.backPay),
      value: <Amount>{formatCurrency(summaryValues.backPayCredit)}</Amount>,
    },
  ];
  return (
    <SummaryRow
      title={tl(economicPlanOverviewTranslations.unitResultsTable.headers.total)}
      rightSideElements={summaryElements}
    />
  );
}

export default function UnitResultsTable(props: UnitResultsTableProps): JSX.Element {
  const { dataSource, loading } = props;
  return (
    <div className="UnitResultsTable">
      <ResultsTable dataSource={dataSource} loading={loading} />
      <div className="unit-results-summary">
        <RenderResultsTotal
          dataSource={dataSource}
        />
      </div>
    </div>
  );
}
