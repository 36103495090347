import React, { ReactNode, useContext, useMemo } from 'react';
import { translations } from '../../../../elements/Translation/translations';
import UnitExpensesCard, {
  useRenderUnitExpensesTotal,
} from '../../../HouseMoneySettlement/pages/HouseMoneySettlementOverview/components/AccountBalancesCards/UnitExpensesCard/UnitExpensesCard';
import UnitIncomeCard, {
  useRenderUnitIncomeTotal,
} from '../../../HouseMoneySettlement/pages/HouseMoneySettlementOverview/components/AccountBalancesCards/UnitIncomeCard/UnitIncomeCard';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { ExpandableCardsList } from '../../../../elements/CustomElements/ExpandableCardsList/ExpandableCardsList';
import { OwnershipDuesTable, useRenderOwnerShipDuesTotal } from '../MonthlyPaymentTable/MonthlyPaymentTable';
import TotalAmountTable from '../TotalAmountTable/TotalAmountTable';
import UnitReserveFundsTable, { useRenderUnitReserveFundsTotal } from '../UnitReserveFundsTable/UnitReserveFundsTable';
import { economicPlanOverviewTranslations } from '../economicPlanOverviewTranslations';

interface UnitEconomicPlanProps {
  headerChildren: ReactNode,
  economicPlan: any,
  unitEconomicPlan: any
}

export default function UnitEconomicPlan(props: UnitEconomicPlanProps): JSX.Element {
  const { headerChildren, economicPlan, unitEconomicPlan } = props;
  const { tl } = useContext(LanguageContext);
  const economicPlanData = economicPlan ? economicPlan.data : {};
  const unitEconomicPlanData = unitEconomicPlan ? unitEconomicPlan.data : {};

  const totalAmountDataSource = useMemo(() => {
    const reserveFundAccounts = unitEconomicPlanData && unitEconomicPlanData.reserveFundResults ? unitEconomicPlanData.reserveFundResults : [];
    return {
      totalExpenses: economicPlanData && economicPlanData.results ? economicPlanData.results.applicableExpenses.reduce((acc: any, expense: any) => acc + (expense.totalSum || 0), 0)
          + economicPlanData.results.notApplicableExpenses.reduce((acc: any, expense: any) => acc + (expense.totalSum || 0), 0)
        : 0,
      totalIncome: economicPlanData && economicPlanData.results ? economicPlanData.results.income.reduce((acc: any, expense: any) => acc + (expense.totalSum || 0), 0) : 0,
      totalReserveFunds: economicPlanData && economicPlanData.results && economicPlanData.results.reserveFunds
        ? economicPlanData.results.reserveFunds.reduce((acc: any, reserve: any) => (typeof reserve.totalSum === 'number' ? acc + (reserve.totalSum || 0) : acc), 0) : 0,
      expensesShare: unitEconomicPlanData ? unitEconomicPlanData.applicableExpenseResults.reduce((acc: any, expense: any) => acc + (expense.newUnitAmount || 0), 0)
          + unitEconomicPlanData.notApplicableExpenseResults.reduce((acc: any, expense: any) => acc + (expense.newUnitAmount || 0), 0)
        : 0,
      incomeShare: unitEconomicPlanData ? unitEconomicPlanData.incomeResults.reduce((acc: any, income: any) => acc + (income.newUnitAmount || 0), 0) : 0,
      reserveFundsShare: reserveFundAccounts ? reserveFundAccounts.reduce((acc: any, reserve: any) => acc + (reserve.newUnitAmount || 0), 0) : 0,
    };
  }, [unitEconomicPlanData, economicPlanData]);

  const reserveFundsDataSource = useMemo(() => (unitEconomicPlanData && unitEconomicPlanData.reserveFundResults ? unitEconomicPlanData.reserveFundResults : []),
    [unitEconomicPlan]);

  const ownershipDuesDataSource = useMemo(() => {
    try {
      const previousReserveMoney = 0;
      let previousHouseMoney = 0;
      if (typeof unitEconomicPlanData.unit.monthlyHouseMoney !== 'undefined') {
        previousHouseMoney = unitEconomicPlanData.unit.monthlyHouseMoney;
      } else if (typeof unitEconomicPlanData.unit.houseMoneyAmounts !== 'undefined') {
        previousHouseMoney = unitEconomicPlanData.unit.houseMoneyAmounts[0];
      }
      return [
        {
          name: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.houseMoney),
          previous: previousHouseMoney || 0,
          newAmount: unitEconomicPlanData.monthlyHouseMoney || 0,
          difference: unitEconomicPlanData.monthlyHouseMoney - previousHouseMoney || 0,
          due: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.monthly),
        },
        {
          name: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.reserveMoney),
          previous: previousReserveMoney || 0,
          newAmount: unitEconomicPlanData.monthlyReserveFunds || 0,
          difference: unitEconomicPlanData.monthlyReserveFunds - previousReserveMoney || 0,
          due: tl(economicPlanOverviewTranslations.ownershipDues.table.headers.monthly),
        },
      ];
    } catch (e) {
      return [];
    }
  },
  [unitEconomicPlanData]);

  const expandableCardGroups = [
    {
      groupName: tl(translations.pages.economicPlan.navigationGroups.overview),
      expandableCards: [
        {
          loading: unitEconomicPlan.loading,
          title: tl(translations.pages.houseMoneySettlement.unitAccountBalances.expenses.title),
          children: <UnitExpensesCard
            applicableExpenses={unitEconomicPlanData ? unitEconomicPlanData.applicableExpenseResults : []}
            notApplicableExpenses={unitEconomicPlanData ? unitEconomicPlanData.notApplicableExpenseResults : []}
            labelsPrefix="economicPlan"
            loading={unitEconomicPlan.loading}
          />,
          category: 'expenses',
          navigationTitle: tl(translations.pages.economicPlan.unitAccountBalances.expenses.title),
          summaryOnBottom: true,
          summary: useRenderUnitExpensesTotal({
            applicableExpenses: unitEconomicPlanData?.applicableExpenseResults ? unitEconomicPlanData.applicableExpenseResults : [],
            notApplicableExpenses: unitEconomicPlanData?.notApplicableExpenseResults ? unitEconomicPlanData.notApplicableExpenseResults : [],
            labelsPrefix: 'economicPlan',
            loading: unitEconomicPlan.loading,
            summaryTitle: tl(translations.pages.economicPlan.unitAccountBalances.expenses.total),
          }),
          navigationSubElements: [{
            name: tl(translations.pages.economicPlan.propertyAccountBalances.expenses.subTitles.applicableExpenses),
            elementId: 'applicableExpenses',
          },
          {
            name: tl(translations.pages.economicPlan.propertyAccountBalances.expenses.subTitles.notApplicableExpenses),
            elementId: 'notApplicableExpenses',
          }],
        },
        {
          loading: unitEconomicPlan.loading,
          title: tl(translations.pages.economicPlan.unitAccountBalances.income.title),
          children: <UnitIncomeCard
            incomes={unitEconomicPlanData ? unitEconomicPlanData.incomeResults : []}
            labelsPrefix="economicPlan"
            loading={unitEconomicPlan.loading}
          />,
          category: 'income',
          navigationTitle: tl(translations.pages.economicPlan.unitAccountBalances.income.title),
          summaryOnBottom: true,
          summary: useRenderUnitIncomeTotal({
            incomes: unitEconomicPlanData ? unitEconomicPlanData.incomeResults : [],
            labelsPrefix: 'economicPlan',
            loading: unitEconomicPlan.loading,
            summaryTitle: tl(translations.pages.economicPlan.unitAccountBalances.income.total),
          }),
        },
        {
          loading: economicPlan.loading,
          title: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.title),
          children: <UnitReserveFundsTable
            dataSource={reserveFundsDataSource}
            loading={economicPlan.loading}
          />,
          category: 'unit-reserve-funds',
          navigationTitle: tl(translations.pages.economicPlan.unitAccountBalances.reserveFunds.title),
          summaryOnBottom: true,
          summary: useRenderUnitReserveFundsTotal({
            dataSource: reserveFundsDataSource,
          }),
        },
        {
          loading: economicPlan.loading,
          title: tl(translations.pages.economicPlan.unitAccountBalances.totalAmount.title),
          children: <TotalAmountTable
            dataSource={totalAmountDataSource}
            loading={economicPlan.loading}
            isUnitTotal
          />,
          category: 'unit-total-amount',
          navigationTitle: tl(translations.pages.economicPlan.unitAccountBalances.totalAmount.title),
        },
        {
          loading: economicPlan.loading,
          title: tl(translations.pages.economicPlan.unitAccountBalances.ownershipDues.title),
          children: <OwnershipDuesTable
            dataSource={ownershipDuesDataSource}
            loading={economicPlan.loading}
          />,
          category: 'ownership-dues-table',
          navigationTitle: tl(translations.pages.economicPlan.unitAccountBalances.ownershipDues.title),
          summaryOnBottom: true,
          summary: useRenderOwnerShipDuesTotal({ dataSource: ownershipDuesDataSource }),
        },
      ],
    },
  ];

  return (
    <div className="UnitEconomicPlan">
      <ExpandableCardsList
        headerChildren={headerChildren}
        expandableCardGroups={expandableCardGroups}
      />
    </div>
  );
}
