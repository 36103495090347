import { LanguageContext } from 'contexts/LanguageContext';
import DeleteButton from 'elements/FormElements/FormSection/DeleteButton';
import { isEmpty } from 'lodash';
import { getDocumentPreviewRoute } from 'pages/DocumentPreview/routes';
import { RecipientDatasource } from 'pages/MessageSendingPage/interfaces';
import { useDocumentContext, useRecipientContext, useRecipientUpdaterContext } from 'pages/MessageSendingPage/services/MessageSendingContext';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

export const useRecipientFilesCell = (recipient: RecipientDatasource) => {
  const { tl } = useContext(LanguageContext);
  const [visible, setVisible] = useState(false);
  const [removedDocumentIds, setRemovedDocumentIds] = useState<number[]>([]);

  const recipientContext = useRecipientContext('useRecipientFilesCell');
  const documentContext = useDocumentContext('useRecipientFilesCell');
  const recipientUpdaterContext = useRecipientUpdaterContext('useRecipientFilesCell');

  const { documentRecipients } = recipientContext;
  const { documentDatasource } = documentContext;
  const { setDocumentRecipients } = recipientUpdaterContext;

  const onClickDelete = (id: number) => {
    setRemovedDocumentIds((prev) => {
      const newVal = [...prev];
      newVal.push(id);
      return newVal;
    });
  };

  const columns = [{
    dataIndex: 'displayName',
    title: tl(messageSendingTranslations.recipientFiles.files),
    render: (_, record) => <Link target="_blank" to={getDocumentPreviewRoute(record.id)}>{record.displayName}</Link>,
  }, {
    title: '',
    dataIndex: 'delete',
    width: 80,
    render: (_, record) => <DeleteButton onClick={() => onClickDelete(record.id)} />,
  }];

  const datasource = useMemo(() => {
    const documentIds = documentRecipients
      .filter(dr => dr.contractId === recipient.contractId && dr.contactId === recipient.contactId)
      .map(dr => dr.documentId);
    const documents = documentDatasource.data
      ?.flatMap(prp => prp.children)
      .flatMap(d => d?.children || d)
      .filter(d => documentIds.includes(d.id));

    return documents.filter(d => !removedDocumentIds.includes(d.id));
  }, [documentRecipients, documentDatasource, removedDocumentIds]);

  const onClose = () => {
    setVisible(false);
  };

  const onOpen = () => {
    setVisible(true);
  };

  const onDone = () => {
    if (!isEmpty(removedDocumentIds)) {
      setDocumentRecipients(prev => prev.filter(d => !(removedDocumentIds.includes(d.documentId)
            && d.contractId === recipient.contractId
            && d.contactId === recipient.contactId)));
    }
    setVisible(false);
  };

  return {
    subtitle: recipient.name,
    columns,
    datasource,
    loading: documentDatasource.loading,
    visible,
    onOpen,
    onClose,
    onDone,
  };
};
