import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { Route, RouteProps } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { useOverlayContext } from 'services/OverlayContext/useOverlayContext';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import FocusView from '../elements/FocusView/FocusView';
import { overlayTypes } from '../services/OverlayContext/OverlayContext';
import CurrentOverlayInfoProvider from '../components/OverlayRoute/services/CurrentOverlayInfoContext';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

export const FocusRoute: React.FC<{componentProps?: any} & RouteProps> = ({
  children, component, componentProps, ...routeProps
}) => {
  const {exact, path} = routeProps;
  const { zIndex } = useOverlayContext({ path: path as string, type: overlayTypes.FOCUS, exact });

  const RenderComponent = component as React.FC; // for some reason I cannot use JSX.Element type :/
  return (
    <>
      <SentryRoute {...routeProps}>
        {({ match }) => (
          <CSSTransition
            appear
            in={match != null}
            timeout={200}
            classNames="focus-transition"
            unmountOnExit
          >
            <CurrentOverlayInfoProvider currentPathname={routeProps.location.pathname} pathPattern={routeProps.path}>
              <FocusView match={match} zIndex={zIndex}>
                <Suspense fallback={<LoadingScreen />}>
                  <RenderComponent match={match} {...componentProps} />
                </Suspense>
              </FocusView>
            </CurrentOverlayInfoProvider>
          </CSSTransition>
        )}
      </SentryRoute>
      {children}
    </>
  );
};
