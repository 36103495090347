

import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import useSmartTable from '../../elements/SmartTable/useSmartTable';
import { useDistributionKeyTableColumns } from './UseDistributionKeyTableColumns';
import './DistributionKeyTable.scss';
import SmartTable from '../../elements/SmartTable/SmartTable';
import MainPageHeader from '../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../services/useSiteMap/useSiteMap';
import { useGlobalSearch } from '../../components/Header/components/SearchBar/services/useGlobalSearch';
import { PropertyLegacyDtoPropertyStateEnum } from '../../api/accounting';
import useDistributionKeyFilters from './useDistributionKeyFilters';
import { useDistributionKeysPropertyList } from './services/useDistributionKeysPropertyList';
import DistributionKeysPropertyListContext from './services/DistributionKeysPropertyListContext';
import { DISTRIBUTION_FOR_HGA_EP } from './AccountDistributionEditor/services/utils';

const DistributionKeyTable = () => {
  const {
    propertyList, filterState, onChangeFilterState, sortState, setSortField, loadPropertyList, onSetDefaultFilterFromQueryParams,
  } = useDistributionKeysPropertyList();
  const location = useLocation();
  const history = useHistory();

  useGlobalSearch({
    key: 'distributionKeys',
    filterProps: {
      availableFilters: useDistributionKeyFilters(),
      setFilter: onChangeFilterState,
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams,
      defaultFilters: {
        propertyStates: [PropertyLegacyDtoPropertyStateEnum.READY],
      },
    },
  });

  const smartTable = useSmartTable(
    {
      tableName: 'distributionKeys',
      columns: useDistributionKeyTableColumns(),
      dataSource: propertyList.data,
      onRow: (record: any) => ({
        onClick: () => {
          const economicYear = moment(record.economicYearStart).get('year');
          let path = `${location.pathname}/${record.propertyHrId}?economicYear=${economicYear}`;
          if (record?.administrationType === 'WEG') {
            path = `${path}&distributionFor=${DISTRIBUTION_FOR_HGA_EP}`;
          }
          history.push(path);
        },
      }),
      propSort: {
        field: sortState.field,
        order: sortState.order,
        onSortChange: (dataKey: string) => setSortField(dataKey),
      },
      contentLoading: propertyList.loading,
      infiniteScrollerProps: {
        hasMoreData: !propertyList.lastPage,
        loadMoreData: loadPropertyList,
      },
      rowKey: 'propertyHrId',
    },
  );

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'distributionKeys',
    navItems: subcategorySwitcherItems.accounting,
  };
  return (
    <div className="DistributionKeyTable page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      <SmartTable {...smartTable} />
    </div>
  );
};


export const DistributionKeyTableWithProvider = () => (
  <DistributionKeysPropertyListContext>
    <DistributionKeyTable />
  </DistributionKeysPropertyListContext>
);
