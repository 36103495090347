import { PaymentProjectionDtoPaymentStateEnum } from 'api/accounting';
import ColorDot, { DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { PaymentTableData } from '../interfaces';

const mapStateToColor = (state: PaymentProjectionDtoPaymentStateEnum) => {
  switch (state) {
  case PaymentProjectionDtoPaymentStateEnum.NEW:
    return DotColorEnum.BLUE;
  case PaymentProjectionDtoPaymentStateEnum.DELETED:
    return DotColorEnum.GRAY;
  case PaymentProjectionDtoPaymentStateEnum.SENT:
    return DotColorEnum.GREEN;
  default: return DotColorEnum.GRAY;
  }
};

export const PaymentStateCell = ({ record } : { record: PaymentTableData }) => (
  <ColorDot color={mapStateToColor(record.paymentState)}>
    <span>{record.translatedPaymentState}</span>
  </ColorDot>
);
