import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { useMessageSendingContext } from './MessageSendingContext';
import { messageSendingTranslations } from '../translations';

export const useMessageSendingPage = () => {
  const messageSendingContext = useMessageSendingContext('useMessageSendingPage');
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);

  const {
    dirty,
    source,
  } = messageSendingContext;


  return {
    title: tl(messageSendingTranslations.title),
    subtitle: source?.sourceType ? tl(messageSendingTranslations.sourceType[source.sourceType]) : '',
    dirty,
    goBack,
  };
};
