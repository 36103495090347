import Icon from '@ant-design/icons/lib/components/Icon';
import {
  Alert, Col, Modal, Row,
} from 'antd';
import { DocumentDto } from 'api/public';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import RadioGroupInput from 'elements/Inputs/RadioGroupInput/RadioGroupInput';
import { SPACE_SIZES } from 'lib/constants';
import React, { useContext } from 'react';
import { downloadModalTranslations } from './translations';
import { useDocumentDownloadModal } from './useDocumentDownloadModal';

interface DocumentDownloadModalProps {
    visible: boolean,
    onChangeVisible: (visible: boolean) => void,
    loading: boolean,
    startLoading: () => void,
    stopLoading: () => void,
    selectedDocumentId?: number,
    documents: DocumentDto[],
}

export const DocumentDownloadModal = ({
  visible, onChangeVisible, loading, selectedDocumentId, documents, startLoading, stopLoading,
}: DocumentDownloadModalProps) => {
  const { tl } = useContext(LanguageContext);

  const {
    merged,
    withOverview,
    onChangeMerged,
    onChangeWithOverview,
    onDownload,
  } = useDocumentDownloadModal({
    selectedDocumentId, documents, startLoading, stopLoading, onChangeVisible,
  });
  return (
    <Modal
      visible={visible}
      title={tl(downloadModalTranslations.title)}
      onCancel={() => onChangeVisible(false)}
      footer={[
        <Button key="cancel" type="text" onClick={() => onChangeVisible(false)}>
          {tl(downloadModalTranslations.cancel)}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={onDownload}
          loading={loading}
        >
          {tl(downloadModalTranslations.download)}
        </Button>,
      ]}
    >
      <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
        {selectedDocumentId !== undefined
        && (
          <Col span={24}>
            <RadioGroupInput
              onChange={onChangeMerged}
              value={merged}
              label={tl(downloadModalTranslations.format)}
              options={[
                {
                  value: false,
                  label: tl(downloadModalTranslations.single),
                },
                {
                  value: true,
                  label: tl(downloadModalTranslations.merged),
                },
              ]}
            />
          </Col>
        )
        }
        {(merged || !selectedDocumentId) && (
          <Col span={24}>
            <RadioGroupInput
              onChange={onChangeWithOverview}
              value={withOverview}
              label={tl(downloadModalTranslations.includeOverview)}
              options={[
                {
                  value: true,
                  label: tl(downloadModalTranslations.yes),
                },
                {
                  value: false,
                  label: tl(downloadModalTranslations.no),
                },
              ]}
            />
          </Col>
        )}
        <Col span={24}>
          <Alert
            message={tl(downloadModalTranslations.info)}
            type="info"
            showIcon
            icon={<Icon component={ICONS.info} />}
          />
        </Col>
      </Row>
    </Modal>
  );
};

DocumentDownloadModal.defaultProps = {
  selectedDocumentId: undefined,
};
