import { useContext } from 'react';
import { ContactsListContext } from './ContactsListContext';

export const useInvalidateAllContactsListCache = () => {
  const contactsListContext = useContext(ContactsListContext);

  if (contactsListContext === undefined) {
    throw new Error('useInvalidateAllContactsListCache must be used within a ContactsListContextProvider');
  }

  const { setIsCacheValid } = contactsListContext;

  const invalidateCache = () => {
    setIsCacheValid(false);
  };

  return { invalidateCache };
};
