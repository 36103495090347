export const translations = {
  create: {
    en: 'Create',
    de: 'Erstellen',
  },
  list: {
    header: {
      name: {
        en: 'Name',
        de: 'Name',
      },
    },
    actions: {
      create: {
        en: 'Create a new message',
        de: 'Neuen Brief erstellen',
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
      setAsDefault: {
        en: 'Set as default',
        de: 'Als Standard festlegen',
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
    },
    emptyList: {
      noWatermarkFiles: {
        en: 'No watermark files',
        de: 'Keine Schreibwarenordner',
      },
      pleaseUploadWatermark: {
        en: 'Please upload a PDF/A file via the "Add Watermark" button above.',
        de: 'Bitte laden Sie eine PDF/A-Datei über die Schaltfläche "Briefpapier hinzufügen" oben hoch.',
      },
    },
    labels: {
      default: {
        en: 'Default',
        de: 'Standard',
      },
    }
  },
};
