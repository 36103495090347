export const translations = {
  placeholder: {
    en: 'DD.MM.YYYY',
    de: 'TT.MM.JJJJ',
  },
  yearPlaceholder: {
    en: 'YYYY',
    de: 'JJJJ',
  },
  dayMonthPlaceholder: {
    en: 'DD.MM',
    de: 'TT.MM',
  },
  dateTooFarInFutureMessage: {
    en: 'The booking date you specified is far in the future.',
    de: 'Das von Ihnen festgelegte Buchungsdatum liegt weit in der Zukunft.',
  },
  dateTooFarInPastMessage: {
    en: 'The booking date you specified is far in the past.',
    de: 'Das von Ihnen festgelegte Buchungsdatum liegt weit in der Vergangenheit.',
  },
  dateTooFarDescription: {
    en: 'As this is unusual we ask you to please double-check and adapt if necessary before executing.',
    de: 'Da dies unüblich ist bitten wir Sie, das zu überprüfen und falls notwendig vor der Ausführung anzupassen.',
  },
};
