import { useContext } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { Link } from 'react-router-dom';
import PopoverTruncatingText
  from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import {
  AssignButton,
} from 'storybook-components/TicketIntegrationSection/components/AssignButton/AssignButton';
import {
  ExternalPropertyIssue,
  TICKET_COLUMN_TYPE,
  TicketColumnProps,
} from 'storybook-components/TicketIntegrationSection/interfaces';

import { translations } from '../../translations';

const PROPERTY_COLUMNS = ['name', 'description'];
const CONTACT_COLUMNS = ['name', 'description'];
const DOCUMENT_COLUMNS = ['name', 'assign'];

export const useEtg24TicketColumns = (props: TicketColumnProps) => {
  const { tl } = useContext(LanguageContext);

  const {
    type, isTicketAssigned, showCommentModal, showUnassignModal,
  } = props;
  const allColumns: ColumnsType<ExternalPropertyIssue> = [
    {
      key: 'name',
      title: tl(translations.etg24.ticket.name),
      ellipsis: true,
      render: data => (
        <Link
          to={{ pathname: data.url }}
          target="_blank"
          rel="noreferrer"
        >
          {data.name}
        </Link>
      ),
    },
    {
      key: 'description',
      title: tl(translations.etg24.ticket.description),
      ellipsis: true,
      dataIndex: 'description',
      render: (value: string) => (
        <PopoverTruncatingText
          containerClassName="etg24TicketDescription"
          value={value}
        />
      ),
    },
    {
      key: 'assign',
      title: '',
      render: issue => (
        <AssignButton
          issueId={issue.id}
          assigned={issue.assigned}
          hidden={!issue.assigned && isTicketAssigned}
          showModal={showCommentModal}
          unassignInvoice={showUnassignModal}
        />
      ),
      width: '13.0rem',
    },
  ];


  const getColumns = () => {
    switch (type) {
    case TICKET_COLUMN_TYPE.PROPERTY:
      return allColumns.filter(col => PROPERTY_COLUMNS.includes(col.key as string));
    case TICKET_COLUMN_TYPE.CONTACT:
      return allColumns.filter(col => CONTACT_COLUMNS.includes(col.key as string));
    case TICKET_COLUMN_TYPE.DOCUMENT:
    case TICKET_COLUMN_TYPE.INVOICE:
      return allColumns.filter(col => DOCUMENT_COLUMNS.includes(col.key as string));
    default:
      return allColumns;
    }
  };

  const columns = getColumns();

  return { columns };
};
