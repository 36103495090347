import { UnitContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { Tooltip } from 'antd';
import { Icon } from '@material-ui/core';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { Link } from 'react-router-dom';
import { ContractDataSource } from '../services/ContactPropertiesContext';
import { contactPropertiesTranslations } from '../translation';


const ContactPropertiesDunningLevelCell = ({ record } : {record: ContractDataSource}) => {
  const { tl } = useContext(LanguageContext);
  if (record?.dunningLevel !== UnitContractProjectionDtoDunningLevelEnum.EXCLUDED) {
    return null;
  }


  return (
    <Tooltip
      title={(
        <Link target="_blank" to={{ pathname: articleUrls.orderOpenBalances.excludedContract }}>
          {tl(contactPropertiesTranslations.propertyTable.tooltipText)}
        </Link>
      )}
    >
      {/* We need the div wrapper because otherwise the tooltip isn't triggered (icon means no content for Tooltip) */}
      <div style={{ display: 'flex' }}>
        <Icon component={ICONS.pause} />
      </div>
    </Tooltip>
  );
};

export default ContactPropertiesDunningLevelCell;
