import { getEnvVar } from 'lib/getEnvVar';


export const useAlertOnDashboard = () => {
  const title = getEnvVar('notification.title') as string;
  const identifier = getEnvVar('notification.identifier') as string;
  const description = getEnvVar('notification.description') as string;
  const descriptionList = getEnvVar('notification.descriptionList') as Array<string>;
  const readMoreLink = getEnvVar('notification.readMoreLink') as string;

  return {
    title,
    identifier,
    description,
    descriptionList,
    readMoreLink,
  };
};
