import { useContext } from 'react';

import {
  Filter,
} from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';

import { usePropertyAdministrationTypeFilterOption } from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';
import { GetMessagesUsingGETSourceTypeEnum, MessageListProjectionStateEnum } from '../../../../api/accounting';
import { messagesListTranslations } from '../translations';
import { MessagesListContext } from './MessagesListContext';

export const useMessagesListFilters = () => {
  const { tl } = useContext(LanguageContext);
  const messagesListContext = useContext(MessagesListContext);

  if (messagesListContext === undefined) {
    throw new Error('useMessagesListFilters must be used within MessagesListContextProvider');
  }

  const { filterState, setFilterState } = messagesListContext;
  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  const onChangeFilterState = (key: keyof any, value: any[keyof any]) => {
    setFilterState((prev) => {
      if (value === null) {
        return ({
          ...prev,
          [key]: undefined,
        });
      }
      return ({
        ...prev,
        [key]: value,
      });
    });
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  const filters: Filter[] = [
    {
      type: 'text',
      name: tl(messagesListTranslations.header.title),
      key: 'processName',
    },
    {
      type: 'number',
      name: tl(messagesListTranslations.header.sourceId),
      key: 'sourceId',
    },
    {
      type: 'enum',
      name: tl(messagesListTranslations.header.state),
      options: Object.keys(MessageListProjectionStateEnum).filter(s => s !== MessageListProjectionStateEnum.DRAFT).map(value => ({
        label: tl(messagesListTranslations.states[value]),
        value,
      })),
      multiValue: true,
      key: 'state',
    },
    {
      type: 'enum',
      name: tl(messagesListTranslations.filters.applicationName),
      // The list below shall reflect all apps from select * from app_app; that support message sending
      // future: load list dynamically when we have the endpoints/markers
      options: ['EPOST', 'facilioo', 'casavi', 'etg24', 'MANUAL'].map(value => ({
        label: value,
        value,
      })),
      key: 'applicationName',
    },
    {
      type: 'text',
      name: tl(messagesListTranslations.filters.propertyInternalId),
      key: 'propertyIdInternal',
    },
    {
      type: 'text',
      name: tl(messagesListTranslations.filters.propertyName),
      key: 'propertyName',
    },
    {
      type: 'text',
      name: tl(messagesListTranslations.filters.createdBy),
      key: 'createdBy',
    },
    {
      type: 'date',
      name: tl(messagesListTranslations.filters.minUpdated),
      key: 'minUpdateDate',
    },
    {
      type: 'date',
      name: tl(messagesListTranslations.filters.maxUpdated),
      key: 'maxUpdateDate',
    },
    {
      type: 'text',
      name: tl(messagesListTranslations.filters.businessPartnerName),
      key: 'businessPartnerName',
    },
    {
      type: 'enum',
      name: tl(messagesListTranslations.filters.processType),
      key: 'sourceType',
      multiValue: true,
      options: Object.keys(GetMessagesUsingGETSourceTypeEnum).map(value => ({
        label: tl(messagesListTranslations.filters.sourceTypes[value]),
        value,
      })),
    },
    {
      type: 'text',
      name: tl(messagesListTranslations.filters.contactId),
      key: 'contactId',
      visibleInSearchDropdown: false,
    },
    {
      type: 'text',
      name: tl(messagesListTranslations.filters.administrator),
      key: 'supervisorName',
    },
    {
      type: 'text',
      name: tl(messagesListTranslations.filters.accountant),
      key: 'accountantName',
    },
    administrationTypeFilterOption,
  ];

  return {
    filters,
    onSetDefaultFilterFromQueryParams,
    filterState,
    onChangeFilterState,
  };
};
