
export const translations = {
  tabTitles: {
    imports: {
      de: 'Importe',
      en: 'Imports',
    },
    connections: {
      de: 'Bankverbindungen',
      en: 'Connections',
    },
  },
};
