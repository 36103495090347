import { useContext } from 'react';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from '../translations';
import { ContactLegacyDtoTypeEnum } from '../../../api/accounting/models';
import { EMAIL_MATCHER, IBAN_MOD97_PATTERN, PHONE_NUMBER_MATCHER } from '../../../lib/Utils';

export default function useContactFilters(type: ContactLegacyDtoTypeEnum): Filter[] {
  const { tl } = useContext(LanguageContext);

  const personFilters: Filter[] = [
    {
      type: 'text',
      name: tl(translations.personList.filters.name),
      key: 'name',
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.email),
      key: 'email',
      matcher: EMAIL_MATCHER,
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.phoneBusiness),
      key: 'phoneBusiness',
      matcher: PHONE_NUMBER_MATCHER,
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.phoneMobile),
      key: 'phoneMobile',
      matcher: PHONE_NUMBER_MATCHER,
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.phonePrivate),
      key: 'phonePrivate',
      matcher: PHONE_NUMBER_MATCHER,
    },
  ];

  const companyFilters: Filter[] = [
    {
      type: 'text',
      name: tl(translations.personList.filters.name),
      key: 'name',
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.vatId),
      key: 'vatId',
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.tradeRegisterNumber),
      key: 'tradeRegisterNumber',
    },
  ];

  const contactFilters: Filter[] = [
    {
      type: 'text',
      name: tl(translations.personList.filters.street),
      key: 'street',
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.number),
      key: 'number',
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.city),
      key: 'city',
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.postalCode),
      key: 'postalCode',
      matcher: /^\d+$/,
    },
    {
      type: 'text',
      name: tl(translations.personList.filters.iban),
      key: 'iban',
      matcher: IBAN_MOD97_PATTERN,
    },
  ];

  switch (type) {
    case ContactLegacyDtoTypeEnum.PERSON:
      return personFilters.concat(contactFilters);
    case ContactLegacyDtoTypeEnum.COMPANY:
      return companyFilters.concat(contactFilters);
    case ContactLegacyDtoTypeEnum.MANAGEMENT_COMPANY:
      return companyFilters.concat(contactFilters);
    default:
      return contactFilters;
  }
}
