import { Form, Input } from 'antd';
import React from 'react';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import { ValidateStatuses } from 'lib/Utils';
import './PasswordInput.scss';

export interface PasswordInputProps {
  onChange: (value: string) => void,
  label?: string,
  required?: boolean,
  infoText?: string,
  disabled?: boolean,
  validationState?: typeof ValidateStatuses[number]
  validationMessage?: string
}

export const PasswordInput = ({
  onChange: onChangeProp, label, required, infoText, disabled, validationState, validationMessage,
}: PasswordInputProps) => {
  const onChange = (event: any) => {
    const { value } = event.target;
    onChangeProp(value);
  };

  return (
    <div className="PasswordInput">
      <Form.Item
        label={label ? (
          <span>
            <span>{`${label}${required ? ' *' : ''}`}</span>
            {infoText && <InfoIcon popupText={infoText} size="small" />}
          </span>
        ) : null}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <Input.Password className="Input" onChange={onChange} autoComplete="new-password" />
      </Form.Item>
    </div>
  );
};

PasswordInput.defaultProps = {
  label: undefined,
  required: false,
  infoText: undefined,
  disabled: false,
  validationState: undefined,
  validationMessage: undefined,
};
