import React, { useContext } from 'react';
import {
  Col, Row,
} from 'antd';
import { BankConnection } from 'api/accounting';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { SPACE_SIZES } from 'lib/constants';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import { translations } from '../../services/translations';
import useBankConnections from '../../services/useBankConnections';
import './BankConnectionModal.scss';

interface BankConnectionUpdateModalProps {
  onClose: Function,
  selectedBankConnection: BankConnection,
}

export default function BankConnectionUpdateModal(props: BankConnectionUpdateModalProps): JSX.Element {
  const {
    onClose, selectedBankConnection,
  } = props;
  const { tl } = useContext(LanguageContext);
  const { updateBankConnection, updatingBankConnection } = useBankConnections();

  return (
    <ModalWithSubtitle
      title={tl(translations.updateConnectionModalTitle)}
      subtitle={selectedBankConnection?.name}
      className="BankConnectionUpdateModal"
      visible={!!selectedBankConnection}
      onCancel={() => onClose()}
      footer={[
        <Button onClick={() => onClose()} type="text" key="cancel">
          {tl(translations.bankConnectionActions.updateModal.cancel)}
        </Button>,
        <Button onClick={async () => { await updateBankConnection(selectedBankConnection.id, true); onClose(); }} type="default" key="syncimport" loading={updatingBankConnection}>
          {tl(translations.bankConnectionActions.updateModal.synchronize)}
        </Button>,
        <Button onClick={async () => { await updateBankConnection(selectedBankConnection.id, false); onClose(); }} type="primary" key="sync" loading={updatingBankConnection}>
          {tl(translations.bankConnectionActions.updateModal.import)}
        </Button>,
      ]}
    >
      <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.large]}>
        <Col span={24}>
          <div dangerouslySetInnerHTML={{ __html: tl(translations.bankConnectionActions.updateModal.help) }} />
        </Col>
        <Col span={24}>
          <Button
            className="how-to-button"
            type="text"
            target="blank"
            href={articleUrls.bankConnections.finApi}
            icon={<Icon component={ICONS.howToIcon!} />}
          >

            {tl(translations.documentationLink)}
          </Button>
        </Col>

      </Row>

    </ModalWithSubtitle>
  );
}
