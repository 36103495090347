import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import Icon from '@ant-design/icons';
import { Form, Select } from 'antd';
import './SmartSearch.scss';
import _ from 'lodash';
import { ICONS } from '../../../components/icons';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import InputProps from '../InputProps';
import { InfoIcon } from '../../../storybook-components/InfoIcon/InfoIcon';
import { getParentScrollElement } from '../../../lib/Utils';

const { Option } = Select;

export interface OptionInterface {
  label: string,
  value: any,
  simpleLabel?: string,
}

export interface SmartSearchAddButton {
  label: string,
  onClick: () => void
}

interface smartSearchProps extends InputProps<any> {
  optionClassName?: string,
  options: OptionInterface[], // options to show
  searchFunction: (searchValue: string) => void, // function that sets the options based on the search string (probably BE call)
  getOneFunction?: (id: string | number) => void, // function that loads the already selected option based on the value received
  valueKey?: string, // key of the value (if the value is an object, ex: contact object has id field => valueKey = 'id')
  transformFunction?: (hrId: string) => void, // function that returns the whole object based on the selected option's human readable id
  loading?: boolean, // options is being loaded
  delay?: number, // delayed time between searches
  editLabel?: string,
  noPopupContainer?: boolean,
  addButtons?: SmartSearchAddButton[],
  allowClear?: boolean,
}

export default function SmartSearch(props: smartSearchProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const defaultProps = {
    delay: 500,
    addButtons: [],
    placeholder: tl(translations.elements.smartSearch.placeholder),
    editLabel: tl(translations.elements.smartSearch.edit),
    allowClear: true,
  };

  props = {
    ...defaultProps,
    ...props,
  };

  const {
    className, label, required, inputClassName, disabled, validationState, validationMessage, showPlaceholderWhenDisabled, getOneFunction, valueKey,
    searchFunction, onChange, transformFunction, delay, optionClassName, addButtons, loading, options, autoFocus, infoText, allowClear,
  } = props;
  const timer = useRef<number>();


  const [addButtonSelected, setAddButtonSelected] = useState(false);

  useEffect(() => {
    if (!!getOneFunction && !!props.value) {
      if (valueKey) {
        getOneFunction(props.value[valueKey]);
      } else {
        getOneFunction(props.value);
      }
    } else searchFunction('');
  }, [props.value]);

  const [searchedText, setSearchedText] = useState(''); // value that has been searched for
  let currentSearchValue = ''; // current value of the field

  const addButtonLabels = addButtons?.map(ab => ab.label) || [];

  const onSelect = (selectedOption: any) => {
    if (_.findIndex(addButtonLabels, l => _.isEqual(l, selectedOption)) === -1) {
      setAddButtonSelected(false);
      if (transformFunction) {
        onChange(transformFunction(selectedOption));
      } else {
        onChange(selectedOption);
      }
    } else {
      setAddButtonSelected(true);
    }
    setSearchedText('');
  };

  const onSearch = (value: string) => {
    currentSearchValue = value;

    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      if (currentSearchValue === value && searchedText !== value) {
        searchFunction(value);
        setSearchedText(value);
      }
    }, delay);
  };

  const value = (!!props.value && !!valueKey) ? props.value[valueKey] : props.value;
  let placeholder = props.placeholder ? props.placeholder : tl(translations.elements.smartSearch.placeholder);
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }

  const noPopupContainer = props.noPopupContainer ? props.noPopupContainer : false;
  const smartSearchElement = useRef(null);
  return (
    <div className={`SmartSearch ${className}`} ref={smartSearchElement}>
      <div className="select-div">
        <Form.Item
          label={(
            <span>
              <span>{`${label}${required ? ' *' : ''}`}</span>
              {infoText && <InfoIcon popupText={infoText} size="small" />}
            </span>
          )}
          validateStatus={disabled ? '' : (validationState || 'success')}
          help={disabled ? '' : (
            <span className="validation-message">
              {validationMessage}
            </span>
          )}
        >
          <Select
            className={`Select ${inputClassName || ''} ${disabled ? 'read-only' : ''}`}
            placeholder={placeholder}
            onSearch={onSearch}
            onSelect={onSelect}
            onChange={(v: any) => {
              typeof v === 'undefined' && onChange(null);
            }}
            value={addButtonSelected ? null : value}
            allowClear={allowClear}
            showSearch
            optionLabelProp="title"
            loading={loading}
            autoClearSearchValue={false}
            autoFocus={autoFocus}
            filterOption={false}
            showAction={['focus']}
            disabled={disabled || false}
            suffixIcon={<Icon component={ICONS.triangleDown} />}
            {...(!noPopupContainer && { getPopupContainer: (): HTMLElement => getParentScrollElement(smartSearchElement.current) })}
          >
            {addButtons!.map(addButton => (
              <Option
                className={`add-option ${optionClassName}`}
                key={addButton.label}
                value={addButton.label}
              >
                <div className="select-menu-item">
                  <div
                    role="button"
                    tabIndex={0}
                    className="smart-search-add-option-label"
                    onClick={addButton.onClick}
                  >
                    <Icon component={ICONS.plus} className="smart-search-add-icon" />
                    {addButton.label}
                  </div>
                </div>
              </Option>
            ))}
            {options && options.map(op => (
              <Option
                className={optionClassName}
                key={op.value}
                value={op.value}
                title={op.simpleLabel ? op.simpleLabel : op.label}
              >
                <div className="select-menu-item">
                  {op.label}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
}
