import { useContext, useEffect } from 'react';
import usePropertyOptions from 'services/usePropertyOptions';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { FindFilteredPropertiesUsingGETAdministrationTypesEnum } from 'api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { ownersMeetingInvitationTranslations } from '../translations/OwnersMeetingInvitationTranslations';
import defaultSection from './DefaultSection';
import useCoverLetterSubsection from './ShippingSection/subsections/coverLetterSubsection/useCoverLetterSubsection';
import { OwnersMeetingInvitationContext } from '../../../../contexts/OwnersMeetingInvitationContext';
import { PropertyListContext } from '../../../../contexts/PropertyListContext';
import { OwnersMeetingProtocolDtoStatusEnum } from '../../../../api/accounting';

export default function useTypeSection({ index, setDirty }: any) {
  const { tl } = useContext(LanguageContext);
  const { propertyOptions, searchForProperty, onLoadProperty } = usePropertyOptions(true);
  const { ownersMeeting } = useContext(OwnersMeetingInvitationContext);
  const { setSelectedDisplayPropertyId } = useContext(PropertyListContext);
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const { goBack } = useContext(OverlayContext);
  const isSent = !ownersMeeting.data || [OwnersMeetingProtocolDtoStatusEnum.SENT, OwnersMeetingProtocolDtoStatusEnum.DONE].includes(ownersMeeting.data.status);


  useEffect(() => {
    const { propertyId } = ownersMeeting.data;
    setSelectedDisplayPropertyId(propertyId);

    if (propertyId === undefined) return;

    const matchingPropertyOption = propertyOptions.find(po => po.value === propertyId)!;

    if (matchingPropertyOption === undefined) {
      // it is being loaded
      return;
    }

    const { propertyHrId } = matchingPropertyOption;

    checkPropertyValidity({ propertyHrId: propertyHrId!, onCancel: () => { setDirty(false); goBack(); } });
  }, [ownersMeeting.data.propertyId, propertyOptions]);
  return {
    ...defaultSection(index, index),
    sectionTitle: tl(ownersMeetingInvitationTranslations.editPage.sections.typeSection.title),
    sectionId: 'MeetingTypeSection',
    content: [
      {
        sectionId: 'typeAndProperty',
        title: '',
        content: [
          [
            {
              type: 'smartSearch',
              key: 'propertyId',
              props: {
                required: true,
                label: tl(ownersMeetingInvitationTranslations.editPage.sections.typeSection.fields.property),
                searchFunction: (filter) => { searchForProperty(filter, [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG]); },
                getOneFunction: onLoadProperty,
                options: propertyOptions,
                // if the ETV is already saved(has id) property change should not be possible
                disabled: isSent || (!!ownersMeeting.data.id && !!ownersMeeting.data.propertyId),
              },
            },
            {
              type: 'radioGroup',
              key: 'meetingType',
              props: {
                label: tl(ownersMeetingInvitationTranslations.editPage.sections.typeSection.fields.type),
                options: [
                  {
                    value: 'REGULAR',
                    label: tl(ownersMeetingInvitationTranslations.editPage.sections.typeSection.fields.typeOptions.regular),
                  },
                  {
                    value: 'EXTRAORDINARY',
                    label: tl(ownersMeetingInvitationTranslations.editPage.sections.typeSection.fields.typeOptions.extraordinary),
                  },
                ],
                disabled: isSent,
                required: true,
              },
            },
          ],
        ],
      },
      ...useCoverLetterSubsection({ setDirty }),
    ],
  };
}
