import { DirectDebitMandateDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';


type Props = {
  children: React.ReactNode;
};


export interface ExtendedDirectDebitMandateDto extends DirectDebitMandateDto {
  isActive: boolean,
}


export interface DirectDebitMandateListParamsCache {
  bankAccountIds: number[];
  unitContractIds?: number[];
  propertyIds: number[];
}


export const DirectDebitMandateListContext = createContext<{
  directDebitMandateList: DefaultDataInterface<ExtendedDirectDebitMandateDto[]>,
  setDirectDebitMandateList: React.Dispatch<React.SetStateAction<DefaultDataInterface<ExtendedDirectDebitMandateDto[]>>>,
  paramOfCachedValue: DirectDebitMandateListParamsCache | undefined,
  setParamOfCachedValue: React.Dispatch<React.SetStateAction<DirectDebitMandateListParamsCache | undefined>>,
  paramOfFetchInProgress: DirectDebitMandateListParamsCache | undefined,
  setParamOfFetchInProgress: React.Dispatch<React.SetStateAction<DirectDebitMandateListParamsCache | undefined>>,
} | undefined>(undefined);


const DirectDebitMandateListContextProvider = ({ children }: Props) => {
  const [directDebitMandateList, setDirectDebitMandateList] = useState(DEFAULT_DATA<ExtendedDirectDebitMandateDto[]>([]));
  const [paramOfCachedValue, setParamOfCachedValue] = useState<DirectDebitMandateListParamsCache | undefined>(undefined);
  const [paramOfFetchInProgress, setParamOfFetchInProgress] = useState<DirectDebitMandateListParamsCache | undefined>(undefined);


  // putting them in objects so they are easier to destructure when using the contexts
  // no need to memoize since they're all in the same object
  const providerValue = {
    directDebitMandateList,
    setDirectDebitMandateList,
    paramOfCachedValue,
    setParamOfCachedValue,
    paramOfFetchInProgress,
    setParamOfFetchInProgress,
  };

  return (
    <DirectDebitMandateListContext.Provider value={providerValue}>
      {children}
    </DirectDebitMandateListContext.Provider>
  );
};

export default DirectDebitMandateListContextProvider;
