import { useContext } from 'react';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { showNotification } from '../../../../lib/Notification';
import { useSaveEconomicPlan } from '../../services/useSaveEconomicPlan';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { useSaveHouseMoney } from '../../components/HouseMoneyTable/services/useSaveHouseMoney';
import { useSectionManager } from '../../../../services/SectionManager/useSectionManager';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export const useSaveUnitFiguresEconomicPlan = () => {
  const { tl } = useContext(LanguageContext);
  const { dirty } = useSimpleDirtModal();

  const {
    saveEconomicPlan,
    canSave: canSaveEconomicPlan,
  } = useSaveEconomicPlan();

  const {
    saveHouseMoneyAmounts,
    canSave: canSaveHouseMoney,
  } = useSaveHouseMoney();

  const { onOpenNextSection } = useSectionManager();

  // TODO now that we have sections we should refactor save to only send data from one section to BE
  const save = () => (
    saveEconomicPlan()
      .then(saveHouseMoneyAmounts)
      .then((savedEconomicPlan) => {
        showNotification({
          key: 'saveHouseMoneySuccess',
          message: tl(economicPlanTranslations.notifications.saveSuccess.message),
          type: 'success',
        });
        return savedEconomicPlan;
      })
  );

  const saveAndOpenNextSection = () => save()
    .then((savedEconomicPlan) => {
      onOpenNextSection();
      return savedEconomicPlan;
    });

  return {
    canSave: canSaveHouseMoney && canSaveEconomicPlan && dirty,
    save,
    saveAndOpenNextSection,
  };
};
