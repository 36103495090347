import React, { useContext, useEffect, useMemo } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { navigateConcat } from 'navigation/History';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { formatAddress } from 'lib/Utils';
import { ContactListContext } from 'contexts/ContactListContext';
import { useCompanyListColumns } from './UseCompanyListColumns';
import MainPageHeader from '../../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../../../services/useSiteMap/useSiteMap';
import useContactFilters from '../../services/UseContactFilters';
import { ContactLegacyDtoTypeEnum } from '../../../../api/accounting/models';


export default function CompanyList(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const contactListContext: any = useContext(ContactListContext);

  useGlobalSearch({
    key: 'companies',
    filterProps: {
      availableFilters: useContactFilters(ContactLegacyDtoTypeEnum.COMPANY),
      setFilter: (key: string, value: string) => contactListContext.setCompanyListFilters(
        (currentFilter: any) => ({
          ...currentFilter,
          [key]: value,
        }),
      ),
    },
    queryParamAsFilter: {
      filterState: contactListContext.companyListFilters,
      onSetDefaultFilterFromQueryParams: contactListContext.updateCompanyListFilters,
    },
  });

  useEffect(() => () => {
    contactListContext.onClearCompanyListFilters();
  }, []);

  const dataSource = useMemo(() => (contactListContext.companyListState && contactListContext.companyListState.data ? contactListContext.companyListState.data.map((company: any) => ({
    ...company,
    numberOfBankAccounts: company.bankAccounts.length,
    numberOfEmployees: company.linkedByContacts.length,
    address: company.addresses.map(formatAddress).reduce((accumulator: string, current: string) => `${accumulator}\n${current}`, '').substr(1),
  })) : []), [contactListContext.companyListState.data]);

  const smartTable = useSmartTable(
    {
      tableName: 'companyList',
      columns: useCompanyListColumns(),
      dataSource,
      contentLoading: contactListContext.companyListState.loading,
      onRow: (record: any) => ({
        onClick: () => {
          navigateConcat(`/view-company/${record.id}`);
        },
      }),
      infiniteScrollerProps: {
        hasMoreData: !contactListContext.companyListState.lastPage,
        loadMoreData: contactListContext.onLoadCompanyList,
      },
      rowKey: 'id',
    },
  );

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'contactCompany',
    navItems: subcategorySwitcherItems.contacts,
  };

  return (
    <div className="CompanyList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button
            type="primary"
            onClick={() => navigateConcat('/create-company')}
          >
            {tl(translations.pages.contacts.company.add)}
          </Button>
        )}
      />
      <SmartTable {...smartTable} />
    </div>
  );
}
