import { useContext } from 'react';
import { translations } from '../../../../elements/Translation/translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';

export const useEconomicPlanPropertyListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.property.table.headers.propertyHrId),
      dataKey: 'propertyHrId',
    },
    {
      title: tl(translations.pages.property.table.headers.internalNr),
      dataKey: 'propertyIdInternal',
    },
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'name',
    },
    {
      title: tl(translations.pages.distributionKeyTable.table.headers.administrator),
      dataKey: 'supervisorName',
      sortable: false,
    },
  ];
};
