export const translations: any = {
  title: {
    en: 'Agenda',
    de: 'Tagesordnung',
  },
  detailsTitle: {
    en: 'TOP',
    de: 'TOP',
  },
  sections: {
    details: {
      en: 'Details',
      de: 'Details',
    },
    vote: {
      en: 'Voting results',
      de: 'Abstimmungsergebnis',
    },
  },
  fields: {
    addTopic: {
      en: 'Add Agenda item',
      de: 'Tagesordnungspunkt',
    },
    templateSelector: {
      en: 'Select template',
      de: 'Vorlage auswählen',
    },
    title: {
      en: 'Title',
      de: 'Titel',
    },
    description: {
      en: 'Description',
      de: 'Beschreibung',
    },
    resolutionRecordText: {
      en: 'Resolution record text',
      de: 'Beschlussfassungstext',
    },
  },
  deleteButton: {
    en: 'Delete',
    de: 'Löschen',
  },
  agendaItemsTable: {
    table: {
      title: {
        en: 'Title',
        de: 'Titel',
      },
      topicNr: {
        en: 'Top-Nr.',
        de: 'Top-Nr.',
      },
      voteStatus: {
        title: {
          en: 'Vote status',
          de: 'Beschlussstatus',
        },
        statusOptions: {
          ACCEPTED: {
            en: 'Accepted',
            de: 'Angenommen',
          },
          DECLINED: {
            en: 'Declined',
            de: 'Abgelehnt',
          },
          DRAFT: {
            en: 'Draft',
            de: 'Entwurf',
          },
          NOT_DECIDED: {
            en: 'Not decided',
            de: 'Nicht abgestimmt',
          },
        },

      },
    },
    addAgendaItem: {
      en: 'Add Agenda Item',
      de: 'Agendapunkt hinzufügen',
    },
    noItems: {
      en: 'No agenda items created. \nPlease use the button above to add an agenda item.',
      de: 'Bisher wurden keine Agendapunkte erstellt.\n Fügen Sie Agendapunkte über den Button oben hinzu., um einen Tagesordnungspunkt hinzuzufügen.',
    },
    loadAgendaItemsError: {
      en: 'Failed to load agenda item list.',
      de: 'Liste der Tagesordnungspunkte konnte nicht geladen werden.',
    },
  },
  notifications: {
    deleteSuccess: {
      en: 'Agenda item deleted successfully',
      de: 'Tagesordnungspunkt wurde erfolgreich gelöscht',
    },
    deleteError: {
      en: 'Cannot delete agenda item',
      de: 'Tagesordnungspunkt kann nicht gelöscht werden',
    },
    fieldValidationWarning: {
      en: 'Cannot save agenda item with missing or invalid fields.',
      de: 'Tagesordnungspunkt mit fehlenden oder ungültigen Feldern konnte nicht gespeichert werden.',
    },
  },
  agendaItemDetails: {
    title: {
      en: 'Agenda Item',
      de: 'Tagesordnungspunkt',
    },
    typeField: {
      en: 'Type',
      de: 'Art',
    },
    placeholder: {
      en: 'Please select...',
      de: 'Bitte auswählen...',
    },
    header: {
      saveAndClose: {
        en: 'Save & Close',
        de: 'Speichern & Schließen',
      },
      saveAndOpenNext: {
        en: 'Save & Open next',
        de: 'Speichern & Nächsten öffnen',
      },
      saveAndCreateNew: {
        en: 'Save & Create further',
        de: 'Speichern & weiteren anlegen',
      },
      close: {
        en: 'Close',
        de: 'Schließen',
      },
    },
    typeOptions: {
      proposal: {
        en: 'Proposal',
        de: 'Beschluss',
      },
      pointOfOrder: {
        en: 'Point of order',
        de: 'Geschäftsordnungsbeschluss',
      },
      discussionTopic: {
        en: 'Discussion topic without resolution',
        de: 'Diskussion ohne Beschluss',
      },
    },
    topDetailsTitle: {
      en: 'TOP Details',
      de: 'Details',
    },
    notifications: {
      loadError: {
        message: {
          en: 'Agenda item loading error',
          de: 'Fehler beim Laden des Tagesordnungspunkts',
        },
        description: {
          en: 'Could not load agenda item',
          de: 'Tagesordnungspunkt konnte nicht geladen werden',
        },
      },
      saveSuccess: {
        message: {
          en: 'Successfully Saved',
          de: 'Erfolgreich gespeichert',
        },
      },
      saveError: {
        message: {
          en: 'Agenda item could not be saved.',
          de: 'Tagesordnungspunkt konnte nicht gespeichert werden.',
        },
      },
      deleteSuccess: {
        en: 'Agenda item deleted successfully',
        de: 'Tagesordnungspunkt wurde erfolgreich gelöscht',
      },
      deleteError: {
        en: 'Cannot delete agenda item',
        de: 'Tagesordnungspunkt kann nicht gelöscht werden',
      },
      fieldValidationWarning: {
        en: 'Cannot save agenda item with missing or invalid fields.',
        de: 'Tagesordnungspunkt mit fehlenden oder ungültigen Feldern konnte nicht gespeichert werden.',
      },
    },
  },
};
