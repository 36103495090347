import { debounce } from 'lodash';
import { useContext } from 'react';
import { ServiceCompanyAllocationUpdatersContext } from './ServiceCompanyAllocationContext';

const DEBOUNCE_WAIT_TIME = 200;


export const useSearchExchangeList = () => {
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);


  if (updatersContext === undefined) {
    throw new Error(
      'useSearchExchangeList must be used within a ServiceCompanyAllocationContextProvider',
    );
  }


  const { setSearchString } = updatersContext;


  const onSearch = debounce((searchInput: string) => {
    setSearchString(searchInput);
  }, DEBOUNCE_WAIT_TIME);


  return { onSearch };
};
