import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { PropertyListContext } from '../../../contexts/PropertyListContext';
import SmartTable from '../../../elements/SmartTable/SmartTable';
import './EconomicPlanList.scss';
import useEconomicPlanList from './services/useEconomicPlanList';
import CreateEconomicPlanButton from './CreateEconomicPlanButton';

export default function EconomicPlanList(): JSX.Element {
  const { propertyHrId } = useParams<{ propertyHrId: string | undefined }>();
  const {
    selectedProperty,
    setSelectedPropertyHrId,
  } = useContext(PropertyListContext);
  const {
    smartTable,
  } = useEconomicPlanList();

  useEffect(() => {
    if (propertyHrId) {
      setSelectedPropertyHrId(propertyHrId);
    }
  }, [propertyHrId]);

  return (
    <Page className="EconomicPlanList">
      <PageHeader
        title={`${propertyHrId}${selectedProperty.data ? ` - ${selectedProperty.data.propertyIdInternal}` : ''}`}
        subtitle={<PropertyInfo property={selectedProperty.data} />}
        rightSideComponent={<CreateEconomicPlanButton />}
      />
      <PageContent className="table-wrapper">
        <SmartTable {...smartTable} />
      </PageContent>
    </Page>
  );
}
