import { Form } from 'antd';
import { useInviteUsersCell } from 'pages/Users/InviteUsers/components/InviteUsersCell/useInviteUsersCell';
import React from 'react';
import { PasswordInput } from 'elements/Inputs/PasswordInput/PasswordInput';
import TextInput from 'elements/Inputs/TextInput/TextInput';

interface InviteUsersCellProps {
  id: string,
  type: 'email' | 'password',
}

export const InviteUsersCell = ({ id, type }: InviteUsersCellProps) => {
  const { onChange, validationState } = useInviteUsersCell(id, type);

  const Component = type === 'password' ? PasswordInput : TextInput;

  return (
    <Form.Item validateStatus={validationState}>
      <Component onChange={onChange} validationState={validationState} />
    </Form.Item>
  );
};
