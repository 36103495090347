import React, { useContext } from 'react';

import {
  Col,
  Row,
} from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { PasswordInput } from 'elements/Inputs/PasswordInput/PasswordInput';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { SPACE_SIZES } from 'lib/constants';
import {
  useUserDetails,
} from 'pages/Users/UserDetails/services/useUserDetails';

import Page from 'storybook-components/layout/Page/Page';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import Button from '../../../elements/Buttons/Button/Button';
import TextInput from '../../../elements/Inputs/TextInput/TextInput';
import { userDetailsTranslations } from './userDetailsTranslations';

export const UserDetails = () => {
  const { tl } = useContext(LanguageContext);
  const {
    save, goBack, email, onChangePassword, canSave, validationState, roleOptions, onChangeRole, roleId, showDeleteUserModal, loading,
  } = useUserDetails();
  return (
    <Page>
      <PageHeader
        title={tl(userDetailsTranslations.header.title)}
        showLogo
        rightSideComponent={(
          <>
            <Button
              type="text"
              onClick={goBack}
            >
              {tl(userDetailsTranslations.header.buttons.close)}
            </Button>
            <Button
              type="ghost"
              onClick={showDeleteUserModal}
              loading={loading}
            >
              {tl(userDetailsTranslations.header.buttons.delete)}
            </Button>
            <Button
              type="primary"
              onClick={save}
              loading={loading}
              disabled={!canSave}
            >
              {tl(userDetailsTranslations.header.buttons.save)}
            </Button>
          </>
        )}
      />
      <PageContent>
        <PrimarySectionWrapper
          sectionId="coreInformation"
          sectionIcon={ICONS.contacts()}
          sectionTitle={tl(userDetailsTranslations.section.title)}
        >
          <Row gutter={[SPACE_SIZES.large, SPACE_SIZES.large]}>
            <Col span={8}>
              <TextInput onChange={() => { }} label="Email" disabled value={email} />
            </Col>
            <Col span={8}>
              <PasswordInput onChange={onChangePassword} label={tl(userDetailsTranslations.password)} validationState={validationState} infoText={tl(userDetailsTranslations.passwordHint)} />
            </Col>
            <Col span={8}>
              <SelectInput options={roleOptions} onChange={onChangeRole} label={tl(userDetailsTranslations.role)} value={roleId} />
            </Col>
          </Row>
        </PrimarySectionWrapper>
      </PageContent>
    </Page>
  );
};
