import { getContractEditingRoute, getPropertyEditingRoute } from 'pages/Property/routes';


export const useContactPropertiesNameCell = (record) => {
  const getUrl = () => {
    if (record?.children) {
      return getPropertyEditingRoute(record.propertyHrId);
    }
    return getContractEditingRoute({ propertyHrId: record.propertyHrId, unitDbId: record?.unitId, contractDbId: record?.unitContractId });
  };


  return { url: getUrl() };
};
