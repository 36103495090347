import {
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { templateAgendaItems } from 'contexts/util/AgendaTemplateItem';
import {
  useHistory,
  useLocation,
} from 'react-router';
import { useParams } from 'react-router-dom';

import {
  AgendaItemDto,
  AgendaItemDtoAgendaItemTypeEnum,
  AgendaItemsControllerApi,
  AgendaItemUpdateDtoAgendaItemTypeEnum,
} from '../../../../../../api/accounting';
import {
  AgendaItemsContext,
} from '../../../../../../contexts/AgendaItemsContext';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { showNotification } from '../../../../../../lib/Notification';
import {
  OverlayContext,
} from '../../../../../../services/OverlayContext/OverlayContext';
import {
  ownersMeetingInvitationTranslations,
} from '../../../translations/OwnersMeetingInvitationTranslations';
import { AgendaItemDetailsContext } from './AgendaItemDetailsContext';

export const useAgendaItemDetails = () => {
  const { agendaItemId } = useParams<{agendaItemId:string}>();
  const { etvId } = useParams<{etvId:string}>();
  const selectedAgendaItemId = parseInt(agendaItemId, 10);
  const ownersMeetingInvitationId = parseInt(etvId, 10);

  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);
  const { apiConfiguration } = useContext(AuthContext);
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));

  const agendaItemsContext = useContext(AgendaItemsContext);
  if (agendaItemsContext === undefined) {
    throw new Error('useAgendaItemDetails must be used within a AgendaItemsContextProvider');
  }

  const agendaItemDetailsContext = useContext(AgendaItemDetailsContext);
  if (agendaItemDetailsContext === undefined) {
    throw new Error('useAgendaItemDetails must be used within a AgendaItemDetailsContextProvider');
  }
  const {
    selectedAgendaItem, setSelectedAgendaItem, setDirty, dirty, setDirtModalVisible, dirtModalVisible,
  } = agendaItemDetailsContext;
  const location = useLocation();
  const history = useHistory();
  const { agendaItemsList } = agendaItemsContext;

  const nextAgendaItem = useMemo(() => (selectedAgendaItem ? agendaItemsList.data?.find(ai => ai?.index > selectedAgendaItem?.data?.index) : undefined), [selectedAgendaItem.data]);

  const onClickSaveAndOpenNext = () => {
    if (!nextAgendaItem) {
      return;
    }
    onSaveAgendaItem(() => {
      const nextAgendaItemUrl = location.pathname.replace(selectedAgendaItem.data.id.toString(), nextAgendaItem.id.toString());
      history.push(nextAgendaItemUrl);
    });
  };

  const onSaveAgendaItem = (callback?: ()=> void) => {
    const agendaItem = { ...selectedAgendaItem.data };
    if (agendaItem?.resolutionRecordText?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
      agendaItem.resolutionRecordText = agendaItem.resolutionRecordText.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
    }

    if (agendaItem?.comment?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
      agendaItem.comment = agendaItem.comment.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
    }
    const resp = location.pathname.includes('create') ? agendaItemsControllerApi.createAgendaItemForInvitationUsingPOST({
      agendaItemDto: { ...agendaItem, agendaItemType: agendaItem?.agendaItemType ? agendaItem?.agendaItemType : AgendaItemDtoAgendaItemTypeEnum.PROPOSAL },
      ownersMeetingInvitationId,
    })
      : agendaItemsControllerApi.updateAgendaItemUsingPUT({
        agendaItemUpdateDto: { ...agendaItem, index: agendaItem.index, agendaItemType: agendaItem.agendaItemType as unknown as AgendaItemUpdateDtoAgendaItemTypeEnum },
        agendaItemId: selectedAgendaItemId,
      });
    setSelectedAgendaItem(prev => prev.startLoading());
    resp.then((response: AgendaItemDto) => {
      setSelectedAgendaItem(prev => prev.load(response).finishLoading());
      showNotification({
        key: 'saveAgendaItemSuccess',
        message: tl(ownersMeetingInvitationTranslations.agendaItemDetails.notifications.saveSuccess.message),
        type: 'success',
      });
      setDirty(false);
      callback?.();
    }).catch((error) => {
      setSelectedAgendaItem(prev => prev.failed());
      if (error.response.status === 422) {
        showNotification({
          key: 'saveAgendaItemError',
          message: tl(ownersMeetingInvitationTranslations.agendaItemDetails.notifications.fieldValidationWarning),
          type: 'error',
        });
      } else {
        showNotification({
          key: 'saveAgendaItemError',
          message: tl(ownersMeetingInvitationTranslations.agendaItemDetails.notifications.saveError.message),
          type: 'error',
        });
      }
    });
  };

  const onClickClose = () => {
    if (dirty) {
      setDirtModalVisible(true);
    } else {
      goBack();
    }
  };

  useEffect(() => {
    if (selectedAgendaItemId) {
      setSelectedAgendaItem(prev => prev.resetToInitial().startLoading());
      agendaItemsControllerApi.getAgendaItemByIdUsingGET({ agendaItemId: selectedAgendaItemId })
        .then((response: any) => {
          setSelectedAgendaItem(prev => prev.load(response).finishLoading());
        })
        .catch(() => {
          setSelectedAgendaItem(prev => prev.failed());
          showNotification({
            key: 'loadAgendaItemError',
            message: tl(ownersMeetingInvitationTranslations.agendaItemDetails.notifications.loadError.message),
            description: tl(ownersMeetingInvitationTranslations.agendaItemDetails.notifications.loadError.description),
            type: 'warning',
          });
        });
    }
  }, [selectedAgendaItemId]);

  const onChange = (key: keyof AgendaItemDto, value: AgendaItemDto[keyof AgendaItemDto]) => {
    setSelectedAgendaItem(prev => prev.load({ ...prev.data, [key]: value }));
    setDirty(true);
  };

  const onChangeTemplate = (value:AgendaItemDto[keyof AgendaItemDto]) => {
    const templateItem = templateAgendaItems.find(item => item.label === value).value;
    setSelectedAgendaItem(prev => prev.load({ ...prev.data, title: templateItem.title, resolutionRecordText: templateItem.resolutionRecordText }));
    setDirty(true);
  };

  return {
    selectedAgendaItem,
    setSelectedAgendaItem,
    onSaveAgendaItem,
    onClickClose,
    onClickSaveAndOpenNext,
    nextAgendaItem,
    onChange,
    templateAgendaItems,
    loading: selectedAgendaItem?.loading,
    onChangeTemplate,
    dirty,
    setDirtModalVisible,
    dirtModalVisible,
    goBack,
  };
};
