import React from 'react';
import { Empty } from 'antd';
import './EmptyTableMultilineMessage.scss';
import Icon from '@ant-design/icons';

export interface EmptyTableMultilineMessageProps {
  description?: string | React.FC | JSX.Element;
  title?: string | React.ReactNode;
  icon?: React.FC;
  suffix?: React.ReactNode;
}

const EmptyTableMultilineMessage = ({
  description,
  title,
  icon,
  suffix,
}: EmptyTableMultilineMessageProps) => (
  <div className="EmptyTableMultilineMessageContainer">
    <Empty
      image={<Icon className="EmptyTableMessageIcon" component={icon} />}
      className="EmptyTableMultilineMessage"
      description={(
        <>
          {title && <div className="title">{title}</div>}
          <div className="description">{description}</div>
          {suffix && <div className="suffix">{suffix}</div>}
        </>
      )}
    />
  </div>
);


EmptyTableMultilineMessage.defaultProps = {
  description: undefined,
  title: undefined,
  icon: undefined,
  suffix: undefined,
};


export default EmptyTableMultilineMessage;
