import { ContactLegacyDtoTypeEnum } from 'api/accounting';

const BUILDING_START_INDEX = 4;


export const getSectionFromOpenIndex = (openSectionIndex: number, buildingSize: number): number => {
  // management data or buildings
  if (openSectionIndex <= BUILDING_START_INDEX) return openSectionIndex;

  // owners and further
  if (openSectionIndex >= BUILDING_START_INDEX + buildingSize) return openSectionIndex - buildingSize;

  return BUILDING_START_INDEX;
};

export const getBuildingIndex = (openSectionIndex: number, buildingSize: number) => {
  if (openSectionIndex <= BUILDING_START_INDEX || openSectionIndex > BUILDING_START_INDEX + buildingSize) return -1;

  return openSectionIndex - BUILDING_START_INDEX - 1;
};

export const removeLastPartOfUrl = (url: string, numberOfElementsToRemove: number = 1) => {
  const urlList = url.split('/');
  return urlList.slice(0, urlList.length - numberOfElementsToRemove).join('/');
};

export const getContactLabelName = (contact: any) => (contact != null ? `${contact.type === ContactLegacyDtoTypeEnum.PERSON ? `${contact.firstName} ${contact.lastName}` : contact.companyName}` : '');
export const getContactLastNameFirstName = (contact: any) => (contact != null ? `${contact.type === ContactLegacyDtoTypeEnum.PERSON ? `${contact.lastName} ${contact.firstName}` : contact.companyName}` : '');
