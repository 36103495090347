import React, { useContext } from 'react';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext, languages } from '../../contexts/LanguageContext';
import './LanguageSwitcher.scss';

export default function LanguageSwitcher(): JSX.Element {
  const { setLanguage, language } = useContext(LanguageContext);

  return (
    <div className="language-switcher">
      {Object.keys(languages).map(lang => (
        <Button
          className={`lang-button lang-button-${lang} ${language === lang ? 'selected' : ''}`}
          key={lang}
          onClick={() => setLanguage(lang)}
        >
          {lang}
        </Button>
      ))}
    </div>
  );
}
