import { useMemo, useState } from 'react';
import { useFeatureSettings } from 'lib/useFeatureSettings';
import moment from 'moment';
import { translations } from '../../Translation/translations';

interface usePaymentExecutionModalProps {
  onDownload: Function
  filter: any,
  numberOfTransactions?: number | null,
  amount?: number | null,
  basedOnSelection?: boolean,
  propertiesWithInsufficientFunds?: any,
  loading?: boolean,
  onExclude?: Function,
  excludedIbans?: string[],
  tl: Function,
  managementCompanyName?: string,
  executionDateWarning?: boolean,
  sendAmendMandateInfoToBank?: boolean,
  setSendAmendMandateInfoToBank?: Function,
  showMandateAmendmentCheckbox: boolean,
  executionDate?: moment.Moment,
}

export function useExecutionModal(props: usePaymentExecutionModalProps) {
  const {
    onDownload, filter, numberOfTransactions, amount, basedOnSelection,
    propertiesWithInsufficientFunds, loading, onExclude, excludedIbans,
    tl, managementCompanyName, executionDateWarning, setSendAmendMandateInfoToBank,
    sendAmendMandateInfoToBank, showMandateAmendmentCheckbox, executionDate
  } = props;

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleDownload = () => {
    setVisible(false);
    onDownload();
  };

  const handleCancel = () => {
    setVisible(false);
  };


  const criteria = useMemo(() => Object.keys(filter)
    .filter((key: string) => filter[key] && filter[key] !== '')
    .map((key: string) => {
      if (key === 'states' || key === 'paymentStates') {
        const val = filter[key].map((status: string) => tl(translations.pages.payment.table.paymentStates[status])).join(',');
        return {
          field: 'state',
          value: val,
        };
      }
      if (key === 'managementCompanyId') {
        return {
          field: 'managementCompany',
          value: managementCompanyName || '',
        };
      }
      return {
        field: key,
        value: filter[key].toString(),
      };
    }), [filter]);

  return {
    visible,
    showModal,
    handleDownload,
    handleCancel,
    criteria,
    numberOfTransactions,
    amount,
    basedOnSelection,
    propertiesWithInsufficientFunds,
    loading,
    onExclude,
    excludedIbans,
    executionDateWarning,
    sendAmendMandateInfoToBank,
    setSendAmendMandateInfoToBank,
    showMandateAmendmentCheckbox,
    executionDate,
  };
}
