import React, { useContext } from 'react';
import { Prompt, useLocation } from 'react-router';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { LanguageContext } from '../../contexts/LanguageContext';
import Button from '../../elements/Buttons/Button/Button';
import DirectDebitForm from './DirectDebitForm/DirectDebitForm';
import { translations } from './translations';
import useDirectDebitCreation from './useDirectDebitCreation';

export default function DirectDebitView(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    isDirty, onSaveDirectDebit, onChange, directDebit, onClearDirectDebit, loadOwners, ownerOptions, ownerBankAccountOptions,
  } = useDirectDebitCreation();

  const location = useLocation();

  const saveDirectDebitButton = (
    <Button
      type="primary"
      onClick={onSaveDirectDebit}
    >
      {tl(translations.header.create)}
    </Button>
  );

  return (
    <Page className="DirectDebitView">
      <Prompt
        when={isDirty}
        message={loc => (location.pathname === loc.pathname ? true : tl(translations.prompt))}
      />
      <PageHeader
        title={tl(translations.title)}
        rightSideComponent={saveDirectDebitButton}
      />
      <PageContent>
        <DirectDebitForm
          onChange={onChange}
          directDebit={directDebit}
          onClearDirectDebit={onClearDirectDebit}
          loadOwners={loadOwners}
          ownerOptions={ownerOptions}
          ownerBankAccountOptions={ownerBankAccountOptions}
        />
      </PageContent>
    </Page>
  );
}
