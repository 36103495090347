
import Icon from '@ant-design/icons/lib/components/Icon';
import { Alert } from 'antd';
import { ICONS } from 'components/icons';
import _ from 'lodash';
import { useWarningList } from './useWarningList';

const WarningList = ({ sectionNr } : { sectionNr: number }) => {
  const { alerts } = useWarningList(sectionNr);

  if (_.isEmpty(alerts)) return <span />;
  return (
    <>
      {alerts.map(a => (
        <Alert
          message={a.title}
          description={a.description}
          type="warning"
          showIcon
          icon={<Icon component={ICONS.warning} />}
        />
      ))}
    </>

  );
};

export default WarningList;
