import React, { useMemo, useState } from 'react';
import DirtModal from '../DirtModal';

export const DEFAULT_DIRT_MODAL_STATE = {
  visible: false,
  onSave: (cb?: () => void) => { cb?.(); },
  onDiscard: (cb?: () => void) => { cb?.(); },
};

interface Props {
  children: React.ReactChild | React.ReactChildren;
}

export interface DirtModalProps {
  visible: boolean,
  loading?: boolean,
  onSave: (callback: () => void) => void,
  onDiscard: (callback: () => void) => void,
  onProceedCallback?: () => void,
}

export const IsolatedSectionsDirtModalContext = React.createContext<{
  dirty: boolean,
  modalVisible: boolean,
  setDirty: React.Dispatch<React.SetStateAction<boolean>>,
  setModalProps: React.Dispatch<React.SetStateAction<DirtModalProps>>,
} | undefined>(undefined);


const IsolatedDirtModalContextProvider = ({ children }: Props) => {
  const [dirty, setDirty] = useState(false);
  const [modalProps, setModalProps] = useState<DirtModalProps>(DEFAULT_DIRT_MODAL_STATE);

  const providerValue = useMemo(() => ({
    dirty,
    modalVisible: modalProps.visible,
    setDirty,
    setModalProps,
  }), [dirty, modalProps.visible, setDirty, setModalProps]);


  const onCancel = () => setModalProps(prev => ({ ...prev, visible: false }));


  const onSave = () => modalProps.onSave(() => {
    modalProps.onProceedCallback?.();
    setModalProps(DEFAULT_DIRT_MODAL_STATE);
    setDirty(false);
  });


  const onDiscard = () => modalProps.onDiscard(() => {
    modalProps.onProceedCallback?.();
    setModalProps(DEFAULT_DIRT_MODAL_STATE);
    setDirty(false);
  });


  return (
    <IsolatedSectionsDirtModalContext.Provider value={providerValue}>
      {children}
      <DirtModal
        visible={modalProps.visible}
        onSave={onSave}
        onDiscard={onDiscard}
        onCancel={onCancel}
        loading={modalProps.loading}
      />
    </IsolatedSectionsDirtModalContext.Provider>
  );
};

export default IsolatedDirtModalContextProvider;
