export const economicPlanOverviewTranslations = {

  ownershipDues: {
    title: {
      en: 'New ownership dues',
      de: 'Neufestsetzung der Beiträge',
    },
    table: {
      headers: {
        name: {
          en: 'Name',
          de: 'Bezeichnung',
        },
        previous: {
          en: 'Current',
          de: 'Aktuelle',
        },
        newAmount: {
          en: 'New WP',
          de: 'Neu WP ',
        },
        difference: {
          en: 'Difference',
          de: 'Differenz',
        },
        due: {
          en: 'Due',
          de: 'Fällig',
        },
        monthly: {
          en: 'Monthly',
          de: 'Monatlich',
        },
        houseMoney: {
          en: 'House money',
          de: 'Hausgeld',
        },
        reserveMoney: {
          en: 'Reserve money',
          de: 'Rücklagenzuführung',
        },
      },
    },
  },

  unitResultsTable: {
    houseMoney: {
      en: 'Housemoney',
      de: 'Hausgeld',
    },
    headers: {
      unit: {
        en: 'Unit',
        de: 'Einheit',
      },
      owner: {
        en: 'Owner',
        de: 'Eigentümer',
      },
      account: {
        en: 'Subaccount',
        de: 'Unterkonto',
      },
      previousAmount: {
        en: 'Amount previous',
        de: 'Betrag bisher',
      },
      fromDate: {
        en: 'From date',
        de: 'Ab Datum',
      },
      newAmount: {
        en: 'Amount new',
        de: 'Betrag neu',
      },
      backPay: {
        en: 'Back pay(-) credit',
        de: 'Nachzahlung(-) Gutschrift',
      },
      subtotal: {
        en: 'Subtotal',
        de: 'ZwischenSumme',
      },
      total: {
        en: 'Total',
        de: 'Gesamt',
      },
    },
  },
};
