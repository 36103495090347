import { useContext } from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  useSimpleDirtModal,
} from 'elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';
import { isNil } from 'lodash';
import {
  PdfPageAssignerCurrentAssignmentsContext,
  PdfPageAssignerDataContext,
  PdfPageAssignerSelectedPagesContext,
  PdfPageAssignerUpdatersContext,
} from 'pages/PdfPageAssigner/services/PdfPageAssignerContext';

import { translations } from '../../../../translations';

const EMPTY_LABELS = { tagLabel: '', tooltipMessage: '' };


const getBorderType = (isSelected: boolean, showWarningForSelectedPages: boolean) => {
  if (isSelected && showWarningForSelectedPages) return 'border-warning';
  if (isSelected) return 'border-selected';
  return 'border-invisible';
};


export const usePdfAssignmentCheckboxWrapper = (documentId: number, pageNumber: number) => {
  const { tl } = useContext(LanguageContext);
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);
  const currentAssignments = useContext(PdfPageAssignerCurrentAssignmentsContext);
  const selectedPages = useContext(PdfPageAssignerSelectedPagesContext);

  const { addDirt } = useSimpleDirtModal();


  if (pdfPageAssignerDataContext === undefined || pdfPageAssignerUpdatersContext === undefined) {
    throw new Error('usePdfAssignmentCheckboxWrapper must be used within a PdfPageAssignerContextProvider');
  }


  const {
    viewMode,
    contractsWithinDateRange,
    contractsOutsideOfDateRange,
    property,
    loading,
    units,
    showWarningForSelectedPages,
  } = pdfPageAssignerDataContext;
  const { setSelectedPages, setCurrentAssignments } = pdfPageAssignerUpdatersContext;

  const assignmentOfThisPage = currentAssignments.find(ca => ca.originalDocumentId === documentId && ca.page === pageNumber);


  const isAssigned = Boolean(assignmentOfThisPage);
  const isSelected = Boolean(selectedPages.find(sp => sp.documentId === documentId && sp.pageNumber === pageNumber));
  const checked = isAssigned || isSelected;


  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    addDirt();
    // if it was not checked before then set it as a selected page
    if (e.target.checked) {
      setSelectedPages(prev => [...prev, { documentId, pageNumber }]);
      return;
    }

    // if it was an assignment then remove it from currentAssignments
    if (currentAssignments.find(ca => ca.originalDocumentId === documentId && ca.page === pageNumber)) {
      setCurrentAssignments(prev => prev.filter(ca => !(ca.originalDocumentId === documentId && ca.page === pageNumber)));
      return;
    }

    // it was a selected pages (without assignment), remove it from selectedPages
    setSelectedPages(prev => prev.filter(ca => !(ca.documentId === documentId && ca.pageNumber === pageNumber)));
  };

  const getTagLabelAndTooltipMessage = (): { tagLabel: string, tooltipMessage: string, isInvalid?: boolean } => {
    if (!isAssigned || !property.loaded || !contractsWithinDateRange.loaded) {
      return EMPTY_LABELS;
    }

    const isAssignedToProperty = assignmentOfThisPage.contractId === undefined && assignmentOfThisPage.unitId === undefined;
    const isAssignedToContract = assignmentOfThisPage.contractId !== undefined && assignmentOfThisPage.unitId !== undefined;
    const isAssignedToUnit = assignmentOfThisPage.contractId === undefined && assignmentOfThisPage.unitId !== undefined;


    if (isAssignedToProperty) {
      return {
        tagLabel: property.data.addressConcatenation,
        tooltipMessage: property.data.addressConcatenation,
      };
    }

    if (isAssignedToContract) {
      return getLabelsForContractAssignment();
    }

    if (isAssignedToUnit) {
      return getLabelsForUnitAssignment();
    }


    console.error("Assignment doesn't belong to property, contract, or unit. Something went wrong.");
    return EMPTY_LABELS;
  };


  const getLabelsForContractAssignment = () => {
    if (!assignmentOfThisPage) return EMPTY_LABELS;

    const matchingContractWithinDateRange = contractsWithinDateRange.data.find(contract => contract.unitContractId === assignmentOfThisPage.contractId);

    if (matchingContractWithinDateRange) {
      const tenantName = matchingContractWithinDateRange.isVacant ? tl(translations.pagePreview.vacancy) : matchingContractWithinDateRange.mailingContact.name;
      const tagLabel = `${matchingContractWithinDateRange.unitNrSharingDeclaration} · ${tenantName}`;
      return { tagLabel, tooltipMessage: tagLabel };
    }

    /**
     * No contract found matching the contractId on the assignment, so we must load it separately
     * (this can happen if we change the contract's start/end date so that it no longer matches those in the URL).
     */

    const matchingContractOutsideOfDateRange = contractsOutsideOfDateRange.data
      .find(contract => contract.unitContractId === assignmentOfThisPage.contractId);

    if (matchingContractOutsideOfDateRange) {
      const tagLabel = `${matchingContractOutsideOfDateRange.unitNrSharingDeclaration} · ${matchingContractOutsideOfDateRange.mailingContact.name}`;
      const tooltipMessage = tl(translations.pagePreview.dateRangeErrorTooltip);
      return { tagLabel, tooltipMessage, isInvalid: true };
    }

    // fallback, theoretically shouldn't get here
    console.error(`Couldn't find a matching contract for ${assignmentOfThisPage?.contractId} between contracts within daterange or outside of it, something went wrong.`);
    return EMPTY_LABELS;
  };

  // If it's contract based assignment, we should show the correct contract label even if it's outside the date range.

  const getLabelsForUnitAssignment = () => {
    const contractAssignmentDetails = assignmentOfThisPage
      ? contractsWithinDateRange.data.find(contract => contract.unitId === assignmentOfThisPage.unitId)
      : undefined;

    if (!isNil(contractAssignmentDetails)) {
      const tagLabel = `${contractAssignmentDetails.unitNrSharingDeclaration} · ${contractAssignmentDetails.mailingContact.name}`;
      return { tagLabel, tooltipMessage: tagLabel };
    }

    /**
     * If we got here then we have an assignment to this unit,
     * but the unit doesn't have an active contract in the date-range of the WKA.
     */

    const unitNr = units.data.find(u => u.id === assignmentOfThisPage.unitId)?.unitNrSharingDeclaration;
    const tagLabel = `${unitNr} · ${tl(translations.actionBar.missingContract)}`;
    const tooltipMessage = tl(translations.pagePreview.noActiveContractTooltip);

    return { tagLabel, tooltipMessage, isInvalid: true };
  };

  return {
    viewMode,
    checked,
    onChangeCheckbox,
    ...getTagLabelAndTooltipMessage(),
    loading,
    borderType: getBorderType(isSelected, showWarningForSelectedPages),
  };
};
