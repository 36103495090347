import React, { useContext, useState } from 'react';
import './Login.scss';
import { Form, Input } from 'antd';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { useHistory } from 'react-router';
import Heading from 'storybook-components/typography/Heading/Heading';
import Paragraph from 'storybook-components/typography/Paragraph/Paragraph';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';
import { ICONS } from '../../components/icons';

export default function Login(): JSX.Element {
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const authContext: any = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  const login = () => {
    authContext.onLogin(email, password, domain);
  };

  if (authContext.isLoggedIn()) history.replace('/');

  return (
    <div className="Login container">
      <div className="login-container col-xs-12 col-sm-10 col-md-8 col-lg-6">
        <div className="big-logo">
          <Icon component={ICONS.impower} />
        </div>
        <div className="title-section">
          <Heading level={1} className="title">
            {tl(translations.pages.loginPage.title)}
          </Heading>
          <Paragraph level={1} type="secondary" className="sub-title">
            {tl(translations.pages.loginPage.subTitle)}
          </Paragraph>
        </div>
        <Form className="login-form">
          <Form.Item className="login-form-item">
            <Input
              placeholder={tl(translations.pages.loginPage.loginForm.domainPlaceholder)}
              className="Input"
              value={domain || ''}
              onChange={(event: any) => {
                setDomain(event.target.value);
              }}
            />
          </Form.Item>
          <Form.Item className="login-form-item">
            <Input
              placeholder={tl(translations.pages.loginPage.loginForm.emailPlaceholder)}
              className="Input"
              value={email || ''}
              onChange={(event: any) => {
                setEmail(event.target.value);
              }}
            />
          </Form.Item>
          <Form.Item className="login-form-item">
            <Input
              placeholder={tl(translations.pages.loginPage.loginForm.passwordPlaceholder)}
              type="password"
              className="Input"
              value={password || ''}
              onChange={(event: any) => {
                setPassword(event.target.value);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={login}
              loading={authContext.loading}
              htmlType="submit"
              className="login-button"
            >
              {tl(translations.pages.loginPage.loginForm.loginButton)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
