import { FindFilteredPropertiesUsingGETRequest, PropertyDisplayDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { createContext, useContext, useState } from 'react';
import { Order } from 'services/useSort';

export interface DistributionKeysPropertyListContextProviderProps {
    children: React.ReactChild | React.ReactChildren,
  }

const DistributionKeysPropertyListContext = createContext<{
    propertyList: DefaultDataInterface<PropertyDisplayDto[]>,
    setPropertyList: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyDisplayDto[]>>>,
    filterState: FindFilteredPropertiesUsingGETRequest,
    setFilterState: React.Dispatch<React.SetStateAction<FindFilteredPropertiesUsingGETRequest>>,
    sortState: { field: keyof PropertyDisplayDto, order: Order },
    setSortState: React.Dispatch<React.SetStateAction<{ field: keyof PropertyDisplayDto, order: Order }>>,
}>(undefined);


export const useDistributionKeyPropertyListContext = (usageName: string) => {
  const distributionKeyPropertyListContext = useContext(DistributionKeysPropertyListContext);

  if (distributionKeyPropertyListContext === undefined) {
    throw new Error(`${usageName} must be used within a DistributionKeyPropertyListContext`);
  }

  return distributionKeyPropertyListContext;
};

const DEFAULT_SORT: { field: keyof PropertyDisplayDto, order: Order } = {
  field: 'updated',
  order: -1,
};

const DistributionKeysPropertyListContextProvider = ({ children }: DistributionKeysPropertyListContextProviderProps) => {
  const [propertyList, setPropertyList] = useState(DEFAULT_DATA<PropertyDisplayDto[]>([]));
  const [sortState, setSortState] = useState(DEFAULT_SORT);
  const [filterState, setFilterState] = useState({});

  return (
    <DistributionKeysPropertyListContext.Provider value={{
      propertyList,
      setPropertyList,
      sortState,
      setSortState,
      filterState,
      setFilterState,
    }}
    >
      {children}
    </DistributionKeysPropertyListContext.Provider>
  );
};

export default DistributionKeysPropertyListContextProvider;
