import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import { Distribution, TableDataSourceType } from 'pages/ManualExchange/services/ManualExchangeContext';
import { useAmountToDistributeCell } from './useAmountToDistribueCell';

interface Props {
    record: TableDataSourceType;
    onChangeDistributionValues: (unitAccountCode: string, field: keyof Distribution, value: string | number, record: TableDataSourceType) => void
}

const AmountToDistributeCell = ({ record, onChangeDistributionValues }: Props) => {
  const { amount } = useAmountToDistributeCell(record);

  const onChange = (value) => {
    onChangeDistributionValues(record.accountCode!, 'amount', value, record);
  };

  return (
    <CurrencyInput
      onChange={onChange}
      value={amount}
      disabled={!record.selected}
    />
  );
};

export default AmountToDistributeCell;
