import { usePdfJsLib } from 'lib/usePdfJsLib';

declare let pdfjsLib: any;

const BASE64_MARKER = ';base64,';
export const blobToBase64 = blob => new Promise((resolve, _) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

export const usePdfUtils = () => {
  // call this to load the pdfJS library
  usePdfJsLib();

  const convertDataURIToBinary = (dataURI) => {
    const base64Index = dataURI?.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  };

  const getPdfByBase64UrlUtil = (base64Url) => {
    const pdfAsArray = convertDataURIToBinary(base64Url);

    return pdfjsLib.getDocument(pdfAsArray).promise;
  };

  return {
    convertDataURIToBinary,
    getPdfByBase64UrlUtil,
  };
};
