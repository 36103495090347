import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import './PersonEditing.scss';
import { Modal } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import {
  Prompt, useHistory, useLocation, useParams,
} from 'react-router';
import _ from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import FormSidebar from 'elements/FormElements/FormSidebar/FormSidebar';
import { generateMenuFromSections } from 'lib/Utils';
import FormSection from 'elements/FormElements/FormSection/FormSection';
import { PersonEditingContext } from 'contexts/PersonEditingContext';
import { ContactListContext } from 'contexts/ContactListContext';
import ContactHeaderButtons from 'pages/Contacts/components/ContactHeaderButtons/ContactHeaderButtons';
import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import ContactSection from './sections/PersonSection';
import CompanySection from './sections/CompanySection';
import { OverlayContext } from '../../../../services/OverlayContext/OverlayContext';


export default function PersonEditing(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { personId, companyId } = useParams<{ personId: string, companyId: string }>();
  const contactEditingContext: any = useContext(PersonEditingContext);
  const contactListContext: any = useContext(ContactListContext);
  const { overlays }: any = useContext(OverlayContext);
  const [openSectionIndex, setOpenSectionIndex] = useState(0);
  const [dirtModalSection, setDirtModalSection] = useState(-1);
  const [dirtModalVisible, setDirtModalVisible] = useState(false);
  const openNextSectionOnSuccessRef = useRef(false);

  const history = useHistory();
  const location = useLocation();
  const { isDirty, setDirty, onChangePersonData } = contactEditingContext;
  const { goBack } = useContext(OverlayContext);

  const hideCompanies = location.pathname !== '/persons/create-person' && !personId;

  useEffect(() => {
    if (personId) {
      contactEditingContext.onLoadContact(personId);
    } else contactEditingContext.clearContact();

    return () => {
      contactEditingContext.clearAutoSelectContactFunction();
    };
  }, []);

  useEffect(() => {
    if (contactEditingContext.saved) {
      setDirty(false);

      if (_.isEmpty(contactEditingContext.validationErrors)) {
        if (openNextSectionOnSuccessRef.current) {
          if (dirtModalSection !== -1) {
            setOpenSectionIndex(dirtModalSection);
            setDirtModalSection(-1);
          } else {
            setOpenSectionIndex(openSectionIndex + 1);
          }
        } else {
          setOpenSectionIndex(-1);
        }
      }
    }
    setDirtModalVisible(false);
  }, [contactEditingContext.saved]);

  useEffect(() => {
    if (openSectionIndex >= 0) {
      const sectionId = sections[openSectionIndex] ? sections[openSectionIndex].sectionId : null;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openSectionIndex]);

  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (openSectionIndex === nextSectionIndex) {
      // if the clicked section is the current one, close the sections
      nextSectionIndex = -1;
    }
    if (isDirty) {
      setDirtModalVisible(true);
      setDirtModalSection(nextSectionIndex);
    } else {
      setOpenSectionIndex(nextSectionIndex);
    }
  };

  const saveAndAutoSelect = () => {
    contactEditingContext.onSaveContact(companyId, openSectionIndex + 1)
      .then((resp: any) => {
        goBack();
        contactListContext.onLoadContact(resp.id);
        contactEditingContext.autoSelectContactAfterCreation(resp);
      });
  };

  const save = (openNextSectionOnSuccess: boolean) => {
    openNextSectionOnSuccessRef.current = openNextSectionOnSuccess;
    setDirtModalSection(-1);
    if (hideCompanies || companyId) {
      saveAndAutoSelect();
    } else {
      contactEditingContext.onSaveContact(companyId, openSectionIndex + 1).then(() => {
        if (location.pathname.includes('create-person')) {
          goBack();
        }
      })
      // we need the catch, othewrise we get an `Object captured as promise rejection
      // with keys: status, title, validationErrors` error in Sentry
        .catch(() => {});
    }
  };

  const autoSelectCompanyAfterCreation = (contact?: any) => {
    const newContact = { ...contactEditingContext.data.contact };
    const newCompany = {
      linkedContactId: contact.id,
    };
    if (newContact.linkedToContacts && newContact.linkedToContacts.length > 0) {
      newContact.linkedToContacts.push(newCompany);
    } else {
      newContact.linkedToContacts = [newCompany];
    }
    setDirty(true);
    contactEditingContext.updateContactState({ contact: newContact });
  };

  const addressNumber = contactEditingContext.data.contact.addressNumber ? contactEditingContext.data.contact.addressNumber : 1;
  const bankAccountNumber = contactEditingContext.data.contact.bankAccountNumber ? contactEditingContext.data.contact.bankAccountNumber : 0;
  const companyNumber = contactEditingContext.data.contact.companyNumber ? contactEditingContext.data.contact.companyNumber : 1;
  const { duplicate } = contactEditingContext;
  const sections: any[] = [
    ContactSection({ addressNumber, bankAccountNumber, duplicate }),
    CompanySection({ companyNumber, autoSelectCompanyAfterCreation }),
  ];


  if (companyId || hideCompanies) {
    // delete CompanySection
    sections.splice(sections.length - 1, 1);
  }

  const menuElements: any[] = generateMenuFromSections({
    sections,
    openSectionIndex,
  });

  const handleDirtModalDiscard = () => {
    setDirtModalVisible(false);
    setDirty(false);
    if (personId) {
      contactEditingContext.onLoadContact(personId);
    } else {
      contactEditingContext.clearContact();
    }

    if (openSectionIndex === dirtModalSection) {
      setOpenSectionIndex(-1);
    } else setOpenSectionIndex(dirtModalSection);
  };

  const handleDirtModalSave = () => {
    openNextSectionOnSuccessRef.current = true;
    contactEditingContext.onSaveContact(companyId, openSectionIndex + 1).then((resp: any) => {
      if (location.pathname.includes('create-person')) {
        history.replace(`${overlays[overlays.length - 2].url}/edit-person/${resp.id}`);
      }
    });
  };

  return (
    <Page className="ContactEditing page">
      {companyId
        ? (
          <PageHeader
            title={tl(translations.pages.contactEditing.header.titleNew)}
            rightSideComponent={(
              <ContactHeaderButtons
                contactType={ContactLegacyDtoTypeEnum.COMPANY}
                contactId={parseInt(companyId, 10)}
                editEnabled
                onSave={save}
                onGoBack={goBack}
                duplicate={duplicate}
              />
            )}
          />
        )
        : (
          <PageHeader
            showLogo
            title={personId
              ? tl(translations.pages.contactEditing.header.titleEdit)
              : tl(translations.pages.contactEditing.header.titleNew)}
            rightSideComponent={(
              <ContactHeaderButtons
                contactType={ContactLegacyDtoTypeEnum.PERSON}
                contactId={parseInt(personId, 10)}
                editEnabled
                onSave={save}
                onGoBack={goBack}
                duplicate={duplicate}
              />
            )}
          />
        )
      }
      <PageContent className="page-content">
        <Prompt
          when={isDirty}
          message={loc => (loc.pathname.includes(location.pathname) ? true : tl(translations.elements.prompt))}
        />
        <FormSidebar
          elements={menuElements}
          openSection={openSectionIndex}
          onOpenSection={handleOpenSectionChange}
          scrollTarget={document.getElementsByClassName('form-content')[0]}
        />
        <div className="form-content">
          <div id="scrollElement">
            {sections.map((section: any) => {
              const prevSectionIndex = section.openingNumber - 1;

              return (
                <FormSection
                  key={section.sectionId}
                  open={(openSectionIndex === prevSectionIndex)}
                  onClickHeader={() => handleOpenSectionChange(prevSectionIndex)}
                  onSubmit={() => save(true)}
                  addDirt={() => setDirty(true)}
                  validationErrors={contactEditingContext.validationErrors}
                  onChange={onChangePersonData}
                  hideSaveButton={duplicate}
                  {...section}
                />
              );
            })}
          </div>
        </div>
      </PageContent>
      <Modal
        visible={dirtModalVisible}
        title={tl(translations.elements.dirtModal.title)}
        onCancel={() => setDirtModalVisible(false)}
        footer={[
          <Button key="cancel" type="text" onClick={() => setDirtModalVisible(false)}>
            {tl(translations.elements.dirtModal.cancel)}
          </Button>,
          <Button key="discard" type="ghost" onClick={handleDirtModalDiscard}>
            {tl(translations.elements.dirtModal.discard)}
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleDirtModalSave}
            loading={contactEditingContext.loading}
          >
            {tl(translations.elements.dirtModal.save)}
          </Button>,
        ]}
      >
        <p>{tl(translations.elements.dirtModal.description)}</p>
      </Modal>
    </Page>
  );
}

PersonEditing.defaultProps = {
  backPath: undefined,
  hideCompanies: undefined,
};
