import React, { ReactNode } from 'react';
import Icon from '@ant-design/icons';
import './SmartTableHeader.scss';
import { ICONS } from '../../../../components/icons';

export interface SmartTableHeaderSort {
  selected: boolean,
  directionAsc: boolean
}

interface SmartTableHeaderProps {
  title: string | ReactNode
  sort?: SmartTableHeaderSort
  onClick?: () => void
}

export default function SmartTableHeader(props: SmartTableHeaderProps): JSX.Element {
  const { title, sort, onClick } = props;

  let arrow = ICONS.arrowDown;
  if (sort) {
    arrow = sort.selected ? (sort.directionAsc ? ICONS.arrowUp : ICONS.arrowDown) : ICONS.arrowUp;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={`SmartTableHeader ${sort ? 'clickable' : null}`}
      title={typeof title === 'string' ? title : ''}
      onClick={onClick}
    >
      <div className="header-title">{title}</div>
      {sort ? (
        <Icon
          className={`sort-icon ${sort.selected ? 'selected' : null}`}
          component={arrow}
        />
      ) : null}
    </div>
  );
}
