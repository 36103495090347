import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import Amount from 'storybook-components/typography/Amount/Amount';
import { translations } from '../../../../translations';
import { ContractContactProjectionDto, OwnersMeetingProtocolRecipientExtendedDisplayDto } from '../../../../../../api/accounting';

export const useAttendeeListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.editPage.sections.attendeeList.columns.number),
      dataIndex: 'number',
      width: '3%',
      render: (item, record, index: number) => (
        <div>
          {index + 1}
        </div>
      ),
    },
    {
      title: tl(translations.editPage.sections.attendeeList.columns.owners),
      dataIndex: ['contractProjectionDto', 'contacts', 'name'],
      className: 'tenant',
      render: (text: any, record: OwnersMeetingProtocolRecipientExtendedDisplayDto) => record.contractProjectionDto?.contacts?.map((contact: ContractContactProjectionDto) => contact.name).join(',\n'),
    },
    {
      title: tl(translations.editPage.sections.attendeeList.columns.units),
      dataIndex: ['contractProjectionDto', 'unitNrSharingDeclaration'],
    },
    {
      title: tl(translations.editPage.sections.attendeeList.columns.mea),
      dataIndex: ['contractProjectionDto', 'propertyShare'],
      render: (_, record: OwnersMeetingProtocolRecipientExtendedDisplayDto) => (
        <Amount className="property-share">
          {record.contractProjectionDto?.propertyShare?.toFixed(2)?.replace('.', ',')}
        </Amount>
      ),
      width: '10%',
    },
    {
      title: tl(translations.editPage.sections.attendeeList.columns.powerOfAttorney),
      dataIndex: 'powerOfAttorney',
    },
    {
      title: tl(translations.editPage.sections.attendeeList.columns.signatures),
      dataIndex: '',
      width: '20%',
      render: () => (
        <div />
      ),
    },
  ];
};
