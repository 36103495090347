import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import ProgressBar from 'storybook-components/feedback/ProgressBar/ProgressBar';

import { BankAccountImportDto } from '../BankAccountsImportSection/interfaces';
import { ContactImportDto } from '../ContactsImportSection/interfaces';
import { IProgress } from '../interfaces';
import { PropertyImportDto } from '../PropertiesImportSection/interfaces';
import { translations } from './translations';

export const ImportProgress = ({
  progress, contacts, properties, bankAccounts,
}:
  {
    progress: IProgress,
    contacts: ContactImportDto[], properties: PropertyImportDto[], bankAccounts: BankAccountImportDto[]
  }) => {
  const { tl } = useContext(LanguageContext);
  return (
    <ProgressBar
      title={tl(translations.title)}
      description={tl(translations.description)}
      data={[{
        label: tl(translations.contacts),
        percentage: Math.round(progress.contactsImported * 100 / Math.max(contacts.length, 1)),
      },
      {
        label: tl(translations.properties),
        percentage: Math.round(progress.propertiesImported * 100 / Math.max(properties.length, 1)),
      },
      {
        label: tl(translations.bankAccounts),
        percentage: Math.round(progress.bankAccountsImported * 100 / Math.max(bankAccounts.length, 1)),
      },
      ]}
    />
  );
};
