import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import React from 'react';
import { ICONS } from '../../../components/icons';
import './DeleteButton.scss';

interface DeleteButtonProps {
  dataKey?: string,
  nrKey?: string,
  onClick: (key1?: string, key2?: string, callback?: Function, deletionModal?: object) => void,
  className?: string,
  disabled?: boolean,
  deletionCallback?: Function,
  deletionModal?: object,
}

export default function DeleteButton(props: DeleteButtonProps): JSX.Element {
  const {
    className, onClick, dataKey, nrKey, disabled, deletionCallback, deletionModal,
  } = props;
  return (
    <Button
      type="ghost"
      className={`icon-button ${className} trash ${disabled ? 'disabled' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick(dataKey, nrKey, deletionCallback, deletionModal);
      }}
      disabled={disabled}
    >
      <Icon component={ICONS.bin} />
    </Button>
  );
}
