import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { Action } from 'elements/SmartTable/data';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { useUpdateContractDunningLevel } from 'services/useUpdateContractDunningLevel';
import { useOrderOpenBalancesContext, useOrderOpenBalancesSelectionContext } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/OrderOpenBalancesContext';
import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import { DebtorBalancesGroupedType } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';

export const useContractBalanceActions = (record: DebtorBalancesGroupedType) => {
  const { tl } = useContext(LanguageContext);
  const { updateContractDunningLevel } = useUpdateContractDunningLevel();
  const { setPropertyAndContractBalanceList } = useOrderOpenBalancesContext('useContractBalanceActions');

  const {
    setInnerTableSelectedRowKeysCurrent,
    setInnerTableSelectedRowKeysTotal,
    setOuterTableSelectedRowKeysCurrent,
    setOuterTableSelectedRowKeysTotal,
  } = useOrderOpenBalancesSelectionContext('useContractBalanceActions');

  const label = record.contractDunningLevel !== ContractProjectionDtoDunningLevelEnum.EXCLUDED
    ? tl(orderOpenBalancesTranslations.innerTableColumns.actions.exclude)
    : tl(orderOpenBalancesTranslations.innerTableColumns.actions.include);
  const newDunningLevel = record.contractDunningLevel !== ContractProjectionDtoDunningLevelEnum.EXCLUDED
    ? ContractProjectionDtoDunningLevelEnum.EXCLUDED
    : null;

  const updateSelectedKeys = () => {
    const propertyIdAndAccountCodePrefix = `${record.propertyId.toString()}-${record.accountCode}`;
    const propertyIdPrefix = `${record.propertyId.toString()}-`;

    setInnerTableSelectedRowKeysTotal((prev) => {
      const filteredInnerSelectedRowKeyTotal = prev.filter(
        innerSelectedTotal => !innerSelectedTotal.startsWith(propertyIdAndAccountCodePrefix),
      );

      // if there are no other selected contracts from this property
      // remove propertyId from outerSelectedRowKeysTotal
      const filteredInnerSelectedRowKeyTotalWithinProperty = filteredInnerSelectedRowKeyTotal.filter(
        inner => inner.startsWith(propertyIdPrefix),
      );
      if (isEmpty(filteredInnerSelectedRowKeyTotalWithinProperty)) {
        setOuterTableSelectedRowKeysTotal(prevOuter => prevOuter.filter(outer => outer !== record.propertyId));
      }

      return prev.filter(
        innerSelectedTotal => !(innerSelectedTotal === propertyIdAndAccountCodePrefix)
        && !innerSelectedTotal.startsWith(`${propertyIdAndAccountCodePrefix}/`),
      );
    });

    setInnerTableSelectedRowKeysCurrent((prev) => {
      const filteredInnerSelectedRowKeyCurrent = prev.filter(
        innerSelectedTotal => !innerSelectedTotal.startsWith(propertyIdAndAccountCodePrefix),
      );

      // if there are no other selected contracts from this property
      // remove propertyId from outerSelectedRowKeysCurrent
      const filteredInnerSelectedRowKeyTotalWithinProperty = filteredInnerSelectedRowKeyCurrent.filter(
        inner => inner.startsWith(propertyIdPrefix),
      );
      if (isEmpty(filteredInnerSelectedRowKeyTotalWithinProperty)) {
        setOuterTableSelectedRowKeysCurrent(prevOuter => prevOuter.filter(outer => outer !== record.propertyId));
      }

      return prev.filter(
        innerSelectedTotal => !(innerSelectedTotal === propertyIdAndAccountCodePrefix)
        && !innerSelectedTotal.startsWith(`${propertyIdAndAccountCodePrefix}/`),
      );
    });
  };

  const updatedChildAccountDunningLevels = (children) => {
    if (isEmpty(children)) {
      return null;
    }

    return children.map((child) => {
      if (!isEmpty(child.children)) {
        return ({
          ...child,
          children: updatedChildAccountDunningLevels(child.children),
          contractDunningLevel: newDunningLevel,
        });
      }
      return {
        ...child,
        contractDunningLevel: newDunningLevel,
      };
    });
  };

  const onUpdateDunningLevel = () => {
    updateContractDunningLevel(record.contractId, newDunningLevel)
      .then(([resp]) => {
        setPropertyAndContractBalanceList((prev) => {
          const updatedList = prev.data.map((p) => {
            if (p.propertyId !== record.propertyId) {
              return p;
            }

            return ({
              ...p,
              debtorBalancesGrouped: p.debtorBalancesGrouped.map((db) => {
                if (db.contractId !== resp.unitContractId) {
                  return db;
                }

                return ({
                  ...db,
                  contractDunningLevel: newDunningLevel,
                  children: updatedChildAccountDunningLevels(db.children),
                });
              }),
            });
          });

          return prev.load(updatedList);
        });

        updateSelectedKeys();
      });
  };

  const onClickUpdateDunningLevel = () => {
    // when contract is about to be excluded show confirmation modal
    if (newDunningLevel === ContractProjectionDtoDunningLevelEnum.EXCLUDED) {
      Modal.confirm({
        title: tl(orderOpenBalancesTranslations.innerTableColumns.actions.modal.title),
        okText: tl(orderOpenBalancesTranslations.innerTableColumns.actions.modal.ok),
        cancelText: tl(orderOpenBalancesTranslations.innerTableColumns.actions.modal.cancel),
        onOk: () => onUpdateDunningLevel(),
        content: (
          <>
            <p>{ tl(orderOpenBalancesTranslations.innerTableColumns.actions.modal.line1) }</p>
            <p>{ tl(orderOpenBalancesTranslations.innerTableColumns.actions.modal.line2) }</p>
          </>),
        okButtonProps: { className: 'Button' },
        cancelButtonProps: { className: 'Button' },
        closable: true,
      });
    } else {
    // when contract is about to be included, update dunning level without confirmation
      onUpdateDunningLevel();
    }
  };

  const actions: Action[] = [
    {
      label,
      onAction: onClickUpdateDunningLevel,
    },
  ];

  return {
    actions,
  };
};
