import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useAccountNameCell } from './useAccountNameCell';

export const AccountNameCell = ({ accountName, accountCode, propertyHrId }) => {
  const { account, linkToAccountSheet } = useAccountNameCell(accountCode, propertyHrId);

  return (
    <Skeleton
      loading={!(account || accountName)}
      active
      title={false}
      paragraph={{ rows: 1, width: '100%' }}
    >
      <Link to={linkToAccountSheet} target="_blank">
        {`${account?.name || accountName}`}
      </Link>
    </Skeleton>
  );
};
