import {
  Configuration, DocumentLegacyControllerApi, DocumentProjectionDto, FindDocumentsFilteredUsingGETAdministrationTypesEnum, FindDocumentsFilteredUsingGETOrderEnum, FindDocumentsFilteredUsingGETSourceTypesEnum,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import moment from 'moment';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ProfitAndLossReportListContext } from './ProfitAndLossReportListContext';


const getStartAndEndDate = (metaData: string) => {
  const { startDate, endDate } = JSON.parse(metaData || '{}');

  return {
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
  };
};


export const useProfitAndLossReportList = () => {
  const { tl } = useContext(LanguageContext);
  const profitAndLossReportContext = useContext(ProfitAndLossReportListContext);
  const { apiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as Configuration);
  const history = useHistory();
  const location = useLocation();

  if (profitAndLossReportContext === undefined) {
    throw new Error('useProfitAndLossReportList must be used within a ProfitAndLossReportListContextProvider');
  }

  const {
    profitAndLossReportList, filterState, sortState, setProfitAndLossReportList,
  } = profitAndLossReportContext;


  useEffect(() => {
    if (location.pathname === '/profit-and-loss' || !profitAndLossReportList.loaded) {
      loadProfitAndLossReports(true);
    }
  }, [filterState, sortState, location.pathname === '/profit-and-loss']);


  const getFilters = () => ({
    ...filterState,
    minCreated: filterState?.minCreated ? moment(filterState.minCreated).format('YYYY-MM-DD') : undefined,
    maxCreated: filterState?.maxCreated ? moment(filterState.maxCreated).format('YYYY-MM-DD') : undefined,
    sourceIds: [-1],
  });

  const loadProfitAndLossReports = (resetPage?: boolean) => {
    setProfitAndLossReportList(prev => prev.startLoading());
    documentControllerApi.findDocumentsFilteredUsingGET({
      ...getFilters(),
      sort: sortState.field,
      order: sortState.order > 0 ? FindDocumentsFilteredUsingGETOrderEnum.ASC : FindDocumentsFilteredUsingGETOrderEnum.DESC,
      page: resetPage ? 0 : profitAndLossReportList.page,
      size: 30,
      // @ts-ignore
      sourceTypes: [FindDocumentsFilteredUsingGETSourceTypesEnum.PROFIT_AND_LOSS],
    })
      .then((response) => {
        setProfitAndLossReportList(prev => prev.loadPaged(
          response.content.map(doc => ({
            ...doc,
            ...getStartAndEndDate(doc.metaData),
          })),
          resetPage,
          response.last,
        ));
      })
      .catch((err) => {
        setProfitAndLossReportList(prev => prev.failed(err));
        showNotification({
          key: 'loadProfitAndLossReportsError',
          message: tl(translations.notifications.loadError),
          type: 'error',
        });
      });
  };

  const openProfitAndLossReportView = (record: DocumentProjectionDto) => ({
    onClick: () => {
      history.push(`${location.pathname}/${record.id}/view`);
    },
  });

  return {
    profitAndLossReportList,
    loadProfitAndLossReports,
    openProfitAndLossReportView,
  };
};
