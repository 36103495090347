import { Alert, Col, Row } from 'antd';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { SPACE_SIZES } from 'lib/constants';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import { Link } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import { ICONS } from 'components/icons';
import { getPropertyEditingRoute } from 'pages/Property/routes';
import { PropertyLegacyDtoVatRelevanceEnum } from 'api/accounting';
import { SEVPropertyCreationModalTranslation } from './SEVPropertyCreationModalTranslation';
import { InputValues, useSEVPropertyCreationModal } from './useSEVPropertyCreationModal';

interface SEVPropertyCreationModalProps {
    existingSEVPropertyId?: number,
    existingSEVUnitId?: number,
    existingSEVVatRelevance?: PropertyLegacyDtoVatRelevanceEnum,
    alreadyCopiedWegTenantContractIds?: number[],
    initInputValues?: InputValues,
    visible: boolean,
    onCancel: ()=> void,
    onSuccess?: ()=> void,

}

export const SEVPropertyCreationModal = ({
  existingSEVPropertyId, existingSEVUnitId, alreadyCopiedWegTenantContractIds, existingSEVVatRelevance, initInputValues, visible, onCancel, onSuccess,
}: SEVPropertyCreationModalProps) => {
  const { tl } = useContext(LanguageContext);

  const {
    wegOptions,
    onChangePropertyId,
    inputValues,
    ownerContractOptions,
    disabledPropertySelect,
    disabledOwnerContractSelect,
    ownerContractsLoading,
    propertyListLoading,
    onChangeOwnerContract,
    tenantContractsOptions,
    disabledTenantContractSelect,
    onChangeTenantContracts,
    onCreateSEVProperty,
    onCopyWegContractsToExistingSEV,
    disabledCreationButton,
    loadingCreationButton,
    onCancelAndClearValuesOfInputs,
    existingSEVPropertyWithTheSameOwnerContractId,
    existingSEVHasDifferentVatRelevanceCompareToContracts,
  } = useSEVPropertyCreationModal(
    existingSEVPropertyId,
    existingSEVUnitId,
    existingSEVVatRelevance,
    alreadyCopiedWegTenantContractIds ?? [],
    initInputValues,
    onCancel,
    onSuccess,
    visible,
  );


  const warningExistingSEVPropertyWithTheSameOwner = existingSEVPropertyWithTheSameOwnerContractId !== undefined && !existingSEVPropertyId;
  const warningExistingSEVHasDifferentVatRelevanceCompareToContracts = existingSEVHasDifferentVatRelevanceCompareToContracts && existingSEVPropertyId;
  const warningAlertIsDisplayed = warningExistingSEVPropertyWithTheSameOwner || warningExistingSEVHasDifferentVatRelevanceCompareToContracts;
  const translationBase = existingSEVPropertyId ? SEVPropertyCreationModalTranslation.copy : SEVPropertyCreationModalTranslation.create;

  const getWarningMessage = () => {
    if (warningExistingSEVPropertyWithTheSameOwner) {
      return (
        <>
          {tl(SEVPropertyCreationModalTranslation.warningAlertOwner.firstPart)}
          {linkButton}
          {tl(SEVPropertyCreationModalTranslation.warningAlertOwner.secondPart)}
        </>
      );
    }
    if (warningExistingSEVHasDifferentVatRelevanceCompareToContracts) {
      return tl(SEVPropertyCreationModalTranslation.warningAlertVat);
    }
  };

  const linkButton = (
    <Link
      to={getPropertyEditingRoute(existingSEVPropertyWithTheSameOwnerContractId?.propertyHrId)}
      target="_blank"
    >
      {existingSEVPropertyWithTheSameOwnerContractId?.propertyIdInternal ?? existingSEVPropertyWithTheSameOwnerContractId?.propertyHrId}
    </Link>
  );
  return (

    <ModalWithSubtitle
      visible={visible}
      onCancel={onCancelAndClearValuesOfInputs}
      onOk={existingSEVPropertyId ? onCopyWegContractsToExistingSEV : onCreateSEVProperty}
      subtitle={tl(translationBase.subtitle)}
      destroyOnClose
      title={tl(translationBase.title)}
      okButtonProps={{
        className: 'Button',
        disabled: disabledCreationButton,
        loading: loadingCreationButton,
      }}
      okText={tl(SEVPropertyCreationModalTranslation.okButton)}
      cancelButtonProps={{ className: 'Button' }}
      cancelText={tl(SEVPropertyCreationModalTranslation.cancelButton)}
      getContainer={() => document.getElementById('root') ?? document.body}
    >
      <Row gutter={[SPACE_SIZES.medium, SPACE_SIZES.medium]}>
        {
          warningAlertIsDisplayed
          && (
            <Col span={24}>
              <Alert
                message={(
                  <>
                    {getWarningMessage()}
                  </>
                )}
                type="warning"
                showIcon
                icon={<Icon component={ICONS.warning} />}
              />
            </Col>
          )
        }
        <Col span={24}>
          <SelectInput
            className="custom-required"
            showSearch
            allowClear
            label={tl(SEVPropertyCreationModalTranslation.wegSelect)}
            options={wegOptions}
            disabled={disabledPropertySelect}
            onChange={onChangePropertyId}
            value={inputValues?.propertyId}
            loading={propertyListLoading}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            className="custom-required"
            showSearch
            allowClear
            label={tl(SEVPropertyCreationModalTranslation.ownerContract)}
            options={ownerContractOptions}
            disabled={disabledOwnerContractSelect}
            loading={ownerContractsLoading}
            onChange={onChangeOwnerContract}
            value={inputValues?.ownerContractId}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            showSearch
            allowClear
            label={tl(SEVPropertyCreationModalTranslation.tenantContract)}
            options={tenantContractsOptions}
            disabled={disabledTenantContractSelect}
            onChange={onChangeTenantContracts}
            value={inputValues?.tenantContractIds}
            mode="multiple"
          />
        </Col>
      </Row>
    </ModalWithSubtitle>
  );
};

SEVPropertyCreationModal.defaultProps = {
  existingSEVPropertyId: undefined,
  existingSEVUnitId: undefined,
  alreadyCopiedWegTenantContractIds: [],
  initInputValues: undefined,
  onSuccess: undefined,
  existingSEVVatRelevance: undefined,
};
