/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  JWTToken,
} from '../models';
import {
    JWTTokenFromJSON,
    JWTTokenToJSON,
} from '../models';

export interface GenerateResourceTokenUsingPOSTRequest {
    resource: string;
    resourceId: number;
}

/**
 * 
 */
export class MetabaseApi extends runtime.BaseAPI {

    /**
     * Generate an embed token for an already shared !for embedding metabase dashboard
     */
    async generateResourceTokenUsingPOSTRaw(requestParameters: GenerateResourceTokenUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JWTToken>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling generateResourceTokenUsingPOST.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling generateResourceTokenUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // API Key authentication
        }

        const response = await this.request({
            path: `/api/v1/metabase/{resource}/{resourceId}/token`.replace(`{${"resource"}}`, encodeURIComponent(String(requestParameters.resource))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JWTTokenFromJSON(jsonValue));
    }

    /**
     * Generate an embed token for an already shared !for embedding metabase dashboard
     */
    async generateResourceTokenUsingPOST(requestParameters: GenerateResourceTokenUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JWTToken> {
        const response = await this.generateResourceTokenUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
