import React, { useMemo } from 'react';
import Icon from '@ant-design/icons';
import { Steps } from 'antd';

import './StepsComponent.scss';
import { ICONS } from '../icons';

const { Step } = Steps;

interface StepsProps {
    optionsList: StepOption[],
    onChangeStep: (current: any) => void,
    selectedStep: any,
    className?: string,
    disabled?: boolean,
}

// Either define value for ALL options or NONE (if it's not specified index will be used)
interface StepOption {
    label: string,
    numberIcon?: number,
    value?: any,
}

export const StepsComponent: React.FC<StepsProps> = (props) => {
  const {
    optionsList, onChangeStep, selectedStep, className, disabled,
  } = props;

  const iconComponent = (index:number) => (
    <Icon
      className="steps-component-svg-icon"
      component={index === 0 ? ICONS.verticalDivider : ICONS.arrowSteps}
    />
  );

  const onChange = (index: number) => {
    if (optionsList[index].value !== undefined) {
      onChangeStep(optionsList[index].value);
    } else {
      onChangeStep(index);
    }
  };

  const selectedStepIndex: number = optionsList[0].value ? optionsList.findIndex(option => option.value === selectedStep) : selectedStep;

  const stepsList = useMemo(() => (
    <Steps
      type="navigation"
      onChange={onChange}
      className="steps-component-navigation"
      current={selectedStepIndex}
    >
      {optionsList.map((option, index) => (
        <Step
          disabled={disabled}
          key={index}
          status="process"
          title={option.label}
          icon={
            option.numberIcon
              ? (
                <div className="steps-component-inline">
                  <div className={`steps-component-icon ${
                    selectedStepIndex === index
                      ? 'steps-component-active-color-icon' : 'steps-component-color-icon'}`}
                  >
                    {option.numberIcon}
                  </div>
                  {iconComponent(index)}
                </div>
              )
              : (
                <div>
                  {iconComponent(index)}
                </div>
              )
          }
        />
      ))
      }
    </Steps>
  ), [optionsList]);

  return (
    <div className={`Steps ${className}`}>
      {stepsList}
    </div>
  );
};

StepsComponent.defaultProps = {
  className: '',
  disabled: false,
};
