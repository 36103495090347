import React from 'react';
import './PdfZoomButtons.scss';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import { ICONS } from 'components/icons';

interface PdfZoomButtonsProps {
  onChangeZoom: (type: 'zoomIn' | 'zoomOut') => void;
  zoomInDisabled: boolean,
  zoomOutDisabled: boolean,
}

const PdfZoomButtons = ({
  onChangeZoom, zoomInDisabled, zoomOutDisabled,
}: PdfZoomButtonsProps) => (
  <div className="PdfZoomButtons">
    <Button
      className="zoom-btn zoom-in-btn"
      disabled={zoomInDisabled}
      onClick={() => {
        onChangeZoom('zoomIn');
      }}
    >
      <Icon component={ICONS.plusNew} />
    </Button>
    <Button
      className="zoom-btn zoom-out-btn"
      disabled={zoomOutDisabled}
      onClick={() => {
        onChangeZoom('zoomOut');
      }}
    >
      <Icon component={ICONS.minusNew} />
    </Button>
  </div>
);

export default PdfZoomButtons;
