import { RouteDefinition } from 'components/RenderRoutes';
import DocumentPreviewPage from './DocumentPreviewPage';

export const DOCUMENT_PREVIEW_PATH = '/preview/:documentDbId';

export const getDocumentPreviewRoute = (documentDbId: string | number) => (
  DOCUMENT_PREVIEW_PATH
    .replace(':documentDbId', `${documentDbId}`)
);

export const DocumentPreviewRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: DOCUMENT_PREVIEW_PATH,
      component: DocumentPreviewPage,
      strict: true,
    },
  ],
};
