import { useContext } from 'react';
import { AllocationAmountsStateContext, ServiceCompanyAllocationUpdatersContext } from '../../../../services/ServiceCompanyAllocationContext';


const useSumToAllocateCell = (key: string) => {
  const allocationAmounts = useContext(AllocationAmountsStateContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);

  if (updatersContext === undefined) {
    throw new Error('useAccountCell must be used within a ServiceCompanyAllocationContextProvider!');
  }

  const { setAllocationAmounts } = updatersContext;


  const currentValue = allocationAmounts?.find(alloc => alloc.key === key)?.currentAmount;

  const onChange = (value: number) => {
    setAllocationAmounts(prev => prev.map(allocationAmount => (
      allocationAmount.key === key
        ? { ...allocationAmount, currentAmount: value }
        : allocationAmount
    )));
  };

  return { currentValue, onChange };
};

export default useSumToAllocateCell;
