import {
  Context,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  CommentControllerApi,
  CommentDto,
  Configuration,
  GetCommentsOfEntityUsingGETCommentEntityTypeEnum,
  PageOfCommentDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';

import { translations } from './translations';

export function useComments(entityType: GetCommentsOfEntityUsingGETCommentEntityTypeEnum, id: number) {
  const [comments, setComments] = useState<CommentDto[]>([]);

  const { apiConfiguration } = useContext<{ apiConfiguration:(string) => Configuration }>(AuthContext as unknown as Context<{ apiConfiguration: (string) => Configuration }>);
  const commentsController = new CommentControllerApi(apiConfiguration('accounting'));

  const { tl } = useContext(LanguageContext);

  useEffect(() => {
    if (entityType && id) {
      loadComments(entityType, id);
    }
  }, [entityType, id]);

  const loadComments = async (commentEntityType: GetCommentsOfEntityUsingGETCommentEntityTypeEnum, entityId: number) => {
    try {
      const page: PageOfCommentDto = await commentsController.getCommentsOfEntityUsingGET({ commentEntityType, entityId });
      setComments(page.content);
    } catch (e) {
      console.error(e);
      showNotification({
        key: 'loadCommentsError',
        message: tl(translations.notifications.load.error),
        type: 'error',
      });
    }
  };

  return {
    comments,
  };
}
