export const translations = {
  notifications: {
    load: {
      error: {
        en: 'Could not load comments.',
        de: 'Kommentare conten nicht geladen werden.',
      },
    },
  },
};
