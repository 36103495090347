import {
  EconomicPlanDto,
  EconomicPlanSimpleProjection,
  EconomicPlanSimpleProjectionStatusEnum,
  EconomicPlanSimpleProjectionTypeEnum,
} from '../../../api/accounting';

export const convertEconomicPlanDtoToEconomicPlanSimpleProjection = (dto: EconomicPlanDto) : EconomicPlanSimpleProjection => ({
  ...dto,
  status: EconomicPlanSimpleProjectionStatusEnum[dto.status.toUpperCase()],
  type: EconomicPlanSimpleProjectionTypeEnum[dto.type.toUpperCase()],
}) as EconomicPlanSimpleProjection;
