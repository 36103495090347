import { RouteDefinition } from 'components/RenderRoutes';
import { BANKACCOUNT_COMPONENT, BANKACCOUNT_CREATE_PATH, BANKACCOUNT_EDIT_PATH } from 'pages/BankAccountEditor/routes';
import BankAccountList from './BankAccountList/BankAccountList';

export const BankAccountRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/bank-account',
      component: BankAccountList,
    },
    {
      path: [`/bank-account${BANKACCOUNT_CREATE_PATH}`, `/bank-account${BANKACCOUNT_EDIT_PATH}`],
      component: BANKACCOUNT_COMPONENT,
      type: 'overlay',
    },
  ],
};
