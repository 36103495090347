import {
  useContext,
  useEffect,
} from 'react';

import {
  AgendaItemDto,
  AgendaItemsControllerApi,
  AgendaItemUpdateDtoAgendaItemTypeEnum,
} from 'api/accounting';
import {
  useCurrentOverlayInfo,
} from 'components/OverlayRoute/services/useCurrentOverlayInfo';
import { AgendaItemsContext } from 'contexts/AgendaItemsContext';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _ from 'lodash';
import {
  OwnersMeetingProtocolContext,
} from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';

import {
  translations as ownersMeetingProtocolTranslations,
} from '../../../../../translations';
import { useLoadAgendaItemsList } from './useLoadAgendaItemsList';

export const useAgendaItemsList = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const { ownersMeeting, setDirty } = useContext(OwnersMeetingProtocolContext);
  const agendaItemsContext = useContext(AgendaItemsContext);
  const agendaItemsControllerApi = new AgendaItemsControllerApi(apiConfiguration('accounting'));


  if (agendaItemsContext === undefined) {
    throw new Error('useAgendaItemsList must be used within a AgendaItemsContextProvider');
  }
  const {
    agendaItemsList, setAgendaItemsList,
  } = agendaItemsContext;
  const { isOverlayOnTop } = useCurrentOverlayInfo();

  const { onLoadAgendaItemsList } = useLoadAgendaItemsList();

  // wrapper for BasicDragNDrop table call
  const setDragNDropValuesDispatcher = (setStateFunction: (param: AgendaItemDto[]) => AgendaItemDto[]) => {
    setAgendaItemsList(prev => prev.load(setStateFunction(prev.data)));
  };

  const changeOrderOfAgendaItems = () => {
    const indexesList = agendaItemsList.data.map(a => a.index).sort((a, b) => a - b);
    const newAgendaItemsIndexChanged = [];
    const agendaList = agendaItemsList.data.map((agendaItem, index) => {
      if (agendaItem.index !== indexesList[index]) {
        newAgendaItemsIndexChanged.push({ ...agendaItem, index: indexesList[index] });
        return {
          ...agendaItem,
          index: indexesList[index],
        };
      }
      return agendaItem;
    });

    if (!_.isEmpty(newAgendaItemsIndexChanged)) {
      setAgendaItemsList(prev => prev.load(agendaList, true, agendaItemsList.lastPage));
      onSaveAgendaItems(newAgendaItemsIndexChanged);
    }
  };


  useEffect(() => {
    if (!_.isEmpty(agendaItemsList?.data)) {
      changeOrderOfAgendaItems();
    }
    // this useEffect has agendaItemsList?.data as a dependency because the DragNDrop reordering directly sets the state and we need to save the order changes
  }, [agendaItemsList?.data]);

  useEffect(() => {
    if (isOverlayOnTop && ownersMeeting?.data?.id) {
      onLoadAgendaItemsList(true);
    }
  }, [ownersMeeting.data.id, isOverlayOnTop]);


  const onSaveAgendaItems = (agendaItemsToUpdate: AgendaItemDto[], callback?: () => void) => {
    const promises: any = [];

    agendaItemsToUpdate.forEach((agendaItem) => {
      if (agendaItem?.resolutionRecordText?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
        agendaItem.resolutionRecordText = agendaItem.resolutionRecordText.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
      }

      if (agendaItem?.comment?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
        agendaItem.comment = agendaItem.comment.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
      }
      promises.push(agendaItemsControllerApi.updateAgendaItemUsingPUT({ agendaItemId: agendaItem.id, agendaItemUpdateDto: { ...agendaItem, index: agendaItem.index, agendaItemType: agendaItem.agendaItemType as unknown as AgendaItemUpdateDtoAgendaItemTypeEnum } }));
    });
    if (_.isEmpty(promises)) {
      callback?.();
    } else {
      Promise.all(promises).then((response: AgendaItemDto[]) => {
        showNotification({
          key: 'saveOwnersMeetingSuccess',
          message: tl(ownersMeetingProtocolTranslations.notifications.saveSuccess),
          type: 'success',
        });
        callback?.();
      }).catch(() => {
        showNotification({
          key: 'saveOwnersMeetingWithValidationErrors',
          message: tl(ownersMeetingProtocolTranslations.notifications.saveError),
          type: 'warning',
        });
      }).finally(() => {
        setDirty(false);
      });
    }
  };

  return {
    agendaItemsList,
    setDragNDropValuesDispatcher,
    changeOrderOfAgendaItems,
    setDirty,
  };
};
