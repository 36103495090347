import React, { useContext, useEffect, useMemo } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import './PropertyList.scss';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import SmartTable, { DataIndex } from 'elements/SmartTable/SmartTable';
import { Action } from 'elements/SmartTable/data';
import { useHistory, useParams } from 'react-router';
import { usePropertyListColumns } from './UsePropertyListColumns';
import { PropertyListContext } from '../../../contexts/PropertyListContext';
import MainPageHeader from '../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import { useGlobalSearch } from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import useSiteMap from '../../../services/useSiteMap/useSiteMap';
import { PropertyLegacyDto, PropertyLegacyDtoPropertyStateEnum } from '../../../api/accounting/models';
import usePropertyFilters from './usePropertyFilters';
import useFullPageScroll from '../../../services/useFullPageScroll';
import { useModal } from '../../../elements/Modals/useModal/useModal';
import { DeletionModal } from '../../../elements/Modals/DeletionModal/DeletionModal';
import CreatePropertyButton from './components/CreatePropertyButton/CreatePropertyButton';
import { usePropertyEditing } from '../PropertyEditing/services/PropertyEditingContext/usePropertyEditing';


export default function PropertyList(): JSX.Element {
  const propertyListContext: any = useContext(PropertyListContext);

  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { clearProperty } = usePropertyEditing();

  useGlobalSearch({
    key: 'properties',
    filterProps: {
      availableFilters: usePropertyFilters(),
      setFilter: (key, value) => propertyListContext.updateFilterState({
        [key]: value,
      }),
    },
    queryParamAsFilter: {
      filterState: propertyListContext.propertyFilterState,
      onSetDefaultFilterFromQueryParams: propertyListContext.onSetDefaultFilterFromQueryParams,
      defaultFilters: propertyListContext.defaultPropertyFilterState,
    },
  });

  const deletionModal = useModal({ onOk: propertyListContext.onDeleteProperty });

  const toTableData = (property: any): any => ({
    ...property,
    key: property?.id,
    address: [(property?.propertyAddress?.street || ''), (property?.propertyAddress?.number || '')].join(' '),
  });

  useEffect(() => {
    if (location.pathname === '/properties') {
      clearProperty();
    }
  }, [location.pathname]);

  useFullPageScroll({ tableName: 'propertyList', beforeElement: document.getElementsByClassName('MainPageHeader')[0], className: 'small' });

  const actionsMenu: Action[] = [{
    label: tl(translations.pages.property.table.actions.delete),
    onAction: (record: PropertyLegacyDto) => {
      deletionModal.showModal(record.id!);
    },
    actionSupported: ((record: PropertyLegacyDto) => record.propertyState === PropertyLegacyDtoPropertyStateEnum.DRAFT),
  }];

  const tableData = useMemo(() => propertyListContext.data.propertyList.map(toTableData), [propertyListContext.data.propertyList]);
  const smartTable = useSmartTable(
    {
      tableName: 'propertyList',
      columns: usePropertyListColumns(),
      dataSource: tableData,
      propSort: {
        field: propertyListContext.sortField,
        order: propertyListContext.sortOrder,
        onSortChange: (dataKey: string) => propertyListContext.setSortField(dataKey),
      },
      contentLoading: propertyListContext.loading,
      onRow: (record: any) => ({
        onClick: () => {
          history.push(`/properties/${record.propertyHrId}/edit`);
        },
      }),
      infiniteScrollerProps: {
        hasMoreData: !propertyListContext.data.lastPage,
        loadMoreData: propertyListContext.onLoadPropertyList,
      },
      rowKey: 'id',
      actionsMenu,
    },
  );

  useEffect(() => (() => {
    // cleanup
    propertyListContext.onResetListState();
  }), []);

  useEffect(() => {
    if (propertyHrId) {
      propertyListContext.updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);


  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'propertyOverview',
    navItems: subcategorySwitcherItems.propertyData,
  };


  return (
    <div className="PropertyList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={<CreatePropertyButton />}
      />
      <SmartTable {...smartTable} />
      <DeletionModal {...deletionModal} />
    </div>
  );
}
