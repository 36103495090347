import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';

import { ICONS } from 'components/icons';
import { SearchBar } from './components/SearchBar/SearchBar';
import './Header.scss';
import { QuickAddMenu } from './components/AddButton/QuickAddMenu';
import { BurgerMenu } from './components/SearchBar/components/BurgerMenu';
import { BurgerButton } from './components/SearchBar/components/BurgerMenu/BurgerButton';
import BackNavigationButton from '../../elements/Buttons/BackNavigationButton/BackNavigationButton';

export const Header: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();
  const location = useLocation();
  const navigateDashboard: (e: React.SyntheticEvent) => void = (e) => {
    e.preventDefault();
    history.push('/dashboard');
  };

  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(prevValue => !prevValue);
  };

  const leftButton = () => (
    <>
      <div className="header-item">
        <a
          className="logo-link"
          href="/dashboard"
          onClick={navigateDashboard}
          type="link"
        >
          <Icon className="logo-icon" component={ICONS.impower} />
        </a>
      </div>
      {location.pathname !== '/dashboard' && <div className="header-item"><BackNavigationButton /></div>}
    </>
  );

  return (
    <div id={id} className="header">
      <div className="header-placeholder" />
      <div className="header-content">
        {!burgerMenuOpen && leftButton()}
        <div className="header-item header-search">
          {!burgerMenuOpen && <SearchBar />}
        </div>
        <div className="header-item">
          <QuickAddMenu />
        </div>
        <div className="header-item">
          <BurgerButton onClick={toggleBurgerMenu} open={burgerMenuOpen} />
        </div>
        <BurgerMenu
          open={burgerMenuOpen}
          setOpen={setBurgerMenuOpen as () => void}
        />
      </div>
      {' '}

    </div>
  );
};
