import { useContext } from 'react';
import { DEFAULT_DIRT_MODAL_STATE, IsolatedSectionsDirtModalContext, DirtModalProps } from './IsolatedSectionsDirtModalContext';


type ShowModalArgs = Omit<DirtModalProps, 'visible' | 'loading' | 'onProceedCallback' >;


export const useIsolatedDirtModalUtils = () => {
  const dirtModalContext = useContext(IsolatedSectionsDirtModalContext);

  if (dirtModalContext === undefined) {
    throw new Error('useIsolatedDirtModalUtils must be used within a IsolatedSectionsDirtModalContext');
  }

  const { dirty, setDirty, setModalProps } = dirtModalContext;

  const onSetDirtyValue = () => {
    setDirty(true);
  };

  const onClearDirty = () => {
    setDirty(false);
    setModalProps(DEFAULT_DIRT_MODAL_STATE);
  };

  const onSetDirty = ({ onSave, onDiscard }: ShowModalArgs) => {
    setDirty(true);
    setModalProps(prev => ({
      ...prev, onSave, onDiscard,
    }));
  };

  const onShowModal = (onProceedCallback?: () => void) => {
    setModalProps(prev => ({ ...prev, visible: true, onProceedCallback }));
  };

  const onChangeModalLoading = (loading: boolean) => {
    setModalProps(prev => ({
      ...prev,
      loading,
    }));
  };


  return {
    dirty,
    onSetDirty,
    onClearDirty,
    onShowModal,
    onChangeModalLoading,
    onSetDirtyValue,
  };
};
