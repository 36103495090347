import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useInvalidateAllContactsListCache } from 'services/ContactsList/useInvalidateAllContactsListCache';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { LanguageContext } from './LanguageContext';
import { translations } from '../elements/Translation/translations';
import { convertToBEModel, convertToFEModel } from './functions/ContactEditingFunctions';
import { getPersonSectionErrors } from './util/ContactSectionFieldsConfiguration';
import { showNotification } from '../lib/Notification';
import { ContactListContext } from './ContactListContext';

export const PersonEditingContext: any = React.createContext({});

const DUPLICATE_STATUS_CODE = 409;

export type AutoSelectFunctionType = (contact?: any) => void;

export default function ContactEditingProvider({ children }: any) {
  const defaultContext = {
    contact: {
      type: 'PERSON',
      addressNumber: 1,
      companyNumber: 1,
      contactPreferences: ['POST'],
    },
  };

  const { invalidateCache } = useInvalidateAllContactsListCache();

  const defaultAutoSelectFunction: AutoSelectFunctionType = (contact?: any) => {};
  const [savedContactState, setSavedContactState] = useState(DEFAULT_DATA<any>(defaultContext));
  const [contactState, setContactState] = useState(DEFAULT_DATA<any>(defaultContext));
  const [duplicate, setDuplicate] = useState(false);
  const [autoSelectContactAfterCreation, setAutoSelectContactAfterCreation]: [AutoSelectFunctionType, any] = useState(() => defaultAutoSelectFunction);
  const [firstRecipientNameTouched, setFirstRecipientNameTouched] = useState(false);
  const isDirty = useRef(false);

  const { tl } = useContext(LanguageContext);
  const { onLoadPersonList } = useContext(ContactListContext);

  useEffect(() => {
    let timer: any;
    if (isDirty.current) {
      timer = setTimeout(() => {
        verifyDuplicate();
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [contactState]);

  const setDirty = (value: boolean) => {
    isDirty.current = value;
  };

  const updateContactState = (data: object, validationErrors?: any, saved?: boolean) => {
    setContactState(contactState.load({
      ...contactState.data,
      ...data,
    }, validationErrors, saved));
  };

  const clearContact = () => {
    setContactState(DEFAULT_DATA<any>(defaultContext));
    setFirstRecipientNameTouched(false);
  };

  const onLoadContact = (id: string): void => {
    setContactState(state => state.startLoading());
    setSavedContactState(state => state.startLoading());
    backend.get(`${endpointUrls.CONTACT}/${id}`, {})
      .then((response: any) => {
        const FEData = convertToFEModel(response);
        updateContactState({ contact: FEData });
        setSavedContactState(state => state.load(FEData));

        // only auto-fill when creating, not when editing
        setFirstRecipientNameTouched(true);
      })
      .catch(() => {
        setContactState(state => state.failed());
        setSavedContactState(state => state.failed());
        showNotification({
          key: 'loadContactError',
          message: tl(translations.notifications.contactEditingContext.loadError.message),
          description: tl(translations.notifications.contactEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onSaveContact = (companyId?: string, savingSectionNumber?: number) => {
    setContactState(contactState.startLoading());
    setDirty(false);
    let p;
    const data = convertToBEModel(contactState.data.contact);
    if (companyId) {
      data.linkedToContacts = [{ linkedContactId: companyId }];
    }
    if (contactState.data.contact.id) {
      p = backend.put(`${endpointUrls.CONTACT}/${contactState.data.contact.id}`, data);
    } else {
      p = backend.post(`${endpointUrls.CONTACT}`, data);
    }
    p.then((response: any) => {
      const FEData = convertToFEModel(response);
      updateContactState({ contact: FEData }, {}, true);
      setSavedContactState(state => state.load(FEData));
      onLoadPersonList(true);
      showNotification({
        key: 'saveContactSuccess',
        message: tl(translations.notifications.contactEditingContext.success.message),
        type: 'success',
      });

      invalidateCache();
    }).catch((error) => {
      setDirty(true);
      if (error.title === 'Validation error') {
        const currentSectionErrors: any = getPersonSectionErrors(savingSectionNumber || 1, error.validationErrors);
        if (!_.isEmpty(currentSectionErrors)) {
          showNotification({
            key: 'invalidContactError',
            message: tl(translations.notifications.contactEditingContext.validationError.message),
            type: 'error',
          });
        } else if (!_.isEmpty(error.validationErrors)) {
          showNotification({
            key: 'invalidContactError',
            message: tl(translations.notifications.contactEditingContext.validationError.differentSection),
            type: 'warning',
          });
        }
        updateContactState({}, currentSectionErrors, false);
      } else {
        setContactState(contactState.failed());
        showNotification({
          key: 'loadContactError',
          message: tl(translations.notifications.contactEditingContext.editError.message),
          description: tl(translations.notifications.contactEditingContext.editError.description),
          type: 'error',
        });
      }
    });
    return p;
  };

  const verifyDuplicate = () => {
    backend.post(`${endpointUrls.CONTACT}/duplicate`, contactState.data.contact)
      .then(() => {
        setDuplicate(false);
      })
      .catch((response) => {
        if (response.status === DUPLICATE_STATUS_CODE) setDuplicate(true);
      });
  };

  const clearAutoSelectContactFunction = () => {
    setAutoSelectContactAfterCreation(defaultAutoSelectFunction);
  };


  const onChangePersonData = (value: any) => {
    setContactState((prev) => {
      const prevFullName = `${prev.data.contact.firstName ?? ''} ${prev.data.contact.lastName ?? ''}`.trim();
      const fullName = `${value.firstName ?? ''} ${value.lastName ?? ''}`.trim();

      // set first recipient's name to fullName if it wasn't touched yet (on creation)
      if (!firstRecipientNameTouched && prevFullName !== fullName) {
        return prev.load({
          contact: {
            ...(value ?? {}),
            addresses: [
              {
                ...(prev.data.contact?.addresses?.[0] ?? {}),
                recipient: fullName,
              },
              ...(prev.data.contact.addresses?.slice(1) ?? []),
            ],
          },
        });
      }

      // if first recipient's name was changed by the user then set touched to true
      if (prev.data.contact.addresses?.[0]?.recipient !== value.addresses?.[0]?.recipient) {
        setFirstRecipientNameTouched(true);
      }

      return prev.load({ contact: value });
    });
    setDirty(true);
  };


  return (
    <PersonEditingContext.Provider value={{
      ...contactState,
      savedContactState,
      duplicate,
      isDirty: isDirty.current,
      autoSelectContactAfterCreation,
      setAutoSelectContactAfterCreation,
      setDirty,
      setContactState,
      updateContactState,
      onLoadContact,
      onSaveContact,
      clearContact,
      clearAutoSelectContactFunction,
      onChangePersonData,
    }}
    >
      {children}
    </PersonEditingContext.Provider>
  );
}

ContactEditingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
