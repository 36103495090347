import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { useDistributionModeCell } from './useDistributionModeCell';

interface Props {
    recordKey: string,
  }

const DistributionModeCell = ({ recordKey }: Props) => {
  const {
    options,
    onChange,
    value,
    disabled,
    validationError,
    selectPlacement,
  } = useDistributionModeCell(recordKey);

  return (
    <SelectInput
      onChange={onChange}
      value={value}
      options={options}
      disabled={disabled}
      mode="multiple"
      validationState={validationError?.state}
      placement={selectPlacement}
    />
  );
};

export default DistributionModeCell;
