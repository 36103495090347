import { Link } from 'react-router-dom';
import { formatCurrency } from 'lib/Utils';
import Amount from 'storybook-components/typography/Amount/Amount';
import { useContactPropertiesAmountCell } from './useContactPropertiesAmountCell';


const ContactPropertiesAmountCell = ({ record }) => {
  const { color, url } = useContactPropertiesAmountCell(record);

  if (record.children) {
    return null;
  }
  return (
    <Link to={url} target="_blank">
      <Amount color={color}>
        {formatCurrency(record?.accountBalance ?? 0)}
      </Amount>
    </Link>
  );
};

export default ContactPropertiesAmountCell;
