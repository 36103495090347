import { GetMessagesUsingGETSourceTypeEnum, MessageControllerApi, MessageListProjectionDtoStateEnum } from 'api/accounting';
import { useCurrentOverlayInfo } from 'components/OverlayRoute/services/useCurrentOverlayInfo';
import { AuthContext } from 'contexts/AuthContext';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import usePoll from './usePoll';

export const useCheckMessages = ({ sourceId, sourceType }: { sourceId: number, sourceType: GetMessagesUsingGETSourceTypeEnum}) => {
  const [messagesExist, setMessagesExist] = useState<boolean>(false);
  const [messagesGenerating, setMessagesGenerating] = useState<boolean>(false);
  const { apiConfiguration } = useContext(AuthContext);
  const messageControllerApi = new MessageControllerApi(apiConfiguration('accounting'));
  const { isOverlayOnTop } = useCurrentOverlayInfo();

  useEffect(() => {
    if (!Number.isNaN(sourceId) && isOverlayOnTop) {
      messageControllerApi.getMessagesUsingGET({ sourceId, sourceType })
        .then((resp) => {
          if (!_.isEmpty(resp.content)) {
            setMessagesExist(true);
            setMessagesGenerating(resp.content?.some(m => m.state === MessageListProjectionDtoStateEnum.GENERATING));
          } else {
            setMessagesExist(false);
          }
        });
    }
  }, [sourceId, isOverlayOnTop]);


  const checkMessageStates = () => {
    messageControllerApi.getMessagesUsingGET({ sourceId, sourceType })
      .then((resp) => {
        setMessagesGenerating(resp.content?.some(m => m.state === MessageListProjectionDtoStateEnum.GENERATING));
      });
  };

  usePoll({
    shouldPoll: messagesGenerating,
    pollFunction: checkMessageStates,
    timeoutSeconds: 5000,
  });

  return {
    messagesExist,
    messagesGenerating,
  };
};
