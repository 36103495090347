import React from 'react';
import './ActionBar.scss';

type Props = {
  className?: string;
  children: React.ReactChild | React.ReactChild[];
  visible?: boolean;
}

const ActionBar = ({ className: classNameProp, visible, children }: Props) => {
  const className = `ActionBar ${classNameProp} ${visible ? 'ActionBarVisible' : 'ActionBarHidden'}`;

  return (
    <div className={className}>{children}</div>
  );
};

export default ActionBar;

ActionBar.defaultProps = {
  className: '',
  visible: true,
};
