import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { compareAccountCodes, formatCurrency } from 'lib/Utils';
import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import Amount from 'storybook-components/typography/Amount/Amount';
import { ExtendedAccountBalanceDto } from 'api/accounting';
import { useGetSimulationOfTheYearlyProfitAndLoss } from './useGetSimulationOfTheYearlyProfitAndLoss';


interface AssetReportTableDataSource {
  accountCode: string;
  accountName: string;
  activeValue?: number;
  passiveValue?: number;
}


const COLUMN_WIDTH = '12%';
const AMOUNT_COLUMNS_WIDTH = '15%';

const matchesRegexAndIsLeafAndHasNonZeroBalance = (regex: RegExp, account: ExtendedAccountBalanceDto) => (
  account.accountCode.match(regex)
  && account.leaf
  && account.normalizedBalanceAtEndOfDateRange
);

export const useAssetReportTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useAssetReportTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances } = profitAndLossReportContext;
  const { simulationOfTheYearlyProfitAndLoss } = useGetSimulationOfTheYearlyProfitAndLoss();

  const tenantDepositAccountBalances = useMemo(() => Object.keys(accountBalances.data)
    .filter(accCode => (accCode.match(/^2001\/\d+\/4$/)))
    .reduce((acc, accCode) => acc + accountBalances.data[accCode].normalizedBalanceAtEndOfDateRange, 0),
  [accountBalances.data]);

  const firstDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    const accountBalanceValues = Object.values(accountBalances.data);

    const ds = [];

    const accounts26 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^26(\/.*)?$/, acc));
    if (accounts26.length > 0) {
      accounts26.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          activeValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts271 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^27\/1(\/.*)?$/, acc));
    if (accounts271.length > 0) {
      accounts271.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          activeValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts274 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^27\/4(\/.*)?$/, acc));
    if (accounts274.length > 0) {
      accounts274.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          activeValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts28 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^28(\/.*)?$/, acc));
    if (accounts28.length > 0) {
      accounts28.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          activeValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    // 301/x/1
    const paymentToOwnersBalance = Object.keys(accountBalances.data)
      .filter(accCode => (accCode.match(/^301\/\d+\/1$/) && accountBalances.data[accCode].normalizedBalanceAtEndOfDateRange))
      .reduce((acc, accCode) => acc + accountBalances.data[accCode].normalizedBalanceAtEndOfDateRange, 0);

    const toOwnerBalanceType = paymentToOwnersBalance >= 0 ? 'passiveValue' : 'activeValue';
    const toOwnerBalanceValue = Math.abs(paymentToOwnersBalance);

    if (paymentToOwnersBalance) {
      ds.push({
        accountCode: '301/x/1',
        accountName: 'Zahlungen an Objekteigentümer',
        [toOwnerBalanceType]: toOwnerBalanceValue,
      });
    }

    // 301/x/2
    const paymentFromOwnersBalance = Object.keys(accountBalances.data)
      .filter(accCode => (accCode.match(/^301\/\d+\/2$/) && accountBalances.data[accCode].normalizedBalanceAtEndOfDateRange))
      .reduce((acc, accCode) => acc + accountBalances.data[accCode].normalizedBalanceAtEndOfDateRange, 0);

    const fromOwnerBalanceType = paymentFromOwnersBalance >= 0 ? 'passiveValue' : 'activeValue';
    const fromOwnerBalanceValue = Math.abs(paymentFromOwnersBalance);

    if (paymentFromOwnersBalance) {
      ds.push({
        accountCode: '301/x/2',
        accountName: 'Zahlungen von Objekteigentümer',
        [fromOwnerBalanceType]: fromOwnerBalanceValue,
      });
    }


    const accounts33 = accountBalanceValues.filter(acc => acc.accountCode.match(/^33\/\d+$/) && acc.normalizedBalanceAtEndOfDateRange);
    if (accounts33.length > 0) {
      accounts33.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          passiveValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts41 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^41(\/.*)?$/, acc));
    if (accounts41.length > 0) {
      accounts41.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          passiveValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts29 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^29(\/.*)?$/, acc));
    if (accounts29.length > 0) {
      accounts29.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          activeValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts49 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^49(\/.*)?$/, acc));
    if (accounts49.length > 0) {
      accounts49.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          passiveValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const tenantOpenBalancesExcludingDeposit = accountBalances.data['2001'].normalizedBalanceAtEndOfDateRange - tenantDepositAccountBalances;

    const tenantBalanceType = tenantOpenBalancesExcludingDeposit >= 0 ? 'activeValue' : 'passiveValue';
    const tenantBalanceValue = Math.abs(tenantOpenBalancesExcludingDeposit);

    if (tenantOpenBalancesExcludingDeposit) {
      ds.push({
        accountCode: '2001',
        accountName: tl(translations.report.sections.assetReportSection.datasetNames.tenantBalances),
        [tenantBalanceType]: tenantBalanceValue,
      });
    }

    const accounts35 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^35(\/.*)?$/, acc));
    if (accounts35.length > 0) {
      accounts35.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          passiveValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts39 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^39(\/.*)?$/, acc));
    if (accounts39.length > 0) {
      accounts39.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          passiveValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts9800 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^9800(\/.*)?$/, acc));
    if (accounts9800.length > 0) {
      accounts9800.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          activeValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const accounts34 = accountBalanceValues.filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^34(\/.*)?$/, acc));
    if (accounts34.length > 0) {
      accounts34.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode))
        .forEach(acc => ds.push({
          accountCode: acc.accountCode,
          accountName: acc.accountName,
          passiveValue: acc.normalizedBalanceAtEndOfDateRange,
        }));
    }

    const yearlyBalanceType = simulationOfTheYearlyProfitAndLoss <= 0 ? 'activeValue' : 'passiveValue';
    const yearlyBalanceValue = Math.abs(Number(simulationOfTheYearlyProfitAndLoss));

    ds.push({
      accountCode: '',
      accountName: 'Verrechnungskonto Jahresergebnis',
      [yearlyBalanceType]: yearlyBalanceValue,
    });

    return ds;
  }, [accountBalances.data, simulationOfTheYearlyProfitAndLoss]);

  const firstActiveSum = firstDataSource.reduce((acc, curr) => (acc + (curr.activeValue || 0)), 0);
  const firstPassiveSum = firstDataSource.reduce((acc, curr) => (acc + (curr.passiveValue || 0)), 0);

  const secondDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    const ds: AssetReportTableDataSource[] = [];

    const depositAccounts = Object.values(accountBalances.data)
      .filter(acc => matchesRegexAndIsLeafAndHasNonZeroBalance(/^27\/5(\/.*)?$/, acc))
      .map(acc => ({
        accountCode: acc.accountCode,
        accountName: acc.accountName,
        activeValue: acc.normalizedBalanceAtEndOfDateRange,
      }));

    ds.push(...depositAccounts);

    const tenantDepositBalanceType = tenantDepositAccountBalances >= 0 ? 'activeValue' : 'passiveValue';
    const tenantDepositBalanceValue = Math.abs(tenantDepositAccountBalances);

    if (tenantDepositAccountBalances) {
      ds.push({
        accountCode: '2001/x/4',
        accountName: tl(translations.report.sections.assetReportSection.datasetNames.depositPaymentDeficits),
        [tenantDepositBalanceType]: tenantDepositBalanceValue,
      });
    }

    if (accountBalances.data['440']?.normalizedBalanceAtEndOfDateRange) {
      ds.push({
        accountCode: '440',
        accountName: tl(translations.report.sections.assetReportSection.datasetNames.depositLiabilities),
        passiveValue: accountBalances.data['440'].normalizedBalanceAtEndOfDateRange,
      });
    }

    return ds;
  }, [accountBalances.data]);

  const secondActiveSum = secondDataSource.reduce((acc, curr) => (acc + (curr.activeValue || 0)), 0);
  const secondPassiveSum = secondDataSource.reduce((acc, curr) => (acc + (curr.passiveValue || 0)), 0);

  const totalActiveSum = firstActiveSum + secondActiveSum;
  const totalPassiveSum = firstPassiveSum + secondPassiveSum;


  const columns: ColumnsType<AssetReportTableDataSource> = [
    {
      title: tl(translations.report.sections.assetReportSection.columns.accountCode),
      dataIndex: 'accountCode',
      width: COLUMN_WIDTH,
    },
    {
      title: tl(translations.report.sections.assetReportSection.columns.accountName),
      dataIndex: 'accountName',
    },
    {
      title: tl(translations.report.sections.assetReportSection.columns.active),
      dataIndex: 'activeValue',
      className: 'column-align-right no-wrap',
      width: AMOUNT_COLUMNS_WIDTH,
      render: (num: number) => <Amount>{formatCurrency(num, '', false)}</Amount>,
    },
    {
      title: tl(translations.report.sections.assetReportSection.columns.passive),
      dataIndex: 'passiveValue',
      className: 'column-align-right no-wrap',
      width: AMOUNT_COLUMNS_WIDTH,
      render: (num: number) => <Amount>{formatCurrency(num, '', false)}</Amount>,
    },
  ];

  return {
    columns,
    firstDataSource,
    firstActiveSum,
    firstPassiveSum,
    secondDataSource,
    secondActiveSum,
    secondPassiveSum,
    totalActiveSum,
    totalPassiveSum,
  };
};
