import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import * as H from 'history';
import _ from 'lodash';
import { useSerialLetterListColumns } from './services/useSerialLetterListColumns';
import { translations } from '../../translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import useSmartTable from '../../../../elements/SmartTable/useSmartTable';
import SmartTable from '../../../../elements/SmartTable/SmartTable';
import { Action } from '../../../../elements/SmartTable/data';
import MainPageHeader from '../../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../../../services/useSiteMap/useSiteMap';
import { useGlobalSearch } from '../../../../components/Header/components/SearchBar/services/useGlobalSearch';
import { useSerialLetter } from '../../services/useSerialLetter';
import Button from '../../../../elements/Buttons/Button/Button';
import useSerialLetterFilters from './UseSerialLetterFilters';
import { SerialLetter } from '../../interfaces';
import { SerialLetterDto, SerialLetterDtoShippingStatusEnum } from '../../../../api/accounting/models';
import { useModal } from '../../../../elements/Modals/useModal/useModal';
import { DeletionModal } from '../../../../elements/Modals/DeletionModal/DeletionModal';

export default function SerialLetterList(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const serialLetterContext: any = useSerialLetter();
  const history = useHistory();
  const {
    serialLetterListState,
    onLoadSerialLetters,
    clearData,
    clearFilterState,
    filterState,
    setFilterState,
    updateFilterState,
    serialLetterListSortState,
    onChangeSortField,
  } = serialLetterContext;

  useGlobalSearch({
    key: 'serialLetter',
    filterProps: {
      availableFilters: useSerialLetterFilters(),
      setFilter: (key: string, value: any) => setFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);

          if (_.isNil(value) || _.isEmpty(value)) {
            delete newFilter[key];
            return newFilter;
          }

          newFilter[key] = value;
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams: updateFilterState,
    },
  });

  const deletionModal = useModal({ onOk: serialLetterContext.onDeleteSerialLetter });

  useEffect(() => {
    onLoadSerialLetters();
    return () => { clearData(); clearFilterState(); };
  }, []);

  const location: H.Location<{ reload?: boolean }> = useLocation();

  useEffect(() => {
    if (location.state?.reload) {
      onLoadSerialLetters(true);
    }
  }, [location.state]);

  const toTableData = (data: SerialLetter) => ({
    ...data,
    translatedStatus: data.shippingStatus && tl(translations.states[data.shippingStatus]) ? tl(translations.states[data.shippingStatus]) : '',
  });

  const dataSource = useMemo(() => serialLetterListState.data.map(toTableData), [serialLetterListState]);

  const actionsMenu: Action[] = [{
    label: tl(translations.actions.delete),
    onAction: (record: SerialLetterDto) => {
      deletionModal.showModal(record.id!);
    },
    actionSupported: ((record: SerialLetterDto) => record.shippingStatus === SerialLetterDtoShippingStatusEnum.DRAFT),
  }];

  const smartTable = useSmartTable({
    tableName: 'serialLetterList',
    columns: useSerialLetterListColumns(),
    dataSource,
    contentLoading: serialLetterListState.loading,
    onRow: (record: SerialLetter) => ({
      onClick: () => {
        if (record.shippingStatus !== SerialLetterDtoShippingStatusEnum.DRAFT
        && record.shippingStatus !== SerialLetterDtoShippingStatusEnum.ERROR) {
          history.push(`/letters/view/${record.id}`);
        } else {
          history.push(`/letters/edit/${record.id}`);
        }
      },
    }),
    propSort: {
      field: serialLetterListSortState.field,
      order: serialLetterListSortState.order,
      onSortChange: (dataKey: string) => onChangeSortField(dataKey),
    },
    infiniteScrollerProps: {
      hasMoreData: !serialLetterListState.lastPage,
      loadMoreData: onLoadSerialLetters,
    },
    rowKey: 'id',
    actionsMenu,
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'serial-letters',
    navItems: subcategorySwitcherItems.communication,
  };

  return (
    <div className="SerialLetterList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button
            type="primary"
            onClick={() => {
              history.push('/letters/create');
            }}
          >
            {tl(translations.create)}
          </Button>
        )}
      />
      <SmartTable {...smartTable} />
      <DeletionModal {...deletionModal} />
    </div>
  );
}
