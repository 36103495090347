export const getAccountCategories = (accountCode: string) => {
  if (!accountCode) return null;
  const firstNumber = parseInt(accountCode.charAt(0), 10);
  const secondNumber = parseInt(accountCode.charAt(1), 10);

  if (firstNumber === 6) {
    return {
      mainCategory: 'houseMoneySettlement',
      subCategory: 'revenue',
    };
  }
  if (firstNumber === 8) {
    return {
      mainCategory: 'houseMoneySettlement',
      subCategory: 'expense',
    };
  }
  if (firstNumber === 2 && secondNumber > 5) {
    return {
      mainCategory: 'wealthStatus',
      subCategory: 'liquidity',
    };
  }
  if (accountCode === '41' || accountCode === '49' || accountCode === '440') {
    return {
      mainCategory: 'wealthStatus',
      subCategory: 'liability',
    };
  }
  if (firstNumber === 3) {
    return {
      mainCategory: 'wealthStatus',
      subCategory: 'capital',
    };
  }
  if (firstNumber === 9) {
    return {
      mainCategory: 'wealthStatus',
      subCategory: 'openingStatus',
    };
  }
  if (firstNumber === 2 && secondNumber < 6) {
    return {
      mainCategory: 'debitorsCreditors',
      subCategory: 'debitors',
    };
  }
  if (firstNumber === 4 && secondNumber > 3) {
    return {
      mainCategory: 'debitorsCreditors',
      subCategory: 'creditors',
    };
  }
  return null;
};
