import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { translations } from '../../translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import {
  AccountControllerApi,
  AccountDto,
  PropertyBankAccountProjectionDto,
  PropertyBankAccountControllerApi,
  SpecialContributionControllerApi,
  SpecialContributionDetailsDto,
  SpecialContributionDetailsDtoStateEnum,
  SpecialContributionDetailsDtoTypeEnum,
  SpecialContributionExecutionDetailsDto,
  SpecialContributionExecutionDetailsDtoTypeEnum,
} from '../../../../api/accounting';
import { showNotification } from '../../../../lib/Notification';
import DEFAULT_DATA, { DefaultDataInterface } from '../../../../lib/data';
import { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';

const NEXT_SECTION_INDEX: number = 2;
export default function useSection2(
  setDirty: (dirty: boolean) => void,
  setLoading: (value: boolean) => void,
  setOpenSectionIndex: (openSectionIndex: number) => void,
  specialContributionDetails: SpecialContributionDetailsDto | undefined,
  setSpecialContributionDetails: (specialContributionDetailsDto: SpecialContributionDetailsDto) => void,
) {
  const { tl } = useContext(LanguageContext);
  const [postingAccounts, setPostingAccounts] = useState<AccountDto[]>([]);
  const [allBankAccounts, setAllBankAccounts] = useState<PropertyBankAccountProjectionDto[]>([]);
  const [filteredBankAccounts, setFilteredBankAccounts] = useState<PropertyBankAccountProjectionDto[]>([]);
  const [specialContributionExecutionDetails, setSpecialContributionExecutionDetails] = useState<DefaultDataInterface<SpecialContributionExecutionDetailsDto>>(DEFAULT_DATA<SpecialContributionExecutionDetailsDto>({}));
  const { specialContributionId: specialContributionIdString } = useParams<{ specialContributionId: string }>();
  const specialContributionId = parseInt(specialContributionIdString || '-1', 10);

  const { apiConfiguration } = useContext(AuthContext);
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const bankAccountControllerApi = new PropertyBankAccountControllerApi(apiConfiguration('accounting'));
  const specialContributionControllerApi = new SpecialContributionControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (specialContributionDetails) {
      setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
        ...specialContributionDetails,
        type: (specialContributionDetails.type || SpecialContributionDetailsDtoTypeEnum.CONTRIBUTION) as unknown as SpecialContributionExecutionDetailsDtoTypeEnum,
      }));
    }

    if (specialContributionDetails?.propertyHrId) {
      accountControllerApi.getAccountsMatchingRegexUsingGET({
        propertyHrIds: [specialContributionDetails.propertyHrId],
        accountCodeRegex: '(^693)|(^33/\\d+/2$)',
      })
        .then((response: AccountDto[]) => {
          setPostingAccounts(response);
        })
        .catch(() => {
          showNotification({
            key: 'fetchPostingAccountsError',
            message: tl(translations.specialContribution.fetchPostingAccountsError),
            type: 'error',
          });
        });

      bankAccountControllerApi.filterPropertyBankAccountsUsingGET({ propertyHrId: specialContributionDetails.propertyHrId })
        .then((response: PropertyBankAccountProjectionDto[]) => {
          setAllBankAccounts(response);
          setFilteredBankAccounts(response);
        })
        .catch(() => {
          showNotification({
            key: 'fetchBankAccountsError',
            message: tl(translations.specialContribution.fetchBankAccountsError),
            type: 'error',
          });
        });
    }
  }, [specialContributionDetails]);

  const ibanOptions = filteredBankAccounts?.map((bankAccount: PropertyBankAccountProjectionDto) => ({
    value: bankAccount.bankAccountId,
    label: `${bankAccount.accountName} - ${bankAccount.iban}`,
  }));

  const searchIbans = (searchText: string) => {
    setFilteredBankAccounts(allBankAccounts.filter((bankAccount: PropertyBankAccountProjectionDto) => bankAccount.iban.includes(searchText)));
  };

  const save = (isSuccessNotificationRequired: boolean = true) => {
    setLoading(true);
    return specialContributionControllerApi.updateExecutionDetailsUsingPUT({
      dto: { ...specialContributionExecutionDetails?.data },
      id: specialContributionId,
    })
      .then(response => setSpecialContributionDetails({ ...response }))
      .then(() => specialContributionControllerApi.getExecutionDetailsValidationMessagesUsingGET({
        id: specialContributionId,
      }))
      .then((validationMessages) => {
        setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load(specialContributionExecutionDetails.data!, { ...validationMessages }, true));
        setDirty(false);
        if (isSuccessNotificationRequired && areMandatoryFieldsForCreationValid(Object.keys(validationMessages))) {
          setOpenSectionIndex(NEXT_SECTION_INDEX);
          if (Object.keys(validationMessages).length === 0) {
            showNotification({
              key: 'saveSpecialContributionSuccess',
              message: tl(translations.specialContribution.saveSpecialContributionSuccess),
              type: 'success',
            });
          }
        }
      })
      .catch(() => {
        showNotification({
          key: 'saveSpecialContributionError',
          message: tl(translations.specialContribution.saveSpecialContributionError),
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const areMandatoryFieldsForCreationValid = (validationMessages: string[]): boolean => !validationMessages.includes('type') && !validationMessages.includes('dueDate');

  const dueDateValidationState = () => {
    if (isFieldInvalid('dueDate')) {
      return 'error';
    }
    if (!!specialContributionExecutionDetails && !!specialContributionExecutionDetails?.data?.dueDate && !!specialContributionExecutionDetails?.data?.resolutionDate
      && moment(specialContributionExecutionDetails?.data?.resolutionDate)
        .isAfter(moment(specialContributionExecutionDetails?.data?.dueDate))) {
      return 'warning';
    }
    return '';
  };

  const dueDateValidationMessage = () => {
    if (dueDateValidationState() === 'warning') {
      return tl(translations.specialContribution.validations.dueDate.invalid);
    }
    if (dueDateValidationState() === 'error') {
      return tl(translations.specialContribution.validations.dueDate[specialContributionExecutionDetails.validationErrors.dueDate]);
    }
    return '';
  };

  const isFieldInvalid = (key: string): boolean => !!specialContributionExecutionDetails?.validationErrors && !!specialContributionExecutionDetails?.validationErrors[key];

  return {
    sectionTitle: tl(translations.specialContribution.section.executionDetailsSection.title),
    sectionId: 'executionDetailsSectionId',
    sectionNumber: 2,
    openingNumber: 1,
    onSubmit: (isSuccessNotificationRequired: boolean) => save(isSuccessNotificationRequired),
    value: specialContributionExecutionDetails.data,
    validationErrors: specialContributionExecutionDetails.validationErrors,
    inputsDisabled: specialContributionDetails?.state && specialContributionDetails?.state !== SpecialContributionDetailsDtoStateEnum.DRAFT,
    content: [
      {
        sectionId: 'executionDetailsSubsectionId',
        content: [
          [
            {
              type: 'radioGroup',
              key: 'type',
              sectionId: 'typeSectionId',
              props: {
                label: tl(translations.specialContribution.section.executionDetailsSection.type.subtitle),
                options: [
                  {
                    value: SpecialContributionExecutionDetailsDtoTypeEnum.CONTRIBUTION,
                    label: tl(translations.specialContribution.section.executionDetailsSection.type.contribution),
                  },
                  {
                    value: SpecialContributionExecutionDetailsDtoTypeEnum.DISTRIBUTION,
                    label: tl(translations.specialContribution.section.executionDetailsSection.type.distribution),
                  },
                ],
                onChange: (value: SpecialContributionExecutionDetailsDtoTypeEnum) => {
                  setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
                    ...specialContributionExecutionDetails.data,
                    type: value,
                  }));
                  setDirty(true);
                },
              },
            },
          ],
          [
            {
              type: 'accountFilter',
              key: 'postingAccountCode',
              props: {
                label: tl(translations.specialContribution.section.executionDetailsSection.postingAccount),
                required: true,
                allAccounts: postingAccounts,
                onChange: (value: string) => {
                  setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
                    ...specialContributionExecutionDetails.data,
                    postingAccountCode: value,
                  }));
                  setDirty(true);
                },
              },
            },
          ],
          [
            {
              type: 'smartSearch',
              key: 'bankAccountId',
              props: {
                label: tl(translations.specialContribution.section.executionDetailsSection.bankAccount),
                required: true,
                searchFunction: searchIbans,
                options: ibanOptions,
                onChange: (selectedId: number) => {
                  setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
                    ...specialContributionExecutionDetails.data,
                    bankAccountId: selectedId,
                  }));
                  setDirty(true);
                },
              },
            },
          ],
          [
            {
              type: 'date',
              key: 'resolutionDate',
              props: {
                required: true,
                label: tl(translations.specialContribution.section.executionDetailsSection.resolutionRecordDate),
                value: specialContributionExecutionDetails?.data?.resolutionDate || '',
                onChange: (value: string) => {
                  setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
                    ...specialContributionExecutionDetails.data,
                    resolutionDate: value,
                  }));
                  setDirty(true);
                },
                softMinDate: MIN_DATE_NORMAL,
                softMaxDate: MAX_DATE_NORMAL,
              },
            },
            {
              type: 'date',
              key: 'dueDate',
              props: {
                required: true,
                label: tl(translations.specialContribution.section.executionDetailsSection.dueDate),
                value: specialContributionExecutionDetails?.data?.dueDate || '',
                onChange: (value: string) => {
                  setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
                    ...specialContributionExecutionDetails.data,
                    dueDate: value,
                  }));
                  setDirty(true);
                },
                validationState: dueDateValidationState(),
                validationMessage: dueDateValidationMessage(),
                softMinDate: MIN_DATE_NORMAL,
                softMaxDate: MAX_DATE_NORMAL,
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'statement',
              props: {
                label: tl(translations.specialContribution.section.executionDetailsSection.statement),
                value: specialContributionExecutionDetails?.data?.statement,
                required: true,
                onChange: (value: string) => {
                  if (value.length <= 140) {
                    setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
                      ...specialContributionExecutionDetails.data,
                      statement: value,
                    }));
                    setDirty(true);
                  }
                },
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'note',
              props: {
                label: tl(translations.specialContribution.section.executionDetailsSection.note),
                placeholder: 'Optional',
                value: specialContributionExecutionDetails?.data?.note,
                onChange: (value: string) => {
                  setSpecialContributionExecutionDetails(specialContributionExecutionDetails.load({
                    ...specialContributionExecutionDetails.data,
                    note: value,
                  }));
                  setDirty(true);
                },
              },
            },
          ],
        ],
      },
    ],
  };
}
