import { PropertyDisplayDto, PropertyDisplayDtoVatEligibilityTypeEnum, PropertyDisplayDtoVatRelevanceEnum, UnitContractProjectionDtoVatRelevanceEnum, UnitProjectionDto, UnitProjectionDtoUnitTypeEnum } from "api/accounting";
import { round2dec } from "lib/Utils";

export const calculateEligibilityShareValue = (property: PropertyDisplayDto, units: UnitProjectionDto[], vatRelevantUnits: UnitProjectionDto[]) => {
    if (property?.vatRelevance === PropertyDisplayDtoVatRelevanceEnum.NOT_RELEVANT) return undefined; 
    if (property?.vatRelevance === PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT) return 100;

    if (property?.vatEligibilityType === PropertyDisplayDtoVatEligibilityTypeEnum.FIXED_RATE) return property?.vatEligibility;

    const total = calculateAreaSum(units);
    const creditable = calculateAreaSum(vatRelevantUnits);

    if (total) return round2dec(creditable * 100 / total);

    return undefined;
};

export const calculateAreaSum = units => units?.map(u => u.livingArea).reduce((acc, a) => acc + (a || 0), 0);

export const getActiveVatRelevantUnits = (units, activeContracts) => units?.filter((u) => {
    const contract = activeContracts?.find(c => c.unitId === u.id);
    return [UnitContractProjectionDtoVatRelevanceEnum.REDUCED_VAT_RELEVANT,
      UnitContractProjectionDtoVatRelevanceEnum.VAT_RELEVANT].includes(contract?.vatRelevance);
  });