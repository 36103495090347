import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import './RecipientSummary.scss';
import { recipientSelectorModalTranslations } from '../../translations';

export default function RecipientSummary({ nrOfRecipients } : {nrOfRecipients: number}) {
  const { tl } = useContext(LanguageContext);
  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell index={1} colSpan={2} className="group-column-darker no-wrap recipient-summary-cell">
          <div className="wrapper">
            <span className="label">{tl(recipientSelectorModalTranslations.summaryLabel)}</span>
            <span className="value column-align-right">{nrOfRecipients}</span>
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
}
