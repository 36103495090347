export const searchBarTranslations = {
  searchPlaceholder: {
    en: 'Search',
    de: 'Suche',
  },
  searchResults: {
    en: 'search results',
    de: 'Suchergebnisse',
  },
};
