import React from 'react';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { usePdfViewerWithAssignmentsHeader } from './usePdfViewerWithAssignmentsHeader';
import './PdfViewerWithAssignmentsHeader.scss';


type Props = {
  documentId: number,
  filename: string,
}

const PdfViewerWithAssignmentsHeader = ({ documentId, filename }: Props) => {
  const { onCLickDeleteFile, loading } = usePdfViewerWithAssignmentsHeader(documentId);

  return (
    <div className="PdfViewerWithAssignmentsHeader">
      <span className="filename">{filename}</span>

      {/* using native `button` because we don't need any of the antd button's features */}
      <button className="delete-button" onClick={onCLickDeleteFile} disabled={loading}>
        <Icon component={ICONS.bin} />
      </button>
    </div>
  );
};

export default PdfViewerWithAssignmentsHeader;
