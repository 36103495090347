/* tslint:disable */
/* eslint-disable */
/**
 * Impower ERP
 *  # Setup  Enquire a `Bearer Token` from Api Support  # Authentication  Use API calls with an `Authorization: Bearer TOKEN` header  # Webhooks  The application supports sending secured notification events to registered endpoints.  The events one may receive are sent as  ``` POST https://your.webhook.url/path/somewhere Authorization: Bearer TOKEN  {   \"connectionId\": 0,   \"entityType\": \"properties|contacts|invoices|etc.\",   \"entityId\": 0,   \"eventType\": \"CREATE|UPDATE|DELETE\" } ```  <!-- Style overrides --> <style> code {   display: block; } </style> 
 *
 * The version of the OpenAPI document: 2.206.f745640a
 * Contact: support+api@impower.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data that describes existing sync-data
 * @export
 * @interface SyncDataDto
 */
export interface SyncDataDto {
    /**
     * ID of connection to which the sync entity is linked
     * @type {number}
     * @memberof SyncDataDto
     */
    connectionId?: number;
    /**
     * Creation time of the entity.
     * @type {Date}
     * @memberof SyncDataDto
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof SyncDataDto
     */
    createdBy?: string;
    /**
     * ID of core entity marked for sync
     * @type {number}
     * @memberof SyncDataDto
     */
    entityId?: number;
    /**
     * External ID of the core entity
     * @type {string}
     * @memberof SyncDataDto
     */
    externalEntityId?: string;
    /**
     * Id of the entity.
     * @type {number}
     * @memberof SyncDataDto
     */
    id?: number;
    /**
     * The timestamp of the last data sync
     * @type {Date}
     * @memberof SyncDataDto
     */
    lastSyncTimestamp?: Date;
    /**
     * The last state of the sync data
     * @type {string}
     * @memberof SyncDataDto
     */
    state?: SyncDataDtoStateEnum;
    /**
     * Details of the last sync event of the entity
     * @type {string}
     * @memberof SyncDataDto
     */
    stateData?: string;
    /**
     * Human-readable description of the last sync process
     * @type {string}
     * @memberof SyncDataDto
     */
    stateText?: string;
    /**
     * Last update time of the entity.
     * @type {Date}
     * @memberof SyncDataDto
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof SyncDataDto
     */
    updatedBy?: string;
}

/**
* @export
* @enum {string}
*/
export enum SyncDataDtoStateEnum {
    READY = 'READY',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}


/**
 * Check if a given object implements the SyncDataDto interface.
 */
export function instanceOfSyncDataDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SyncDataDtoFromJSON(json: any): SyncDataDto {
    return SyncDataDtoFromJSONTyped(json, false);
}

export function SyncDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectionId': !exists(json, 'connectionId') ? undefined : json['connectionId'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'externalEntityId': !exists(json, 'externalEntityId') ? undefined : json['externalEntityId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lastSyncTimestamp': !exists(json, 'lastSyncTimestamp') ? undefined : (new Date(json['lastSyncTimestamp'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'stateData': !exists(json, 'stateData') ? undefined : json['stateData'],
        'stateText': !exists(json, 'stateText') ? undefined : json['stateText'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function SyncDataDtoToJSON(value?: SyncDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectionId': value.connectionId,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'createdBy': value.createdBy,
        'entityId': value.entityId,
        'externalEntityId': value.externalEntityId,
        'id': value.id,
        'lastSyncTimestamp': value.lastSyncTimestamp === undefined ? undefined : (value.lastSyncTimestamp.toISOString()),
        'state': value.state,
        'stateData': value.stateData,
        'stateText': value.stateText,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'updatedBy': value.updatedBy,
    };
}

