import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import './AddAgendaItemButton.scss';
import { OwnersMeetingProtocolContext } from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';
import AdditionalEntityButton from 'elements/Buttons/AdditionalEntityButton/AdditionalEntityButton';
import { useHistory, useLocation } from 'react-router';
import { OwnersMeetingProtocolDtoStatusEnum } from 'api/accounting';
import { translations as ownersMeetingInvitationTranslations } from '../../translations';

const AddAgendaItemButton = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const location = useLocation();
  const { ownersMeeting } = useContext(OwnersMeetingProtocolContext);
  const isDisabled = !ownersMeeting.data || (ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.SENT || ownersMeeting.data!.status === OwnersMeetingProtocolDtoStatusEnum.DELETED);

  const addAgendaItem = () => {
    history.push(`${location.pathname}/agenda-item/create`);
  };

  return (
    <AdditionalEntityButton
      label={tl(ownersMeetingInvitationTranslations.agendaItemsTable.addAgendaItem)}
      className="add-agenda-item"
      onClick={addAgendaItem}
      disabled={isDisabled}
    />
  );
};

export default AddAgendaItemButton;
