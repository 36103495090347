import React from 'react';
import './Amount.scss';


export type AmountColor = 'green' | 'red' | 'gray' | undefined;

  type AmountProps = {
  children: React.ReactNode,
  className?: string,
  color?: AmountColor,
}


const Amount = ({
  children,
  className: classNameProp,
  color,
}: AmountProps) => {
  const className = `AmountTypography ${classNameProp ?? ''} ${color ?? ''}`;

  return <span className={className}>{children}</span>;
};


Amount.defaultProps = {
  className: undefined,
  color: undefined,
};


export default Amount;
