import { DocumentDto } from 'api/public';
import { useContext } from 'react';
import { DocumentListContext } from './DocumentListContext';

export const useDocumentsListSort = () => {
  const documentListContext = useContext(DocumentListContext);

  if (documentListContext === undefined) {
    throw new Error('useDocumentsListSort must be used within a DocumentListContext');
  }

  const { sortState, setSortState } = documentListContext;

  const onChangeSort = (field: keyof DocumentDto) => {
    setSortState((currentState) => {
      const order: 1 | -1 = (currentState.field === field ? currentState.order * (-1) : 1) as 1 | -1;
      return {
        field,
        order,
      };
    });
  };

  return {
    sortField: sortState.field,
    sortOrder: sortState.order,
    onChangeSort,
  };
};
