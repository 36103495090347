import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import './TextInput.scss';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import InputProps from '../InputProps';
import { InfoIcon } from '../../../storybook-components/InfoIcon/InfoIcon';

interface TextInputProps extends Omit<InputProps<string>, 'value'> {
  validator?: RegExp,
  onClick?: (v: any) => void,
  value?: string,
  prefix?: string,
  maxLength?: number,
  showCount?: boolean,
  'data-testid'?: string,
}

export default function TextInput(props: TextInputProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    value, className, label, required, inputClassName, disabled, validationState, validationMessage, showPlaceholderWhenDisabled, validator, autoFocus, infoText, title,
    onClick, readOnly, onFocus, onBlur, defaultValue, prefix, maxLength, showCount,
  } = props;

  const onChange = (event: any) => {
    const { value } = event.target;

    if (!validator || validator.test(value)) {
      props.onChange(value);
    }
  };

  let placeholder = props.placeholder ? props.placeholder : tl(translations.elements.textInput.placeholder);
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }

  return (
    <div className={`TextInput ${className}`}>
      <Form.Item
        label={label ? (
          <span>
            <span>{`${label}${required ? ' *' : ''}`}</span>
            {infoText && <InfoIcon popupText={infoText} size="small" />}
          </span>
        ) : null}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <Input
          className={`Input ${inputClassName} ${disabled ? 'read-only' : ''}`}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          title={title}
          value={value}
          disabled={disabled || false}
          autoFocus={autoFocus}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
          prefix={prefix}
          maxLength={maxLength}
          showCount={showCount}
          data-testid={props['data-testid']}
        />
      </Form.Item>
    </div>
  );
}

TextInput.defaultProps = {
  required: false,
  className: '',
  inputClassName: '',
  prefix: '',
  maxLength: undefined,
  showCount: undefined,
};
