import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { EconomicPlanContext } from './EconomicPlanContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { EconomicPlanControllerApi } from '../../../api/accounting';
import { showNotification } from '../../../lib/Notification';
import { economicPlanTranslations } from '../economicPlanTranslations';
import { LanguageContext } from '../../../contexts/LanguageContext';


export const useDeleteEconomicPlan = () => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const economicPlanContext = useContext(EconomicPlanContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const { propertyHrId } = useParams<{ propertyHrId: string }>();

  if (economicPlanContext === undefined) {
    throw new Error('useEconomicDeleteEconomicPlan must be used within a EconomicPlanContextProvider');
  }
  const {
    setEconomicPlanList,
  } = economicPlanContext;

  const deleteEconomicPlan = (id: number) => {
    economicPlanControllerApi.deleteByIdUsingDELETE({ id })
      .then(() => {
        setEconomicPlanList(prevState => prevState.load(
          prevState.data.filter(it => it.id !== id),
        ));
        history.push(`/economic-plan/property/${propertyHrId}`);
        showNotification({
          key: 'deleteEconomicPlanSuccess',
          message: tl(economicPlanTranslations.notifications.deleteEconomicPlanSuccess.message),
          type: 'success',
        });
      })
      .catch(() => {
        showNotification({
          key: 'copyEconomicPlanError',
          message: tl(economicPlanTranslations.notifications.deleteEconomicPlanError.message),
          type: 'error',
        });
      });
  };

  return {
    deleteEconomicPlan,
  };
};
