import { CurrencyInput } from 'elements/Inputs/CurrencyInput/CurrencyInput';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Col, Row, Switch } from 'antd';
import { SPACE_SIZES } from 'lib/constants';
import CurrencyInputWithPrefix from 'elements/Inputs/CurrencyInputWithPrefix/CurrencyInputWithPrefix';
import { translations as globalTranslations } from 'elements/Translation/translations';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { PercentageInput } from 'elements/Inputs/PercentageInput/PercentageInput';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { Link } from 'react-router-dom';
import { translations } from '../../../../translations';
import { useNetAndVatAmount } from './useNetAndVatAmount';
import { useInvoiceBookingValidation } from '../../useInvoiceBookingValidation';

interface NetVatAmountProps {
    index: number,
    disabled?: boolean,
}


const NetAndVatAmount = ({
  index,
  disabled,
}: NetVatAmountProps) => {
  const {
    invoiceBookings, onChangeVatPercentage, changeInvoiceBookings, onChangeNetAmount, onChangeGrossAmount, vatOptions,
    displayVatEligibilityFields, onChangeVatEligibilityPercentage,
  } = useNetAndVatAmount(index);
  const { bookingValidationError } = useInvoiceBookingValidation(index);

  const invoiceBooking = invoiceBookings[index];
  const { tl } = useContext(LanguageContext);
  return (
  // vertical gutter is 10, for legacy reasons
    <>
      <Row gutter={[SPACE_SIZES.xLarge, 10]} className="legacy-row">
        {(index !== 0 || invoiceBookings?.length > 1)
          && (
            <Col span={24}>
              <CurrencyInputWithPrefix
                label={tl(translations.invoiceEditing.sections.section3.subsection3.fields.splitAmountP1)}
                value={Math.abs(invoiceBooking?.amount)}
                inputClassName="number-input-with-switch"
                autoFormat
                prefixComponent={(
                  <Switch
                    className="green-red-switch"
                    checkedChildren={tl(globalTranslations.pages.invoice.searchBar.directions.incoming)}
                    unCheckedChildren={tl(globalTranslations.pages.invoice.searchBar.directions.outgoing)}
                    checked={invoiceBookings[index].incoming}
                    onChange={() => changeInvoiceBookings({ type: 'updateSign', idx: index })}
                    disabled={index === 0 || disabled}
                  />
                )}
                onChange={val => onChangeGrossAmount(val)}
                required
                disabled={index === 0 || disabled}
                autoFocus={false}
              />
            </Col>
          )
        }
        <Col span={24}>
          <CurrencyInput
            className="input"
            label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.netAmount)}
            value={Math.abs(invoiceBooking?.netAmount)}
            onChange={val => onChangeNetAmount(val)}
            disabled={index === 0 || disabled}
          />
        </Col>
      </Row>
      <Row gutter={[SPACE_SIZES.xLarge, 10]} className="legacy-row">
        <Col className="legacy-col">
          <SelectInput
            size="large"
            allowClear
            showSearch
            className="input"
            label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.vatPercentage)}
            value={invoiceBooking?.vatPercentage}
            onChange={val => onChangeVatPercentage(val)}
            validationState={bookingValidationError?.vatPercentage?.validationState}
            validationMessage={bookingValidationError?.vatPercentage?.validationMessage}
            disabled={disabled}
            options={vatOptions}
            dropdownClassName="large-dropdown"
          />
        </Col>
        <Col className="legacy-col">
          <CurrencyInput
            className="input"
            label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.vatAmount)}
            value={Math.abs(invoiceBooking?.vatAmount)}
            onChange={() => {}}
            disabled
          />
        </Col>
      </Row>
      {
        displayVatEligibilityFields
        && (
          <Row gutter={[SPACE_SIZES.xLarge, 10]} className="legacy-row">
            <Col className="legacy-col">
              <PercentageInput
                className="input"
                label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.vatEligibilityPercentage)}
                infoText={(
                  <div>
                    {tl(translations.invoiceEditing.sections.section3.subsection2.fields.vatEligibilityTooltip)}
                    <Link target="_blank" to={{ pathname: articleUrls.vatEligibility }}>
                      {tl(translations.invoiceEditing.sections.section3.subsection2.fields.vatEligibilityTooltipLink)}
                    </Link>
                  </div>
                )}
                value={invoiceBooking?.vatEligibilityPercentage}
                onChange={val => onChangeVatEligibilityPercentage(val)}
                disabled={disabled}
              />
            </Col>
            <Col className="legacy-col">
              <CurrencyInput
                className="input"
                label={tl(translations.invoiceEditing.sections.section3.subsection2.fields.vatEligibilityAmount)}
                value={Math.abs(invoiceBooking?.vatEligibilityAmount)}
                onChange={() => {}}
                disabled
              />
            </Col>
          </Row>
        )
      }
    </>
  );
};

export default NetAndVatAmount;

NetAndVatAmount.defaultProps = {
  disabled: undefined,
};
