export const translations = {
  title: {
    en: 'Resolution Records',
    de: 'Beschlusssamlungen',
  },
  import: {
    en: 'Import',
    de: 'Importieren',
    description: {
      en: 'Please do not refresh the page until the process finishes',
      de: 'Bitte abwarten bis das Prozess erfolgreich endet. Bitte navigieren sie nicht weg.',
    },
    title: {
      en: 'Importing',
      de: 'Importieren',
    },
  },
  description: {
    en: 'List of resolution records to upload',
    de: 'Liste der Beschlusssamlungen zum hochladen.',
  },
  upload: {
    en: 'Upload Resolution Records',
    de: 'Beschlusssamlungen hochladen',
  },
  success: {
    en: 'Success',
    de: 'Erfolgreich',
  },
  error: {
    en: 'Failed',
    de: 'Fehler',
  },
  notifications: {
    propertyLoadError: {
      en: 'Error loading properties.',
      de: 'Objekte konnten nicht geladen werden.',
    },
    error: {
      title: { en: 'Error importing CSV.', de: 'Fehler.' },
      description: { en: 'Import failed half way through.', de: 'Import Prozess fehlgeschlagen.' },
    },
  },
  validations: {
    success: {
      en: 'Resolution records have been verified and ready for import.',
      de: 'Beschlüsse wurden geprüft und sind bereit zum Importieren',
    },
    errors: {
      en: 'Issues found in the CSV',
      de: 'Es wurden Fehlern gefunden',
    },
    issues: {
      propertyIdInternal: {
        en: 'Invalid property number',
        de: 'Ungültiges Objektsreferenz',
      },
      resolutionRecordNumber: {
        en: 'Invalid resolution record number',
        de: 'Ungültiges Beschluss Nummer',
      },
      resolutionRecordStatus: {
        en: 'Invalid status',
        de: 'Ungültiges Status',
      },
      resolutionTitle: {
        en: 'Invalid title',
        de: 'Ungültiges Titel',
      },
      resolutionText: {
        en: 'Invalid text',
        de: 'Ungültiges Beschlussfassungstext',
      },
      date: {
        en: 'Invalid date',
        de: 'Ungültiges Datum',
      },
    },
  },
};
