import { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import moment from 'moment';
import { DATE_FORMAT } from 'lib/Utils';
import { Tooltip } from 'antd';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { LanguageContext } from '../../../../contexts/LanguageContext';

export const useProfitAndLossReportColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.table.property),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.table.name),
      dataKey: 'name',
    },
    {
      title: tl(translations.table.dateRange),
      dataKey: 'dateRange',
      sortable: false,
      render: ((_, record) => `${moment(record.startDate).format(DATE_FORMAT)} - ${moment(record.endDate).format(DATE_FORMAT)}`),
      defaultWidth: 220,
    },
    {
      title: tl(translations.table.accountant),
      dataKey: 'accountant',
      defaultWidth: 220,
      sortable: false,
    },
    {
      title: tl(translations.table.administrator),
      dataKey: 'administrator',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(translations.table.createdOn),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
    {
      title: tl(translations.table.createdBy),
      dataKey: 'createdBy',
      defaultVisible: false,
      sortable: false,
    },
  ];
};
