import { useContext } from 'react';

import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { formatCurrency } from 'lib/Utils';
import _ from 'lodash';

const invoiceHtmlText = 'Eine neue Rechnung mit der Nummer {invoiceNumber} über die Summe von {totalGross} (brutto) von <span style="color: #AAA;">{counterpartName}</span> wurde eingetragen.<br>'
+ '<br>'
+ 'Rechnungsteller: {counterpartName}<br>'
+ 'Rechnungsnummer: {invoiceNumber}<br>'
+ 'Bruttosumme: EUR {totalGross}<br>'
+ '<br>'
+ 'Impower: <a href="https://app.impower.de/invoice/edit/{invoiceHrId}" target="_blank">{invoiceHrId}</a><br>';
const additionalInvoiceHtmlComment = '<br>'
+ 'Notizen:<br>'
+ '{comment}';

const invoiceText = 'Eine neue Rechnung mit der Nummer {invoiceNumber} über die Summe von {totalGross} (brutto) von {counterpartName} wurde eingetragen. \n'
+ 'Rechnungsteller: {counterpartName} \n'
+ 'Rechnungsnummer: {invoiceNumber} \n'
+ 'Bruttosumme: EUR {totalGross} \n'
+ 'Impower: https://app.impower.de/invoice/edit/{invoiceHrId} \n';
const additionalInvoiceComment = ' Notizen: {comment}';


export function useGetInvoiceCommentForPortal(isHtml: boolean) {
  const invoiceEditingContext = useContext(InvoiceEditingContext);
  const counterpartName = invoiceEditingContext.data.invoice?.counterpartName ?? '—';
  const invoiceHrId = invoiceEditingContext.data.invoice?.invoiceHrId;
  const invoiceNumber = invoiceEditingContext.data.invoice?.invoiceNumber ?? '—';
  const totalGross = invoiceEditingContext.data.invoice?.totalGross ? formatCurrency(Math.abs(invoiceEditingContext.data.invoice?.totalGross), '-', true) : '—';

  const getCommentText = (comment?: string) => {
    const textWithPlaceholders = isHtml ? invoiceHtmlText : invoiceText;
    let fullComment = textWithPlaceholders
      .replaceAll('{invoiceNumber}', invoiceNumber)
      .replaceAll('{totalGross}', totalGross)
      .replaceAll('{counterpartName}', counterpartName)
      .replaceAll('{invoiceHrId}', invoiceHrId);

    if (!_.isEmpty(comment)) {
      const commentWithPlaceholders = isHtml ? additionalInvoiceHtmlComment : additionalInvoiceComment;
      fullComment += commentWithPlaceholders.replaceAll('{comment}', comment);
    }
    return fullComment;
  };


  return {
    getCommentText,
  };
}
