export const translations = {
  header: {
    rightSideComponent: {
      uploadButton: {
        en: 'Upload',
        de: 'Hochladen',
      },
    },
  },
  table: {
    header: {
      createdOn: {
        en: 'Created on',
        de: 'Erstellt am',
      },
      createdBy: {
        en: 'Created by',
        de: 'Erstellt von',
      },
      administrator: {
        en: 'Administrator',
        de: 'Objektbetreuer',
      },
      accountant: {
        en: 'Accountant',
        de: 'Buchhalter',
      },
      title: {
        en: 'Title',
        de: 'Titel',
      },
      tag: {
        en: 'Tags',
        de: 'Kategorie',
      },
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      unit: {
        en: 'Unit',
        de: 'Einheit',
      },
      contract: {
        en: 'Contract',
        de: 'Vertrag',
      },
      contact: {
        en: 'Contact',
        de: 'Kontakt',
      },
      date: {
        en: 'Date',
        de: 'Datum',
      },
      value: {
        en: 'Value',
        de: 'Wert',
      },
      lastUpdated: {
        en: 'Last edited',
        de: 'Zuletzt bearbeitet',
      },
    },
  },
  filter: {
    createdBy: {
      en: 'Created by',
      de: 'Ersteller',
    },
    propertyName: {
      en: 'Property name',
      de: 'Objektbezeichnung',
    },
    propertyIdInternal: {
      en: 'Internal property ID',
      de: 'Objekt-Nr Intern',
    },
    title: {
      en: 'Title',
      de: 'Titel',
    },
    tagName: {
      en: 'Tag name',
      de: 'Kategorie Name',
    },
    date: {
      en: 'Date',
      de: 'Datum',
    },
    minDate: {
      en: 'Min Date',
      de: 'Min Datum',
    },
    maxDate: {
      en: 'Max Date',
      de: 'Max Datum',
    },
    administrator: {
      en: 'Administrator',
      de: 'Objektbetreuer',
    },
    accountant: {
      en: 'Accountant',
      de: 'Objektbuchhalter',
    },
    contract: {
      en: 'Contract',
      de: 'Vertrag',
    },
    contactId: {
      en: 'Contact Id',
      de: 'Kontakt Id',
    },
  },
  actions: {
    download: {
      en: 'Download',
      de: 'Herunterladen',
    },
    edit: {
      en: 'Edit',
      de: 'Bearbeiten',
    },
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
    openSource: {
      en: 'Open Source',
      de: 'Quelle öffnen',
    },
  },
  notification: {
    loadDocumentsList: {
      failure: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load list of documents.',
          de: 'Dokumente konnten nicht geladen werden.',
        },
      },
    },
    downloadDocuments: {
      failure: {
        message: {
          en: 'Document download error',
          de: 'Fehler beim herunterladen des Dokuments.',
        },
        description: {
          en: 'Failed to download document',
          de: 'Dokumente konnten nicht heruntergeladen werden.',
        },
      },
    },
    deleteDocument: {
      success: {
        message: {
          en: 'Document deleted successfully.',
          de: 'Dokument wurde erfolgreich gelöscht.',
        },
      },
      failure: {
        message: {
          en: 'Document couldn´t be deleted.',
          de: 'Dokument konnte nicht gelöscht werden.',
        },
      },
    },
  },
};
