import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { ValidateStatuses } from 'lib/Utils';
import { useContext } from 'react';

export interface ValidationErrorValue {
  state: (typeof ValidateStatuses)[number],
  errorMessage: string,
}

export interface ValidationErrors {
  [key: string]: ValidationErrorValue,
}
export const useBankAccountValidations = (validationErrors: ValidationErrors | undefined, bankAccountIndex: number) => {
  const { tl } = useContext(LanguageContext);


  const getValidationErrors = () => {
    const newErrors: ValidationErrors = {};
    Object.entries(validationErrors ?? {})
      .filter(([key]) => key.includes(`bankAccounts[${bankAccountIndex}]`))
      .forEach(([key, val]) => {
        newErrors[key.replace(`bankAccounts[${bankAccountIndex}].`, '')] = {
          state: 'error',
          // @ts-ignore
          errorMessage: tl(translations.validations[val] || translations.validations.defaultError),
        };
      });

    return newErrors;
  };
  const bankAccountValidationErrors = getValidationErrors();

  return {
    validationErrors: bankAccountValidationErrors,
  };
};
