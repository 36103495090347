import {
  useContext,
} from 'react';
import {
  ExchangeControllerApi,
} from 'api/accounting';
import { AuthContext } from '../../../contexts/AuthContext';
import { NotificationObject, showNotification } from '../../../lib/Notification';
import { translations } from '../../../elements/Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';

export function useRevertExchange() {
  const { apiConfiguration } = useContext(AuthContext);
  const exchangeControllerApi = new ExchangeControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);

  const onRevertExchange = (exchangeId: number) => new Promise((onSuccess, onFailure) => {
    exchangeControllerApi.revertExchangeUsingPOST({ exchangeId })
      .then(() => {
        const revertSuccessMessage: NotificationObject = {
          key: 'revertPostingSuccess',
          message: tl(translations.notifications.postingListContext.revertSuccess.message),
          type: 'success',
        };
        showNotification(revertSuccessMessage);
        onSuccess(exchangeId);
      })
      .catch((ex: any) => {
        console.error('Failed to revert exchange', ex);
        const revertErrorMessage: NotificationObject = {
          key: 'revertPostingError',
          message: tl(translations.notifications.postingListContext.revertError.message),
          type: 'error',
        };
        showNotification(revertErrorMessage);
        onFailure();
      });
  });

  return {
    onRevertExchange,
  };
}
