import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import DEFAULT_DATA from '../../../../../../lib/data';
import { PropertySimpleDto } from '../../../../../../api/accounting/models';
import { showNotification } from '../../../../../../lib/Notification';
import { translations as globalTranslations } from '../../../../../../elements/Translation/translations';
import { PropertyLegacyControllerApi } from '../../../../../../api/accounting/apis';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';

export const usePropertySuggestions = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  const [propertyList, setPropertyList] = useState(DEFAULT_DATA<PropertySimpleDto[]>([]));
  const [selectedProperty, setSelectedProperty] = useState<PropertySimpleDto | null>(null);
  const [selectedPropertyHrId, setSelectedPropertyHrId] = useState<string | null>(null);

  useEffect(() => {
    if (selectedPropertyHrId) loadPropertyDisplayByHrId();
    else setSelectedProperty(null);
  }, [selectedPropertyHrId]);

  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const loadPropertyList = async (filter: string) => {
    if (filter && filter.length && filter.length === 0) {
      setPropertyList(state => state.load([]));
      return;
    }
    setPropertyList(propertyList.startLoading());
    try {
      const response = await propertyControllerApi.findPropertiesByNameAndHrIdAndInternalIdUsingGET({
        filter,
      });
      setPropertyList(state => state.load(response));
    } catch (error) {
      setPropertyList(state => state.failed());
      showNotification({
        key: 'loadPropertyListError',
        message: tl(globalTranslations.notifications.propertyListContext.loadError.message),
        description: tl(globalTranslations.notifications.propertyListContext.loadError.description),
        type: 'warning',
      });
    }
  };

  const loadPropertyDisplayByHrId = async () => {
    if (!selectedPropertyHrId) return;
    try {
      // TODO migrate to another standardized property call
      const response = await propertyControllerApi.getPropertySimpleByHrIdUsingGET({ propertyHrId: selectedPropertyHrId });
      setSelectedProperty(response);
    } catch (e) {
      showNotification({
        key: 'loadPropertyListError',
        message: tl(globalTranslations.notifications.propertyEditingContext.loadError.message),
        description: tl(globalTranslations.notifications.propertyEditingContext.loadError.description),
        type: 'warning',
      });
    }
  };


  const setSelectedPropertyByIdAndNavigate = (propertyId: number) => {
    try {
      const property = propertyList.data!.filter(prp => prp.id === propertyId)[0];
      const url = `/properties/${property.propertyHrId}/edit`;
      history.push(url);
      setSelectedProperty(property);
    } catch (e) {
      console.error('Property not found');
    }
  };

  return {
    propertyList,
    setPropertyList,
    selectedProperty,
    setSelectedProperty,
    setSelectedPropertyHrId,
    setSelectedPropertyByIdAndNavigate,
    loadPropertyList,
  };
};
