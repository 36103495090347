import React, { useContext } from 'react';
import { ArrowRightOutlined, SyncOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from '../../../translations';
import './SeparatingColumnIcon.scss';


const SeparatingColumnIcon = ({ record }: any) => {
  const { tl } = useContext(LanguageContext);

  if (record.childRows) {
    if (record.houseMoneyAmount && record.reserveFundAmount) {
      return <span className="separating-dot">•</span>;
    }
    return null;
  }

  if (
    record.sumToAllocate !== undefined
    && record.previouslyAllocatedAmount !== undefined
    && record.sumToAllocate !== record.previouslyAllocatedAmount
  ) {
    return (
      <Tooltip className="SeparatingColumnIcon" title={tl(translations.bankTransactionAllocation.unitAccountTableHeader.iconTooltip)}>
        <SyncOutlined />
      </Tooltip>
    );
  }

  return <ArrowRightOutlined />;
};


export default SeparatingColumnIcon;
