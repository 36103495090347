import { PropertyDisplayDtoAdministrationTypeEnum, PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';


export enum PropertyAdministrationTypeMapped {
  WEG = 'WEG',
  MV = 'MV',
  SEV = 'SEV',
}

type AdministrationType = PropertyDisplayDtoAdministrationTypeEnum | PropertyLegacyDtoAdministrationTypeEnum;

type PropertyWegAndMvAdministrationType = Pick<typeof PropertyAdministrationTypeMapped, 'WEG' | 'MV'>;

export const PropertyWegAndMvAdministrationTypes:PropertyWegAndMvAdministrationType = {
  WEG: PropertyAdministrationTypeMapped.WEG,
  MV: PropertyAdministrationTypeMapped.MV,
};


export const PropertyWegAndMvAndSevMvAdministrationTypes = {
  WEG: PropertyLegacyDtoAdministrationTypeEnum.WEG,
  MV: PropertyLegacyDtoAdministrationTypeEnum.MV,
  SEV_MV: PropertyLegacyDtoAdministrationTypeEnum.SEV_MV,
};

export const mapPropertyAdministrationTypeToFrontend = (administrationType: AdministrationType) => {
  if (administrationType === undefined) return undefined;
  if (administrationType === PropertyLegacyDtoAdministrationTypeEnum.SEV_MV) {
    return PropertyAdministrationTypeMapped.SEV;
  }

  return PropertyAdministrationTypeMapped[administrationType];
};
