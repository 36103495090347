import { useContext } from 'react';
import { useSectionManager } from '../../../../services/SectionManager/useSectionManager';
import { useSaveUnitFiguresEconomicPlan } from './useSaveUnitFiguresEconomicPlan';
import { useLoadUnitFiguresEconomicPlan } from './useLoadUnitFiguresEconomicPlan';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';


export const useDirtModalNavigation = () => {
  const { onOpenSpecificSection } = useSectionManager();
  const { dirty: isDirty, showModal } = useSimpleDirtModal();
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useDirtModalNavigation can only be used within EconomicPlanContextProvider');
  }
  const { economicPlan } = economicPlanContext;

  const { save } = useSaveUnitFiguresEconomicPlan();
  const { loadUnitFiguresEconomicPlan } = useLoadUnitFiguresEconomicPlan();

  const onDiscard = () => {
    loadUnitFiguresEconomicPlan(economicPlan?.data?.id);
    return Promise.resolve();
  };

  const handleOpenSectionChange = (nextSectionId: number): void => {
    if (isDirty) {
      showModal({
        onSave: save,
        onProceedCallback: () => onOpenSpecificSection(nextSectionId),
        onDiscard,
      });
    } else {
      onOpenSpecificSection(nextSectionId);
    }
  };

  return {
    handleOpenSectionChange,
  };
};
