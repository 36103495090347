import {
  useContext,
  useEffect,
} from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import moment from 'moment';

import {
  FindFilteredPropertiesUsingGETAdministrationTypesEnum,
  OwnersMeetingProtocolDtoOwnersMeetingInvitationTypeEnum,
} from '../../../../../api/accounting';
import {
  PropertyListContext,
} from '../../../../../contexts/PropertyListContext';
import {
  OverlayContext,
} from '../../../../../services/OverlayContext/OverlayContext';
import {
  useCheckPropertyValidity,
} from '../../../../../services/Property/useCheckPropertyValidity';
import usePropertyOptions from '../../../../../services/usePropertyOptions';
import { OwnersMeetingProtocol } from '../../../interfaces';
import {
  OwnersMeetingProtocolContext,
} from '../../../services/OwnersMeetingProtocolContext';
import {
  translations as ownersMeetingProtocolTranslations,
} from '../../../translations';
import defaultSection from '../components/DefaultSection';
import { useDefaultProtocolTexts } from './useDefaultProtocolTexts';

interface ProtocolGeneralDataProps {
  index: number,
  setDirty: (val: boolean) => void,
}

export default function useProtocolGeneralDataSection({ index, setDirty }: ProtocolGeneralDataProps) {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);
  const { setSelectedDisplayPropertyId } = useContext(PropertyListContext);
  const { propertyOptions, searchForProperty, onLoadProperty } = usePropertyOptions(true);
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const { ownersMeeting, updateOwnersMeetingProtocol } = useContext(OwnersMeetingProtocolContext);
  const { getDefaultProtocolText } = useDefaultProtocolTexts();

  useEffect(() => {
    const { propertyId } = ownersMeeting.data;
    setSelectedDisplayPropertyId(propertyId);

    if (propertyId === undefined) return;
    const matchingPropertyOption = propertyOptions.find(po => po.value === propertyId)!;

    if (matchingPropertyOption === undefined) {
      // it is being loaded
      return;
    }
    const { propertyHrId } = matchingPropertyOption;
    checkPropertyValidity({ propertyHrId: propertyHrId!, onCancel: () => { setDirty(false); goBack(); } });
  }, [ownersMeeting.data.propertyId, propertyOptions]);

  const getProtocolTitle = (value: OwnersMeetingProtocol) => {
    const selectedPropertyName = propertyOptions.find(po => po.value === value.propertyId)?.simpleLabel;
    let propertyName = `${selectedPropertyName ?? ''}`?.replaceAll(', ', '_');
    propertyName = propertyName?.replaceAll(',', '_');
    const date = value?.date ? `, ${moment(value?.date).format('DD.MM.YYYY')}` : '';

    const meetingType = value?.ownersMeetingInvitationType ? `, ${tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.typeSection.typeOptions[value?.ownersMeetingInvitationType.toLowerCase()])}` : '';

    return `${propertyName}${meetingType}${date}`;
  };

  const customOnChange = (key: keyof OwnersMeetingProtocol, value: OwnersMeetingProtocol[keyof OwnersMeetingProtocol]) => {
    updateOwnersMeetingProtocol((prev) => {
      if (key !== 'titleOfProtocol' && !prev.isTitleTouched) {
        const newState = { ...prev, [key]: value };
        return { ...newState, titleOfProtocol: getProtocolTitle(newState) };
      }
      return ({ ...prev, [key]: value, isTitleTouched: true });
    });
  };

  return {
    ...defaultSection(index, index),
    sectionTitle: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.title),
    sectionId: 'protocolGeneralData',
    content: [
      {
        title: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.title),
        sectionId: 'protocolGeneralDataForm',
        content: [
          [
            {
              type: 'smartSearch',
              key: 'propertyId',
              props: {
                required: true,
                label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.property),
                searchFunction: (filter) => { searchForProperty(filter, [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG]); },
                getOneFunction: onLoadProperty,
                options: propertyOptions,
                // if the ETV Protocol has already been saved, property change should not be possible
                disabled: !!ownersMeeting.data.id && !!ownersMeeting.data.propertyId,
                onChange: (value: any) => customOnChange('propertyId', value),
              },
            },
          ],
          [
            {
              type: 'date',
              key: 'date',
              props: {
                required: true,
                label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.date),
                onChange: (value: any) => {
                  customOnChange('date', value);
                },
              },
            },
            {
              type: 'radioGroup',
              key: 'ownersMeetingInvitationType',
              props: {
                onChange: (value: any) => customOnChange('ownersMeetingInvitationType', value),
                label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.typeSection.title),
                options: [
                  {
                    value: OwnersMeetingProtocolDtoOwnersMeetingInvitationTypeEnum.REGULAR,
                    label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.typeSection.typeOptions.regular),
                  },
                  {
                    value: OwnersMeetingProtocolDtoOwnersMeetingInvitationTypeEnum.EXTRAORDINARY,
                    label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.typeSection.typeOptions.extraordinary),
                  },
                ],
                required: true,
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'meetingLocation',
              props: {
                label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.location),
                onChange: (value: any) => customOnChange('meetingLocation', value),
              },
            },
            {
              type: 'text',
              key: 'meetingRoom',
              props: {
                label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.room),
                onChange: (value: any) => customOnChange('meetingRoom', value),
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'titleOfProtocol',
              props: {
                required: true,
                label: tl(ownersMeetingProtocolTranslations.editPage.sections.generalData.protocolTitle),
                onChange: (value: any) => customOnChange('titleOfProtocol', value),
              },
            },
          ],
        ],
      },
    ],
  };
}
