import { DUPLICATE_STATUS_CODE, InvoiceEditingContext, InvoiceInfo } from 'contexts/InvoiceEditingContext';
import { InvoiceListContext } from 'contexts/InvoiceListContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext, useMemo } from 'react';
import { invoiceListTranslations } from 'pages/Invoice/InvoiceList/translations';
import { useParams } from 'react-router';
import { getInvoiceEditingRoute } from 'pages/Invoice/routes';
import _ from 'lodash';
import { Modal } from 'antd';
import { CompanyEditingContext } from 'contexts/CompanyEditingContext';
import { InvoiceEpoStateEnum } from 'api/accounting';
import { setValue } from 'lib/Utils';
import { useLoadInvoice } from './useLoadInvoice';
import { translations as invoiceEditingTranslations } from '../../translations';
import { AfterSaveAction, useInvoiceEditorMiscDataContext } from './InvoiceEditorMiscDataContext';

const bookingEditableStates: string[] = [
  InvoiceEpoStateEnum.APPROVED.toString(),
];


export const useInvoiceEditorForm = () => {
  const { invoiceHrId } = useParams<{ invoiceHrId: string }>();

  const invoiceEditingContext = useContext(InvoiceEditingContext);
  const invoiceListContext: any = useContext(InvoiceListContext);
  const companyEditingContext: any = useContext(CompanyEditingContext);

  const { tl } = useContext(LanguageContext);
  const {
    isDirty,
    setDirty,
    setEditingBookings,
  } = invoiceEditingContext;

  const {
    setDirtModalVisible,
    openSectionIndex, setOpenSectionIndex,
    dirtModalSection, setDirtModalSection,
    actionAfterSaveRef,
  } = useInvoiceEditorMiscDataContext('useInitializeInvoiceEditor');

  const { onLoadInvoice } = useLoadInvoice();


  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (openSectionIndex === nextSectionIndex) {
      // if the clicked section is the current one, close the sections
      nextSectionIndex = -1;
    }
    if (isDirty) {
      setDirtModalVisible(true);
      setDirtModalSection(nextSectionIndex);
    } else {
      setOpenSectionIndex(nextSectionIndex);
    }
  };

  const saveAsDraft = () => {
    invoiceListContext.addInvoiceToList(invoiceEditingContext.data.invoice);
    save(AfterSaveAction.NAVIGATE_BACK);
  };

  const save = (actionAfterSave: AfterSaveAction) => {
    actionAfterSaveRef.current = actionAfterSave;
    setDirty(false);
    invoiceEditingContext.onSaveInvoice(invoiceHrId, openSectionIndex + 1);
  };

  const approveInvoice = () => {
    invoiceEditingContext.setInvoiceState(invoiceEditingContext.startLoading());
    invoiceEditingContext.onApproveInvoice(invoiceHrId)
      .catch(() => invoiceEditingContext.setInvoiceState(invoiceEditingContext.failed()));
  };

  const getModalContent = err => (
    <>
      <p>
        {tl(invoiceEditingTranslations.invoiceEditing.duplicateWarning.messagePart1)}
      </p>
      <p>
        <ul>
          {Object.entries(err.duplicateInvoices)
            .map(([hrId, invoiceInfo]: [string, InvoiceInfo]) => {
              const invoiceIdentifier = !_.isEmpty(invoiceInfo.invoiceNumber) ? invoiceInfo.invoiceNumber : hrId;
              return (
                <li key={invoiceInfo.invoiceNumber}>
                  <a href={getInvoiceEditingRoute({ invoiceHrId: hrId })} target="_blank" rel="noopener noreferrer">
                    {`${tl(invoiceListTranslations.table.states[invoiceInfo.state])} · ${invoiceIdentifier}`}
                  </a>
                </li>
              );
            })}
        </ul>
      </p>
      <p>{tl(invoiceEditingTranslations.invoiceEditing.duplicateWarning.messagePart2)}</p>
    </>
  );

  const onClickApproveInvoice = () => {
    invoiceEditingContext.setInvoiceState(invoiceEditingContext.startLoading());
    invoiceEditingContext.onDuplicateCheck()
      .then(() => {
        invoiceEditingContext.onApproveInvoice(invoiceHrId)
          .catch(() => invoiceEditingContext.setInvoiceState(invoiceEditingContext.failed()));
      })
      .catch((err: any) => {
        invoiceEditingContext.setInvoiceState(invoiceEditingContext.failed());

        if (err.status === DUPLICATE_STATUS_CODE) {
          Modal.confirm({
            title: tl(invoiceEditingTranslations.invoiceEditing.duplicateWarning.title),
            content: getModalContent(err),
            okText: tl(invoiceEditingTranslations.invoiceEditing.duplicateWarning.confirm),
            cancelText: tl(invoiceEditingTranslations.invoiceEditing.duplicateWarning.cancel),
            okButtonProps: { className: 'Button', disabled: invoiceEditingContext.loading },
            cancelButtonProps: { className: 'Button' },
            onOk: () => approveInvoice(),
            closable: true,
            maskClosable: true,
            width: 512,
          });
        }
      });
  };

  const handleDirtModalDiscard = (): void => {
    setDirtModalVisible(false);
    setDirty(false);
    if (invoiceHrId) {
      onLoadInvoice(invoiceHrId);
    } else {
      invoiceEditingContext.clearInvoice();
    }

    if (openSectionIndex === dirtModalSection) {
      setOpenSectionIndex(-1);
    } else {
      setOpenSectionIndex(dirtModalSection);
    }
  };

  const handleDirtModalSave = (): void => {
    actionAfterSaveRef.current = AfterSaveAction.OPEN_NEXT_SECTION;
    invoiceEditingContext.onSaveInvoice(invoiceHrId, openSectionIndex + 1);
  };

  const handleConfirmationModalDiscard = (): void => {
    setEditingBookings(false);
    setDirty(false);
    onLoadInvoice(invoiceHrId);
    setOpenSectionIndex(-1);
  };

  const handleConfirmationModalSave = (): void => {
    invoiceEditingContext.onSaveInvoice(invoiceHrId, 3);
  };

  const autoSelectContact = (contact: any, key: string) => {
    const newInvoice = { ...invoiceEditingContext.data.invoice };
    if (key.includes('Id')) {
      setValue(newInvoice, key, contact.id);
    } else {
      setValue(newInvoice, key, contact);
    }
    setDirty(true);
    invoiceEditingContext.updateInvoiceState({ invoice: newInvoice });
  };
  const setAutoSelectCompany = (key: string) => {
    companyEditingContext.setAutoSelectCompanyAfterCreation(() => (contact: any) => autoSelectContact(contact, key));
  };

  let invoiceState: InvoiceEpoStateEnum;
  try {
    invoiceState = invoiceEditingContext.data.invoice.state;
  } catch (e) {
    invoiceState = InvoiceEpoStateEnum.NEW;
  }

  const editable = useMemo(() => !invoiceHrId || invoiceEditingContext.data.invoice.state === InvoiceEpoStateEnum.NEW || invoiceEditingContext.data.invoice.state === InvoiceEpoStateEnum.READY_FOR_APPROVAL, [invoiceHrId, invoiceEditingContext.data]);

  const bookingsEditable = useMemo(() => invoiceHrId && bookingEditableStates.includes(invoiceEditingContext.data.invoice.state),
    [invoiceHrId, invoiceEditingContext.data]);

  const isCreditNote = useMemo(() => invoiceEditingContext.data?.invoice?.totalNet > 0, [invoiceEditingContext.data]);

  const handleStopRecurring = () => {
    invoiceListContext.onStopRecurringInvoice(invoiceEditingContext.data.invoice.id);
  };


  return {
    editable,
    bookingsEditable,
    isCreditNote,
    invoiceState,
    save,
    handleOpenSectionChange,
    saveAsDraft,
    handleDirtModalSave,
    handleDirtModalDiscard,
    handleConfirmationModalSave,
    handleConfirmationModalDiscard,
    handleStopRecurring,
    onClickApproveInvoice,
    setAutoSelectCompany,
  };
};
