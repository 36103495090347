import { LanguageContext } from 'contexts/LanguageContext';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { DATE_MATCHER, DECIMAL_MATCHER, NUMBER_MATCHER } from 'lib/Utils';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { translations } from '../../components/Header/translations';
import { BreadcrumbInterface } from '../../components/Header/components/SearchBar/components/Breadcrumbs/Breadcrumbs';
import { Filter, SuggestedFilter } from '../../components/Header/components/SearchBar/services/GlobalSearchContext';


export const useSearchBarFilters = (breadcrumbs: SuggestedFilter[] | BreadcrumbInterface[], availableFilters: SuggestedFilter[]) => {
  const { tl } = useContext(LanguageContext);
  const [suggestedFilters, setSuggestedFilters] = useState<SuggestedFilter[]>([]);
  const [currentlyEditedFilter, setCurrentlyEditedFilter] = useState<SuggestedFilter | null>(null);


  const addAllEnumValuesToSuggestions = (newSuggestedFilters: SuggestedFilter[], filter: SuggestedFilter, limitOptions: boolean) => {
    if (!filter.options) {
      console.error("Options are missing from 'enum' type filter");
      return;
    }
    const limit = limitOptions ? 2 : filter.options.length;
    const alreadyActiveEnumValues = breadcrumbs.filter(bc => bc.key === filter.key).map(bc => bc.matchingValue);
    filter.options
      .filter(option => !alreadyActiveEnumValues.includes(option.value))
      .slice(0, limit).forEach((option: SelectOption) => {
        newSuggestedFilters.push({
          ...filter,
          matchingValue: option.value,
        });
      });
  };

  const addAllMatchingEnumValuesToSuggestions = (newSuggestedFilters: SuggestedFilter[], filter: SuggestedFilter, matchingFilterValues: string[]) => {
    if (!filter.options) {
      console.error("Options are missing from 'enum' type filter");
      return;
    }

    const alreadyActiveEnumValues = breadcrumbs.filter(bc => bc.key === filter.key).map(bc => bc.matchingValue);
    filter.options
      .filter(option => !alreadyActiveEnumValues.includes(option.value))
      .forEach((option: SelectOption) => {
        if (matchingFilterValues.includes(option.value)) {
          newSuggestedFilters.push({
            ...filter,
            matchingValue: option.value,
          });
        }
      });
  };

  const addAllBooleanValuesToSuggestions = (newSuggestedFilters: SuggestedFilter[], filter: SuggestedFilter) => {
    newSuggestedFilters.push({
      ...filter,
      matchingValue: 'true',
    });
    newSuggestedFilters.push({
      ...filter,
      matchingValue: 'false',
    });
  };
  const updateSuggestedFilters = (value: string) => {
    const newSuggestedFilters: SuggestedFilter[] = [];
    availableFilters.forEach((filter: SuggestedFilter) => {
      const matchingValue = shouldSuggestFilter(filter, value);
      if (!_.isNull(matchingValue)) {
        if (filter.type === 'enum') {
          if (_.isEmpty(matchingValue)) {
            const limitOptions = filter.visibleAllOptions ? false : _.isEmpty(value);
            addAllEnumValuesToSuggestions(newSuggestedFilters, filter, limitOptions);
          } else {
            addAllMatchingEnumValuesToSuggestions(newSuggestedFilters, filter, matchingValue as string[]);
          }
        } else if (_.isEmpty(matchingValue) && filter.type === 'boolean') {
          addAllBooleanValuesToSuggestions(newSuggestedFilters, filter);
        } else {
          newSuggestedFilters.push({
            ...filter,
            matchingValue,
          });
        }
      }
    });
    setSuggestedFilters(newSuggestedFilters);
  };

  const shouldSuggestFilter = (filter: Filter, value: string): string | string[] | null => {
    // show all for empty input
    if (_.isEmpty(value)) {
      return '';
    }

    if (filter.name.toLowerCase().includes(value.toLowerCase())) {
      return '';
    }

    if (filter.matcher) {
      return filter.matcher.test(value) ? value : null;
    }

    const isDate = DATE_MATCHER.test(value);
    switch (filter.type) {
    case 'text':
      return !isDate ? value : null;
    case 'number':
      return !isDate && NUMBER_MATCHER.test(value) ? value : null;
    case 'decimal':
      return !isDate && DECIMAL_MATCHER.test(value) ? value : null;
    case 'date':
      return isDate ? value : null;
    case 'boolean':
      if ((filter.trueLabel || tl(translations.searchBar.filter.booleanValues.true)).toLowerCase().startsWith(value.toLowerCase())) {
        return 'true';
      }
      if ((filter.falseLabel || tl(translations.searchBar.filter.booleanValues.false)).toLowerCase().startsWith(value.toLowerCase())) {
        return 'false';
      }
      return null;
    case 'enum': {
      try {
        const alreadyActiveEnumValues = breadcrumbs.filter(bc => bc.key === filter.key).map(bc => bc.matchingValue);
        const matchingOptions = filter.options!
          .filter((option: SelectOption) => !alreadyActiveEnumValues.includes(option.value))
          .filter((option: SelectOption) => option.label.toLowerCase().includes(value.toLowerCase()));
        if (matchingOptions.length > 0) {
          return matchingOptions.map(option => option.value);
        }
      } catch (e) {
        console.error("Options are missing from 'enum' type filter", e);
      }
      return null;
    }
    default:
      return null;
    }
  };

  return {
    suggestedFilters, setSuggestedFilters, updateSuggestedFilters, currentlyEditedFilter, setCurrentlyEditedFilter,
  };
};
