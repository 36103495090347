import React, { useContext } from 'react';
import defaultSection from '../../../../Property/PropertyEditing/sections/defaultSection';
import AddAgendaItemButton from './components/AddAgendaItemButton/AddAgendaItemButton';
import AgendaItemsTable from './components/AgendaItemsTable';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { ownersMeetingInvitationTranslations } from '../../translations/OwnersMeetingInvitationTranslations';

interface AgendaTopicsSectionProps {
  index: number,
  openNextSection:()=>void,
  isDisabled: boolean,
  showAddButton: boolean,
  disabled: boolean
}

export const useAgendaItemsSection = ({
  index, openNextSection, isDisabled, showAddButton, disabled,
}: AgendaTopicsSectionProps) => {
  const { tl } = useContext(LanguageContext);

  return ({
    ...defaultSection(index, index),
    sectionTitle: tl(ownersMeetingInvitationTranslations.editPage.sections.agendaSection.title),
    sectionId: 'AgendaTopics',
    disabled,
    onSubmit: () => {
      openNextSection();
    },
    content: [
      {
        sectionId: 'AgendaTopicsBody',
        content: (
          <AgendaItemsTable isDisabled={isDisabled} />
        ),
      },
    ],
    additionalButton: (
      showAddButton && <AddAgendaItemButton />
    ),
  });
};
