import { Table } from 'antd';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from '../../translations';
import { useHouseMoneySettlementCloseYearSection2Columns } from '../../services/useHouseMoneySettlementCloseYearSection2Columns';
import { useHouseMoneySettlementCloseYearTableData } from '../../services/useHouseMoneySettlementCloseYearTableData';
import InformationBarWrapper from '../InformationBarWrapper';
import TableSummary from './TableSummary';


export default function HouseMonetSettlementCloseYearSection2(): JSX.Element {
  const { tableDataSource, loading } = useHouseMoneySettlementCloseYearTableData();
  const { tl } = useContext(LanguageContext);

  return (
    <PrimarySectionWrapper
      sectionNumber={2}
      sectionTitle={tl(translations.creationPage.valuesTitle)}
      sectionId="hms-close-year-values"
    >
      <div className="HouseMoneySettlementCloseYearSection2">
        <Table
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: !!loading,
          }}
          columns={useHouseMoneySettlementCloseYearSection2Columns()}
          dataSource={tableDataSource.data}
          pagination={false}
          scroll={{ y: 500 }}
          summary={TableSummary}
        />
        <InformationBarWrapper />
      </div>
    </PrimarySectionWrapper>
  );
}
