export const translations: any = {
  title: {
    en: 'This may be the answer you are looking for.',
    de: 'Dies mag die Antwort auf deine Suche sein.',
  },
  subtitle: {
    en: 'If not, try removing or changing your filters to make them less specific.',
    de: 'Falls nicht, versuche deine Filter zu ändern oder zu entfernen.',
  },
};
