import React from 'react';
import { Tag } from 'antd';
import Icon from '@ant-design/icons';
import Heading from 'storybook-components/typography/Heading/Heading';
import './PageHeader.scss';
import { ICONS } from 'components/icons';
import { Link } from 'react-router-dom';

export enum PageHeaderTagColor {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  GRAY = 'GRAY',
}

interface PageHeaderProps {
  title: React.ReactNode,
  subtitle?: React.ReactNode,
  tagContent?: React.ReactNode,
  tagColor?: PageHeaderTagColor,
  rightSideComponent?: React.ReactNode,
  showLogo?: boolean,
}


const PageHeader = ({
  title,
  subtitle,
  tagContent,
  tagColor: tagColorProp,
  rightSideComponent,
  showLogo,
}: PageHeaderProps) => {
  /**
   * This is required because the antd Tag component makes a difference between 'gray' and 'default'
   * and we need 'default', but to stay compatible with DotColorEnum we need to keep the value 'gray'
   * in the enum.
   */
  const tagColor = tagColorProp === 'GRAY'
    ? 'DEFAULT'
    : tagColorProp;

  return (
    <div className="PageHeader">
      {showLogo && <Link to="/dashboard" className="logo-icon"><Icon component={ICONS.impower} /></Link>}
      <div className="left-side">
        <Heading level={2}>{title}</Heading>

        <span className="subtitle-wrapper">
          {tagContent && <Tag color={tagColor?.toLowerCase?.()}>{tagContent}</Tag>}
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </span>
      </div>

      <div className="right-side">
        {rightSideComponent}
      </div>
    </div>
  );
};


PageHeader.defaultProps = {
  subtitle: undefined,
  rightSideComponent: undefined,
  tagContent: undefined,
  tagColor: undefined,
  showLogo: false,
};


export default PageHeader;
