export const translations = {
  title: { en: 'Bank accounts', de: 'Bankkonten' },
  description: { en: 'List of bank accounts to upload', de: 'Liste der zu importierende Bankkonten' },
  upload: { en: 'Upload bank accounts', de: 'Bankkonten CSV hochladen' },
  notifications: {
    propertyLoadError: {
      en: 'Error loading properties',
      de: 'Objekte konnten nicht geladen werden',
    },
  },
  validations: {
    success: { en: 'Bank accounts have been verified and ready for import.', de: 'Bankkonten wurden geprüft und sind bereit zum Importieren' },
    errors: { en: 'Issues found in the CSV', de: 'Es wurden Fehlern gefunden' },
    issues: {
      property: { en: 'Invalid property reference', de: 'Ungültiges Objektreferenz' },
      accountType: { en: 'Invalid accountType', de: 'Ungültiges Kontenart' },
      accountHolderName: { en: 'Invalid accountHolderName', de: 'Ungültige Konteninhabername' },
      iban: { en: 'Invalid iban', de: 'Ungültige IBAN' },
      bic: { en: 'Invalid bic', de: 'Ungültige BIC' },
      accountInstitute: { en: 'Invalid accountInstitute', de: 'Ungültige Bank Name' },
    },
  },
};
