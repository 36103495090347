import React, { useContext, useState } from 'react';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from './LanguageContext';
import { showNotification } from '../lib/Notification';

export const PropertyManagementCompanyContext: any = React.createContext({});

export default function PropertyManagementCompaniesProvider({ children }: any): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const defaultContext = {
    propertyManagementCompanyOptions: [],
    propertyManagementCompanies: [],
  };

  const [propertyManagementCompaniesListState, setPropertyManagementCompaniesListState] = useState(DEFAULT_DATA<any>(defaultContext));

  const updateListState = (data: any) => {
    setPropertyManagementCompaniesListState(propertyManagementCompaniesListState.load({
      ...propertyManagementCompaniesListState.data,
      ...data,
    }));
  };

  const optionObjectFromCompany = (company: any) => ({ value: company.managementCompanyHrId, label: company.name });

  const onLoadManagementCompanies = (filter: string) => {
    setPropertyManagementCompaniesListState(propertyManagementCompaniesListState.startLoading());
    backend.get(`${endpointUrls.MANAGEMENT_COMPANY}`, { filter })
      .then((response: any) => {
        updateListState({
          propertyManagementCompanyOptions: response.map(optionObjectFromCompany),
          propertyManagementCompanies: response,
        });
      })
      .catch(() => {
        setPropertyManagementCompaniesListState(
          propertyManagementCompaniesListState.failed(),
        );
        showNotification({
          key: 'loadManagementCompaniesError',
          message: tl(translations.notifications.serviceCompanyEditingContext.loadError.message),
          description: tl(translations.notifications.serviceCompanyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onLoadManagementCompany = (managementCompanyHrId: string): void => {
    setPropertyManagementCompaniesListState(propertyManagementCompaniesListState.startLoading());
    backend.get(`${endpointUrls.MANAGEMENT_COMPANY}/${managementCompanyHrId}`, {})
      .then((response: any) => {
        const newOptionList = propertyManagementCompaniesListState.data.propertyManagementCompanyOptions;
        const newList = propertyManagementCompaniesListState.data.propertyManagementCompanies;
        const companyOption = optionObjectFromCompany(response);
        if (newOptionList.filter((option: any) => option.value === companyOption.value).length === 0) {
          newOptionList.push(companyOption);
          newList.push(response);
          updateListState({
            propertyManagementCompanyOptions: newOptionList,
            propertyManagementCompanies: newList,
          });
        }
      })
      .catch(() => {
        setPropertyManagementCompaniesListState(
          propertyManagementCompaniesListState.failed(),
        );
        showNotification({
          key: 'loadManagementCompaniesError',
          message: tl(translations.notifications.serviceCompanyEditingContext.loadError.message),
          description: tl(translations.notifications.serviceCompanyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const getObject = (hrId: string) => {
    const filteredList = propertyManagementCompaniesListState.data.propertyManagementCompanies.filter((object: any) => object.managementCompanyHrId === hrId);
    if (filteredList.length === 1) {
      return filteredList[0];
    } return null;
  };

  return (
    <PropertyManagementCompanyContext.Provider value={{
      ...propertyManagementCompaniesListState,
      onLoadManagementCompanies,
      onLoadManagementCompany,
      getObject,
    }}
    >
      {children}
    </PropertyManagementCompanyContext.Provider>
  );
}
