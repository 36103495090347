import { useContext } from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import {
  DropdownButton,
} from 'storybook-components/DropdownButton/DropdownButton';
import { SEVPropertyCreationModal } from '../SEVPropertyCreationModal/SEVPropertyCreationModal';
import { useCreatePropertyButton } from './useCreatePropertyButton';


const CreatePropertyButton = () => {
  const { tl } = useContext(LanguageContext);
  const { menuOptions, isVisibleModal, onCancelModal } = useCreatePropertyButton();

  return (
    <>
      <DropdownButton
        buttonLabel={tl(translations.pages.property.addButton.buttonLabel)}
        menuItems={menuOptions}
        getPopupContainer={() => (document.getElementsByClassName('PropertyList')?.[0]) as HTMLElement}
      />
      <SEVPropertyCreationModal visible={isVisibleModal} onCancel={onCancelModal} />
    </>
  );
};

export default CreatePropertyButton;
