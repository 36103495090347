import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import './BankTransactionList.scss';
import SmartTable from 'elements/SmartTable/SmartTable';
import { Action } from 'elements/SmartTable/data';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import _ from 'lodash';
import { useLocation, useHistory, useParams } from 'react-router';
import { BreadcrumbInterface } from 'components/Header/components/SearchBar/components/Breadcrumbs/Breadcrumbs';
import useBeforeUnload from 'lib/useBeforeUnload';
import { translations as overlayContextTranslations } from 'services/OverlayContext/translations';
import { useTransactionListColumns } from './services/useTransactionListColumns';
import useTransactionList from './services/useTransactionList';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../translations';
import MainPageHeader from '../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../../services/useSiteMap/useSiteMap';
import { useGlobalSearch } from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import useBankTransactionFilters from './services/useBankTransactionFilters';
import {
  BankTransactionProjectionDtoTransactionPaymentStatusEnum,
} from '../../../api/accounting';
import { BankTransactionListLocationStateType, ExtendedBankTransaction, TransactionFilter } from '../interfaces';
import { ResizableDrawer } from '../../../components/Drawer/ResizableDrawer';
import BankTransactionAllocation from '../BankTransactionAllocation/BankTransactionAllocation';
import ConfirmResetAllocationModal from '../BankTransactionAllocation/components/ConfirmResetAllocationModal/ConfirmResetAllocationModal';
import { GlobalSearchContext } from '../../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { BankTransactionAllocationContext } from '../services/BankTransactionAllocationContext';
import { UpdateListElementFunction } from '../BankTransactionAllocation/services/useTransactionAllocation';
import { EmptyResultsHint } from '../../../components/EmptyResults/EmptyResultsHint';
import { AllocationTypeSelectorProvider } from '../BankTransactionAllocation/services/AllocationTypeSelectorContext';
import HgaValidationContextProvider from '../BankTransactionAllocation/services/HgaValidationContext';
import BankTransactionListContextProvider from '../services/BankTransactionListContext';
import { useTransactionListFilterAndSort } from '../services/useTransactionListFilterAndSort';
import { useInitializeBankTransactionList } from '../services/useInitializeBankTransactionList';
import { useTransactionListActions } from '../services/useTransactionListActions';
import { useTransactionAllocationDrawer } from '../services/useTransactionAllocationDrawer';
import { useSelectedTransactions } from '../services/useSelectedTransactions';
import PropertyVatRatesContextProvider from '../BankTransactionAllocation/services/PropertyVatRatesContext';


const MODAL_DEFAULT_STATE = {
  isVisible: false,
  onCancel: () => {
  },
  onConfirm: () => {
  },
};


const BankTransactionList = (): JSX.Element => {
  const {
    updateTransactionStateAndRemainingAmount,
  } = useTransactionList();

  const {
    datasource,
    lastPage,
    loading,
    loaded,
    error,
    transactionIdsFromUrlParams,
    setTransactionIdsFromUrlParams,
    onLoadBankTransactions,
  } = useInitializeBankTransactionList();

  const {
    filterContainsOpenStates,
    defaultFilters,
    sortField,
    setSortField,
    sortOrder,
    setFilterState,
    onSetDefaultFilterFromQueryParams,
  } = useTransactionListFilterAndSort();

  const {
    resetTransaction,
    markTransactionNotBookable,
    markTransactionsAsDuplicate,
  } = useTransactionListActions();

  const history = useHistory();
  const location = useLocation<BankTransactionListLocationStateType>();
  const params = useParams<{ allocationGroupId: string | undefined }>();

  const { tl } = useContext(LanguageContext);
  const { allocationContentLoading } = useContext(BankTransactionAllocationContext);
  const [confirmResetAllocationModal, setConfirmResetAllocationModal] = useState(MODAL_DEFAULT_STATE);

  const {
    selectedTransactions,
    selectedRowKeys,
    onChangeSelectedRowKeys,
  } = useSelectedTransactions();

  const {
    drawerId,
    heightOfDrawer,
    resizeableDrawerSize,
    allocationVisible,
    setAllocationVisible,
    afterVisibleChangeDrawer,

  } = useTransactionAllocationDrawer();

  const onBeforeUnloadFunction = useCallback(() => {
    // must clear cache before page reload otherwise `navigatedFromApp` will be `true`
    history.replace(history.location.pathname, {});
  }, [params.allocationGroupId]);

  useBeforeUnload(onBeforeUnloadFunction);

  useGlobalSearch({
    key: 'bankTransactions',
    availableFiltersKey: 'transaction-list-search',
    filterProps: {
      setFilter: (key: string, value: string) => setFilterState(
        (currentFilter: TransactionFilter) => {
          const newFilter: TransactionFilter = _.cloneDeep(currentFilter);

          if (value === undefined || value === null) {
            // @ts-ignore
            delete newFilter[key];
          } else if (['fromDate', 'untilDate'].includes(key)) {
            // @ts-ignore
            newFilter[key] = new Date(value);
          } else {
            // @ts-ignore
            newFilter[key] = value;
          }
          return newFilter;
        },
      ),
      availableFilters: useBankTransactionFilters(),
    },
    queryParamAsFilter: {
      onSetDefaultFilterFromQueryParams,
      defaultFilters,
      syncSearchBarAndQueryParams: false,
    },
  });

  const {
    setHideGlobalSearch, addBreadcrumb, removeBreadcrumb, breadcrumbs, replaceBreadcrumb,
  } = useContext(GlobalSearchContext);

  useEffect(() => {
    if (allocationVisible) {
      setHideGlobalSearch(true);
    } else {
      setHideGlobalSearch(false);
    }
  }, [allocationVisible]);

  useEffect(() => () => {
    // unhide search bar if navigating away
    setHideGlobalSearch(false);
  }, []);

  const onAction = (record: ExtendedBankTransaction, onConfirm: (shouldOpenNext: boolean, allocationGroupId: number, bankTransactionIds?: number[], bankTransactionsForAction?: ExtendedBankTransaction[]) => void) => {
    const ids = _.isEmpty(record.children) ? [record.bankTransactionId] : record.children.map((tx: ExtendedBankTransaction) => tx.bankTransactionId);
    const txList = _.isEmpty(record.children) ? [record] : record.children;

    if ([BankTransactionProjectionDtoTransactionPaymentStatusEnum.ASSIGNED, BankTransactionProjectionDtoTransactionPaymentStatusEnum.PARTIALLY_BOOKED].includes(record.transactionPaymentStatus!)) {
      setConfirmResetAllocationModal({
        isVisible: true,
        onCancel: () => setConfirmResetAllocationModal(MODAL_DEFAULT_STATE),
        onConfirm: () => {
          setConfirmResetAllocationModal(MODAL_DEFAULT_STATE);
          onConfirm(false, record.allocationGroupId!, ids, txList);
        },
      });
    } else {
      onConfirm(false, record.allocationGroupId!, ids, txList);
    }
  };
  const actionsMenu: Action[] = [
    {
      label: tl(translations.table.dontAllocate),
      onAction: (record: ExtendedBankTransaction) => onAction(record, markTransactionNotBookable),
      actionSupported: (record: ExtendedBankTransaction) => record.transactionPaymentStatus !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.WONT_BE_ALLOCATED,
    },
    {
      label: tl(translations.table.markDuplicate),
      onAction: (record: ExtendedBankTransaction) => onAction(record, markTransactionsAsDuplicate),
      actionSupported: (record: ExtendedBankTransaction) => record.transactionPaymentStatus !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.DUPLICATE,
    },
    {
      label: tl(translations.table.resetAllocation),
      onAction: (record: ExtendedBankTransaction) => onAction(record, resetTransaction),
      actionSupported: (record: ExtendedBankTransaction) => record.transactionPaymentStatus !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.UNASSIGNED,
    }];

  const columns = useTransactionListColumns();

  const rowClassNames = (record: ExtendedBankTransaction) => {
    if (!_.isEmpty(selectedTransactions) && record.allocationGroupId !== selectedTransactions[0]?.allocationGroupId) {
      return 'disabled-row';
    }
    // @ts-ignore
    if (!_.isEmpty(smartTable.expandedRowKeys) && !_.isEmpty(record.children) && _.findIndex(smartTable.expandedRowKeys, k => _.isEqual(k, record.rowKey)) !== -1) {
      return allocationVisible ? 'hidden-row' : 'expanded-row';
    }
    if (_.isEmpty(selectedTransactions)) {
      return '';
    }
    return 'selected-row';
  };

  const onRowClick = (e: React.MouseEvent, record: ExtendedBankTransaction) => {
    if (selectedTransactions.length === 0) {
      history.push(`/bank-transactions/${record.allocationGroupId}`, { navigatedFromApp: true, updateUrlOnCurrentOverlay: true });
    }
  };

  useEffect(() => {
    const { allocationGroupId: allocationGroupIdString } = params;
    const allocationGroupId = allocationGroupIdString ? parseInt(allocationGroupIdString, 10) : undefined;

    if (!allocationGroupId) {
      if ((!location.state?.navigatedFromApp && lastPage) || location.state?.reloadFirstPage) {
        onLoadBankTransactions(true);
      }

      if (!allocationVisible) {
        removeBreadcrumb((breadCrumb: BreadcrumbInterface) => breadCrumb.url === `/bank-transactions/${allocationGroupId}`);
      }
    }
  }, [params.allocationGroupId]);

  useEffect(() => {
    // on initial page load of single transaction (group) split the breadcrumb into two
    // as a workaround to how the OverlayContext parses URLs
    if (location.state?.navigatedFromApp) return;

    const bankTransactionsWithAllocationGroupIdRegex = new RegExp(`${tl(overlayContextTranslations.banktransactions)} \\d+`);
    if (breadcrumbs.length === 1 && bankTransactionsWithAllocationGroupIdRegex.test(breadcrumbs[0].label)) {
      const [labelWithoutAllocationGroupId] = breadcrumbs[0].label.split(/ \d+/);
      const [allocationGroupId] = /\d+/.exec(breadcrumbs[0].label) || [''];

      replaceBreadcrumb(0, {
        ...(breadcrumbs[0]),
        label: labelWithoutAllocationGroupId,
        path: '"/bank-transactions/:allocationGroupId?"',
        url: '/bank-transactions',
      });
      addBreadcrumb({
        breadcrumbType: 'LINK',
        label: allocationGroupId,
        path: `/bank-transactions/${allocationGroupId}`,
        url: `/bank-transactions/${allocationGroupId}`,
      });
    }
  }, [breadcrumbs.length]);

  useEffect(() => {
    const { allocationGroupId: allocationGroupIdString } = params;
    const allocationGroupId = allocationGroupIdString ? parseInt(allocationGroupIdString, 10) : undefined;

    if (!allocationGroupId) return;

    // if reset/allocate fails then `loaded` becomes false and it triggers adding a breadcrumb
    if (loading) return;
    if (error) return;
    if (lastPage && datasource?.length === 0) return;


    if (!location.state?.navigatedFromApp) {
      setFilterState((filter) => {
        if (filter.allocationGroupId === allocationGroupId) {
          // if the allocationGroupId didn't change then return the same object to avoid any useEffect's triggering unnecessarily
          return filter;
        }

        return ({
          ...filter,
          allocationGroupId,
        });
      });
    }

    if (location.state?.navigatedFromApp) {
      const breadcrumbIndex = breadcrumbs.findIndex((bc: BreadcrumbInterface) => /^\/bank-transactions\/\d+$/.test(bc.url) && bc.url !== `/bank-transactions/${allocationGroupId}`);
      if (breadcrumbIndex > -1) {
        replaceBreadcrumb(breadcrumbIndex, {
          breadcrumbType: 'LINK',
          label: `${allocationGroupId}`,
          path: `/bank-transactions/${allocationGroupId}`,
          url: `/bank-transactions/${allocationGroupId}`,
        });
      } else if (!location.state.reloadFirstPage || !location.search) {
        addBreadcrumb({
          breadcrumbType: 'LINK',
          label: `${allocationGroupId}`,
          path: `/bank-transactions/${allocationGroupId}`,
          url: `/bank-transactions/${allocationGroupId}`,
        });
      }
    }
  }, [params.allocationGroupId, loaded]);

  useEffect(() => {
    const { allocationGroupId: allocationGroupIdString } = params;
    const allocationGroupId = allocationGroupIdString ? parseInt(allocationGroupIdString, 10) : undefined;

    if (!allocationGroupId) return;

    // if reset/allocate fails then `loaded` becomes false and it triggers adding a breadcrumb
    if (loading) return;
    if (error) return;
    if (lastPage && datasource?.length === 0) return;
    if (_.isEmpty(datasource)) return;

    const groupIdx = _.findIndex(datasource, g => _.isEqual(g.allocationGroupId, allocationGroupId));
    if (groupIdx === -1) return;

    const record = { ...datasource[groupIdx] };
    if (_.isEmpty(record)) {
      onChangeSelectedRowKeys([]);
      return;
    }

    if (transactionIdsFromUrlParams && transactionIdsFromUrlParams.length > 0) {
      onChangeSelectedRowKeys(record.children
        ? record.children.filter((child: any) => transactionIdsFromUrlParams?.includes(child.bankTransactionId))?.map(r => r.rowKey)
        : [record.rowKey]);
    } else {
      onChangeSelectedRowKeys(record.children?.map(r => r.rowKey) || [record.rowKey]);
    }
    setTransactionIdsFromUrlParams(undefined);

    // expand group if not yet expanded
    smartTable.setExpandedRowKeys!((oldRowKeys: string[]) => {
      const newRowKeys = oldRowKeys.slice();
      if (!newRowKeys.includes(record.rowKey!)) {
        newRowKeys.push(record.rowKey!);
      }
      return newRowKeys;
    });
    setAllocationVisible(true);
  }, [params.allocationGroupId, loaded, datasource]);

  const smartTable = useSmartTable(
    {
      tableName: 'bankTransactionListTable',
      columns,
      dataSource: datasource,
      contentLoading: loading,
      infiniteScrollerProps: {
        hasMoreData: !lastPage,
        loadMoreData: () => {
          onLoadBankTransactions(false);
        },
      },
      onRow: (record: ExtendedBankTransaction) => ({
        onClick: e => onRowClick(e, record),
      }),
      propSort: {
        field: sortField === 'bankBookingDate' ? 'transactionDate' : sortField,
        order: sortOrder,
        onSortChange: (dataKey: string) => {
          if (dataKey === 'transactionDate') {
            setSortField('bankBookingDate');
          } else {
            setSortField(dataKey);
          }
        },
      },
      rowKey: 'rowKey',
      actionsMenu,
      supportBatchActions: allocationVisible,
      showOnlyCheckboxes: allocationVisible,
      showHeaderCheckbox: !allocationVisible,
      shouldDisableCheckbox: (record: ExtendedBankTransaction, selectedRows: ExtendedBankTransaction[]) => !_.isEmpty(selectedRows) && record.allocationGroupId !== selectedRows[0]?.allocationGroupId,
      rowClassName: rowClassNames,
      expandable: true,
      expandRowByClick: false,
      emptyResultsComponent: <EmptyResultsHint
        title={filterContainsOpenStates ? tl(translations.table.noResultTitle) : undefined}
        subTitle={filterContainsOpenStates ? tl(translations.table.noResultsSubtitle) : undefined}
      />,
      virtualize: false,
      selectedRowKeys,
      onChangeSelectedRowKeys,
      hideActionBar: true,
    },
  );

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'bankTransactions',
    navItems: subcategorySwitcherItems.accounting,
  };

  const updateListElement: UpdateListElementFunction = (
    shouldOpenNext,
    originalGroupId,
    groupIdForAllocatedTransactions,
    transactionIds,
    status,
    remainingAmount,
    allocatedInvoiceIds,
    unallocatedInvoiceIds,
  ) => {
    const currentTxIndex = _.findIndex(smartTable.dataSource, tx => tx.allocationGroupId === originalGroupId);
    updateTransactionStateAndRemainingAmount(shouldOpenNext, originalGroupId, groupIdForAllocatedTransactions, transactionIds, status, remainingAmount, allocatedInvoiceIds, unallocatedInvoiceIds);

    // open next transaction if fully allocated

    if (currentTxIndex === -1) return;

    if (shouldOpenNext) {
      if (smartTable.dataSource[currentTxIndex + 1]) {
        history.push(`/bank-transactions/${smartTable.dataSource[currentTxIndex + 1].allocationGroupId}`, { navigatedFromApp: true, updateUrlOnCurrentOverlay: true });
      } else {
        history.push('/bank-transactions');
        setAllocationVisible(false);
        onChangeSelectedRowKeys([]);
      }
      return;
    }

    if (!location.state?.navigatedFromApp || location.state?.reloadFirstPage) {
      history.replace('/bank-transactions', { navigatedFromApp: false, reloadFirstPage: true });
      setAllocationVisible(false);
      onChangeSelectedRowKeys([]);
    }
  };

  return (
    <div className="BankTransactionList page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} className={`TransactionListHeader ${allocationVisible ? 'hidden' : ''}`} />
      <SmartTable {...smartTable} heightOfElementsBelowTable={heightOfDrawer} />
      <ResizableDrawer
        id={drawerId}
        {...resizeableDrawerSize}
        visible={allocationVisible}
        setVisible={() => { /* drawer visibility is determined by url */ }}
        placement="bottom"
        onCloseCallback={() => {
          setAllocationVisible(false);
          onChangeSelectedRowKeys([]);
          history.replace('/bank-transactions', { navigatedFromApp: location.state?.navigatedFromApp, reloadFirstPage: location.state?.reloadFirstPage });
        }}
        disableClose={allocationContentLoading}
        afterVisibleChange={afterVisibleChangeDrawer}
      >
        <AllocationTypeSelectorProvider>
          <PropertyVatRatesContextProvider>
            <BankTransactionAllocation
              updateListElement={updateListElement}
              parentHeight={resizeableDrawerSize.height}
              parentComponentId={drawerId}
            />
          </PropertyVatRatesContextProvider>
        </AllocationTypeSelectorProvider>
      </ResizableDrawer>
      <ConfirmResetAllocationModal {...confirmResetAllocationModal} />
    </div>
  );
};

const BankTransactionListWithProvider = () => (
  <HgaValidationContextProvider>
    <BankTransactionListContextProvider>
      <BankTransactionList />
    </BankTransactionListContextProvider>
  </HgaValidationContextProvider>
);

export default BankTransactionListWithProvider;
