export const translations = {
  creationPage: {
    title: {
      en: 'HGA Year End Exchange generation',
      de: 'Abrechnungsergebnis verbuchen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    execute: {
      en: 'Execute',
      de: 'Ausführen',
    },
    accountSelectionTitle: {
      en: 'Account Selection',
      de: 'Kontoauswahl',
    },
    valuesTitle: {
      en: 'Values',
      de: 'Werte',
    },
    prompt: {
      en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
      de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie weiter?',
    },
    sections: {
      section1: {
        bookingDate: {
          en: 'Booking date',
          de: 'Buchungsdatum',
        },
        bookingText: {
          en: 'Booking text',
          de: 'Buchungstext',
        },
        bankAccount: {
          en: 'Bank account',
          de: 'Bankkonto',
        },
        noOrder: {
          en: 'No order needed',
          de: 'Keine Überweisungen',
        },
        bookingDateBeforeEndWarning: {
          en: 'The booking date you specified is before the end date of the report. As this is unusual we ask you to please double-check and adapt if necessary before executing.',
          de: 'Das von Ihnen festgelegte Buchungsdatum ist vor dem Enddatum des Berichts. Da dies unüblich ist bitten wir Sie, das zu überprüfen und falls notwendig vor der Ausführung anzupassen.',
        },
      },
      section2: {
        unitNumber: {
          en: 'Nr.',
          de: 'Nr.',
        },
        ownerContract: {
          en: 'Owner Name',
          de: 'Eigentümer Name',
        },
        amountToDistribute: {
          en: 'Amount to Distribute',
          de: 'Zu verteilender Betrag',
        },
        direction: {
          en: 'Direction',
          de: 'Richtung',
        },
        processing: {
          en: 'Processing',
          de: 'Abwicklung',
        },
        selectOptionsLabels: {
          selfPayer: {
            en: 'Self payer',
            de: 'Selbstzahler',
          },
          paymentRun: {
            en: 'Payment run',
            de: 'Zahlungslauf',
          },
          directDebit: {
            en: 'Direct debit',
            de: 'Lastschriftzahlung',
          },
        },
        totalAmountLabel: {
          en: 'Total amount is split',
          de: 'Der Gesamtbetrag wurde verteilt',
        },
        infoBar: {
          unitsSelected: {
            en: 'Units selected',
            de: 'Ausgewählte Einheiten',
          },
          totalAmountPayout: {
            en: 'Total Amount Payout',
            de: 'Gesamtbetrag Auszahlung',
          },
          totalAmountPull: {
            en: 'Total Amount Pull',
            de: 'Gesamtbetrag Einzug',
          },
          bankOrders: {
            en: 'Bank orders',
            de: 'Bankaufträge',
          },
        },
      },
    },
    notifications: {
      validationError: {
        en: 'There are validation errors',
        de: 'Es sind Validierungsfehler aufgetreten',
      },
      amountIsZeroError: {
        en: 'The amount to distribute must be greater than 0',
        de: 'Der zu verteilende Betrag muss größer sein als 0',
      },
      mandatory: {
        en: 'Field is required',
        de: 'Feld ist erforderlich.',
      },
      executeSuccess: {
        en: 'Exchange created successfully',
        de: 'Buchung wurde erfolgreich erstellt',
      },
      executeError: {
        en: 'Failed to create exchange',
        de: 'Buchung konnte nicht erstellt werden',
      },
      fetchHouseMoneySettlementError: {
        en: 'Something went wrong during retrieval of House Money Settlement',
        de: 'Es ist ein Fehler beim Abruf der Hausgeldabrechnung aufgetreten',
      },
    },
  },
};
