import { ExtendedExchangeProjectionTypeEnum } from 'api/accounting';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { isEmpty } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { ServiceCompanyAllocationValues } from '../../../../services/ServiceCompanyAllocationContext';
import { translations } from '../../../../../../../translations';
import { usePropertyCell } from './usePropertyCell';

interface Property {
  propertyId?: number,
  propertyHrId?: string,
  propertyIdInternal?: string,
  propertyName?: string
}

interface Props {
  record: ServiceCompanyAllocationValues,
  properties: Property[]
}

const PropertyCell = ({
  record,
  properties,
}: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    currentValue,
    onChange,
  } = usePropertyCell(record.key);
  const headerTranslations = translations.bankTransactionAllocation.serviceCompanyAllocation.headers;

  const selectOptions = useMemo(() => (properties?.map(p => ({
    value: p.propertyHrId!,
    label: `${p.propertyHrId} ${p.propertyName}`,
  })) || []), [properties]);


  if (record.type === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) {
    return (
      <SelectInput
        size="large"
        showSearch
        placeholder={tl(headerTranslations.property)}
        value={currentValue}
        disabled={isEmpty(properties)}
        defaultValue={record.propertyHrId}
        options={selectOptions}
        onChange={onChange}
        dropdownClassName="large-dropdown"
        placement="bottomLeft"
      />
    );
  }

  return (<CellWithTwoLines firstElement={record.propertyIdInternal} secondElement={record.propertyName} />);
};

export default PropertyCell;
