export const translations = {
  propertyValidityModal: {
    title: {
      en: 'Property is in an invalid state. Make sure you have an active contract for all units.',
      de: 'Objekt ist in einem ungültigen Status. Stellen Sie sicher dass für alle Einheiten ein aktiver Vertrag erstellt ist.',
    },
    content: {
      en: 'This property has one or more units without contracts.',
      de: 'Objekt hat eine oder mehrere Einheiten ohne Verträge.',
    },
    okText: {
      en: 'Go to property editing',
      de: "Zu 'Objekt bearbeiten' wechseln",
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    checkFailed: {
      en: 'Failed to check property validity.',
      de: 'Objekt konnte nicht geprüft werden.',
    },
  },
};
