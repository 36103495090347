import { RouteDefinition } from '../../components/RenderRoutes';
import ManualExchangeCreation from './ManualExchangeCreation';

export const ExchangeRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/exchange/create',
      component: ManualExchangeCreation,
      type: 'focus',
    },
  ],
};
