import React from 'react';
import './BurgerButton.scss';

interface BurgerButtonProps {
  onClick: () => void;
  open: boolean;
}

export const BurgerButton = (props: BurgerButtonProps) => {
  const { onClick, open } = props;
  return (
    <button
      className={`BurgerButton ${open ? 'open' : ''}`}
      onClick={onClick}
    >
      <div className="burger-line line-1" />
      <div className="burger-line line-2" />
      <div className="burger-line line-3" />
    </button>
  );
};
