import { useContext } from 'react';
import { PersonEditingContext } from 'contexts/PersonEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';

export default function useDefaultSection(index: number) {
  const { tl } = useContext(LanguageContext);

  const contactEditingContext: any = useContext(PersonEditingContext);

  return {
    sectionNumber: index + 1,
    openingNumber: index + 1,
    buttonLoading: contactEditingContext.loading,
    value: contactEditingContext.data.contact,
  };
}
