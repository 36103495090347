import { HouseMoneyUnitPaymentPreferenceDto, HouseMoneyUnitPaymentPreferenceDtoPaymentPreferenceEnum } from 'api/accounting';
import { HouseMoneySettlementAggregationControllerApi } from 'api/accounting/apis/HouseMoneySettlementAggregationControllerApi';
import { AuthContext } from 'contexts/AuthContext';
import { showNotification } from 'lib/Notification';
import { useContext, useState } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { useHistory, useParams } from 'react-router';
import { AccountSelectionContext, AmountDirectionAndProcessingValuesContext, HouseMoneySettlementAndPropertyContext } from './HouseMoneySettlementCloseYearContext';
import { translations } from '../translations';

export const useExecuteHouseMoneyYearEndExchange = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const amountDirectionAndProcessingValues = useContext(AmountDirectionAndProcessingValuesContext);
  const accountSelectionContext = useContext(AccountSelectionContext);
  const houseMoneySettlementAndPropertyContext = useContext(HouseMoneySettlementAndPropertyContext);
  const hmsControllerApi = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const { hmsId } = useParams<{ hmsId: string }>();
  const history = useHistory();

  if (accountSelectionContext === undefined || houseMoneySettlementAndPropertyContext === undefined) {
    throw new Error('useExecuteHouseMoneyYearEndExchange must be used within a HouseMoneySettlementContextProvider');
  }

  const { formValues, setDirty } = accountSelectionContext;
  const { propertyAndEconomicYearData } = houseMoneySettlementAndPropertyContext;

  const onExecute = () => {
    setLoading(true);
    if (amountDirectionAndProcessingValues === undefined) {
      throw new Error('amount directions have not been initialized');
    }
    const houseMoneySettlementFinalizationList: HouseMoneyUnitPaymentPreferenceDto[] = amountDirectionAndProcessingValues.map(amountDirectionAndProcessingValue => ({
      unitId: amountDirectionAndProcessingValue.unitId,
      paymentPreference: amountDirectionAndProcessingValue.processing === 'PLATFORM' ? HouseMoneyUnitPaymentPreferenceDtoPaymentPreferenceEnum.GENERATE_ORDER : HouseMoneyUnitPaymentPreferenceDtoPaymentPreferenceEnum.NOT_NEEDED,
    }));
    hmsControllerApi.markHouseMoneySettlementFinalizedUsingPOST({
      id: parseInt(hmsId, 10),
      houseMoneySettlementFinalizationDto: {
        bookingDate: formValues.bookingDate?.toISOString(),
        bookingText: formValues.bookingText || '',
        propertyBankAccountId: formValues.bankAccount === -1 ? undefined : formValues.bankAccount,
        unitPaymentPreference: houseMoneySettlementFinalizationList,
      },
    }).then(() => {
      setDirty(false);
      showNotification({
        key: 'executeManualExchangeSuccess',
        message: tl(translations.creationPage.notifications.executeSuccess),
        type: 'success',
      });
    })
      .then(goBack)
      .catch(() => {
        setDirty(false);
        showNotification({
          key: 'executeManualExchangeError',
          message: tl(translations.creationPage.notifications.executeError),
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goBack = () => {
    history.push(`/house-money-settlement/${propertyAndEconomicYearData?.propertyHrId}`);
  };

  return { onExecute, loading, goBack };
};
