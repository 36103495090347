import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import IncomeTableSummary from '../IncomeTableSummary';
import { useIncomeTable } from './useIncomeTable';
import IncomeTableSubSummary from '../IncomeTableSubSummary';
import _ from 'lodash';


const IncomeTable = () => {
  const {
    vatRelevantGermanProperty, columns, dataSource, sum, netSum, vatSum, secondColumns, tenantPaymentDeficitData, secondSum,
    vatDatasource, vatColumns
  } = useIncomeTable();
  const { tl } = useContext(LanguageContext);

  return (
    <div className="IncomeTable">
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => (
          <IncomeTableSubSummary
            title={tl(translations.report.sections.incomeSection.totalRentIncome)}
            totalGross={sum}
            totalNet={netSum}
            totalVat={vatSum}
            nrTotalColumns={columns.length}
            displayVatColumns={vatRelevantGermanProperty}
          />
        )}
        rowKey="accountCode"
      />

      <Table
        className="NoHeaderTable"
        columns={secondColumns}
        dataSource={tenantPaymentDeficitData}
        pagination={false}
        summary={() => (
          <IncomeTableSummary
            title={tl(translations.report.sections.incomeSection.totalRentIcomeActual)}
            totalGross={secondSum}
            nrTotalColumns={secondColumns.length}
          />
        )}
        rowKey="label"
      />
      {!_.isEmpty(vatDatasource) && vatDatasource.length > 1 && 
        <Table
          className="NoHeaderTable SecondaryTable"
          columns={vatColumns}
          dataSource={vatDatasource}
          pagination={false} 
          rowKey="vatPercentage"
          rowClassName={(_, index) => (index === 0 ? 'reference-row' : '')}
        />
      }
    </div>
  );
};

export default IncomeTable;
