import { Button as AntButton, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import React from 'react';
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';
import './Button.scss';

export interface CustomButtonProps extends ButtonProps {
  tooltipText?: string,
}

export default function Button(props: CustomButtonProps): JSX.Element {
  const {
    loading, children, className, tooltipText, onClick, ...antButtonProps
  } = props;
  const antButton = () => (
    <AntButton
      {...antButtonProps}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}
      loading={false} // prevent default loading of antd button
      className={`Button ${loading ? 'loading' : ''} ${className}`}
    >
      {loading && <span className="indicator"><LoadingIndicator /></span>}
      {children}
    </AntButton>
  );
  return (
    tooltipText ? (
      <Tooltip title={tooltipText} placement="bottom">
        {antButton()}
      </Tooltip>
    ) : (
      antButton()
    )
  );
}


Button.defaultProps = {
  tooltipText: undefined,
};
