import { useContext, useEffect } from 'react';
import {
  ContractLegacyControllerApi, PropertyDisplayDto, PropertyDisplayDtoAdministrationTypeEnum, PropertyLegacyControllerApi, SliceOfPropertyDisplayDto, UnitContractControllerApi, UnitContractProjectionDto,
} from 'api/accounting';
import { isEmpty, isNil, uniq } from 'lodash';
import { ISO_DATE_FORMAT } from 'lib/Utils';
import moment from 'moment';
import { getStateOfContract } from 'services/UnitContractsList/useUnitContractUtils';
import { PropertyContractsDebtorBalances } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from '../../../../../lib/Notification';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { ContactPropertiesDataSource, ContractDataSource, useContactPropertiesContext } from './ContactPropertiesContext';
import { contactPropertiesTranslations } from '../translation';


const getLinkedProperty = (
  property: PropertyDisplayDto,
  properties: SliceOfPropertyDisplayDto,
  contracts: UnitContractProjectionDto[],
  contractsForProperty: UnitContractProjectionDto[],
) => {
  if (property.administrationType !== PropertyDisplayDtoAdministrationTypeEnum.SEV) {
    return undefined;
  }

  const contractGroupIds = uniq(contractsForProperty.map(c => c.contractGroupId).filter(c => !isNil(c)));
  const contractsInGroups = contracts.filter(c => contractGroupIds.includes(c.contractGroupId));

  // when an SEV contract (c2) is linked to a WEG's tenant contract (c1) then c1.contractGroupId === c1.unitContractId;
  // so here we find the "root" contract of the group and based on that we find the "root WEG property"
  const contractsOfOtherPropertiesInGroups = contractsInGroups.filter(c => c.contractGroupId === c.unitContractId);

  const propertyOfOtherContractsInGroups = properties.content.find(
    p => contractsOfOtherPropertiesInGroups.some(c => c.propertyId === p.id),
  );

  return propertyOfOtherContractsInGroups;
};

const getWegPropertiesConnectedToSevs = (properties: SliceOfPropertyDisplayDto, contracts: UnitContractProjectionDto[]) => (
  properties.content.filter((prp) => {
    if (prp.administrationType !== PropertyDisplayDtoAdministrationTypeEnum.WEG) {
      return false;
    }

    const contractsForProperty = contracts.filter(contract => contract.propertyId === prp.id);

    if (contractsForProperty.some(contract => !isNil(contract.contractGroupId))) {
      return true;
    }

    return false;
  })
);


export default function useContactProperties(contactId: number) {
  const { tl } = useContext(LanguageContext);
  const { contactPropertiesDataSource, setContactPropertiesDataSource } = useContactPropertiesContext('useContactProperties');

  const { apiConfiguration } = useContext(AuthContext);
  const contractLegacyControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const propertyController = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const contractController = new ContractLegacyControllerApi(apiConfiguration('accounting'));


  useEffect(() => {
    if (!Number.isNaN(contactId)) {
      onLoadContactPropertiesDataSource();
    }
  }, [contactId]);


  const onLoadContracts = () => contractLegacyControllerApi.getUnitContractsUsingGET({ contactId });

  const onLoadActiveContracts = () => contractLegacyControllerApi.getUnitContractsUsingGET({ atDate: moment().format(ISO_DATE_FORMAT), contactId });

  const onLoadProperties = (propertyIds: number[]) => propertyController.findFilteredPropertiesUsingGET({ propertyIds });

  const onLoadBalanceForContracts = (contractIds: number[]) => contractController.getContractAccountBalancesUsingGET({ contractIds });

  const onLoadContactPropertiesDataSource = () => {
    setContactPropertiesDataSource(prev => prev.startLoading());
    Promise.all([onLoadContracts(), onLoadActiveContracts()])
      .then(([contracts, activeContracts]) => {
        const propertyIds = uniq(contracts.map(contract => contract.propertyId));
        const contractIds = contracts.map(contract => contract.unitContractId);
        if (!isEmpty(contractIds)) {
          Promise.all([onLoadProperties(propertyIds), onLoadBalanceForContracts(contractIds)])
            .then(([properties, balanceForContracts]) => {
              const wegPropertiesConnectedToSevs = getWegPropertiesConnectedToSevs(properties, contracts);

              const dataSource = properties.content.map((property): ContactPropertiesDataSource => {
                const balanceForProperty = balanceForContracts.content.find(balance => balance.propertyId === property.id);

                const contractsParsedBalances: PropertyContractsDebtorBalances[] = balanceForProperty?.debtorBalances
                  ? JSON.parse(balanceForProperty?.debtorBalances)
                  : [];


                const isThisWegPropertyConnectedToAnSev = wegPropertiesConnectedToSevs.some(prp => (prp.id === property.id));

                const contractsForProperty = contracts.filter((contract) => {
                  if (isThisWegPropertyConnectedToAnSev) {
                    return contract.propertyId === property.id && isNil(contract.contractGroupId);
                  }

                  return contract.propertyId === property.id;
                });

                const contractsDataSourceForProperty: ContractDataSource[] = contractsForProperty.map((contract) => {
                  const accountBalance = contractsParsedBalances
                    .filter(contractParsedBalances => contractParsedBalances.contractId === contract.unitContractId)
                    .reduce((sum, contractParsedBalances) => sum + contractParsedBalances.todaysAccountBalance, 0);
                  const role = contract.contacts.find(c => c.contactId === contactId)?.role;
                  const activeContractId = activeContracts
                    .find(activeContract => activeContract.unitId === contract.unitId)?.unitContractId;

                  return {
                    ...contract,
                    name: `${contract.unitNrSharingDeclaration} - ${tl(contactPropertiesTranslations.propertyTable.unitTypes[contract.unitType])}`,
                    propertyHrId: property.propertyHrId,
                    role,
                    accountBalance,
                    state: getStateOfContract(contract as unknown as UnitContractProjectionDto, activeContractId),
                  };
                });

                return {
                  key: property.id,
                  name: property.name,
                  propertyHrId: property.propertyHrId,
                  propertyIdInternal: property.propertyIdInternal,
                  administrationType: property.administrationType,
                  state: property.propertyState,
                  children: contractsDataSourceForProperty,
                  linkedProperty: getLinkedProperty(property, properties, contracts, contractsForProperty),
                } as ContactPropertiesDataSource;
              })
                // don't show WEG's where the only contract this contact has is linked to an SEV
                .filter(prp => !isEmpty(prp.children));

              setContactPropertiesDataSource(prev => prev.load(dataSource));
            });
        } else {
          setContactPropertiesDataSource(prev => prev.finishLoading());
        }
      }).catch((err) => {
        console.error(err);
        setContactPropertiesDataSource(prev => prev.failed());
        showNotification({
          type: 'error',
          message: tl(contactPropertiesTranslations.loadingError),
        });
      });
  };

  return {
    contactPropertiesDataSource,
  };
}
