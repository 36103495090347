import { Table } from 'antd';
import React from 'react';
import { usePastBookings } from './services/usePastBookings';
import { usePastBookingsColumns } from './services/usePastBookingsColumns';
import './PastBookings.scss';

export const PastBookings = () => {
  const { dataSource } = usePastBookings();
  const columns = usePastBookingsColumns();

  return (
    <div className="PastBookings">
      <Table
        rowKey="key"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
