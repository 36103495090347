import _ from 'lodash';

const searchEnvForValueWith = (obj: any, key: string) => {
  if (obj) {
    if (obj[key]) {
      return obj[key];
      // eslint-disable-next-line no-else-return
    } else {
      const keyParts = key.split('.');
      const subKeyParts = keyParts.slice(1);
      const subKey = subKeyParts.join('.');
      return searchEnvForValueWith(obj[keyParts[0]], subKey);
    }
  }
  return undefined;
};

const getDeploymentEnvVar = (key: string) => {
  let envObject = {};
  // get environment/html injected _env values (see Dockerfile)
  if (globalThis._env) {
    // globalThis = window in the browser;
    envObject = { ...globalThis._env };
  }
  return searchEnvForValueWith(envObject, key);
};

const getLocalStorageEnvVar = (key: string) => {
  const localStorageEnvString = localStorage?.getItem('_env');

  if (!localStorageEnvString) {
    return undefined;
  }

  let envObject = {};
  try {
    envObject = JSON.parse(localStorageEnvString);
  } catch (e) {
    console.warn('Failed loading localstorage _env', e);
  }

  return searchEnvForValueWith(envObject, key);
};


const copyQueryParamEnvToLocalStorage = () => {
  const urlEnv64 = new URLSearchParams(window.location.search).get('_env');
  if (urlEnv64) {
    try {
      const urlEnvString = atob(urlEnv64);
      const urlEnv = JSON.parse(urlEnvString);
      const localStorageEnvString = localStorage?.getItem('_env') || '{}';
      const localStorageEnv = JSON.parse(localStorageEnvString);
      _.merge(localStorageEnv, urlEnv);
      localStorage.setItem('_env', JSON.stringify(localStorageEnv));
    } catch (e) {
      console.warn('Failed to parse query param _env: ', urlEnv64, e);
    }
  }
};

const initLocalStorageEnvVar = () => {
  try {
    copyQueryParamEnvToLocalStorage();
  } catch (e) {
    console.warn('Failed to initialize localstorage from path variables', e);
  }
};
initLocalStorageEnvVar();

/**
 * Look through:
 * 1. localStorage._env
 * 2. globalThis(window)._env
 *
 * for the desired key.
 * If not found: return the default value.
 *
 *
 * It can return both 123, 456. From
 * { 'a.b.c': 123 }
 * and
 * { a: {b: {c: 456}} }
 *
 * If both are present 123 will have precedence.
 *
 * @param envKey the key to look for in _env
 * @param defaultValue the default value, shall no env var be defined
 * @returns the identified value according to above rules
 */
export const getEnvVar = (envKey: string, defaultValue?: any): unknown => {
  defaultValue = defaultValue || '';

  const deploymentEnvVar = getDeploymentEnvVar(envKey);
  const localStorageEnvVar = getLocalStorageEnvVar(envKey);

  return localStorageEnvVar ?? deploymentEnvVar ?? defaultValue;
};
