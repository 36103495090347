import {
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  PropertyBankAccountControllerApi,
  PropertyBankAccountProjectionDto,
} from '../api/accounting';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import { translations } from '../elements/Translation/translations';
import { showNotification } from '../lib/Notification';

export default function usePropertyBankAccountOptions() {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const [bankAccounts, setBankAccounts] = useState<PropertyBankAccountProjectionDto[]>([]);
  const propertyBankAccountControllerApi = new PropertyBankAccountControllerApi(apiConfiguration('accounting'));

  const optionObjectFromBankAccount = (bankAccount: PropertyBankAccountProjectionDto) => ({
    value: bankAccount.bankAccountId,
    label: `${bankAccount.accountName} - ${bankAccount.iban}`,
  });

  const onLoadBankAccounts = (propertyId: number) => {
    propertyBankAccountControllerApi.filterPropertyBankAccountsUsingGET({ propertyIds: [propertyId] })
      .then((response: PropertyBankAccountProjectionDto[]) => {
        setBankAccounts(response);
      })
      .catch(() => {
        showNotification({
          key: 'loadBankAccountsError',
          message: tl(translations.notifications.bankAccountListContext.loadError),
          type: 'error',
        });
      });
  };

  const bankAccountOptions = useMemo(() => (bankAccounts.map(optionObjectFromBankAccount)), [bankAccounts]);

  return {
    onLoadBankAccounts,
    bankAccountOptions,
  };
}
