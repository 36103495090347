import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { ContactLegacyDto } from 'api/accounting';

export const usePersonListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.contacts.contact.table.name),
      render: (text: any, row: ContactLegacyDto) => (
        `${row.firstName || ''}${(row.firstName || '') && ' '}${row.lastName || ''}`
      ),
    },
    {
      title: tl(translations.pages.contacts.contact.table.email),
      dataKey: 'email',
    },
    {
      title: tl(translations.pages.contacts.contact.table.phoneMobile),
      dataKey: 'phoneMobile',
    },
    {
      title: tl(translations.pages.contacts.contact.table.phonePrivate),
      dataKey: 'phonePrivate',
    },
    {
      title: tl(translations.pages.contacts.contact.table.phoneOffice),
      dataKey: 'phoneBusiness',
    },
  ];
};
