import React, {
  useContext, useMemo, useState, useEffect,
} from 'react';
import './PostingList.scss';
import moment from 'moment';
import { formatToEuro } from 'lib/Utils';
import { Order } from 'services/useSort';
import { useLocation } from 'react-router';
import usePostingHGAValidation from 'storybook-components/modals/PostingWarningModal/usePostingHGAValidation';
import { PostingWarningModal } from 'storybook-components/modals/PostingWarningModal/PostingWarningModal';
import _ from 'lodash';
import Amount from 'storybook-components/typography/Amount/Amount';
import useSmartTable from '../../../elements/SmartTable/useSmartTable';
import { translations } from '../../../elements/Translation/translations';
import { translations as accountSheetTranslations } from '../translations';
import { PostingListContext } from '../../../contexts/PostingListContext';
import SmartTable from '../../../elements/SmartTable/SmartTable';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { UsePostingListColumns } from './UsePostingListColumns';
import { Action } from '../../../elements/SmartTable/data';
import {
  PostingItemDetailsDto,
  PostingItemDetailsDtoAccountingEventTypeEnum,
  PostingItemDetailsDtoExchangePlanTypeEnum,
} from '../../../api/accounting';
import PostingDeletionOrReversionModal from './components/PostingDeletionOrReversionModal';
import { useModal } from '../../../elements/Modals/useModal/useModal';
import { InvoiceEditingContext } from '../../../contexts/InvoiceEditingContext';
import { useRevertExchange } from '../services/useRevertExchange';
import { useTransactionNavigation } from '../services/useTransactionNavigation';

export default function PostingList(): JSX.Element {
  const {
    data, loading, balanceData, sortField, sortOrder, setSortField, onDeletePosting, onRevertPosting, removePostingsRelatedToExchange,
  } = useContext(PostingListContext);
  const { onRevertExchange } = useRevertExchange();
  const [selectedPosting, setSelecetedPosting] = useState<PostingItemDetailsDto>({});
  const { tl } = useContext(LanguageContext);
  const { headers } = translations.pages.accountSheet.table;

  const { onLoadInvoiceByInvoiceId } = useContext(InvoiceEditingContext);
  const location = useLocation();

  const deletionModal = useModal({ onOk: (id: number) => onDeletePosting(id) });
  const revertPostingModal = useModal({ onOk: (id: number) => onRevertPosting(id) });
  const revertExchnageModalAction = (id: number) => onRevertExchange(id).then(() => removePostingsRelatedToExchange(id));
  const revertExchangeModal = useModal({ onOk: (id: number) => revertExchnageModalAction(id) });
  const { navigateToAllocationFromTx, navigateToAllocation } = useTransactionNavigation();

  const isExchangePosting = (record: PostingItemDetailsDto, accountingEventType: PostingItemDetailsDtoAccountingEventTypeEnum) => record.accountingEventType === accountingEventType.toString();

  const {
    visible, isHgaClosed, onClickProceed, onClickReview, onClickCancel, showModal, year,
  } = usePostingHGAValidation({
    propertyId: selectedPosting.propertyId,
    date: selectedPosting.postingDate ? moment(selectedPosting.postingDate, 'YYYY-MM-DD') : undefined,
  });
  const actionsMenu: Action[] = [{
    label: tl(accountSheetTranslations.postingList.postingDeletionModal.delete),
    onAction: (record: PostingItemDetailsDto) => {
      const param = record.postingId ? record.postingId : null;
      deletionModal.showModal(param);
    },
    actionSupported: (record: PostingItemDetailsDto) => [PostingItemDetailsDtoAccountingEventTypeEnum.LEGACY_DEBT, PostingItemDetailsDtoAccountingEventTypeEnum.UNKNOWN, PostingItemDetailsDtoAccountingEventTypeEnum.MANUAL_POSTING].includes(record.accountingEventType!),
  }, {
    label: tl(accountSheetTranslations.postingList.postingRevertingModal.revert),
    onAction: (record: PostingItemDetailsDto) => {
      setSelecetedPosting(record);
    },
    actionSupported: () => true,
  }];


  const onActionRevertPosting = (record: PostingItemDetailsDto) => {
    if (record.accountingEventType === PostingItemDetailsDtoAccountingEventTypeEnum.LEGACY_ALLOCATION && record.transactionId) {
      navigateToAllocationFromTx(record.transactionId);
    } else if (record.exchangePlanType === PostingItemDetailsDtoExchangePlanTypeEnum.INVOICE) {
      onLoadInvoiceByInvoiceId(record.exchangePlanId, `${location.pathname}/invoice/edit`, 'triggerEdit=true');
    } else if (isExchangePosting(record, PostingItemDetailsDtoAccountingEventTypeEnum.XC_ALLOCATION)) {
      navigateToAllocation(record.allocationId!);
    } else if (isExchangePosting(record, PostingItemDetailsDtoAccountingEventTypeEnum.XC_RAISE)) {
      if (isHgaClosed) {
        showModal(() => revertExchnageModalAction(record.exchangeId));
      } else {
        revertExchangeModal.showModal(record.exchangeId);
      }
    } else {
      const param = record.postingId ? record.postingId : null;
      if (isHgaClosed) {
        showModal(() => onRevertPosting(param));
      } else {
        revertPostingModal.showModal(param);
      }
    }
  };


  useEffect(() => {
    if (!_.isEmpty(selectedPosting) && moment(selectedPosting.postingDate).get('year').toString() === year) {
      onActionRevertPosting(selectedPosting);
    }
  }, [selectedPosting, year]);

  const toTableData = (postingItem: any) => ({
    ...postingItem,
    postDate: postingItem.postDate ? moment(postingItem.postDate).format('YYYY-MM-DD') : '',
    invoiceDate: postingItem.invoiceDate ? moment(postingItem.invoiceDate).format('YYYY-MM-DD') : '',
    credit: postingItem.credit,
    debit: postingItem.debit,
    totalGross: postingItem.totalGross,
    accountCode: postingItem.accountCode || '',
  });


  const tableData = useMemo(() => data.map(toTableData), [data]);
  const smartTable = useSmartTable({
    tableName: 'postingList',
    columns: UsePostingListColumns(),
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    dataSource: tableData,
    contentLoading: loading,
    // infiniteScrollerProps: {
    //   hasMoreData: !lastPage,
    //   loadMoreData: () => onLoadPostings(propertyHrId, accountCode),
    // },
    rowKey: 'rowKey',
    actionsMenu,
  });


  const debitSum = useMemo(() => data.reduce((acc: number, entry: any) => acc + (entry.debit || 0), 0), [data]);
  const creditSum = useMemo(() => data.reduce((acc: number, entry: any) => acc + (entry.credit || 0), 0), [data]);

  const startingBalanceRowData = useMemo(() => [
    {
      dataKey: 'accountCode',
      value: tl(headers.startingBalance),
      className: 'sticky-row-header',
    },
    {
      dataKey: 'runningBalance',
      value: <Amount>{formatToEuro(balanceData.startBalance)}</Amount>,
      className: 'number',
    },
  ], [balanceData.startBalance]);

  const endBalanceRowData = useMemo(() => [
    {
      dataKey: 'accountCode',
      value: tl(headers.endBalance),
      className: 'sticky-row-header',
    },
    {
      dataKey: 'invoiceHrId',
      value: '',
    },
    {
      dataKey: 'invoiceDate',
      value: '',
    },
    {
      dataKey: 'totalGross',
      value: '',
    },
    {
      dataKey: 'state',
      value: '',
    },
    {
      dataKey: 'creditor',
      value: '',
    },
    {
      dataKey: 'postDate',
      value: '',
    },
    {
      dataKey: 'bookingText',
      value: '',
    },
    {
      dataKey: 'counterAccounts',
      value: '',
    },
    {
      dataKey: 'debit',
      value: <Amount>{formatToEuro(debitSum)}</Amount>,
      className: 'number',
    },
    {
      dataKey: 'credit',
      value: <Amount>{formatToEuro(creditSum)}</Amount>,
      className: 'number',
    },
    {
      dataKey: 'runningBalance',
      value: <Amount>{formatToEuro(balanceData.endBalance)}</Amount>,
      className: 'number background-darker',
    },
  ], [debitSum, creditSum, balanceData.endBalance]);

  const stickyFirstRowData = sortOrder === Order.ASC ? startingBalanceRowData : endBalanceRowData;
  const stickyLastRowData = sortOrder === Order.ASC ? endBalanceRowData : startingBalanceRowData;
  return (
    <div className="PostingList">
      {/**/}
      <SmartTable {...smartTable} stickyFirstRowData={stickyFirstRowData} stickyLastRowData={stickyLastRowData} />
      <PostingDeletionOrReversionModal {...deletionModal} type="postingDeletionModal" />
      <PostingDeletionOrReversionModal {...revertPostingModal} type="postingRevertingModal" />
      <PostingDeletionOrReversionModal {...revertExchangeModal} type="postingRevertingModal" />
      <PostingWarningModal
        visible={visible}
        onCancel={onClickCancel}
        onProceed={onClickProceed}
        onReview={onClickReview}
      >
        <span>
          {tl(accountSheetTranslations.postingList.postingRevertingModal.content)}
          <br />
          {tl(accountSheetTranslations.postingList.postingRevertingModal.posting)}
        </span>
      </PostingWarningModal>
    </div>
  );
}
