import { useOpsCostReportHtmlGenerator } from 'pages/OpsCostReport/OpsCostReportEditing/services/useOpsCostReportHtmlGenerator';
import { useDunningAnnexHtmlGenerator } from 'pages/OrderOpenBalances/OrderOpenBalancesView/services/useDunningAnnexHtmlGenerator';


/**
 * Main function to handles all events. The serviceWorker should forward all events to this function.
 * In case serviceWorker is not supported, this method should be called from main thread.
 *
 * This should be the only function that impower-lib.ts exports for serviceWorkers.
 */
export const handleServiceWorkerMessageEvents = (eventData): any => {
  if (eventData.type === 'OPS_COST_REPORT') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useOpsCostReportHtmlGenerator(eventData);
  }
  if (eventData.type === 'DUNNING_ANNEX') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDunningAnnexHtmlGenerator(eventData);
  }
  throw new Error('Event type not suppoerted.');
};

export default { handleServiceWorkerMessageEvents };
