import { isEmpty } from 'lodash';
import { useContext, useEffect, useRef } from 'react';
import { useLinkSearchBarToQueryParams } from 'services/filtering/useLinkSearchBarToQueryParams';
import useQueryParamsAsDefaultFilter from 'services/filtering/useQueryParamsAsDefaultFilter';
import { Filter, GlobalSearchContext } from './GlobalSearchContext';

export interface GlobalSearchFilterProps {
  availableFilters: Filter[],
  defaultFilters?: any,
  setFilter: (key: string, value: any) => void,
}

interface UseGlobalSearchProps {
  key: string
  availableFiltersKey?: string,
  filterProps?: GlobalSearchFilterProps,
  queryParamAsFilter?: {
    defaultFilters?: any,
    onSetDefaultFilterFromQueryParams: (searchParams: { [key: string]: any }) => void,
    filterState?: any,
    syncSearchBarAndQueryParams?: boolean,
  }
}

export const useGlobalSearch = ({
  key, filterProps, availableFiltersKey, queryParamAsFilter,
}: UseGlobalSearchProps) => {
  const { registerPage, deregisterPage, updateAvailableFilters } = useContext(GlobalSearchContext);
  const listenerKey = useRef<number | null>(null);

  const syncSearchBarAndQueryParams = queryParamAsFilter?.syncSearchBarAndQueryParams ?? true;

  const {
    defaultFilters: defaultFiltersFromQueryParams,
  } = useQueryParamsAsDefaultFilter({
    onSetDefaultFilterFromQueryParams: queryParamAsFilter?.onSetDefaultFilterFromQueryParams,
    fallbackDefaulFilters: queryParamAsFilter?.defaultFilters || {},
    syncSearchBarAndQueryParams,
  });

  useLinkSearchBarToQueryParams(queryParamAsFilter?.filterState, syncSearchBarAndQueryParams);

  useEffect(() => {
    listenerKey.current = registerPage(key, {
      ...filterProps,
      defaultFilters:
        defaultFiltersFromQueryParams && !isEmpty(defaultFiltersFromQueryParams)
          ? defaultFiltersFromQueryParams
          : filterProps?.defaultFilters,
    });
    return () => {
      deregisterPage(key);
    };
  }, [key, filterProps?.defaultFilters, defaultFiltersFromQueryParams]);

  useEffect(() => {
    // update available filters
    updateAvailableFilters(key, filterProps);
  }, [availableFiltersKey]);
};
