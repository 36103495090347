import { lazy } from 'react';

import {
  PropertyLegacyDtoAdministrationTypeEnum,
  UnitContractProjectionDtoTypeEnum,
} from 'api/accounting/models';
import { RouteDefinition } from 'components/RenderRoutes';
import {
  BANKACCOUNT_COMPONENT,
  BANKACCOUNT_CREATE_PATH,
  BANKACCOUNT_EDIT_PATH,
} from 'pages/BankAccountEditor/routes';
import {
  DISTRIBUTION_COMPONENT,
  DISTRIBUTION_CREATE_PATH,
  DISTRIBUTION_EDIT_PATH,
} from 'pages/Distribution/routes';
import {
  HEATING_CENTER_CREATE_PATH,
  HEATING_CENTER_EDIT_PATH,
  HEATING_CENTER_EDITOR_PAGE,
} from 'pages/HeatingCenter/routesHeatingCenter';
import {
  UNIT_SET_COMPONENT,
  UNIT_SET_CREATE_PATH,
  UNIT_SET_EDIT_PATH,
} from 'pages/UnitSet/routes';

import {
  PropertyPage,
} from './PropertyEditing/pages/PropertyPage/PropertyPage';
import UsePropertyList from './PropertyList/PropertyList';

const ContractEditor = lazy(() => import('pages/ContractEditor/ContractEditor'));
const PropertyCreation = lazy(() => import('./PropertyEditing/PropertyCreation'));
const PropertyPortalImport = lazy(() => import('pages/PropertyPortalImport/PropertyPortalImport'));
const PropertyEditing = lazy(() => import('./PropertyEditing/PropertyEditing'));
const UnitContractHistory = lazy(() => import('pages/UnitContractHistory/UnitContractHistory'));
const PropertyOwnerContractEditor = lazy(() => import('pages/ContractEditor/pages/PropertyOwnerContractEditor/PropertyOwnerContractEditor'));
const RentPlanEditor = lazy(() => import('pages/ContractEditor/pages/RentPlanEditor/RentPlanEditor'));
const DirectDebitMandateCreation = lazy(() => import('pages/DirectDebitMandateCreation/DirectDebitMandateCreation'));

export const propertyCreationPath = '/properties/create';
const propertyEditingPath = '/properties/:propertyHrId/edit';
const contractListPath = `*${propertyEditingPath}/unit/:unitDbId/contract/all`;
const editContractPath = `${contractListPath}/edit/:contractDbId`;
const createContractPath = '/unit/:unitDbId/contract/all/create?type=:contractType&isVacant=:isVacant';
const propertyOwnerContractPath = `*${propertyEditingPath}/property-owners`;
const propertyOwnerContractEditingPath = `${propertyOwnerContractPath}/edit/:contractDbId`;

export const getPropertyCreationRoute = (administrationType: PropertyLegacyDtoAdministrationTypeEnum) => (`${propertyCreationPath}?type=${administrationType}`);

export const getPropertyEditingRoute = (propertyHrId: string) => (`/properties/${propertyHrId}/edit`);
export const getContractListPath = (propertyHrId: string, unitId: number) => (contractListPath
  .substring(1)
  .replace(':unitDbId', `${unitId}`)
  .replace(':propertyHrId', `${propertyHrId}`));

const rentPlanEditingRoute = `${editContractPath}/rent-plan/:rentPlanDbId/edit`;
export const getRentPlanEditingRoute = ({
  propertyHrId, unitDbId, contractDbId, rentPlanDbId,
}: {
  propertyHrId: string | number,
  contractDbId: string | number,
  rentPlanDbId: string | number,
  unitDbId: string | number,
}) => (
  rentPlanEditingRoute
    .substring(1) // remove '*' from beginning of URL to allow navigating correctly
    .replace(':propertyHrId', `${propertyHrId}`)
    .replace(':unitDbId', `${unitDbId}`)
    .replace(':contractDbId', `${contractDbId}`)
    .replace(':rentPlanDbId', `${rentPlanDbId}`)
);

const rentPlanCreateRouteWithSevContext = `${editContractPath}/rent-plan/create?sevTenantContractId=:sevTenantContractId&sevPropertyId=:sevPropertyId`;
export const getRentPlanCreationRouteWithSevContext = ({
  propertyHrId, unitDbId, contractDbId, sevTenantContractId, sevPropertyId,
}:{
  propertyHrId: string | number,
  contractDbId: string | number,
  unitDbId: string | number,
  sevTenantContractId: string | number,
  sevPropertyId: string | number,
}) => (
  rentPlanCreateRouteWithSevContext
    .substring(1) // remove '*' from beginning of URL to allow navigating correctly
    .replace(':propertyHrId', `${propertyHrId}`)
    .replace(':unitDbId', `${unitDbId}`)
    .replace(':contractDbId', `${contractDbId}`)
    .replace(':sevTenantContractId', `${sevTenantContractId}`)
    .replace(':sevPropertyId', `${sevPropertyId}`)
);

export const getContractCreationRoute = ({
  propertyViewPath, unitDbId, contractType, isVacant,
}: {
  propertyViewPath: string,
  unitDbId: string | number,
  contractType: UnitContractProjectionDtoTypeEnum,
  isVacant: boolean,
}) => (
  `${propertyViewPath}/unit/${unitDbId}/contract/all/create?type=${contractType}&isVacant=${isVacant}`
);

export const getContractEditingRoute = ({
  propertyHrId, unitDbId, contractDbId,
}: {
  propertyHrId: string | number,
  contractDbId: string | number,
  unitDbId: string | number,
}) => (
  editContractPath
    .substring(1) // remove '*' from beginning of URL to allow navigating correctly
    .replace(':propertyHrId', `${propertyHrId}`)
    .replace(':unitDbId', `${unitDbId}`)
    .replace(':contractDbId', `${contractDbId}`)
);

export const getContractCreatingRoute = ({
  unitDbId, contractType, isVacant,
}: {
  contractType: UnitContractProjectionDtoTypeEnum,
  unitDbId: string | number,
  isVacant: boolean,
}) => (
  createContractPath
    .replace(':unitDbId', `${unitDbId}`)
    .replace(':contractType', `${contractType}`)
    .replace(':isVacant', `${isVacant}`)
);

export const getContractHistoryRoute = ({
  propertyHrId, unitDbId,
}: {
  propertyHrId: string | number,
  unitDbId: string | number,
}) => (
  contractListPath
    .substring(1) // remove '*' from beginning of URL to allow navigating correctly
    .replace(':propertyHrId', `${propertyHrId}`)
    .replace(':unitDbId', `${unitDbId}`)
);

export const getPropertyOwnerContractEditingRoute = ({
  propertyHrId, contractDbId,
}: {
  propertyHrId: string | number,
  contractDbId: string | number,
}) => (
  propertyOwnerContractEditingPath
    .substring(1) // remove '*' from beginning of URL to allow navigating correctly
    .replace(':propertyHrId', `${propertyHrId}`)
    .replace(':contractDbId', `${contractDbId}`)
);


export const getHeatingCenterEditPath = (propertyHrId: string, hcId: string) => `${getPropertyEditingRoute(propertyHrId)}${HEATING_CENTER_EDIT_PATH.replace(':hcId', hcId)}`;


export const PropertyRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/properties',
      component: UsePropertyList,
      strict: true,
      subRoutes: [
        {
          path: propertyCreationPath,
          component: PropertyCreation,
          type: 'focus',
        },
        {
          path: '/properties/:propertyId',
          component: PropertyPage,
          type: 'main',
          exact: true,
        },
        {
          path: '/properties/import-from-portal/:connectionId',
          component: PropertyPortalImport,
          strict: true,
          type: 'focus',
        },
        {
          path: `*${propertyEditingPath}`,
          component: PropertyEditing,
          type: 'focus',
          subRoutes: [
            // if the unit has contracts
            {
              path: contractListPath,
              component: UnitContractHistory,
              type: 'overlay',
              subRoutes: [
                {
                  path: [`${contractListPath}/create`, editContractPath],
                  component: ContractEditor,
                  type: 'overlay',
                  subRoutes: [
                    {
                      path: [`${contractListPath}/create/mandate/:bankAccountId`, `${editContractPath}/mandate/:bankAccountId`],
                      component: DirectDebitMandateCreation,
                      type: 'overlay',
                    },
                    {
                      path: [`${editContractPath}/rent-plan/create`, `${editContractPath}/rent-plan/:rentPlanDbId/edit`],
                      component: RentPlanEditor,
                      type: 'overlay',
                    },
                  ],
                },
              ],
            },
            {
              path: [`${propertyOwnerContractPath}/create`, `${propertyOwnerContractPath}/edit/:contractDbId`],
              component: PropertyOwnerContractEditor,
              type: 'overlay',
              subRoutes: [
                {
                  path: [`${propertyOwnerContractPath}/create/mandate/:bankAccountId`, `${propertyOwnerContractPath}/edit/:contractDbId/mandate/:bankAccountId`],
                  component: DirectDebitMandateCreation,
                  type: 'overlay',
                },
              ],
            },
            {
              path: [`${propertyEditingPath}${BANKACCOUNT_CREATE_PATH}`, `${propertyEditingPath}${BANKACCOUNT_EDIT_PATH}`],
              component: BANKACCOUNT_COMPONENT,
              type: 'overlay',
            },
            {
              path: [`${propertyEditingPath}${DISTRIBUTION_CREATE_PATH}`, `${propertyEditingPath}${DISTRIBUTION_EDIT_PATH}`],
              component: DISTRIBUTION_COMPONENT,
              type: 'overlay',
            },
            {
              path: [`${propertyEditingPath}${UNIT_SET_CREATE_PATH}`, `${propertyEditingPath}${UNIT_SET_EDIT_PATH}`],
              component: UNIT_SET_COMPONENT,
              type: 'overlay',
            },
            {
              path: [`${propertyEditingPath}${HEATING_CENTER_CREATE_PATH}`, `${propertyEditingPath}${HEATING_CENTER_EDIT_PATH}`],
              component: HEATING_CENTER_EDITOR_PAGE,
              type: 'overlay',
              subRoutes: [{
                path: [
                  `${propertyEditingPath}${HEATING_CENTER_CREATE_PATH}${DISTRIBUTION_CREATE_PATH}`, `${propertyEditingPath}${HEATING_CENTER_EDIT_PATH}${DISTRIBUTION_CREATE_PATH}`,
                  `${propertyEditingPath}${HEATING_CENTER_CREATE_PATH}${DISTRIBUTION_EDIT_PATH}`, `${propertyEditingPath}${HEATING_CENTER_EDIT_PATH}${DISTRIBUTION_EDIT_PATH}`,
                ],
                component: DISTRIBUTION_COMPONENT,
                type: 'overlay',
              }],
            },
          ],
        },
      ],
    },
  ],
};
