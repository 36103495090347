import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { UnitContractProjectionDto } from '../../../api/accounting';
import { processingSelectorTranslations } from './processingSelectorTranslations';

export enum ProcessingSelectorOptions {
  PLATFORM='PLATFORM',
  SELF_PAYER='SELF_PAYER'
}

const getProcessingDefaultValue = (contract: UnitContractProjectionDto, direction: 'incoming' | 'outgoing') => {
  if (direction === 'incoming' && contract.hasMandateAtDate) return ProcessingSelectorOptions.PLATFORM;
  if (direction === 'outgoing' && !!contract.bankAccountIban) return ProcessingSelectorOptions.PLATFORM;
  return ProcessingSelectorOptions.SELF_PAYER;
};

export const useProcessingSelector = (contract: UnitContractProjectionDto, direction: 'incoming' | 'outgoing') => {
  const { tl } = useContext(LanguageContext);

  const SELECT_OPTIONS = [
    {
      label: tl(processingSelectorTranslations.selfPayer),
      value: ProcessingSelectorOptions.SELF_PAYER,
    },
    {
      label: tl(processingSelectorTranslations[direction === 'incoming' ? 'directDebit' : 'paymentRun']),
      value: 'PLATFORM',
      disabled: (direction === 'incoming' && !contract.hasMandateAtDate) || (direction === 'outgoing' && contract.bankAccountIban === undefined),
    },
  ];

  return {
    SELECT_OPTIONS,
    defaultValue: getProcessingDefaultValue(contract, direction),
  };
};
