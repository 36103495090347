import { AccountDistributionKeyCopyRequestDtoSourceEnum } from 'api/accounting';
import React, {
  createContext, ReactNode, useState,
} from 'react';

export const IndividualDistributionKeyCopyModalContext: any = createContext({});

const IndividualDistributionKeyCopyModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [sourceDocumentType, setSourceDocumentType] = useState<AccountDistributionKeyCopyRequestDtoSourceEnum>(AccountDistributionKeyCopyRequestDtoSourceEnum.EP);
  const [targetEPType, setTargetEPType] = useState<boolean>(false);
  const [targetHMSType, setTargetHMSType] = useState<boolean>(false);
  const [targetAllType, setTargetAllType] = useState<boolean>(false);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectedYears, setSelectedYears] = useState<string[]>([]);

  return (
    <IndividualDistributionKeyCopyModalContext.Provider value={{
      sourceDocumentType,
      setSourceDocumentType,
      targetEPType,
      setTargetEPType,
      targetHMSType,
      setTargetHMSType,
      targetAllType,
      setTargetAllType,
      selectedAccounts,
      setSelectedAccounts,
      selectedYears,
      setSelectedYears,
    }}
    >
      {children}
    </IndividualDistributionKeyCopyModalContext.Provider>
  );
};

export default IndividualDistributionKeyCopyModalContextProvider;
