import { useContext } from 'react';
import { Filter } from '../../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { EconomicPlanDtoStatusEnum, OwnersMeetingInvitationDtoStatusEnum } from '../../../api/accounting';
import { ownersMeetingInvitationTranslations } from '../OwnersMeetingInvitationEditing/translations/OwnersMeetingInvitationTranslations';

enum HouseMoneySettlementState{
  DRAFT = 'DRAFT',
  PREPARED = 'PREPARED',
  FINALIZED = 'FINALIZED',
  FAILED = 'FAILED',
}

export default function useOwnersMeetingInvitationFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);

  return [
    {
      type: 'enum',
      name: tl(ownersMeetingInvitationTranslations.table.headers.status),
      key: 'statuses',
      options: Object.values(OwnersMeetingInvitationDtoStatusEnum).map(value => ({
        label: tl(ownersMeetingInvitationTranslations.ownersMeetingStates[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'number',
      name: tl(ownersMeetingInvitationTranslations.table.headers.etvId),
      key: 'etvId',
    },
    {
      type: 'number',
      name: tl(ownersMeetingInvitationTranslations.table.headers.propertyId),
      key: 'propertyId',
    },
    {
      type: 'text',
      name: tl(ownersMeetingInvitationTranslations.table.headers.property),
      key: 'propertyName',
    },
    {
      type: 'text',
      name: tl(ownersMeetingInvitationTranslations.table.headers.propertyManager),
      key: 'propertySupervisorName',
    },
    {
      type: 'date',
      name: `Min ${tl(ownersMeetingInvitationTranslations.table.headers.date)}`,
      key: 'minDate',
    },
    {
      type: 'date',
      name: `Max ${tl(ownersMeetingInvitationTranslations.table.headers.date)}`,
      key: 'maxDate',
    },
    {
      type: 'text',
      name: tl(ownersMeetingInvitationTranslations.table.headers.hgaTitle),
      key: 'houseMoneySettlement',
    },
    {
      type: 'enum',
      name: tl(ownersMeetingInvitationTranslations.table.headers.hgaStatus),
      key: 'houseMoneySettlementStatuses',
      options: Object.values(HouseMoneySettlementState).map(value => ({
        label: tl(ownersMeetingInvitationTranslations.ownersMeetingStates[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'enum',
      name: tl(ownersMeetingInvitationTranslations.table.headers.wpStatus),
      key: 'economicPlanStatuses',
      options: Object.values(EconomicPlanDtoStatusEnum).map(value => ({
        label: tl(ownersMeetingInvitationTranslations.ownersMeetingStates[value]),
        value,
      })),
      multiValue: true,
    },
    {
      type: 'text',
      name: tl(ownersMeetingInvitationTranslations.table.headers.wpTitle),
      key: 'economicPlan',
    },
  ];
}
