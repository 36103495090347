import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import Amount from 'storybook-components/typography/Amount/Amount';

export const DunningAnnexAccountBalanceTableTotalSummary = ({ totalSummaryLabel, totalSummaryAmount }: { totalSummaryLabel: string, totalSummaryAmount: Number }) => (
  <Table.Summary>
    <Table.Summary.Row>
      <Table.Summary.Cell index={1} colSpan={4}>
        {totalSummaryLabel}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2} colSpan={1} className="column-align-right">
        <Amount>{formatCurrency(totalSummaryAmount, '0,00', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);
