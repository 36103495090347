import { PropertyLegacyControllerApi, PropertyDisplayDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useState } from 'react';

export const useGetPropertyById = (propertyId: number) => {
  const { tl } = useContext(LanguageContext);
  const [property, setProperty] = useState<PropertyDisplayDto>();

  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));


  useEffect(() => {
    loadProperty();
  }, [propertyId]);

  const loadProperty = () => {
    if (!propertyId) return;
    propertyControllerApi.getPropertyDisplayByIdUsingGET({ propertyId })
      .then((resp) => {
        setProperty(resp);
      })
      .catch(() => {
        showNotification({
          key: 'loadPropertyError',
          type: 'error',
          message: tl(translations.notifications.propertyEditingContext.loadError.description),
        });
      });
  };

  return {
    property,
  };
};
