import { useContext, useMemo } from 'react';
import _ from 'lodash';
import { DECIMAL_MATCHER } from 'lib/Utils';
import { ListSpecialContributionUsingGETSpecialContributionStatusesEnum, ListSpecialContributionUsingGETTypeEnum } from 'api/accounting';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import useDefaultPropertyFilters from '../../../components/Header/components/data/useDefaultPropertyFilters';
import { translations } from '../translations';

export const useSpecialContributionFilters = (selectedQuickFilter: string, setFilterState: Function) => {
  const { tl } = useContext(LanguageContext);

  const statusOptions = useMemo(() => {
    if (selectedQuickFilter === 'OPEN') {
      return [
        {
          label: tl(translations.specialContribution.statuses.DRAFT),
          value: ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DRAFT,
        },
        {
          label: tl(translations.specialContribution.statuses.ERROR),
          value: ListSpecialContributionUsingGETSpecialContributionStatusesEnum.ERROR,
        },
      ];
    }
    if (selectedQuickFilter === 'DONE') {
      return [
        {
          label: tl(translations.specialContribution.statuses.DONE),
          value: ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DONE,
        },
      ];
    }
    return [
      {
        label: tl(translations.specialContribution.statuses.DRAFT),
        value: ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DRAFT,
      },
      {
        label: tl(translations.specialContribution.statuses.DONE),
        value: ListSpecialContributionUsingGETSpecialContributionStatusesEnum.DONE,
      },
      {
        label: tl(translations.specialContribution.statuses.ERROR),
        value: ListSpecialContributionUsingGETSpecialContributionStatusesEnum.ERROR,
      },
    ];
  }, [selectedQuickFilter]);

  const commonFilters: Filter[] = [
    ...useDefaultPropertyFilters(false),
    {
      type: 'decimal',
      name: tl(translations.specialContribution.table.amount),
      matcher: DECIMAL_MATCHER,
      key: 'totalAmount',
    },
    {
      type: 'decimal',
      name: `Min ${tl(translations.specialContribution.table.amount)}`,
      matcher: DECIMAL_MATCHER,
      key: 'minAmount',
    },
    {
      type: 'decimal',
      name: `Max ${tl(translations.specialContribution.table.amount)}`,
      matcher: DECIMAL_MATCHER,
      key: 'maxAmount',
    },
    {
      type: 'date',
      name: tl(translations.specialContribution.filters.fromDate),
      key: 'fromDate',
    },
    {
      type: 'date',
      name: tl(translations.specialContribution.filters.untilDate),
      key: 'untilDate',
    },
    {
      type: 'text',
      name: tl(translations.specialContribution.filters.statement),
      key: 'statement',
    },
    {
      type: 'enum',
      name: tl(translations.specialContribution.table.header.type),
      key: 'type',
      options: [
        {
          label: tl(translations.specialContribution.types.CONTRIBUTION),
          value: ListSpecialContributionUsingGETTypeEnum.CONTRIBUTION,
        },
        {
          label: tl(translations.specialContribution.types.DISTRIBUTION),
          value: ListSpecialContributionUsingGETTypeEnum.DISTRIBUTION,
        },
      ],
      multiValue: true,
    },
  ];

  const availableFilters: Filter[] = useMemo(() => [
    ...commonFilters,
    {
      type: 'enum',
      name: tl(translations.specialContribution.table.header.status),
      key: 'specialContributionStatuses',
      options: statusOptions,
      multiValue: true,
    },
  ], [selectedQuickFilter]);

  const onChangeFilter = (key: string, value: string) => setFilterState(
    (currentFilter: any) => {
      const newFilter = _.cloneDeep(currentFilter);
      if (value === undefined || value === null) {
        // @ts-ignore
        delete newFilter[key];
      } else {
        // @ts-ignore
        newFilter[key] = value;
      }
      return newFilter;
    },
  );

  return {
    key: 'specialContribution',
    availableFiltersKey: `${selectedQuickFilter}`,
    filterProps: {
      setFilter: onChangeFilter,
      availableFilters,
    },
  };
};
