import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import EmptyTableMultilineMessage
  from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { inviteUsersTranslations } from 'pages/Users/InviteUsers/inviteUsersTranslations';


const EmptyUsersTableComponent = () => {
  const { tl } = useContext(LanguageContext);

  return (
    <EmptyTableMultilineMessage description={tl(inviteUsersTranslations.noUsers)} />
  );
};

export default EmptyUsersTableComponent;
