import { LanguageContext } from 'contexts/LanguageContext';
import {
  FindFilteredPropertiesUsingGETAdministrationTypesEnum,
  FindFilteredPropertiesUsingGETOrderEnum, FindFilteredPropertiesUsingGETRequest, PropertyDisplayDtoAdministrationTypeEnum, PropertyLegacyControllerApi,
} from 'api/accounting';

import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect } from 'react';
import { showNotification } from 'lib/Notification';
import { isEmpty, isNil } from 'lodash';
import { useParams } from 'react-router';
import { translations } from '../../../elements/Translation/translations';
import { useDistributionKeyPropertyListContext } from './DistributionKeysPropertyListContext';


export const useDistributionKeysPropertyList = () => {
  const { tl } = useContext(LanguageContext);
  const {
    propertyList, setPropertyList, filterState, setFilterState, sortState, setSortState,
  } = useDistributionKeyPropertyListContext('useDistributionKeysPropertyList');
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    loadPropertyList(true);
  }, [filterState, sortState]);

  useEffect(() => {
    if (propertyHrId) {
      onChangeFilterState('propertyHrId', propertyHrId);
    }
  }, [propertyHrId]);

  const getValidAdministrationTypeFilter = () => {
    if (isEmpty(filterState?.administrationTypes)) {
      return [PropertyDisplayDtoAdministrationTypeEnum.MV, PropertyDisplayDtoAdministrationTypeEnum.WEG, PropertyDisplayDtoAdministrationTypeEnum.SEV_MV] as unknown as FindFilteredPropertiesUsingGETAdministrationTypesEnum;
    }
    return filterState.administrationTypes;
  };

  const loadPropertyList = (resetPage: boolean = false) => {
    setPropertyList(state => state.startLoading());
    propertyControllerApi.findFilteredPropertiesUsingGET({
      ...filterState,
      administrationTypes: getValidAdministrationTypeFilter(),
      // @ts-ignore
      excludeFields: ['bankAccounts'],
      page: resetPage ? 0 : propertyList.page,
      order: sortState.order > 0 ? FindFilteredPropertiesUsingGETOrderEnum.ASC : FindFilteredPropertiesUsingGETOrderEnum.DESC,
      sort: sortState.field,
      size: 30,
    }).then((response) => {
      setPropertyList(state => state.loadPaged(response.content, resetPage, response.last!));
    }).catch(() => {
      setPropertyList(state => state.failed());
      showNotification({
        key: 'loadPropertiesError',
        message: tl(translations.notifications.usePropertyList.loadError.message),
        description: tl(translations.notifications.usePropertyList.loadError.description),
        type: 'error',
      });
    });
  };

  const onChangeFilterState = (key: keyof FindFilteredPropertiesUsingGETRequest, value: any[keyof FindFilteredPropertiesUsingGETRequest]) => {
    setFilterState((prev) => {
      if (isNil(value)) {
        return ({
          ...prev,
          [key]: undefined,
        });
      }
      return ({
        ...prev,
        [key]: value,
      });
    });
  };

  const setSortField = (field: string) => {
  // @ts-ignore
    setSortState((old) => {
      const order = old.field === field ? old.order * (-1) : 1;
      return {
        field,
        order,
      };
    });
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  return {
    filterState,
    propertyList,
    sortState,
    loadPropertyList,
    onChangeFilterState,
    setSortField,
    onSetDefaultFilterFromQueryParams,
  };
};
