import { InviteUsersContext } from 'pages/Users/InviteUsers/services/InviteUsersContext';
import { useContext } from 'react';

export const useRemoveUserButton = () => {
  const inviteUsersContext = useContext(InviteUsersContext);
  if (inviteUsersContext === undefined) {
    throw Error('useInviteUsersCell not wrapped in InviteUsersContextProvider');
  }
  const { setUsersList } = inviteUsersContext;

  const removeUser = (atIndex: number) => {
    setUsersList(prev => prev.load(prev.data.filter((_, index) => index !== atIndex)));
  };

  return {
    removeUser,
  };
};
