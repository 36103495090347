import { useContext } from 'react';
import { Modal } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { AuthContext } from 'contexts/AuthContext';
import { Configuration, DocumentLegacyControllerApi } from 'api/document';
import { PdfPageAssignerDataContext, PdfPageAssignerUpdatersContext } from 'pages/PdfPageAssigner/services/PdfPageAssignerContext';
import { uniq } from 'lodash';
import { showNotification } from 'lib/Notification';
import { translations } from '../../../../translations';

export const usePdfViewerWithAssignmentsHeader = (documentId: number) => {
  const { tl } = useContext(LanguageContext);
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);
  const { apiConfiguration } = useContext(AuthContext);
  const documentController = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as Configuration);


  if (pdfPageAssignerDataContext === undefined || pdfPageAssignerUpdatersContext === undefined) {
    throw new Error('usePdfViewerWithAssignmentsHeader must be used within a PdfPageAssignerContextProvider');
  }


  const { existingAssignments, loading } = pdfPageAssignerDataContext;
  const {
    setCurrentAssignments,
    setExistingAssignments,
    setLoadedPdfsData,
    setOriginalDocuments,
    setSelectedPages,
  } = pdfPageAssignerUpdatersContext;


  const onCLickDeleteFile = () => {
    Modal.confirm({
      title: tl(translations.modals.deleteModal.title),
      content: tl(translations.modals.deleteModal.content),
      okText: tl(translations.modals.deleteModal.okText),
      cancelText: tl(translations.modals.deleteModal.cancelText),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: onConfirmDeleteFile,
      width: 500,
      closable: true,
    });
  };

  const onConfirmDeleteFile = () => {
    const documentIdsToDelete = uniq(
      existingAssignments
        .filter(ea => ea.originalDocumentId === documentId)
        .map(ea => ea.assignmentDocumentId),
    );


    // if we return a promise to `onOk` then the modal will automagically show loading until it finishes
    return documentController.deleteDocumentUsingDELETE({ documentIds: [documentId, ...documentIdsToDelete] })
      .then(() => {
        setCurrentAssignments(currentAssignments => currentAssignments.filter(ca => ca.originalDocumentId !== documentId));
        setExistingAssignments(exAssignments => exAssignments.filter(ca => ca.originalDocumentId !== documentId));
        setLoadedPdfsData(pdfs => pdfs.filter(pdf => pdf.documentId !== documentId));
        setOriginalDocuments(originalDocuments => originalDocuments.load(originalDocuments.data.filter(od => od.id !== documentId)));
        setSelectedPages(selectedPages => selectedPages.filter(sp => sp.documentId !== documentId));
        showNotification({
          type: 'success',
          message: tl(translations.notifications.deleteDocumentsSuccess),
        });
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.deleteDocumentsError),
        });
      });
  };

  return { onCLickDeleteFile, loading };
};
