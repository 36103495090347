export const translations: any = {
  title: {
    en: 'New Direct Debit',
    de: 'Neue Lastschrift',
  },
  prompt: {
    en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
    de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie weiter?',
  },
  notifications: {
    saveSuccess: {
      message: {
        en: 'The direct debit was created successfully.',
        de: 'Die Lastschrift wurde erfolgreich erstellt.',
      },
      description: {
        en: 'The direct debit is in the list.',
        de: 'Die Lastschrift befindet sich in der Lastschriftliste.',
      },
    },
    saveError: {
      message: {
        en: 'Direct debit could not be saved.',
        de: 'Lastschrift konnte nicht gespeichert werden.',
      },
    },
    saveValidationError: {
      message: {
        en: 'Please fill all required fields.',
        de: 'Bitte alle notwendigen Felder ausfüllen.',
      },
      description: {
        en: 'Cannot save direct debit.',
        de: 'Lastschrift kann nicht gespeichert werden.',
      },
    },
  },
  header: {
    create: {
      en: 'Create',
      de: 'Erstellen',
    },
  },
  form: {
    property: {
      en: 'Property',
      de: 'Objekt',
    },
    bankAccount: {
      en: 'Select bank account',
      de: 'Konto auswählen',
    },
    counterpart: {
      en: 'Select contact',
      de: 'Kontakt auswählen',
    },
    counterpartBankAccount: {
      en: 'Select contact bank account',
      de: 'Wählen Sie das Gegenkonto aus',
    },
    iban: {
      en: 'IBAN',
      de: 'IBAN',
    },
    bic: {
      en: 'BIC',
      de: 'BIC',
    },
    amount: {
      en: 'Amount in EUR',
      de: 'Betrag in EUR',
    },
    text: {
      en: 'Statement',
      de: 'Verwendungstext',
    },
    execution: {
      en: 'Execute',
      de: 'Ausführen',
    },
    asap: {
      en: 'Earliest possible',
      de: 'Nächstmöglich',
    },
    date: {
      en: 'Scheduled',
      de: 'Terminiert',
    },
  },
};
