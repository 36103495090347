import React from 'react';
import { readValue } from '../../../lib/Utils';
import InputRenderer from './InputRenderer';
import DeleteButton from './DeleteButton';

const GroupContent = React.memo(({
  section, value, renderLink, validationErrors, onChange, onAddEntity, onToggle, setDeletionKey, disabled,
}: any) => {
  const linked = section.link ? readValue(value, section.link.linked) : false;
  return (
    <div className="sub-section" id={section.sectionId}>
      <div className="sub-section-header">
        {!!section.title && (
          <div className="sub-section-title">
            {section.title}
            {!!section.titleNote
                && <span className="sub-section-title-note">{section.titleNote}</span>}
          </div>
        )}
        {section.deleteButton && section.deleteButton.props.show
          && <DeleteButton {...section.deleteButton.props} className="additional-section-button" onClick={setDeletionKey} />}
        {
          section.additionalButton
        }
        {!!section.link && renderLink(section.link)}
      </div>
      {((!!section.link && !linked) || !section.link)
        && (
          <div className="sub-section-content">
            {Array.isArray(section.content) ? section.content.map((row: any, j: number) => (
              <div className="sub-section-content-row" key={j}>
                {row.map((input: any, k: number) => (
                  <div
                    className="sub-section-content-column"
                    key={k}
                    style={{
                      minWidth: input.minWidth ? input.minWidth : '25.0rem',
                      maxWidth: input.maxWidth ? input.maxWidth : 'auto',
                    }}
                  >
                    <InputRenderer
                      input0={input}
                      disabled={disabled}
                      value={value ? readValue(value, input.key) : null}
                      validationErrors={validationErrors}
                      onChange={onChange}
                      onAddEntity={onAddEntity}
                      onToggle={onToggle}
                      setDeletionKey={setDeletionKey}
                    />
                  </div>
                ))}
              </div>
            )) : section.content}
          </div>
        )}
    </div>
  );
});

export default GroupContent;
