import { Skeleton } from 'antd';

export const AccountBalanceAmountCell = ({ amount }:{ amount: string}) => (
  <Skeleton
    loading={amount === '-'}
    active
    title={false}
    paragraph={{ rows: 1, width: '100%' }}
  >
    {`${amount}`}
  </Skeleton>
);
