import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { AccountDto } from '../api/accounting';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import DEFAULT_DATA from '../lib/data';
import { showNotification } from '../lib/Notification';
import { InvoiceEditingContext } from './InvoiceEditingContext';
import { LanguageContext } from './LanguageContext';

export const AccountListContext: any = React.createContext({});

export default function AccountListProvider({ children }: any) {
  const [accountListState, setAccountListState] = useState(DEFAULT_DATA<any>([]));
  const [selectedAccount, setSelectedAccount] = useState<AccountDto>({});
  const [selectedAccountNormalityMultiplier, setSelectedAccountNormalityMultiplier] = useState<number>();
  const [selectedAccountIdentifiers, setSelectedAccountIdentifiers] = useState({
    accountCode: null,
    propertyHrId: null,
  });

  const { tl } = useContext(LanguageContext);

  const invoiceEditingContext = useContext(InvoiceEditingContext);

  const optionObjectFromAccount = (account: any) => ({
    value: account.code,
    label: `${account.code} ${account.name}`,
    divisible: account.divisible,
  });

  const onLoadBookableAccountList = (filter: string, propertyHrId: string) => {
    setAccountListState(accountList => accountList.startLoading());
    backend.get(`${endpointUrls.ACCOUNTING}/accounts/${propertyHrId}/lowest-level`, { filter })
      .then((response: any) => {
        const accountList = response.map(optionObjectFromAccount);
        setAccountListState(list => list.load(accountList));
      })
      .catch(() => {
        setAccountListState(list => list.failed());
        showNotification({
          key: 'loadAccountListError',
          message: tl(translations.notifications.accountContext.loadAccountError.message),
          description: tl(translations.notifications.accountContext.loadAccountError.description),
          type: 'error',
        });
      });
  };

  const addAccountToList = (response: any) => {
    setAccountListState((prevValue: any) => {
      if (prevValue.data.filter((account: any) => account.value === response.code).length <= 0) {
        return prevValue.load([...prevValue.data, optionObjectFromAccount(response)]);
      }
      return prevValue.load([...prevValue.data]);
    });
  };


  const errorLoading = () => {
    setAccountListState(accountListState.failed());
    showNotification({
      key: 'loadAccountListError',
      message: tl(translations.notifications.accountContext.loadAccountError.message),
      description: tl(translations.notifications.accountContext.loadAccountError.description),
      type: 'error',
    });
  };

  const onLoadAccount = (accountCode: string, propertyId?: string): void => {
    setAccountListState(accountListState.startLoading());

    const propertyHrId: string = propertyId || ((invoiceEditingContext.loaded && !!invoiceEditingContext.data.invoice.propertyHrId)
      ? invoiceEditingContext.data.invoice.propertyHrId
      : null);

    backend.get(`${endpointUrls.ACCOUNTING}/account`, { accountCode, propertyHrId })
      .then(addAccountToList)
      .catch(errorLoading);
  };

  const getAccountByName = (accountName: string) => {
    const filteredList = accountListState.data.filter((account: any) => account.label.indexOf(accountName) > -1);
    if (filteredList.length > 0) {
      return filteredList[0].value;
    }
    return '';
  };

  const getAccountByCode = (accountCode: string, propertyHrId: string) => backend.get(`${endpointUrls.ACCOUNTING}/account`, { accountCode, propertyHrId });

  useEffect(() => {
    const { accountCode, propertyHrId } = selectedAccountIdentifiers;
    if (!!accountCode && !!propertyHrId) {
      backend.get(`${endpointUrls.ACCOUNTING}/account`, { accountCode, propertyHrId })
        .then((response: any) => setSelectedAccount(response))
        .catch(errorLoading);
    }
  }, [selectedAccountIdentifiers]);

  useEffect(() => {
    if (!selectedAccount || !selectedAccount.accountType) return;
    setSelectedAccountNormalityMultiplier(() => (selectedAccount?.accountType?.debitNormal ? 1 : -1));
  },
  [selectedAccount]);

  return (
    <AccountListContext.Provider value={{
      ...accountListState,
      onLoadBookableAccountList,
      onLoadAccount,
      getAccountByName,
      getAccountByCode,
      setSelectedAccountIdentifiers,
      selectedAccountNormalityMultiplier,
      selectedAccount,
    }}
    >
      {children}
    </AccountListContext.Provider>
  );
}

AccountListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
