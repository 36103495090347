import React from 'react';
import { TabLink } from 'storybook-components/TabLink/TabLink';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import Page from 'storybook-components/layout/Page/Page';
import { useTransactionImportColumns } from './services/useTransactionImportsColumns';
import { useTransactionImports } from './services/useTransactionImports';
import './TransactionImports.scss';
import { TransactionImportActions } from './components/TransactionImportAction';
import { useTransactionImportsSectionLinks } from '../services/useTransactionImportsSectionLinks';

export const TransactionImports: React.FC = () => {
  const {
    transactionImports, load, download, lastPage, loading, sortField, sortOrder, setSortField,
  } = useTransactionImports();


  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'transactionImports',
    navItems: subcategorySwitcherItems.accounting,
  };

  const smartTable = useSmartTable({
    tableName: 'transactionImportList',
    columns: useTransactionImportColumns(download),
    dataSource: transactionImports,
    contentLoading: loading,
    infiniteScrollerProps: {
      hasMoreData: !lastPage,
      loadMoreData: load,
    },
    virtualize: false,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    rowKey: 'rowId',
  });

  const { transactionImportSectionLinks } = useTransactionImportsSectionLinks();
  return (
    <Page>
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={<TransactionImportActions />}
      />
      <TabLink links={transactionImportSectionLinks} />
      <SmartTable {...smartTable} />
    </Page>
  );
};
