import { useOpsCostReportHiddenAmountsActions } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportHiddenAmounts/selectorsOpsCostReportHiddenAmounts';
import { OpsCostSectionKeys } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/interfacesOpsCostReportManualAmounts';


export const useMissingHiddenAccountCodeAlert = () => {
  const { removeHiddenRows } = useOpsCostReportHiddenAmountsActions();

  const onCloseMissingHiddenAccountCodesAlert = (selectedTenantContractId: number, missingHiddenAccountCodes:string[], sectionName:OpsCostSectionKeys) => {
    removeHiddenRows(selectedTenantContractId, sectionName, missingHiddenAccountCodes);
  };

  return { onCloseMissingHiddenAccountCodesAlert };
};
