import { useContext } from 'react';
import moment from 'moment';
import { OwnersMeetingProtocol } from 'pages/OwnersMeetingProtocol/interfaces';
import { OwnersMeetingProtocolDtoVotingRightsEnum, OwnersMeetingProtocolDtoVotingSchemeEnum } from 'api/accounting';
import defaultSection from '../components/DefaultSection';
import { translations } from '../../../translations';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../../../contexts/PropertyListContext';
import { OptionInterface } from '../../../../../elements/Inputs/SmartSearch/SmartSearch';
import { OwnersMeetingProtocolContext } from '../../../services/OwnersMeetingProtocolContext';
import usePropertyManagementCompanyEmployeeOptions from '../../../../../services/usePropertyManagementCompanyEmployeeOptions';
import { useDefaultProtocolTexts } from './useDefaultProtocolTexts';

interface ProtocolSetupProps {
  index: number,
  disabled: boolean,
}

export default function useProtocolSetup(props: ProtocolSetupProps) {
  const { index, disabled } = props;
  const { tl } = useContext(LanguageContext);
  const { selectedDisplayProperty } = useContext(PropertyListContext);
  const {
    ownersMeeting, distributionOptions, roleOptions, votingSchemeOptions, updateOwnersMeetingProtocol,
  } = useContext(OwnersMeetingProtocolContext);

  const { managementCompanyEmployees } = usePropertyManagementCompanyEmployeeOptions();
  const { getDefaultProtocolText } = useDefaultProtocolTexts();

  const onChangeOwnerMeetingProtocolField = (v: any, key: string) => {
    updateOwnersMeetingProtocol((oldOwnersMeeting: OwnersMeetingProtocol) => ({ ...oldOwnersMeeting, [key]: v }));
  };

  const onChangeProtocolTextType = (key: string, value) => {
    onChangeOwnerMeetingProtocolField(value, `${key}IsStandardText`);
    if (value === true) {
      onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data), key);
    } else {
      onChangeOwnerMeetingProtocolField('', key);
    }
  };

  const radioGroupForKey = (key: string) => ({
    type: 'radioGroup',
    key: `${key}IsStandardText`,
    props: {
      label: tl(translations.editPage.sections.protocolSetup.radioGroup.label),
      onChange: (value) => { onChangeProtocolTextType(key, value); },
      options: [
        {
          value: true,
          label: tl(translations.editPage.sections.protocolSetup.radioGroup.options.standard),
        },
        {
          value: false,
          label: tl(translations.editPage.sections.protocolSetup.radioGroup.options.alternative),
        },
      ],
      disabled: false,
      required: true,
    },
  }
  );

  return {
    ...defaultSection(index, index),
    sectionTitle: tl(translations.editPage.sections.protocolSetup.title),
    sectionId: 'ProtocolSetup',
    disabled,
    content: [{
      sectionId: 'meetingDuration',
      title: tl(translations.editPage.sections.protocolSetup.meetingDuration.title),
      content: [
        [
          {
            type: 'time',
            key: 'startTime',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.meetingDuration.fields.start),
              required: true,
              onChangeSideEffect: (val: moment.Moment) => {
                const key = 'meetingDurationProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  const value = {
                    startTime: val ? val.format('HH:mm') : '00:00',
                    endTime: ownersMeeting.data && ownersMeeting.data.endTime ? moment(ownersMeeting.data.endTime).format('HH:mm') : '00:00',
                  };
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                }
              },
            },
          },
          {
            type: 'time',
            key: 'endTime',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.meetingDuration.fields.end),
              required: true,
              onChangeSideEffect: (val: moment.Moment) => {
                const key = 'meetingDurationProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  const value = {
                    startTime: ownersMeeting.data && ownersMeeting.data.startTime ? moment(ownersMeeting.data.startTime).format('HH:mm') : '00:00',
                    endTime: val ? val.format('HH:mm') : '00:00',
                  };
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                }
              },
            },
          },
        ],
        [
          radioGroupForKey('meetingDurationProtocolText'),
        ],
        [
          {
            type: 'textArea',
            key: 'meetingDurationProtocolText',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolText),
              required: true,
              value: ownersMeeting.data.meetingDurationProtocolText,
              disabled: ownersMeeting.data.meetingDurationProtocolTextIsStandardText,
            },
          },
        ],
      ],
    },
    {
      sectionId: 'meetingManagement',
      title: tl(translations.editPage.sections.protocolSetup.meetingManagement.title),
      content: [
        [
          {
            type: 'select',
            key: 'meetingLeader',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.meetingManagement.fields.leader),
              required: true,
              showSearch: true,
              options: managementCompanyEmployees,
              onChangeSideEffect: (val: number) => {
                const key = 'meetingLeaderProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  const value = {
                    meetingLeader: managementCompanyEmployees.filter((employee: OptionInterface) => employee.value === val)[0].label,
                    meetingLeaderRole: ownersMeeting.data && ownersMeeting.data.meetingLeaderRole ? roleOptions.filter((role: OptionInterface) => ownersMeeting.data && role.value === ownersMeeting.data.meetingLeaderRole)[0].label : roleOptions[0].label,
                    managementCompany: selectedDisplayProperty.data.managementCompany.companyName,
                  };
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                }
              },
            },
          },
          {
            type: 'select',
            key: 'meetingLeaderRole',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.meetingManagement.fields.role),
              options: roleOptions,
              onChangeSideEffect: (val: string) => {
                const key = 'meetingLeaderProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  let meetingLeader = '';
                  if (ownersMeeting.data && ownersMeeting.data.meetingLeader) {
                    meetingLeader = managementCompanyEmployees.filter((employee: OptionInterface) => ownersMeeting.data && employee.value === ownersMeeting.data.meetingLeader)[0].label;
                  } else if (managementCompanyEmployees[0]) {
                    meetingLeader = managementCompanyEmployees[0].label;
                  }
                  const value = {
                    meetingLeader,
                    meetingLeaderRole: roleOptions.filter((role: OptionInterface) => role.value === val)[0].label,
                    managementCompany: selectedDisplayProperty.data.managementCompany.companyName,
                  };

                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                }
              },
            },
          },
        ],
        [
          radioGroupForKey('meetingLeaderProtocolText'),
        ],
        [
          {
            type: 'textArea',
            key: 'meetingLeaderProtocolText',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolText),
              required: true,
              value: ownersMeeting.data.meetingLeaderProtocolText,
              disabled: ownersMeeting.data.meetingLeaderProtocolTextIsStandardText,
            },
          },
        ],
      ],
    },
    {
      sectionId: 'protocolLeader',
      title: tl(translations.editPage.sections.protocolSetup.protocolLeader.title),
      content: [
        [
          {
            type: 'select',
            key: 'minuteTaker',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolLeader.fields.protocolLeader),
              required: true,
              showSearch: true,
              options: managementCompanyEmployees,
              onChangeSideEffect: (val: number) => {
                const key = 'protocolLeaderProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  const value = {
                    minuteTaker: managementCompanyEmployees.filter((employee: OptionInterface) => employee.value === val)[0].label,
                    minuteTakerRole: ownersMeeting.data && ownersMeeting.data.minuteTakerRole ? roleOptions.filter((role: OptionInterface) => ownersMeeting.data && role.value === ownersMeeting.data.minuteTakerRole)[0].label : roleOptions[0].label,
                    managementCompany: selectedDisplayProperty.data.managementCompany.companyName,
                  };

                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                }
              },
            },
          },
          {
            type: 'select',
            key: 'minuteTakerRole',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolLeader.fields.role),
              options: roleOptions,
              onChangeSideEffect: (val: string) => {
                const key = 'protocolLeaderProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  let minuteTaker = '';
                  if (ownersMeeting.data && ownersMeeting.data.minuteTaker) {
                    minuteTaker = managementCompanyEmployees.filter((employee: OptionInterface) => ownersMeeting.data && employee.value === ownersMeeting.data.minuteTaker)[0].label;
                  } else if (managementCompanyEmployees[0]) {
                    minuteTaker = managementCompanyEmployees[0].label;
                  }
                  const value = {
                    minuteTaker,
                    minuteTakerRole: roleOptions.filter((role: OptionInterface) => role.value === val)[0].label,
                    managementCompany: selectedDisplayProperty.data.managementCompany.companyName,
                  };

                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                }
              },
            },
          },
        ],
        [
          radioGroupForKey('protocolLeaderProtocolText'),
        ],
        [
          {
            type: 'textArea',
            key: 'protocolLeaderProtocolText',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolText),
              required: true,
              value: ownersMeeting.data.protocolLeaderProtocolText,
              disabled: ownersMeeting.data.protocolLeaderProtocolTextIsStandardText,
            },
          },
        ],
      ],
    },

    {
      sectionId: 'meetingAgenda',
      title: tl(translations.editPage.sections.protocolSetup.agenda.title),
      content: [
        [
          radioGroupForKey('agendaPointProtocolText'),
        ],
        [
          {
            type: 'textArea',
            key: 'agendaPointProtocolText',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolText),
              required: true,
              value: ownersMeeting.data.agendaPointProtocolText,
              disabled: ownersMeeting.data.agendaPointProtocolTextIsStandardText,
            },
          },
        ],
      ],
    },
    {
      sectionId: 'votingRights',
      title: tl(translations.editPage.sections.protocolSetup.votingRights.title),
      content: [
        [
          {
            type: 'select',
            key: 'votingRights',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.votingRights.fields.byUnits),
              options: distributionOptions,
              value: ownersMeeting?.data?.votingRights || OwnersMeetingProtocolDtoVotingRightsEnum.MEA,
              onChangeSideEffect: (val: string) => {
                const keyWithHeadcount = 'votingRightProtocolTextHeadcount';
                const keyWithMea = 'votingRightProtocolTextWithMea';
                const key = 'votingRightProtocolText';
                if (val === OwnersMeetingProtocolDtoVotingRightsEnum.MEA && ownersMeeting.data[`${keyWithMea}IsStandardText`]) {
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(keyWithMea, ownersMeeting.data, ownersMeeting.data.votingRightProtocolTextWithMea), keyWithMea);
                } else if (val === OwnersMeetingProtocolDtoVotingRightsEnum.UNIT && ownersMeeting.data[`${key}IsStandardText`]) {
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, ownersMeeting.data.votingRightProtocolText), key);
                } else if (val === OwnersMeetingProtocolDtoVotingRightsEnum.HEAD && ownersMeeting.data[`${keyWithHeadcount}IsStandardText`]) {
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(keyWithHeadcount, ownersMeeting.data, ownersMeeting.data.votingRightProtocolTextHeadcount), keyWithHeadcount);
                }
              },
            },
          },
          {},
        ],
        [
          // eslint-disable-next-line no-nested-ternary
          radioGroupForKey(ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.MEA ? 'votingRightProtocolTextWithMea'
            : (ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.HEAD ? 'votingRightProtocolTextHeadcount' : 'votingRightProtocolText')),
        ],
        [
          {
            type: 'textArea',
            // eslint-disable-next-line no-nested-ternary
            key: ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.MEA ? 'votingRightProtocolTextWithMea'
              : (ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.HEAD ? 'votingRightProtocolTextHeadcount' : 'votingRightProtocolText'),
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolText),
              required: true,
              onChange: (value: string) => {
                // eslint-disable-next-line no-nested-ternary
                const key = ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.MEA ? 'votingRightProtocolTextWithMea'
                  : (ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.HEAD ? 'votingRightProtocolTextHeadcount' : 'votingRightProtocolText');
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                } else {
                  onChangeOwnerMeetingProtocolField(value, key);
                }
              },
              // eslint-disable-next-line no-nested-ternary
              value: ownersMeeting.data && ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.MEA
                ? ownersMeeting.data.votingRightProtocolTextWithMea
                : (ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.UNIT ? ownersMeeting.data.votingRightProtocolText
                  : ownersMeeting.data.votingRightProtocolTextHeadcount || ''),
              disabled: ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.MEA ? ownersMeeting.data.votingRightProtocolTextWithMeaIsStandardText
                : (ownersMeeting.data.votingRights === OwnersMeetingProtocolDtoVotingRightsEnum.HEAD ? ownersMeeting.data.votingRightProtocolTextHeadcountIsStandardText : ownersMeeting.data.votingRightProtocolTextIsStandardText),
            },
          },
        ],
      ],
    },
    {
      sectionId: 'quorum',
      title: tl(translations.editPage.sections.protocolSetup.quorum.title),
      content: [
        [
          radioGroupForKey('quorumProtocolText'),
        ],
        [
          {
            type: 'textArea',
            key: 'quorumProtocolText',
            props: {
              disabled: ownersMeeting.data.quorumProtocolTextIsStandardText,
              label: tl(translations.editPage.sections.protocolSetup.protocolText),
              required: true,
              value: ownersMeeting.data.quorumProtocolText,
              onChange: (value: string) => {
                const key = 'quorumProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                } else {
                  onChangeOwnerMeetingProtocolField(value, key);
                }
              },
            },
          },
        ],
      ],
    },
    {
      sectionId: 'votingScheme',
      title: tl(translations.editPage.sections.protocolSetup.votingScheme.title),
      content: [
        [
          {
            type: 'select',
            key: 'votingScheme',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.votingScheme.fields.votingScheme),
              required: true,
              options: votingSchemeOptions,
              value: ownersMeeting.data.votingScheme || OwnersMeetingProtocolDtoVotingSchemeEnum.OPEN_VOTING,
              onChangeSideEffect: (val: string) => {
                const key = 'votingRulesProtocolText';
                if (ownersMeeting.data[`${key}IsStandardText`]) {
                  const value = {
                    votingScheme: votingSchemeOptions.filter((role: OptionInterface) => role.value === val)[0].label,
                  };
                  onChangeOwnerMeetingProtocolField(getDefaultProtocolText(key, ownersMeeting.data, value), key);
                }
              },
            },
          },
          {},
        ],
        [
          radioGroupForKey('votingRulesProtocolText'),
        ],
        [
          {
            type: 'textArea',
            key: 'votingRulesProtocolText',
            props: {
              label: tl(translations.editPage.sections.protocolSetup.protocolText),
              required: true,
              value: ownersMeeting.data.votingRulesProtocolText,
              disabled: ownersMeeting.data.votingRulesProtocolTextIsStandardText,
            },
          },
        ],
      ],
    },
    ],
  };
}
