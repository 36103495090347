import Icon from '@ant-design/icons/lib/components/Icon';
import { Tooltip } from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import CheckboxInput from 'elements/Inputs/CheckboxInput/CheckboxInput';
import { useContext } from 'react';
import ActionBar from 'storybook-components/ActionBar/ActionBar/ActionBar';
import ActionBarSelectionCounter from 'storybook-components/ActionBar/ActionBarSelectionCounter/ActionBarSelectionCounter';
import { messagesListTranslations } from '../../translations';
import { useMessagesActionBar } from './useMessagesActionBar';
import './MessagesActionBar.scss';

const MessagesActionBar = () => {
  const { tl } = useContext(LanguageContext);
  const {
    nrSelectedMessagesCurrent,
    nrSelectedMessagesTotal,
    actionBarVisible,
    showWarningIcon,
    showAllSelected,
    onChangeShowAllSelected,
    onUnselectAll,
    tooltipVisible,
    onChangeTooltipVisible,
  } = useMessagesActionBar();

  return (
    <Tooltip
      title={tl(messagesListTranslations.actionBar.warningTooltip)}
      visible={tooltipVisible}
      overlayStyle={{ maxWidth: 600, padding: 0 }}
    >
      <ActionBar className="MessagesActionBar" visible={actionBarVisible}>
        <ActionBarSelectionCounter>
          <span>{`${nrSelectedMessagesCurrent}/${nrSelectedMessagesTotal}`}</span>
          {showWarningIcon && (
            <span
              className="warning-icon-wrapper"
              onMouseEnter={() => onChangeTooltipVisible(true)}
              onMouseLeave={() => onChangeTooltipVisible(false)}
            >
              <Icon component={ICONS.warning} />
            </span>
          )}
        </ActionBarSelectionCounter>

        <Button type="primary" onClick={onUnselectAll}>
          {tl(messagesListTranslations.actionBar.unselectAll)}
        </Button>

        <CheckboxInput
          onChange={onChangeShowAllSelected}
          value={showAllSelected}
          label={tl(messagesListTranslations.actionBar.showAllSelected)}
        />
      </ActionBar>
    </Tooltip>
  );
};

export default MessagesActionBar;
