import { PropertyDisplayDto, UnitContractProjectionDto } from 'api/accounting';
import { Overwrite } from 'lib/Utils';

interface UnitContractExtension {
    state?: ContractState,
    warnings?: ContractWarnings[],
    hasActiveRentPlan?: boolean,
    rentAmount?: number,
    activeRentPlanId?: number,
    economicPlanName?: string,
    economicPlanId?: number,
    sevProperties?: PropertyDisplayWithOwnerContract[],
    key?: string,
}

interface PropertyDisplayWithOwnerContract extends PropertyDisplayDto{
  ownerContract: ExtendedUnitContractProjectionDto,
}

export interface ExtendedUnitContractProjectionDto extends Overwrite<UnitContractProjectionDto, UnitContractExtension> {
}

export enum ContractState {
    PAST = 'PAST',
    ACTIVE = 'ACTIVE',
    FUTURE = 'FUTURE',
    OVERRIDEN = 'OVERRIDEN',
}

export enum ContractWarnings {
  OVERRIDDEN = 'OVERRIDDEN',
  OVERWRITING = 'OVERWRITING',
  GAP = 'GAP',
}
