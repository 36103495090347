import { Modal } from 'antd';
import { GetMessagesUsingGETSourceTypeEnum, SpecialContributionControllerApi } from 'api/accounting';
import {
  DocumentApi, DocumentDtoStateEnum, GetDocumentsByFilterUsingGETOrderEnum, GetDocumentsByFilterUsingGETSourceTypeEnum,
} from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { MAX_PAGE_SIZE } from 'lib/messageUtils';
import { showNotification } from 'lib/Notification';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { useCheckMessages } from 'services/useCheckMessages';
import { translations } from '../../translations';
import { SpecialContributionCreationContext } from './SpecialContributionCreationContext';

export const useDownloadSpecialContributionDocument = () => {
  const specialContributionContext = useContext(SpecialContributionCreationContext);
  if (specialContributionContext === undefined) {
    throw Error('useDownloadSpecialContributionDocument must be used withing SpecialContributionCreationContextProvider');
  }
  const {
    setLoading, setDocuments, documents, specialContribution, documentModalVisible, setDocumentModalVisible,
  } = specialContributionContext;

  const { tl } = useContext(LanguageContext);
  const { apiConfiguration, publicApiConfiguration } = useContext(AuthContext);
  const specialContributionControllerApi = new SpecialContributionControllerApi(apiConfiguration('accounting'));
  const documentControllerApi = new DocumentApi(publicApiConfiguration('public'));

  const { messagesExist, messagesGenerating } = useCheckMessages({ sourceId: specialContribution?.id, sourceType: GetMessagesUsingGETSourceTypeEnum.SPECIAL_CONTRIBUTION });

  const onRegenerateSpecialContribution = (specialContributionId: number) => {
    const onProceed = () => {
      setLoading(true);
      setDocuments([]);
      specialContributionControllerApi.generateSpecialContributionDocumentUsingPOST({ id: specialContributionId })
        .then(() => {
          showNotification({
            key: 'generationTriggered',
            message: tl(translations.specialContribution.regenerateDocumentSuccess.message),
            description: tl(translations.specialContribution.regenerateDocumentSuccess.description),
            type: 'info',
          });
          fetchDocuments(specialContributionId);
        }).catch((err) => {
          console.error(err);
          showNotification({
            key: 'generationTriggered',
            message: tl(translations.specialContribution.regenerateDocumentError),
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    };

    onProceed();
  };

  const fetchDocuments = (specialContributionId: number) => {
    if (specialContributionId) {
      documentControllerApi.getDocumentsByFilterUsingGET({
        sourceType: GetDocumentsByFilterUsingGETSourceTypeEnum.SPECIAL_CONTRIBUTION,
        sourceId: specialContributionId,
        sort: 'unitRank',
        order: GetDocumentsByFilterUsingGETOrderEnum.ASC,
        size: MAX_PAGE_SIZE,
      }).then((page) => {
        setDocuments(page.content);
      }).catch((err) => {
        console.log(`Could not check if special contribution has document. Details: ${err}`);
        showNotification({
          key: 'loadDocumentsError',
          message: tl(translations.specialContribution.loadDocumentsError),
          type: 'error',
        });
      });
    }
  };

  const pollDocumentGenerationStatus = (specialContributionId: number) => {
    if (!_.isEmpty(documents)) {
      fetchDocuments(specialContributionId);
    }
  };

  const documentsInGeneration = useMemo(() => documents.some(d => [DocumentDtoStateEnum.GENERATING, DocumentDtoStateEnum.DRAFT].includes(d.state)), [documents]);
  const hasFailedDocuments = useMemo(() => documents.some(d => [DocumentDtoStateEnum.FAILED].includes(d.state)), [documents])
  
  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  return {
    onRegenerateSpecialContribution,
    fetchDocuments,
    hasDocuments: !_.isEmpty(documents),
    documentsInGeneration,
    hasFailedDocuments,
    pollDocumentGenerationStatus,
    messagesExist,
    documents,
    documentModalVisible,
    setDocumentModalVisible,
    startLoading,
    stopLoading,
    messagesGenerating,
  };
};
