import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { PrimarySectionWrapper } from '../../../../storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { economicPlanHouseMoneyTranslations } from '../economicPlanHouseMoneyTranslations';
import { CorrectionsWrapper } from '../../components/CorrectionsWrapper/CorrectionsWrapper';
import { useSectionManager } from '../../../../services/SectionManager/useSectionManager';
import { useDirtModalNavigation } from '../services/useDirtModalNavigation';
import { useSaveUnitFiguresEconomicPlan } from '../services/useSaveUnitFiguresEconomicPlan';
import Button from '../../../../elements/Buttons/Button/Button';
import { translations } from '../../../../elements/Translation/translations';

export const CorrectionalBookingsSection = () => {
  const { tl } = useContext(LanguageContext);
  const { openSectionId } = useSectionManager();
  const { handleOpenSectionChange } = useDirtModalNavigation();

  const {
    saveAndOpenNextSection,
    canSave,
  } = useSaveUnitFiguresEconomicPlan();

  return (
    <PrimarySectionWrapper
      sectionId="CorrectionalBookings"
      sectionTitle={tl(economicPlanHouseMoneyTranslations.corrections)}
      sectionNumber={3}
      isOpen={openSectionId === 2}
      onClick={() => handleOpenSectionChange(2)}
      footer={(
        <Button type="ghost" onClick={saveAndOpenNextSection} disabled={!canSave}>
          {tl(translations.elements.section.save)}
        </Button>
      )}
    >
      <CorrectionsWrapper />
    </PrimarySectionWrapper>
  );
};
