export const translations: any = {
  votingResult: {
    placeholder: {
      en: 'Please select...',
      de: 'Bitte auswählen...',
    },
    title: {
      en: 'Voting result',
      de: 'Abstimmungsergebnis',
    },
    radioGroup: {
      label: {
        en: 'Text',
        de: 'Text',
      },
      options: {
        standard: {
          en: 'Standard',
          de: 'Standardmäßig',
        },
        alternative: {
          en: 'Alternative',
          de: 'Alternative',
        },
      },
    },
    voteDetails: {
      en: 'Resolution text',
      de: 'Beschlusstext',
    },
    countingMethod: {
      en: 'Counting method',
      de: 'Stimmrecht',
    },
    countingMethods: {
      mea: {
        en: 'MEA',
        de: 'MEA',
      },
      peopleHeadCount: {
        en: 'People head count',
        de: 'Kopfprinzip',
      },
      numberOfUnits: {
        en: 'Number of units',
        de: 'Einheitsprinzip',
      },
    },
    majorityRequirement: {
      en: 'Majority requirement',
      de: 'Mehrheitserfordernis',
    },
    majorityRequirementOptions: {
      absolute_majority: {
        en: 'Simple majority',
        de: 'Einfache Mehrheit',
      },
      unanimous_majority: {
        en: 'Unanimity of all owners',
        de: 'Allstimmigkeit',
      },
      attending_unanimous_majority: {
        en: 'Unanimity of attending owners',
        de: 'Einstimmigkeit',
      },
      qualified_majority: {
        en: 'Qualified majority',
        de: 'Qualifizierte Mehrheit',
      },
    },
    statusOptions: {
      accepted: {
        en: 'Accepted',
        de: 'Angenommen',
      },
      declined: {
        en: 'Declined',
        de: 'Abgelehnt',
      },
      draft: {
        en: 'Draft - will not be listed in the protocol',
        de: 'Entwurf - wird nicht Teil des Protokolls',
      },
      notDecided: {
        en: 'Not decided',
        de: 'Nicht abgestimmt',
      },
    },
    present: {
      en: 'Present',
      de: 'Anwesend',
    },
    yes: {
      en: 'Yes',
      de: 'Ja',
    },
    no: {
      en: 'No',
      de: 'Nein',
    },
    abstention: {
      en: 'Abstention',
      de: 'Enthaltungen',
    },
    resolutionStatus: {
      en: 'Resolution status',
      de: 'Beschlussstatus',
    },
    notifications: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Failed loading agenda item voting result.',
          de: 'Fehler beim Laden des Tagesordnungspunkt-Abstimmungsergebnisses.',
        },
      },
    },
  },
};
