import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { PropertyListContext } from '../../../../contexts/PropertyListContext';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';
import { EconomicPlanData } from '../../components/EconomicPlanData/EconomicPlanData';
import { useSaveEconomicPlan } from '../../services/useSaveEconomicPlan';
import { economicPlanAccountEditingTranslations } from '../economicPlanAccountEditingTranslations';
import defaultSection from './defaultSection';

interface PropertyDataSection1Props {
  economicPlan: any,
  economicPlanCreationData: any
}

export default function PropertyDataSection1({
  economicPlan,
  economicPlanCreationData,
}: PropertyDataSection1Props) {
  const history = useHistory();
  const { tl } = useContext(LanguageContext);
  const {
    setSelectedDisplayPropertyId,
    selectedDisplayProperty,
    setSelectedPropertyHrId,
  }: any = useContext(PropertyListContext);
  const { propertyHrId }: { propertyHrId: string } = useParams();
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const { saveEconomicPlan, canSave } = useSaveEconomicPlan();

  const {
    dirty: isDirty,
  } = useSimpleDirtModal();

  const goBack = () => {
    history.push(`/economic-plan/property/${propertyHrId}`);
  };

  useEffect(() => {
    if (selectedDisplayProperty.data?.propertyHrId) {
      checkPropertyValidity({ propertyHrId: selectedDisplayProperty.data.propertyHrId, onCancel: goBack });
    }
  }, [selectedDisplayProperty.data]);

  useEffect(() => {
    setSelectedPropertyHrId(propertyHrId);
    if (!propertyHrId) {
      setSelectedDisplayPropertyId(0);
    }
  }, [propertyHrId]);

  return {
    ...defaultSection({
      index: 0,
      openingIndex: 0,
      economicPlanCreationData,
      economicPlan,
    }),
    sectionTitle: tl(economicPlanAccountEditingTranslations.sections.propertySection.title),
    sectionId: 'EconomicPlanData',
    buttonDisabled: !(isDirty && canSave),
    onSubmit: saveEconomicPlan,
    content: [
      {
        content: <EconomicPlanData />,
      },
    ],
  };
}
