import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ContractProjectionDtoDunningLevelEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import { getPropertyAccountBalancePath } from 'pages/Account/routes';
import { getOpenBalanceAmountColor } from 'lib/utilGetOpenBalanceAmountColor';
import { DIRECT_DEBIT_LIST_URL } from 'pages/DirectDebitList/routes';
import { useOpenTransactionNumberContext, useOrderOpenBalancesContext } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/OrderOpenBalancesContext';
import { DebtorBalancesGroupedType, OpenBankOrderAndFutureBalance } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/interfaces';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { PAYMENT_LIST_URL } from 'pages/PaymentList/routes';
import { useContext } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';

interface OpenAmountDataSource {
  category: 'directDebits' | 'payments' | 'todaysBalance' | 'overallBalance';
  categoryLabel: string;
  color: 'green' | 'red';
  amount: number;
  propertyHrId?: string;
  accountCode?: string;
}


const getLink = (
  record: OpenAmountDataSource,
  openBankOrdersForContract: OpenBankOrderAndFutureBalance,
  propertyHrId: string,
  accountCode: string,
) => {
  if (record.category === 'overallBalance') {
    return getPropertyAccountBalancePath(propertyHrId, accountCode);
  }

  if (record.category === 'directDebits') {
    const managementCompanyIdFilter = `managementCompanyId=${openBankOrdersForContract.managementCompanyId}`;
    const stateFilter = `states=${encodeURIComponent(JSON.stringify(['NEW']))}`;
    const ibanFilter = `counterpartIban=${openBankOrdersForContract.contractIban}`;

    return `${DIRECT_DEBIT_LIST_URL}?${[managementCompanyIdFilter, stateFilter, ibanFilter].join('&')}`;
  }

  // else category === 'payments'
  const managementCompanyIdFilter = `managementCompanyId=${openBankOrdersForContract.managementCompanyId}`;
  const stateFilter = `paymentStates=${encodeURIComponent(JSON.stringify(['NEW']))}`;
  const ibanFilter = `counterpartIban=${openBankOrdersForContract.contractIban}`;

  return `${PAYMENT_LIST_URL}?${[managementCompanyIdFilter, stateFilter, ibanFilter].join('&')}`;
};


export const useOpenAmountCellWithBankOrderWarning = ({
  contractId,
  accountCode,
  propertyId,
  propertyHrId,
  contractDunningLevel,
}: DebtorBalancesGroupedType) => {
  const { tl } = useContext(LanguageContext);
  const { openBankOrdersAndFutureBalance } = useOrderOpenBalancesContext('useOpenAmountCell');
  const { openTransactionNumbers } = useOpenTransactionNumberContext('useOpenAmountCellWithBankOrderWarning');

  const hasOpenTransactions = openTransactionNumbers[propertyId] && openTransactionNumbers[propertyId] > 0;
  const hasOpenBankOrdersOrFuturePostings = openBankOrdersAndFutureBalance.data.has(contractId);
  const openBankOrdersAndFutureBalanceForContract = openBankOrdersAndFutureBalance.data.get(contractId);
  const todaysAndOverallBalanceDifference = hasOpenBankOrdersOrFuturePostings
    ? openBankOrdersAndFutureBalanceForContract.overallAccountBalance - openBankOrdersAndFutureBalanceForContract.todaysAccountBalance
    : 0;
  const shouldShowWarning = hasOpenBankOrdersOrFuturePostings || todaysAndOverallBalanceDifference !== 0;

  const columns = [
    {
      title: tl(orderOpenBalancesTranslations.openOrdersModal.table.category),
      dataIndex: 'categoryLabel',
      render: (val, record) => ((record.category === 'todaysBalance')
        ? val
        : <a href={getLink(record, openBankOrdersAndFutureBalanceForContract, propertyHrId, accountCode)} target="_blank" rel="noreferrer">{val}</a>
      ),
    },
    {
      title: tl(orderOpenBalancesTranslations.openOrdersModal.table.amount),
      dataIndex: 'amount',
      className: 'column-align-right',
      render: (val, record) => (
        <Amount color={record.color}>
          {formatCurrency(val)}
        </Amount>
      ),
    },
  ] as ColumnsType<OpenAmountDataSource>;

  const getDataSource = () => {
    const dataSource: OpenAmountDataSource[] = [{
      category: 'todaysBalance',
      categoryLabel: tl(orderOpenBalancesTranslations.openOrdersModal.table.todaysBalance),
      color: getOpenBalanceAmountColor(openBankOrdersAndFutureBalanceForContract?.todaysAccountBalance),
      amount: openBankOrdersAndFutureBalanceForContract?.todaysAccountBalance,
    }];

    if (todaysAndOverallBalanceDifference !== 0) {
      dataSource.push({
        category: 'overallBalance',
        categoryLabel: tl(orderOpenBalancesTranslations.openOrdersModal.table.overallBalance),
        color: getOpenBalanceAmountColor(todaysAndOverallBalanceDifference),
        amount: todaysAndOverallBalanceDifference,
      });
    }

    if (openBankOrdersAndFutureBalanceForContract?.sumDirectDebits > 0) {
      dataSource.push({
        category: 'directDebits',
        categoryLabel: tl(orderOpenBalancesTranslations.openOrdersModal.table.openDirectDebits),
        color: 'green',
        amount: openBankOrdersAndFutureBalanceForContract?.sumDirectDebits,
      });
    }

    if (openBankOrdersAndFutureBalanceForContract?.sumPayments > 0) {
      dataSource.push({
        category: 'payments',
        categoryLabel: tl(orderOpenBalancesTranslations.openOrdersModal.table.openPayments),
        color: 'red',
        amount: openBankOrdersAndFutureBalanceForContract?.sumPayments * -1,
      });
    }

    return dataSource;
  };


  const summaryValue = openBankOrdersAndFutureBalanceForContract?.todaysAccountBalance
    - openBankOrdersAndFutureBalanceForContract?.sumDirectDebits
    + openBankOrdersAndFutureBalanceForContract?.sumPayments
    + todaysAndOverallBalanceDifference;

  const onOpenModal = (title: string, content: React.ReactNode) => {
    Modal.info({
      title,
      content,
      closable: true,
      maskClosable: true,
      width: 512,
      icon: null,
      okButtonProps: { style: { display: 'none' } },
    });
  };

  const disabled = contractDunningLevel === ContractProjectionDtoDunningLevelEnum.EXCLUDED;

  return {
    onOpenModal,
    hasOpenTransactions,
    columns,
    dataSource: getDataSource(),
    summaryValue,
    shouldShowWarning,
    disabled,
  };
};
