export enum Color {
  White = 'White',
  WhiteMilk = 'WhiteMilk',
  WhiteCream = 'WhiteCream',
  Gray = 'Gray',
  LightGray = 'LightGray',
  DarkGray = 'DarkGray',
  Black = 'Black',
  Dust = 'Dust',
  Yellow = 'Yellow',
  LightYellow = 'LightYellow',
  Green = 'Green',
  LightGreen = 'LightGreen',
  Red = 'Red',
  LightRed = 'LightRed',
  Blue = 'Blue',
  Purple = 'Purple',
  Warning = 'Warning'
}