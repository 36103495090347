import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import SmartTable from 'elements/SmartTable/SmartTable';
import { navigateConcat } from 'navigation/History';
import UsersContextProvider from 'pages/Users/UserList/services/UsersListContext';
import { useUsersTable } from 'pages/Users/UserList/services/useUsersTable';
import { userListTranslations } from 'pages/Users/UserList/userListTranslations';
import React, { useContext } from 'react';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import { useUsersListFilters } from 'pages/Users/UserList/services/useUsersListFilters';
import './UsersList.scss';

const UsersList = () => {
  const {
    availableFilters, onChangeFilter, filterState, onSetDefaultFilterFromQueryParams,
  } = useUsersListFilters();
  useGlobalSearch({
    key: 'users',
    filterProps: {
      availableFilters,
      setFilter: onChangeFilter,
    },
    queryParamAsFilter: {
      onSetDefaultFilterFromQueryParams,
      filterState,
    },
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'users',
    navItems: subcategorySwitcherItems.settings,
  };
  const { tl } = useContext(LanguageContext);
  const { usersTable, onInviteUsers } = useUsersTable();

  return (
    <div className="UsersList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={(
          <Button
            type="primary"
            onClick={onInviteUsers}
          >
            {tl(userListTranslations.add)}
          </Button>
        )}

      />
      <SmartTable {...usersTable} />
    </div>
  );
};

export default () => (
  <UsersContextProvider>
    <UsersList />
  </UsersContextProvider>
);
