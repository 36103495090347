import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { PrimarySectionWrapper } from '../../../../storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { economicPlanHouseMoneyTranslations } from '../economicPlanHouseMoneyTranslations';
import { EconomicPlanData } from '../../components/EconomicPlanData/EconomicPlanData';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { useSectionManager } from '../../../../services/SectionManager/useSectionManager';
import { useDirtModalNavigation } from '../services/useDirtModalNavigation';
import Button from '../../../../elements/Buttons/Button/Button';
import { useSaveUnitFiguresEconomicPlan } from '../services/useSaveUnitFiguresEconomicPlan';
import { translations } from '../../../../elements/Translation/translations';

export const EconomicPlanDataSection = () => {
  const { tl } = useContext(LanguageContext);
  const { openSectionId } = useSectionManager();
  const { handleOpenSectionChange } = useDirtModalNavigation();

  const {
    selectedDisplayProperty,
    selectedPropertyHrId,
  } = useContext(PropertyListContext);
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const history = useHistory();

  const {
    saveAndOpenNextSection,
    canSave,
  } = useSaveUnitFiguresEconomicPlan();

  const goBack = () => {
    history.push(`/economic-plan/property/${selectedPropertyHrId}`);
  };

  useEffect(() => {
    if (selectedDisplayProperty.data?.propertyHrId) {
      checkPropertyValidity({ propertyHrId: selectedDisplayProperty.data.propertyHrId, onCancel: goBack });
    }
  }, [selectedDisplayProperty.data]);

  return (
    <PrimarySectionWrapper
      sectionId="EconomicPlanData"
      sectionTitle={tl(economicPlanHouseMoneyTranslations.general)}
      sectionNumber={1}
      isOpen={openSectionId === 0}
      onClick={() => handleOpenSectionChange(0)}
      footer={(
        <Button type="ghost" onClick={saveAndOpenNextSection} disabled={!canSave}>
          {tl(translations.elements.section.save)}
        </Button>
      )}
    >
      <EconomicPlanData />
    </PrimarySectionWrapper>
  );
};
