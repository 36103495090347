import React, { useState } from 'react';
import { PdfPageWrapperProps } from '../services/interfaces';
import PdfPagePreview from './PdfPage';


const PdfPageWrapper = (props: PdfPageWrapperProps) => {
  const [viewport, setViewPort]: any = useState(props.page.getViewport(1));

  return (
    <PdfPagePreview {...props} viewport={viewport} setViewPort={setViewPort} />
  );
};

export default PdfPageWrapper;
