import React from 'react';
import DeleteButton from 'elements/FormElements/FormSection/DeleteButton';
import { useDeleteBankAccountButton } from './useDeleteBankAccountButton';


interface Props {
  removeFromList: (idx: number) => void;
  index: number;
  contactId?: number;
  bankAccountId?: number;
}


const DeleteBankAccountButton = ({
  removeFromList, index, contactId, bankAccountId,
}: Props) => {
  const { onClick } = useDeleteBankAccountButton(removeFromList, index, contactId, bankAccountId);


  return (
    <DeleteButton onClick={onClick} />
  );
};

export default DeleteBankAccountButton;

DeleteBankAccountButton.defaultProps = {
  contactId: undefined,
  bankAccountId: undefined,
};
