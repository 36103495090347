import { RouteDefinition } from 'components/RenderRoutes';
import MessageSendingPageWithProvider from 'pages/MessageSendingPage/MessageSendingPage';
import { lazy } from 'react';
import { DOCUMENT_PREVIEW_PATH } from 'pages/DocumentPreview/routes';
import ProfitAndLossReportListWithProvider from './ProfitAndLossReportList/ProfitAndLossReportList';
import ProfitAndLossReportViewWithProvider from './ProfitAndLossReportView/ProfitAndLossReportView';
import ProfitAndLossReportEditor from './ProfitAndLossReportEditing/ProfitAndLossReportEditor';
import { ProfitAndLossReportInstantCalculatePage } from './ProfitAndLossReportEditing/ProfitAndLossReportInstantCalculatePage';

export const PROFIT_AND_LOSS_REPORTS_PATH = '/profit-and-loss';
export const PROFIT_AND_LOSS_REPORT_CREATE_PATH = `${PROFIT_AND_LOSS_REPORTS_PATH}/create`;
const PROFIT_AND_LOSS_REPORT_VIEW_PATH = `${PROFIT_AND_LOSS_REPORTS_PATH}/:documentDbId/view`;

const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));

export const getProfitAndLossReportViewRoute = ({ documentDbId }: { documentDbId: string | number }) => (
  PROFIT_AND_LOSS_REPORT_VIEW_PATH.replace(':documentDbId', `${documentDbId}`)
);

export const ProfitAndLossRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/reports/profit-and-loss',
      component: ProfitAndLossReportInstantCalculatePage,
    },
    {
      path: PROFIT_AND_LOSS_REPORTS_PATH,
      component: ProfitAndLossReportListWithProvider,
      strict: true,
      subRoutes: [
        {
          path: PROFIT_AND_LOSS_REPORT_VIEW_PATH,
          component: ProfitAndLossReportViewWithProvider,
          type: 'overlay',
          addBreadcrumb: false,
          subRoutes: [
            {
              path: `${PROFIT_AND_LOSS_REPORT_VIEW_PATH}/message-sending`,
              component: MessageSendingPageWithProvider,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${PROFIT_AND_LOSS_REPORT_VIEW_PATH}/message-sending${DOCUMENT_PREVIEW_PATH}`,
                  component: DocumentPreviewPage,
                  type: 'overlay',
                },
              ],
            },
          ],
        },
        {
          path: `${PROFIT_AND_LOSS_REPORTS_PATH}/:documentDbId/edit`,
          component: ProfitAndLossReportEditor,
          type: 'focus',
        },
        {
          path: `${PROFIT_AND_LOSS_REPORTS_PATH}/create`,
          component: ProfitAndLossReportEditor,
          type: 'focus',
        },
      ],
    },
  ],
};
