import { INTEGRATION_APPS } from './interfaces';

export const translations = {
  searchPlaceholder: {
    en: 'Search',
    de: 'Suche',
  },
  sectionTitle: {
    [INTEGRATION_APPS.CASAVI]: {
      en: 'casavi: Issues',
      de: 'casavi: Vorgänge',
    },
    [INTEGRATION_APPS.FACILIOO]: {
      en: 'facilioo: Issues',
      de: 'facilioo: Vorgänge',
    },
    [INTEGRATION_APPS.ETG24]: {
      en: 'Issues',
      de: 'Vorgänge',
    },
  },
  errors: {
    propertyNotFound: {
      en: 'Property could not be found.',
      de: 'Objekt konnte nicht gefunden werden.',
    },
    contactNotFound: {
      en: 'Contact could not be found.',
      de: 'Kontakt konnte nicht gefunden werden.',
    },
    communication: {
      en: (portal: string) => `Error communicating with ${portal}.`,
      de: (portal: string) => `Kommunikationsfehler mit ${portal}.`,
    },
    credentials: {
      en: 'Error getting credentials.',
      de: 'Fehler beim Anmelden.',
    },
    assign: {
      en: 'Error assigning invoice.',
      de: 'Fehler bei der Zuweisung.',
    },
    unassign: {
      en: 'Error unassigning invoice.',
      de: 'Fehler bei der Entfernung.',
    },
    uploadFail: {
      en: (portal: string) => `Could not upload document to ${portal}.`,
      de: (portal: string) => `Dokument konnte zum ${portal} nicht hochgeladen werden.`,
    },
  },
  casavi: {
    ticket: {
      state: {
        en: 'State',
        de: 'Status',
      },
      name: {
        en: 'name',
        de: 'name',
      },
      contactName: {
        en: 'Contact',
        de: 'Kontakt',
      },
      description: {
        en: 'Details',
        de: 'Details',
      },
      stateNames: {
        CLOSED: {
          en: 'Closed',
          de: 'Geschlossen',
        },
        PENDING: {
          en: 'Pending',
          de: 'Wartet',
        },
        OPEN: {
          en: 'Open',
          de: 'Eingegangen',
        },
        INPROGRESS: {
          en: 'In Progress',
          de: 'In bearbeitung',
        },
        POSTPONED: {
          en: 'Postponed',
          de: 'Verschoben',
        },
      },
    },
  },
  facilioo: {
    ticket: {
      name: {
        en: 'name',
        de: 'name',
      },
      description: {
        en: 'Details',
        de: 'Details',
      },
    },
  },
  etg24: {
    ticket: {
      name: {
        en: 'name',
        de: 'name',
      },
      description: {
        en: 'Details',
        de: 'Details',
      },
    },
  },
};
