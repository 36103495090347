import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import { getMessagePath } from 'pages/Messages/routes';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { translations } from './translations';

export interface SerialLetterHeaderButtonsProps {
  isSendOutValid: boolean;
  onSave: (openNextSectionOnSuccess: boolean) => void;
  onSend: () => void;
  letterId?: number,
  loading?: boolean;
  letterState?: string;
}

export default function SerialLetterHeaderButtons({
  isSendOutValid, onSave, onSend, loading, letterState, letterId,
}): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const { goBack } = useContext(OverlayContext);

  const isLetterSent = letterState === 'SENT';

  const onViewMessages = () => {
    const path = getMessagePath(letterId, 'SERIAL_LETTER');
    history.push(path);
  };

  return (
    <>
      <Button type="text" onClick={goBack}>{tl(translations.close)}</Button>
      <Button type="ghost" onClick={() => onSave(false)} disabled={isLetterSent}>{tl(translations.save)}</Button>
      {
        isLetterSent
          ? (
            <Button
              type="primary"
              loading={loading}
              onClick={onViewMessages}
            >
              {tl(translations.viewMessages)}
            </Button>
          )
          : (
            <Button
              type="primary"
              loading={loading}
              disabled={isLetterSent || !isSendOutValid}
              onClick={onSend}
            >
              {tl(translations.sendLetter)}
            </Button>
          )
      }
    </>
  );
}
