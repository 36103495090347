import Icon from '@ant-design/icons/lib/components/Icon';
import { Table } from 'antd';
import { ICONS } from 'components/icons';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { RecipientDatasource } from 'pages/MessageSendingPage/interfaces';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { useRecipientFilesCell } from './useRecipientFilesCell';
import './RecipientFilesCell.scss';

const RecipientFilesCell = ({ record } : {record: RecipientDatasource}) => {
  const { tl } = useContext(LanguageContext);
  const {
    subtitle, columns, datasource, loading, visible, onClose, onDone, onOpen,
  } = useRecipientFilesCell(record);

  if (record.children) return <span />;

  return (
    <>
      <Button
        className="RecipientFilesCell"
        type="ghost"
        icon={<Icon component={ICONS.document} />}
        onClick={onOpen}
      >
        {record.nrOfDocuments}
      </Button>
      <ModalWithSubtitle
        title={tl(messageSendingTranslations.recipientFiles.title)}
        subtitle={subtitle}
        onCancel={onClose}
        className="RecipientFilesModal"
        visible={visible}
        width={600}
        footer={[
          <Button key="close" type="default" onClick={onClose}>
            {tl(messageSendingTranslations.recipientFiles.close)}
          </Button>,
          <Button key="done" type="primary" onClick={onDone}>
            {tl(messageSendingTranslations.recipientFiles.done)}
          </Button>,
        ]}
      >
        <h4>{tl(messageSendingTranslations.recipientFiles.selectedFiles)}</h4>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={datasource}
          pagination={false}
          scroll={{ y: 500 }}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
        />
      </ModalWithSubtitle>
    </>
  );
};

export default RecipientFilesCell;
