import React, { useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import {
  PropertyLegacyDto,
  PropertyLegacyDtoPropertyStateEnum,
  PropertyLegacyDtoVatRelevanceEnum,
} from '../../../../../api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from '../../../../../lib/data';

// this is a hack to get TS typecheck/autocomplete for the properties definde by PropertyLegacyDto
// but to also accomodate for the extra fields added onto the property object in e.g. convertToFeModel
export interface ExtendedPropertyDto extends Omit<PropertyLegacyDto, 'economicYearStart'> {
  [key: string]: any,
  economicYearStart: moment.Moment,
}

interface PropertyEditingContextValue extends DefaultDataInterface<{ property: ExtendedPropertyDto }> {
  isValid: boolean,
  isDirty: boolean,
  setDirty: React.Dispatch<React.SetStateAction<boolean>>,
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>,
  setPropertyState: React.Dispatch<React.SetStateAction<DefaultDataInterface<any>>>,
}


export const DEFAULT_PROPERTY_EDITING_CONTEXT_PROPERTY_DATA = {
  property: {
    propertyState: PropertyLegacyDtoPropertyStateEnum.DRAFT,
    buildings: [{}],
    numberBuildings: 1,
    economicYearStart: moment().utc().startOf('year'),
    reserveAccountDrafts: [
      {
        name: 'Rücklage',
      },
    ],
    country: 'DE',
    vatRelevance: PropertyLegacyDtoVatRelevanceEnum.NOT_RELEVANT,
    casaviActive: true,
  } as ExtendedPropertyDto,
};


export const PropertyEditingContext = React.createContext<PropertyEditingContextValue | undefined>(undefined);

export default function PropertyEditingProvider({ children }: any) {
  const [propertyState, setPropertyState] = useState(DEFAULT_DATA<any>(DEFAULT_PROPERTY_EDITING_CONTEXT_PROPERTY_DATA));
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setDirty] = useState(false);


  return (
    <PropertyEditingContext.Provider value={{
      ...propertyState,
      isValid,
      isDirty,
      setPropertyState,
      setDirty,
      setIsValid,
    }}
    >
      {children}
    </PropertyEditingContext.Provider>
  );
}


PropertyEditingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
