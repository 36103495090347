import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import defaultSection from 'pages/Property/PropertyEditing/sections/defaultSection';
import AgendaItemsTable from './components/AgendaItemsTable';
import AddAgendaItemButton from './components/AddAgendaItemButton/AddAgendaItemButton';
import { translations as agendaItemTranslations } from './translations';

interface AgendaTopicsSectionProps {
  index: number,
  openNextSection:()=>void,
  showAddButton: boolean,
  disabled: boolean,
}

export const useAgendaItemsSection = ({
  index, openNextSection, showAddButton, disabled,
}: AgendaTopicsSectionProps) => {
  const { tl } = useContext(LanguageContext);

  return ({
    ...defaultSection(index, index),
    sectionTitle: tl(agendaItemTranslations.title),
    sectionId: 'AgendaTopics',
    disabled,
    onSubmit: () => {
      openNextSection();
    },
    content: [
      {
        sectionId: 'AgendaTopicsBody',
        content: (
          <AgendaItemsTable />
        ),
      },
    ],
    additionalButton: (
      showAddButton && <AddAgendaItemButton />
    ),
  });
};
