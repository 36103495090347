import { usePdfJsLib } from 'lib/usePdfJsLib';
import { useEffect, useRef } from 'react';
import { PdfPageProps } from '../services/interfaces';

declare let pdfjsLib: any;

const A4_PAGE_HEIGHT_WIDTH_RATIO = 1.41;

const PdfPage = (props: PdfPageProps) => {
  const {
    page,
    pdfPreviewWidth,
    parentWidth,
    id, selectableText, renderTextLayer,
    viewport: viewportProp,
    setViewPort,
    onClick,
    className: classNameProp,
  } = props;
  const canvasRef: any = useRef(null);
  const textLayerRef: any = useRef(null);

  const renderTaskRef = useRef(null);

  const { pdfJsLibLoaded } = usePdfJsLib();

  const desiredWidth = pdfPreviewWidth;
  const desiredHeight = pdfPreviewWidth * A4_PAGE_HEIGHT_WIDTH_RATIO;


  useEffect(() => {
    if (!pdfJsLibLoaded) return;

    if (canvasRef.current) {
      // Calculate the viewport and scale values
      const viewport = page.getViewport({ scale: 1 });
      const scale = Math.min(desiredWidth / viewport.width, desiredHeight / viewport.height);
      const scaledViewport = page.getViewport({ scale });

      // Get the canvas element and set its dimensions
      const canvas = canvasRef.current;
      canvas.width = desiredWidth;
      canvas.height = desiredHeight;

      // Render the page onto the canvas at the higher resolution
      const ctx = canvas.getContext('2d');
      const renderContext = {
        canvasContext: ctx,
        viewport: scaledViewport,
      };

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      renderTaskRef.current = page.render(renderContext);

      if (renderTextLayer) {
        renderTaskRef.current.promise.then(() =>
          // Returns a promise, on resolving it will return text contents of the page
          page.getTextContent()).then((textContent: string) => {
          // Assign CSS to the textLayer element
          // var textLayer = document.querySelector(".textLayer");
          const textLayer = textLayerRef.current;

          // clear previous text layer (in case of zoom)
          textLayer.innerHTML = '';

          textLayer.style.left = `${canvas.offsetLeft}px`;
          textLayer.style.top = `${canvas.offsetTop}px`;
          textLayer.style.height = `${canvas.offsetHeight}px`;
          textLayer.style.width = `${canvas.offsetWidth}px`;

          // Pass the data to the method for rendering of text over the pdf canvas.
          pdfjsLib.renderTextLayer({
            textContent,
            enhanceTextSelection: true,
            container: textLayer,
            viewport: scaledViewport,
            textDivs: [],
          });
        }).catch((e: any) => {
          console.error('Could not render text of pdf page', e);
        });
      }
    }
  }, [page, pdfPreviewWidth, parentWidth, pdfJsLibLoaded, window.devicePixelRatio]);


  const className = `canvas-wrapper ${classNameProp}`;

  return (
    /**
     * the height and width change with the zoom level, so we can't set it from css
     * and we need to set it on this div as well to make sure the placeholder pages
     * are the same height as the real pages
     */
    <div className={className} style={{ height: desiredHeight, width: desiredWidth }}>
      {selectableText && (<div className="pdf-text-layer" ref={textLayerRef} />)}
      <canvas
        id={id}
        onClick={onClick}
        ref={canvasRef}
        className="pdf-canvas"
      />
    </div>
  );
};

export default PdfPage;
