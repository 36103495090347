import { Modal } from 'antd';
import { showNotification } from 'lib/Notification';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { AuthContext } from 'contexts/AuthContext';
import { Configuration, UserControllerApi } from 'api/user';
import { deleteUserModalTranslations } from 'pages/Users/services/deleteUserModalTranslations';

export const useShowDeleteUserModal = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const usersApi = new UserControllerApi(apiConfiguration('user') as unknown as Configuration);

  const showDeleteUserModal = (userId, onSuccess) => {
    Modal.confirm({
      title: tl(deleteUserModalTranslations.deletionModal.title),
      content: tl(deleteUserModalTranslations.deletionModal.description),
      okText: tl(deleteUserModalTranslations.deletionModal.delete),
      cancelText: tl(deleteUserModalTranslations.deletionModal.cancel),
      okButtonProps: { className: 'Button' },
      cancelButtonProps: { className: 'Button' },
      onOk: () => (deleteUser(userId, onSuccess)),
      width: 750,
    });
  };

  const deleteUser = (userId, onSuccess) => (
    usersApi.deleteUserUsingDELETE({
      id: userId,
    })
      .then(() => {
        showNotification({
          key: 'deleteUsersSuccess',
          message: tl(deleteUserModalTranslations.deleteUserSuccess.message),
          type: 'success',
        });
        onSuccess();
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          key: 'deleteUsersError',
          message: tl(deleteUserModalTranslations.deleteUserError.message),
          type: 'error',
        });
      })
  );

  return {
    showDeleteUserModal,
  };
};
