import { LanguageContext } from 'contexts/LanguageContext';
import './BankAccountSubsection.scss';
import TextInput from 'elements/Inputs/TextInput/TextInput';
import { translations } from 'elements/Translation/translations';
import React, { useContext } from 'react';
import DeleteBankAccountButton from 'pages/Contacts/components/DeleteBankAccountButton/DeleteBankAccountButton';
import { useBankAccountSubsection } from './useBankAccountSubsection';
import { AddBankAccountButton } from '../AddBankAccountButton/AddBankAccountButton';

export const BankAccountSubsection = ({ indexOfBankAccount, addButton }: {indexOfBankAccount: number, addButton: boolean}) => {
  const { tl } = useContext(LanguageContext);
  const {
    bankAccount, bicPlaceHolder, onChange, onBlurIban, validationErrors, contactId, removeBankAccountFromList,
  } = useBankAccountSubsection(indexOfBankAccount);

  return (
    <div className="BankAccountSubsection">
      <div className="row">
        <div className="column">
          <TextInput
            value={bankAccount?.accountHolderName}
            onChange={val => onChange('accountHolderName', val)}
            label={tl(translations.pages.contactEditing.contactSection.bankSection.fields.owner)}
            required
            validationState={validationErrors.accountHolderName?.state}
            validationMessage={validationErrors.accountHolderName?.errorMessage}
          />
        </div>
        <div className="column">
          <TextInput
            value={bankAccount?.iban}
            onChange={val => onChange('iban', val)}
            label={tl(translations.pages.contactEditing.contactSection.bankSection.fields.iban)}
            onBlur={onBlurIban}
            validationState={validationErrors.iban?.state}
            validationMessage={validationErrors.iban?.errorMessage}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <TextInput
            value={bankAccount?.bic}
            onChange={val => onChange('bic', val)}
            label={tl(translations.pages.contactEditing.contactSection.bankSection.fields.bic)}
            placeholder={bicPlaceHolder}
            validationState={validationErrors.bic?.state}
            validationMessage={validationErrors.bic?.errorMessage}
            required
          />
        </div>
        <div className="column">
          <TextInput
            value={bankAccount?.accountInstitute}
            onChange={val => onChange('accountInstitute', val)}
            label={tl(translations.pages.contactEditing.contactSection.bankSection.fields.institute)}
          />
        </div>
      </div>
      <div className="row">
        <DeleteBankAccountButton
          index={indexOfBankAccount}
          contactId={contactId}
          bankAccountId={bankAccount?.id}
          removeFromList={removeBankAccountFromList}
        />
      </div>
      {addButton
      && (
        <AddBankAccountButton />
      )

      }
    </div>
  );
};
