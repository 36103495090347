import { getEnvVar } from 'lib/getEnvVar';

import {
  CasaviContact,
  CasaviFile,
  CasaviPage,
  CasaviProperty,
  CasaviTicket,
  CasaviTicketComment,
} from './interfaces';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface ExtraHeaders {
  'Content-Type': string,
  'X-Filename': string
}

const buildFetchOptions = (method: HttpMethod, token: string, extraHeaders?: ExtraHeaders): RequestInit => ({
  method,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json; charset=utf-8',
    token,
    ...extraHeaders,
  },
  credentials: 'include', // include, same-origin, *omit
  mode: 'cors', // no-cors, *same-origin
  redirect: 'follow', // *manual, error
  referrer: 'no-referrer', // *client
});

const casaviBaseURL = getEnvVar('casavi.apiBaseUrl', 'https://mocks.app.impower.de/services/casavi') as string;

export interface ICasaviAPI {
  getContactById: (contactId: string) => Promise<CasaviContact>;
  getAllTicketsBy: (params: { unitId?: string, propertyId?: string }) => Promise<CasaviTicket[]>;
}

export class CasaviAPI implements ICasaviAPI {
  private serverUrl: string;

  private token: string = 'no default token';

  constructor({ serverUrl, token }: { serverUrl?: string, token: string }) {
    this.serverUrl = serverUrl ?? casaviBaseURL;
    this.token = token;
  }

  authenticate() {
    throw new Error(`Use ERP backend for retrieving token; Backend set to ${this.serverUrl}`);
  }

  async getContactById(contactId: string): Promise<CasaviContact> {
    const response = await fetch(`${this.serverUrl}/v2/contacts/${contactId}`,
      buildFetchOptions('GET', this.token));
    return response.json() as Promise<CasaviContact>;
  }

  async getPropertyById(propertyId: string): Promise<CasaviProperty> {
    const response = await fetch(`${this.serverUrl}/v2/properties/${propertyId}`,
      buildFetchOptions('GET', this.token));
    return response.json() as Promise<CasaviProperty>;
  }

  async getPropertyPage(params: {page?: string}): Promise<CasaviPage<CasaviProperty>> {
    const response = await fetch(`${this.serverUrl}/v2/properties?${new URLSearchParams(params).toString()}`,
      buildFetchOptions('GET', this.token));
    return response.json() as Promise<CasaviPage<CasaviProperty>>;
  }

  async getAllProperties(): Promise<CasaviProperty[]> {
    const firstPage = await this.getPropertyPage({ page: '1' });
    const restOfThePages = await this.getFollowingPropertyPages(firstPage);
    const allProperties = [...firstPage.list, ...restOfThePages];
    return allProperties;
  }

  async getFollowingPropertyPages(currentPage: CasaviPage<CasaviProperty>): Promise<CasaviProperty[]> {
    if (!currentPage || !currentPage._links || !currentPage._links.next) {
      return [];
    }

    const response = await fetch(currentPage._links.next.replace('https://api.mycasavi.com', casaviBaseURL), buildFetchOptions('GET', this.token));
    const nextPage = await (response.json() as Promise<CasaviPage<CasaviProperty>>);

    // recuresively load next pages concatenating lists
    return [...nextPage.list, ...(await this.getFollowingPropertyPages(nextPage))];
  }

  async getTicketPageBy(params: { unitId?: string, propertyId?: string }): Promise<CasaviPage<CasaviTicket>> {
    const response = await fetch(`${this.serverUrl}/v2/tickets?${new URLSearchParams(params).toString()}`,
      buildFetchOptions('GET', this.token));
    return response.json() as Promise<CasaviPage<CasaviTicket>>;
  }

  async getAllTicketsBy(params: { unitId?: string, propertyId?: string }): Promise<CasaviTicket[]> {
    const firstPage = await this.getTicketPageBy(params);
    const restOfTheTickets = await this.getFollowingPages(firstPage);
    const allTickets = [...firstPage.list, ...restOfTheTickets];
    return allTickets;
  }

  async getFollowingPages(currentPage: CasaviPage<CasaviTicket>): Promise<CasaviTicket[]> {
    if (!currentPage || !currentPage._links || !currentPage._links.next) {
      return [];
    }
    const response = await fetch(currentPage._links.next.replace('https://api.mycasavi.com', casaviBaseURL), buildFetchOptions('GET', this.token));
    const nextPage = await (response.json() as Promise<CasaviPage<CasaviTicket>>);

    // recuresively load next pages concatenating lists
    return [...nextPage.list, ...(await this.getFollowingPages(nextPage))];
  }

  async uploadFile(fileType: string, fileName: string, file: Blob) {
    const fetchOptions = buildFetchOptions('POST', this.token, {
      'Content-Type': fileType,
      'X-Filename': fileName,
    });
    const data: FormData = new FormData();
    data.append('file', file);
    fetchOptions.body = data;
    const response = await fetch(`${this.serverUrl}/v2/files`, fetchOptions);
    return response.json() as Promise<CasaviFile>;
  }

  async commentOnTicket(ticketId: string, text: string, attachments?: number[]) {
    const fetchOptions = buildFetchOptions('POST', this.token);
    fetchOptions.body = JSON.stringify({
      text,
      attachments,
      // store as a private comment so not all users will have direct access automatically
      isInternal:true
    });
    const response = await fetch(`${this.serverUrl}/v2/tickets/${ticketId}/comments`, fetchOptions);
    return response.json() as Promise<CasaviTicketComment>;
  }
}
