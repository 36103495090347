import React, { useContext, useEffect, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';
import './AccountSheet.scss';
import AccountSheetHeader from './AccountSheetHeader/AccountSheetHeader';
import PostingListFilter from './PostingListFilter/PostingListFilter';
import PostingList from './PostingList/PostingList';
import useFullPageScroll from '../../services/useFullPageScroll';
import Button from '../../elements/Buttons/Button/Button';
import { ICONS } from '../../components/icons';
import { useAccountOverviewDataExportModal } from '../Account/AccountsOverview/components/useAccountOverviewDataExportModal';
import { AccountOverviewDataExportBody, DOWNLOAD_ALL_ACCOUNTS } from '../Account/AccountsOverview/components/AccountOverviewDataExportBody';
import { useAccountSheet } from './services/useAccountSheet';

const HeaderRightSideComponent = ({ onDownload, disabled }: { onDownload: () => void, disabled: boolean }) => (
  <div className="header-component">
    <PostingListFilter />
    <Button className="download-button" disabled={disabled} onClick={onDownload}>
      <Icon component={ICONS.download} />
    </Button>
  </div>
);

export default function AccountSheet(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    currentEconomicYear, previousEconomicYear, selectedProperty, setSelectedPropertyHrId, accountsBalances, filterState, selectedAccount, dateRange, accountDateRange,
  } = useAccountSheet();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  useEffect(() => {
    if (selectedProperty.data?.propertyHrId !== propertyHrId) {
      setSelectedPropertyHrId(propertyHrId);
    }
  }, [propertyHrId, selectedProperty]);


  const dataExportModalProps = useAccountOverviewDataExportModal({
    currentEconomicYear,
    previousEconomicYear,
    startDateProp: accountDateRange ? accountDateRange[0] : dateRange[0],
    endDateProp: accountDateRange ? accountDateRange[1] : dateRange[1],
  });

  useEffect(() => {
    if (accountDateRange) {
      setStartDate(accountDateRange[0]);
      setEndDate(accountDateRange[1]);
    } else {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }
  }, [dateRange, accountDateRange]);

  const {
    setEndDate, setStartDate, setSelectedAccounts, showModal, setDownloadAllAccountsValue, onClickDownload, onClickPrint,
  } = dataExportModalProps;

  const accountOverviewDataExportModalProps = useMemo(() => ({
    currentEconomicYear,
    previousEconomicYear,
    accountsBalances,
    dataExportModalProps,
    onClickDownload: () => { onClickDownload(propertyHrId, filterState.includeCorrectional); },
    onClickPrint: () => { onClickPrint(propertyHrId, filterState.includeCorrectional); },
  }), [currentEconomicYear, previousEconomicYear, accountsBalances, dataExportModalProps, propertyHrId, filterState.includeCorrectional]);

  useFullPageScroll({
    tableName: 'postingList',
    beforeElement: document.getElementsByClassName('AccountSheetHeader')[0],
    className: 'hidden',
  });

  const onDownload = () => {
    setDownloadAllAccountsValue(DOWNLOAD_ALL_ACCOUNTS.FALSE);
    setSelectedAccounts([selectedAccount.code]);
    showModal();
  };

  const downloadButtonDisabled = isEmpty(selectedAccount) || accountsBalances.loading || accountsBalances.error || selectedProperty.loading || selectedProperty.error;

  return (
    <Page className="AccountSheet">
      <PageHeader
        title={tl(translations.pages.accountSheet.header.title)}
        subtitle={<PropertyInfo property={selectedProperty.data} />}
        rightSideComponent={(
          <HeaderRightSideComponent
            disabled={downloadButtonDisabled}
            onDownload={onDownload}
          />
        )}
      />
      <PageContent>
        <AccountSheetHeader />
        <div className="page-divider" />
        <PostingList />
        <AccountOverviewDataExportBody {...accountOverviewDataExportModalProps} />
      </PageContent>
    </Page>
  );
}
