import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { ColumnProps } from 'antd/lib/table';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import { translations } from '../translations';
import { useOnChangeDistributionValues } from './useOnChangeDistributionValues';
import ProcessingSelector from '../components/ManualExchangeValuesSection/ProcessingSelector';
import { UnitContractProjectionDto } from '../../../api/accounting';
import { PaymentDirectionSwitch } from '../../../storybook-components/inputs/PaymentDirectionSwitch/PaymentDirectionSwitch';
import { AccountSelectionContext, TableDataSourceType } from './ManualExchangeContext';
import AmountToDistributeCell from '../components/ManualExchangeValuesSection/AmountToDistributeCell/AmountToDistributeCell';
import ManualExchangeVatSelectorCell from '../components/ManualExchangeValuesSection/ManualExchangeVatSelectorCell/ManualExchangeVatSelectorCell';


export const useManualExchangeValuesSectionColumns: () => ColumnProps<UnitContractProjectionDto>[] = () => {
  const { tl } = useContext(LanguageContext);
  const accountSelectionContext = useContext(AccountSelectionContext);
  const { formValues } = accountSelectionContext;
  const onChangeDistributionValues = useOnChangeDistributionValues();
  const displayVatColumn = formValues?.property?.vatRateCountryCode !== undefined;

  const columns = [
    {
      title: tl(translations.creationPage.sections.section2.unitNumber),
      dataIndex: 'unitNrSharingDeclaration',
      width: '150px',
      render: value => (
        <PopoverTruncatingText
          value={value}
          containerClassName="ManualExchangeCreation"
        />
      ),
    },
    {
      title: tl(translations.creationPage.sections.section2.ownerContract),
      dataIndex: ['mailingContact', 'name'],
    },
    {
      title: tl(translations.creationPage.sections.section2.amountToDistribute),
      dataIndex: 'amountToDistribute',
      render: (_, rec: TableDataSourceType) => <AmountToDistributeCell record={rec} onChangeDistributionValues={onChangeDistributionValues} />,
    },
    {
      title: tl(translations.creationPage.sections.section2.direction),
      dataIndex: 'direction',
      render: (_, rec: TableDataSourceType) => (
        <PaymentDirectionSwitch defaultValue="incoming" onChange={value => onChangeDistributionValues(rec.accountCode!, 'direction', value, rec)} disabled={!rec.selected} />
      ),
      width: '150px',
    },
    {
      title: tl(translations.creationPage.sections.section2.processing),
      dataIndex: 'processing',
      render: (_, rec) => <ProcessingSelector record={rec} onChange={onChangeDistributionValues} />,
      width: '250px',
    },
  ];

  if (displayVatColumn) {
    columns.splice(2, 0, {
      title: tl(translations.creationPage.sections.section2.vat),
      dataIndex: 'vatRate',
      render: (_, rec: TableDataSourceType) => (
        <ManualExchangeVatSelectorCell record={rec} onChangeDistributionValues={onChangeDistributionValues} />
      ),
      width: '130px',
    });
  }
  return columns;
};
