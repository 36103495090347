import { Col, Form, Row } from 'antd';
import { MessageCreateDtoLetterDispatchTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import CheckboxInput from 'elements/Inputs/CheckboxInput/CheckboxInput';
import RadioGroupInput from 'elements/Inputs/RadioGroupInput/RadioGroupInput';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { useDistributionModeRow } from './useDistributionModeRow';
import './DistributionModeRow.scss';


const DistributionModeRow = () => {
  const { tl } = useContext(LanguageContext);
  const {
    onChangeRadioButton, value, options,
    onChangeDistributionMode,
    portalCheckboxValue,
    portalCheckboxIndeterminate,
    epostCheckboxValue,
    epostCheckboxIndeterminate,
    manualCheckboxValue,
    manualCheckboxIndeterminate,
    disabled,
    epostDisabled,
    portalDisabled,
    portalTooltipText,
    epostTooltipText,
  } = useDistributionModeRow();

  return (
    <Row justify="space-between" className="DistributionModeRow">
      <Col>
        <RadioGroupInput
          options={options}
          label={tl(messageSendingTranslations.distribute)}
          onChange={onChangeRadioButton}
          value={value}
          disabled={disabled}
        />
      </Col>

      <Col>
        <Form.Item
          label={tl(messageSendingTranslations.applyToAll)}
        />
        <Row className="distribution-option-checkboxes">
          <CheckboxInput
            label={tl(messageSendingTranslations.portal)}
            value={portalCheckboxValue}
            indeterminate={portalCheckboxIndeterminate}
            onChange={() => onChangeDistributionMode(MessageCreateDtoLetterDispatchTypeEnum.PORTAL)}
            disabled={value || portalDisabled}
            tooltipText={portalTooltipText}
          />
          <CheckboxInput
            label={tl(messageSendingTranslations.epost)}
            value={epostCheckboxValue}
            indeterminate={epostCheckboxIndeterminate}
            onChange={() => onChangeDistributionMode(MessageCreateDtoLetterDispatchTypeEnum.EPOST)}
            disabled={value || epostDisabled}
            tooltipText={epostTooltipText}
          />
          <CheckboxInput
            label={tl(messageSendingTranslations.manual)}
            value={manualCheckboxValue}
            indeterminate={manualCheckboxIndeterminate}
            onChange={() => onChangeDistributionMode(MessageCreateDtoLetterDispatchTypeEnum.MANUAL)}
            disabled={value}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default DistributionModeRow;
