import { RecipientDatasource } from 'pages/MessageSendingPage/interfaces';
import { useRecipientUpdaterContext } from 'pages/MessageSendingPage/services/MessageSendingContext';

export const useRecipientTableOptions = (record: RecipientDatasource) => {
  const recipientUpdaterContext = useRecipientUpdaterContext('useRecipientTableOptions');

  const { setDocumentRecipients } = recipientUpdaterContext;


  const onRemove = () => {
    // no need to call setRecipients, because the list is re-initialized
    setDocumentRecipients(prev => prev.filter(dr => !(dr.contractId === record.contractId && dr.contactId === record.contactId)));
  };


  return { onRemove };
};
