import React, { useContext } from 'react';
import './LegacyActionBar.scss';
import Icon from '@ant-design/icons';
import { Action } from '../../elements/SmartTable/data';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from './translations';
import { ICONS } from '../icons';
import Button from '../../elements/Buttons/Button/Button';


interface LegacyActionBarProp {
  records: any[],
  actions: Action[],
  onUnselectAll: () => void,
}

export default function LegacyActionBar(props: LegacyActionBarProp): JSX.Element {
  const { actions, records, onUnselectAll } = props;
  const { tl } = useContext(LanguageContext);

  return (
    <div className="LegacyActionBar">
      <div className="checked-nr">
        <Icon className="icon" component={ICONS.checkBoxCheckMark} />
        <div className="records-number">
          {records.length}
        </div>
      </div>
      <div className="btn-wrapper">
        <Button
          className="action-btn unselect-btn"
          onClick={onUnselectAll}
        >
          {tl(translations.unselectAll)}
        </Button>
      </div>
      <div className="action-buttons">
        {actions && actions.filter(action => !!action.onBatchAction || !!action.element).map((action: Action) => {
          if (action.element) return (<div key={`action-${action.label}`} className="btn-wrapper">{action.element}</div>);
          return (
            <div key={`action-${action.label}`} className="btn-wrapper">
              <Button
                className="action-btn"
                onClick={() => {
                  if (action.onBatchAction) {
                    action.onBatchAction(records);
                    onUnselectAll();
                  }
                }}
              >
                {action.label}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
