export const eInvoiceTranslations = {
    invoiceNumber: {
        en: 'Invoice number:',
        de: 'Rechnungsnummer:',
    },
    invoiceDate: {
        en: 'Invoice date:',
        de: 'Rechnungsdatum:',
    },
    items: {
        en: 'Invoice line items',
        de: 'Rechnungsposten'
    },
    unitPrice : {
        en: 'Unit price',
        de: 'Einheitspreis',
    },
    count: {
        en: 'Count',
        de: 'Menge',
    },
    vat: {
        en: 'VAT',
        de: 'USt.',
    },
    net: {
        en: 'Net',
        de: 'Netto',
    },
    gross: {
        en: 'Gross',
        de: 'Brutto',
    },
    discount: {
        en: 'Discount',
        de: 'Rabatt',
    },
    total: {
        en: 'Total',
        de: 'Sum',
    },
    totalTax: {
        en: 'Total tax',
        de: 'Enthaltene USt.'
    },
    totalPrepaid: {
        en: 'Prepaid',
        de: 'Vorauszahlungen'
    },
    amountToPay: {
        en: 'Amount to pay',
        de: 'Zu zahlende Betrag'
    },
    orderTextIntro: {
        en: 'Please note that the invoice amount will be debited from your bank account in accordance with your direct debit mandate',
        de: 'Bitte beachten Sie, dass der Rechnungsbetrag entsprechend Ihres Lastschriftmandats von Ihrem Bankkonto abgebucht wird',
    },
    orderText: {
        en: 'Please transfer the above amount to the account',
        de: 'Bitte überweisen Sie den oben genannten Betrag auf das Konto'
    }
}