import moment from 'moment';
import { OwnersMeetingProtocolDto, OwnersMeetingProtocolRecipientExtendedDisplayDto } from '../../api/accounting';
import { Overwrite } from '../../lib/Utils';

export interface OwnersMeetingProtocolRecipient extends OwnersMeetingProtocolRecipientExtendedDisplayDto {
  signingIndex?: number,
  ownerName?: string,
  dispatchType?: DispatchType
}


interface OwnersMeetingProtocolExtension {
  startTime?: moment.Moment | string;
  endTime?: moment.Moment | string;
  meetingLeader?: number,
  meetingLeaderRole?: string,
  minuteTaker?: number,
  minuteTakerRole?: string,
  votingRights?: string,
  votingScheme?: string,
  presentPropertyShare?: number,
  numberOfVotes?: number,
  votingRightProtocolTextWithMea: string,
  votingRightProtocolTextHeadcount: string,
  nrPointOfOrders: number,
  lastSigningIndex: number
  protocolSigned: boolean
  dispatchType: DispatchType
  nrOfAdditionalDocuments: number
}

export interface OwnersMeetingProtocol extends Overwrite<OwnersMeetingProtocolDto, OwnersMeetingProtocolExtension> {
  isTitleTouched?: boolean,
  agendaPointProtocolTextIsStandardText?: boolean,
}

export interface OwnersMeetingProtocolTexts {
  meetingDurationProtocolText?: string,
  meetingLeaderProtocolText?: string,
  protocolLeaderProtocolText?: string,
  agendaPointProtocolText?: string,
  votingRightProtocolTextWithMea?: string,
  votingRightProtocolTextHeadcount?: string,
  votingRightProtocolText?: string,
  votingRulesProtocolText?: string,
  quorumProtocolText?: string
}

export enum DispatchType {
  EPOST = 'EPOST',
  PORTAL = 'PORTAL',
  PREFERENCE = 'PREFERENCE',
  BASED_ON_SELECTION = 'BASED_ON_SELECTION'
}
