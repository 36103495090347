import { GetUnitContractsUsingGETContractTypesEnum, UnitContractControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useRef } from 'react';
import { AllContractsListContext } from './AllContractsListContext';
import { translations } from './translations';

export const useAllContractsList = (propertyId: number | undefined, isComponentHidden?: boolean, contractTypes?: GetUnitContractsUsingGETContractTypesEnum[]) => {
  const allContractsListContext = useContext(AllContractsListContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);

  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  if (allContractsListContext === undefined) {
    throw new Error('useAllContractsList must be used within a UnitContractsListContextProvider');
  }

  const {
    allContractsList,
    setAllContractsList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
  } = allContractsListContext;


  const isCacheValid = paramOfCachedValue === propertyId;

  useEffect(() => {
    if (propertyId === undefined || isCacheValid || isComponentHidden) return;


    setParamOfFetchInProgress((prevParamOfFetchInProgress) => {
      // this must be inside the setParamOfFetchInProgress to make sure we have the latest
      // value of `paramOfFetchInProgress`, in case another instance of this hook
      // already triggered the fetch
      const doesCurrentParamMatchTheParamOfTheInProgressFetch = prevParamOfFetchInProgress === propertyId;

      if (prevParamOfFetchInProgress === undefined || !doesCurrentParamMatchTheParamOfTheInProgressFetch) {
        fetchAllContracts();

        return propertyId;
      }

      return prevParamOfFetchInProgress;
    });
  }, [propertyId, isCacheValid, isComponentHidden]);


  const fetchAllContracts = () => {
    if (propertyId === undefined) {
      throw new Error('Trying to fetch ALL unit contracts with undefined propertyId');
    }

    // if params changed since last initiated fetch then abort the in-progress fetch
    abortController.current?.abort();
    // create new abort controllers
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setAllContractsList(prev => prev.startLoading());

    unitContractControllerApi.getUnitContractsUsingGET({
      propertyId,
      contractTypes: contractTypes as unknown as GetUnitContractsUsingGETContractTypesEnum,
    }, { signal })
      .then((resp) => {
        setAllContractsList(prev => prev.load(resp));
        setParamOfCachedValue(propertyId);
      })
      .catch((err) => {
        if (signal.aborted) return;
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.loadContractsError),
        });
        setAllContractsList(prev => prev.failed(err));
      })
      .finally(() => {
        setParamOfFetchInProgress(undefined);
      });
  };

  return {
    allContractsList,
    manuallyTriggerFetchAllContracts: fetchAllContracts,
  };
};
