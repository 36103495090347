import { useContext } from 'react';
import { OpsCostContext, ReserveFundAmountContext } from '../../../../services/HouseMoneyContext';


export const useTotalOfUnit = (unitId: number, rowIndex: number) => {
  const reserveFundAmountContext = useContext(ReserveFundAmountContext);
  const opsCostContext = useContext(OpsCostContext);

  if (reserveFundAmountContext === undefined || opsCostContext === undefined) {
    throw new Error('useTableSummary must be used within a HouseMoneyListContextProvider');
  }

  const { reserveFundAmounts } = reserveFundAmountContext;
  const { opsCosts } = opsCostContext;

  const opsCostValue = parseFloat((opsCosts.find(ops => ops.unitId === unitId)?.amount ?? 0)
    ?.toFixed(2));

  const reserveFundSum = reserveFundAmounts[rowIndex]?.reduce((total, rf) => parseFloat(total.toFixed(12)) + parseFloat((rf.amount ?? 0).toFixed(2)), 0);

  return { value: opsCostValue + reserveFundSum };
};
