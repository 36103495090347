import { LanguageContext } from 'contexts/LanguageContext';
import { WATERMARKS_NAME_COLUMN_WIDTH } from 'pages/Watermarks/constants';
import { useContext } from 'react';
import { Watermark } from 'pages/Watermarks/interfaces';
import CellWithTwoLinesAndTag from 'storybook-components/table/CellWithTwoLinesAndTag/CellWithTwoLinesAndTag';
import { translations } from '../translations';


export const useWatermarksListColumns = () => {
  const { tl } = useContext(LanguageContext);
  return [
    {
      title: tl(translations.list.header.name),
      defaultWidth: WATERMARKS_NAME_COLUMN_WIDTH,
      dataKey: 'name',
      render: (_: any, record: Watermark) => (
        <CellWithTwoLinesAndTag
          firstElement={record.name}
          secondElement={record.metaData?.isDefault ? tl(translations.list.labels.default) : null}
        />
      ),
    },
  ];
};
