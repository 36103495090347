import { LanguageContext } from 'contexts/LanguageContext';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { DATE_FORMAT } from 'lib/Utils';
import moment from 'moment';
import { useContext } from 'react';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { Tooltip } from 'antd';
import { opsCostTranslation } from '../../translations';
import { OpsCostReportStatusCell } from '../components/OpsCostReportStatusCell';

export const useOpsCostReportColumns = () => {
  const { tl } = useContext(LanguageContext);
  return [
    {
      title: tl(opsCostTranslation.tableHeader.status),
      dataKey: 'status',
      render: (_, record) => <OpsCostReportStatusCell record={record} />,
    },
    {
      title: tl(opsCostTranslation.tableHeader.property),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(opsCostTranslation.tableHeader.title),
      dataKey: 'name',
      defaultWidth: '20%',
    },
    {
      title: tl(opsCostTranslation.tableHeader.dateRange),
      dataKey: 'dateRange',
      sortable: false,
      render: ((_, record) => `${moment(record.startDate).format(DATE_FORMAT)} - ${moment(record.endDate).format(DATE_FORMAT)}`),
      defaultWidth: '15%',
    },
    {
      title: tl(opsCostTranslation.tableHeader.accountant),
      dataKey: 'accountant',
    },
    {
      title: tl(opsCostTranslation.tableHeader.administrator),
      dataKey: 'administrator',
      defaultVisible: false,
      defaultWidth: '10%',
    },
    {
      title: tl(opsCostTranslation.tableHeader.creationDate),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
      defaultWidth: '6%',
    },
    {
      title: tl(opsCostTranslation.tableHeader.creationBy),
      dataKey: 'createdBy',
      defaultVisible: false,
      defaultWidth: '7%',
    },
  ];
};
