import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { AccountDistributionKeyControllerApi } from '../api/accounting/apis';
import { AccountDistributionKeyDto } from '../api/accounting/models';
import { showNotification } from '../lib/Notification';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from '../contexts/LanguageContext';

interface UseAccountDistributionKeyProps {
  propertyId: number,
  accountCode: string,
  year: string
  leaf: boolean
}

export function useAccountDistributionKey({
  propertyId, accountCode, year, leaf,
}: UseAccountDistributionKeyProps) {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const accountDistributionKeyControllerApi = new AccountDistributionKeyControllerApi(apiConfiguration('accounting'));


  const [accountDistributionKey, setAccountDistributionKey] = useState<AccountDistributionKeyDto>();

  useEffect(() => {
    loadAccountDistributionKey();
  }, [propertyId, accountCode, year, leaf]);

  const isAccountDistributable = (code: string, isLeaf: boolean) => isLeaf && !code.match(new RegExp('^(2000|2001|301|27|442)/.+$'));

  const loadAccountDistributionKey = async () => {
    if (!propertyId || !accountCode || !year || !isAccountDistributable(accountCode, leaf)) return;
    try {
      const response = await accountDistributionKeyControllerApi.getAccountDistributionKeyByYearUsingGET({ propertyId, accountCode, year });
      setAccountDistributionKey(response);
    } catch (error) {
      showNotification({
        key: 'loadAccountDistributionKeyError',
        message: tl(translations.notifications.loadAccountDistributionKeyError.message),
        type: 'error',
      });
    }
  };

  return {
    accountDistributionKey,
    loadAccountDistributionKey,
    isAccountDistributable,
  };
}
