import React, {
  useContext,
} from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from './translations';
import SmartTable from '../../elements/SmartTable/SmartTable';

import { usePropertyDistributionKeysTable } from './services/usePropertyDistributionKeysTable';
import { useGetPropertyDistributionKeySmartTableProps } from './services/useGetPropertyDistributionKeySmartTableProps';


export const PropertyDistributionKeysTable = ({ onCopy } : {onCopy: (record: any) => void}) => {
  const { tl } = useContext(LanguageContext);

  const {
    incomeTableData,
    expensesTableData,
    reserveFundsTableData,
    isWeg,
    isForOpsCost,
  } = usePropertyDistributionKeysTable();


  const smartTableExpenses = useGetPropertyDistributionKeySmartTableProps(isWeg, isForOpsCost, expensesTableData, 'propertyDistributionKeyExpenses', 'expenses', onCopy);


  const smartTableReserveFunds = useGetPropertyDistributionKeySmartTableProps(isWeg, isForOpsCost, reserveFundsTableData, 'propertyDistributionKeyReserveFunds', 'reserveFunds', onCopy);


  const smartTable = useGetPropertyDistributionKeySmartTableProps(isWeg, isForOpsCost, incomeTableData, 'propertyDistributionKeyIncome', 'income', onCopy);

  return (

    <div className="income-expense-tables-wrapper">
      <div className="table-header">
        {tl(translations.propertyDistributionKey.incomeHeader)}
      </div>
      <SmartTable {...smartTable} />
      <div className="table-header">
        {tl(translations.propertyDistributionKey.reserveFunds)}
      </div>
      <SmartTable {...smartTableReserveFunds} />
      <div className="table-header">
        {tl(translations.propertyDistributionKey.expensesHeader)}
      </div>
      <SmartTable {...smartTableExpenses} />
    </div>
  );
};
