import React, {
  createContext,
  ReactNode,
  useState,
} from 'react';

import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';

import { AgendaItemVoteDto } from '../../../../../../api/accounting';

export const AgendaItemVoteContext = createContext<{
  selectedAgendaItemVote: DefaultDataInterface<AgendaItemVoteDto>,
  setSelectedAgendaItemVote: React.Dispatch<React.SetStateAction<DefaultDataInterface<AgendaItemVoteDto>>>,
  isStandardVoteDetails: boolean,
  setIsStandardVoteDetails: React.Dispatch<React.SetStateAction<boolean>>,
  voteIsDirty: boolean,
  setVoteIsDirty:React.Dispatch<React.SetStateAction<boolean>>,
} | undefined>(undefined);


const AgendaItemVoteContextProvider = ({ children }: { children: ReactNode }) => {
  const [selectedAgendaItemVote, setSelectedAgendaItemVote] = useState(DEFAULT_DATA<AgendaItemVoteDto>({ }));
  const [isStandardVoteDetails, setIsStandardVoteDetails] = useState<boolean>(true);
  const [voteIsDirty, setVoteIsDirty] = useState<boolean>(false);

  const providerValue = {
    selectedAgendaItemVote,
    setSelectedAgendaItemVote,
    isStandardVoteDetails,
    setIsStandardVoteDetails,
    setVoteIsDirty,
    voteIsDirty,
  };

  return (
    <AgendaItemVoteContext.Provider value={providerValue}>
      {children}
    </AgendaItemVoteContext.Provider>
  );
};

export default AgendaItemVoteContextProvider;
