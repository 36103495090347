import { OpsCostReportProjectionStatusEnum, UnitContractProjectionDto } from 'api/accounting';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { HeaderTagColorKeys } from 'storybook-components/headers/utils';
import { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import {
  IndividualTenantCostManualValue, OpsCostSectionKeys, OtherOrTransferableCostManualValue, YourPrepaymentManualValue,
} from '../OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/interfacesOpsCostReportManualAmounts';

export function getDotColor(state: OpsCostReportProjectionStatusEnum): HeaderTagColorKeys {
  switch (state) {
    case OpsCostReportProjectionStatusEnum.BOOKED:
    case OpsCostReportProjectionStatusEnum.SENT:
      return PageHeaderTagColor.BLUE as HeaderTagColorKeys;
    case OpsCostReportProjectionStatusEnum.DONE:
      return PageHeaderTagColor.GREEN as HeaderTagColorKeys;
    case OpsCostReportProjectionStatusEnum.FAILED:
      return PageHeaderTagColor.RED as HeaderTagColorKeys;
    case OpsCostReportProjectionStatusEnum.IN_GENERATION:
      return PageHeaderTagColor.YELLOW as HeaderTagColorKeys;
    default:
      return PageHeaderTagColor.GRAY as HeaderTagColorKeys;
  }
}

export const headerAndFooterStyling = `
<style>
  header, footer {
    font-family: 'IBM Plex Sans';
    font-size: 9px;
    line-height: 12px;
    color: #595959;
    width: 100%;
  }

  @media (orientation: portrait) {
    header, footer {
      margin-left: 23mm;
      margin-right: 12mm;
    }
  }

  @media (orientation: landscape) {
    header, footer {
      margin-left: 12mm;
      margin-right: 18mm;
    }
  }
</style>
`;


export const getHeader = (headerLeft: string, headerRight: string) => (`
${headerAndFooterStyling}
<header>
  <span style="float: left;">${headerLeft}</span>
  <span style="float: right;">${headerRight}</span>
</header>`
);

// the classnames `pageNumber` and `totalPages` are used to inject values
// https://pptr.dev/next/api/puppeteer.pdfoptions.headertemplate/
export const getFooter = () => (`
${headerAndFooterStyling}
<footer>
  <div style="float: left;">${moment().format('DD.MM.YYYY')}</div>
  <span style="float: right;"><span class='pageNumber'></span>/<span class='totalPages'></span></span>
</footer>`
);

export const wrapHtmlWithBoilerplate = (html: string) => `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8"/>
    <link rel="stylesheet" type="text/css" media="all" href="style.css"/>
  </head>
  <body 'print-no-header-first-page'>
    ${html}
  </body>
  </html>`;

// we have to use it when we choose a WEG property because for WEG we don't have accounting for tenant contracts, we only have it for WOU and SEV
export const getValidTenantContract = (selectedTenantContracId: number, sevTenantContracts: UnitContractProjectionDto[], tenantContracts: UnitContractProjectionDto[]) => {
  const tenantContract = tenantContracts.find(contract => contract.unitContractId === selectedTenantContracId);
  if (isEmpty(sevTenantContracts)) {
    return tenantContract;
  }

  const sevTenantContract = tenantContract?.contractGroupId && sevTenantContracts.find(sevContract => sevContract?.contractGroupId === tenantContract?.contractGroupId);

  if (sevTenantContract) {
    return sevTenantContract;
  }

  return tenantContract;
};


export const getRowClassName = (isHiddenRow: boolean, isManualRow?: boolean) => {
  if (isManualRow) {
    return 'table-row-with-inputs';
  }

  if (isHiddenRow) {
    return 'table-row-strike-trough';
  }
  return '';
};


export const areAllManualValuesEmpty = (row: IndividualTenantCostManualValue|OtherOrTransferableCostManualValue|YourPrepaymentManualValue, requiredKeys: string[]) => requiredKeys.every(key => row && (row[key] === '' || row[key] === undefined || row[key] === 0));

const REQUIRED_KEYS_FOR_INDIVIDUAL_TENANT_COST_VAT_RELEVANT = ['accountCode', 'accountName', 'yourAmountGross', 'yourAmountNet', 'yourAmountVat', 'totalAmountGross', 'totalAmountNet'];
const REQUIRED_KEYS_FOR_INDIVIDUAL_TENANT_COST = ['accountCode', 'accountName', 'yourAmountGross', 'totalAmountGross'];
const REQUIRED_KEYS_FOR_YOUR_PREPAYMENTS_VAT_RELEVANT = ['date', 'bookingText', 'amountGross', 'amountVat', 'amountNet'];
const REQUIRED_KEYS_FOR_YOUR_PREPAYMENTS = ['date', 'bookingText', 'amountGross'];
const REQUIRED_KEYS_FOR_OTHER_OR_TRANSFERABLE_COST_VAT_RELEVANT = ['accountCode', 'accountName', 'distributionKey', 'yourAmountGross', 'yourAmountNet', 'yourAmountVat', 'totalAmountGross', 'totalAmountNet'];
const REQUIRED_KEYS_FOR_OTHER_OR_TRANSFERABLE_COST = ['accountCode', 'accountName', 'distributionKey', 'yourAmountGross', 'totalAmountGross'];

export const getRequiredKeysBasedOnSectionAndVatRelevance = (section: OpsCostSectionKeys, isContractVatRelevant: boolean) => {
  switch (section) {
    case 'individualTenantCosts':
      if (isContractVatRelevant) {
        return REQUIRED_KEYS_FOR_INDIVIDUAL_TENANT_COST_VAT_RELEVANT;
      }
      return REQUIRED_KEYS_FOR_INDIVIDUAL_TENANT_COST;
    case 'otherOrTransferableCosts':
      if (isContractVatRelevant) {
        return REQUIRED_KEYS_FOR_OTHER_OR_TRANSFERABLE_COST_VAT_RELEVANT;
      }
      return REQUIRED_KEYS_FOR_OTHER_OR_TRANSFERABLE_COST;
    case 'yourPrepayments':
      if (isContractVatRelevant) {
        return REQUIRED_KEYS_FOR_YOUR_PREPAYMENTS_VAT_RELEVANT;
      }
      return REQUIRED_KEYS_FOR_YOUR_PREPAYMENTS;
    default: return undefined;
  }
};
