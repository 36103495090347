import './CellWithTwoLines.scss';
import { CellWithTwoLinesComponentProps } from './interfaces';
import { useCellWithTwoLines } from './useCellWithTwoLines';

const CellWithTwoLines = ({
  firstElement,
  secondElement,
  disabled,
  ...restProps
}: CellWithTwoLinesComponentProps) => {
  const {
    onClickFirst,
    onClickSecond,
    onClickWrapper,
    onKeyDownWrapper,
    tabIndexFirstElement,
    tabIndexSecondElement,
    tabIndexWrapper,
  } = useCellWithTwoLines({ ...restProps, disabled });

  const firstElementClassname = `first-element${restProps.onClickFirstElement ? ' underlined' : ''} ${disabled ? 'disabled' : ''}`;
  const secondElementClassname = `second-element${restProps.onClickSecondElement ? ' underlined' : ''} ${disabled ? 'disabled' : ''}`;
  const wrapperClassName = `CellWithTwoLines${restProps.onClickWrapperElement ? ' clickable' : ''}`;


  return (
    <div
      className={wrapperClassName}
      onClick={onClickWrapper}
      onKeyDown={onKeyDownWrapper}
      role="button"
      tabIndex={tabIndexWrapper}
    >
      <div
        className={firstElementClassname}
        onClick={onClickFirst}
        role="button"
        tabIndex={tabIndexFirstElement}
      >
        {firstElement}
      </div>
      <div
        className={secondElementClassname}
        onClick={onClickSecond}
        role="button"
        tabIndex={tabIndexSecondElement}
      >
        {secondElement}
      </div>
    </div>
  );
};

export default CellWithTwoLines;

CellWithTwoLines.defaultProps = {
  tabIndex: 0,
  onClickFirstElement: undefined,
  onClickSecondElement: undefined,
  onClickWrapperElement: undefined,
};
