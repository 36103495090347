import { Table } from 'antd';
import { formatCurrency } from 'lib/Utils';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
  title: string,
  nrTotalColumns: number,
  totalGross: number,
  totalNet?: number,
  totalVat?: number,
  totalVatEligibility?: number,
  totalVatEligibilityPercentage?: number,
  displayVatColumns?: boolean,

}

const ExpenseTableSummary = ({
  title, totalGross, nrTotalColumns, totalNet, totalVat, totalVatEligibility, totalVatEligibilityPercentage, displayVatColumns = false,
}: Props) => (
  <Table.Summary>
    <Table.Summary.Row className="ProfitAndLossSummary">
      <Table.Summary.Cell index={0} colSpan={nrTotalColumns - (displayVatColumns ? 4 : 1)}>
        {title}
      </Table.Summary.Cell>
      {displayVatColumns && (
        <>
          <Table.Summary.Cell className="column-align-right no-wrap normal-weight" index={nrTotalColumns - 4} colSpan={1}>
            <Amount>{formatCurrency(totalNet, '-', false)}</Amount>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="column-align-right no-wrap normal-weight" index={nrTotalColumns - 3} colSpan={1}>
            <Amount>{formatCurrency(totalVat, '-', false)}</Amount>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="column-align-right no-wrap normal-weight" index={nrTotalColumns - 2} colSpan={1}>
            <CellWithTwoLines
              firstElement={<Amount>{formatCurrency(totalVatEligibility, '-', false)}</Amount>}
              secondElement={`${totalVatEligibilityPercentage}%`}
            />
          </Table.Summary.Cell>
        </>
      )}
      <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
        <Amount>{formatCurrency(totalGross, '-', false)}</Amount>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </Table.Summary>
);

export default ExpenseTableSummary;

ExpenseTableSummary.defaultProps = {
  totalNet: undefined,
  totalVat: undefined,
  totalVatEligibility: undefined,
  totalVatEligibilityPercentage: undefined,
  displayVatColumns: false,
};
