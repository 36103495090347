import React, { useMemo } from 'react';
import Icon from '@ant-design/icons';
import { Menu } from 'antd';

import './SimpleSidebar.scss';
import { ICONS } from '../../../components/icons';

export interface NavigationElement {
  name: string,
  elementId: string,
  subElements?: NavigationElement[]
}

export interface NavigationElementGroup {
  groupName: string,
  elements: NavigationElement[],
}

interface SimpleSidebarProps {
  groups: NavigationElementGroup[]
  expandElement?: (category: string) => void,
}

export function SimpleSidebar(props: SimpleSidebarProps) {
  const { groups, expandElement } = props;

  const onSelected = (params: any): void => {
    const { key } = params;

    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (expandElement) {
      // find clicked element
      const clickedElement = formattedGroups.flatMap(group => group.elements).filter((el: any) => el.elementId === key)[0];
      let expandingElementUrl = '';
      // if clicked element is sub element, card expanding should occur on parent element
      if (clickedElement.isSubElement) {
        expandingElementUrl = clickedElement.parentElementUrl;
      } else {
        expandingElementUrl = clickedElement.elementId;
      }
      expandElement(expandingElementUrl.substring(1, expandingElementUrl.length));
    }
  };

  const flattenElements = (group: NavigationElementGroup) => {
    const tempElements: any = [];
    group.elements.forEach((element) => {
      tempElements.push({ ...element, isSubElement: false });
      if (element.subElements) {
        element.subElements.forEach((subElement: any) => {
          tempElements.push({
            ...subElement,
            isSubElement: true,
            parentElementUrl: element.elementId,
          });
        });
      }
    });
    return {
      ...group,
      elements: tempElements,
    };
  };

  const formattedGroups = useMemo(() => groups.map(group => flattenElements(group)), [groups]);

  return (
    <div className="SimpleSidebar">
      <Menu
        className="simple-sidebar-menu"
        onClick={onSelected}
      >
        {formattedGroups.map(group => (
          <Menu.ItemGroup
            key={group.groupName}
            title={group.groupName}
            className="group"
          >
            {group.elements.map((element: any) => (
              <Menu.Item
                key={element.elementId}
                className={`element ${element.isSubElement ? 'sub-element' : ''}`}
              >
                {element.isSubElement && <Icon component={ICONS.circle} />}
                {element.name}
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        ))}
      </Menu>
    </div>
  );
}
